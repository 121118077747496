import React, { Component } from "react";
import ShorelineImg from "./lightened-seashore.jpg";

class HomeValue extends Component {
  render() {
    let projects = [
      {
        project: "Manufactured Stone Veneer",
        cost: 10175,
        resaleValue: 12157,
        costRecouped: 119.5
      },
      {
        project: "Garage Door Replacement/Upgrade",
        cost: 3874,
        resaleValue: 4537,
        costRecouped: 117.1
      },
      {
        project: "Entry Door Replacement",
        cost: 2048,
        resaleValue: 1801,
        costRecouped: 87.9
      },
      {
        project: "Minor Kitchen Remodel | Midrange",
        cost: 18059,
        resaleValue: 22657,
        costRecouped: 86.6
      },
      {
        project: "Deck Addition | Wood",
        cost: 26150,
        resaleValue: 13924,
        costRecouped: 77.1
      },
      {
        project: "Roofing Replacement",
        cost: 27769,
        resaleValue: 20902,
        costRecouped: 75.3
      },
      {
        project: "Window Replacement",
        cost: 22976,
        resaleValue: 16750,
        costRecouped: 72.9
      },
      {
        project: "Bath Remodel | Midrange",
        cost: 24757,
        resaleValue: 16349,
        costRecouped: 66.0
      },
      {
        project: "Major Kitchen Remodel | Midrange",
        cost: 75292,
        resaleValue: 47780,
        costRecouped: 63.3
      }
    ];
    let mappedProjectRows = projects.map((row, i) => {
      return (
        <tr key={row.project}>
          <td>{row.project}</td>
          <td>
            <div className="inner-table-data">
              <p>$</p>
              <p>{row.cost.toLocaleString()}</p>
            </div>
          </td>

          <td>
            <div className="inner-table-data">
              <p>$</p>
              <p>{row.resaleValue.toLocaleString()}</p>
            </div>
          </td>
          <td>
            <p>{Math.round(row.costRecouped)}%</p>
          </td>
        </tr>
      );
    });
    return (
      <div className="home-value">
        <img
          src={ShorelineImg}
          alt="Shoreline Img"
          className="home-value-bg-img"
        />
        <div className="home-value-body">
          <div className="home-value-header">
            <div className="home-value-title">Increase Your Home's Value</div>
            <div className="home-value-divider" />
            <div className="home-value-subtitle">
              Leading Home Improvements for Best Return on Investment
            </div>
          </div>
          <table className="home-value-table">
            <thead>
              <tr>
                <th>Project</th>
                <th>Cost</th>
                <th>
                  Resale <br /> Value
                </th>
                <th>
                  Cost <br />
                  Recouped
                </th>
              </tr>
            </thead>
            <tbody>{mappedProjectRows}</tbody>
          </table>
          <div className="home-value-source">Source: www.remodeling.hw.net</div>
        </div>
      </div>
    );
  }
}

export default HomeValue;
