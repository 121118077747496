import { green, red, blueGrey, grey, yellow, amber } from "@material-ui/core/colors";
import palette from "theme/palette";

export default theme => ({
  root: {
    fontFamily: "objektiv-mk2 !important",
  },
  //add group details
  objectiveMk2: {
    fontFamily: "objektiv-mk2",
  },
  mailproSupportDialogPaper: {
    width: '600px'
  },
  mailproSupportHeaderFullName: {
    fontSize: '18px',
    lineHeight: '32px'
  },
  mailproSupportTitle: {
    fontSize: '13px',
    lineHeight: '18px',
    letterSpacing: '0.01em',
    margin: '16px',
    textAlign: 'center'
  },
  mailproSupportHeader: {
    fontSize: '16px',
    fontFamily: "objektiv-mk2",
    lineHeight: "18px",
    letterSpacing: "0.01em",
    fontWeight: 500,
    color: 'white',
    textAlign: 'center'
  },
  mailproSupportAgentName: {
    fontSize: '16px'
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 0px'
  },
});
