export default theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        backgroundColor: theme.palette.background.default,
    },
    grid: {
        height: '100%'
    },
    optionGrid: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    optionGridButtonContainer: {
        display: 'flex',
        justifyContent: 'space-evenly'
    },
    optionGridButtons: {
        color: theme.palette.common.white,
        border: '1px solid white'
    },
    clear: {
        clear: "both"
    },
    margin4UnitBottom: {
        marginBottom: theme.spacing(4),
    },
    whiteColor: {
        color: theme.palette.common.white
    },
    textAlignCenter: {
        textAlign: "center"
    },
    fontWeigth500: {
        fontWeight: 500
    },
    field: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginTop: theme.spacing(1),
    },
    marginBottomField: {
        marginBottom: theme.spacing(1.5)
    },
    textField: {
        width: '100%',
        maxWidth: '100%',
        marginRight: theme.spacing(3),
        textAlign: 'left'
    },
    fieldError: {
        color: theme.palette.error.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
        textAlign: 'left'
    },
    portletFooter: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
        height: '48px',
    },
    footerButtons: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: '100%'
    },
    footerButtonsCenter: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    footerButtonsLeftRight: {
        flex: 1,
        textAlign: "left"
    },
    dropdownTopMargin: {
        marginTop: -theme.spacing(4.5)
    },
    dropdownTopMargin4Unit: {
        marginTop: -theme.spacing(4)
    },
    dropdownTopMargin6Unit: {
        marginTop: -theme.spacing(5.5)
    },
    dropdownTopMargin2Unit: {
        marginTop: -theme.spacing(3)
    },
    menu: {
        width: 200,
        textAlign: 'left'
    },
    marginBottomPortletContent: {
        marginBottom: theme.spacing(2)
    },
    hiddenGrid: {
        display: 'none'
    },
    messageGrid: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    dialogText: {
        lineHeight: '10px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        height: '120px'
    },
    successText: {
        textAlign: 'center',
        height: '200px',
        margin: theme.spacing(3),
    },
    centerJustifyContent: {
        justifyContent: 'center'
    },
    iconHolder: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
    },
    plusColColor: {
        color: '#012159'
    },
    iconLarge: {
        width: '30px',
        height: '30px',
        color: '#112657'
    },
    dialNumber: {
        marginTop: theme.spacing(1)
    },
    dialNumberButton: {
        marginTop: theme.spacing(2)
    },
    fontSize18px: {
        fontSize: '18px'
    },
    fontSize20px: {
        fontSize: '20px'
    },
    startHeadingSection: {
        fontSize: '18px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    contactBox: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        borderTop: '1px solid rgba(0, 0, 0, 0.08)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //backgroundColor: 'rgba(0, 0, 0, 0.08)',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    portletHeader: {
        height: '48px'
    }
});