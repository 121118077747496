import React from 'react';
import styles from "./styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { orange } from '@material-ui/core/colors';
import MailProStar from '../UI/MailProStar';


const HomeValuationRatingStarsView = ({ classes, ratingValue }) => {
    const filledStars = [];
    const emptyStars = [];
    for (let i = 0; i < ratingValue; i++) {
        filledStars.push(<MailProStar defaultFilled={true} starContainerStyle={{ width: '16px', height: '16px' }}  />);
    }
    for (let i = 0; i < 5 - ratingValue; i++) {
        emptyStars.push(<MailProStar defaultFilled={false} starContainerStyle={{ width: '16px', height: '16px'  }} />);
    }
    return <div className={classes.homeValuationRatingStarsViewMain}>
        {filledStars}
        {emptyStars}
    </div>
}

export default compose(withStyles(styles), withSnackbar)(HomeValuationRatingStarsView);
