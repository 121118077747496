import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";

class YearEndHighlights extends Component {
  getHighlightsCity = () => {
    const { endsWithS, calculateDiff, roundSalePrice } = propertyUtil;
    const { currentArea, yearEnd } = this.props;
    const { pmaYearEndCityDTO } = yearEnd;
    const {
      avgSalePriceDetailsForLast2Years,
      currentYearMonthSupplyForEachMonth,
      marketTrends,
    } = pmaYearEndCityDTO;
    // console.log('pmaYearEndCityDTO', pmaYearEndCityDTO);
    const { mlsCityName } = currentArea;
    let currentSalePriceDetails = avgSalePriceDetailsForLast2Years.filter(
      (obj) => obj.Year === "2020"
    );

    let pastYearObj = marketTrends.filter(
      (obj) => obj.TrendName === "Past 1-Year Trending"
    );

    let currentYearObj = marketTrends.filter(
      (obj) => obj.TrendName === "1-Year Trending"
    );

    let lowInventoryMonths = Object.values(currentYearMonthSupplyForEachMonth)
      .map((x) => +x)
      .filter((x) => x < 4).length;
    let balancedInventoryMonths = Object.values(
      currentYearMonthSupplyForEachMonth
    )
      .map((x) => +x)
      .filter((x) => x >= 4 && x <= 6).length;
    let highInventoryMonths = Object.values(currentYearMonthSupplyForEachMonth)
      .map((x) => +x)
      .filter((x) => x > 6).length;

    let inventoryObj = {
      lowInventory: lowInventoryMonths,
      balancedInventory: balancedInventoryMonths,
      highInventory: highInventoryMonths,
    };
    let mostInventoryMonths = Object.keys(inventoryObj).reduce((a, b) =>
      inventoryObj[a] > inventoryObj[b] ? a : b
    );
    const {
      AvgSalePrice: AvgSalePriceCurrentYear,
      AvgDaysOnMarket: AvgDaysOnMarketCurrentYear,
      HomesSold: HomesSoldCurrentYear,
    } = currentYearObj[0];
    const {
      percentOfHomesSoldGreaterThanAskingPrice: percentOfHomesSoldGreaterThanAskingPriceCurrentYear,
    } = currentSalePriceDetails;

    const {
      AvgSalePrice: AvgSalePricePastYear,
      AvgDaysOnMarket: AvgDaysOnMarketPastYear,
    } = pastYearObj[0];

    let dataObj = {
      compareDays: null,
      salePriceDiff: null,
      salePriceDataPoint: null,
      receivedPerc: null,
      receivedDataPoint: null,
      daysToSellPerc: null,
      daysToSellDataPoint: null,
      unitsSoldPerc: null,
      unitsSoldDataPoint: null,
      monthComparison: null,
    };

    dataObj.salePriceDiff = calculateDiff(
      AvgSalePriceCurrentYear,
      AvgSalePricePastYear
    );
    dataObj.daysToSellPerc = calculateDiff(
      AvgDaysOnMarketCurrentYear,
      AvgDaysOnMarketPastYear
    );

    let cityPoints = [
      { cPoint1: { title: "Market Health", text: "point1" } },
      { cPoint2: { title: "Buyer Activity", text: "point2" } },
      { cPoint3: { title: "Home Shortage", text: "point3" } },
    ];

    // Market Health (Bullet 1)
    // IF: Average Sale Price increases by 1% or more, then use this sentence:
    if (dataObj.salePriceDiff >= 1) {
      cityPoints[0].cPoint1.text = `${mlsCityName}${endsWithS(
        mlsCityName
      )} real estate market has shown resilience in the face of unprecedented economic and social challenges — exceeding expectations with an increased average sale price of ${roundSalePrice(
        AvgSalePriceCurrentYear
      )}, which is ${Math.round(dataObj.salePriceDiff)}% higher than 2019.`;
    } else {
      cityPoints[0].cPoint1.text = `In 2020, ${mlsCityName}${endsWithS(
        mlsCityName
      )} average sale price is ${roundSalePrice(
        AvgSalePriceCurrentYear
      )} — a decrease of ${Math.abs(
        Math.round(dataObj.salePriceDiff)
      )}% over 2019.`;
    }

    // Buyer Activity (Bullet 2)
    if (Math.round(percentOfHomesSoldGreaterThanAskingPriceCurrentYear) > 0) {
      // Buyer Activity (Bullet 2 option B)
      cityPoints[1].cPoint2.text = `Of the ${HomesSoldCurrentYear} homes sold in ${mlsCityName}, ${Math.round(
        percentOfHomesSoldGreaterThanAskingPriceCurrentYear
      )}% were sold at or over the asking price — another strong indicator of buyer demand outstripping available homes for sale.`;
    } else if (dataObj.daysToSellPerc < 0) {
      //  IF: Average Days on Market decreased, then use this sentence:
      cityPoints[1].cPoint2.text = `${mlsCityName} homes are being sold at an increasingly fast pace, with a new average of ${AvgDaysOnMarketCurrentYear} days to sell — ${Math.abs(
        Math.round(dataObj.daysToSellPerc)
      )}% fewer days than 2019, which was ${AvgDaysOnMarketPastYear}. `;
    } else if (dataObj.daysToSellPerc > 0) {
      cityPoints[1].cPoint2.text = `The average number of days to sell a home in ${mlsCityName} went up in 2020 as compared to 2019. The number of days it took to sell in 2019 was ${AvgDaysOnMarketPastYear} as compared to ${AvgDaysOnMarketCurrentYear} days in 2020 -- a ${Math.round(
        dataObj.daysToSellPerc
      )}% increase. `;
    } else {
      cityPoints[1].cPoint2.text = `The average days to sell a home in ${mlsCityName} remained unchanged from 2019 to 2020. It took an average of ${AvgDaysOnMarketCurrentYear} days to sell in both 2019 and 2020.`;
    }

    // Home Shortage (Bullet 3)
    //if monthsSupply < 4 it is low
    if (mostInventoryMonths === "lowInventory") {
      // IF: Of inventory was low a minimum of 6 out of 12 months, then use this sentence:
      cityPoints[2].cPoint3.text = `Housing inventory is measured by the “months supply” of available homes for sale. In 2020, ${lowInventoryMonths} out of 12 months, ${mlsCityName} experienced a low supply of homes for sale. A low supply of homes provides buyers less to choose from and typically applies upward pressure on home prices.`;
      cityPoints[2].cPoint3.title = "Home Shortage";
    } else if (mostInventoryMonths === "balancedInventory") {
      cityPoints[2].cPoint3.text = `Housing inventory is measured by the “months supply” of available homes for sale. In 2020, ${balancedInventoryMonths} out of 12 months, ${mlsCityName} experienced what is referred to as a 'balanced supply' of homes for sale. A balanced supply of homes does not typically give buyers or sellers a significant advantage.`;
      cityPoints[2].cPoint3.title = "Housing Supply";
    } else {
      //if high inventory
      cityPoints[2].cPoint3.text = `Housing inventory is measured by the “months supply” of available homes for sale. In 2020, ${highInventoryMonths} out of 12 months, ${mlsCityName} experienced an oversupply of homes for sale, giving buyers more to choose from, and is one of several market factors that applies downward pressure on home prices. `;
      cityPoints[2].cPoint3.title = "Housing Supply";
    }
    return cityPoints;
  };

  getHighlightsArea = () => {
    const {
      roundSalePrice,
      calculateDiff,
      endsWithS,
      shortenToYourNeighborhood,
    } = propertyUtil;
    const { currentArea, yearEnd, agentData } = this.props;
    const { isMemberOfTeam } = agentData;
    const { mlsNeighborhood } = currentArea;

    const {
      averageSalePriceForPast10Years,
      currentYearMonthSupplyForEachMonth,
      marketTrends,
    } = yearEnd.pmaYearEndRunAreaDTO;
    let past10YearsArr = Object.entries(
      averageSalePriceForPast10Years
    ).reverse();
    let thisYearSalePrice = Number(past10YearsArr[0][1]);
    let findHighestPastSalePrice = past10YearsArr.find((x) => {
      let convertXToNum = Number(x[1]);
      return convertXToNum > thisYearSalePrice;
    });
    let yearsSince = findHighestPastSalePrice
      ? Number(past10YearsArr[0][0]) - Number(findHighestPastSalePrice[0])
      : 0;

    let currentYearObj = marketTrends.filter(
      (obj) => obj.TrendName === "1-Year Trending"
    );
    let pastYearObj = marketTrends.filter(
      (obj) => obj.TrendName === "Past 1-Year Trending"
    );

    let lowInventoryMonths = Object.values(currentYearMonthSupplyForEachMonth)
      .map((x) => +x)
      .filter((x) => x < 4).length;
    let balancedInventoryMonths = Object.values(
      currentYearMonthSupplyForEachMonth
    )
      .map((x) => +x)
      .filter((x) => x >= 4 && x <= 6).length;
    let highInventoryMonths = Object.values(currentYearMonthSupplyForEachMonth)
      .map((x) => +x)
      .filter((x) => x > 6).length;

    let inventoryObj = {
      lowInventory: lowInventoryMonths,
      balancedInventory: balancedInventoryMonths,
      highInventory: highInventoryMonths,
    };
    let mostInventoryMonths = Object.keys(inventoryObj).reduce((a, b) =>
      inventoryObj[a] > inventoryObj[b] ? a : b
    );
    const {
      AvgSalePrice: AvgSalePriceCurrentYear,
      AvgDaysOnMarket: AvgDaysOnMarketCurrentYear,
    } = currentYearObj[0];

    const {
      AvgSalePrice: AvgSalePricePastYear,
      AvgDaysOnMarket: AvgDaysOnMarketPastYear,
    } = pastYearObj[0];

    let dataObj = {
      compareDays: null,
      salePriceDiff: null,
      salePriceDataPoint: null,
      receivedPerc: null,
      receivedDataPoint: null,
      daysToSellPerc: null,
      daysToSellDataPoint: null,
      unitsSoldPerc: null,
      unitsSoldDataPoint: null,
      monthComparison: null,
    };

    dataObj.salePriceDiff = calculateDiff(
      AvgSalePriceCurrentYear,
      AvgSalePricePastYear
    );
    dataObj.daysToSellPerc = calculateDiff(
      AvgDaysOnMarketCurrentYear,
      AvgDaysOnMarketPastYear
    );

    let areaPoints = [
      { aPoint1: { title: "Market Health", text: "point1" } },
      { aPoint2: { title: "Home Shortage", text: "point2" } },
      { aPoint3: { title: "Looking Forward", text: "point3" } },
    ];
    let salePriceDiff = calculateDiff(
      AvgSalePriceCurrentYear,
      AvgSalePricePastYear
    );
    // Market Health (Bullet 1)
    // IF: Average Sale Price increases by 1% or more, then use this sentence:
    if (salePriceDiff >= 1) {
      areaPoints[0].aPoint1.text = `The speed of the housing recovery from April’s economic shutdown has been impressive.  2020 market conditions showed ${shortenToYourNeighborhood(
        mlsNeighborhood
      )} favoring sellers, with the average sale price increasing to ${roundSalePrice(
        AvgSalePriceCurrentYear
      )}.${
        yearsSince > 0
          ? ` In the last ${
              yearsSince === 1 ? "year" : `${yearsSince} years`
            }, the average sale price has never been this high.`
          : ""
      }`;
    } else if (salePriceDiff === 0) {
      // IF: Average Sale Price stayed the same
      areaPoints[0].aPoint1.text = `In 2020, ${shortenToYourNeighborhood(
        mlsNeighborhood
      )}${endsWithS(
        mlsNeighborhood
      )} average sale price decreased to ${roundSalePrice(
        AvgSalePriceCurrentYear
      )}, which was the same as 2019’s average.`;
    } else {
      // IF: Average Sale Price decreases or stays the same, then use this:
      areaPoints[0].aPoint1.text = `In 2020, ${shortenToYourNeighborhood(
        mlsNeighborhood
      )}${endsWithS(
        mlsNeighborhood
      )} average sale price decreased to ${roundSalePrice(
        AvgSalePriceCurrentYear
      )}, which is ${Math.abs(
        salePriceDiff
      )}% lower than 2019’s average of ${roundSalePrice(
        AvgSalePricePastYear
      )}.`;
    }

    //AREA BULLET 2 INVENTORY
    if (mostInventoryMonths === "lowInventory") {
      // IF: Of inventory was low a minimum of 6 out of 12 months, then use this sentence:
      areaPoints[1].aPoint2.text = `${shortenToYourNeighborhood(
        mlsNeighborhood,
        false,
        true
      )} experienced a real shortage of available homes for sale in 2020, with ${lowInventoryMonths} out of 12 months measuring LOW INVENTORY. A shortage of supply typically puts upward pressure on home prices — and favors sellers.`;
      areaPoints[1].aPoint2.title = "Home Shortage";
    } else if (mostInventoryMonths === "balancedInventory") {
      areaPoints[1].aPoint2.text = `In 2020, the available number of homes for sale in ${shortenToYourNeighborhood(
        mlsNeighborhood
      )}  was BALANCED ${balancedInventoryMonths} out of 12 months — meaning there wasn't an oversupply or shortage. A balanced number of homes for sale provides buyers with a good assortment of homes to choose from, but typically doesn't give buyers or sellers a significant advantage.`;
      areaPoints[1].aPoint2.title = "Housing Supply";
    } else {
      //if high inventory
      areaPoints[1].aPoint2.text = `In 2020, ${shortenToYourNeighborhood(
        mlsNeighborhood
      )} experienced an oversupply of homes for sale, with ${highInventoryMonths} out of 12 months measuring HIGH INVENTORY. An oversupply of homes for sale typically puts downward pressure on home prices — and tends to favor buyers.`;
      areaPoints[1].aPoint2.title = "Housing Supply";
    }

    // BUYER ACTIVITY (Bullet 3)
    // IF: Average Sale Price increases, then use this:

    if (dataObj.salePriceDiff >= 1) {
      areaPoints[2].aPoint3.text = `With all-time high sale prices and homes selling faster than ever, ${
        isMemberOfTeam ? "we" : "I"
      } anticipate 2021 to start strong in favor of homeowners.`;
    } else {
      areaPoints[2].aPoint3.text = `Even though the average sale price ${
        dataObj.salePriceDiff >= 0 ? "stayed the same" : "slipped"
      } in 2020, ${
        isMemberOfTeam ? "we" : "I"
      } still anticipate the market to continue to favor home sellers with strong buyer demand and a shortage of available homes for sale.`;
    }

    return areaPoints;
  };
  render() {
    const { getHighlightsCity, getHighlightsArea } = this;
    const { mlsCityName, mlsNeighborhood } = this.props.currentArea;
    const { formatYENeighborhoodTitle, slashSeparatedArea } = propertyUtil;
    let highlightsCity = getHighlightsCity();
    let mappedCityPoints = highlightsCity.map((point, i) => {
      return (
        <div className="points-section" key={point[`cPoint${i + 1}`].title}>
          <div className="point-title">{point[`cPoint${i + 1}`].title}</div>
          <div className="point-text">{point[`cPoint${i + 1}`].text}</div>
        </div>
      );
    });
    let highlightsArea = getHighlightsArea();
    let mappedAreaPoints = highlightsArea.map((point, i) => {
      return (
        <div className="points-section" key={point[`aPoint${i + 1}`].title}>
          <div className="point-title">{point[`aPoint${i + 1}`].title}</div>
          <div className="point-text">{point[`aPoint${i + 1}`].text}</div>
        </div>
      );
    });
    return (
      <div className="year-end-highlights">
        <div className="year-end-area-highlights">
          <div className="year-end-area-title-container">
            <div className="year-end-area-title">
              <div className={formatYENeighborhoodTitle(mlsCityName)}>
                {slashSeparatedArea(mlsCityName, true)}
              </div>
            </div>
            <div className="year-end-compared">2020 Compared to 2019</div>
          </div>
          {mappedCityPoints}
        </div>
        <div className="year-end-area-highlights">
          <div className="year-end-area-title-container">
            <div className="year-end-area-title">
              {mlsNeighborhood.length >= 20 ? (
                <div className="xl-ye-highlight-area-text">
                  YOUR NEIGHBORHOOD
                </div>
              ) : (
                <div className="xl-ye-highlight-area-text">
                  {slashSeparatedArea(mlsNeighborhood, true)}
                </div>
              )}
            </div>
            <div className="year-end-compared">2020 Compared to 2019</div>
          </div>
          {mappedAreaPoints}
        </div>
      </div>
    );
  }
}

export default YearEndHighlights;
