import React, { Component } from "react";
import TableQuadrantsSecondLeft from "./TableQuadrantsSecondLeft";
import TableQuadrantsSecondRight from "./TableQuadrantsSecondRight";
import YETwoFirstLeft from "views/Pma/PmaEditor/YearEnd/YETwo/YETwoFirstPage/YETwoFirstLeft";

class TableQuadrantsPageTwo extends Component {
  render() {
    const {
      auth,
      agentData,
      hoData,
      currentArea,
      printVersion,
      version,
      mlsAreas,
      flipBook,
      quarterlyAreaTable,
      quarterlyCityTable,
      propertyPhotoDetailsDTO,
      MQCityDetails,
    } = this.props;

    return (
      <div
        className={
          "pma-page-container " +
          (printVersion
            ? "table-quadrants-print-page-container " +
              (flipBook ? "flipbook" : "regular-print-page")
            : "table-quadrants-pma-editor-page-container")
        }
      >
        <TableQuadrantsSecondLeft
          currentArea={currentArea}
          hoData={hoData}
          agentData={agentData}
          printVersion={printVersion}
          quarterlyAreaTable={quarterlyAreaTable}
          quarterlyCityTable={quarterlyCityTable}
          MQCityDetails={MQCityDetails}
        />
        <TableQuadrantsSecondRight
          currentArea={currentArea}
          auth={auth}
          agentData={agentData}
          printVersion={printVersion}
          version={version}
          mlsAreas={mlsAreas}
          hoData={hoData}
          quarterlyAreaTable={quarterlyAreaTable}
        />
      </div>
    );
  }
}

export default TableQuadrantsPageTwo;
