import React, { useState, useRef, Fragment } from 'react'
import { Editor, EditorState, ContentState, RichUtils, convertToRaw, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormatBold from '@material-ui/icons/FormatBold';
import FormatItalic from '@material-ui/icons/FormatItalic';
import FormatTitle from '@material-ui/icons/FormatSize';
import FormatUnderlined from '@material-ui/icons/FormatUnderlined';
import FormatColor from '@material-ui/icons/Palette';
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Paper } from 'components';
import { useEffect } from 'react';
const HANDLED = 'handled', NOT_HANDLED = 'not-handled';

const NavButton = ({ children, readOnly = false, onClick }) => {
    return <IconButton disabled={readOnly} onClick={onClick}
        onMouseDown={
            e => {
                e.stopPropagation();
                e.preventDefault();
            }
        }
    >{children}</IconButton>
};

const customStyleMap = {
    'RED': {
        color: '#FF4136'
    },
    'ORANGE': {
        color: '#FF851B'
    },
    'YELLOW': {
        color: '#FFDC00'
    },
    'GREEN': {
        color: '#2ECC40'
    },
    'BLUE': {
        color: '#0074D9'
    },
    'PURPLE': {
        color: '#A052FF'
    },
    'BLACK': {
        color: '#12161B'
    },
    'GRAY': {
        color: '#ccc'
    },
    'WHITE': {
        color: '#f2f2f2'
    },
    'TITLE': {
        fontSize: '1.2em'
    },
    'BOLD': {
        fontWeight: 'bold',
    },
    'CODE': {
        fontStyle: 'normal',
        fontSize: '1.2em'
    },
    'STRIKETHROUGH': {
        textDecoration: 'line-through',
    },
};

let options = {
    inlineStyles: {
        // Override default element (`strong`).
        BOLD: { element: 'b' },//style: { fontWeight: 'bold' }
        // ITALIC: {
        //     style: {  }//fontSize: 12
        // },
        // Use a custom inline style. Default element is `span`.
        RED: { attributes: { class: 'RichEditor-RED' }, style: { color: '#900' } },
        ORANGE: { attributes: { class: 'RichEditor-ORANGE' }, style: { color: '#FF851B' } },
        YELLOW: { attributes: { class: 'RichEditor-YELLOW' }, style: { color: '#FFDC00' } },
        GREEN: { attributes: { class: 'RichEditor-GREEN' }, style: { color: '#2ECC40' } },
        BLUE: { attributes: { class: 'RichEditor-BLUE' }, style: { color: '#0074D9' } },
        PURPLE: { attributes: { class: 'RichEditor-PURPLE' }, style: { color: '#A052FF' } },
        BLACK: { attributes: { class: 'RichEditor-BLACK' }, style: { color: '#12161B' } },
        GRAY: { attributes: { class: 'RichEditor-GRAY' }, style: { color: '#ccc' } },
        WHITE: { attributes: { class: 'RichEditor-WHITE' }, style: { color: '#f2f2f2' } },
        TITLE: { attributes: { class: 'RichEditor-TITLE' }, style: { fontSize: '1.2em' } },
    }
};

const useStyles = makeStyles({
    RTEContainer: {
        border: '1px solid #f2f2f2',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        borderTopRightRadius: '0px',
        borderTopLeftRadius: '0px',
        padding: '1em',
        cursor: 'text',
        background: '#ecfbfb',
        minHeight: '50px'
    },
    RTEContainerFocus: {
        // borderBottomColor: '#012159',
        // borderWidth: '2px',
        // outline: 'none'
    },
    RichEditorContent: {
        fontSize: '5em'
    },
    readOnlyBg: {
        background: 'rgba(0, 0, 0, 0.09)',
        pointerEvents: 'none',
    },
    ToolbarButton: {
        border: '1px solid #f2f2f2',
        padding: '1em',
        background: 'white',
        border: 0,
        outline: 0,
        cursor: 'pointer',
        border: '1px solid #f2f2f2',
    },
    ColorsContainer: {
        background: 'white',
        fontSize: '14px',
        borderRadius: '3px',
        boxShadow: '0px 4px 8px 0px rgba(33,33,33,.3)',
        WebkitBoxShadow: '0px 4px 8px 0px rgba(33,33,33,.3)',
        MozBoxShadow: '0px 4px 8px 0px rgba(33,33,33,.3)',
        zIndex: 1400
    },
    Palette: {
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: '150px',
        marginRight: '1em'
    },
    NavBar: {
        display: 'flex',
        flexWrap: 'wrap',
        border: '1px solid #f2f2f2',
        borderBottom: 'none',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        borderBottomRightRadius: '0px',
        borderBottomLeftRadius: '0px',
    },
    NavDivider: {
        width: '1px',
        background: '#D3D3D3',
        minHeight: '1em',
        margin: '.5em .5em',
        display: 'inline-block',

    },
    navbarSectionLeft: {
        width: '35%',
        display: 'flex',
        alignItems: 'center',
        minHeight: '42px'
    },
    navbarSectionMid: {
        width: '65%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    navbarSectionMidText: {
        //display: 'flex',
        //justifyContent: 'flex-end',
        fontSize: '12px',
        textAlign: 'center',
        marginRight: '8px'
    },
    extraGreetingText: {
        color: '#004fac',
        fontWeight: 500,
        //width: '100%',
        textAlign: 'left'
    },
    extraGreetingTextMarginTop: {
        marginTop: '8px'
    },
    extraGreetingTextMarginBottom: {
        marginBottom: '8px'
    },
    characterCounter: {
        width: '100%',
        textAlign: 'center',
    },
    characterCounterText: {
        fontSize: '14px',
        marginRight: '16px'
    },
    characterCounterError: {
        color: red[900]
    },
    extraInformation: {
        marginTop: '4px',
        marginBottom: '4px'
    },
    inlineButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
});
//color drop settings
const styles = {
    ColorDrop: {
        display: 'inline-block',
        padding: '1em',
        margin: '1em',
        marginRight: 0,
        height: '.5em',
        width: '.5em',
        borderRadius: '3px',
        cursor: 'pointer',
        background: (props) => {
            let color = props.color.toUpperCase();
            if (customStyleMap[color]) {
                return `${customStyleMap[color].color};`
            } else {
                return `none;`
            }
        }
    }
};
const ColorDropRaw = (props) => {
    const { classes, color, ...other } = props;
    return <div className={classes.ColorDrop} {...other} />;
};
const ColorDrop = withStyles(styles)(ColorDropRaw);


const RichTextEditorV2 = (props) => {
    const rte = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    /**props */
    const { minLength, maxLength, readOnly, preText, postText, type, category, placeholder, showPlaceholder, showInlineButton, showToolbar, returnTextMode, rteResetEditor } = props;
    const classes = useStyles();
    //console.log('props.children===', props.children)
    //pre state methods
    const getEditorState = (preData) => {
        let content = stateFromHTML(preData);
        return EditorState.createWithContent(content);
    };
    //sanitize predata
    let { preData } = props;
    preData = preData ? preData : '';
    /**content state */
    //let content = stateFromHTML(preData);
    // const blocksFromHTML = convertFromHTML(preData);
    // const content = ContentState.createFromBlockArray(
    //     blocksFromHTML.contentBlocks,
    //     blocksFromHTML.entityMap
    // );

    const [editorState, setEditorState] = useState(getEditorState(preData));
    const [hasFocus, setFocus] = useState(false);
    //const [readOnly, handleReadOnlyMode] = useState(openInDisableState);
    /**Editor content related methods */
    /**build plain text according to pre data */
    const [editorPlainText, setEditorPlainText] = React.useState(preData ? editorState.getCurrentContent().getPlainText() : '');//'\u0001'

    //build an reset hooks. This requires when we reset the global note or personal note
    useEffect(() => {
        if (rteResetEditor) {
            //get the latest editor state becuase use state editorState wont be updatedso quickly so plain text below one would be old one
            let editorState = getEditorState(preData);
            setEditorState(editorState);
            let plainText = preData ? editorState.getCurrentContent().getPlainText() : '';
            setEditorPlainText(plainText);
            props.updatePlainNoteFn(plainText);
            props.disableRTEResetMode(false);
        }
    }, [rteResetEditor]);

    //Update ps method of parent. we gonna call this to update the plain text in parent global/personal ps editor. We need it for first tiem only
    useEffect(() => {
        //console.log('should update updatePlainNoteFn')
        props.updatePlainNoteFn(editorPlainText);
    }, []);


    const onChange = editorState => {
        setEditorState(editorState);
        // console.log(editorState.getCurrentContent())
        //console.log(stateToHTML(editorState.getCurrentContent(), options));
        //console.log(convertToRaw(editorState.getCurrentContent())); to store on server and then use convertFromRaw
        //console.log(stateToHTML(editorState.getCurrentContent(), options));
        const plainTextContent = (editorState.getCurrentContent().getPlainText()).replace(/(\r\n|\n|\r)/gm, " ");//'\u0001'
        setEditorPlainText(plainTextContent);
        // console.log(editorState.getCurrentContent())
        //console.log(rte.current.editor.innerHTML);
        if (returnTextMode === 'html') {
            props.onChange(stateToHTML(editorState.getCurrentContent(), options), plainTextContent)
        } else {
            props.onChange(plainTextContent, plainTextContent)
        }
    };

    const handleKeyCommand = command => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onChange(newState)
            return HANDLED;
        }
        return NOT_HANDLED;
    };

    const setStyle = style => {
        onChange(RichUtils.toggleInlineStyle(editorState, style))
    };

    const onUnderlineClick = () => onChange(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
    const onBoldClick = () => onChange(RichUtils.toggleInlineStyle(editorState, 'BOLD'))
    const onItalicClick = () => setStyle('ITALIC');
    const onColorClick = color => {
        setStyle(color.toUpperCase());
        setAnchorEl(null);
    };
    const onTitleClick = () => setStyle('CODE');//TITLE

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const getTypeText = (type) => {
        let text = '';
        switch (type) {
            case "globalNote":
                text = 'GLOBAL NOTE'
                break;

            case "globalPS":
                text = 'GLOBAL P.S.'
                break;

            case "personalNote":
                text = 'PERSONAL NOTE'
                break;

            case "personalPS":
                text = 'PERSONAL P.S.'
                break;

            case "comment":
                text = 'COMMENTS'
                break;

            default:
                text = ''
                break;
        }
        return text;
    };

    const getCategoryText = (category) => {
        let text = '';
        switch (category) {
            case "note":
                text = 'note';
                break;

            case "ps":
                text = 'p.s';
                break;

            case "comment":
                text = 'comment';
                break;

            default:
                text = '';
                break;
        }
        return text;
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <Fragment>
            <div className={classes.NavBar}>
                {/* <NavButton readOnly={readOnly} onClick={onTitleClick}><FormatTitle /></NavButton>
                <div className={classes.NavDivider} /> */}
                {showToolbar ?
                    <div className={classes.navbarSectionLeft}>
                        <NavButton readOnly={readOnly} onClick={onBoldClick}><FormatBold /></NavButton>
                        <NavButton readOnly={readOnly} onClick={onItalicClick}><FormatItalic /></NavButton>
                        <NavButton readOnly={readOnly} onClick={onUnderlineClick}><FormatUnderlined /></NavButton>
                        <div className={classes.NavDivider} />
                    </div>
                    : <div className={classes.navbarSectionLeft}></div>}
                <div className={classes.navbarSectionMid}>
                    <Typography variant="subtitle2" align="center" color="primary" className={classes.characterCounterText}>
                        {getTypeText(type)} character count {editorPlainText && editorPlainText.length ? editorPlainText.length : '0'}/{maxLength}
                    </Typography>
                    <div className={classes.navbarSectionMidText}>
                        {minLength > 0 ? `**Min character count is ${minLength} to submit ${getCategoryText(category)}.` : ``}
                    </div>
                    {/* <Typography variant="caption" align="center" className={classes.characterCounterText}>
                            <p>Min character count is {minLength} to submit note.</p>
                            <p>Max character count is {maxLength} to submit note.</p>
                        </Typography> */}
                </div>
                {/* <div className={classes.NavDivider} />
                <NavButton aria-describedby={id} onClick={handleClick}><FormatColor /></NavButton>
                
                <Popper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    style={{ zIndex: 1400 }}
                >
                    <ClickAwayListener onClickAway={handleClose}>
                        <div className={classes.ColorsContainer}>
                            <div className={classes.Palette}>
                                {['red', 'orange', 'yellow', 'green', 'blue', 'purple', 'black', 'gray', 'white'].map(color => (<ColorDrop classes={classes} key={color} onMouseDown={e => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                }} onClick={e => {
                                    onColorClick(color)
                                }} color={color} />))}
                            </div>
                        </div>
                    </ClickAwayListener>
                </Popper> */}
            </div>
            <div className={classNames(classes.RTEContainer, (hasFocus ? classes.RTEContainerFocus : ''), (readOnly ? classes.readOnlyBg : ''))} onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                rte.current.editor.focus();
                setFocus(true)
            }}>
                {preText ? <div className={classNames(classes.extraGreetingText, classes.extraGreetingTextMarginBottom)}>{preText}</div> : ''}
                <Editor
                    ref={rte}
                    {...{ editorState, onChange, handleKeyCommand, customStyleMap }}
                    onBlur={event => setFocus(false)}
                    stripPastedStyles={true}
                    readOnly={readOnly}
                    placeholder={showPlaceholder ? placeholder : ''}//(preText === '' && postText === '') || 
                />
                {postText ? <div className={classNames(classes.extraGreetingText, classes.extraGreetingTextMarginTop)}>{postText}</div> : <div style={{ minHeight: '16px' }}></div>}
                {showInlineButton ? <div className={classes.inlineButton}>{props.children}</div> : ''}
            </div>
            {/* <div className={classes.characterCounter}>
                <Typography variant="caption" align="center" className={classes.characterCounterText}>
                    Character count {editorPlainText && editorPlainText.length ? editorPlainText.length : '0'}/{maxLength}
                </Typography>
            </div>
            <div className={classNames(classes.extraInformation, classes.characterCounter)}>
                <Typography variant="caption" align="center" className={classes.characterCounterText}>
                    Min character count is {minLength} to submit note.Max character count is {maxLength} to submit note.
        </Typography>
            </div> */}
            {(editorPlainText && editorPlainText.length && editorPlainText.length > parseInt(maxLength)) ?
                <div className={classes.characterCounter}>
                    <Typography variant="subtitle2" align="center" className={classes.characterCounterError}>
                        You have entered {editorPlainText.length} characters. Max allowed character count is {maxLength}.
                    </Typography>
                </div>
                : ''}
        </Fragment>
    );
}

RichTextEditorV2.propTypes = {
    onChange: PropTypes.func,
    updatePlainNoteFn: PropTypes.func,
    showPlaceholder: PropTypes.bool,
    showInlineButton: PropTypes.bool,
    showToolbar: PropTypes.bool,
    type: PropTypes.oneOf(["globalNote", "globalPS", "personalNote", "personalPS", "comments"]),
    category: PropTypes.oneOf(["note", "ps", "comment"]),
    placeholder: PropTypes.string,
    returnTextMode: PropTypes.oneOf(["plain", "html"]),
    rteResetEditor: PropTypes.bool,
    disableRTEResetMode: PropTypes.func,
};

RichTextEditorV2.defaultProps = {
    onChange: () => { },
    updatePlainNoteFn: () => { },
    minLength: 200,
    maxLength: 1050,
    preText: '',
    postText: '',
    placeholder: '',
    preData: '',
    showPlaceholder: false,
    showInlineButton: false,
    showToolbar: true,
    type: "globalNote",
    category: "note",
    returnTextMode: "html",
    rteResetEditor: false,
    disableRTEResetMode: () => { }
};
export default RichTextEditorV2;