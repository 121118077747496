import React, { Component } from "react";
import FirstLeftHeader from "views/Pma/PmaEditor/CommonEditor/FirstLeftHeader";
import HOAddress from "views/Pma/PmaEditor/CommonEditor/HOAddress";
import QVFourLogoAndAvatar from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourLogoAndAvatar";
import YETwoPropertyHighlights from "views/Pma/PmaEditor/YearEnd/YETwo/YETwoFirstPage/YETwoPropertyHighlights";
import AvailableSoon from "./AvailableSoon";
import { propertyUtil } from "views/Pma/PropertyUtil";
import { Link } from "react-router-dom";

class YETwoFirstLeft extends Component {
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      currentArea,
      mlsAreas,
      quarterlyAreaTable,
      quarterlyCityTable,
      navbarData,
      coverPageDetails,
      yearEnd,
      salePriceForCities,
      aprilData,
      demoAccountHOName,
      demoAccountAreaName,
      testimonials,
      handlePropertyHighlightDialog,
      propertyPhotoDetailsDTO,
    } = this.props;
    const { mlsAreaID } = currentArea;
    console.log("  quarterlyAreaTable,", quarterlyAreaTable);
    const { listgenUserID } = agentData;
    let currentSoldListings;

    let propertyHighlightsTitle;
    let propertyHighlightsSubtitle;
    let homesSoldPreviousYear;
    let homesSoldCurrentYear;
    let totalSalePricePreviousYear;
    let totalSalePriceCurrentYear;
    let photoDetails;

    const { isMemberOfTeam } = agentData;
    const { roundSalePrice } = propertyUtil;
    let selected;
    let sortSelected;
    let isDemo;
    if (mlsAreaID === "DEMO-SCL-16B") {
      sortSelected = quarterlyAreaTable.currentSoldListings.slice(0, 6);
      isDemo = true;
    }
    if (
      propertyPhotoDetailsDTO &&
      propertyPhotoDetailsDTO.photoDetails &&
      propertyPhotoDetailsDTO.photoDetails.length >= 1
    ) {
      propertyHighlightsTitle = propertyPhotoDetailsDTO.propertyHighlightsTitle;
      propertyHighlightsSubtitle =
        propertyPhotoDetailsDTO.propertyHighlightsSubtitle;
      homesSoldPreviousYear = propertyPhotoDetailsDTO.homesSoldPreviousYear;
      homesSoldCurrentYear = propertyPhotoDetailsDTO.homesSoldCurrentYear;
      totalSalePricePreviousYear =
        propertyPhotoDetailsDTO.totalSalePricePreviousYear;
      totalSalePriceCurrentYear =
        propertyPhotoDetailsDTO.totalSalePriceCurrentYear;
      photoDetails = propertyPhotoDetailsDTO.photoDetails;

      selected = photoDetails.filter((prop) => prop.isSelected === true);
      if (selected.length === 0) {
        selected = photoDetails.slice(0, 5);
      }
      sortSelected = selected.sort((a, b) => a.displayOrder - b.displayOrder);

      currentSoldListings = propertyPhotoDetailsDTO;
    } else {
      photoDetails = [{ displayOrder: "1" }];
    }

    let propSoldTotal = +totalSalePriceCurrentYear;
    let constructedTitle = `A few homes ${
      isMemberOfTeam ? "We" : "I"
    } represented for Sellers and Buyers`;
    // `${
    //   isMemberOfTeam ? "We" : "I"} `
    // } listed and sold ${+homesSoldCurrentYear} ${
    //   +homesSoldCurrentYear === 1 ? "home" : "homes"
    // }, totaling ${roundSalePrice(propSoldTotal, false, "writtenOut")} in 2021`;
    let constructedSubtitle =
      "A Record Year of High Home Prices, Fast Transactions, and Extraordinary Buyer Demand";
    // +"The Balance of This Year is Showing Signs of Slowing, But Still Looks Strong For Sellers — Let’s Connect!";
    let hasTitle =
      propertyHighlightsTitle && propertyHighlightsTitle.length > 1
        ? true
        : false;
    let hasSubtitle =
      propertyHighlightsSubtitle && propertyHighlightsSubtitle.length > 1
        ? true
        : false;
    console.log("hasSubtitle --------------", hasSubtitle);
    console.log(
      "sortSelected && sortSelected.length===",
      propertyPhotoDetailsDTO
    );
    return (
      <div className="ye2-first-left">
        <FirstLeftHeader
          agentData={agentData}
          printVersion={printVersion}
          currentArea={currentArea}
        />
        <QVFourLogoAndAvatar
          agentData={agentData}
          printVersion={printVersion}
        />
        {hoData &&
          hoData.changeDetails &&
          Object.keys(hoData.changeDetails).length > 1 && (
            <HOAddress
              hoData={hoData}
              version={version}
              agentData={agentData}
              currentArea={currentArea}
              printVersion={printVersion}
              mailInfoClass="mail-info-container-print"
              demoAccountHOName={demoAccountHOName}
              coverPageDetails={coverPageDetails}
            />
          )}
        {sortSelected && sortSelected.length >= 1 ? (
          <YETwoPropertyHighlights
            currentSoldListings={sortSelected}
            handlePropertyHighlightDialog={handlePropertyHighlightDialog}
            agentData={agentData}
            printVersion={printVersion}
            currentArea={currentArea}
            title={hasTitle ? propertyHighlightsTitle : constructedTitle}
            subtitle={
              hasSubtitle ? propertyHighlightsSubtitle : constructedSubtitle
            }
            isDemo={isDemo}
          />
        ) : (
          <AvailableSoon />
        )}
      </div>
    );
  }
}

export default YETwoFirstLeft;
