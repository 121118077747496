import React, { Component } from "react";
import BubbleCompareFirstPage from "./BubbleCompareFirstPage";
import BubbleCompareSecondPage from "./BubbleCompareSecondPage";

class BubbleCompare extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./bubble-compare-print.scss");
    } else {
      require("./bubble-compare-editor.scss");
    }
  }
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,

      currentArea,
      MQCityDetails,
      mlsAreas,
      coverPageDetails,
      testimonials,
      demoAccountAreaName,
      demoAccountCity,
      qArea,
      qCity,
      quarterlyAreaTable,
      quarterlyCityTable,
      bubbleBoxes,
      isDemo,
      propertyPhotoDetailsDTO,
      handlePropertyHighlightDialog,
      missionStatement,
      quadrantModalOpen,
      quadrantDetails,
    } = this.props;

    return (
      <div
        className={
          printVersion ? "bubble-compare-print" : "bubble-compare-editor"
        }
      >
        <div
          className={
            printVersion
              ? "bubble-compare-print-page"
              : "bubble-compare-editor-page"
          }
        >
          <BubbleCompareFirstPage
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            MQCityDetails={MQCityDetails}
            printVersion={printVersion}
            version={version}
            mlsAreas={mlsAreas}
            coverPageDetails={coverPageDetails}
            testimonials={testimonials}
            demoAccountAreaName={demoAccountAreaName}
            quarterlyAreaTable={quarterlyAreaTable}
            quarterlyCityTable={quarterlyCityTable}
            bubbleBoxes={bubbleBoxes}
            isDemo={isDemo}
            missionStatement={missionStatement}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            quadrantModalOpen={quadrantModalOpen}
            quadrantDetails={quadrantDetails}
            handlePropertyHighlightDialog={handlePropertyHighlightDialog}
          />
          <div id="back-to-top-anchor-pma-editor-page-2" />
          <BubbleCompareSecondPage
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            MQCityDetails={MQCityDetails}
            printVersion={printVersion}
            version={version}
            qArea={qArea}
            qCity={qCity}
            demoAccountAreaName={demoAccountAreaName}
            demoAccountCity={demoAccountCity}
            quarterlyAreaTable={quarterlyAreaTable}
            quarterlyCityTable={quarterlyCityTable}
            isDemo={isDemo}
          />
        </div>
      </div>
    );
  }
}

export default BubbleCompare;
