import React, { Component } from "react";
import ChildrenImage from "./johnsons-teacher-fund.png";
import TeacherFundIcon from "./teacher-fund-apple.png";
import JohnsonsTempQR from "./johnsons-temp-qr.png";

class JohnsonsEducationFund extends Component {
  render() {
    const { mlsAreaID } = this.props.currentArea;
    return (
      <div className="johnsons-education-fund">
        <div className="johnsons-education-body">
          <div className="johnsons-education-top-row">
            <div className="johnsons-education-top-row-first-col">
              <img
                src="https://pma-coverphotos.s3.amazonaws.com/teacher-fund-2023.png"
                className="teacher-education-apple-icon"
              />
              <div className="teacher-education-qr-container">
                <img
                  src="https://pma-coverphotos.s3.amazonaws.com/teacher-fund-qr-code.png"
                  className="teacher-education-qr-code"
                />
                <div className="johnsons-education-fund-subtitle-1">
                  <p>Take a look to see what</p>
                  {mlsAreaID === "SBA-15J" ? (
                    <p>Hope School teachers need!</p>
                  ) : mlsAreaID === "SBA-35F" || mlsAreaID === "SBA-35H" ? (
                    <p>Mountain View teachers need!</p>
                  ) : mlsAreaID === "SBA-5F" ? (
                    <p>Carpinteria teachers need!</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="johnsons-education-top-row-second-col">
              <div className="johnsons-education-top-row-title">
                <p>We believe in education</p>
              </div>
              <div className="johnsons-education-top-row-body">
                <p>
                  Brianna is excited to once again be chairing our 5th annual
                  Teacher’s Fund Supplies Drive to help our local teachers get
                  the supplies they need to help our students thrive! In our 20
                  years at the Teachers Fund we've raised over $2 million for
                  our local schools grades K-12 — granting over $135,000 in the
                  '22-'23 school year alone!
                </p>
                <p>
                  Please join us as we raise funds this year for our community
                  teachers! Browse the curated list of Teacher's needs at{" "}
                  <span className="supplies-link">TFSuppliesDrive.com</span> and
                  please consider granting a teacher's wish!
                </p>
              </div>
            </div>
          </div>
          <div className="johnsons-education-bottom-row">
            <img
              src="https://pma-coverphotos.s3.amazonaws.com/teacher-fund-school-supplies.jpg"
              alt="Teacher Fund"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default JohnsonsEducationFund;
