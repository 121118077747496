import { CommonAxiosAPIHandler } from "common/helpers";

const UPDATE_TAGLINE_MODEL = "/admin/updateTagLines";


export const UpdateTaglineService = (data) => {
  return CommonAxiosAPIHandler("POST", [
    `${UPDATE_TAGLINE_MODEL}`,
    data
  ]);
};