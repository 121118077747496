import React, { Component, useEffect } from 'react';
import classNames from 'classnames';
import { Portlet, PortletContent } from 'components';
import { Grid, Typography, CircularProgress, Divider } from '@material-ui/core';
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import { ResetAllLocalStorages, ResetDataExceptSessionSwitchLocalStorages } from 'common/storage';
import { Store } from 'Store';
import { DO_LOGOUT_DATA_RESET } from 'store/types';
import querystring from 'querystring';
import { handleLogoutFn } from 'common/helpers';

const LogoutScreen = (props) => {
    const { state, dispatch } = React.useContext(Store);
    const { classes, className } = props;
    const rootClassName = classNames(className);

    useEffect(() => {
        // ResetAllLocalStorages();
        // //Reset except session switch data also
        // ResetDataExceptSessionSwitchLocalStorages();
        // const { history } = props;
        // dispatch({
        //     type: DO_LOGOUT_DATA_RESET
        // });
        /**check for mode now */
        setTimeout(() => {
            try {
                let parsedParams = querystring.parse(
                    props.location.search.substring(1)
                );
                let mode = parsedParams.mode;
                if (mode) {
                    handleLogoutFn(mode)
                } else {
                    handleLogoutFn();
                }
            } catch (err) {
                handleLogoutFn();
            }
        }, 500);
    });



    return (
        <div className={rootClassName}>
            <Grid className={classes.grid} container>
                <Grid item lg={3} sm={12} xs={12} md={3}></Grid>
                <Grid item lg={6} sm={12} xs={12} md={6}>
                    <Portlet>
                        <PortletContent className={classes.displayFlex}>
                            <Grid className={classes.grid} container>
                                <Grid item lg={12} sm={12} xs={12} md={12}>
                                    <CircularProgress />
                                </Grid>
                                <Grid item lg={12} sm={12} xs={12} md={12}>
                                    <Typography variant="subtitle2" className={classes.displayFlexText}>
                                        Logging Out...
                                    </Typography>
                                </Grid>
                            </Grid>
                        </PortletContent>
                    </Portlet>
                </Grid>
                <Grid item lg={3} sm={12} xs={12} md={3}></Grid>
            </Grid>
        </div>
    );
};
export default compose(
    withStyles(styles)
)(LogoutScreen);

