export default theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  contentBody: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  field: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1.5),
  },
  textField: {
    width: '100%',
    maxWidth: '100%',
    marginRight: theme.spacing(3),
  },
  portletFooter: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  fieldError: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  footerButtons: {
    position: 'relative',
    display: 'flex',
  },
  footerButtonsCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(2)
  },
  footerButtonsLeftRight: {
    //flex: 1
  },
});
