import React, { Component } from 'react';
import classNames from 'classnames';
import {
    Portlet,
    PortletContent,
} from 'components';
import { Grid, Typography, Divider, Button } from '@material-ui/core';
import compose from 'recompose/compose';
import _ from 'lodash';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { WebsiteLayout } from 'layouts';
import DocumentTitle from 'react-document-title';
import ScheduleBodyV2 from './ScheduleBodyV2';

class ScheduleScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    openPrintSchedulePage = () => {
        window.open('/printschedule', '_blank');
    };

    render() {
        const { classes, className } = this.props;
        const rootClassName = classNames(className);
        const { scheduleData } = this.state;

        return (
            <DocumentTitle title="Mailing Schedule">
                <WebsiteLayout>
                    <div className={classes.root}>
                        <Grid container className={classes.grid} spacing={0}>
                            {/* <Grid item lg={1}></Grid> */}
                            <Grid item lg={12}>
                                <Portlet className={rootClassName}>
                                    <PortletContent className={classes.portletContent}>
                                        {/* <div className={classes.downloadSection}>
                                        <Button
                                            color="primary"
                                            size="small"
                                            variant="contained"
                                            onClick={event => this.openPrintSchedulePage()}
                                        >
                                            Download
                        </Button>
                                    </div> */}
                                        <ScheduleBodyV2
                                            printMode={false} />

                                    </PortletContent>
                                </Portlet>
                            </Grid>
                            {/* <Grid item lg={1}></Grid> */}
                        </Grid>
                    </div>
                </WebsiteLayout>
            </DocumentTitle>
        );
    }
}

export default compose(withStyles(styles))(ScheduleScreen);
