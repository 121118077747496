import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import moment from "moment";
import MQThreeMarketSaleRow from "./MQThreeMarketSaleRow";
import MarketTableBG from "./green-table.png";
import UpdatedMarketTableBG from "./green-table.png";

class MQThreeMarketSalesTable extends Component {
  getTitle = (area) => {
    const {
      changeDetails,
      subdivisionName,
      coverPageHeader,
    } = this.props.hoData;

    const { listgenUserID } = this.props.agentData;
    const { mlsAreaID } = this.props.currentArea;
    const { demoAccountAreaName } = this.props;
    const { splitAreas } = propertyUtil;
    let areaSplit = splitAreas(area);

    let characterCount = areaSplit.join("").length + areaSplit.length;

    let specialFontSize;
    if (characterCount > 40) {
      specialFontSize = "xs-market-sales-title";
    } else if (characterCount > 33) {
      specialFontSize = "small-market-sales-title";
    } else if (characterCount > 27) {
      specialFontSize = "medium-market-sales-title";
    } else {
      specialFontSize = "regular-market-sales-title";
    }
    if (mlsAreaID === "SCL-16J") {
      return (
        <div className="market-sales-title-text">HERE'S WHAT'S HAPPENING</div>
      );
    } else if (coverPageHeader === "Upper East and Lower Mission Canyon") {
      return (
        <div className="market-sales-title-text small-market-sales-title">
          Lower Mission Canyon and Upper East
        </div>
      );
    } else if (area === "Winchester Canyon") {
      return (
        <div className="market-sales-title-text">
          Rancho Embarcadero & Winchester
        </div>
      );
    } else if (demoAccountAreaName && demoAccountAreaName.length > 1) {
      return (
        <div className="market-sales-title-text">{demoAccountAreaName}</div>
      );
    } else if (
      (changeDetails && changeDetails.subdivisionName) ||
      (subdivisionName && subdivisionName.length > 1)
    ) {
      return (
        <div className="market-sales-title-text">
          {subdivisionName ? subdivisionName : changeDetails.subdivisionName}
        </div>
      );
    } else {
      return (
        <div className={`market-sales-non-sub-container ${specialFontSize}`}>
          {areaSplit.map((area, i) => {
            return (
              <div key={area} className="market-sales-inside-title-row">
                {i > 0 && (
                  <div
                    className={`market-sales-title-separator ${specialFontSize}`}
                  ></div>
                )}

                <p className={`market-sales-title-text ${specialFontSize}`}>
                  {area}
                </p>
              </div>
            );
          })}
        </div>
      );
    }
  };
  sortByStatus = (propList) => {
    // let sliced = propList
    //   .slice(0, 19)
    //   .sort((a, b) => new Date(b.saleDate) - new Date(a.saleDate));
    // let sold = sliced.filter(x => x.status === "Sold");
    // let pend = sliced.filter(x => x.status === "Pending");
    // let cont = sliced.filter(x => x.status === "Contingent");
    // let active = sliced.filter(x => x.status === "Active");
    // let trueSort = [...sold, ...pend, ...cont, ...active];
    // return trueSort;
    return propList.slice(0, 19);
  };
  getOldestDate = (propList) => {
    let sliced = propList
      .slice(0, 18)
      .sort((a, b) => new Date(a.saleDate) - new Date(b.saleDate))[0].saleDate;

    let formatted = moment(sliced).format("MMM D, YYYY");
    return formatted;
  };
  getTodayDate = () => {
    return moment(new Date()).format("MMM D, YYYY");
  };
  formatLastPrintDate = (date) => {
    return moment(date).format("MMM D, YYYY");
  };
  render() {
    const {
      currentArea,
      quarterlyAreaTable,
      printDate,
      printVersion,
      lastMidQuarterlyPrintDate,
      agentData,
    } = this.props;

    const {
      totalActiveListings,
      totalPendingListings,
      daysToSellSinceLastMidQtr,
      monthsSupplyTable,
    } = quarterlyAreaTable;

    const {
      getPropView,
      formatSuffix,
      formatBaths,
      toAcre,
      cutName,
      propTypeAcronym,
      splitAreas,
      countAreaChars,
      slashSeparatedArea,
    } = propertyUtil;
    //  console.log("mq3 market sales table", this.props);
    const { mlsAreaID, mlsNeighborhood } = currentArea;
    let isBuellton = mlsAreaID === "SBA-40F" || mlsAreaID === "SBA-40G";
    const { getTitle, getOldestDate } = this;
    const midQuarterSoldListingCount = quarterlyAreaTable.midQuarterSoldListingsCount
      ? quarterlyAreaTable.midQuarterSoldListingsCount
      : 0;

    let totalActives = isBuellton
      ? monthsSupplyTable.CityNumberOfHousesForSale
      : monthsSupplyTable.AreaNumberOfHousesForSale;

    const totalSolds = this.props.quarterlyAreaTable.soldListingsCount;
    let sorted =
      quarterlyAreaTable && quarterlyAreaTable.marketActivityTable
        ? this.sortByStatus(quarterlyAreaTable.marketActivityTable)
        : [];
    let preSoldTable =
      quarterlyAreaTable &&
      quarterlyAreaTable.preSoldsMarketActivityTable === null
        ? []
        : this.sortByStatus(quarterlyAreaTable.preSoldsMarketActivityTable);

    const soldNum =
      (quarterlyAreaTable.marketActivityTable &&
        quarterlyAreaTable.marketActivityTable.filter(
          (list) => list.status === "Sold"
        ).length) ||
      0;

    const activesNum =
      quarterlyAreaTable.marketActivityTable &&
      quarterlyAreaTable.marketActivityTable.filter(
        (list) => list.status === "Active"
      ).length;

    const contingentsNum =
      quarterlyAreaTable.marketActivityTable &&
      quarterlyAreaTable.marketActivityTable.filter(
        (list) => list.status === "Contingent"
      ).length;

    let hasOnlySglFam = true;

    let determineSglFamStatus =
      sorted &&
      sorted.map((x) => {
        if (x.propertyType !== "Single Family Home") {
          hasOnlySglFam = false;
        }
      });
    let preSoldsDetermineSglFamStatus =
      preSoldTable &&
      preSoldTable.map((x) => {
        if (x.propertyType !== "Single Family Home") {
          hasOnlySglFam = false;
        }
      });

    let soldOverflowMsg =
      quarterlyAreaTable.midQuarterSoldListingsCount &&
      midQuarterSoldListingCount > 19 ? (
        <div className="sold-overflow-tb">
          <div className="high-sold">
            <div className="sales-table-row">
              There were {midQuarterSoldListingCount} homes that{" "}
              <p className="sold-homes"> SOLD </p> in your neighborhood since{" "}
              {this.formatLastPrintDate(lastMidQuarterlyPrintDate)}, but due to
              space limitations only {soldNum === 19 ? 19 : soldNum} are shown
              in the above table.
            </div>
          </div>
        </div>
      ) : null;

    let activeOverflowMsg =
      totalActives > activesNum ? (
        <div className="active-overflow-tb">
          <div className="high-sold">
            <div className="sales-table-row">
              <p>
                There {totalActives === "1" ? "is" : "are"}{" "}
                <span className="active-bold">{totalActives}</span>{" "}
              </p>
              <p className="standout-active">
                active {totalActives === "1" ? "listing" : "listings"}
              </p>{" "}
              <p>
                in your neighborhood, but due to space limitations,{" "}
                {activesNum === 0
                  ? "none of these properties are shown"
                  : `only ${activesNum} ${
                      activesNum === 1 ? "is shown" : "are shown"
                    } `}{" "}
                in the above table.
              </p>
            </div>
          </div>
        </div>
      ) : null;

    let messageOverflowNum = 0;
    if (soldOverflowMsg) {
      messageOverflowNum++;
    }
    if (activeOverflowMsg) {
      messageOverflowNum++;
    }

    let currentSoldsSlice = 19 - messageOverflowNum;
    let leftover = 0;
    if (sorted.length < 18 && preSoldTable.length > 0) {
      leftover = 18 - messageOverflowNum - sorted.length;
    }
    const currentSolds = (
      <MQThreeMarketSaleRow
        solds={sorted.slice(0, 19)}
        mlsAreaID={mlsAreaID}
        isSingleTable={leftover == 0}
        agentData={agentData}
      />
    );

    const preSolds = (
      <MQThreeMarketSaleRow
        solds={preSoldTable.slice(0, leftover)}
        mlsAreaID={mlsAreaID}
        older={true}
        mlsAreaID={mlsAreaID}
        agentData={agentData}
      />
    );

    let hasOffMarketSale = [
      ...sorted.slice(0, 19),
      ...preSoldTable.slice(0, leftover),
    ].some((prop) => prop.isOffMarketSale === "1");

    const tableHeader = (
      <thead>
        <tr>
          <th>Status</th>
          <th>
            <p>Sale </p>
            <p>Date</p>
          </th>
          <th>
            <p>Property</p>
            <p>Type</p>
          </th>

          <th>Address</th>
          <th>List Price</th>
          <th>Sale Price</th>

          <th>
            <p>Days on</p>
            <p>Market</p>
          </th>

          <th>
            <p>Beds</p>
            <p>Baths</p>
          </th>
          <th>
            <p>Living</p>
            <p>SqFt</p>
          </th>
        </tr>
      </thead>
    );

    let isLosOlivos = mlsAreaID === "SBA-40G";
    //console.log("isBuellton", isBuellton);
    const hasTable = soldOverflowMsg || activeOverflowMsg;
    const pendingAndContingents =
      Number(totalPendingListings) + Number(contingentsNum);
    return (
      <div className="mq3-market-sales-table">
        <div className="market-sales-title-container">
          {getTitle(mlsNeighborhood)}
          {mlsAreaID === "SCL-16J" ? (
            <p className="market-sales-subtitle-text right-aligned-subtitle">
              IN YOUR NEIGHBORHOOD
            </p>
          ) : (
            <p className="market-sales-subtitle-text">
              HERE'S WHAT'S HAPPENING IN{" "}
              {isLosOlivos
                ? "LOS OLIVOS"
                : isBuellton
                ? "BUELLTON"
                : "YOUR NEIGHBORHOOD"}
            </p>
          )}
        </div>
        <div className="subtitle-row">
          <div className="left-subtitle-section">
            <div className="left-subtitle-text">
              <p className="small-left-subtitle">
                {sorted.length > 0
                  ? this.formatLastPrintDate(lastMidQuarterlyPrintDate)
                  : getOldestDate(
                      quarterlyAreaTable.preSoldsMarketActivityTable
                    )}{" "}
                — Aug 15, 2023
              </p>
            </div>
            <div className="right-subtitle-section">
              <p>
                {totalActiveListings} Active{" "}
                {+totalActiveListings === 1 ? "Listing" : "Listings"}{" "}
              </p>
              <div className="right-subtitle-divider">|</div>
              <p>
                {pendingAndContingents} Pending{" "}
                {+pendingAndContingents === 1 ? "Sale" : "Sales"}
              </p>
            </div>
          </div>
          <div className="golden-divider extra-long" />
        </div>
        <div className="market-table-containers">
          <img
            // src="https://pma-coverphotos.s3.amazonaws.com/072723_TableBG_green_NoShadow.png"
            src="https://pma-coverphotos.s3.amazonaws.com/green-table-aug-11-2023.png"
            alt="Market Table Cloud"
            className="market-table-bg-image"
          />
          <div className="both-market-tables">
            <table className="regular-sales-table">
              {/*{tableHeader}*/}
              {sorted.length >= 1 ? tableHeader : null}
              {currentSolds}
            </table>

            {hasTable && (
              <div className="overflow-message-table">
                {soldOverflowMsg}
                {activeOverflowMsg}
              </div>
            )}

            {leftover >= 1 && (
              <div className="subtitle-row">
                <div className="left-subtitle-section">
                  <div className="left-subtitle-text overflow-subtitle-text">
                    <p className="left-subtitle-text-title">Market Activity</p>
                    <div className="left-subtitle-divider"></div>
                    {sorted.length > 0 && (
                      <p className="small-left-subtitle">PREVIOUSLY SOLD</p>
                    )}
                  </div>
                </div>
                <div className="golden-divider" />
              </div>
            )}
            {leftover >= 1 && (
              <table className="regular-sales-table">
                {sorted.length === 0 && tableHeader}
                {preSolds}
              </table>
            )}
            {/*<div className="active-overflow-tb">
              <div className="high-sold">
                <div className="sales-table-row">
                  <p>
                    There {totalActives === "1" ? "is" : "are"} {totalActives}{" "}
                  </p>
                  <p className="standout-active">
                    active {totalActives === "1" ? "listing" : "listings"}
                  </p>{" "}
                  <p>
                    in your neighborhood, but due to space limitations,{" "}
                    {activesNum === 0
                      ? "none of these properties are shown"
                      : `only ${activesNum} ${
                          activesNum === 1 ? "is shown" : "are shown"
                        } `}{" "}
                    in the above table.
                  </p>
                </div>
              </div>
            </div>
            */}
            {hasOffMarketSale && (
              <div className="overflow-message-table">
                <div className="active-overflow-tb">
                  <div className="sales-table-row">
                    *This property was not listed on the Multiple Listing
                    Service (MLS) and was sold "off-market" privately.
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MQThreeMarketSalesTable;
