import React, { Component, Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Input,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from "@material-ui/core";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "./styles";
import { withSnackbar } from "notistack";
import classNames from "classnames";
import ClearIcon from "@material-ui/icons/Clear";
import { showSuccessNotification } from "common/helpers";
import { showErrorNotification } from "common/helpers";
import { AddUpdatePmaAdmin } from "services/agent";
import TestHome from "./test-home.jpg";
import { propertyUtil } from "views/Pma/PropertyUtil";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorModal = (props) => {
  const { handleErrorModal, errorModalText, maxPropertiesNum } = props;
  console.log("*error modal props", props);
  const { formatSuffix } = propertyUtil;

  const displayCloseText = () => {
    if (errorModalText === "Max properties") {
      return "Ok — Got it";
    } else {
      return "Close";
    }
  };
  const displayErrorText = () => {
    if (errorModalText === "Max properties") {
      return (
        <div className="error-modal-text">
          <p>You have reached the maximum of {maxPropertiesNum} properties.</p>
          <p> Please unselect a property before adding a new one.</p>
        </div>
      );
    } else if (errorModalText === "Min properties") {
      return (
        <div className="error-modal-text">
          <p>Please add another property</p>
          <p>Before removing the last one</p>
        </div>
      );
    } else if (errorModalText === "Max title") {
      return (
        <div className="error-modal-text">
          <p>You have reached the maximum character limit</p>
          <p>Please reduce character count and submit again.</p>
        </div>
      );
    } else if (errorModalText === "Missing inputs") {
      return (
        <div className="error-modal-text">
          <p>Please fill out all fields before submitting</p>
        </div>
      );
    } else if (errorModalText === "Missing properties") {
      return (
        <div className="error-modal-text">
          <p>Please select {maxPropertiesNum} properties to display</p>
        </div>
      );
    } else if (errorModalText === "Issue submitting form") {
      return (
        <div className="error-modal-text">
          <p>There was an issue submitting the form</p>
          <p>Please contact ListGen Support</p>
        </div>
      );
    }
  };
  return (
    <Fragment>
      <Dialog
        open={true}
        //onClose={onCloseFn}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
        TransitionComponent={Transition}
      >
        <div className="error-modal-container">
          {displayErrorText()}
          <div className="error-form-button-container">
            <div
              className="error-form-cancel-button"
              onClick={() => handleErrorModal("close")}
            >
              <p>{displayCloseText()}</p>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default compose(withSnackbar, withStyles(styles))(ErrorModal);
