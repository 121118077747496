import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import moment from "moment";
import MarketSaleRow from "./MarketSaleRow";

class MarketSalesTable extends Component {
  sortByStatus = (propList) => {
    let sliced = propList
      .slice(0, 19)
      .sort((a, b) => new Date(b.saleDate) - new Date(a.saleDate));
    let sold = sliced.filter((x) => x.status === "Sold");
    let pend = sliced.filter((x) => x.status === "Pending");
    let cont = sliced.filter((x) => x.status === "Contingent");
    let active = sliced.filter((x) => x.status === "Active");
    let trueSort = [...sold, ...pend, ...cont, ...active];
    return trueSort;
  };
  getTodayDate = () => {
    return moment(new Date()).format("MMM D, YYYY");
  };
  formatLastPrintDate = (date) => {
    return moment(date).format("MMM D");
  };
  render() {
    const { currentArea, qArea } = this.props;

    // console.log("sales table props", this.props);
    const {
      lastMidQuarterlyPrintDate,
      totalActiveListings,
      totalPendingListings,
    } = qArea;
    const {
      getPropView,
      formatSuffix,
      formatBaths,
      toAcre,
      cutName,
      propTypeAcronym,
      splitAreas,
      countAreaChars,
      slashSeparatedArea,
    } = propertyUtil;
    const { mlsAreaID, mlsNeighborhood } = currentArea;
    const midQuarterSoldListingCount = qArea.midQuarterSoldListingsCount
      ? qArea.midQuarterSoldListingsCount
      : 0;
    const totalActives = this.props.qArea.monthsSupplyTable
      .AreaNumberOfHousesForSale;
    const totalSolds = this.props.qArea.soldListingsCount;
    let sorted = qArea && this.sortByStatus(qArea.marketActivityTable);
    // console.log("sorted", sorted);

    let preSoldTable =
      qArea && qArea.preSoldsMarketActivityTable === null
        ? []
        : this.sortByStatus(qArea.preSoldsMarketActivityTable);
    const soldNum =
      (qArea.marketActivityTable &&
        qArea.marketActivityTable.filter((list) => list.status === "Sold")
          .length) ||
      0;

    const activesNum =
      qArea.marketActivityTable &&
      qArea.marketActivityTable.filter((list) => list.status === "Active")
        .length;
    let hasOnlySglFam = true;

    let determineSglFamStatus =
      sorted &&
      sorted.map((x) => {
        if (x.propertyType !== "Single Family Home") {
          hasOnlySglFam = false;
        }
      });
    let preSoldsDetermineSglFamStatus =
      preSoldTable &&
      preSoldTable.map((x) => {
        if (x.propertyType !== "Single Family Home") {
          hasOnlySglFam = false;
        }
      });

    let soldOverflowMsg =
      qArea.midQuarterSoldListingsCount && midQuarterSoldListingCount > 19 ? (
        <tbody>
          <tr className="high-sold">
            <td className="sales-table-row">
              There were {midQuarterSoldListingCount} homes that{" "}
              <p className="sold-homes"> SOLD </p> in your neighborhood since{" "}
              {this.formatLastPrintDate(lastMidQuarterlyPrintDate)}, but due to
              space limitations only {soldNum === 19 ? 19 : soldNum} are shown
              in the above table.
            </td>
          </tr>
        </tbody>
      ) : null;

    // console.log('soldOverflowMsg', soldOverflowMsg);

    let activeOverflowMsg =
      totalActives > activesNum ? (
        <tbody>
          <tr className="high-sold">
            <td className="sales-table-row">
              <p>
                There {totalActives === "1" ? "is" : "are"} {totalActives}{" "}
              </p>
              <p className="standout-active">
                active {totalActives === "1" ? "listing" : "listings"}
              </p>{" "}
              <p>
                in your neighborhood, but due to space limitations,{" "}
                {activesNum === 0
                  ? "none of these properties are shown"
                  : `only ${activesNum} ${
                      activesNum === 1 ? "is shown" : "are shown"
                    } `}{" "}
                in the above table.
              </p>
            </td>
          </tr>
        </tbody>
      ) : null;
    // console.log('soldOverflowMsg', soldOverflowMsg);
    // console.log('activeOverflowMsg', activeOverflowMsg);
    let messageOverflowNum = 0;
    if (soldOverflowMsg) {
      //  console.log('plus 1 for sold');
      messageOverflowNum++;
    }
    if (activeOverflowMsg) {
      //console.log('plus 1 for active');
      messageOverflowNum++;
    }

    // console.log('messageOverflowNum', messageOverflowNum);
    // console.log('sorted.length', sorted.length);
    let currentSoldsSlice = 19 - messageOverflowNum;
    const currentSolds = (
      <MarketSaleRow solds={sorted.slice(0, 19)} mlsAreaID={mlsAreaID} />
    );
    // console.log('currentSoldsSlice', currentSoldsSlice);

    let leftover = 0;
    if (sorted.length < 18 && preSoldTable.length > 0) {
      leftover = 18 - messageOverflowNum - sorted.length;
    }
    // console.log('leftover', leftover);
    const preSolds = (
      <MarketSaleRow
        solds={preSoldTable.slice(0, leftover)}
        mlsAreaID={mlsAreaID}
        older={true}
        mlsAreaID={mlsAreaID}
      />
    );

    // console.log('preSolds', preSolds);

    const tableHeader = (
      <thead>
        <tr>
          <th>Status</th>
          <th>Sale Date</th>
          <th>
            <p>Property</p>
            <p>Type</p>
          </th>

          <th>Address</th>
          <th>List Price</th>
          <th>Sale Price</th>

          <th>
            <p>Days on</p>
            <p>Market</p>
          </th>

          <th>
            <p>Beds</p>
            <p>Baths</p>
          </th>
          <th>
            <p>Living</p>
            <p>SqFt</p>
          </th>
        </tr>
      </thead>
    );

    return (
      <div className="market-sales-table">
        <div className="subtitle-row">
          <div className="left-subtitle-section">
            <div className="left-subtitle-text">
              <p className="left-subtitle-text-title">Recent Activity</p>
              <div className="left-subtitle-divider"></div>

              {/* //replace with date */}
              <p className="small-left-subtitle">
                {this.formatLastPrintDate(lastMidQuarterlyPrintDate)} —{" "}
                {this.getTodayDate()}
              </p>
            </div>
            <div className="golden-divider" />
          </div>

          <div className="right-subtitle-section">
            <p>Active Listings: {totalActiveListings}</p>
            <div className="right-subtitle-divider">|</div>
            <p>Pending: {totalPendingListings}</p>
          </div>
        </div>
        <table className="regular-sales-table">
          {tableHeader}
          {currentSolds}
        </table>

        <table className="overflow-message-table">
          {soldOverflowMsg}
          {activeOverflowMsg}
        </table>

        {/* <div className="row">
          <div className="sales-dates col">
            {marketActivity.midQuarterSoldListingsCount &&
              midQuarterSoldListingCount > 0 && (
                <div className="row">
                  <p className="sold">*SOLDS</p>
                  <p>
                    Sale Dates Are From February 24th, 2020 to{' '}
                    {this.getTodayDate()}
                  </p>
                </div>
              )}
            <div>
              {marketActivity.midQuarterSoldListingsCount &&
                midQuarterSoldListingCount > 16 && (
                  <div className="high-sold">
                    There were {midQuarterSoldListingCount} homes that{' '}
                    <p className="sold-homes">SOLD</p> in your neighborhood
                    since February 23rd, but due to space limitations only{' '}
                    {soldNum > 16 ? 16 : soldNum} printed in the above table.
                  </div>
                )}
              {totalActives > activesNum && (
                <div className="high-sold">
                  There are {totalActives} active listings in your neighborhood,
                  but due to space limitations{' '}
                  {activesNum === 0 ? 'none' : `only ${activesNum} `} printed in
                  the above table.
                </div>
              )}
            </div>
          </div>
          <div className="sales-legend">
            <div className="row">
              <p>(SF): Single Family Home</p>
              <p>(C): Condo</p>
              <p>(TH): Townhouse</p>
            </div>
            <div className="row">
              <p>(2X): Duplex</p>
              <p>(3X): Triplex</p>
              <p>(4+): > 4 plex</p>
            </div>
          </div>
        </div> */}

        {leftover >= 1 && (
          <div className="subtitle-row">
            <div className="left-subtitle-section">
              <div className="left-subtitle-text overflow-subtitle-text">
                <p className="left-subtitle-text-title">Market Activity</p>
                <div className="left-subtitle-divider"></div>
                <p className="small-left-subtitle">
                  PREVIOUSLY SOLD — Prior to{" "}
                  {this.formatLastPrintDate(lastMidQuarterlyPrintDate)}
                </p>
              </div>
              <div className="golden-divider extra-long" />
            </div>
          </div>
        )}
        {leftover >= 1 && (
          <table className="regular-sales-table">
            {tableHeader}
            {preSolds}
          </table>
        )}
      </div>
    );
  }
}

export default MarketSalesTable;
