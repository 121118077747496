import React, { Component } from "react";
import { propertyUtil } from "../../PropertyUtil";
import Barcode from "./Barcode";
import Endorsement from "./Endorsement";
import TinyCoverBG from "_img/cover-page-bg/tiny_cover.jpg";
import { renderLogicUtil } from "views/Pma/RenderLogicUtil";

class HOAddress extends Component {
  componentWillMount() {
    if (this.props.printVersion) {
      require("./printCommon.scss");
    } else {
      require("./common.scss");
    }
  }
  render() {
    let date = new Date();
    let thisYear = date.getFullYear();
    const thisMonth = date.toLocaleString("default", { month: "long" });
    const today = `${date.getDate()}`.padStart(2, 0);
    let property = renderLogicUtil.homeownerNamesV3([this.props.hoData])[0];

    const { mailingDetails, changeDetails } = this.props.hoData || {};
    const {
      MailAddress,
      MailCity,
      MailState,
      MailZip,
      OwnerFirst,
      OwnerLast,
      SecondaryOwnerFirst,
      SecondaryOwnerLast,
    } = this.props.hoData.mailingDetails || {};

    const {
      hoData,
      version,
      agentData,
      currentArea,
      printVersion,
      mailInfoClass,

      demoAccountHOName,
      coverPageDetails,
    } = this.props;

    const detailsHOName = propertyUtil.changeDetailsHOName(
      changeDetails,
      true,
      mailingDetails
    );

    const { mlsCityName, mlsNeighborhood, mlsAreaID } = currentArea;
    const {
      connectedHOName,

      HONickname,
      EditedHomeownerName,
      emptyValueCheck,
      nicknameWithOwnerLastName,
      removeTrust,
    } = propertyUtil;
    const { isMemberOfTeam, brokerageName, listgenUserID } = agentData;
    let isAnnetteVait = listgenUserID === "101001";

    let includeExtraDisclaimer = !mlsAreaID.includes("SBA");

    // let editedHomeownerName = EditedHomeownerName(
    //   changeDetails && changeDetails.nickName
    // );
    // let ogHoNicknameWithOwnerLastName = propertyUtil.nicknameWithOwnerLastName(
    //   mailingDetails,
    //   changeDetails
    // );
    //
    // let nameToShow;
    //
    // if (
    //   ogHoNicknameWithOwnerLastName.oneHO &&
    //   ogHoNicknameWithOwnerLastName.oneHO.length
    // ) {
    //   nameToShow = removeTrust(ogHoNicknameWithOwnerLastName.oneHO);
    // } else if (editedHomeownerName) {
    //   nameToShow = `${editedHomeownerName} ${emptyValueCheck(OwnerLast)}`;
    // } else {
    //   nameToShow = connectedHOName(mailingDetails, true);
    // }
    //
    // let hoSecondName;
    // if (
    //   ogHoNicknameWithOwnerLastName.twoHO &&
    //   ogHoNicknameWithOwnerLastName.twoHO.length > 1
    // ) {
    //   hoSecondName = ogHoNicknameWithOwnerLastName.twoHO;
    // } else if (
    //   SecondaryOwnerFirst &&
    //   SecondaryOwnerFirst.length > 1 &&
    //   !nameToShow.includes("&")
    // ) {
    //   let secondName =
    //     ogHoNicknameWithOwnerLastName.twoHO &&
    //     ogHoNicknameWithOwnerLastName.twoHO.length > 1 &&
    //     changeDetails &&
    //     changeDetails.nickName &&
    //     !changeDetails.nickName[0].includes("&") &&
    //     !changeDetails.nickName[0]
    //       .toLowerCase()
    //       .includes(ogHoNicknameWithOwnerLastName.twoHO) &&
    //     !changeDetails.nickName[0].toLowerCase().includes(SecondaryOwnerFirst)
    //       ? ogHoNicknameWithOwnerLastName.twoHO
    //       : `${SecondaryOwnerFirst} ${emptyValueCheck(SecondaryOwnerLast)}`;
    //   hoSecondName = `${emptyValueCheck(secondName)}`;
    // }

    return (
      <div className={printVersion ? mailInfoClass : "mail-info-container"}>
        <Barcode hoData={hoData} />
        <Endorsement hoData={hoData} />

        <div className="mq-ho-address">
          <div className="ho-mailing-info">
            <p className="ho-name">{detailsHOName && detailsHOName[0]}</p>
            {detailsHOName && detailsHOName[1] && (
              <p className="ho-second-name">{detailsHOName[1]}</p>
            )}

            <p>{MailAddress}</p>
            <div className="row">
              <p>{MailCity}, </p>
              <p> {MailState}</p> <p>{MailZip}</p>
            </div>
          </div>
        </div>

        <div
          className={
            (version === "custom"
              ? "custom-disclaimer-container "
              : "disclaimer-container ") +
            (isMemberOfTeam ? `team-solicitation` : "") +
            (isAnnetteVait ? "annette-disclaimer-container" : "")
          }
        >
          {includeExtraDisclaimer && (
            <p className={`solicitation-disclaimer updated-2024-disclaimer`}>
              Data contained within this brochure is sourced from MLSListing Inc
              as of {thisMonth} {today}, {thisYear}.
            </p>
          )}

          <p className={`solicitation-disclaimer`}>
            IF YOUR PROPERTY IS ACTIVELY FOR SALE, THIS MAILING IS NOT INTENDED
            TO BE A SOLICITATION.
          </p>

          <p className={`reliable-text`}>
            ALL CONTENT AND DATA ARE DEEMED TO BE ACCURATE, BUT ARE NOT
            GUARANTEED.
          </p>
          {!isAnnetteVait && (
            <div className={`reliable-text one-trees`}>
              <p>
                To offset the paper usage of our mailings, we’ve contributed to
              </p>

              <p>
                planting over 6,000 trees in cooperation with OneTreePlanted.org
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default HOAddress;
