import React, { Component } from "react";

class DashboardValuation extends Component {
  render() {
    const { isMemberOfTeam, listgenUserID } = this.props.agentData;
    let wordsToUse = {
      expert: "expert",
      my: "my",
    };
    if (isMemberOfTeam || listgenUserID === "100988") {
      wordsToUse.expert = "experts";
      wordsToUse.my = "our";
    }
    return (
      <div className="dashboard-valuation">
        <div className="valuation-title">
          As your neighborhood {wordsToUse.expert}, let's connect to review your
          comprehensive home valuation:
        </div>
        <div className="valuation-section-row">
          <div className="valuation-section">
            <div className="valuation-number light">1</div>
            <div className="valuation-data">
              Use {wordsToUse.my} local expertise to prepare a current market
              valuation for your home.
            </div>
          </div>
          <div className="valuation-section">
            <div className="valuation-number medium">2</div>
            <div className="valuation-data">
              Leverage {wordsToUse.my} deep market knowledge to analyze how
              today's trends affect your home's value.
            </div>
          </div>
          <div className="valuation-section">
            <div className="valuation-number dark">3</div>
            <div className="valuation-data">
              Tap into {wordsToUse.my} professional team to prepare
              your property for the highest sale price.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardValuation;
