import React, { Fragment } from 'react';
import { propertyUtil } from '../../PropertyUtil';

const BuyerandListingAgentView = (props) => {
    const { isPropertyMapped } = props;

    if (isPropertyMapped) {
        const {
            buyerAgentFullName,
            sellingOfficeBrokerName,
            listingOfficeName,
            listingAgentFullName,
        } = props.propertyDetails || {};


        return (
            <div className="global-ps-body-container tableTdFlex">
                <div className="tableTdFlex-First textCursor">

                    <p style={{ fontWeight: 700 }}>Listing Agent:</p>
                    <p className="breakAll">{listingAgentFullName && listingAgentFullName.split(' ')[0]}</p>
                    <p className="breakAll">
                        {
                            listingAgentFullName && listingAgentFullName.split(' ')[
                            listingAgentFullName.split(' ').length - 1
                            ]
                        }
                    </p>
                    <br />
                    <p className="breakAll">
                        {listingOfficeName ? propertyUtil.brokerageFirstLineNameHandler(listingOfficeName) : ''}
                    </p>
                    <p className="breakAll">
                        {listingOfficeName ? propertyUtil.brokerageSecondLineNameHandler(listingOfficeName) : ''}
                    </p>
                    <br />
                    <p style={{ fontWeight: 700 }}>Buyer Agent:</p>
                    {(!buyerAgentFullName && !sellingOfficeBrokerName) ? (
                        <Fragment>
                            <p>---</p>
                        </Fragment>
                    ) : ''}
                    <p className="breakAll">{buyerAgentFullName && buyerAgentFullName.split(' ')[0]}</p>
                    <p className="breakAll">
                        {
                            buyerAgentFullName && buyerAgentFullName.split(' ')[
                            buyerAgentFullName.split(' ').length - 1
                            ]
                        }
                    </p>
                    <br />
                    {isPropertyMapped && sellingOfficeBrokerName ? (
                        <Fragment>
                            <p className="breakAll">
                                {propertyUtil.brokerageFirstLineNameHandler(sellingOfficeBrokerName)}
                            </p>
                            <p className="breakAll">
                                {
                                    propertyUtil.brokerageSecondLineNameHandler(sellingOfficeBrokerName)
                                }
                            </p>
                            <br />
                        </Fragment>
                    ) : (
                        <p></p>
                    )}

                </div>
                <div className="tableTdFlex-Second pointer"></div>
            </div>
        );
    } else {
        return (<div className="global-ps-body-container tableTdFlex">
            <div className="tableTdFlex-First textCursor">
                ---
            </div>
            <div className="tableTdFlex-Second pointer"></div>
        </div>);
    }
};

export default BuyerandListingAgentView;