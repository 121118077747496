import React, { Component } from "react";
import BubbleCompareSecondLeft from "./BubbleCompareSecondLeft";
import BubbleCompareSecondRight from "./BubbleCompareSecondRight";
import TableQuadrantsSecondLeft from "views/Pma/PmaEditor/TableQuadrants/TableQuadrantsPageTwo/TableQuadrantsSecondLeft";

class BubbleCompareSecondPage extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      MQCityDetails,
      printVersion,
      mlsAreas,
      version,
      qArea,
      qCity,
      demoAccountAreaName,
      quarterlyAreaTable,
      quarterlyCityTable,
      demoAccountCity,
      isDemo,
    } = this.props;

    return (
      <div
        className={
          printVersion
            ? "bubble-compare-print-page-container"
            : "pma-page-container bubble-compare-editor-page-container"
        }
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <TableQuadrantsSecondLeft
          MQCityDetails={quarterlyCityTable}
          printVersion={printVersion}
          agentData={agentData}
          qArea={qArea}
          qCity={qCity}
          currentArea={currentArea}
          hoData={hoData}
          demoAccountAreaName={demoAccountAreaName}
          quarterlyAreaTable={quarterlyAreaTable}
          quarterlyCityTable={quarterlyCityTable}
          demoAccountCity={demoAccountCity}
        />
        <BubbleCompareSecondRight
          agentData={agentData}
          currentArea={currentArea}
          MQCityDetails={MQCityDetails}
          quarterlyAreaTable={quarterlyAreaTable}
          printVersion={printVersion}
          version={version}
          hoData={hoData}
          mlsAreas={mlsAreas}
          quarterlyCityTable={quarterlyCityTable}
          isDemo={isDemo}
        />
      </div>
    );
  }
}

export default BubbleCompareSecondPage;
