import React, { Component } from "react";

class QVFourEmailAndWebsiteFooter extends Component {
  componentDidMount = () => {
    if (this.props.printVersion) {
      require("./print.scss");
    } else {
      require("./editor.scss");
    }
  };
  render() {
    const { printVersion } = this.props;
    let {
      websiteURL,
      emailAddress,
      teamMembers,
      listgenUserID,
      displayEmailAddress,
      teamName,
      isMemberOfTeam,
      listgenUserFirstName,
      listgenUserLastName,
    } = this.props.agentData;
    const { mlsAreaID } = this.props.currentArea;
    //  console.log("website", websiteURL);
    const isBerkshire = false;
    const isScar = teamName === "Team Scarborough";
    const isShantiAccount = listgenUserID === "106999";
    let hasBlackBorder = false;
    if (listgenUserID === "104412") {
      hasBlackBorder = true;
    }
    let websiteEmailStyle;
    if (listgenUserID === "102843") {
      websiteEmailStyle = "larger-email-website-1";
    }
    if (emailAddress === "superadmin@listgen.com") {
      emailAddress = "SarahSullivanHomes@email.com";
    }
    if (
      listgenUserID === "103" ||
      listgenUserID === "104" ||
      listgenUserID === "105"
    ) {
      websiteURL = "SarahSullivanYourExpert.com";
    }
    let emailToUse;
    if (displayEmailAddress) {
      emailToUse = displayEmailAddress;
    } else {
      emailToUse = emailAddress;
    }
    if (isBerkshire) {
      if (isMemberOfTeam) {
        return (
          <div
            className={`${
              printVersion
                ? "print-qv4-email-website-footer"
                : "editor-qv4-email-website-footer"
            }
              ${hasBlackBorder && "black-compiled-by"} berk-compiled-presented`}
          >
            <div>
              {isScar ? (
                <p>Compiled and Presented by {teamName}</p>
              ) : (
                <p>
                  Compiled and Presented by {listgenUserFirstName} &{" "}
                  {teamMembers[1].firstName}
                </p>
              )}
              <div className="produced-by-divider">|</div>

              <p>BERKSHIRE HATHAWAY HomeServices California Properties</p>
            </div>
            {emailToUse && <div className="berk-email">{emailToUse}</div>}
          </div>
        );
      } else {
        return (
          <div
            className={`${
              printVersion
                ? "print-qv4-email-website-footer"
                : "editor-qv4-email-website-footer"
            }
              ${hasBlackBorder && "black-compiled-by"} berk-compiled-presented`}
          >
            <div>
              <p>
                Compiled and Presented by {listgenUserFirstName}{" "}
                {listgenUserLastName}
              </p>
              <div className="produced-by-divider">|</div>
              <p>BERKSHIRE HATHAWAY HomeServices California Properties</p>
            </div>
            {emailToUse && <div className="berk-email">{emailToUse}</div>}
          </div>
        );
      }
    } else {
      return (
        <div
          className={`${
            printVersion
              ? "print-qv4-email-website-footer"
              : "editor-qv4-email-website-footer"
          }
            ${hasBlackBorder && "black-compiled-by"}`}
        >
          {(listgenUserID === "100129" ||
            listgenUserID === "105642" ||
            listgenUserID === "106463" ||
            listgenUserID === "106550") &&
          teamMembers &&
          emailAddress &&
          teamMembers[1].email ? (
            <div className="compiled-by-footer-inner-container">
              <p>
                Email:<span className="compiled-by-colon-padding"></span>
                {emailAddress}
              </p>
              <div className="compiled-by-footer-space" />
              {teamMembers[1].email && (
                <p>
                  Email:<span className="compiled-by-colon-padding"></span>
                  {teamMembers[1].email}
                </p>
              )}
            </div>
          ) : listgenUserID === "102843" ? (
            <div className="compiled-by-footer-inner-container">
              <p className={websiteEmailStyle}>
                Email:<span className="compiled-by-colon-padding"></span>
                {emailToUse}
              </p>

              <div className="compiled-by-footer-space" />

              <p className={websiteEmailStyle}>
                {mlsAreaID === "SCL-207A" ? "Websites" : "Website"}:
                <span className="compiled-by-colon-padding"></span>
                {mlsAreaID === "SCL-209A"
                  ? "cuestapark.com"
                  : mlsAreaID === "SCL-19Q"
                  ? "LasPalmasPark.com"
                  : mlsAreaID === "SCL-209B"
                  ? "VarsityPark.com"
                  : mlsAreaID === "SCL-207A"
                  ? "OldMountainView.com"
                  : mlsAreaID === "SCL-207B"
                  ? "DowntownMountainView.com"
                  : websiteURL.replace(/^www./g, "")}
              </p>
            </div>
          ) : listgenUserID === "103486" ? (
            <div className="compiled-by-footer-inner-container">
              <p>
                <span className="compiled-by-colon-padding"></span>
                StanTabler.com
              </p>
              <div className="compiled-by-footer-space" />

              <p>
                <span className="compiled-by-colon-padding"></span>
                KirkHodson.com
              </p>
            </div>
          ) : isShantiAccount ? (
            <div className="compiled-by-footer-inner-container">
              <p>
                <span className="compiled-by-colon-padding"></span>
                {emailAddress}
              </p>
              <div className="compiled-by-footer-space" />

              <p>
                <span className="compiled-by-colon-padding"></span>
                {websiteURL.replace(/^www./g, "")}
              </p>
              <div className="compiled-by-footer-space" />
              <p>
                <span className="compiled-by-colon-padding"></span>
                {displayEmailAddress}
              </p>
            </div>
          ) : websiteURL && websiteURL && listgenUserID === "105047" ? (
            <div
              className="compiled-by-footer-inner-container"
              style={listgenUserID === "105047" ? { fontSize: "11px" } : {}}
            >
              <p>
                Email:<span className="compiled-by-colon-padding"></span>
              </p>
              <div>
                <p>{emailAddress}</p>
                <p>{teamMembers[1].email}</p>
              </div>

              <div className="compiled-by-footer-space" />

              {websiteURL && (
                <>
                  <p>Website:</p>
                  <span className="compiled-by-colon-padding"></span>
                  <div>
                    <p>{websiteURL.replace(/^www./g, "")}</p>
                    <p>{listgenUserID === "105047" ? " kdavydova.com" : ""}</p>
                  </div>
                </>
              )}
            </div>
          ) : websiteURL && websiteURL ? (
            <div
              className="compiled-by-footer-inner-container"
              style={listgenUserID === "105047" ? { fontSize: "11px" } : {}}
            >
              <p>
                Email:<span className="compiled-by-colon-padding"></span>
                {emailToUse}
              </p>
              <div className="compiled-by-footer-space" />

              {websiteURL && (
                <p>
                  Website:<span className="compiled-by-colon-padding"></span>
                  {websiteURL.replace(/^www./g, "")}
                  {listgenUserID === "105047" ? " kdavydova.com" : ""}
                </p>
              )}
            </div>
          ) : (
            <div className="compiled-by-footer-inner-container center">
              <p>
                Email:<span className="compiled-by-colon-padding"></span>
                {emailToUse}
              </p>
            </div>
          )}
        </div>
      );
    }
  }
}

export default QVFourEmailAndWebsiteFooter;
