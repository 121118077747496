import React from 'react';
import styles from "./styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { orange } from '@material-ui/core/colors';
import HomeValuationRatingStarsView from './HomeValuationRatingStarsView';
import HomeValuationRatingDialog from './HomeValuationRatingDialog';
import { HomeValuationList, isAVeryGoodListing } from './HomeValuationUtils';
import classNames from 'classnames';


const HomeValuationsRatingHandler = ({ classes, property, ...rest }) => {
    const { agentCommentsOnHO, likelyhoodOfListing, isHomeValuationGivenByAgent } = property;

    const getTextAccordingToRating = (rating) => {
        const ratingObj =  HomeValuationList.find(item => item.key == rating);
        return ratingObj ? ratingObj.value : 'NO RATING GIVEN'
    }

    const isAVeryGoodListinng = isAVeryGoodListing(isHomeValuationGivenByAgent, likelyhoodOfListing);

    return !isHomeValuationGivenByAgent && !likelyhoodOfListing ? <div>
        <HomeValuationRatingDialog property={property} {...rest}>
            <div className={classNames(classes.homeValuationsRatingsButton, classes.flashText)}>
                <p>CLICK TO RATE</p>
                <p>LIKELYHOOD OF LISTING</p>
            </div>
        </HomeValuationRatingDialog>
    </div>
        : <div className={classes.homeValuationsRatingHandlerMain}>
            {likelyhoodOfListing > 0 && <div className={classes.homeValuationsRatingHandlerStars}>
                <HomeValuationRatingStarsView property={property} ratingValue={likelyhoodOfListing} />
            </div>}

            <div className={classes.homeValuationsRatingHandlerTextTitle} style={isAVeryGoodListinng ? {color: 'white'} : {}}>
                {getTextAccordingToRating(likelyhoodOfListing)}
            </div>
            <HomeValuationRatingDialog property={property} {...rest}>
                <div>
                    <div>{agentCommentsOnHO ? <span  className={classes.underline}>VIEW YOUR COMMENTS</span> : <span>NO COMMENTS MADE</span>}</div>
                    <div className={classes.homeValuationsRatingHandlerBlueLink}>
                        <div>Edit</div>
                    </div>
                </div>
            </HomeValuationRatingDialog>
        </div>

}

export default compose(withStyles(styles), withSnackbar)(HomeValuationsRatingHandler);
