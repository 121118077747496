import {
  SET_TOKEN_DATA,
  HANDLE_FIELD_CHANGE,
  HANDLE_VALIDATIONS,
  SHOW_PASSWORD,
  START_LOADING,
  STOP_LOADING,
  RESET_APP_STATE,
  ADD_AGENT_DASHBOARD_DATA,
  STOP_LOADING_WITH_MESSAGE,
  STOP_LOADING_WITH_SUCCESS_MESSAGE,
  SET_STATE_ERRORS,
  RESET_APP_STATE_EXCEPT_VALUES,
  CHANGE_MULTITPLE_DATA_INTO_STATE,
  UPDATE_USER_PROFILE_IMAGE,
  DO_LOGOUT_DATA_RESET,
  UPDATE_CITY_AREA_MAP_DATA,
  UPDATE_PMA_STRENGTH_COUNT,
  UPDATE_PMA_ADVANTAGE_COUNT,
  UPDATE_PMA_TESTIMONIALS_COUNT,
  UPDATE_PMA_MISSIONS_COUNT
} from 'store/types';
import validate from 'validate.js';
import _ from 'lodash';
import Base from 'common/constants';
import { SetStateInStorage, SetExpireTimerInStorage } from 'common/storage';
// import moment from "moment";

export const LoginReducer = (state, action) => {
  switch (action.type) {
    case RESET_APP_STATE:
      const newDPState = { ...state };
      const deepState = _.cloneDeep(state.deepCopy);
      for (let key in state.deepCopy) {
        newDPState[key] = state.deepCopy[key];
      }
      /**now check for apecial fields also */
      newDPState.isValid = false;
      newDPState.deepCopy = deepState;
      return newDPState;

    case RESET_APP_STATE_EXCEPT_VALUES:
      const newDPEVState = { ...state };
      const xState = _.cloneDeep(state.deepCopy);
      /**check for invalied values */
      for (let key in newDPEVState.values) {
        let havingError = newDPEVState.touched[key] && newDPEVState.errors[key];
        if (havingError) {
          newDPEVState.values[key] = xState.values[key];
        }
      }
      /**Now deep copy state also except values */
      for (let key in state.deepCopy) {
        if (key !== 'values') newDPEVState[key] = state.deepCopy[key];
      }
      /**now check for apecial fields also */
      newDPEVState.deepCopy = xState;
      return newDPEVState;

    case DO_LOGOUT_DATA_RESET:
      const newDPLState = { ...state };
      const deepLState = _.cloneDeep(state.deepCopy);
      for (let key in state.deepCopy) {
        newDPLState[key] = state.deepCopy[key];
      }
      /**now check for apecial fields also */
      newDPLState.isValid = false;
      newDPLState.deepCopy = deepLState;
      newDPLState.isUserLoggedIn = false;
      newDPLState.updateData = true;
      newDPLState.reloadData = true;
      return newDPLState;

    case SET_TOKEN_DATA:
      let newLSState = {
        ...state,
        isUserLoggedIn: true,
        userName: action.payload.userName,
        userRole: action.payload.roleID
          ? action.payload.roleID
          : Base.APP_DATA.APP_AGENT_ROLE_ID,
        tokenData: action.payload,
      };
      /**store into db also */
      const persistData = _.pick(
        newLSState,
        'isUserLoggedIn',
        'userName',
        'userRole',
        'tokenData',
        'isValid',
        'isLoading',
        'submitError',
        'submitSuccess'
      );
      SetStateInStorage(_.cloneDeep(persistData));
      /**Now set expire time also */
      SetExpireTimerInStorage(_.cloneDeep(persistData));
      return newLSState;

    case ADD_AGENT_DASHBOARD_DATA:
      /**write custom code to add last print date */
      const newLSSState = {
        ...state,
        userData: action.payload,
        userID: action.payload.listgenUserID
          ? action.payload.listgenUserID
          : null,
        updateData: false,
      };

      const persisttData = _.pick(
        newLSSState,
        'isUserLoggedIn',
        'userID',
        'userData',
        'userName',
        'userRole',
        'tokenData',
        'isValid',
        'isLoading',
        'submitError',
        'submitSuccess'
      );
      SetStateInStorage(_.cloneDeep(persisttData));
      return newLSSState;

    case HANDLE_FIELD_CHANGE:
      const newState = { ...state };
      newState.submitError = null;
      newState.touched[action.name] = true;
      newState.values[action.name] = action.value;
      return newState;

    case HANDLE_VALIDATIONS:
      const { values } = state;
      const newVState = { ...state };
      const errors = validate(values, action.schema);
      newVState.errors = errors || {};
      newVState.isValid = errors ? false : true;
      return newVState;

    case SET_STATE_ERRORS:
      const newSSEState = { ...state };
      newSSEState.errors = action.errors;
      return newSSEState;

    case SHOW_PASSWORD:
      const newPState = { ...state };
      newPState.showPassword[action.name] = !newPState.showPassword[
        action.name
      ];
      return newPState;

    case START_LOADING:
      const newSLState = { ...state };
      newSLState.isLoading = true;
      return newSLState;

    case STOP_LOADING:
      const newSTLState = { ...state };
      newSTLState.isLoading = false;
      return newSTLState;

    case STOP_LOADING_WITH_MESSAGE:
      const newSTLWMState = { ...state };
      newSTLWMState.isLoading = false;
      newSTLWMState.submitError = action.message;
      return newSTLWMState;

    case STOP_LOADING_WITH_SUCCESS_MESSAGE:
      const newSTLWSMState = { ...state };
      newSTLWSMState.isLoading = false;
      newSTLWSMState.submitSuccess = action.message;
      return newSTLWSMState;

    case UPDATE_USER_PROFILE_IMAGE:
      const newUUPState = { ...state };
      newUUPState.userData.photoURL = action.payload;
      return newUUPState;

    case CHANGE_MULTITPLE_DATA_INTO_STATE:
      const newMDSSMState = { ...state };
      for (let key in action.data) {
        newMDSSMState.values[key] = action.data[key];
      }
      return newMDSSMState;

    case UPDATE_CITY_AREA_MAP_DATA:
      const newCAMDMState = { ...state };
      newCAMDMState.cityAreaData = action.payload;
      return newCAMDMState;

    case UPDATE_PMA_STRENGTH_COUNT:
      const newPSUUPState = { ...state };
      newPSUUPState.userData.designationsCount = (parseInt(action.count) > 0 ? parseInt(action.count) : 0);
      return newPSUUPState;

    case UPDATE_PMA_ADVANTAGE_COUNT:
      const newPAUUPState = { ...state };
      newPAUUPState.userData.advantagesCount = (parseInt(action.count) > 0 ? parseInt(action.count) : 0);
      return newPAUUPState;

    case UPDATE_PMA_TESTIMONIALS_COUNT:
      const newPTUUPState = { ...state };
      newPTUUPState.userData.testimonialsCount = (parseInt(action.count) > 0 ? parseInt(action.count) : 0);
      return newPTUUPState;

    case UPDATE_PMA_MISSIONS_COUNT:
      const newPTUMIPState = { ...state };
      newPTUMIPState.userData.missionStatementsCount = (parseInt(action.count) > 0 ? parseInt(action.count) : 0);
      return newPTUMIPState;

    default:
      return state;
  }
};
