import React, { Component } from "react";
import TableQuadrantsFirstLeft from "./TableQuadrantsFirstLeft";
import TableQuadrantsFirstRight from "./TableQuadrantsFirstRight";
import V2DebSamuel from "views/Pma/PmaEditor/CustomAgentPages/V2DebSamuel";
import QVFourFirstLeft from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourFirstLeft";
import YETwoFirstLeft from "views/Pma/PmaEditor/YearEnd/YETwo/YETwoFirstPage/YETwoFirstLeft";
// import YETwoFirstLeft from "views/Pma/PmaEditor/YearEnd/YETwo/YETwoFirstPage/YETwoFirstLeft";
import RGB from "views/Pma/PmaEditor/CustomAgentPages/RGB";
// import CYMK from "views/Pma/PmaEditor/CustomAgentPages/CYMK";

class TableQuadrantsPageOne extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      mlsAreas,
      navbarData,
      printVersion,
      version,
      coverPageDetails,
      quarterlyAreaTable,
      testimonials,
      quadrantModalOpen,
      quadrantDetails,
      propertyPhotoDetailsDTO,
    } = this.props;
    // console.log("this.props", this.props);
    const { mlsAreaID } = currentArea;
    const { listgenUserID } = agentData;
    let isKerryMormann = listgenUserID === "100560";

    return (
      <div
        className={
          "pma-page-container " +
          (printVersion
            ? "table-quadrants-print-page-container"
            : "table-quadrants-pma-editor-page-container")
        }
      >
        {(printVersion && mlsAreaID === "SBA-10A") ||
        (printVersion && mlsAreaID === "SBA-10G") ? (
          <RGB
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version="qv4"
            //  demoAccountHOName={demoAccountHOName}
            coverPageDetails={coverPageDetails}
          />
        ) : listgenUserID === "100585" ? (
          <RGB
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version="qv4"
            //  demoAccountHOName={demoAccountHOName}
            coverPageDetails={coverPageDetails}
          />
        ) : (
          <TableQuadrantsFirstLeft
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            printVersion={printVersion}
            version={version}
            mlsAreas={mlsAreas}
            coverPageDetails={coverPageDetails}
            quarterlyAreaTable={quarterlyAreaTable}
            testimonials={testimonials}
            quadrantModalOpen={quadrantModalOpen}
            quadrantDetails={quadrantDetails}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
          />
        )}
        <TableQuadrantsFirstRight
          agentData={agentData}
          hoData={hoData}
          printVersion={printVersion}
          version={version}
          currentArea={currentArea}
          coverPageDetails={coverPageDetails}
          quarterlyAreaTable={quarterlyAreaTable}
          quadrantModalOpen={quadrantModalOpen}
        />
      </div>
    );
  }
}

export default TableQuadrantsPageOne;
