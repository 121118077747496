import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { CommonConfirmationDialogV2Component } from 'components';
import { CommonAxiosAPIHandler, showErrorNotification } from 'common/helpers';
import { withSnackbar } from 'notistack';
import { compose } from 'recompose';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const GET_MAILPRO_DATA_S3_PATH = "/admin/mailProExportOfAreaAsExcel";

const useStyles = makeStyles({
    buttonStyle: {
        backgroundColor: 'white',
    },
    dialogContentContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    dialogContentText: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        fontSize: '15px',
        lineHeight: '18px'
    },
    bold500: {
        fontWeight: 500
    }
});
const DownloadMarketData = ({ areaID, areaName, iconButton = false, buttonStyle = {}, classes:customClasses = {}, iconClasses: iconCustomClasses = {}, ...rest }) => {
    const classes = useStyles();

    const GetAllAgentDataForMPInsight = (areaID) => {
        return CommonAxiosAPIHandler("GET", [
            `${GET_MAILPRO_DATA_S3_PATH}?areaIDs=${areaID}&mailingStatus=-&includeBlocked=false`
        ]);
    };

    const downloadCSV = () => {
        GetAllAgentDataForMPInsight(areaID).then((response) => {
            console.log('response', response)
            if (response) {
                window.open(response, '_blank');
            }
        }).catch((error) => {
            console.log("Error in downloading MailPro data", error);
            showErrorNotification(rest, "Error in downloading MailPro data");
        });
    }

    const dialognContent = <div className={classes.dialogContentContainer}>
        <div className={classes.bold500} style={{marginTop: '8px'}}>{areaName}</div>
        <div className={classes.dialogContentText}>
            <div style={{ marginTop: '16px' }}>Just checking... Are you sure you want to download all your Homeowners as an Excel file?</div>
        </div>
    </div>

    return <CommonConfirmationDialogV2Component
        title={"You are Downloading your Homeowners Database"}//YOU ARE DOWNLOADING YOUR HOMEOWNERS DATABASE
        subtitle={dialognContent}
        methodToCallOnConfirmation={() => { downloadCSV() }}
        key={'MailProAgentData-Navbar'}
        cancelButtonText={'CANCEL'}
        confirmationButtonText={'YES, DOWNLOAD'}
    >
        {!iconButton ?
            <Button
                variant="contained"
                size="small"
                className={classes.buttonStyle}
                style={buttonStyle}
                classes={customClasses}
            >Download MailPro Data</Button>
            :
            <IconButton
                color="primary"
                disableRipple={true}
                disableFocusRipple={true}
                edge="start"
                style={buttonStyle}
                classes={customClasses}
            >
                <CloudDownloadIcon classes={iconCustomClasses} />
            </IconButton>}
    </CommonConfirmationDialogV2Component>
}


DownloadMarketData.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object,
};

export default compose(withSnackbar)(DownloadMarketData);