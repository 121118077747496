import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { Link, withRouter } from "react-router-dom";
import { Typography, Card, Fab } from "@material-ui/core";
import compose from "recompose/compose";
import _ from "lodash";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import { WebsiteLayout } from "layouts";
import {
  IsValidLoginAuth,
  IsValidSemiAuth,
  GetStateStorage,
} from "common/storage";
import Constants from "common/constants";
import Base from "common/constants";
import DocumentTitle from "react-document-title";
import querystring from "querystring";
import { GetFreeAreasByCountyID } from "services/agent";
import { connect } from "react-redux";
import { homeConstants } from "../../_constants/home.constant";
import { getPMAApprovalDateForAAgentFromSchedule } from "views/Schedule/MailingScheduleServices";
import moment from "moment";
import { handleLogoutFn } from "common/helpers";

class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showCutOff: true,
      isChrome: false,
      isValidLogin: false,
      isValidSemiLogin: false,
      userData: {},
      isValidROICounty: false,
      availableAreas: 0,
      countyID: 0,
      openSplitButton: true,
      cutoffDate: null,
      reviewMonthName: null,
    };
    /**
     * Check for query params. If reload page is 1 then reset the query para and reload the page. This one we required for the switch back to Admin account.
     */
    try {
      let parsedParams = querystring.parse(props.location.search.substring(1));
      let reloadPage = parsedParams.reloadPage;
      this.props.history.push({
        pathname: "/home",
      });
      if (reloadPage && Number(reloadPage) === 1) {
        window.location.reload();
        return;
      }
    } catch (err) {
      console.log(err);
    }

    this.anchorRef = React.createRef();
  }

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  redirectToRegister = () => {};

  redirectToPMA = () => {};

  handleStateDelayProblem = (isValidLogin) => {
    const startIntervel = setInterval(() => {
      let localStateData = GetStateStorage();
      let userData = localStateData ? localStateData : {};
      if (userData.userData && userData.userData.countyID) {
        //its only be valid if agent is registered not in registration process
        let isValidROICounty =
          isValidLogin && userData.userData && userData.userData.countyID;
        //&&
        //Number(userData.userData.countyID) !== 27;
        //clear the intervel also
        clearInterval(startIntervel);
        this.setState(
          (prevState) => ({
            userData: { ...prevState.userData, ...userData },
            isValidROICounty: isValidROICounty,
            countyID: Number(userData.userData.countyID),
          }),
          () => {
            if (isValidROICounty && this.props.availableCountyAreas === 0) {
              this.getCountyFreeAreaData(userData.userData.countyID);
            }
            if (isValidLogin) {
              this.handleScheuleData(userData.userData);
            }
          }
        );
      }
    }, 300);
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    let localStateData = GetStateStorage();
    let isValidLogin = IsValidLoginAuth();
    let isValidSemiLogin = IsValidSemiAuth();

    if (window) {
      let isChrome =
        /Chrome/.test(navigator.userAgent) &&
        /Google Inc/.test(navigator.vendor);
      let userData = localStateData ? localStateData : {};
      let isValidROICounty =
        isValidLogin && userData.userData && userData.userData.countyID;
      //&&
      //Number(userData.userData.countyID) !== 27;
      //here the user data wills et after calling the profile api so complete userdata wont be there only token data would be there. so use the token data
      this.setState(
        (prevState) => ({
          isChrome,
          isValidLogin,
          isValidSemiLogin,
          userData: {
            ...prevState.userData,
            ...(userData.userData ? userData : {}),
          }, //here if we dont put this condition then for agetn account by default it will show other button except ROI button with no listgen profile user data so when we click on buttons it will show no acess.basically it measn profile api data is loaded and this problem only comes when we come from login screen directly. for other cases like refreshing page, coming from other screen this problem is not there.
          isValidROICounty: isValidROICounty,
          countyID:
            userData.userData &&
            userData.userData.countyID &&
            Number(userData.userData.countyID),
        }),
        () => {
          if (!userData.userData) {
            // there is a catch if profile api is loading late then we need to call this method so we can update store by using intervel
            this.handleStateDelayProblem(isValidLogin);
          } else {
            if (isValidROICounty && this.props.availableCountyAreas === 0) {
              this.getCountyFreeAreaData(userData.userData.countyID);
            }
            isValidLogin && this.handleScheuleData(userData.userData);
          }
        }
      );
    }
  };

  handleScheuleData = (userData) => {
    //check for review end date
    getPMAApprovalDateForAAgentFromSchedule(
      userData || {},
      (cutoffDate, cutoffMonth) => {
        this.setState({ cutoffDate: cutoffDate, cutoffMonth: cutoffMonth });
      }
    );
  };

  getCountyFreeAreaData = (countyID) => {
    GetFreeAreasByCountyID(countyID)
      .then((data) => {
        //this.setState({ availableAreas: data });
        this.props.updateAvailableAreaCount({
          type: homeConstants.UPDATE_AVAILABLE_AREA_COUNT,
          payload: data,
        });
      })
      .catch((err) => {});
  };

  handleROIClick = () => {
    let countyMapObj = Base.COUNTY_ID_NAME_MAP;
    const {
      userData: { userData },
    } = this.state;
    let countyID = userData.countyID ? userData.countyID : 42;
    const { history } = this.props;

    history.push(
      `/agent/areas-by-city?emailAddress=${userData.emailAddress}&listgenUserFirstName=${userData.listgenUserFirstName}&listgenUserLastName=${userData.listgenUserLastName}&brokerageName=${userData.brokerageName}&countyID=${countyID}&countyName=${countyMapObj[countyID]}&listgenUserID=${userData.listgenUserID}&agentLicenseNumber=${userData.agentLicenseNumber}`
    );
  };

  isAnyAreaTaglineAdded = (navbarInfo = []) => {
    if (Array.isArray(navbarInfo)) {
      return (
        navbarInfo.find((item) => item.tagLine && item.tagLine !== "") !==
        undefined
      );
    }
    return false;
  };

  isAnyTestimonialAdded = (agentData = {}) => {
    return agentData.testimonialsCount && agentData.testimonialsCount > 0;
  };

  isAnyMissionStatementAdded = (agentData = {}) => {
    return (
      agentData.missionStatementsCount && agentData.missionStatementsCount > 0
    );
  };

  isAnyLogoAdded = (agentData = {}) => {
    return agentData.logoURL && agentData.logoURL !== "";
  };

  isAnyPhotoAdded = (agentData = {}) => {
    return agentData.photoURL && agentData.photoURL !== "";
  };

  render() {
    const { classes, availableCountyAreas } = this.props;
    const {
      isChrome,
      userData,
      isValidLogin,
      isValidSemiLogin,
      isValidROICounty,
      countyID,
    } = this.state;

    let isAgentSession =
      parseInt(userData.userRole) === parseInt(Base.APP_DATA.APP_AGENT_ROLE_ID);
    const stateData = GetStateStorage();
    const agentData = (stateData || {}).userData;
    let navbarData =
      agentData &&
      Array.isArray(agentData.navbarInfo) &&
      agentData.navbarInfo[0];

    const isAnyAreaTaglineExist = this.isAnyAreaTaglineAdded(
      (agentData || {}).navbarInfo
    );
    const anyTestimonialAdded = this.isAnyTestimonialAdded(agentData);
    const anyMissionStatementAdded = this.isAnyMissionStatementAdded(agentData);
    const isAnyLogoAdded = this.isAnyLogoAdded(agentData);
    const isAnyPhotoAdded = this.isAnyPhotoAdded(agentData);

    return (
      <DocumentTitle title="Welcome to ListGen">
        <WebsiteLayout gradientMode={true} hideFooter={true}>
          <div className={classNames(classes.root)}>
            {/* <div className={classes.bgBackground}> */}
            {/* <Grid
              className={classNames(classes.grid, classes.backgroundGradient)}
              container
            >
              <Grid item lg={12} sm={12} xs={12} md={12}> */}
            <div className={classes.homeImageHeader}>
              <div className={classes.textOverImage}>
                <div
                  className={classNames(
                    classes.gridButtonDesign,
                    classes.firstGridWidth
                  )}
                >
                  <div className={classes.gridInnerButtonDesign}>
                    {/* //only show opma button if its an agent account */}
                    {isValidLogin && !isValidSemiLogin && isAgentSession ? (
                      <Fragment>
                        {/* {!this.state.openSplitButton && (
                          <Fab
                            variant="outlined"
                            color="default"
                            aria-label="add"
                            onClick={() =>
                              this.setState((prevState) => ({
                                openSplitButton: !prevState.openSplitButton,
                              }))
                            }
                            className={classNames(
                              classes.whiteBorderButton,
                              classes.whiteBorderButtonSmall
                            )}
                            size="small"
                          >
                            VIEW OPTIONS
                          </Fab>
                        )} */}
                        {this.state.openSplitButton ? (
                          <Fragment>
                            <Link
                              to={`/agent/details/ag/${btoa(userData.userID)}`}
                            >
                              <Fab
                                variant="outlined"
                                color="default"
                                aria-label="add"
                                //onClick={this.handleOpen}
                                className={classNames(
                                  classes.whiteBorderButton,
                                  classes.whiteBorderButtonSmall,
                                  classes.fabBottomMargin
                                )}
                                size="small"
                              >
                                Edit Profile
                              </Fab>
                            </Link>

                            <Link
                              to={`/agent/details/ag/${btoa(
                                userData.userID
                              )}#agent-photo-section`}
                            >
                              <Fab
                                variant="outlined"
                                color="default"
                                aria-label="add"
                                className={classNames(
                                  classes.whiteBorderButton,
                                  classes.whiteBorderButtonSmall,
                                  classes.fabBottomMargin,
                                  !isAnyPhotoAdded &&
                                    classes.incompleteDetailsOrRedButton,
                                  !isAnyPhotoAdded && classes.flashButton
                                )}
                                size="small"
                              >
                                {/* {isAnyPhotoAdded ? 'Edit' : 'Add'} Photo */}
                                Your Photo
                              </Fab>
                            </Link>

                            <Link
                              to={`/agent/details/ag/${btoa(
                                userData.userID
                              )}#agent-logo-section`}
                            >
                              <Fab
                                variant="outlined"
                                color="default"
                                aria-label="add"
                                className={classNames(
                                  classes.whiteBorderButton,
                                  classes.whiteBorderButtonSmall,
                                  classes.fabBottomMargin,
                                  !isAnyLogoAdded &&
                                    classes.incompleteDetailsOrRedButton,
                                  !isAnyLogoAdded && classes.flashButton
                                )}
                                size="small"
                              >
                                {/* {isAnyLogoAdded ? 'Edit' : 'Add'} Logo */}
                                Your Logo
                              </Fab>
                            </Link>

                            <Link
                              to={`/agent/details/ag/${btoa(
                                userData.userID
                              )}#area-tagline-section`}
                            >
                              <Fab
                                variant="outlined"
                                color="default"
                                aria-label="add"
                                //onClick={this.handleOpen}
                                className={classNames(
                                  classes.whiteBorderButton,
                                  classes.whiteBorderButtonSmall,
                                  classes.fabBottomMargin,
                                  !isAnyAreaTaglineExist &&
                                    classes.incompleteDetailsOrRedButton,
                                  !isAnyAreaTaglineExist && classes.flashButton
                                )}
                                size="small"
                              >
                                {/* {isAnyAreaTaglineExist ? 'Edit' : 'Add'} Area Taglines */}
                                Area Taglines
                              </Fab>
                            </Link>

                            <Link
                              to={`/cards/credit/ag/${btoa(
                                userData.userID
                              )}/${btoa(null)}/${btoa(null)}/${btoa(null)}`}
                            >
                              <Fab
                                variant="outlined"
                                color="default"
                                aria-label="add"
                                //onClick={this.handleOpen}
                                className={classNames(
                                  classes.whiteBorderButton,
                                  classes.whiteBorderButtonSmall,
                                  classes.fabBottomMargin
                                )}
                                size="small"
                              >
                                Edit Credit Card
                              </Fab>
                            </Link>

                            <Link to="/account">
                              <Fab
                                variant="outlined"
                                color="default"
                                aria-label="add"
                                //onClick={this.handleOpen}
                                className={classNames(
                                  classes.whiteBorderButton,
                                  classes.whiteBorderButtonSmall,
                                  classes.fabBottomMargin
                                )}
                                size="small"
                              >
                                Change Password
                              </Fab>
                            </Link>
                            <Link to="/agent/clienttestimonials">
                              <Fab
                                variant="outlined"
                                color="default"
                                aria-label="add"
                                //onClick={this.handleOpen}
                                className={classNames(
                                  classes.whiteBorderButton,
                                  classes.whiteBorderButtonSmall,
                                  classes.fabBottomMargin,
                                  !anyTestimonialAdded &&
                                    classes.incompleteDetailsOrRedButton,
                                  !anyTestimonialAdded && classes.flashButton
                                )}
                                size="small"
                              >
                                {/* {!anyTestimonialAdded ? "Add Client Testimonials" : 'Select a Client Testimonial'} */}
                                Client Testimonials
                              </Fab>
                            </Link>
                            <Link to="/agent/missionstatements">
                              <Fab
                                variant="outlined"
                                color="default"
                                aria-label="add"
                                //onClick={this.handleOpen}
                                className={classNames(
                                  classes.whiteBorderButton,
                                  classes.whiteBorderButtonSmall,
                                  classes.fabBottomMargin,
                                  !anyMissionStatementAdded &&
                                    classes.incompleteDetailsOrRedButton,
                                  !anyMissionStatementAdded &&
                                    classes.flashButton
                                )}
                                size="small"
                              >
                                {/* {!anyMissionStatementAdded ? 'Add Mission Statements' : "Select a Mission Statement"} */}
                                Your Mission Statement
                              </Fab>
                            </Link>

                            {/* {this.state.openSplitButton && <div className={classes.closeMenuButton} onClick={() => this.setState((prevState) => ({ openSplitButton: !prevState.openSplitButton }))}>CLOSE OPTIONS</div>} */}
                          </Fragment>
                        ) : null}
                      </Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* //className={classes.innerTOIBlock} */}
                <div
                  className={classNames(
                    classes.gridButtonDesign,
                    classes.secondGridWidth
                  )}
                >
                  <div className={classes.textOIButtons}>
                    {!isChrome ? (
                      <div className={classes.useChromeSection}>
                        <Card className={classes.notChromeCard}>
                          Looks like your not using chrome. We recommend using
                          Chrome for best results.
                        </Card>
                      </div>
                    ) : (
                      ""
                    )}
                    {!isValidLogin && isValidSemiLogin ? (
                      <Link to="/agent/register">
                        <Fab
                          variant="outlined"
                          color="default"
                          aria-label="add"
                          //onClick={this.handleOpen}
                          className={classes.whiteBorderButton}
                        >
                          CLICK HERE TO REGISTER
                        </Fab>
                      </Link>
                    ) : (
                      ""
                    )}
                    {/* //only show opma button if its an agent account */}
                    {isValidLogin && !isValidSemiLogin ? (
                      <Fragment>
                        <div className={classes.pmaFragment}>
                          {this.state.showCutOff &&
                          Constants.CUT_OFF_DATES_COUNTY_WISE[countyID] ? (
                            <div className={classes.cutOffMargin}>
                              <Typography
                                variant="body1"
                                color="inherit"
                                style={{ marginTop: "-2px", fontSize: "16px" }}
                              >
                                Your all-new {this.state.cutoffMonth} PMAs are
                                under development.
                                <br />
                                <br />
                                {this.state.cutoffDate ? (
                                  <div
                                    className={classes.flashButton}
                                    style={{
                                      marginTop: "0px",
                                      fontSize: "18px",
                                    }}
                                  >
                                    <p>The Final Approval date is</p>
                                    <p>
                                      {moment(this.state.cutoffDate).format(
                                        "dddd MMMM Do @ h A"
                                      )}
                                    </p>
                                    {/* <p>Wednesday January 10th @ 12pm</p> */}
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </Typography>
                            </div>
                          ) : (
                            ""
                          )}
                          {/* {isAgentSession ? (<Link to="/mailpro">
                            <div className={classes.newHOSection}>
                              <Typography variant="body1" color="inherit">
                                You have 10 new homeowners in 3 of your area's ready to be personalized
                              </Typography>
                            </div>
                          </Link>) : null} */}
                          <div className={classes.pmaFragmentButtons}>
                            {isAgentSession ? (
                              <Fragment>
                                <Link to={`/pma/${navbarData.mlsAreaID}`}>
                                  <Fab
                                    variant="outlined"
                                    color="default"
                                    aria-label="add"
                                    //onClick={this.handleOpen}
                                    className={classes.whiteBorderButton}
                                    size="medium"
                                  >
                                    BUILD YOUR PRECISION MARKET ANALYSIS
                                  </Fab>
                                  {/* <Fab
                                    variant="outlined"
                                    color="default"
                                    aria-label="add"
                                    //onClick={this.handleOpen}
                                    className={classNames(classes.whiteBorderButton, classes.whiteBorderButtonSmall, classes.greenBgButton)}
                                    size="medium"
                                  >
                                    REVIEW and APPROVE YOUR PRECISION MARKET ANALYSIS
                            </Fab> */}
                                </Link>
                                <Link to={`/mailpro/${navbarData.mlsAreaID}`}>
                                  <Fab
                                    variant="outlined"
                                    color="default"
                                    aria-label="add"
                                    //onClick={this.handleOpen}
                                    className={classes.whiteBorderButton}
                                    size="medium"
                                    style={{ marginTop: "32px" }}
                                  >
                                    Go to MailPro
                                  </Fab>
                                </Link>
                              </Fragment>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div
                  className={classNames(
                    classes.gridButtonDesign,
                    classes.thirdGridWidth
                  )}
                >
                  <div className={classes.gridInnerButtonDesign}>
                    {/* //only show opma button if its an agent account */}
                    {isValidLogin && !isValidSemiLogin && isAgentSession ? (
                      <Fragment>
                        {/* <Link to="/agent/areas-by-city"> */}
                        {isValidROICounty ? (
                          <div>
                            <Fab
                              variant="outlined"
                              color="default"
                              aria-label="add"
                              //onClick={this.handleOpen}
                              className={classNames(
                                classes.whiteBorderButton,
                                classes.whiteBorderButtonSmall
                              )}
                              size="small"
                              onClick={(event) => this.handleROIClick()}
                            >
                              Area ROI Analysis
                            </Fab>
                            {availableCountyAreas > 0 ? (
                              <Typography
                                variant="subtitle2"
                                component="p"
                                className={classes.areaAvailable}
                              >
                                {availableCountyAreas} Areas Available
                              </Typography>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        <Link to="/schedule" style={{ marginTop: "24px" }}>
                          {/* <div style={{ height: '72px', width: '72px'}}></div> */}
                          <Fab
                            variant="outlined"
                            color="default"
                            aria-label="add"
                            //onClick={this.handleOpen}
                            className={classNames(
                              classes.whiteBorderButton,
                              classes.whiteBorderButtonSmall
                            )}
                            size="small"
                          >
                            {/* <span className={classes.newTextWithoutBorder}>
                              New
                            </span> */}
                            &nbsp;View Mailing Calendar
                          </Fab>
                        </Link>

                        <Fab
                            variant="outlined"
                            color="default"
                            aria-label="add"
                            onClick={() => handleLogoutFn()}
                            className={classNames(
                              classes.whiteBorderButton,
                              classes.whiteBorderButtonSmall,
                              // classes.incompleteDetailsOrRedButton
                            )}
                            size="small"
                          >
                            &nbsp;&nbsp;&nbsp;Logout&nbsp;&nbsp;&nbsp;
                          </Fab>
                        {/* // &nbsp;&nbsp;<span className={classes.newText}>New</span> */}
                        {/* </Link> */}
                        {/* <Link
                          to="/agent/missionstatements"
                        //style={{ marginLeft: "-26px" }}
                        >
                          <Fab
                            variant="outlined"
                            color="default"
                            aria-label="add"
                            //onClick={this.handleOpen}
                            className={classNames(
                              classes.whiteBorderButton,
                              classes.whiteBorderButtonSmall
                            )}
                            size="small"
                          >
                            Mission Statements
                          </Fab>
                        </Link> */}
                        {/* &nbsp;&nbsp;<span className={classes.newText}>New</span> */}
                        {/* <Link to="/agent/clienttestimonials">
                          <Fab
                            variant="outlined"
                            color="default"
                            aria-label="add"
                            //onClick={this.handleOpen}
                            className={classNames(
                              classes.whiteBorderButton,
                              classes.whiteBorderButtonSmall
                            )}
                            size="small"
                          >
                            Add Testimonials
                          </Fab>
                        </Link> */}
                        <Link to="pma?step=1&amp;openNewMailingHODialog=1">
                          {/* <Fab
                            variant="outlined"
                            color="default"
                            aria-label="add"
                            //onClick={this.handleOpen}
                            className={classNames(
                              classes.whiteBorderButton,
                              classes.whiteBorderButtonSmall
                            )}
                            size="small"
                          >
                            Edit Designations
                          </Fab> */}
                          {/* <span className={classes.newText} style={{ position: 'absolute', bottom: (isValidROICounty ? (availableCountyAreas > 0 ? '30%' : '31.5%') : '34%'), right: '25%' }}>New</span> */}
                          {/* <Fab
                            variant="outlined"
                            color="default"
                            aria-label="add"
                            //onClick={this.handleOpen}
                            className={classNames(
                              classes.whiteBorderButton,
                              classes.whiteBorderButtonSmall,
                              classes.flashButton
                            )}
                            size="small"
                          >
                            You have 10 New Homeowners
                          </Fab> */}
                        </Link>
                        {/* <Link to="/agent/myadvantages">
                          <Fab
                            variant="outlined"
                            color="default"
                            aria-label="add"
                            //onClick={this.handleOpen}
                            className={classNames(classes.whiteBorderButton, classes.whiteBorderButtonSmall)}
                            size="small"
                          >
                            Edit Neighborhood Advantages
        </Fab>
                        </Link> */}
                      </Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </WebsiteLayout>
      </DocumentTitle>
    );
  }
}

function mapStateToProps(state) {
  return {
    availableCountyAreas: state.home.availableCountyAreas,
  };
}
//dispatch({
//   type: homeConstants.GET_SALE_PRICE_FOR_CITIES,
//   payload: res.data
// });
function mapDispatchToProps(dispatch) {
  return {
    updateAvailableAreaCount: (actionObject) => dispatch(actionObject),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withStyles(styles), withRouter)(HomeScreen));
