import React, { Component, Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Input,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from "@material-ui/core";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "views/Pma/PmaEditor/TableQuadrants/table-quadrants-editor.scss";
import { withSnackbar } from "notistack";
import classNames from "classnames";
import ClearIcon from "@material-ui/icons/Clear";
import { showSuccessNotification } from "common/helpers";
import { showErrorNotification } from "common/helpers";
import { AddUpdatePmaAdmin } from "services/agent";

import { propertyUtil } from "views/Pma/PropertyUtil";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditQuadrantModal = (props) => {
  const {
    oldQuadrantData,
    handleInputChange,
    editQuadrantFormData,
    onCloseFn,
    modalType,
    areaID,
    onEditFormSubmit,
    selectedQuadrantNumber,
    handleRestoreDefault,
    titleEdit,
    maxMainTitleLength,
    mlsAreas,
    maxMainSubtitleLength,
    savingMessageShowing,
  } = props;
  const transformInput = (str) => {
    let capital = str.charAt(0).toUpperCase() + str.slice(1);
    return capital;
  };
  // const { }

  if (titleEdit) {
    const { quadAreaSubTitle, quadAreaTitle } = editQuadrantFormData;

    let overMaxMainTitleLength =
      quadAreaTitle &&
      quadAreaTitle.length &&
      quadAreaTitle.length >= maxMainTitleLength;

    let overMaxMainSubtitleLength =
      quadAreaSubTitle &&
      quadAreaSubTitle.length &&
      quadAreaSubTitle.length >= maxMainSubtitleLength;
    return (
      <Fragment>
        <div className="edit-quadrant-container">
          <div className="edit-quadrant-form new-props">
            <div className="edit-quadrant-form-title-container">
              <p className="edit-quadrant-form-title-text">
                You Can Make Changes to the Title and Subtitle Here
              </p>
            </div>

            <div className="edit-quadrant-form-body">
              <div className="edit-form-body-col">
                <div className="edit-form-thead">
                  <div>
                    <p>Title </p>
                  </div>
                </div>
                <div className="edit-form-input main-title-input">
                  <div className="address-input-col">
                    <input
                      type="text"
                      id={`main-title`}
                      name={`quadAreaTitle`}
                      className="title-input"
                      value={quadAreaTitle || ""}
                      onChange={handleInputChange}
                      placeholder={quadAreaTitle}
                      maxLength={maxMainTitleLength}
                    ></input>
                    <label
                      htmlFor="quadrantsTitle"
                      className={
                        overMaxMainTitleLength ? "character-count-error" : ""
                      }
                    >
                      Character count:{" "}
                      {quadAreaTitle && quadAreaTitle.length
                        ? quadAreaTitle.length
                        : "0"}
                      /{maxMainTitleLength}
                    </label>
                  </div>
                </div>
              </div>

              <div className="edit-form-body-col">
                <div className="edit-form-thead subtitle-thead">
                  <div>
                    <p>Subtitle</p>
                  </div>
                </div>
                <div className="edit-form-input subtitle-input">
                  <div className="address-input-col">
                    <input
                      type="text"
                      id={`quadAreaSubTitle`}
                      name={`quadAreaSubTitle`}
                      className="title-input"
                      value={transformInput(quadAreaSubTitle) || ""}
                      onChange={handleInputChange}
                      placeholder={quadAreaSubTitle}
                      maxLength={maxMainSubtitleLength}
                    ></input>
                    <label
                      htmlFor="quadAreaSubTitle"
                      className={
                        overMaxMainSubtitleLength ? "character-count-error" : ""
                      }
                    >
                      Character count:{" "}
                      {quadAreaSubTitle && quadAreaSubTitle.length
                        ? quadAreaSubTitle.length
                        : "0"}
                      /{maxMainSubtitleLength}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="edit-form-button-container">
            <div className="button-col">
              <div className="edit-form-cancel-button" onClick={onCloseFn}>
                <p>Close</p>
              </div>
            </div>
            <div className="button-col">
              <div
                className="edit-form-submit-button"
                onClick={onEditFormSubmit}
              >
                <p>Save and Close</p>
              </div>
            </div>
          </div>
          {savingMessageShowing && (
            <div className="saving-message">Your work has been saved!</div>
          )}
        </div>
      </Fragment>
    );
  } else {
    let quadrantTitle =
      editQuadrantFormData[`quad${selectedQuadrantNumber}Title`];
    let quadrantText =
      editQuadrantFormData[`quad${selectedQuadrantNumber}Text`];

    // let quadrantText = editQuadrantFormData[selectedQuadrantNumber].text;
    console.log("quadrantText", quadrantText);
    let maxQuadrantTextLength = 180;
    let overMaxQuadrantTextLength =
      quadrantText &&
      quadrantText.length &&
      quadrantText.length >= maxQuadrantTextLength;

    let maxQuadrantTitleLength = 25;
    let overMaxQuadrantTitleLength =
      quadrantTitle &&
      quadrantTitle.length &&
      quadrantTitle.length >= maxQuadrantTitleLength;

    return (
      <Fragment>
        <div className="edit-quadrant-container">
          <div className="edit-quadrant-form new-props">
            <div className="edit-quadrant-form-title-container">
              <p className="edit-quadrant-form-title-text">
                You Can Make Changes to the Title and Text Here
              </p>
            </div>
            <div className="edit-quadrant-form-body row-body">
              <div className="edit-form-body-col">
                <div className="edit-form-thead">
                  <div>
                    <p>Title</p>
                  </div>
                </div>
                <div className="edit-form-input">
                  <div className="address-input-col">
                    <input
                      type="text"
                      id={`quad${selectedQuadrantNumber}Title`}
                      name={`quad${selectedQuadrantNumber}Title`}
                      className="title-input"
                      value={quadrantTitle || ""}
                      onChange={handleInputChange}
                      placeholder={quadrantTitle}
                      maxLength={maxQuadrantTitleLength}
                    ></input>
                    <label
                      htmlFor={`quad${selectedQuadrantNumber}Title`}
                      className={
                        overMaxQuadrantTitleLength
                          ? "character-count-error"
                          : ""
                      }
                    >
                      Character count:{" "}
                      {quadrantTitle && quadrantTitle.length
                        ? quadrantTitle.length
                        : "0"}
                      /{maxQuadrantTitleLength}
                    </label>
                  </div>
                </div>
              </div>

              <div className="edit-form-body-col">
                <div className="edit-form-thead">
                  <div>
                    <p>Text</p>
                  </div>
                </div>
                <div className="edit-form-input">
                  <div className="address-input-col">
                    <textarea
                      placeholder={quadrantText}
                      onChange={handleInputChange}
                      name={`quad${selectedQuadrantNumber}Text`}
                      value={quadrantText || ""}
                      className="quadrant-textarea"
                      maxLength={maxQuadrantTextLength}
                    ></textarea>

                    <label
                      htmlFor={`quad${selectedQuadrantNumber}Text`}
                      className={
                        overMaxQuadrantTextLength ? "character-count-error" : ""
                      }
                    >
                      Character count:{" "}
                      {quadrantText && quadrantText.length
                        ? quadrantText.length
                        : "0"}
                      /{maxQuadrantTextLength}
                    </label>
                    {/*<input
                    type="textbox"
                    id={`text-${selectedQuadrantNumber}`}
                    name={`text-${selectedQuadrantNumber}`}
                    value={
                      editQuadrantFormData[selectedQuadrantNumber].text || ""
                    }
                    onChange={handleInputChange}
                    placeholder={(e) =>
                      editQuadrantFormData[selectedQuadrantNumber].text(
                        e,
                        "text"
                      )
                    }
                  ></input>*/}
                    {/*<input
                    type="textbox"
                    id={`text-${selectedQuadrantNumber}`}
                    name={`text-${selectedQuadrantNumber}`}
                    value={
                      editQuadrantFormData[selectedQuadrantNumber].text || ""
                    }
                    onChange={handleInputChange}
                    placeholder={(e) =>
                      editQuadrantFormData[selectedQuadrantNumber].text(
                        e,
                        "text"
                      )
                    }
                  ></input>*/}
                    {/*<input
                    type="textbox"
                    id={`text-${selectedQuadrantNumber}`}
                    name={`text-${selectedQuadrantNumber}`}
                    value={
                      editQuadrantFormData[selectedQuadrantNumber].text || ""
                    }
                    onChange={handleInputChange}
                    placeholder={(e) =>
                      editQuadrantFormData[selectedQuadrantNumber].text(
                        e,
                        "text"
                      )
                    }
                  ></input>*/}
                    {/*<input
                    type="textbox"
                    id={`text-${selectedQuadrantNumber}`}
                    name={`text-${selectedQuadrantNumber}`}
                    value={
                      editQuadrantFormData[selectedQuadrantNumber].text || ""
                    }
                    onChange={handleInputChange}
                    placeholder={(e) =>
                      editQuadrantFormData[selectedQuadrantNumber].text(
                        e,
                        "text"
                      )
                    }
                  ></input>*/}
                    {/*<input
                    type="textbox"
                    id={`text-${selectedQuadrantNumber}`}
                    name={`text-${selectedQuadrantNumber}`}
                    value={
                      editQuadrantFormData[selectedQuadrantNumber].text || ""
                    }
                    onChange={handleInputChange}
                    placeholder={(e) =>
                      editQuadrantFormData[selectedQuadrantNumber].text(
                        e,
                        "text"
                      )
                    }
                  ></input>*/}
                  </div>
                </div>
                {/*<div className="edit-form-body-col">
                <textarea
                  // name="textarea"
                  style={{ width: "250px", height: "150px" }}
                  placeholder={
                    editQuadrantFormData[selectedQuadrantNumber].text
                  }
                  onChange={handleInputChange}
                  name={`text-${selectedQuadrantNumber}`}
                  value={
                    editQuadrantFormData[selectedQuadrantNumber].text || ""
                  }
                  className="quadrant-textarea"
                ></textarea>
              </div>*/}
              </div>
            </div>
          </div>

          <div className="edit-form-button-container">
            <div className="button-col">
              <div className="edit-form-cancel-button" onClick={onCloseFn}>
                <p>Close</p>
              </div>
              {/*<div className="restore-default" onClick={handleRestoreDefault}>
                <p>Restore Default</p>
              </div>*/}
            </div>
            <div className="button-col">
              <div
                className="edit-form-submit-button"
                onClick={onEditFormSubmit}
              >
                <p>Save</p>
              </div>
            </div>
          </div>
          {savingMessageShowing && (
            <div className="saving-message">Your work has been saved!</div>
          )}
        </div>
        {/*</Dialog>*/}
      </Fragment>
    );
  }
};

export default compose(withSnackbar, withStyles(styles))(EditQuadrantModal);
