import React, { Component } from "react";

import QVFourMissionStatementModal from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourMissionStatementModal";
import { pmaActions } from "_store/_actions";
import { connect } from "react-redux";
import { propertyUtil } from "views/Pma/PropertyUtil";

class QVFourMissionStatement extends Component {
  state = {
    editQuadrantFormData: null,
    oldQuadrantData: [],
    selectedBubbleNumber: null,
    titleEdit: false,
    maxMainTitleLength: 17,
    addAreasModalOpen: false,
    filteredAreas: {},
    savingMessageShowing: false,
  };

  handleFormClose = () => {
    this.props.updateMSModalOpen(false);
    this.setState({
      titleEdit: false,
      addAreasModalOpen: false,
      savingMessageShowing: false,
    });
  };
  onEditFormSubmit = () => {
    const { editQuadrantFormData, oldQuadrantData } = this.state;
    const { missionStatement } = this.props.missionStatement;
    const {
      missionStatementContent,
      namePreferred,
    } = this.props.missionStatement;
    oldQuadrantData = editQuadrantFormData;
    this.props.setMissionStatementDetails(oldQuadrantData);
    this.setState({
      savingMessageShowing: true,
    });
    setTimeout(() => {
      this.setState({ savingMessageShowing: false });
      this.props.updateMSModalOpen(false);
    }, 500);
  };
  openSelectedBubble = (bubbleNumber, titleEdit) => {
    console.log("inside open seleced bubble", bubbleNumber, titleEdit);
    this.props.updateMSModalOpen(true);
    this.setState({
      selectedBubbleNumber: bubbleNumber,
      titleEdit: titleEdit,
      savingMessageShowing: false,
    });
  };

  componentDidMount = () => {
    const {
      missionStatementContent,
      namePreferred,
    } = this.props.missionStatement;
    let newObj = { missionStatementContent, namePreferred };
    this.setState({
      oldQuadrantData: { missionStatementContent, namePreferred },
      editQuadrantFormData: { missionStatementContent, namePreferred },
    });
    console.log("after component did mount", this.state);
  };

  handleInputChange = (e) => {
    const { editQuadrantFormData, oldQuadrantData, titleEdit } = this.state;
    const { missionStatement } = this.props.missionStatement;
    const {
      missionStatementContent,
      namePreferred,
    } = this.props.missionStatement;
    const target = e.target;
    const value = target.value;
    const name = target.name;
    let clonedEditQuadrantFormData = Object.assign(editQuadrantFormData, {
      [name]: value,
    });
    this.setState({
      editQuadrantFormData: clonedEditQuadrantFormData,
    });
  };

  render() {
    const {
      openSelectedBubble,
      handleInputChange,
      handleRestoreDefault,
      applyToAllAreas,
    } = this;
    const {
      editQuadrantFormData,
      oldQuadrantData,
      selectedBubbleNumber,
      titleEdit,
      addAreasModalOpen,
      maxMainTitleLength,
      savingMessageShowing,
    } = this.state;
    console.log("editQuadrantFormData", editQuadrantFormData);
    console.log("oldQuadrantData", oldQuadrantData);
    const {
      agentData,
      printVersion,
      missionStatementModalOpen,
      mlsAreas,
      missionStatement,
    } = this.props;
    const { missionStatementContent, namePreferred } = missionStatement;
    let showButtons = false;
    if (editQuadrantFormData && Object.keys(editQuadrantFormData).length >= 1) {
      let {
        missionStatementContent,
        namePreferred,
        mktValAreaID,
        mktValAreaTitle,
        mktValBox1Text,
        mktValBox1Title,
      } = editQuadrantFormData;

      return (
        <>
          {missionStatement &&
            Object.keys(missionStatement.missionStatementContent).length >= 1 &&
            missionStatementModalOpen && (
              <QVFourMissionStatementModal
                oldQuadrantData={oldQuadrantData}
                onCloseFn={this.handleFormClose}
                handleInputChange={handleInputChange}
                editQuadrantFormData={editQuadrantFormData}
                onEditFormSubmit={this.onEditFormSubmit}
                selectedBubbleNumber={selectedBubbleNumber}
                handleRestoreDefault={handleRestoreDefault}
                titleEdit={titleEdit}
                mlsAreas={mlsAreas}
                maxMainTitleLength={maxMainTitleLength}
                savingMessageShowing={savingMessageShowing}
                //missionStatementTitle={missionStatementTitle}
                missionStatementContent={missionStatementContent}
                namePreferred={namePreferred}
              />
            )}

          <div className="qv4-mission-statement">
            <div className="qv4-mission-statement-title-container">
              <div className="qv4-mission-statement-title-text">
                Meet Kyle & David
              </div>
            </div>
            <div
              className="qv4-mission-statement-content-container"
              // onClick={() => openSelectedBubble(1)}
            >
              {printVersion && showButtons && (
                <>
                  <div
                    className="mission-statement-btn mission-statement-title-btn"
                    onClick={() => openSelectedBubble(null, true)}
                  >
                    <div>Edit Title</div>
                  </div>
                </>
              )}
              {printVersion && showButtons && (
                <div
                  className="mission-statement-btn mission-statement-btn-1"
                  onClick={() => openSelectedBubble(1)}
                >
                  <div>Edit Mission</div>
                </div>
              )}

              <div
                className="qv4-mission-statement-content-text"
                dangerouslySetInnerHTML={{ __html: missionStatementContent }}
              ></div>
              <div className="qv4-mission-statement-signoff">
                {namePreferred}
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return <div>Please contact us to upload information</div>;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    missionStatementModalOpen: state.pma.missionStatementModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMSModalOpen: (isModalOpen) => {
      dispatch(pmaActions.updateMissionStatmentModalOpen(isModalOpen));
    },

    setMissionStatementDetails: (missionStatementDetails) => {
      dispatch(pmaActions.setMissionStatementDetails(missionStatementDetails));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QVFourMissionStatement);
