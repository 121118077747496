import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';


const useStyles = makeStyles({
  checkboxIcon: {
    width: '16px', 
    height: '16px',
    border: '1px solid #A9A19D',
    textAlign: 'center',
    verticalAlign: 'middle',
    cursor: 'pointer',
    position: 'relative',
    background: 'white'
  },
  checkboxImage: {
    width: '150%', 
    height: '100%',
    display: 'block',
    opacity: 0
  },
  checkedCheckboxIcon: {
    border: '1px solid #A9A19D',
  },
  checkedCheckboxImage: {
    opacity: 1,
  },
});

function MailProCheckbox({label, classes, checked, onChange}) {
  const styleClasses = useStyles();

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          classes={classes}
          icon={
            <span className={styleClasses.checkboxIcon}>
              {/* You can add an image here */}
              <img
                src={require("./assets/check_mark.png")}
                alt="Checkbox Image"
                className={styleClasses.checkboxImage}
              />
            </span>
          }
          checkedIcon={
            <span className={`${styleClasses.checkboxIcon} ${styleClasses.checkedCheckboxIcon}`}>
              <img
                src={require("./assets/check_mark.png")}
                alt="Checkbox Image"
                className={`${styleClasses.checkboxImage} ${styleClasses.checkedCheckboxImage}`}
              />
            </span>
          }
        />
      }
      label={label || <span></span>}
    />
  );
}

//define proptypes
MailProCheckbox.propTypes = {
  label: PropTypes.node,
  classes: PropTypes.object,
  checked: PropTypes.bool,
  handleCheckboxChange: PropTypes.func,
};

export default MailProCheckbox;
