import { red } from '@material-ui/core/colors';
export default theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        //padding: theme.spacing.unit * 3,
        marginTop: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    grid: {
        height: '100%'
    },

    App: {
        textAlign: "center"
    },

    topBox: {
        backgroundColor: "#012159",
        height: 'auto',
        minHeight: '100vh',
        paddingBottom: theme.spacing(6)
    },
    section: {
        maxWidth: "1100px",
        paddingTop: "100px",
        margin: "auto",
        textAlign: "center",
    },
    clear: {
        clear: "both"
    },
    margin4UnitBottom: {
        marginBottom: theme.spacing(4),
    },
    whiteColor: {
        color: theme.palette.common.white
    },
    textAlignCenter: {
        textAlign: "center"
    },
    fontWeigth500: {
        fontWeight: 500
    },
    field: {
        margin: theme.spacing(3)
    },
    textField: {
        width: '100%',
        maxWidth: '100%',
        marginRight: theme.spacing(3)
    },
    fieldError: {
        color: theme.palette.error.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    bottomBox: {
        //paddingBottom: "150px"
    },
    eachQandA: {
        textAlign: "left",
        padding: theme.spacing(3)
    },
    afTitle: {
        color: theme.palette.common.white,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        '&>span': {
            fontWeight: "normal"
        }
    },
    dropdownTopMargin: {
        marginTop: -theme.spacing(5)
    },
    dropdownTopMargin6Unit: {
        marginTop: -theme.spacing(5.5)
    },
    dropdownTopMargin2Unit: {
        marginTop: -theme.spacing(3)
    },
    middleBox: {
        minHeight: '32vh',
        width: '100%',
        backgroundImage: 'url(./images/curve_lines.png)',
        backgroundColor: '#EEEEEE',
        display: 'flex',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        marginTop: theme.spacing(0),
        alignItems: 'center',
        justifyContent: 'center',
    },
    plusColColor: {
        color: '#012159'
    },
    iconLarge: {
        width: '50px',
        height: '50px',
        color: '#112657'
    },
    portletFooter: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    footerButtons: {
        position: 'relative',
        display: 'flex'
    },
    footerButtonsCenter: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    footerButtonsLeftRight: {
        flex: 1,
        textAlign: "left"
    },
    hiddenGrid: {
        display: 'none'
    },
    closeModalBtn: {
        margin: theme.spacing(-1.5, -2.5, 1, 2),
        cursor: 'pointer',
    },
    closeredButton: {
        minWidth: theme.spacing(2.5),
        zIndex: 1,
        '&:hover': {
            backgroundColor: red[900],
            color: 'white',
        }
    },
    redBg900: {
        backgroundColor: red[900]
    },
    redBg500: {
        backgroundColor: red[500],
    },
    redBgHover500: {
        '&:hover': {
            backgroundColor: red[500],
        }
    },
});