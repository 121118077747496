import { white, black } from "../common/colors";
import green from "@material-ui/core/colors/green";

export default {
  common: {
    black,
    white,
    neutral: "#E4E7EB",
    muted: "#9EA0A4",
  },
  primary: {
    contrastText: white,
    main: "#012159", //#0767DB
    neutral: "#004fac",
    light: "#F6F9FD",
    darkLight: "#B9CCE1",
    mediumLight: "#1aa7ff",
    dark: "#0B48A0",
    darker: "#00225a",
    intense: '#000a1a',
    currentLightBlue: '#3d85c6',
    currentMailProColor: '#4A7396'
  },
  secondary: {
    contrastText: white,
    main: "#7d58ff",
    light: "#f2ebf7",
    dark: "#37248F",
  },
  info: {
    contrastText: white,
    main: "#1070CA",
    mediumLight: "#51A8F5",
    light: "#F1FBFC",
    dark: "#007489",
  },
  warning: {
    contrastText: white,
    main: "#FFB822",
    light: "#FDF8F3",
    dark: "#95591E",
  },
  error: {
    contrastText: white,
    main: "#ED4740",
    medium: "#fcc5c9",
    light: "#FEF6F6",
    dark: "#BF0E08",
    darker: "#8b0000",
  },
  text: {
    primary: "#12161B",
    secondary: "#66788A",
    disabled: "#A6B1BB",
    green: "#45B880",
  },
  success: {
    contrastText: white,
    main: green["500"],
    light: "#F1FAF5",
    dark: "#00783E",
  },
  latestLightGreen: {
    main: "#ecfbfb",
    medium: "#d6eeec",
    dark: "#85cdc5",
  },
  background: {
    default: "#f8fafc",
    dark: "#172B4D",
    paper: white,
  },
  red: {
    main: "#CC0000",
  },
  border: "#DFE3E8",
  divider: "#DFE3E8",
  lg: {
    darkblue: "rgb(0, 34, 90)",
  },
  agentRoi: {
    darkBlue: "#00305e",
    darkGray: "#3C4C55",
    mediumBlue: "#4A7396",
    tableDivider: "#D4D4D5",
  },
  propDetails: {
    mainGray: "#646565",
    mainBlue: "#00305E",
    mainRed: "#A31b1f",
    lightBlue: "#4A7396",
  },
  button: {
    main: "#4e85af",
  },
  black: {
    jetBlack: '#343434'
  }
};
