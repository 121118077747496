export const pmaActionConstants = {
  IS_LOADING: "IS_LOADING",
  SWITCH_VERSION: "SWITCH_VERSION",
  SWITCH_STEP: "SWITCH_STEP",
  SWITCH_AREA: "SWITCH_AREA",
  SWITCH_AREA_WITH_STEP: "SWITCH_AREA_WITH_STEP",
  SWITCH_CL_VERSION: "SWITCH_CL_VERSION",
  UPDATE_CURRENT_AREA_DATA: "UPDATE_CURRENT_AREA_DATA",
  GET_PMA_DATA: "GET_PMA_DATA",
  GET_ALL_PROPERTIES: "GET_ALL_PROPERTIES",
  GET_MLS_AREA_DATA: "GET_MLS_AREA_DATA",

  TOGGLE_LISTING_CHECK: "TOGGLE_LISTING_CHECK",

  SET_CURRENT_RECORD: "SET_CURRENT_RECORD",
  SET_TAGS: "SET_TAGS",
  SET_BLOCKED_REASON: "SET_BLOCKED_REASON",
  UPDATE_HO_SEARCH: "UPDATE_HO_SEARCH",
  UPDATE_HO_FILTER: "UPDATE_HO_FILTER",
  UPDATE_HO_ADVANCE_FILTER: "UPDATE_HO_ADVANCE_FILTER",
  UPDATE_HO_ADVANCE_FILTER_OPTION_DATA: "UPDATE_HO_ADVANCE_FILTER_OPTION_DATA",
  UPDATE_HO_QUICK_FILTER: "UPDATE_HO_QUICK_FILTER",
  RESET_MAILPRO_HEADER_SORT_ONLY: "RESET_MAILPRO_HEADER_SORT_ONLY",
  //FOR ALL SORTING FROM THE TABLE HEADERS CLICK EVENTS
  TABLE_SORT: "TABLE_SORT",
  RESET_MAILPRO_HEADER_AND_ALL_FILTERS: "RESET_MAILPRO_HEADER_AND_ALL_FILTERS",
  ADD_PERSONAL_PS: "ADD_PERSONAL_PS",
  UPDATE_PS: "UPDATE_PS",
  UPDATE_NICKNAME: "UPDATE_NICKNAME",
  APPROVE_MAILING_LIST: "APPROVE_MAILING_LIST",
  POST_HOMEOWNERS_DATA: "POST_HOMEOWNERS_DATA",
  GET_MAILER_PRO_DETAILS_SUCCESS: "GET_MAILER_PRO_DETAILS_SUCCESS",
  GET_MAILER_PRO_DETAILS_FAILURE: "GET_MAILER_PRO_DETAILS_FAILURE",
  CHANGE_MAILPRO_STATUS: "CHANGE_MAILPRO_STATUS",

  UPDATE_HOMEOWNER_PROP: "UPDATE_HOMEOWNER_PROP",
  GET_CITY_AREA_MAP: "GET_CITY_AREA_MAP",
  GET_MAILPRO_ID_DETAILS: "GET_MAILPRO_ID_DETAILS",
  UPDATE_RECENT_UPDATED_MAILPRO_ID: "UPDATE_RECENT_UPDATED_MAILPRO_ID",
  GET_MARKET_ACTIVITY: "GET_MARKET_ACTIVITY",
  GET_NAVBAR_INFO: "GET_NAVBAR_INFO",
  UPDATE_NAVBAR: "UPDATE_NAVBAR",
  GET_MQ_CITY_DETAILS: "GET_MQ_CITY_DETAILS",
  GET_DASHBOARD_DONUT_API: "GET_DASHBOARD_DONUT_API",
  GET_DONUT_AND_TABLE: "GET_DONUT_AND_TABLE",
  GET_AGENT_QR: "GET_AGENT_QR",
  GET_MARKET_TRENDS_API: "GET_MARKET_TRENDS_API",
  GET_MQ2_CITY_DETAILS: "GET_MQ2_CITY_DETAILS",
  GET_APRIL_API: "GET_APRIL_API",
  GET_MAY_API: "GET_MAY_API",
  GET_SPEEDOMETER_DATA: "GET_SPEEDOMETER_DATA",
  GET_BUBBLE_BOX_DETAILS: "GET_BUBBLE_BOX_DETAILS",
  GET_PMA_DETAILS_API: "GET_PMA_DETAILS_API",
  GET_QUADRANT_DETAILS: "GET_QUADRANT_DETAILS",
  GET_DECEMBER_DATA: "GET_DECEMBER_DATA",
  GET_LOCAL_SELLING_AGENTS: "GET_LOCAL_SELLING_AGENTS",
  UPDATE_QUADRANT_MODAL: "UPDATE_QUADRANT_MODAL",
  UPDATE_CHECKBOX_PHOTO_MODAL: "UPDATE_CHECKBOX_PHOTO_MODAL",
  UPDATE_RECENT_ACTIVITY_DATA: "UPDATE_RECENT_ACTIVITY_DATA",
  UPDATE_MISSION_STATEMENT_MODAL: "UPDATE_MISSION_STATEMENT_MODAL",
  // SUBMIT_REGISTRATION: 'SUBMIT_REGISTRATION',
  /**local storage update */
  GET_QUARTER_AREA_TABLE: "GET_QUARTER_AREA_TABLE",
  GET_QUARTER_CITY_TABLE: "GET_QUARTER_CITY_TABLE",
  GET_PMA_COVER_PHOTO_DETAILS: "GET_PMA_COVER_PHOTO_DETAILS",
  UPDATE_LOCAL_STORAGE_FOR_REDUX: "UPDATE_LOCAL_STORAGE_FOR_REDUX",
  /**Next PMA print date */
  GET_NEXT_PMA_PRINT_DATE: "GET_NEXT_PMA_PRINT_DATE",
  GET_TESTIMONIALS: "GET_TESTIMONIALS",
  GET_AGENT_DESIGNATIONS: "GET_AGENT_DESIGNATIONS",
  GET_AGENT_ADVANTAGES: "GET_AGENT_ADVANTAGES",
  GET_SALE_PRICE_FOR_CITIES: "GET_SALE_PRICE_FOR_CITIES",
  GET_SAT: "GET_SAT",
  GET_DATA_VERIFICATION: "GET_DATA_VERIFICATION",
  GET_YEAR_END_DETAILS: "GET_YEAR_END_DETAILS",
  Q_AREA_AND_CITY_DETAILS: "Q_AREA_AND_CITY_DETAILS",
  GET_TABLE_PMA_API: "GET_TABLE_PMA_API",
  GET_TABLE_BUBBLE_DATA: "GET_TABLE_BUBBLE_DATA",
  GET_BUBBLE_PRICE_RANGE: "GET_BUBBLE_PRICE_RANGE",
  GET_MISSION_STATEMENT: "GET_MISSION_STATEMENT",
  CHANGE_PDF_PRINT_VERSION: "CHANGE_PDF_PRINT_VERSION",
  ADD_ROI_AREA_CITY_DATA_FOR_COUNTY: "ADD_ROI_AREA_CITY_DATA_FOR_COUNTY", //roi
  ADD_SELECTED_AREA: "ADD_SELECTED_AREA", //roi
  SET_ROI_ENLARGE_VIEW: "SET_ROI_ENLARGE_VIEW", //roi
  INIT_ROI_OPEN_ACCESS_VIEW: "INIT_ROI_OPEN_ACCESS_VIEW", //roi
  DELETE_SELECTED_AREA: "DELETE_SELECTED_AREA", //roi
  HANDLE_ROI_AREA_ALLOCATION_RESPONSE: "HANDLE_ROI_AREA_ALLOCATION_RESPONSE", //roi
  SET_ROI_AREA_INTERVEL_ID: "SET_ROI_AREA_INTERVEL_ID",
  GET_PROPERTY_PHOTO_DETAILS: "GET_PROPERTY_PHOTO_DETAILS",
  GET_RECENT_ACTIVITY_PHOTOS: "GET_RECENT_ACTIVITY_PHOTOS",
  //New Mailing
  NEW_MAILING_ADD_UPDATED_DATA: "NEW_MAILING_ADD_UPDATED_DATA",
  NEW_MAILING_MAILPRO_DATA: "NEW_MAILING_MAILPRO_DATA",
  SET_NEW_MAILPRO_INIT_DIALOG_MODE: "SET_NEW_MAILPRO_INIT_DIALOG_MODE",
  DISABLE_NEW_MAILING_FOR_CURRENT_SESSION:
    "DISABLE_NEW_MAILING_FOR_CURRENT_SESSIOn",
  EXPLICIT_ENABLE_NEW_MAILPRO_INIT_SCREEN:
    "EXPLICIT_ENABLE_NEW_MAILPRO_INIT_SCREEN",
  RESET_NEW_MAILPRO_INIT_DIALOG_DATA: "RESET_NEW_MAILPRO_INIT_DIALOG_DATA",
};

export const pmaSize = {
  width: 17,
  height: 11,
};

export const pmaInch = 100; // px
export const pmaPageSize = {
  width: pmaSize.width * pmaInch,
  height: pmaSize.height * pmaInch,
};

// The PMA is automatically generated and mailed out 9 times/year. Once at the beginning of each quarter (Jan, Apr, July, and Oct) Then each Mid-Quarter (Feb 15th, May 15th, Aug 15th, and Nov 15th) + there is a SPECIAL YEAR-END EDITION that is mailed in Mid December.

export const pmaConstants = {
  periods: [
    "First Midquarter",
    "First Quarter",
    "Second Midquarter",
    "Second Quarter",
    "Third Midquarter",
    "Third Quarter",
    "Fourth Midquarter",
    "Fourth Quarter",
    "Year-End",
  ],

  versions: ["4-Page", "6-Page"],

  steps: ["Editor", "MailPro", "Approval"],
  // steps: {
  //   editor: 0,
  //   mailpro: 1,
  //   approval: 2,
  // },

  areaStatuses: ["Unapproved", "Approved"],

  pricePerMailing: [1.7, 2.2],

  pmaEditSections: {
    attentionGrabberOrPS: {
      top: 1.15 * pmaInch,
      left: 0.5 * pmaInch,
      width: 3.25 * pmaInch,
      height: 1.625 * pmaInch,
      page: "outside",
    },
    topImage: [
      {
        top: 0.2 * pmaInch,
        left: 9.75 * pmaInch,
        width: 1 * pmaInch,
        height: 1 * pmaInch,
        page: "outside",
      },
      {
        top: 0.2 * pmaInch,
        left: 11.5 * pmaInch,
        width: 1 * pmaInch,
        height: 1 * pmaInch,
        page: "outside",
      },
    ],
    listingsAndSales: {
      top: 2.75 * pmaInch,
      left: pmaPageSize.width + 0.25 * pmaInch,
      width: 7.125 * pmaInch,
      height: 9.125 * pmaInch,
      page: "inside",
    },
    coverLetter: {
      top: 1.25 * pmaInch,
      left: 6.5 * pmaInch,
      width: 4.5 * pmaInch,
      height: 7 * pmaInch,
      page: "outside",
    },
  },

  pmaStaticSections: {
    hoAddress: {
      type: "text",
      agentSpecific: true,
      coordinates: [
        {
          top: 1.75 * pmaInch,
          left: 3.75 * pmaInch,
        },
      ],
      fontAttributes: {
        size: 14,
        color: "black",
        family: "Lato",
      },
      page: "outside",
    },
    pinkBox: [
      {
        top: 6.4 * pmaInch,
        left: 0.25 * pmaInch,
        width: 5.4 * pmaInch,
        height: 1 * pmaInch,
        page: "inside",
      },
      {
        top: 8.25 * pmaInch,
        left: 0.75 * pmaInch,
        width: 5.4 * pmaInch,
        height: 1 * pmaInch,
        page: "inside",
      },
    ],
    agentLogo: {
      type: "image",
      agentSpecific: true,
      coordinates: [
        {
          top: 0.25 * pmaInch,
          left: 0.5 * pmaInch,
        },
        {
          // position: 'absolute',
          top: 0.25 * pmaInch,
          left: 6.5 * pmaInch,
        },
        //midquarter
        {
          top: 0.25 * pmaInch,
          left: 7.25 * pmaInch,
        },
      ],
      size: {
        width: 2 * pmaInch,
        height: 0.75 * pmaInch,
      },
      page: "outside",
    },
    returnAddressLine1: {
      type: "text",
      agentSpecific: true,
      coordinates: [
        {
          top: 0.35 * pmaInch,

          left: 3 * pmaInch,
        },
      ],
      fontAttributes: {
        size: 14,
        color: "black",
      },
      page: "outside",
    },
    returnAddressLine2: {
      type: "text",
      agentSpecific: true,
      coordinates: [
        {
          top: 1.0625 * pmaInch,
          left: 3.25 * pmaInch,
        },
      ],
      fontAttributes: {
        size: 14,
        color: "black",
        family: "Lato",
      },
      page: "outside",
    },
    postage: {
      type: "image",
      agentSpecific: false,
      src: "#",
      coordinates: [
        {
          top: 0.25 * pmaInch,
          left: 5 * pmaInch,
        },
      ],
      size: {
        width: 0.75 * pmaInch,
        height: 0.75 * pmaInch,
      },
      page: "outside",
    },
    mailingAddressLine1: {
      type: "text",
      agentSpecific: true,
      coordinates: [
        {
          top: 0.275 * pmaInch,
          left: 8.75 * pmaInch,
        },
        //midquarter
        {
          top: 0.275 * pmaInch,
          left: 9.5 * pmaInch,
        },
      ],
      fontAttributes: {
        size: 12,
        color: "black",
        family: "Lato",
      },
      page: "outside",
    },
    mailingAddressLine2: {
      type: "text",
      agentSpecific: true,
      coordinates: [
        {
          top: 1.0625 * pmaInch,
          left: pmaPageSize.width + 3.25 * pmaInch,
        },
      ],
      fontAttributes: {
        size: 12,
        color: "black",
        family: "Lato",
      },
      page: "outside",
    },
    agentPhoneNumber: {
      type: "text",
      agentSpecific: true,
      coordinates: [
        {
          top: 1.25 * pmaInch,
          left: pmaPageSize.width + 3.25 * pmaInch,
        },
      ],
      fontAttributes: {
        size: 15,
        color: "black",
        family: "Lato",
      },
      page: "outside",
    },
    cityHighlightsHeader: {
      type: "component",
      coordinates: [
        {
          top: 2.25 * pmaInch,
          left: pmaPageSize.width + 1.25 * pmaInch,
        },
      ],
      size: {
        width: 6.5 * pmaInch,
        height: 0.75 * pmaInch,
      },
      page: "outside",
    },
    areaHighlightsHeader: {
      type: "component",
      coordinates: [
        {
          top: 5.55 * pmaInch,
          left: pmaPageSize.width + 1.25 * pmaInch,
        },
      ],
      size: {
        width: 6.5 * pmaInch,
        height: 0.75 * pmaInch,
      },
      page: "outside",
    },
    //1st page, bottom right in gray
    contactInformation: [
      {
        type: "component",
        coordinates: [
          {
            top: 7.1 * pmaInch,
            left: pmaPageSize.width * 0.39,
          },
        ],
        size: {
          width: 4.5 * pmaInch,
          height: 0.25 * pmaInch,
        },
        page: "outside",
      },
      //midquarter
      {
        type: "component",
        coordinates: [
          {
            top: 9.15 * pmaInch,
            left: pmaPageSize.width * 0.45,
          },
        ],
        size: {
          width: 5 * pmaInch,
          height: 0.25 * pmaInch,
        },
        page: "outside",
      },
    ],
    frontGraph: {
      type: "component",
      coordinates: [
        {
          top: 2.88 * pmaInch,
          left: 0.25 * pmaInch,
        },
      ],
      size: {
        width: pmaPageSize.width * 0.32,
        height: 4.25 * pmaInch,
      },
      page: "outside",
    },
    frontBar: {
      type: "component",
      coordinates: [
        {
          top: 8.875 * pmaInch,
          left: 0.25 * pmaInch,
        },
      ],
      size: {
        width: 8 * pmaInch,
        height: 1.625 * pmaInch,
      },
      page: "outside",
    },

    cityHighlights: {
      type: "component",
      coordinates: [
        {
          top: 2.825 * pmaInch,
          left: pmaPageSize.width,
        },
      ],
      size: {
        width: 6.5 * pmaInch,
        height: 2.5 * pmaInch,
      },
      page: "outside",
    },
    areaHighlights: {
      type: "component",
      coordinates: [
        {
          top: 6.125 * pmaInch,
          left: pmaPageSize.width + 1.25 * pmaInch,
        },
      ],
      size: {
        width: 6.5 * pmaInch,
        height: 2.5 * pmaInch,
      },
      page: "outside",
    },
    callToAction: [
      {
        type: "component",
        coordinates: [
          {
            top: 5.25 * pmaInch,
            left: 6.25 * pmaInch,
          },
        ],
        size: {
          width: 5.5 * pmaInch,
          height: 1.625 * pmaInch,
        },
        page: "outside",
      },
      //midquarter
      {
        type: "component",
        coordinates: [
          {
            top: 7.25 * pmaInch,
            left: 7.25 * pmaInch,
          },
        ],
        size: {
          width: 6 * pmaInch,
          height: 1.625 * pmaInch,
        },
        page: "outside",
      },
    ],

    insideBorder: {
      type: "component",
      coordinates: [
        {
          top: 0,
          left: 0,
        },
      ],
      size: {
        width: 2 * pmaPageSize.width,
        height: 11 * pmaInch,
      },
      page: "inside",
    },
    asOf: {
      type: "component",
      coordinates: [
        {
          top: 1.125 * pmaInch,
          left: 7.75 * pmaInch,
        },
      ],
      size: {
        width: 2 * pmaInch,
        height: 0.5 * pmaInch,
      },
      page: "inside",
    },
    insideHeader: {
      type: "component",
      coordinates: [
        {
          top: 1.5 * pmaInch,
          left: 0.5 * pmaInch,
        },
      ],
      size: {
        width: 16 * pmaInch,
        height: 1 * pmaInch,
      },
      page: "inside",
    },
    insideTable: {
      type: "component",
      coordinates: [
        {
          top: 2.75 * pmaInch,
          left: 0.75 * pmaInch,
        },
      ],
      size: {
        width: 7.25 * pmaInch,
        height: 4.25 * pmaInch,
      },
      page: "inside",
    },
    insideGraph: {
      type: "component",
      coordinates: [
        {
          top: 7 * pmaInch,
          left: 0.75 * pmaInch,
        },
      ],
      size: {
        width: 7.25 * pmaInch,
        height: 2.5 * pmaInch,
      },
      page: "inside",
    },
    insideDivider: {
      type: "component",
      coordinates: [
        {
          top: 2 * pmaInch,
          left: 8.25 * pmaInch,
        },
      ],
      size: {
        width: 0.25 * pmaInch,
        height: 9 * pmaInch,
      },
      page: "inside",
    },
    LASDisclaimer: {
      type: "text",
      agentSpecific: false,
      coordinates: [
        {
          top: 10.825 * pmaInch,
          left: pmaPageSize.width + 0.5 * pmaInch,
        },
      ],
      fontAttributes: {
        size: 12,
        color: "black",
        family: "Ubuntu",
        src: `Unless otherwise indicated, listings and sales shown above are other agents' than`,
      },
      size: {
        width: 10 * pmaInch,
        height: 0.25 * pmaInch,
      },
      page: "inside",
    },
    insideBar: {
      type: "component",
      coordinates: [
        {
          top: 9.5 * pmaInch,
          left: 0.5 * pmaInch,
        },
      ],
      size: {
        width: 7.75 * pmaInch,
        height: 1.5 * pmaInch,
      },
      page: "inside",
    },
  },
};
