export default theme => ({
  root: {
    width: 'auto',
    minHeight: '80vh',
    height: 'auto',
    padding: theme.spacing(3),
    marginTop: theme.spacing(9)
  },
  content: {
    marginTop: theme.spacing(4),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  footerButtons: {
    position: 'relative',
    display: 'flex',
  },
  footerButtonsCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerButtonsLeftRight: {
    flex: 1,
  },
  invalidRequest: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fieldError: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  successGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
  },
  fieldSuccess: {
    color: theme.palette.success.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  fieldSuccessBody: {
    textAlign: 'left',
  },
  successGridActions: {
    // padding: theme.spacing(1),
    // '&>button': {
    //   marginRight: theme.spacing(4),
    // },
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center'
  },
  marginBottomSuccessHeader: {
    marginBottom: theme.spacing(0),
  },
  marginLeftSuccessGridButton: {
    marginLeft: theme.spacing(4),
  },
  listgenLogoHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -theme.spacing(4),
    marginBottom: theme.spacing(3)
  },
  listgenLogoWrapper: {
    width: '100%',
    textAlign: 'center'
  },
  listgenLogoTag: {
    width: '75px'
  },
  listgenAltTag: {
    width: '200px'
  }
});
