import React, { Component } from "react";

class TestRouter extends Component {
  render() {
    console.log("this.props", this.props);
    return (
      <div style={{ fontSize: "36px" }}>
        <h1>My Listing Page</h1>
        <p>
          Homeowner Code from Params in URL:
          {this.props.match.params.homeownerCode}
        </p>
      </div>
    );
  }
}

export default TestRouter;
