export default theme => ({
  root: {},
  field: {
    //padding: theme.spacing(3)
    margin: theme.spacing(1),
  },
  paddingGrid: {
    padding: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
  },
  dividerPaddingGrid: {
    padding: theme.spacing(8),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
  },
  cancelButton: {
    backgroundColor: theme.palette.common.muted,
    color: theme.palette.common.white,
    marginTop: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.common.neutral,
      color: theme.palette.common.black,
    },
  },
  acceptButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  textField: {
    width: '100%'
  },
  centerText: {
    textAlign: 'center'
  },
  buttonGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1)
  },
  fieldError: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
});
