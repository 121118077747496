import React, { Component } from "react";
import FirstLeftHeader from "views/Pma/PmaEditor/CommonEditor/FirstLeftHeader";
import HOAddress from "views/Pma/PmaEditor/CommonEditor/HOAddress";
import QVFourLogoAndAvatar from "../../../Quarterly/QVFour/QVFourFirstPage/QVFourLogoAndAvatar";
import HomeValue from "./HomeValue";
import MQTwoTestimonial from "./MQTwoTestimonial";
import FactBubbles from "./FactBubbles";

class MQTwoFirstLeft extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      printVersion,
      version,
      testimonials,
      demoAccountAreaName,
      MQCityDetails,
      coverPageDetails,
      quarterlyAreaTable,
      quarterlyCityTable,
    } = this.props;

    let isBuellton =
      currentArea.mlsAreaID === "SBA-40F" ||
      currentArea.mlsAreaID === "SBA-40G";
    return (
      <div className="mq2-first-left">
        <FirstLeftHeader
          agentData={agentData}
          printVersion={printVersion}
          currentArea={currentArea}
        />
        <QVFourLogoAndAvatar
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
        />
        {hoData &&
          hoData.changeDetails &&
          Object.keys(hoData.changeDetails).length > 1 && (
            <HOAddress
              hoData={hoData}
              version={version}
              agentData={agentData}
              currentArea={currentArea}
              printVersion={printVersion}
              mailInfoClass="mail-info-container-print"
              demoAccountAreaName={demoAccountAreaName}
              // typeOfVersion="qv4"
              coverPageDetails={coverPageDetails}
            />
          )}
        <div className="backpage-bg">
          {testimonials && (
            <MQTwoTestimonial
              agentData={agentData}
              testimonials={testimonials}
            />
          )}

          <FactBubbles
            currentArea={currentArea}
            hoData={hoData}
            bubbleDataDetails={
              isBuellton
                ? quarterlyCityTable.bubbleDataDetails
                : quarterlyAreaTable.bubbleDataDetails
            }
            quarterlyAreaTable={
              isBuellton ? quarterlyCityTable : quarterlyAreaTable
            }
          />
        </div>
      </div>
    );
  }
}

export default MQTwoFirstLeft;
