import React, { Component } from "react";

import DBHeader from "./DBHeader";
import DBBody from "./DBBody";

import QVFourEmailAndWebsiteFooter from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourEmailAndWebsiteFooter";
import { propertyUtil } from "views/Pma/PropertyUtil";

class QVThreeSecondLeft extends Component {
  timelineText = (timeline, plural, shortened, spelled) => {
    if (timeline === "6 months") {
      if (spelled && plural) {
        return "six months";
      } else if (spelled && !plural) {
        return "six month";
      }
      if (plural) {
        return "6 months";
      }
      return "6 month";
    } else if (timeline === "1 year") {
      if (shortened) {
        return "year";
      }
      return "1 year";
    }
    return timeline;
  };

  getTimeline = () => {
    const {
      pmaQuarterlyCityDTO: pmaAprilCityDTO,
      pmaQuarterlyAreaDTO: pmaAprilRunAreaDTO,
    } = this.props.aprilData;

    let tryArea6Months = pmaAprilRunAreaDTO.marketTrends.find(
      (x) => x.TrendName === "6-Months Trending"
    );
    let tryAreaPast6Months = pmaAprilRunAreaDTO.marketTrends.find(
      (x) => x.TrendName === "Past 6-Months Trending"
    );
    let area6Months;
    let areaPast6Months;
    let timeline;
    if (
      tryArea6Months.AvgSalePrice === "0" ||
      tryAreaPast6Months.AvgSalePrice === "0"
    ) {
      //USE YEAR

      area6Months = pmaAprilRunAreaDTO.marketTrends.find(
        (x) => x.TrendName === "1-Year Trending"
      );
      areaPast6Months = pmaAprilRunAreaDTO.marketTrends.find(
        (x) => x.TrendName === "Past 1-Year Trending"
      );
      timeline = "1 year";
    } else {
      area6Months = pmaAprilRunAreaDTO.marketTrends.find(
        (x) => x.TrendName === "6-Months Trending"
      );
      areaPast6Months = pmaAprilRunAreaDTO.marketTrends.find(
        (x) => x.TrendName === "Past 6-Months Trending"
      );
      timeline = "6 months";
    }
    return timeline;
  };
  aprilDataAndAdvantages = (aprilData) => {
    const { infinityCheck, calculateDiff } = propertyUtil;

    let citySellersAdvantagesList = [];
    let areaSellersAdvantagesList = [];
    function calculateSellersAdvantage(
      perc,
      trendForSellersAdv,
      category,
      place,
      special,
      data
    ) {
      special = +special;
      perc = +perc;
      data = +data;
      if (data) {
      }
      if (special) {
      }

      if (
        (category === "average % received of asking" &&
          data >= 95 &&
          perc >= -2) ||
        (category === "average % received of asking" && data >= 100) ||
        (category === "average % received of asking" && perc >= special) ||
        (category === "units sold" && perc >= special) ||
        (category === "average dom" && (perc <= special || data <= 15)) ||
        (category === "average sale price" && perc >= special) ||
        (category === "average sale price" && data >= 2) ||
        (category === "over list price" && perc >= special && data !== 0) ||
        (category === "average sqft" && perc >= special) ||
        (category === "inventory" && special < 3) ||
        (perc > 1 && trendForSellersAdv === "up") ||
        (perc < 1 && trendForSellersAdv === "down")
      ) {
        if (place === "city") {
          citySellersAdvantagesList = [...citySellersAdvantagesList, category];
        } else if (place === "area") {
          areaSellersAdvantagesList = [...areaSellersAdvantagesList, category];
        }
      } else {
        return false;
      }
    }
    const {
      pmaQuarterlyCityDTO: pmaAprilCityDTO,
      pmaQuarterlyAreaDTO: pmaAprilRunAreaDTO,
    } = aprilData;

    let city6Obj = {
      cityAvgSalePrice: null,
      cityAvgSalePricePerc: null,
      cityAvgDOM: null,
      cityPastAvgDOM: null,
      cityAvgDOMPerc: null,
      cityPercReceived: null,
      cityPercReceivedPerc: null,
      cityPercOverList: null,
      cityPercOverListPerc: null,
      cityAvgSqFtPrice: null,
      cityPastAvgSqFtPrice: null,
      cityAvgSqFtPricePerc: null,
      cityUnitsSold: null,
      cityPastUnitsSold: null,
      cityUnitsSoldPerc: null,
      cityInventory: null,
      cityInventoryPerc: null,
      cityTotalActiveListings: null,
      cityYOYSalePricePerc: null,
    };
    const {
      totalActiveListingsCount: city6TotalActiveListings,
    } = pmaAprilCityDTO;

    let city6Months = pmaAprilCityDTO.marketTrends.find(
      (x) => x.TrendName === "6-Months Trending"
    );
    const {
      AvgDaysOnMarket: city6DOM,
      AvgPercentOfReceiving: city6PercOfReceiving,
      AvgPricePerSqFt: city6SqftPrice,
      AvgSalePrice: city6SalePrice,
      HomesSold: city6HomesSold,
      Inventory: city6Inventory,
      PercentageSoldAtOrOverListPrice: city6OverListPrice,
    } = city6Months;
    let cityPast6Months = pmaAprilCityDTO.marketTrends.find(
      (x) => x.TrendName === "Past 6-Months Trending"
    );
    const {
      AvgDaysOnMarket: cityPast6DOM,
      AvgPercentOfReceiving: cityPast6PercOfReceiving,
      AvgPricePerSqFt: cityPast6SqftPrice,
      AvgSalePrice: cityPast6SalePrice,
      HomesSold: cityPast6HomesSold,
      Inventory: cityPast6Inventory,
      PercentageSoldAtOrOverListPrice: cityPast6OverListPrice,
    } = cityPast6Months;
    let city1YearDiff = pmaAprilCityDTO.marketTrendsPercentage.find(
      (x) => x.TrendPercentageName === "1 Year Trends Percentage"
    );
    const {
      PercentageAvgSalePrice: city1YearSalePriceDiffPerc,
    } = city1YearDiff;

    city6Obj.cityTotalActiveListings = +pmaAprilCityDTO.totalActiveListings;
    city6Obj.cityYOYSalePricePerc = city1YearSalePriceDiffPerc;
    city6Obj.cityAvgSalePrice = city6SalePrice;
    city6Obj.cityAvgSalePricePerc = calculateDiff(
      city6SalePrice,
      cityPast6SalePrice
    );

    let city6AvgSaleAdv = calculateSellersAdvantage(
      city6Obj.cityAvgSalePricePerc,
      "special",
      "average sale price",
      "city",
      1,
      city6Obj.cityYOYSalePricePerc
    );

    city6Obj.cityAvgDOM = city6DOM;
    city6Obj.cityPastAvgDOM = cityPast6DOM;
    city6Obj.cityAvgDOMPerc = calculateDiff(city6DOM, cityPast6DOM);
    let city6DOMAdv = calculateSellersAdvantage(
      city6Obj.cityAvgDOMPerc,
      "special",
      "average dom",
      "city",
      -1,
      +city6DOM
    );

    city6Obj.cityPercOverList = city6OverListPrice;
    city6Obj.cityPercOverListPerc = calculateDiff(
      city6OverListPrice,
      cityPast6OverListPrice
    );

    let city6PercOverListAdv = calculateSellersAdvantage(
      city6Obj.cityPercOverListPerc,
      "special",
      "over list price",
      "city",
      30,
      city6OverListPrice,
      cityPast6OverListPrice
    );

    city6Obj.cityInventory = city6Inventory;
    city6Obj.cityInventoryPerc = calculateDiff(
      city6Inventory,
      cityPast6Inventory
    );

    let city6InventoryAdv = calculateSellersAdvantage(
      city6Obj.cityInventoryPerc,
      "special",
      "inventory",
      "city",
      +city6Inventory
    );

    city6Obj.cityPercReceived = city6PercOfReceiving;
    city6Obj.cityPercReceivedPerc = calculateDiff(
      city6PercOfReceiving,
      cityPast6PercOfReceiving
    );

    let city6PercReceivingAdv = calculateSellersAdvantage(
      city6Obj.cityPercReceivedPerc,
      "special",
      "average % received of asking",
      "city",
      2.1,
      +city6Obj.cityPercReceived
    );

    city6Obj.cityAvgSqFtPrice = city6SqftPrice;
    city6Obj.cityPastAvgSqFtPrice = cityPast6SqftPrice;
    city6Obj.cityAvgSqFtPricePerc = calculateDiff(
      city6SqftPrice,
      cityPast6SqftPrice
    );

    let city6AvgSqftAdv = calculateSellersAdvantage(
      city6Obj.cityAvgSqFtPricePerc,
      "special",
      "avg sqft",
      "city",
      2.1
    );

    city6Obj.cityUnitsSold = city6HomesSold;
    city6Obj.cityPastUnitsSold = cityPast6HomesSold;
    city6Obj.cityUnitsSoldPerc = calculateDiff(
      city6HomesSold,
      cityPast6HomesSold
    );

    let city6UnitsSoldAdv = calculateSellersAdvantage(
      city6Obj.cityUnitsSoldPerc,
      "special",
      "units sold",
      "city",
      25
    );
    let area6Obj = {
      areaAvgSalePrice: null,
      areaAvgSalePricePerc: null,
      areaAvgDOM: null,
      areaPastAvgDOM: null,
      areaAvgDOMPerc: null,
      areaPercReceived: null,
      areaPercReceivedPerc: null,
      areaPercOverList: null,
      areaPercOverListPerc: null,
      areaAvgSqFtPrice: null,
      areaPastAvgSqFtPrice: null,
      areaAvgSqFtPricePerc: null,
      areaUnitsSold: null,
      areaPastUnitsSold: null,
      areaUnitsSoldPerc: null,
      areaInventory: null,
      areaInventoryPerc: null,
      areaTotalActiveListings: null,
      areaYOYSalePricePerc: null,
    };
    area6Obj.areaTotalActiveListings = +pmaAprilRunAreaDTO.totalActiveListings;

    let area1YearDiff = pmaAprilRunAreaDTO.marketTrendsPercentage.find(
      (x) => x.TrendPercentageName === "1 Year Trends Percentage"
    );
    const {
      PercentageAvgSalePrice: area1YearSalePriceDiffPerc,
    } = area1YearDiff;

    const {
      totalActiveListings: area6TotalActiveListings,
    } = pmaAprilRunAreaDTO;
    // console.log("area1YearSalePriceDiffPerc", area1YearSalePriceDiffPerc);
    let tryArea6Months = pmaAprilRunAreaDTO.marketTrends.find(
      (x) => x.TrendName === "6-Months Trending"
    );
    let tryAreaPast6Months = pmaAprilRunAreaDTO.marketTrends.find(
      (x) => x.TrendName === "Past 6-Months Trending"
    );
    let area6Months;
    let areaPast6Months;
    let timeline;
    if (
      tryArea6Months.AvgSalePrice === "0" ||
      tryAreaPast6Months.AvgSalePrice === "0"
    ) {
      //USE YEAR

      area6Months = pmaAprilRunAreaDTO.marketTrends.find(
        (x) => x.TrendName === "1-Year Trending"
      );
      areaPast6Months = pmaAprilRunAreaDTO.marketTrends.find(
        (x) => x.TrendName === "Past 1-Year Trending"
      );
      timeline = "1 year";
    } else {
      area6Months = pmaAprilRunAreaDTO.marketTrends.find(
        (x) => x.TrendName === "6-Months Trending"
      );
      areaPast6Months = pmaAprilRunAreaDTO.marketTrends.find(
        (x) => x.TrendName === "Past 6-Months Trending"
      );
      timeline = "6 months";
    }

    const {
      AvgDaysOnMarket: area6DOM,
      AvgPercentOfReceiving: area6PercOfReceiving,
      AvgPricePerSqFt: area6SqftPrice,
      AvgSalePrice: area6SalePrice,
      HomesSold: area6HomesSold,
      Inventory: area6Inventory,
      PercentageSoldAtOrOverListPrice: area6OverListPrice,
    } = area6Months;

    const {
      AvgDaysOnMarket: areaPast6DOM,
      AvgPercentOfReceiving: areaPast6PercOfReceiving,
      AvgPricePerSqFt: areaPast6SqftPrice,
      AvgSalePrice: areaPast6SalePrice,
      HomesSold: areaPast6HomesSold,
      Inventory: areaPast6Inventory,
      PercentageSoldAtOrOverListPrice: areaPast6OverListPrice,
    } = areaPast6Months;

    area6Obj.areaYOYSalePricePerc = area1YearSalePriceDiffPerc;
    area6Obj.areaAvgSalePrice = area6SalePrice;
    area6Obj.areaAvgSalePricePerc = calculateDiff(
      area6SalePrice,
      areaPast6SalePrice
    );
    let area6AvgSaleAdv = calculateSellersAdvantage(
      area6Obj.areaAvgSalePricePerc,
      "special",
      "average sale price",
      "area",
      1,
      area6Obj.areaYOYSalePricePerc
    );

    area6Obj.areaAvgDOM = area6DOM;
    area6Obj.areaPastAvgDOM = areaPast6DOM;
    area6Obj.areaAvgDOMPerc = calculateDiff(area6DOM, areaPast6DOM);
    let area6DOMAdv = calculateSellersAdvantage(
      area6Obj.areaAvgDOMPerc,
      "special",
      "average dom",
      "area",
      -1,
      +area6DOM
    );

    area6Obj.areaPercOverList = area6OverListPrice;
    area6Obj.areaPercOverListPerc = calculateDiff(
      area6OverListPrice,
      areaPast6OverListPrice
    );

    let area6PercOverListAdv = calculateSellersAdvantage(
      area6Obj.areaPercOverListPerc,
      "special",
      "over list price",
      "area",
      30,
      area6OverListPrice,
      areaPast6OverListPrice
    );

    area6Obj.areaInventory = area6Inventory;
    area6Obj.areaInventoryPerc = calculateDiff(
      area6Inventory,
      areaPast6Inventory
    );
    let area6InventoryAdv = calculateSellersAdvantage(
      area6Obj.areaInventoryPerc,
      "special",
      "inventory",
      "area",
      +area6Inventory
    );

    area6Obj.areaPercReceived = area6PercOfReceiving;
    area6Obj.areaPercReceivedPerc = calculateDiff(
      area6PercOfReceiving,
      areaPast6PercOfReceiving
    );
    let area6PercReceivingAdv = calculateSellersAdvantage(
      area6Obj.areaPercReceivedPerc,
      "special",
      "average % received of asking",
      "area",
      2.1,
      +area6Obj.areaPercReceived
    );

    area6Obj.areaAvgSqFtPrice = area6SqftPrice;
    area6Obj.areaPastAvgSqFtPrice = areaPast6SqftPrice;
    area6Obj.areaAvgSqFtPricePerc = calculateDiff(
      area6SqftPrice,
      areaPast6SqftPrice
    );

    let area6AvgSqftAdv = calculateSellersAdvantage(
      area6Obj.areaAvgSqFtPricePerc,
      "special",
      "avg sqft",
      "area",
      2.1
    );

    area6Obj.areaUnitsSold = area6HomesSold;
    area6Obj.areaPastUnitsSold = areaPast6HomesSold;
    area6Obj.areaUnitsSoldPerc = calculateDiff(
      area6HomesSold,
      areaPast6HomesSold
    );
    let area6UnitsSoldAdv = calculateSellersAdvantage(
      area6Obj.areaUnitsSoldPerc,
      "special",
      "units sold",
      "area",
      25
    );
    return {
      areaAdvantages: areaSellersAdvantagesList,
      cityAdvantages: citySellersAdvantagesList,
      city6Obj,
      area6Obj,
    };
  };
  useCityOrArea = (cityAdvantages, areaAdvantages, cityObj, areaObj) => {
    const { mlsAreaID } = this.props.currentArea;
    let useWhich = "area";
    let actualAdvantagesCount;
    let dataToSend;
    let advantagesToUse;
    //all these must switch to area only: Toro Park / Sara Village / Creek Bridge / Del Mesa /  Hacienda / Las Palmas / Indian Springs / sea haven/dunes,  east garrison, pasadera,  Bay Ridge, Hidden Hills, Laguna Seca, Del Rey Oaks
    //need for indian springs,

    // let allAreaSwitches = [
    //   "77",
    //   "162",
    //   "932933",
    //   "931",
    //   "751",
    //   "108",
    //   "SBA-25A",
    // ];
    // //if both city and area's sale price is up
    // if (allAreaSwitches.includes(mlsAreaID)) {
    //   useWhich = "area";
    // }
    // if (allAreaSwitches.includes("SBA-25A")) {
    //   useWhich = "area";
    // } else if (
    //   areaAdvantages.includes("average sale price") &&
    //   areaAdvantages.length >= 3
    // ) {
    //   useWhich = "area";
    // } else if (
    //   areaAdvantages.includes("average sale price") &&
    //   cityAdvantages.includes("average sale price")
    // ) {
    //   if (cityAdvantages.length > areaAdvantages.length) {
    //     useWhich = "city";
    //   } else {
    //     useWhich = "area";
    //   }
    // } else if (areaAdvantages.includes("average sale price")) {
    //   //1)If average sale price is up in neighborhood. use neighborhood.
    //   useWhich = "area";
    // } else if (
    //   !areaAdvantages.includes("average sale price") &&
    //   cityAdvantages.includes("average sale price")
    // ) {
    //   {
    //     /*  2) The logic should be that IF Avg sale price is DOWN, we switch from the neighborhood (the default) to the city. Then if the city’s avg sale price went UP, we use the city for all the market indicators use city*/
    //   }
    //   useWhich = "city";
    // } else if (cityAdvantages.length >= 4) {
    //   {
    //     /* 3) IF the avg sale price for the city did not go up either, then the logic should be to see if there are 4 out of 9 market indicators that are to the seller’s advantage in the city.  if city has at least 4 sellers advantage market indicators, exit and use city*/
    //   }
    //   useWhich = "city";
    // } else if (areaAdvantages.length >= 4) {
    //   {
    //     /* 4) Then IF the city doesn’t have 4 MI that are to the seller’s advantage, then go back to the neighborhood and see if there are 4 MI to the seller’s adv. If area has 4 MI, exit and use area*/
    //   }
    //   useWhich = "area";
    // } else {
    //   if (cityAdvantages.length > areaAdvantages.length) {
    //     useWhich = "city";
    //   } else {
    //     useWhich = "area";
    //   }
    // }

    function fillInAdv(advantages) {
      let newAdvantages = [];
      if (advantages.includes("average sale price") && advantages.length == 4) {
        return advantages;
      } else if (!advantages.includes("average sale price")) {
        newAdvantages = ["average sale price", ...advantages.slice(0, 3)];
        return fillInAdv(newAdvantages);
      } else if (!advantages.includes("average dom")) {
        newAdvantages = [...advantages, "average dom"];
        return fillInAdv(newAdvantages);
      } else if (!advantages.includes("inventory")) {
        newAdvantages = [...advantages, "inventory"];
        return fillInAdv(newAdvantages);
      } else if (!advantages.includes("average % received of asking")) {
        newAdvantages = [...advantages, "average % received of asking"];
        return fillInAdv(newAdvantages);
      }

      return advantages;
    }

    if (useWhich === "city") {
      let sliced = cityAdvantages.slice(0, 4);
      if (!sliced.includes("average sale price")) {
        actualAdvantagesCount = sliced.length - 1;
      } else {
        actualAdvantagesCount = sliced.length;
      }

      advantagesToUse = fillInAdv(sliced);
      dataToSend = {
        AvgDOM: +cityObj.cityAvgDOM,
        AvgPastDOM: +cityObj.cityPastAvgDOM,
        AvgDOMPerc: cityObj.cityAvgDOMPerc,
        AvgSalePrice: +cityObj.cityAvgSalePrice,
        AvgSalePricePerc: cityObj.cityAvgSalePricePerc,
        AvgSqFtPrice: +cityObj.cityAvgSqFtPrice,
        AvgPastSqFtPrice: +cityObj.cityPastAvgSqFtPrice,
        AvgSqFtPricePerc: cityObj.cityAvgSqFtPricePerc,
        PercOverList: +cityObj.cityPercOverList,
        PercOverListPerc: cityObj.cityPercOverListPerc,
        PercReceived: +cityObj.cityPercReceived,
        PercReceivedPerc: cityObj.cityPercReceivedPerc,
        UnitsSold: +cityObj.cityUnitsSold,
        UnitsPastSold: +cityObj.cityPastUnitsSold,
        UnitsSoldPerc: cityObj.cityUnitsSoldPerc,
        Inventory: +cityObj.cityInventory,
        InventoryPerc: cityObj.cityInventoryPerc,
        TotalActiveListings: +cityObj.cityTotalActiveListings,
        YOYAvgSalePricePerc: +cityObj.cityYOYSalePricePerc,
      };
    } else if (useWhich === "area") {
      let sliced = areaAdvantages.slice(0, 4);
      if (!sliced.includes("average sale price")) {
        actualAdvantagesCount = sliced.length - 1;
      } else {
        actualAdvantagesCount = sliced.length;
      }
      advantagesToUse = fillInAdv(areaAdvantages.slice(0, 4));
      dataToSend = {
        AvgDOM: +areaObj.areaAvgDOM,
        AvgPastDOM: +areaObj.areaPastAvgDOM,
        AvgDOMPerc: areaObj.areaAvgDOMPerc,
        AvgSalePrice: +areaObj.areaAvgSalePrice,
        AvgSalePricePerc: areaObj.areaAvgSalePricePerc,
        AvgSqFtPrice: +areaObj.areaAvgSqFtPrice,
        AvgPastSqFtPrice: +areaObj.areaPastAvgSqFtPrice,
        AvgSqFtPricePerc: areaObj.areaAvgSqFtPricePerc,
        PercOverList: +areaObj.areaPercOverList,
        PercOverListPerc: areaObj.areaPercOverListPerc,
        PercReceived: +areaObj.areaPercReceived,
        PercReceivedPerc: areaObj.areaPercReceivedPerc,
        UnitsSold: +areaObj.areaUnitsSold,
        UnitsPastSold: +areaObj.areaPastUnitsSold,
        UnitsSoldPerc: areaObj.areaUnitsSoldPerc,
        Inventory: +areaObj.areaInventory,
        InventoryPerc: areaObj.areaInventoryPerc,
        TotalActiveListings: +areaObj.areaTotalActiveListings,
        YOYAvgSalePricePerc: +areaObj.areaYOYSalePricePerc,
      };
    }
    return {
      cityOrArea: useWhich,
      advantages: advantagesToUse,
      data: dataToSend,
      actualAdvantagesCount,
    };
  };
  constructAdvSentences = (
    cityOrArea,
    advantages,
    {
      AvgDOM,
      AvgPastDOM,
      AvgDOMPerc,
      AvgSalePrice,
      AvgSalePricePerc,
      AvgSqFtPrice,
      AvgPastSqFtPrice,
      AvgSqFtPricePerc,
      PercOverList,
      PercOverListPerc,
      PercReceived,
      PercReceivedPerc,
      UnitsSold,
      UnitsPastSold,
      UnitsSoldPerc,
      Inventory,
      InventoryPerc,
      TotalActiveListings,
      YOYAvgSalePricePerc,
    }
  ) => {
    const { mlsNeighborhood, mlsCityName, mlsAreaID } = this.props.currentArea;
    const { subdivisionName } = this.props.hoData;
    const { roundSalePrice, endsWithS, homesNumber } = propertyUtil;
    const { printVersion, demoAccountCity, aprilData } = this.props;

    let sentences = [];
    let rawPlace;
    function placeCount(place, lowercase) {
      let placeLength = place.length;

      if (mlsAreaID === "SBA-45H" || mlsAreaID === "SBA-45I") {
        if (lowercase) {
          return "your area";
        } else {
          return "Your area";
        }
      } else if (mlsAreaID === "SCL-16J") {
        if (lowercase) {
          return "your neighborhood";
        } else {
          return "Your neighborhood";
        }
      } else if (placeLength >= 20) {
        if (cityOrArea === "city") {
          if (lowercase) {
            return "your city";
          } else {
            return "Your city";
          }
        } else if (cityOrArea === "area") {
          if (lowercase) {
            return "your neighborhood";
          } else {
            return "Your neighborhood";
          }
        }
      } else {
        if (place === "SANTA BARBARA") {
          return "Santa Barbara";
        } else {
          return place;
        }
      }
    }
    if (cityOrArea === "city") {
      rawPlace = mlsCityName;
    } else if (cityOrArea === "area") {
      if (subdivisionName) {
        rawPlace = subdivisionName;
      } else if (mlsAreaID === "SBA-20FInv" || mlsAreaID === "SBA-20FRes") {
        rawPlace = "Downtown";
      } else {
        rawPlace = mlsNeighborhood;
      }
    }
    let homesOrCondos = "homes";
    let homeOrCondo = "home";
    let capHomesOrCondos = "Homes";
    if (mlsAreaID === "SBA-20FCondo") {
      homesOrCondos = "condos";
      homeOrCondo = "condo";
      capHomesOrCondos = "Condos";
    } else if (mlsAreaID === "SBA-20FInv") {
      homesOrCondos = "investment properties";
      homeOrCondo = "investment property";
      capHomesOrCondos = "Investment properties";
    }
    const timeline = this.getTimeline();

    const { timelineText } = this;

    let place = placeCount(rawPlace);
    const domDayDifference = Math.abs(AvgDOM - AvgPastDOM);
    function toThousandths(num) {
      let rounded = Math.round(num / 1000) * 1000;
      return rounded.toLocaleString();
    }

    const { monthsSupplyTable } = aprilData.pmaQuarterlyAreaDTO;

    const {
      AreaNumberOfHousesForSale,
      AvgHomesSoldLast12Months,
    } = monthsSupplyTable;

    const areaInventory = homesNumber(
      AreaNumberOfHousesForSale,
      Number(AvgHomesSoldLast12Months) * 10,
      true,
      false,
      null,
      null,
      null,
      Number(AvgHomesSoldLast12Months)
    )[1];

    advantages.map((category) => {
      if (category === "average sale price") {
        if (AvgSalePricePerc >= 1) {
          let newSentence = {
            header: `HIGHER ${homeOrCondo} PRICES`,
            statement: `Market conditions push ${homeOrCondo} prices up when there is high buyer demand and fewer ${homesOrCondos} for <span class="keep-hyphen">sale — even</span> with higher interest rates.`,

            logic:
              mlsAreaID === "SBA-20FInv"
                ? `While Downtown's investment property prices have recently been decreasing, average sales prices remain higher than the previous 6 month average.`
                : `${placeCount(rawPlace, false)}${endsWithS(
                    place
                  )} average sale price has increased to $${toThousandths(
                    AvgSalePrice
                  )}, which is ${AvgSalePricePerc}% higher than the previous ${timelineText(
                    timeline,
                    false
                  )} average.`,
            benefit: "Seller's",
          };

          sentences = [...sentences, newSentence];
        } else if (AvgSalePricePerc <= -1) {
          //if avg saleprice perc <= 0
          let newSentence;
          if (YOYAvgSalePricePerc >= 1) {
            newSentence = {
              header: `AVERAGE ${homeOrCondo} PRICES`,
              statement: `When mortgage interest rates rise sharply, buyer purchasing power can be <span class="keep-hyphen">affected — putting</span> more importance on pricing your ${homeOrCondo} accurately.`,

              logic:
                mlsAreaID === "SBA-20FRes"
                  ? `${
                      mlsAreaID === "SBA-20FCondo"
                        ? "Downtown Condos'"
                        : `${placeCount(rawPlace, false)}${endsWithS(place)}`
                    } average sale price has decreased to $${toThousandths(
                      AvgSalePrice
                    )} over the previous <span class="keep-hyphen">${timelineText(
                      timeline,
                      true
                    )} — however,</span> the average sale price is still trending positively year over year.`
                  : `${
                      mlsAreaID === "SBA-20FCondo"
                        ? "Downtown Condos'"
                        : `${placeCount(rawPlace, false)}${endsWithS(place)}`
                    } average sale price has decreased to $${toThousandths(
                      AvgSalePrice
                    )} over the previous ${timelineText(
                      timeline,
                      true
                    )}. Despite a ${Math.abs(
                      AvgSalePricePerc
                    )}% decrease, the average sale price is still trending positively year over year.`,
              benefit: "Seller's",
            };
          } else {
            newSentence = {
              header: `AVERAGE ${homeOrCondo} PRICES`,
              statement: `When mortgage interest rates rise sharply, buyer purchasing power can be <span class="keep-hyphen">affected — putting</span> more importance on pricing your ${homeOrCondo} accurately.`,

              logic: `${placeCount(rawPlace, false)}${endsWithS(
                place
              )} average sale price has decreased to $${toThousandths(
                AvgSalePrice
              )}, which is ${Math.abs(
                AvgSalePricePerc
              )}% lower than the previous ${timelineText(
                timeline,
                false,
                true
              )} average.`,
            };
          }

          sentences = [...sentences, newSentence];
        } else if (AvgSalePricePerc >= -2 && AvgSalePricePerc <= 2) {
          if (YOYAvgSalePricePerc >= 1) {
            if (AvgSalePricePerc >= -2 && AvgSalePricePerc < 0) {
              let newSentence = {
                header: `AVERAGE ${homeOrCondo} PRICES`,
                statement: `When mortgage interest rates rise sharply, buyer purchasing power can be <span class="keep-hyphen">affected — putting</span> more importance on pricing your ${homeOrCondo} accurately.`,

                logic: `${placeCount(rawPlace, false)}${endsWithS(
                  place
                )} average sale is $${toThousandths(
                  AvgSalePrice
                )}, which is unchanged from the previous ${timelineText(
                  timeline,
                  false
                )} average.`,
              };

              sentences = [...sentences, newSentence];
            } else if (AvgSalePricePerc >= 0) {
              let newSentence = {
                header: `AVERAGE ${homeOrCondo} PRICES`,
                statement: `When mortgage interest rates rise sharply, buyer purchasing power can be <span class="keep-hyphen">affected — putting</span> more importance on pricing your ${homeOrCondo} accurately.`,

                logic: `${placeCount(rawPlace, false)}${endsWithS(
                  place
                )} average sale is $${toThousandths(
                  AvgSalePrice
                )}, which is unchanged from the previous ${timelineText(
                  timeline,
                  false
                )} average.`,
              };

              sentences = [...sentences, newSentence];
            }
          } else {
            let newSentence = {
              header: `AVERAGE ${homeOrCondo} PRICES`,
              statement: `When mortgage interest rates rise sharply, buyer purchasing power can be <span class="keep-hyphen">affected — putting</span> more importance on pricing your ${homeOrCondo} accurately.`,

              logic: `${placeCount(rawPlace, false)}${endsWithS(
                place
              )} average sale is $${toThousandths(
                AvgSalePrice
              )}, which is unchanged from the previous ${timelineText(
                timeline,
                false
              )} average.`,
            };
            sentences = [...sentences, newSentence];
          }
        }
      } else if (category === "average dom") {
        if (AvgDOMPerc >= 1) {
          let newSentence = {
            header: "NUMBER OF DAYS TO SELL",
            statement: `When it takes longer to sell a ${homeOrCondo}, multiple market factors come into play, including sharp interest rate increases and/or a limited selection of ${homesOrCondos} for sale.`,

            // 3 - delete "an average of 101 days to sell, which is 90 days more" insert "slightly longer to sell". Then add at the end insert ", however, there remains strong demand". at the bottom make it " - A Seller's Advantage" 4 - is ok at the very bottom "3 out of 4" so it is a Seller's advantage
            //
            logic:
              mlsAreaID === "SBA-20FRes"
                ? `${
                    homeOrCondo === "condo"
                      ? "Condo's in your neighborhood"
                      : placeCount(rawPlace, false)
                  }${homeOrCondo === "condo" ? "" : endsWithS(place)} ${
                    homeOrCondo === "condo" ? "" : homesOrCondos
                  } are taking slightly longer to sell than the previous <span class="keep-hyphen">${timelineText(
                    timeline,
                    true,
                    true
                  )} — however,</span> there remains strong demand.`
                : `${
                    homeOrCondo === "condo"
                      ? "Condo's in your neighborhood"
                      : placeCount(rawPlace, false)
                  }${homeOrCondo === "condo" ? "" : endsWithS(place)} ${
                    homeOrCondo === "condo" ? "" : homesOrCondos
                  } are taking an average of ${AvgDOM} days to sell, which is ${domDayDifference} ${
                    domDayDifference === 1 ? "day" : "days"
                  } more than the previous ${timelineText(
                    timeline,
                    true,
                    true
                  )}.`,
            benefit: `${mlsAreaID === "SBA-20FRes" ? "Seller's" : ""}`,
          };

          sentences = [...sentences, newSentence];
        } else if (AvgDOMPerc < -2) {
          let newSentence = {
            header: `NUMBER OF DAYS TO SELL`,
            statement: `When the number of days to sell a ${homeOrCondo} decreases, this typically indicates high buyer demand and a shortage of available ${homesOrCondos} for sale.`,
            logic: `${placeCount(rawPlace, false)}${endsWithS(
              place
            )} ${homesOrCondos} are taking an average of ${AvgDOM} ${
              AvgDOM == 1 ? "day" : "days"
            } to sell, which is ${domDayDifference} ${
              domDayDifference === 1 ? "day" : "days"
            } less than the previous ${timelineText(timeline, true, true)}.`,
            benefit: "Seller's",
          };
          sentences = [...sentences, newSentence];
        } else if (AvgDOMPerc >= -2 && AvgDOMPerc <= 2) {
          let newSentence = {
            header: "NUMBER OF DAYS TO SELL",
            statement: `When the number of days to sell a ${homesOrCondos} does not go up or down, this typically indicates a balance between the demand and supply of available ${homesOrCondos} for sale.`,
            logic: `${placeCount(rawPlace, false)}${endsWithS(
              place
            )} ${homesOrCondos} are taking an average of ${AvgDOM} days to sell, which is unchanged from the previous ${timelineText(
              timeline,
              true,
              true
            )}.`,
          };
          sentences = [...sentences, newSentence];
        }
      } else if (category === "average % received of asking") {
        if (PercReceivedPerc > 2) {
          let newSentence = {
            header: "% RECEIVED OF ASKING",
            statement: `When ${homeOrCondo} sellers receive their full asking price or above, this is an indication of strong demand and a competitive market.`,
            logic: `${capHomesOrCondos} sold in ${placeCount(
              rawPlace,
              true
            )} have received ${Math.round(
              PercReceived
            )}% of their asking price, which is ${Math.round(
              PercReceivedPerc
            )}% higher than the previous ${timelineText(
              timeline,
              true,
              true
            )}, indicating stronger buyer demand. `,
            benefit: "Seller's",
          };
          sentences = [...sentences, newSentence];
        } else if (PercReceivedPerc < -2) {
          if (Math.round(PercReceived < 95)) {
            let newSentence;

            newSentence = {
              header: "% RECEIVED OF ASKING",
              statement:
                "When homeowners receive a lower percentage of their asking price, this is often an indication that buyer demand could be softening.",

              logic: `Over the last ${timelineText(
                timeline,
                true,
                true,
                true
              )}, the average percentage homeowners’ received of their asking price slipped slightly but it’s still above 95%.`,
            };

            sentences = [...sentences, newSentence];
          } else {
            let newSentence;
            newSentence = {
              header: "% RECEIVED OF ASKING",
              statement:
                "When homeowners receive a higher percentage of their asking price, this is an indication of strong demand and a competitive market.",

              logic:
                +PercReceived >= 100
                  ? `Over the last ${timelineText(
                      timeline,
                      true,
                      true,
                      true
                    )}, ${placeCount(rawPlace, true)}${endsWithS(
                      place
                    )} homeowners have received ${Math.round(
                      PercReceived
                    )}% of their asking price.`
                  : `Over the last ${timelineText(
                      timeline,
                      true,
                      true,
                      true
                    )}, ${placeCount(rawPlace, true)}${endsWithS(
                      place
                    )} homeowners have received ${Math.round(
                      PercReceived
                    )}% of their asking price, which is ${Math.abs(
                      Math.round(PercReceivedPerc)
                    )}% less than the previous ${timelineText(
                      timeline,
                      false
                    )} average.`,
              benefit: +PercReceived >= 100 ? "Seller's" : "",
            };

            sentences = [...sentences, newSentence];
          }
        } else if (PercReceivedPerc >= -2 && PercReceivedPerc <= 2) {
          let newSentence = {
            header: "% RECEIVED OF ASKING",
            statement:
              "When home sellers receive their full asking price or above, this is an indication of strong demand and a competitive market.",

            logic: `${placeCount(rawPlace, false)}${endsWithS(place)} ${
              mlsAreaID === "SBA-20FCondo"
                ? "condo owners"
                : mlsAreaID === "SBA-20FInv"
                ? "investment properties"
                : "homeowners"
            } received ${Math.round(
              PercReceived
            )}% of their asking price which is unchanged over the past ${timelineText(
              timeline,
              true,
              true
            )}.`,
            benefit: "Seller's",
          };
          sentences = [...sentences, newSentence];
        }
      } else if (category === "over list price") {
        if (PercOverListPerc >= 30) {
          let newSentence = {
            header: "AT OR OVER ASKING PRICE",
            statement: `When ${homesOrCondos} sell at or over their asking price, this indicates a competitive market that often leads to buyer bidding wars.`,
            logic: `Over the last ${timelineText(
              timeline,
              true,
              true,
              false
            )}, ${Math.round(
              PercOverList
            )}% of ${homesOrCondos} in ${placeCount(
              rawPlace,
              true
            )} were sold at or over their asking price.`,
            benefit: "Seller's",
          };
          sentences = [...sentences, newSentence];
        }
      } else if (category === "units sold") {
        if (UnitsSoldPerc >= 25) {
          let newSentence = {
            header: `MORE ${homesOrCondos} HAVE SOLD`,
            statement: `Typically, when the number of ${homesOrCondos} that sell in a given period of time increases, this indicates an active and healthy market.`,
            logic: `${placeCount(
              rawPlace,
              false
            )} has experienced increased market activity with ${UnitsSold} ${
              UnitsSold === 1 ? homeOrCondo : homesOrCondos
            } sold in the last ${timelineText(
              timeline,
              true,
              true,
              true
            )} vs ${UnitsPastSold} ${
              UnitsPastSold === 1 ? homeOrCondo : homesOrCondos
            } sold over the previous ${timelineText(
              timeline,
              true,
              true,
              true
            )}.`,
            benefit: "Seller's",
          };
          sentences = [...sentences, newSentence];
        } else {
          let newSentence = {
            header: `Units Sold`,
            statement:
              "Typically, when the number of homes that sell in a given period of time decreases, this can be indicative of a slowing market.",
            logic: `${placeCount(
              rawPlace,
              false
            )} has experienced decreased market activity with ${UnitsSold} ${
              UnitsSold === 1 && homeOrCondo === "home"
                ? "home"
                : UnitsSold === 1 && homeOrCondo === "condo"
                ? "condo"
                : UnitsSold > 1 && homesOrCondos === "homes"
                ? "homes"
                : "condos"
            } sold in the last ${timelineText(
              timeline,
              true,
              true
            )} compared to ${UnitsPastSold} ${
              UnitsPastSold === 1 && homeOrCondo === "home"
                ? "home"
                : UnitsPastSold === 1 && homeOrCondo === "condo"
                ? "condo"
                : UnitsPastSold > 1 && homesOrCondos === "homes"
                ? "homes"
                : "condos"
            } sold in the previous ${timelineText(timeline, true, true)}.`,
            benefit: "Seller's",
          };
          sentences = [...sentences, newSentence];
        }
      } else if (category === "avg sqft") {
        if (AvgSqFtPrice > 2) {
          let newSentence = {
            header: "RISING PRICE PER SQUARE FOOT",
            statement:
              "When the price per square foot climbs, this is typically an indication of a strong and healthy seller’s market.",

            logic: `The average price per square foot in ${placeCount(
              rawPlace,
              true
            )} has risen to $${AvgSqFtPrice.toLocaleString()}, compared to $${AvgPastSqFtPrice.toLocaleString()}/ sqft in the previous ${timelineText(
              timeline,
              true,
              true
            )}.`,
            benefit: "Seller's",
          };
          sentences = [...sentences, newSentence];
        } else if (AvgSqFtPrice < -2) {
          let newSentence = {
            header: "PRICE PER SQUARE FOOT",
            statement:
              "When the price per square foot climbs, this is typically an indication of a strong and healthy seller’s market.",

            logic: `The average price per square foot in ${placeCount(
              rawPlace,
              true
            )} has decreased to $${AvgSqFtPrice.toLocaleString()}, compared to $${AvgPastSqFtPrice.toLocaleString()}/ sqft in the previous ${timelineText(
              timeline,
              true,
              true
            )}. .`,
          };
          sentences = [...sentences, newSentence];
        } else if (AvgSqFtPrice >= -2 || AvgSqFtPrice <= 2) {
          let newSentence = {
            header: "PRICE PER SQUARE FOOT",
            statement:
              "When the price per square foot climbs, this is typically an indication of a strong and healthy seller’s market.",

            logic: `The average price per square foot in ${placeCount(
              rawPlace,
              true
            )} has remained at $${AvgSqFtPrice.toLocaleString()}, which is unchanged from the previous ${timelineText(
              timeline,
              true,
              true
            )}.`,
          };
          sentences = [...sentences, newSentence];
        }
      } else if (category === "inventory") {
        if (areaInventory === "Low") {
          let newSentence = {
            header: `FEWER ${homesOrCondos} CURRENTLY LISTED`,
            statement: `When the number of ${homesOrCondos} for sale is low, buyers have fewer choices, which can increase competition and drive prices upward.`,
            // " 2- delete "is only 1 home"; insert "are very few homes" 3 - delete "an average of 101 days to sell, which is 90 days more" insert "slightly longer to sell". Then add at the end insert ", however, there remains strong demand". at the bottom make it " - A Seller's Advantage" 4 - is ok at the very bottom "3 out of 4" so it is a Seller's advantage
            logic:
              mlsAreaID === "SBA-20FRes"
                ? `Currently, there are very few homes actively listed for sale in ${placeCount(
                    rawPlace,
                    true
                  )} — giving any new listings that come on the market a distinct competitive advantage. `
                : mlsAreaID === "46C"
                ? `Currently, there are only 3 homes actively listed for sale in Santa Cruz <span class="keep-hyphen">Gardens — giving</span> any new listings that come on the market a distinct competitive advantage.`
                : `Currently, there ${
                    TotalActiveListings === 1 ? "is" : "are"
                  } ${
                    TotalActiveListings === 0 ? "" : "only"
                  } ${TotalActiveListings} ${
                    TotalActiveListings === 1 ? homeOrCondo : homesOrCondos
                  } actively listed for sale in ${placeCount(
                    rawPlace,
                    true
                  )} — giving any new listings that come on the market a distinct competitive advantage.`,

            benefit: "Seller's",
          };
          sentences = [...sentences, newSentence];
        } else if (areaInventory === "Balanced") {
          let newSentence = {
            header: "AVAILABLE HOMES FOR SALE",
            statement: `When the number of ${homesOrCondos} for sale is approximately equal to the number of buyers wanting to buy, this is considered a balanced market and does not typically push prices up or down.`,

            logic: `${placeCount(
              rawPlace,
              false
            )} currently has ${TotalActiveListings} ${
              TotalActiveListings === 1 && homeOrCondo === "home"
                ? "home"
                : TotalActiveListings === 1 && homeOrCondo === "condo"
                ? "condo"
                : TotalActiveListings > 1 && homesOrCondos === "homes"
                ? "homes"
                : "condos"
            } for sale which is considered a healthy (balanced) amount of homes for sale.`,
          };
          sentences = [...sentences, newSentence];
        } else if (areaInventory === "High") {
          let newSentence = {
            header: "AVAILABLE HOMES FOR SALE",
            statement:
              "When there is a plentiful number of homes for sale, buyers have more homes to choose from, which can push home prices down.",

            logic: `${placeCount(
              rawPlace,
              false
            )} currently has ${TotalActiveListings} ${
              TotalActiveListings === 1 && homeOrCondo === "home"
                ? "home"
                : TotalActiveListings === 1 && homeOrCondo === "condo"
                ? "condo"
                : TotalActiveListings > 1 && homesOrCondos === "homes"
                ? "homes"
                : "condos"
            } for sale. This is considered a high number of homes for sale.
`,
          };
          sentences = [...sentences, newSentence];
        }
      }
      return "";
    });
    return sentences;
  };

  render() {
    const {
      agentData,
      aprilData,
      currentArea,
      hoData,
      printVersion,
      demoAccountCity,
    } = this.props;

    const aprilDataAndAdvantages = this.aprilDataAndAdvantages(aprilData);
    const useCityOrArea = this.useCityOrArea(
      aprilDataAndAdvantages.cityAdvantages,
      aprilDataAndAdvantages.areaAdvantages,
      aprilDataAndAdvantages.city6Obj,
      aprilDataAndAdvantages.area6Obj
    );
    //adding update
    const constructAdvSentences = this.constructAdvSentences(
      useCityOrArea.cityOrArea,
      useCityOrArea.advantages,
      useCityOrArea.data
    );

    return (
      <div className="qv3-second-left">
        <img
          src={`https://pma-coverphotos.s3.amazonaws.com/060623_JUNE-PMA_Gradient-Dashboard.jpg`}
          alt="Leaf"
          className="dashboard-bg"
        />
        <DBHeader aprilData={aprilData} />
        <DBBody
          aprilData={aprilData}
          sentences={constructAdvSentences}
          actualAdvantagesCount={useCityOrArea.actualAdvantagesCount}
          cityOrArea={useCityOrArea.cityOrArea}
          currentArea={currentArea}
          hoData={hoData}
          printVersion={printVersion}
          agentData={agentData}
          demoAccountCity={demoAccountCity}
        />

        <QVFourEmailAndWebsiteFooter
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
        />
      </div>
    );
  }
}

export default QVThreeSecondLeft;
