import React, { Component } from "react";

class QRCodeSection extends Component {
  render() {
    let {
      homeOwnerURLID,
      homeOwnerURLQRCodePath,
      homeOwnerURL,
    } = this.props.hoData;

    const { isMemberOfTeam, listgenUserID } = this.props.agentData;
    if (listgenUserID === "101") {
      homeOwnerURL = "https://ourproperty.info/c12p";
      homeOwnerURLID = "c12p";
      homeOwnerURLQRCodePath =
        "https://lg-qr-code-images.s3.amazonaws.com/c12p.png";
    }

    return (
      <div className="qr-code-section">
        <div className="qr-code-col1">
          <div className="bubble-arrow-container">
            <img src="https://pma-coverphotos.s3.amazonaws.com/double-arrow-sep.png" />
          </div>
        </div>
        <div className="qr-code-col2">
          <div className="qr-code-title">
            <p>
              <span className="qr-code-space">NEW!</span>Get a Customized Home
              Valuation of Your Property
            </p>
          </div>
          <div className="qr-code-content-row">
            <div className="bubble-qr-container">
              {listgenUserID === "100411" ? (
                <img
                  src="https://pma-coverphotos.s3.amazonaws.com/040323_Garret-MC_QRcode_500px.jpg"
                  className="bubble-qr-image"
                  alt="Garrett QR Code"
                />
              ) : (
                <img
                  src={homeOwnerURLQRCodePath}
                  className="bubble-qr-image"
                  alt="QR Code"
                />
              )}
            </div>
            <div className="qr-code-text">
              <p>
                Simply scan your personal QR code or go to{" "}
                {listgenUserID === "100411" ? (
                  <a href="https://hmbt.co/DcY6Gq" target="_blank">
                    <span className="qr-code-link">https://hmbt.co/DcY6Gq</span>
                  </a>
                ) : (
                  <a href={homeOwnerURL} target="_blank">
                    <span className="qr-code-link">
                      OurProperty.info/{homeOwnerURLID}
                    </span>
                  </a>
                )}
                and{" "}
                {isMemberOfTeam || listgenUserID === "100988"
                  ? "we'll"
                  : "I'll"}{" "}
                deliver a no-obligation Home Valuation report based on the
                current market.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QRCodeSection;
