import palette from '../../theme/palette';

export default theme => ({
  root: {

  },
  appBar: {
    position: 'relative',
    background: '#00225a'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: palette.common.white
  },
  colorWhite: {
    color: theme.palette.common.white
  },
  progressWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100vh'
  },
  messageBox: {
    textAlign: 'center',
    margin: theme.spacing(4)
  },
  listItemText: {
    height: '80px',
    '&>div>span': {
      fontSize: '16px',
      fontWeight: 500
    },
    '&>div>p': {
      marginTop: theme.spacing(1),
      fontSize: '14px',
      fontWeight: 400,
      color: 'inherit'
    },
  },
  agentDetailsOuter: {
    padding: theme.spacing(4)
  },
  stepHeader: {
    marginBottom: theme.spacing(1)
  },
  dividerRightMargin: {
    marginRight: theme.spacing(2),
  },
  portletMainClass: {
    marginTop: theme.spacing(1)
  },
  imageHolder: {
    maxWidth: '200px',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    height: '200px',
    objectFit: 'cover'
  },
  imageHolderDialog: {
    maxWidth: '200px',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    height: '200px',
    objectFit: 'cover'
  },
  imageTag: {
    width: '100%',
    cursor: 'pointer',
    height: '200px',
  },
  boldFont: {
    fontWeight: 500
  },
  pdfIcon: {
    width: '100%',
    height: '100%',
  }
});
