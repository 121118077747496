import React, { Component, Fragment, useEffect } from 'react';
import classNames from 'classnames';
import { Portlet, PortletContent, PortletHeader, PortletLabel, PortletFooter } from 'components';
import {
    Grid,
    Typography,
    Divider,
    Button,
    TextField,
    IconButton,
    MenuItem,
    Zoom,
    Fade,
    withStyles,
    CircularProgress,
    withWidth
} from '@material-ui/core';
import compose from "recompose/compose";
// import _ from 'lodash';
import styles from "./styles";
import { withSnackbar } from 'notistack';
// import schema from './schema';
// import { Store } from 'Store';
// import { HANDLE_FIELD_CHANGE } from 'store/types';
// import { HANDLE_VALIDATIONS } from 'store/types';
// import { START_LOADING } from 'store/types';
// import { RESET_APP_STATE } from 'store/types';
// import { STOP_LOADING } from 'store/types';
import { capitalizeFirstLetterForEveryword, showSuccessNotification } from 'common/helpers';
import { showErrorNotification } from 'common/helpers';
import { SubmitHelpRequestAPI } from 'services/others';
import PhoneIcon from '@material-ui/icons/PhonelinkRing';
import { IsValidSemiAuth, GetSemiAuthStateStorage, GetStateStorage } from 'common/storage';
import { OnLoadRegisterAgentData } from 'services/agent';
import HelpListForm from '../HelpListForm';
import PropTypes from 'prop-types';
import HelpPrimaryDropdwonData from '../../HelpDropDownData.json';
import { withRouter } from 'react-router-dom';
import helpUtils from '../../HelpUtils';


const HelpSubmitForm = (props) => {
    // const { state, dispatch } = React.useContext(Store);
    const [isRequest, setIsRequest] = React.useState(true);
    const [showMessage, setShowMessage] = React.useState(false);
    const [showContactBox, setShowContactBox] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    /**semi auth method */
    /**Check for semi auth mode */
    const isSemiAuth = IsValidSemiAuth();
    const [isSemiLogin, setSemiLogin] = React.useState(isSemiAuth);
    const [semiStorageData, setSemiStorageData] = React.useState({});
    /**form data */
    const [subject, setSubject] = React.useState(null);
    const [message, setMessage] = React.useState('');
    const [isLoading, setLoading] = React.useState(false);
    const [subjectTouched, setSubjectTouched] = React.useState(false);
    const [messageTouched, setMessageTouched] = React.useState(false);
    // const {
    //     values,
    //     touched,
    //     errors,
    //     submitError,
    //     isValid,
    // } = state;

    const { classes, className, primaryDropdwonData, shortDialogMode, handleCloseHelpRequestDialog, accessedFromAreaID } = props;
    //const isMobile = ['xs', 'sm', 'md'].includes(props.width);
    //const [isDeviceMobile, setDeviceMobile] = React.useState(false);
    const rootClassName = classNames(className);

    /**error controller */
    // const showSubjectError = touched.subject && errors.subject;
    // const showMessageError = touched.message && errors.message;

    // useEffect(() => {
    //     /**useful to handle device resolution changes */
    //     if (isMobile) {
    //         setDeviceMobile(true);
    //     } else {
    //         setDeviceMobile(false);
    //     }
    // }, [isMobile]);

    // useEffect(() => {
    //     /**only call when you don't have null value into local state data */
    //     /**chexk for semi login also */
    //     if (!isSemiLogin) {
    //         dispatch({
    //             type: HANDLE_FIELD_CHANGE,
    //             fieldType: 'textbox',
    //             value: (
    //                 (state.userData.listgenUserFirstName ? state.userData.listgenUserFirstName : '')
    //                 + ' ' +
    //                 (state.userData.listgenUserLastName ? state.userData.listgenUserLastName : '')
    //             ),
    //             name: 'name',
    //         });
    //         dispatch({
    //             type: HANDLE_FIELD_CHANGE,
    //             fieldType: 'textbox',
    //             value: (
    //                 state.userData.emailAddress ? state.userData.emailAddress : ''
    //             ),
    //             name: 'email',
    //         });
    //         dispatch({
    //             type: HANDLE_FIELD_CHANGE,
    //             fieldType: 'textbox',
    //             value: (
    //                 state.userData.mobilePhoneNo ? state.userData.mobilePhoneNo : ''
    //             ),
    //             name: 'phone',
    //         });
    //     }
    // }, []);

    // useEffect(() => {
    //     if (Object.keys(semiStorageData).length > 0) {
    //         dispatch({
    //             type: HANDLE_FIELD_CHANGE,
    //             fieldType: 'textbox',
    //             value: (
    //                 (semiStorageData.listgenUserFirstName ? semiStorageData.listgenUserFirstName : '')
    //                 + ' ' +
    //                 (semiStorageData.listgenUserLastName ? semiStorageData.listgenUserLastName : '')
    //             ),
    //             name: 'name',
    //         });
    //         dispatch({
    //             type: HANDLE_FIELD_CHANGE,
    //             fieldType: 'textbox',
    //             value: (
    //                 semiStorageData.emailAddress ? semiStorageData.emailAddress : ''
    //             ),
    //             name: 'email',
    //         });
    //         dispatch({
    //             type: HANDLE_FIELD_CHANGE,
    //             fieldType: 'textbox',
    //             value: (
    //                 semiStorageData.mobilePhoneNo ? semiStorageData.mobilePhoneNo : ''
    //             ),
    //             name: 'phone',
    //         });
    //     }
    // }, [semiStorageData]);

    const getRegisterAgentOnLoadData = (emailAddress) => {
        try {
            OnLoadRegisterAgentData(emailAddress)
                .then(data => {
                    if (Object.keys(data).length > 0) {
                        setSemiStorageData(data);
                    } else {
                        setSemiStorageData({});
                    }
                })
                .catch(err => {
                    setSemiStorageData({});
                });
        } catch (err) {
            setSemiStorageData({});
        }
    };

    useEffect(() => {
        if (isSemiLogin) {
            /**yes we have semi session */
            const semiTempStorageData = GetSemiAuthStateStorage();
            getRegisterAgentOnLoadData(semiTempStorageData.emailAddress);
        }
    }, [isSemiLogin]);

    // useEffect(() => {
    //     getRegisterAgentOnLoadData("annettevait@bhhscal.com");
    // }, [])

    //cleanup function
    // useEffect(() => {
    //     return () => {
    //         dispatch({
    //             type: RESET_APP_STATE
    //         });
    //     };
    // }, []);

    const handlePartialCleanup = () => {
        setSubjectTouched(false);
        setMessageTouched(false);
        setSubject('');
        setMessage('');
        // dispatch({
        //     type: HANDLE_FIELD_CHANGE,
        //     fieldType: 'textbox',
        //     value: '',
        //     name: 'subject',
        // });
        // dispatch({
        //     type: HANDLE_FIELD_CHANGE,
        //     fieldType: 'textbox',
        //     value: '',
        //     name: 'message',
        // });
    }

    // const validateForm = _.debounce(() => {
    //     dispatch({
    //         type: HANDLE_VALIDATIONS,
    //         schema
    //     });
    // }, 500);

    const capitalizeFirstLetter = (inputString) => {
        return inputString.charAt(0).toUpperCase() + inputString.slice(1);
    }

    const closeTicketAfterSubmit = () => {
        //reset the subject and message field
        // dispatch({
        //     type: RESET_APP_STATE
        // });
        const { history } = props;
        try {
            if (history.length === 1 && (props.location.search || "").indexOf("mode=window") !==-1){
                //if we have opned in a diff table with mode window then we want to close tab
                window.close()
            } else {
                history.goBack();
            }
        } catch (err) {
            history.push('/home');
        }
    };

    const submitaNewTicket = () => {
        //reset the subject and message field
        handlePartialCleanup();
        setIsSuccess(false);
    };

    const handleHelpRequestClose = (event) => {
        if(!shortDialogMode){
            return closeTicketAfterSubmit()
        } else if(shortDialogMode) {
            return handleCloseHelpRequestDialog()
        }
        //if we have opned in a diff table with mode window then we want to close tab
        if((props.location.search || "").indexOf("mode=window") !==-1){
            window.close()
        }

    }

    const getTicketType = (index) => {
        let isRequest = primaryDropdwonData[index].isRequest;
        let callDirect = primaryDropdwonData[index].callDirect;
        if (callDirect) {
            setShowContactBox(true);
            setIsRequest(false);
            setShowMessage(false);
        } else {
            setShowContactBox(false);
            setIsRequest(isRequest);
            setShowMessage(true);
        }

    };

    const handleListSelection = (index, value) => {
        setSubject(value);
        setSubjectTouched(true);
        // dispatch({
        //     type: HANDLE_FIELD_CHANGE,
        //     fieldType: 'textbox',
        //     value: value,
        //     name: 'subject',
        // });
        //validateForm();
        if (index || index === 0) {
            //call only if we have anything selected. not call on unselect
            getTicketType(index);
        }
    };

    const getLocalData = () => {
        let data = {};
        if (isSemiLogin) {
          data = semiStorageData;
        } else {
          let localStateData = GetStateStorage();
          data =
            localStateData && localStateData.userData ? localStateData.userData : {};
        }
        return data;
      };

    const handleSubmit = () => {
        /**make loading true */
        try {
            setLoading(true);
            const { listgenUserID, name, email, phone } = helpUtils.getHelpObject(null, getLocalData);
            const {cityName, areaNames} = helpUtils.getAgentAreasandCityName(accessedFromAreaID, getLocalData);
            SubmitHelpRequestAPI(
                listgenUserID,
                name, email, phone, helpUtils.subjectBuilder(getLocalData), message, (isRequest ? 'request' : 'suggestion/idea'), capitalizeFirstLetterForEveryword(cityName.toLowerCase()), capitalizeFirstLetterForEveryword(areaNames.toLowerCase())).then(data => {
                    switch (data) {
                        case true:
                            if (!shortDialogMode) {
                                showSuccessNotification(props, "Request submitted.");
                            }
                            /**clean the form */
                            // dispatch({
                            //     type: RESET_APP_STATE
                            // });
                            handlePartialCleanup();
                            setIsSuccess(true);
                            break;
                        case false:
                            showErrorNotification(props, "Some unexpected error occured.");
                            break;
                        default:
                            showErrorNotification(props, "Something went wrong. Please try after some time.");
                            break;
                    }
                    /**stop loading also */
                    setLoading(false);

                }).catch(err => {
                    showErrorNotification(props, "Unable to process request.");
                    setLoading(false);

                });
        } catch (err) {
            console.log('errp==')
            console.log(err);
            showErrorNotification(props, "Unable to process request.");
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <div className={classes.portletHolder}>
                <Grid className={classes.grid} container>
                    {!shortDialogMode ? <Grid item lg={2} sm={2} xs={2} md={2}></Grid> : ''}
                    <Grid item lg={shortDialogMode ? 12 : 8} sm={8} xs={8} md={shortDialogMode ? 12 : 8}>
                        {!isSuccess ?
                            <Portlet
                                className={rootClassName}
                                outlined={!shortDialogMode}
                            >
                                <PortletHeader className={classes.portletHeader}>
                                    <PortletLabel title={<span>How can we help you?&nbsp;&nbsp;&nbsp;Select an issue and type your comments.</span>} />
                                </PortletHeader>
                                <PortletContent noPadding className={classes.marginBottomPortletContent}>
                                    <form
                                        autoComplete="off"
                                        noValidate
                                    >
                                        <Grid className={classes.grid} container>
                                            <Grid item lg={12} sm={12} xs={12} md={12}>
                                                <div className={classes.field}>
                                                    {/* <Typography variant="subtitle2" align="left" className={classes.startHeadingSection}>
                                                        
                                                    </Typography> */}
                                                    <div className={classes.helpListForm}>
                                                        <HelpListForm
                                                            primaryDropdwonData={primaryDropdwonData}
                                                            handleListSelection={handleListSelection}
                                                        />
                                                        {subjectTouched && !subject && (
                                                            <Typography
                                                                className={classes.fieldError}
                                                                variant="body2"
                                                            >
                                                                Help topic is required
                                                            </Typography>
                                                        )}
                                                    </div>
                                                    {/* <div className={classes.contactBox}>
                                                        <div className={classes.iconHolder}>
                                                            <div>
                                                                <IconButton
                                                                    className={classes.backButton}
                                                                >
                                                                    <PhoneIcon className={classes.iconLarge} />
                                                                </IconButton>
                                                            </div>

                                                        </div>
                                                        <Typography variant="h6" align="left">
                                                            Call our Support team directly at&nbsp;
                                                        </Typography>
                                                        <Typography variant="h4">(800) 922-9750</Typography>
                                                    </div> */}
                                                    {/* <TextField
                                                        id="outlined-select-subject"
                                                        select
                                                        label={showMessage ? 'Selected Help Subject' : 'How can we help you?'}
                                                        className={classes.textField}
                                                        value={state.values.subject}
                                                        onChange={(event, index) => {
                                                            dispatch({
                                                                type: HANDLE_FIELD_CHANGE,
                                                                fieldType: event.target.type,
                                                                value: event.target.value,
                                                                name: 'subject',
                                                            });
                                                            validateForm();
                                                            getTicketType(index);
                                                        }
                                                        }
                                                        SelectProps={{
                                                            MenuProps: {
                                                                className: classes.menu,
                                                            },
                                                        }}
                                                        margin="dense"
                                                        variant="outlined"
                                                    >
                                                        {primaryDropdwonData.map((option, key) => (
                                                            <MenuItem
                                                                key={key}
                                                                value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                    {showSubjectError && (
                                                        <Typography
                                                            className={classes.fieldError}
                                                            variant="body2"
                                                        >
                                                            {errors.subject[0]}
                                                        </Typography>
                                                    )} */}
                                                </div>

                                            </Grid>
                                        </Grid>
                                        {showMessage ?
                                            <Grid className={classNames(classes.grid, classes.messageGrid)} container>
                                                <Grid item lg={12} sm={12} xs={12} md={12}>
                                                    <Grid className={classes.grid} container>
                                                        <Grid item lg={12} sm={12} xs={12} md={12}>
                                                            <div className={classNames(classes.field, classes.marginBottomField)}>
                                                                <TextField
                                                                    className={classNames(classes.textField)}
                                                                    label="Please give us an explanation of what your issue or suggestion is."
                                                                    margin="dense"
                                                                    multiline
                                                                    rows="4"
                                                                    value={message}
                                                                    variant="outlined"
                                                                    onChange={event => {
                                                                        setMessageTouched(true);
                                                                        setMessage(event.target.value);
                                                                        // dispatch({
                                                                        //     type: HANDLE_FIELD_CHANGE,
                                                                        //     fieldType: event.target.type,
                                                                        //     value: event.target.value,
                                                                        //     name: 'message',
                                                                        // });
                                                                        // validateForm();
                                                                    }
                                                                    }
                                                                    inputProps={{
                                                                        maxLength: 3000
                                                                    }}
                                                                />
                                                                {messageTouched && !message && (
                                                                    <Typography
                                                                        className={classes.fieldError}
                                                                        variant="body2"
                                                                    >
                                                                        Message is required
                                                                    </Typography>
                                                                )}
                                                            </div>

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            : ''}
                                        {/* {showContactBox ?
                                            <Fragment>
                                                <Grid container className={classes.grid}>
                                                    <Grid item lg={12} sm={12} xs={12} md={12}>
                                                        <Fade in={true} style={{ transitionDelay: '300ms' }}>
                                                            <div className={classes.dialogText}>
                                                                {generateContactHeading()}
                                                            </div>
                                                        </Fade>
                                                        <Zoom
                                                            in={true}
                                                            style={{ transformOrigin: '0 0 0', transitionDelay: '600ms' }}
                                                        >
                                                            <div className={classes.contactBox}>
                                                                <div className={classes.iconHolder}>
                                                                    {/* <div>
                                                                        <IconButton
                                                                            className={classes.backButton}
                                                                        >
                                                                            <PhoneIcon className={classes.iconLarge} />
                                                                        </IconButton>
                                                                    </div> */}
                                        {/* <div>
                                                                        <Typography variant="h4">(800) 922-9750</Typography>
                                                                    </div>
                                                                </div> */}
                                        {/* {isDeviceMobile ?
                                                                    <div className={classes.dialNumber}>
                                                                        <Divider />
                                                                        <Button
                                                                            color="primary"
                                                                            variant="outlined"
                                                                            className={classes.dialNumberButton}
                                                                            onClick={
                                                                                event => {
                                                                                    dialNumber();
                                                                                }
                                                                            }
                                                                        >
                                                                            Dial Number
                                                                    </Button>
                                                                    </div>
                                                                    : ''} */}
                                        {/* </div>
                                                        </Zoom>
                                                    </Grid>
                                                </Grid>
                                            </Fragment>
                                            :
                                            ''} */}
                                    </form>

                                </PortletContent>
                                {true ?
                                    <PortletFooter className={classes.portletFooter}>
                                        <div className={classes.footerButtons}>
                                            <div className={classes.footerButtonsLeftRight}>
                                                {!shortDialogMode ?
                                                    //no need to call becuase it will transfer to last page from history
                                                    <Button onClick={event => {
                                                        closeTicketAfterSubmit()
                                                    }}
                                                        color="default"
                                                        variant="outlined"
                                                    >
                                                        Cancel
                                                    </Button>
                                                    : ''}
                                            </div>
                                            <div className={classes.footerButtonsCenter}>
                                                {isLoading ? (
                                                    <CircularProgress className={classes.progress} />
                                                ) : (
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        disabled={subject === '' || message === ''}
                                                        onClick={event => handleSubmit()}
                                                    >
                                                        Submit
                                                    </Button>
                                                )}
                                            </div>
                                            <div className={classes.footerButtonsLeftRight}></div>
                                        </div>
                                    </PortletFooter>
                                    : ''}
                            </Portlet>
                            :
                            <Portlet
                                className={rootClassName}
                            >
                                <PortletContent noPadding className={classes.marginBottomPortletContent}>
                                    <div className={classNames(classes.dialogText, classes.successText)}>
                                        <Typography variant="subtitle1" className={classes.fontSize18px}>
                                            Hi{(helpUtils.getHelpObject('listgenUserFirstName', getLocalData) ? ', ' + capitalizeFirstLetter(helpUtils.getHelpObject('listgenUserFirstName', getLocalData)) : '')}
                                        </Typography>
                                        <Typography variant="subtitle1" className={classes.fontSize18px}>
                                            Thank you for your {isRequest ? 'SUPPORT request' : 'suggestion/idea'}.
                                        </Typography>
                                        <Typography variant="subtitle1" className={classes.fontSize18px}>
                                            One of our team members will contact you shortly.
                                        </Typography>
                                    </div>
                                    {/* <div className={classNames(classes.dialogText, classes.successText)}>
                                        <Typography variant="subtitle1" className={classes.fontSize18px}>
                                            Thank you {(state.userData.listgenUserFirstName ? capitalizeFirstLetter(state.userData.listgenUserFirstName) : '')} for your {isRequest ? 'request' : 'suggestion/idea'}!
                    </Typography>
                                        <Typography variant="subtitle1" className={classes.fontSize18px}>
                                            Your {isRequest ? 'request' : 'suggestion/idea'} has been received.
                    </Typography>
                                        <Typography variant="subtitle1" className={classes.fontSize18px}>
                                            One of our tech support people will contact you ASAP!
                        </Typography>
                                        <Typography variant="subtitle2" color="primary" className={classes.fontSize20px}>
                                            The ListGen Team
                        </Typography>
                                    </div> */}
                                </PortletContent>
                                <PortletFooter className={classes.portletFooter}>
                                    <div className={classes.footerButtons}>
                                        <div className={classes.footerButtonsLeftRight}></div>
                                        <div className={classes.footerButtonsCenter}>
                                            <Button onClick={event => {
                                                submitaNewTicket()
                                            }} color="primary"
                                                variant="outlined"
                                            >
                                                Submit a New Ticket
                                            </Button>

                                            <Button onClick={handleHelpRequestClose} color="default"
                                                variant="outlined"
                                                style={{ marginLeft: '16px' }}
                                            >
                                                Close
                                            </Button>

                                        </div>
                                        <div className={classes.footerButtonsLeftRight}></div>
                                    </div>
                                </PortletFooter>
                            </Portlet>
                        }
                    </Grid>
                    {!shortDialogMode ? <Grid item lg={2} sm={8} xs={2} md={2}></Grid> : ''}
                </Grid>
            </div>

        </Fragment>
    );
};

HelpSubmitForm.propTypes = {
    primaryDropdwonData: PropTypes.array,
    shortDialogMode: PropTypes.bool,
    handleCloseHelpRequestDialog: PropTypes.func
};

HelpSubmitForm.defaultProps = {
    primaryDropdwonData: HelpPrimaryDropdwonData,
    shortDialogMode: false,
    handleCloseHelpRequestDialog: () => { }
};


export default compose(
    withStyles(styles),
    withSnackbar,
    withRouter,
    withWidth()
)(HelpSubmitForm);