import React, { Component } from "react";
import QVFourCoverPage from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourCoverPage";

class QVThreeFirstRight extends Component {
  render() {
    const {
      agentData,
      coverPageDetails,
      currentArea,
      hoData,
      aprilData,
      printVersion,
      demoAccountCoverPhoto,
      demoAccountAreaName,
      quarterlyAreaTable,
      version,
      typeOfVersion,
    } = this.props;
    const {
      currentSoldListings,
      marketHighLightsData,
    } = aprilData.pmaQuarterlyAreaDTO;
    // console.log(this.props.coverPageDetails);
    return (
      <div className="qv3-first-right">
        <QVFourCoverPage
          agentData={agentData}
          currentArea={currentArea}
          coverPageDetails={coverPageDetails}
          hoData={hoData}
          printVersion={printVersion}
          demoAccountCoverPhoto={demoAccountCoverPhoto}
          demoAccountAreaName={demoAccountAreaName}
          quarterlyAreaTable={aprilData.pmaQuarterlyAreaDTO}
          currentSoldListings={currentSoldListings}
          marketHighLightsData={marketHighLightsData}
          photoNum="0"
          version={version}
          printVersion={printVersion}
          only6Months={true}
          typeOfVersion={typeOfVersion}
          printDate="December 6th, 2021"
          yearEnd={false}
        />
      </div>
    );
  }
}

export default QVThreeFirstRight;
