import React from "react";
import moment from "moment";
import { renderLogicUtil } from "views/Pma/RenderLogicUtil";
import MailProButton from "../../UI/MailProButton";
import PropertyImageSliderDialog from "../../PropertyImageSliderDialog";
import classNames from "classnames";
import { propertyUtil } from "views/Pma/PropertyUtil";
import EditTagsDialog from "../../EditTagsDialog";
import { getHONamesBaseDataClosureV2, getHONamesOriginalDataClosureV2 } from "../../MailProHTMLShortMethods";

const AnniversaryAutomatorDialogHeaderExtra = ({
  classes,
  property,
  propertySequenceNumber,
  forceBlockEditing,
  isMailProLocked,
  ...rest
}) => {
  const { formattedSale, formattedSold } =
    renderLogicUtil.getFormattedSaleDateandPrice(property);

  const { tags, customTags } = renderLogicUtil.getTagsAndCustomTags(
    property.changeDetails || {}
  );

  const tagCount = (tags || []).length;

  const { lgIsOwnerNameModified } = property.changeDetails;

  const { xOwner1First, xOwner1Last, xOwner2First, xOwner2Last } =  getHONamesBaseDataClosureV2().call(this, property);
    // (lgIsOwnerNameModified ? getHONamesBaseDataClosureV2() : getHONamesOriginalDataClosureV2()).call(this, property);

  return (
    <div className={classes.headerExtra}>
      <div
        style={{ marginBottom: "-8px" }}
        className={classNames(classes.headerButtonandExtraFirst, classes.flexEnd)}
      >
        <PropertyImageSliderDialog
          propertyDetails={property.propertyDetails || {}}
          mailingDetails={property.mailingDetails || {}}
          propertySequenceNumber={propertySequenceNumber}
          property={property}
          customStyle={{ children: { fontSize: "14px !important" } }}
        />
      </div>
      <div style={{ textAlign: "center" }}>
        <div style={{textTransform: 'uppercase', fontWeight: 800}}>
          <p>
            {xOwner1First}&nbsp;{xOwner1Last}
          </p>
          {(xOwner2First) && (
            <p>
              {xOwner2First}&nbsp;{xOwner2Last}
            </p>
          )}
        </div>
        <div className={classes.specialGlowText}>
          {" "}
          {propertyUtil.daySuffixMailPro(
            renderLogicUtil.getHOAnniversaryNumber(property)
          )}{" "}
          ANNIVERSARY!{" "}
        </div>
        {property.HOQuickFilterData.isAbsentee && (
          <div className={classes.absenteeHO}>ABSENTEE HOMEOWNER</div>
        )}
        {tagCount > 0 && (
          <EditTagsDialog
            agentID={property.agentID}
            areaID={property.areaID}
            mailerProID={property.mailerProID}
            mailingStatus={property.mailingStatus}
            changeDetails={property.changeDetails}
            propertyDetails={property.propertyDetails}
            propertyData={rest.propertyData}
            agentData={rest.agentData}
            propertySequenceNumber={propertySequenceNumber}
            property={property}
            isMailProLocked={isMailProLocked}
          >
            <div className={classes.tagHO}>
              This Homeowner has {tagCount} {tagCount > 1 ? 'TAGS' : 'TAG'}
            </div>
          </EditTagsDialog>
        )}
      </div>

      {formattedSold ? (
        <div
          className={classNames(
            classes.headerButtonandExtraThird,
            classes.headerButtonandExtraDisplay,
            classes.flexStart
          )}
          style={{ textAlign: "center" }}
        >
          Purchased:{" "}
          {formattedSold
            ? moment(new Date(formattedSold)).format("MMM DD, YYYY")
            : "Sale Date Unknown"}{" "}
          <br />
          @&nbsp;{formattedSale ? "$" + formattedSale : "Sale Price Unknown"}
        </div>
      ) : (
        <div
          className={classNames(
            classes.headerButtonandExtraThird,
            classes.headerButtonandExtraDisplay,
            classes.flexStart
          )}
        ></div>
      )}
    </div>
  );
};

export default AnniversaryAutomatorDialogHeaderExtra;
