import React, {
  Component,
  Fragment,
  useState,
  useEffect,
  useContext,
} from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "../styles";
import styles2 from "./styles.scss";
import "./styles.scss";
import { Dashboard as DashboardLayout } from "layouts";
import { withSnackbar } from "notistack";
import { Portlet } from "components";
import { PortletContent } from "components";
import { PortletHeader } from "components";
import classNames from "classnames";
import { GetAreaPropHighlights } from "services/others";
import Base from "common/constants";
import {
  GetStateStorage,
  GetNavbarDataFromStorage,
  IsValidSessionSwitch,
} from "common/storage";
import Quadrant from "./Quadrant";
import moment from "moment";
import { GetPmaAdminDataAPI } from "services/agent";
import { showErrorNotification } from "common/helpers";
import { showSuccessNotification } from "common/helpers";
// import LinearProgress from "@mui/material/LinearProgress";
import { withRouter } from "react-router-dom";
import { Store } from "Store";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";
import { propertyUtil } from "views/Pma/PropertyUtil";
import EditQuadrantModal from "./EditQuadrantModal";

const QuadrantEditor = (props) => {
  const { classes } = props;
  const { dispatch } = useContext(Store);
  //session related variables
  const isAValidSessionSwitch = IsValidSessionSwitch();
  const { userData } = GetStateStorage();

  /**loading variables */
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);

  const [currentArea, setCurrentArea] = useState({});
  const [highlightedList, setHighlightedList] = useState([]);
  const [selectedQuadrant, setSelectedQuadrant] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [apiQuadrantsList, setApiQuadrantsList] = useState([]);
  const [editQuadrantFormData, setEditQuadrantFormData] = React.useState({
    title: "",
    text: "",
    isCustom: false,
  });
  const [oldQuadrantData, setOldQuadrantData] = React.useState({});
  const [showEditDialog, setEditDialog] = React.useState(false);

  //checkf or admin
  const isAdminAccess =
    isAValidSessionSwitch ||
    userData.listgenRoleID === Base.APP_DATA.APP_ADMIN_ROLE_ID;
  const stateData = GetStateStorage();

  let { navbarInfo } = stateData.userData;

  //call useEffect with api Call
  let defaultQuadrant = {
    title: "DefaultTitle",
    text:
      "Default text Default text Default text Default text Default text Default text",
    isCustom: false,
  };

  const { areaID, position } = props.match.params;

  useEffect(() => {
    const filteredArea = navbarInfo.filter(
      (area) => area.mlsAreaID === props.match.params.areaID
    )[0];

    setCurrentArea(filteredArea);
    setHighlightedList([filteredArea.mlsAreaID]);
    setSelectedQuadrant(defaultQuadrant);

    let apiQuadrants = [
      {
        title: "DOWNSIZING",
        text:
          "We review with you items to keep, gift, sell & donate, then coordinate distribution for you.",
        isCustom: false,
      },
      {
        title: "RELOCATION",
        text:
          "We pack your belongings, coordinate logistics, and set up your new home.",
        isCustom: false,
      },
      {
        title: "CLEAN-OUT",
        text:
          "Upon completion of moving, sales, and donations, we will leave your home real estate ready.",
        isCustom: false,
      },
      {
        title: "ESTATE SALES",
        text:
          "In addition to our local sales effort, our national online auctions favor us to sell to a broader market.",
        isCustom: false,
      },
    ];
    setApiQuadrantsList(apiQuadrants);
  }, []);

  const handleFormClose = () => {
    setEditDialog(false);
  };

  const isHighlighted = (areaID) => {
    if (highlightedList.includes(areaID)) {
      return true;
    } else {
      return false;
    }
  };
  const handleOpenEditDialog = (type) => {
    console.log("*****8inside edit dialog", type);

    setOldQuadrantData(selectedQuadrant);
    setEditQuadrantFormData(selectedQuadrant);
    setEditDialog(true);
  };

  const handleHighlight = (areaID) => {
    let isCurrent = areaID === currentArea.mlsAreaID;
    if (areaID === currentArea.mlsAreaID) {
      return;
    } else if (highlightedList.includes(areaID)) {
      let filteredOut = highlightedList.filter((id) => id !== areaID);
      setHighlightedList(filteredOut);
    } else {
      setHighlightedList([...highlightedList, areaID]);
    }
  };
  const handleRestore = () => {
    setSelectedQuadrant(defaultQuadrant);
  };

  const handleQuadrantSelection = (quadrant) => {
    quadrant.isCustom = true;
    const { title } = quadrant;
    setSelectedQuadrant(quadrant);
    window.scrollTo(0, 110);
  };

  const handleSubmit = () => {
    //api call to backend
    const { areaID, position } = props.match.params;

    let newQuadrantObj = setSubmitting(true);
    const agentData = stateData.userData;
    let navbarData =
      agentData &&
      Array.isArray(agentData.navbarInfo) &&
      agentData.navbarInfo[0];
    setTimeout(() => {
      props.history.push(`/pma/${navbarData.mlsAreaID}`);
    }, 1300);
  };
  //add quadrant styles

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    console.log("name is", name);
    //console.log("inside handle input change", editPropFormData[name]);
    setEditQuadrantFormData({
      ...editQuadrantFormData,
      [e.target.name]: value,
    });
  };

  const onEditFormSubmit = () => {
    handleFormClose();
  };
  const { type } = props.match.params;
  console.log("props.match", props.match);

  const agentData = stateData.userData;
  let navbarData =
    agentData && Array.isArray(agentData.navbarInfo) && agentData.navbarInfo[0];
  if (type === "title" || type === "subtitle") {
    return (
      <Fragment>
        <DocumentTitle title="Quadrant Editor">
          <DashboardLayout title="" isCompleteInvisible={true}>
            <div className={classes.root}>
              <Portlet className={classes.contentPadded}>
                <PortletContent noPadding>
                  {showEditDialog ? (
                    <EditQuadrantModal
                      oldQuadrantData={oldQuadrantData}
                      onCloseFn={handleFormClose}
                      handleInputChange={handleInputChange}
                      editQuadrantFormData={editQuadrantFormData}
                      onEditFormSubmit={onEditFormSubmit}
                    />
                  ) : (
                    ""
                  )}

                  <div className="quadrant-editor">
                    <Link
                      to={`/pma/${navbarData.mlsAreaID}`}
                      className="return-to-pma-btn"
                    >
                      <div>Return to PMA Editor</div>
                    </Link>
                    <div className="area-list">
                      {navbarInfo.map((area, id) => (
                        <div
                          className={`area-button ${
                            isHighlighted(area.mlsAreaID) === true &&
                            "highlighted-btn"
                          }`}
                          onClick={() => handleHighlight(area.mlsAreaID)}
                        >
                          <div className="area-name" key={area.mlsAreaID}>
                            {area.mlsNeighborhood}
                          </div>
                        </div>
                      ))}
                    </div>
                    {navbarInfo.length > 1 && (
                      <div className="area-select-instructions">
                        CLICK ON ONE THE AREA NAME(S) YOU WANT THIS TEXT TO
                        PRINT ON THE PMAs
                      </div>
                    )}

                    <div className="quadrant-title-instructions">
                      <div>
                        You can edit the provided content or select another
                        option by scrolling down.
                      </div>
                    </div>
                    <div className="quadrant-body">
                      <div
                        className="edit-btn edit-title"
                        onClick={() => handleOpenEditDialog()}
                      >
                        <div>Edit Title and/or Text</div>
                      </div>

                      <div className="submit-btn-container">
                        <div
                          className="submit-btn"
                          onClick={() => handleSubmit()}
                        >
                          {submitting ? (
                            <div className="submit-btn-text">Loading...</div>
                          ) : (
                            <div className="submit-btn-text">
                              Looks Good — Update & Continue
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </PortletContent>
              </Portlet>
            </div>
          </DashboardLayout>
        </DocumentTitle>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <DocumentTitle title="Quadrant Editor">
          <DashboardLayout title="" isCompleteInvisible={true}>
            <div className={classes.root}>
              <Portlet className={classes.contentPadded}>
                <PortletContent noPadding>
                  {showEditDialog ? (
                    <EditQuadrantModal
                      oldQuadrantData={oldQuadrantData}
                      onCloseFn={handleFormClose}
                      handleInputChange={handleInputChange}
                      editQuadrantFormData={editQuadrantFormData}
                      onEditFormSubmit={onEditFormSubmit}
                    />
                  ) : (
                    ""
                  )}

                  <div className="quadrant-editor">
                    <Link
                      to={`/pma/${navbarData.mlsAreaID}`}
                      className="return-to-pma-btn"
                    >
                      <div>Return to PMA Editor</div>
                    </Link>
                    <div className="area-list">
                      {navbarInfo.map((area, id) => (
                        <div
                          className={`area-button ${
                            isHighlighted(area.mlsAreaID) === true &&
                            "highlighted-btn"
                          }`}
                          onClick={() => handleHighlight(area.mlsAreaID)}
                        >
                          <div className="area-name" key={area.mlsAreaID}>
                            {area.mlsNeighborhood}
                          </div>
                        </div>
                      ))}
                    </div>
                    {navbarInfo.length > 1 && (
                      <div className="area-select-instructions">
                        CLICK ON ONE THE AREA NAME(S) YOU WANT THIS TEXT TO
                        PRINT ON THE PMAs
                      </div>
                    )}

                    <div className="quadrant-title-instructions">
                      <div>
                        You can edit the provided content or select another
                        option by scrolling down.
                      </div>
                    </div>
                    <div className="quadrant-body">
                      <div className="selected-quadrant-row">
                        <div
                          className="restore-btn"
                          onClick={() => handleRestore()}
                        >
                          <div>Restore to Original</div>
                        </div>
                        <Quadrant
                          title={selectedQuadrant.title}
                          text={selectedQuadrant.text}
                        />
                        <div
                          className="edit-btn edit-title"
                          onClick={() => handleOpenEditDialog()}
                        >
                          <div>Edit Title and/or Text</div>
                        </div>
                      </div>
                      <div className="submit-btn-container">
                        <div
                          className="submit-btn"
                          onClick={() => handleSubmit()}
                        >
                          {submitting ? (
                            <div className="submit-btn-text">Loading...</div>
                          ) : (
                            <div className="submit-btn-text">
                              Looks Good — Update & Continue
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="quadrant-divider"></div>
                      <div className="quadrant-selector-text">
                        Or you may select any of the following 10 options
                      </div>
                    </div>
                    {apiQuadrantsList.map((quadrant, id) => {
                      return (
                        <div
                          className="quadrant-selection-row"
                          onClick={() => handleQuadrantSelection(quadrant)}
                        >
                          <div className="select-quadrant-button-container">
                            <div className="select-quadrant-button">
                              <div>Click to Select</div>
                            </div>
                          </div>
                          <Quadrant
                            title={quadrant.title}
                            text={quadrant.text}
                          />
                          <div className="empty-div"></div>
                        </div>
                      );
                    })}
                  </div>
                </PortletContent>
              </Portlet>
            </div>
          </DashboardLayout>
        </DocumentTitle>
      </Fragment>
    );
  }
};

QuadrantEditor.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose(
  withSnackbar,
  withStyles(styles),
  withRouter
)(QuadrantEditor);
