import React, { Fragment, Component, useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from "@material-ui/core";
import Slide from '@material-ui/core/Slide';
import classNames from 'classnames';
import styles from '../../styles';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { noteUtils } from '../Common/NoteUtils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GlobalNoteAreaChooserDialog = (props) => {
  const [open, setOpen] = useState(true);
  const [noteAreas, setNoteAreas] = useState([]);
  const [psAreas, setPSAreas] = useState([]);

  const { classes, currentArea, mlsAreas, closeFn, submitFn, currentEditMode } = props;

  const otherAreas = mlsAreas.filter(e => e.mlsAreaID !== currentArea.mlsAreaID && noteUtils.isAreaUnapproved(e));

  const handleClose = () => {
    setOpen(false);
    closeFn();
  };

  const handleSubmit = (event) => {
    setOpen(false);
    closeFn();
    //check is any checkbox selected
    if (noteAreas.length || psAreas.length) {
      submitFn(event, true, noteAreas, psAreas);
    } else {
      submitFn(event, false, [], []);
    }
  };

  const handleCheckboxData = (type, areaID) => {
    let selectedData = type === 'area' ? [...noteAreas] : [...psAreas];
    //let Fn = (type === 'area' ? setNoteAreas : setPSAreas);
    let areaIdIndex = selectedData.indexOf(areaID);
    if (areaIdIndex === -1) {
      selectedData.push(areaID)
    } else {
      //ara found remove it
      selectedData = selectedData.filter(id => id != areaID);
    }
    if (type === 'area') {
      setNoteAreas(selectedData);
    } else {
      setPSAreas(selectedData);
    }
  };

  // useEffect(() => {
  //   console.log('noteAreas uopdated==', noteAreas)
  // }, [noteAreas]);

  // useEffect(() => {
  //   console.log('psAreas uopdated==', psAreas)
  // }, [psAreas]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="global-note-area-view-dialog"
      aria-describedby="global-note-area-view-dialog-description"
      maxWidth={'md'}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
    >
      <DialogContent dividers={true} className={classes.dialogContentPadding}>
        <div className={classes.areaChooserMain}>
          <FormControl component="fieldset">
            {currentEditMode === 'globalNote' ?
              <Fragment>
                <FormLabel component="p" className={classNames(classes.areaChooserHeading, classes.lightBoldText)}>Check the area(s) you would like your new <span className={classes.primaryColor}>GLOBAL NOTE</span>  to be copied to</FormLabel>
                <FormGroup>
                  {otherAreas.map((area, key) => {
                    return (
                      <FormControlLabel
                        key={'note-' + key}
                        control={<Checkbox checked={noteAreas.indexOf(area.mlsAreaID) !== -1} onChange={event => handleCheckboxData('area', area.mlsAreaID)} name={'note' + area.mlsNeighborhood} value={'note' + area.mlsNeighborhood} color="primary" />}
                        label={area.mlsNeighborhood}
                      />
                    );
                  })}
                </FormGroup>
              </Fragment>
              : currentEditMode === 'globalPS' ?
                <Fragment>
                  <FormLabel component="p" className={classNames(classes.areaChooserHeading, classes.lightBoldText)} style={{ marginTop: '16px' }}>Check the area(s) you would like your new <span className={classes.primaryColor}>GLOBAL P.S.</span> to be copied to</FormLabel>
                  <FormGroup>
                    {otherAreas.map((area, key) => {
                      return (
                        <FormControlLabel
                          key={'ps-' + key}
                          control={<Checkbox checked={psAreas.indexOf(area.mlsAreaID) !== -1} onChange={event => handleCheckboxData('ps', area.mlsAreaID)} name={'ps-' + area.mlsNeighborhood} value={'ps-' + area.mlsNeighborhood} color="primary" />}
                          label={area.mlsNeighborhood}
                        />
                      );
                    })}
                  </FormGroup>
                </Fragment>
                : <FormLabel></FormLabel>}
          </FormControl>
        </div>
        <Fragment>
          <div className={classes.portletFooter}>
            <div className={classes.footerButtons}>
              <div className={classes.footerButtonsLeftRight}>
                <Button
                  variant="outlined"
                  color="default"
                  onClick={event => handleClose(event)}
                >
                  Cancel
                </Button>
              </div>
              <div className={classes.footerButtonsCenter}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={event => handleSubmit(event)}
                >
                  Submit
                </Button>
              </div>
              <div className={classNames(classes.footerButtonsLeftRight, classes.endAlignemnt)}></div>
            </div>
          </div>
        </Fragment>
      </DialogContent>
    </Dialog>
  );
}

export default compose(withStyles(styles))(GlobalNoteAreaChooserDialog);