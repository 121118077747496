import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import White40Percent from "./WhiteBox_2_wShadow_40perct.png";

class DashboardBodyRange extends Component {
  render() {
    const {
      rangeNum,
      baths,
      halfBaths,
      beds,
      dom,
      garageSpaces,
      golfView,
      sqft,
      lotSize,
      homesSold,
      oceanView,
      priceRangeLow,
      priceRangeHigh,
    } = this.props;
    const { roundSalePriceTenths, acreOrSqft, toAcre } = propertyUtil;
    return (
      <div className="dashboard-body-range">
        <div className="price-range-col">
          <div className="price-range-inner-container">
            <div className="price-range-text">
              {roundSalePriceTenths(priceRangeLow)}
              {+priceRangeHigh === "100000000"
                ? " & up"
                : ` - ${roundSalePriceTenths(priceRangeHigh)}`}
            </div>

            <div className="price-range-number">
              Average Price Range #{rangeNum}
            </div>
          </div>
        </div>
        <img
          className="range-details-img"
          src={White40Percent}
          alt="Transparent dashboard container"
        />
        <div
          className={`range-details-col-with-image range-detail-${rangeNum}`}
        >
          <div className="range-details-inner-container">
            <div className="range-property-details">
              <p>
                {homesSold} {homesSold === "1" ? "Home" : "Homes"} Sold
              </p>
              <p>
                {dom} {dom === "1" ? "Day" : "Days"} On Market
              </p>
            </div>
            <div className="property-attributes">
              <p className="property-attributes-title">
                Common Property Attributes
              </p>
              <p className="property-attributes-text">
                {Number(sqft).toLocaleString()} Square Feet
              </p>
              <p className="property-attributes-text">
                {beds} {beds === "1" ? "bedroom" : "bedrooms"}
              </p>
              <p className="property-attributes-text">
                {baths} full {baths === "1" ? "bath" : "baths"}{" "}
                {+halfBaths > 0
                  ? `& ${halfBaths}
                half ${halfBaths === "1" ? "bath" : "baths"}
              `
                  : ""}
              </p>
              {lotSize && +lotSize > 0 && (
                <p className="property-attributes-text">
                  {toAcre(Math.round(lotSize), true)}{" "}
                  {acreOrSqft(lotSize, true)} Lot Size
                </p>
              )}
              {garageSpaces && +garageSpaces > 0 && (
                <p className="property-attributes-text">
                  {garageSpaces} car garage
                </p>
              )}
              {+oceanView > 0 ? (
                <p className="property-attributes-text">
                  {oceanView}{" "}
                  {+oceanView === 1
                    ? "home has an ocean view"
                    : "homes have ocean views"}
                </p>
              ) : +golfView > 0 ? (
                <p className="property-attributes-text">
                  {golfView}{" "}
                  {+golfView === 1
                    ? "home has a golf course view"
                    : "homes have golf course views"}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className={`dashboard-body-divider dashboard-divider-${rangeNum}`}
        />
      </div>
    );
  }
}
export default DashboardBodyRange;
