import React, { Fragment, useRef, useEffect, useState } from "react";
import MailProButton from "../../UI/MailProButton";
import { FormLabel, InputAdornment, TextField } from "@material-ui/core";
import AnniversaryAutomatorNoteandPSEditorContainer from "./AnniversaryAutomatorNoteandPSEditorContainer";
import AnniversaryAutomatorPSSelector from "../Common/NoteAutomatorPSSelector";
import { noteUtils } from "../Common/NoteUtils";
import NoteAutomatorNoteViewMode from "../Common/NoteAutomatorNoteViewMode";
import NoteAutomatorPSViewMode from "../Common/NoteAutomatorPSViewMode";

const AnniversaryAutomatorNoteandPSView = ({
  classes,
  property,
  cameFrom,
  isBlocked,
  agentData,
  localNoteCopy,
  localPSCopy,
  //resetNoteandPS,
  restoreSystemNotePS,
  fetchLastHoAnniversaryMessage,
  propertySequenceNumber,
  isNotePSSkipped,
  isNotePSOverridden,
  isLoadingSystemDefaultNoteData,
  ...rest
}) => {

  //const [doesHomeownerHasSavedCamedFromNote, setDoesHomeownerHasSavedCamedFromNote] = useState(false);

  const { personalNoteMinLength, personalPSMinLength } =
    noteUtils.getPersonalNoteSizes();

  useEffect(() => {
    const { lastNoteHTML, lastNotePS } =
      noteUtils.getNotePSFromPropertyandCameFrom(property, cameFrom);
    rest.updateFinalLocalCopyNotePS(lastNoteHTML, lastNotePS);
    //lastNoteHTML && setDoesHomeownerHasSavedCamedFromNote(true);
  }, []);

  const psRef = useRef();
  const noteRef = useRef();

  const { greetTextTop, signOffTextBottom } =
    noteUtils.getPersonalNoteGreetingTexts(property, agentData, 'and');

  const plainNoteText =
    noteUtils.getPlainTextFromRichEditorState(localNoteCopy);

  const NoteEditCustomContainer = (props) => (
    <AnniversaryAutomatorNoteandPSEditorContainer
      property={property}
      cameFrom={cameFrom}
      autoOpenPSSelector={false}
      isBlocked={isBlocked}
      onSuccessUpdate={rest.updateFinalLocalCopyNotePS}
      lastNoteTextHtml={localNoteCopy}
      lastNotePS={localPSCopy}
      agentData={agentData}
      {...props}
    />
  );

  return (
    <div className={classes.aaNoteContainer}>
      <div className={classes.noteTextareaSides}>
        <NoteEditCustomContainer>
          <MailProButton
            key={"anniversary_automator_note"}
            variant="text"
            label={
              <Fragment>
                <p>CLICK HERE</p>
                <p>TO EDIT</p>
                <p>THIS NOTE</p>
                {/* <p>or P.S.</p> */}
              </Fragment>
            }
            customStyle={{
              fontSize: "13px",
              fontStyle: "italic",
              lineHeight: "17.5px",
              textAlign: "center",
            }}
          />
        </NoteEditCustomContainer>
      </div>
      <div className={classes.noteTextareaContainer}>

        {isNotePSOverridden ? <div className={classes.skippedMessage}>
          <p>You have already written a PERSONAL NOTE to this Homeowner.</p>
          <p>If you want to override your PERSONAL NOTE with an Anniversary Note, click 'Edit This Note' on the left.</p>
        </div> :
          isNotePSSkipped ? <div className={classes.skippedMessage}>
            <p>You have chosen to skip Anniversary Note for this Homeowner. </p>
            <p>If you want to reinstate the Anniversary Note for this Homeowner, click on the System Note on the right {">>"} and <br/>then click on Send Anniversary Note on the bottom.</p>
          </div> : null}
        {/* {isNotePSOverridden && <div className={classes.footerOverrideNote}></div>} */}

        <NoteAutomatorNoteViewMode
          ComponentWrapper={NoteEditCustomContainer}
          key={"aa-note-edit-view-note"}
          localNoteCopy={localNoteCopy}
          plainNoteText={plainNoteText}
          noteMinLength={personalNoteMinLength}
          greetTextTop={greetTextTop}
          signOffTextBottom={signOffTextBottom}
          placeholder={"Anniversary Note..."}
        />

        {/* <NoteAutomatorPSViewMode
          ComponentWrapper={NoteEditCustomContainer}
          key={"aa-note-edit-view-ps"}
          localPSCopy={localPSCopy}
          psMinLength={personalPSMinLength}
          psRef={psRef}
          placeholder={"Anniversary P.S...."}
        />
        <NoteEditCustomContainer autoOpenPSSelector={true}>
          <AnniversaryAutomatorPSSelector
            disableLinkClickOpening={false}
            key={"aa-note-ps-view"}
          />
        </NoteEditCustomContainer> */}
      </div>
      <div className={classes.noteTextareaSides}>
        {/* {rest.isAnyLastHomeownerNoteExist && (
          <Fragment>
            <NoteEditCustomContainer
              lastNoteTextHtml={rest.getLastHomeownerNotePS().localNoteCopy}
              lastNotePS={rest.getLastHomeownerNotePS().localPSCopy}
            >
              <MailProButton
                key={"anniversary_automator_copy_from_last_ho"}
                variant="text"
                label={
                  <Fragment>
                    <p>Use my last</p>
                    <p>custom message</p>
                    <p>for this Homeowner</p>
                  </Fragment>
                }
                customStyle={{
                  fontSize: "13px",
                  fontStyle: "italic",
                  lineHeight: "17.5px",
                  textAlign: "center",
                  color: "darkred",
                }}
                onClick={fetchLastHoAnniversaryMessage}
              />
            </NoteEditCustomContainer>
            <br />
            <br />
          </Fragment>
        )} */}

        <MailProButton
          key={"anniversary_automator_note_erase"}
          variant="text"
          label={
            <Fragment>
              <p>CLICK HERE TO</p>
              <p>TO USE THE</p>
              <p>SYSTEM NOTE</p>
            </Fragment>
          }
          customStyle={{
            fontSize: "13px",
            fontStyle: "italic",
            lineHeight: "17.5px",
            textAlign: "center",
            fontWEIGHT: 500
          }}
          onClick={restoreSystemNotePS}
          animation={!isLoadingSystemDefaultNoteData && localNoteCopy === ''} // animate if note is not empty
        />
        {/* {doesHomeownerHasSavedCamedFromNote && <MailProButton
          key={"anniversary_automator_note_restore"}
          variant="text"
          label={
            <Fragment>
              <p>Reset Note & P.S.</p>
            </Fragment>
          }
          customStyle={{
            fontSize: "9px",
            fontStyle: "italic",
            lineHeight: "17.5px",
            textAlign: "center",
            marginTop: '32px',
            color: '#922324'
          }}
          onClick={resetNoteandPS}
        />} */}
      </div>
    </div>
  );
};

export default AnniversaryAutomatorNoteandPSView;
