import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { pmaActions } from "_store/_actions";

import TableQuadrantsPageOne from "./TableQuadrantsPageOne";
import TableQuadrantsPageTwo from "./TableQuadrantsPageTwo";
import PropTypes from "prop-types";

class TableQuadrants extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.changeLoading(false);
    }, 500);
    if (this.props.printVersion) {
      require("./table-quadrants-print.scss");
      // require("views/Pma/PmaEditor/Quarterly/QVFour/qv4print.scss");
    } else {
      require("./table-quadrants-editor.scss");
    }
  }

  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      currentArea,
      mlsAreas,
      quarterlyAreaTable,
      quarterlyCityTable,
      navbarData,
      coverPageDetails,
      testimonials,
      handlePropertyHighlightDialog,
      propertyPhotoDetailsDTO,
      quadrantModalOpen,
      qArea,
      qCity,
      aprilData,
      quadrantDetails,
      MQCityDetails,
      photoCheckboxModalOpen,
    } = this.props;

    const nonPrintStyle = {
      padding: "10px 0 25px 0",
    };
    return (
      <Fragment>
        <div
          className={
            "pma-editor " + (printVersion ? "table-quadrants-print" : "")
          }
          style={printVersion ? null : nonPrintStyle}
        >
          <div
            className={`${photoCheckboxModalOpen ? "dimmed-editor" : ""}`}
          ></div>
          <div id="back-to-top-anchor-pma-editor-page-1" />
          <div
            className={
              printVersion
                ? "table-quadrants-print-page"
                : "table-quadrants-pma-editor-page"
            }
          >
            {!photoCheckboxModalOpen && (
              <TableQuadrantsPageOne
                agentData={agentData}
                hoData={hoData}
                currentArea={currentArea}
                mlsAreas={mlsAreas}
                printVersion={printVersion}
                version={version}
                coverPageDetails={coverPageDetails}
                quarterlyAreaTable={quarterlyAreaTable}
                testimonials={testimonials}
                quadrantModalOpen={quadrantModalOpen}
                quadrantDetails={quadrantDetails}
                propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
              />
            )}

            {!quadrantModalOpen && (
              <TableQuadrantsPageTwo
                agentData={agentData}
                hoData={hoData}
                currentArea={currentArea}
                mlsAreas={mlsAreas}
                printVersion={printVersion}
                version={version}
                quarterlyAreaTable={quarterlyAreaTable}
                quarterlyCityTable={quarterlyCityTable}
                MQCityDetails={MQCityDetails}
              />
            )}
          </div>
          <div id="back-to-top-anchor-pma-editor-page-2" />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    photoCheckboxModalOpen: state.pma.photoCheckboxModalOpen,
    isLoading: state.pma.isLoading,
    quadrantModalOpen: state.pma.quadrantModalOpen,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeLoading: (status) => {
      dispatch(pmaActions.changeLoading(status));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TableQuadrants);
