import React, { Component } from "react";
import YETwoPropertyHighlightModal from "./YETwoPropertyHighlightModal";
import TestHome from "views/PmaAdmin/PropertyHighlights/test-home.jpg";
import { propertyUtil } from "views/Pma/PropertyUtil";
import { Link } from "react-router-dom";
import { IsValidSessionSwitch } from "common/storage";

class YETwoPropertyHighlight extends Component {
  state = {
    isModalOpen: false,
  };
  isUnit = (prop) => {};
  shortenCourtesy = (brokerage, isSellingAgent) => {
    const { printVersion, agentData } = this.props;
    const { brokerageName } = agentData;
    let len = brokerage.length;
    let updatedBrokerage = brokerage.slice();
    if (brokerage === "Village Properties - 1") {
      updatedBrokerage = "Village Properties";
    } else if (
      brokerage === "Berkshire Hathaway HomeServices California Properties" &&
      brokerageName === "Berkshire Hathaway Home Services California Properties"
    ) {
      updatedBrokerage =
        "Berkshire Hathaway HomeServices California Properties";
    }
    if (!isSellingAgent && brokerageName === updatedBrokerage) {
      return "";
    } else if (isSellingAgent) {
      return "";
    } else if (!isSellingAgent && len >= 35) {
      return `Image courtesy of ${updatedBrokerage.slice(0, 35) + "..."}`;
    } else {
      return `Image courtesy of ${updatedBrokerage}`;
    }
  };

  formatSalePrice = (price) => {
    let salePriceOnlyNumbers = ((price || 0) + "").replace(/\D/g, "");
    return Number(salePriceOnlyNumbers);
  };
  determineTitleYear = () => {
    const { currentSoldListings, agentData } = this.props;
    const { isMemberOfTeam, listgenUserID } = agentData;
    let mappedSaleDate;
    if (!currentSoldListings) {
      mappedSaleDate = ["1999"];
    } else {
      mappedSaleDate = currentSoldListings.map((prop) =>
        prop.saleDate ? prop.saleDate.slice(0, 4) : "1999"
      );
    }
    let years = mappedSaleDate.map(Number);
    let isDianneJohnson = listgenUserID === "100988";
    if (years.includes(2021) && years.includes(2022)) {
      return (
        <div className="ye2-property-highlight-title-top-row">
          <div className="y2-property-highlight-title-text small">
            THE MAGIC OF NEW BEGINNINGS
          </div>
          {/* <div className="y2-property-highlight-title-year small">
            2021 & 2022
          </div> */}
        </div>
      );
    } else {
      return (
        <div className="ye2-property-highlight-title-top-row">
          <div className="y2-property-highlight-title-text">
            THE MAGIC OF NEW BEGINNINGS
          </div>
          {/* <div className="y2-property-highlight-title-year">
            {years.includes(2020) ? "2020" : 2021}
          </div> */}
        </div>
      );
    }
  };
  handlePropertyHighlightDialog = (openDialog = false) => {
    this.setState({
      isModalOpen: openDialog,
    });
  };

  determineRepresentation = (prop) => {
    const { isMemberOfTeam, listgenUserID } = this.props.agentData;
    let isDianneJohnson = listgenUserID === "100988";
    let pronoun;

    if (isMemberOfTeam || isDianneJohnson) {
      pronoun = "We";
    } else {
      pronoun = "I";
    }
    const { isSellingAgent, isBuyingAgent } = prop;
    if (isSellingAgent && isBuyingAgent) {
      return `${pronoun} Represented the Seller and the Buyer`;
    } else if (isSellingAgent) {
      return `${pronoun} Represented the Sellers`;
    } else if (isBuyingAgent) {
      return `${pronoun} Represented the Buyers`;
    }
  };

  render() {
    let {
      currentSoldListings,
      mlsAreaID,
      isAgentPage,
      setHighlightTitleDialog,
      title,
      subtitle,
      onHighlightTitleOpen,
      agentData,
      printVersion,
      currentArea,
      isDemo,
    } = this.props;
    //  console.log("*****currentSoldListings", currentSoldListings);
    const { isMemberOfTeam, listgenUserID, brokerageName } = agentData;
    const {
      handlePropertyHighlightDialog,
      determineRepresentation,
      shortenCourtesy,
    } = this;
    const { isModalOpen } = this.state;
    let isCountryClubGate = mlsAreaID === "132" ? true : false;
    const {
      formatSuffix,
      formatBaths,
      toAcre,
      emptyValueCheck,
      endsWithS,
      statusContingentToPending,
    } = propertyUtil;
    let isDianneJohnson = listgenUserID === "100988";
    let showTitle = false;
    const isASessionSwitch = true; //!printVersion && IsValidSessionSwitch();
    // if (listgenUserID === "100778" || listgenUserID === "100528") {
    //   showTitle = false;
    // }
    //Need to implement this logic if required
    // if(!isAgentPage){
    //   currentSoldListings =([...currentSoldListings] || []).sort((a, b) => Number(a.salePrice) > Number(b.salePrice) ? -1 : Number(a.salePrice) < Number(b.salePrice) ? 1 : 0);
    // }

    let extraSubtitleLine;

    if (listgenUserID === "100988") {
      extraSubtitleLine =
        "Santa Barbara area homes are still in high demand - Let’s Connect!";
      //put condition for diana
      if (currentArea && currentArea.mlsAreaID === "SBA-15J") {
        subtitle =
          "A few beautiful properties we've represented in our local area";
      } else if (currentArea && currentArea.mlsAreaID === "SBA-35F") {
        subtitle =
          "A few of the Homes we've helped your neighbors sell in the Mountain View Area";
      } else if (currentArea && currentArea.mlsAreaID === "SBA-35H") {
        subtitle = "Specializing in Fine Homes in the Santa Barbara Foothills";
      }
    }

    if (listgenUserID === "100988") {
    }

    // console.log('currentSoldListings=====', currentSoldListings)
    let row1 = (
      <div className="ye2-property-highlight-row-1">
        <div className="ye2-property-highlight-inner-photo-container">
          {[2, 3, 4].map((placeholder, index) => {
            let prop = currentSoldListings[index + 2];
            console.log("prop is", prop);
            if (!prop) {
              return (
                <div
                  className="property-highlight-placeholder"
                  key={placeholder}
                >
                  <p>Please select</p>
                  <p>a property</p>
                </div>
              );
            } else {
              return (
                <div className="row1-photo-col" key={placeholder}>
                  <div className="row-banner-text">
                    <div>
                      {`${emptyValueCheck(
                        prop.streetNumber
                      )}  ${emptyValueCheck(prop.streetName)}${
                        prop.streetSuffix !== "-"
                          ? `${formatSuffix(prop.streetSuffix, mlsAreaID)}`
                          : ""
                      }`}
                      {prop.unitNumber &&
                      prop.unitNumber !== "-" &&
                      prop.unitNumber !== null
                        ? `, #${prop.unitNumber}`
                        : ""}
                      ,{" "}
                      <span className="capitalize-city">
                        {prop.cityName && prop.cityName.toLowerCase()}
                      </span>
                    </div>
                    {prop.status === "Active" ? (
                      <div>
                        Offered for $
                        {this.formatSalePrice(prop.salePrice).toLocaleString()}
                      </div>
                    ) : (
                      <div>
                        Sold for $
                        {this.formatSalePrice(prop.salePrice).toLocaleString()}
                      </div>
                    )}
                  </div>
                  <img
                    src={isDemo ? prop.photoURL : prop.optimizedPhotoURL}
                    className="property-highlight-photo-0"
                  />
                  <div className="photo-footer-text">
                    <div>{determineRepresentation(prop)}</div>
                    {prop.brokerageName && (
                      <div className="brokerage-courtesy">
                        {shortenCourtesy(
                          prop.brokerageName,
                          prop.isSellingAgent
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
    let row2 = (
      <div className="ye2-property-highlight-row-2">
        <div className="ye2-property-highlight-inner-photo-container">
          {[0, 1].map((placeholder, index) => {
            let prop = currentSoldListings[index];
            if (!prop) {
              return (
                <div
                  className="property-highlight-placeholder"
                  key={placeholder}
                >
                  <p>Please select</p>
                  <p>a property</p>
                </div>
              );
            } else {
              return (
                <div className="row2-photo-col" key={placeholder}>
                  <div className="row-banner-text">
                    <div>
                      {`${emptyValueCheck(
                        prop.streetNumber
                      )}  ${emptyValueCheck(prop.streetName)}${
                        prop.streetSuffix !== "-"
                          ? `${formatSuffix(prop.streetSuffix, mlsAreaID)}`
                          : ""
                      }`}
                      {prop.unitNumber &&
                      prop.unitNumber !== "-" &&
                      prop.unitNumber !== null
                        ? `, #${prop.unitNumber}`
                        : ""}
                      ,{" "}
                      <span className="capitalize-city">
                        {prop.cityName && prop.cityName.toLowerCase()}
                      </span>
                    </div>
                    {prop.status === "Active" ? (
                      <div>
                        Offered for $
                        {this.formatSalePrice(prop.salePrice).toLocaleString()}
                      </div>
                    ) : (
                      <div>
                        Sold for $
                        {this.formatSalePrice(prop.salePrice).toLocaleString()}
                      </div>
                    )}
                  </div>
                  <img
                    src={isDemo ? prop.photoURL : prop.optimizedPhotoURL}
                    className="property-highlight-photo-3"
                  />
                  <div className="photo-footer-text">
                    <div>{determineRepresentation(prop)}</div>
                    {prop.brokerageName && (
                      <div className="brokerage-courtesy">
                        {shortenCourtesy(
                          prop.brokerageName,
                          prop.isSellingAgent
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    );

    return (
      <div
        className={`${
          isAgentPage
            ? "ye2-agent-page-property-highlight"
            : "ye2-property-highlight"
        }`}
      >
        {isModalOpen && (
          <YETwoPropertyHighlightModal
            isModalOpen={isModalOpen}
            handlePropertyHighlightDialog={handlePropertyHighlightDialog}
          />
        )}
        {!isAgentPage && !printVersion && isASessionSwitch && (
          <div className="ye2-new-edit-button-container">
            <Link
              to="/agent/property-highlights"
              className="ye2-new-edit-button"
            >
              Click Here to Edit This Page
            </Link>
          </div>
        )}

        <div className="ye2-property-highlight-background"></div>
        <div className="ye2-property-highlight-body">
          <div className="ye2-property-highlight-title-container">
            {this.determineTitleYear()}

            <div className="ye2-property-highlight-title-divider"></div>
            {listgenUserID === "100171" ? (
              <div className="ye2-property-subtitle">
                A few Buyers I have represented recently
              </div>
            ) : listgenUserID === "100560" ? (
              <div className="ye2-property-subtitle">
                A few properties I represented for Sellers and Buyers
              </div>
            ) : (
              <div className="ye2-property-subtitle">
                A few homes {isMemberOfTeam || isDianneJohnson ? "we" : "I"}{" "}
                represented for Sellers and Buyers
              </div>
            )}
            {title}
          </div>

          {/*
            {title && showTitle && <div className="ye2-property-highlight-photo-title-text">
              {title ? title : <div className="no-title"></div>}
            </div>} */}
          {/* {isAgentPage ? (
              <div
                className="title-edit-button"
                onClick={() => onHighlightTitleOpen()}
                style={{marginTop: '-16px', marginBottom: '16px'}}
              >
                edit
              </div>
            ) : (
              <div></div>
            )} */}

          {listgenUserID === "100015" ? (
            <div>
              {row2}
              {row1}
            </div>
          ) : (
            <div>
              {row1}
              {row2}
            </div>
          )}

          <div className="y2-property-highlight-footer">
            <div className="y2-property-highlight-custom-footer-text">
              <div></div>
              <div className="ye2-property-highlight-photo-title-text">
                {subtitle && subtitle}
              </div>
              {extraSubtitleLine && !isAgentPage && (
                <div
                  className="ye2-property-highlight-photo-title-text"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  {extraSubtitleLine}
                </div>
              )}

              {isAgentPage ? (
                <div
                  className="title-edit-button"
                  onClick={() => onHighlightTitleOpen()}
                >
                  edit
                </div>
              ) : (
                <div></div>
              )}
            </div>
            {extraSubtitleLine && !isAgentPage && (
              <div
                className="ye2-property-highlight-photo-title-text-fixed"
                style={{ width: "100%", textAlign: "center" }}
              >
                {extraSubtitleLine}
              </div>
            )}
            {/* <div className="y2-property-highlight-footer-text">
              Looking Forward to a Strong 2023!
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default YETwoPropertyHighlight;
