import { rgbToHex } from "@material-ui/core";
import {
  blueGrey,
  green,
  grey,
  pink,
  red,
  teal,
} from "@material-ui/core/colors";
import palette from "theme/palette";

export default (theme) => ({
  root: {
    padding: theme.spacing(3),
    height: "fit-content",
  },
  rootMinHeight: {
    minHeight: "90vh",
  },
  content: {
    //minHeight: '90vh',
    padding: theme.spacing(0),
  },
  contentPadded: {
    padding: theme.spacing(2),
  },
  topTextSection: {
    marginBottom: theme.spacing(2),
  },
  headerMainFont: {
    fontSize: "20px",
  },
  widthAuto: {
    width: "auto",
  },
  width100: {
    width: "100%",
  },
  width50: {
    width: "50%",
  },
  width30: {
    width: "30%",
  },
  width333: {
    width: "33.3%",
  },
  height100: {
    height: "100%",
  },
  displyFlex: {
    display: "flex",
  },
  rowFlex: {
    flexDirection: "row",
  },
  columnFlex: {
    flexDirection: "column",
  },
  centerJustify: {
    justifyContent: "center",
  },
  flexEndJustify: {
    justifyContent: "flex-end",
  },
  spaceBWJustify: {
    justifyContent: "space-between",
  },
  flexStartAlign: {
    alignItems: "flex-start",
  },
  centerAlign: {
    alignItems: "center",
  },
  underline: {
    textDecoration: "underline",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  lineThrough: {
    textDecoration: "line-through",
  },
  wordBreak: {
    wordBreak: "break-word",
  },
  red900: {
    color: red[900],
  },
  paletteRed: {
    color: palette.red.main,
  },
  green900: {
    color: green[900],
  },
  lightGreen: {
    background: green[100],
  },
  vlightTeal: {
    background: teal[50],
  },
  lightRed: {
    background: red[100],
  },
  vLightPink: {
    background: pink[50],
  },
  lightGrayMedium: {
    background: blueGrey[200],
  },
  primaryColor: {
    color: palette.primary.main,
    "&>h6": {
      color: palette.primary.main,
    },
  },
  lightBlueBG: {
    background: `${palette.latestLightGreen.main} !important`,
  },
  hoverBgWhite: {
    // '&:hover': {
    //   background: 'white'
    // }
  },
  hoverBgLightBlue: {
    "&:hover": {
      background: `${palette.latestLightGreen.main} !important`,
    },
  },
  blueGrayBorder: {
    border: `1px solid ${blueGrey[200]}`,
  },
  progressWatcher: {
    padding: theme.spacing(1),
    borderRadius: "4px",
  },
  highZIndex: {
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: 5,
  },
  whiteColor: {
    color: "white",
  },
  requiredRed: {
    textAlign: "left",
    color: palette.error.main,
    margin: theme.spacing(0, 0, 2, 0),
    //margin: theme.spacing(1, 0, 1, 0)
  },
  requirePLeft: {
    paddingLeft: theme.spacing(2),
  },
  bold500Font: {
    fontWeight: 500,
  },
  bold700Font: {
    fontWeight: 700,
  },
  boldNormalFont: {
    fontWeight: "normal",
  },
  blueLink: {
    color: "blue",
  },
  pointer: {
    cursor: "pointer",
  },
  nonePointer: {
    pointerEvents: "none",
  },
  disableCursor: {
    cursor: "not-allowed",
  },
  px14: {
    fontSize: "14px",
  },
  px12: {
    fontSize: "12px",
  },
  selectFLText: {
    fontSize: "16px",
  },
  //strength css
  minus15pxTop: {
    marginTop: -theme.spacing(2),
  },
  sampleLink: {
    width: "100px",
    textAlign: "right",
  },
  strengthFont: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  disabled: {
    opacity: 0.9,
  },
  edisabled: {
    opacity: 0.4,
  },
  strengthEditDiv: {
    display: "flex",
    aflexDirection: "row",
    width: "100%",
    margin: theme.spacing(1, 0, 1.5, 0),
    fontSize: "14px",
    minHeight: "40vh",
  },
  strengthsLeftMainSection: {
    width: "30%",
  },
  strengthsLeftSection: {
    border: `1px solid ${blueGrey[50]}`,
    //background: blueGrey[50],
    borderRadius: "4px",
    height: "fit-content",
  },
  strengthsLeftSectionH: {
    padding: theme.spacing(1, 1, 1, 1),
  },
  px56Height: {
    height: "56px",
    display: "flex",
    alignItems: "center",
  },
  boxCustomImageLayout: {
    border: `1px solid ${blueGrey[100]}`,
    padding: theme.spacing(0.5),
    borderRadius: "4px",
  },
  middleDivider: {
    margin: theme.spacing(0.5, 2),
    color: blueGrey[600],
  },
  strengthsRightSection: {
    //flexGrow: 100,
    width: "66%",
  },
  optionListSmallDiv: {
    height: "48vh",
    overflowY: "scroll",
  },
  optionWebkitScroll: {
    display: "block",
    scrollbarColor: `${palette.primary.main} #d4e0ec`, //#b3bccd
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar-track": {
      WebkitBoxShadow: "inset 0 0 6px #d4e0ec",
      WebkitBorderRadius: "8px",
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "8px",
      backgroundColor: palette.primary.main,
      WebkitBoxShadow: "inset 0 0 0",
    },
  },
  optionListDiv: {
    height: "100%", //55vh
    overflowY: "scroll",
  },
  muiItemItem: {
    padding: 0,
    minHeight: "24px",
  },
  muiItemGrouped: {
    padding: 0,
    minHeight: "24px",
    "&:hover": {
      backgroundColor: blueGrey[50],
    },
  },
  textboxMain: {
    padding: theme.spacing(0, 2, 1, 0),
  },
  textboxSmallMain: {
    padding: theme.spacing(0, 2, 2, 0),
  },
  textboxLeftAS: {
    flexGrow: 100,
  },
  textboxRightAS: {
    width: "10%",
    paddingTop: theme.spacing(2),
  },
  strengthImageTxtMain: {
    width: "30%",
    padding: theme.spacing(0, 2),
  },
  strengthImageHolder: {
    width: "40px",
  },
  strengthImage: {
    width: "100%",
  },
  strengthPDFIcon: {
    width: "28px",
    height: "28px",
  },
  orDiv: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  strengthTextboxMain: {
    flexGrow: 100,
  },
  //advantage specific
  areaSelectDiv: {
    marginBottom: theme.spacing(0),
  },
  errorDiv: {
    //margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
  },
  //testimonials specific
  checkIconDiv: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  checkIcon: {
    color: green[500],
  },
  testimonialsMainFont: {
    fontSize: "18px",
    lineHeight: "20px",
  },
  testimonialsFont: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  testimonialTextboxDiv: {
    padding: theme.spacing(2, 2, 2, 2),
    //marginBottom: theme.spacing(2)
  },
  testimonialTextboxDivBorder: {
    border: `2px solid ${palette.primary.currentMailProColor}`,
    borderRadius: "4px",
    margin: "8px 0 8px 0",
    "&:last-child": {
      marginBottom: "0",
    },
    "&:first-child": {
      marginTop: "0",
    },
  },
  textboxLeftTM: {
    flexGrow: 100,
    width: "100%",
  },
  textboxRightTM: {
    width: "20%",
    paddingTop: theme.spacing(4),
  },
  textAreaMultiline: {
    padding: theme.spacing(1.5, 1),
  },
  rightAlignText: {
    width: "100%",
    textAlign: "right",
  },
  textRightAlign: {
    textAlign: "right",
  },
  textLeftAlign: {
    textAlign: "left",
  },
  textCenterAlign: {
    textAlign: "center",
  },
  namesDiv: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  namesDivML: {
    marginLeft: theme.spacing(4),
  },
  hlLessPadding: {
    margin: theme.spacing(0.5),
  },
  hlDiv: {
    padding: "2px 4px",
    backgroundColor: palette.red.main,
    color: "white",
    borderRadius: "4px",
    animation: "$hlBlinker 5s linear infinite",
  },
  "@keyframes hlBlinker": {
    "95%": {
      opacity: 0,
    },
  },
  //testimonials
  savedTestimonials: {
    margin: theme.spacing(2, 0),
    border: `1px solid ${blueGrey[50]}`,
    //background: blueGrey[50],
    borderRadius: "4px",
    padding: theme.spacing(1, 1, 2, 1),
    height: "fit-content",
  },
  savedHeader: {
    margin: theme.spacing(2, 0),
  },
  addedTestimonialsDiv: {
    borderRadius: "4px",
    padding: theme.spacing(2),
    margin: theme.spacing(0.5, 0),
    width: "",
  },
  hoRectangularBox: {
    border: `1px solid ${blueGrey[100]}`,
    borderRadius: "4px",
    padding: theme.spacing(0.5),
  },
  //mission
  addedMissionsDiv: {
    padding: theme.spacing(1),
    borderRadius: "4px",
    margin: theme.spacing(1, 0, 1, 0),
  },
  stickyHead: {
    background: "white",
    position: "-webkit-sticky",
    position: "sticky",
    top: 65,
    zIndex: 5,
    height: theme.spacing(7),
  },
  stickyHeadNormal: {
    position: "-webkit-sticky",
    position: "sticky",
    top: 65,
    //zIndex: 5,
    height: "65vh",
  },
  progressWrapper: {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  smallDivider: {
    width: "50%",
    margin: theme.spacing(1.5, 1.5),
  },
  noDataDiv: {
    padding: theme.spacing("10vh", 0),
  },
  closeModalBtn: {
    marginTop: -theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    marginRight: -theme.spacing(2.5),
  },
  closeredButton: {
    minWidth: theme.spacing(2.5),
    backgroundColor: red[900],
    verticalAlign: "middle",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: red[900],
      color: "white",
    },
  },
  footerButtons: {
    position: "relative",
    display: "flex",
  },
  portletFooter: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  footerButtonsCenter: {
    justifyContent: "center",
    alignItems: "center",
  },
  footerButtonsLeftRight: {
    flex: 1,
  },
  progressWrapper: {
    paddingTop: "8px",
    paddingBottom: "8px",
    display: "flex",
    justifyContent: "center",
  },
  listRoot: {
    width: "100%",
    //maxWidth: 360,
    backgroundColor: "inherit",
  },
  listSubHeader: {
    backgroundColor: palette.primary.currentMailProColor, // blueGrey[300],
    padding: theme.spacing(0, 1),
    lineHeight: "40px",
    //color: palette.primary.main,
    minHeight: "40px",
  },
  listSubHeaderBorder: {
    borderBottom: `1px solid ${palette.latestLightGreen.main}`,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  //drag and drop
  dragDropContainer: {
    background: `${palette.info.mediumLight}`,
    color: "white",
    padding: "4px",
    borderRadius: "4px",
  },
  highlightBorder: {
    border: `2px solid ${palette.warning.main}`,
    borderRadius: "4px",
  },
});

export const getDDItemStyle = (isDragging, draggableStyle, useBorder) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: "12px",
  margin: `0 0 8px 0`,
  // change background colour if dragging
  background: isDragging ? palette.latestLightGreen.main : "white", //
  border: useBorder
    ? isDragging
      ? `2px solid ${palette.warning.main}`
      : `2px solid ${palette.primary.currentMailProColor}`
    : "none",
  borderRadius: "4px",
  // styles we need to apply on draggables
  ...draggableStyle,
});

export const getDDListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? palette.latestLightGreen.medium : "white",
  padding: "8px",
  width: "100%",
  margin: `8px 0 8px 0`,
});

export const getPHListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? palette.latestLightGreen.medium : "white",
  width: "100%",
});
