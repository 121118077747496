import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  notePSView: {
    fontSize: '12px',
    color: 'black',
    fontFamily: "objektiv-mk2",
  },
  wordBreakNotePS: {
    wordBreak: "break-word"
  },
  viewNoteUnfocused: {
    borderBottom: "1px solid #4A7396",//#8EA2AD
  },
  maxCharacterLimit: {
    textAlign: "right",
    fontSize: "10.5px",
    letterSpacing: "0.025em",
    fontStyle: "italic",
    color: "#4A7396",
    opacity: "0.7",
    fontFamily: "objektiv-mk2",
  },
  labelLikeText: {
    opacity: '1',
    color: '#A2A19F',
    fontSize: '14px',
    fontFamily: "objektiv-mk2",
    lineHeight: '17px'
  },
}));

const NoteAutomatorNoteViewMode = ({
  localNoteCopy,
  plainNoteText,
  noteMinLength,
  greetTextTop,
  signOffTextBottom,
  placeholder,
  ComponentWrapper = Fragment,
  ...rest
}) => {
  const classes = useStyles();

  return <div>
    <ComponentWrapper>
      <div className={classes.notePSView}>
        <div>{greetTextTop + ","}</div>
        {localNoteCopy ? (
          <div
            dangerouslySetInnerHTML={{ __html: localNoteCopy }}
            style={{ marginBottom: "8px", marginTop: "8px" }}
            className={classes.wordBreakNotePS}
          ></div>
        ) : (
          <div
            className={classes.labelLikeText}
            style={{ marginBottom: "8px", marginTop: "16px" }}
          >
            {placeholder}
          </div>
        )}
        <div
          className={classes.viewNoteUnfocused}
          style={{ textAlign: "right" }}
        >
          {signOffTextBottom}
        </div>
      </div>
    </ComponentWrapper>
    <p className={classes.maxCharacterLimit}>
      Character Count: {(plainNoteText && plainNoteText.length) || 0} /
      Min {noteMinLength} characters)
    </p>
  </div>
}

NoteAutomatorNoteViewMode.propTypes = {
  localNoteCopy: PropTypes.string,
  plainNoteText: PropTypes.string,
  noteMinLength: PropTypes.number,
  greetTextTop: PropTypes.string,
  signOffTextBottom: PropTypes.string,
  ComponentWrapper: PropTypes.elementType,
};

export default NoteAutomatorNoteViewMode;