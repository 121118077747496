import React, { Component } from "react";
import QVThreeSecondLeft from "./QVThreeSecondLeft";
import QVThreeSecondRight from "./QVThreeSecondRight";

class QVThreeSecondPage extends Component {
  render() {
    const {
      auth,
      agentData,
      hoData,
      currentArea,
      printVersion,
      version,
      salePriceForCities,
      MQCityDetails,
      mlsAreas,
      qArea,
      qCity,
      aprilData,
      flipBook,
      demoAccountCity,
      demoAccountHOName,
    } = this.props;

    return (
      <div
        className={
          "pma-page-container " +
          (printVersion
            ? "qv3-print-page-container"
            : "qv3-pma-editor-page-container")
        }
      >
        <QVThreeSecondLeft
          currentArea={currentArea}
          hoData={hoData}
          agentData={agentData}
          aprilData={aprilData}
          printVersion={printVersion}
          demoAccountCity={demoAccountCity}
        />
        <QVThreeSecondRight
          currentArea={currentArea}
          auth={auth}
          agentData={agentData}
          printVersion={printVersion}
          version={version}
          mlsAreas={mlsAreas}
          hoData={hoData}
          aprilData={aprilData}
          demoAccountHOName={demoAccountHOName}
        />
      </div>
    );
  }
}

export default QVThreeSecondPage;
