import React, { Fragment, Component } from 'react';
import DeleteProperty from "./DeleteProperty";
import styles from './styles';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { propertyUtil } from '../../PropertyUtil';
import { renderLogicUtil } from '../../RenderLogicUtil';
import HOGroupsList from './Groups/HOGroupsList';
import MailProGroupButton from './Groups/MailProGroupButton';

const SiteAddress = (props) => {
    const { isPropertyMapped, classes, SiteNumber, SiteStreet, SiteCity, SiteState, SiteZip, Parcel, blocked, areaID, agentID, mailerProID, mailingStatus, changeDetails, propertyData, propertyDetails, agentData, currentArea, hoaSubdivisionName, property, isMailProLocked, mailingDetails } = props;
    const { Parcel: parcelNumber } = mailingDetails;

    return (
        <div className="tableTdFlex">
            <div className="tableTdFlex-First textCursor">
                {((propertyUtil.emptyValueCheck(SiteNumber) === '' && propertyUtil.emptyValueCheck(SiteStreet) === '') || (propertyUtil.emptyValueCheck(SiteCity) === '' && propertyUtil.emptyValueCheck(SiteState) === '')) ? (
                    <Fragment>
                        <div className="site-address-table-body">
                            {/* <p>---</p> */}
                            <p>No Site Address</p>
                            <br />
                        </div>
                    </Fragment>
                ) : (
                    <div className="site-address-table-body capitalizeTextTransform">
                        <p>
                            {propertyUtil.emptyValueCheck(SiteNumber).toLowerCase()}{' '}
                            {propertyUtil.emptyValueCheck(SiteStreet).toLowerCase()}{' '}
                        </p>
                        <p>
                            {propertyUtil.emptyValueCheck(SiteCity).toLowerCase() ? propertyUtil.emptyValueCheck(SiteCity).toLowerCase() + ',' : '-'}{' '}
                            {propertyUtil.emptyValueCheck(SiteState) ? propertyUtil.emptyValueCheck(SiteState).toUpperCase() : ''}{' '}{propertyUtil.emptyValueCheck(SiteZip)}</p>
                    </div>
                )}
                <p className="" style={{ marginTop: '16px' }}>
                    <p>APN: {renderLogicUtil.parcelNumberBuilder(parcelNumber)}</p>
                </p>
                {/* Check here if agent is allowed or not to do so */}
                {/* currentArea && currentArea.showDeleteButton */}
                {currentArea && currentArea.showDeleteButton ?
                    <DeleteProperty
                        blocked={blocked}
                        areaID={areaID}
                        agentID={agentID}
                        mailerProID={mailerProID}
                        mailingStatus={mailingStatus}
                        changeDetails={changeDetails}
                        propertyData={propertyData}
                        propertyDetails={propertyDetails}
                        agentData={agentData}
                        isMailProLocked={isMailProLocked}
                    />
                    : ''}
                <br />
                {hoaSubdivisionName ?
                    <Fragment>
                        <p>HOA/Subdiv</p>
                        <p>{hoaSubdivisionName}</p>
                    </Fragment>
                    : ''}
                {/* <div>
                    <HOGroupsList property={property} />
                </div> */}
            </div>
            {/* {hoaSubdivisionName ?
                <Fragment>
                    <HOASubdivisonDialog
                        hoaSubdivisionName={hoaSubdivisionName}
                         isMailProLocked={isMailProLocked}
                    />
                </Fragment>
                : ''} */}
            <div className="tableTdFlex-Second pointer" onClick={() => { }}>
                {/* <MailProGroupButton>
                <p
                    className="pointer mailProLinkClass blue-text"
                >
                    MailProGroupButton will change text
                </p>
                </MailProGroupButton> */}
            </div>
        </div>
    );
};

export default compose(withStyles(styles))(SiteAddress);;