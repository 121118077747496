import React, { Fragment } from "react";
import { noteUtils } from "./NoteUtils";
import { Button, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Slide from "@material-ui/core/Slide";
import classNames from "classnames";
import styles from "../../styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core";

const PersonalNoteOverride = ({
  typePS,
  classes,
  style = { fontFamily: "objektiv-mk2 !important" },
  onOverride = () => {},
  onCancel = () => {},
}) => {
  return (
    <div style={style}>
      <br />
      <Typography
        variant="subtitle2"
        align="center"
        className={classes.darkRedColor}
        style={{ fontFamily: "inherit" }}
      >
        {" "}
        Hmmmm. Looks like you’ve already created a{" "}
        {noteUtils.getPersonalNoteTextFromCameFrom(typePS)} for this homeowner.
      </Typography>
      <Typography
        variant="subtitle2"
        align="center"
        className={classes.darkRedColor}
        style={{ fontFamily: "inherit" }}
      >
        {" "}
        You can only write one personal note and personal ps per PMA mailing per
        property.{" "}
      </Typography>
      <Typography
        variant="body1"
        align="center"
        style={{ fontFamily: "inherit" }}
      >
        {" "}
        If you’d like to erase and override the{" "}
        {noteUtils.getPersonalNoteTextFromCameFrom(typePS)} you wrote previously
        just click the{" "}
        <span className={classes.warningPrimaryText}>
          ERASE &amp; OVERRIDE CURRENT PERSONAL NOTE / PS
        </span>{" "}
        button and type a new one.
      </Typography>
      <Typography
        variant="body1"
        align="center"
        style={{ fontFamily: "inherit" }}
      >
        Or
      </Typography>
      <Typography
        variant="body1"
        align="center"
        style={{ fontFamily: "inherit" }}
      >
        If you’d like to keep your previous{" "}
        {noteUtils.getPersonalNoteTextFromCameFrom(typePS)} just click the{" "}
        <span className={classNames(classes.darkRedColor, classes.boldFont)}>
          CANCEL
        </span>{" "}
        button.
      </Typography>
      <br />
      <div className={classes.portletFooter}>
        <div className={classes.footerButtons}>
          <div className={classes.footerButtonsLeftRight}>
            <Button
              variant="contained"
              color="default"
              onClick={(event) => onCancel(event)}
              className={classes.redColorButton}
              size="small"
              style={{ fontFamily: "inherit" }}
            >
              Cancel
            </Button>
          </div>
          <div className={classes.footerButtonsCenter}>
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => {
                onOverride(event);
              }}
              size="small"
              style={{ fontFamily: "inherit" }}
            >
              Erase &amp; Override Current PERSONAL NOTE / PS
            </Button>
          </div>
          <div className={classes.footerButtonsLeftRight}></div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default compose(withStyles(styles))(PersonalNoteOverride);
