import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';

import {
    Input as InputIcon,
    AccountCircleOutlined as AccountIcon
} from '@material-ui/icons';
import palette from 'theme/palette';

const styles = theme => ({
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.light,
            '& $primary, & $icon': {
                color: palette.common.white
            },
        },
    },
    menuIcon: {
        marginRight: theme.spacing(1),
    },
    primary: {
        
    },
    listItemText: {
        padding: theme.spacing(0),
    },
    icon: {
        marginRight: theme.spacing(0.5),
    },
});

class AccountMenu extends Component {
    render() {
        const { classes, onSelect, handleSignOut } = this.props;
        return (
            <Paper>
                <MenuList>
                <Link to="/account">
                    <MenuItem className={classes.menuItem} onClick={event => onSelect()}>
                        <ListItemIcon className={classes.icon}>
                            <AccountIcon />
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.primary }} className={classes.listItemText} inset primary="My Account" />
                    </MenuItem>
                    </Link>
                    <MenuItem className={classes.menuItem} onClick={event => handleSignOut()}>
                        <ListItemIcon className={classes.icon}>
                            <InputIcon />
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.primary }} className={classes.listItemText} inset primary="Logout" li />
                    </MenuItem>
                </MenuList>
            </Paper>
        );
    }
}

AccountMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

AccountMenu.defaultProps = {
    onSelect: () => {},
    handleSignOut: () => {}
  };

export default withStyles(styles)(AccountMenu);
