import React, { Component } from "react";

import YETwoFirstLeft from "views/Pma/PmaEditor/YearEnd/YETwo/YETwoFirstPage/YETwoFirstLeft";
import YETwoFirstRight from "./YETwoFirstRight";
import { connect } from "react-redux";
import CustomDeborahSamuelPage from "views/Pma/PmaEditor/CustomAgentPages/CustomDeborahSamuelPage";
import QVFourFirstLeft from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourFirstLeft";

class YETwoFirstPage extends Component {
  render() {
    const {
      auth,
      agentData,
      hoData,
      currentArea,
      mlsAreas,
      navbarData,
      printVersion,
      version,
      viewingGeneratedCL,
      coverPageDetails,
      qArea,
      qCity,
      quarterlyAreaTable,
      salePriceForCities,
      quarterlyCityTable,
      marketActivity,
      MQCityDetails,
      aprilData,
      flipBook,
      demoAccountHOName,
      demoAccountCoverPhoto,
      demoAccountAreaName,
      testimonials,
      typeOfVersion,
      handlePropertyHighlightDialog,
      propertyPhotoDetailsDTO,
      quadrantModalOpen,
      quadrantDetails,
    } = this.props;

    const { listgenUserID } = agentData;
    let showPropertyHighlightPage = false;

    //create logic for agent focus page

    if (listgenUserID === "100526") {
      showPropertyHighlightPage = false;
    }

    return (
      <div
        className={
          "pma-page-container " +
          (printVersion
            ? "ye2-print-page-container"
            : "ye2-pma-editor-page-container")
        }
      >
        {/* {listgenUserID === "100585" ? (
          <CustomDeborahSamuelPage
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version="ye2"
            demoAccountHOName={demoAccountHOName}
            coverPageDetails={coverPageDetails}
          />
        ) : ( */}
        {showPropertyHighlightPage ? (
          <YETwoFirstLeft
            auth={auth}
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version={version}
            aprilData={aprilData}
            demoAccountHOName={demoAccountHOName}
            demoAccountAreaName={demoAccountAreaName}
            coverPageDetails={coverPageDetails}
            testimonials={testimonials}
            handlePropertyHighlightDialog={handlePropertyHighlightDialog}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            quarterlyAreaTable={quarterlyAreaTable}
          />
        ) : (
          <QVFourFirstLeft
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            printVersion={printVersion}
            version={version}
            mlsAreas={mlsAreas}
            coverPageDetails={coverPageDetails}
            quarterlyAreaTable={quarterlyAreaTable}
            testimonials={testimonials}
            quadrantModalOpen={quadrantModalOpen}
            quadrantDetails={quadrantDetails}
          />
        )}
        {/* )} */}

        <YETwoFirstRight
          currentSoldListings={quarterlyAreaTable.currentSoldListings}
          auth={auth}
          agentData={agentData}
          hoData={hoData}
          navbarData={navbarData}
          printVersion={printVersion}
          version={version}
          currentArea={currentArea}
          mlsAreas={mlsAreas}
          viewingGeneratedCL={viewingGeneratedCL}
          coverPageDetails={coverPageDetails}
          aprilData={aprilData}
          demoAccountCoverPhoto={demoAccountCoverPhoto}
          demoAccountAreaName={demoAccountAreaName}
          typeOfVersion={typeOfVersion}
          quarterlyAreaTable={quarterlyAreaTable}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    quadrantModalOpen: state.pma.quadrantModalOpen,
    // quadrantDetails:  state.pma.quadrantDetails,
  };
};
export default connect(mapStateToProps)(YETwoFirstPage);
