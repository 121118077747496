import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { ClickAwayListener, Fade } from "@material-ui/core";
import styles from "../AnniversaryAutomator/styles";
import compose from "recompose/compose";
import { withSnackbar } from "notistack";
import classNames from "classnames";
import MailProButton from "../../UI/MailProButton";
import { Popper } from "@material-ui/core";
import { useEffect } from "react";
import { useRef } from "react";
import { psSuggestions as psSuggestionsFromLibrary } from './NotesConfig';
import { noteUtils } from "./NoteUtils";
import { useContext } from "react";
import { AnniversaryAutomatorContext } from "../AnniversaryAutomator/AnniversaryAutomatorDialog";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  someIdeasContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "16px",
    marginTop: "16px",
    cursor: "pointer",
  },
  
  ideaLogoContainer: {
    width: "32px",
    "&>img": {
      width: "100%",
    },
  },
  ideaExtraText: {
    fontSize: "12px",
    color: "#4A7396",
    fontStyle: "italic",
  },
  rootPaperDecorative: {
    borderRadius: "0px !important",
    fontFamily: "objektiv-mk2",
    border: "1.5px solid #8EA2AD", //#757374
    margin: "16px",
    background: "#FBFBF5",
    fontFamily: "objektiv-mk2",
  },
  rootPaperRadius: {
    borderRadius: "4px !important",
  },
  psSelectorDialog: {
    background: "#FFFFFF",
    padding: "24px",
    // display: 'block',
    // position: 'absolute',
    // top: '30%'
  },
  psSelectorDialogHeader: {
    fontStyle: "italic",
    fontSize: "13.5px",
    color: "#4A7396",
    fontWeight: 500,
    marginBottom: "16px",
  },
  psSelectorDialogHeaderInner: {
    display: 'flex',
    width: '10%%',
    justifyContent: 'space-between'
  },
  psSelectorDialogUList: {
    listStyle: "none",
    "&>:hover": {
      background: "#E9F7FE",
    },
    "&>li": {
      borderBottom: "1px solid #8EA2AD",
    },
    "&>li:is(:last-child)": {
      borderBottom: "none",
    },
  },
  psSelectorDialogListItem: {
    lineHeight: "17px",
    fontSize: "13.5px",
    padding: "12px 0",
    "&>:hover": {
      background: "#E9F7FE",
    },
  },
  psSelectorDialogListItemSelected: {
    background: "#E9F7FE",
  }
}));


function NoteAutomatorPSSelector({onChange, currentPS, defaultOpen = false, disableLinkClickOpening = false }) {
  const lightBulbImage = "../../images/Lightbulb_lightbulb-small.png";
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const linkRef = useRef(null);
  const {mlsAreas, currentArea} = useContext(AnniversaryAutomatorContext);
  const finalPSSuggestions = noteUtils.replacePSSuggestionsWithCorrectData(mlsAreas, currentArea, psSuggestionsFromLibrary)
  const classes = useStyles();

  useEffect(() => {
    if(defaultOpen){
      setAnchorEl(linkRef.current);
      setOpen(true);
    }
  }, [defaultOpen]);

  const handleClickOpen = (event) => {
    if(!disableLinkClickOpening){
      setAnchorEl(event.currentTarget);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleOnSeletect = (psValue) => {
    onChange(psValue)
    handleClose()
  };

  return (
    <div>
      <div onClick={handleClickOpen} ref={linkRef}>
        <div className={classes.someIdeasContainer}>
          <div className={classes.ideaLogoContainer}>
            <img src={lightBulbImage} />
          </div>
          <div>
            <div className={classes.ideaExtraText}>
              Can you think of saying something that will connect to this
              homeowner?
            </div>
            <MailProButton
              key={"note_automator_some_ideas"}
              variant="text"
              label={"Click here for some P.S. ideas"}
              customStyle={{
                fontSize: "12px",
                fontStyle: "italic",
              }}
              onClick={() => {}}
            />
          </div>
        </div>
      </div>
      {open && (
        <ClickAwayListener onClickAway={handleClose} disableReactTree={false}>
        <Popper
          placement="bottom"
          disablePortal={true}
          open={true}
          anchorEl={anchorEl}
          transition
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: false,
              boundariesElement: "scrollParent",
            },
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <div
                className={classNames(
                  classes.rootPaperRadius,
                  classes.rootPaperDecorative,
                  classes.psSelectorDialog
                )}
                //style={{zIndex: '123456'}}
              >
                <div className={classes.psSelectorDialogHeader}>
                  <div  className={classes.psSelectorDialogHeaderInner}>
                  <div>
                    Click any one of the options below (All text is editable):
                  </div>
                  <div>
                  <MailProButton
                    key={"cancel_automator_note_editor_ps_close"}
                    variant="outlined"
                    label={"Close"}
                    customStyle={{
                      fontSize: "16px",
                      fontWeight: 600,
                      paddingTop: "4px",
                      paddingBottom: "4px",
                    }}
                    onClick={handleClose}
                  />
                  </div>
                  </div>
                </div>
                <div className={classes.psSelectorDialogContent}>
                  <ul className={classes.psSelectorDialogUList}>
                    {finalPSSuggestions.map((suggestion) => {
                      return (
                        <li
                          className={classNames(classes.psSelectorDialogListItem, (currentPS === suggestion ? classes.psSelectorDialogListItemSelected : ''))}
                          onClick={() => handleOnSeletect(suggestion)}
                        >
                         <span style={{fontWeight: 500}}>P.S.</span>&nbsp;{suggestion}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </Fade>
          )}
        </Popper>
        </ClickAwayListener>
      )}
    </div>
  );
}

NoteAutomatorPSSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentPS: PropTypes.string.isRequired,
  defaultOpen: PropTypes.bool,
  disableLinkClickOpening: PropTypes.bool
};

export default compose(
  withStyles(styles),
  withSnackbar
)(NoteAutomatorPSSelector);
