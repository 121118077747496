//small S for PMA's on last header
//more padding between header and bottom border
//when hovering over print, increase font
import React, {
  Component,
  Fragment,
  useState,
  useEffect,
  useContext,
} from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "../styles";
import styles2 from "./styles.scss";
import "./styles.scss";
import { Dashboard as DashboardLayout } from "layouts";
import { withSnackbar } from "notistack";
import { Portlet } from "components";
import { PortletContent } from "components";
import { PortletHeader } from "components";
import classNames from "classnames";
import { GetAreaPropHighlights } from "services/others";
import Base from "common/constants";
import { GetStateStorage, IsValidSessionSwitch } from "common/storage";
import moment from "moment";
import { GetPmaAdminDataAPI } from "services/agent";
import { showErrorNotification } from "common/helpers";
import { showSuccessNotification } from "common/helpers";
import { withRouter } from "react-router-dom";
import { Store } from "Store";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";
import { propertyUtil } from "views/Pma/PropertyUtil";

const AgentPerformance = (props) => {
  const { classes } = props;
  const { dispatch } = useContext(Store);
  //session related variables
  const isAValidSessionSwitch = IsValidSessionSwitch();
  const { userData } = GetStateStorage();

  /**loading variables */
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);
  //checkf or admin
  const isAdminAccess =
    isAValidSessionSwitch ||
    userData.listgenRoleID === Base.APP_DATA.APP_ADMIN_ROLE_ID;

  return (
    <Fragment>
      <DocumentTitle title="Agent Performance">
        <DashboardLayout title="">
          <div className={classes.root}>
            <Portlet className={classes.contentPadded}>
              <PortletContent noPadding>
                <div className="agent-performance">
                  <div className="ap-header">
                    <div className="ap-header-inner">
                      <div className="ap-header-left">
                        <div>ListGen</div>
                      </div>
                      <div className="ap-header-center">Agent Performance</div>
                      <div className="ap-header-right">
                        <div>Santa Barbara County</div>
                        <div>October 21, 2021</div>
                      </div>
                    </div>
                  </div>
                  <div className="ap-body">
                    <div className="ap-body-header">
                      <div className="available-areas">
                        <div>View 13 Available Areas</div>
                      </div>
                      <div className="download-link">Download</div>
                    </div>
                    <div className="ap-body-info">
                      <div className="ap-body-info-left">
                        <div className="brokerage-info">
                          <div>Berkshire Hathaway Home Services</div>
                          <div className="ap-body-info-left-divider"></div>
                          <div>California Properties</div>
                        </div>
                        <div className="agent-name">
                          <div>Kyle Kemp</div>
                          <div>19 BHHS Agents</div>
                        </div>
                      </div>
                      <div className="ap-body-info-right"></div>
                    </div>
                    <div className="invoice-table">
                      <div className="invoice-table-header">
                        <div></div>
                        <div></div>
                        <div>
                          <div>Invoice #</div>
                        </div>
                        <div>
                          <div>Invoice</div>
                          <div>Date</div>
                        </div>
                        <div>
                          <div>Amount</div>
                        </div>
                        <div>
                          <div>Areas</div>
                        </div>
                        <div>
                          <div>Total Pmas</div>
                          <div>Printed & Mailed</div>
                        </div>
                      </div>
                      <div className="invoice-table-body">
                        {[0, 1, 2, 3, 4, 5, 6].map((row, index) => {
                          return (
                            <div className="invoice-table-row">
                              <div className="view-invoice">
                                <div>View</div>
                                <div>Invoice</div>
                              </div>
                              <div className="number">
                                <div>{index + 1}</div>
                              </div>
                              <div className="invoice-table-number">
                                <div>12345</div>
                              </div>
                              <div>
                                <div>1-15-21</div>
                              </div>
                              <div>
                                <div>$926.00</div>
                              </div>
                              <div>
                                <div>Village</div>
                                <div>East Garrison</div>
                                <div>West Mesa</div>
                              </div>
                              <div>
                                <div>1250</div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </PortletContent>
            </Portlet>
          </div>
        </DashboardLayout>
      </DocumentTitle>
    </Fragment>
  );
};

AgentPerformance.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose(
  withSnackbar,
  withStyles(styles),
  withRouter
)(AgentPerformance);
