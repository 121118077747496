import React, { useEffect, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import classNames from 'classnames';
import {
    Button,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Divider,
    Grid,
    TextField
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { TeamProfileDialog } from '..';
import _ from 'lodash';
import schema from './schema';
import validate from 'validate.js';
import { ImageUploadDialog } from 'components';
import { MediaViewComponent } from 'components';
import { isAValidZipCode } from 'views/AgentDetails/AgentDetailsUtils';

const TeamDialog = React.memo((props) => {
    let { classes, maxWidth, openDialog, onDialogClose, setTeamProfileField, currentProfileData, teamNameValue, teamNameTouched, teamNameErrors, parentHandleFieldChange, listgenUserID, designationData, parentValues } = props;
    const [open, setOpen] = React.useState(openDialog);
    const [rowCount, setRowCount] = React.useState((currentProfileData.length === 0 ? 1 : currentProfileData.length));
    const [isValid, setIsValid] = React.useState(false);
    /**if data is already there let set state hanbdle this. otherwise make this true */
    const [startValidation, setStartValidation] = React.useState((currentProfileData.length > 0 ? false : true));
    /**media open dialog state */
    const [openUploadImageDialog, setOpenUploadImageDialog] = React.useState(false);
    const [imageType, setImageType] = React.useState(null);
    const [currentImage, setCurrentImage] = React.useState(null);
    const [teamKeyID, setTeamKeyID] = React.useState(null);

    /**changed team name value validate method little bit. because we can't put team name into schema otherwise it will start validating into parent component */
    const showTeamNameErrors = teamNameTouched && !teamNameValue ? true : false;

    const stateSkeleton = {
        values: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            agentLicenseNumber: '',
            mailingAddress1: '',
            mailingAddress2: '',
            city: '',
            state: '',
            zipcode: '',
            agentSignature: '',
            agentDesignation: '',
        },
        touched: {
            firstName: false,
            lastName: false,
            phone: false,
            email: false,
            agentLicenseNumber: false,
            mailingAddress1: false,
            mailingAddress2: false,
            city: false,
            state: false,
            zipcode: false,
            agentSignature: false,
            agentDesignation: false,
        },
        errors: {
            firstName: false,
            lastName: false,
            phone: false,
            email: false,
            agentLicenseNumber: false,
            mailingAddress1: false,
            mailingAddress2: false,
            city: false,
            state: false,
            zipcode: false,
            agentSignature: false,
            agentDesignation: false
        }
    };
    const [localState, setLocalState] = React.useState(currentProfileData);
    const [errors, setErrors] = React.useState([]);

    /**media view */
    const [mediaURL, setMediaURL] = React.useState(null);
    const [mediaTitle, setMediaTitle] = React.useState(null);
    const [openMediaView, setOpenMediaView] = React.useState(false);

    useEffect(() => {
        let data = [];
        /**check for increment */
        /**initial load case if we have previous data */
        if (currentProfileData.length > 0) {
            let data = [];
            for (let i = 0; i < rowCount; i++) {
                if (currentProfileData[i]) {
                    data.push(returnInitialSkelton(currentProfileData[i]));
                }else{
                    data.push(stateSkeleton);
                }
            }
            /**first on validation mode now */
            setStartValidation(true);
            setLocalState(data);
        }
        /**if we have don't have any previous data */
        if (localState.length === 0) {
            let data = [];
            for (let i = 0; i < rowCount; i++) {
                data.push(stateSkeleton);
            }
            setLocalState(data);
        }
        /**Add more case */
        if ((rowCount > localState.length && localState.length !== 0)) {
            const newState = _.cloneDeep(localState);
            newState.push(stateSkeleton);
            setLocalState(newState);
            /**check submit button */
            //checkValidButton(newState);
            customTeamMemberValidator();
        }

    }, [rowCount]);

    useEffect(() => {
        /**only validate if validation mode is on */
        if (startValidation) {
            customTeamMemberValidator();
        }
    }, [localState]);

    useEffect(() => {
        setOpen(openDialog);
    }, [openDialog]);

    const returnInitialSkelton = (values) => {
        let xData = _.cloneDeep(stateSkeleton);
        /* loop order should be from xData values to api values because ther is a case when we don't get any key in api team members data. */
        for (let key in xData.values) {
            if (values[key]) {
                xData.touched[key] = true;
                xData.values[key] = values[key];
            } else {
                xData.values[key] = '';
                xData.touched[key] = false;
            }
        }
        return xData;
    };

    const handleClose = () => {
        onDialogClose(false);
    }

    const deleteRow = (rowID) => {
        setRowCount(rowCount - 1);
        const newState = _.cloneDeep(localState);
        let newData = newState.filter((item, key) => (key !== rowID));
        setLocalState(newData);
        //checkValidButton(newData);
        customTeamMemberValidator();
    }

    const cleanRow = (keyID) => {
        try {
            const newState = _.cloneDeep(localState);
            newState[keyID] = stateSkeleton
            setLocalState(newState);
            //checkValidButton(newState);
            //customTeamMemberValidator();
        } catch (err) {
        }
    };

    const clearAll = () => {
        try {
            let data = [];
            for (let i = 0; i < rowCount; i++) {
                data.push(stateSkeleton);
            }
            setLocalState(data);
            //checkValidButton(data);
            customTeamMemberValidator();
        } catch (err) {
        }
    };

    const handleFieldChange = (field, value, keyID) => {
        try {
            const newState = _.cloneDeep(localState);
            let localData = _.cloneDeep(newState[keyID]);
            if (field === 'phone') {
                localData['values'][field] = value.replace(/\D/g, '');
                
            } else if (field === "zipcode") {
                localData['values'][field] = value.replace(/[^0-9-]/g, "");
              }  else {
                localData['values'][field] = value;
            }
            localData['touched'][field] = true;//(keyID !== 0 && value === '' ? false : true);
            newState[keyID] = _.cloneDeep(localData);
            setLocalState(newState);
            //customTeamMemberValidator();
        } catch (err) {
        }
    };

    const analyzeTeamMemberDetails = (errors) => {
        let noOfTeamMembers = localState.length;
        if (noOfTeamMembers === 1) {
            return null;
        } else if (Array.isArray(errors)) {
            /**return only equal to no of team member errors */
            /**exclude no 1 array because its readonly and alreadt validated */
            let xData = [undefined];
            let allUndefined = true;
            for (let i = 1; i < noOfTeamMembers; i++) {
                xData.push(errors[i]);
                if (errors[i]) {
                    allUndefined = false;
                }
            }
            return (allUndefined ? undefined : xData);
        }
    };

    const customTeamMemberValidator = _.debounce(() => {
        const newState = _.cloneDeep(localState);
        let errors = [];
        for (let key in newState) {
            const keyErrors = validate(
                newState[key].values,
                schema
            );
            const zipCodeError = isAValidZipCode("zipcode", newState[key].values.zipcode, []);
            if ((keyErrors && Object.keys(keyErrors).length > 0) || (zipCodeError && zipCodeError.length > 0)) {
                errors[key] = { ...keyErrors, ...zipCodeError };
            }
        }
        errors = analyzeTeamMemberDetails(errors);
        setErrors(errors);
        setIsValid((errors ? false : true));
    }, 300);

    // truncateTeamMember = data => {
    //     let newData = [];
    //     for (let row of data.teamMembers) {
    //         if (
    //             row.firstName !== '' ||
    //             row.lastName !== '' ||
    //             row.email != '' ||
    //             row.phone !== '' ||
    //             row.agentLicenseNumber !== '' ||
    //             row.mailingAddress1 !== '' ||
    //             row.mailingAddress2 !== '' ||
    //             row.city !== '' ||
    //             row.state !== '' ||
    //             row.zipcode !== ''
    //         ) {
    //             newData.push(row);
    //         }
    //     }
    //     data.teamMembers = newData;
    //     return data;
    // };

    const submitData = () => {
        /**first build the data */
        let localData = _.cloneDeep(localState);
        let returnData = [];
        for (let key in localData) {
            returnData[key] = localData[key].values;
        }
        setTeamProfileField(returnData);
        //setOpen(false);
    };

    const openImageUplaodDialog = (imageType, currentImage, keyID) => {
        setTeamKeyID(keyID);
        setCurrentImage(currentImage);
        setImageType(imageType);
        setOpenUploadImageDialog(true);
    };

    const onMediaDialogSubmit = (uploadedImageUrl) => {
        handleFieldChange('agentSignature', uploadedImageUrl, teamKeyID);
        setTeamKeyID(null);
        setCurrentImage(null);
        setImageType(null);
        setOpenUploadImageDialog(false);

    };

    const onMediaDialogClose = () => {
        setTeamKeyID(null);
        setCurrentImage(null);
        setImageType(null);
        setOpenUploadImageDialog(false);
    };

    const openMediaViewDialog = (mediaURL, mediaTitle) => {
        setMediaURL(mediaURL);
        setMediaTitle(mediaTitle);
        setOpenMediaView(true);
    };


    const closeMediaViewDialog = () => {
        setMediaURL(null);
        setMediaTitle(null);
        setOpenMediaView(false);
    };

    /**genrally also check for validation */
    //checkValidButton(localState);
    //customTeamMemberValidator();
    return (
        <React.Fragment>
            {openMediaView ?
                <MediaViewComponent
                    mediaURL={mediaURL}
                    mediaTitle={mediaTitle}
                    onOpen={openMediaViewDialog}
                    onClose={closeMediaViewDialog}
                />
                : ''}
            <ImageUploadDialog
                openDialog={openUploadImageDialog}
                onSubmit={onMediaDialogSubmit}
                onClose={onMediaDialogClose}
                imageType={imageType}
                listgenUserID={listgenUserID}
                currentImage={currentImage}
                teamMode={true}
            />
            <Dialog
                fullWidth={true}
                maxWidth={maxWidth}
                open={open}
                onClose={event => onDialogClose(false)}
                aria-labelledby="max-width-dialog-title"
                className={classes.dialogMain}
                scroll={"paper"}
            >

                <div id="scroll-dialog-title" className={classes.dialogTitle}>
                    <Typography
                        className={classes.title}
                        variant="subtitle1"
                    >
                        Team Profiles
                    </Typography>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        Please enter each team member in the order you want them to print in the PMA. i.e. 1st, 2nd, 3rd, and 4th.
                    </Typography>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                    A maximum of 4 team members can be loaded into system.
                  </Typography>
                    <IconButton aria-label="Close" className={classes.closeButton} onClick={event => handleClose()}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Divider variant="middle" />
                <div className={classes.requiredFields}>
                    <Typography
                        className={classes.fieldError}
                        variant="subtitle2"
                    >
                        <sup>
                            *
                            </sup>&nbsp;Required fields.
                        </Typography>

                </div>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={0}>
                        <Grid item lg={4}>
                            <div className={classes.field}>
                                <TextField
                                    className={classNames(classes.textField)}
                                    label="Team Name"
                                    margin="dense"
                                    required
                                    variant="outlined"
                                    name="teamName"
                                    value={teamNameValue}
                                    error={showTeamNameErrors}
                                    onChange={event =>
                                        parentHandleFieldChange("teamName", event.target.value, event)
                                    }
                                />
                                {showTeamNameErrors && (
                                    <Typography
                                        className={classes.fieldError}
                                        variant="body2"
                                    >
                                        {/* {teamNameErrors[0]} */}
                                        Team name is required
                                    </Typography>
                                )}
                            </div>
                        </Grid>
                        <Grid item lg={8}></Grid>
                        <Grid item lg={12}>
                            <Divider className={classes.dividerTeamProfile} />
                        </Grid>
                    </Grid>
                    {
                        localState.map((item, key) => {
                            return (item ? <TeamProfileDialog key={"team_dialog_" + key} keyID={key} deleteRow={deleteRow} handleFieldChange={handleFieldChange} cleanRow={cleanRow} stateData={localState}
                                errors={errors} openImageUplaodDialog={openImageUplaodDialog}
                                designationData={designationData}
                                parentValues={parentValues}
                                openMediaViewDialog={openMediaViewDialog}
                            />
                                : '')
                        })
                    }
                </DialogContent>

                <DialogActions className={classes.portletFooter}>
                    <div className={classes.footerButtons}>
                        <div className={classes.footerButtonsLeft}>
                            <Button
                                variant="outlined"
                                onClick={event => clearAll()}
                            >
                                Clear
                                        </Button>
                        </div>
                        <div className={classes.footerButtonsCenter}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={event => submitData()}
                                disabled={!isValid || !teamNameValue}
                            >
                                Save
                                        </Button>
                        </div>
                        <div className={classes.footerButtonsRight}>
                            {rowCount >= 4 ?
                                <Fragment>
                                    <Typography variant="subtitle2" className={classes.redColor}>
                                    Your maximum of 4 team member limit reached
                        </Typography>
                                </Fragment>
                                : <Fragment>
                                    <Button
                                        //size="small" 
                                        color="primary"
                                        variant="contained"
                                        disabled={!(rowCount < 4)}
                                        onClick={event => setRowCount((rowCount + 1))}
                                        className={classes.textAlignRight}
                                    >
                                        Add Another Team Member
                    </Button>
                                </Fragment>}

                        </div>

                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
});

export default withStyles(styles)(TeamDialog);