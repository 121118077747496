import React, { Component, Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Input,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from "@material-ui/core";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "views/Pma/PmaEditor/TableQuadrants/table-quadrants-editor.scss";
import { withSnackbar } from "notistack";
import classNames from "classnames";
import ClearIcon from "@material-ui/icons/Clear";
import { showSuccessNotification } from "common/helpers";
import { showErrorNotification } from "common/helpers";
import { AddUpdatePmaAdmin } from "services/agent";

import { propertyUtil } from "views/Pma/PropertyUtil";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QVFourMissionStatementModal = (props) => {
  const {
    oldQuadrantData,
    handleInputChange,
    editQuadrantFormData,
    onCloseFn,
    modalType,
    areaID,
    onEditFormSubmit,
    selectedBubbleNumber,
    handleRestoreDefault,
    titleEdit,
    maxMainTitleLength,
    missionStatementTitle,
    mlsAreas,
    savingMessageShowing,
    missionStatementContent,
    namePreferred,
  } = props;
  const transformInput = (str) => {
    let capital = str.charAt(0).toUpperCase() + str.slice(1);
    return capital;
  };
  console.log("insdie bubble box modal", props);

  if (titleEdit) {
    const { mktValAreaTitle } = editQuadrantFormData;
    console.log("mktValAreaTitle", mktValAreaTitle);
    let overMaxMainTitleLength =
      mktValAreaTitle &&
      mktValAreaTitle.length &&
      mktValAreaTitle.length >= maxMainTitleLength;

    return (
      <Fragment>
        <div className="mission-statement-modal-container">
          <div className="edit-quadrant-form new-props">
            <div className="edit-quadrant-form-title-container">
              <p className="edit-quadrant-form-title-text">
                You Can Make Changes to the Title Here
              </p>
            </div>

            <div className="edit-quadrant-form-body">
              <div className="edit-form-body-col">
                <div className="edit-form-thead">
                  <div>
                    <p>Title </p>
                  </div>
                </div>
                <div className="edit-form-input main-title-input">
                  <div className="address-input-col">
                    <input
                      type="text"
                      id={`main-title`}
                      name={`missionStatementTitle`}
                      className="title-input input-uppercase"
                      value={missionStatementTitle || ""}
                      onChange={handleInputChange}
                      placeholder={missionStatementTitle}
                      maxLength={maxMainTitleLength}
                    ></input>
                    <label
                      htmlFor="quadrantsTitle"
                      className={
                        overMaxMainTitleLength ? "character-count-error" : ""
                      }
                    >
                      Character count:{" "}
                      {mktValAreaTitle && mktValAreaTitle.length
                        ? mktValAreaTitle.length
                        : "0"}
                      /{maxMainTitleLength}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="edit-form-button-container">
            <div className="button-col">
              <div className="edit-form-cancel-button" onClick={onCloseFn}>
                <p>Close</p>
              </div>
            </div>
            <div className="button-col">
              <div
                className="edit-form-submit-button"
                onClick={onEditFormSubmit}
              >
                <p>Save</p>
              </div>
            </div>
          </div>
          {savingMessageShowing && (
            <div className="saving-message">Your work has been saved!</div>
          )}
        </div>
      </Fragment>
    );
  } else {
    let quadrantText = editQuadrantFormData.missionStatementContent;

    let maxQuadrantTextLength = 650;
    let overMaxQuadrantTextLength =
      quadrantText &&
      quadrantText.length &&
      quadrantText.length >= maxQuadrantTextLength;

    return (
      <Fragment>
        <div className="mission-statement-modal-container">
          <div className="edit-quadrant-form new-props">
            <div className="edit-quadrant-form-title-container">
              <p className="edit-quadrant-form-title-text">
                You Can Make Changes to the Text Here
              </p>
            </div>
            <div className="edit-quadrant-form-body row-body">
              <div className="edit-form-body-col">
                <div className="edit-form-thead">
                  <div>
                    <p>Text</p>
                  </div>
                </div>
                <div className="edit-form-input">
                  <div className="address-input-col">
                    <textarea
                      id={`missionStatementContent`}
                      placeholder={missionStatementContent}
                      onChange={handleInputChange}
                      name={`missionStatementContent`}
                      value={missionStatementContent || ""}
                      className="quadrant-textarea"
                      maxLength={maxQuadrantTextLength}
                    ></textarea>

                    <label
                      htmlFor={`quad${selectedBubbleNumber}Text`}
                      className={
                        overMaxQuadrantTextLength ? "character-count-error" : ""
                      }
                    >
                      Character count:{" "}
                      {quadrantText && quadrantText.length
                        ? quadrantText.length
                        : "0"}
                      /{maxQuadrantTextLength}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="edit-form-button-container">
            <div className="button-col">
              <div className="edit-form-cancel-button" onClick={onCloseFn}>
                <p>Close</p>
              </div>
            </div>
            <div className="button-col">
              <div
                className="edit-form-submit-button"
                onClick={onEditFormSubmit}
              >
                <p>Save</p>
              </div>
            </div>
          </div>
          {savingMessageShowing && (
            <div className="saving-message">Your work has been saved!</div>
          )}
        </div>
      </Fragment>
    );
  }
};

export default compose(
  withSnackbar,
  withStyles(styles)
)(QVFourMissionStatementModal);
