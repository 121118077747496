// TODO: working on creating the phone number formatting, you can check it out in the textMaskCustom component from 'react-text-mask' package

import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import _ from 'lodash';
import {
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
} from '@material-ui/core';
import styles from './styles';
import { withSnackbar } from 'notistack';
import compose from 'recompose/compose';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';


const TextMaskCustom = React.memo((props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask={false}
      guide={true}
      keepCharPositions={false}
    />
  );
});

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const Step1 = props => {
  const {
    classes,
    className,
    handleFieldChange,
    validateForm,
    validateRegisterEmail,
    validateAgentLicenseNumber,
    validateBrokerageLicenseNumber,
    haveErrors
  } = props;
  const rootClassName = classNames(classes.root, className);
  const { values, touched, errors, isValid, isLoading, designationData } = props;

  /**Error handling */
  const showFirstNameError = touched.firstName && errors.firstName;
  const showLastNameError = touched.lastName && errors.lastName;
  const showEmailError = touched.emailAddress && errors.emailAddress;
  const showAgentDreNumberError = touched.agentLicenseNumber && errors.agentLicenseNumber;
  const showBrokerageDreNumberError = touched.brokerLicenseNumber && errors.brokerLicenseNumber;
  const showMobilePhoneError = touched.mobilePhone && errors.mobilePhone;
  const showOfficePhoneError = touched.officePhone && errors.officePhone && values.officePhone;
  const showPasswordError = touched.password && errors.password;
  const showConfirmPasswordError = touched.confirmPassword && errors.confirmPassword;
  const showDesignationError = touched.agentDesignation && errors.agentDesignation;

  return (
    <Fragment>
      <Grid container className={classes.grid} spacing={0}>
        <Grid item lg={4}>
          <div className={classes.field}>
            <TextField
              className={classes.textField}
              label="First Name"
              margin="dense"
              type="text"
              required
              name="firstName"
              value={values.firstName}
              error={haveErrors(showFirstNameError)}
              onChange={event => {
                handleFieldChange('firstName', event.target.value, event);
              }}
            />
            {showFirstNameError && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.firstName[0]}
              </Typography>
            )}
          </div>
        </Grid>

        <Grid item lg={4}>
          <div className={classes.field}>
            <TextField
              className={classes.textField}
              label="Last Name"
              margin="dense"
              type="text"
              required
              name="lastName"
              value={values.lastName}
              error={haveErrors(showLastNameError)}
              onChange={event => {
                handleFieldChange('lastName', event.target.value, event);
              }}
            />
            {showLastNameError && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.lastName[0]}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={4}>
          <div className={classes.field}>
            <TextField
              id="select-agent-designation"
              select
              label="Select Your Designation"
              className={classes.textField}
              value={values.agentDesignation}
              //required
              error={haveErrors(showDesignationError)}
              onChange={event => {
                handleFieldChange(
                  'agentDesignation',
                  event.target.value,
                  event
                );
              }}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="dense"
            >
              {designationData.map(option => (
                <MenuItem key={option.listgenDesignationID} value={option.listgenDesignationName}>
                  {option.listgenDesignationName}
                </MenuItem>
              ))}
            </TextField>
            {showDesignationError && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.agentDesignation[0]}
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.grid} spacing={0}>
        <Grid item lg={4}>
          <div className={classes.field}>
            <TextField
              className={classNames(
                classes.textField,
                classes.dropdownTopMargin
              )}
              label="Agent License Number"
              margin="dense"
              type="text"
              required
              name="agentLicenseNumber"
              value={values.agentLicenseNumber}
              error={haveErrors(showAgentDreNumberError)}
              onBlur={event => {
                validateAgentLicenseNumber();
              }}
              inputProps={{ readOnly: true }}
              onChange={event => {
                handleFieldChange(
                  'agentLicenseNumber',
                  event.target.value,
                  event
                );
              }}
              helperText={"Agent License Number is a Readonly field."}
            />
            {showAgentDreNumberError && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.agentLicenseNumber[0]}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={4}>
          <div className={classes.field}>
            <TextField
              className={classNames(
                classes.textField,
                classes.dropdownTopMargin
              )}
              label="Brokerage License Number"
              margin="dense"
              type="text"
              required
              name="brokerLicenseNumber"
              value={values.brokerLicenseNumber}
              error={haveErrors(showBrokerageDreNumberError)}
              onBlur={event => {
                validateBrokerageLicenseNumber();
              }}
              inputProps={{ readOnly: true }}
              onChange={event => {
                handleFieldChange(
                  'brokerLicenseNumber',
                  event.target.value,
                  event
                );
              }}
              helperText={"Brokerage License Number is a Readonly field."}
            />
            {showBrokerageDreNumberError && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.brokerLicenseNumber[0]}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={4}>
          <div className={classNames(classes.field)}>
            <TextField
              className={classNames(
                classes.textField,
                classes.dropdownTopMargin
              )}
              label="Brokerage Name"
              margin="dense"
              type="text"
              required
              name="brokerageName"
              value={values.brokerageName}
              inputProps={{ readOnly: true }}
            />
          </div>
          {/* <div className={classNames(classes.field, classes.brokerageField)}>
              <InputLabel>Brokerage Name</InputLabel>
              <Typography variant="subtitle1" className={classes.brokerageFieldTypo}>
              {values.brokerageName}
              </Typography>
        </div> */}
        </Grid>
      </Grid>
      <Grid container className={classes.grid} spacing={0}>
        <Grid item lg={4}>
          <div className={classNames(classes.field)}>
            <FormControl className={classNames(classes.formControl, classes.dropdownTopMargin)}>
              <InputLabel required={true} htmlFor="formatted-text-mask-input-mobile-phone" shrink={true}>Mobile Phone</InputLabel>
              <Input
                value={values.mobilePhone ? values.mobilePhone : null}
                name="mobilePhone"
                error={haveErrors(showMobilePhoneError)}
                className={classNames(
                  classes.textField
                )}
                onChange={
                  event => {
                    handleFieldChange('mobilePhone', event.target.value, event)
                  }
                }
                required={true}
                id="formatted-text-mask-input-mobile-phone"
                inputComponent={TextMaskCustom}
              />
            </FormControl>
            {showMobilePhoneError && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.mobilePhone[0]}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={4}>
          <div className={classes.field}>
            <FormControl className={classNames(classes.formControl, classes.dropdownTopMargin)}>
              <InputLabel htmlFor="formatted-text-mask-input-office-phone" shrink={true}>Office Phone</InputLabel>
              <Input
                value={values.officePhone ? values.officePhone : null}
                name="officePhone"
                error={haveErrors(showOfficePhoneError)}
                className={classNames(
                  classes.textField
                )}
                onChange={
                  event => {
                    handleFieldChange('officePhone', event.target.value, event)
                  }
                }
                id="formatted-text-mask-input-office-phone"
                inputComponent={TextMaskCustom}
              />
            </FormControl>
            {showOfficePhoneError && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.officePhone[0]}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={4}>
          <div className={classes.field}>
            <TextField
              className={classNames(
                classes.textField,
                classes.dropdownTopMargin
              )}
              label="Email"
              margin="dense"
              type="text"
              required
              name="emailAddress"
              value={values.emailAddress}
              error={haveErrors(showEmailError)}
              onBlur={event => {
                validateRegisterEmail();
              }}
              onChange={event => {
                handleFieldChange('emailAddress', event.target.value, event);
              }}
            />
            {showEmailError && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.emailAddress[0]}
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.grid} spacing={0}>
        <Grid item lg={4}>
          <div className={classes.field}>
            <TextField
              className={classNames(
                classes.textField,
                classes.dropdownTopMargin
              )}
              label="Password"
              margin="dense"
              type="password"
              required
              name="password"
              value={values.password}
              error={haveErrors(showPasswordError)}
              inputProps={{ minLength: 8 }}
              onChange={event => {
                handleFieldChange('password', event.target.value, event);
              }}
            />
            {showPasswordError && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.password[0]}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={4}>
          <div className={classes.field}>
            <TextField
              className={classNames(
                classes.textField,
                classes.dropdownTopMargin
              )}
              label="Confirm Password"
              margin="dense"
              type="password"
              required
              name="confirmPassword"
              value={values.confirmPassword}
              error={haveErrors(showConfirmPasswordError)}
              inputProps={{ minLength: 8 }}
              onChange={event => {
                handleFieldChange('confirmPassword', event.target.value, event);
              }}
            />
            {showConfirmPasswordError && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.confirmPassword[0]}
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles)
)(Step1);
