import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import DonutBar from "./final2-donut-chart.png";

class QV3DonutBar extends Component {
  render() {
    const { useCity } = this.props;
    let {
      soldListingRowsFor12Months,
      highestSalePricePast12Months,
    } = this.props.quarterlyAreaTable;
    //console.log("****donut bar props", this.props.quarterlyAreaTable);
    //  console.log("quarterlyAreaTable", this.props.quarterlyAreaTable);
    //console.log("soldListingRowsFor12Months", soldListingRowsFor12Months);
    const { roundSalePriceTenths } = propertyUtil;
    soldListingRowsFor12Months = soldListingRowsFor12Months
      ? soldListingRowsFor12Months.slice(0, 7)
      : [];
    const numOfHomesSold = (soldListingRowsFor12Months
      ? soldListingRowsFor12Months
      : []
    ).map((row, i) => {
      return (
        <div key={i} className={`slice${i + 1}-text`}>
          <p className="slice-count">{row.SoldListingsCountLast12Months}</p>
          <p className="slice-sold">SOLD</p>
        </div>
      );
    });
    const priceRangeTable = (soldListingRowsFor12Months
      ? soldListingRowsFor12Months
      : []
    ).map((row, i) => {
      return (
        <div key={i} className={`price${i + 1}-box`}>
          {i === 6 ? (
            <div className={`price-range-row price${i + 1}-align`}>
              <p>
                {roundSalePriceTenths(row.PriceRangeLow, false, true)}-
                {roundSalePriceTenths(
                  highestSalePricePast12Months,
                  false,
                  true
                )}
              </p>
            </div>
          ) : (
            <div className={`price-range-row price${i + 1}-align`}>
              <p>{roundSalePriceTenths(row.PriceRangeLow, false, true)}</p>{" "}
              <p>-</p>
              <p>{roundSalePriceTenths(row.PriceRangeHigh, false, true)}</p>
            </div>
          )}
        </div>
      );
    });
    const daysOnMarket = (soldListingRowsFor12Months
      ? soldListingRowsFor12Months
      : []
    ).map((row, i) => {
      return (
        <div key={i} className={`dom${i + 1}-text`}>
          {row.SoldListingsCountLast12Months === "0"
            ? "—"
            : row.SoldListingsCountLast12Months === "1" &&
              row.AvgDaysOnMarketLast12Months === "0"
            ? "1"
            : row.AvgDaysOnMarketLast12Months}
        </div>
      );
    });
    return (
      <div className="qv3-donut-container">
        <img src={DonutBar} className="donut-bar-image" alt="Donut Bar Img" />
        <div className="donut-header">
          <div className="donut-title">Homes Sold</div>
          <div className="donut-subtitle">
            By Price in Your {useCity ? "City" : "Neighborhood"}
            <div className="donut-subtitle-circle">—</div>Last 12 Months
          </div>
        </div>
        {numOfHomesSold}
        {priceRangeTable}
        {daysOnMarket}
      </div>
    );
  }
}

export default QV3DonutBar;
