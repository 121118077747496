import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import EventIcon from '@material-ui/icons/Event';
import { IconButton, Tooltip } from '@material-ui/core';
import PropTypes from "prop-types";
import CalendarHelper from './CalendarHelper';
const helpers = new CalendarHelper();

export default function AddToCalendar(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDropdownLinkClick = (e) => {
        e.preventDefault();
        let url = e.currentTarget.getAttribute("dataTarget");

        if (
            !helpers.isMobile() &&
            (url.startsWith("data") || url.startsWith("BEGIN"))
        ) {
            let filename = "download.ics";
            let blob = new Blob([url], { type: "text/calendar;charset=utf-8" });
            /****************************************************************
            // many browsers do not properly support downloading data URIs
            // (even with "download" attribute in use) so this solution
            // ensures the event will download cross-browser
            ****************************************************************/
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(url, "_blank");
        }

        setAnchorEl(null)
    }

    return (
        <div>

            <Tooltip title="Add to Calendar">
                {props.children ? React.cloneElement(props.children, { onClick: handleClick }) :

                    <IconButton aria-controls="fade-menu" aria-haspopup="true" color="secondary" onClick={handleClick} >
                        <EventIcon />

                    </IconButton>}
            </Tooltip>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem dataTarget={helpers.buildUrl(
                    props.event,
                    "google"
                )} onClick={handleDropdownLinkClick}>Add to Google Calendar</MenuItem>
                <MenuItem dataTarget={helpers.buildUrl(
                    props.event,
                    "outlookcom"
                )} onClick={handleDropdownLinkClick}>Add to Outlook Calendar</MenuItem>
                <MenuItem dataTarget={helpers.buildUrl(
                    props.event,
                    "apple"
                )} onClick={handleDropdownLinkClick}>Add to Apple Calendar</MenuItem>
                <MenuItem dataTarget={helpers.buildUrl(
                    props.event,
                    "yahoo"
                )} onClick={handleDropdownLinkClick}>Add to Yahoo Calendar</MenuItem>
            </Menu>
        </div>
    );
}

AddToCalendar.propTypes = {
    event: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        location: PropTypes.string,
        startTime: PropTypes.string,
        endTime: PropTypes.string,
        attendies: PropTypes.array,
        links: PropTypes.arrayOf(PropTypes.shape({
            href: PropTypes.string,
            title: PropTypes.string
        })),
        appleCalUrl: PropTypes.string
    }).isRequired
};

AddToCalendar.defaultProps = {
    event: {
        title: "ListGen PMA Cut-Off Date",
        description: "Please review and approve your PMA before this date. Let us know if you want any changes in your PMA.",
        location: "California (CA), US",
        startTime: new Date(),
        endTime: new Date(),
        attendies: [],
        links: [],
        appleCalUrl: null
    }
};
