import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import DownArrow from "./mq3-arrow-down.png";
import UpArrow from "./mq3-arrow-up.png";

class MQThreeIndicatorSection extends Component {
  formatCategory = (category) => {
    if (category === "Sale Prices") {
      return (
        <div className="indicator-category">
          <p>Avg Sale Price</p>
        </div>
      );
    } else if (category === "Days to Sell") {
      return (
        <div className="indicator-category">
          <p>Avg Days on Market</p>
        </div>
      );
    } else if (category === "Square Ft") {
      return (
        <div className="indicator-category">
          <p>Avg Price Per Sq Ft</p>
        </div>
      );
    } else if (category === "Homes for Sale") {
      return (
        <div className="indicator-category">
          <p>For Sale</p>
        </div>
      );
    } else if (category === "% Received of Asking") {
      return (
        <div className="indicator-category">
          <p>% Received of Asking</p>
        </div>
      );
    }
  };
  formatValue = (value) => {
    const { category } = this.props;
    const { roundSalePrice } = propertyUtil;
    if (category === "Sale Prices") {
      //console.log("sale prices", value);
      return `${roundSalePrice(value).toLowerCase()}`;
    } else if (category === "Days to Sell") {
      return `${Math.round(value)} Days`;
    } else if (category === "Square Ft") {
      return `$${Number(value).toLocaleString()}`;
    } else if (category === "Homes for Sale") {
      return `${value} ${value == 1 ? "Home" : "Homes"}`;
    } else if (category === "% Received of Asking") {
      return `${Math.round(value)}%`;
    }
  };
  formatArrow = (perc, category) => {
    perc = +perc;
    if (perc > 0) {
      if (
        category === "Sale Prices" ||
        category === "Square Ft" ||
        category === "% Received of Asking"
      ) {
        //console.log("sale prices", value);
        return (
          <img
            src="https://d2onbxnrte2nim.cloudfront.net/Arrows_UP_Green.png"
            alt="Up Green Arrow"
            className="mq3-indicator-arrow-up"
          />
        );
      } else {
        return (
          <img
            src="https://d2onbxnrte2nim.cloudfront.net/Arrows_UP_DOWN_082822_UP-Red.png"
            alt="Up Red Arrow"
            className="mq3-indicator-arrow-up"
          />
        );
      }
    } else if (perc < 0) {
      if (
        category === "Sale Prices" ||
        category === "Square Ft" ||
        category === "% Received of Asking"
      ) {
        return (
          <img
            src="https://d2onbxnrte2nim.cloudfront.net/Arrows_DOWN_Red.png"
            alt="Down Red Arrow"
            className="mq3-indicator-arrow-down"
          />
        );
      } else {
        return (
          <img
            src="https://d2onbxnrte2nim.cloudfront.net/Arrows_UP_DOWN_082822_DOWN-Green.png"
            alt="Down Green Arrow"
            className="mq3-indicator-arrow-down"
          />
        );
      }
    } else if (perc === 0) {
      return <div className="indicator-balanced">No Change</div>;
    }
  };
  render() {
    const { category, value, perc } = this.props;
    const { formatCategory, formatValue, formatArrow } = this;
    const { posOrNeg, infinityCheck } = propertyUtil;
    return (
      <div className="indicator-section">
        <div className="indicator-title-container">
          {formatCategory(category)}
          <p className="indicator-value">{formatValue(value)}</p>
          <div className="indicator-title-bottom-border" />
        </div>
        <div className="indicator-col-1">
          {perc == "0" ? (
            ""
          ) : (
            <p className="indicator-perc">{posOrNeg(perc, null, true)}%</p>
          )}

          {formatArrow(perc, category)}
        </div>
      </div>
    );
  }
}

export default MQThreeIndicatorSection;
