export default {
  assistantFirstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64,
    },
  },
  assistantLastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64,
    },
  },
  emailID: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
  phone: {
    presence: {
      allowEmpty: false,
      message: 'is required',
    },
    numericality: {
      onlyInteger: true,
      message: 'is not valid',
    },
    length: {
      minimum: 6
    },
  },
  mailingAddr1: {
    presence: { allowEmpty: false, message: 'is required' },
    email: false,
    length: {
      maximum: 300,
    },
  },
  city: {
    presence: { allowEmpty: false, message: 'is required' },
    email: false,
    length: {
      maximum: 20,
    },
  },
  state: {
    presence: { allowEmpty: false, message: 'is required' },
    email: false,
    length: {
      maximum: 2,
    },
  },
  zipCode: {
    presence: { allowEmpty: false, message: 'is required' },
  }
};