import React, { Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import noImage from "../../../../_img/not-available.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Button, Typography, Divider, Container } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { propertyUtil } from "../../PropertyUtil";
import Slide from "@material-ui/core/Slide";
import { red } from "@material-ui/core/colors";
import classNames from "classnames";
import styles from "./styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core";
import PropertyImageSliderDialog from "./PropertyImageSliderDialog";
import moment from "moment";
import { renderLogicUtil } from "../../RenderLogicUtil";
import PropertyDetailsModal from "./PropertyDetailsModal";
import { agentBarSwitchClosureV2, MailProCloseButton } from "./MailProHTMLShortMethods";
import OffMarketSale from "./OffMarketSale";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PropertyViewDialog = props => {
  const [open, setOpen] = React.useState(false);
  const [openImageSlider, setImageSlider] = React.useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(0);
  const { classes } = props;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeImageSlider = (index = 0) => {
    setSelectedImageIndex(index);
    setImageSlider(false);
  };

  const getCorrectedData = inputString => {
    if (
      !inputString ||
      inputString === "-" ||
      inputString === "0" ||
      inputString === "EmptyValue" ||
      inputString === "*no Site Address*"
    ) {
      return "---";
    }
    return inputString;
  };

  const enlargeImage = imageNumber => {
    let { openMediaViewDialog, propertyDetails } = props;
    let { photos } = propertyDetails;
    if (photos[imageNumber]) {
      openMediaViewDialog(photos[imageNumber]);
    }
  };

  const {
    property,
    propertyDetails,
    mailingDetails,
    propertySequenceNumber,
    agentData
  } = props;

  const {
    SiteNumber,
    SiteStreet,
    SiteCity,
    SiteState,
    SiteZip,
    Bathfull,
    Lotacres,
    BedRooms,
    Bedrooms,
    Baths,
    Totalsf,
    Lotsqft,
    YearBuilt,
    Garage
  } = mailingDetails;
  const neighborhood = propertyDetails.areaText;
  const {
    photos,
    listingID,
    //parcelNumber,Not in use anyo\more
    areaID,
    county,
    age,
    yearBuilt,
    classType,
    propertySubtype,
    listPrice,
    originalPrice,
    beds,
    squareFeet,
    garageSpaces,
    view,
    daysOnMarket,
    listDate,
    halfBaths,
    noOfStories,
    description,
    agentRemarks,
    listingStatus
  } = propertyDetails;

  const { Parcel: parcelNumber } = mailingDetails;
  //let splitListDate = listDate ? listDate.slice(0, 10).split("-") : null;
  //(`${splitListDate[1]}/${splitListDate[2]}/${splitListDate[0]}`)
  let formattedListDate = listDate
    ? moment(new Date(listDate)).format("M/D/YYYY")
    : null;

  let propPhoto = !photos ? null : photos[0];
  let photoMap =
    photos &&
    photos.map((photo, index) => (
      <div key={index} style={{ cursor: "pointer" }}>
        <img src={photo} alt="Property" style={{ cursor: "pointer" }} />
      </div>
    ));
  //calculate property details
  let havingPropertyDetails =
    propertyDetails && Object.keys(propertyDetails).length > 0 ? true : false;

  return (
    <Fragment>
      <div
        className="tableTdFlex-Second pointer"
        onClick={event => handleClickOpen()}
      >
        <p className="blue-text mailProLinkClass">
          View
          <br />
          Property Details
        </p>
      </div>

      {open ? (
        <Dialog
          open={true}
          onClose={handleClose}
          scroll={"paper"}
          aria-labelledby="propert-view-dialog"
          aria-describedby="propert-view-dialog-description"
          maxWidth={"md"}
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle id="scroll-dialog-title">
            <div className={classes.propertyFactHeader}>
              <Typography variant="h4">Property Facts</Typography>
              <div>
                {(getCorrectedData(SiteNumber) === "---" &&
                  getCorrectedData(SiteStreet) === "---") ||
                (getCorrectedData(SiteCity) === "---" &&
                  getCorrectedData(SiteState) === "---") ? (
                  <Typography variant="body1">No site address found</Typography>
                ) : (
                  <Typography variant="h6">
                    <span>
                      {getCorrectedData(SiteNumber)}{" "}
                      {getCorrectedData(SiteStreet)}
                    </span>
                    <br />
                    <span>
                      {getCorrectedData(SiteCity)
                        ? getCorrectedData(SiteCity) + ","
                        : ""}
                      {getCorrectedData(SiteState)
                        ? getCorrectedData(SiteState) + " "
                        : ""}
                      {getCorrectedData(SiteZip)}
                    </span>
                  </Typography>
                )}
              </div>
              <MailProCloseButton onClick={handleClose} />
            </div>
          </DialogTitle>
          {/* Ho Dynamic section html begin. send object like this as it is only accepting this object */}
          {agentBarSwitchClosureV2(classes).call({ props: {...props}})}
          {/* Ho Dynamic section html end */}
          <DialogContent dividers={true}>
            {openImageSlider ? (
              <PropertyImageSliderDialog
                src={null}
                isOpen={true}
                propertyDetails={propertyDetails}
                mailingDetails={mailingDetails}
                selectedItem={selectedImageIndex}
                closeFn={closeImageSlider}
                propertySequenceNumber={propertySequenceNumber}
                property={property}
              />
            ) : (
              ""
            )}
            <div>
              <div
                className={classes.propertyDetailsModal}
                onClick={props.toggleDetailsModal}
              >
                <Container>
                  <div className="details-body">
                    <div className={classes.propertyDetailsSection}>
                      <div>
                        {propPhoto ? (
                          <Fragment>
                            <Carousel
                              className={"lg-property-slider"}
                              showThumbs={false}
                              showIndicators={false}
                              infiniteLoop={true}
                              width="300px"
                              dynamicHeight={true}
                              emulateTouch={false}
                              autoPlay={true}
                              useKeyboardArrows={true}
                              stopOnHover={true}
                              centerMode={true}
                              onClickItem={(index, args) => {
                                setSelectedImageIndex(index);
                                setImageSlider(true);
                              }}
                            >
                              {photoMap}
                            </Carousel>
                            <p
                              className={`blue-text pointer center-items view-enalrged-image`}
                              onClick={event => setImageSlider(true)}
                            >
                              View Enlarged Photos
                            </p>
                          </Fragment>
                        ) : (
                          <img
                            src={noImage}
                            style={{ width: "300px", height: "200px" }}
                            alt="Property"
                          />
                        )}

                        <div className={classes.propertyListingDetails}>
                          <div className="divider">
                            <Divider />
                          </div>
                          <div
                            className={classNames(
                              classes.propertyDetailsRow,
                              classes.propertyDetailsLeft
                            )}
                          >
                            <div>
                              <Typography variant="subtitle2">
                                Listing ID:{" "}
                              </Typography>
                            </div>
                            <div>
                              <Typography variant="body2">
                                {listingID ? listingID : "---"}
                              </Typography>
                            </div>
                          </div>
                          <div
                            className={classNames(
                              classes.propertyDetailsRow,
                              classes.propertyDetailsLeft
                            )}
                          >
                            <div>
                              <Typography variant="subtitle2">
                                Parcel #:{" "}
                              </Typography>
                            </div>
                            <div>
                              {/* <Typography variant="body2">
                                {getCorrectedData(parcelNumber)}
                              </Typography> */}

                              <Typography variant="body2">
                                {renderLogicUtil.parcelNumberBuilder(
                                  parcelNumber
                                )}
                              </Typography>
                            </div>
                          </div>
                          <div
                            className={classNames(
                              classes.propertyDetailsRow,
                              classes.propertyDetailsLeft
                            )}
                          >
                            <div>
                              <Typography variant="subtitle2">
                                City:{" "}
                              </Typography>
                            </div>
                            <div>
                              <Typography variant="body2">
                                {getCorrectedData(SiteCity)}
                              </Typography>
                            </div>
                          </div>
                          <div
                            className={classNames(
                              classes.propertyDetailsRow,
                              classes.propertyDetailsLeft
                            )}
                          >
                            <div>
                              <Typography variant="subtitle2">
                                Listing Area:{" "}
                              </Typography>
                            </div>
                            <div>
                              <Typography variant="body2">
                                {getCorrectedData(neighborhood)}
                                {getCorrectedData(areaID) &&
                                getCorrectedData(areaID) != "---"
                                  ? `(${getCorrectedData(areaID)})`
                                  : ""}
                              </Typography>
                            </div>
                          </div>
                          <div
                            className={classNames(
                              classes.propertyDetailsRow,
                              classes.propertyDetailsLeft
                            )}
                          >
                            <div>
                              <Typography variant="subtitle2">
                                County:
                              </Typography>
                            </div>
                            <div>
                              <Typography variant="body2">
                                {getCorrectedData(county)}
                              </Typography>
                            </div>
                            <div>
                              <Typography variant="subtitle2">
                                State:
                              </Typography>
                            </div>
                            <div>
                              <Typography variant="body2">
                                {getCorrectedData(SiteState)}
                              </Typography>
                            </div>
                            <div>
                              <Typography variant="subtitle2">
                                Zipcode:
                              </Typography>
                            </div>
                            <div>
                              <Typography variant="body2">
                                {SiteZip === "0" || SiteZip === "-"
                                  ? "---"
                                  : getCorrectedData(SiteZip)}
                              </Typography>
                            </div>
                          </div>
                          <div
                            className={classNames(
                              classes.propertyDetailsRow,
                              classes.propertyDetailsLeft
                            )}
                          >
                            <div>
                              <Typography variant="subtitle2">Age:</Typography>
                            </div>
                            <div>
                              <Typography variant="body2">
                                {age ? `${age} Years` : "---"}
                              </Typography>
                            </div>
                            <div>
                              <Typography variant="subtitle2">
                                Year Built:
                              </Typography>
                            </div>
                            <div>
                              <Typography variant="body2">
                                {yearBuilt === "0" ||
                                yearBuilt === "-" ||
                                !yearBuilt
                                  ? "---"
                                  : yearBuilt}
                              </Typography>
                            </div>
                          </div>
                          <div
                            className={classNames(
                              classes.propertyDetailsRow,
                              classes.propertyDetailsLeft
                            )}
                          >
                            <div>
                              <Typography variant="subtitle2">
                                Class:
                              </Typography>
                            </div>
                            <div>
                              <Typography variant="body2">
                                {getCorrectedData(classType)}
                              </Typography>
                            </div>
                          </div>
                          <div
                            className={classNames(
                              classes.propertyDetailsRow,
                              classes.propertyDetailsLeft
                            )}
                          >
                            <div>
                              <Typography variant="subtitle2">
                                Property Sub Type:
                              </Typography>
                            </div>
                            <div>
                              <Typography variant="body2">
                                {getCorrectedData(propertySubtype)}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={classes.topDetailsSection}>
                      <OffMarketSale property={property} />
                        <table cellSpacing={10}>
                          <tr>
                            <td>
                              <Typography variant="subtitle2">
                                Current Status:{" "}
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="body2">
                                {listingStatus ? listingStatus : "---"}
                              </Typography>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <Typography variant="subtitle2">
                                List Price:
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="body2">
                                {listPrice
                                  ? `$${Number(listPrice).toLocaleString()}`
                                  : "---"}
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="subtitle2">
                                Days On Market:
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="body2">
                                {daysOnMarket ? daysOnMarket : "---"}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography variant="subtitle2">
                                Original Price:
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="body2">
                                {originalPrice
                                  ? `$${Number(originalPrice).toLocaleString()}`
                                  : "---"}
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="subtitle2">
                                List Date:
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="body2">
                                {formattedListDate ? formattedListDate : "---"}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography variant="subtitle2">Beds:</Typography>
                            </td>
                            <td>
                              <Typography variant="body2">
                                {beds && beds > 0
                                  ? Number(beds)
                                  : BedRooms && BedRooms > 0
                                  ? Number(BedRooms)
                                  : Bedrooms && Bedrooms > 0
                                  ? Number(Bedrooms)
                                  : "---"}
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="subtitle2">
                                Half Baths:
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="body2">
                                {halfBaths && halfBaths > 0
                                  ? Number(halfBaths)
                                  : "---"}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography variant="subtitle2">
                                Stories:
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="body2">
                                {noOfStories ? Number(noOfStories) : "---"}
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="subtitle2">
                                Full Baths:
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="body2">
                                {Bathfull && Bathfull > 0
                                  ? Number(Bathfull)
                                  : Baths && Baths > 0
                                  ? Number(Baths)
                                  : "---"}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography variant="subtitle2">
                                Square Feet:
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="body2">
                                {squareFeet && squareFeet > 0
                                  ? `${Number(squareFeet).toLocaleString()}SqFt`
                                  : Totalsf && Totalsf > 0
                                  ? `${Number(Totalsf).toLocaleString()}SqFt`
                                  : "---"}
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="subtitle2">
                                Lot Size:
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="body2">
                                {Lotacres && Lotacres > 0
                                  ? `${Lotacres} acres`
                                  : Lotsqft
                                  ? `${Lotsqft}SqFt`
                                  : "---"}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography variant="subtitle2">
                                Garage Spaces:
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="body2">
                                {garageSpaces && garageSpaces > 0
                                  ? garageSpaces
                                  : Garage && Garage > 0
                                  ? Garage
                                  : "---"}
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="subtitle2">
                                Year Built:
                              </Typography>
                            </td>
                            <td>
                              <Typography variant="body2">
                                {yearBuilt &&
                                yearBuilt > 0 &&
                                yearBuilt.length === "4"
                                  ? yearBuilt
                                  : YearBuilt && YearBuilt.length > 0
                                  ? YearBuilt
                                  : "---"}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography variant="subtitle2">View:</Typography>
                            </td>
                            <td colSpan="3">
                              <Typography variant="body1">
                                {view
                                  ? view
                                      .split("/")
                                      .join(",")
                                      .split(",")
                                      .join(", ")
                                  : "---"}
                              </Typography>
                            </td>
                          </tr>
                        </table>
                        <br />
                        <Typography variant="subtitle2" align="left">
                          Property Description:
                        </Typography>
                        <Divider />
                        <br />
                        <div className={classes.propertyDetailsRow}>
                          <Typography variant="body2" align="left">
                            {description
                              ? description
                                  .split(" ")
                                  .slice(0, 80)
                                  .join(" ")
                              : "No description listed"}
                          </Typography>
                        </div>
                        <br />
                        <Typography variant="subtitle2" align="left">
                          Agent Remarks:
                        </Typography>
                        <Divider />
                        <br />
                        <div className={classes.propertyDetailsRow}>
                          <Typography variant="body2" align="left">
                            {agentRemarks
                              ? agentRemarks
                                  .split(" ")
                                  //.slice(0, 80)
                                  .join(" ")
                              : "No agent remark listed"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
    </Fragment>
  );
};
export default compose(withStyles(styles))(PropertyViewDialog);

{
  /*<PropertyDetailsModal
  propertyDetails={propertyDetails}
  handleClose={handleClose}
  openImageSlider={openImageSlider}
  selectedImageIndex={selectedImageIndex}
  closeImageSlider={closeImageSlider}
  setImageSlider={setImageSlider}
  mailingDetails={mailingDetails}
  open={open}
  agentData={agentData}
/>*/
}
