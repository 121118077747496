import React, { Fragment, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  Button,
  Typography,
  Divider,
  Container,
  TextField,
  CircularProgress,
  Grid,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemIcon,
  Fab,
  List,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { propertyUtil } from "../../PropertyUtil";
import Slide from "@material-ui/core/Slide";
import classNames from "classnames";
import styles from "./styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core";
import { GetCookieItem } from "common/cookies";
import { SetCookieItem } from "common/cookies";
import Base from "common/constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MailProNoteWarningDialog = props => {
  const [isOpen, setOpen] = useState(true);
  const { classes, closeFn, submitFn, type, areaName } = props;

  const handleClickOpen = () => {
    //check whether we should display the
  };

  const handleClose = () => {
    //set cookie hhere on click of acknowledgement
    //expire time in seconds 84600 = 23.5 hours
    setOpen(false);
    //set the flag here so it wont come for the current session
    closeFn();
  };

  return (
    <Fragment>
      {/* {isOpen ? */}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        //onExit={handleClose}
        scroll={"paper"}
        aria-labelledby="mailpro-warning-info-view-dialog"
        aria-describedby="mailpro-warning-info-view-dialog-description"
        maxWidth={"sm"}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        disableBackdropClick={true}
      >
        <DialogContent dividers={true}>
          {type === 'gps' ?
            <Fragment>
              <Typography variant="h5" align="center" style={{ padding: "16px" }} className={classes.redColor}>
                Just Checking...
            </Typography>
              <Typography variant="subtitle1" align="center" style={{ margin: "16px 0" }}>
                Just making sure you know that the <span className={classes.lightBoldText}>GLOBAL P.S.</span> you just typed is going
            to appear on <span className={classes.lightBoldText}>ALL</span> of your PMAs. (ALL of your homeowners in  <span className={classes.lightBoldText}>{areaName}</span> )
              </Typography>
              <Typography variant="subtitle1" align="center" style={{ margin: "16px 0" }}>
                If this is OK with you then just click on the <span className={classNames(classes.primaryBackground, classes.whiteColor)} style={{ padding: '2px 4px' }}>SUBMIT</span> button.
            </Typography>
              <Typography variant="subtitle1" align="center" style={{ margin: "16px 0" }}>
                If you meant to send a P.S. to just <span className={classes.lightBoldText}>ONE SPECIFIC</span> homeowner please click the <span className={classes.lightBoldText}>CANCEL</span> button.
            Then click on the  <span className={classNames(classes.primaryBackground, classes.whiteColor)} style={{ padding: '2px 4px' }}>Add Personal Note</span>  button to add a PERSONAL P.S. to that specific homeowner.
            </Typography>
              <Typography variant="subtitle1" align="center" style={{ margin: "16px 0" }}>
                Thanks!
          </Typography>
            </Fragment>
            :
            <Fragment>
              <Typography variant="h5" align="center" style={{ padding: "16px" }} className={classes.redColor}>
                Just Checking...
                </Typography>
              <Typography variant="subtitle1" align="center" style={{ margin: "16px 0" }}>
                Just making sure you know that the PERSONAL P.S. you just typed is going
                to appear on the PMA for just this <span className={classes.lightBoldText}>ONE</span> homeowner.  [for this issue ONLY]
                  </Typography>
              <Typography variant="subtitle1" align="center" style={{ margin: "16px 0" }}>
                If this is OK with you then just click on the <span className={classNames(classes.primaryBackground, classes.whiteColor)} style={{ padding: '2px 4px' }}>SUBMIT</span> button.
            </Typography>
              <Typography variant="subtitle1" align="center" style={{ margin: "16px 0" }}>
                If you want to send a P.S. to ALL your homeowners, please click the <span className={classes.lightBoldText}>CANCEL</span> button.
            Then click on the <span className={classNames(classes.primaryBackground, classes.whiteColor)} style={{ padding: '2px 4px' }}>Edit Global Note</span> button to add a GLOBAL P.S. [that will print on ALL your PMAs – for this issue only]
            </Typography>
              <Typography variant="subtitle1" align="center" style={{ margin: "16px 0" }}>
                Thanks!
          </Typography>
            </Fragment>
          }
        </DialogContent>
        <div className={classes.hosFooterContent}>
          <div className={classes.portletFooter}>
            <div className={classes.footerButtons}>
              <div className={classes.footerButtonsLeftRight}>
                <Button
                  variant="contained"
                  color="default"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </div>
              <div className={classes.footerButtonsCenter}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={event => submitFn(event)}
                >
                  Submit
                </Button>
              </div>
              <div className={classes.footerButtonsLeftRight}></div>
            </div>
          </div>
        </div>
      </Dialog>
      {/* : ''} */}
    </Fragment>
  );
};

export default compose(withStyles(styles))(MailProNoteWarningDialog);
