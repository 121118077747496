import React from 'react';
import styles from "./styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import ManageAGroup from './ManageAGroup';


export const HOGroupsList = (props) => {
    const { classes } = props;

    const hoHasGroups = true;

    const hoGroups = ["MAIN SI. HOMES", "AKLANE CONDOS", "RIVERWOOD HOMES"]


    return hoHasGroups ? <div className={classes.hoGroupsMain}>
        <div className={classes.hoGroupsHeader}>Group Name{hoGroups.length > 1 ? 's' : ''}:</div>
            {hoGroups.map((hoGroup, index) => {
                return <ManageAGroup key={index}>
                    <div className={classes.hoGroupsBoxItem}>{hoGroup}</div>
                </ManageAGroup>

            })}
    </div>
        :
        <div></div>
}

export default compose(withStyles(styles), withSnackbar)(HOGroupsList);