import React, { useState, useEffect } from "react";
import {
  withStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Input,
  TextField,
  List,
  ListSubheader,
  ListItem,
  Collapse
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import { compose } from "recompose";
import classNames from "classnames";
import styles from "../../styles";
import ColdwellBankerLogo from "_img/brokerage-logos/coldwell-banker-transparent-logo.png";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import Modal from "@material-ui/core/Modal";
import DeletePropertyModal from "../modals/DeletePropertyModal";

const AgentROIContainer = props => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModalDetails, setDeleteModalDetails] = useState("");

  const { classes } = props;

  const handleDeleteModal = details => {
    setDeleteModalOpen(true);
    setDeleteModalDetails(details);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setDeleteModalDetails("");
  };

  return (
    <div className={classes.agentROIContainer}>
      <div className={classNames(classes.header, classes.pageWidthMarginAuto)}>
        <div className={classes.headerLeft}>
          <div className={classes.headerDate}>As of May 30, 2021</div>
          <div className={classes.headerTitle}>ROI analysis</div>
        </div>
        <div className={classes.headerRight}>
          <img
            src={ColdwellBankerLogo}
            className={classes.headerBrokerageLogo}
            alt="Coldwell Banker Logo"
          />
        </div>
      </div>
      <div className={classes.currentROISection}>
        <div
          className={classNames(
            classes.currentROISectionContent,
            classes.pageWidthMarginAuto
          )}
        >
          <div className={classes.currentROISectionLeft}>
            <div className={classes.currentROISectionName}>
              Elizabeth Springer
            </div>
          </div>
          <div className={classes.currentROISectionRight}>
            <div className={classes.currentROIText}>Your Current ROI is:</div>
            <div className={classes.currentROIData}>372%</div>
          </div>
        </div>
      </div>
      <div
        className={classNames(classes.allAreas, classes.pageWidthMarginAuto)}
      >
        <div className={classes.allAreasTitle}>
          Roi Analysis <span>&#8212;</span> All Areas
        </div>
        <table
          className={classNames(classes.allAreasTable, classes.regTableSpacing)}
        >
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th>Commission</th>
              <th>Paid Out</th>
              <th>Profit</th>
              <th>ROI</th>
            </tr>
          </thead>
          <tbody className={classes.regTableTbody}>
            <tr>
              <td className={classes.solds}>TOTAL SOLDS</td>
              <td>6</td>
              <td>$7,250,000</td>
              <td>$103,000</td>
              <td>$62,000</td>
              <td>$42,000</td>
              <td>392%</td>
            </tr>
            <tr className={classes.emptyRow}>
              <td></td>
            </tr>
            <tr>
              <td className={classes.escrow}>TOTAL IN ESCROW</td>
              <td>3</td>
              <td>$XXX,XXX</td>
              <td>$XX,XXX*</td>
              <td>&#8212;</td>
              <td>&#8212;</td>
              <td>&#8212;</td>
            </tr>
            <tr>
              <td className={classes.active}>TOTAL ACTIVE</td>
              <td>1</td>
              <td>$XXX,XXX*</td>
              <td>$XX,XXX*</td>
              <td>&#8212;</td>
              <td>&#8212;</td>
              <td>&#8212;</td>
            </tr>
            <tr className={classes.totalTotalRow}>
              <td className={classes.totalTotal}>TOTAL/TOTAL</td>
              <td className={classes.bolded}>9</td>
              <td>$XXX,XXX*</td>
              <td>$XX,XXX*</td>
              <td>$XXX,XXX*</td>
              <td>$XX,XXX*</td>
              <td>605%</td>
            </tr>
            <tr className={classes.emptyRow}>
              <td></td>
            </tr>
            <tr>
              <td className={classes.pipeline}>TOTAL in PIPELINE</td>
              <td>6</td>
              <td>$XXX,XXX*</td>
              <td>$XX,XXX*</td>
              <td>&#8212;</td>
              <td>&#8212;</td>
              <td>&#8212;</td>
            </tr>
          </tbody>
        </table>
        {deleteModalOpen && (
          <DeletePropertyModal
            details={deleteModalDetails}
            handleDeleteModal={handleDeleteModal}
            deleteModalOpen={deleteModalOpen}
            closeDeleteModal={handleCloseDeleteModal}
          />
        )}

        <div className={classes.areaAnalysis}>
          <div className={classes.areaAnalysisHeader}>
            <div className={classes.areaAnalysisHeaderContent}>
              <div className={classes.areaAnalysisHeaderLeft}>
                <div className={classes.areaAnalysisHeaderLeftText}>
                  ROI ANALYSIS{" "}
                  <span className={classes.areaAnalysisHeaderSeparator}>|</span>{" "}
                  RIO del Mar Flats Beach
                </div>
              </div>
              <div className={classes.areaAnalysisHeaderRight}>
                <div className={classes.areaAnalysisHeaderRightText}>310%</div>
              </div>
            </div>
          </div>
          <div className={classes.areaAnalysisSubheader}>
            <div className={classes.areaAnalysisStartDate}>
              Start Date: OCT 2020
            </div>
            <div className={classes.areaAnalysisTotalMailings}>
              Total Mailings: xxxx
            </div>
          </div>
          <div className={classes.areaAnalysisOverview}>
            <table
              className={classNames(
                classes.areaAnalysisOverviewTable,
                classes.regTableSpacing
              )}
            >
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>Commission $</th>
                  <th>Paid Out</th>
                  <th>Profit</th>
                  <th>ROI</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={classes.solds}>TOTAL SOLDS</td>
                  <td>3</td>
                  <td>$3,550,000</td>
                  <td>$97,000</td>
                  <td>$31,000</td>
                  <td>$61,000</td>
                  <td>310%</td>
                </tr>

                <tr>
                  <td className={classes.escrow}>TOTAL In Escrow</td>
                  <td>2</td>
                  <td>$2,950,000</td>
                  <td>$79,500*</td>
                  <td>&#8212;</td>
                  <td>&#8212;</td>
                  <td>&#8212;</td>
                </tr>

                <tr>
                  <td className={classes.active}>TOTAL Active</td>
                  <td>1</td>
                  <td>$1,250,000*</td>
                  <td>$31,000*</td>
                  <td>&#8212;</td>
                  <td>&#8212;</td>
                  <td>&#8212;</td>
                </tr>

                <tr className={classes.totalTotalRow}>
                  <td className={classes.totalTotal}>TOTAL/TOTAL</td>
                  <td>3</td>
                  <td>$2,600,000*</td>
                  <td>$130,000*</td>
                  <td>&#8212;</td>
                  <td>&#8212;</td>
                  <td>&#8212;</td>
                </tr>
                <tr className={classes.emptyRow}>
                  <td></td>
                </tr>
                <tr>
                  <td>IN PIPELINE</td>
                  <td>3</td>
                  <td>$2,600,000*</td>
                  <td>$130,000*</td>
                  <td>&#8212;</td>
                  <td>&#8212;</td>
                  <td>&#8212;</td>
                </tr>
              </tbody>
            </table>
            <div className={classes.detailedBreakdown}>
              <div className={classNames(classes.detailedBreakdownTitle)}>
                Detailed Breakdown
              </div>
              <table className={classes.detailedBreakdownTable}>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Address</th>
                    <th>Sale Date</th>
                    <th>Sale Price</th>
                    <th>Commission</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={classes.solds}>Sold</td>
                    <td>1234 Main St</td>
                    <td>May 20, 2021</td>
                    <td>$1,295,000</td>
                    <td>2.5% | $36,000</td>
                    <td>
                      <CancelIcon />
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.solds}>Sold</td>
                    <td>1234 Main St</td>
                    <td>May 20, 2021</td>
                    <td>$1,295,000</td>
                    <td>2.5% | $36,000</td>
                    <td>
                      <CancelIcon />
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.escrow}>In Escrow</td>
                    <td>1234 Main St</td>
                    <td>May 20, 2021</td>
                    <td>$1,295,000</td>
                    <td>2.5% | $36,000</td>
                    <td>
                      <CancelIcon />
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.escrow}>In Escrow</td>
                    <td>1234 Main St</td>
                    <td>May 20, 2021</td>
                    <td>$1,295,000</td>
                    <td>2.5% | $36,000</td>
                    <td>
                      <CancelIcon />
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.active}>Active</td>
                    <td>test 1234 Main St</td>
                    <td>May 20, 2021</td>
                    <td>$1,295,000</td>
                    <td>2.5% | $36,000</td>
                    <td
                      onClick={() =>
                        handleDeleteModal({ address: "1234 Main St" })
                      }
                      className={classes.cancelIconContainer}
                    >
                      <CancelIcon />
                    </td>
                  </tr>
                  <tr>
                    <td>In Pipeline</td>
                    <td>1234 Main St</td>
                    <td>May 20, 2021</td>
                    <td>$1,295,000</td>
                    <td>2.5% | $36,000</td>
                    <td>
                      <CancelIcon />
                    </td>
                  </tr>
                  <tr>
                    <td>In Pipeline</td>
                    <td>1234 Main St</td>
                    <td>May 20, 2021</td>
                    <td>$1,295,000</td>
                    <td>2.5% | $36,000</td>
                    <td>
                      <CancelIcon />
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.addCircleRow}>
                      <AddCircleOutlineIcon /> <span>Add</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={classes.roiFooter}>
              * Projected after all properties close Escrow.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AgentROIContainer.propTypes = {
  classes: PropTypes.object.isRequired
};
export default compose(withSnackbar, withStyles(styles))(AgentROIContainer);
