import React, { Component } from 'react';
import classNames from 'classnames';
import {
  Portlet,
  PortletContent,
  PortletHeader,
  PortletLabel,
} from 'components';
import {
  Grid,
  Typography,
  Divider,
  Button,
  Card,
  CardContent,
} from '@material-ui/core';
import compose from 'recompose/compose';
import _ from 'lodash';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { WebsiteLayout } from 'layouts';
import DocumentTitle from 'react-document-title';

class PricingScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, className } = this.props;
    const rootClassName = classNames(className);
    const Atv = '/images/atv.png';
    const Cabriolet = '/images/cabriolet.png';
    const Pickup = '/images/pickup.png';
    const Van = '/images/van.png';

    return (
      <DocumentTitle title="Pricing">
        <WebsiteLayout>
          <div className={classes.root}>
            <Grid className={classes.grid} container>
              <Grid item lg={12} sm={12} xs={12} md={12}>
                <div className={classes.App}>
                  <div className={classes.topBox}>
                    <div className={classes.section}>
                      <Typography
                        className={classNames(
                          classes.whiteColor,
                          classes.textAlignCenter,
                          classes.margin4UnitBottom
                        )}
                        variant="h4"
                      >
                        Pricing
                    </Typography>
                      <Grid className={classes.grid} container>
                        <Grid item lg={12} sm={12} xs={12} md={12}>
                          <div className={classes.cardHolder}>
                            <Card
                              className={classNames(
                                classes.card,
                                classes.freeCol
                              )}
                            >
                              <CardContent>
                                <Typography
                                  className={classNames(
                                    classes.whiteColor,
                                    classes.textAlignCenter
                                  )}
                                  variant="button"
                                >
                                  Precision Market Analysis
                              </Typography>
                                <div className={classes.inlinePriceBox}>
                                  <Typography
                                    className={classNames(
                                      classes.whiteColor,
                                      classes.displayInline,
                                      classes.font75px
                                    )}
                                    variant="h1"
                                    component="h1"
                                  >
                                    $1.85
                                </Typography>
                                  <Typography
                                    className={classNames(
                                      classes.whiteColor,
                                      classes.displayInline
                                    )}
                                    variant="h5"
                                    component="h6"
                                  >
                                    / PMA
                                </Typography>
                                </div>
                                <Typography
                                  variant="subtitle1"
                                  className={classes.whiteColor}
                                >
                                  Total printing and mailing changes will depend on the number of homes In your licensed area
                              </Typography>
                              </CardContent>
                            </Card>
                            <Card
                              className={classNames(
                                classes.card,
                                classes.plusCol
                              )}
                            >
                              <CardContent
                                className={classes.cardContentContainer}
                              >
                                <Typography
                                  className={classNames(
                                    classes.plusColColor,
                                    classes.textAlignCenter
                                  )}
                                  variant="h6"
                                >
                                  Agent exclusive area licence fees
                              </Typography>
                                <div className={classes.inlinePriceBox}>
                                  <Typography
                                    className={classNames(
                                      classes.plusColColor,
                                      classes.displayInline,
                                      classes.font75px
                                    )}
                                    variant="h1"
                                    component="h1"
                                  >
                                    $300
                                </Typography>

                                  <Typography
                                    className={classNames(
                                      classes.plusColColor,
                                      classes.displayInline
                                    )}
                                    variant="h5"
                                    component="h6"
                                  >
                                    {'     '} / Area per Month
                                </Typography>
                                </div>

                                <Typography
                                  variant="subtitle1"
                                  className={classes.plusColColor}
                                >
                                  PMA's sent out 9 times a year to homeowners in
                                  your ListGen exclusive area.
                              </Typography>
                              </CardContent>
                            </Card>
                          </div>
                        </Grid>
                        {/* <Grid item lg={4} sm={12} xs={12} md={6}>

                                            </Grid> */}
                      </Grid>
                      <div className="col">
                        <div className={classes.clear} />
                      </div>
                    </div>
                    <div className={classes.section}>
                      <Typography
                        className={classNames(
                          classes.whiteColor,
                          classes.textAlignCenter,
                          classes.margin4UnitBottom
                        )}
                        variant="h3"
                      >
                        Being a ListGen Agent Includes:
                    </Typography>
                      <Grid className={classes.grid} container>
                        <Grid item lg={2} sm={false} xs={false} md={false} />
                        <Grid item lg={4} sm={12} xs={12} md={12}>
                          <div className={classes.eachFeature}>
                            <div className={classes.eachFeatureIcon}>
                              <i
                                className={`${'material-icons'} ${classes.icon}`}
                              >
                                credit_card
                            </i>
                            </div>
                            <div className={classes.eachFeatureBlock}>
                              {/* <Typography
                              className={classes.eachFeaturemTitle}
                              variant="subtitle1"
                            >
                              Stripe Integration
                            </Typography> */}
                              <Typography
                                className={classes.eachFeaturemDescription}
                                variant="subtitle2"
                              >
                                Secure Stripe Payments
                            </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item lg={4} sm={12} xs={12} md={12}>
                          <div className={classes.eachFeature}>
                            <div className={classes.eachFeatureIcon}>
                              <i
                                className={`${'material-icons'} ${classes.icon}`}
                              >
                                picture_in_picture
                            </i>
                            </div>
                            <div className={classes.eachFeatureBlock}>
                              {/* <Typography
                              className={classes.eachFeaturemTitle}
                              variant="subtitle1"
                            >
                              PMA's Printed and Mailed
                            </Typography> */}
                              <Typography
                                className={classes.eachFeaturemDescription}
                                variant="subtitle2"
                              >
                                Customized Precision Market Analysis
                            </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item lg={2} sm={false} xs={false} md={false} />
                      </Grid>
                      <Grid className={classes.grid} container>
                        <Grid item lg={2} sm={false} xs={false} md={false} />
                        <Grid item lg={4} sm={12} xs={12} md={12}>
                          <div className={classes.eachFeature}>
                            <div className={classes.eachFeatureIcon}>
                              <i
                                className={`${'material-icons'} ${classes.icon}`}
                              >
                                call
                            </i>
                            </div>
                            <div className={classes.eachFeatureBlock}>
                              {/* <Typography
                              className={classes.eachFeaturemTitle}
                              variant="subtitle1"
                            >
                              ListGen Team
                            </Typography> */}
                              <Typography
                                className={classes.eachFeaturemDescription}
                                variant="subtitle2"
                              >
                                Exceptional Customer Service
                            </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item lg={4} sm={12} xs={12} md={12}>
                          <div className={classes.eachFeature}>
                            <div className={classes.eachFeatureIcon}>
                              <i
                                className={`${'material-icons'} ${classes.icon}`}
                              >
                                date_range
                            </i>
                            </div>
                            <div className={classes.eachFeatureBlock}>
                              {/* <Typography
                              className={classes.eachFeaturemTitle}
                              variant="subtitle1"
                            >
                              Mail Dates
                            </Typography> */}
                              <Typography
                                className={classes.eachFeaturemDescription}
                                variant="subtitle2"
                              >
                                Quarterly, Midquarter and Year End PMA's sent out
                            </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item lg={2} sm={false} xs={false} md={false} />
                      </Grid>

                      <Grid className={classes.grid} container>
                        <Grid item lg={2} sm={false} xs={false} md={false} />
                        <Grid item lg={4} sm={12} xs={12} md={12}>
                          <div className={classes.eachFeature}>
                            <div className={classes.eachFeatureIcon}>
                              <i
                                className={`${'material-icons'} ${classes.icon}`}
                              >
                                print
                            </i>
                            </div>
                            <div className={classes.eachFeatureBlock}>
                              {/* <Typography
                              className={classes.eachFeaturemTitle}
                              variant="subtitle1"
                            >
                              Easy Printing
                            </Typography> */}
                              <Typography
                                className={classes.eachFeaturemDescription}
                                variant="subtitle2"
                              >
                                High Quality Prints
                            </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item lg={4} sm={12} xs={12} md={12}>
                          <div className={classes.eachFeature}>
                            <div className={classes.eachFeatureIcon}>
                              <i
                                className={`${'material-icons'} ${classes.icon}`}
                              >
                                home
                            </i>
                            </div>
                            <div className={classes.eachFeatureBlock}>
                              {/* <Typography
                              className={classes.eachFeaturemTitle}
                              variant="subtitle1"
                            >
                              Mail Dates
                            </Typography> */}
                              <Typography
                                className={classes.eachFeaturemDescription}
                                variant="subtitle2"
                              >
                                Create your PMA from Home
                            </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item lg={2} sm={false} xs={false} md={false} />
                      </Grid>

                      <Typography className={classes.afTitle} variant="h3">
                        Includes Support for Setting Up
                      <span />
                      </Typography>
                    </div>
                  </div>
                  {/* <div className={classes.bottomBox}>
                  <div className={classes.section}>
                    <Grid className={classes.grid} container>
                      <Grid item lg={12} sm={12} xs={12} md={12}>
                        <Typography
                          className={classNames(
                            classes.orgColor,
                            classes.textAlignCenter,
                            classes.margin4UnitBottom,
                            classes.fontWeigth500
                          )}
                          variant="h2"
                        >
                          Q &amp; A
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid className={classes.grid} container>
                      <Grid item lg={2} sm={12} xs={12} md={12} />
                      <Grid item lg={4} sm={12} xs={12} md={12}>
                        <div className={classes.eachQandA}>
                          <Typography variant="subtitle2">
                            Who is active?
                          </Typography>
                          <Typography variant="body1">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas mattis ut arcu eget lacinia. Lorem
                            ipsum dolor sit amet, consectetur adipiscing elit.
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={4} sm={12} xs={12} md={12}>
                        <div className={classes.eachQandA}>
                          <Typography variant="subtitle2">
                            Who is active?
                          </Typography>
                          <Typography variant="body1">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas mattis ut arcu eget lacinia. Lorem
                            ipsum dolor sit amet, consectetur adipiscing elit.
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={2} sm={12} xs={12} md={12} />
                    </Grid>
                    <Grid className={classes.grid} container>
                      <Grid item lg={2} sm={12} xs={12} md={12} />
                      <Grid item lg={4} sm={12} xs={12} md={12}>
                        <div className={classes.eachQandA}>
                          <Typography variant="subtitle2">
                            Who is active?
                          </Typography>
                          <Typography variant="body1">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas mattis ut arcu eget lacinia. Lorem
                            ipsum dolor sit amet, consectetur adipiscing elit.
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={4} sm={12} xs={12} md={12}>
                        <div className={classes.eachQandA}>
                          <Typography variant="subtitle2">
                            Who is active?
                          </Typography>
                          <Typography variant="body1">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Maecenas mattis ut arcu eget lacinia. Lorem
                            ipsum dolor sit amet, consectetur adipiscing elit.
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={2} sm={12} xs={12} md={12} />
                    </Grid>
                    <div className={classes.clear} />
                  </div>
                </div> */}
                </div>
              </Grid>
            </Grid>
          </div>
        </WebsiteLayout>
      </DocumentTitle>
    );
  }
}

export default compose(withStyles(styles))(PricingScreen);
