import React, { Component, useEffect, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import validate from 'validate.js';
import _ from 'lodash';
import { withStyles } from "@material-ui/core";
import { withSnackbar } from 'notistack';
import {
  Grid,
  Button,
  CircularProgress,
  TextField,
  Typography,
  IconButton
} from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import styles from "./styles";
import schema from "./schema";
import { ForgotPassword as ForgotPasswordAPI } from "services/users";
import { showErrorNotification } from "common/helpers";
import { showSuccessNotification } from "common/helpers";
import { STOP_LOADING } from "store/types";
import { START_LOADING } from "store/types";
import { HANDLE_VALIDATIONS } from "store/types";
import { HANDLE_FIELD_CHANGE } from "store/types";
import { Store } from "Store";
import { RESET_APP_STATE } from "store/types";
import { RESET_APP_STATE_EXCEPT_VALUES } from "store/types";
import { ResetAllLocalStorages } from "common/storage";
/**google captcha libs */
import ReCAPTCHA from "react-google-recaptcha";
import Base from 'common/constants';
import { SetCookieItem, RemoveCookieItem, GetCookieItem } from "common/cookies";
import DocumentTitle from "react-document-title";

const ForgotPassword = (props) => {
  const { state, dispatch } = React.useContext(Store);
  /**captcha variables */
  const [showCaptcha, setCaptcha] = React.useState(false);
  const [captchaVerified, setCaptchaVerified] = React.useState(false);
  const [captchaTokenData, setCaptchaTokenData] = React.useState(null);
  const [retryCount, setRetryCount] = React.useState(0);
  const listgenLogo = '/images/listgen-logo.png';
  const listgenLogoAlt = 'ListGen Logo';
  const { history } = props;
  const { classes } = props;
  const {
    values,
    touched,
    errors,
    isValid,
    submitSuccess,
    isLoading,
  } = state;
  /**define error methods */
  const showEmailError = touched.email && errors.email;

  /**ask for captcha if retry count is 3 or more */
  useEffect(() => {
    if (retryCount >= 3) {
      setCaptcha(true);
    }
  }, [retryCount]);

  /**Check the cookies for the retry number */
  useEffect(() => {
    //we have then update in the retry number also
    let cookieRetryNumber = GetCookieItem(Base.CookieStorageBase.LG_RETRY_NUMBER_COOKIE);
    if (parseInt(cookieRetryNumber) > 0 && parseInt(cookieRetryNumber) !== retryCount) {
      setRetryCount(parseInt(cookieRetryNumber));
    }
  }, []);

  useEffect(() => {
    /**reset all storage */
    ResetAllLocalStorages();
    /**reset app also */
    dispatch({
      type: RESET_APP_STATE_EXCEPT_VALUES
    });
    validateForm();
  }, []);

  const handleBack = () => {
    history.push('/login?mode=0');
  };

  const validateForm = _.debounce(() => {
    dispatch({
      type: HANDLE_VALIDATIONS,
      schema
    });
  }, 300);

  const handleForgotPassword = () => {
    try {
      dispatch({
        type: START_LOADING
      });
      ForgotPasswordAPI(values.email).then(data => {
        switch (Number(data)) {
          case 0:
            showSuccessNotification(props, "Password reset email sent.");
            //set retry count to 0
            setRetryCount(0);
            setCaptcha(false);
            //reset the count
            resetRetryCount();
            /**clean the form */
            dispatch({
              type: RESET_APP_STATE
            });
            break;
          case 1:
            showErrorNotification(props, "Please register before password reset.");
            //call the increase data API
            increaseRetryNumber();
            break;
          case 2:
            showErrorNotification(props, "Email doesn't associated with any account.");
            //call the increase data API
            increaseRetryNumber();
            break;
          default:
            showErrorNotification(props, "Something went wrong. Please try after some time.");
            //call the increase data API
            increaseRetryNumber();
            break;
        }
        /**stop loading also */
        dispatch({
          type: STOP_LOADING
        });

      }).catch(err => {
        showErrorNotification(props, err);
        dispatch({
          type: STOP_LOADING
        });
      });
    } catch (err) {
      showErrorNotification(props, 'Unable to process request.');
      dispatch({
        type: STOP_LOADING
      });
    }
  };

  const increaseRetryNumber = () => {
    //increase the retry number
    let newRetryCount = retryCount + 1;
    setRetryCount(newRetryCount);
    //set cookie for same, 600 means for next 10 minutes ask for the Captcha
    SetCookieItem(Base.CookieStorageBase.LG_RETRY_NUMBER_COOKIE, newRetryCount, 600, null, null, false);
  };

  const resetRetryCount = () => {
    RemoveCookieItem(Base.CookieStorageBase.LG_RETRY_NUMBER_COOKIE);
  };

  const handleReCaptchaOnChange = (tokenData) => {
    //set the token data
    setCaptchaTokenData(tokenData);
    //set the token verified
    if (tokenData) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };

  /**get props */

  return (
    <DocumentTitle title="Forgot Password | ListGen">
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteWrapper} item lg={7}>
            <div className={classes.quote} />
          </Grid>
          <Grid className={classes.content} item lg={5} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <IconButton
                  className={classes.backButton}
                  onClick={event => handleBack()}
                >
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <div className={classes.contentBody}>
                <form className={classes.form}>
                  <div className={classes.imageWrapper}>
                    <img
                      alt={listgenLogoAlt}
                      className={classes.image}
                      src={listgenLogo}
                    />
                  </div>
                  <Typography className={classes.title} variant="h4">
                    Forgot Password
                  </Typography>
                  <Typography className={classes.sugestion} variant="body1">
                    Enter the email address you used when creating the account and click Send Password Reset Email. A message will be sent to that address containing a link to reset your password.
                  </Typography>
                  <Typography className={classes.sugestion} variant="body1">
                    For security reasons the link can only be used for one hour. If you do not receive your email within five minutes check your spam folder.
                  </Typography>
                  {submitSuccess == null && (
                    <div>
                      <div className={classes.fields}>
                        <TextField
                          className={classes.textField}
                          label="Email address"
                          name="email"
                          onChange={event => {
                            dispatch({
                              type: HANDLE_FIELD_CHANGE,
                              fieldType: event.target.type,
                              value: event.target.value,
                              name: 'email',
                            });
                            validateForm();
                          }
                          }
                          type="text"
                          value={values.email}
                          variant="outlined"
                        />
                        {showEmailError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.email[0]}
                          </Typography>
                        )}
                      </div>
                      <div className={classes.captachaContainer}>
                        {showCaptcha ?
                          <Fragment>
                            <ReCAPTCHA
                              sitekey="6Lfpot4UAAAAAC7J_KrKqA9UrqEm3IytdruDCfLF"
                              size="normal"
                              theme="light"
                              onChange={handleReCaptchaOnChange}
                            />
                            {!captchaVerified ?
                              <Typography variant="subtitle2" color="primary">Check the checkbox to continue.</Typography>
                              : ''}
                          </Fragment>
                          : ''}
                      </div>
                      {isLoading ? (
                        <CircularProgress className={classes.progress} />
                      ) : (
                        <Button
                          className={classes.forgotPasswordButton}
                          color="primary"
                          disabled={!isValid || (showCaptcha && !captchaVerified)}
                          onClick={event => handleForgotPassword()}
                          size="large"
                          variant="contained"
                        >
                          Send Password Reset Email
                        </Button>
                      )}
                    </div>
                  )}
                  {submitSuccess && (
                    <Typography className={classes.submitSuccess} variant="body2">
                      {submitSuccess}
                      <br />
                      <br />
                      <Link className={classes.rememberPassword} to="/password/reset">Reset Password</Link>
                    </Typography>
                  )}
                  <Typography className={classes.rememberPassword} variant="body1">
                    Remember password?{" "}
                    <Link className={classes.rememberPasswordUrl} to="/login">
                      Back to sign in
                    </Link>
                  </Typography>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </DocumentTitle>
  );
}

ForgotPassword.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles)
)(ForgotPassword);
