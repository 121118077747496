import React, { Component } from "react";
import DotGraph from "./DotGraph";
import { propertyUtil } from "views/Pma/PropertyUtil";
import DownArrows from "views/Pma/PmaEditor/CommonEditor/Arrows/Arrows_DOWN.png";
import UpArrows from "views/Pma/PmaEditor/CommonEditor/Arrows/Arrows_UP.png";

class SellerGraph extends Component {
  determineArrow = perc => {
    return (
      <div className="seller-change-arrow-container">
        {perc == 0 ? (
          <div />
        ) : perc > 0 ? (
          <img src={UpArrows} alt="Up Arrows" className="seller-graph-arrow" />
        ) : (
          <img
            src={DownArrows}
            alt="Down Arrows"
            className="seller-graph-arrow"
          />
        )}
      </div>
    );
  };
  render() {
    const {
      category,
      description,
      agentData,
      quarterlyCityTable,
      dataPoints,
      formattedPoints,
      currentYearDataPoint,
      lastYearDataPoint,
      trendingPercPoint,
      bgColor,
      colorOrder,
      graphPosition
    } = this.props;
    const { determineArrow } = this;
    const { posOrNeg } = propertyUtil;
    return (
      <div className="seller-adv-section">
        <div className="seller-adv-row">
          <DotGraph
            dataPoints={dataPoints}
            category={category}
            formattedPoints={formattedPoints}
            bgColor={bgColor}
            colorOrder={colorOrder}
            graphPosition={graphPosition}
          />
          <div className="seller-data-section">
            <div className="seller-data-section-category-row">
              <div className="seller-data-section-category-text">
                {category}
              </div>
            </div>

            <div className="seller-two-box-row">
              <div className="seller-data-inner-section">
                <div className="seller-data-inner-section-col">
                  <div className="seller-year-and-data-col">
                    <div className="seller-data-year">2019</div>
                    <div className="seller-data-data">{lastYearDataPoint}</div>
                  </div>
                </div>
                <div className="seller-data-section-divider" />
                <div className="seller-data-inner-section-col">
                  <div className="seller-year-and-data-col">
                    <div className="seller-data-year">2020</div>
                    <div className="seller-data-data">
                      {currentYearDataPoint}
                    </div>
                  </div>
                </div>
              </div>
              <div className="seller-description-perc">
                <div className="seller-description-text">{description}</div>

                {!trendingPercPoint ? (
                  <div className="seller-perc-diff-container">
                    <div className="seller-perc-no-change">
                      <p>Under</p>
                      <p>1% Change</p>
                    </div>
                  </div>
                ) : (
                  <div className="seller-perc-diff-container">
                    <div className="seller-perc-diff">
                      {posOrNeg(trendingPercPoint, 0, true)}%
                    </div>
                    {determineArrow(trendingPercPoint)}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SellerGraph;
