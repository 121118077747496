import React, { Fragment, Component, useEffect, lazy, Suspense } from 'react';
/**Store */
import { Store } from './Store';
/**Theme */
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
/**Routes */
import { Router, withRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './Routes';

/**Styles */
import './assets/scss/index.scss';

/**Notisnackbar installation */
import { SnackbarProvider } from 'notistack';

/**lodash */
import _ from 'lodash';
import { Slide } from '@material-ui/core';
//import NetworkDetector from 'components/NetworkDetector';
//import { SessionSwitchFABComponent } from 'components';

/**
 * Use this file to define all route related stuff and provider related stuff.
 */
const browserHistory = createBrowserHistory();
/**
 * Update state in context
 */

/**Lazy loading */
const ListGenLoader = (
  <div id="root">
    <div className="lg-ll-container">
      <div className="lg-ll-grid">
        <img
          alt="ListGen Logo"
          src="/images/listgen-logo.png"
          className="lg-ll-grid-image"
        />
      </div>
      <div className="lg-ll-text">Loading...</div>
    </div>
  </div>
);

/**lazy load the session switch and net work detector. it only supports the default exports */
const SessionSwitchFABComponent = lazy(() => import('components/SessionSwitchFAB'));
const NetworkDetectorComponent = lazy(() => import('components/NetworkDetector'));

export default function App(props) {

  const { state } = React.useContext(Store);
  if (state.deepCopy === null) {
    const deepClonedState = _.pick(state, 'values', 'touched', 'errors', 'showPassword', 'submitError',
      'submitSuccess', 'userName', 'userRole');
    state.deepCopy = _.cloneDeep(deepClonedState);
  }

  return (
    <Router history={browserHistory}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <Suspense fallback={ListGenLoader}>
            <Routes />
          </Suspense>
          <Suspense fallback={ListGenLoader}>
            <NetworkDetectorComponent />
            <SessionSwitchFABComponent />
          </Suspense>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  );
  //}
}
