import React, { Component } from "react";
import { connect } from "react-redux";
import AgentPMA from "./AgentPMA";
import { pmaActions } from "../../../_store/_actions";
import { GetStateStorage, IsValidNavbarData } from "common/storage";
import { WebsiteLayout } from "layouts";
import { CircularProgress, Typography, Button, Grid } from "@material-ui/core";
import querystring from "querystring";
import PMAIssueReportsDialog from "./PMAIssueReportsDialog";
// import PMAEditorInformationDialog from "./components/PMAEditorInformationDialog";
import { handleLogoutFn } from "common/helpers";
import { Alert } from "@material-ui/lab";
import { isEmpty } from "lodash";

class PMAMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbarData: {},
      resetLocalState: false,
      openDrawer: false,
      isFullMode: true,
      enableMailProApprovalProcess: false, //no need to touch this as it will have no effect. use component Did Mount. It iwll controlled by backend now
      openPMAIssuesDialog: false,
      enablePMAInformationDialog: false,
      // enablePMAInformationDialog: true,// true means system will display the PMA info dialog. When we push Year End PMA then make it false
      openPMAInformationDialog: false,
    };
    /**bind methods */
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handlePMAIssuesDialog = this.handlePMAIssuesDialog.bind(this);
  }
  // componentWillMount = () => {
  //   /** check for default step */
  //   try {
  //     this.props.switchStep(0);
  //   } catch (err) {}
  // };

  componentWillReceiveProps(newProps) {}

  // componentDidUpdate(prevProps, prevState) {
  //   const { mlsAreas, switchArea, currentArea, isLoading } = this.props;
  //   let paramAreaID = this.props.match.params.areaID;
  //   console.log("!!!!comnponentdid update", paramAreaID);
  //   console.log("currentArea", currentArea);
  //   console.log("prevProps", prevProps);
  //   console.log("prevState", prevState);
  //   if (
  //     mlsAreas &&
  //     Array.isArray(mlsAreas) &&
  //     mlsAreas.length > 0 &&
  //     isEmpty(currentArea)
  //   ) {
  //     this.setState(
  //       {
  //         navbarData: {
  //           ...mlsAreas.filter((area) => area.areaID === paramAreaID)[0],
  //         },
  //       },
  //       () => {
  //         //currentArea.mlsAreaID !== mlsAreas[0].mlsAreaID &&
  //         switchArea(mlsAreas.filter((area) => area.areaID === paramAreaID)[0]);
  //       }
  //     );
  //   }
  // }

  componentDidMount() {
    const stateData = GetStateStorage();
    const agentData = stateData.userData;
    //check if state data not founf

    const { areaID } = this.props.match.params;

    const { mlsAreas, switchArea, history } = this.props;

    /**Get session storage */
    // let navbarData1 =
    //   agentData && Array.isArray(agentData.navbarInfo)
    //     ? agentData.navbarInfo[0]
    //     : {};
    // console.log("navbarData1", navbarData1);

    // let paramAreaID = this.props.match.params.areaID;

    // let navbarData =
    //   agentData && Array.isArray(agentData.navbarInfo)
    //     ? agentData.navbarInfo.filter(
    //         (area) => area.mlsAreaID === paramAreaID
    //       )[0]
    //     : {};

    let paramAreaID = this.props.match.params.areaID;
    // console.log("***agentData", agentData);

    let navbarInfo;
    // console.log(
    //   "Array.isArray(agentData.navbarInfo",
    //   Array.isArray(agentData.navbarInfo)
    // );
    let areaIDList = agentData.navbarInfo.map((area) => area.mlsAreaID);
    // console.log("areaIDList", areaIDList);
    let idMatches = areaIDList.indexOf(paramAreaID) >= 0; //if session switch, if the agent does not have the navbar area id that matches the url, then we need to take the first navbar area for the agent because that means that they are being switched from one agent to another on admin side
    // console.log("idMatches", idMatches);

    if (agentData && Array.isArray(agentData.navbarInfo)) {
      // console.log("inside agent data and is array");
      if (!idMatches) {
        console.log("id doesnt match", agentData);
        navbarInfo = agentData.navbarInfo[0];
        history.push(`/pma/${agentData.navbarInfo[0].mlsAreaID}`);
      } else {
        //console.log("has agent data and navbar info");
        navbarInfo = agentData.navbarInfo.filter(
          (area) => area.mlsAreaID === paramAreaID
        )[0];
      }
    } else {
      //  console.log("inside the else");
      navbarInfo = {};
    }
    //console.log("navbarData", navbarInfo);

    const isValid = IsValidNavbarData();

    !this.props.mlsAreas.length &&
      this.props.getNavbarInfo(stateData.userData.listgenUserID, navbarInfo);
    switchArea(navbarInfo);
    /**get next pma print date */
    this.props.getNextPMAPrintDate();

    const { mlsAreaID, mlsCityID, mlsCityName } = navbarInfo;
    //  console.log("inside index", agentData);
    // let splitLicense = agentData.agentLicenseNumber.split("/")[0].trim();
    // console.log("splitLicense", splitLicense);
    this.props.getAgentQR(agentData.agentLicenseNumber, mlsAreaID);
    let showApproval = []; // ["101003", "101006", "100474"];

    if (showApproval.includes(agentData.listgenUserID)) {
      this.setState({
        enableMailProApprovalProcess: true, //it's also linked with mailpro approval button is also enable
      });
    } else {
      this.setState({
        enableMailProApprovalProcess: agentData.enableApprovalButton || false, //check the agent data for this process
      });
    }
    if (isValid) {
      this.setState({
        navbarData: navbarInfo,
      });
      switchArea(navbarInfo);
    } else {
    }
    /**now scroll to top also */
    window.scrollTo(0, 0);
    //show the pma information dialog
    if (this.state.enablePMAInformationDialog) {
      this.setState({
        openPMAInformationDialog: true,
      });
    }
  }

  handleDrawerOpen = () => {
    this.setState({
      openDrawer: true,
    });
  };

  handleDrawerClose = () => {
    this.setState({
      openDrawer: false,
    });
  };

  handlePMAIssuesDialog = (openDialog = false) => {
    this.setState({
      openPMAIssuesDialog: openDialog,
    });
  };

  // switchVersion = v => {
  //   console.log('version is switching to ', v, typeof v);
  //   const switchVersion = this.props.switchVersion(v);
  // };

  render() {
    const {
      mlsAreas,
      currentArea,
      currentStep,
      propertyData,
      qrCodeMailerProID,
    } = this.props;
    const stateData = GetStateStorage();
    const agentData = stateData.userData;
    const { mlsCityName } = currentArea;
    // console.log("inside agent index");
    // console.log("mlsAreas", mlsAreas);
    //  console.log("currentArea", this.props, this.state);
    const { pmaScheduleID } = agentData;
    let isUnavailable = false;

    if (
      mlsCityName === "WOODSIDE" ||
      mlsCityName === "ATHERTON" ||
      mlsCityName === "MENLO PARK" ||
      mlsCityName === "PORTOLA VALLEY" ||
      pmaScheduleID === "COMPASS-SCL"
    ) {
      isUnavailable = true;
    }

    return (
      <WebsiteLayout
        sidebarMode={true}
        openSidebarModeSidebar={this.state.openDrawer}
        sidebarModeHandleClose={this.handleDrawerClose}
        hideFooter={true}
      >
        {this.state.openPMAIssuesDialog ? (
          <PMAIssueReportsDialog
            handlePMAIssuesDialog={this.handlePMAIssuesDialog}
            currentArea={this.props.currentArea}
          />
        ) : (
          ""
        )}

        {mlsAreas &&
        mlsAreas.length >= 1 &&
        !isEmpty(currentArea) &&
        qrCodeMailerProID &&
        qrCodeMailerProID.length > 1 ? (
          <AgentPMA
            agentData={agentData}
            mlsAreas={mlsAreas}
            navbarData={currentArea}
            handleDrawerOpen={this.handleDrawerOpen}
            isFullProMode={this.state.isFullMode}
            enableMailProApprovalProcess={
              this.state.enableMailProApprovalProcess
            }
            handlePMAIssuesDialog={this.handlePMAIssuesDialog}
            propertyData={propertyData}
            qrCodeMailerProID={qrCodeMailerProID}
            //version={version}
            //switchVersion={this.switchVersion}
            isUnavailable={isUnavailable}
          />
        ) : (
          <div
            style={{
              height: "80vh",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              background: "inherit",
            }}
          >
            <div>
              <CircularProgress />
            </div>
            {/* style={{ marginTop: '2vh', marginLeft: '2vh' }} */}
            <div>
              <Typography variant="subtitle2">
                &nbsp;&nbsp;Loading...
              </Typography>
            </div>
          </div>
        )}
      </WebsiteLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentArea: state.pma.currentArea,
    mlsAreas: state.pma.mlsAreas,
    localStorageData: state.localStorageData,
    version: state.pma.version,
    currentStep: state.pma.currentStep,
    propertyData: state.pma.propertyData,
    isLoading: state.pma.isLoading,
    nextPMAPrintDate: state.pma.nextPMAPrintDate,
    qrCodeMailerProID: state.pma.qrCodeMailerProID,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    getNavbarInfo: (listgenUserID) =>
      dispatch(pmaActions.getNavbarInfo(listgenUserID)),
    switchArea: (area) => dispatch(pmaActions.switchArea(area)),
    switchStep: (step) => dispatch(pmaActions.switchStep(step)),
    getNextPMAPrintDate: () => dispatch(pmaActions.getNextPMAPrintDate()),
    updateLocalStorageDataForRedux: (localStorageData) =>
      dispatch(pmaActions.updateLocalStorageDataForRedux(localStorageData)),
    switchVersion: (version) => {
      dispatch(pmaActions.switchVersion(version));
    },
    explicitEnableNewMailProInitFn: (data) =>
      dispatch(pmaActions.explicitEnableNewMailProInitFn(data)),
    changeLoading: (status) => {
      dispatch(pmaActions.changeLoading(status));
    },

    getAgentQR: (agentLicenseNumber, areaId) => {
      dispatch(pmaActions.getAgentQR(agentLicenseNumber, areaId));
    },
  };
}
//withRouter
export default connect(mapStateToProps, mapDispatchToProps)(PMAMain);
