import React, { Component } from "react";
import moment from "moment";
import { propertyUtil } from "views/Pma/PropertyUtil";

class HighlightRow extends Component {
  render() {
    const {
      prop,
      index,
      areaID,
      handleSelect,
      openEditDialogFn,
      showButton,
    } = this.props;
    //  console.log("highlightrow", this.props);
    const getMonthAndYearString = (inputTime) => {
      try {
        if (!inputTime || inputTime === "-") {
          return <p>-</p>;
        } else {
          let month = moment(inputTime).format("MMM");
          let day = moment(inputTime).format("D");
          let year = moment(inputTime).format("YYYY");
          return (
            <p>
              {month} {day},{" "}
              {Number(year) === 2021 ? (
                <span className="bolded-year">{year}</span>
              ) : (
                year
              )}
            </p>
          );
        }
      } catch (err) {
        return "";
      }
    };
    const { formatSuffix } = propertyUtil;
    let represented =
      prop.isBuyingAgent && prop.isSellingAgent
        ? "Buyer/Seller"
        : prop.isBuyingAgent
        ? "Buyer"
        : prop.isSellingAgent
        ? "Seller"
        : "";

    return (
      <div
        key={`${prop.address} ${prop.listPrice} ${prop.saleDate} ${index}`}
        className={`prop-highlight-tr ${
          prop.isSystemSelected ? "selected-prop" : ""
        }`}
      >
        <div className="highlight-td">
          {/*{(prop.isSystemSelected && index === 0) ||
          (prop.isSystemSelected && index === 1)
            ? "Large"
            : (prop.isSystemSelected && index === 2) ||
              (prop.isSystemSelected && index === 3) ||
              (prop.isSystemSelected && index === 4)
            ? "Small"
            : ""}*/}
        </div>
        <div className="highlight-td">
          {prop.isSystemSelected ? index + 1 : ""}
        </div>

        {prop.isSystemSelected ? (
          <div className="highlight-td">SELECTED</div>
        ) : (
          <div
            className="highlight-td"
            onClick={() => handleSelect(prop, true)}
          >
            <div
              className={!prop.isSystemSelected ? "selected-prop" : undefined}
            ></div>
          </div>
        )}

        <div className="highlight-td" onClick={() => handleSelect(prop, false)}>
          {prop.isSystemSelected ? (
            <div
              className={
                prop.isSystemSelected ? "deselect-btn highlight-td" : undefined
              }
            >
              <p>X</p>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="highlight-td">
          {showButton && (
            <div className="edit-button" onClick={() => openEditDialogFn(prop)}>
              <p>Edit</p>
            </div>
          )}
        </div>
        <div className="highlight-td">{represented}</div>
        <div className="highlight-td">
          {" "}
          {prop.streetNumber === "-" || prop.streetNumber === "0"
            ? ""
            : prop.streetNumber}{" "}
          {prop.streetName}{" "}
          {prop.streetSuffix ? formatSuffix(prop.streetSuffix, areaID) : ""}
          {prop.unitNumber && prop.unitNumber !== "-"
            ? `, #${prop.unitNumber}`
            : ""}
        </div>
        <div className="highlight-td">
          {prop.listPrice && prop.status === "Active"
            ? `${Number(prop.listPrice).toLocaleString()}`
            : "-"}
        </div>
        <div className="highlight-td">
          {prop.status !== "Sold"
            ? "-"
            : `${Number(prop.salePrice).toLocaleString()}`}
        </div>
        <div className="highlight-td">
          {getMonthAndYearString(prop.saleDate)}
        </div>
        {/*<div className="highlight-td" >Upload Photo</div>*/}
      </div>
    );
  }
}

export default HighlightRow;
