import React, { Component } from 'react';

class TopEconomists extends Component {
  render() {
    return (
      <div className="top-economists">
        <div className="top-economists-title-container">
          <div className="top-economists-title">Top Economists</div>
          <div className="top-economists-subtitle">Weigh in on 2021</div>
          <div className="top-economists-divider" />
        </div>
        <div className="top-economists-body">
          <div className="top-economists-section-container">
            <div className="top-economists-section short">
              <div className="top-economists-section-title">Fresh Start...</div>
              <div className="top-economists-section-text">
                "With Covid-19 vaccines soon available and with mortgage rates
                to hover around 3%, NAR Chief Economist predicts a 3% home price
                increase, 9% existing-home sales increase, and a 23% surge in
                new-home sales."
              </div>
              <div className="top-economists-author">
                - Lawrence Yun,{' '}
                <span className="top-economists-author-margin"></span>National
                Association of Realtors
              </div>
            </div>
          </div>
          <div className="top-economists-section-container">
            <div className="top-economists-section short">
              <div className="top-economists-section-title">
                Buyers are Moving Faster!
              </div>
              <div className="top-economists-section-text">
                "Buyers are moving much faster than this time last year to beat
                out competition and lock in low mortgage rates. This means homes
                are sitting on the market for much less time, despite notably
                higher price tags."
              </div>
              <div className="top-economists-author">
                - Danielle Hale,{' '}
                <span className="top-economists-author-margin"></span>Chief
                Economist, Realtor.com
              </div>
            </div>
          </div>
          <div className="top-economists-section-container">
            <div className="top-economists-section">
              <div className="top-economists-section-title">
                Sellers Positioned Well
              </div>
              <div className="top-economists-section-text">
                In 2021, “Home prices will hit new highs, even though the pace
                of growth slows. Buyers will remain plentiful and low mortgage
                rates keep purchasing power healthy, but monthly mortgage costs
                will rise as mortgage rates steady and home prices continue to
                rise."
              </div>
              <div className="top-economists-author">
                - Danielle Hale,{' '}
                <span className="top-economists-author-margin"></span>Chief
                Economist, Realtor.com
              </div>
            </div>
          </div>

          <div className="top-economists-section-container">
            <div className="top-economists-section">
              <div className="top-economists-section-title">
                The Bottom Line
              </div>
              <div className="top-economists-section-text">
                "In most local real estate markets across the U.S., sellers will
                continue to have the upper hand in 2021, due to tight inventory
                conditions. Home prices will keep climbing in most parts of the
                U.S., and borrowers will continue to enjoy low mortgage rates
                throughout next year."
              </div>
              <div className="top-economists-author">
                - Home Buying Institute
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopEconomists;
