export default theme => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        //margin: theme.spacing(10),
        display: '-webkit-flex',
        WebkitAlignItems: 'center',
        WebkitJustifyContent: 'center',
        backgroundImage: 'linear-gradient(to right top, #a2beeb, #8ecfee, #8edde5, #a4e7d5, #c7eec7)',
        flexDirection: 'column',
        WebkitFlexDirection: 'column'
    },
    card: {
        width: '320px',
        height: '190px',
        WebkitPerspective: '600px',
        MozPerspective: '600px',
        perspective: '600px',
        marginTop: -theme.spacing(5),
        marginLeft: - theme.spacing(5),
        '&:hover': {
            '&>.front_card': {
                WebkitTransform: 'rotateY(180deg)',
                MozTransform: 'rotateY(180deg)'
            },
            '&>.back_card': {
                WebkitTransform: 'rotateY(0deg)',
                MozTransform: 'rotateY(0deg)'
            }
        }
    },
    cardPart: {
        boxShadow: '1px 1px #aaa3a3',
        WebkitBoxShadow: '1px 1px #aaa3a3',
        MozBoxShadow: '1px 1px #aaa3a3',
        top: '0',
        position: 'absolute',
        zIndex: '10000999',
        left: '0',
        display: 'inline-block',
        width: '320px',
        height: '190px',
        backgroundImage: "url('/images/g3095.png'), linear-gradient(to right bottom, #fd696b, #fa616e, #f65871, #f15075, #ec4879)",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: '8px',
        WebkitTransition: 'all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
        MozTransition: 'all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
        MsTransition: 'all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
        OTransition: 'all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
        transition: 'all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
        WebkitTransformStyle: 'preserve-3d',
        MozTransformStyle: 'preserve-3d',
        WebkitBackfaceVisibility: 'hidden',
        MozBackfaceVisibility: 'hidden',
    },
    cardFront: {
        padding: theme.spacing(2.5),
        WebkitTransform: 'rotateY(0)',
        MozTransform: ' rotateY(0)',
    },
    cardFrontTop: {
        width: '100%'
    },
    cardBack: {
        padding: theme.spacing(2.5),
        WebkitTransform: 'rotateY(-180deg)',
        MozTransform: 'rotateY(-180deg)',
    },
    cardFrontBottom: {
        marginTop: '30%'
    },
    cardBlackLine: {
        marginTop: '5px',
        height: '38px',
        backgroundColor: '#303030',
        width: 'calc(100% + 40px)',
        marginLeft: -theme.spacing(2.5),
        marginRight: -theme.spacing(2.5)
    },
    cardLogo: {
        height: '16px',
    },
    cardFrontLogo: {
        position: 'absolute',
        top: '16px',
        right: '18px',
        color: 'white',
        textTransform: 'uppercase',
        fontWeight: '1000'
    },
    cardSquare: {
        borderRadius: '5px',
        height: '30px',
        float: 'left'
    },
    cardNumber: {
        display: 'block',
        width: '100%',
        wordSpacing: '4px',
        fontSize: '20px',
        letterSpacing: '2px',
        color: '#fff',
        textAlign: 'center',
        marginBottom: '20px',
        marginTop: '20px',
        fontWeight: '800'
    },
    cardSpace75: {
        width: '75%',
        float: 'left',
        textAlign: 'left'
    },
    cardSpace25: {
        width: '25%',
        float: 'left',
        textAlign: 'right'
    },
    cardLabel: {
        fontSize: '10px',
        textTransform: 'uppercase',
        color: 'rgba(255,255,255,0.8)',
        letterSpacing: '1px'
    },
    cardInfo: {
        marginBottom: '0',
        marginTop: '5px',
        fontSize: '16px',
        lineHeight: ' 18px',
        letterSpacing: '1px',
        textTransform: 'uppercase',
        color: 'white'
    },
    cardBackContent: {
        padding: '15px 15px 0'
    },
    cardSecretFirst: {
        position: 'absolute',
        height: '40px',
        top: '-8px',
        left: '-10px',
        width: '91%',
        borderRadius: '4px',
        background: 'repeating-linear-gradient(45deg, #ededed, #ededed 5px, #f9f9f9 5px, #f9f9f9 10px)'
    },
    cardSecretLast: {
        color: '#303030',
        textAlign: 'right',
        margin: theme.spacing(0),
        fontSize: '14px',
        float: 'right',
        width: '9%'
    },
    cardSecret: {
        padding: '5px 12px',
        backgroundColor: '#fff',
        position: 'relative',
        width: '95%',
        display: 'inline-block'
    },
    cardBackLogo: {
        position: 'absolute',
        bottom: '22px',
        right: '15px',
        color: 'white',
        fontWeight: '1000'
    },
    cardBackSquare: {
        position: 'absolute',
        bottom: '15px',
        left: '15px'
    }
});