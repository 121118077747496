import React, { Component } from "react";
import MQThreeFirstLeft from "./MQThreeFirstLeft";
import MQThreeFirstRight from "./MQThreeFirstRight";
import CustomDeborahSamuelPage from "views/Pma/PmaEditor/CustomAgentPages/CustomDeborahSamuelPage";
import V2DebSamuel from "views/Pma/PmaEditor/CustomAgentPages/V2DebSamuel";

class MQThreeFirstPage extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      MQCityDetails,
      printVersion,
      mlsAreas,
      version,
      coverPageDetails,
      testimonials,
      quarterlyAreaTable,
      agentAdvantages,
      agentDesignations,
      demoAccountAreaName,
      demoAccountCoverPhoto,
      demoAccountHOName,
      missionStatement,
    } = this.props;
    const { listgenUserID } = agentData;
    return (
      <div
        className={
          "pma-page-container " +
          (printVersion
            ? "mq3-print-page-container"
            : "mq3-editor-page-container")
        }
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {listgenUserID === "100585" ? (
          <V2DebSamuel
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version="mq3"
            coverPageDetails={coverPageDetails}
          />
        ) : (
          <MQThreeFirstLeft
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            MQCityDetails={MQCityDetails}
            printVersion={printVersion}
            mlsAreas={mlsAreas}
            version={version}
            testimonials={testimonials}
            agentAdvantages={agentAdvantages}
            agentDesignations={agentDesignations}
            coverPageDetails={coverPageDetails}
            quarterlyAreaTable={quarterlyAreaTable}
            missionStatement={missionStatement}
          />
        )}

        <MQThreeFirstRight
          agentData={agentData}
          currentArea={currentArea}
          MQCityDetails={MQCityDetails}
          printVersion={printVersion}
          version={version}
          hoData={hoData}
          mlsAreas={mlsAreas}
          coverPageDetails={coverPageDetails}
          quarterlyAreaTable={quarterlyAreaTable}
          demoAccountAreaName={demoAccountAreaName}
          demoAccountCoverPhoto={demoAccountCoverPhoto}
        />
      </div>
    );
  }
}

export default MQThreeFirstPage;
