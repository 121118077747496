import React, { Component } from 'react';
import ShorelineImg from './updated_shoreline.png';

class YETestimonial extends Component {
  render() {
    const { testimonials } = this.props;
    const {
      clientFirstName,
      clientLastName,
      testimonialContent,
    } = testimonials;
    if (
      testimonials === 'none' ||
      !testimonials ||
      !testimonials.testimonialContent
    ) {
      return (
        <div className="ye-testimonial">
          <img
            src={ShorelineImg}
            alt="Shoreline Img"
            className="sunset-beach-img"
          />
        </div>
      );
    } else {
      return (
        <div className="ye-testimonial">
          <img
            src={ShorelineImg}
            alt="Shoreline Img"
            className="sunset-beach-img"
          />

          <div className="testimonial-top-layer">
            <div className="testimonial-container">
              <div className="testimonial-content-row">
                <div className="ye-testimonial-quotation start-quote">"</div>
                <div className="testimonial-text">{testimonialContent}</div>
                <div className="ye-testimonial-quotation end-quote">"</div>
              </div>
              <div className="testimonial-signoff">
                <p>
                  {clientFirstName} {clientLastName}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default YETestimonial;
