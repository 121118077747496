import React, { Component } from "react";
import "./customStyles.scss";
import "./customPrintStyles.scss";

class JohnsonsLogoQuadrants extends Component {
  render() {
    //console.log("inside quadrant");
    return (
      <>
        <div className="johnsons-logo-quadrants">
          <div className="johnsons-quadrants-body">
            <div className="body-title-section">
              <div className="johnsons-quadrants-body-title">
                <p>Born and Raised in Santa Barbara,</p>
                <p>Uniquely Positioned...</p>
              </div>

              <div className="johnsons-quadrants-body-subtitle-text">
                To connect your property with the world
              </div>
              <div className="johnsons-quadrants-details">
                <p>
                  Our qualified listings enjoy enhanced global exposure through
                  our exclusive worldwide
                </p>
                <p>
                  affiliations & memberships totaling 162,000+ real estate
                  professionals around the world.
                </p>
              </div>
            </div>
            <div className="section-container">
              <div className="section-row">
                <div className="johnsons-quadrants-section">
                  <img
                    src="https://pma-coverphotos.s3.amazonaws.com/FGP_Logo_Print_Black_500px.png"
                    className="forbes-logo"
                  />
                </div>
                <div className="johnsons-quadrants-section">
                  <img
                    src="https://pma-coverphotos.s3.amazonaws.com/LPI_Horizontal_500px-72ppi.png"
                    className="lpi-logo"
                  />
                </div>
              </div>

              <div className="section-row">
                <div className="johnsons-quadrants-section">
                  <img
                    src="https://pma-coverphotos.s3.amazonaws.com/LeadingRE_ExHorizontal_500px-72ppi.png"
                    className="leading-logo"
                  />
                </div>
                <div className="johnsons-quadrants-section">
                  <img
                    src="https://pma-coverphotos.s3.amazonaws.com/Mayfair_Realty_Black-White_500px-72ppi.png"
                    className="mayfair-logo"
                  />
                </div>
              </div>
            </div>
            <div className="johnsons-quadrants-logo-container">
              <img
                src="https://pma-coverphotos.s3.amazonaws.com/johnsons-quadrant-tile.png"
                alt="Johnsons Icons"
              />
            </div>
            <div className="johnsons-quadrants-details">
              <p>
                At Village Properties, we are honored to have been hand-selected
                by these organizations to
              </p>
              <p>
                deliver an enhanced worldwide marketing strategy for our clients
                and their unique properties.
              </p>
            </div>
            <div className="johnsons-quadrants-body-subtitle-text">
              Let's explore how our networks will go to work for you
            </div>
          </div>
        </div>
        <div className="johnsons-testimonial">
          <div className="johnsons-testimonial-inner">
            <p>
              "I have worked with Dianne and Bri over the course of several
              years through multiple transactions and their investment in the
              journey is unmatched. They understood my goals and knew it would
              take many years of stepping stones to get where I ultimately
              wanted to be, and they buckled up for the long ride without
              hesitation. Their patience and commitment to the hunt for "home"
              (even when I was *cough cough* a touch stubborn at times) was
              incredibly valuable. Persistence in this challenging market is
              key, and they provided the unwavering support, local knowledge,
              and active approach to securing the perfect property. Even if
              early on in your real estate dream, I highly recommend reaching
              out for a chat. It's always the right time to start the journey
              toward "home"!"
            </p>
            <p>— Mary Kathryn</p>
          </div>
        </div>
      </>
    );
  }
}

export default JohnsonsLogoQuadrants;
