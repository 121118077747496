import React, { Component } from "react";
import QVFourEmailAndWebsiteFooter from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourEmailAndWebsiteFooter";
import { propertyUtil } from "views/Pma/PropertyUtil";
import TQAvailableHomes from "views/Pma/PmaEditor/TableQuadrants/TableQuadrantsPageTwo/TQAvailableHomes";
import YETwoYearInReview2024V3 from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoSecondPage/YETwoYearInReview2024/V3";
//import YETwoYearInReview from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoSecondPage/YETwoYearInReview";
// import TQMarketSalesTable from "views/Pma/PmaEditor/TableQuadrants/TableQuadrantsPageTwo/TQMarketSalesTable";
import MQThreeMarketSalesTable from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/MQThreeMarketSalesTable";
import TQMarketIndicators from "views/Pma/PmaEditor/TableQuadrants/TableQuadrantsPageTwo/TQMarketIndicators";

class YETwoSecondLeft extends Component {
  render() {
    const {
      agentData,
      aprilData,
      currentArea,
      hoData,
      printVersion,
      quarterlyAreaTable,
      quarterlyCityTable,
      pmaQuarterlyCombinedCityDTO,
    } = this.props;
    let useCity = false;

    return (
      <div className="ye2-second-left">
        <YETwoYearInReview2024V3
          currentArea={currentArea}
          hoData={hoData}
          aprilData={aprilData}
          pmaQuarterlyCombinedCityDTO={pmaQuarterlyCombinedCityDTO}
          agentData={agentData}
          printVersion={printVersion}
        />
        <QVFourEmailAndWebsiteFooter
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
        />
      </div>
    );
  }
}

export default YETwoSecondLeft;
