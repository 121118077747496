export default theme => ({
    root: {
        
    },
    field: {
        //padding: theme.spacing(3)
        margin: theme.spacing(3)
      },
      textField: {
        width: '100%',
        maxWidth: '100%',
        //marginRight: theme.spacing(3)
      },
      dropdownTopMargin: {
        marginTop: -theme.spacing(5)
      },
      dropdownTopMargin2Unit: {
        marginTop: -theme.spacing(2)
      },
      dropdownTopMargin1UnitPlus: {
        marginTop: theme.spacing(1)
      },
      fieldError: {
        color: theme.palette.error.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
      },
      smallField: {
        width: '25%'
      }
  });
  