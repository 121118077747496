import React, { Component } from "react";
import QV2IndicatorSection from "./QV2IndicatorSection";
import { propertyUtil } from "views/Pma/PropertyUtil";

class QV2MarketIndicators extends Component {
  determineComparison = () => {
    const { infinityCheck } = propertyUtil;
    function calculateDiff(curr, prev) {
      if (curr === "0" && prev === "0") {
        return "0";
      } else if (curr === "0") {
        return -100;
      } else if (prev === "0") {
        return 100;
      } else {
        curr = Math.round(curr);
        prev = Math.round(prev);
        let res = infinityCheck(
          Math.round(((curr - prev) / Math.abs(prev)) * 100)
        );
        return res;
      }
    }
    const { qArea, currentArea } = this.props;
    const { marketHighLightsData } = qArea;

    let dataObj = {
      compareDays: null,
      salePriceDiff: null,
      salePriceDataPoint: null,
      receivedPerc: null,
      receivedDataPoint: null,
      daysToSellPerc: null,
      daysToSellDataPoint: null,
      unitsSoldPerc: null,
      unitsSoldDataPoint: null,
      sqftPercDiff: null,
      sqftDataPoint: null,
      monthComparison: null,
      highestSalePrice: null,
    };
    const {
      current180DaysAvgSalePrice,
      previous180DaysAvgSalePrice,
      current180DaysPercentReceivedOfAsking,
      previous180DaysPercentReceivedOfAsking,
      current180DaysAvgDOM,
      previous180DaysAvgDOM,
      current180DaysUnitsSold,
      previous180DaysUnitsSold,
      current180DaysPricePerSqFt,
      previous180DaysPricePerSqFt,

      current90DaysAvgSalePrice,
      previous90DaysAvgSalePrice,
      current90DaysPercentReceivedOfAsking,
      previous90DaysPercentReceivedOfAsking,
      current90DaysAvgDOM,
      previous90DaysAvgDOM,
      current90DaysUnitsSold,
      previous90DaysUnitsSold,
      current90DaysPricePerSqFt,
      previous90DaysPricePerSqFt,
    } = marketHighLightsData;
    // console.log("marketHighLightsData", marketHighLightsData);
    if (+current90DaysAvgSalePrice < 1) {
      dataObj.compareDays = "6 Months";
      dataObj.monthComparison = 6;
      dataObj.salePriceDataPoint = current180DaysAvgSalePrice;
      dataObj.salePriceDiff = calculateDiff(
        current180DaysAvgSalePrice,
        previous180DaysAvgSalePrice
      );
      dataObj.receivedDataPoint = current180DaysPercentReceivedOfAsking;
      dataObj.receivedPerc = calculateDiff(
        current180DaysPercentReceivedOfAsking,
        previous180DaysPercentReceivedOfAsking
      );
      dataObj.sqftDataPoint = current180DaysPricePerSqFt;
      dataObj.sqftPercDiff = calculateDiff(
        current180DaysPricePerSqFt,
        previous180DaysPricePerSqFt
      );
      dataObj.daysToSellDataPoint = current180DaysAvgDOM;
      dataObj.daysToSellPerc = calculateDiff(
        current180DaysAvgDOM,
        previous180DaysAvgDOM
      );
      dataObj.unitsSoldDataPoint = current180DaysUnitsSold;
      dataObj.unitsSoldPerc = calculateDiff(
        current180DaysUnitsSold,
        previous180DaysUnitsSold
      );
      // console.log("dataObj", dataObj);
      return dataObj;
    } else {
      dataObj.compareDays = "90 Days";
      dataObj.monthComparison = 6;
      dataObj.salePriceDataPoint = current90DaysAvgSalePrice;
      dataObj.salePriceDiff = calculateDiff(
        current90DaysAvgSalePrice,
        previous90DaysAvgSalePrice
      );
      dataObj.receivedDataPoint = current90DaysPercentReceivedOfAsking;
      dataObj.receivedPerc = calculateDiff(
        current90DaysPercentReceivedOfAsking,
        previous90DaysPercentReceivedOfAsking
      );

      dataObj.sqftDataPoint = current90DaysPricePerSqFt;
      dataObj.sqftPercDiff = calculateDiff(
        current90DaysPricePerSqFt,
        previous90DaysPricePerSqFt
      );

      dataObj.daysToSellDataPoint = current90DaysAvgDOM;
      dataObj.daysToSellPerc = calculateDiff(
        current90DaysAvgDOM,
        previous90DaysAvgDOM
      );
      dataObj.unitsSoldDataPoint = current90DaysUnitsSold;
      dataObj.unitsSoldPerc = calculateDiff(
        current90DaysUnitsSold,
        previous90DaysUnitsSold
      );

      return dataObj;
    }
  };

  render() {
    const determineComparison = this.determineComparison();

    return (
      <div className="qv2-market-indicators">
        <div className="mini-market-title">
          <div className="left-market-title-container">
            <div className="left-market-title-section">
              <p>Key Market Indicators</p>
            </div>
            <div className="mini-market-title-divider"></div>
          </div>

          <div className="right-market-title-section">
            <p>Last {determineComparison.compareDays}</p>
            <p className="lc-market-title">vs</p>
            <p> Previous {determineComparison.compareDays}</p>
          </div>
        </div>
        <div className="indicator-container">
          <div className="inner-indicator-container">
            <QV2IndicatorSection
              category="Sale Prices"
              value={determineComparison.salePriceDataPoint}
              perc={determineComparison.salePriceDiff}
            />
            <QV2IndicatorSection
              category="% Received of Asking"
              value={determineComparison.receivedDataPoint}
              perc={determineComparison.receivedPerc}
            />
            <QV2IndicatorSection
              category="Days to Sell"
              value={determineComparison.daysToSellDataPoint}
              perc={determineComparison.daysToSellPerc}
            />

            <QV2IndicatorSection
              category="Square Ft"
              value={determineComparison.sqftDataPoint}
              perc={determineComparison.sqftPercDiff}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default QV2MarketIndicators;
