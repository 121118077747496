import React, { Component } from "react";
import YETwoFirstPage from "./YETwoFirstPage";
import YETwoSecondPage from "./YETwoSecondPage";
import PropTypes from "prop-types";

class YETwo extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./ye2print.scss");
      require("./ye2-alt-print.scss");
    } else {
      require("./ye2styles.scss");
      require("./ye2-alt-editor.scss");
    }
  }
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      currentArea,
      mlsAreas,
      coverPageDetails,
      aprilData,
      flipBook,
      demoAccountHOName,
      demoAccountCoverPhoto,
      demoAccountAreaName,
      demoAccountCity,
      quarterlyAreaTable,
      testimonials,
      typeOfVersion,
      handlePropertyHighlightDialog,
      propertyPhotoDetailsDTO,
      pmaQuarterlyCombinedCityDTO,
      quadrantDetails,
    } = this.props;

    console.log("ye2", this.props);
    // console.log("ye2", this.props.hoData);

    return (
      <div>
        {/*do not show Edit button for Deborah Samuel, she has a different page*/}

        <div
          className={printVersion ? "ye2-print-page" : "ye2-pma-editor-page"}
        >
          <YETwoFirstPage
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version={version}
            coverPageDetails={coverPageDetails}
            aprilData={aprilData}
            demoAccountHOName={demoAccountHOName}
            demoAccountCoverPhoto={demoAccountCoverPhoto}
            demoAccountAreaName={demoAccountAreaName}
            testimonials={testimonials}
            typeOfVersion={typeOfVersion}
            handlePropertyHighlightDialog={handlePropertyHighlightDialog}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            quadrantDetails={quadrantDetails}
            quarterlyAreaTable={quarterlyAreaTable}
          />
          <YETwoSecondPage
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version={version}
            aprilData={aprilData}
            demoAccountCity={demoAccountCity}
            demoAccountHOName={demoAccountHOName}
            quarterlyAreaTable={quarterlyAreaTable}
            pmaQuarterlyCombinedCityDTO={pmaQuarterlyCombinedCityDTO}
          />
        </div>
      </div>
    );
  }
}

export default YETwo;
