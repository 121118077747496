import React, { useEffect, memo, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  DialogTitle,
  ListSubheader,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  Chip,
  Paper,
  Divider,
  Link
}
  from '@material-ui/core';
import styles from './styles';
import classNames from 'classnames';
import { isArray } from 'util';
import { remove, cloneDeep } from 'lodash';
import { ClearAll } from '@material-ui/icons';
import { isEqual } from 'lodash';

/**
 * Sticky list component
 */
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

const PinnedSubheaderList = (props) => {
  const classes = useStyles();
  const { optionData, handleCheckboxClick, selectedValues } = props;
  return (
    <List className={classes.root} subheader={<li />}>
      {Object.keys(optionData).map((key, i) => (
        <li key={`section-g-${key}-${Math.random()}`} className={classes.listSection}>
          <ul className={classes.ul}>
            <ListSubheader className={classes.listSUbHeader}>{`${key}`}</ListSubheader>
            {optionData[key].map(item => (
              <ListItem key={`item-c-${key}-${item[0]}-${Math.random()}`}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedValues.indexOf(item[0]) !== -1}
                    tabIndex={-1}
                    disableRipple
                    color="primary"
                    inputProps={{ 'aria-labelledby': `checkbox-list-label-${key - item[0]}-${Math.random()}` }}
                    onClick={event => handleCheckboxClick(event, item[0], item[1])}
                  />
                </ListItemIcon>
                <ListItemText primary={`${item[1]} (${item[0]})`} />
              </ListItem>
            ))}
          </ul>
        </li>
      ))}
    </List>
  );
};

class CityAreaMapDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadList: true,
      previousValue: [],
      chipData: [],
      selectedValues: []
    };
    /**bind methods */
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
  }

  handleCancel = () => {
    const { onClose } = this.props;
    onClose();
  }

  handleOk = () => {
    const { onSubmit } = this.props;
    onSubmit(this.state.chipData);
  }

  handleChange = (event, newValue) => {
    //setValue(newValue);
  }

  clearAll = (event) => {
    event.preventDefault();
    const newState = { ...this.state };
    newState.chipData = [];
    newState.selectedValues = [];
    this.setState(newState);
  }

  handleDelete = (id) => {
    const newState = { ...this.state };
    let xData = newState.chipData;
    remove(xData, (item) => {
      return item.id === id;
    });
    newState.chipData = xData;
    /**now do for selected values also */
    xData = newState.selectedValues;
    remove(xData, (item) => {
      return item === id;
    });
    newState.selectedValues = xData;
    this.setState(newState);
  };

  handleCheckboxClick = (event, id, label) => {
    const newState = { ...this.state };
    if (event.target.checked) {
      let xData = newState.chipData;
      xData.push({
        id,
        label
      });
      newState.chipData = xData;
      newState.selectedValues.push(id);
      this.setState(newState);
    } else {
      this.handleDelete(id);
    }
  };

  componentWillReceiveProps = (newProps) => {
    const { selectedAreas } = newProps; 
    let isEqualAreas = isEqual(selectedAreas.sort(), this.state.selectedValues.sort());
    if (!isEqualAreas) {
      this.setState((prevState, props) => {
        return {
          selectedValues: selectedAreas,
          chipData: (selectedAreas.length === 0 ? [] : prevState.chipData)
        };
      });
    }
  };

  render() {
    const { onClose, onSubmit, selectedAreas, open, optionData, classes } = this.props;
    const { chipData, loadList, selectedValues } = this.state;
    return (

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        aria-labelledby="city-area-map-dialog-title"
        open={open}
      >
        <DialogTitle id="city-area-map-dialog-title">Select Area</DialogTitle>
        <DialogContent dividers>
          {isArray(chipData) && chipData.length ?
            <div>
              <Typography variant="subtitle2">
                Selected Area's&nbsp;({chipData.length})
                &nbsp; &nbsp;&nbsp;<Link className={classes.cursorPointer} onClick={event => this.clearAll(event)}>Clear All</Link>
              </Typography>
              <br />
              {chipData.map((item, key) => (
                <Chip
                  variant="outlined"
                  size="small"
                  key={item.id}
                  label={item.label + ' ' + '(' + item.id + ')'}
                  onDelete={event => this.handleDelete(item.id)}
                  className={classes.chip}
                />
              ))}
              <Divider className={classes.chipDivider} />
            </div>
            : ''}
          {Object.keys(optionData).length > 0 ?

            loadList ?
              <PinnedSubheaderList
                optionData={optionData}
                handleCheckboxClick={this.handleCheckboxClick}
                selectedValues={selectedValues} />
              : 'Loading...'
            :
            <Typography variant="subtitle1">
              No city area data found.
      </Typography>
          }
        </DialogContent>
        <DialogActions className={classes.dialogFooterActions}>
          <Button onClick={event => this.handleCancel()} color="primary">
            Cancel
          </Button>
          <Button onClick={event => this.handleOk()} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
};

CityAreaMapDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  optionData: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  selectedAreas: PropTypes.array.isRequired,
};

CityAreaMapDialog.defaultProps = {
  onSubmit: () => { },
  onClose: () => { }
};

export default withStyles(styles)(CityAreaMapDialog);

