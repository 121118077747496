import React from 'react';
import { connect } from "react-redux";
import styles from "./styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import { pmaActions } from "_store/_actions";
import { TextField } from '@material-ui/core';
import classNames from 'classnames';
import { MailProDialog } from '../MailProDialog/MailProDialog';
import { MailProDialogHeader } from '../MailProDialog/MailProDialogHeader';
import { MailProDialogBody } from '../MailProDialog/MailProDialogBody';
import MailProButton from '../UI/MailProButton';
import { Fragment } from 'react';


const ManageAGroup = ({ children, classes }) => {
    const [open, setOpen] = React.useState(false);
    const [groupName, setCurrentGroup] = React.useState('');

    const openDialog = () => {
        setOpen(true);
    }

    const closeDialog = () => {
        setOpen(false);
    }

    const updateGroup = (newGroupName) => { }

    return <div>
        {children ? <div onClick={openDialog}>{children}</div> : <div></div>}
        {open && <MailProDialog open={open} classes={classes.root}>
            <MailProDialogHeader>
                <div className={classNames(classes.displayRow, classes.manageGroupTitle)} style={{ gap: 0 }}>
                    <div className={classes.manageGroupHeader}>Group Options</div>
                    <div className={classes.manageGroupSubHeader}>MAIN ST. HOMES</div>

                </div>
            </MailProDialogHeader>
            <MailProDialogBody>
                <div className={classes.manageGroupContainer}>
                    <div className={classNames(classes.dialogSubtitle, classes.currentMailProColor)}>
                        15 Properties in this Group
                    </div>
                    <div className={classes.manageGroupButtons}>
                        <div className={classes.manageGroupCustomPS}>
                            <MailProButton
                                key={"mp_new_add_group_cance;"}
                                variant="text"
                                label={<Fragment>
                                    <p>Click to write a Custom</p>
                                    <p>Group Note or P.S.</p>
                                    <p>in this Group</p>
                                </Fragment>}
                                customStyle={{ fontSize: "12px", fontWeight: 500 }}
                                onClick={closeDialog}
                            />
                        </div>
                        <div>
                            <MailProButton
                                key={"mp_new_add_group_cance;"}
                                variant="text"
                                label={"Edit Group Name"}
                                customStyle={{ fontSize: "12px", fontWeight: 500 }}
                                classes={classes.manageGroupBlackLinks}
                                onClick={closeDialog}
                            />
                        </div>
                        <div>
                            <MailProButton
                                key={"mp_new_update_group"}
                                variant="text"
                                additionalClass={classes.manageGroupBlackLinks}
                                label={<Fragment>
                                    <p>Display a List</p>
                                    <p>of Homeowners</p>
                                    <p>in this Group</p>
                                </Fragment>}
                                customStyle={{ fontSize: "12px", fontWeight: 500 }}
                                onClick={() => { }}
                            />
                        </div>
                        <div>
                            <MailProButton
                                key={"mp_new_update_group"}
                                variant="text"
                                label={"Delete this Group"}
                                customStyle={{ fontSize: "12px", fontWeight: 500 }}
                                additionalClass={classes.manageGroupDelete}
                                onClick={() => { }}
                            />
                        </div>
                    </div>
                    <div className={classes.footer} style={{marginTop: '16px'}}>
                        <div>
                            <MailProButton
                                key={"mp_new_add_group_cance;"}
                                variant="outlined"
                                label={"CANCEL"}
                                customStyle={{ fontSize: "12px", fontWeight: 600 }}
                                onClick={closeDialog}
                            />
                        </div>
                        <div></div>
                        <div></div>


                    </div>
                </div>
            </MailProDialogBody>
        </MailProDialog>}
    </div>
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles), withSnackbar)(ManageAGroup));