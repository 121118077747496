import React, { Fragment, useEffect, useRef, useState } from "react";
import { pmaActions } from "../../../../_store/_actions";
import { connect } from "react-redux";
import { tableSortUtil } from "../../TableSortUtil";

const MailingTableHeader = (props) => {
  const defaultSortingOrder = "desc";
  const defaultSortingHeader = "homeValuation";
  const firstLoadRef = useRef(true);
  let {
    mailProHeader,
    mailProSortDirection,
    propertyData,
    agentData,
    nextPMAPrintDate,
    lastPMAPrintDate,
    currentArea,
    isLoading,
  } = props.pma;

  //mailPro Headers
  const mailProHerders = [
    {
      align: "left",
      childrens: [
        {
          id: "OwnerFirst",
          disableSorting: false,
          label: (
            <Fragment>
              <p>Homeowner [HO]</p>
              <p>Name</p>
            </Fragment>
          ),
          sortingMethod: hoNameSort,
        },
      ],
    },
    {
      align: "left",
      childrens: [
        {
          id: "globalPS",
          disableSorting: true,
          label: (
            <Fragment>
              <p>Global</p>
              <p>Note</p>
            </Fragment>
          ),
        },
      ],
    },
    {
      align: "left",
      childrens: [
        {
          id: "textPS",
          disableSorting: false,
          label: (
            <Fragment>
              <p>Personal Notes</p>
            </Fragment>
          ),
          sortingMethod: psSort,
        }
      ],
    },
    {
      align: "left",
      childrens: [
        {
          id: "homeValuation",
          disableSorting: false,
          label: (
            <Fragment>
              <p>Home</p>
              <p>Valuations</p>
            </Fragment>
          ),
          sortingMethod: hvSort,
        },
      ],
    },
    {
      align: "left",
      childrens: [
        {
          id: "tags",
          disableSorting: false,
          label: (
            <Fragment>
              <p>Tags</p>
            </Fragment>
          ),
          sortingMethod: tagSort,
        },
      ],
    },
    {
      align: "left",
      childrens: [
        {
          id: "SiteStreet",
          disableSorting: false,
          label: (
            <Fragment>
              {" "}
              <p>Site Address</p>
            </Fragment>
          ),
          sortingMethod: siteAddressSort,
        },
        // {
        //   id: "hoGroups",
        //   disableSorting: false,
        //   label: (
        //     <Fragment>
        //       {" "}
        //       <p>Groups</p>
        //     </Fragment>
        //   ),
        //   sortingMethod: siteAddressSort,
        // },
      ],
    },
    {
      align: "left",
      childrens: [
        {
          id: "propertyType",
          disableSorting: false,
          label: (
            <Fragment>
              {" "}
              <p>
                Property Type /<br /> Property Details
              </p>
            </Fragment>
          ),
          sortingMethod: propertyTypeSort,
        },
      ],
    },
    {
      align: "left",
      childrens: [
        {
          id: "MailAddress",
          disableSorting: false,
          label: (
            <Fragment>
              {" "}
              <p>Mailing</p>
              <p>Address</p>
            </Fragment>
          ),
          sortingMethod: mailingAddressSort,
        },
      ],
    },
    {
      align: "left",
      childrens: [
        {
          id: "lastSaleDate",
          disableSorting: false,
          label: (
            <Fragment>
              <p>Last</p>
              <p>Sale</p>
            </Fragment>
          ),
          sortingMethod: lastSaleSort,
        },
      ],
    },
    {
      align: "left",
      childrens: [
        {
          id: "listingAgentFullName",
          disableSorting: false,
          label: (
            <Fragment>
              {" "}
              <p>Listing Agent</p>
            </Fragment>
          ),
          sortingMethod: listingAgent,
        },
        {
          id: "buyerAgentFullName",
          disableSorting: false,
          label: (
            <Fragment>
              <p>Buyer's Agent</p>
            </Fragment>
          ),
          sortingMethod: buyersAgent,
        },
      ],
    },
  ];


  const determineAsc = (xMailProSortDirection, sorted, xMailProHeader) => {
    props.mailProHeaderSort(sorted, xMailProHeader, xMailProSortDirection);
  };

  const getDirection = (clickedHeader) => {
    if (clickedHeader !== mailProHeader) {
      return defaultSortingOrder;
    } else {
      return mailProSortDirection  === 'asc' ? 'desc' : 'asc';
    }
  };

  //Mailpro table headers
  function hoNameSort(direction) {
    let newSorted = tableSortUtil.hoOwnerFirstSorting(
      propertyData,
      "OwnerFirst",
      direction === "desc" ? false : true
    );
    determineAsc(direction, newSorted, "OwnerFirst");
  }

  function hvSort(direction) {
    const newData =  propertyData.slice()
    let newSorted = tableSortUtil.hoValuationSorting(
      newData,
      "homeValuation",
      direction === "desc" ? false : true
    );
    determineAsc(direction, newSorted, "homeValuation");
  }

  function tagSort(direction) {
    let newSorted = tableSortUtil.filterByTagsandHomeownerName(
      propertyData,
      agentData,
      direction === "desc" ? false : true
    );
    determineAsc(direction, newSorted, "tags");
  }

  function psSort(direction) {
    let newSorted = tableSortUtil.personalPSSorting(
      propertyData,
      "textPS",
      direction === "desc" ? false : true
    );
    determineAsc(direction, newSorted, "textPS");
  }

  function propertyTypeSort(direction) {
    let newSorted = tableSortUtil.propertyTypeSorting(
      propertyData,
      "propertyType",
      direction === "desc" ? false : true
    );
    determineAsc(direction, newSorted, "propertyType");
  }

  function siteAddressSort(direction) {
    let newSorted = tableSortUtil.mailingDetailsSorting(
      propertyData,
      "SiteStreet",
      direction === "desc" ? false : true
    );
    determineAsc(direction, newSorted, "SiteStreet");
  }

  function mailingAddressSort(direction) {
    let newSorted = tableSortUtil.mailingDetailsSorting(
      propertyData,
      "MailAddress",
      direction === "desc" ? false : true
    );
    determineAsc(direction, newSorted, "MailAddress");
  }

  function lastSaleSort(direction) {
    /**
     * Sort by last sale date
     * 1. first give priority to widgets / action items
     * 2. now give priority to date
     * 3. rest on bottom
     */
    //let direction = getDirection('lastSaleDate') === 'asc' ? 'desc' : 'asc';
    let newSorted = tableSortUtil.lastSaleSorting(
      propertyData,
      lastPMAPrintDate,
      nextPMAPrintDate,
      direction === "desc" ? false : true
    );
    determineAsc(direction, newSorted, "lastSaleDate");
  }

  function listingAgent(direction) {
    let newSorted = tableSortUtil.agentNameSorting(
      propertyData,
      "listingAgentFullName",
      direction === "desc" ? false : true
    );
    determineAsc(direction, newSorted, "listingAgentFullName");
  }

  function buyersAgent(direction) {
    let newSorted = tableSortUtil.agentNameSorting(
      propertyData,
      "buyerAgentFullName",
      direction === "desc" ? false : true
    );
    determineAsc(direction, newSorted, "buyerAgentFullName");
  }

  const handleClickEvent = (header) => {
    if (!header.disableSorting && !isLoading) {
      const direction =  getDirection(header.id);
      header.sortingMethod(direction);
      window && window.scrollTo(0, 0);
    } else {
      return () => { };
    }
  };

  return (
    <thead
      className="mailing-table-thead"
      style={isLoading ? { opacity: 0.5 } : {}}
      title={isLoading ? "Please wait. Loading MailPro Data..." : ""}
    >
      <tr>
        <td />
        {mailProHerders.map((header, key) => {
          const childrenCount = header.childrens.length;
          return (
            <td key={key}>
              {header.childrens.map((child, iKey) => {
                return (
                  <Fragment>

                  {iKey % 2 === 1 && <div className="border-between-lables"><div className="border-between-lables-inner"></div></div>}
                  <div
                    key={iKey}
                    className={`th ${!child.disableSorting && "hoverable "}   ${mailProHeader === child.id && "blue-header"}`}
                    onClick={() => handleClickEvent(child)}
                    style={{height: (100/childrenCount - 2) + '%'}}
                  >
                    <div>{child.label}</div>

                    {mailProHeader === child.id ? (
                      <div
                        className={mailProSortDirection}
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                  </Fragment>
                );
              })}
            </td>
          );
        })}
      </tr>
    </thead>
  );
};
function mapStateToProps(state) {
  return {
    pma: state.pma,
    propertyData: state.pma.propertyData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    mailProHeaderSort: (sorted, xMailProHeader, xMailProSortDirection) => {
      dispatch(
        pmaActions.mailProHeaderSort(
          sorted,
          xMailProHeader,
          xMailProSortDirection
        )
      );
    },
    changeLoading: (status) => {
      dispatch(pmaActions.changeLoading(status));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MailingTableHeader);
