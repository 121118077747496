/* Root Reducer */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

//import { authReducer } from './auth.reducer';
import { pmaReducer } from './pma.reducer';
//import { accountReducer } from './account.reducer';
import { homeReducer } from './home.reducer';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  //auth: authReducer,
  pma: pmaReducer,
  home: homeReducer,
  //account: accountReducer
});

export default rootReducer;