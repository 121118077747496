import React, { Fragment, memo, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { TextField, IconButton, Divider, Typography, Tooltip } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import theme from 'theme';
import { CommonAxiosAPIHandler } from 'common/helpers';
import { green, red } from '@material-ui/core/colors';
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    toolbarCenterGridMain: {
        width: 'fit-content',
        border: `1px solid ${theme.palette.border}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        // '& svg': {
        //   margin: theme.spacing(1.5),
        // },
        '& hr': {
            margin: theme.spacing(0, 2),
        },
        padding: theme.spacing(0.5, 2)
    },
    redColor: {
        color: red[500]
    },
    successColor: {
        color: green[500]
    }
});

const NavbarCounters = memo((props) => {
    // The first commit of Material-UI
    //const { state, dispatch } = React.useContext(Store);
    const [isCountRefreshing, setCounterRefreshing] = React.useState(false);
    const [failedInvoiceData, setFailedInvoiceData] = React.useState(0);

    /**classes */
    const classes = useStyles();

    const GET_FAILED_INVOICE = "/admin/getFailedInvoicesCount";

    const GetCounterAPIData = () => {
        return CommonAxiosAPIHandler("GET", [
            `${GET_FAILED_INVOICE}`
        ]);
    };

    /**check picker date */

    const refreshTheCount = () => {
        setCounterRefreshing(true);
        GetCounterAPIData()
            .then(data => {
                setFailedInvoiceData(data)
            })
            .catch(err => {
                console.log('Error in counters: ', err);
            }).finally(() => {
                setCounterRefreshing(false);
            }
            );
    };

    useEffect(() => {
        setTimeout(() => {
            refreshTheCount();
        }, 1000);
    }, [])

    return (
        <Grid container alignItems="center" className={classes.toolbarCenterGridMain} alignContent="center">
            <Link to="/agent/print/manage">
            <div>
                <Typography variant="body1">Failed Invoices</Typography>
                <Typography variant="subtitle2" align="center" color='primary'>
                    <div className={failedInvoiceData > 0 ? classes.redColor : classes.successColor}>
                        {failedInvoiceData}
                    </div>
                </Typography>
                {/* //MidQuarter */}
            </div>
            </Link>
            <Divider orientation="vertical" flexItem />
            {/* <div>
                <Typography variant="body1">PMA Print Count</Typography>
                <Typography variant="subtitle2" align="center" color="primary"></Typography>
            </div> */}
            {/* <Divider orientation="vertical" flexItem /> */}
            <div>
                <Tooltip title="Refresh counters">
                    <IconButton color="primary" size="small" onClick={!isCountRefreshing ? refreshTheCount : ''}>
                        {isCountRefreshing ? <CircularProgress size={22} color="primary" /> : <RefreshIcon color="primary" fontSize="small" />}
                    </IconButton>
                </Tooltip>
            </div>
        </Grid>
    );
});

export default NavbarCounters;