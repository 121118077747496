import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  withStyles,
  CircularProgress,
  Grid,
  Dialog,
  DialogContent,
  Button,
  Typography,
  TextField,
  makeStyles,
  ListItemText,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  CloseIcon,
  Slide,
  MenuItem
} from '@material-ui/core';
import styles from './styles';
import { Portlet, PortletContent, PortletHeader, PortletFooter, PortletLabel } from 'components';
import { GetTicketCompleteData } from 'services/others';
import { GetOneSpecificRequestData } from 'services/others';
import { GetOneSpecificRequestNotesData } from 'services/others';
import { showSuccessNotification } from 'common/helpers';
import { UpdateRequestNotesDataAPI } from 'services/others';
import moment from 'moment';
import { UpdateRequestStatusDataAPI } from 'services/others';
import { Store } from 'Store';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TicketViewComponent = props => {
  const { classes, className, onOpen, onClose, ticketID, statusDataArray, currentStatus, reloadTheTable } = props;

  const rootClassName = classNames(classes.root, className);

  const [open, setOpen] = useState(true);

  const [isLoading, setLoading] = useState(false);

  const [isNotesLoading, setNotesLoading] = useState(false);

  const [message, setMessage] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);

  const [ticketData, setTicketData] = useState({});

  const [notesData, setNotesData] = useState({});

  const [isChanged, setIsChanged] = useState(false);

  const { state, dispatch } = React.useContext(Store);

  /**note related things */
  const [noteData, setNoteData] = useState('');
  const [isAddNoteLoading, setAddNoteLoading] = useState(false);
  const [isAddNoteValid, setAddNoteValid] = useState(false);

  /** status related status */
  const [statusData, setStatusData] = useState('');
  const [isStatusLoading, setStatusLoading] = useState(false);
  const [isStatusValid, setStatusValid] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    if (onOpen) {
      onOpen();
    }
  }

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getTicketData();
      getNotesData();
    }, 200);
  }, [ticketID]);

  useEffect(() => {
    setStatusData(currentStatus);
  }, [currentStatus]);

  const getTicketData = () => {
    /**make loading true */
    setLoading(true);
    try {
      GetOneSpecificRequestData(ticketID)
        .then(data => {
          setTicketData(data);
          setLoading(false);
        })
        .catch(err => {
          setErrorMessage('Unable to process request.');
          setLoading(false);
        });
    } catch (err) {
      setErrorMessage('Unable to process request.');
      setLoading(false);
    }
  };

  const getNotesData = () => {
    /**make loading true */
    setNotesLoading(true);
    try {
      GetOneSpecificRequestNotesData(ticketID)
        .then(data => {
          setNotesData(data);
          setNotesLoading(false);
        })
        .catch(err => {
          setErrorMessage('Unable to process request.');
          setNotesLoading(false);
        });
    } catch (err) {
      setErrorMessage('Unable to process request.');
      setNotesLoading(false);
    }
  };

  const getCurrentDateTime = () => {
    return moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  };

  const convertTimeToUSATime = (inputTime) => {
    return moment(new Date(inputTime)).format('MM-DD-YYYY h:mm A');
  };

  const addNote = () => {
    /**make loading true */
    try {
      setAddNoteLoading(true);
      let userFullName = (state.userData.listgenUserFirstName ? state.userData.listgenUserFirstName : 'ListGen');
      let inputData =
      {
        "ticketID": ticketID,
        "notesAddedBy": userFullName,
        "notesAddedOnDate": getCurrentDateTime(),
        "notesDescription": noteData
      };
      let xNotes = [];
      if (Array.isArray(notesData)) {
        xNotes = notesData;
        xNotes.unshift(inputData);
      } else {
        xNotes.push(inputData);
      }
      UpdateRequestNotesDataAPI(xNotes).then(data => {
        switch (data) {
          case true:
            setMessage("Admin note added.");
            /**reload notes data */
            setNotesData(xNotes);
            /* empty the note form */
            setNoteData('');
            /**set is changed to true */
            setIsChanged(true);
            break;
          case false:
            setErrorMessage("Some unexpected error occured.");
            break;
          default:
            setErrorMessage("Something went wrong. Please try after some time.");
            break;
        }
        /**stop loading also */
        setAddNoteLoading(false);

      }).catch(err => {
        setErrorMessage("Unable to process request.");
        setAddNoteLoading(false);
      });
    } catch (err) {
      setErrorMessage("Unable to process request.");
      setAddNoteLoading(false);
    }
  };

  const updateStatus = () => {
    /**make loading true */
    try {
      setStatusLoading(true);
      UpdateRequestStatusDataAPI(ticketID, statusData).then(data => {
        switch (data) {
          case true:
            setMessage("Status updated.");
            setIsChanged(true);
            break;
          case false:
            setErrorMessage("Some unexpected error occured.");
            break;
          default:
            setErrorMessage("Something went wrong. Please try after some time.");
            break;
        }
        /**stop loading also */
        setStatusLoading(false);

      }).catch(err => {
        setErrorMessage("Unable to process request.");
        setStatusLoading(false);
      });
    } catch (err) {
      setErrorMessage("Unable to process request.");
      setStatusLoading(false);
    }
  };

  const handleFieldChange = (event) => {
    if (event.target.name === 'note') {
      setNoteData(event.target.value);
    } else if (event.target.name === 'status') {
      setStatusData(event.target.value);
    }
  };

  useEffect(() => {
    validateForm();
  }, [noteData, statusData]);

  const validateForm = () => {
    /**check for note data */
    if (noteData !== '') {
      setAddNoteValid(true);
    } else {
      setAddNoteValid(false);
    }
    /**check for status */
    if (statusData !== '') {
      setStatusValid(true);
    } else {
      setStatusValid(false);
    }
  };

  /**use effect to set message null after 2 seconds */
  useEffect(() => {
    setTimeout(() => {
      setMessage(null);
      setErrorMessage(null);
    }, 2000);
  }, [message, errorMessage]);

  /**apply check on is change */
  useEffect(() => {
    if (isChanged) {
      reloadTheTable(true);
      /**set is change back to false */
      setIsChanged(false);
    }
  }, [isChanged]);


  return (
    <div className={rootClassName}>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar} color="default">
          <Toolbar>
            {/* <IconButton edge="start" color="default" onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton> */}
            <Typography variant="h4" className={classes.title}>
              View Ticket
            </Typography>
            {/* <Button color="inherit" className={classes.colorWhite} onClick={handleClose}>
              View Agent Profile
            </Button> */}
            <Button color="inherit" className={classes.colorWhite} onClick={handleClose}>
              Close Ticket
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {isLoading ?
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
            : ''}
          {message ?
            <Typography variant="subtitle2" className={classes.messageBox}>
              <div className={classes.fieldSuccess}>
                {message}
              </div>
            </Typography>
            : ''}
          {errorMessage ?
            <Typography variant="subtitle2" className={classes.messageBox}>
              <div className={classes.fieldError}>
                {errorMessage}
              </div>
            </Typography>
            : ''}
          {/* {ticketData} */}
          {isLoading ? '' :
            <div className={classes.agentDetailsOuter}>
              <Grid container className={classNames(classes.grid, classes.mainGrid)} spacing={3}>
                <Grid item lg={8} md={8} sm={12} xs={12}>
                  <div className={classes.agentDetails}>
                    <Portlet>
                      <PortletHeader>
                        <PortletLabel
                          title="Ticket Summary"
                        />
                      </PortletHeader>
                      <PortletContent>
                        <Grid container className={classes.grid}>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Status"
                                  secondary={ticketData.status}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Date / Time Received"
                                  secondary={ticketData.timeReceived ? convertTimeToUSATime(ticketData.timeReceived) : '-'}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Date / Time Responded"
                                  secondary={ticketData.timeResponded ? convertTimeToUSATime(ticketData.timeResponded) : '-'}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                        </Grid>
                        <Grid container className={classes.grid}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Divider />
                          </Grid>
                        </Grid>
                        <Grid container className={classes.grid}>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Full Name"
                                  secondary={ticketData.fullName}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Email"
                                  secondary={ticketData.email}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Phone"
                                  secondary={ticketData.phone}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                        </Grid>
                        <Grid container className={classes.grid}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Divider />
                          </Grid>
                        </Grid>
                        <Grid container className={classes.grid}>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="City Name"
                                  secondary={ticketData.cityName ? ticketData.cityName : '-'}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Area Name"
                                  secondary={ticketData.areaName ? ticketData.areaName : '-'}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={4}></Grid>
                        </Grid>
                        <Grid container className={classes.grid}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Divider />
                          </Grid>
                        </Grid>
                        <Grid container className={classes.grid}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Subject"
                                  secondary={ticketData.subject}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Message"
                                  secondary={ticketData.message}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                        </Grid>
                      </PortletContent>
                    </Portlet>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <div className={classes.extraDetails}>
                    <Grid container className={classes.grid}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Portlet>
                          <PortletHeader>
                            <PortletLabel
                              title="Change Status"
                            />
                          </PortletHeader>
                          <PortletContent>
                            <Grid container className={classes.grid}>
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <TextField
                                  id="outlined-select-status"
                                  select
                                  margin="dense"
                                  label="Select a Status"
                                  className={classes.textField}
                                  value={statusData}
                                  name="status"
                                  onChange={event => handleFieldChange(event)}
                                  SelectProps={{
                                    MenuProps: {
                                      className: classes.menu,
                                    },
                                  }}
                                  variant="outlined"
                                >
                                  {statusDataArray.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                            </Grid>
                          </PortletContent>
                          <PortletFooter className={classes.portletFooter}>
                            {isStatusLoading ? (
                              <CircularProgress className={classes.progress} />
                            ) : (
                                <Button
                                  color="primary"
                                  variant="contained"
                                  disabled={!isStatusValid}
                                  onClick={event => updateStatus()}
                                >
                                  Update Status
          </Button>
                              )}
                          </PortletFooter>
                        </Portlet>
                      </Grid>
                    </Grid>
                    <Grid container className={classNames(classes.grid, classes.marginTop3Unit)}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Portlet>
                          <PortletHeader>
                            <PortletLabel
                              title="Admin Notes"
                            />
                          </PortletHeader>
                          <PortletContent className={classes.notesFixHeight}>
                            {isNotesLoading ?
                              <div className={classes.progressWrapper}>
                                <CircularProgress />
                              </div>
                              :
                              <Grid container className={classes.grid}>
                                {Array.isArray(notesData) ?
                                  <div className={classes.adminNotesContainer}>
                                    {notesData.map((item, key) => (
                                      <Grid item lg={12} md={12} sm={12} xs={12} key={'notes-grid-' + key}>
                                        <div className={classes.noteBlock}>
                                          <div className={classes.noteBlockText}>
                                            <Typography variant="subtitle1">
                                              {item.notesDescription}
                                            </Typography>
                                          </div>
                                          <div className={classes.noteBlockSubtext}>
                                            <Typography variant="body2" className={classes.greyColor}>
                                              - {item.notesAddedBy ? 'Added by ' + item.notesAddedBy : ''}
                                            </Typography>
                                            <Typography variant="body2" className={classes.greyColor}>
                                              - {item.notesAddedOnDate ? 'Added on ' + convertTimeToUSATime(item.notesAddedOnDate) : ''}
                                            </Typography>
                                          </div>
                                          <Divider />
                                        </div>
                                      </Grid>
                                    ))}
                                  </div>
                                  :
                                  <div className={classes.noNoteFound}>
                                    <Typography variant="subtitle2">No previous admin notes found.</Typography>
                                  </div>
                                }
                              </Grid>
                            }
                            {/* Add note section */}
                            <Grid container className={classes.grid}>
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <TextField
                                  id="outlined-multiline-flexible"
                                  label="Add a note here..."
                                  multiline
                                  rowsMax="4"
                                  value={noteData}
                                  name="note"
                                  onChange={event => handleFieldChange(event)}
                                  className={classes.textField}
                                  margin="normal"
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                          </PortletContent>
                          <PortletFooter className={classes.portletFooter}>
                            {isAddNoteLoading ? (
                              <CircularProgress className={classes.progress} />
                            ) : (
                                <Button
                                  color="primary"
                                  variant="contained"
                                  disabled={!isAddNoteValid}
                                  onClick={event => addNote()}
                                >
                                  Add Note
          </Button>
                              )}
                          </PortletFooter>
                        </Portlet>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          }
        </DialogContent>
      </Dialog>
    </div>
  );
};

TicketViewComponent.propTypes = {
  ticketID: PropTypes.string.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
};

TicketViewComponent.defaultProps = {
  onOpen: () => { },
  onClose: () => { }
};

export default withStyles(styles)(TicketViewComponent);

