import React, { useEffect, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import classNames from "classnames";
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { showErrorNotification, showSuccessNotification } from "common/helpers";
import { UpdateTaglineService } from "views/AgentDetails/AgentDetailsService";
import { withSnackbar } from "notistack";
import compose from "recompose/compose";
import Base from "common/constants";
import { Alert } from "@material-ui/lab";

const AreaTagline = React.memo((props) => {
  let {
    classes,
    navbarInfo,
    listgenUserID,
    parentHandleFieldChange,
    isValid,
    forceDisableEditing,
    isLoading: isParentSubmissionInProgress,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [localNavbarInfo, setNavbarInfo] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const perTaglineLimit = 45;

  useEffect(() => {
    setNavbarInfo(handleLineTaglines(navbarInfo, true));
  }, [navbarInfo]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleLineTaglines = (navbarInfo = [], splitTagline = true) => {
    if (!navbarInfo) return [];
    const navbarInfoCopy = [...navbarInfo];
    if (splitTagline) {
      navbarInfoCopy.forEach((area) => {
        if (area.tagLine) {
          const tagLines = area.tagLine.split(Base.LG_DEFAULT_TEXT_SEPARATOR);
          area.tagLine1 = tagLines[0];
          area.tagLine2 = tagLines[1];
        }
      });
    } else {
      navbarInfoCopy.forEach((area) => {
        if (area.tagLine1 || area.tagLine2) {
          area.tagLine = `${area.tagLine1 || ""}${
            area.tagLine2 ? Base.LG_DEFAULT_TEXT_SEPARATOR + area.tagLine2 : ""
          }`;
        }
      });
    }
    return navbarInfoCopy;
  };

  const updateData = (key, field, tagLine) => {
    const tempInfo = [...localNavbarInfo];
    tempInfo[key][field] = tagLine;

    if (tagLine.length > perTaglineLimit) {
      tempInfo[key]["isTaglineError"] = true;
    } else {
      tempInfo[key]["isTaglineError"] = false;
    }
    setNavbarInfo(tempInfo);
  };

  const duplicateTaglineFromLastArea = (currentRow) => {
    if (currentRow === 0) return;
    const tempInfo = [...localNavbarInfo];
    tempInfo[currentRow]["tagLine1"] = tempInfo[currentRow - 1]["tagLine1"];
    tempInfo[currentRow]["tagLine2"] = tempInfo[currentRow - 1]["tagLine2"];
    setNavbarInfo(tempInfo);
  };

  const submitData = () => {
    setIsLoading(true);
    const oneLineTaglineNavbarInfo = handleLineTaglines(localNavbarInfo, false);
    const updateTaglineFormat = getUpdateTaglineFormat(
      oneLineTaglineNavbarInfo
    );
    UpdateTaglineService(updateTaglineFormat)
      .then((response) => {
        if (response) {
          showSuccessNotification(props, "Taglines updated successfully");
          handleParentData();
          handleClose();
        }
      })
      .catch((error) => {
        showErrorNotification(props, "Error updating taglines");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleParentData = () => {
    //dummy event we need to create so we can call parent function in a fashion other components are calling
    const dummyEvent = {
      target: {
        type: "input",
        value: localNavbarInfo,
      },
    };
    const updatedNavbarInfo = handleLineTaglines(localNavbarInfo, false);
    parentHandleFieldChange("narbarInfo", updatedNavbarInfo, dummyEvent);
  };

  const getUpdateTaglineFormat = (localNavbarInfo = []) => {
    return {
      listgenUserID: listgenUserID,
      //return mlsAreaID, tagLine object
      areaTagLinesForDTOList: localNavbarInfo.map((area) => {
        return {
          mlsAreaID: area.mlsAreaID,
          tagLine: area.tagLine,
        };
      }),
    };
  };

  const isError = localNavbarInfo.find((area) => area.isTaglineError);
  const isEditMode =
    navbarInfo && navbarInfo.find((area) => area.tagLine) ? true : false;

  const isLineError = (lineTagline = "") => {
    return lineTagline && lineTagline.length > perTaglineLimit;
  };

  return (
    <React.Fragment>
      <div>
        <Button
          onClick={() => setOpen(true)}
          variant="contained"
          color="primary"
          className={classNames(
            classes.uploadButton,
            isEditMode ? "" : classes.flashButton
          )}
        >
          {isEditMode ? "View / Edit" : "Add"} Tagline
        </Button>
      </div>
      <Dialog
        fullScreen={false}
        fullWidth={true}
        open={open}
        onClose={(event) => handleClose()}
        aria-labelledby="max-width-dialog-title"
        classes={{
          paper: classes.taglineDialogPaper,
        }}
      >
        <div id="scroll-dialog-title" className={classes.dialogTitle}>
          <Typography className={classes.title} variant="subtitle1">
            Area Taglines
          </Typography>
          <Typography variant="subtitle2" className={classes.subtitle}>
            YOUR TAGLINE GOES UNDER YOUR PHOTO ON THE BACKPAGE
          </Typography>
          <Typography
            variant="subtitle2"
            className={classNames(classes.subtitle, classes.subtitleSpace)}
          >
            YOU CAN HAVE A DIFFERENT TAGLINE FOR EACH AREA
          </Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={(event) => handleClose()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Divider variant="middle" />
        <DialogContent className={classes.dialogContent}>
          {forceDisableEditing && (
            <Alert
              severity="info"
              variant="filled"
              style={{ margin: "8px", fontSize: "14px" }}
            >
              PMA Printing is in progress. You can update taglines after that.
            </Alert>
          )}
          {localNavbarInfo.map((area, key) => {
            return (
              <Grid
                container
                spacing={0}
                key={"area_tagline" + area.mlsAreaID}
                className={classes.areaGrid}
              >
                <Grid item lg={4}>
                  <div className={classes.areaName}>{area.mlsNeighborhood}</div>
                </Grid>
                <Grid item lg={8}>
                  <div className={classes.textfieldParentContainer}>
                    <div style={{ width: "80%" }}>
                      <div className={classes.textfieldContainer}>
                        <TextField
                          className={classNames(classes.textField)}
                          label={`Enter Tagline for ${area.mlsNeighborhood} Line 1`}
                          margin="dense"
                          variant="outlined"
                          name="tagLine1"
                          value={area.tagLine1}
                          error={isLineError(area.tagLine1)}
                          onChange={(event) =>
                            updateData(key, "tagLine1", event.target.value)
                          }
                          fullWidth
                          helperText={
                            isLineError(area.tagLine1) &&
                            `You can add up to ${perTaglineLimit} characters per line for an area Tagline.`
                          }
                          InputLabelProps={{ shrink: area.tagLine1 }}
                        />
                        <div className={classes.characterCountContainer}>
                          <Typography
                            variant="caption"
                            component={"span"}
                            align="left"
                          >
                            Character Count:{" "}
                            {(area.tagLine1 && area.tagLine1.length) || 0}
                          </Typography>
                          <Typography
                            variant="caption"
                            component={"span"}
                            align="right"
                          >
                            Max Characters: {perTaglineLimit}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.textfieldContainer}>
                        <TextField
                          className={classNames(classes.textField)}
                          label={`Enter Tagline for ${area.mlsNeighborhood} Line 2`}
                          margin="dense"
                          variant="outlined"
                          name="tagLine2"
                          value={area.tagLine2}
                          error={isLineError(area.tagLine2)}
                          onChange={(event) =>
                            updateData(key, "tagLine2", event.target.value)
                          }
                          fullWidth
                          helperText={
                            isLineError(area.tagLine2) &&
                            `You can add up to ${perTaglineLimit} characters for an area Tagline.`
                          }
                          InputLabelProps={{ shrink: area.tagLine2 }}
                        />
                        <div className={classes.characterCountContainer}>
                          <Typography
                            variant="caption"
                            component={"span"}
                            align="left"
                          >
                            Character Count:{" "}
                            {(area.tagLine2 && area.tagLine2.length) || 0}
                          </Typography>
                          <Typography
                            variant="caption"
                            component={"span"}
                            align="right"
                          >
                            Max Characters: {perTaglineLimit}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div>
                      {key > 0 && (
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => duplicateTaglineFromLastArea(key)}
                        >
                          Copy from Above
                        </Button>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item lg={12}>
                  <Divider className={classes.dividerTeamProfile} />
                </Grid>
              </Grid>
            );
          })}
        </DialogContent>

        <DialogActions className={classes.portletFooter}>
          <div className={classes.footerButtons}>
            <div className={classes.footerButtonsLeft}>
              <Button variant="outlined" onClick={(event) => handleClose()}>
                Cancel
              </Button>
            </div>
            <div className={classes.footerButtonsCenter}>
              {isLoading ? (
                <div className={classes.progressWrapper}>
                  <CircularProgress />
                </div>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(event) => submitData()}
                  disabled={
                    isError ||
                    !isValid ||
                    forceDisableEditing ||
                    isParentSubmissionInProgress
                  }
                >
                  Submit
                </Button>
              )}
            </div>
            <div className={classes.footerButtonsRight}></div>
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default compose(withStyles(styles), withSnackbar)(AreaTagline);
