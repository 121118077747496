import React from "react";
import PmaEditor from "./PMAEditor";
import axios from "common/interceptors";
import { propertyUtil } from "views/Pma/PropertyUtil";

class PrintYearEndPMA extends React.Component {
  state = {
    singleHOData: null,
    agentData: null,
    mlsAreas: null,
    marketActivity: null,
    MQCityDetails: null,
    currentArea: null,
    version: "ye2",
    coverPageDetails: null,
    qArea: null,
    qCity: null,
    aprilData: null,
  };

  async componentDidMount() {
    let mailProId = this.props.match.params.mailproid;
    let listgenUserID = this.props.match.params.listgenUserID;
    let brokerage = this.props.match.params.brokerage;
    const { version } = this.state;
    let url = window.location.href;
    if (url.includes("test-print")) {
      listgenUserID = url[url.length - 2];
    } else if (version === "ye2") {
      let date = new Date();
      let thisYear = date.getFullYear();
      const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
      const today = `${date.getDate()}`.padStart(2, 0);
      try {
        let mailPrint = await axios
          .get(
            `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
            {
              headers: {
                "content-type": "application/json",
              },
              data: {},
            }
          )
          .then((res) => {
            const singleHOData = res.data.mailerProDTO;
            const agentData = res.data.listgenUserDTO;
            this.setState({
              singleHOData,
              agentData,
            });
          })
          .catch((err) => console.log("error retrieving idDetails" + err));
      } catch (error) {}
      let navBar = await axios
        .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
          headers: {
            "content-type": "application/json",
          },
          data: {},
        })
        .then((res) => {
          this.setState({
            mlsAreas: res.data,
          });
        })
        .catch((err) => {});
      const thisArea = this.state.singleHOData.areaID;
      let currentArea = this.state.mlsAreas.filter(
        (area) => area.mlsAreaID === thisArea
      )[0];
      const { mlsCityID, mlsAreaID } = currentArea;
      const { singleHOData } = this.state;
      const { formatDemoBrokerage, formatDemoEmail } = propertyUtil;
      if (singleHOData) {
        const { subdivisionName, mailerProID } = singleHOData;
        let updatedID;
        if (listgenUserID === "100037" && mlsAreaID === "160SalesDemo") {
          updatedID = "160";
        } else if (listgenUserID === "100037" && mlsAreaID === "143SalesDemo") {
          updatedID = "143";
        } else if (listgenUserID === "100037" && mlsAreaID === "126SalesDemo") {
          updatedID = "126";
        } else {
          updatedID = mlsAreaID;
        }

        let getPmaDetails = await axios
          .get(
            `/agent/getPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
              subdivisionName ? subdivisionName : "-"
            }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,

            {
              headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
              data: {},
            }
          )
          .then((res) => {
            console.log("inside speedometer", res.data);
            const {
              mailerPrintInfoDTO,
              lgTestmonialsDTO,
              missionStatementDTO,
            } = res.data;
            const singleHOData = mailerPrintInfoDTO.mailerProDTO;
            let agentData = mailerPrintInfoDTO.listgenUserDTO;

            let isDemo = false;
            let demoValues = {
              agentDesignation: "Broker Associate",
              agentLicenseNumber: "12345678",
              billingAddressLine1: "7979 Soquel Drive test",
              billingAddressLine2: null,
              billingCity: "Aptos",
              billingFirstName: "Sarah",
              billingLastName: "Sullivan",
              billingState: "CA",
              billingZipCode: "95003",
              brokerLicenseNumber: "01317331",
              brokerageLogoURL: null,
              brokerageName: "COMPASS",
              displayEmailAddress: "sarah@compass.com",
              emailAddress: "sarah@compass.com",

              isMemberOfTeam: false,
              listgenRoleID: 2,
              listgenUserFirstName: "Sarah",
              listgenUserID: "101",
              listgenUserLastName: "Sullivan",
              listgenUserPassword: null,
              logoURL:
                "https://uploadagentdetails.s3.amazonaws.com/1626808274547-SarahSullivan-logo-lightBG.png",
              logoURL2:
                "https://uploadagentdetails.s3.amazonaws.com/1566296755270-test-file-for-upload.jpg",
              mailProStatus: null,
              mailingAddressLine1: "7979 Soquel Drive test",
              mailingAddressLine2: null,
              mailingCity: "Aptos",
              mailingMonthsInvoicesCount: null,
              mailingState: "CA",
              mailingZipCode: "95003",
              missionStatementsCount: null,
              missionStatementsVerifiedCount: null,
              mobilePhoneNo: "3219876543",
              officeAddressLine1: "7979 Soquel Drive",
              officeAddressLine2: null,
              officeCity: "Aptos",
              officePhoneNo: null,
              officeState: "CA",
              officeZipcode: "95003",
              photoURL:
                "https://uploadagentdetails.s3.amazonaws.com/1699300827098-1674530346469-sarahsullivan_nosuch_032821-1.jpg",
              signatureURL:
                "https://uploadagentdetails.s3.amazonaws.com/1566296763311-test-file-for-upload.jpg",
              websiteURL: "www.SarahSullivanHomes.com",
              demoCoverDate: "July 20",
            };
            console.log("print year end");
            agentData = Object.assign(agentData, demoValues);
            res.data.pmaCoverPhotoDTO = Object.assign(
              res.data.pmaCoverPhotoDTO,
              {
                coverPhoto:
                  "https://d2onbxnrte2nim.cloudfront.net/Summerkitchen_July2024.jpg",
                coverPhotoCredit1:
                  "Cover Credit: Interior Design by Hayley Bridges Design",
              }
            );
            // if (
            //   listgenUserID === "103" ||
            //   listgenUserID === "105" ||
            //   listgenUserID === "101"
            // ) {
            agentData.brokerageName = formatDemoBrokerage(brokerage);
            agentData.emailAddress = formatDemoEmail(brokerage);

            isDemo = true;
            // currentArea.mlsNeighborhood = "Pruneyard";
            // }
            this.setState({
              singleHOData,
              agentData,
              currentArea,
              coverPageDetails: res.data.pmaCoverPhotoDTO,
              quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
              quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
              testimonials: lgTestmonialsDTO,
              aprilData: {
                pmaQuarterlyAreaDTO: res.data.pmaQuarterlyAreaDTO,
                pmaQuarterlyCityDTO: res.data.pmaQuarterlyCityDTO,
              },
              bubbleBoxes: res.data.marketValueInfoList,
              testimonials: res.data.lgTestmonialsDTO,
              missionStatement: missionStatementDTO,
            });
          })
          .catch((err) =>
            console.log("error retrieving table bubble data" + err)
          );
      }
      let replacedArea;
      if (mlsAreaID === "752") {
        replacedArea = "5389";
      } else if (mlsAreaID === "1522155") {
        replacedArea = "5370";
      } else {
        replacedArea = mlsCityID;
      }
    }
  }
  render() {
    const { propertyData } = this.props;
    const {
      singleHOData,
      agentData,
      mlsAreas,
      marketActivity,
      MQCityDetails,
      currentArea,
      version,
      quarterlyCityTable,
      quarterlyAreaTable,
      coverPageDetails,
      qArea,
      qCity,
      aprilData,
    } = this.state;
    if (
      currentArea &&
      singleHOData &&
      agentData &&
      coverPageDetails &&
      aprilData &&
      quarterlyAreaTable
    ) {
      return (
        <div className="pma-creator">
          <main>
            <section>
              <PmaEditor
                propertyData={propertyData}
                printVersion={true}
                version={version}
                singleHOData={singleHOData}
                agentData={agentData}
                currentArea={currentArea}
                marketActivity={marketActivity}
                MQCityDetails={MQCityDetails}
                mlsAreas={mlsAreas}
                quarterlyAreaTable={quarterlyAreaTable}
                quarterlyCityTable={quarterlyCityTable}
                coverPageDetails={coverPageDetails}
                qArea={qArea}
                qCity={qCity}
                aprilData={aprilData}
                dontShowPma={false}
              />
            </section>
          </main>
        </div>
      );
    } else {
      return <div>Loading PMA...</div>;
    }
  }
}
export default PrintYearEndPMA;
