import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} value={props.type === 'normal' ? (Math.ceil((props.progress/props.totalProgress)*100)) : props.progress} />
      </Box>
      <Box style={{ width: '15%' }}>
        <Typography variant="subtitle2" color="primary">{`${props.type === 'normal' ? props.progress : `${Math.ceil(
          props.progress,
        )}%`} ${props.message ? props.message : ``}`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  progress: PropTypes.number.isRequired,
  totalProgress: PropTypes.number.isRequired,
  message: PropTypes.any,
  type: PropTypes.oneOf(['normal', 'percentage']),
};

LinearProgressWithLabel.defaultProps = {
  progress: 0,
  totalProgress: 1,
  type: 'percentage',
  message: ''
};

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export default function LinearWithValueLabel(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel {...props} />
    </div>
  );
}
