import React, { Component } from "react";

class BrettNote extends Component {
  render() {
    return (
      <div className="brett-note">
        <div className="brett-note-inner-container">
          <div className="brett-note-title">A NOTE FROM BRETT</div>
          <div className="brett-note-text">
            Happy Holidays from my family to yours. My wife Hannah and I are
            very blessed to be able to raise our daughter, Navy, in this
            wonderful community. We are both local business owners who are very
            dedicated and passionate about this neighborhood, "Noleta," we call
            home. I have lived and worked in the "Noleta" area for the past 17
            years, and when it came time for us to buy a home, this was the only
            area we considered. We look forward to many years of helping our
            neighbors through our businesses and giving back to this community.
          </div>
          <div className="brett-note-text-2">
            Thank you for your business and support over the years!
          </div>
          <div className="brett-note-signoff">
            Happy Holidays, Brett Buschbom
          </div>
        </div>
        <div className="brett-note-img-container">
          <img
            src="https://pma-coverphotos.s3.amazonaws.com/non-covers/cropped-brett-note.jpg"
            className="brett-note-img"
            alt="Brett Note"
          />
        </div>
      </div>
    );
  }
}

export default BrettNote;
