import React, { Component } from "react";

class DashboardHeader extends Component {
  render() {
    const { dashBoardMonths } = this.props;
    const { useCity, currentArea, agentData } = this.props;
    let { listgenUserID, isMemberOfTeam } = agentData;
    const { mlsAreaID } = currentArea;
    if (listgenUserID === "104835" || listgenUserID === "104480") {
      isMemberOfTeam = true;
    }
    const getNameToUse = () => {
      const { mlsNeighborhood, mlsCityName } = currentArea;

      if (mlsAreaID === "SCL-16J") {
        return "your neighborhood";
      } else if (mlsAreaID === "SBA-40H") {
        return "the Santa Ynez Valley";
      } else if (useCity) {
        if (mlsCityName.length > 30) {
          return "your city";
        } else {
          return mlsCityName;
        }
      } else {
        if (mlsAreaID === "SCL-236A") {
          return "Southgate - Evergreen Park";
        } else if (mlsNeighborhood.length > 30) {
          return "your neighborhood";
        } else {
          return mlsNeighborhood;
        }
      }
    };
    let useRanches =
      mlsAreaID === "SBA-40H" ||
      mlsAreaID === "SBA-45I" ||
      mlsAreaID === "SBA-45H";
    let KerryMormannRanch = mlsAreaID === "SBA-45I" || mlsAreaID === "SBA-45H";

    return (
      <div className="dashboard-header">
        <div className="dashboard-header-content">
          <div className="dashboard-title">
            <p className="dashboard-title-dark">
              What Price Range Does Your Property Fit In?
            </p>
          </div>
          <div className="dashboard-subtitle-section">
            <div className="dashboard-info">
              {KerryMormannRanch ? (
                <p>
                  Below are three price ranges of{" "}
                  <span className="bolded-dashboard-header-area-name">
                    Coastal Ranches
                  </span>{" "}
                  that sold over the last{" "}
                  {Number(dashBoardMonths) > 0 ? dashBoardMonths : 1} months.
                  This is a simple yet effective way to see how your property
                  might fit into one of these three price categories.{" "}
                  <span className="bolded-dashboard-header-area-name">
                    Use this as a starting point and contact me to provide a
                    detailed home valuation.{" "}
                  </span>
                </p>
              ) : (
                <p>
                  Below are three price ranges of{" "}
                  {useRanches
                    ? "ranches"
                    : mlsAreaID === "SBA-20FCondo"
                    ? "condos"
                    : mlsAreaID === "SBA-20FInv"
                    ? "properties"
                    : "homes"}{" "}
                  that sold in{" "}
                  <span className="bolded-dashboard-header-area-name">
                    {getNameToUse()}
                  </span>{" "}
                  over the last{" "}
                  {Number(dashBoardMonths) > 0 ? dashBoardMonths : 1} months.
                  This is a simple yet effective way to see how your property
                  might fit into one of these three price categories.{" "}
                  <span className="bolded-dashboard-header-area-name">
                    Use this as a starting point and contact{" "}
                    {isMemberOfTeam ? "us" : "me"} to provide a detailed home
                    valuation.{" "}
                  </span>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardHeader;
