import React from "react";
import PmaEditor from "./PMAEditor";
import axios from "common/interceptors";

class PrintMarketTrendsPMA extends React.Component {
  state = {
    singleHOData: null,
    agentData: null,
    mlsAreas: null,
    marketActivity: null,
    MQCityDetails: null,
    currentArea: null,
    version: "qv3",
    coverPageDetails: null,
    qArea: null,
    qCity: null,
    aprilData: null,
  };

  async componentDidMount() {
    console.log("inside 2");
    let mailProId = this.props.match.params.mailproid;
    let listgenUserID = this.props.match.params.listgenUserID;
    let brokerage = this.props.match.params.brokerage;
    const { version } = this.state;
    let url = window.location.href;
    if (url.includes("test-print")) {
      listgenUserID = url[url.length - 2];
    }
    let date = new Date();
    let thisYear = date.getFullYear();
    const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
    const today = `${date.getDate()}`.padStart(2, 0);
    try {
      let mailPrint = await axios
        .get(
          `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
          {
            headers: {
              "content-type": "application/json",
            },
            data: {},
          }
        )
        .then((res) => {
          const singleHOData = res.data.mailerProDTO;
          const agentData = res.data.listgenUserDTO;
          this.setState({
            singleHOData,
            agentData,
          });
        })
        .catch((err) => console.log("error retrieving idDetails" + err));
    } catch (error) {}
    let navBar = await axios
      .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
        headers: {
          "content-type": "application/json",
        },
        data: {},
      })
      .then((res) => {
        this.setState({
          mlsAreas: res.data,
        });
      })
      .catch((err) => {});
    const thisArea = this.state.singleHOData.areaID;

    const { singleHOData } = this.state;
    let currentArea =
      this.state.mlsAreas.filter((area) => area.mlsAreaID === thisArea)[0] ||
      this.state.mlsAreas[0];
    const { mlsCityID, mlsAreaID } = currentArea;
    // if (mlsAreaID === "DEMO-SCL-17F") {
    //   currentArea.mlsNeighborhood = "Cielito";
    // }
    const { subdivisionName } = singleHOData;

    let getPmaDetails = await axios
      .get(
        `/agent/getPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
          subdivisionName ? subdivisionName : "-"
        }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,

        {
          headers: {
            "content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
        }
      )
      .then((res) => {
        console.log("inside market trends res.data", res.data);
        const {
          mailerPrintInfoDTO,
          lgTestmonialsDTO,
          missionStatementDTO,
          propertyPhotoDetailsDTO,
        } = res.data;
        const singleHOData = mailerPrintInfoDTO.mailerProDTO;
        const agentData = mailerPrintInfoDTO.listgenUserDTO;
        this.setState({
          singleHOData,
          agentData,
          currentArea,
          coverPageDetails: res.data.pmaCoverPhotoDTO,
          quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
          quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
          testimonials: lgTestmonialsDTO,
          propertyPhotoDetailsDTO: propertyPhotoDetailsDTO,
          aprilData: {
            pmaQuarterlyAreaDTO: res.data.pmaQuarterlyAreaDTO,
            pmaQuarterlyCityDTO: res.data.pmaQuarterlyCityDTO,
          },
          bubbleBoxes: res.data.marketValueInfoList,
          testimonials: res.data.lgTestmonialsDTO,
          missionStatement: missionStatementDTO,
        });
      })
      .catch((err) => console.log("error retrieving table bubble data" + err));
  }
  render() {
    const { propertyData } = this.props;
    const {
      singleHOData,
      agentData,
      mlsAreas,
      marketActivity,
      MQCityDetails,
      currentArea,
      version,
      quarterlyCityTable,
      quarterlyAreaTable,
      coverPageDetails,
      qArea,
      qCity,
      aprilData,
      bubbleBoxes,
    } = this.state;
    if (
      currentArea &&
      singleHOData &&
      agentData &&
      coverPageDetails &&
      quarterlyAreaTable &&
      bubbleBoxes
    ) {
      return (
        <div className="pma-creator">
          <main>
            <section>
              <PmaEditor
                propertyData={propertyData}
                printVersion={true}
                version={version}
                singleHOData={singleHOData}
                agentData={agentData}
                currentArea={currentArea}
                marketActivity={marketActivity}
                MQCityDetails={MQCityDetails}
                mlsAreas={mlsAreas}
                quarterlyAreaTable={quarterlyAreaTable}
                quarterlyCityTable={quarterlyCityTable}
                coverPageDetails={coverPageDetails}
                qArea={qArea}
                qCity={qCity}
                aprilData={aprilData}
                dontShowPma={false}
                bubbleBoxes={bubbleBoxes}
              />
            </section>
          </main>
        </div>
      );
    } else {
      return <div>Loading PMA...</div>;
    }
  }
}
export default PrintMarketTrendsPMA;
