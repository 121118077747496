import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  withStyles,
  Dialog,
  DialogContent,
  Button,
  Typography,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import styles from "../styles";
import ClearIcon from "@material-ui/icons/Clear";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MediaViewComponent = (props) => {
  console.log("MediaViewComponent", props);
  const { classes, className, onOpen, onClose, mediaTitle, mediaURL } = props;

  const noImageURL = "/images/no-image.png";

  const rootClassName = classNames(classes.root, className);

  const [open, setOpen] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
    if (onOpen) {
      onOpen();
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className={rootClassName}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        //fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id="media-view-dialog-title">
          <div
            className={classNames(
              classes.width100,
              classes.displayFlex,
              classes.justifyContentSbtw,
              classes.flexDirectionRow,
              classes.alignItemsCenter
            )}
          >
            <div>{mediaTitle}</div>
            <div></div>
            <div
              onClick={handleClose}
              className={classNames(
                classes.displayFlex,
                classes.flexDirectionRow,
                classes.alignItemsStart,
                classes.justifyContentEnd,
                classes.closeModalBtn
              )}
            >
              <Button
                variant="contained"
                size="small"
                className={classNames(
                  classes.whiteColor,
                  classes.textAlignC,
                  classes.redBg900,
                  classes.closeredButton
                )}
              >
                <ClearIcon className={classes.whiteColor} />
              </Button>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div
            className={classNames(
              classes.displayFlex,
              classes.justifyContentCenter,
              classes.mediaImageHolder
            )}
          >
            <img src={mediaURL} className={classes.mediaImage} />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

MediaViewComponent.propTypes = {
  mediaURL: PropTypes.string.isRequired,
  mediaTitle: PropTypes.string.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

MediaViewComponent.defaultProps = {
  onOpen: () => {},
  onClose: () => {},
};

export default withStyles(styles)(MediaViewComponent);
