import React, { Component } from "react";
import DashboardHeader from "./DashboardHeader";
import DashboardBody from "./DashboardBody";
import DashboardValuation from "./DashboardValuation";
import QVFourEmailAndWebsiteFooter from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourEmailAndWebsiteFooter";
import QRCodeSection from "./QRCodeSection";

class HomeValueChanged extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./print.scss");
    } else {
      require("./editor.scss");
    }
  }
  render() {
    const {
      agentData,
      MQCityDetails,
      printVersion,
      currentArea,
      hoData,
      qArea,
      qCity,
      quarterlyAreaTable,
      quarterlyCityTable,
    } = this.props;
    const { mlsAreaID } = currentArea;
    let isBuellton =
      currentArea.mlsAreaID === "SBA-40F" ||
      currentArea.mlsAreaID === "SBA-40G";

    let useCity = false;
    if (mlsAreaID === "SMA-353A") {
      useCity = true;
    }

    if (useCity) {
      return (
        <div
          className={
            printVersion
              ? "print-home-value-changed"
              : "editor-home-value-changed"
          }
        >
          <img
            className="dashboard-iris-img"
            src="https://pma-coverphotos.s3.amazonaws.com/non-covers/AUG-24_Dashboard-ImageBG-turquoise_2.jpg"
          />
          {quarterlyCityTable &&
            quarterlyCityTable.dashBoardDetailsWithPriceRange && (
              <DashboardHeader
                MQCityDetails={quarterlyAreaTable}
                printVersion={printVersion}
                currentArea={currentArea}
                useCity={useCity}
                dashBoardMonths={quarterlyCityTable.dashBoardMonths}
                agentData={agentData}
              />
            )}
          {quarterlyCityTable &&
            quarterlyCityTable.dashBoardDetailsWithPriceRange && (
              <DashboardBody
                MQCityDetails={quarterlyCityTable}
                printVersion={printVersion}
                agentData={agentData}
                currentArea={currentArea}
              />
            )}
          <DashboardValuation agentData={agentData} />
          {/*<QRCodeSection hoData={hoData} agentData={agentData} />*/}

          <QVFourEmailAndWebsiteFooter
            agentData={agentData}
            currentArea={currentArea}
            printVersion={printVersion}
          />
        </div>
      );
    } else {
      return (
        <div
          className={
            printVersion
              ? "print-home-value-changed"
              : "editor-home-value-changed"
          }
        >
          <img
            className="dashboard-iris-img"
            src="https://pma-coverphotos.s3.amazonaws.com/non-covers/AUG-24_Dashboard-ImageBG-turquoise_2.jpg"
          />
          {quarterlyAreaTable &&
            quarterlyAreaTable.dashBoardDetailsWithPriceRange && (
              <DashboardHeader
                MQCityDetails={quarterlyAreaTable}
                dashBoardMonths={MQCityDetails.dashBoardMonths}
                printVersion={printVersion}
                currentArea={currentArea}
                agentData={agentData}
              />
            )}
          {quarterlyAreaTable &&
            quarterlyAreaTable.dashBoardDetailsWithPriceRange && (
              <DashboardBody
                MQCityDetails={quarterlyAreaTable}
                printVersion={printVersion}
                agentData={agentData}
                currentArea={currentArea}
              />
            )}
          <DashboardValuation agentData={agentData} />
          {/*<QRCodeSection hoData={hoData} agentData={agentData} />*/}

          <QVFourEmailAndWebsiteFooter
            agentData={agentData}
            currentArea={currentArea}
            printVersion={printVersion}
          />
        </div>
      );
    }
  }
}

export default HomeValueChanged;
