import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Typography, CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import styles from '../styles';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ApproveMailingListPendingActions } from './ApproveMailingListPendingActions';
import { propertyUtil } from 'views/Pma/PropertyUtil';

const ApprovalMailingListDialog = (props) => {
    const [open, setOpen] = React.useState(true);
    const [isLoading, setLoading] = React.useState(false);
    /**get classes */
    const { classes, approveMailingListFn, continueEditingModeFn, mlsAreas, areaName, hoFilter } = props;

    const { showBox, areaCount } = propertyUtil.getUnapprovedAreaCount(mlsAreas);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="approval-mailing-list-dialog"
                aria-describedby="approval-mailing-list-dialog"
                maxWidth={"sm"}
                disableBackdropClick={true}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className={classes.approvalDialogTextContent}>
                        <ApproveMailingListPendingActions {...props} mode="body" handleClose={handleClose}>
                            <Fragment>
                                <Typography variant="subtitle2" align="center" className={classNames(classes.primaryColor, classes.boldFont)}>
                                    Ahhhh… Looks like you’ve finished all your edits for area {areaName}. Great!
                                </Typography>
                                <br />
                                <Typography variant="subtitle2" align="center">
                                    Just checking…
                                </Typography>
                                <br />
                                <Typography variant="body1" align="center">
                                    Before you finish up, we just want to make sure you’re 100% satisfied with your work.
                                </Typography>
                                <Typography variant="body1" align="center">
                                    If you’re sure you’re all done making any changes to your PMA and to MailPro, then just click
                                    the <span className={classNames(classes.greenColor, classes.boldFont)}>OK, I’M FINISHED</span> button.
                                </Typography>
                                <br />
                                <Typography variant="body1" align="center" className={classes.boldFont}>
                                    (You won’t be able to make any more changes after this)
                                </Typography>
                                <br />
                                <Typography variant="body1" align="center">
                                    OR
                                </Typography>
                                <br />
                                <Typography variant="body1" align="center">
                                    If you’d like to continue editing your PMA or MailPro, just click <spna className={classNames(classes.primaryColor, classes.boldFont)}>CONTINUE EDITING</spna>.
                                </Typography>
                                <br />
                                {(showBox && areaCount > 0) ?
                                    <div className={classNames(classes.unapproveAreaDivDialog)}>
                                        <Typography variant="body1" className={classes.whiteColor}>
                                            Please Note: You have {areaCount} remaining area{areaCount > 1 ? '[s]' : ''} to approve.
                                        </Typography>
                                    </div>
                                    : ''}
                            </Fragment>
                        </ApproveMailingListPendingActions>
                    </DialogContentText>
                </DialogContent>
                {isLoading ?
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '8px', height: '48px' }}>
                        <CircularProgress />
                    </div>
                    :
                    <div className={classNames(classes.portletFooter, classes.approvalFooter)} style={{ width: 'auto' }}>
                        <ApproveMailingListPendingActions {...props} mode="footer" handleClose={handleClose}>
                            <div className={classes.footerButtons}>
                                <div className={classes.footerButtonsLeftRight}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={event => continueEditingModeFn()}
                                    >
                                        CONTINUE EDITING
                                    </Button>
                                </div>
                                <div className={classes.footerButtonsCenter}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.finishedButton}
                                        onClick={event => { setLoading(true); approveMailingListFn(); }}
                                    >
                                        OK, I’M FINISHED
                                    </Button>
                                </div>
                                <div className={classes.footerButtonsLeftRight}></div>
                            </div>
                        </ApproveMailingListPendingActions>
                    </div>
                }
            </Dialog>
        </div>
    );
}

ApprovalMailingListDialog.propTypes = {
    approveMailingListFn: PropTypes.func.isRequired,
    continueEditingModeFn: PropTypes.func.isRequired,
    mlsAreas: PropTypes.array.isRequired,
    areaName: PropTypes.string.isRequired,
    hoFilter: PropTypes.string.isRequired,
};

ApprovalMailingListDialog.defaultProps = {
    approveMailingListFn: () => { },
    continueEditingModeFn: () => { },
    mlsAreasa: [],
    areaName: null
};

export default compose(withStyles(styles))(ApprovalMailingListDialog);
