import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Button, Link } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';
import DocumentTitle from 'react-document-title';
const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    marginTop: '50px',
    textAlign: 'center'
  },
  grow: {
    marginBottom: theme.spacing(2)
  },
  inlineImageHolder: {
    display: 'inline-flex',
    justifyContent: 'center',
    width: '100%'
  },
  imageWrapper: {
    height: '52px',
    width: '48px',
    border: 'none',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(0.5)
  },
  imageWrapperAlt: {
    height: '52px',
    width: '144px',
    border: 'none',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(0.5),
  },
  image1: {
    width: '100%',
  },
  image: {
    display: 'inline-block',
    marginTop: '50px',
    maxWidth: '100%',
    width: '354px',
    justifyContent: 'center'
  },
  goToHomePage: {
    marginTop: theme.spacing(8)
  }
});

class NotFound extends Component {
  goToHomePage = () => {
    window.location.href = '/home';
  }
  render() {
    const { classes } = this.props;
    const listgenLogo = '/images/listgen-logo.png';
    const listgenAltLogo = '/images/listgen-text-advanced.PNG';
    const listgenLogoAlt = 'ListGen Logo';

    return (
      <DocumentTitle title="Page Not Found">
      <div className={classes.root}>
        <Grid
          container
          justify="center"
          spacing={4}
        >
          <Grid
            item
            lg={6}
            xs={12}
          >
            <div className={classes.content}>
              <div className={classes.grow}>
                <div className={classes.inlineImageHolder}>
                  <Link to="/home">
                    <div className={classes.imageWrapper}>
                      <img
                        alt={listgenLogoAlt}
                        className={classes.image1}
                        src={listgenLogo}
                      />
                    </div>
                  </Link>
                  <Link to="/home">
                    <div className={classes.imageWrapperAlt}>
                      <img
                        alt={listgenLogoAlt}
                        className={classes.image1}
                        src={listgenAltLogo}
                      />
                    </div>
                  </Link>
                </div>
              </div>
              <Typography variant="h1">
                404: The page you are looking for isn’t here
              </Typography>
              <Typography variant="subtitle2">
                You either tried some shady route or you came here by mistake.
                Whichever it is, try using the navigation
              </Typography>
              <div>
                <img
                  alt="Under development"
                  className={classes.image}
                  src="/images/not_found.png"
                />
              </div>
              <Button color="primary" variant="outlined" className={classes.goToHomePage} onClick={event => this.goToHomePage()}>Go to Home Page</Button>
            </div>
          </Grid>
        </Grid>
      </div>
      </DocumentTitle>
    );
  }
}

NotFound.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NotFound);
