import * as React from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";

// import { DeleteIcon, IconButton } from "@material-ui/icons";
// import Tooltip from "@mui/material/Tooltip";
// import { Tooltip } from "@material-ui";
//mport Tooltip from "@mui/material/Tooltip";
import { withSnackbar } from "notistack";
import { compose } from "recompose";
import styles from "views/PmaAdmin/PropertyHighlights/styles";
import { Tooltip } from "@material-ui/core";

function ListingRow(props) {
  const getMonthAndYearString = (inputTime) => {
    try {
      let month = moment(inputTime).format("MMM");
      let day = moment(inputTime).format("D");
      let year = moment(inputTime).format("YY");

      return (
        <div className="date-td">
          <div>
            {month} {day}, {year}
          </div>
        </div>
      );
      // return moment(inputTime).format("MMM D, YYYY");
    } catch (err) {
      return "";
    }
  };
  const getTodayDate = () => {
    return moment(new Date()).format("MMM D, YYYY");
  };
  const calculatePricePerSqft = (calculation, saleP, sqft) => {
    if (calculation === "-") {
      let res = Math.round(saleP / sqft);
      return res.toLocaleString();
    } else {
      return Math.round(calculation).toLocaleString();
    }
  };

  const {
    propertyList,
    classes,
    fromPhotoTable,
    solds,
    mlsAreaID,
    older,
    isSingleTable,
    handlePropCheck,
    agentData,
    handleOpenEditDialog,
    oldPropData,
    properties,
    isTopSix,
    propListMap,
    singleProperty,
  } = props;
  const {
    getPropView,
    formatSuffix,
    formatBaths,
    toAcre,
    cutName,
    propTypeAcronym,
    shortenStatus,
    shortenPropType,
  } = propertyUtil;
  const { shortenYear } = propertyUtil;
  // console.log("******inside listing row", props);
  // console.log("singleProperty is", singleProperty);
  let {
    listgenUserFirstName,
    agentLicenseNumber,
    isMemberOfTeam,
    teamMembers,
    listgenUserID,
    teamName,
  } = agentData;

  let isCountryClubGate = mlsAreaID === "132" ? true : false;
  const listMap =
    properties &&
    properties.map((list, i) => {
      let hasUnitNum =
        !isCountryClubGate &&
        (list.propertyType === "Townhouse" ||
          list.propertyType === "Condominium") &&
        list.unitNumber !== "-";

      let addressName = "";
      if (
        list.streetNumber !== "-" &&
        list.streetNumber != "0" &&
        list.streetNumber !== "."
      ) {
        addressName += list.streetNumber;
      }
      addressName += ` ${list.streetName}`;
      if (list.streetSuffix && list.streetSuffix !== "-") {
        addressName += ` ${list.streetSuffix}`;
      }
      if (hasUnitNum) {
        addressName += `, #${list.unitNumber}`;
      }
      let addressSize = addressName.length > 25 ? "smaller-row-address" : "";

      const teamMember1License =
        isMemberOfTeam && teamMembers[0].agentLicenseNumber;
      const teamMember2License =
        isMemberOfTeam && teamMembers[1].agentLicenseNumber;
      let teamMember1Name =
        isMemberOfTeam && teamMembers[0].firstName.toUpperCase();

      let isListingAgent = false;

      if (
        (!isMemberOfTeam &&
          agentLicenseNumber === list.listingAgentLicenseID) ||
        (isMemberOfTeam &&
          (teamMember1License === list.listingAgentLicenseID ||
            teamMember2License === list.listingAgentLicenseID)) ||
        (list.isCoListingAgent && list.isCoListingAgent === "true") ||
        list.coListingAgents
      ) {
        isListingAgent = true;
      }
      let isSellingAgent = false;
      if (
        (!isMemberOfTeam &&
          agentLicenseNumber === list.sellingAgentLicenseID) ||
        (isMemberOfTeam &&
          (teamMember1License === list.sellingAgentLicenseID ||
            teamMember2License === list.sellingAgentLicenseID)) ||
        (list.isCoSellingAgent && list.isCoSellingAgent === "true")
      ) {
        isSellingAgent = true;
      }

      const hasBanner = isSellingAgent || isListingAgent;

      return (
        <div
          key={`${list.address}-${list.listPrice}-${list.saleDate}-${list.status}-${i}`}
          className={`row-for-property ${list.status.toLowerCase()} ${
            older ? "photo-presolds-row" : "photo-recent-row"
          } ${isSingleTable ? "single-table-row" : ""} ${
            list.isSystemSelected && hasBanner
              ? "prop-row-checked banner-checked"
              : list.isSystemSelected && "prop-row-checked"
          }`}
        >
          <div className="list-checked-td">
            <img
              src={
                list.isSystemSelected
                  ? "https://pma-coverphotos.s3.amazonaws.com/check-mark_50px_Wbox_72ppi.png"
                  : "https://pma-coverphotos.s3.amazonaws.com/check-box_50px._72ppi.png"
              }
              className="photo-row-checkbox"
              id={`${list.address} ${list.listPrice} ${list.saleDate} ${i}`}
              className="photo-row-checkbox"
              onClick={(e) => handlePropCheck(e, list, hasBanner, false)}
            />
          </div>
          <div className="list-num-td">{isTopSix ? i + 1 : ""} </div>
          <div className="edit-btn" onClick={() => handleOpenEditDialog(list)}>
            <Tooltip title="Edit the details of this property or upload a different photo">
              <div>Edit</div>
            </Tooltip>
          </div>
          <div
            // className={`status-text`}
            className={`status-text ${list.status.toLowerCase()} list-status-td`}
            align="left"
          >
            {list.isOffMarketSale == "1" && <strong>*</strong>}
            <p>{shortenStatus(list.status)}</p>
          </div>

          {list.saleDate === "-" ? (
            <div className="list-date-td"></div>
          ) : (
            <div
              className={`market-table-sale-date-row list-date-td list-date-td-${list.status}`}
            >
              {getMonthAndYearString(list.saleDate)}
            </div>
          )}
          {!fromPhotoTable && (
            <div className="prop-type-text">
              {propTypeAcronym(list.propertyType)}
            </div>
          )}

          <div className="list-address-td">
            {hasBanner && (
              <p>
                {isMemberOfTeam ? "Our" : "My"} {list.status}
              </p>
            )}
            <p className={addressSize} className="list-address-name-td-text">
              {addressName}
            </p>
          </div>
          <div className="list-list-price-td">
            {list.listPrice === "-" ? (
              <div className="off-centered-dash">
                <p>—</p>
              </div>
            ) : (
              <div>
                <p>$</p>
                <p>{Number(list.listPrice).toLocaleString()}</p>
              </div>
            )}
          </div>

          <div className="list-sale-price-td">
            {list.salePrice === "-" ? (
              <div className="off-centered-dash">
                <p>—</p>
              </div>
            ) : (
              <div>
                <p>$</p>
                <p>{Number(list.salePrice).toLocaleString()}</p>
              </div>
            )}
          </div>
          {list.isOffMarketSale == "1" &&
          (list.daysOnMarket === "0" || list.daysOnMarket === "-") ? (
            <div>
              <div className="off-centered-dash">
                <p>—</p>
              </div>
            </div>
          ) : !fromPhotoTable ? (
            <div>{list.daysOnMarket}</div>
          ) : null}
          {!fromPhotoTable && <div>{formatBaths(list.bedBaths)}</div>}
        </div>
      );
    });

  return (
    <>
      {properties && properties.length >= 1 && (
        <div className="table-header-tr">
          <div></div>
          <div></div>
          <div></div>
          <div>Status</div>
          <div>Sale Date</div>
          <div>Address</div>
          <div>List Price</div>
          <div>Sale Price</div>
        </div>
      )}

      {listMap}
    </>
  );
}
export default compose(withSnackbar, withStyles(styles))(ListingRow);
