import React, {
  Component,
  Fragment,
  useState,
  useEffect,
  useContext
} from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Input,
  TextField
} from "@material-ui/core";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "./styles";
import { Dashboard as DashboardLayout } from "layouts";
import { withSnackbar } from "notistack";
import { Portlet } from "components";
import { PortletContent } from "components";
import { PortletHeader } from "components";
import classNames from "classnames";
import { PortletFooter } from "components";
import { PortletLabel } from "components";

import { GetPmaAdminDataAPI } from "services/agent";
import moment from "moment";
import { GetStateStorage, IsValidSessionSwitch } from "common/storage";
import { showSuccessNotification } from "common/helpers";
import { showErrorNotification } from "common/helpers";
import CommonConfirmationDialog from "components/CommonConfirmationDialog";
import { Store } from "Store";
import { UPDATE_PMA_TESTIMONIALS_COUNT } from "store/types";
import Base from "common/constants";
import DocumentTitle from "react-document-title";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Skeleton } from "@material-ui/lab";
import AgentROIContainer from "./component/AgentROIContainer";

const AgentROIForm = props => {
  const { classes } = props;
  // const { dispatch } = useContext(Store);
  //state storage
  const isAValidSessionSwitch = IsValidSessionSwitch();
  // const { userData } = GetStateStorage();
  // const [addedTestimonials, setAddedTestimonials] = React.useState([]);
  //
  // const [showAddDialog, setAddDialog] = React.useState(false);
  // const [oldData, setOldData] = React.useState(null);
  // const [mode, setMode] = React.useState(null);
  // const [isLoading, setIsLoading] = useState(false);
  // const [showConfirmationDialog, setConfirmationDialog] = React.useState(false);
  // const [confirmationConstantID, setConfirmationConstantID] = React.useState(
  //   null
  // );

  //check for admin access
  // const isAdminAccess =
  //   isAValidSessionSwitch ||
  //   userData.listgenRoleID === Base.APP_DATA.APP_ADMIN_ROLE_ID;

  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  // const renderSearchResult = () => {
  //   return <AgentROIContainer data="hey" />;
  // };
  return (
    <Fragment>
      <DocumentTitle title="Agent ROI Form">
        <DashboardLayout title="Agent ROI Form">
          <div className={classNames(classes.root, classes.rootMinHeight)}>
            <Portlet className={classes.content}>
              <PortletHeader className={classes.stickyHead}>
                <PortletLabel
                  title="Agent ROI Form"
                  className={classes.primaryColor}
                />
              </PortletHeader>
              <PortletContent noPadding className={classes.agentROIBackground}>
                <AgentROIContainer />
              </PortletContent>
            </Portlet>
          </div>
        </DashboardLayout>
      </DocumentTitle>
    </Fragment>
  );
};

AgentROIForm.propTypes = {
  classes: PropTypes.object.isRequired
};
export default compose(withSnackbar, withStyles(styles))(AgentROIForm);
