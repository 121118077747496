/* PMA Action Creators */

import { getPMAHoNextPrintDateForAAgentFromSchedule } from "views/Schedule/MailingScheduleServices";
import { pmaActionConstants } from "../../_constants";
import { pmaUtil } from "../../_util/";

export const pmaActions = {
  getAgentQR,
  updateRecentActivityPhotos,
  getRecentActivityPhotos,
  updateCheckboxPhotoModalOpen,
  getPropertyPhotoDetails,
  getBubbleBoxDetails,
  getBubblePriceRange,
  getDonutAndTable,
  setBubbleDetails,
  setMissionStatementDetails,
  setQuadrantDetails,
  getQuadrantDetails,
  updateQuadrantModalOpen,
  updateMissionStatmentModalOpen,
  getMarketTrends,
  getTableBubbleData,
  getDecemberData,
  getMissionStatement,
  getDashboardDonutApi,
  getPmaDetailsApi,
  getTablePmaApi,
  getDataVerification,
  getSpeedometerData,
  changePDFPrintVersion,
  getMayApi,
  getAprilApi,
  getMQ2CityDetails,
  deleteSelectedArea,
  addSelectedArea,
  qAreaAndCityDetails,
  getYearEndDetails,
  getSAT,
  getSalePriceForCities,
  getAgentAdvantages,
  getAgentDesignations,
  getTestimonials,
  getPMACoverPhotoDetails,
  switchCLVersion,
  getQuarterAreaTable,
  getQuarterCityTable,
  changeLoading,
  switchVersion,
  switchStep,
  switchArea,
  updateCurrentAreaData,
  switchAreaWithStep,
  toggleListingCheck,
  setCurrentRecord,
  setBlockedReason,
  setTags,
  updateHOSearch,
  updateHOQuickFilter,
  updateHOAdvanceFilter,
  updateHOAdvanceFilterOptionData,
  mailProHeaderSort,
  resetProMailHeaderandAllFilters,
  resetMailproCurrentHeaderSort,
  approveMailingList,
  postHomeowners,
  getMailerProDetails,
  getMailProMailerData,
  updateCompleteMailerProDataAsync,
  updateRecentUpdatedMailProId,
  updateHomeownerProp,
  updateQuickPersonalNotePS,
  getCityAreaMap,
  getMailProIDDetails,
  getMarketActivity,
  getLocalSellingAgents,
  getNavbarInfo,
  updateNavbar,
  getMQCityDetails,
  changeMailProStatus,
  updateHOFilter,
  getNextPMAPrintDate,
  setROIEnlargeView,
  setROIAreaIntervelID,
  initROIOpenAccessView,
  addROICityDataMapForCounty,
  handleROIAreaSelectionResponse,
  updateEditedNewMailingData,
  updateNewMailingMailProData,
  setNewMailProInitDialogModeFn,
  disableNewMailingForCurrentSessionFn,
  explicitEnableNewMailProInitFn,
  resetNewMailProInitDialogFn,
};

function updateRecentActivityPhotos(recentActivityData) {
  console.log("inside recent activity photos", recentActivityData);
  return {
    type: pmaActionConstants.UPDATE_RECENT_ACTIVITY_DATA,
    payload: recentActivityData,
  };
}

function getRecentActivityPhotos(listgenUserAreaID) {
  return async (dispatch) => {
    const res = await pmaUtil.getRecentActivityPhotos(listgenUserAreaID);
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_RECENT_ACTIVITY_PHOTOS,
        payload: res.data,
      });
    }
  };
}

function setMissionStatementDetails(missionStatementDetails) {
  console.log(
    "inside SET missionStatementDetails details",
    missionStatementDetails
  );
  return async (dispatch) => {
    const res = await pmaUtil.setMissionStatementDetails(
      missionStatementDetails
    );
    if (res && res.status === 200) {
      console.log("setting quadrant details", res.status, res.data);
    }
  };
}

function setBubbleDetails(bubbleDetails) {
  console.log("inside SET bubble details", bubbleDetails);
  return async (dispatch) => {
    const res = await pmaUtil.setBubbleDetails(bubbleDetails);
    if (res && res.status === 200) {
      console.log("setting quadrant details", res.status, res.data);
      // dispatch({
      //   type: pmaActionConstants.GET_QUADRANT_DETAILS,
      //   payload: {
      //     quadrantDetails: res.data,
      //   },
      // });
    }
  };
}

function setQuadrantDetails(quadrantDetails) {
  console.log("inside SET quadrant details", quadrantDetails);
  return async (dispatch) => {
    const res = await pmaUtil.setQuadrantDetails(quadrantDetails);
    if (res && res.status === 200) {
      console.log("setting quadrant details", res.status, res.data);
      // dispatch({
      //   type: pmaActionConstants.GET_QUADRANT_DETAILS,
      //   payload: {
      //     quadrantDetails: res.data,
      //   },
      // });
    }
  };
}

function getBubbleBoxDetails(listgenUserID) {
  return async (dispatch) => {
    const res = await pmaUtil.getBubbleBoxDetails(listgenUserID);
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_BUBBLE_BOX_DETAILS,
        payload: {
          bubbleBoxDetails: res.data,
        },
      });
    }
  };
}

function getQuadrantDetails(listgenUserID) {
  //  console.log("inside quadrant details", listgenUserID);
  return async (dispatch) => {
    const res = await pmaUtil.getQuadrantDetails(listgenUserID);
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_QUADRANT_DETAILS,
        payload: {
          quadrantDetails: res.data,
        },
      });
    }
  };
}

function updateCheckboxPhotoModalOpen(isModalOpen) {
  console.log("****UPDATE PHOTO MODAL actions", isModalOpen);
  return {
    type: pmaActionConstants.UPDATE_CHECKBOX_PHOTO_MODAL,
    payload: {
      isModalOpen,
    },
  };
}

function updateMissionStatmentModalOpen(isModalOpen) {
  console.log("****actions update mission statement", isModalOpen);
  return {
    type: pmaActionConstants.UPDATE_MISSION_STATEMENT_MODAL,
    payload: {
      isModalOpen,
    },
  };
}

function updateQuadrantModalOpen(isModalOpen) {
  //  console.log("****actions", isModalOpen);
  return {
    type: pmaActionConstants.UPDATE_QUADRANT_MODAL,
    payload: {
      isModalOpen,
    },
  };
}

function getPropertyPhotoDetails(listgenUserID, areaID) {
  return async (dispatch) => {
    const res = await pmaUtil.getPropertyPhotoDetails(
      `${listgenUserID}-${areaID}`
    );
    if (res && res.status === 200) {
      // console.log("market trends res", res);
      dispatch({
        type: pmaActionConstants.GET_PROPERTY_PHOTO_DETAILS,
        payload: {
          resData: res.data,
        },
      });
    } else {
      console.log("issue returning market trends api");
    }
  };
}

function getMarketTrends(
  areaAndCity,
  subdivision,
  listgenUserID,
  mailerProID,
  firstHO
) {
  return async (dispatch) => {
    const res = await pmaUtil.getMarketTrends(
      areaAndCity,
      subdivision,
      listgenUserID,
      mailerProID
    );
    if (res && res.status === 200) {
      console.log("market trends res", res);
      dispatch({
        type: pmaActionConstants.GET_MARKET_TRENDS_API,
        payload: {
          resData: res.data,
          singleHOData: firstHO,
        },
      });
    } else {
      console.log("issue returning market trends api");
    }
  };
}
function getDonutAndTable(
  areaAndCity,
  subdivision,
  listgenUserID,
  mailerProID
) {
  return async (dispatch) => {
    const res = await pmaUtil.getDonutAndTable(
      areaAndCity,
      subdivision,
      listgenUserID,
      mailerProID
    );
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_DONUT_AND_TABLE,
        payload: res.data,
      });
    } else {
      console.log("issue returning pma table api");
    }
  };
}

// console.log("***new api", res.data);
// const {
//   navbarInfoList,
//   pmaCoverPhotoDTO,
//   mailerPrintInfoDTO,
//   // listgenUserDTO,
//   pmaQuarterlyAreaDTO,
//   pmaQuarterlyCityDTO,
//   missionStatementDTO,
//   pmaQuarterlyCombinedCityDTO,
// } = res.data;
// const singleHOData = mailerPrintInfoDTO.mailerProDTO;
// const agentData = mailerPrintInfoDTO.listgenUserDTO;
// //  console.log("pmaQuarterlyCityDTO", pmaQuarterlyCityDTO);
// //console.log("pmaQuarterlyAreaDTO", pmaQuarterlyAreaDTO);
// //console.log("two", pmaQuarterlyCityDTO);
// const currentAreaFromApi = navbarInfoList.filter(
//   (area) => area.mlsAreaID === thisArea
// )[0];
function getPmaDetailsApi(
  areaAndCity,
  subdivision,
  listgenUserID,
  mailerProID
) {
  return async (dispatch) => {
    const res = await pmaUtil.getPmaDetailsApi(
      areaAndCity,
      subdivision,
      listgenUserID,
      mailerProID
    );
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_PMA_DETAILS_API,
        payload: res.data,
      });
    } else {
      console.log("issue returning pma table api");
    }
  };
}

function getDashboardDonutApi(
  areaAndCity,
  subdivision,
  listgenUserID,
  mailerProID
) {
  return async (dispatch) => {
    const res = await pmaUtil.getDashboardDonutApi(
      areaAndCity,
      subdivision,
      listgenUserID,
      mailerProID
    );
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_DASHBOARD_DONUT_API,
        payload: res.data,
      });
    } else {
      console.log("issue returning pma table api");
    }
  };
}

function getMissionStatement(listgenUserID) {
  return async (dispatch) => {
    const res = await pmaUtil.getMissionStatementApi(listgenUserID);

    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_MISSION_STATEMENT,
        payload: res.data,
      });
    } else {
      console.log("something went wrong with getMission statement");
    }
  };
}
function getBubblePriceRange(
  areaAndCity,
  subdivision,
  listgenUserID,
  mailerProID
) {
  return async (dispatch) => {
    const res = await pmaUtil.getBubblePriceRange(
      areaAndCity,
      subdivision,
      listgenUserID,
      mailerProID
    );
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_BUBBLE_PRICE_RANGE,
        payload: res.data,
      });
    } else {
      console.log("issue returning pma table api");
    }
  };
}

function getTableBubbleData(
  areaAndCity,
  subdivision,
  listgenUserID,
  mailerProID
) {
  // console.log("inside actions getTableApi", listgenUserID);
  // console.log("inside Table api", areaAndCity, subdivision, mailerProID);
  return async (dispatch) => {
    const res = await pmaUtil.getTableBubbleData(
      areaAndCity,
      subdivision,
      listgenUserID,
      mailerProID
    );
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_TABLE_BUBBLE_DATA,
        payload: res.data,
      });
    } else {
      console.log("issue returning pma table api");
    }
  };
}

function getTablePmaApi(areaAndCity, subdivision, listgenUserID, mailerProID) {
  // console.log("inside actions getTableApi", listgenUserID);
  // console.log("inside Table api", areaAndCity, subdivision, mailerProID);
  return async (dispatch) => {
    const res = await pmaUtil.getTablePmaApi(
      areaAndCity,
      subdivision,
      listgenUserID,
      mailerProID
    );
    if (res && res.status === 200) {
      const {
        mailerPrintInfoDTO,
        pmaCoverPhotoDTO,
        pmaQuarterlyAreaDTO,
        pmaQuarterlyCityDTO,
      } = res.data;
      const { lgTestimonialsDTO } = mailerPrintInfoDTO;
      console.log("&&&res.data", res.data);
      dispatch({
        type: pmaActionConstants.GET_TABLE_PMA_API,
        payload: {
          quarterlyAreaTable: pmaQuarterlyAreaDTO,
          MQCityDetails: pmaQuarterlyCityDTO,

          testimonials: lgTestimonialsDTO,
          coverPageDetails: pmaCoverPhotoDTO,
        },
      });
    } else {
      console.log("issue returning pma table api");
    }
  };
}

function getDataVerification(areaAndDate, subdivision, listgenUserID, area) {
  return async (dispatch) => {
    console.log(
      "****inside actions data verification",
      areaAndDate,
      subdivision
    );
    console.log("listgenUserID", listgenUserID);
    let loadStart = await dispatch(changeLoading(true));
    const res = await pmaUtil.getDataVerification(areaAndDate, subdivision);
    const navbar = await dispatch(getNavbarInfo(listgenUserID));
    const stepSwitch = await dispatch(switchStep(2));
    // const gettingDecemberData = await dispatch(
    //   getDecemberData(areaAndDate, subdivision, listgenUserID, mailerProID)
    // );

    // const propertyRecords = await this.props.getMailerProDetails(
    //   "",
    //   "",
    //   "",
    //   true,
    //   "qv4",
    //   "",
    //   "",
    //   listgenUserID,
    //   "qv4",
    //   true
    // );

    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_DATA_VERIFICATION,
        payload: res.data,
      });
      let loadStart = await dispatch(changeLoading(false));
    } else {
      let loadStart = await dispatch(changeLoading(false));
    }
  };
}

function getDecemberData(cityAndDate, subdivision, listgenUserID, mailerProID) {
  return async (dispatch) => {
    const res = await pmaUtil.getDashBoardWithPriceRangeAndFeaturePhotos(
      cityAndDate,
      subdivision,
      listgenUserID,
      mailerProID
    );
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_DECEMBER_DATA,
        payload: {
          coverPageDetails: res.data.pmaCoverPhotoDTO,
          propertyPhotoDetailsDTO: res.data.propertyPhotoDetailsDTO,
          quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
          quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
          singleHOData: res.data.mailerPrintInfoDTO.mailerProDTO,
          agentData: res.data.mailerPrintInfoDTO.listgenUserDTO,
          testimonials: res.data.mailerPrintInfoDTO.lgTestimonialsDTO,
          pmaQuarterlyCombinedCityDTO: res.data.pmaQuarterlyCombinedCityDTO,
          quadrantInfoList: res.data.quadrantInfoList,
        },
      });
    }
  };
}

function getSpeedometerData(
  cityAndDate,
  subdivision,
  listgenUserID,
  mailerProID
) {
  return async (dispatch) => {
    const res = await pmaUtil.getSpeedometerData(
      cityAndDate,
      subdivision,
      listgenUserID,
      mailerProID
    );
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_SPEEDOMETER_DATA,
        payload: {
          coverPageDetails: res.data.pmaCoverPhotoDTO,
          quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
          quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
          singleHOData: res.data.mailerPrintInfoDTO.mailerProDTO,
          agentData: res.data.mailerPrintInfoDTO.listgenUserDTO,
          testimonials: res.data.mailerPrintInfoDTO.lgTestimonialsDTO,
        },
      });
    }
  };
}

function changePDFPrintVersion(version) {
  return async (dispatch) => {
    dispatch({
      type: pmaActionConstants.CHANGE_PDF_PRINT_VERSION,
      payload: version,
    });
  };
}
function getMayApi(cityAndDate, subdivision, listgenUserID, mailerProID) {
  return async (dispatch) => {
    const res = await pmaUtil.getMayApi(
      cityAndDate,
      subdivision,
      listgenUserID,
      mailerProID
    );
    if (res && res.status === 200) {
      const {
        mailerPrintInfoDTO,
        pmaCoverPhotoDTO,
        pmaQuarterlyAreaDTO,
        pmaQuarterlyCityDTO,
      } = res.data;
      const {
        lgTestimonialsDTO,
        listAgentDesignationsDTO,
      } = mailerPrintInfoDTO;

      dispatch({
        type: pmaActionConstants.GET_MAY_API,
        payload: {
          quarterlyAreaTable: pmaQuarterlyAreaDTO,
          MQCityDetails: pmaQuarterlyCityDTO,
          agentDesignations: listAgentDesignationsDTO,
          testimonials: lgTestimonialsDTO,
          coverPageDetails: pmaCoverPhotoDTO,
        },
      });
    } else {
      console.log("issue returning may api");
    }
  };
}

function getAprilApi(cityAndDate, subdivision) {
  return async (dispatch) => {
    const res = await pmaUtil.getAprilApi(cityAndDate, subdivision);
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_APRIL_API,
        payload: res.data,
      });
    }
  };
}

function getMQ2CityDetails(cityAndDate, subdivision, listgenUserID) {
  return async (dispatch) => {
    const res = await pmaUtil.getMQ2CityDetails(
      cityAndDate,
      subdivision,
      listgenUserID
    );

    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_MQ2_CITY_DETAILS,
        payload: {
          testimonials: res.data.lgTestmonialsDTO,
          pmaMidQuarterlyAreaDTO: res.data.pmaMidQuarterlyAreaDTO,
        },
      });
    }
  };
}

function deleteSelectedArea(areaID) {
  //for ROI

  return {
    type: pmaActionConstants.DELETE_SELECTED_AREA,
    payload: areaID,
  };
}

function addSelectedArea(areaObj) {
  //for ROI
  return {
    type: pmaActionConstants.ADD_SELECTED_AREA,
    payload: areaObj,
  };
}

function initROIOpenAccessView(enlarge = true) {
  //for ROI
  return {
    type: pmaActionConstants.INIT_ROI_OPEN_ACCESS_VIEW,
    payload: enlarge,
  };
}

function setROIEnlargeView(enlarge = true) {
  //for ROI
  return {
    type: pmaActionConstants.SET_ROI_ENLARGE_VIEW,
    payload: enlarge,
  };
}

function setROIAreaIntervelID(enlarge = true) {
  //for ROI
  return {
    type: pmaActionConstants.SET_ROI_AREA_INTERVEL_ID,
    payload: enlarge,
  };
}

function addROICityDataMapForCounty(data) {
  //for ROI
  //for ROI
  return {
    type: pmaActionConstants.ADD_ROI_AREA_CITY_DATA_FOR_COUNTY,
    payload: data,
  };
}

function handleROIAreaSelectionResponse(pendingAreas, selectedAreas, licensedAreas) {
  //for ROI
  return {
    type: pmaActionConstants.HANDLE_ROI_AREA_ALLOCATION_RESPONSE,
    payload: {
      pendingAreas,
      selectedAreas,
      licensedAreas
    },
  };
}

function qAreaAndCityDetails(mlsAreaID, cityAndDate, subdivision, test) {
  return async (dispatch) => {
    const res = await pmaUtil.qAreaAndCityDetails(
      mlsAreaID,
      cityAndDate,
      subdivision
    );
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.Q_AREA_AND_CITY_DETAILS,
        payload: {
          qAreaDetails: res.data,
          subdivision: subdivision,
        },
      });
    }
  };
}

function getYearEndDetails(date, listgenUserID) {
  console.log("inside actions getYearEndDetails", date);
  // console.log('inside actions getYearEndDetails', listgenUserID);
  return async (dispatch) => {
    const res = await pmaUtil.getYearEndDetails(date, listgenUserID);
    // console.log('the res of pma.actions getYearEndDetails', res);
    if (res && res.status === 200) {
      console.log("response from actions get year end details", res.data);
      dispatch({
        type: pmaActionConstants.GET_YEAR_END_DETAILS,
        payload: res.data,
      });
    }
  };
}

function getSAT(hoID, listgenUserID) {
  return async (dispatch) => {
    const res = await pmaUtil.getSAT(hoID, listgenUserID);

    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_SAT,
        payload: res.data,
      });
    }
  };
}

function switchCLVersion(version) {
  return {
    type: pmaActionConstants.SWITCH_CL_VERSION,
    payload: {
      version,
    },
  };
}

function getSalePriceForCities(date) {
  return async (dispatch) => {
    const res = await pmaUtil.getSalePriceForCities(date);

    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_SALE_PRICE_FOR_CITIES,
        payload: res.data,
      });
    }
  };
}

function getAgentAdvantages(listgenUserID) {
  return async (dispatch) => {
    const res = await pmaUtil.getAgentAdvantages(listgenUserID);

    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_AGENT_ADVANTAGES,
        payload: res.data,
      });
    }
  };
}

function getAgentDesignations(listgenUserID) {
  //console.log('inside actions agentDesignations', listgenUserID);
  return async (dispatch) => {
    const res = await pmaUtil.getAgentDesignations(listgenUserID);
    //console.log('the res of pma.actions agentDesignations', res);
    if (res && res.status === 200) {
      //console.log('response from actions agentDesignations', res.data);
      dispatch({
        type: pmaActionConstants.GET_AGENT_DESIGNATIONS,
        payload: res.data,
      });
    }
  };
}

function getTestimonials(listgenUserID) {
  // console.log('inside actions testimonials', listgenUserID);
  return async (dispatch) => {
    const res = await pmaUtil.getTestimonials(listgenUserID);
    //console.log('the res of pma.actions testimonials', res);
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_TESTIMONIALS,
        payload: res.data,
      });
    }
  };
}

function getPMACoverPhotoDetails(cityID) {
  // console.log('inside actions', cityID);
  return async (dispatch) => {
    const res = await pmaUtil.getPMACoverPhotoDetails(cityID);
    // console.log('the res of pma.actions getcover photo details', res);
    if (res && res.status === 200) {
      // console.log('response from actions getcover photo details', res.data);
      dispatch({
        type: pmaActionConstants.GET_PMA_COVER_PHOTO_DETAILS,
        payload: res.data,
      });
    }
  };
}

function getQuarterAreaTable(areaAndDate, clear) {
  return async (dispatch) => {
    const res = await pmaUtil.getQuarterAreaTable(areaAndDate);
    //console.log('the res of pma.actions getQuarterAreaTable', res);
    if (res && res.status === 200) {
      //console.log('response from actions getQuarterAreaTable', res.data);
      dispatch({
        type: pmaActionConstants.GET_QUARTER_AREA_TABLE,
        payload: res.data,
        clear,
      });
    }
  };
}

function getLocalSellingAgents(areaAndDate) {
  return async (dispatch) => {
    const res = await pmaUtil.getLocalSellingAgents(areaAndDate);

    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_LOCAL_SELLING_AGENTS,
        payload: res.data,
      });
    }
  };
}

function getQuarterCityTable(cityAndDate, clear) {
  return async (dispatch) => {
    const res = await pmaUtil.getQuarterCityTable(cityAndDate);
    //console.log('the res of pma.actions getQuarterCityTable', res);
    if (res && res.status === 200) {
      //console.log('response from actions getQuarterCityTable', res.data);
      dispatch({
        type: pmaActionConstants.GET_QUARTER_CITY_TABLE,
        payload: res.data,
        clear,
      });
    }
  };
}

function changeLoading(status) {
  return {
    type: pmaActionConstants.IS_LOADING,
    payload: {
      status,
    },
  };
}
function switchVersion(version) {
  console.log("pma actions version", version, typeof version);
  return {
    type: pmaActionConstants.SWITCH_VERSION,
    payload: {
      version,
    },
  };
}

function switchStep(step) {
  return {
    type: pmaActionConstants.SWITCH_STEP,
    payload: {
      step,
    },
  };
}

function switchArea(area, isLoading = true) {
  //  console.log("area going to update===", area);
  return {
    type: pmaActionConstants.SWITCH_AREA,
    payload: {
      area,
      isLoading,
    },
  };
}

function switchAreaWithStep(area, step) {
  return {
    type: pmaActionConstants.SWITCH_AREA_WITH_STEP,
    payload: {
      step,
      area,
    },
  };
}

function toggleListingCheck(mlsNumber) {
  //console.log('pmaActions toggling listing check address', mlsNumber);
  return {
    type: pmaActionConstants.TOGGLE_LISTING_CHECK,
    payload: {
      mlsNumber,
    },
  };
}

function setTags(tags) {
  return {
    type: pmaActionConstants.SET_TAGS,
    payload: {
      tags,
    },
  };
}

function setCurrentRecord(record) {
  return {
    type: pmaActionConstants.SET_CURRENT_RECORD,
    payload: {
      record,
    },
  };
}

function setBlockedReason(reason) {
  return {
    type: pmaActionConstants.SET_BLOCKED_REASON,
    payload: {
      reason,
    },
  };
}
function updateHOSearch(searchTerm) {
  // console.log('updating HOSearch', searchTerm);
  return {
    type: pmaActionConstants.UPDATE_HO_SEARCH,
    payload: {
      searchTerm,
    },
  };
}

function updateHOQuickFilter(quickFilter) {
  // console.log('updating HOSearch', searchTerm);
  return {
    type: pmaActionConstants.UPDATE_HO_QUICK_FILTER,
    payload: {
      quickFilter,
    },
  };
}

function updateHOFilter(filterStatus) {
  // console.log('updating HOSearch', searchTerm);
  return {
    type: pmaActionConstants.UPDATE_HO_FILTER,
    payload: {
      filterStatus,
    },
  };
}

function updateHOAdvanceFilter(filterStatus) {
  return {
    type: pmaActionConstants.UPDATE_HO_ADVANCE_FILTER,
    payload: {
      advanceFilter: filterStatus,
    },
  };
}

function updateHOAdvanceFilterOptionData(dataJson) {
  return {
    type: pmaActionConstants.UPDATE_HO_ADVANCE_FILTER_OPTION_DATA,
    payload: {
      advanceFilterOptionData: dataJson,
    },
  };
}

function resetMailproCurrentHeaderSort() {
  return {
    type: pmaActionConstants.RESET_MAILPRO_HEADER_SORT_ONLY,
    payload: {},
  };
}

//FOR ALL SORTING FROM THE TABLE HEADERS CLICK EVENTS
function mailProHeaderSort(propertyData, mailProHeader, mailProSortDirection) {
  return {
    type: pmaActionConstants.TABLE_SORT,
    payload: {
      propertyData,
      mailProHeader,
      mailProSortDirection,
    },
  };
}

//FOR ALL SORTING FROM THE TABLE HEADERS CLICK EVENTS

function resetProMailHeaderandAllFilters() {
  return {
    type: pmaActionConstants.RESET_MAILPRO_HEADER_AND_ALL_FILTERS,
    payload: {},
  };
}

function approveMailingList(approveObj) {
  return async (dispatch) => {
    const res = await pmaUtil.approveMailingList(approveObj);
    //('the res of pma.actions approveMailingList', res);
    if (res && res.status === 200) {
      //console.log('response from actions approveMailignList', res);
      dispatch({
        type: pmaActionConstants.APPROVE_MAILING_LIST,
        payload: {
          // countyID,
          // index,
        },
      });
    }
  };

  //console.log('approving mailing list', time);
  // return {
  //   type: pmaActionConstants.APPROVE_MAILING_LIST,
  //   payload: {
  //     time,
  //   },
  // };
}

function postHomeowners(homeownersData) {
  //console.log('action postHomeowners', homeownersData);
  return {
    type: pmaActionConstants.POST_HOMEOWNERS_DATA,
    payload: {
      homeownersData,
    },
  };
}

function getMailProMailerData(areaId, agentData, nextPMAPrintDate) {
  return async (dispatch) => {
    const res = await pmaUtil.getMailerProDetails(areaId);
    if (res && res.status === 200) {
      //const tempData = [res.data[0], res.data[1]]
      dispatch({
        type: pmaActionConstants.GET_MAILER_PRO_DETAILS_SUCCESS,
        payload: {
          propertyData: res.data, //tempData,
          agentData: agentData,
          defaultSort: true, //by default sort by homeValuation
          nextPMAPrintDate: nextPMAPrintDate, // we dont require at any other place as it will be updated by the time we will use it at other places but not in starting
          pmaMailerDataLoaded: false, // Both mailpro and pma will call mailer data api separately so all data loaded for PMA will be handled by this.. if we are only loading MailPro mailer data then send this as false
        },
      });
    } else {
      dispatch({
        type: pmaActionConstants.GET_MAILER_PRO_DETAILS_FAILURE,
        payload: {
          errorCode: null,

          pmaMailerDataLoaded: false,
        },
      });
    }
  };
}

function getAgentQR(
  dreNum,
  areaID,
  switchingStep,
  areaAndCity,
  subdivision,
  listgenUserID
) {
  return async (dispatch) => {
    let splitLicense = dreNum.split("/")[0].trim();
    const res = await pmaUtil.getAgentQR(splitLicense, areaID);

    //console.log('the res of pma.actions getQuarterAreaTable', res);
    if (res && res.status === 200) {
      if (switchingStep) {
        console.log("swiching step");
        const getPmaDetails = await dispatch(
          getPmaDetailsApi(areaAndCity, subdivision, listgenUserID, res.data)
        );
      }
      dispatch({
        type: pmaActionConstants.GET_AGENT_QR,
        payload: res.data,
      });
    } else {
      console.log("***ERROR");
    }
  };
}
function getMailerProDetails(
  areaId,
  cityAndDate,
  agentData,
  forEditor,
  version,
  typeOfMQVersion,
  typeOfQVersion,
  listgenUserID,
  typeOfYEVersion,
  isDataVerification,
  specialHO,
  switchingStep
) {
  // console.log("isDataVerification", isDataVerification);
  // console.log("specialHO", specialHO);
  // console.log(
  //   "getMailerProDetails",
  //   areaId,
  //   cityAndDate,
  //   agentData,
  //   forEditor,
  //   version,
  //   typeOfMQVersion,
  //   typeOfQVersion,
  //   listgenUserID,
  //   typeOfYEVersion,
  //   isDataVerification,
  //   specialHO,
  //   switchingStep
  // );

  return async (dispatch) => {
    let date = new Date();
    let thisYear = date.getFullYear();
    const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
    const today = `${date.getDate()}`.padStart(2, 0);
    let areaAndDate = `${areaId}-${thisYear}-${thisMonth}-${today}`;
    const res = await pmaUtil.getMailerProDetails(areaId);
    //console.log("res is", res);
    let modifiedAreaAndDate = `${areaId}-${cityAndDate}`;
    const areaAndCity = `${areaId}-${cityAndDate}`;
    if (res && res.status === 200) {
      // const mailerProID = specialHO || res.data[1];
      let mailerProID = res.data[8].mailerProID;
      if (areaId === "SBA-15I") {
        mailerProID = "8a20e582-e4dc-4f74-98db-48ef769e973c";
      }
      if (areaId === "SCL-234B") {
        mailerProID = "468887f7-7c4a-44ad-a320-a8fb5eb21614";
      }
      // const mailerProID =
      //   specialHO === "Del Mesa" ? res.data[1] : res.data[3].mailerProID;

      // const mailerProID = res.data.slice(-1);
      let firstHO = res.data[8];

      if (
        specialHO &&
        (specialHO === "delmesa" || "hacienda" || "Del Mesa/Hacienda")
      ) {
        let findSpecialSubdivision = res.data.filter((property) => {
          let formattedSub =
            property.subdivisionName &&
            property.subdivisionName.toLowerCase().split(" ").join("");

          return formattedSub === specialHO;
        })[1];

        firstHO = findSpecialSubdivision;
        mailerProID = findSpecialSubdivision.mailerProID;
      }
      // let firstHO = specialHO === "Del Mesa" ? res.data[1] : res.data[3];
      let subdivision =
        firstHO && firstHO.subdivisionName && firstHO.subdivisionName.length > 1
          ? firstHO.subdivisionName
          : "-";
      //Septembers api ye2
      if (isDataVerification == "useData") {
        console.log("isDataVerification", isDataVerification);
        const gettingDecemberData = await dispatch(
          getDecemberData(
            modifiedAreaAndDate,
            subdivision,
            listgenUserID,
            mailerProID
          )
        );
      } else if (areaId === "DEMO-SCL-16B") {
        let changeTheVersion = await switchVersion("ye2");
        const gettingDecemberData = await dispatch(
          getDecemberData(
            modifiedAreaAndDate,
            subdivision,
            listgenUserID,
            mailerProID
          )
        );
      } else if (version === "bubble-compare") {
        // let getBubblePriceRangeApi = await dispatch(
        //   getBubblePriceRange(
        //     areaAndCity,
        //     subdivision,
        //     listgenUserID,
        //     mailerProID
        //   )
        // );
        //  console.log("inside bubble compare");
        if (switchingStep) {
          //  console.log("inside actions about to switch step");
          const gettingAgentQR = await dispatch(
            getAgentQR(
              agentData.agentLicenseNumber,
              areaId,
              switchingStep,
              areaAndCity,
              subdivision,
              listgenUserID
            )
          );
        } else {
          const getPmaDetails = await dispatch(
            getPmaDetailsApi(
              areaAndCity,
              subdivision,
              listgenUserID,
              mailerProID
            )
          );
        }

        //
      } else if (version === "ye2" || version === "table-quadrants") {
        let dashBoardDonutApi = await dispatch(
          getDonutAndTable(areaAndCity, subdivision, listgenUserID, mailerProID)
        );
      } else if (version === "mq3") {
        let dashBoardDonutApi = await dispatch(
          getDonutAndTable(areaAndCity, subdivision, listgenUserID, mailerProID)
        );
      } else if (version === "qv4") {
        const getPmaDetails = await dispatch(
          getPmaDetailsApi(areaAndCity, subdivision, listgenUserID, mailerProID)
        );

        // const gettingQuadrantDetails = await dispatch(
        //   getQuadrantDetails(listgenUserID)
        // );
      } else if (version === "mq2") {
        const gettingDecemberData = await dispatch(
          getTableBubbleData(
            areaAndCity,
            subdivision,
            listgenUserID,
            mailerProID
          )
        );
      } else if (
        (version === "ye" && typeOfYEVersion === "ye2") ||
        version === "qv2"
      ) {
        const getPmaDetails = await dispatch(
          getPmaDetailsApi(areaAndCity, subdivision, listgenUserID, mailerProID)
        );

        // const gettingRecentActivityPhotos = await dispatch(
        //   getRecentActivityPhotos(areaId)
        // );
        // const gettingDashboardApi = await dispatch(
        //   getDashboardDonutApi(
        //     areaAndCity,
        //     subdivision,
        //     listgenUserID,
        //     mailerProID
        //   )
        // );

        // const getBubbleRange = await dispatch(
        //   getBubblePriceRange(
        //     areaAndCity,
        //     subdivision,
        //     listgenUserID,
        //     mailerProID
        //   )
        // );
      } else if (version === "year-end-2022") {
        //console.log("inside year end 2022");
        // const gettingMarketTrends = await dispatch(
        //   getMarketTrends(
        //     modifiedAreaAndDate,
        //     subdivision,
        //     listgenUserID,
        //     mailerProID,
        //     firstHO
        //   )
        // );
        const getPmaDetails = await dispatch(
          getPmaDetailsApi(areaAndCity, subdivision, listgenUserID, mailerProID)
        );
        // const gettingPropertyPhotoDetails = await dispatch(
        //   getPropertyPhotoDetails(listgenUserID, areaId)
        // );
        // let dashBoardDonutApi = await dispatch(
        //   getDonutAndTable(areaAndCity, subdivision, listgenUserID, mailerProID)
        // );
      }

      dispatch({
        type: pmaActionConstants.GET_MAILER_PRO_DETAILS_SUCCESS,
        payload: {
          propertyData: res.data,
          agentData: agentData,
          defaultSort: true, //by default sort by homeValuation
          nextPMAPrintDate: null,
          pmaMailerDataLoaded: true, // Both mailpro and pma will call mailer data api separately so all data loaded for PMA will be handled by this
        },
      });
    } else {
      dispatch({
        type: pmaActionConstants.GET_MAILER_PRO_DETAILS_FAILURE,
        payload: {
          errorCode: null,
          pmaMailerDataLoaded: false,
        },
      });
    }
  };
}

function updateCompleteMailerProDataAsync(propertyData, agentData = {}) {
  /**
   *
   * ONLY CALL THIS FUNCTION WHEN WE HAVE TO UPDATE ENTIRE MAILPRO DATA. USE CASE CAN WHEN WE NEED TO UPDATE FILTER / ADVANCE / HO QUICK FILTER DATA ETC.
   * REMEMBER THIS FUNCTION WILL UPDATE ENTIRE MAILPRO DATA ARRAY SO IT WILL RESET SEQUENCE OF HOMEOWNER DATA (ROW SEQUENCE IN MAILPRO) AS WELL.
   */
  return async (dispatch) => {
    dispatch({
      type: pmaActionConstants.GET_MAILER_PRO_DETAILS_SUCCESS,
      payload: {
        propertyData: propertyData,
        agentData: agentData,
        defaultSort: false,
        nextPMAPrintDate: null,
      },
    });
  };
}

function updateRecentUpdatedMailProId(recentUpdatedMailProId) {
  return {
    type: pmaActionConstants.UPDATE_RECENT_UPDATED_MAILPRO_ID,
    payload: recentUpdatedMailProId,
  };
}

function updateHomeownerProp(hoData) {
  const { mailerProID, changeDetails } = hoData;
  //update the modified and ais agen tinfo in hoData
  hoData.isFirstNameModified = hoData.isFirstNameModified
    ? hoData.isFirstNameModified
    : false;
  hoData.isAgent = hoData.isAgent ? hoData.isAgent : false;
  //execute the API now.
  return async (dispatch) => {
    const res = await pmaUtil.updateHomeownerProp(hoData);
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.UPDATE_NAVBAR,
        payload: res.data,
      });
      dispatch({
        type: pmaActionConstants.UPDATE_HOMEOWNER_PROP,
        payload: {
          hoData,
          mailerProID,
          changeDetails,
        },
      });
    }
  };
}

function updateQuickPersonalNotePS(
  hoData,
  mode,
  resetMode,
  onSuccessCallbackFn = () => {}
) {
  //console.log('pma.actions getting mailer pro details', hoData);
  const { mailerProID, changeDetails } = hoData;
  //execute the API now.
  return async (dispatch) => {
    const res = await pmaUtil.updateQuickPersonalNotePS(
      hoData,
      mode,
      resetMode
    );
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.UPDATE_HOMEOWNER_PROP,
        payload: {
          hoData,
          mailerProID,
          changeDetails,
        },
      });
      //check onSuccessCallbackFn is async than handle accoridngly. code, if (onSuccessCallbackFn.constructor.name === 'AsyncFunction') {

      onSuccessCallbackFn && onSuccessCallbackFn();
    }
  };
}

function getCityAreaMap(countyID, index) {
  // console.log('***actions getting city area map', countyID);
  return async (dispatch) => {
    const res = await pmaUtil.getCityAreaMap(countyID);
    //console.log('the res of pma.actions getCityAreaMap', res);
    if (res && res.status === 200) {
      //console.log('response from actions getCityAreaMap', res);
      dispatch({
        type: pmaActionConstants.GET_CITY_AREA_MAP,
        payload: {
          countyID,
          index,
        },
      });
    }
  };
}

function getMailProIDDetails(mailProId) {
  // console.log('**actions getting mail pro id details', mailProId);
  return async (dispatch) => {
    const res = await pmaUtil.getMailProIDDetails(mailProId);
    //console.log('the res of pma.actions getMailProId', res);
    if (res && res.status === 200) {
      // console.log('response from actions getMailProIDDetails', res);
      dispatch({
        type: pmaActionConstants.GET_MAILPRO_ID_DETAILS,
        payload: res.data,
      });
    }
  };
}

function getMarketActivity(areaAndDate, clear) {
  return async (dispatch) => {
    const res = await pmaUtil.getMarketActivity(areaAndDate);
    // console.log('the res of pma.actions getMarketActivity', res);
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_MARKET_ACTIVITY,
        payload: res.data,
        clear,
      });
    }
  };
}

function getNavbarInfo(listgenUserID, navbarData) {
  return async (dispatch) => {
    const res = await pmaUtil.getNavbarInfo(listgenUserID);
    //  console.log("the res of pma.actions getNavbarInfo", res);
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_NAVBAR_INFO,
        payload: res.data,
      });
    }
  };
}

function updateNavbar(mlsAreas) {
  //console.log('**action creator Navbar mls areas', mlsAreas);
  return async (dispatch) => {
    const res = await pmaUtil.updateNavbar(mlsAreas);
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.UPDATE_NAVBAR,
        payload: Array.isArray(res.data) ? res.data : mlsAreas,
      });
    }
  };
}

function updateCurrentAreaData(currentArea) {
  return async (dispatch) => {
    dispatch({
      type: pmaActionConstants.UPDATE_CURRENT_AREA_DATA,
      payload: currentArea,
    });
  };
}

function getMQCityDetails(cityAndDate) {
  return async (dispatch) => {
    const res = await pmaUtil.getMQCityDetails(cityAndDate);
    //console.log('the res of pma.actions cityAndDate', res);
    if (res && res.status === 200) {
      dispatch({
        type: pmaActionConstants.GET_MQ_CITY_DETAILS,
        payload: res.data,
      });
    }
  };
}

function changeMailProStatus(listgenUserID, mailProStatus) {
  return async (dispatch) => {
    const res = await pmaUtil.changeMailProStatus(listgenUserID, mailProStatus);
    //console.log('the res of pma.actions change mail pro status', res);
    if (res && res.status === 200) {
      //console.log('response from actions changeMail Pro Status', res);
      dispatch({
        type: pmaActionConstants.CHANGE_MAILPRO_STATUS,
        payload: res.data,
      });
    }
  };
}

function getNextPMAPrintDate(agentData) {
  return async (dispatch) => {
    const res = await getPMAHoNextPrintDateForAAgentFromSchedule(agentData);
    if (res) {
      //console.log('response from actions changeMail Pro Status', res);
      dispatch({
        type: pmaActionConstants.GET_NEXT_PMA_PRINT_DATE,
        payload: res,
      });
    }
  };
}

function updateEditedNewMailingData(data) {
  return {
    type: pmaActionConstants.NEW_MAILING_ADD_UPDATED_DATA,
    payload: data,
  };
}

function setNewMailProInitDialogModeFn(data) {
  return {
    type: pmaActionConstants.SET_NEW_MAILPRO_INIT_DIALOG_MODE,
    payload: data,
  };
}

function disableNewMailingForCurrentSessionFn(data) {
  return {
    type: pmaActionConstants.DISABLE_NEW_MAILING_FOR_CURRENT_SESSION,
    payload: data,
  };
}

function explicitEnableNewMailProInitFn(data) {
  return {
    type: pmaActionConstants.EXPLICIT_ENABLE_NEW_MAILPRO_INIT_SCREEN,
    payload: data,
  };
}

function updateNewMailingMailProData(data) {
  return {
    type: pmaActionConstants.NEW_MAILING_MAILPRO_DATA,
    payload: data,
  };
}

function resetNewMailProInitDialogFn() {
  return {
    type: pmaActionConstants.RESET_NEW_MAILPRO_INIT_DIALOG_DATA,
    payload: null,
  };
}
