import React, { Component } from "react";

import QVThreeFirstLeft from "./QVThreeFirstLeft";
import QVThreeFirstRight from "./QVThreeFirstRight";
import { connect } from "react-redux";
import CustomDeborahSamuelPage from "views/Pma/PmaEditor/CustomAgentPages/CustomDeborahSamuelPage";

class QVThreeFirstPage extends Component {
  render() {
    const {
      auth,
      agentData,
      hoData,
      currentArea,
      mlsAreas,
      navbarData,
      printVersion,
      version,
      viewingGeneratedCL,
      coverPageDetails,
      qArea,
      qCity,
      quarterlyAreaTable,
      salePriceForCities,
      quarterlyCityTable,
      marketActivity,
      MQCityDetails,
      aprilData,
      flipBook,
      demoAccountHOName,
      demoAccountCoverPhoto,
      demoAccountAreaName,
      testimonials,
      typeOfVersion,
      handlePropertyHighlightDialog,
      bubbleBoxes,
    } = this.props;
    const { listgenUserID } = agentData;
    console.log("inside qv3 first page");
    //console.log(this.props.hoData.changeDetails);

    return (
      <div
        className={
          "pma-page-container " +
          (printVersion
            ? "qv3-print-page-container"
            : "qv3-pma-editor-page-container")
        }
      >
        {listgenUserID === "100585" ? (
          <CustomDeborahSamuelPage
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version="qv3"
            demoAccountHOName={demoAccountHOName}
            coverPageDetails={coverPageDetails}
          />
        ) : (
          <QVThreeFirstLeft
            auth={auth}
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version={version}
            aprilData={aprilData}
            demoAccountHOName={demoAccountHOName}
            demoAccountAreaName={demoAccountAreaName}
            coverPageDetails={coverPageDetails}
            testimonials={testimonials}
            bubbleBoxes={bubbleBoxes}
          />
        )}

        <QVThreeFirstRight
          auth={auth}
          agentData={agentData}
          hoData={hoData}
          navbarData={navbarData}
          printVersion={printVersion}
          version={version}
          currentArea={currentArea}
          mlsAreas={mlsAreas}
          viewingGeneratedCL={viewingGeneratedCL}
          coverPageDetails={coverPageDetails}
          aprilData={aprilData}
          demoAccountCoverPhoto={demoAccountCoverPhoto}
          demoAccountAreaName={demoAccountAreaName}
          typeOfVersion={typeOfVersion}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(QVThreeFirstPage);
