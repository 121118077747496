import React from 'react';
import styles from "./styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import { Checkbox } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const AllGroupList = (props) => {
    const { classes, handleChange, selectedValues =  new Set() } = props;

     //const CheckboxMain  = <Checkbox color='primary' checked={true} onChange={handleChange} name="Oaklane Condos (13)" /> uild 
     //convert CheckboxMain with withStyles
        //const CheckboxMainWithStyles = withStyles(styles)(CheckboxMain);


    return <div className={classes.addHoToGroupBoxMain}>
        <div className={classes.addHoToGroupBox}>
            <FormControl component="fieldset" className={classes.addHoToGroupFieldset}>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={true} onChange={handleChange} name="Oaklane Condos (13)" />}
                        label="Oaklane Condos (13)"
                        className={classes.checkboxLabel}
                        color="primary"
                        classes={{label: classes.objectiveMk2 }}
                    />
                    <FormControlLabel
                        control={<Checkbox color='primary' checked={false} onChange={handleChange} name="Riverwood Homes (9)" />}
                        label="Riverwood Homes (9)"
                        className={classes.checkboxLabel}
                        color="primary"
                        classes={{label: classes.objectiveMk2 }}
                    />
                    <FormControlLabel
                        control={<Checkbox color='primary' checked={true} onChange={handleChange} name="Point Lobos Homes (2)" />}
                        label="Point Lobos Homes (2)"
                        className={classes.checkboxLabel}
                        color="primary"
                        classes={{label: classes.objectiveMk2 }}
                    />
                </FormGroup>
            </FormControl>
        </div>
    </div>
}

export default compose(withStyles(styles), withSnackbar)(AllGroupList);