import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import moment from "moment";
import PhotoModal from "./PhotoModal";
import TestPhotoModal from "./TestPhotoModal";
import TestPhoto2 from "./TestPhoto2";
import PhotoErrorModal from "./PhotoErrorModal";
import { connect } from "react-redux";
import { pmaActions } from "_store/_actions";
// import { photoCheckboxModalOpen } from "_store/_actions/pma.actions";

class QVFourPhotos extends Component {
  state = {
    oldPropData: {
      address: "",
      bedBaths: "",
      brokerageName: "",
      cityName: "",
      daysOnMarket: "",
      displayOrder: "",
      isBuyingAgent: false,
      isSelected: false,
      isSellingAgent: false,
      listDate: "",
      listPrice: "",
      listingAgentLicenseID: "",
      listingAgentName: "",
      lotSizeSquareFt: "",
      photoURL: "",
      saleDate: "",
      salePrice: "",
      sellingAgentLicenseID: "",
      sellingAgentName: "",
      squareFeet: "",
      status: "",
      streetDirPrefix: "-",
      streetName: "",
      streetNumber: "",
      streetSuffix: "",
      unitNumber: "",
      view: "",
      justAdded: false,
    },
    editPropFormData: {},
    // photoModalOpen: true,
    photoDetails: [],
    oldPhotoDetails: [],
    editPropFormData: [],
    errorModalText: "",
    showErrorModal: false,

    tempList: [],
    tempProp: [],
    showAddPropDialog: false,
    showEditPropDialog: false,
    openUploadImageDialog: false,
    uploadCurrentImage: "",
    editDialog: false,
  };
  componentDidMount = () => {
    const { recentActivityPhotoList } = this.props;

    let data = recentActivityPhotoList;

    let transformed;
    let currentFiltered0 = recentActivityPhotoList.photoDetails.filter(
      (listing) => (listing.isSystemSelected = true)
    );
    let updatedCurrent = [];
    if (
      currentFiltered0.length === recentActivityPhotoList.photoDetails.length
    ) {
      let updateList = currentFiltered0.map((prop, index) => {
        if (index >= 6) {
          prop.isSystemSelected = false;
        } else {
          prop.isSystemSelected = true;
        }
      });

      // updatedCurrent = updateList;
    }

    // let currentFiltered = currentFiltered0.forEach(
    //   (prop) => (prop.isSystemSelected = true)
    // );
    // console.log("currentFiltered", currentFiltered);

    // const newTab = tab.map((item) => ({
    //   ...item,
    //   original: {
    //     ...item.original,
    //     isActive: item.original.isActive ? "on" : "off",
    //   },
    // }));

    // const currentFiltered = currentFiltered0.map((item) => ({
    //   ...item,
    //   isSystemSelected: true,
    // }));

    // const editFiltered = (currentFiltered0) => {
    //   return currentFiltered0.map((propItem) => {
    //     var temp = Object.assign({}, propItem);
    //
    //     temp.isSystemSelected = true;
    //
    //     return temp;
    //   });
    // };
    //  console.log("currentFiltered", currentFiltered);

    // if (currentFiltered.length === 0) {
    //   transformed = recentActivityPhotoList.map((listing, i) => {
    //     if (i <= 5) {
    //       listing.isSystemSelected = true;
    //       listing.isSystemSelected = true;
    //     }
    //     return listing;
    //   });
    // }
    // console.log("***inside componentDidMOunt", transformed);
    //let isSame = this.props.oldPhotoDetails === recentActivityPhotoList.photoDetials;
    // console.log("isSame", isSame);
    //  console.log("transformed", transformed);
    //  console.log("***current filtered", currentFiltered);
    this.setState({
      // photoDetails: [...recentActivityPhotoList],
      photoDetails: [...currentFiltered0],
      // oldPhotoDetails: [...transformed.slice()],
      //photoDetails: recentActivityPhotoList,
      errorModalText: "",
    });
  };
  getPhotoData = () => {};
  handleErrorModal = (type) => {
    this.setState({
      showErrorModal: false,
      errorModalText: "",
    });
  };
  updatePhotoDetails = (details) => {
    console.log("inside update   updatePhotoDetails", details);
    this.setState({
      photoDetails: details,
    });
  };
  keepOrRemoveProperty = (keepProperty) => {
    const { tempList, tempProp, photoDetails } = this.state;
    // console.log("***list is inside prop func", list);
    console.log("keepProperty", keepProperty);
    console.log("tempList", tempList);
    console.log("tempProp", tempProp);
    if (keepProperty) {
      this.handlePropCheck(null, tempProp, true, true, true);
      // return tempProp;
    } else {
      this.handlePropCheck(null, tempProp, true, true, false);
      //return Object.assign(tempList, { checked: false });
    }
  };
  handlePropCheck = (e, list, hasBanner, fromErrorModal, keepProp) => {
    const { photoDetails } = this.state;
    console.log("****photoDetails", photoDetails);

    console.log("list is", list);
    // list.isSystemSelected = true;
    //  console.log("list is", list);
    // console.log("keepProp", keepProp);
    // console.log("**has banner", hasBanner);
    // console.log("fromErrorModal", fromErrorModal);
    // console.log("inside list", list);

    // console.log(
    //   "***BEFORE updatedState",
    //   this.state.photoDetails[0].isSystemSelected
    // );
    // console.log(
    //   "***BEFORE updatedProps",
    //   this.props.oldListings[0].isSystemSelected
    // );
    // console.log(
    //   "****BEFORE soldListings",
    //   this.props.recentActivityPhotoList[0].isSystemSelected
    // );
    const updateCheck = photoDetails.map((prop) => {
      console.log("***prop is", prop);
      if (
        prop.streetName === list.streetName &&
        prop.streetNumber === list.streetNumber &&
        prop.listDate === list.listDate
      ) {
        console.log("****same prop");
        //  console.log("**list is", list);
        //console.log("actual list", list.isSystemSelected, "opposite", !list.isSystemSelected);
        let preCheck = photoDetails.filter((prop) => prop.isSystemSelected)
          .length;
        console.log("preCheck", preCheck);
        if (list.isSystemSelected && fromErrorModal && keepProp) {
          console.log("keep prop");
          this.setState({ errorModalText: "", showErrorModal: false });
          return prop;
        } else if (list.isSystemSelected && fromErrorModal && !keepProp) {
          console.log("dont keep prop);");
          this.setState({ errorModalText: "", showErrorModal: false });

          const updatedCheck = {
            isSelected: false,
          };
          console.log("***updated check is", updatedCheck);
          return Object.assign(prop, updatedCheck);
        } else if (list.isSystemSelected && hasBanner) {
          console.log("checked and banner");
          this.setState({
            errorModalText: "Banner Property",
            showErrorModal: true,
            tempList: list,
            tempProp: prop,
          });
          // this.keepOrRemoveProperty(list);
          //return Object.assign(prop, { checked: false });
          return prop;
        } else if (preCheck >= 6 && !list.isSystemSelected) {
          console.log("inside preCheck", this.isSystemSelected);
          this.setState({
            errorModalText: "Max properties",
            showErrorModal: true,
          });
          return Object.assign(prop, { checked: false });
        } else {
          const updatedCheck = {
            isSelected:
              list.isSystemSelected && list.isSystemSelected == true
                ? false
                : true,
          };
          console.log("***updated check is", updatedCheck);
          return Object.assign(prop, updatedCheck);
          // return prop;
        }
      } else {
        console.log("return plain check", prop);
        return prop;
      }
    });

    console.log("****after update check", updateCheck);
    let checkedCount = updateCheck.filter((prop) => prop.isSystemSelected)
      .length;
    console.log("checkedCount", checkedCount);
    // console.log("***updatedState", this.state.photoDetails[0].isSystemSelected);
    // console.log("***updatedProps", this.props.oldListings[0].isSystemSelected);
    console.log(
      "****updated soldListings",
      this.props.recentActivityPhotoList[0].isSystemSelected
    );
    if (checkedCount >= 7) {
      console.log("*****greater than 7");
      this.setState({
        errorModalText: "Max properties",
        showErrorModal: true,
      });
    } else {
      this.setState({
        photoDetails: updateCheck,
      });
    }
  };
  openPhotoModal = () => {
    this.props.updateCheckboxPhotoModalOpen(true);
    // this.setState({
    //   photoModalOpen: true,
    // });
  };
  onEditFormSubmit = () => {
    const {
      photoDetails,
      editPropFormData,
      showErrorModal,
      errorModalText,
    } = this.state;
    const changeProperty = photoDetails.map((prop) =>
      prop.streetName === editPropFormData.streetName &&
      prop.streetNumber === editPropFormData.streetNumber &&
      prop.listDate === editPropFormData.listDate
        ? editPropFormData
        : prop
    );
  };

  getMonthAndYearString = (inputTime) => {
    try {
      let month = moment(inputTime).format("M");
      let day = moment(inputTime).format("D");
      let year = moment(inputTime).format("YY");
      return (
        <p>
          {month}&#8729;
          {day}&#8729;
          {year}
        </p>
      );
    } catch (err) {
      return "";
    }
  };

  shortenPresentedBy = (name, brokerageRaw, printVersion) => {
    const { mlsCityID } = this.props.currentArea;
    let isSB = false;
    if (mlsCityID === "5060" || mlsCityID === "5063" || mlsCityID === "5056") {
      isSB = true;
    }

    let max;
    if (printVersion) {
      max = 47;
    } else {
      max = 47;
    }
    let brokerage = brokerageRaw;
    if (brokerageRaw === "Village Properties - 1") {
      brokerage = "Village Properties";
    }

    const presented = "Presented by "; //20 w/ space
    let presentedCount = presented.length + 1; //adding space
    let brokerageCount = brokerage.split("").length + 1;
    let credit = "Source: MLSListings";

    let allButBrokerageCount = presentedCount + credit.length;
    let leftover = max - allButBrokerageCount;
    let cutoffBrokerage = brokerageCount >= leftover;

    if (brokerage.length < 2) {
      return <p className="presented">{credit}</p>;
    } else if (brokerage.length >= max) {
      return (
        <p className="presented">
          {presented} {brokerage.slice(0, max - 3)}
          ...
          <span className="credit-ml"></span>
          {isSB ? <span>Source: Flexmls &copy;</span> : credit}
        </p>
      );
    } else {
      return (
        <p className="presented">
          {presented} {brokerage} <span className="credit-ml"></span>
          {isSB ? <span>Source: Flexmls &copy;</span> : credit}
        </p>
      );
    }
  };
  updateChanges = () => {
    //add stuff here
    console.log("updating changes");
  };
  closePhotoModal = () => {
    this.props.updateCheckboxPhotoModalOpen(false);

    // this.setState({
    //   photoModalOpen: false,
    // });
  };
  dateSort = (propertyList) => {
    let sorted = propertyList.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.listDate) - new Date(a.listDate);
    });
    return propertyList;
  };
  openUploadMedia = (key, currentImage) => {
    // console.log("inside upload media");
    this.setState({
      openUploadImageDialog: true,
      uploadCurrentImage: currentImage,
      uploadImageKey: key,
    });
  };
  handleRepresentedOptions = () => {
    console.log("handle represented");
    const { editPropFormData } = this.state;
    const { agentData } = this.props;
    const { isMemberOfTeam, agentLicenseNumber, teamMembers } = agentData;
    const teamMember1License =
      isMemberOfTeam && teamMembers[0].agentLicenseNumber;
    const teamMember2License =
      isMemberOfTeam && teamMembers[1].agentLicenseNumber;
    let isListingAgent = false;
    // console.log("inside represented edit", editPropFormData);

    if (
      (!isMemberOfTeam &&
        agentLicenseNumber === editPropFormData.listingAgentLicenseID) ||
      (isMemberOfTeam &&
        (teamMember1License === editPropFormData.listingAgentLicenseID ||
          teamMember2License === editPropFormData.listingAgentLicenseID)) ||
      (editPropFormData.isCoListingAgent &&
        editPropFormData.isCoListingAgent === "true") ||
      editPropFormData.coListingAgents
    ) {
      isListingAgent = true;
    }
    let isSellingAgent = false;
    if (
      (!isMemberOfTeam &&
        agentLicenseNumber === editPropFormData.sellingAgentLicenseID) ||
      (isMemberOfTeam &&
        (teamMember1License === editPropFormData.sellingAgentLicenseID ||
          teamMember2License === editPropFormData.sellingAgentLicenseID)) ||
      (editPropFormData.isCoSellingAgent &&
        editPropFormData.isCoSellingAgent === "true")
    ) {
      isSellingAgent = true;
    }

    if (
      (editPropFormData.isBuyingAgent && editPropFormData.isSellingAgent) ||
      (isSellingAgent && isListingAgent)
    ) {
      this.setState((prevState) => ({
        editPropFormData: {
          ...prevState.editPropFormData,
          isSellingAgent: false,
          isBuyingAgent: true,
        },
      }));
    } else if (editPropFormData.isBuyingAgent || isListingAgent) {
      this.setState((prevState) => ({
        editPropFormData: {
          ...prevState.editPropFormData,
          isSellingAgent: true,
          isBuyingAgent: false,
        },
      }));
    } else if (editPropFormData.isSellingAgent || isSellingAgent) {
      this.setState((prevState) => ({
        editPropFormData: {
          ...prevState.editPropFormData,
          isSellingAgent: false,
          isBuyingAgent: false,
        },
      }));
    } else {
      console.log("here");
      this.setState((prevState) => ({
        editPropFormData: {
          ...prevState.editPropFormData,
          isSellingAgent: true,
          isBuyingAgent: true,
        },
      }));
    }
    console.log("after", editPropFormData);
  };
  handleFormClose = () => {
    console.log("inside handle form close");
    this.setState({
      showAddPropDialog: false,
      showEditPropDialog: false,
    });
  };

  handleAddPropDialog = () => {
    console.log("inside add prop dialog");
    const { photoDetails } = this.state;
    const emptyProp = {
      address: "",
      bedBaths: "",
      brokerageName: "",
      cityName: "",
      daysOnMarket: "",
      displayOrder: "",
      isBuyingAgent: false,
      isSelected: false,
      isSellingAgent: true,
      listDate: "",
      listPrice: "",
      listingAgentLicenseID: "",
      listingAgentName: "",
      lotSizeSquareFt: "",
      photoURL: "",
      saleDate: "",
      salePrice: "",
      sellingAgentLicenseID: "",
      sellingAgentName: "",
      squareFeet: "",
      status: "",
      streetDirPrefix: "-",
      streetName: "",
      streetNumber: "",
      streetSuffix: "",
      unitNumber: "",
      view: "",
      justAdded: false,
    };
    let selected =
      (photoDetails &&
        photoDetails.filter(
          (property) => property.isSystemSelected === true
        )) ||
      [];
    if (selected.length >= 6) {
      console.log("more than 6 selected");
      this.setState({
        errorModalText: "Max properties",
        showErrorModal: true,
      });
    } else {
      this.setState({
        showAddPropDialog: true,
        editPropFormData: emptyProp,
      });
    }
  };

  handleInputChange = (e) => {
    const target = e.target;
    let value = target.value;
    const name = target.name;
    console.log("before value", value);
    if (e.target.name === "salePrice") {
      // value = Number(value);
      // if (value.match(/^,\D/)) {
      //   console.log("*******has a non digit");
      // }
      value = value.replace(/\,/g, "");

      console.log("value is", value);
    }
    console.log("name is", name);
    console.log("target is", target);
    console.log("value is", value);
    this.setState((prevState) => ({
      editPropFormData: {
        ...prevState.editPropFormData,
        [name]: value,
      },
    }));
    console.log("editPropFormData", this.state.editPropFormData);
    console.log("oldPropData", this.state.oldPropData);
  };

  beginClosePhotoModal = () => {
    const { photoDetails, oldPhotoDetails } = this.state;
    const { recentActivityPhotoList, oldListings } = this.props;
    const { dateSort } = this;
    console.log("photoDetails", photoDetails);
    console.log("oldListings", oldListings);
    let hasChanges =
      JSON.stringify(oldListings) !== JSON.stringify(photoDetails);
    console.log("hasChanges", hasChanges);

    if (!hasChanges) {
      this.closePhotoModal();
    } else {
      this.closePhotoModal();
      //Do the Are you sure you want to exit?
    }
  };
  handleOpenEditDialog = (prop) => {
    console.log("prop", prop);
    const propObj = prop;
    const { editPropFormData, oldPropData } = this.state;
    console.log("propObj", prop);
    // console.log("editPropFormData", editPropFormData);
    // console.log("oldPropData", oldPropData);
    this.setState({
      oldPropData: propObj,
    });
    // this.setState((prevState) => ({
    //   oldPropData: {
    //     ...propObj,
    //     streetSuffix: propObj.streetSuffix === "-" ? "" : propObj.streetSuffix,
    //     unitNumber: propObj.unitNumber === "-" ? "" : propObj.unitNumber,
    //   },
    // }));
    this.setState((prevState) => ({
      editPropFormData: {
        ...propObj,
        streetSuffix: propObj.streetSuffix === "-" ? "" : propObj.streetSuffix,
        unitNumber: propObj.unitNumber === "-" ? "" : propObj.unitNumber,
      },
    }));
    console.log("after opening", this.state);
    this.setState({
      showEditPropDialog: true,
    });
  };
  render() {
    const {
      agentData,
      currentArea,
      printVersion,
      hoData,
      photoTableData,
      recentActivityPhotoList,
      photoCheckboxModalOpen,
    } = this.props;
    const {
      // photoModalOpen,
      photoDetails,
      errorModalText,
      showErrorModal,
      oldPhotoDetails,
      showEditPropDialog,
      editPropFormData,
      oldPropData,
      showAddPropDialog,
      openUploadImageDialog,
    } = this.state;
    console.log("&&&&from state photoDetails", photoDetails);
    const {
      getMonthAndYearString,
      openPhotoModal,
      handlePropCheck,
      handleErrorModal,
      keepOrRemoveProperty,
      updateChanges,
      closePhotoModal,
      dateSort,
      openUploadMedia,
      handleRepresentedOptions,
      handleFormClose,
      handleInputChange,
      beginClosePhotoModal,
      handleOpenEditDialog,
      handleAddPropDialog,
      shortenPresentedBy,
    } = this;
    let agentSelected = photoDetails.filter((x) => x.isAgentSelected);
    console.log("agentSelected", agentSelected);
    let systemSelected = photoDetails.filter((x) => x.isSystemSelected);
    console.log("systemSelected", systemSelected);
    // console.log("inside qv4 photos", this.props);
    const { subdivisionName, coverPageHeader } = hoData;
    const { mlsAreaID, mlsNeighborhood } = currentArea;
    const { splitAreas } = propertyUtil;
    // console.log("props.match", this.props.match);
    const { demoAccountAreaName } = this.props;
    const {
      formatSuffix,
      formatBaths,
      toAcre,
      emptyValueCheck,
      endsWithS,
      statusContingentToPending,
    } = propertyUtil;
    // console.log("inside render", this.props);
    let isCountryClubGate = mlsAreaID === "132" ? true : false;
    let {
      listgenUserFirstName,
      agentLicenseNumber,
      isMemberOfTeam,
      teamMembers,
      listgenUserID,
      teamName,
    } = agentData;
    const teamMember1License =
      isMemberOfTeam && teamMembers[0].agentLicenseNumber;
    const teamMember2License =
      isMemberOfTeam && teamMembers[1].agentLicenseNumber;
    let teamMember1Name =
      isMemberOfTeam && teamMembers[0].firstName.toUpperCase();

    let isScar = listgenUserID === "100588";

    const teamMember2Name =
      isMemberOfTeam && teamMembers[1].firstName.toUpperCase();
    const lastPrintDate = "2021-01-22";
    let oldestDate = photoDetails
      .map((prop) => (prop.SaleDate === "-" ? prop.ListDate : prop.SaleDate))
      .sort()[0];
    let date = new Date(oldestDate);
    console.log("above mapped listings photo details", photoDetails);
    let mappedListings =
      photoDetails &&
      photoDetails.length >= 1 &&
      photoDetails
        .filter((prop) => prop.isSystemSelected === true)
        .slice(0, 6)
        .map((list, i) => {
          // let list = trueList;
          // console.log("list is", list);
          //console.log("i is", i);
          let formattedSaleDate = new Date(list.saleDate).toLocaleDateString(
            "en-US"
          );
          let isListingAgent = false;
          let isNew = list.listDate > lastPrintDate;
          if (
            (!isMemberOfTeam &&
              agentLicenseNumber === list.listingAgentLicenseID) ||
            (isMemberOfTeam &&
              (teamMember1License === list.listingAgentLicenseID ||
                teamMember2License === list.listingAgentLicenseID)) ||
            (list.isCoListingAgent && list.isCoListingAgent === "true") ||
            list.coListingAgents
          ) {
            isListingAgent = true;
          }
          let isSellingAgent = false;
          if (
            (!isMemberOfTeam &&
              agentLicenseNumber === list.sellingAgentLicenseID) ||
            (isMemberOfTeam &&
              (teamMember1License === list.sellingAgentLicenseID ||
                teamMember2License === list.sellingAgentLicenseID)) ||
            (list.isCoSellingAgent && list.isCoSellingAgent === "true")
          ) {
            isSellingAgent = true;
          }
          let soldStatus = list.status === "Sold";
          let bannerText;
          let shouldHighlightDOM = false;
          if (list.daysOnMarket < 30 && list.status !== "Active") {
            shouldHighlightDOM = true;
          }
          let teamComboWithS;
          let teamCombo;
          if (isMemberOfTeam) {
            teamComboWithS = `${teamMember1Name} & ${teamMember2Name}${endsWithS(
              teamMember2Name,
              true
            )}`;
            teamCombo = `${teamMember1Name} & ${teamMember2Name}`;
            if (isScar) {
              teamComboWithS = "TEAM SCARBOROUGH'S";
              teamCombo = "TEAM SCARBOROUGH";
            }
          }

          if (soldStatus) {
            if (
              listgenUserID === "100528" &&
              isListingAgent &&
              !isSellingAgent
            ) {
              bannerText = "DOUGLAS & ASSOCIATES RECENT SALE";
            } else if (!isMemberOfTeam && isListingAgent && !isSellingAgent) {
              bannerText = `${listgenUserFirstName.toUpperCase()}${endsWithS(
                listgenUserFirstName,
                true
              )} LISTING SOLD`;
            } else if (!isMemberOfTeam && !isListingAgent && isSellingAgent) {
              bannerText = `SOLD BY ${listgenUserFirstName.toUpperCase()}`;
            } else if (!isMemberOfTeam && isListingAgent && isSellingAgent) {
              bannerText = `LISTED & SOLD BY ${listgenUserFirstName.toUpperCase()}`;
            } else if (isMemberOfTeam && isListingAgent && !isSellingAgent) {
              bannerText = `${teamComboWithS} LISTING SOLD`;
              // bannerText = `${teamMember1Name} & ${teamMember2Name}${endsWithS(
              //   teamMember2Name,
              //   true
              // )} LISTING SOLD`;
            } else if (isMemberOfTeam && !isListingAgent && isSellingAgent) {
              bannerText = `SOLD BY ${teamCombo}`;
              // bannerText = `SOLD BY ${teamMember1Name} & ${teamMember2Name}`;
            } else if (isMemberOfTeam && isListingAgent && isSellingAgent) {
              bannerText = `LISTED & SOLD BY ${teamCombo}`;
              // bannerText = `LISTED & SOLD BY ${teamMember1Name} & ${teamMember2Name}`;
            }
          } else {
            if (!isMemberOfTeam && isListingAgent && !isSellingAgent) {
              bannerText = `${listgenUserFirstName.toUpperCase()}${endsWithS(
                listgenUserFirstName,
                true
              )} LISTING`;
            } else if (isMemberOfTeam && isListingAgent && !isSellingAgent) {
              bannerText = `${teamComboWithS} LISTING`;
              // bannerText = `${teamMember1Name} & ${teamMember2Name}${endsWithS(
              //   teamMember2Name,
              //   true
              // )} LISTING`;
            } else if (!isMemberOfTeam && !isListingAgent && isSellingAgent) {
              bannerText = `${listgenUserFirstName.toUpperCase()} IS REPRESENTING THE BUYER`;
            } else if (isMemberOfTeam && !isListingAgent && isSellingAgent) {
              bannerText = `${teamMember1Name} & ${teamMember2Name} ${
                isScar ? "IS" : "ARE"
              } REPRESENTING THE BUYER`;
              // bannerText = `${teamMember1Name} & ${teamMember2Name} ARE REPRESENTING THE BUYER`;
            }
          }
          return (
            <div
              key={`${list.address} ${list.listPrice} ${list.saleDate} ${i}`}
              className="list-map-item"
            >
              <div>
                <div className="list-img-container">
                  {bannerText && (
                    <p
                      className={`banner-text-bg ${
                        listgenUserID === "100528" && "douglas-banner"
                      }`}
                    >
                      {bannerText}
                    </p>
                  )}
                  <div className="list-photo-and-banner-container">
                    <img
                      src={list.photoURL}
                      alt="Home"
                      className="listing-img"
                    />
                    <div className="list-row status-address-row">
                      <div className="status-address-row-inner">
                        <p className="list-address sold-address">
                          {`${emptyValueCheck(
                            list.streetNumber
                          )}   ${emptyValueCheck(list.streetName)} ${
                            list.streetSuffix &&
                            formatSuffix(list.streetSuffix, mlsAreaID)
                          }`}
                          {(!isCountryClubGate &&
                            (list.propertyType === "Condominium") &
                              (list.unitNumber !== "-")) ||
                          (list.propertyType === "Condo/Co-Op" &&
                            list.unitNumber !== "-") ||
                          (!isCountryClubGate &&
                            list.propertyType === "Townhouse" &&
                            list.unitNumber !== "-")
                            ? `, #${list.unitNumber}`
                            : ""}
                        </p>
                        <div className="status-and-sales-price">
                          <p
                            className={`list-${list.status.toLowerCase()} status`}
                          >
                            {statusContingentToPending(list.status)}
                          </p>
                          {list.status !== "Active" &&
                            list.salePrice !== "-" && (
                              <p className="list-sale-price">
                                {" "}
                                ${Math.round(list.salePrice).toLocaleString()}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="active-listing-text-container three-active-cols">
                  {list.status === "Sold" ? (
                    <div className="list-row">
                      {list.listPrice !== "-" ? (
                        <div className="list-col">
                          <p>List Price</p>
                          <p> ${Math.round(list.listPrice).toLocaleString()}</p>
                        </div>
                      ) : (
                        <p></p>
                      )}

                      <div className="list-col">
                        <p>Sale Date</p>
                        {getMonthAndYearString(list.saleDate)}
                      </div>
                      <div className="list-col">
                        <p>Beds / Baths</p>
                        <p>
                          {formatBaths(list.bedBaths, true).bed} /{" "}
                          {formatBaths(list.bedBaths, true).bath}
                        </p>
                      </div>
                    </div>
                  ) : list.propertyType === "Residential Lot" ? (
                    <div className="list-row">
                      {list.listPrice !== "-" ? (
                        <div className="list-col">
                          <p>List Price</p>
                          <p> ${Math.round(list.listPrice).toLocaleString()}</p>
                        </div>
                      ) : (
                        <p></p>
                      )}
                      <div className="list-col">Vacant Lot</div>
                    </div>
                  ) : (mlsAreaID === "SBA-35H" || mlsAreaID === "SBA-35F") &&
                    list.streetName === "La Paloma" &&
                    list.streetNumber === "4488" ? (
                    <div className="list-row">
                      {list.listPrice !== "-" ? (
                        <div className="list-col">
                          <p>List Price</p>
                          <p> ${Math.round(list.listPrice).toLocaleString()}</p>
                        </div>
                      ) : (
                        <p></p>
                      )}
                      <div className="list-col">
                        {String(list.guestHouseBathsAndBeds)
                          .split(" ")
                          .slice(0, 3)
                          .join(" ")}
                        {/*<p>Beds</p>
                    <p> {formatBaths(list.bedBaths, true).bed}</p>*/}
                      </div>
                      <div className="list-col smaller-guest-text">
                        {String(list.guestHouseBathsAndBeds)
                          .split(" ")
                          .slice(3)
                          .join(" ")}
                        {/*<p>Baths</p>
                    <p> {formatBaths(list.bedBaths, true).bath}</p>*/}
                      </div>
                    </div>
                  ) : (
                    <div className="list-row">
                      {list.listPrice !== "-" ? (
                        <div className="list-col">
                          <p>List Price</p>
                          <p> ${Math.round(list.listPrice).toLocaleString()}</p>
                        </div>
                      ) : (
                        <p></p>
                      )}
                      <div className="list-col">
                        <p>Beds</p>
                        <p> {formatBaths(list.bedBaths, true).bed}</p>
                      </div>
                      <div className="list-col">
                        <p>Baths</p>
                        <p> {formatBaths(list.bedBaths, true).bath}</p>
                      </div>
                    </div>
                  )}
                  <div className="list-row">
                    {list.daysOnMarket !== null ? (
                      <div
                        className={
                          "list-col " +
                          (shouldHighlightDOM ? "highlight-dom" : "")
                        }
                      >
                        <p>Days on Market </p>
                        <p>{Math.round(list.daysOnMarket).toLocaleString()}</p>
                      </div>
                    ) : (
                      <p></p>
                    )}

                    <div className="list-col">
                      <p>Living SqFt</p>
                      <p>{Math.round(list.squareFeet).toLocaleString()}</p>
                    </div>
                    <div className="list-col">
                      <p>Lot</p>
                      <p>{toAcre(list.lotSizeSquareFt, false, false, true)}</p>
                    </div>
                  </div>
                  <div
                    className={
                      "full-row presented " +
                      (isListingAgent ? "highlight-presented" : "small-gray")
                    }
                  >
                    {this.shortenPresentedBy(
                      list.listingAgentName,
                      list.brokerageName,
                      printVersion,
                      "Presented by "
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        });

    let place;
    let placeCount = mlsNeighborhood.length;
    if (mlsAreaID === "SBA-20FInv" || mlsAreaID === "SBA-20FRes") {
      place = "Downtown Santa Barbara";
    } else if (demoAccountAreaName && demoAccountAreaName.length > 1) {
      place = demoAccountAreaName;
    } else if (
      coverPageHeader &&
      coverPageHeader === "Upper East and Lower Mission Canyon"
    ) {
      place = "Upper East + Lower Mission Canyon";
    } else if (mlsNeighborhood === "Winchester Canyon") {
      place = "Rancho Embarcadero & Winchester";
    } else if (mlsNeighborhood === "Ennisbrook Birnam Wood") {
      place = "Ennisbrook / Birnam Wood";
    } else if (subdivisionName) {
      place = subdivisionName;
    } else if (placeCount > 30) {
      place = "Your Neighborhood";
    } else {
      place = splitAreas(mlsNeighborhood).join(" + ");
    }
    // console.log("this.state", this.state);

    let remainingMapped;
    let remainingList;
    console.log("remainingList", remainingList);
    console.log("mappedListings", mappedListings);
    if (mappedListings.length < 6) {
      remainingMapped = 6 - mappedListings.length;
      remainingList = [...Array(remainingMapped).keys()].map((listing, i) => {
        return (
          <div
            className="select-another-property-container list-map-item"
            key={i}
            onClick={() => openPhotoModal()}
          >
            <div>
              <p>You Must Select</p>
              <p>Another Property</p>
            </div>
          </div>
        );
      });
      // console.log("remainingList", remainingList);
    }
    // console.log("remainingMapped", remainingMapped);
    // console.log(
    //   "before modal this.state.photoDetails",
    //   this.state.photoDetails
    // );
    console.log("$$$$$$qv4 photos", recentActivityPhotoList);
    // console.log("before modal this.props.oldListings", this.props.oldListings);
    return (
      <>
        {photoCheckboxModalOpen && (
          <TestPhoto2
            currentSoldListings={photoDetails}
            place={place}
            photoDetails={photoDetails}
            handlePropCheck={handlePropCheck}
            agentData={agentData}
            updateChanges={updateChanges}
            closePhotoModal={closePhotoModal}
            dateSort={dateSort}
            openUploadMedia={openUploadMedia}
            handleRepresentedOptions={handleRepresentedOptions}
            handleFormClose={handleFormClose}
            handleInputChange={handleInputChange}
            beginClosePhotoModal={beginClosePhotoModal}
            handleOpenEditDialog={handleOpenEditDialog}
            handleAddPropDialog={handleAddPropDialog}
            showEditPropDialog={showEditPropDialog}
            editPropFormData={editPropFormData}
            oldPropData={oldPropData}
            showAddPropDialog={showAddPropDialog}
            openUploadImageDialog={openUploadImageDialog}
            shortenPresentedBy={shortenPresentedBy}
            getMonthAndYearString={getMonthAndYearString}
            hoData={hoData}
            currentArea={currentArea}
            //updateRecentActivity={updateRecentActivity}
            recentActivityPhotoList={photoDetails}
            // recentActivityPhotoList={recentActivityPhotoList.photoDetails}
            updatePhotoDetails={this.updatePhotoDetails}
          />
        )}
        {showErrorModal ? (
          <PhotoErrorModal
            errorModalText={errorModalText}
            handleErrorModal={handleErrorModal}
            keepOrRemoveProperty={keepOrRemoveProperty}
          />
        ) : (
          ""
        )}
        <div
          className="qv4-photo-section-container"
          onClick={() => openPhotoModal()}
        >
          {!photoCheckboxModalOpen ? (
            <div>
              <div className="edit-properties-button">
                <p>Edit 6 Properties Below</p>
              </div>
            </div>
          ) : (
            <div
              className="update-changes-button"
              // onClick={() => updateChanges()}
            >
              <p>Save and Close</p>
            </div>
          )}

          <div className="qv4-photo-section-title">
            <p>Recent Activity in</p>
            <p className="photo-section-title-neighborhood">{place}</p>
          </div>
          <div className="qv4-mapped-listings">
            {mappedListings}
            {remainingList && remainingList}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    photoCheckboxModalOpen: state.pma.photoCheckboxModalOpen,
    recentActivityPhotoList: state.pma.recentActivityPhotoList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCheckboxPhotoModalOpen: (isModalOpen) => {
      dispatch(pmaActions.updateCheckboxPhotoModalOpen(isModalOpen));
    },
    // setQuadrantDetails: (quadrantDetails) => {
    //   dispatch(pmaActions.setQuadrantDetails(quadrantDetails));
    // },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(QVFourPhotos);
