import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Chip, Badge, Link, Typography, Button } from '@material-ui/core';
import theme from 'theme';
import { red, green, teal } from '@material-ui/core/colors';
import classNames from 'classnames';
import palette from 'theme/palette';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    fixImage: {
        width: '48px'
    },
    badgeIcon: {
        marginTop: theme.spacing(2)
    },
    boldText: {
        fontWeight: '500',
        fontSize: '13px'
    },
    pointerCursor: {
        cursor: 'pointer'
    },
    redColor: {
        color: red[900]
    },
    greenColor: {
        color: green[900]
    },
    primaryColor: {
        color: palette.primary.main
    },
    lightGreenColor: {
        background: green[50]
    },
    normalButton: {
        textTransform: 'none'
    },
    vlightTeal: {
        background: `${palette.latestLightGreen.main} !important`,
    },
    boxBorder: {
        border: `2px solid ${palette.latestLightGreen.dark}`
    }
});

export default function CreditCardTable(props) {
    const classes = useStyles();
    const { isAdmin, cardList, enableCCConfirmationDialogFn, closeCCConfirmationDialogFn, enableCCChargeDialogFn, setDefaultCreditCardForAnAgent, isLoading, deleteAgentCreditCard } = props;
    const creditCardLogos = {
        "Visa": "https://img.icons8.com/color/96/000000/visa.png",
        "MasterCard": "https://img.icons8.com/color/96/000000/mastercard-credit-card.png",
        "American Express": "https://img.icons8.com/color/96/000000/amex.png",
        "Discover": "https://img.icons8.com/color/96/000000/discover.png",
        "JCB": "https://img.icons8.com/color/96/000000/jcb.png",
        "Diners Club": "https://img.icons8.com/color/96/000000/diners-club.png",
        "Maestro": "https://img.icons8.com/color/96/000000/maestro.png",
        "Laser": "https://img.icons8.com/cotton/96/000000/credit-card-contactless.png",
        "UnionPay": "https://img.icons8.com/color/96/000000/unionpay.png",
        "Elo": "https://img.icons8.com/cotton/96/000000/credit-card-contactless.png",
        "HiperCard": "https://img.icons8.com/cotton/96/000000/credit-card-contactless.png"
    };

    return (
        <Table className={classes.table} aria-label="credit card table">
            <TableHead>
                <TableRow>
                    <TableCell align="center">Name on Card</TableCell>
                    <TableCell align="center" style={{ paddingLeft: 0, }}>Last 4 Digits</TableCell>
                    <TableCell align="center" style={{ paddingLeft: 0 }}>Brand</TableCell>
                    <TableCell align="center" style={{ paddingLeft: 0 }}>Expire Date<br />(MM / YY)</TableCell>
                    {/* //if agent only have ccredit card and its not admin also then no need to show last column */}
                    {cardList.length > 1 || isAdmin ?
                        <TableCell align="center" style={{ paddingLeft: 0 }}></TableCell>
                        : ''}
                </TableRow>
            </TableHead>
            <TableBody>
                {cardList.map((row, key) => (
                    <TableRow key={key} className={classNames( {
                        [classes.vlightTeal]: row.isDefault,
                        [classes.boxBorder]: row.isDefault
                    })}>
                        <TableCell align="center" className={classes.boldText}>{row.nameOnCard ? row.nameOnCard : '---'}</TableCell>
                        <TableCell align="center" className={classes.boldText}>
                            **{row.last4}
                            {row.isDefault ?
                                <Fragment>
                                    <div className={classes.badgeIcon}>
                                        <Chip
                                            label="Default Card"
                                            color="primary"
                                        />
                                        <br />
                                        <br />
                                        <Typography variant="subtitle2" color="primary">Current card being charged</Typography>
                                    </div>
                                </Fragment>
                                : ''}
                        </TableCell>
                        <TableCell align="center" style={{ paddingLeft: 0 }}>
                            <img src=
                                {
                                    creditCardLogos[row.brand]
                                }
                                className={classes.fixImage}
                            />

                        </TableCell>
                        <TableCell align="center" className={classes.boldText}>
                            {row.expiryMonth && row.expiryMonth.toString().length == 1 ? `0${row.expiryMonth}` : row.expiryMonth}
                            &nbsp;/&nbsp;
                             {row.expiryYear && row.expiryYear.toString().length == 4 ? `${row.expiryYear.toString().slice(2, row.expiryYear.toString().length)}` : row.expiryYear}
                        </TableCell>
                        {/* //if agent only have ccredit card and its not admin also then no need to show last column */}
                        {cardList.length > 1 || isAdmin ?
                            <TableCell align="left" className={classes.boldText} style={{ width: '40%' }}>
                                <Fragment>
                                    {!row.isDefault ? <Fragment>
                                        <Button className={classNames(classes.pointerCursor, classes.greenColor, classes.normalButton)} onClick={event => setDefaultCreditCardForAnAgent(row.cardID, row.customerID)} disabled={isLoading}>Select this Card for PMA Payment</Button>&nbsp;&nbsp;&nbsp;
                                <Button className={classNames(classes.pointerCursor, classes.redColor, classes.normalButton)} onClick={event => enableCCConfirmationDialogFn("Are you sure to remove this card?", "This card will no longer be available for your ListGen account. If you want to use this card again you can add again.", row.cardID, "remove_card")} disabled={isLoading}>Remove Card</Button>

                                    </Fragment> :
                                        <Fragment>
                                            {isAdmin ?
                                                //only show if the role is admin. admin can only charge the amount.
                                                <Button className={classNames(classes.pointerCursor, classes.primaryColor)} onClick={event => enableCCChargeDialogFn(row.cardID)} disabled={isLoading}>Charge Card</Button>
                                                : ''}
                                        </Fragment>
                                    }
                                </Fragment>
                            </TableCell>
                            : ''}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
