import { Typography } from '@material-ui/core';
import React from 'react';

const OffMarketSale = ({ property, styles = {} }) => {
    const { propertyDetails } = property ? property : {};
    const { listingID } = propertyDetails ? propertyDetails : {};
    const isOffMarketSale = !listingID;
    return null;
    // return (
    //     <div style={{...{marginTop: '16px'}, ...styles}}>
    //         {isOffMarketSale ?
    //             <div className='off-market-sale-section'>Off-Market Sale</div>
    //             :
    //             <Typography variant='subtitle2'>MLS # {listingID}</Typography>}
    //     </div>
    // );
}
export default OffMarketSale;