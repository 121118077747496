import React, { Component } from 'react';

class AgentLogoHeader extends Component {
  render() {
    const { logoURL } = this.props.agentData;
    return (
      <div className="agent-logo-header">
        <img className="agent-logo-header-img" src={logoURL} alt="agent logo" />
      </div>
    );
  }
}

export default AgentLogoHeader;
