import React, { Fragment, Component } from "react";
import PropertyImageVizSensor from "./PropertyImageVizSensor";
import { propertyUtil } from "../../PropertyUtil";
import noImage from "../../../../_img/not-available.png";
import PropertyViewDialog from "./PropertyViewDialog";
import PropertyImageSliderDialog from "./PropertyImageSliderDialog";
import { Typography } from "@material-ui/core";
import { renderLogicUtil } from "views/Pma/RenderLogicUtil";

const PhotoView = props => {
  const {
    isPropertyMapped,
    propertyDetails,
    mailingDetails,
    listingStatus,
    openMediaViewDialog,
    property,
    propertySequenceNumber,
    agentData,
  } = props;

  //get prop photo
  let propPhoto =
    isPropertyMapped && propertyDetails.photos
      ? propertyDetails.photos[0]
      : null;
  //get propview and main view
  let propView =
    propertyDetails && propertyDetails.view ? propertyDetails.view : [];
  let mainView = propertyUtil.getPropView(propView);
  const isADU = renderLogicUtil.isPropertyIsADU(property);
  const expandedPropertyType = renderLogicUtil.getPropertyType(property);
  return (
    <div className="global-ps-body-container tableTdFlex">
      <div className="tableTdFlex-First textCursor">
        <div>
          {/* {!isPropertyMapped || propPhoto === null ? (
            <div className="no-image-available-section">
              No Image
              <br />
              Available
            </div>
          ) : null} */}
          <div>
            {/* {listingStatus && listingStatus !== "Sold" ? (
              <div>Status: {listingStatus}</div>
            ) : (
              ""
            )} */}
          </div>
          {/* //isPropertyMapped. used true instead of this */}
          {true ? (
            <div>
              {/* <div>
                {propertyDetails &&
                  propertyDetails.propertySubtype === "Single Family Home"
                  ? "Single Family"
                  : propertyDetails.propertySubtype}
              </div> */}
              <div>
                Beds:{" "}
                {propertyDetails && propertyDetails.beds > 0
                  ? Number(propertyDetails.beds)
                  : mailingDetails && mailingDetails.BedRooms > 0
                    ? Number(mailingDetails.BedRooms)
                    : mailingDetails && mailingDetails.Bedrooms > 0
                      ? Number(mailingDetails.Bedrooms)
                      : "---"}
                , Baths:{" "}
                {mailingDetails && mailingDetails.Bathfull > 0
                  ? Number(mailingDetails.Bathfull) +
                  (propertyDetails.halfBaths > 0 ? "+" : "")
                  : mailingDetails && mailingDetails.Baths > 0
                    ? parseInt(mailingDetails.Baths) +
                    (propertyDetails.halfBaths > 0 ? "+" : "")
                    : "---"}
              </div>
              <div>
                {propertyDetails &&
                  propertyDetails.squareFeet &&
                  propertyDetails.squareFeet > 0 &&
                  !isNaN(Number(propertyDetails.squareFeet))
                  ? `${Number(propertyDetails.squareFeet)} SqFt`
                  : mailingDetails &&
                    mailingDetails.Totalsf &&
                    mailingDetails.Totalsf > 0 &&
                    !isNaN(Number(mailingDetails.Totalsf))
                    ? `${Number(mailingDetails.Totalsf)} SqFt`
                    : ""}
              </div>
              {mainView && <div>View: {mainView}</div>}
              {expandedPropertyType && <Typography variant="subtitle2" style={{marginTop: '8px', marginBottom: (isADU ? 0 : '16px')}}>{ expandedPropertyType}</Typography>}
              {isADU && <Typography variant="subtitle2" style={{marginBottom: '16px'}}>+ ADU</Typography>}
            </div>
          ) : null}
        </div>
      </div>
      {/* {isPropertyMapped && propPhoto !== null ? */}
      <div style={{marginBottom: '8px', display: 'block'}}>
        <PropertyImageSliderDialog
          //src={propPhoto}
          //className="mailing-photo-thumbnail"
          //alt="Property Image"
          propertyDetails={propertyDetails}
          mailingDetails={mailingDetails}
          propertySequenceNumber={propertySequenceNumber}
          property={property}
          //noImageMode={!propPhoto}//isPropertyMapped && 
        />
        </div>
      <PropertyViewDialog
        propertyDetails={propertyDetails}
        isPropertyMapped={isPropertyMapped}
        mailingDetails={mailingDetails}
        openMediaViewDialog={openMediaViewDialog}
        propertySequenceNumber={propertySequenceNumber}
        property={property}
        agentData={agentData}
      />
    </div>
  );
};

export default PhotoView;
