import React, { Component, Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Input,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from "@material-ui/core";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "../../../styles";
import { withSnackbar } from "notistack";
import classNames from "classnames";
import ClearIcon from "@material-ui/icons/Clear";
import { showSuccessNotification } from "common/helpers";
import { showErrorNotification } from "common/helpers";
import { AddUpdatePmaAdmin } from "services/agent";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddTestimonialsComponent = (props) => {
  const {
    mode,
    oldData,
    completeData,
    classes,
    listgenUserID,
    localUpdateFn,
    onCloseFn,
    isAdminAccess,
  } = props;

  //Min variables
  const minCharacterLength = 100;
  const maxCharacterLength = 370;
  const [updateID, setUpdateID] = React.useState(null);
  const [newTestimonials, setNewTestimonials] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [showError, setShowError] = React.useState("");
  //const [lastName, setLastName] = React.useState("");
  /**loading variables */
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (mode === "update") {
      //setUpdateID();
      setNewTestimonials(oldData.testimonialContent);
      setFirstName(oldData.clientFirstName);
      setLastName(oldData.clientLastName);
    }
  }, []);

  useEffect(() => {
    setShowError("");
  }, [newTestimonials, firstName]);

  const checkErros = () => {
    let errorString = [];
    if (
      !newTestimonials ||
      (newTestimonials && newTestimonials.length < minCharacterLength)
    ) {
      //add first error
      errorString.push(
        `Testimonial is incomplete. It should have atleast ${minCharacterLength} characters.`
      );
    }
    if (newTestimonials && newTestimonials.length > maxCharacterLength) {
      //add first error
      errorString.push(
        `Testimonial length is exceeded by ${
          newTestimonials.length - maxCharacterLength
        } characters. Please shorten the testimonial.`
      );
    }
    if (!firstName) {
      //add space if theres any previous error
      errorString.push("Client first name is required.");
    }
    //check if errors is there add errros and return false
    if (errorString.length > 0) {
      setShowError(
        errorString.map((i) => (
          <Fragment>
            {i} <br />
          </Fragment>
        ))
      );
      return false;
    } else {
      //no erro return true
      return true;
    }
  };

  const AddUpdateATestimonialData = (mode) => {
    //first check fro error
    if (checkErros()) {
      /**get the charge data */
      /**make loading true */
      setIsLoading(true);
      try {
        let data = {};
        if (mode === "add") {
          data = {
            listgenUserID,
            clientFirstName: firstName,
            clientLastName: lastName,
            testimonialContent: newTestimonials,
            testimonialSortOrder: completeData.length + 1,
          };
        } else {
          console.log("old data logged==", oldData);
          data = Object.assign(oldData, {
            clientFirstName: firstName,
            clientLastName: lastName,
            testimonialContent: newTestimonials,
          });
        }
        //check for the verification status
        if (isAdminAccess) {
          data["testimonialVerificationStatus"] = "verified";
        }
        //check for isALready used correct data. in create api we are not getting this back so added this check
        data["isAlreadyUsed"] =
          data.isAlreadyUsed === null ? false : data.isAlreadyUsed;
        //save the data now
        AddUpdatePmaAdmin(
          mode === "add" ? "addTestimonials" : "updateTestimonials",
          data
        )
          .then((data) => {
            showSuccessNotification(
              props,
              mode === "add"
                ? `Testimonial successfully added.`
                : `Testimonial successfully updated.`
            );
            setIsLoading(false);
            //agent charged so close the dialog
            //call local state
            localUpdateFn(mode, data, oldData && oldData.testimonialID);
            onCloseFn();
          })
          .catch((err) => {
            showErrorNotification(
              props,
              "Something went wrong while processing your request."
            );
            setIsLoading(false);
          });
      } catch (err) {
        showErrorNotification(props, "Unable to process request.");
        setIsLoading(false);
      }
    }
  };

  return (
    <Fragment>
      <Dialog
        open={true}
        onClose={onCloseFn}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
        TransitionComponent={Transition}
      >
        <DialogTitle id="cc-alert-dialog-title">
          <div
            className={classNames(
              classes.width100,
              classes.displyFlex,
              classes.rowFlex,
              classes.spaceBWJustify
            )}
          >
            <div></div>
            <div>
              <Typography
                variant="h4"
                align="center"
                className={classes.uppercase}
                color="primary"
              >
                {mode === "add"
                  ? "You are creating a new testimonial"
                  : "You are editing an existing testimonial"}
              </Typography>
              <Typography variant="body1" align="center">
                Please add as many client Testimonials as you like. Your
                Testimonials will print on the back cover page of your PMAs.
                <br />
                The system will rotate your Testimonials so a new one is printed
                each time. Only the quarterly versions of your PMAs will print
                your Testimonials.
              </Typography>
            </div>
            <div
              onClick={onCloseFn}
              className={classNames(
                classes.displyFlex,
                classes.rowFlex,
                classes.flexEndJustify,
                classes.flexStartAlign,
                classes.closeModalBtn
              )}
            >
              <Button
                variant="contained"
                size="small"
                className={classNames(
                  classes.closeredButton,
                  classes.whiteColor,
                  classes.textCenterAlign,
                  classes.red900
                )}
              >
                <ClearIcon className={classes.whiteColor} />
              </Button>
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers={true}>
          <p
            className={classNames(
              classes.width100,
              classes.requiredRed,
              classes.bold500Font,
              classes.px12,
              classes.requirePLeft
            )}
          >
            * Required Field
          </p>
          <div
            className={classNames(
              classes.testimonialTextboxDiv,
              classes.displyFlex
            )}
          >
            <div className={classes.textboxLeftTM}>
              <TextField
                className={classNames(
                  classes.lightBlueBG,
                  classes.hoverBgLightBlue
                )} //(!newTestimonials ? classNames(classes.lightBlueBG, classes.hoverBgWhite) : '')}
                key={"testimonials-main-box-"}
                label={
                  <span className={classes.bold500Font}>
                    &#8220;Type your Testimonial here&#8221;
                  </span>
                }
                multiline
                rows={3}
                variant="outlined"
                fullWidth={true}
                InputProps={{
                  classes: {
                    root: classNames(
                      classes.textAreaMultiline,
                      classes.hoverBgLightBlue
                    ),
                  },
                }}
                inputProps={
                  {
                    //maxLength: 370,
                    //className: classes.hoverBgLightBlue
                  }
                }
                required={true}
                value={newTestimonials}
                onChange={(event) => setNewTestimonials(event.target.value)}
                error={
                  showError &&
                  (!newTestimonials ||
                    (newTestimonials &&
                      newTestimonials.length < minCharacterLength) ||
                    (newTestimonials &&
                      newTestimonials.length > maxCharacterLength))
                }
              />
              <div
                className={classNames(
                  classes.width100,
                  classes.displyFlex,
                  classes.centerAlign,
                  classes.spaceBWJustify
                )}
              >
                <div className={classNames(classes.px12)}>
                  (Minimum Character Count: {minCharacterLength})
                </div>
                <div
                  className={classNames(
                    classes.px12,
                    newTestimonials.length > maxCharacterLength
                      ? classes.paletteRed
                      : ""
                  )}
                >
                  (Character Count: {newTestimonials.length}/
                  {maxCharacterLength})
                </div>
              </div>
            </div>
            {/* <div className={classNames(classes.textboxRightTM, classes.displyFlex, classes.columnFlex)}>
                  <Button color="primary">Edit</Button>
                </div> */}
          </div>
          <div
            className={classNames(
              classes.widthAuto,
              classes.displyFlex,
              classes.namesDiv
            )}
          >
            <div>
              <TextField
                required
                id="outlined-first-name"
                label="Enter Client First Name(s)"
                variant="outlined"
                required={true}
                helperText={"Full name or Initials, Max character: 30"}
                margin="dense"
                value={""}
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                inputProps={{
                  maxLength: 30,
                  className: classNames(
                    classes.lightBlueBG,
                    classes.hoverBgWhite
                  ), //(!firstName ? classNames(classes.lightBlueBG, classes.hoverBgWhite) : '')
                }}
                error={showError && !firstName}
              />
              {/* <p className={classNames(classes.px12, classes.rightAlignText)}>(Character Count: 0/30)</p> */}
            </div>
            <div className={classes.namesDivML}>
              <TextField
                required
                id="outlined-second-name"
                label="Enter Client Last Name(s)"
                variant="outlined"
                required={false}
                helperText={"Full last name, Max character: 30"}
                margin="dense"
                value={""}
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                inputProps={{
                  maxLength: 30,
                  className: classNames(
                    classes.lightBlueBG,
                    classes.hoverBgWhite
                  ), //(!lastName ? classNames(classes.lightBlueBG, classes.hoverBgWhite) : '')
                }}
              />
              {/* <p className={classNames(classes.px12, classes.rightAlignText)}>(Character Count: 0/30)</p> */}
            </div>
          </div>
          <div className={classNames(classes.width100, classes.errorDiv)}>
            {showError ? (
              <Typography
                variant="body1"
                align="center"
                className={classNames(classes.paletteRed)}
              >
                {showError}
              </Typography>
            ) : (
              ""
            )}
          </div>
        </DialogContent>
        {
          isLoading ? (
            <div
              className={classNames(
                classes.progressWrapper,
                classes.displyFlex,
                classes.centerJustify
              )}
            >
              <CircularProgress />
            </div>
          ) : (
            // <DialogActions>
            <Fragment>
              <div className={classNames(classes.portletFooter)}>
                <div className={classes.footerButtons}>
                  <div className={classes.footerButtonsLeftRight}>
                    <Button
                      variant="outlined"
                      onClick={(event) => onCloseFn()}
                      color="default"
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={classes.footerButtonsCenter}>
                    <Button
                      variant="contained"
                      onClick={(event) => AddUpdateATestimonialData(mode)}
                      color="primary"
                      //disabled={newTestimonials === '' || (newTestimonials && newTestimonials.length < 100) || firstName === ''}
                    >
                      {!isAdminAccess ? "Submit" : "Verify and Close"}
                    </Button>
                  </div>
                  <div
                    className={classNames(
                      classes.footerButtonsLeftRight,
                      classes.displyFlex,
                      classes.flexEndJustify
                    )}
                  ></div>
                  {/* <div className={classNames(classes.footerButtonsLeftRight, classes.displyFlex, classes.flexEndJustify)}>
                  <Button
                    variant="outlined"
                    //onClick={event => openDefaultCardDialog()}
                    color="default"
                  //disabled={!isCreditCardInfoValid}
                  >
                    Save and Add Another Testimonials
                </Button>
                </div> */}
                </div>
              </div>
            </Fragment>
          )
          // </DialogActions>
        }
      </Dialog>
    </Fragment>
  );
};

AddTestimonialsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose(
  withSnackbar,
  withStyles(styles)
)(AddTestimonialsComponent);
