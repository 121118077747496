import React, { Component } from "react";
import "./customStyles.scss";
import "./customPrintStyles.scss";

class JohnsonsQuadrants extends Component {
  render() {
    //console.log("inside quadrant");
    return (
      <>
        <div className="johnsons-quadrants">
          <div className="johnsons-quadrants-body">
            <div className="body-title-section">
              <div className="johnsons-quadrants-body-title">
                <p>Elevate your</p>
                <p>Real Estate Experience</p>
              </div>

              <div className="johnsons-quadrants-body-subtitle-text">
                We Have solutions for home sellers
              </div>
            </div>
            <div className="section-container">
              <div className="section-row">
                <div className="johnsons-quadrants-section">
                  <div className="johnsons-quadrants-section-subtitle">
                    Elevate Your
                  </div>
                  <div className="johnsons-quadrants-section-title">
                    Property
                  </div>
                  <div className="johnsons-quadrants-section-text">
                    <p>Prep your home for sale with cosmetic upgrades,</p>
                    <p>staging & repairs to enhance the marketability</p>
                    <p>of your home, pay at closing.</p>
                  </div>
                </div>
                <div className="johnsons-quadrants-section">
                  <div className="johnsons-quadrants-section-subtitle">
                    Elevate Your
                  </div>
                  <div className="johnsons-quadrants-section-title">
                    Peace of Mind
                  </div>
                  <div className="johnsons-quadrants-section-text">
                    <p>Risk Management protection services, giving</p>
                    <p>you access to legal professionals during your</p>
                    <p>escrow & up to 1yr after.</p>
                  </div>
                </div>
              </div>
              <div className="section-row">
                <div className="johnsons-quadrants-section">
                  <div className="johnsons-quadrants-section-subtitle">
                    Elevate Your
                  </div>
                  <div className="johnsons-quadrants-section-title">
                    Negotiations
                  </div>
                  <div className="johnsons-quadrants-section-text">
                    <p>Buy your next home prior to selling your current.</p>
                    <p>Unlock your equity early & strengthen your</p>
                    <p>negotiating position on your purchase.</p>
                  </div>
                </div>
                <div className="johnsons-quadrants-section">
                  <div className="johnsons-quadrants-section-subtitle">
                    Elevate Your
                  </div>
                  <div className="johnsons-quadrants-section-title">Reach</div>
                  <div className="johnsons-quadrants-section-text">
                    <p>Utilize our strategic portfolio of affiliated real</p>
                    <p>estate companies ensuring that your property</p>
                    <p>will be presented to an engaged domestic &</p>
                    <p>global audience.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="johnsons-quadrants-logo-container">
            <img
              src="https://pma-coverphotos.s3.amazonaws.com/johnsons-quadrant-tile.png"
              alt="Johnsons Icons"
            />
          </div>
        </div>
        <div className="johnsons-testimonial">
          <div className="johnsons-testimonial-inner">
            <p>
              "It’s refreshing to have been represented by the most
              professional, insightful, creative, resourceful and knowledgeable
              agents ever. We’ve bought and sold many homes over the years and
              certainly feel we’ve struck gold when we chose Dianne and Brianna
              Johnson to be our agents. Great Job ladies!"
            </p>
            <p>— The Crawfords</p>
          </div>
        </div>
      </>
    );
  }
}

export default JohnsonsQuadrants;
