import { Checkbox, FormControlLabel, TextField, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const useStyles = makeStyles({
  commentTextfield: {
    backgroundColor: '#EFF9FE',
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#EFF9FE',
      color: 'black',
      opacity: '0.9',
      '&:hover': {
        backgroundColor: '#EFF9FE',
      },
      '&:focused': {
        backgroundColor: '#EFF9FE',
      },
      '& fieldset': {
        border: '1px solid #8EA2AD',
      },
      '&:hover fieldset': {
        border: '1px solid #8EA2AD',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #8EA2AD',
      },
    }
  }
});

function MailProTextarea({ classes = {}, rows, onChange, placeholder, fullWidth = true, value, ...rest }) {
  const styleClasses = useStyles();
  const { root, ...restClasses } = classes;
  classes = root ? {root: classNames(styleClasses.commentTextfield, root), ...restClasses} : {...{root: styleClasses.commentTextfield}, ...classes};

  return (
    <TextField
      rows={rows}
      fullWidth={fullWidth}
      classes={classes}
      onChange={onChange}
      multiline
      variant="outlined"
      placeholder={placeholder}
      value={value}
      {...rest}
    />
  );
}

//define proptypes
MailProTextarea.propTypes = {
  rows: PropTypes.number,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  classes: PropTypes.object,
};

export default MailProTextarea;
