import React, { Component } from 'react';
import FirstLeftHeader from 'views/Pma/PmaEditor/CommonEditor/FirstLeftHeader';
import HOAddress from 'views/Pma/PmaEditor/CommonEditor/HOAddress';
import LogoAndAvatar from 'views/Pma/PmaEditor/CommonEditor/LogoAndAvatar';
import TopEconomists from './TopEconomists';
import YETestimonial from './YETestimonial';

class YEOneFirstPageLeft extends Component {
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      marketActivity,
      pma,
      currentArea,
      MQCityDetails,
      mlsAreas,
      quarterlyCityTable,
      navbarData,
      viewingGeneratedCL,
      coverPageDetails,
      agentAdvantages,
      testimonials,
      agentDesignations,
      salePriceForCities,
    } = this.props;
    // console.log('left props', this.props);
    return (
      <div className="ye1-first-page-left">
        <FirstLeftHeader
          agentData={agentData}
          printVersion={printVersion}
          currentArea={currentArea}
        />
        <LogoAndAvatar agentData={agentData} />
        <HOAddress
          hoData={hoData}
          version={version}
          agentData={agentData}
          currentArea={currentArea}
        />
        <TopEconomists />
        <YETestimonial testimonials={testimonials} />
      </div>
    );
  }
}

export default YEOneFirstPageLeft;
