import React, { Component, useEffect, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import validate from 'validate.js';
import _ from 'lodash';
import { withStyles } from "@material-ui/core";
import { withSnackbar } from 'notistack';
import {
  Grid,
  Button,
  IconButton,
  CircularProgress,
  TextField,
  Typography,
  Checkbox
} from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import styles from "./styles";
import schema from "./schema";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { SignInUser, GetAgentDetails } from "services/users";
import { SetOAuthToken, ResetAllLocalStorages } from "common/storage";
import { showErrorNotification } from "common/helpers";
import { showSuccessNotification } from "common/helpers";
import { Store } from "Store";
import { SET_TOKEN_DATA } from "store/types";
import { HANDLE_FIELD_CHANGE } from "store/types";
import { HANDLE_VALIDATIONS } from "store/types";
import { SHOW_PASSWORD } from "store/types";
import { START_LOADING } from "store/types";
import { STOP_LOADING } from "store/types";
import { DEEP_CLONE_STATE } from "store/types";
import { RESET_APP_STATE } from "store/types";
import { RESET_APP_STATE_EXCEPT_VALUES } from "store/types";
import querystring from 'querystring';
/** Google reCaptcha Integration */
import ReCAPTCHA from "react-google-recaptcha";
import Base from 'common/constants';
import { SetCookieItem, RemoveCookieItem, GetCookieItem } from "common/cookies";
import { handleLogoutFn } from "common/helpers";

function SignIn(props) {
  const { state, dispatch } = React.useContext(Store);
  const [uriMessage, setURIMessage] = React.useState(null);
  const [backButton, setBackButton] = React.useState(false);
  const [showCaptcha, setCaptcha] = React.useState(false);
  const [captchaVerified, setCaptchaVerified] = React.useState(false);
  const [captchaTokenData, setCaptchaTokenData] = React.useState(null);
  const [retryCount, setRetryCount] = React.useState(0);
  const listgenLogo = '/images/listgen-logo.png';
  const listgenLogoAlt = 'ListGen Logo';
  const { history } = props;
  /**get props */
  const { classes } = props;
  const {
    values,
    touched,
    errors,
    isValid,
    submitError,
    isLoading,
  } = state;
  /**define error methods */
  const showEmailError = touched.email && errors.email;
  const showPasswordError = touched.password && errors.password;

  /**ask for captcha if retry count is 3 or more */
  useEffect(() => {
    if (retryCount >= 3) {
      setCaptcha(true);
    }
  }, [retryCount]);

  /**Check the cookies for the retry number */
  useEffect(() => {
    //we have then update in the retry number also
    let cookieRetryNumber = GetCookieItem(Base.CookieStorageBase.LG_RETRY_NUMBER_COOKIE);
    if (parseInt(cookieRetryNumber) > 0 && parseInt(cookieRetryNumber) !== retryCount) {
      setRetryCount(parseInt(cookieRetryNumber));
    }
  }, []);

  useEffect(() => {
    /**reset all storage */
    //ResetAllLocalStorages();
    //call the handle logout fn to delete all local storage by not refreshing the page
    handleLogoutFn(0, false);
    dispatch({
      type: RESET_APP_STATE_EXCEPT_VALUES
    });
    validateForm();
  }, []);

  useEffect(() => {
    try {
      let parsedParams = querystring.parse(
        props.location.search.substring(1)
      );
      let mode = parsedParams.mode;
      if (mode === '0') {
        setURIMessage('Login or click on your registration email to continue.');
      } else if (mode === '1') {
        setURIMessage('You\'ve been successfully logged out.');
      } else if (mode === '2') {
        setURIMessage('Your token is no longer valid. Please contact support.');
      } else if (mode === '3') {
        setURIMessage('Session expired. Please login again to continue.');
      } else if (mode === '4') {
        setURIMessage('Please login to continue.');
      } else if (mode === '5') {
        setURIMessage('Please login with new email address to continue.');
      } else if (mode === '6') {
        setURIMessage('Session expired abruptly. Please login again to continue.');
      }

    } catch (err) {
      setURIMessage(null);
    }
  }, []);

  const increaseRetryNumber = () => {
    //increase the retry number
    let newRetryCount = retryCount + 1;
    setRetryCount(newRetryCount);
    //set cookie for same, 600 means for next 10 minutes ask for the Captcha
    SetCookieItem(Base.CookieStorageBase.LG_RETRY_NUMBER_COOKIE, newRetryCount, 600, null, null, false);
  };

  const resetRetryCount = () => {
    RemoveCookieItem(Base.CookieStorageBase.LG_RETRY_NUMBER_COOKIE);
  };

  const validateForm = _.debounce(() => {
    dispatch({
      type: HANDLE_VALIDATIONS,
      schema
    });
  }, 300);

  const handleSignIn = (event) => {
    event.preventDefault();
    //remove URI message
    try {
      const { values } = state;
      dispatch({
        type: START_LOADING
      });
      /**call the data api over here */
      SignInUser(values.email, values.password).then(data => {
        /**set into local storage */
        SetOAuthToken(data);
        dispatch({
          type: STOP_LOADING
        });
        dispatch({
          type: RESET_APP_STATE
        });
        dispatch({
          type: SET_TOKEN_DATA,
          payload: data
        });
        //showSuccessNotification(props, "Logged in.");
        //set retry count to 0
        setRetryCount(0);
        setCaptcha(false);
        //reset the count
        resetRetryCount();
        /**trnasfer to dashboard */
        history.push('/home');
      }).catch(err => {
        showErrorNotification(props, err);
        //call the increase data API
        increaseRetryNumber();
        //remove the passwords
        dispatch({
          type: HANDLE_FIELD_CHANGE,
          fieldType: 'textfield',
          value: '',
          name: 'password',
        });
        validateForm();
        dispatch({
          type: STOP_LOADING
        });
      }).finally(() => {
        setURIMessage(null);
      });
    } catch (err) {
      showErrorNotification(props, 'Something went wrong on our side.');
      setURIMessage(null);
      dispatch({
        type: STOP_LOADING
      });
    }
  };

  const handleBack = () => {
    history.push('/home');
  };

  const handleReCaptchaOnChange = (tokenData) => {
    //set the token data
    setCaptchaTokenData(tokenData);
    //set the token verified
    if (tokenData) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteWrapper} item lg={7}>
          <div className={classes.quote} />
        </Grid>
        <Grid className={classes.content} item lg={5} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              {backButton ?
                <IconButton
                  className={classes.backButton}
                  onClick={event => handleBack()}
                >
                  <ArrowBackIcon />
                </IconButton>
                : ''}
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form} autocomplete="off">
                <div className={classes.imageWrapper}>
                  <img
                    alt={listgenLogoAlt}
                    className={classes.image}
                    src={listgenLogo}
                  />
                </div>
                <Typography className={classes.title} variant="h4">
                  Sign in
                  </Typography>
                <Typography className={classes.sugestion} variant="body1">
                  Please enter your email and password to continue
                  </Typography>
                {uriMessage ?
                  <div className={classes.infoMessageBlock}>
                    <Typography className={classes.infoMessageText} variant="subtitle2">
                      {uriMessage}
                    </Typography>
                  </div>
                  : ''}
                <div className={classes.fields}>
                  <TextField
                    className={classes.textField}
                    label="Email address"
                    name="email"
                    onChange={event => {
                      dispatch({
                        type: HANDLE_FIELD_CHANGE,
                        fieldType: event.target.type,
                        value: event.target.value,
                        name: 'email',
                      });
                      validateForm();
                    }}
                    type="text"
                    value={values.email}
                    variant="outlined"
                    autoComplete="username"
                  />
                  {showEmailError && (
                    <Typography
                      className={classes.fieldError}
                      variant="body2"
                    >
                      {errors.email[0]}
                    </Typography>
                  )}
                  <TextField
                    className={classes.textField}
                    label="Password"
                    name="password"
                    onChange={event => {
                      dispatch({
                        type: HANDLE_FIELD_CHANGE,
                        fieldType: event.target.type,
                        value: event.target.value,
                        name: 'password',
                      });
                      validateForm();
                    }
                    }
                    type={state.showPassword.password ? 'text' : 'password'}
                    autoComplete="current-password"
                    value={values.password}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={event => dispatch({
                              type: SHOW_PASSWORD,
                              name: 'password'
                            })}
                          >
                            {state.showPassword.password ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {showPasswordError && (
                    <Typography
                      className={classes.fieldError}
                      variant="body2"
                    >
                      {errors.password[0]}
                    </Typography>
                  )}
                  <div className={classes.topBottom2Margin}></div>
                  {/* <div className={classes.rememberPasswordField}>
                    <Checkbox
                      color="primary"
                      defaultChecked
                      onChange={event =>
                        dispatch({
                          type: HANDLE_FIELD_CHANGE,
                          fieldType: event.target.type,
                          value: event.target.value,
                          name: 'staySignedIn',
                        })
                      }
                    />
                    <div>
                      <Typography variant="body1">Stay signed in</Typography>
                    </div>
                  </div> */}
                  {/* <div className={classes.checkboxField}>
                      <Checkbox
                        color="primary"
                        onChange={event =>
                          this.handleFieldChange("password", event.target.value)
                        }
                      />
                      <div>
                        <Typography variant="body1">Login as admin</Typography>
                      </div>
                    </div> */}
                </div>
                <div className={classes.captachaContainer}>
                  {showCaptcha ?
                    <Fragment>
                      <ReCAPTCHA
                        sitekey="6Lfpot4UAAAAAC7J_KrKqA9UrqEm3IytdruDCfLF"
                        size="normal"
                        theme="light"
                        onChange={handleReCaptchaOnChange}
                      />
                      {!captchaVerified ?
                        <Typography variant="subtitle2" color="primary">Check the checkbox to continue.</Typography>
                        : ''}
                    </Fragment>
                    : ''}
                </div>
                {submitError && (
                  <Typography className={classes.submitError} variant="body2">
                    {submitError}
                  </Typography>
                )}
                {isLoading ? (
                  <CircularProgress className={classes.progress} />
                ) : (
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!isValid || (showCaptcha && !captchaVerified)}
                    onClick={event => handleSignIn(event)}
                    size="large"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                )}
                <Typography className={classes.forgotPassword} variant="body1">
                  Can't sign in?{" "}
                  <Link className={classes.forgotPasswordUrl} to="/password/forgot">
                    Forgot password
                    </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

SignIn.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles)
)(SignIn);
