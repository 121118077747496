import React, { Component } from "react";
import YECoverPage from "./YECoverPage";

class YEOneFirstPageRight extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      marketActivity,
      coverPageDetails,
      yearEnd,
      demoAccountAreaName,
      demoAccountCoverPhoto
    } = this.props;
    return (
      <div className="ye1-first-page-right">
        <YECoverPage
          agentData={agentData}
          marketActivity={marketActivity}
          coverPageDetails={coverPageDetails}
          currentArea={currentArea}
          yearEnd={yearEnd}
          hoData={hoData}
          demoAccountAreaName={demoAccountAreaName}
          demoAccountCoverPhoto={demoAccountCoverPhoto}
        />
      </div>
    );
  }
}

export default YEOneFirstPageRight;
