import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    dialogContentMain: {
        background: "#FBFBF5",
    },
});

export const MailProDialogBody = ({ children, ...rest }) => {
    const classes = useStyles();
    return <DialogContent className={classes.dialogContentMain}  {...rest}>{children}</DialogContent>
}


MailProDialogBody.propTypes = {
    children: PropTypes.node,
};