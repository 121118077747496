import React, { Component } from "react";

import QVFourEmailAndWebsiteFooter from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourEmailAndWebsiteFooter";
import DashboardSunset from "./dashboard-sunset-img.jpg";
import DashboardPalmTree from "./dashboard-palmtree.jpg";
import MarketSalesTable from "views/Pma/PmaEditor/Quarterly/QVTwo/QVTwoSecondPage/MarketSalesTable";
import QV2MarketIndicators from "views/Pma/PmaEditor/Quarterly/QVTwo/QVTwoSecondPage/QV2MarketIndicators";
import CloudSection from "views/Pma/PmaEditor/Quarterly/QVTwo/QVTwoSecondPage/CloudSection";
import QV2AvailableHomes from "views/Pma/PmaEditor/Quarterly/QVTwo/QVTwoSecondPage/QV2AvailableHomes";

import TQAvailableHomes from "views/Pma/PmaEditor/TableQuadrants/TableQuadrantsPageTwo/TQAvailableHomes";

import MQThreeMarketSalesTable from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/MQThreeMarketSalesTable";
import MQThreeAvailableHomes from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/MQThreeAvailableHomes";
import MQThreeMarketIndicators from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/MQThreeMarketIndicators";
import HomeValueChanged from "views/Pma/PmaEditor/BubbleCompare/BubbleCompareSecondPage/HomeValueChanged";

class BubbleCompareSecondLeft extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      // require("./bubble-compare-print.scss");
      require("./may-2024-bubble-compare-print.scss");
    } else {
      require("./may-2024-bubble-compare-editor.scss");
    }
  }

  render() {
    const {
      agentData,
      MQCityDetails,
      printVersion,
      currentArea,
      hoData,
      qArea,
      qCity,
      demoAccountAreaName,
      quarterlyAreaTable,
      quarterlyCityTable,
      demoAccountCity,
    } = this.props;

    return (
      <div className="bubble-compare-second-left">
        <HomeValueChanged
          agentData={agentData}
          MQCityDetails={MQCityDetails}
          printVersion={printVersion}
          currentArea={currentArea}
          hoData={hoData}
          quarterlyAreaTable={quarterlyAreaTable}
          quarterlyCityTable={quarterlyCityTable}
        />
      </div>
    );
  }
}

export default BubbleCompareSecondLeft;
{
  /*

<div className="bubble-compare-second-left">
  <div className="mq3-second-left-middle-section">
    <MQThreeMarketSalesTable
      quarterlyCityTable={quarterlyCityTable}
      MQCityDetails={quarterlyCityTable}
      currentArea={currentArea}
      quarterlyAreaTable={
        useCity ? quarterlyCityTable : quarterlyAreaTable
      }
      useCity="false"
      hoData={hoData}
      agentData={agentData}
      demoAccountAreaName={demoAccountAreaName}
      MQCityDetails={MQCityDetails}
      lastMidQuarterlyPrintDate={
        quarterlyAreaTable.lastMidQuarterlyPrintDate
      }
      //printDate="Nov 1, 2021"
      printVersion={printVersion}
    />
    <MQThreeMarketIndicators
      quarterlyAreaTable={quarterlyAreaTable}
      currentArea={currentArea}
      useCity={useCity}
      quarterlyCityTable={quarterlyCityTable}
      MQCityDetails={quarterlyCityTable}
    />
  </div>

  <TQAvailableHomes
    currentArea={currentArea}
    quarterlyAreaTable={quarterlyAreaTable}
    quarterlyCityTable={quarterlyCityTable}
    MQCityDetails={quarterlyCityTable}
    hoData={hoData}
    agentData={agentData}
    demoAccountAreaName={demoAccountAreaName}
    demoAccountCity={demoAccountCity}
    useCity={useCity}
    printVersion={printVersion}
  />
  <QVFourEmailAndWebsiteFooter
    agentData={agentData}
    currentArea={currentArea}
      printVersion={printVersion}
  />

*/
}
