//small S for PMA's on last header
//more padding between header and bottom border
//when hovering over print, increase font
import React, {
  Component,
  Fragment,
  useState,
  useEffect,
  useContext,
} from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "../styles";
import styles2 from "./styles.scss";
import "./styles.scss";
import { Dashboard as DashboardLayout } from "layouts";
import { withSnackbar } from "notistack";
import { Portlet } from "components";
import { PortletContent } from "components";
import { PortletHeader } from "components";
import classNames from "classnames";
import { GetAreaPropHighlights, GetYearEndStatement } from "services/others";
import Base from "common/constants";
import { GetStateStorage, IsValidSessionSwitch } from "common/storage";
import moment from "moment";
import { GetPmaAdminDataAPI } from "services/agent";
import { showErrorNotification } from "common/helpers";
import { showSuccessNotification } from "common/helpers";
import { withRouter } from "react-router-dom";
import { Store } from "Store";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";
import { propertyUtil } from "views/Pma/PropertyUtil";

const YearEndStatement = (props) => {
  const { classes } = props;
  const { dispatch } = useContext(Store);
  //session related variables
  const isAValidSessionSwitch = IsValidSessionSwitch();
  const { userData } = GetStateStorage();

  /**loading variables */
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [yearEndData, setYearEndData] = useState([]);
  const [isYTD, setIsYTD] = useState(false);
  //checkf or admin
  const isAdminAccess =
    isAValidSessionSwitch ||
    userData.listgenRoleID === Base.APP_DATA.APP_ADMIN_ROLE_ID;

  const getYearEndStatement = () => {
    const { roundSalePrice } = propertyUtil;
    const { year, listgenUserID } = props.match.params;
    console.log("props.match.params", props.match.params);
    const { isMemberOfTeam } = userData;
    console.log("getting year end statement");

    setIsLoading(true);
    GetYearEndStatement(listgenUserID, year)
      // GetYearEndStatement(userData.listgenUserID, 2022)
      .then((data) => {
        console.log("coming from the backend upon loading", data);
        if (Object.keys(data).length > 0) {
          console.log("data is", data);

          setYearEndData(data);
        } else {
          console.log("else set to 0");
          setYearEndData({});
        }
      })
      .catch((err) => {
        console.log("catching it");
        setYearEndData({});
      });
  };

  useEffect(() => {
    console.log("inside year end statement use effect calling api");
    window.scrollTo(0, 0);
    if (Object.keys(yearEndData).length === 0) {
      getYearEndStatement();
    }
  }, []);

  // console.log("yearEndData", yearEndData);
  // console.log("userData", userData);
  const {
    listgenUserFirstName,
    listgenUserLastName,
    listgenUserID,
    emailAddress,
    mobilePhoneNo,
  } = userData;
  let pmasArray = [];
  console.log("yearendData", yearEndData);
  let finalTotalPmas = yearEndData.map((area) => area.eachAreaDetails);
  for (let i = 0; i < finalTotalPmas.length; i++) {
    let row = finalTotalPmas[i].map((area) => +area.numOfPMAs);
    let charges = finalTotalPmas[i].map((area) => +area.orgPmaCost);
    console.log("row is ", row);
    pmasArray.push(...row);
  }
  console.log("finalTotalPmas", finalTotalPmas);
  console.log("pmasArray", pmasArray);
  let sumOfTotalPmas = pmasArray.reduce((a, b) => a + b, 0);
  console.log("yearendData", yearEndData);
  let sumOfTotalCharges = yearEndData.reduce((a, b) => a + b.total, 0);
  console.log("sumOfTotalPmas", sumOfTotalPmas);
  console.log("sumOfTotalCharges", sumOfTotalCharges);
  const { year } = props.match.params;
  let date = new Date();
  let thisYear = date.getFullYear();
  const thisMonth = date.toLocaleString("default", { month: "short" });
  const today = `${date.getDate()}`.padStart(2, 0);
  const { splitAreas } = propertyUtil;

  return (
    <Fragment>
      <DocumentTitle title="Year End Statement">
        <DashboardLayout title="" isCompleteInvisible={true}>
          <div className={classes.root}>
            <Portlet className={classes.contentPadded}>
              <PortletContent noPadding>
                <div className="year-end-statement">
                  <div className="yes-header">
                    <div className="yes-header-inner">
                      <div className="yes-header-left">
                        <div>ListGen</div>
                      </div>
                      <div className="yes-header-right">
                        <div>print</div>
                        <div>download</div>
                      </div>
                    </div>
                  </div>
                  <div className="yes-body">
                    <div className="agent-information">
                      <div className="agent-information-top">
                        <div className="agent-name">
                          {listgenUserFirstName} {listgenUserLastName}
                        </div>
                        <div className="account-number">
                          Acct # {listgenUserID}
                        </div>
                      </div>
                      <div className="agent-information-divider" />
                      <div className="agent-phone-number">
                        {mobilePhoneNo.slice(0, 3)}-{mobilePhoneNo.slice(3, 6)}-
                        {mobilePhoneNo.slice(6)}
                      </div>
                      <div className="agent-email">{emailAddress}</div>
                    </div>
                    <div className="ytd-title">{year} Year-End statement</div>
                    <div className="ytd-dates">
                      <div className="ytd-date-range">
                        {year === 2021 ? (
                          <div>Jan 1, 2021 - Dec 31, 2021</div>
                        ) : (
                          <div>
                            Jan 1, 2022 - {thisMonth} {today}, {year}
                          </div>
                        )}
                      </div>
                      <div className="ytd-divider-container">
                        <div className="ytd-divider" />
                      </div>
                    </div>
                    <div className="ytd-total">
                      <div>y-t-d total:</div>
                      <div>$6,900,000</div>
                    </div>
                    <div className="invoice-table">
                      <div className="invoice-table-header">
                        <div></div>
                        <div></div>
                        <div>
                          <div>Invoice #</div>
                        </div>
                        <div>
                          <div>Invoice</div>
                          <div>Date</div>
                        </div>
                        <div>
                          <div>Amount</div>
                        </div>
                        <div>
                          <div>Areas</div>
                        </div>
                        <div>
                          <div>Total Pmas</div>
                          <div>Printed & Mailed</div>
                        </div>
                      </div>
                      <div className="invoice-table-body">
                        {yearEndData.map((row, index) => {
                          const { eachAreaDetails } = row;
                          //console.log("**eachAreaDetails", eachAreaDetails);
                          let splitDate = row.invoiceDate.split(" ")[0];
                          let mapped = eachAreaDetails.map(
                            (area) => +area.numOfPMAs
                          );
                          //console.log("mapped", mapped);
                          let totalPmas = mapped.reduce((a, b) => a + b, 0);
                          //  console.log("totalPmas", totalPmas);
                          return (
                            <div
                              className="invoice-table-row"
                              key={row.invoiceNumber}
                            >
                              <div className="view-invoice">
                                <div>View</div>
                                <div>Invoice</div>
                              </div>
                              <div className="number">
                                <div>{index + 1}</div>
                              </div>
                              <div className="invoice-table-number">
                                <div>{row.invoiceNumber}</div>
                              </div>
                              <div>
                                <div>{splitDate}</div>
                              </div>
                              <div>
                                <div>${Number(row.total).toLocaleString()}</div>
                              </div>
                              <div className="areas-col">
                                {row.eachAreaDetails.map((area) => {
                                  let splitArea = splitAreas(area.areaName);

                                  console.log("area is", area);
                                  console.log("splitArea", splitArea);

                                  let mappedAreas = splitArea.map(
                                    (dividedArea) => {
                                      console.log(
                                        "divided area is",
                                        dividedArea
                                      );
                                      return <div>{dividedArea}</div>;
                                    }
                                  );
                                  console.log("mappedAreas", mappedAreas);
                                  return (
                                    <div className="mapped-area-box">
                                      {mappedAreas}
                                    </div>
                                  );
                                })}
                              </div>
                              <div>
                                <div>{totalPmas.toLocaleString()}</div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="total-pmas">
                        <div className="total-pmas-text">
                          {sumOfTotalPmas.toLocaleString()}
                        </div>
                      </div>
                      <div className="yes-summary">
                        <div className="credits">
                          <div className="credits-text">Total Credits</div>
                          <div className="credits-data">-$300.00</div>
                        </div>
                        <div className="charges">
                          <div className="charges-text">Total Charges</div>
                          <div className="charges-data">
                            ${sumOfTotalCharges.toLocaleString()}
                          </div>
                        </div>
                        <div className="year-to-date-total">
                          <div className="year-to-date-total-text">
                            Y-T-D Total:
                          </div>
                          <div className="year-to-date-total-data">
                            $XXXXXXX
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </PortletContent>
            </Portlet>
          </div>
        </DashboardLayout>
      </DocumentTitle>
    </Fragment>
  );
};

YearEndStatement.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose(
  withSnackbar,
  withStyles(styles),
  withRouter
)(YearEndStatement);
