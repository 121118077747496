import React, {
  // Component,
  Fragment,
} from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
// import _ from 'lodash';
import { Grid, TextField, Typography } from '@material-ui/core';
import styles from './styles';
import { withSnackbar } from 'notistack';
import compose from 'recompose/compose';

const Step2 = props => {
  const {
    classes,
    // className,
    handleFieldChange,
    // validateForm
  } = props;
  // const rootClassName = classNames(classes.root, className);
  const {
    values,
    touched,
    errors,
    // isValid,
    // isLoading,
    haveErrors
  } = props;

  /**Error handling */
  const showMailingAddressLine1Error =
    touched.mailingAddressLine1 && errors.mailingAddressLine1;
  const showMailingAddressLine2Error =
    touched.mailingAddressLine2 && errors.mailingAddressLine2;
  const showCityError = touched.city && errors.city;
  const showStateError = touched.state && errors.state;
  const showZipcodeError = touched.zipcode && errors.zipcode;

  return (
    <Fragment>
      <Grid container className={classes.grid} spacing={0}>
        <Grid item lg={4}>
          <div className={classes.field}>
            <TextField
              className={classes.textField}
              label="Mailing Address"
              margin="dense"
              type="text"
              required
              helperText={
                <Fragment>
                  This is the address your personal PMA will be mailed to.
                  Preferably your <strong>HOME ADDRESS</strong>, not your office address.
                </Fragment>
              }
              //variant="outlined"
              name="mailingAddressLine1"
              value={values.mailingAddressLine1}
              error={haveErrors(showMailingAddressLine1Error)}
              onChange={event => {
                handleFieldChange(
                  'mailingAddressLine1',
                  event.target.value,
                  event
                );
              }}
            />
            {showMailingAddressLine1Error && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.mailingAddressLine1[0]}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={4}>
          {/* <div className={classes.field}>
            <TextField
              className={classes.textField}
              label="Mailing Address Line 2"
              margin="dense"
              type="text"
              //variant="outlined"
              name="mailingAddressLine2"
              value={values.mailingAddressLine2}
              error={haveErrors(showMailingAddressLine2Error)}
              onChange={event => {
                handleFieldChange(
                  'mailingAddressLine2',
                  event.target.value,
                  event
                );
              }}
            />
            {showMailingAddressLine2Error && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.mailingAddressLine2[0]}
              </Typography>
            )}
          </div> */}
        </Grid>
      </Grid>
      <Grid container className={classes.grid} spacing={0}>
        <Grid item lg={4}>
          <div className={classes.field}>
            <TextField
              className={classNames(
                classes.textField,
                classes.dropdownTopMargin
              )}
              label="City"
              margin="dense"
              type="text"
              required
              //variant="outlined"
              name="city"
              value={values.city}
              error={haveErrors(showCityError)}
              onChange={event => {
                handleFieldChange('city', event.target.value, event);
              }}
            />
            {showCityError && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.city[0]}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={1}>
          <div className={classes.field}>
            <TextField
              className={classNames(
                classes.textField,
                classes.dropdownTopMargin
              )}
              label="State"
              margin="dense"
              type="text"
              required
              inputProps={{ maxLength: 2 }}
              name="state"
              value={values.state.toUpperCase()}
              error={haveErrors(showStateError)}
              onChange={event => {
                handleFieldChange(
                  'state',
                  event.target.value.toUpperCase(),
                  event
                );
              }}
            />
            {showStateError && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.state[0]}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={1} />
        <Grid item lg={2}>
          <div className={classes.field}>
            <TextField
              className={classNames(
                classes.textField,
                classes.dropdownTopMargin
              )}
              label="Zip Code"
              margin="dense"
              type="text"
              required
              //variant="outlined"
              name="zipcode"
              inputProps={{ maxLength: 10 }}
              value={values.zipcode}
              error={haveErrors(showZipcodeError)}
              onChange={event => {
                handleFieldChange('zipcode', event.target.value, event);
              }}
            />
            {showZipcodeError && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.zipcode[0]}
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles)
)(Step2);
