import React, { Component, Fragment } from "react";

import { CircularProgress, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { pmaActions } from "_store/_actions";
import YEOne from "./YEOne";
import YETwo from "./YETwo";

class YearEnd2022 extends Component {
  render() {
    const { isLoading } = this.props;
    const {
      printVersion,
      version,
      agentData,
      hoData,
      pma,
      currentArea,
      mlsAreas,
      navbarData,
      coverPageDetails,
      agentAdvantages,
      testimonials,
      yearEnd,
      quarterlyAreaTable,
      quarterlyCityTable,
      demoAccountAreaName,
      demoAccountCoverPhoto,
      typeOfVersion,
      typeOfYEVersion,
      aprilData,
      propertyPhotoDetailsDTO,
      pmaQuarterlyCombinedCityDTO,
      quadrantDetails,
      oldPhotoDetails,
      photoTableData,
      isDemo,
      MQCityDetails,
      bubbleBoxes,
    } = this.props;

    const nonPrintStyle = {
      padding: "10px 0 25px 0",
    };

    if (version === "year-end-2022") {
      return (
        <Fragment>
          <div
            className={"pma-editor " + (printVersion ? "ye-print" : "")}
            style={printVersion ? null : nonPrintStyle}
          >
            <div id="back-to-top-anchor-pma-editor-page-1" />

            <YETwo
              agentData={agentData}
              hoData={hoData}
              currentArea={currentArea}
              printVersion={printVersion}
              version={version}
              coverPageDetails={coverPageDetails}
              testimonials={testimonials}
              yearEnd={yearEnd}
              demoAccountAreaName={demoAccountAreaName}
              demoAccountCoverPhoto={demoAccountCoverPhoto}
              aprilData={aprilData}
              propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
              pmaQuarterlyCombinedCityDTO={pmaQuarterlyCombinedCityDTO}
              quarterlyAreaTable={quarterlyAreaTable}
              quadrantDetails={quadrantDetails}
              oldPhotoDetails={oldPhotoDetails}
              photoTableData={photoTableData}
              quarterlyCityTable={quarterlyCityTable}
              isDemo={isDemo}
              MQCityDetails={MQCityDetails}
              bubbleBoxes={bubbleBoxes}
              quadrantDetails={quadrantDetails}
            />
            <div id="back-to-top-anchor-pma-editor-page-2" />
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div
            className={"pma-editor " + (printVersion ? "ye-print" : "")}
            style={printVersion ? null : nonPrintStyle}
          >
            <div id="back-to-top-anchor-pma-editor-page-1" />
            <YEOne
              agentData={agentData}
              hoData={hoData}
              currentArea={currentArea}
              printVersion={printVersion}
              version={version}
              coverPageDetails={coverPageDetails}
              testimonials={testimonials}
              yearEnd={yearEnd}
              demoAccountAreaName={demoAccountAreaName}
              demoAccountCoverPhoto={demoAccountCoverPhoto}
            />
            <div id="back-to-top-anchor-pma-editor-page-2" />
          </div>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.pma.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeLoading: (status) => {
      dispatch(pmaActions.changeLoading(status));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(YearEnd2022);
