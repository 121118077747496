import React, { useEffect } from 'react';
import { GetSemiAuthStateStorage, GetStateStorage, IsValidSemiAuth } from 'common/storage';
import { OnLoadRegisterAgentData } from 'services/agent';
import MailProSupport from 'views/Pma/MailPro/components/MailProSupport';

const HelpV1Wrapper = ({ children }) => {
    const [semiStorageData, setSemiStorageData] = React.useState({});
    const isSemiLogin = IsValidSemiAuth();

    useEffect(() => {
        if (isSemiLogin) {
            /**yes we have semi session */
            const semiTempStorageData = GetSemiAuthStateStorage();
            getRegisterAgentOnLoadData(semiTempStorageData.emailAddress);
        }
    }, [isSemiLogin]);

    const getRegisterAgentOnLoadData = (emailAddress) => {
        try {
            OnLoadRegisterAgentData(emailAddress)
                .then(data => {
                    if (Object.keys(data).length > 0) {
                        setSemiStorageData(data);
                    } else {
                        setSemiStorageData({});
                    }
                })
                .catch(err => {
                    setSemiStorageData({});
                });
        } catch (err) {
            setSemiStorageData({});
        }
    };

    const getLocalData = () => {
        let data = {};
        if (isSemiLogin) {
            data = semiStorageData;
        } else {
            let localStateData = GetStateStorage();
            data =
                localStateData && localStateData.userData ? localStateData.userData : {};
        }
        return data;
    };

    const agentData = getLocalData();

    return (
        <MailProSupport
            accessedFromAreaID={null}
            accessedFromAreaName={'-'}
            agentData={agentData}
        >
            {children}
        </MailProSupport>
    );
};

export default HelpV1Wrapper;