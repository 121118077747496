import React, { useEffect, useState, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Typography } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import styles from './styles';
import LegalTermsCompass from './indexV1_compass';
import LegalTermsNonCompass from './indexV1_others';

const LegalTerms = props => {
    const {brokerageName } = props;

    return (
        brokerageName === 'Compass' ? <LegalTermsCompass {...props} /> :  <LegalTermsNonCompass {...props} />
    )
};

LegalTerms.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    agentFirstName: PropTypes.string.isRequired,
    agentLastName: PropTypes.string.isRequired,
    navbarInfo: PropTypes.object.isRequired,
    brokerageName: PropTypes.object.isRequired,
};

LegalTerms.defaultProps = {
    agentFirstName: '',
    agentLastName: '',
    navbarInfo: {},
    brokerageName: ''
  };

export default withStyles(styles)(LegalTerms);

