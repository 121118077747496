//TODO: Find another package to show stripe billing without a popup modal

import React, { Component, Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles, Divider } from '@material-ui/core';
import _ from 'lodash';
import { Grid, TextField, Typography, Checkbox, IconButton, Button } from '@material-ui/core';
import styles from './styles';
import { withSnackbar } from 'notistack';
import compose from 'recompose/compose';
import StripePinLogo from '../../../../_img/stripe-blue-solid.png';

import Amex from '../../../../_img/registrationIcons/amex.png';
import Discover from '../../../../_img/registrationIcons/discover.png';
import Mastercard from '../../../../_img/registrationIcons/mastercard.png';
import Visa from '../../../../_img/registrationIcons/visa.png';

import StripeLock from '../../../../_img/registrationIcons/stripe-lock.webp';

import {
  Portlet,
  PortletContent,
  PortletFooter,
} from 'components';

import { LockOutlined, InfoOutlined } from '@material-ui/icons';
import { CreditCardComponent } from 'components';

const Step5 = props => {
  const {
    classes,
    className,
    handleFieldChange,
    validateForm,
    copyValuesFromMailingToBilling,
  } = props;
  const rootClassName = classNames(classes.root, className);
  const { values, touched, errors, isValid, isLoading, registerToken, email, completeAgentRegistration } = props;

  const [openAutoPayDialog, setAutoPayDialog] = React.useState(false);

  const [tokenData, setTokenData] = React.useState(null);

  const [creditCardData, setCreditCardData] = React.useState(null);

  const [isCreditCardInfoValid, setCreditCardInfoValid] = React.useState(null);

  const setSameAddress = checkedValue => {
    copyValuesFromMailingToBilling(checkedValue);
  };

  // useEffect(() => {
  //   /**call for another case. if value is true from starting */
  //   if (values.billingAddresSameAsMailingAddress) {
  //     setSameAddress(true);
  //   }
  // });

  /**Stripe methods */

  // const handleRegisterToken = (token) => { };

  // const onToken = (token) => {
  //   /**Token data received call callback */
  //   setTokenData(token);
  //   setAutoPayDialog(true);
  // };

  // const handleAgreeandSubmit = () => {
  //   /**set token data first */

  //   addStripeTokenData(tokenData);
  // };

  // const handleStripeOpened = () => { };

  // const handleStripeClosed = () => { };

  // const handleConfirmationResponse = (dialogResponse) => {
  //   setAutoPayDialog(false);
  //   addAutoPayConfirmation(dialogResponse);
  // };

  const handleCreditCardData = (creditCardData) => {
    setCreditCardData(creditCardData);
  };

  const handleCreditCardValidity = (isValid) => {
    setCreditCardInfoValid(isValid);
  };

  /**Use effect to handle is valid */
  useEffect(() => {
    /**its a virtualized field change. not gonna show any error */
    if (isCreditCardInfoValid) {
      let expiryArray = creditCardData.expiry.split('/');
      handleFieldChange('nameOnCard', creditCardData.name);
      handleFieldChange('cardNumber', creditCardData.number.replace(/\D+/g, ''), null);
      handleFieldChange('expiryMonth', expiryArray[0], null);
      handleFieldChange('expiryYear', '20' + expiryArray[1], null);
      handleFieldChange('cvc', creditCardData.cvc, null);
    } else {
      /**make null all */
      handleFieldChange('nameOnCard', '', null);
      handleFieldChange('cardNumber', '', null);
      handleFieldChange('expiryMonth', '', null);
      handleFieldChange('expiryYear', '', null);
      handleFieldChange('cvc', '', null);
    }
  }, [isCreditCardInfoValid]);

  /**Error handling */
  const showFirstNameError =
    touched.billingFirstName && errors.billingFirstName ? true : false;
  const showLastNameError = touched.billingLastName && errors.billingLastName ? true : false;
  const showBillingAddressLine1Error =
    touched.billingAddressLine1 && errors.billingAddressLine1 ? true : false;
  const showBillingAddressLine2Error =
    touched.billingAddressLine2 && errors.billingAddressLine2 ? true : false;
  const showCityError = touched.billingCity && errors.billingCity ? true : false;
  const showStateError = touched.billingState && errors.billingState ? true : false;
  const showZipcodeError = touched.billingZipcode && errors.billingZipcode ? true : false;

  return (
    <Fragment>
      {/* {openAutoPayDialog && tokenData ?
        <AutoPayDialog
          openDialog={openAutoPayDialog}
          handleConfirmationResponse={handleConfirmationResponse}
          handleAgreeandSubmit={handleAgreeandSubmit}
          cardData={tokenData}
        />
        :
        ''
      } */}
      <Grid container className={classes.grid} spacing={0}>
        <Grid item lg={10}>
          <div className={classes.field}>
            <div className={classes.checkboxField}>
              <Checkbox
                color="primary"
                checked={values.billingAddresSameAsMailingAddress}
                onChange={event => {
                  // handleFieldChange(
                  //   'billingAddresSameAsMailingAddress',
                  //   event.target.value,
                  //   event
                  // );
                  setSameAddress(event.target.checked);
                }}
              />
              <div>
                <Typography variant="subtitle2">
                  Is the billing address same as mailing address?
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item lg={2}>
        </Grid>
      </Grid>
      {!values.billingAddresSameAsMailingAddress ?
        <Fragment>
          <Grid
            container
            className={classNames(classes.grid, classes.dropdownTopMargin)}
            spacing={0}
          >
            <Grid item lg={4}>
              <div className={classes.field}>
                <TextField
                  className={classes.textField}
                  autoFocus
                  label="First Name"
                  margin="dense"
                  type="text"
                  required
                  //variant="outlined"
                  name="billingFirstName"
                  helperText="Please enter the first and last name exactly as it appears on your credit card."
                  value={values.billingFirstName}
                  error={showFirstNameError}
                  onChange={event => {
                    handleFieldChange(
                      'billingFirstName',
                      event.target.value,
                      event
                    );
                  }}
                />
                {showFirstNameError && (
                  <Typography className={classes.fieldError} variant="body2">
                    {errors.billingFirstName[0]}
                  </Typography>
                )}
              </div>
            </Grid>

            <Grid item lg={4}>
              <div className={classes.field}>
                <TextField
                  className={classes.textField}
                  label="Last Name"
                  margin="dense"
                  type="text"
                  required
                  //variant="outlined"
                  name="billingLastName"
                  value={values.billingLastName}
                  error={showLastNameError}
                  onChange={event => {
                    handleFieldChange('billingLastName', event.target.value, event);
                  }}
                />
                {showLastNameError && (
                  <Typography className={classes.fieldError} variant="body2">
                    {errors.billingLastName[0]}
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid container className={classes.grid} spacing={0}>
            <Grid item lg={4}>
              <div className={classes.field}>
                <TextField
                  className={classNames(
                    classes.textField,
                    classes.dropdownTopMargin
                  )}
                  label="Billing Address"
                  margin="dense"
                  type="text"
                  required
                  //variant="outlined"
                  name="billingAddressLine1"
                  value={values.billingAddressLine1}
                  error={showBillingAddressLine1Error}
                  onChange={event => {
                    handleFieldChange(
                      'billingAddressLine1',
                      event.target.value,
                      event
                    );
                  }}
                />
                {showBillingAddressLine1Error && (
                  <Typography className={classes.fieldError} variant="body2">
                    {errors.billingAddressLine1[0]}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item lg={4} />
            <Grid item lg={4} />
          </Grid>
          {/* <Grid container className={classes.grid} spacing={0}>
            <Grid item lg={4}>
              <div className={classes.field}>
                <TextField
                  className={classNames(
                    classes.textField,
                    classes.dropdownTopMargin
                  )}
                  label="Billing Address Line 2"
                  margin="dense"
                  type="text"
                  //variant="outlined"
                  name="billingAddressLine2"
                  value={values.billingAddressLine2}
                  error={showBillingAddressLine2Error}
                  onChange={event => {
                    handleFieldChange(
                      'billingAddressLine2',
                      event.target.value,
                      event
                    );
                  }}
                />
                {showBillingAddressLine2Error && (
                  <Typography className={classes.fieldError} variant="body2">
                    {errors.billingAddressLine2[0]}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item lg={4} />
            <Grid item lg={4} />
          </Grid> */}
          <Grid container className={classes.grid} spacing={0}>
            <Grid item lg={4}>
              <div className={classes.field}>
                <TextField
                  className={classNames(
                    classes.textField,
                    classes.dropdownTopMargin
                  )}
                  label="City"
                  margin="dense"
                  type="text"
                  required
                  //variant="outlined"
                  name="billingCity"
                  value={values.billingCity}
                  error={showCityError}
                  onChange={event => {
                    handleFieldChange('billingCity', event.target.value, event);
                  }}
                />
                {showCityError && (
                  <Typography className={classes.fieldError} variant="body2">
                    {errors.billingCity[0]}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item lg={1}>
              <div className={classes.field}>
                <TextField
                  className={classNames(
                    classes.textField,
                    classes.dropdownTopMargin
                    // classes.smallField
                  )}
                  label="State"
                  margin="dense"
                  type="text"
                  required
                  //variant="outlined"
                  name="billingState"
                  value={values.billingState.toUpperCase()}
                  inputProps={{ maxLength: 2 }}
                  error={showStateError}
                  onChange={event => {
                    handleFieldChange('billingState', event.target.value.toUpperCase(), event);
                  }}
                />
                {showStateError && (
                  <Typography className={classes.fieldError} variant="body2">
                    {errors.billingState[0]}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item lg={2}>
              <div className={classes.field}>
                <TextField
                  className={classNames(
                    classes.textField,
                    classes.dropdownTopMargin
                  )}
                  label="Zip Code"
                  margin="dense"
                  type="text"
                  required
                  //variant="outlined"
                  name="billingZipcode"
                  value={values.billingZipcode}
                  error={showZipcodeError}
                  inputProps={{ maxLength: 10 }}
                  onChange={event => {
                    handleFieldChange('billingZipcode', event.target.value, event);
                  }}
                />
                {showZipcodeError && (
                  <Typography className={classes.fieldError} variant="body2">
                    {errors.billingZipcode[0]}
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </Fragment>
        : ''}
      <Grid
        container
        className={classes.grid}
      >
        <Grid item lg={12} md={12} xs={12} sm={12}>
          <Divider className={classes.centerDivider} />
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.grid}
      >
        {/* <Grid item lg={3} md={3} xs={12} sm={12}></Grid> */}
        <Grid item lg={12} md={12} xs={12} sm={12}>
          <Portlet outlined={false}>
            <PortletContent>
              <div className={classes.poweredByStripe}>
                <div className={classes.stripeLogoImageWrapper}>
                  <img
                    src={StripeLock}
                    className={classes.poweredByStripeImage}
                    alt="Powered By Stripe"
                  />
                </div>
              </div>
              <div className={classes.stripeBody}>
                <Grid item lg={12}>
                  <CreditCardComponent
                    holdername={(values.firstName ? values.firstName + ' ' + values.lastName : '')}
                    handleCreditCardData={handleCreditCardData}
                    handleCreditCardValidity={handleCreditCardValidity}
                    name={values.nameOnCard}
                    cardNumber={values.cardNumber}
                    expiryMonth={values.expiryMonth}
                    expiryYear={values.expiryYear}
                    cvc={values.cvc}
                  />
                </Grid>
              </div>
              <div className="stripeText">
                {/* <Typography variant="body2">
                  Stripe validates card information when it is saved. As a result of this process, customers may see a temporary authorization for $1 on their statement. This does not guarantee that any future charges succeed (e.g., the card no longer has sufficient funds, is reported lost or stolen, or if the account is closed). This process also updates the results of any checks, including traditional bank checks by Radar (e.g., CVC or postal code), that may have been performed.
          </Typography> */}
              </div>
              <div className={classes.paymentSecureBox}>
                <div className={classes.paymentIconHolder}>
                  <div>
                    <img
                      src={Amex}
                      alt="American Express"
                      className={classes.paymentIcon}
                    />
                  </div>
                  <div>
                    <img
                      src={Discover}
                      alt="Discover"
                      className={classes.paymentIcon}
                    />
                  </div>
                  <div>
                    <img
                      src={Visa}
                      alt="Visa"
                      className={classes.paymentIcon}
                    />
                  </div>
                  <div>
                    <img
                      src={Mastercard}
                      alt="Master Card"
                      className={classes.paymentIcon}
                    />
                  </div>
                </div>
                <div className={classes.paymentFooter}>
                  <IconButton>
                    <LockOutlined />
                  </IconButton>
                  <Typography variant="subtitle2">
                    Secure Stripe Payment (256 bit SSL)
             </Typography>
                </div>
              </div>
            </PortletContent>
            <PortletFooter>
              <div className={classes.portletFooter}>
                <div>
                  <IconButton color="primary">
                    <InfoOutlined fontSize='large' />
                  </IconButton>
                </div>
                <div className={classes.bottomText}>
                  <Typography variant="subtitle2" className={classes.dialogContent}>
                    Your credit card information is encrypted, saved and held on file by STRIPE.
                        </Typography>
                  <Typography variant="subtitle2" className={classes.dialogContent}>
                    Your credit card will be automatically charged with each mailing.
                        </Typography>
                </div>
              </div>
            </PortletFooter>
          </Portlet>

        </Grid>
        <Grid item lg={12} md={12} sm={3} xs={3}>

        </Grid>
      </Grid>
    </Fragment>
  );
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles)
)(Step5);
