import React, { useEffect, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import classNames from 'classnames';
import {
    Button,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Divider,
    Grid,
    TextField
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { AssistantsProfile } from '..';
import _ from 'lodash';
import schema from './schema';
import validate from 'validate.js';
import { isAValidZipCode } from 'views/AgentDetails/AgentDetailsUtils';

const AgentAssistants = React.memo((props) => {
    let { classes, maxWidth, onDialogClose, setAssistantFieldandAutoSave, currentAssistantData = [], listgenUserID, designationData } = props;
    const [open, setOpen] = React.useState(false);
    const [rowCount, setRowCount] = React.useState(1);  

    const valuesSkeleton = {
        assistantID: null,
        assistantFirstName: '',
        assistantLastName: '',
        phone: '',
        emailID: '',
        mailingAddr1: '',
        city: '',
        state: '',
        zipCode: '',
        sendPMACopy: false
    };

    const [localState, setLocalState] = React.useState([]);
    const [errors, setErrors] = React.useState([]);

    const handleDialog = () => {
        setOpen(true);
    }

    const handleClose = () => {
        //onDialogClose(false);
        setOpen(false);
    }

    const getEmptyValues = () => {
        return _.cloneDeep(valuesSkeleton);
    }

    useEffect(() => {
        if (open) {
            if (currentAssistantData.length > 0) {
                setRowCount(currentAssistantData.length);
                setLocalState(currentAssistantData);
            } else {
                setRowCount(1);
                setLocalState([getEmptyValues()]);
            }
        }
    }, [open]);

    const customTeamMemberValidator = () => {
        const newState = _.cloneDeep(localState);
        let errors = [];
        for (let key in newState) {
            const keyErrors = validate(newState[key], schema);
            let zipCodeError = isAValidZipCode("zipCode", newState[key].zipCode, []);
            if (!newState[key].sendPMACopy) {
                delete keyErrors.mailingAddr1;
                delete keyErrors.city;
                delete keyErrors.state;
                delete keyErrors.zipCode;
                zipCodeError = {};
            }
            if ((keyErrors && Object.keys(keyErrors).length > 0) || (zipCodeError && zipCodeError.length > 0)) {
                errors[key] = { ...keyErrors, ...zipCodeError };
            }
        }
        setErrors(errors);
        return errors;
    };

    const submitData = () => {
        const errors = customTeamMemberValidator();
        if (errors.length === 0) {
            let localData = _.cloneDeep(localState);
            let returnData = [];
            for (let key in localData) {
                let tempData = _.cloneDeep(localData[key]);
                if (!tempData.sendPMACopy) {
                    tempData = {
                        ...tempData, ...{
                            mailingAddr1: '',
                            city: '',
                            state: '',
                            zipCode: ''
                        }
                    };
                }
                returnData[key] = tempData;
            }
            setAssistantFieldandAutoSave(returnData);
            handleClose();
        }
    };

    const addMoreAssistant = (currentRowCount) => {
        setRowCount(currentRowCount + 1);
        const newState = _.cloneDeep(localState);
        newState.push(getEmptyValues());
        setLocalState(newState);
    }

    const deleteRow = (rowID) => {
        setRowCount(rowCount - 1);
        const newState = _.cloneDeep(localState);
        let newData = newState.filter((item, key) => (key !== rowID));
        setLocalState(newData);
    }

    const cleanRow = (keyID) => {
        try {
            const newState = _.cloneDeep(localState);
            newState[keyID] = getEmptyValues();
            setLocalState(newState);
        } catch (err) {
        }
    };

    const clearAll = () => {
        try {
            let data = [];
            for (let i = 0; i < rowCount; i++) {
                data.push(getEmptyValues());
            }
            setLocalState(data);
        } catch (err) {
        }
    };

    const handleFieldChange = (field, value, keyID) => {
        try {
            const newState = _.cloneDeep(localState);
            let localData = _.cloneDeep(newState[keyID]);
            if (field === 'phone') {
                localData[field] = value.replace(/\D/g, '');

            } else if (field === "zipCode") {
                localData[field] = value.replace(/[^0-9-]/g, "");
            } else {
                localData[field] = value;
            }
            newState[keyID] = _.cloneDeep(localData);
            setLocalState(newState);

            if (errors[keyID] && errors[keyID][field]) {
                let newErrors = _.cloneDeep(errors);
                newErrors[keyID][field] = [];
                setErrors(newErrors);
            }
        } catch (err) {
            console.log("Error in handleFieldChange", err);
        }
    };


    return (
        <React.Fragment>
            <div className={classes.inlineAssistantButton}>
                <Typography
                    variant='subtitle2'>
                    {currentAssistantData.length > 0 ? `You have ${currentAssistantData.length} Assistant${currentAssistantData.length > 1 ? 's' : ''} added.` : `Want to add Assistant's Details?`}
                </Typography>
                <Button onClick={handleDialog} variant='contained' color='primary' size='small'>
                    {currentAssistantData.length > 0 ? 'Click here to Manage them' : 'Click here to Add Assistant Details'}
                </Button>
            </div>
            <Dialog
                fullWidth={true}
                maxWidth={maxWidth}
                open={open}
                onClose={event => onDialogClose(false)}
                aria-labelledby="max-width-dialog-title"
                className={classes.dialogMain}
                scroll={"paper"}
            >

                <div id="scroll-dialog-title" className={classes.dialogTitle}>
                    <Typography
                        className={classes.title}
                        variant="subtitle1"
                    >
                        Agent Assistants
                    </Typography>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        A maximum of 4 assistants can be loaded into system.
                    </Typography>
                    <IconButton aria-label="Close" className={classes.closeButton} onClick={event => handleClose()}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Divider variant="middle" />
                <div className={classes.requiredFields}>
                    <Typography
                        className={classes.fieldError}
                        variant="subtitle2"
                    >
                        <sup>
                            *
                        </sup>&nbsp;Required fields.
                    </Typography>

                </div>
                <DialogContent className={classes.dialogContent}>
                    {localState.length === 0 && <Typography variant="subtitle2" className={classes.noAssistantText}>
                    It appears that there are no assistants added. Please add assistant details by clicking on the "Add First Assistant" button.  <br/>
                    If you have just removed all the assistants, press the "Save" button to save the changes.
                    </Typography>}
                    {
                        localState.map((item, key) => {
                            return (item ? <AssistantsProfile key={"team__assistant_dialog_" + key} keyID={key}
                                deleteRow={deleteRow}
                                handleFieldChange={handleFieldChange}
                                cleanRow={cleanRow}
                                stateData={localState}
                                errors={errors}
                            />
                                : '')
                        })
                    }
                </DialogContent>

                <DialogActions className={classes.portletFooter}>
                    <div className={classes.footerButtons}>
                        <div className={classes.footerButtonsLeft}>
                            {rowCount !== 0 && <Button
                                variant="outlined"
                                onClick={event => clearAll()}
                            >
                                Clear
                            </Button>}
                        </div>
                        <div className={classes.footerButtonsCenter}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={event => submitData()}
                            >
                                Save
                            </Button>
                        </div>
                        <div className={classes.footerButtonsRight}>
                            {rowCount >= 4 ?
                                <Fragment>
                                    <Typography variant="subtitle2" className={classes.redColor}>
                                        Your maximum of 4 assistants limit reached
                                    </Typography>
                                </Fragment>
                                : <Fragment>
                                    <Button
                                        //size="small" 
                                        color="primary"
                                        variant="contained"
                                        onClick={event => addMoreAssistant((rowCount))}
                                        className={classes.textAlignRight}
                                    >
                                       {rowCount === 0 ? "Add First Assistant" : "Add Another Assistant"}
                                    </Button>
                                </Fragment>}

                        </div>

                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
});

export default withStyles(styles)(AgentAssistants);