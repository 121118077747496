import React, { Component, useEffect, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles, CircularProgress, OutlinedInput } from '@material-ui/core';
import validate from 'validate.js';
import schema from './schema';
import _ from 'lodash';
import {
    Grid,
    TextField,
    Button,
    Select,
    Typography,
    MenuItem,
    Input,
    Checkbox,
    ListItemText,
    FormControl,
    InputLabel,
    FormHelperText,
    ListItemIcon,
    Avatar,
    Link as AnchorLink
} from '@material-ui/core';
import { Portlet, PortletContent, PortletFooter } from 'components';
import styles from './styles';
import { CreateAgentAPI } from 'services/agent';
import { showErrorNotification } from 'common/helpers';
import { showSuccessNotification } from 'common/helpers';
import { withSnackbar } from 'notistack';
import compose from 'recompose/compose';
import { Store } from 'Store';
import { RESET_APP_STATE } from 'store/types';
import { HANDLE_VALIDATIONS } from 'store/types';
import { START_LOADING } from 'store/types';
import { STOP_LOADING } from 'store/types';
import { HANDLE_FIELD_CHANGE } from 'store/types';
import { GetOfficeForDesignatedBroker } from 'services/broker';
import { GetAgentsForOffice } from 'services/broker';
import { CHANGE_MULTITPLE_DATA_INTO_STATE } from 'store/types';
import { showInfoNotification } from 'common/helpers';
import { AgentDetailCard } from '..';
import { CityAreaMapDialog } from 'components';
import { GetCityAreaMap } from 'services/others';
import { GetAgentsToBeInvitedForACounty } from 'services/others';

const AgentForm = (props) => {
    const { state, dispatch } = React.useContext(Store);
    const [officeLabelWidth, setOfficeLabelWidth] = React.useState(0);
    const [agentLabelWidth, setAgentLabelWidth] = React.useState(0);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [areaData, setAreaData] = React.useState([]);
    const [areaOverallData, setAreaOverallData] = React.useState([]);
    const [enableDreProcees, setDreProcess] = React.useState(false);
    /** To handle local state */
    const [localState, setLocalState] = React.useState({
        officeID: '',
        agentID: '',
        officeData: [],
        agentData: [],
    });
    /**county data */
    const [countyData, setCountyData] = React.useState({
        "27": 'Monterey',
        "44": 'Santa Cruz'
    });
    /**mls data */
    const [mlsData, setMLSData] = React.useState({});

    let InputLabelRef;
    let OfficeLabelRef;
    let AgentLabelRef;
    const { classes, className } = props;
    const rootClassName = classNames(classes.root, className);
    const {
        values,
        touched,
        errors,
        isValid,
        isLoading,
    } = state;

    /**Error handling */
    const showFirstNameError = touched.firstName && errors.firstName;
    const showLastNameError = touched.lastName && errors.lastName;
    const showEmailError = touched.email && errors.email;
    const showdreNumberError = touched.dreNumber && errors.dreNumber;
    const showLicenseError = touched.license && errors.license;
    const showownedMlsIdError = touched.ownedMlsId && errors.ownedMlsId;
    const showBrokerageNameError = touched.brokerageName && errors.brokerageName;
    const showCountyError = touched.selectedCountyId && errors.selectedCountyId;

    useEffect(() => {
        /**reset app also */
        dispatch({
            type: RESET_APP_STATE
        });
        /**set label width */
        //setLabelWidth(ReactDOM.findDOMNode(InputLabelRef).offsetWidth);
        /**validate form */
        validateForm();
    }, []);

    const handleCountyChange = (countyID, fieldType) => {
        countyID = parseInt(countyID);
        //reset the local state now
        resetLocalState();
        // //reset the state first
        dispatch({
            type: RESET_APP_STATE
        });
        dispatch({
            type: HANDLE_FIELD_CHANGE,
            fieldType: fieldType,
            value: countyID,
            name: 'selectedCountyId',
        });
        validateForm();
        //hide the dre process if the county id is 44
        setDreProcess(countyID === 44 ? false : true);
        if (countyID === 44) {
            //only call this for 44 county
            getAgentsToBeInvited(countyID);
            showInfoNotification(props, 'Select an agent to autofill form and continue.');
        } else {
            showInfoNotification(props, 'Enter brokerage license number to continue.');
        }
        getCityAreaData(countyID);
    };


    const validateForm = _.debounce(() => {
        dispatch({
            type: HANDLE_VALIDATIONS,
            schema
        });
    }, 300);

    const serviceSetOfficeData = (officeData) => {
        setLocalState(prevState => ({
            ...prevState, ...{
                officeID: '',
                agentID: '',
                officeData,
                agentData: []
            }
        }
        ));
    };

    const serviceSetAgentData = (officeID, agentData) => {
        setLocalState(prevState => ({
            ...prevState, ...{
                agentData,
                officeID,
                agentID: ''
            }
        }));
    };

    const sortAgentData = (data, keytoSort) => {
        return data.sort((a, b) => (b[keytoSort] > a[keytoSort] ? -1 : b[keytoSort] < a[keytoSort] ? 1 : 0));
    };

    const getAgentsToBeInvited = (countyID) => {
        GetAgentsToBeInvitedForACounty(countyID)
            .then(data => {
                if (Array.isArray(data) && data.length > 0) {
                    serviceSetAgentData('', sortAgentData(data, 'listgenUserFirstName'));
                    //set label width for the santa cruz area selection dropdown
                    setAgentLabelWidth(180);
                } else {
                    showInfoNotification(props, 'No agents found in the selected county.');
                    serviceSetAgentData('', []);
                }
            })
            .catch(err => {
                serviceSetAgentData('', []);
            });
    };

    const getCityAreaData = (countyID) => {
        /**make loading true */
        GetCityAreaMap(countyID)
            .then(data => {
                if (Object.keys(data).length > 0) {
                    setMLSData(data);
                } else {
                    showInfoNotification(props, 'No city found in the selected county.');
                    setMLSData({});
                }
            })
            .catch(err => {
                setMLSData({});
            });
    };

    const loadOffices = (dreNo) => {
        if (dreNo.length === 8 && !isNaN(dreNo)) {
            GetOfficeForDesignatedBroker(dreNo).then(data => {
                /**Check for data length also */
                if (Array.isArray(data) && data.length === 0) {
                    showInfoNotification(props, 'No office found for entered dre number.');
                    serviceSetOfficeData([]);
                    return;
                }
                serviceSetOfficeData(data);
                /**set office label width */
                setOfficeLabelWidth(90);
            }).catch(err => {
                serviceSetOfficeData([]);
                showErrorNotification(props, 'Unable to load offices.');
            });
        }
    };

    const loadAgents = (officeID) => {
        if (officeID !== '') {
            const newState = { ...localState };
            newState.officeID = officeID;
            setLocalState(newState);
            GetAgentsForOffice(officeID).then(data => {
                /**Check for data length also */
                if (Array.isArray(data) && data.length === 0) {
                    showInfoNotification(props, 'No agent found in selected office.');
                    serviceSetAgentData(officeID, []);
                    return;
                }
                serviceSetAgentData(officeID, sortAgentData(data, 'fullName'));
                /**set agent label width */
                setAgentLabelWidth(90);
                /**reset values also */
                fillFormValues('');
            }).catch(err => {
                serviceSetAgentData(officeID, []);
                fillFormValues('');
                showErrorNotification(props, 'Unable to load agetns.');
            });
        } else {
            /**office id already set */
            serviceSetAgentData(officeID, []);
            fillFormValues('');
        }
    };

    const setStateValue = (key, value) => {
        const newState = { ...localState };
        newState[key] = value;
        setLocalState(newState);
    };

    const resetLocalState = () => {
        setLocalState({
            dreNumber: '',
            officeID: '',
            agentID: '',
            officeData: [],
            agentData: [],
            ownedMlsId: []
        });
        /**reset values also */
        fillFormValues('');
        /** reset values for county 44 */
        fillFormValuesForCounty44('');
        /**reset overall data also */
        setAreaOverallData([]);
        /**reset the area data also */
        setAreaData([]);
        /** blank the agent data  */
        setMLSData({});
        //set default dre process
        setDreProcess(false);
    };

    const fillFormValues = (agentID) => {
        try {
            const newState = { ...localState };
            const { officeData, officeID } = localState;
            let newData = newState.agentData.find(item => item.agentID === agentID);
            newData = newData ? newData : {};
            /**get the brokerage name also */
            const xOfficeData = officeData ? officeData.find(item => item.officeID === officeID) : {};
            const brokerageName = (xOfficeData && xOfficeData.officeName ? xOfficeData.officeName : '');
            /**if agent id is blank than fill brokerage name with null. i.e. It's the case when we want to reset the values. */
            let foundData = {
                firstName: (newData.firstName ? newData.firstName : ''),
                lastName: (newData.lastName ? newData.lastName : ''),
                license: (newData.licenseNumber ? newData.licenseNumber : ''),
                email: (newData.agentEmailAddress ? newData.agentEmailAddress : ''),
                brokerageName: agentID ? brokerageName : ''
            };
            dispatch({
                type: CHANGE_MULTITPLE_DATA_INTO_STATE,
                data: foundData
            });
            validateForm();
        } catch (err) {
            //console.log(err);
        }
    };

    const fillFormValuesForCounty44 = (agentID) => {
        try {
            const newState = { ...localState };
            let newData = newState.agentData.find(item => item.listgenUserID === agentID);
            /**if agent id is blank than fill brokerage name with null. i.e. It's the case when we want to reset the values. */
            let foundData = {
                firstName: (newData.listgenUserFirstName ? newData.listgenUserFirstName : ''),
                lastName: (newData.listgenUserLastName ? newData.listgenUserLastName : ''),
                dreNumber: (newData.brokerLicenseNumber ? newData.brokerLicenseNumber : ''),
                license: (newData.agentLicenseNumber ? newData.agentLicenseNumber : ''),
                email: (newData.emailAddress ? newData.emailAddress : ''),
                brokerageName: (newData.brokerageName ? newData.brokerageName : '')
            };
            dispatch({
                type: CHANGE_MULTITPLE_DATA_INTO_STATE,
                data: foundData
            });
            validateForm();
        } catch (err) {
            //console.log(err);
        }
    };

    const saveAgent = () => {
        /**make loading true */
        dispatch({
            type: START_LOADING
        });
        const { firstName, lastName, email, dreNumber, license, ownedMlsId, brokerageName, selectedCountyId } = state.values;
        try {
            //send differen data based on county id
            let data = {
                listgenUserFirstName: firstName,
                listgenUserLastName: lastName,
                brokerLicenseNumber: dreNumber,
                agentLicenseNumber: license,
                emailAddress: email,
                ownedMLSID: ownedMlsId,
                brokerageName: brokerageName
            };
            if (!enableDreProcees) {//county id is 44
                const newState = { ...localState };
                let newData = newState.agentData.find(item => item.listgenUserID === newState.agentID);
                data = Object.assign(data, {
                    listgenUserID: newData.listgenUserID,
                    listgenRoleID: newData.listgenRoleID
                });
            }
            CreateAgentAPI(data).then(data => {
                switch (Number(data)) {
                    case 0:
                        showSuccessNotification(props, "Agent created.");
                        /**clean the form */
                        dispatch({
                            type: RESET_APP_STATE
                        });
                        resetLocalState();
                        break;
                    case 1:
                        showErrorNotification(props, "Some unexpected error occured.");
                        break;
                    case 2:
                        showErrorNotification(props, "Email already associated with other agent.");
                        break;
                    default:
                        showErrorNotification(props, "Something went wrong. Please try after some time.");
                        break;
                }
                /**stop loading also */
                dispatch({
                    type: STOP_LOADING
                });

            }).catch(err => {
                showErrorNotification(props, "Unable to process request.");
                dispatch({
                    type: STOP_LOADING
                });

            });
        } catch (err) {
            console.log(err)
            showErrorNotification(props, "Unable to process request.");
            dispatch({
                type: STOP_LOADING
            });

        }
    };

    const getPhotoUrl = (photoURL) => {
        if (Array.isArray(photoURL)) {
            return photoURL[0];
        } else {
            return photoURL;
        }
    };

    const openCityDialog = (event) => {
        event.preventDefault();
        setOpenDialog(true);
    };

    const onCityDialogClose = () => {
        setOpenDialog(false);
        dispatch({
            type: HANDLE_FIELD_CHANGE,
            fieldType: 'textbox',
            value: areaOverallData.map(item => item.id),
            name: 'ownedMlsId',
        });
        validateForm();
    };

    const onCityDialogValueSubmit = (chipsData) => {
        setOpenDialog(false);
        setAreaOverallData(chipsData);
        dispatch({
            type: HANDLE_FIELD_CHANGE,
            fieldType: 'textbox',
            value: chipsData.map(item => item.id),
            name: 'ownedMlsId',
        });
        setAreaData(chipsData.map(item => item.label + ' ' + '(' + item.id + ')'));
        validateForm();
    };


    return (
        <Fragment>
            <CityAreaMapDialog
                open={openDialog}
                optionData={mlsData}
                onClose={onCityDialogClose}
                selectedCountyID={state.values.selectedCountyId}
                selectedAreas={Array.isArray(values.ownedMlsId) ? values.ownedMlsId : []}
                onSubmit={onCityDialogValueSubmit}
            />
            <Portlet className={rootClassName}>
                <PortletContent noPadding>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <Grid container className={classes.grid} spacing={0}>
                            <Grid item lg={4}>
                                <div className={classes.field}>
                                    <TextField
                                        className={classNames(classes.textField)}
                                        label="Select County"
                                        margin="dense"
                                        select
                                        SelectProps={{ native: false, shrink: true }}
                                        name="selectedCountyId"
                                        value={state.values.selectedCountyId}
                                        onChange={event => {
                                            handleCountyChange(event.target.value, event.target.type)
                                        }}
                                        variant="outlined"
                                        helperText={'Select the county id'}
                                    >
                                        {/* <MenuItem key="agent-search-county-form-blank-value" value="">
                                            <em>View All Counties</em>
                                        </MenuItem> */}
                                        {Object.keys(countyData).map((key) => (
                                            <MenuItem key={key} value={key}>
                                                {countyData[key]}&nbsp;({key})
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {showCountyError && (
                                        <Typography
                                            className={classes.fieldError}
                                            variant="body2"
                                        >
                                            {errors.status[0]}
                                        </Typography>
                                    )}
                                </div>
                            </Grid>
                            <Grid item lg={4}>
                                {!enableDreProcees && state.values.selectedCountyId ? <div className={classes.field}>
                                    <FormControl
                                        variant="outlined"
                                        className={classNames(classes.textField, classes.dropdownTopMargin1UnitPlus)}
                                        disabled={!localState.agentData.length}
                                        required={true}
                                    >
                                        <InputLabel
                                            ref={ref => {
                                                AgentLabelRef = ref;
                                            }}
                                            htmlFor="outlined-agent-id"
                                            className={classes.centerLabel}
                                        >
                                            {localState.agentData.length ? `Select an Agent for ${countyData[state.values.selectedCountyId]} County` : `Loading agents...`}
                                        </InputLabel>
                                        <Select
                                            name="agentID"
                                            margin="dense"
                                            value={localState.agentID}
                                            input={
                                                <OutlinedInput
                                                    labelWidth={agentLabelWidth}
                                                    name="SELECT_AGENT_ID"
                                                    id="outlined-agent-id"
                                                />
                                            }
                                            onChange={event => {
                                                setStateValue('agentID', event.target.value);
                                                fillFormValuesForCounty44(event.target.value);
                                            }
                                            }
                                            renderValue={selected => {
                                                let agentData = localState.agentData.find(item => item.listgenUserID === selected);
                                                return (<div>{agentData.listgenUserFirstName}&nbsp;{agentData.listgenUserLastName}</div>)
                                            }}
                                        >
                                            {/* <MenuItem key="agent-blank-value" value="">
                                                <em>None</em>
                                            </MenuItem> */}
                                            {localState.agentData.map(item => (
                                                <MenuItem key={item.listgenUserID} value={item.listgenUserID}>
                                                    <p>{item.listgenUserFirstName}&nbsp;{item.listgenUserLastName} (Agent License Number: {item.agentLicenseNumber})</p>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>Select an agent to autofill form.</FormHelperText>
                                    </FormControl>
                                </div> : ''}

                            </Grid>
                            <Grid item lg={4}></Grid>
                        </Grid>

                        <Grid container className={classes.grid} spacing={0}>
                            <Grid item lg={4}>
                                <div className={classes.field}>
                                    <TextField
                                        className={classNames(classes.textField, classes.dropdownTopMargin)}
                                        label="Brokerage License Number"
                                        margin="dense"
                                        type="text"
                                        required
                                        variant="outlined"
                                        name="dreNumber"
                                        helperText="Enter brokerage license number to get office and agent details."
                                        inputProps={{ minLength: 7, maxLength: 8 }}
                                        value={state.values.dreNumber}
                                        onChange={event => {
                                            dispatch({
                                                type: HANDLE_FIELD_CHANGE,
                                                fieldType: event.target.type,
                                                value: event.target.value,
                                                name: 'dreNumber',
                                            });
                                            validateForm();
                                            //only call this if dre process
                                            return (enableDreProcees ? loadOffices(event.target.value) : '');
                                        }
                                        }
                                    />
                                    {showdreNumberError && (
                                        <Typography
                                            className={classes.fieldError}
                                            variant="body2"
                                        >
                                            {errors.dreNumber[0]}
                                        </Typography>
                                    )}
                                </div>
                            </Grid>
                            <Grid item lg={4}
                            >
                                {enableDreProcees && localState.officeData.length > 0 ? <div className={classes.field}>
                                    <FormControl
                                        variant="outlined"
                                        className={classNames(classes.textField, classes.dropdownTopMargin)}
                                        required={true}>
                                        <InputLabel
                                            ref={ref => {
                                                OfficeLabelRef = ref;
                                            }}
                                            htmlFor="outlined-office-id"
                                            className={classes.centerLabel}
                                        >
                                            Select Office
                                        </InputLabel>
                                        <Select
                                            name="officeID"
                                            margin="dense"
                                            value={localState.officeID}
                                            input={
                                                <OutlinedInput
                                                    labelWidth={officeLabelWidth}
                                                    name="SELECT_OFFICE_ID"
                                                    id="outlined-office-id"
                                                />
                                            }
                                            onChange={event => {
                                                loadAgents(event.target.value);
                                            }
                                            }
                                        >
                                            <MenuItem key="office-blank-value" value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {localState.officeData.map(option => (
                                                <MenuItem key={option.officeID} value={option.officeID}>
                                                    {option.officeName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>Select office to load agents.</FormHelperText>
                                    </FormControl>
                                </div> : ''}
                            </Grid>
                            <Grid item lg={4}
                            >
                                {(enableDreProcees && localState.agentData.length > 0) ? <div className={classes.field}>
                                    <FormControl
                                        variant="outlined"
                                        className={classNames(classes.textField, classes.dropdownTopMargin)}
                                        required={true}>
                                        <InputLabel
                                            ref={ref => {
                                                AgentLabelRef = ref;
                                            }}
                                            htmlFor="outlined-agent-id"
                                            className={classes.centerLabel}
                                        >
                                            Select an Agent for {countyData[state.values.selectedCountyId]} County
                                        </InputLabel>
                                        <Select
                                            name="agentID"
                                            margin="dense"
                                            value={localState.agentID}
                                            input={
                                                <OutlinedInput
                                                    labelWidth={agentLabelWidth}
                                                    name="SELECT_AGENT_ID"
                                                    id="outlined-agent-id"
                                                />
                                            }
                                            onChange={event => {
                                                setStateValue('agentID', event.target.value);
                                                fillFormValues(event.target.value);
                                            }
                                            }
                                            renderValue={selected => {
                                                let agentData = localState.agentData.find(item => item.agentID === selected);
                                                return (<div>{agentData.fullName}</div>)
                                            }}
                                        >
                                            <MenuItem key="agent-blank-value" value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {localState.agentData.map(option => (
                                                <MenuItem key={option.agentID} value={option.agentID}>
                                                    <ListItemIcon className={classes.icon}>
                                                        {option.agentPhotoURLs ?
                                                            <Avatar src={getPhotoUrl(option.agentPhotoURLs)} />
                                                            :
                                                            <Avatar>{option.fullName.charAt(0).toUpperCase()}</Avatar>
                                                        }
                                                    </ListItemIcon>
                                                    <ListItemText classes={{ primary: classes.primary }} inset primary={option.fullName} />

                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>Select agent to fill from automatically.</FormHelperText>
                                    </FormControl>
                                </div> : ''}
                            </Grid>
                        </Grid>
                        <Grid container className={classes.grid} spacing={0}>
                            <Grid item lg={12}>
                                <div className={classNames(classes.field, classes.dropdownTopMargin2Unit)}>
                                    {localState.agentID && enableDreProcees ?
                                        <AgentDetailCard
                                            //for 27 county and 44 county data format would be different. only show this card for the county 27.
                                            agentData={localState.agentData.find(item => (item.agentID === localState.agentID))}
                                        />
                                        : ''
                                    }

                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className={classNames(classes.grid)} spacing={0}>
                            <Grid item lg={4}>
                                <div className={classes.field}>
                                    <TextField
                                        className={classNames(classes.textField, classes.dropdownTopMargin)}
                                        label="First name"
                                        margin="dense"
                                        required
                                        variant="outlined"
                                        name="firstName"
                                        value={state.values.firstName}
                                        onChange={event => {
                                            dispatch({
                                                type: HANDLE_FIELD_CHANGE,
                                                fieldType: event.target.type,
                                                value: event.target.value,
                                                name: 'firstName',
                                            });
                                            validateForm();
                                        }
                                        }
                                    />
                                    {showFirstNameError && (
                                        <Typography
                                            className={classes.fieldError}
                                            variant="body2"
                                        >
                                            {errors.firstName[0]}
                                        </Typography>
                                    )}
                                    <TextField
                                        className={classNames(classes.textField)}
                                        label="Agent License Number"
                                        margin="dense"
                                        required
                                        variant="outlined"
                                        name="license"
                                        value={state.values.license}
                                        onChange={event => {
                                            dispatch({
                                                type: HANDLE_FIELD_CHANGE,
                                                fieldType: event.target.type,
                                                value: event.target.value,
                                                name: 'license',
                                            });
                                            validateForm();
                                        }
                                        }
                                    />
                                    {showLicenseError && (
                                        <Typography
                                            className={classes.fieldError}
                                            variant="body2"
                                        >
                                            {errors.license[0]}
                                        </Typography>
                                    )}
                                </div>
                            </Grid>
                            <Grid item lg={4}>
                                <div className={classes.field}>
                                    <TextField
                                        className={classNames(classes.textField, classes.dropdownTopMargin)}
                                        label="Last name"
                                        margin="dense"
                                        required
                                        variant="outlined"
                                        name="lastName"
                                        value={state.values.lastName}
                                        onChange={event => {
                                            dispatch({
                                                type: HANDLE_FIELD_CHANGE,
                                                fieldType: event.target.type,
                                                value: event.target.value,
                                                name: 'lastName',
                                            });
                                            validateForm();
                                        }
                                        }
                                    />
                                    {showLastNameError && (
                                        <Typography
                                            className={classes.fieldError}
                                            variant="body2"
                                        >
                                            {errors.lastName[0]}
                                        </Typography>
                                    )}
                                    <TextField
                                        className={classNames(classes.textField)}
                                        label="Brokerage Name"
                                        margin="dense"
                                        required
                                        variant="outlined"
                                        name="brokerageName"
                                        value={state.values.brokerageName}
                                        onChange={event => {
                                            dispatch({
                                                type: HANDLE_FIELD_CHANGE,
                                                fieldType: event.target.type,
                                                value: event.target.value,
                                                name: 'brokerageName',
                                            });
                                            validateForm();
                                        }
                                        }
                                    />
                                    {showBrokerageNameError && (
                                        <Typography
                                            className={classes.fieldError}
                                            variant="body2"
                                        >
                                            {errors.brokerageName[0]}
                                        </Typography>
                                    )}
                                </div>
                            </Grid>
                            <Grid item lg={4}>
                                <div className={classes.field}>
                                    <TextField
                                        className={classNames(classes.textField, classes.dropdownTopMargin)}
                                        label="Email Address"
                                        margin="dense"
                                        required
                                        variant="outlined"
                                        name="email"
                                        value={state.values.email}
                                        onChange={event => {
                                            dispatch({
                                                type: HANDLE_FIELD_CHANGE,
                                                fieldType: event.target.type,
                                                value: event.target.value,
                                                name: 'email',
                                            });
                                            validateForm();
                                        }
                                        }
                                    />
                                    {showEmailError && (
                                        <Typography
                                            className={classes.fieldError}
                                            variant="body2"
                                        >
                                            {errors.email[0]}
                                        </Typography>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.grid} spacing={0}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className={classes.field}>
                                    <TextField
                                        className={classNames(classes.textField, classes.dropdownTopMargin)}
                                        label="Owned MLS ID Area's"
                                        margin="dense"
                                        required
                                        variant="outlined"
                                        name="ownedMlsIdText"
                                        value={areaData.join(', ')}
                                        onClick={event => openCityDialog(event)}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        disabled={!state.values.selectedCountyId}
                                        helperText={areaOverallData.length === 0 ? 'To select area\'s first select the county' : ''}
                                    />
                                    <Typography variant="body2">
                                        {areaOverallData.map(item => { return item.label + ' ' + '(' + item.id + ')' }
                                        ).join(', ')}
                                    </Typography>
                                    {showownedMlsIdError && (
                                        <Typography
                                            className={classes.fieldError}
                                            variant="body2"
                                        >
                                            {errors.ownedMlsId[0]}
                                        </Typography>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                </PortletContent>
                <PortletFooter className={classes.portletFooter}>
                    <div className={classes.footerButtons}>
                        <div className={classes.footerButtonsLeftRight}>
                            <Button
                                color="default"
                                variant="contained"
                                onClick={event => {
                                    dispatch({
                                        type: RESET_APP_STATE
                                    });
                                    resetLocalState();
                                }
                                }
                            >
                                Clear Data
          </Button>
                        </div>
                        <div className={classes.footerButtonsCenter}>
                            {isLoading ? (
                                <CircularProgress className={classes.progress} />
                            ) : (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={!isValid}
                                    onClick={event => saveAgent()}
                                >
                                    Create Agent
                                </Button>
                            )}
                        </div>
                        <div className={classes.footerButtonsLeftRight}></div>
                    </div>
                </PortletFooter>
            </Portlet>
        </Fragment>
    );
}

export default compose(
    withRouter,
    withSnackbar,
    withStyles(styles)
)(AgentForm);