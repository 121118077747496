import Base from "common/constants";
import querystring from "querystring";
import { GetAuthorization } from "common/helpers";
import { GetOAuthTokenByKey } from "common/storage";
import { isArray } from "util";
import axios from "common/interceptors";

export const GetYearEndStatement = (listgenUserID, year) => {
  console.log("inside services", listgenUserID, year);

  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(
          `${Base.GET_YEAR_END_STATEMENT}?listgenUserID=${listgenUserID}&year=${year}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*",
            },
            data: {},
          }
        )
        .then((res) => {
          if (res.status === 200) {
            console.log("res", res.data);
            resolve(res.data);
            console.log("INSIDE get year end statement *** res.data", res.data);
          } else {
            reject("Unable to process request for year end statement");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};
export const GetPropertyPhotoDetails = (listgenUserID, areaID) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(
          `${Base.GET_AREA_PHOTO_DETAILS}?listgenUserAreaID=${listgenUserID}-${areaID}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*",
            },
            data: {},
          }
        )
        .then((res) => {
          if (res.status === 200) {
            //console.log("res", res.data);
            resolve(res.data);
            //console.log("INSIDE get all map for county *** res.data", res.data);
          } else {
            reject("Unable to process request for GetAreaPropHighlight.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetAreaPropHighlights = (listgenUserID, areaID) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(
          `${Base.GET_AREA_PROP_HIGHLIGHTS}?listgenUserAreaID=${listgenUserID}-${areaID}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*",
            },
            data: {},
          }
        )
        .then((res) => {
          if (res.status === 200) {
            //console.log("res", res.data);
            resolve(res.data);
            //console.log("INSIDE get all map for county *** res.data", res.data);
          } else {
            reject("Unable to process request for GetAreaPropHighlight.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};
export const GetAllCityAreaMapForCounty = (countyID) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(`${Base.GET_ALL_CITY_AREA_DATA_FOR_COUNTY}?countyID=${countyID}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
            //console.log("INSIDE get all map for county *** res.data", res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetCityAreaMap = (countyID) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(`${Base.GET_CITY_AREA_MAP}?countyID=` + countyID, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetAgentsToBeInvitedForACounty = (countyID) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(
          `${Base.GET_AGENTS_TO_BE_INVITED_FOR_COUNTY}?countyID=` + countyID,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*",
            },
            data: {},
          }
        )
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetAllCityAreaMap = (countyID) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(`${Base.GET_ALL_CITY_AREA_DATA}?countyID=` + countyID, {
          headers: {
            "Content-Type": "application/json",
            //'Authorization': `Bearer ${GetOAuthTokenByKey('access_token')}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetTicketCompleteData = (ticketID) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(`${Base.GET_TICKET_COMPLETE_DATA}?ticketID=` + ticketID, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const SubmitHelpRequestAPI = (
  listgenUserID,
  userFullName,
  userEmail,
  userPhone,
  userSubject,
  userMessage,
  requestType,
  cityName = null,
  areaName = null
) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(
          Base.SUBMIT_HELP_REQUEST,
          {
            listgenUserID,
            status: "New",
            fullName: userFullName,
            email: userEmail,
            phone: userPhone,
            subject: userSubject,
            message: userMessage,
            helpType: requestType,
            cityName,
            areaName,
          },
          {
            headers: {
              "Content-Type": "application/json",
              //'Authorization': `Bearer ${GetOAuthTokenByKey('access_token')}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetAllRequestData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(`${Base.GET_ALL_REQUESTS_DATA}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetOneSpecificRequestData = (requestID) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(
          `${Base.GET_ONE_SPECIFIC_REQUESTS_DATA}?listgenRequestID=` +
            requestID,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*",
            },
            data: {},
          }
        )
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetOneSpecificRequestNotesData = (requestID) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(
          `${Base.GET_ONE_SPECIFIC_REQUEST_NOTES_DATA}?listgenRequestID=` +
            requestID,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*",
            },
            data: {},
          }
        )
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const UpdateRequestNotesDataAPI = (inputData) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(Base.UPDATE_LISTGEN_REQUEST_NOTES_DATA, inputData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const UpdateRequestStatusDataAPI = (listgenRequestID, status) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(
          `${Base.UPDATE_LISTGEN_REQUEST_STATUS_DATA}?listgenRequestID=${listgenRequestID}&status=${status}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*",
            },
            data: {},
          }
        )
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetAllDesignationsData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(`${Base.GET_ALL_AGENT_DESIGNATIONS_DATA}`, {
          headers: {
            "Content-Type": "application/json",
            //'Authorization': `Bearer ${GetOAuthTokenByKey('access_token')}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetAllLGConstantData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(`${Base.LG_CONSTANT_GET_ALL}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetaLGConstantData = (constantName) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(`${Base.LG_CONSTANT_GET_ONE}?constantName=${constantName}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(
              res.data && res.data.constantValue ? res.data.constantValue : ""
            );
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const AddAConstantDataAPI = (constantName, constantValue) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(
          `${
            Base.LG_CONSTANT_ADD_NEW
          }?constantName=${constantName}&constantValue=${encodeURIComponent(
            constantValue
          )}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*",
            },
            data: {
              constantName,
              constantValue,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const UpdateAConstantDataAPI = (constantName, constantValue) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(
          `${
            Base.LG_CONSTANT_UPDATE_ONE
          }?constantName=${constantName}&constantValue=${encodeURIComponent(
            constantValue
          )}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*",
            },
            data: {
              constantName,
              constantValue,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const DeleteAConstantDataAPI = (constantName, constantValue) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(`${Base.LG_CONSTANT_DELETE_ONE}?constantName=${constantName}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {
            constantName,
          },
        })
        .then((res) => {
          if (res.data) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const UpdateGlobalNotePSForAllAgents = (
  countyID,
  listgenUserID,
  forceUpdate
  //mode='note'
) => {
  return new Promise(async (resolve, reject) => {
    try {
      //(mode === 'note' ? Base.SET_ADMIN_GLOBAL_NOTE_PS_FOR_ALL_AGENTS : Base.SET_GLOBAL_PS_FOR_ALL_AGENTS)
      axios
        .get(
          `${Base.SET_ADMIN_GLOBAL_NOTE_PS_FOR_ALL_AGENTS}?schID=${countyID}&listgenUserID=${listgenUserID}&forceUpdate=${forceUpdate}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*",
            },
            data: {
              schID: countyID,
              listgenUserID: listgenUserID,
              forceUpdate: forceUpdate,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetAdminPMAPdfData = (action, listgenUserId, areaId, version) => {
  return new Promise(async (resolve, reject) => {
    console.log("inside api call for pdf data", version);
    try {
      axios
        .get(
          `${
            action === "view"
              ? Base.GENERATE_PRINT_PMA_URL
              : Base.GENERATE_SINGLE_TEST_PRINT_PDF
          }?listgenUserID=${listgenUserId}&areaID=${areaId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*",
            },
            data: {},
          }
        )
        .then((res) => {
          console.log("res is", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GenerateTestPMAPdfForAllAgents = (
  action,
  listgenUserId,
  areaId
) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(`${Base.GENERATE_TEST_PDFS_FOR_ALL_AGENTS}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

/**
 * HOA & Subdivison
 */
export const GetListOfCitiesByCounty = (countyId) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(`${Base.GET_CITIES_FOR_COUNTY}?countyID=${countyId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};
export const GetListOfAreasByCity = (cityId) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(`${Base.GET_AREA_FOR_CITY}?cityID=${cityId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetHoNameMap = (countyId, areaId) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(`${Base.GET_HO_NAME_MAP}?countyID=${countyId}&areaID=${areaId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetComplexNameMap = (countyId, areaId) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(
          `${Base.GET_COMPLEX_NAME_MAP}?countyID=${countyId}&areaID=${areaId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*",
            },
            data: {},
          }
        )
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetAllHoaNamesAndComplexNames = (countyId, areaId) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(
          `${Base.GET_ALL_HOA_NAMES_AND_COMPLEX_NAMES}?countyID=${countyId}&areaID=${areaId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*",
            },
            data: {},
          }
        )
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const SaveHoaNamesOrComplexNames = (
  countyId,
  areaId,
  mapType,
  mapData
) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(
          Base.SAVE_HOA_NAMES_OR_COMPLEX_NAMES,
          {
            countyID: countyId,
            areaID: areaId,
            hoaOrComplexNameIndicator:
              mapType === "hoa" ? "HOAName" : "ComplexName",
            lgMLSHOANameMap: mapData,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetAgentDetailsBySelectedArea = (areaId) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(`${Base.GET_AGENT_DATA_BY_AREA}?areaID=${areaId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const DeleteHoaComplexNameMapping = (
  countyId,
  areaId,
  mapType,
  mappingName
) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(
          Base.DELETE_HOA_COMPLEX_NAME_MAPPING,
          {
            countyID: countyId,
            areaID: areaId,
            hoaOrComplexNameIndicator:
              mapType === "hoa" ? "HOAName" : "ComplexName",
            hoaOrComplexMapName: mappingName,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const CallGrammerBotApi = (textToCheck) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(
          Base.GRAMMER_BOT_CHECK_API,
          querystring.stringify({
            language: "en-US",
            text: textToCheck,
          }),
          {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              "x-rapidapi-host": Base.APP_DATA.GRAMMER_BOT_HOST,
              "x-rapidapi-key": Base.APP_DATA.GRAMMER_BOT_KEY,
              useQueryString: true,
            },
            timeout: 5000, //5 second timeout
          }
        )
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};
