import React, { Component } from "react";

class Endorsement extends Component {
  render() {
    const { hoData } = this.props;
    return (
      <div>
        {hoData && hoData.trayType && (
          <div className="endorsement">
            <p>{hoData.trayType}</p> <p>{hoData.sortPosition}</p>{" "}
            <p>{hoData.bundleNumber}</p> <p>{hoData.trayNumber}</p>
            {hoData.breakMark && hoData.breakMark !== "-" && (
              <p className="break-mark">{hoData.breakMark} </p>
            )}{" "}
            <p className="pallet-number">{hoData.palletNumber}</p>
          </div>
        )}
      </div>
    );
  }
}

export default Endorsement;
