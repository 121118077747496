import React, { useEffect, useState, Fragment, useContext } from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';

const ScrollToTop = (props) => {
  const { children, window, classes, normalMode, triggerNow, pageMode1, pageMode2, alignment } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document || window).querySelector('#back-to-top-anchor');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });//{ behavior: 'smooth', block: 'center' }
    }
  };

  const handleMailingTableClick = event => {
    const anchor = (event.target.ownerDocument || document || window).querySelector('#back-to-top-anchor-mailing-table');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });//{ behavior: 'smooth', block: 'center' }
    }
  };

  const handleClickPage1 = event => {
    const anchor = (event.target.ownerDocument || document || window).querySelector('#back-to-top-anchor-pma-editor-page-1');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });//{ behavior: 'smooth', block: 'center' }
    }
  };
  const handleClickPage2 = event => {
    if (global.window) {
      let element = document.getElementById('back-to-top-anchor-pma-editor-page-2');
      global.window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth"
      });
    } else {
      const anchor = (event.target.ownerDocument || document || window).querySelector('#back-to-top-anchor-pma-editor-page-2');
      if (anchor) {
        anchor.scrollIntoView({ behavior: 'auto', block: 'start' });//{ behavior: 'smooth', block: 'center' }
      }
    }

  };

  const optionSwitcher = () => {
    if (normalMode && triggerNow) {
      return (
        <Fragment>
          <Zoom in={true}>
            <div onClick={event => handleMailingTableClick(event)} role={"presentation"} className={alignment === 'right' ? classes.root : classes.rootLeft}>
              {children}
            </div>
          </Zoom>
        </Fragment>
      );
    } else if (pageMode1) {
      return (
        <Fragment>
          <Zoom in={trigger}>
            <div onClick={event => handleClick(event)} role="presentation" className={classes.rootForP1}>
              {children}
            </div>
          </Zoom>
        </Fragment>
      );
    } else if (pageMode2) {
      return (
        <Fragment>
          <Zoom in={trigger}>
            <div onClick={event => handleClickPage2(event)} role="presentation" className={classes.rootForP2}>
              {children}
            </div>
          </Zoom>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Zoom in={trigger}>
            <div onClick={event => handleClick(event)} role="presentation" className={alignment === 'right' ? classes.root : classes.rootLeft}>
              {children}
            </div>
          </Zoom>
        </Fragment>
      );
    }
  };

  return optionSwitcher();
}

ScrollToTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

ScrollToTop.defaultProps = {
  alignment: 'right'
};

export default withStyles(styles)(ScrollToTop);