import React, { Component } from "react";

class HomeImprovementTrends extends Component {
  render() {
    return (
      <div className="home-improvement-trends-container">
        <div className="home-improvement-main-title">
          <div className="home-improvement-main-title-text">Trends</div>
          <div className="home-improvement-main-subtitle">
            Outdoor Living Upgrades
          </div>
        </div>
        <div className="home-improvement-image-text-container">
          <p className="home-improvement-image-text">
            Increase your home's value
          </p>
          <p>by focusing on making your</p>
          <p>outdoor areas a continuum of</p>{" "}
          <p>your interior, creating more</p>
          <p>lounge worthy space and</p>
          <p>strong first impressions.</p>
        </div>
        <div className="home-improvement-number-section-column">
          {[1, 2, 3, 4, 5].map(x => (
            <div key={x} className={`number-section section-${x}`}>
              0{x}
            </div>
          ))}
        </div>
        <div className="home-improvement-content-section">
          <div className="home-improvement-image-container"></div>
          <div className="home-improvement-text-container">
            <div className="home-improvement-section">
              <div className="home-improvement-section-title">
                Blur the Boundaries
              </div>
              <div className="home-improvement-section-text">
                Increase your square footage by creating
                <br />a seamless transition from indoor to
                <br />
                outdoor spaces with fabrics, pillows,
                <br />
                and rugs that complement your indoor
                <br /> aesthetic and create visual unity.
              </div>
            </div>

            <div className="home-improvement-section">
              <div className="home-improvement-section-title">
                Lighting is Everything
              </div>
              <div className="home-improvement-section-text">
                Instantly add ambiance and allure with
                <br /> soft outdoor lighting like lanterns that
                <br /> line the deck, uplighting that spotlights
                <br />
                unique details, and strings of Italian
                <br /> bulbs draped over a dining area.
              </div>
            </div>

            <div className="home-improvement-section">
              <div className="home-improvement-section-title">
                Year-Round Enjoyment
              </div>
              <div className="home-improvement-section-text">
                Create an all-season space with an
                <br /> overhead pergola, built-in fire pit, cozy
                <br /> blankets, and optional California Gold
                <br /> gravel for carefree flooring.
              </div>
            </div>

            <div className="home-improvement-section">
              <div className="home-improvement-section-title">
                A Lush Touch of Nature
              </div>
              <div className="home-improvement-section-text">
                There is nothing more enticing than
                <br /> greenery that fills empty spaces and
                <br /> softens the edges. Small raised garden
                <br /> beds create a focal point that pulls
                <br /> nature into play and soothes the senses.
              </div>
            </div>

            <div className="home-improvement-section">
              <div className="home-improvement-section-title">
                First Welcome
              </div>
              <div className="home-improvement-section-text">
                Connect with neighbors and create
                <br /> memorable first impressions with an
                <br /> outfitted front yard or porch — add
                <br /> rattan cushioned chairs, a hanging chair,
                <br /> or a water feature.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeImprovementTrends;
