import palette from "theme/palette";
import { blue, green, red, yellow } from "@material-ui/core/colors";

export default (theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  logoInner: {
    width: "180px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&>div:nth-child(2)": {
      marginTop: theme.spacing(1.5),
    },
  },
  logoWrapper: {
    flexShrink: 0,
    height: "64px",
    display: "flex",
    justifyContent: "flex-start",
    margin: theme.spacing(0, 0, 0, 2),
    alignItems: "center",
  },
  logoLink: {
    width: "auto",
  },
  logoImage: {
    cursor: "pointer",
    width: "100%",
    height: "90%",
  },
  logoDivider: {
    marginBottom: theme.spacing(0),
  },
  profile: {
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "left",
    backgroundImage: "url(/images/connected_world.svg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  avatar: {
    width: "100px",
    height: "100px",
  },
  nameText: {
    marginTop: theme.spacing(2),
  },
  bioText: {},
  profileDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
  listSubheader: {
    color: theme.palette.text.secondary,
  },
  listItem: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: palette.latestLightGreen.main, //theme.palette.primary.light,
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      borderRadius: "4px",
      "& $listItemIcon": {
        //color: palette.primary.light,
        marginLeft: "-4px",
      },
    },
    "& + &": {
      marginTop: theme.spacing(0.5),
    },
  },
  listItemGutter: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  activeListItem: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    borderRadius: "4px",
    backgroundColor: palette.latestLightGreen.main, //theme.palette.primary.light,
    "& $listItemText": {
      color: theme.palette.text.primary,
    },
    "& $listItemIcon": {
      color: theme.palette.text.primary,
      marginLeft: "-4px",
    },
  },
  listItemIcon: {
    marginRight: theme.spacing(1),
    minWidth: "auto",
  },
  listItemTextRoot: {
    //flex: '0.6 1 auto'
  },
  pmaAdminWidth60: {
    width: "60%",
    flex: "none",
  },
  pmaAdminIcon: {
    //marginRight: -theme.spacing(0.5)
  },
  pmaAdminErrorText: {
    color: palette.error.main,
  },
  pmaAdminErrorBg: {
    backgroundColor: palette.error.main,
    color: "white",
  },
  pmaAdminGreenText: {
    color: green[500],
  },
  pmaAdminGreenBg: {
    backgroundColor: green[500],
    color: "white",
  },
  pmaAdminBlueText: {
    color: blue[500],
  },
  pmaAdminBlueBg: {
    backgroundColor: blue[500],
    color: "white",
  },
  listItemText: {
    fontWeight: 500,
    fontSize: "14px",
    color: theme.palette.text.secondary,
    justifyContent: "space-between",
    alignItems: "center",
  },
  // importantTab: {
  //   backgroundColor: theme.palette.primary.neutral,
  //   color: "blue",
  // },
  listItemSpecialSessionSwitchIcon: {
    marginRight: 0,
    color: red[900],
  },
  listItemSpecialSessionSwitchText: {
    fontWeight: 500,
    fontSize: "14px",
    color: red[900],
  },
  listDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  nestedList: {
    paddingLeft: theme.spacing(2),
  },
  listItemTextNested: {
    fontWeight: 500,
    fontSize: "13px",
  },
  title: {
    color: theme.palette.common.primary,
  },
  newBadge: {
    // marginLeft: theme.spacing(3),
    // alignItems: 'flex-end'
  },
});
