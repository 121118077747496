import React, { Component } from "react";

class QVFourDotGraph extends Component {
  lowerPoint = (leftPoint, currentPoint, rightPoint) => {
    const slope = (a, b) => {
      let change = Math.round(((b - a) / Math.abs(a)) * 100);
      return change;
    };
    let leftPercDiff;
    let rightPercDiff;

    if (!leftPoint) {
      leftPercDiff = 0;
    } else {
      leftPercDiff = slope(leftPoint, currentPoint);
    }
    if (!rightPoint) {
      rightPercDiff = 0;
    } else {
      rightPercDiff = slope(currentPoint, rightPoint);
    }

    if (leftPercDiff >= 46 || rightPercDiff < -40) {
      return currentPoint + 22;
    } else {
      return currentPoint - 13;
    }
  };
  calculateTimeFrame = (time) => {
    if (time.includes("Q1")) {
      return "Jan-Mar";
    } else if (time.includes("Q2")) {
      return "Apr-Jun";
    } else if (time.includes("Q3")) {
      return "Jul-Sep";
    } else if (time.includes("Q4")) {
      return "Oct-Dec";
    } else {
      return "";
    }
  };
  render() {
    const {
      dataPoints,
      formattedPoints,
      bgColor,
      colorOrder,
      graphPosition,
      bottomLabelsText,
      printVersion,
    } = this.props;
    const { lowerPoint, calculateTimeFrame } = this;
    let maxNum = Math.max(...dataPoints);
    let minNum = Math.min(...dataPoints);
    let percentage = dataPoints.map((point) => {
      let roundedPerc = Math.round(
        ((point - minNum) / (maxNum - minNum)) * 100
      );
      let inversePerc = 125 - roundedPerc;
      return inversePerc;
    });
    let pointDots = `24,150 25,${percentage[0]} 50,${percentage[0]} 112,${percentage[1]} 174,${percentage[2]} 236,${percentage[3]} 298,${percentage[4]} 325,${percentage[4]} 326,150`;

    let linePoints = `25,${percentage[0]} 50,${percentage[0]} 112,${percentage[1]} 174,${percentage[2]} 236,${percentage[3]} 298,${percentage[4]} 325,${percentage[4]}`;

    let bottomLabelsPrint = [
      [37, 170],
      [97, 170],
      [161, 170],
      [220, 170],
      [280, 170],
      [37, 185],
      [99, 185],
      [164, 185],
      [220, 185],

      [280, 185],
    ];

    let bottomLabelsEditor = [
      [37, 162],
      [97, 162],
      [161, 162],
      [220, 162],
      [280, 162],
      [36, 173],
      [99, 173],
      [163, 173],
      [220, 173],
      [280, 173],
    ];

    let labelCoordsToUse = printVersion
      ? bottomLabelsPrint
      : bottomLabelsEditor;

    {
      /*<text x={37} y={162}>
  {bottomLabelsText[0]}
</text>
<text x={97} y={162}>
  {bottomLabelsText[1]}
</text>
<text x={161} y={162}>
  {bottomLabelsText[2]}
</text>
<text x={220} y={162}>
  {bottomLabelsText[3]}
</text>
<text x={280} y={162}>
  {bottomLabelsText[4]}
</text>
<text x={275} y={175}>
  Thru Nov 1st
</text>*/
    }

    return (
      <div className="slds-p-top--medium">
        <div className="svg-cover">
          <svg className="quiz-graph">
            {/*<line
              x1="25"
              y1="0"
              x2="325"
              y2="0"
              className="graph-line"
              strokeWidth="2"
            />
            <line x1="25" y1="50" x2="325" y2="50" className="graph-line" />
            <line x1="25" y1="100" x2="325" y2="100" className="graph-line" />
            <line x1="25" y1="150" x2="325" y2="150" className="graph-line" />*/}
            <defs>
              <pattern width={25} height={25} patternUnits="userSpaceOnUse">
                <path fill="none" />
              </pattern>
            </defs>
            <rect
              x={25}
              width="276px"
              height="150px"
              fill="url(#grid)"
              stroke={1}
            />
            <g className="bottom-x-labels">
              <text
                x={labelCoordsToUse[0][0]}
                y={labelCoordsToUse[0][1]}
                className="graph-quarter-text"
              >
                {bottomLabelsText[0]}
              </text>
              <text
                x={labelCoordsToUse[1][0]}
                y={labelCoordsToUse[1][1]}
                className="graph-quarter-text"
              >
                {bottomLabelsText[1]}
              </text>
              <text
                x={labelCoordsToUse[2][0]}
                y={labelCoordsToUse[2][1]}
                className="graph-quarter-text"
              >
                {bottomLabelsText[2]}
              </text>
              <text
                x={labelCoordsToUse[3][0]}
                y={labelCoordsToUse[3][1]}
                className="graph-quarter-text"
              >
                {bottomLabelsText[3]}
              </text>

              <text
                x={labelCoordsToUse[4][0]}
                y={labelCoordsToUse[4][1]}
                className="graph-quarter-text"
              >
                {bottomLabelsText[4]}
              </text>
              <text x={labelCoordsToUse[5][0]} y={labelCoordsToUse[5][1]}>
                {calculateTimeFrame(bottomLabelsText[0])}
              </text>
              <text x={labelCoordsToUse[6][0]} y={labelCoordsToUse[6][1]}>
                {calculateTimeFrame(bottomLabelsText[1])}
              </text>
              <text x={labelCoordsToUse[7][0]} y={labelCoordsToUse[7][1]}>
                {calculateTimeFrame(bottomLabelsText[2])}
              </text>
              <text x={labelCoordsToUse[8][0]} y={labelCoordsToUse[8][1]}>
                {calculateTimeFrame(bottomLabelsText[3])}
              </text>
              <text x={labelCoordsToUse[9][0]} y={labelCoordsToUse[9][1]}>
                {calculateTimeFrame(bottomLabelsText[4])}
              </text>
            </g>
            <linearGradient id={`grad1`} x1="0%" y1="0%" x2="0%" y2="125%">
              <stop
                offset="0%"
                style={{ stopColor: "rgba(71,117,115,0.8)", stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: "white", stopOpacity: 0 }}
              />
            </linearGradient>

            <linearGradient id={`grad2`} x1="0%" y1="0%" x2="0%" y2="125%">
              <stop
                offset="0%"
                style={{
                  stopColor: "rgba(178,139,65, 0.8)",
                  stopOpacity: 1,
                }}
              />
              <stop
                offset="100%"
                style={{ stopColor: "white", stopOpacity: 0 }}
              />
            </linearGradient>

            <linearGradient id={`grad3`} x1="0%" y1="0%" x2="0%" y2="125%">
              <stop
                offset="0%"
                style={{
                  stopColor: "rgba(114,53,79, 0.8)",
                  stopOpacity: 1,
                }}
              />
              <stop
                offset="100%"
                style={{ stopColor: "white", stopOpacity: 0 }}
              />
            </linearGradient>
            <polyline
              fill={`url(#grad${graphPosition})`}
              strokeWidth={0}
              points={pointDots}
            />
            <polyline
              fill="none"
              stroke={bgColor}
              strokeWidth={2}
              points={linePoints}
            />
            <g>
              <circle
                className={`quiz-graph-dot ${colorOrder}`}
                cx={50}
                cy={percentage[0]}
                r={4}
              />
              <circle
                className={`quiz-graph-dot ${colorOrder}`}
                cx={112}
                cy={percentage[1]}
                r={4}
              />
              <circle
                className={`quiz-graph-dot ${colorOrder}`}
                cx={174}
                cy={percentage[2]}
                r={4}
              />
              <circle
                className={`quiz-graph-dot ${colorOrder}`}
                cx={236}
                cy={percentage[3]}
                r={4}
              />
              <circle
                className={`quiz-graph-dot ${colorOrder}`}
                cx={298}
                cy={percentage[4]}
                r={4}
              />
            </g>
            <g className="data-labels price-range">
              <text x={50} y={lowerPoint(null, percentage[0], percentage[1])}>
                {formattedPoints[0]}
              </text>
              <text
                x={112}
                y={lowerPoint(percentage[0], percentage[1], percentage[2])}
              >
                {formattedPoints[1]}
              </text>
              <text
                x={175}
                y={lowerPoint(percentage[1], percentage[2], percentage[3])}
              >
                {formattedPoints[2]}
              </text>
              <text
                x={237}
                y={lowerPoint(percentage[2], percentage[3], percentage[4])}
              >
                {formattedPoints[3]}
              </text>
              <text
                x={298}
                y={lowerPoint(percentage[3], percentage[4], percentage[5])}
              >
                {formattedPoints[4]}
              </text>
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

export default QVFourDotGraph;
