import React, { useEffect } from "react";
import { LoginReducer } from "./store/reducers";
import { SetStateInStorage, GetStateStorage } from "common/storage";
import _ from "lodash";
import { ADD_AGENT_DASHBOARD_DATA } from "store/types";
import { OnLoadRegisterAgentData } from "services/agent";
import { GetAgentDashboard } from "services/agent";
import Base from "common/constants";

/**Create store context */
export const Store = React.createContext();

/**Set initial set to empty */
const initialState = {
  isUserLoggedIn: false,
  userName: "",
  userID: "",
  userRole: "", //admin or agent
  userData: [],
  tokenData: {},
  maxMediaSize: 5000000,
  cityAreaData: [],
  mediaSize: {
    profile: {
      width: "160",
      height: "80",
    },
    logo: {
      width: "200",
      height: "75",
    },
    signature: {
      width: "200",
      height: "60",
    },
  },
  settings: {
    file: {
      accept: "image/*",
      multiple: true,
    },
  },
  values: {
    //loginAndForgotPassword
    email: "",
    password: "",
    staySignedIn: true,
    //reset password
    confirmPassword: "",
    //createAgent
    firstName: "",
    lastName: "",
    dreNumber: "",
    license: "",
    ownedMlsId: [],
    selectedCountyId: "",
    //Account
    oldPassword: "",
    newPassword: "",
    //Help
    name: "",
    phone: "",
    subject: "",
    customSubject: "",
    message: "",
    brokerageName: "",
  },
  touched: {
    email: false,
    password: false,
    confirmPassword: false,
    firstName: false,
    lastName: false,
    dreNumber: false,
    license: false,
    ownedMlsId: false,
    oldPassword: false,
    newPassword: false,
    name: false,
    phone: false,
    subject: false,
    customSubject: false,
    message: false,
    brokerageName: false,
    selectedCountyId: false
  },
  errors: {
    email: null,
    password: null,
    confirmPassword: null,
    firstName: null,
    lastName: null,
    dreNumber: null,
    license: null,
    ownedMlsId: null,
    oldPassword: null,
    newPassword: null,
    name: null,
    phone: null,
    subject: null,
    customSubject: null,
    message: null,
    brokerageName: null,
    selectedCountyId: false
  },
  showPassword: {
    password: false,
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  },
  isValid: false,
  isLoading: false,
  submitError: null,
  submitSuccess: null,
  deepCopy: null,
  reloadData: true,
  updateData: true,
};

/**Return to encapsulate as components */
export function StoreProvider(props) {
  const [state, dispatch] = React.useReducer(LoginReducer, initialState);
  /**use effect to handle all needed data */
  /**
   * Call only if user is logged in.
   * Don't pass any dependencies without any surety.
   */
  //it's not working properly
  useEffect(() => {
    if (state.isUserLoggedIn && state.updateData) {
      let pathName = window.location.pathname;
      //we will get something like this /pmaurlswitcher. so need to get array 1 key data
      let excludeURls = Base.NO_AGENT_DASHBOARD_API_CALL_URLS;
      //split by /
      pathName = pathName.split("/");
      pathName = pathName[1];
      if (excludeURls.indexOf(pathName) === -1) {
        GetAgentDashboard(state.userName)
          .then((data) => {
            dispatch({
              type: ADD_AGENT_DASHBOARD_DATA,
              payload: data,
            });
          })
          .catch((err) => {});
      }
    }
  }, [state.isUserLoggedIn, state.updateData]);

  const persistStorage = (state, dispatch) => {
    /**check for reload flag to reload data from localstorage */
    if (state.reloadData) {
      /**Add values that you want to persist on page reload */
      /**stop reload */
      state.reloadData = false;
      /**start updating data now */
      let storedData = GetStateStorage();
      for (let key in storedData) {
        state[key] = storedData[key];
      }
      return { state, dispatch };
    } else {
      return { state, dispatch };
    }
  };

  //const value = { state, dispatch };
  return (
    <Store.Provider value={{ ...persistStorage(state, dispatch) }}>
      {props.children}
    </Store.Provider>
  );
}
