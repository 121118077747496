/* Redux Store */

import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './_reducers';

export const history = createBrowserHistory();

// export const store = createStore(
//   rootReducer(history),
//   compose(
//     applyMiddleware(
//       routerMiddleware(history),
//       thunkMiddleware
//     )
//   )
// );

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducer(history),
  composeEnhancers(
    applyMiddleware(routerMiddleware(history), thunkMiddleware),
)
);