import React from 'react';
import { Button } from '@material-ui/core';
import { GetStateStorage } from 'common/storage';

const PreviewPMAFromMailPro = ({ agentID, mailerProID, userData, customStyle }) => {
    const openPMAPreviewMode = () => {
        Object.assign(document.createElement('a'), {
            target: '_blank',
            rel: 'noopener noreferrer',
            href: `/agent/pma/preview/${(userData || {}).listgenUserID}/${mailerProID}`,
        }).click();
    }

    return <p className="blue-text mailProLinkClass pointer quickMarginTop" onClick={openPMAPreviewMode} style={customStyle}>
        View <br />PMA
    </p>
}

export default PreviewPMAFromMailPro;