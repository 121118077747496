import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import {
  Portlet,
  PortletContent,
  PortletHeader,
  PortletLabel,
} from 'components';
import {
  Grid,
  Typography,
  Divider,
  Button,
  IconButton,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  CardActionArea,
} from '@material-ui/core';
import compose from 'recompose/compose';
import _ from 'lodash';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { WebsiteLayout } from 'layouts';
import MissionIcon from '@material-ui/icons/MyLocationOutlined';
import VisionIcon from '@material-ui/icons/VisibilityOutlined';
import ValuesIcon from '@material-ui/icons/StarsOutlined';
import { ProfileAboutCard } from './components';
import DocumentTitle from 'react-document-title';

class AboutScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileData: [
        {
          id: 1,
          name: 'Kyle Morrison',
          image: './images/team/kyle_morrison.jpg',
          designation: 'Founder - CEO',
          description: (
            <Fragment>
              20 years of experience in software design.
              <br />
              California Broker's License Holder.
              <br />
              Top 10% in sales dollars for the last 12 out of 15 years -
              Sotheby's International Realty - Monterey Peninsula, CA.
              <br />
              Built a $2M company into a $50M distribution and light
              manufacturing company.
              <br />
              Won 2 US distribution contracts for fortune 500 apparel companies.
            </Fragment>
          ),
        },
        {
          id: 2,
          name: 'Andy B',
          image: './images/team/andy.jpg',
          designation: 'Backend Developer',
          description: (
            <Fragment>
              Andy has a deep understanding of building Cloud, Web & Mobile, and
              Full Stack applications.
              <br />
              With over 20 years of experience engineering and developing
              applications for enterprises, he helps organizations always remain
              on cutting edge of technology.
            </Fragment>
          ),
        },
        {
          id: 3,
          name: 'Subashini Subramanian',
          image: './images/team/suba_s.JPG',
          designation: 'Backend Developer',
          description: (
            <Fragment>
              25 years of experience in the development of cloud computing, Full
              Stack Development, Web based applications, Realty applications,
              Telecom applications, Banking applications and OSS in the areas of
              architecture, requirements definition, design, development, Change
              Management System (CMS) and testing.
            </Fragment>
          ),
        },
        {
          id: 4,
          name: 'Natalie Salemme',
          image: './images/team/natalie.jpeg',
          designation: 'Frontend Developer',
          description: (
            <Fragment>
              A self-taught frontend enthusiast, with a passion to write code,
              having 4 years of experience in property management, intrigued by
              new technology and is constantly finding better ways to create
              engaging user experiences.
            </Fragment>
          ),
        },
        {
          id: 5,
          name: 'Amit Kumar',
          image: './images/team/amit.jpg',
          designation: 'Web Developer',
          description: (
            <Fragment>
              An enthusiast is how Amit describes himself. Be it Coding, Cricket, Riding, Cycling or party, he does it with full-on-energy. A CS engineer who spends wee-lot of time on making websites look better and learning never ends for this lad.
            </Fragment>
          ),
        },
        {
          id: 6,
          name: 'Kevin Twidle',
          image: './images/team/kewin_tidle.jpg',
          designation: 'Advisor / Board Member',
          description: (
            <Fragment>
              Seasoned professional with broad experience managing and playing an integral role in engineering and IT teams. Innovative computer consultant, communications systems specialist, and distributed systems architect with over 25 years of experience in both the commercial and academic sectors.
            </Fragment>
          ),
        },
      ],
    };
  }

  render() {
    const { classes, className } = this.props;
    const rootClassName = classNames(className);

    return (
      <DocumentTitle title="About us">
        <WebsiteLayout>
          <div className={classes.root}>
            <Grid className={classes.grid} container>
              <Grid item lg={12} sm={12} xs={12} md={12}>
                <div className={classes.App}>
                  <div className={classes.topBox}>
                    <div className={classes.section}>
                      <Typography
                        className={classNames(
                          classes.whiteColor,
                          classes.textAlignCenter,
                          classes.margin4UnitBottom
                        )}
                        variant="h4"
                      >
                        Who are we?
                    </Typography>
                      <div className={classes.portletHolder}>
                        <Grid className={classes.grid} container>
                          <Grid item lg={1} sm={2} xs={2} md={3} />
                          <Grid item lg={10} sm={8} xs={8} md={6}>
                            <Typography
                              variant="subtitle1"
                              className={classNames(
                                classes.leftAlign,
                                classes.whiteColor
                              )}
                            >
                              ListGen gives agents exclusive access over a ListGen area. Agents will be able to create personalized messages as well as send out a variety of ways to present information to their homeowners including graphs, photos, and statistical data for their neighborhood and city.
                          </Typography>
                          </Grid>
                          <Grid item lg={1} sm={2} xs={2} md={3} />
                        </Grid>
                      </div>
                    </div>
                  </div>
                  <div className={classes.clear} />
                  <div className={classes.middleBox}>
                    <Grid className={classes.grid} container>
                      {/* <Grid item lg={2} sm={0} xs={0} md={3}></Grid> */}
                      <Grid item lg={4} sm={12} xs={12} md={4}>
                        <div className={classes.iconHolder}>
                          <IconButton onClick={this.handleBack}>
                            <MissionIcon className={classes.iconLarge} />
                          </IconButton>
                          <Typography
                            variant="h6"
                            className={classes.marginTopBottom1}
                          >
                            MISSION
                        </Typography>
                          <Typography variant="subtitle2">
                            Our mission is to create the industry’s most advanced
                            and elegant real estate marketing system by giving
                            homeowners a deep understanding of their local market
                            while building each agent’s brand as the most trusted
                            real estate partner.
                        </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={4} sm={12} xs={12} md={4}>
                        <div className={classes.iconHolder}>
                          <IconButton onClick={this.handleBack}>
                            <VisionIcon className={classes.iconLarge} />
                          </IconButton>
                          <Typography
                            variant="h6"
                            className={classes.marginTopBottom1}
                          >
                            VISION
                        </Typography>
                          <Typography variant="subtitle2">
                            To provide most compelling insights of local markets with <br /> Selection, Quality and Value
                        </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={4} sm={12} xs={12} md={4}>
                        <div className={classes.iconHolder}>
                          <IconButton onClick={this.handleBack}>
                            <ValuesIcon className={classes.iconLarge} />
                          </IconButton>
                          <Typography
                            variant="h6"
                            className={classes.marginTopBottom1}
                          >
                            VALUES
                        </Typography>
                          <Typography variant="subtitle2">
                            Customer Commitment. Quality. Integrity.<br /> Teamwork. A Will to Win
                        </Typography>
                        </div>
                      </Grid>
                      {/* <Grid item lg={1} sm={0} xs={0} md={3}></Grid> */}
                    </Grid>
                  </div>
                  <div className={classes.clear} />
                  {/* <div className={classes.middleBox}>
                                    <Grid className={classes.grid} container>

                                    </Grid>
                                    </div>
                                    <div className={classes.clear}></div> */}
                  <div className={classes.bottomBox}>
                    <div className={classes.section}>
                      <Grid className={classes.grid} container>
                        <Grid item lg={12} sm={12} xs={12} md={12}>
                          <Typography
                            className={classNames(
                              classes.textAlignCenter,
                              classes.margin4UnitBottom,
                              classes.fontWeigth500
                            )}
                            variant="h4"
                          >
                            The Team
                        </Typography>
                        </Grid>
                      </Grid>
                      <ProfileAboutCard profileData={this.state.profileData} />
                      <div className={classes.clear} />
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </WebsiteLayout>
      </DocumentTitle>
    );
  }
}

export default compose(withStyles(styles))(AboutScreen);
