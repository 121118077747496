import 'date-fns';
import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/styles';
import { useEffect } from 'react';
import { CallGrammerBotApi } from 'services/others';
import palette from 'theme/palette';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        background: palette.info.main,
        color: 'white',
        padding: theme.spacing(2),
        width: '100%',
        borderRadius: 4,
        margin: theme.spacing(1, 0, 1, 0),
    },
    infoHeading: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        height: '30px'
    },
    infoFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '20px'
    },
    headingText: {
        fontWeight: 500
    },
    matchingSuggestions: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        maxHeight: '100px',
        overflowY: 'scroll'
    },
    infoOutline: {
        color: 'white',
        width: '26px',
        height: '26px',
        marginTop: -theme.spacing(0.5),
        marginRight: theme.spacing(1)
    },
    headingDivider: {
        margin: theme.spacing(1, 0, 1, 0),
        color: 'white',
    },
    listDividerHolder: {
        display: 'flex',
        width: '100%',
        alignItems:' center',
        justifyContent: 'flex-end'
    },
    listDivider: {
        margin: theme.spacing(1, 0, 1, 0),
        width: '95%',
        color: 'white'
    },
    singleMatchItem: {
        lineHeight: '20px'
    },
    singleMatchMessage: {
        fontWeight: 500,
        fontSize: '12px'
    },
    singleMatchSentence: {
        fontSize: '11px'
    },
    singleMatchReplacements: {
        fontSize: '11px'
    },
    boldText: {
        fontWeight: 500
    },
    boldTextWithLargeText: {
        fontWeight: 500,
        fontSize: '13px',
        textDecoration: 'underline'
    },
    borderText: {
        background: palette.warning.main,
        padding: '0.5px 2px 0.5px 2px',
        fontWeight: 500,
        borderRadius: 4,
        color: '#12161B'
    }
}));

export default function GrammerChecker(props) {
    // The first commit of Material-UI
    let { textToCheck, onSuccessFn, validateButtonLoadingMethod, optionalAfterCallbackType, optionalAfterCallbackFn } = props;
    const [showGrammerSection, setGrammerSection] = React.useState(false);
    const [grammerMatchesData, setGrammerMatchesData] = React.useState([]);
    /**classes */
    const classes = useStyles();

    /**check the grammer text */
    useEffect(() => {
        //call grammer check method
        setGrammerSection(false);
        setGrammerMatchesData([]);
        applyGrammerCheck();
    }, [textToCheck]);

    const applyGrammerCheck = async () => {
        CallGrammerBotApi(textToCheck)
            .then(data => {
                if (data && data.matches && Array.isArray(data.matches) && data.matches.length > 0) {
                    //grammer warning messages got
                    //show warning messages screen and then go to review screen
                    setGrammerMatchesData(data.matches);
                    setGrammerSection(true);
                    validateButtonLoadingMethod(false);
                    if(optionalAfterCallbackFn){
                        optionalAfterCallbackFn(optionalAfterCallbackType, data.matches)
                    }
                } else {
                    //no error to show
                    onSuccessFn();
                    validateButtonLoadingMethod(false);
                    if(optionalAfterCallbackFn){
                        optionalAfterCallbackFn(optionalAfterCallbackType, []);
                    }
                }
            })
            .catch(err => {
                //some error. send to review screen
                onSuccessFn();
                validateButtonLoadingMethod(false);
                if(optionalAfterCallbackFn){
                    optionalAfterCallbackFn(optionalAfterCallbackType, []);
                }
            });
    };

    const highlightText = (sentence, offset, length) => {
        return <Fragment>
            {sentence.substr(0, offset)}
            <span className={classes.borderText}>
                {sentence.substr(offset, length)}
            </span>
            {sentence.substr(offset + length)}
        </Fragment>
    };

    /**Send data only if we have the grammer problems */
    return (
        (showGrammerSection ?
            <div className={classes.root}>
                <div className={classes.infoHeading}>
                    <div>
                        <InfoOutlinedIcon fontSize="large" className={classes.infoOutline} />
                    </div>
                    <div className={classes.headingText}>
                        Before proceeding our system analyzed your note. Here's we found some suggestions for you:
                    </div>
                </div>
                <Divider className={classes.headingDivider} />
                <div className={classes.matchingSuggestions}>
                    {grammerMatchesData.map((match, index) => {
                        return (
                            <Fragment>
                                <div className={classes.singleMatchItem}>
                                    <div className={classes.singleMatchMessage}>{index + 1}.&nbsp;{match.message}</div>
                                    {/* {match.offset}{match.length} */}
                                    <div className={classes.singleMatchSentence}><span className={classes.boldTextWithLargeText}>In Sentence:</span>&nbsp;{highlightText(match.context.text, match.context.offset, match.context.length)}</div>
                                    {match.replacements.length > 0 ?
                                        <div className={classes.singleMatchReplacements}><span className={classes.boldTextWithLargeText}>Replacements Found:</span>&nbsp;{match.replacements.map(item => item.value).splice(0, 5).join(', ')}</div>
                                        : ''}
                                </div>
                                {index < grammerMatchesData.length - 1 ?
                                    <div className={classes.listDividerHolder}>
                                        <Divider className={classes.listDivider} />
                                    </div>
                                    : ''}
                            </Fragment>
                        );
                    })}
                </div>
                <Divider className={classes.headingDivider} />
                <div className={classes.infoFooter}>
                    <div className={classes.boldText}>
                        These are only suggestions. You can still submit the note with suggestions.
                    </div>
                </div>
            </div>
            : '')
    );
}
