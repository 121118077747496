import React, { Component } from "react";
import QVThreeTopCategory from "./QVThreeTopCategory";
import { propertyUtil } from "views/Pma/PropertyUtil";

class QVThreeTopCategories extends Component {
  determineHeaderTitle = () => {
    const {
      currentArea,
      hoData,
      printVersion,
      demoAccountAreaName,
      agentData
    } = this.props;
    const { mlsNeighborhood, mlsCityName } = currentArea;
    const { subdivisionName } = hoData;
    const { listgenUserID } = agentData;
    const { splitAreas } = propertyUtil;

    let areaLength = mlsNeighborhood.length;
    // console.log("areaCount", areaLength);
    if (listgenUserID === "100037") {
      return (
        <div className="qv3-top-categories-header-subtitle small-subtitle">
          {demoAccountAreaName}
        </div>
      );
    }
    if (subdivisionName && printVersion) {
      return (
        <div className="qv3-top-categories-header-subtitle small-subtitle">
          {subdivisionName}
        </div>
      );
    } else if (areaLength > 32) {
      return (
        <div className="qv3-top-categories-header-subtitle small-subtitle">
          {splitAreas(mlsNeighborhood).join("/")}
        </div>
      );
    } else if (areaLength > 24) {
      return (
        <div className="qv3-top-categories-header-subtitle medium-subtitle">
          {splitAreas(mlsNeighborhood).join("/")}
        </div>
      );
    } else {
      return (
        <div className="qv3-top-categories-header-subtitle">
          {mlsNeighborhood}
        </div>
      );
    }
  };
  render() {
    const {
      soldListingRowsFor6Months,
      marketTrends,
      highestSalePricePast6Months,
      addressForHighestSalePricePast6Months
    } = this.props.aprilData.pmaAprilRunAreaDTO;
    // console.log(this.props);
    const { calculateDiff } = propertyUtil;
    let sortByMaxHomes = soldListingRowsFor6Months
      .slice()
      .sort(
        (a, b) =>
          b.SoldListingsCountLast6Months - a.SoldListingsCountLast6Months
      );
    let maxHomesNum = sortByMaxHomes[0].SoldListingsCountLast6Months;
    let filteredMaxHomes = soldListingRowsFor6Months.filter(
      row => row.SoldListingsCountLast6Months === maxHomesNum
    );

    let homesSoldInRange = filteredMaxHomes[0].SoldListingsCountLast6Months;
    let filterOutZeroSolds = soldListingRowsFor6Months.filter(
      row => +row.SoldListingsCountLast6Months > 0
    );
    let sortByMinDOM = filterOutZeroSolds
      .slice()
      .sort(
        (a, b) => a.AvgDaysOnMarketLast6Months - b.AvgDaysOnMarketLast6Months
      );
    let minDOMNum = sortByMinDOM[0].AvgDaysOnMarketLast6Months;
    let filteredMinDOM = filterOutZeroSolds.filter(
      row => row.AvgDaysOnMarketLast6Months === minDOMNum
    );
    let currentYearObj = marketTrends.filter(
      obj => obj.TrendName === "6-Months Trending"
    );
    let pastYearObj = marketTrends.filter(
      obj => obj.TrendName === "Past 6-Months Trending"
    );
    const { AvgSalePrice: AvgSalePriceCurrentYear } = currentYearObj[0];
    const { AvgSalePrice: AvgSalePricePastYear } = pastYearObj[0];

    const salePriceDiff = +calculateDiff(
      AvgSalePriceCurrentYear,
      AvgSalePricePastYear
    );
    let higherSpacedStyles;
    if (filteredMaxHomes.length > 1 || filteredMinDOM.length > 1) {
      higherSpacedStyles = "higher-spaced-styles";
    } else {
      higherSpacedStyles = "regular-spaced-styles";
    }
    return (
      <div className="qv3-top-categories-outer-container">
        <div className="qv3-top-categories-header">
          <div className="qv3-top-categories-header-title-container">
            <p className="qv3-top-categories-header-title-text">
              Top Market Trends
            </p>
            <p className="qv3-top-categories-description">Last 6 Months</p>
          </div>
          <div className="qv3-top-categories-header-divider" />

          {this.determineHeaderTitle()}
        </div>
        <div className="qv3-top-categories-inner-container">
          <QVThreeTopCategory
            category="Hottest"
            data={filteredMaxHomes.slice(-2)}
            description={filteredMaxHomes.length}
            higherSpacedStyles={higherSpacedStyles}
            highestSalePrice={highestSalePricePast6Months}
            index="1"
            homesSoldInRange={homesSoldInRange}
          />
          <QVThreeTopCategory
            category="Fastest"
            data={minDOMNum}
            description={filteredMinDOM.slice(-2)}
            higherSpacedStyles={higherSpacedStyles}
            highestSalePrice={highestSalePricePast6Months}
            index="2"
          />
          <QVThreeTopCategory
            category="Highest"
            data={highestSalePricePast6Months}
            description={salePriceDiff}
            higherSpacedStyles={higherSpacedStyles}
            highestPriceAddress={addressForHighestSalePricePast6Months}
            index="3"
          />
        </div>
      </div>
    );
  }
}

export default QVThreeTopCategories;
