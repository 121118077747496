import React, { Component } from "react";
import YETwoPropertyHighlightModal from "./YETwoPropertyHighlightModal";
import TestHome from "views/PmaAdmin/PropertyHighlights/test-home.jpg";
import { propertyUtil } from "views/Pma/PropertyUtil";
import { Link } from "react-router-dom";
import { IsValidSessionSwitch } from "common/storage";
import YETwoHighlightsProp from "./YETwoHighlightsProp";

class GailPearlCustomHighlight extends Component {
  state = {
    isModalOpen: false,
  };
  isUnit = (prop) => {};
  shortenCourtesy = (brokerage, isSellingAgent) => {
    const { printVersion, agentData } = this.props;
    const { brokerageName } = agentData;
    let len = brokerage.length;

    let updatedBrokerage = brokerage.slice();
    if (brokerage === "Village Properties - 1") {
      updatedBrokerage = "Village Properties";
    } else if (
      brokerage === "Berkshire Hathaway HomeServices California Properties" &&
      brokerageName === "Berkshire Hathaway Home Services California Properties"
    ) {
      updatedBrokerage =
        "Berkshire Hathaway HomeServices California Properties";
    }
    if (!isSellingAgent && brokerageName === updatedBrokerage) {
      return "";
    } else if (isSellingAgent) {
      return "";
    } else if (!isSellingAgent && len >= 35) {
      return `Image courtesy of ${updatedBrokerage.slice(0, 35) + "..."}`;
    } else {
      return `Image courtesy of ${updatedBrokerage}`;
    }
  };

  formatSalePrice = (price) => {
    let salePriceOnlyNumbers = ((price || 0) + "").replace(/\D/g, "");
    return Number(salePriceOnlyNumbers);
  };
  determineTitleYear = (useAlt) => {
    const { currentSoldListings, agentData, title } = this.props;
    const { isMemberOfTeam, listgenUserID } = agentData;
    let mappedSaleDate;
    if (!currentSoldListings) {
      mappedSaleDate = ["1999"];
    } else {
      mappedSaleDate = currentSoldListings.map((prop) =>
        prop.saleDate ? prop.saleDate.slice(0, 4) : "1999"
      );
    }
    let years = mappedSaleDate.map(Number);
    let isDianneJohnson = listgenUserID === "100988";
    let titleStyles = "";

    let titleLength = title.length;
    if (titleLength > 40) {
      titleStyles = "xs-highlight-title";
    }

    return (
      <div className="gail-ye2-property-highlight-title-top-row">
        <div
          className={`${"gail-y2-property-highlight-title-text"} ${titleStyles}`}
        >
          {title}
        </div>
      </div>
    );
  };
  handlePropertyHighlightDialog = (openDialog = false) => {
    this.setState({
      isModalOpen: openDialog,
    });
  };

  determineRepresentation = (prop) => {
    const { isMemberOfTeam, listgenUserID } = this.props.agentData;
    const { mlsAreaID } = this.props;
    let isDianneJohnson = listgenUserID === "100988";
    let pronoun;
    if (listgenUserID === "100656") {
      pronoun = "C&H";
    } else if (isMemberOfTeam || isDianneJohnson) {
      pronoun = "We";
    } else if (listgenUserID === "101677") {
      pronoun = "";
    } else {
      pronoun = "I";
    }
    const { isSellingAgent, isBuyingAgent } = prop;
    if (
      listgenUserID === "101567" &&
      mlsAreaID === "SCL-16F" &&
      prop.streetNumber === "18191" &&
      prop.streetName === "Saratoga-Los Gatos"
    ) {
      return "I Represented the Seller, sold off-market";
    } else if (
      mlsAreaID == "SBA-15J" &&
      prop.streetName === "Shadow Hills" &&
      prop.streetNumber === "4431"
    ) {
      return "We are Representing the Buyers";
    } else if (
      ((mlsAreaID === "SBA-35F" ||
        mlsAreaID === "SBA-35H" ||
        mlsAreaID === "SBA-15J") &&
        prop.streetName === "Shadow Hills Blvd N") ||
      (prop.streetName === "Shadow Hills Blvd" && prop.streetNumber === "4431")
    ) {
      //return "We are Representing the Buyers";
      return "ShadowHillsSB.com";
    } else if (isSellingAgent && isBuyingAgent) {
      return `${pronoun} Represented the Seller and the Buyer`;
    } else if (isSellingAgent) {
      return `${pronoun} Represented the Sellers`;
    } else if (isBuyingAgent) {
      return `${pronoun} Represented the Buyers`;
    }
  };

  photoURLToUse = (prop) => {
    let url;
    if (prop.optimizedPhotoURL === "ERROR") {
      url = prop.photoURL;
    } else if (prop.optimizedPhotoURL) {
      url = prop.optimizedPhotoURL;
    } else {
      url = prop.photoURL;
    }
    return url;
  };

  render() {
    let {
      currentSoldListings,
      mlsAreaID,
      isAgentPage,
      setHighlightTitleDialog,
      setHighlightFooterDialog,
      title,
      subtitle,
      footerText1,
      footerText2,
      onHighlightTitleOpen,
      onHighlightFooterOpen,
      agentData,
      printVersion,
      currentArea,
      isDemo,
      openEditDialogFn,
    } = this.props;

    const { isMemberOfTeam, listgenUserID, brokerageName } = agentData;
    let useSmallerFooterFont = false;
    if (footerText1 && footerText1.length >= 80) {
      useSmallerFooterFont = true;
    }
    let useAlt = false;
    if (listgenUserID === "101566") {
      useAlt = true;
    }

    const {
      handlePropertyHighlightDialog,
      determineRepresentation,
      shortenCourtesy,
      photoURLToUse,
    } = this;

    const { isModalOpen } = this.state;
    let isCountryClubGate = mlsAreaID === "132" ? true : false;
    const {
      formatSuffix,
      formatBaths,
      toAcre,
      emptyValueCheck,
      endsWithS,
      statusContingentToPending,
    } = propertyUtil;
    let isDianneJohnson = listgenUserID === "100988";
    let showTitle = false;
    const isASessionSwitch = true; //!printVersion && IsValidSessionSwitch();
    // if (listgenUserID === "100778" || listgenUserID === "100528") {
    //   showTitle = false;
    // }
    //Need to implement this logic if required
    // if(!isAgentPage){
    //   currentSoldListings =([...currentSoldListings] || []).sort((a, b) => Number(a.salePrice) > Number(b.salePrice) ? -1 : Number(a.salePrice) < Number(b.salePrice) ? 1 : 0);
    // }

    let use1Prop3Photos = false;
    let use4Props = false;
    let useQuadrantPhotoProps = false;

    if (listgenUserID === "101001") {
      useQuadrantPhotoProps = true;
    }

    let use1Prop = false;
    if (
      (currentSoldListings && currentSoldListings.length === 1) ||
      listgenUserID === "103455"
    ) {
      use1Prop = true;
    }
    let use2Props = false;
    if (currentSoldListings && currentSoldListings.length === 2) {
      use2Props = true;
    }

    const use3Props =
      currentSoldListings &&
      currentSoldListings.length >= 3 &&
      currentSoldListings.length < 5;

    let switchedProperties = false;
    // if (listgenUserID === "100015") {
    //   switchedProperties = true;
    // }
    let showButton = true;

    // if (use1Prop || use3Props) {
    //   showButton = false;
    // }
    let row1 = (
      <div className="ye2-property-highlight-row-1">
        <div className="ye2-property-highlight-inner-photo-container">
          {[2, 3, 4].map((placeholder, index) => {
            let prop = currentSoldListings[index + 2];

            if (!prop) {
              return (
                <div
                  className="property-highlight-placeholder"
                  key={placeholder}
                >
                  <p>Please select</p>
                  <p>a property</p>
                </div>
              );
            } else {
              return (
                <div className="row1-photo-col" key={placeholder}>
                  {isAgentPage && showButton ? (
                    <div>
                      <div className="property-number">{index + 3}</div>
                      <div
                        className="property-edit-photo-btn"
                        onClick={() => openEditDialogFn(prop)}
                      >
                        Edit
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <YETwoHighlightsProp
                    prop={prop}
                    agentData={agentData}
                    currentSoldListings={currentSoldListings}
                    useAlt={useAlt}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    );

    let row2 = (
      <div className="ye2-property-highlight-row-2">
        <div className="ye2-property-highlight-inner-photo-container">
          {[0, 1].map((placeholder, index) => {
            let prop = currentSoldListings[index];

            if (!prop) {
              return (
                <div
                  className="property-highlight-placeholder"
                  key={placeholder}
                >
                  <p>Please select</p>
                  <p>a property</p>
                </div>
              );
            } else {
              let hideCourtesy = false;

              if (
                (prop.streetName === "Lasuen" && prop.streetNumber == "1713") ||
                (prop.streetName === "Ferrelo" && prop.streetNumber == "1250")
              ) {
                hideCourtesy = true;
              }

              return (
                <div className="row2-photo-col" key={placeholder}>
                  {isAgentPage && showButton ? (
                    <div>
                      <div className="property-number">{index + 1}</div>
                      <div
                        className="property-edit-photo-btn"
                        onClick={() => openEditDialogFn(prop)}
                      >
                        Edit
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <YETwoHighlightsProp
                    prop={prop}
                    agentData={agentData}
                    currentSoldListings={currentSoldListings}
                    useAlt={useAlt}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    );
    return (
      <div
        className={`${
          isAgentPage
            ? `ye2-agent-page-property-highlight ${
                useAlt && "alt-ye2-property-highlight"
              }`
            : `ye2-property-highlight ${
                useAlt && "alt-ye2-property-highlight"
              } gail-highlight`
        }`}
      >
        {isModalOpen && (
          <YETwoPropertyHighlightModal
            isModalOpen={isModalOpen}
            handlePropertyHighlightDialog={handlePropertyHighlightDialog}
          />
        )}
        {!isAgentPage && !printVersion && showButton && (
          <div
            className={`ye2-new-edit-button-container ${
              useAlt
                ? "gummow-alt-prop-edit-button-container"
                : "regular-prop-edit-button"
            }`}
          >
            <Link
              to={`/agent/property-highlights/${
                currentArea && currentArea.mlsAreaID
              }`}
              className="ye2-new-edit-button"
            >
              Click Here to Edit This Page
            </Link>
          </div>
        )}

        <div className="gail-ye2-property-highlight-bg-container">
          <div className="gail-ye2-property-highlight-bg"></div>
        </div>

        <div className="ye2-property-highlight-body">
          <div className="gail-ye2-property-highlight-title-container">
            <p className="gail-highlight-title">My Most Recent Sale</p>
            <p className="gail-highlight-footer-1">327 Moreton Bay Lane #3</p>
            <p className="gail-highlight-subtitle gail-m1">
              2 bd + Den | 2 ba | 1192 SF | Sold $900,000
            </p>
            <p className="gail-highlight-subtitle">
              Wonderful location on Moreton Bay Lane in Encina Royale. Cottage
              style end unit with 2 bedrooms plus den and 2 full baths. Updates
              include kitchen, windows and slider, plantation shutters and
              canned lighting. Fireplace in the living room. 2 spacious patios
              for your outdoor entertainment. Many activities at the clubhouse
              which make it a wonderful lifestyle for all who live here.
            </p>
          </div>

          <div className="gail-ye2-property-highlight-photo-title-container">
            <div></div>

            {isAgentPage && showButton ? (
              <div
                className="title-edit-button title-btn"
                onClick={() => onHighlightTitleOpen()}
                // style={{ marginTop: "-16px", marginBottom: "16px" }}
              >
                <div>
                  <p>edit</p>
                  <p>Title/Subtitle</p>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>

          <div className="ye2-property-highlight-row-1 gail-prop-1-layout">
            <div className="ye2-property-highlight-inner-photo-container">
              <div className="ye2-prop-highlights-col-1">
                {[0].map((placeholder, index) => {
                  let prop = currentSoldListings[index];

                  return (
                    <div className="row1-photo-col" key={placeholder}>
                      {isAgentPage && showButton ? (
                        <div>
                          <div className="property-number">{index + 1}</div>
                          <div
                            className="property-edit-photo-btn"
                            onClick={() => openEditDialogFn(prop)}
                          >
                            Edit
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <YETwoHighlightsProp
                        prop={prop}
                        agentData={agentData}
                        currentSoldListings={currentSoldListings}
                        useAlt={useAlt}
                        use1Prop={use1Prop}
                        mlsAreaID={mlsAreaID}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GailPearlCustomHighlight;
