import React from "react";
import PmaEditor from "./PMAEditor";
import axios from "common/interceptors";
import { propertyUtil } from "views/Pma/PropertyUtil";

class PrintPMA extends React.Component {
  state = {
    singleHOData: null,
    agentData: null,
    mlsAreas: null,
    marketActivity: null,
    MQCityDetails: null,
    currentArea: null,
    localSellingAgents: null,
    version: "year-end-2022",
    coverPageDetails: null,
    quarterlyAreaTable: null,
    testimonials: null,
    agentDesignations: null,
    agentAdvantages: null,
    salePriceForCities: null,
    yearEnd: null,
    qArea: null,
    qCity: null,
    missionStatement: null,
    aprilData: null,
    flipBook: false,
    isDemo: true,
  };

  async componentDidMount() {
    let mailProId = this.props.match.params.mailproid;
    let listgenUserID = this.props.match.params.listgenUserID;
    let brokerage = this.props.match.params.brokerage;
    const { version } = this.state;
    let url = window.location.href;
    let isDemo = true;
    if (url.includes("test-print")) {
      listgenUserID = url[url.length - 2];
    }
    let date = new Date();
    let thisYear = date.getFullYear();
    const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
    const today = `${date.getDate()}`.padStart(2, 0);
    try {
      let mailPrint = await axios
        .get(
          `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
          {
            headers: {
              "content-type": "application/json",
            },
            data: {},
          }
        )
        .then((res) => {
          const singleHOData = res.data.mailerProDTO;
          const agentData = res.data.listgenUserDTO;
          this.setState({
            singleHOData,
            agentData,
          });
        })
        .catch((err) => console.log("error retrieving idDetails" + err));
    } catch (error) {
      console.log("error", error);
    }
    let navBar = await axios
      .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
        headers: {
          "content-type": "application/json",
        },
        data: {},
      })
      .then((res) => {
        this.setState({
          mlsAreas: res.data,
        });
      })
      .catch((err) => {
        console.log("error retrieving navbarInfo" + err);
      });
    const thisArea = this.state.singleHOData.areaID;

    // let date = new Date();
    // let thisYear = date.getFullYear();
    // const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
    // const today = `${date.getDate()}`.padStart(2, 0);
    try {
      let mailPrint = await axios
        .get(
          `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
          {
            headers: {
              "content-type": "application/json",
            },
            data: {},
          }
        )
        .then((res) => {
          const singleHOData = res.data.mailerProDTO;
          let agentData = res.data.listgenUserDTO;
          if (listgenUserID === "103" || listgenUserID === "105") {
            agentData.brokerageName = brokerage;
          }
          this.setState({
            singleHOData,
            agentData,
          });
        })
        .catch((err) => console.log("error retrieving idDetails" + err));
    } catch (error) {
      console.log("error", error);
    }
    // let navBar = await axios
    //   .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
    //     headers: {
    //       "content-type": "application/json",
    //     },
    //     data: {},
    //   })
    //   .then((res) => {
    //     this.setState({
    //       mlsAreas: res.data,
    //     });
    //   })
    //   .catch((err) => {
    //     console.log("error retrieving navbarInfo" + err);
    //   });
    // const thisArea = this.state.singleHOData.areaID;
    let currentArea = this.state.mlsAreas.filter(
      (area) => area.mlsAreaID === thisArea
    )[0];
    const { mlsCityID, mlsAreaID } = currentArea;

    const { singleHOData } = this.state;
    if (singleHOData) {
      const { subdivisionName, mailerProID } = singleHOData;
      let updatedID;
      updatedID = mlsAreaID;

      let getPmaDetails = await axios
        .get(
          `/agent/getPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
            subdivisionName ? subdivisionName : "-"
          }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,

          {
            headers: {
              "content-type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            data: {},
          }
        )
        .then((res) => {
          console.log("inside year end res.data", res.data);

          let {
            mailerPrintInfoDTO,
            propertyPhotoDetailsDTO,
            pmaQuarterlyAreaDTO,
            pmaQuarterlyCityDTO,
            quadrantInfoList,
          } = res.data;

          let singleHOData = mailerPrintInfoDTO.mailerProDTO;
          let agentData = mailerPrintInfoDTO.listgenUserDTO;
          let coverPhotoDetails = res.data.pmaCoverPhotoDTO;
          let { formatDemoBrokerage, formatDemoEmail } = propertyUtil;

          let isDemo = false;
          let demoValues = {
            agentDesignation: "Broker Associate",
            agentLicenseNumber: "12345678",
            billingAddressLine1: "7979 Soquel Drive test",
            billingAddressLine2: null,
            billingCity: "Aptos",
            billingFirstName: "Sarah",
            billingLastName: "Sullivan",
            billingState: "CA",
            billingZipCode: "95003",
            brokerLicenseNumber: "01317331",
            brokerageLogoURL: null,
            brokerageName: "COMPASS",
            displayEmailAddress: "sarah@compass.com",
            emailAddress: "sarah@compass.com",

            isMemberOfTeam: false,
            listgenRoleID: 2,
            listgenUserFirstName: "Sarah",
            listgenUserID: "101",
            listgenUserLastName: "Sullivan",
            listgenUserPassword: null,
            logoURL:
              "https://uploadagentdetails.s3.amazonaws.com/1626808274547-SarahSullivan-logo-lightBG.png",
            logoURL2:
              "https://uploadagentdetails.s3.amazonaws.com/1566296755270-test-file-for-upload.jpg",
            mailProStatus: null,
            mailingAddressLine1: "7979 Soquel Drive test",
            mailingAddressLine2: null,
            mailingCity: "Aptos",
            mailingMonthsInvoicesCount: null,
            mailingState: "CA",
            mailingZipCode: "95003",
            missionStatementsCount: null,
            missionStatementsVerifiedCount: null,
            mobilePhoneNo: "3219876543",
            officeAddressLine1: "7979 Soquel Drive",
            officeAddressLine2: null,
            officeCity: "Aptos",
            officePhoneNo: null,
            officeState: "CA",
            officeZipcode: "95003",
            photoURL:
              "https://uploadagentdetails.s3.amazonaws.com/1699300827098-1674530346469-sarahsullivan_nosuch_032821-1.jpg",
            signatureURL:
              "https://uploadagentdetails.s3.amazonaws.com/1566296763311-test-file-for-upload.jpg",
            websiteURL: "www.SarahSullivanHomes.com",
            demoCoverDate: "June 20",
          };
          console.log("*****print year end");
          agentData = Object.assign(agentData, demoValues);
          res.data.pmaCoverPhotoDTO = Object.assign(res.data.pmaCoverPhotoDTO, {
            coverPhoto:
              "https://d2onbxnrte2nim.cloudfront.net/Summerkitchen_July2024.jpg",
            coverPhotoCredit1:
              "Cover Credit: Interior Design by Hayley Bridges Design",
          });
          // if (
          //   listgenUserID === "103" ||
          //   listgenUserID === "105" ||
          //   listgenUserID === "101"
          // ) {
          agentData.brokerageName = formatDemoBrokerage(brokerage);
          agentData.emailAddress = formatDemoEmail(brokerage);

          isDemo = true;
          // currentArea.mlsNeighborhood = "Pruneyard";
          // }
          let bubbleBoxes = res.data.marketValueInfoList;
          bubbleBoxes.map((bubble) => (bubble.mktValBox1SubText = "—Sarah"));

          coverPhotoDetails.coverPhoto =
            "https://d2onbxnrte2nim.cloudfront.net/Pinkhouse_jun2024_brighter.jpg";
          coverPhotoDetails.coverPhotoCredit1 = "";
          coverPhotoDetails.coverPhotoCredit2 = "";

          this.setState({
            quarterlyAreaTable: pmaQuarterlyAreaDTO,
            quarterlyCityTable: pmaQuarterlyCityDTO,
            MQCityDetails: pmaQuarterlyCityDTO,
            coverPageDetails: coverPhotoDetails,
            currentArea,
            testimonials: mailerPrintInfoDTO.lgTestimonialsDTO,
            quadrantDetails: quadrantInfoList,
            propertyPhotoDetailsDTO: propertyPhotoDetailsDTO,
            //version: "qv2",
            // singleHOData,
            agentData,
            // currentArea,
            // coverPageDetails: res.data.pmaCoverPhotoDTO,
            // quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
            aprilData: {
              pmaQuarterlyAreaDTO: res.data.pmaQuarterlyAreaDTO,
              pmaQuarterlyCityDTO: res.data.pmaQuarterlyCityDTO,
            },
            bubbleBoxes,
            //   // testimonials: res.data.lgTestmonialsDTO,
            //   // coverPageDetails: res.data.pmaCoverPhotoDTO,
            //   // quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
            //   // quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
            //   bubbleBoxes: res.data.marketValueInfoList,
            //   // currentArea,
            //   // singleHOData,
            //   // agentData,
            //   // version: "bubble-compare",
          });
        })
        .catch((err) =>
          console.log("error retrieving table bubble data" + err)
        );

      let getPropertyHighlights = await axios
        .get(
          `/agent/getPropertyPhotoDetailsForListgenUserArea?listgenUserAreaID=100778-SBA-10F`,
          {
            headers: {
              "content-type": "application/json",
            },
            data: {},
          }
        )
        .then((res) => {
          console.log("***OTHERproperty highlights", res.data);
          let propertyPhotoDetailsDTO = res.data.propertyPhotoDetailsDTO;
          console.log(
            "*********propertyPhotoDetailsDTO",
            propertyPhotoDetailsDTO
          );

          this.setState({
            propertyPhotoDetailsDTO: Object.assign(res.data, {
              propertyHighlightsTitle: "Recent Sales by Sarah Sullivan",
            }),
            //version: "qv2",
          });
        })
        .catch((err) => console.log("error retrieving year end data" + err));
    }
  }
  render() {
    const { propertyData } = this.props;
    const {
      singleHOData,
      agentData,
      mlsAreas,
      marketActivity,
      MQCityDetails,
      currentArea,
      version,
      quarterlyCityTable,
      quarterlyAreaTable,
      localSellingAgents,
      coverPageDetails,
      testimonials,
      agentDesignations,
      agentAdvantages,
      salePriceForCities,
      yearEnd,
      qArea,
      qCity,
      aprilData,
      flipBook,
      missionStatement,
      propertyPhotoDetailsDTO,
      pmaQuarterlyCombinedCityDTO,
      quadrantDetails,
      bubbleBoxes,
      isDemo,
    } = this.state;

    if (
      currentArea &&
      singleHOData &&
      agentData &&
      aprilData &&
      coverPageDetails &&
      quarterlyAreaTable &&
      quarterlyAreaTable.currentSoldListings &&
      bubbleBoxes &&
      Object.keys(bubbleBoxes).length >= 1
    ) {
      return (
        <div className="pma-creator">
          <main>
            <section>
              <PmaEditor
                propertyData={propertyData}
                printVersion={true}
                version={version}
                singleHOData={singleHOData}
                agentData={agentData}
                currentArea={currentArea}
                marketActivity={marketActivity}
                MQCityDetails={MQCityDetails}
                mlsAreas={mlsAreas}
                quarterlyAreaTable={quarterlyAreaTable}
                quarterlyCityTable={quarterlyCityTable}
                localSellingAgents={localSellingAgents}
                coverPageDetails={coverPageDetails}
                printTestimonials={testimonials}
                printAgentAdvantages={agentAdvantages}
                printAgentDesignations={agentDesignations}
                salePriceForCities={salePriceForCities}
                yearEnd={yearEnd}
                qArea={qArea}
                qCity={qCity}
                aprilData={aprilData}
                flipBook={flipBook}
                dontShowPma={false}
                missionStatement={missionStatement}
                propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
                pmaQuarterlyCombinedCityDTO={pmaQuarterlyCombinedCityDTO}
                quadrantDetails={quadrantDetails}
                isDemo={isDemo}
                bubbleBoxes={bubbleBoxes}
              />
            </section>
          </main>
        </div>
      );
    } else {
      return <div>Loading PMA...</div>;
    }
  }
}

export default PrintPMA;
