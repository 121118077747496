export default theme => ({
  root: {

  },
  field: {
    //padding: theme.spacing(3)
    margin: theme.spacing(3)
  },
  textField: {
    width: '100%',
    maxWidth: '100%',
    //marginRight: theme.spacing(3)
  },
  dropdownTopMargin: {
    marginTop: -theme.spacing(4)
  },
  dropdownTopMargin2Unit: {
    marginTop: -theme.spacing(2)
  },
  dropdownTopMargin1UnitPlus: {
    marginTop: theme.spacing(1)
  },
  portletFooter: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  fieldError: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  footerButtons: {
    position: 'relative',
    display: 'flex'
  },
  footerButtonsCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerButtonsLeftRight: {
    flex: 1
  },
  smallAvatar: {
    width: 20,
    height: 20,
  },
  selectedDiv: {
    display: 'flex'
  },
  mlsItem: {
    paddingLeft: 3 * theme.spacing.unit,
  },
  mlsGroup: {
    fontWeight: '400',
    opacity: 1,
  },
  primaryMainColor: {
    '&>span':{
    color: theme.palette.primary.main,
    fontWeight: '800'
    }
  },
  centerLabel: {
    marginTop: -theme.spacing(1)
  }
});
