import React, { Component } from "react";
import YEOneFirstPageLeft from "./YEOneFirstPageLeft";
import YEOneFirstPageRight from "./YEOneFirstPageRight";

class YEOneFirstPage extends Component {
  render() {
    const {
      printVersion,
      agentData,
      hoData,
      currentArea,
      mlsAreas,
      navbarData,
      quarterlyCityTable,
      version,
      viewingGeneratedCL,
      marketActivity,
      coverPageDetails,
      testimonials,
      agentDesignations,
      MQCityDetails,
      yearEnd,
      demoAccountAreaName,
      demoAccountCoverPhoto
    } = this.props;
    return (
      <div
        className={
          "pma-page-container " + (printVersion ? "print-page-container" : "")
        }
      >
        <div style={{ display: "flex" }} className="ye1-first-page">
          <YEOneFirstPageLeft
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            MQCityDetails={MQCityDetails}
            printVersion={printVersion}
            version={version}
            marketActivity={marketActivity}
            navbarData={navbarData}
            quarterlyCityTable={quarterlyCityTable}
            viewingGeneratedCL={viewingGeneratedCL}
            coverPageDetails={coverPageDetails}
            testimonials={testimonials}
            agentDesignations={agentDesignations}
          />
          <YEOneFirstPageRight
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            MQCityDetails={MQCityDetails}
            printVersion={printVersion}
            version={version}
            marketActivity={marketActivity}
            navbarData={navbarData}
            quarterlyCityTable={quarterlyCityTable}
            viewingGeneratedCL={viewingGeneratedCL}
            coverPageDetails={coverPageDetails}
            testimonials={testimonials}
            agentDesignations={agentDesignations}
            yearEnd={yearEnd}
            demoAccountAreaName={demoAccountAreaName}
            demoAccountCoverPhoto={demoAccountCoverPhoto}
          />
        </div>
      </div>
    );
  }
}

export default YEOneFirstPage;
