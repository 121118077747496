import React, { Component } from "react";
import DashboardBodyRange from "views/Pma/PmaEditor/MidQuarter/MQTwo/MQTwoSecondPage/DashboardBodyRange";

class DashboardBody extends Component {
  render() {
    const { MQCityDetails, printVersion } = this.props;
    const { dashBoardDetailsWithPriceRange } = MQCityDetails;
    console.log(
      "******dashBoardDetailsWithPriceRange",
      dashBoardDetailsWithPriceRange
    );
    console.log("******dashboard body props", this.props);
    let mappedRanges;
    if (!dashBoardDetailsWithPriceRange) {
      return <div className="dashboard-data-pending">Data pending</div>;
    } else {
      let reversed = [...dashBoardDetailsWithPriceRange].reverse();
      mappedRanges = reversed.map((range, i) => {
        return (
          <DashboardBodyRange
            printVersion={printVersion}
            key={i}
            rangeNum={i + 1}
            baths={range.AverageBaths}
            halfBaths={range.AverageHalfBaths}
            beds={range.AverageBeds}
            dom={range.AverageDaysOnMarket}
            garageSpaces={range.AverageGarageSpaces}
            sqft={range.AverageSquareFeet}
            lotSize={range.AverageLotSize}
            homesSold={range.HomesSold}
            oceanView={range.OceanView}
            golfView={range.golfView}
            priceRangeLow={range.PriceRangeLow}
            priceRangeHigh={range.PriceRangeHigh}
          />
        );
      });
      return (
        <div className="dashboard-body">
          {mappedRanges}
          <div className="property-attributes-disclaimer-container">
            <p className="property-attributes-disclaimer">
              The above numbers represent averages in each price category.
            </p>
          </div>
        </div>
      );
    }
  }
}

export default DashboardBody;
