import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  withStyles,
  Typography,
  Grid,
  Divider,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import styles from './styles';
import { withSnackbar } from 'notistack';
import compose from 'recompose/compose';
import { CardLayoutComponent } from 'components';

const AutoPayDialog = props => {
  const {
    classes,
    openDialog,
    handleConfirmationResponse,
    handleAgreeandSubmit,
    tokenData,
  } = props;
  const [open, setOpen] = React.useState(openDialog);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth={'md'}
      >
        <DialogContent dividers>
          <div className={classes.contentDisplay}>
            <div className={classes.congratsText}>
              <Typography variant="h4" className={classes.colorGreen}>
                Congratulations! We have received your card information.
              </Typography>
            </div>

            <div className={classes.cardSection}>
              <CardLayoutComponent cardData={tokenData} />
            </div>
            <div className={classes.bottomText}>
              <Typography variant="subtitle2" className={classes.dialogContent}>
                Your credit card information is encrypted and saved by STRIPE.
              </Typography>
              <Typography variant="subtitle2" className={classes.dialogContent}>
                Your credit card information is secure and encrypted and held on
                file with STRIPE.
              </Typography>
              <Typography variant="subtitle2" className={classes.dialogContent}>
                Your credit card will be automatically charged with each
                mailing.
              </Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Divider />
          <div className={classes.portletFooter}>
            <div className={classes.footerButtons}>
              <div className={classes.footerButtonsLeftRight}>
                <Button
                  onClick={event => {
                    handleClose();
                    handleConfirmationResponse(true);
                  }}
                  color="default"
                >
                  Cancel
                </Button>
              </div>
              <div className={classes.footerButtonsCenter}>
                <Button
                  onClick={event => {
                    handleClose();
                    handleConfirmationResponse(true);
                    handleAgreeandSubmit();
                  }}
                  variant="contained"
                  color="primary"
                  autoFocus
                >
                  Agree & Submit Registration
                </Button>
              </div>
              <div
                className={classNames(
                  classes.footerButtonsLeftRight,
                  classes.rightAlign
                )}
              ></div>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default compose(
  withSnackbar,
  withStyles(styles)
)(AutoPayDialog);
