import React, { Component } from "react";

class AddAreasModal extends Component {
  render() {
    const { mlsAreas, currentArea, onCloseFn, onEditFormSubmit } = this.props;
    console.log("mlsAreas", mlsAreas);
    let filtered = mlsAreas.filter(
      (area) => area.mlsAreaID !== currentArea.mlsAreaID
    );
    console.log("filtered", filtered);
    return (
      <div className="add-areas-modal">
        <p className="add-areas-title">
          Please choose the areas you would like to apply this quadrant
          information to
        </p>
        <div className="area-list-section">
          {filtered.map((area) => {
            console.log("area is", area);
            return (
              <div className="area-selection-row">
                {" "}
                <input type="checkbox" id="scales" name="scales" />{" "}
                <p>{area.mlsNeighborhood}</p>
              </div>
            );
          })}
        </div>
        <div className="add-areas-button-row">
          <div className="edit-form-cancel-button" onClick={onCloseFn}>
            <p>Cancel</p>
          </div>
          <div className="edit-form-submit-button" onClick={onEditFormSubmit}>
            <p>Submit</p>
          </div>
        </div>
      </div>
    );
  }
}

export default AddAreasModal;
