import React, { Fragment, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Button, Typography, Divider, Container, Tooltip } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { propertyUtil } from '../../PropertyUtil';
import Slide from '@material-ui/core/Slide';
import { red } from '@material-ui/core/colors';
import classNames from 'classnames';
import styles from './styles';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import PropertyImagesGridList from './PropertyImagesGridList';
import { agentBarSwitchClosureV2, MailProCloseButton } from './MailProHTMLShortMethods';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PropertyImageSliderDialog = (props) => {
    let { classes, src, className, isOpen, alt, propertyDetails, mailingDetails, selectedItem, closeFn, customStyle } = props;

    const [open, setOpen] = useState(isOpen ? isOpen : false);
    const [index, setIndex] = useState(0);
    //define default class
    className = className ? className : 'mailing-photo-thumbnail';
    //get mailing details data
    const {
        SiteNumber,
        SiteStreet,
        SiteCity,
        SiteState,
        SiteZip
    } = mailingDetails;

    const { photos } = propertyDetails ? propertyDetails : {};
    let photosData = photos && Array.isArray(photos) ? photos : [];

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        if (typeof closeFn === 'function') {
            closeFn();
        }
    };

    const slideRight = () => {
        setIndex((index + 1) % photosData.length); // increases index by 1
    };

    const slideLeft = () => {
        const nextIndex = index - 1;
        if (nextIndex < 0) {
            setIndex(photosData.length - 1); // returns last index of images array if index is less than 0
        } else {
            setIndex(nextIndex);
        }
    };

    let photoMap =
        photosData &&
        photosData.map((photo, index) => (
            <div key={index} style={{ cursor: "pointer" }}>
                <img src={photo} alt="Property" style={{ cursor: "pointer" }} />
            </div>
        ));

    const noImageMode = photoMap.length === 0

    customStyle = customStyle || {}

    return (
        <div>
            <div onClick={event => (!noImageMode ? handleClickOpen() : '')}>
                <Fragment>
                    {!noImageMode ?
                    <Tooltip title={`View ${photoMap.length} Available Photos`}>
                        <p className={'blue-text pointer view-image-photo'} style={(customStyle.children || {})}>View {photoMap.length} Avail Photos</p></Tooltip>
                        : <p style={{...{marginBottom: '8px'}, ...(customStyle.children || {})}}>
                        No Photos Available
                      </p>}
                </Fragment>
            </div>
            {open ?
                <Dialog
                    open={true}
                    onClose={handleClose}
                    scroll={"paper"}
                    aria-labelledby="propert-view-dialog"
                    aria-describedby="propert-view-dialog-description"
                    maxWidth={'md'}
                    TransitionComponent={Transition}
                    keepMounted
                    BackdropProps={{
                        classes: {
                            root: classes.backDropBackgroundDark
                        }
                    }}
                >
                    <DialogTitle id="scroll-dialog-title">
                        <div className={classes.propertyFactHeader}>
                            <Typography variant="h4">
                                Property Images
                            </Typography>
                            <div>
                                <Typography variant="h5">
                                    <span>
                                        {propertyUtil.emptyValueCheck(SiteNumber)}{" "}
                                        {propertyUtil.emptyValueCheck(SiteStreet)}
                                    </span>
                                    <br />
                                    <span>
                                        {propertyUtil.emptyValueCheck(SiteCity)
                                            ? propertyUtil.emptyValueCheck(SiteCity) + ","
                                            : ""}
                                        {propertyUtil.emptyValueCheck(SiteState)
                                            ? propertyUtil.emptyValueCheck(SiteState) + " "
                                            : ""}
                                        {propertyUtil.emptyValueCheck(SiteZip)}
                                    </span>
                                </Typography>
                            </div>
                            <MailProCloseButton onClick={handleClose} />
                        </div>
                    </DialogTitle>
                    {/* Ho Dynamic section html begin */}
                    {agentBarSwitchClosureV2(classes).call({ props: {...props}})}
                    {/* Ho Dynamic section html end */}
                    <DialogContent dividers={true} className={classes.dialogContentNoPadding} style={{ padding: 0 }}>
                        <Carousel
                            className={"lg-image-slider"}
                            showThumbs={false}
                            showIndicators={true}
                            infiniteLoop={true}
                            width="700px"
                            dynamicHeight={true}
                            emulateTouch={false}
                            autoPlay={true}
                            useKeyboardArrows={true}
                            stopOnHover={true}
                            showStatus={true}
                            selectedItem={selectedItem ? selectedItem : 0}
                        >
                            {photoMap}
                        </Carousel>
                    </DialogContent>
                </Dialog>
                : ''}
        </div>
    );
};
export default compose(withStyles(styles))(PropertyImageSliderDialog);