import React, { Fragment, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import { connect } from "react-redux";
import { pmaActions } from "_store/_actions";
// import { updateCheckboxPhotoModalOpen } from "pmaActions";
import styles2, { getPHListStyle } from "views/PmaAdmin/styles";
import styles from "views/PmaAdmin/PropertyHighlights/styles";
import "views/PmaAdmin/PropertyHighlights/styles.scss";
import { Dashboard as DashboardLayout } from "layouts";
import { withSnackbar } from "notistack";

import classNames from "classnames";
import { ImageUploadDialog } from "components";
import { GetPropertyPhotoDetails } from "services/others";
import Base from "common/constants";
import { GetStateStorage, IsValidSessionSwitch } from "common/storage";
import moment from "moment";

import {
  UpdatePropertyHighlights,
  UpdatePropertyHighlightsForSingleArea,
} from "services/agent";
import { showErrorNotification, showInfoNotification } from "common/helpers";
import { showSuccessNotification } from "common/helpers";
import { withRouter } from "react-router-dom";
import { Store } from "Store";

import { MediaViewComponent } from "components";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { propertyUtil } from "views/Pma/PropertyUtil";
// import HighlightRow from "./HighlightRow";
// import EditProperty from "./EditProperty";

import EditProperty from "views/Pma/PmaEditor/CommonEditor/PhotoEditProperty";
import AddProperty from "views/Pma/PmaEditor/CommonEditor/PhotoAddProperty";

import PhotoErrorModal from "./PhotoErrorModal";

import SingleListingRow from "./SingleListingRow";
require("views/Pma/PmaEditor/YearEnd/YETwo/ye2styles.scss");

const TestPhoto2 = (props) => {
  const { dispatch } = useContext(Store);
  const isAValidSessionSwitch = IsValidSessionSwitch();
  const { userData } = GetStateStorage();

  const maxHighlightTitleLength = 28;
  const maxHighlightSubtitleLength =
    Base.PMA_ADMIN.MAX_HIGHLIGHT_SUBTITLE_LENGTH;
  const maxHighlightFooter1Length = 60; //60
  const maxHighlightFooter2Length = 30;

  const [showEditDialog, setEditDialog] = React.useState(false);
  const [showAddPropDialog, setAddPropDialog] = React.useState(false);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState("");
  const [goToPma, setGoToPma] = useState(false);
  const [tempList, setTempList] = useState(null);
  const [tempProp, setTempProp] = useState(null);
  const [photoDetails, setPhotoDetails] = useState(null);
  const [oldDetails, setOldDetails] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openUploadImageDialog, setUploadImageDialog] = React.useState(false);
  const [openViewImageDialog, setViewImageDialog] = React.useState(false);
  const [uploadImageKey, setUploadImageKey] = React.useState(null);
  const [uploadCurrentImage, setUploadCurrentImage] = React.useState(null);
  const [oldTitle, setOldTitle] = React.useState({
    propertyHighlightsTitle: "",
    propertyHighlightsSubtitle: "",
  });
  const [oldFooter, setOldFooter] = React.useState({
    propertyHighlightsFooterText1: "",
    propertyHighlightsFooterText2: "",
  });
  const [editPropFormData, setEditPropFormData] = React.useState({
    address: "",
    bedBaths: "",
    brokerageName: "",
    cityName: "",
    daysOnMarket: "",
    displayOrder: "",
    isBuyingAgent: false,
    isSystemSelected: false,
    isSellingAgent: true,
    listDate: "",
    listPrice: "",
    listingAgentLicenseID: "",
    listingAgentName: "",
    lotSizeSquareFt: "",
    photoURL: "",
    saleDate: "",
    salePrice: "",
    sellingAgentLicenseID: "",
    sellingAgentName: "",
    squareFeet: "",
    status: "Sold",
    streetDirPrefix: "-",
    streetName: "",
    streetNumber: "",
    streetSuffix: "",
    unitNumber: "",
    view: "",
    justAdded: false,
  });

  const [oldPropData, setOldPropData] = React.useState({
    address: "",
    bedBaths: "",
    brokerageName: "",
    cityName: "",
    daysOnMarket: "",
    displayOrder: "",
    isBuyingAgent: false,
    isSystemSelected: false,
    isSellingAgent: true,
    listDate: "",
    listPrice: "",
    listingAgentLicenseID: "",
    listingAgentName: "",
    lotSizeSquareFt: "",
    photoURL: "",
    saleDate: "",
    salePrice: "",
    sellingAgentLicenseID: "",
    sellingAgentName: "",
    squareFeet: "",
    status: "",
    streetDirPrefix: "-",
    streetName: "",
    streetNumber: "",
    streetSuffix: "",
    unitNumber: "",
    view: "",
    justAdded: false,
  });
  const emptyProp = {
    address: "",
    bedBaths: "",
    brokerageName: "",
    cityName: "",
    daysOnMarket: "",
    displayOrder: "",
    isBuyingAgent: false,
    isSystemSelected: false,
    isSellingAgent: true,
    listDate: "",
    listPrice: "",
    listingAgentLicenseID: "",
    listingAgentName: "",
    lotSizeSquareFt: "",
    photoURL: "",
    saleDate: "",
    salePrice: "",
    sellingAgentLicenseID: "",
    sellingAgentName: "",
    squareFeet: "",
    status: "",
    streetDirPrefix: "-",
    streetName: "",
    streetNumber: "",
    streetSuffix: "",
    unitNumber: "",
    view: "",
    justAdded: false,
  };
  const [propHighlightData, setPropHighlightData] = React.useState([]);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const isAdminAccess =
    isAValidSessionSwitch ||
    userData.listgenRoleID === Base.APP_DATA.APP_ADMIN_ROLE_ID;
  //
  // const reorderPhotoList = (photoDetails) => {
  //   console.log("inside reorder", photoDetails);
  //
  //   let selected = photoDetails.filter((prop) => prop.isSystemSelected === true);
  //   if (selected.length === 0) {
  //     console.log("****no selected here");
  //     //  let mapToIndex = selected.map((prop, index) => (prop.displayOrder = index));
  //   }
  //   let unselected = photoDetails.filter((prop) => prop.isSystemSelected === false);
  //   let propsNeeded = 5 - selected.length;
  //   if (selected.length < 5) {
  //     let removeTopSales = unselected.slice(0, propsNeeded);
  //     let makeSelected = removeTopSales.map(
  //       (prop) => (prop = { ...prop, isSelected: true })
  //     );
  //     selected = [...selected, ...makeSelected];
  //   }
  //   let sortSelected = selected.map(
  //     (prop, index) => (prop = { ...prop, displayOrder: index })
  //   );
  //   let transformUnselected = unselected
  //     .slice(propsNeeded)
  //     .map(
  //       (prop, index) =>
  //         (prop = { ...prop, displayOrder: index + selected.length })
  //     );
  //   let updatedPropInfo = [...sortSelected, ...transformUnselected];
  //
  //   return updatedPropInfo;
  // };
  const reorderSelected = (details) => {
    console.log("photo details from reorderSelected", details);
    let selected = details.filter((prop) => prop.isSystemSelected === true);
    // console.log("selected are", selected);
    if (selected.length === 0) {
      console.log("selected.length === 0");
      let createSelected = details
        .slice(0, 6)
        .map((prop, index) => (prop.isSystemSelected = true));
      let createSelected1 = details
        .slice(0, 6)

        .map((propAgain, index) => (propAgain.displayOrder = index));
    }

    let unselected = details.filter((prop) => prop.isSystemSelected === false);
    console.log("unselected", unselected);
    let selectedWithSameOrder = selected
      .map((prop) => prop.displayOrder)
      .filter((order) => order === 0).length;
    console.log("selectedWithSameOrder", selectedWithSameOrder);
    if (selectedWithSameOrder > 1) {
      console.log("true selectedWithSameOrder");
      let sortSelected = selected.map(
        (prop, index) => (prop = { ...prop, displayOrder: index })
      );
      return [...sortSelected, ...unselected];
    } else {
      console.log("else returning details");
      return details;
    }
  };
  const getAreaPropHighlights = () => {
    const { roundSalePrice } = propertyUtil;
    const { isMemberOfTeam, listgenUserID } = userData;
    setIsLoading(true);
    console.log("inside property highlghts", props);
    const thisArea = props.currentArea.mlsAreaID;
    //use the below for single areas
    //GetAreaPropHighlights(userData.listgenUserID, thisArea)

    const {
      recentActivityPhotoList,
      currentSoldListings,
      updatePhotoDetails,
    } = props;
    // console.log("****data", photoDetailsData);
    let updatedPropInfo = reorderSelected(recentActivityPhotoList);
    console.log("updatedPropInfo", updatedPropInfo);
    // console.log("reorderSelected", updatedPropInfo);
    let uniqueProps = getUniqueProps(updatedPropInfo);

    // let updatedData = {
    //   recentActivityPhotoList: uniqueProps,
    // };
    // console.log("updatedData", updatedData);
    console.log("uniqueProps", uniqueProps);
    // setPropHighlightData(uniqueProps);
    // setPropHighlightData(uniqueProps);
    // setPhotoDetails(uniqueProps);
    updatePhotoDetails(uniqueProps);
    setOldDetails(Array.from(uniqueProps));
    setEditPropFormData({
      ...editPropFormData,
    });
    // console.log("photo details after", photoDetails);
    // console.log("editPropFormData", editPropFormData);

    setIsLoading(false);
  };
  const { recentActivityPhotoList } = props;
  // const getAreaPropHighlights = () => {
  //   const { roundSalePrice } = propertyUtil;
  //   const { isMemberOfTeam, listgenUserID } = userData;
  //   setIsLoading(true);
  //   // console.log("inside property highlghts");
  //   const thisArea = props.currentArea.mlsAreaID;
  //   //use the below for single areas
  //   //GetAreaPropHighlights(userData.listgenUserID, thisArea)
  //   GetPropertyPhotoDetails(userData.listgenUserID, userData.ownedMLSID[0])
  //     .then((data) => {
  //       if (Object.keys(data).length > 0) {
  //         const { photoDetails: photoDetailsData } = data;
  //         // console.log("****data", photoDetailsData);
  //         let updatedPropInfo = reorderSelected(photoDetailsData);
  //         // console.log("reorderSelected", updatedPropInfo);
  //         let uniqueProps = getUniqueProps(updatedPropInfo);
  //
  //         let updatedData = {
  //           ...data,
  //           photoDetails: uniqueProps,
  //         };
  //         console.log("updatedData", updatedData);
  //         // console.log("uniqueProps", uniqueProps);
  //         // setPropHighlightData(uniqueProps);
  //         setPropHighlightData(updatedData);
  //         setPhotoDetails(uniqueProps);
  //         setOldDetails(Array.from(uniqueProps));
  //         setEditPropFormData({
  //           ...editPropFormData,
  //         });
  //         // console.log("photo details after", photoDetails);
  //         // console.log("editPropFormData", editPropFormData);
  //       } else {
  //         setPropHighlightData([]);
  //         setPhotoDetails([]);
  //         setOldDetails([]);
  //       }
  //     })
  //     .catch((err) => {
  //       setPhotoDetails([]);
  //       setOldDetails([]);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  useEffect(() => {
    if (
      propHighlightData &&
      Object.keys(propHighlightData).length > 1 &&
      isSubmitting === true
    ) {
      // console.log("about to go to submit, in use effect");
      onPropHighlightSubmit();
    }
  }, [isSubmitting]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   if (Object.keys(propHighlightData).length === 0) {
  //     GetPropertyPhotoDetails(
  //       userData.listgenUserID,
  //       props.currentArea.mlsAreaID
  //     );
  //   }
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(propHighlightData).length === 0) {
      getAreaPropHighlights();
    }
  }, []);

  const openUploadMedia = async (key, currentImage) => {
    setUploadImageDialog(true);
    setUploadCurrentImage(currentImage);
    setUploadImageKey(key);
  };

  const handlePropertyHighlightUpload = async (uploadedImageUrl) => {
    if (uploadedImageUrl && uploadedImageUrl.optimizedPhotoURL) {
      setEditPropFormData({
        ...editPropFormData,
        photoURL: uploadedImageUrl.optimizedPhotoURL,
        optimizedPhotoURL: uploadedImageUrl.optimizedPhotoURL,
        justAdded: true,
      });
    }
    setUploadImageDialog(false);
    setUploadCurrentImage(null);
    setUploadImageKey(null);
    setShowError("");
  };

  const openViewMedia = async (currentImage) => {
    setViewImageDialog(true);
    setUploadCurrentImage(currentImage);
  };

  const closeViewMedia = async () => {
    setViewImageDialog(false);
    setUploadCurrentImage(null);
  };
  const updateChanges = () => {
    //add stuff here
    console.log("***inside update changes, editPropFormData", editPropFormData);
    console.log(
      "****updateChanges, recentActivityPhotoList",
      recentActivityPhotoList
    );
    console.log("updating changes");
    onPropHighlightSubmit();
  };
  const handleOpenEditDialog = (prop) => {
    // console.log("prop is", prop);
    const propObj = prop;
    setOldPropData({
      ...propObj,
      streetSuffix: propObj.streetSuffix === "-" ? "" : propObj.streetSuffix,
      unitNumber: propObj.unitNumber === "-" ? "" : propObj.unitNumber,
    });
    setEditPropFormData({
      ...propObj,
      streetSuffix: propObj.streetSuffix === "-" ? "" : propObj.streetSuffix,
      unitNumber: propObj.unitNumber === "-" ? "" : propObj.unitNumber,
    });
    setEditDialog(true);
  };

  const handleErrorModal = (type) => {
    if (type === "close") {
      setShowErrorModal(false);
      setErrorModalText("false");
    }
  };

  const isValidTopFiveForm = () => {
    let isValid = true;
    console.log("inside isValidTopFiveForm");
    // const {
    //   areaID,
    //   averageSalePricePreviousYear,
    //   averageSalePriceCurrentYear,
    //   countyID,
    //   homesSoldPreviousYear,
    //   homesSoldCurrentYear,
    //   listgenUserAreaID,
    //   listgenUserID,
    //   photoDetails,
    //   photoDetailsRunDate,
    //   propertyHighlightsSubtitle,
    //   propertyHighlightsTitle,
    //   propertyHighlightsFooterText1,
    //   propertyHighlightsFooterText2,
    //   totalSalePricePreviousYear,
    //   totalSalePriceCurrentYear,
    // } = propHighlightData;
    // console.log(propHighlightData);
    // console.log(
    //   areaID,
    //   averageSalePricePreviousYear,
    //   averageSalePriceCurrentYear,
    //   countyID,
    //   homesSoldPreviousYear,
    //   homesSoldCurrentYear,
    //   listgenUserAreaID,
    //   listgenUserID,
    //   photoDetails,
    //   photoDetailsRunDate,
    //   propertyHighlightsSubtitle,
    //   propertyHighlightsTitle,
    //   propertyHighlightsFooterText1,
    //   propertyHighlightsFooterText2,
    //   totalSalePricePreviousYear,
    //   totalSalePriceCurrentYear
    // );
    if (
      // !areaID ||
      // !averageSalePricePreviousYear ||
      // !averageSalePriceCurrentYear ||
      // !countyID ||
      // !homesSoldPreviousYear ||
      // !homesSoldCurrentYear ||
      // !listgenUserAreaID ||
      // !listgenUserID ||
      !photoDetails
      //||
      // !photoDetailsRunDate ||
      // !propertyHighlightsSubtitle ||
      // !propertyHighlightsTitle ||
      // propertyHighlightsFooterText1 ||
      // propertyHighlightsFooterText2 ||
      // !totalSalePricePreviousYear ||
      // !totalSalePriceCurrentYear
    ) {
      isValid = false;
    }
    // console.log("is Valid====", isValid);
    return isValid;
  };

  const handleFormClose = () => {
    setAddPropDialog(false);
    setEditDialog(false);
    setShowErrorModal(false);
    setErrorModalText("");
  };

  const onHighlightFooterClose = () => {
    setOldFooter({
      propertyHighlightsFooterText1: "",
      propertyHighlightsFooterText2: "",
    });
  };
  const onHighlightFooterOpen = () => {
    // console.log("inside highlightfooterOpen", propHighlightData);

    setOldFooter({
      propertyHighlightsFooterText1:
        propHighlightData.propertyHighlightsFooterText1,
      propertyHighlightsFooterText2:
        propHighlightData.propertyHighlightsFooterText2,
    });
  };
  const handleAddPropDialog = () => {
    console.log("inside handle add prop");
    let selected =
      (recentActivityPhotoList &&
        recentActivityPhotoList.filter(
          (property) => property.isSystemSelected === true
        )) ||
      [];
    if (selected.length >= 6) {
      setErrorModalText("Max properties");
      setShowErrorModal(true);
    } else {
      console.log("about to open add property");
      setAddPropDialog(true);
      setEditPropFormData(emptyProp);
    }
  };

  const handleAddProperty = () => {
    const { updatePhotoDetails } = props;

    const {
      streetNumber,
      streetName,
      cityName,
      salePrice,
      brokerageName,
      photoURL,
    } = editPropFormData;
    if (
      !streetNumber ||
      !streetName ||
      !cityName ||
      !salePrice ||
      !brokerageName ||
      !photoURL
    ) {
      setErrorModalText("Missing inputs");
      setShowErrorModal(true);
    } else {
      let numberPattern = /\d+/g;
      //Removes all commas, dollar sign and non-numeric characters from user entered sale price
      let salePriceOnlyNumbers = (
        (editPropFormData.salePrice || 0) + ""
      ).replace(/\D/g, "");
      let selected = recentActivityPhotoList.filter(
        (property) => property.isSystemSelected === true
      );

      let otherAgentSale = editPropFormData.soldByOtherAgent === undefined;
      let changedObj = {
        ...editPropFormData,
        salePrice: salePriceOnlyNumbers || 0,
        isSystemSelected: true,
        displayOrder: selected.length,
        soldByOtherAgent: otherAgentSale ? true : false,
      };

      setEditPropFormData(changedObj);
      // setPropHighlightData({
      //   ...propHighlightData,
      //   recentActivityPhotoList: [...propHighlightData.recentActivityPhotoList, changedObj],
      // });
      // setPhotoDetails({
      //   ...recentActivityPhotoList,
      //   recentActivityPhotoList: [...recentActivityPhotoList, changedObj],
      // });

      // updatePhotoDetails(changedObj);
      // setIsSubmitting(true);
      // setAddPropDialog(false);
    }
  };
  const wholePageSubmit = () => {
    //setIsSubmitting(true);
    setGoToPma(true);
    //onPropHighlightSubmit();
  };
  const handleAddTitle = (overMaxTitle, overMaxSubtitle) => {
    if (overMaxTitle || overMaxSubtitle) {
      setErrorModalText("Max title");
      setShowErrorModal(true);
    } else {
      setIsSubmitting(true);
    }
  };

  const handleAddFooter = (overMaxFooter1, overMaxFooter2) => {
    if (overMaxFooter1 || overMaxFooter2) {
      setErrorModalText("Max title");
      setShowErrorModal(true);
    } else {
      setIsSubmitting(true);
    }
  };

  const onPropHighlightSubmit = () => {
    // const { recentActivityPhotoList } = propHighlightData;
    let selected = recentActivityPhotoList.filter(
      (property) => property.isSystemSelected === true
    );
    console.log("inside submit");
    let isValid = isValidTopFiveForm();
    //console.log("isValid", isValid);
    //  console.log("is it valid in prophighlightsubmit?", isValid);
    if (!isValid) {
      //console.log("is not valid);");
      setErrorModalText("Issue submitting form");
      setShowErrorModal(true);
    } else {
      //console.log("in the else");
      //now start loading and submit the form
      try {
        // const stateData = GetStateStorage();
        // const agentData = stateData.userData;
        //check if state data not founf
        //const { mlsAreas, switchArea } = props;
        // /**Get session storage */
        // console.log("in the try block");
        //  let paramAreaID = props.match.params.areaID;
        // console.log("match****", props);
        // console.log("paramAreaID", paramAreaID);
        // let navbarData =
        //   agentData && Array.isArray(agentData.navbarInfo)
        //     ? agentData.navbarInfo.filter(
        //         (area) => area.mlsAreaID === paramAreaID
        //       )[0]
        //     : {};
        //  console.log("prophighlightData", propHighlightData);
        setBtnLoading(true);
        //use the below only for single area
        console.log("propHighlightData", propHighlightData);
        UpdatePropertyHighlightsForSingleArea(propHighlightData)
          //UpdatePropertyHighlights(propHighlightData)
          .then((data) => {
            console.log("data is", data);
            showSuccessNotification(
              props,
              `Your property highlights have been saved`
            );
            setBtnLoading(false);
            //
            setIsSubmitting(false);
            // console.log("props before set timeout", props);
            if (goToPma) {
              setTimeout(() => {
                props.history.push(`/pma/${props.match.params.areaID}`);
              }, 1300);
            }
          })
          .catch((err) => {
            //console.log("in another catch");
            showErrorNotification(
              props,
              "Something went wrong while processing your request."
            );
            setBtnLoading(false);
          });
      } catch (err) {
        //  console.log("in the catch");
        showErrorNotification(props, "Unable to process request.");
        setBtnLoading(false);
      }
    }
  };
  const keepOrRemoveProperty = (keepProperty) => {
    // console.log("***list is inside prop func", list);
    // console.log("keepProperty", keepProperty);
    // console.log("tempList", tempList);
    // console.log("tempProp", tempProp);
    if (keepProperty) {
      handlePropCheck(null, tempProp, true, true, true);
      // return tempProp;
    } else {
      handlePropCheck(null, tempProp, true, true, false);
      //return Object.assign(tempList, { checked: false });
    }
  };
  const handlePropCheck = (e, list, hasBanner, fromErrorModal, keepProp) => {
    // console.log("****recentActivityPhotoList", recentActivityPhotoList);
    // console.log("does it have banner", hasBanner);
    const { updatePhotoDetails } = props;
    const updateCheck = recentActivityPhotoList.map((prop) => {
      // console.log("***prop is", prop);
      // console.log("list is", list);

      if (
        prop.streetName === list.streetName &&
        prop.streetNumber === list.streetNumber &&
        prop.listDate === list.listDate
      ) {
        //  console.log("****same prop");
        //  console.log("**list is", list);
        //console.log("actual list", list.isSystemSelected, "opposite", !list.isSystemSelected);
        let preCheck = recentActivityPhotoList.filter(
          (prop) => prop.isSystemSelected
        ).length;
        //  console.log("preCheck", preCheck);

        if (list.isSystemSelected && fromErrorModal && keepProp) {
          //  console.log("FROM ERROR MODAL keep prop");
          setErrorModalText("");
          setShowErrorModal(false);

          return prop;
        } else if (list.isSystemSelected && fromErrorModal && !keepProp) {
          //  console.log("FROM ERROR MODAL dont keep prop);");
          setErrorModalText("");
          setShowErrorModal(false);

          const updatedCheck = {
            isSystemSelected: false,
          };
          //  console.log("***updated check is", updatedCheck);
          return Object.assign(prop, updatedCheck);
        } else if (list.isSystemSelected && hasBanner) {
          // console.log("checked and banner");
          setErrorModalText("Banner Property");
          setShowErrorModal(true);
          // setTempList(list);
          setTempProp(list);
          // console.log("list is", list);
          //
          // console.log("decide whether to keep or remove");

          // return Object.assign(prop, { isSystemSelected: false });

          //keepOrRemoveProperty(list);
          //return Object.assign(prop, { checked: false });
          return prop;
          //console.log("tempProp is", tempProp);
        } else if (preCheck >= 6 && !list.isSystemSelected) {
          //console.log("inside preCheck", this.isSystemSelected);
          setErrorModalText("Max properties");
          setShowErrorModal(true);

          return Object.assign(prop, { isSystemSelected: false });
        } else {
          const updatedCheck = {
            isSystemSelected:
              list.isSystemSelected && list.isSystemSelected == true
                ? false
                : true,
          };
          //console.log("***updated check is", updatedCheck);
          return Object.assign(prop, updatedCheck);
          // return prop;
        }
      } else {
        //console.log("return plain check", prop);
        return prop;
      }
    });

    let checkedCount = updateCheck.filter((prop) => prop.isSystemSelected)
      .length;
    // console.log("checkedCount", checkedCount);
    // console.log("***updatedState", this.state.recentActivityPhotoList[0].isSystemSelected);

    if (checkedCount >= 7) {
      // console.log("*****greater than 7");
      setErrorModalText("Max properties");
      setShowErrorModal(true);
    } else {
      let updateDisplayOrderSelected = updateCheck
        .filter((prop) => prop.isSystemSelected === true)
        .sort((a, b) => a.displayOrder - b.displayOrder);
      let eachProp = updateDisplayOrderSelected.map((selectedProp, index) =>
        Object.assign(selectedProp, { displayOrder: index })
      );
      let unselectedProperties = updateCheck.filter(
        (prop) => prop.isSystemSelected === false
      );
      // console.log("unselectedProperties", unselectedProperties);
      // console.log("updateDisplayOrderSelected", updateDisplayOrderSelected);
      // console.log("****after update check", updateCheck);
      // console.log("each prop", eachProp);
      let combinedPhotos = [...eachProp, ...unselectedProperties];
      // console.log("combinedPhotos", combinedPhotos);
      // props.updateRecentActivity(
      //   Object.assign(props.recentActivityPhotoList, {
      //     recentActivityPhotoList: [...eachProp, ...unselectedProperties],
      //   })
      // );
      //setPhotoDetails([...eachProp, ...unselectedProperties]);
      console.log("combinedPhotos", combinedPhotos);
      updatePhotoDetails(combinedPhotos);
    }
  };
  const onEditFormSubmit = () => {
    // const { recentActivityPhotoList } = propHighlightData;
    console.log(
      "about to submit recentActivityPhotoList",
      recentActivityPhotoList
    );
    const {
      streetNumber,
      streetName,
      cityName,
      salePrice,
      brokerageName,
      photoURL,
    } = editPropFormData;
    if (
      !streetNumber ||
      !streetName ||
      !cityName ||
      !salePrice ||
      !brokerageName ||
      !photoURL
    ) {
      console.log("missing something");
      setErrorModalText("Missing inputs");
      setShowErrorModal(true);
    } else {
      console.log("about to change property");
      const changeProperty = recentActivityPhotoList.map((prop) =>
        prop.streetName === editPropFormData.streetName &&
        prop.streetNumber === editPropFormData.streetNumber &&
        prop.listDate === editPropFormData.listDate &&
        prop.unitNumber === editPropFormData.unitNumber
          ? editPropFormData
          : prop
      );

      let change3 = recentActivityPhotoList.map((prop) => {
        if (
          //compare fields that cannot be edited by the agent
          prop.view === editPropFormData.view &&
          prop.bedBaths === editPropFormData.bedBaths &&
          prop.listingAgentLicenseID === editPropFormData.listingAgentLicenseID
        ) {
          console.log("returning edit prop form data");
          return editPropFormData;
        } else {
          return prop;
        }
      });

      // setPropHighlightData({
      //   ...propHighlightData,
      //   recentActivityPhotoList: change3,
      // });
      setIsSubmitting(true);
      handleFormClose();
    }
  };

  const handleDragEnd = async (result) => {
    // const {
    //   areaID,
    //   countyID,
    //   listgenUserAreaID,
    //   listgenUserID,
    //   photoDetailsRunDate,
    //   photoDetails,
    // } = propHighlightData;
    // dropped outside the list
    console.log("result is", result);
    let selected = recentActivityPhotoList
      ? recentActivityPhotoList.filter((prop) => prop.isSystemSelected === true)
      : [];
    console.log("inside selected", selected);
    let sortSelected = selected.sort((a, b) => a.displayOrder - b.displayOrder);
    let mapToIndex = selected.map((prop, index) => (prop.displayOrder = index));
    let unselected = recentActivityPhotoList
      ? recentActivityPhotoList.filter(
          (prop) => prop.isSystemSelected === false
        )
      : [];
    if (!result.destination) {
      console.log("has no destination");
      return;
    }
    let dataToSort = Array.from(selected);
    let startIndex = result.source.index;
    let endIndex = result.destination.index;
    let [startData] = selected.splice(startIndex, 1);
    selected.splice(endIndex, 0, startData);
    let changeOrders = selected.map(
      (prop, index) => (prop.displayOrder = index)
    );
    let newList = [...selected, ...unselected];
    // console.log("newList is", newList);
    // let constructedHighlights = {
    //   areaID,
    //   countyID,
    //   listgenUserAreaID,
    //   listgenUserID,
    //   photoDetails: newList,
    //   photoDetailsRunDate,
    // };
    //setPhotoDetails(newList);
    try {
      await props.updateRecentActivityPhotos(
        Object.assign(
          props.currentSoldListings,
          (recentActivityPhotoList: newList)
        )
      );
    } catch {
      console.log("error");
    }
    // setPropHighlightData({
    //   ...propHighlightData,
    //   photoDetails: newList,
    // });
    showInfoNotification(
      props,
      `Please SAVE YOUR CHANGES!  Click on the blue LOOKS GOOD – SUBMIT button at the bottom of the screen.`,
      20000,
      "top",
      "center"
    );
  };

  const filterSelectedProps = (propList) => {
    let selected = propList.filter(
      (property) => property.isSystemSelected === true
    );
    return selected.sort((a, b) => a.displayOrder - b.displayOrder);
  };
  const filterUnselectedProps = (propList) => {
    let unselected = propList.filter(
      (property) => property.isSystemSelected !== true
    );
    return unselected.sort((a, b) => a.sortOrder - b.sortOrder);
  };

  const handleSelect = (property, select) => {
    let selected = recentActivityPhotoList.filter(
      (property) => property.isSystemSelected === true
    );
    let uniqueSelect = new Set(selected);
    if (select === true && selected.length >= 6) {
      setErrorModalText("Max properties");
      setShowErrorModal(true);
    } else {
      let propData = recentActivityPhotoList;
      let selected = recentActivityPhotoList.filter(
        (prop) => prop.isSystemSelected === true
      );
      let newDisplayOrder;
      if (select && !selected) {
        newDisplayOrder = 0;
      } else if (select && selected.length > 0) {
        newDisplayOrder = selected.length;
      } else {
        newDisplayOrder = 0;
      }
      const updatedPropList = propData.map((prop) =>
        prop.streetName === property.streetName &&
        prop.streetNumber === property.streetNumber &&
        prop.listDate === property.listDate
          ? {
              ...prop,
              isSystemSelected: select ? true : false,
              displayOrder: newDisplayOrder,
            }
          : prop
      );
      let updateSelected = updatedPropList.filter(
        (property) => property.isSystemSelected === true
      );
      if (!select) {
        let changeSelectOrders = updateSelected.map(
          (prop, index) => (prop.displayOrder = index)
        );
      }
      const {
        areaID,
        countyID,
        listgenUserAreaID,
        listgenUserID,
        photoDetailsRunDate,
      } = propHighlightData;
      let constructedHighlights = {
        areaID,
        countyID,
        listgenUserAreaID,
        listgenUserID,
        recentActivityPhotoList: updatedPropList,
        photoDetailsRunDate,
      };
      // setPropHighlightData({
      //   ...propHighlightData,
      //   photoDetails: updatedPropList,
      // });
    }
  };
  const radioSaleChange = (event) => {
    const { brokerageName } = agentData;

    let val = event.target.value;
    if (val === "my-sale") {
      setEditPropFormData({
        ...editPropFormData,
        brokerageName,
        soldByOtherAgent: false,
      });
    } else {
      setEditPropFormData({
        ...editPropFormData,
        soldByOtherAgent: true,
      });
    }
  };
  const handleStatusOptions = () => {
    console.log("inside STATUS");
    console.log("editPropFormData", editPropFormData);
    // ["Sold", "Pending", "Contingent", "Active"];
    if (editPropFormData.status === "Sold" || !editPropFormData.status) {
      setEditPropFormData({
        ...editPropFormData,
        status: "Pending",
      });
    } else if (editPropFormData.status === "Pending") {
      setEditPropFormData({
        ...editPropFormData,
        status: "Contingent",
      });
    } else if (editPropFormData.status === "Contingent") {
      setEditPropFormData({
        ...editPropFormData,
        status: "Active",
      });
    } else if (editPropFormData.status === "Active") {
      setEditPropFormData({
        ...editPropFormData,
        status: "Sold",
      });
    }
  };

  const handleRepresentedOptions = () => {
    console.log("inside rpresented");
    console.log("editPropFormData", editPropFormData);
    if (editPropFormData.isBuyingAgent && editPropFormData.isSellingAgent) {
      console.log("isBuyingAgent", editPropFormData.isBuyingAgent);
      console.log("isSellingAgent", editPropFormData.isSellingAgent);
      setEditPropFormData({
        ...editPropFormData,
        isSellingAgent: false,
        isBuyingAgent: true,
      });
      console.log("***after change", editPropFormData);
    } else if (editPropFormData.isBuyingAgent) {
      console.log("isBuyingAgent", editPropFormData.isBuyingAgent);
      setEditPropFormData({
        ...editPropFormData,
        isSellingAgent: true,
        isBuyingAgent: false,
      });
      console.log("***after change", editPropFormData);
    } else {
      console.log("isSellingAgent", editPropFormData.isSellingAgent);
      setEditPropFormData({
        ...editPropFormData,
        isSellingAgent: true,
        isBuyingAgent: true,
      });
      console.log("***after change", editPropFormData);
    }
    // else if (editPropFormData.isSellingAgent) {
    //   console.log("isSellingAgent", editPropFormData.isSellingAgent);
    //   setEditPropFormData({
    //     ...editPropFormData,
    //     isSellingAgent: false,
    //     isBuyingAgent: false,
    //   });
    //   console.log("***after change", editPropFormData);
    // }
    // else if (
    //   !editPropFormData.isSellingAgent &&
    //   !editPropFormData.isBuyingAgent
    // ) {
    //   console.log("is none");
    //   setEditPropFormData({
    //     ...editPropFormData,
    //     isSellingAgent: true,
    //     isBuyingAgent: true,
    //   });
    //   console.log("***after change", editPropFormData);
    // }
  };

  const getUniqueProps = (props) => {
    let final = [];
    let addressList = [];
    for (let i = 0; i < props.length; i++) {
      let prop = props[i];
      let address = `${prop.streetNumber} ${prop.streetName} ${prop.unitNumber}`;
      if (!addressList.includes(address)) {
        final.push(prop);
        addressList.push(address);
      } else {
      }
    }
    return final;
  };
  const handleInputChange = (e) => {
    const target = e.target;
    let value = target.value;
    const name = target.name;
    if (e.target.name === "salePrice") {
      value = Number(value);
    }
    setEditPropFormData({
      ...editPropFormData,
      [e.target.name]: value,
    });
  };

  const handleTitleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setPropHighlightData({ ...propHighlightData, [e.target.name]: value });
  };

  const handleFooterChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setPropHighlightData({ ...propHighlightData, [e.target.name]: value });
  };

  const getMonthAndYearString = (inputTime) => {
    try {
      let month = moment(inputTime).format("M");
      let day = moment(inputTime).format("D");
      let year = moment(inputTime).format("YY");
      return (
        <p>
          {month}&#8729;
          {day}&#8729;
          {year}
        </p>
      );
    } catch (err) {
      return "";
    }
  };

  const shortenPresentedBy = (name, brokerageRaw, printVersion) => {
    const { mlsCityID } = props.currentArea;
    let isSB = false;
    if (mlsCityID === "5060" || mlsCityID === "5063" || mlsCityID === "5056") {
      isSB = true;
    }

    let max;
    if (printVersion) {
      max = 47;
    } else {
      max = 47;
    }
    let brokerage = brokerageRaw;
    if (brokerageRaw === "Village Properties - 1") {
      brokerage = "Village Properties";
    }

    const presented = "Presented by "; //20 w/ space
    let presentedCount = presented.length + 1; //adding space
    let brokerageCount = brokerage.split("").length + 1;
    let credit = "Source: MLSListings";

    let allButBrokerageCount = presentedCount + credit.length;
    let leftover = max - allButBrokerageCount;
    let cutoffBrokerage = brokerageCount >= leftover;

    if (brokerage.length < 2) {
      return <p className="presented">{credit}</p>;
    } else if (brokerage.length >= max) {
      return (
        <p className="presented">
          {presented} {brokerage.slice(0, max - 3)}
          ...
          <span className="credit-ml"></span>
          {isSB ? <span>Source: Flexmls &copy;</span> : credit}
        </p>
      );
    } else {
      return (
        <p className="presented">
          {presented} {brokerage} <span className="credit-ml"></span>
          {isSB ? <span>Source: Flexmls &copy;</span> : credit}
        </p>
      );
    }
  };
  const openPhotoModal = () => {
    props.updateCheckboxPhotoModalOpen(true);
    // this.setState({
    //   photoModalOpen: true,
    // });
  };

  const resetDisplayOrder = () => {
    const { recentActivityPhotoList } = propHighlightData;
    let updatedPropList = recentActivityPhotoList.map((property) => ({
      ...property,
      displayOrder: 0,
    }));

    setPropHighlightData({
      ...propHighlightData,
      recentActivityPhotoList: updatedPropList,
    });
  };
  const dateSort = (propertyList) => {
    let sorted = propertyList.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.listDate) - new Date(a.listDate);
    });
    return propertyList;
  };
  const beginClosePhotoModal = () => {
    // const { dateSort } = this;
    console.log("insideBeginClosePhotoModal");
    console.log("recentActivityPhotoList", recentActivityPhotoList);
    console.log("old Details", JSON.stringify(oldDetails));
    console.log(
      "recentActivityPhotoList are",
      JSON.stringify(recentActivityPhotoList)
    );
    let hasChanges =
      JSON.stringify(oldDetails) !== JSON.stringify(recentActivityPhotoList);
    console.log("hasChanges", hasChanges);

    if (!hasChanges) {
      console.log("doesnt have changes");
      closePhotoModal();
    } else {
      console.log("photo modal begin close");
      setErrorModalText("Photo Changes Made");
      setShowErrorModal(true);
      // closePhotoModal();
      //Do the Are you sure you want to exit?
    }
  };

  const closePhotoModal = () => {
    console.log("inside closePhotoModal");
    props.updateCheckboxPhotoModalOpen(false);
  };

  const scrollDownToProperties = () => {
    window.scrollTo({
      top: 970,
      behavior: "auto",
    });
  };

  const { formatSuffix } = propertyUtil;
  // console.log("****photoDetails are", photoDetails);
  const selectedProps =
    recentActivityPhotoList &&
    recentActivityPhotoList.length > 1 &&
    filterSelectedProps(recentActivityPhotoList);
  // console.log("selectedProps", selectedProps);
  const unselectedProps =
    recentActivityPhotoList &&
    recentActivityPhotoList.length > 1 &&
    filterUnselectedProps(recentActivityPhotoList);
  let showButton = true;
  const thisArea = props.currentArea.mlsAreaID;
  const {
    agentData,
    currentArea,
    printVersion,
    hoData,
    photoTableData,
    currentSoldListings,

    // updateChanges,

    solds,
    older,
    isSingleTable,
  } = props;
  console.log("recentActivityPhotoList", recentActivityPhotoList);
  let soldProperties =
    recentActivityPhotoList &&
    recentActivityPhotoList.length >= 1 &&
    recentActivityPhotoList.filter(
      (prop) => prop.status === "Sold" && prop.isSystemSelected == false
    );
  let activeProperties =
    recentActivityPhotoList &&
    recentActivityPhotoList.length >= 1 &&
    recentActivityPhotoList.filter(
      (prop) => prop.status === "Active" && prop.isSystemSelected == false
    );

  // const {
  //   photoModalOpen,
  //
  //   oldPhotoDetails,
  //   showEditPropDialog,
  // } = this.state;
  // const {
  //   getMonthAndYearString,
  //   openPhotoModal,

  //
  //   keepOrRemoveProperty,
  //   updateChanges,
  //   closePhotoModal,
  //   dateSort,
  //
  //   beginClosePhotoModal,
  // } = this;
  //console.log("inside qv4 photos", props);
  const { subdivisionName, coverPageHeader } = hoData;
  const { mlsAreaID, mlsNeighborhood } = currentArea;
  const { splitAreas } = propertyUtil;

  const { demoAccountAreaName } = props;
  const {
    formatBaths,
    toAcre,
    emptyValueCheck,
    endsWithS,
    statusContingentToPending,
  } = propertyUtil;
  // console.log("photoDetails&&&&&", photoDetails);
  let isCountryClubGate = mlsAreaID === "132" ? true : false;
  let {
    listgenUserFirstName,
    agentLicenseNumber,
    isMemberOfTeam,
    teamMembers,
    listgenUserID,
    teamName,
  } = agentData;
  const teamMember1License =
    isMemberOfTeam && teamMembers[0].agentLicenseNumber;
  const teamMember2License =
    isMemberOfTeam && teamMembers[1].agentLicenseNumber;
  let teamMember1Name =
    isMemberOfTeam && teamMembers[0].firstName.toUpperCase();

  let isScar = listgenUserID === "100588";

  const teamMember2Name =
    isMemberOfTeam && teamMembers[1].firstName.toUpperCase();
  const lastPrintDate = "2021-01-22";
  let oldestDate =
    recentActivityPhotoList &&
    recentActivityPhotoList
      .map((prop) => (prop.SaleDate === "-" ? prop.ListDate : prop.SaleDate))
      .sort()[0];
  let date = new Date(oldestDate);
  let filtered1 =
    recentActivityPhotoList &&
    recentActivityPhotoList.length >= 1 &&
    recentActivityPhotoList.filter((prop) => prop.isSystemSelected === true);
  // console.log("filtered1", filtered1);
  // console.log("recentActivityPhotoList", recentActivityPhotoList);
  let mappedListings =
    recentActivityPhotoList &&
    recentActivityPhotoList.length >= 1 &&
    recentActivityPhotoList
      .filter((prop) => prop.isSystemSelected === true)
      .slice(0, 6)
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map((list, i) => {
        // let list = trueList;
        // console.log("list is", list);
        //console.log("i is", i);
        let formattedSaleDate = new Date(list.saleDate).toLocaleDateString(
          "en-US"
        );
        let isListingAgent = false;
        let isNew = list.listDate > lastPrintDate;
        if (
          (!isMemberOfTeam &&
            agentLicenseNumber === list.listingAgentLicenseID) ||
          (isMemberOfTeam &&
            (teamMember1License === list.listingAgentLicenseID ||
              teamMember2License === list.listingAgentLicenseID)) ||
          (list.isCoListingAgent && list.isCoListingAgent === "true") ||
          list.coListingAgents
        ) {
          isListingAgent = true;
        }
        let isSellingAgent = false;
        if (
          (!isMemberOfTeam &&
            agentLicenseNumber === list.sellingAgentLicenseID) ||
          (isMemberOfTeam &&
            (teamMember1License === list.sellingAgentLicenseID ||
              teamMember2License === list.sellingAgentLicenseID)) ||
          (list.isCoSellingAgent && list.isCoSellingAgent === "true")
        ) {
          isSellingAgent = true;
        }
        let soldStatus = list.status === "Sold";
        let bannerText;
        let shouldHighlightDOM = false;
        if (list.daysOnMarket < 30 && list.status !== "Active") {
          shouldHighlightDOM = true;
        }
        let teamComboWithS;
        let teamCombo;
        if (isMemberOfTeam) {
          teamComboWithS = `${teamMember1Name} & ${teamMember2Name}${endsWithS(
            teamMember2Name,
            true
          )}`;
          teamCombo = `${teamMember1Name} & ${teamMember2Name}`;
          if (isScar) {
            teamComboWithS = "TEAM SCARBOROUGH'S";
            teamCombo = "TEAM SCARBOROUGH";
          }
        }

        if (soldStatus) {
          if (listgenUserID === "100528" && isListingAgent && !isSellingAgent) {
            bannerText = "DOUGLAS & ASSOCIATES RECENT SALE";
          } else if (!isMemberOfTeam && isListingAgent && !isSellingAgent) {
            bannerText = `${listgenUserFirstName.toUpperCase()}${endsWithS(
              listgenUserFirstName,
              true
            )} LISTING SOLD`;
          } else if (!isMemberOfTeam && !isListingAgent && isSellingAgent) {
            bannerText = `SOLD BY ${listgenUserFirstName.toUpperCase()}`;
          } else if (!isMemberOfTeam && isListingAgent && isSellingAgent) {
            bannerText = `LISTED & SOLD BY ${listgenUserFirstName.toUpperCase()}`;
          } else if (isMemberOfTeam && isListingAgent && !isSellingAgent) {
            bannerText = `${teamComboWithS} LISTING SOLD`;
            // bannerText = `${teamMember1Name} & ${teamMember2Name}${endsWithS(
            //   teamMember2Name,
            //   true
            // )} LISTING SOLD`;
          } else if (isMemberOfTeam && !isListingAgent && isSellingAgent) {
            bannerText = `SOLD BY ${teamCombo}`;
            // bannerText = `SOLD BY ${teamMember1Name} & ${teamMember2Name}`;
          } else if (isMemberOfTeam && isListingAgent && isSellingAgent) {
            bannerText = `LISTED & SOLD BY ${teamCombo}`;
            // bannerText = `LISTED & SOLD BY ${teamMember1Name} & ${teamMember2Name}`;
          }
        } else {
          if (!isMemberOfTeam && isListingAgent && !isSellingAgent) {
            bannerText = `${listgenUserFirstName.toUpperCase()}${endsWithS(
              listgenUserFirstName,
              true
            )} LISTING`;
          } else if (isMemberOfTeam && isListingAgent && !isSellingAgent) {
            bannerText = `${teamComboWithS} LISTING`;
            // bannerText = `${teamMember1Name} & ${teamMember2Name}${endsWithS(
            //   teamMember2Name,
            //   true
            // )} LISTING`;
          } else if (!isMemberOfTeam && !isListingAgent && isSellingAgent) {
            bannerText = `${listgenUserFirstName.toUpperCase()} IS REPRESENTING THE BUYER`;
          } else if (isMemberOfTeam && !isListingAgent && isSellingAgent) {
            bannerText = `${teamMember1Name} & ${teamMember2Name} ${
              isScar ? "IS" : "ARE"
            } REPRESENTING THE BUYER`;
            // bannerText = `${teamMember1Name} & ${teamMember2Name} ARE REPRESENTING THE BUYER`;
          }
        }

        return (
          <div
            key={`${list.address} ${list.listPrice} ${list.saleDate} ${i}`}
            className="list-map-item"
          >
            <div>
              <div className="list-img-container">
                {bannerText && (
                  <p
                    className={`banner-text-bg ${
                      listgenUserID === "100528" && "douglas-banner"
                    }`}
                  >
                    {bannerText}
                  </p>
                )}
                <div className="list-photo-and-banner-container">
                  <img src={list.photoURL} alt="Home" className="listing-img" />
                  <div className="list-row status-address-row">
                    <div className="status-address-row-inner">
                      <p className="list-address sold-address">
                        {`${emptyValueCheck(
                          list.streetNumber
                        )}   ${emptyValueCheck(list.streetName)} ${
                          list.streetSuffix &&
                          formatSuffix(list.streetSuffix, mlsAreaID)
                        }`}
                        {(!isCountryClubGate &&
                          (list.propertyType === "Condominium") &
                            (list.unitNumber !== "-")) ||
                        (list.propertyType === "Condo/Co-Op" &&
                          list.unitNumber !== "-") ||
                        (!isCountryClubGate &&
                          list.propertyType === "Townhouse" &&
                          list.unitNumber !== "-")
                          ? `, #${list.unitNumber}`
                          : ""}
                      </p>
                      <div className="status-and-sales-price">
                        <p
                          className={`list-${list.status.toLowerCase()} status`}
                        >
                          {statusContingentToPending(list.status)}
                        </p>
                        {list.status !== "Active" && list.salePrice !== "-" && (
                          <p className="list-sale-price">
                            {" "}
                            ${Math.round(list.salePrice).toLocaleString()}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="active-listing-text-container three-active-cols">
                {list.status === "Sold" ? (
                  <div className="list-row">
                    {list.listPrice !== "-" ? (
                      <div className="list-col">
                        <p>List Price</p>
                        <p> ${Math.round(list.listPrice).toLocaleString()}</p>
                      </div>
                    ) : (
                      <p></p>
                    )}

                    <div className="list-col">
                      <p>Sale Date</p>
                      {getMonthAndYearString(list.saleDate)}
                    </div>
                    <div className="list-col">
                      <p>Beds / Baths</p>
                      <p>
                        {formatBaths(list.bedBaths, true).bed} /{" "}
                        {formatBaths(list.bedBaths, true).bath}
                      </p>
                    </div>
                  </div>
                ) : list.propertyType === "Residential Lot" ? (
                  <div className="list-row">
                    {list.listPrice !== "-" ? (
                      <div className="list-col">
                        <p>List Price</p>
                        <p> ${Math.round(list.listPrice).toLocaleString()}</p>
                      </div>
                    ) : (
                      <p></p>
                    )}
                    <div className="list-col">Vacant Lot</div>
                  </div>
                ) : (mlsAreaID === "SBA-35H" || mlsAreaID === "SBA-35F") &&
                  list.streetName === "La Paloma" &&
                  list.streetNumber === "4488" ? (
                  <div className="list-row">
                    {list.listPrice !== "-" ? (
                      <div className="list-col">
                        <p>List Price</p>
                        <p> ${Math.round(list.listPrice).toLocaleString()}</p>
                      </div>
                    ) : (
                      <p></p>
                    )}
                    <div className="list-col">
                      {String(list.guestHouseBathsAndBeds)
                        .split(" ")
                        .slice(0, 3)
                        .join(" ")}
                    </div>
                    <div className="list-col smaller-guest-text">
                      {String(list.guestHouseBathsAndBeds)
                        .split(" ")
                        .slice(3)
                        .join(" ")}
                    </div>
                  </div>
                ) : (
                  <div className="list-row">
                    {list.listPrice !== "-" ? (
                      <div className="list-col">
                        <p>List Price</p>
                        <p> ${Math.round(list.listPrice).toLocaleString()}</p>
                      </div>
                    ) : (
                      <p></p>
                    )}
                    <div className="list-col">
                      <p>Beds</p>
                      <p> {formatBaths(list.bedBaths, true).bed}</p>
                    </div>
                    <div className="list-col">
                      <p>Baths</p>
                      <p> {formatBaths(list.bedBaths, true).bath}</p>
                    </div>
                  </div>
                )}
                <div className="list-row">
                  {list.daysOnMarket !== null ? (
                    <div
                      className={
                        "list-col " +
                        (shouldHighlightDOM ? "highlight-dom" : "")
                      }
                    >
                      <p>Days on Market </p>
                      <p>{Math.round(list.daysOnMarket).toLocaleString()}</p>
                    </div>
                  ) : (
                    <p></p>
                  )}

                  <div className="list-col">
                    <p>Living SqFt</p>
                    <p>{Math.round(list.squareFeet).toLocaleString()}</p>
                  </div>
                  <div className="list-col">
                    <p>Lot</p>
                    <p>{toAcre(list.lotSizeSquareFt, false, false, true)}</p>
                  </div>
                </div>
                <div
                  className={
                    "full-row presented " +
                    (isListingAgent ? "highlight-presented" : "small-gray")
                  }
                >
                  {shortenPresentedBy(
                    list.listingAgentName,
                    list.brokerageName,
                    printVersion,
                    "Presented by "
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      });

  let remainingMapped = 0;
  let remainingList = [];

  if (mappedListings && mappedListings.length < 6) {
    remainingMapped = 6 - mappedListings.length;
    remainingList = [...Array(remainingMapped).keys()].map((listing, i) => {
      return (
        <div
          className="select-another-property-container list-map-item"
          key={i}
          onClick={() => openPhotoModal()}
        >
          <div>
            <p>You Must Select</p>
            <p>Another Property</p>
          </div>
        </div>
      );
    });
  }

  let selectedProperties =
    recentActivityPhotoList &&
    recentActivityPhotoList.length >= 1 &&
    recentActivityPhotoList.filter((prop) => prop.isSystemSelected == true);
  console.log(
    "inside test photo 2 recentActivityPhotoList",
    recentActivityPhotoList
  );
  let place;
  let placeCount = mlsNeighborhood.length;
  if (mlsAreaID === "SBA-20FInv" || mlsAreaID === "SBA-20FRes") {
    place = "Downtown Santa Barbara";
  } else if (demoAccountAreaName && demoAccountAreaName.length > 1) {
    place = demoAccountAreaName;
  } else if (
    coverPageHeader &&
    coverPageHeader === "Upper East and Lower Mission Canyon"
  ) {
    place = "Upper East + Lower Mission Canyon";
  } else if (mlsNeighborhood === "Winchester Canyon") {
    place = "Rancho Embarcadero & Winchester";
  } else if (mlsNeighborhood === "Ennisbrook Birnam Wood") {
    place = "Ennisbrook / Birnam Wood";
  } else if (subdivisionName) {
    place = subdivisionName;
  } else if (placeCount > 30) {
    place = "Your Neighborhood";
  } else {
    place = splitAreas(mlsNeighborhood).join(" + ");
  }

  return (
    <Fragment>
      {openViewImageDialog ? (
        <MediaViewComponent
          mediaURL={uploadCurrentImage}
          mediaTitle={"Property Highlight"}
          onOpen={() => {}}
          onClose={closeViewMedia}
        />
      ) : (
        ""
      )}
      {openUploadImageDialog ? (
        <ImageUploadDialog
          openDialog={true}
          onSubmit={handlePropertyHighlightUpload}
          onClose={handlePropertyHighlightUpload}
          isMyAccountUpload={isAValidSessionSwitch}
          imageType={"propertyHighlight"}
          listgenUserID={userData.listgenUserID} //not required  because designation Logo doesnt required any image upload
          currentImage={uploadCurrentImage}
          teamMode={false}
        />
      ) : (
        ""
      )}

      <DashboardLayout title="" isCompleteInvisible={true}>
        {showErrorModal ? (
          <PhotoErrorModal
            errorModalText={errorModalText}
            handleErrorModal={handleErrorModal}
            keepOrRemoveProperty={keepOrRemoveProperty}
            handleFormClose={handleFormClose}
          />
        ) : (
          ""
        )}
        {showAddPropDialog ? (
          <AddProperty
            onCloseFn={handleFormClose}
            openUploadMedia={openUploadMedia}
            handleInputChange={handleInputChange}
            editPropFormData={editPropFormData}
            handleStatusOptionsFunc={handleStatusOptions}
            handleRepresentedOptionsFunc={handleRepresentedOptions}
            listgenUserID={userData.listgenUserID}
            handleAddProperty={handleAddProperty}
            userData={userData}
            agentData={agentData}
            radioSaleChangeFunc={radioSaleChange}
          />
        ) : (
          ""
        )}
        {showEditDialog ? (
          <EditProperty
            onCloseFn={handleFormClose}
            openUploadMedia={openUploadMedia}
            handleInputChange={handleInputChange}
            editPropFormData={editPropFormData}
            handleStatusOptionsFunc={handleStatusOptions}
            handleRepresentedOptionsFunc={handleRepresentedOptions}
            listgenUserID={userData.listgenUserID}
            onEditFormSubmit={onEditFormSubmit}
            userData={userData}
            oldPropData={oldPropData}
            areaID={props.currentArea.mlsAreaID}
            handleOpenEditDialog={handleOpenEditDialog}
            agentData={agentData}
          />
        ) : (
          ""
        )}
        <div>
          <div
            className={classNames(
              `photo2-modal `,
              `photo-modal ${!props.photoCheckboxModalOpen && "closed"}`
            )}
          >
            <div className="photo-modal-body">
              <div className="photo-modal-title-section">
                <div className="photo-modal-top-title-row">
                  <div className="photo-modal-title">
                    <div className="photo-modal-title-text">
                      Edit Property Photos
                    </div>
                  </div>
                  <div
                    className="close-photo-modal-button"
                    onClick={() => beginClosePhotoModal()}
                  >
                    <p>Cancel</p>
                  </div>
                </div>

                <div className="photo-modal-top-divider"></div>
                <div className="photo-modal-bottom-title-row">
                  <div className="photo-modal-bottom-title-area">
                    {mlsNeighborhood}
                  </div>
                  <div className="photo-modal-bottom-title-date">
                    As of XXX - March 13, 2023
                  </div>
                </div>
                <div
                  className="photo-add-property-row"
                  onClick={() => handleAddPropDialog(true)}
                >
                  <div className="add-property-button">
                    <div>+</div>
                  </div>
                  <div className="add-property-text">
                    Click here to add a property that was sold off-market or is
                    not shown here.
                  </div>
                </div>

                <div className="photo-modal-information-banner">
                  <div className="photo-modal-information-banner-text">
                    <p>
                      Click the checkbox of any 6 properties that you would like
                      to display on Page 3. You can drag and drop the properties
                      in any order you like.
                    </p>
                  </div>
                </div>
              </div>
              <div className="portlet-content photo2-modal-row">
                {/*<div
                style={{ fontSize: "50px" }}
                onClick={() => resetDisplayOrder()}
              >
                Reset displayOrder
              </div>*/}
                {/*<div className="photo2-modal-col">
                  <div className="photo2-modal-mapped-listings">
                    {mappedListings}
                    {remainingList &&
                      remainingList.length >= 1 &&
                      remainingList}
                  </div>
                </div>*/}
                <div className="photo2-modal-col">
                  {isLoading ? (
                    <div className="photo-checkbox-table-container-empty-container">
                      <p>Loading Properties ....</p>
                    </div>
                  ) : (
                    <div className="photo-checkbox-table-container">
                      {/*<div className="unique-btn" onClick={() => getUniqueProps()}>
                  <p>UNIQUE</p>
                </div>*/}

                      <div className="photo-checkbox-table-container photo-sales-table">
                        {/*{photoDetails &&
                          photoDetails.length === 1 &&
                          photoDetails[0].isSystemSelected === true && (
                            <DragDropContext
                              onDragEnd={handleDragEnd}
                              className="drag-drop-context"
                            >
                              <Droppable
                                droppableId="lg-property-highlight"
                                className="droppable-prop"
                              >
                                {(provided, snapshot) => (
                                  <div
                                    className="draggable-div"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getPHListStyle(
                                      snapshot.isDraggingOver
                                    )}
                                  >
                                    <Draggable
                                      className="draggable-container"
                                      draggableId={`${photoDetails[0].streetNumber}-${photoDetails[0].streetName}-${photoDetails[0].unitNumber}`}
                                      index={photoDetails[0].displayOrder}
                                      key={`${photoDetails[0].streetNumber}-${photoDetails[0].streetName}-${photoDetails[0].unitNumber}`}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="highlight-row-container"
                                          key={`${photoDetails[0].streetNumber}-${photoDetails[0].streetName}-${photoDetails[0].unitNumber}`}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <SingleListingRow
                                            properties={selectedProperties}
                                            oldPropData={oldPropData}
                                            photoDetails={photoDetails.filter(
                                              (listing) =>
                                                listing.isSystemSelected
                                            )}
                                            solds={solds}
                                            mlsAreaID={mlsAreaID}
                                            older={older}
                                            isSingleTable={isSingleTable}
                                            handlePropCheck={handlePropCheck}
                                            agentData={agentData}
                                            handleOpenEditDialog={
                                              handleOpenEditDialog
                                            }
                                            fromPhotoTable={true}
                                            isTopSix={true}
                                            prop={photoDetails[0]}
                                            index={photoDetails[0].displayOrder}
                                            areaID={mlsAreaID}
                                            handleSelect={handleSelect}
                                          />
                                        </div>
                                      )}
                                    </Draggable>

                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          )}*/}
                        <div className="table-header-tr">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div>Status</div>
                          <div>Sale Date</div>
                          <div>Address</div>
                          <div>List Price</div>
                          <div>Sale Price</div>
                        </div>
                        {recentActivityPhotoList &&
                          recentActivityPhotoList.length > 1 && (
                            <DragDropContext
                              onDragEnd={handleDragEnd}
                              className="drag-drop-context"
                            >
                              <Droppable
                                droppableId="lg-property-highlight"
                                className="droppable-prop"
                              >
                                {(provided, snapshot) => (
                                  <div
                                    className="draggable-div"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getPHListStyle(
                                      snapshot.isDraggingOver
                                    )}
                                  >
                                    {recentActivityPhotoList
                                      .filter(
                                        (property) =>
                                          property.isSystemSelected === true
                                      )
                                      .map((prop, index) => {
                                        // console.log("selected is", prop);
                                        return (
                                          <Draggable
                                            className="draggable-container"
                                            // key={prop.displayOrder}
                                            draggableId={`${prop.streetNumber}-${prop.streetName}-${index}-${prop.unitNumber}-${prop.displayOrder}`}
                                            // draggableId={`lg-ag-${prop.displayOrder}`}
                                            // draggableId={index.toString()}
                                            index={index}
                                            key={`${prop.streetNumber}-${prop.streetName}-${prop.unitNumber}-${index}=${prop.displayOrder}`}
                                          >
                                            {(provided, snapshot) => (
                                              <div
                                                className="highlight-row-container"
                                                key={`${prop.streetNumber}-${prop.streetName}-${prop.unitNumber}-${index}-${prop.displayOrder}`}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <SingleListingRow
                                                  // properties={selectedProperties}
                                                  property={[
                                                    recentActivityPhotoList[
                                                      index
                                                    ],
                                                  ]}
                                                  oldPropData={oldPropData}
                                                  photoDetails={recentActivityPhotoList.filter(
                                                    (listing) =>
                                                      listing.isSystemSelected ===
                                                      true
                                                  )}
                                                  solds={solds}
                                                  mlsAreaID={mlsAreaID}
                                                  older={older}
                                                  isSingleTable={isSingleTable}
                                                  handlePropCheck={
                                                    handlePropCheck
                                                  }
                                                  agentData={agentData}
                                                  handleOpenEditDialog={
                                                    handleOpenEditDialog
                                                  }
                                                  fromPhotoTable={true}
                                                  isTopSix={true}
                                                  prop={[0]}
                                                  index={index}
                                                  areaID={mlsAreaID}
                                                  handleSelect={handleSelect}
                                                />
                                                {/*<HighlightRow
                                            prop={prop}
                                            index={index}
                                            areaID={areaID}
                                            handleSelect={handleSelect}
                                            openEditDialogFn={
                                              handleOpenEditDialog
                                            }
                                          />*/}
                                              </div>
                                            )}
                                          </Draggable>
                                        );
                                      })}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          )}

                        <div className="table-header-tr">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div>Status</div>
                          <div>Sale Date</div>
                          <div>Address</div>
                          <div>List Price</div>
                          <div>Sale Price</div>
                        </div>
                        <div
                          className={`photo-checkbox-extra-outer-container adjusted-table-${selectedProps.length}`}
                        >
                          <div className="photo-checkbox-outer-container">
                            {recentActivityPhotoList &&
                              recentActivityPhotoList.length > 1 && (
                                <div className="photo-checkbox-table-container unselected-table">
                                  {unselectedProps.map((prop, index) => {
                                    return (
                                      <div
                                        className="drag-drop-context"
                                        key={index}
                                      >
                                        <div className="droppable-prop">
                                          <div className="draggable-div">
                                            <div className="draggable-container">
                                              <div className="highlight-row-container">
                                                <SingleListingRow
                                                  properties={
                                                    selectedProperties
                                                  }
                                                  property={[
                                                    unselectedProps[index],
                                                  ]}
                                                  oldPropData={oldPropData}
                                                  photoDetails={
                                                    recentActivityPhotoList[
                                                      index
                                                    ]
                                                  }
                                                  // photoDetails={recentActivityPhotoList.filter(
                                                  //   (listing) => listing.isSystemSelected
                                                  // )}
                                                  propListMap={
                                                    selectedProperties
                                                  }
                                                  mlsAreaID={mlsAreaID}
                                                  handlePropCheck={
                                                    handlePropCheck
                                                  }
                                                  agentData={agentData}
                                                  handleOpenEditDialog={
                                                    handleOpenEditDialog
                                                  }
                                                  fromPhotoTable={true}
                                                  isTopSix={true}
                                                  singleProperty={
                                                    recentActivityPhotoList[
                                                      index
                                                    ]
                                                  }
                                                  // prop={recentActivityPhotoList[0]}
                                                  index={index}
                                                  areaID={mlsAreaID}
                                                  handleSelect={handleSelect}
                                                />
                                                {/*<HighlightRow
                                      prop={prop}
                                      index={index}
                                      areaID={areaID}
                                      handleSelect={handleSelect}
                                      openEditDialogFn={handleOpenEditDialog}
                                      showButton={showButton}
                                    />*/}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}

                            {recentActivityPhotoList &&
                              recentActivityPhotoList.length === 1 &&
                              recentActivityPhotoList[0].isSystemSelected ===
                                false && (
                                <div className="photo-checkbox-table-container unselected-table">
                                  <div
                                    className="drag-drop-context"
                                    key={`${recentActivityPhotoList[0].streetName}-${recentActivityPhotoList[0].unitNumber}`}
                                  >
                                    <div className="droppable-prop">
                                      <div className="draggable-div">
                                        <div className="draggable-container">
                                          <div className="highlight-row-container">
                                            {/*<HighlightRow
                                      prop={recentActivityPhotoList[0]}
                                      index={0}
                                      areaID={areaID}
                                      handleSelect={handleSelect}
                                      openEditDialogFn={handleOpenEditDialog}
                                    />*/}
                                            <SingleListingRow
                                              properties={dateSort(
                                                soldProperties
                                              )}
                                              solds={solds}
                                              mlsAreaID={mlsAreaID}
                                              older={older}
                                              isSingleTable={isSingleTable}
                                              handlePropCheck={handlePropCheck}
                                              agentData={agentData}
                                              handleOpenEditDialog={
                                                handleOpenEditDialog
                                              }
                                              fromPhotoTable={true}
                                              oldPropData={oldPropData}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="photo-checkbox-footer-container">
                        {isBtnLoading ? (
                          <div>
                            <CircularProgress />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/*<div
            className="qv4-photo-section-container"
            onClick={() => openPhotoModal()}
          >
            {!props.photoCheckboxModalOpen && (
              <div>
                <div className="edit-properties-button">
                  <p>Edit these Properties</p>
                </div>
              </div>
            )}
            <div className="qv4-photo-section-title">
              <p>Recent Activity in</p>
              <p className="photo-section-title-neighborhood">{place}</p>
            </div>
            <div className="qv4-mapped-listings">{mappedListings}</div>
          </div>*/}
        </div>
      </DashboardLayout>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    photoCheckboxModalOpen: state.pma.photoCheckboxModalOpen,
    oldRecentActivityPhotoList: state.pma.oldRecentActivityPhotoList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCheckboxPhotoModalOpen: (isModalOpen) => {
      dispatch(pmaActions.updateCheckboxPhotoModalOpen(isModalOpen));
    },
    updateRecentActivityPhotos: (recentActivityData) => {
      dispatch(pmaActions.updateRecentActivityPhotos(recentActivityData));
    },
  };
};
//export default connect(mapStateToProps, mapDispatchToProps)(PropertyHighlights);
let enhance = compose(
  withSnackbar,
  withStyles(
    (theme) => ({
      ...styles(theme),
      ...styles2(theme),
    }),
    {
      withTheme: true,
    }
  ),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);
export default enhance(TestPhoto2);
