export default theme => ({
  root: {},
  field: {
    //padding: theme.spacing(3)
    margin: theme.spacing(3),
  },
  textField: {
    width: '100%',
    maxWidth: '100%',
    //marginRight: theme.spacing(3)
  },
  dropdownTopMargin: {
    marginTop: -theme.spacing(5),
  },
  dropdownTopMargin2Unit: {
    marginTop: -theme.spacing(2),
  },
  dropdownTopMargin1UnitPlus: {
    marginTop: theme.spacing(1),
  },
  fieldError: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  stripeBody: {
    marginTop: -theme.spacing(2),
    marginBottom: theme.spacing(3),
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paymentIcon: {
    width: '4em',
  },
  checkboxField: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center'
  },
  paymentIconHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerDivider: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1)
  },
  paymentFooter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  poweredByStripe: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: -theme.spacing(4)
  },
  stripeLogoImageWrapper: {
    maxWidth: '120px',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    height: 'auto',
    objectFit: 'cover'
  },
  poweredByStripeImage: {
    width: '100%'
  },
  bottomText: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column'
  },
  dialogContent: {
    marginTop: theme.spacing(0.5)
  },
  paymentSecureBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  portletFooter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',

  }
});
