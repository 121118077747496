import React, { Component } from "react";
import YETwoReviewBoxV3 from "./YETwoReviewBoxV3";
import { propertyUtil } from "views/Pma/PropertyUtil";

class YETwoYearInReview2024 extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./printv3.scss");
    } else {
      require("./editorv3.scss");
    }
  }
  formatPreviousDate = (date, useYearOverYear) => {
    if (useYearOverYear) {
      return date;
    }
    let splitDate = date.slice(0, -6);

    return splitDate;
  };
  formatCityTitle = (cityName) => {
    const { capitalizeWords } = propertyUtil;
    if (cityName && cityName.length >= 24) {
      return "Your City";
    } else {
      return capitalizeWords(cityName);
    }
  };
  formatAreaTitle = (areaName, subdivisionName, coverPageHeader) => {
    const { mlsAreaID } = this.props;
    if (coverPageHeader) {
      return coverPageHeader;
    } else if (subdivisionName === "Serra Village/Creekside") {
      return "Your Neighborhood";
    } else if (areaName === "Santa Ynez Valley Ranches") {
      return "SYV Ranches";
    } else if (areaName === "Coastal Ranch") {
      return "Coastal Ranches";
    } else if (subdivisionName) {
      return subdivisionName;
    } else if (areaName && areaName.length >= 24) {
      return "Your Neighborhood";
    } else {
      return areaName;
    }
  };
  divideAreas = (areas) => {
    const { currentArea, hoData } = this.props;
    const { subdivisionName, coverPageHeader } = this.props.hoData;
    const { mlsNeighborhood, mlsCityName } = currentArea;
    const { splitAreas } = propertyUtil;
    let areaSplit = splitAreas(areas);
    let len = areaSplit.length;

    if (mlsNeighborhood == "Santa Barbara Downtown Investment Property") {
      return (
        <div className="y2-year-in-review-area-name-v3">
          <p>Santa Barbara</p>
          <p>Downtown Investment Property</p>
        </div>
      );
    } else if (subdivisionName && subdivisionName.length > 1) {
      return (
        <div className="y2-year-in-review-area-name-v3">
          <p>{subdivisionName}</p>
        </div>
      );
    } else if (len === 3) {
      return (
        <div className="y2-year-in-review-area-name-v3">
          <p>
            {areaSplit[0]} + {areaSplit[1]} +
          </p>
          <p>{areaSplit[2]}</p>
        </div>
      );
    } else {
      return (
        <div className="y2-year-in-review-area-name-v3">
          <p>{areaSplit.join(" + ")}</p>
        </div>
      );
    }
  };

  posOrNeg = (data) => {
    if (data > 0) {
      return `+${Math.abs(data)}`;
    } else if (data === 0) {
      return data;
    } else if (data < 0) {
      return `-${Math.abs(data)}`;
    }
  };

  render() {
    const {
      currentArea,
      aprilData,
      pmaQuarterlyCombinedCityDTO,
      agentData,
      hoData,
      printVersion,
    } = this.props;

    const { listgenUserID } = agentData;
    const {
      posOrNeg,
      formatCityTitle,
      formatAreaTitle,
      formatPreviousDate,
    } = this;
    const { subdivisionName, coverPageHeader } = hoData;
    const { mlsNeighborhood, mlsCityName, mlsAreaID } = currentArea;
    const { roundSalePrice, calculateDiff, splitAreas } = propertyUtil;
    const { pmaQuarterlyCityDTO, pmaQuarterlyAreaDTO } = aprilData;
    let isJacquieAdams = listgenUserID === "103563";

    const { divideAreas } = this;
    let city2020;
    let city2021;
    let useYearOverYear = false;
    let comparisonTitle = "LAST 6 MONTHS VS PREVIOUS 6 MONTHS";
    if (isJacquieAdams) {
      useYearOverYear = true;
      comparisonTitle = "LAST 12 MONTHS VS PREVIOUS 12 MONTHS";
    }
    if(listgenUserID === "106550" || listgenUserID === "105246"){
      comparisonTitle = null;
    }
    if (useYearOverYear) {
      city2020 = pmaQuarterlyCityDTO.marketTrends.find(
        (x) => x.TrendName === "Past 1-Year Trending"
      );
      city2021 = pmaQuarterlyCityDTO.marketTrends.find(
        (x) => x.TrendName === "1-Year Trending"
      );
      useYearOverYear = true;
    } else {
      city2020 = pmaQuarterlyCityDTO.marketTrends.find(
        (x) => x.TrendName === "Past 6-Months Trending"
      );
      city2021 = pmaQuarterlyCityDTO.marketTrends.find(
        (x) => x.TrendName === "6-Months Trending"
      );
    }

    let combinedArea2020;
    let combinedArea2021;
    let combinedAreaDiff;

    if (
      pmaQuarterlyCombinedCityDTO &&
      pmaQuarterlyCombinedCityDTO.marketTrends
    ) {
      combinedArea2020 = pmaQuarterlyCombinedCityDTO.marketTrends.find(
        (x) => x.TrendName === "Past 6-Months Trending"
      );
      combinedArea2021 = pmaQuarterlyCombinedCityDTO.marketTrends.find(
        (x) => x.TrendName === "6-Months Trending"
      );
      combinedAreaDiff = calculateDiff(
        combinedArea2021.HomesSold,
        combinedArea2020.HomesSold
      );
    }

    let area2020;
    let area2021;
    let startDate;
    if (useYearOverYear) {
      area2020 = pmaQuarterlyAreaDTO.marketTrends.find(
        (x) => x.TrendName === "Past 1-Year Trending"
      );
      area2021 = pmaQuarterlyAreaDTO.marketTrends.find(
        (x) => x.TrendName === "1-Year Trending"
      );
      startDate = area2020.TrendsStartDate;
    } else {
      area2020 = pmaQuarterlyAreaDTO.marketTrends.find(
        (x) => x.TrendName === "Past 6-Months Trending"
      );
      area2021 = pmaQuarterlyAreaDTO.marketTrends.find(
        (x) => x.TrendName === "6-Months Trending"
      );
    }

    let cityDiff = calculateDiff(city2021.HomesSold, city2020.HomesSold);

    let areaDiff = calculateDiff(area2021.HomesSold, area2020.HomesSold);

    let isBuellton = mlsAreaID === "SBA-40F" || mlsAreaID === "SBA-40G";
    let citySubtitle = false;
    if (
      mlsAreaID === "SBA-20FCondo" ||
      mlsAreaID === "SBA-20FRes" ||
      mlsAreaID === "SBA-20FInv"
    ) {
      citySubtitle = "(This Includes Single Family Homes and Condos)";
    }
    let show1Area;

    if (mlsAreaID === "x150") {
      show1Area = "show1City";
    }
    // if (
    //   mlsAreaID === "SBA-45H" ||
    //   mlsAreaID === "SBA-45I" ||
    //   mlsAreaID === "46C" ||
    //   mlsAreaID === "108" ||
    //   mlsAreaID === "162" ||
    //   mlsAreaID === "1522155" ||
    //   mlsAreaID === "SCL-10K"
    // ) {
    //   show1Area = true;
    // }
    // console.log(
    //   "city2021.AvgPercentOfReceiving",
    //   city2021.AvgPercentOfReceiving
    // );

    let page2TitleForNonShow1City =  <p className="y2-comparing-description-title"><span className="large-c">C</span>omparing</p>
    if(listgenUserID === "102843"){
      page2TitleForNonShow1City = <p className="y2-comparing-description-title"><span className="large-c">L</span>ocal&nbsp;<span className="large-c">D</span>ata</p>
    }

    if (show1Area == "show1City") {
      return (
        <div
          className={
            printVersion
              ? "print-y2-year-in-review-v3"
              : "editor-y2-year-in-review-v3"
          }
        >
          <div className="y2-year-in-review-bg-v3"></div>
          <div className="y2-year-in-review-inner-container-v3">
            <div className="y2-year-in-review-header-v3">
              <div className="y2-year-in-review-title-container-v3">
                <div className="y2-comparing-description-container-v3">
                  <div className="y2-comparing-description-title-row">
                    <p className="y2-comparing-description-title">
                      <span className="large-c">C</span>omparing
                    </p>
                  </div>
                  {listgenUserID === "102843" ? (
                    <div className="y2-comparing-description-text-col">
                      <div className="y2-comparing-description-text">
                        How {mlsNeighborhood} compares
                      </div>
                      <div className="y2-comparing-description-text">
                        to the rest of {formatCityTitle(mlsCityName)}
                      </div>
                    </div>
                  ) : (
                    <div className="y2-comparing-description-text-col">
                      <div className="y2-comparing-description-text"></div>
                      <div className="y2-comparing-description-text"></div>
                    </div>
                  )}
                </div>

                <div className="y2-year-in-review-title-row-v3">
                  <div className="review-title-year-v3">Your City</div>
                </div>
                <div className="y2-year-in-review-subtitle-v3">
                  <div className="y2-year-in-review-subtitle-text-v3">
                    {comparisonTitle}
                  </div>
                </div>
              </div>
            </div>
            {citySubtitle ? (
              <div className="y2-year-in-review-names-row-v3 special-review-mt-negative">
                <div className="y2-review-names-section special-section-1">
                  <div className="y2-year-in-review-special-city-name">
                    {formatCityTitle(mlsCityName)}
                  </div>
                  {citySubtitle && (
                    <div className="y2-year-in-review-area-name-subtitle-v3">
                      {citySubtitle}
                    </div>
                  )}{" "}
                </div>
              </div>
            ) : (
              <div className="y2-year-in-review-names-row-v3">
                <div className="y2-review-names-section">
                  <div className="y2-year-in-review-city-name">
                    {mlsAreaID === "SCL-16J"
                      ? "Your City"
                      : formatCityTitle(mlsCityName)}
                  </div>{" "}
                </div>
              </div>
            )}

            <div className="ye2-year-in-review-body-v3">
              <div className="ye2-year-in-review-col-v3">
                <div className="sideways-previous-6-months-dashboard">
                  {formatPreviousDate(
                    area2020.TrendsStartDate,
                    useYearOverYear
                  )}{" "}
                  - {area2020.TrendsEndDate}
                </div>
                <div className="sideways-past-6-months-dashboard">
                  {formatPreviousDate(
                    area2021.TrendsStartDate,
                    useYearOverYear
                  )}{" "}
                  - {area2021.TrendsEndDate}
                </div>
                <YETwoReviewBoxV3
                  category="Avg Sale Price"
                  data2020={city2020.AvgSalePrice}
                  data2021={city2021.AvgSalePrice}
                  sellersAdv={true}
                  index={1}
                />
                <YETwoReviewBoxV3
                  category="Avg Days on Market"
                  data2020={city2020.AvgDaysOnMarket}
                  data2021={city2021.AvgDaysOnMarket}
                  sellersAdv={true}
                  index={2}
                />
                <YETwoReviewBoxV3
                  category="% Received of Asking"
                  data2020={city2020.AvgPercentOfReceiving}
                  data2021={city2021.AvgPercentOfReceiving}
                  sellersAdv={true}
                  index={3}
                />

                <YETwoReviewBoxV3
                  category="Avg Price per SqFt"
                  data2020={city2020.AvgPricePerSqFt}
                  data2021={city2021.AvgPricePerSqFt}
                  sellersAdv={true}
                  index={4}
                />
                <div className="units-sold-container-v3">
                  <div className="units-sold-inner-container">
                    <div className="units-sold-column">
                      <div className="units-sold-title-v3">Units Sold</div>
                      <div className="units-sold-second-row">
                        <div className="units-sold-data-v3">
                          Prev 6 Months: <strong>{city2020.HomesSold}</strong>
                        </div>
                        <div className="units-sold-data-v3">
                          Last 6 Months: <strong>{city2021.HomesSold}</strong>
                        </div>
                      </div>
                    </div>
                    {posOrNeg(cityDiff) === 0 ? (
                      <div className="units-sold-data-v3 data-percentage-v3 no-change-data-perc-v3">
                        <div>No</div>
                        <div>Change</div>
                      </div>
                    ) : (
                      <div className="units-sold-data-v3 data-percentage-v3">
                        {posOrNeg(cityDiff)}%
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={
            printVersion
              ? "print-y2-year-in-review-v3"
              : "editor-y2-year-in-review-v3"
          }
        >
          <div className="y2-year-in-review-bg-v3">
            {/*<img
              src="https://d2onbxnrte2nim.cloudfront.net/JAN23_YearEnd_Dashboard-ImageBG_SMALL.jpg"
              alt="dashboard background"
            />*/}
          </div>
          <div className="y2-year-in-review-inner-container-v3">
            <div className="y2-year-in-review-header-v3">
              <div className="y2-year-in-review-title-container-v3">
                <div className="y2-comparing-description-container-v3">
                  <div className="y2-comparing-description-title-row">
                   {page2TitleForNonShow1City}
                  </div>
                  {listgenUserID === "102843" ? (
                    <div className="y2-comparing-description-text-col">
                      <div className="y2-comparing-description-text">
                        See How {mlsNeighborhood} compares
                      </div>
                      <div className="y2-comparing-description-text">
                        to the rest of {formatCityTitle(mlsCityName)}
                      </div>
                    </div>
                  ) : (
                    <div className="y2-comparing-description-text-col">
                      <div className="y2-comparing-description-text">
                        Your City to Your {mlsAreaID === 'SMA-390' ? "Sea Colony Neighborhood" : "Neighborhood"} Trends
                      </div>
                      <div className="y2-comparing-description-text">
                        helps to provide a bigger picture of the local market
                      </div>
                    </div>
                  )}
                </div>

                <div className="y2-year-in-review-title-row-v3">
                  <div className="review-title-year-v3">
                    Your City and {mlsAreaID === 'SMA-390' ? "Sea Colony Neighborhood" : "Neighborhood"}
                  </div>
                </div>
                <div className="y2-year-in-review-subtitle-v3">
                  <div className="y2-year-in-review-subtitle-text-v3">
                    {comparisonTitle}
                  </div>
                </div>
              </div>
            </div>

            {citySubtitle ? (
              <div className="y2-year-in-review-names-row-v3 special-review-mt-negative">
                <div className="y2-review-names-section special-section-1">
                  <div className="y2-year-in-review-special-city-name">
                    {formatCityTitle(mlsCityName)}
                  </div>
                  {citySubtitle && (
                    <div className="y2-year-in-review-area-name-subtitle-v3">
                      {citySubtitle}
                    </div>
                  )}{" "}
                </div>
                <div className="y2-review-names-section">
                  <div className="y2-year-in-review-city-name">
                    {formatAreaTitle(
                      mlsNeighborhood,
                      subdivisionName,
                      coverPageHeader
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="y2-year-in-review-names-row-v3">
                <div className="y2-review-names-section">
                  <div className="y2-year-in-review-city-name">
                    {mlsAreaID === "SCL-16J"
                      ? "Your City"
                      : formatCityTitle(mlsCityName)}
                  </div>{" "}
                </div>
                <div className="y2-review-names-section">
                  <div className="y2-year-in-review-city-name">
                    {mlsAreaID === "SCL-16J"
                      ? "Your Neighborhood"
                      : mlsAreaID === "SMA-390" ? "Sea Colony Neighborhood"
                      : formatAreaTitle(
                          mlsNeighborhood,
                          subdivisionName,
                          coverPageHeader
                        )}
                  </div>
                </div>
              </div>
            )}

            <div className="ye2-year-in-review-body-v3">
              <div className="ye2-year-in-review-col-v3">
                <div className="sideways-previous-6-months-dashboard">
                  {formatPreviousDate(
                    area2020.TrendsStartDate,
                    useYearOverYear
                  )}{" "}
                  - {area2020.TrendsEndDate}
                </div>
                <div className="sideways-past-6-months-dashboard">
                  {formatPreviousDate(
                    area2021.TrendsStartDate,
                    useYearOverYear
                  )}{" "}
                  - {area2021.TrendsEndDate}
                </div>
                <YETwoReviewBoxV3
                  category="Avg Sale Price"
                  data2020={city2020.AvgSalePrice}
                  data2021={city2021.AvgSalePrice}
                  sellersAdv={true}
                  index={1}
                />
                <YETwoReviewBoxV3
                  category="Avg Days on Market"
                  data2020={city2020.AvgDaysOnMarket}
                  data2021={city2021.AvgDaysOnMarket}
                  sellersAdv={true}
                  index={2}
                />
                <YETwoReviewBoxV3
                  category="% Received of Asking"
                  data2020={city2020.AvgPercentOfReceiving}
                  data2021={city2021.AvgPercentOfReceiving}
                  sellersAdv={true}
                  index={3}
                />

                <YETwoReviewBoxV3
                  category="Avg Price per SqFt"
                  data2020={city2020.AvgPricePerSqFt}
                  data2021={city2021.AvgPricePerSqFt}
                  sellersAdv={true}
                  index={4}
                />
                <div className="units-sold-container-v3">
                  <div className="units-sold-inner-container">
                    <div className="units-sold-column">
                      <div className="units-sold-title-v3">Units Sold</div>
                      <div className="units-sold-second-row">
                        <div className="units-sold-data-v3">
                          <strong>{city2020.HomesSold}</strong>
                        </div>
                        <div className="units-sold-vs">vs</div>
                        <div className="units-sold-data-v3">
                          <strong>{city2021.HomesSold}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*<div className="units-sold-inner-container">
                    <div className="units-sold-column">
                      <div className="units-sold-title-v3">Units Sold</div>
                      <div className="units-sold-second-row">
                        <div className="units-sold-data-v3">
                          Prev 6 Months: <strong>{city2020.HomesSold}</strong>
                        </div>
                        <div className="units-sold-data-v3">
                          Last 6 Months: <strong>{city2021.HomesSold}</strong>
                        </div>
                      </div>
                    </div>
                    {posOrNeg(cityDiff) === 0 ? (
                      <div className="units-sold-data-v3 data-percentage-v3 no-change-data-perc-v3">
                        <div>No</div>
                        <div>Change</div>
                      </div>
                    ) : (
                      <div className="units-sold-data-v3 data-percentage-v3">
                        {posOrNeg(cityDiff)}%
                      </div>
                    )}
                  </div>*/}
                </div>
              </div>
              <div className="ye2-year-in-review-col-v3">
                <div className="sideways-previous-6-months-dashboard">
                  {formatPreviousDate(
                    area2020.TrendsStartDate,
                    useYearOverYear
                  )}{" "}
                  - {area2020.TrendsEndDate}
                </div>
                <div className="sideways-past-6-months-dashboard">
                  {formatPreviousDate(
                    area2021.TrendsStartDate,
                    useYearOverYear
                  )}{" "}
                  - {area2021.TrendsEndDate}
                </div>
                <YETwoReviewBoxV3
                  category="Avg Sale Price"
                  data2020={area2020.AvgSalePrice}
                  data2021={area2021.AvgSalePrice}
                  sellersAdv={true}
                  index={1}
                />
                <YETwoReviewBoxV3
                  category="Avg Days on Market"
                  data2020={area2020.AvgDaysOnMarket}
                  data2021={area2021.AvgDaysOnMarket}
                  sellersAdv={true}
                  index={2}
                />
                <YETwoReviewBoxV3
                  category="% Received of Asking"
                  data2020={area2020.AvgPercentOfReceiving}
                  data2021={area2021.AvgPercentOfReceiving}
                  sellersAdv={true}
                  index={3}
                />
                <YETwoReviewBoxV3
                  category="Avg Price per SqFt"
                  data2020={area2020.AvgPricePerSqFt}
                  data2021={area2021.AvgPricePerSqFt}
                  sellersAdv={true}
                  index={4}
                />
                <div className="units-sold-container-v3">
                  <div className="units-sold-inner-container">
                    <div className="units-sold-column">
                      <div className="units-sold-title-v3">Units Sold</div>
                      <div className="units-sold-second-row">
                        <div className="units-sold-data-v3">
                          <strong>{area2020.HomesSold}</strong>
                        </div>
                        <div className="units-sold-vs">vs</div>
                        <div className="units-sold-data-v3">
                          <strong>{area2021.HomesSold}</strong>
                        </div>
                      </div>
                    </div>
                    {/*{posOrNeg(areaDiff) === 0 ? (
                      <div className="units-sold-data data-percentage no-change-data-perc-v3">
                        <div>No</div>
                        <div>Change</div>
                      </div>
                    ) : (
                      <div className="units-sold-data-v3 data-percentage-v3">
                        {posOrNeg(areaDiff)}%
                      </div>
                    )}*/}
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default YETwoYearInReview2024;
