import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ButtonGroup, FormControl, InputLabel, OutlinedInput, Select, withStyles } from '@material-ui/core';
import validate from 'validate.js';
import _ from 'lodash';
import {
    Grid,
    TextField,
    Button,
    Typography,
    LinearProgress,
    MenuItem
} from '@material-ui/core';
import { Portlet, PortletContent, PortletFooter } from 'components';
import styles from './styles';
import PropTypes from 'prop-types';
import { SearchAgentByStatusAPI } from 'services/agent';
import { showErrorNotification } from 'common/helpers';
import { withSnackbar } from 'notistack';
import { SearchIntoAgentAPI } from 'services/agent';
import { compose } from 'recompose';
import Base from 'common/constants';
import { GetStateStorage } from 'common/storage';
import { getDefaultStateID } from 'common/helpers';
import { getDefaultCountyID } from 'common/helpers';
import { RemoveCookieItem, SetCookieItem } from 'common/cookies';
import { GetCookieItem } from 'common/cookies';
import { Autocomplete } from '@material-ui/lab';
import { Fragment } from 'react';
import { showInfoNotification } from 'common/helpers';
import { getAllSchedules } from 'views/Schedule/MailingScheduleServices';

class AgentSearchForm extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            values: {
                status: 'active',
                selectedState: '',
                selectedCounty: '',
                filterChoice: 'county',
                selectedSchedule: '',
            },
            stateLabelRef: null,
            countyLabelRef: null,
            disableStateCountyDropdowns: false,
            countyData: Base.COUNTY_ID_NAME_MAP,
            stateData: {
                "1": 'California'
            },

            allAgentData: [],
            scheduleData: []
        };
    };

    componentDidMount() {

        //set the specific state data
        this.setState(prevState => (this.getStateCountyInitialData(prevState)), () => {
            this.getAllSchedulesFn();
            this.applyFilters();
        })
    };

    //we going to need this data on load of component and in reset filter also
    getStateCountyInitialData = (prevState) => {
        //if the role is county admin then set the default state id and county id and disable the state and county dropdowns
        //here no need to check session switch it used in admin side only
        const { userData } = GetStateStorage();
        let stateID = GetCookieItem(Base.CookieStorageBase.LG_DEFAULT_SELECTED_STATE_ID);
        let countyID = GetCookieItem(Base.CookieStorageBase.LG_DEFAULT_SELECTED_COUNTY_ID);
        let scheduleID = GetCookieItem(Base.CookieStorageBase.LG_DEFAULT_SELECTED_SCHEDULE_ID);
        //We use this component in mailpro insight and agent schedule screen. In mailpro insight apis we have support for market but for other screen we dont have so we need to disable market option as per disableFilterChoice. Also, if disableFilterChoice is true and we dont have county cookie set than we will use default ones. But for mailpro insight we dont use default option because in that at a time we can have one option either county or market.
        const finalFilterChoice = this.props.disableFilterChoice ? 'county' : (scheduleID ? 'market' : 'county');
        let selectedState = '', selectedCounty = '';

        selectedState = (stateID ? stateID : this.props.disableFilterChoice  ? getDefaultStateID(userData.listgenRoleID, userData.listgenUserID) : '');
        selectedCounty = (countyID ? countyID : this.props.disableFilterChoice ? getDefaultCountyID(userData.listgenRoleID, userData.listgenUserID) : '');

        return {
            disableStateCountyDropdowns: (parseInt(userData.listgenRoleID) === parseInt(Base.APP_DATA.APP_COUNTY_ADMIN_ROLE_ID)),
            values: {
                ...prevState.values, ...{
                    selectedState: selectedState,
                    selectedCounty: selectedCounty,
                    selectedSchedule: (scheduleID ? scheduleID : ''),
                    filterChoice: finalFilterChoice
                }
            }
        };
    };

    resetFormValues() {
        let initialState = this.getInitialState();
        this.setState(prevState => ({
            ...initialState,
            ...this.getStateCountyInitialData(initialState)
        }), () => {
            this.applyFilters();
        });
    }

    handleFieldChange = (field, value, event) => {
        //in case of lg account it will listgen user row object so in filter users grab the listuser id
        const newState = { ...this.state };
        newState.values[field] = value;

        if (field === 'filterChoice') {
            newState.values.selectedCounty = '';
            newState.values.selectedSchedule = '';
        }

        this.setState(newState, () => {
            if (field === 'selectedCounty' || field === 'selectedSchedule') {
                this.applyFilters();
            }
            if (field === 'filterChoice') {
                RemoveCookieItem(Base.CookieStorageBase.LG_DEFAULT_SELECTED_SCHEDULE_ID);
                RemoveCookieItem(Base.CookieStorageBase.LG_DEFAULT_SELECTED_COUNTY_ID);
                this.callParentCallbackFn([], this.state.values);

            }
        });
    };

    applyFilters = async () => {
        this.filterUsers(this.state.values);
    };

    callParentCallbackFn = (agentData, formData) => {
        const { setFilteredAgentData } = this.props;
        const scheduleDisplayName = this.getScheduleDisplayName(formData.selectedSchedule);
        setFilteredAgentData(agentData, false, this.state.stateData[this.state.values.selectedState] || '', this.state.countyData[this.state.values.selectedCounty] || '', this.state.values.selectedState, this.state.values.selectedCounty, this.state.values.status, this.state.values.filterChoice, this.state.values.selectedSchedule, scheduleDisplayName);
    }

    filterUsers(formData) {
        /**set to loading state */
        const { setFilteredAgentData, startLoading, defaultLoadAgents } = this.props;
        //we dont to load agents from here so call setFilteredAgentData
        if (!defaultLoadAgents) {
            this.callParentCallbackFn([], formData);
            return;
        }
        startLoading();
        SearchAgentByStatusAPI(formData.selectedCounty, formData.status, formData.filterChoice, formData.selectedSchedule).then(data => {
            //resolve(data);
            if (data.length > 0) {
                this.setState((prevState, prevPros) => ({
                    allAgentData: data,
                }));
                this.callParentCallbackFn(data, formData);
            } else {
                showInfoNotification(this.props, "No agents found.");
                setFilteredAgentData([], false);
            }
        })
            .catch(err => {
                //reject(err);
                console.log('error', err);
                showErrorNotification(this.props, "Unable to process request.");
                setFilteredAgentData([], false);
            }).finally(() => {

                if (formData.filterChoice === 'county') {
                    this.setCountyCookie(formData.selectedCounty);
                } else if (formData.filterChoice === 'market') {
                    this.setScheduleCookie(formData.selectedSchedule);
                }

            }
            );
    };

    getScheduleDisplayName = (scheduleID) => {
        if (!scheduleID || this.state.scheduleData.length === 0) {
            return "";
        }
        return this.state.scheduleData.filter(schedule => schedule.pmaScheduleID === scheduleID)[0].displayPMAScheduleID;
    }

    getAllSchedulesFn = () => {
        getAllSchedules().then(data => {
            this.setState(prevState => ({
                ...prevState,
                scheduleData: data
            }));
        }
        ).catch(err => {
            showErrorNotification(this.props, "Unable to get schedules.");
        });
    }

    setCountyCookie = (countyID) => {
        SetCookieItem(Base.CookieStorageBase.LG_DEFAULT_SELECTED_COUNTY_ID, countyID, 2628000, "/", null, false);
    }

    setScheduleCookie = (scheduleID) => {
        SetCookieItem(Base.CookieStorageBase.LG_DEFAULT_SELECTED_SCHEDULE_ID, scheduleID, 2628000, "/", null, false);
    }

    render() {
        const { classes, className, isLoading, statusData, disableFilterChoice } = this.props;
        const rootClassName = classNames(classes.root, className);
        const {
            values,
            stateData,
            countyData,
            allAgentData,
            scheduleData
        } = this.state;

        const { filterChoice } = values;

        return (
            <Portlet className={rootClassName} outlined={false}>

                <PortletContent noPadding>
                    <form
                        autoComplete="off"
                        noValidate
                    >

                        <Grid container className={classes.grid} spacing={0}>
                            <Grid item lg={2}>
                                <Typography variant='subtitle2'>
                                    How do you want to filter Agents?
                                </Typography>
                                <ButtonGroup size="small" aria-label="small outlined choice button" disabled={disableFilterChoice}>
                                    <Button variant='contained' color={filterChoice === 'county' && 'primary'} onClick={event => this.handleFieldChange("filterChoice", "county", event)}>By County</Button>
                                    <Button variant='contained' color={filterChoice === 'market' && 'primary'} onClick={event => this.handleFieldChange("filterChoice", "market", event)}>By Market</Button>
                                </ButtonGroup>
                            </Grid>
                            <Grid item lg={3}>
                                <div className={classes.field}>
                                    <TextField
                                        className={classNames(classes.textField)}
                                        label="Select State"
                                        margin="dense"
                                        select
                                        SelectProps={{ native: false }}
                                        InputLabelProps={{ shrink: true }}
                                        name="selectedState"
                                        value={this.state.values.selectedState}
                                        onChange={event => {
                                            this.handleFieldChange("selectedState", event.target.value, event);
                                            SetCookieItem(Base.CookieStorageBase.LG_DEFAULT_SELECTED_STATE_ID, event.target.value, 2628000, "/", null, false);
                                        }}
                                        variant="outlined"
                                        disabled={this.state.disableStateCountyDropdowns}
                                    >
                                        {/* <MenuItem key="agent-search-state-form-blank-value" value="">
                                            <em>View All States</em>
                                        </MenuItem> */}
                                        {Object.keys(stateData).map((key) => (
                                            <MenuItem key={key} value={key}>
                                                {stateData[key]}&nbsp;({key})
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Grid>
                            <Grid item lg={3}>
                                <div className={classes.field}>
                                    <FormControl className={classes.textField} variant='outlined' disabled={this.state.disableStateCountyDropdowns || filterChoice === 'market'} margin='dense'>
                                        <InputLabel id="select-county-filter-label">Select County</InputLabel>
                                        <Select
                                            labelId="select-county-filter-label"
                                            labelWidth={90}
                                            name="selectedCounty"
                                            value={this.state.values.selectedCounty}
                                            onChange={event => {
                                                this.handleFieldChange("selectedCounty", event.target.value, event);
                                                RemoveCookieItem(Base.CookieStorageBase.LG_DEFAULT_SELECTED_SCHEDULE_ID);
                                            }}
                                            >
                                            {Object.keys(countyData).map((key) => (
                                                <MenuItem key={key} value={key}>
                                                    {countyData[key]}&nbsp;({key})
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>

                            <Grid item lg={3}>
                                <div className={classes.field}>
                                <FormControl className={classes.textField} variant='outlined' disabled={filterChoice === 'county' || disableFilterChoice} margin='dense'>
                                        <InputLabel id="select-market-filter-label">Select Schedule / Market</InputLabel>
                                        <Select
                                            labelId="select-market-filter-label"
                                            labelWidth={140}
                                            name="selectedSchedule"
                                            value={this.state.values.selectedSchedule}
                                            onChange={event => {
                                                this.handleFieldChange("selectedSchedule", event.target.value, event);
                                                RemoveCookieItem(Base.CookieStorageBase.LG_DEFAULT_SELECTED_COUNTY_ID);
                                            }}
                                            >
                                            {scheduleData.map((schedule) => (
                                            <MenuItem key={schedule.displayPMAScheduleID} value={schedule.pmaScheduleID}>
                                                <div>
                                                    <p style={{ fontWeight: 500 }}>{schedule.displayPMAScheduleID}</p>
                                                    <p style={{ fontSize: '10px' }}>{schedule.pmaScheduleDesc}</p>
                                                </div>
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid>
                        {/* <Grid container className={classes.grid} spacing={0}><Grid item lg={4}>
                            <div className={classes.field}>
                                <TextField
                                    className={classNames(classes.textField)}
                                    label="Agent Status"
                                    helperText="Which status agent you want to search. Select here."
                                    margin="dense"
                                    select
                                    SelectProps={{ native: false }}
                                    name="status"
                                    value={this.state.values.status}
                                    onChange={event =>
                                        this.handleFieldChange("status", event.target.value, event)
                                    }
                                    variant="outlined">
                                    {statusData.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Grid>
                            <Grid item lg={4}>

                            </Grid>
                            <Grid item lg={4}></Grid>
                        </Grid> */}
                    </form>
                </PortletContent>
                <PortletFooter className={classes.portletFooter}>
                    <div className={classes.footerButtons}>
                        <div className={classes.footerButtonsLeftRight}>
                            <Button
                                color="default"
                                variant="contained"
                                onClick={() => this.resetFormValues()}
                            >
                                Clear Search
                            </Button>
                        </div>
                        <div className={classes.footerButtonsCenter}>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={isLoading || (!values.selectedCounty && !values.selectedSchedule)}
                                onClick={event => this.filterUsers(this.state.values)}
                            >
                                Search Agent
                            </Button>
                        </div>
                        <div className={classes.footerButtonsLeftRight}></div>
                    </div>
                </PortletFooter>
            </Portlet>
        );
    }
}

AgentSearchForm.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    setFilteredAgentData: PropTypes.func.isRequired,
    startLoading: PropTypes.func.isRequired,
    defaultLoadAgents: PropTypes.func.isRequired
};

AgentSearchForm.defaultProps = {
    isLoading: false,
    //setFilteredAgentData: () => {},
    startLoading: () => { },
    defaultLoadAgents: true
};

export default compose(
    withSnackbar,
    withStyles(styles)
)(AgentSearchForm);