import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";

class YETwoReviewBoxv3 extends Component {
  posOrNeg = (data) => {
    if (data > 0) {
      return <div className="ye2-review-box-perc-v3">+{Math.abs(data)}%</div>;
    } else if (data === 0) {
      return (
        <div className="ye2-review-box-perc-v3 no-change-v3">
          <p>No Change</p>
        </div>
      );
    } else if (data < 0) {
      return <div className="ye2-review-box-perc-v3">-{Math.abs(data)}%</div>;
    }
  };
  render() {
    const { category, data2020, data2021, index } = this.props;
    const { roundSalePrice, calculateDiff } = propertyUtil;
    let perc;
    let sellersAdv;
    let formatted2020;
    let formatted2021;
    let formattedCategoryName;
    if (category === "Avg Sale Price") {
      formattedCategoryName = "Average Sale Price";
      perc = calculateDiff(data2021, data2020);
      formatted2020 = roundSalePrice(data2020);
      formatted2021 = roundSalePrice(data2021);
      sellersAdv = perc >= 1 ? true : false;
    } else if (category === "Avg Days on Market") {
      formattedCategoryName = "Average Days on Market";
      perc = calculateDiff(data2021, data2020);
      formatted2020 = Math.round(data2020);
      formatted2021 = Math.round(data2021);
      sellersAdv = perc <= 1 ? true : false;
    } else if (category === "% Received of Asking") {
      formattedCategoryName = "% Received of Asking";
      perc = calculateDiff(data2021, data2020);
      formatted2020 = `${Math.round(data2020)}%`;
      formatted2021 = `${Math.round(data2021)}%`;
      sellersAdv = perc >= 1 ? true : false;
    } else if (category === "Avg Price per SqFt") {
      formattedCategoryName = "Average Price per Sqft";
      perc = calculateDiff(data2021, data2020);
      formatted2020 = `$${Math.round(data2020.toLocaleString())}`;
      formatted2021 = `$${Math.round(data2021.toLocaleString())}`;
      sellersAdv = perc >= 1 ? true : false;
    }

    return (
      <div className="ye2-review-box-v3">
        <div className="ye2-review-box-top-v3">
          <div className="ye2-review-box-category-v3">
            {formattedCategoryName}
          </div>
        </div>
        <div className="ye2-review-box-comparison-v3">
          <div className="ye2-inner-data-box-v3">
            <div className="ye2-review-box-data-v3">{formatted2020}</div>
            <div className="ye2-box-divider-v3"></div>
            <div className="ye2-review-box-data-v3">{formatted2021}</div>
          </div>
        </div>
        {this.posOrNeg(perc)}
      </div>
    );
  }
}

export default YETwoReviewBoxv3;
