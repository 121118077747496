import React, { Component } from "react";
import QVTwoFirstLeft from "./QVTwoFirstLeft";
import QVTwoFirstRight from "./QVTwoFirstRight";
import V2DebSamuel from "views/Pma/PmaEditor/CustomAgentPages/V2DebSamuel";
import RGB from "views/Pma/PmaEditor/CustomAgentPages/RGB";

class QVTwoFirstPage extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      mlsAreas,
      navbarData,
      printVersion,
      version,
      viewingGeneratedCL,
      coverPageDetails,
      qArea,
      qCity,
      quarterlyAreaTable,
      aprilData,
      propertyPhotoDetailsDTO,
      bubbleBoxes,
    } = this.props;
    const { listgenUserID } = agentData;
    const { mlsAreaID } = currentArea;

    return (
      <div
        className={
          "pma-page-container " +
          (printVersion
            ? "qv2-print-page-container"
            : "qv2-pma-editor-page-container")
        }
      >
        {(printVersion && mlsAreaID === "SBA-10A") ||
        (printVersion && mlsAreaID === "SBA-10G") ? (
          <RGB
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version="qv4"
            //  demoAccountHOName={demoAccountHOName}
            coverPageDetails={coverPageDetails}
          />
        ) : listgenUserID === "100585" ? (
          <V2DebSamuel
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version="qv2"
            //  demoAccountHOName={demoAccountHOName}
            coverPageDetails={coverPageDetails}
          />
        ) : (
          <QVTwoFirstLeft
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version={version}
            coverPageDetails={coverPageDetails}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            bubbleBoxes={bubbleBoxes}
          />
        )}

        <QVTwoFirstRight
          agentData={agentData}
          hoData={hoData}
          navbarData={navbarData}
          printVersion={printVersion}
          version={version}
          currentArea={currentArea}
          coverPageDetails={coverPageDetails}
          quarterlyAreaTable={quarterlyAreaTable}
          aprilData={aprilData}
        />
      </div>
    );
  }
}

export default QVTwoFirstPage;
