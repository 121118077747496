import React, { Component } from "react";

class SeniorRelocation extends Component {
  render() {
    return (
      <div className="senior-relocation">
        <div className="senior-relocation-title-container">
          <div className="senior-relocation-title-text">
            Are you ready to move, but don't know where to start?
          </div>
        </div>
        <div className="senior-relocation-body">
          <div className="body-title-section">
            <div className="senior-relocation-body-title">
              #1 CONCIERGE SENIOR RELOCATION SERVICE
            </div>
            <div className="senior-relocation-body-subtitle-text">
              Call the SB SOS Team, and let us do all the work.
            </div>
            <div className="senior-relocation-phone-number">
              <div>805</div>
              <div className="phone-dot-separator"></div>
              <div>946</div>
              <div className="phone-dot-separator"></div>
              <div>0060</div>
            </div>
          </div>
          <div className="section-container">
            <div className="section-row">
              <div className="senior-relocation-section">
                <div className="senior-relocation-section-title">
                  Downsizing
                </div>
                <div className="senior-relocation-section-text">
                  <p>We review with you items to keep,</p>
                  <p>gift, sell & donate, then coordinate</p>
                  <p>distribution for you.</p>
                </div>
              </div>
              <div className="senior-relocation-section">
                <div className="senior-relocation-section-title">
                  RELOCATION
                </div>
                <div className="senior-relocation-section-text">
                  <p>We pack your belongings,</p>
                  <p>coordinate logistics, and set up</p>
                  <p>your new home.</p>
                </div>
              </div>
            </div>
            <div className="section-row">
              <div className="senior-relocation-section">
                <div className="senior-relocation-section-title">CLEAN-OUT</div>
                <div className="senior-relocation-section-text">
                  <p>Upon completion of moving, sales,</p>
                  <p>and donations, we will leave your</p>
                  <p>home real estate ready.</p>
                </div>
              </div>
              <div className="senior-relocation-section">
                <div className="senior-relocation-section-title">
                  ESTATE SALES
                </div>
                <div className="senior-relocation-section-text">
                  <p>In addition to our local sales</p>
                  <p>effort, our national online</p>
                  <p>auctions favor us to sell to a</p>
                  <p>broader market.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="senior-relocation-logo-container">
          <img
            //src="https://pma-coverphotos.s3.amazonaws.com/040623_DSamuel_D-S-Logo_500px.png"
            //src="https://d2onbxnrte2nim.cloudfront.net/083122_500px_SB-SOSlogo.png"
            src="https://pma-coverphotos.s3.amazonaws.com/SB_SOS_logo_071922.png"
            alt="SOS Logo"
            // style={{ background: "#bfbdb8", height: "130px" }} //extra yellow color coming in print not PDF so putting background, its not fixed yet, printer issue
          />
        </div>
      </div>
    );
  }
}

export default SeniorRelocation;
