export default (theme) => ({
  root: {
    width: "auto",
    height: "auto",
  },
  field: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
    margin: theme.spacing(3),
  },
  fieldError: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  hideField: {
    display: "none",
  },
  imageHolderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  imageHolder: {
    maxWidth: "200px",
    textAlign: "center",
    marginTop: theme.spacing(2),
    height: "auto",
    objectFit: "cover",
  },
  imageHolderDialog: {
    maxWidth: "200px",
    textAlign: "center",
    marginTop: theme.spacing(2),
    height: "auto",
    objectFit: "cover",
  },
  imageTag: {
    width: "100%",
  },
  fieldSection: {
    width: "100%",
    height: "85px",
  },
  fieldSectionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  fieldSectionError: {
    width: "100%",
  },

  uploadButton: {
    backgroundColor: theme.palette.primary.mediumLight,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  uploadPropHighlightsButton: {
    backgroundColor: theme.palette.button.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
    margin: "auto",
    top: "25vh",
    left: "26vw",
  },
  addMoreButton: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: theme.spacing(2),
    padding: 0,
    marginTop: -theme.spacing(3),
    marginBottom: -theme.spacing(3),
  },
  footerButtons: {
    position: "relative",
    display: "flex",
  },
  portletFooter: {
    display: "inline-block",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: "100%",
  },
  footerButtonsCenter: {
    justifyContent: "center",
    alignItems: "center",
  },
  footerButtonsLeftRight: {
    flex: 1,
  },
  center: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  blueText: {
    color: "blue",
    display: "inline",
  },
  spaceAround: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  alignItemCenter: {
    alignSelf: "center",
  },
  marginTop2Unit: {
    marginTop: theme.spacing(2),
  },
  pdfIcon: {
    width: "100%",
    height: "100%",
  },
});
