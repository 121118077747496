import React, { Component, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { compose } from 'recompose';
import styles from "./styles";
import { Alert, AlertTitle } from '@material-ui/lab';
import querystring from 'querystring';
import { red } from '@material-ui/core/colors';
import DocumentTitle from 'react-document-title';

const Dashboard = (props) => {
  const [uriMessage, setURIMessage] = React.useState(null);
  const holaImage = './images/start.svg';
  const holaImage1 = './images/Crane.gif';
  const holaAlt = 'Hola Logo';
  const { classes } = props;

  useEffect(() => {
    try {
      let parsedParams = querystring.parse(
        props.location.search.substring(1)
      );
      let mode = parsedParams.mode;
      if (mode === '1') {
        setURIMessage('OOPS!!! You dont have access for the requested page. Please try with a valid page from left side list.');
      }

    } catch (err) {
      setURIMessage(null);
    }
  }, []);

  return (
    <DocumentTitle title="Dashboard">
      <DashboardLayout title="Dashboard">
        <div className={classes.root}>
          <Grid container className={classes.grid} spacing={0}>
            <Grid item lg={12}>
              <div className={classes.imageWrapper}>
                <img
                  alt={holaAlt}
                  className={classes.image}
                  src={holaImage}
                />
              </div>

              {uriMessage ?
                <Typography className={classes.sugestion} variant="h6" style={{ color: red[900] }}>
                  {uriMessage}
                </Typography>
                :
                <Typography className={classes.sugestion} variant="body2">
                  Hola! Get started by selecting an option from left side.
              </Typography>}
            </Grid>
          </Grid>
        </div>
      </DashboardLayout>
    </DocumentTitle>
  );
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};
export default compose(
  withRouter,
  withStyles(styles)
)(Dashboard);
