import React, { cloneElement, Fragment } from 'react';
import styles from "./styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";


export const MailProGroupButton = ({children, property, classes}) => {
    const HoHasAnyGroup = true;

    const newChildren = cloneElement(children, {"children": HoHasAnyGroup ? <Fragment>Add <br/>to Another Group</Fragment> : <Fragment>Add <br/>to a Group</Fragment>});

    return newChildren
}

export default compose(withStyles(styles), withSnackbar)(MailProGroupButton);
