import React, { Component } from "react";

class TaxRate extends Component {
  render() {
    const {
      mobilePhoneNo,
      isMemberOfTeam,
      listgenUserID,
    } = this.props.agentData;

    return (
      <div className="tax-rate-container">
        <div className="tax-rate-bg-image-container">
          <img
            src={`https://pma-coverphotos.s3.amazonaws.com/041123_APR-PMA_Back_Cover-Image_60perCt.jpg`}
            className="tax-rate-bg-image"
            alt="Dashboard image"
          />
        </div>
        <div className="tax-rate-inner-container">
          <div className="tax-rate-title-container">
            <div className="tax-rate-title-text">
              Make a move & protect your low tax rate !
            </div>
            <div className="tax-rate-subtitle-text">
              What To Know About California's Bold Prop 19
            </div>
          </div>
          <div className="tax-rate-body-container">
            <div className="tax-rate-body-section">
              <div className="tax-rate-body-section-inner">
                <div className="tax-rate-body-section-title">
                  Buy a New Home & Transfer Your Property Tax Rate
                </div>
                <div className="tax-rate-body-section-text">
                  <strong>Prop 19,</strong> allows any homeowners (ages 55 and
                  up, disabled homeowners, or those impacted by a natural
                  disaster) to sell their primary residence and transfer their
                  property taxes to a newly purchased home anywhere in
                  California. The value of any property purchased can be up to
                  105% of the sale price of the original residence.
                </div>
              </div>
            </div>
            <div className="tax-rate-body-section">
              <div className="tax-rate-body-section-inner">
                <div className="tax-rate-body-section-title">
                  Transfer Your Home to Your Children
                </div>
                <div className="tax-rate-body-section-text">
                  <strong>Another option</strong> Prop 19 allows is for parents
                  to transfer their primary residence to their children while
                  maintaining the original property taxes of their home. In
                  order to qualify, the property being transferred must become
                  the primary residence of the child. To take full advantage of
                  the transfer of the parent’s original taxes, the property
                  cannot have gained more than 1 million dollars based on the
                  current market valuation.
                </div>
              </div>
            </div>
          </div>
          <div className="tax-rate-disclaimer-container">
            <div className="tax-rate-disclaimer-text">
              Here is a great source for more information on Prop 19 by the
              State of California: <u>https://boe.ca.gov/pdf/pub801.pdf</u>
            </div>
          </div>
          {listgenUserID === "100411" ? (
            <div className="alt-tax-rate-footer-container">
              <div className="tax-rate-qr-code">
                <img
                  src="https://pma-coverphotos.s3.amazonaws.com/040323_Garret-MC_QRcode_500px.jpg"
                  alt="Garrett McCaw logo"
                  className="mccaw-qr-logo-and-avatar"
                />
              </div>
              <div className="alt-tax-rate-footer-1">
                <p className="alt-tax-text-bold">
                  Do you know what your home is worth? Scan here to find out
                  with a free market analysis.
                </p>
              </div>
            </div>
          ) : (
            <div className="tax-rate-footer-container">
              <div className="tax-rate-footer-1">
                Please call or text {isMemberOfTeam ? "us" : "me"} to further
                discuss your real estate needs.
              </div>
              {listgenUserID === "100902" ? (
                <div className="tax-rate-footer-2">805-683-7335</div>
              ) : (
                <div className="tax-rate-footer-2">
                  {mobilePhoneNo.slice(0, 3)}-{mobilePhoneNo.slice(3, 6)}-
                  {mobilePhoneNo.slice(6)}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TaxRate;
