export default (theme) => ({
  qv2PmaEditorPage: {
    border: "1px solid green",
  },
  page: {
    border: "1px solid green",
  },
  sandwich: {
    height: "500px",
    backgroundColor: "green",
  },
});
