import React, { Component, Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Input,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from "@material-ui/core";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "./styles";
import { withSnackbar } from "notistack";
import classNames from "classnames";
import ClearIcon from "@material-ui/icons/Clear";
import { showSuccessNotification } from "common/helpers";
import { showErrorNotification } from "common/helpers";
import { AddUpdatePmaAdmin } from "services/agent";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const HighlightFooterDialog = (props) => {
  const {
    representedOptions,
    editPropFormData,
    handleInputChange,
    openUploadMedia,
    onCloseFn,
    handleAddFooter,
    propertyHighlightsFooterText1,
    propertyHighlightsFooterText2,
    handleFooterChange,
    onHighlightFooterClose,
    oldFooter,
    maxHighlightFooter1Length,
    maxHighlightFooter2Length,
  } = props;

  let overMaxFooter1 =
    propertyHighlightsFooterText1 &&
    propertyHighlightsFooterText1.length &&
    propertyHighlightsFooterText1.length > maxHighlightFooter1Length;
  let overMaxFooter2 =
    propertyHighlightsFooterText2 &&
    propertyHighlightsFooterText2.length &&
    propertyHighlightsFooterText2.length > maxHighlightFooter2Length;
  return (
    <Fragment>
      <Dialog
        open={true}
        //onClose={onCloseFn}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth={true}
        TransitionComponent={Transition}
      >
        <div className="edit-property-container">
          <div className="edit-property-title-container">
            <p className="edit-property-title">
              Edit Property Highlight Footer
            </p>
            <div className="edit-property-title-divider" />
            <div className="edit-property-subtitle-text column-text">
              <p>
                You have the option to edit the Footer OR you can leave it as it
                is.
              </p>
              <p className="click-submit-text">
                (click CANCEL if you want to leave it unchanged)
              </p>
            </div>
            {/*<div className="edit-property-subtitle-text column-text">
              <p>
                You have the option to edit the Footer Lines OR you can leave
                them as they are.
              </p>
              <p className="click-submit-text">
                (click CANCEL if you want to leave them unchanged)
              </p>
            </div>*/}
          </div>
          <div className="edit-property-form">
            <div className="edit-property-form-title-container">
              <p className="edit-property-form-subtitle-text mt-subtitle">
                Please <span>DOUBLE CHECK</span> each field for accuracy.
              </p>
              <div className="edit-property-form-title-divider"></div>
            </div>
            <div className="edit-property-form-body">
              <div className="title-dialog-body-col">
                <div className="title-column">
                  <div className="title-section">
                    <div className="old-text-container">
                      <p>
                        Current <span> Footer Text: </span>{" "}
                      </p>
                      <p className="actual-footer-text-1">
                        {oldFooter.propertyHighlightsFooterText1}
                      </p>
                    </div>
                    <textarea
                      className="extra-long-input shorter-text-area-height"
                      //type="text"
                      id="propertyHighlightsFooterText1"
                      name="propertyHighlightsFooterText1"
                      value={propertyHighlightsFooterText1 || ""}
                      onChange={handleFooterChange}
                    ></textarea>
                    <label
                      htmlFor="propertyHighlightsFooterText1"
                      className={overMaxFooter1 ? "character-count-error" : ""}
                    >
                      Character count:{" "}
                      {propertyHighlightsFooterText1 &&
                      propertyHighlightsFooterText1.length
                        ? propertyHighlightsFooterText1.length
                        : "0"}
                      /{maxHighlightFooter1Length} Max
                    </label>
                  </div>
                  {/*<div className="title-section">
                    <div className="old-text-container">
                      <p>
                        Current <span> Footer Line 2: </span>
                      </p>{" "}
                      <p className="actual-footer-text">
                        {oldFooter.propertyHighlightsFooterText2}
                      </p>
                    </div>
                    <input
                      className="extra-long-input"
                      type="text"
                      id="propertyHighlightsFooterText2"
                      name="propertyHighlightsFooterText2"
                      value={propertyHighlightsFooterText2 || ""}
                      onChange={handleFooterChange}
                    ></input>
                    <label
                      htmlFor="propertyHighlightsFooterText1"
                      className={overMaxFooter2 ? "character-count-error" : ""}
                    >
                      Character count:{" "}
                      {propertyHighlightsFooterText2 &&
                      propertyHighlightsFooterText2.length
                        ? propertyHighlightsFooterText2.length
                        : "0"}
                      /{maxHighlightFooter2Length} Max
                    </label>
                  </div>*/}

                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div className="edit-form-button-container">
            <div
              className="edit-form-cancel-button"
              onClick={onHighlightFooterClose}
            >
              <p>Cancel</p>
            </div>
            <div
              className="edit-form-submit-button"
              onClick={() =>
                handleAddFooter(overMaxFooter1, overMaxFooter2, false)
              }
            >
              <p>Submit For All Areas</p>
            </div>
            <div
              className="edit-form-submit-button"
              onClick={() =>
                handleAddFooter(overMaxFooter1, overMaxFooter2, true)
              }
            >
              <p>Submit For This Area Only</p>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default compose(withSnackbar, withStyles(styles))(HighlightFooterDialog);
