import React, { Component } from "react";

import QVFourCoverPage from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourCoverPage";

class BubbleCompareFirstRight extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      coverPageDetails,
      MQCityDetails,
      printVersion,
      version,
      quarterlyAreaTable,
      isDemo,
    } = this.props;
    // console.log("***", this.props);
    return (
      <div className="bubble-compare-first-right">
        <QVFourCoverPage
          currentArea={currentArea}
          agentData={agentData}
          coverPageDetails={coverPageDetails}
          currentArea={currentArea}
          hoData={hoData}
          printVersion={printVersion}
          currentSoldListings={quarterlyAreaTable.currentSoldListings}
          marketHighLightsData={quarterlyAreaTable.marketHighLightsData}
          photoNum="0"
          version={version}
          quarterlyAreaTable={quarterlyAreaTable}
          //printDate="March 30"
          isDemo={isDemo}
        />
      </div>
    );
  }
}

export default BubbleCompareFirstRight;
