import React, { Component } from "react";
import YETwoPropertyHighlightModal from "./YETwoPropertyHighlightModal";
import TestHome from "views/PmaAdmin/PropertyHighlights/test-home.jpg";
import { propertyUtil } from "views/Pma/PropertyUtil";
import { Link } from "react-router-dom";
import { IsValidSessionSwitch } from "common/storage";
import YETwoHighlightsProp from "./YETwoHighlightsProp";
import QVFourTestimonial from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourTestimonial";

class YETwoPropertyHighlight extends Component {
  state = {
    isModalOpen: false,
  };
  isUnit = (prop) => {};
  shortenCourtesy = (brokerage, isSellingAgent) => {
    const { printVersion, agentData } = this.props;
    const { brokerageName } = agentData;
    let len = brokerage.length;

    let updatedBrokerage = brokerage.slice();
    if (brokerage === "Village Properties - 1") {
      updatedBrokerage = "Village Properties";
    } else if (
      brokerage === "Berkshire Hathaway HomeServices California Properties" &&
      brokerageName === "Berkshire Hathaway Home Services California Properties"
    ) {
      updatedBrokerage =
        "Berkshire Hathaway HomeServices California Properties";
    }
    if (!isSellingAgent && brokerageName === updatedBrokerage) {
      return "";
    } else if (isSellingAgent) {
      return "";
    } else if (!isSellingAgent && len >= 35) {
      return `Image courtesy of ${updatedBrokerage.slice(0, 35) + "..."}`;
    } else {
      return `Image courtesy of ${updatedBrokerage}`;
    }
  };

  formatSalePrice = (price) => {
    let salePriceOnlyNumbers = ((price || 0) + "").replace(/\D/g, "");
    return Number(salePriceOnlyNumbers);
  };
  determineTitleYear = (useAlt) => {
    const { currentSoldListings, agentData, title } = this.props;
    const { isMemberOfTeam, listgenUserID } = agentData;
    let mappedSaleDate;
    if (!currentSoldListings) {
      mappedSaleDate = ["1999"];
    } else {
      mappedSaleDate = currentSoldListings.map((prop) =>
        prop.saleDate ? prop.saleDate.slice(0, 4) : "1999"
      );
    }
    let years = mappedSaleDate.map(Number);
    let isDianneJohnson = listgenUserID === "100988";
    let titleStyles = "";

    let titleLength = title ? title.length : 1;
    if (titleLength > 40) {
      titleStyles = "xs-highlight-title";
    }

    return (
      <div className="ye2-property-highlight-title-top-row">
        <div
          className={`${
            useAlt
              ? "alt-y2-property-highlight-title-text"
              : "y2-property-highlight-title-text"
          } ${titleStyles}`}
        >
          {title}
        </div>
      </div>
    );
  };
  handlePropertyHighlightDialog = (openDialog = false) => {
    this.setState({
      isModalOpen: openDialog,
    });
  };

  determineRepresentation = (prop) => {
    const { isMemberOfTeam, listgenUserID } = this.props.agentData;
    const { mlsAreaID } = this.props;
    let isDianneJohnson = listgenUserID === "100988";
    let pronoun;
    if (listgenUserID === "100656") {
      pronoun = "C&H";
    } else if (isMemberOfTeam || isDianneJohnson) {
      pronoun = "We";
    } else if (listgenUserID === "101677") {
      pronoun = "";
    } else {
      pronoun = "I";
    }
    const { isSellingAgent, isBuyingAgent } = prop;
    if (isSellingAgent && isBuyingAgent) {
      return `${pronoun} Represented the Seller and the Buyer`;
    } else if (isSellingAgent) {
      return `${pronoun} Represented the Sellers`;
    } else if (isBuyingAgent) {
      return `${pronoun} Represented the Buyers`;
    }
  };

  photoURLToUse = (prop) => {
    let url;
    if (prop.optimizedPhotoURL === "ERROR") {
      url = prop.photoURL;
    } else if (prop.optimizedPhotoURL) {
      url = prop.optimizedPhotoURL;
    } else {
      url = prop.photoURL;
    }
    return url;
  };

  render() {
    let {
      currentSoldListings,
      mlsAreaID,
      isAgentPage,
      setHighlightTitleDialog,
      setHighlightFooterDialog,
      title,
      subtitle,
      footerText1,
      footerText2,
      onHighlightTitleOpen,
      onHighlightFooterOpen,
      agentData,
      printVersion,
      currentArea,
      isDemo,
      openEditDialogFn,
      testimonials,
    } = this.props;

    const { isMemberOfTeam, listgenUserID, brokerageName } = agentData;
    let highlightContainerStyles;

    let subtitleStyles;
    if (subtitle && subtitle.length >= 300) {
      subtitleStyles = "smaller-ye2-subtitle-text";
    }

    let useSmallerFooterFont = false;
    if (footerText1 && footerText1.length >= 80) {
      useSmallerFooterFont = true;
    }
    let useAlt = false;

    const {
      handlePropertyHighlightDialog,
      determineRepresentation,
      shortenCourtesy,
      photoURLToUse,
    } = this;

    const { isModalOpen } = this.state;
    let isCountryClubGate = mlsAreaID === "132" ? true : false;
    const {
      formatSuffix,
      formatBaths,
      toAcre,
      emptyValueCheck,
      endsWithS,
      statusContingentToPending,
    } = propertyUtil;
    let isDianneJohnson = listgenUserID === "100988";
    let isGalliTeam = listgenUserID === "102843";
    let showTitle = false;
    const isASessionSwitch = true; //!printVersion && IsValidSessionSwitch();

    // if (listgenUserID === "100778" || listgenUserID === "100528") {
    //   showTitle = false;
    // }
    //Need to implement this logic if required
    // if(!isAgentPage){
    //   currentSoldListings =([...currentSoldListings] || []).sort((a, b) => Number(a.salePrice) > Number(b.salePrice) ? -1 : Number(a.salePrice) < Number(b.salePrice) ? 1 : 0);
    // }

    let use1Prop3Photos = false;
    let use1Prop = false;
    let use2Props = false;
    let useQuadrantPhotoProps = false;
    let use3Props = false;
    let use4Props = false;
    let switchedProperties = false;
    let backgroundStyles = "";
    if (currentSoldListings && currentSoldListings.length === 1) {
      use1Prop = true;
    } else if (currentSoldListings && currentSoldListings.length === 2) {
      use2Props = true;
      backgroundStyles = "two-prop-bg";
    } else if (currentSoldListings && currentSoldListings.length === 3) {
      use3Props = true;
    } else {
      useQuadrantPhotoProps = true;
    }

    // if (listgenUserID === "100015") {
    //   switchedProperties = true;
    // }
    // let showButton = mailingStatus === "Approved" ? false : true;
    let showButton = false;
    // if (use1Prop || use3Props) {
    //   showButton = false;
    // }
    let col2Styles;
    if (isGalliTeam) {
      col2Styles = "taller-col-2-margin";
    }
    let row1 = (
      <div className="ye2-property-highlight-row-1">
        <div className="ye2-property-highlight-inner-photo-container">
          {[2, 3, 4].map((placeholder, index) => {
            let prop = currentSoldListings[index + 2];

            if (!prop) {
              return (
                <div
                  className="property-highlight-placeholder"
                  key={placeholder}
                >
                  <p>Please select</p>
                  <p>a property</p>
                </div>
              );
            } else {
              return (
                <div className="row1-photo-col" key={placeholder}>
                  {isAgentPage && showButton ? (
                    <div>
                      <div className="property-number">{index + 3}</div>
                      <div
                        className="property-edit-photo-btn"
                        onClick={() => openEditDialogFn(prop)}
                      >
                        Edit
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <YETwoHighlightsProp
                    prop={prop}
                    agentData={agentData}
                    currentSoldListings={currentSoldListings}
                    useAlt={useAlt}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    );

    let row2 = (
      <div className="ye2-property-highlight-row-2">
        <div className="ye2-property-highlight-inner-photo-container">
          {[0, 1].map((placeholder, index) => {
            let prop = currentSoldListings[index];

            if (!prop) {
              return (
                <div
                  className="property-highlight-placeholder"
                  key={placeholder}
                >
                  <p>Please select</p>
                  <p>a property</p>
                </div>
              );
            } else {
              let hideCourtesy = false;

              if (
                (prop.streetName === "Lasuen" && prop.streetNumber == "1713") ||
                (prop.streetName === "Ferrelo" && prop.streetNumber == "1250")
              ) {
                hideCourtesy = true;
              }

              return (
                <div className="row2-photo-col" key={placeholder}>
                  {isAgentPage && showButton ? (
                    <div>
                      <div className="property-number">{index + 1}</div>
                      <div
                        className="property-edit-photo-btn"
                        onClick={() => openEditDialogFn(prop)}
                      >
                        Edit
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <YETwoHighlightsProp
                    prop={prop}
                    agentData={agentData}
                    currentSoldListings={currentSoldListings}
                    useAlt={useAlt}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    );
    return (
      <div
        className={`${
          isAgentPage
            ? `ye2-agent-page-property-highlight ${
                useAlt && "alt-ye2-property-highlight"
              } ${highlightContainerStyles}`
            : `ye2-property-highlight ${
                useAlt && "alt-ye2-property-highlight"
              } ${highlightContainerStyles}`
        }`}
      >
        {isModalOpen && (
          <YETwoPropertyHighlightModal
            isModalOpen={isModalOpen}
            handlePropertyHighlightDialog={handlePropertyHighlightDialog}
          />
        )}
        {!isAgentPage && !printVersion && showButton && (
          <div
            className={`ye2-new-edit-button-container ${
              useAlt
                ? "gummow-alt-prop-edit-button-container"
                : "regular-prop-edit-button"
            }`}
          >
            <Link
              to={`/agent/property-highlights/${
                currentArea && currentArea.mlsAreaID
              }`}
              className="ye2-new-edit-button"
            >
              Click Here to Edit This Page
            </Link>
          </div>
        )}
        {useAlt ? (
          <div className="alt-ye2-property-highlight-bg-container">
            <div className="alt-ye2-property-highlight-bg"></div>
          </div>
        ) : (
          <div
            className={`ye2-property-highlight-bg-container ${backgroundStyles}`}
          >
            <div className="ye2-property-highlight-bg"></div>
          </div>
        )}

        <div className="ye2-property-highlight-body">
          {mlsAreaID === "SMA-448" ? (
            <div className="ye2-property-highlight-title-container">
              {this.determineTitleYear()}

              <div className={`ye2-property-subtitle ${subtitleStyles}`}>
                <p>
                  When listing your home, we become your negotiator, project
                  coordinator and proven marketer. When helping you buy a home,
                  we use our depth of experience to strategize a winning offer.
                  Our latest Hillsborough seller, Richard on Hayne Road, summed
                  it up this way: <br />
                  "You won’t find a better real estate team in the area."
                </p>
              </div>
            </div>
          ) : useAlt ? (
            <div className="alt-ye2-property-highlight-title-container">
              {this.determineTitleYear(useAlt)}

              <div className="alt-ye2-property-subtitle">
                <p>
                  We want to take a moment to appreciate all our clients,
                  friends, and neighbors who have trusted us in achieving their
                  real estate goals! Working with so many kind and interesting
                  people is a testament to the passion we have for serving our
                  community.
                </p>
              </div>
            </div>
          ) : useAlt == "previous-version-gummow" ? (
            <div className="alt-ye2-property-highlight-title-container">
              <div className="gummow-qr">
                <img src="https://pma-coverphotos.s3.amazonaws.com/GUMMOWBROTHERS_QRcode_blackBG.png" />
              </div>
              <div className="gummow-arrow">
                <img src="https://pma-coverphotos.s3.amazonaws.com/gummow-arrow-highlights.png" />
              </div>

              <div className="ye2-property-highlight-title-top-row">
                <div className={`alt-y2-property-highlight-title-text `}>
                  How much is your home worth?
                </div>
              </div>

              <div className="alt-scan-here">
                <p>Scan Here</p>
              </div>
              <div className="alt-ye2-property-subtitle">
                <p>
                  A sampling of some of the beautiful homes we represented for
                  both Sellers and Buyers
                </p>
              </div>
            </div>
          ) : (
            <div className="ye2-property-highlight-title-container">
              {this.determineTitleYear()}

              <div className={`ye2-property-subtitle ${subtitleStyles}`}>
                <p>{subtitle}</p>
              </div>
            </div>
          )}

          {/*<div className="y2-property-highlight-footer">
            <div className="y2-property-highlight-custom-footer-text">
              {useAlt ? (
                <div className="alt-ye2-property-highlight-footer-text-1">
                  Best regards
                </div>
              ) : (
                <div
                  className={`ye2-property-highlight-photo-title-text prop-highlight-footer-text-1 ${
                    useSmallerFooterFont && "smaller-footer-1-text"
                  }`}
                >
                  {footerText1 && footerText1}
                </div>
              )}
              <div
                className={`${
                  useAlt
                    ? "alt-ye2-property-highlight-footer-text-2"
                    : "ye2-property-highlight-photo-title-text prop-highlight-footer-text-2"
                }`}
              >

              </div>

              {isAgentPage && showButton ? (
                <div
                  className="title-edit-button footer-btn"
                  onClick={() => onHighlightFooterOpen()}
                >
                  <div>
                    <p>edit</p>
                    <p>Footer</p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>*/}

          {isAgentPage && showButton ? (
            <div
              className="title-edit-button title-btn"
              onClick={() => onHighlightTitleOpen()}
              // style={{ marginTop: "-16px", marginBottom: "16px" }}
            >
              <div>
                <p>edit</p>
                <p>Title/Subtitle</p>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          {useQuadrantPhotoProps ? (
            <div
              className={`ye2-property-highlight-row-1 quadrant-prop-layout`}
            >
              <div className="ye2-property-highlight-inner-photo-container">
                <div className="ye2-prop-highlights-col-1">
                  {[0, 1].map((placeholder, index) => {
                    let prop = currentSoldListings[index];

                    return (
                      <div className="row1-photo-col" key={placeholder}>
                        {isAgentPage && showButton ? (
                          <div>
                            <div className="property-number">{index + 1}</div>
                            <div
                              className="property-edit-photo-btn"
                              onClick={() => openEditDialogFn(prop)}
                            >
                              Edit
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <YETwoHighlightsProp
                          prop={prop}
                          agentData={agentData}
                          currentSoldListings={currentSoldListings}
                          useAlt={useAlt}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className={`ye2-prop-highlights-col-2 ${col2Styles}`}>
                  {[2, 3].map((placeholder, index) => {
                    let prop = currentSoldListings[index + 2];

                    return (
                      <div className="row1-photo-col" key={placeholder}>
                        {isAgentPage && showButton ? (
                          <div>
                            <div className="property-number">{index + 3}</div>
                            <div
                              className="property-edit-photo-btn"
                              onClick={() => openEditDialogFn(prop)}
                            >
                              Edit
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <YETwoHighlightsProp
                          prop={prop}
                          agentData={agentData}
                          currentSoldListings={currentSoldListings}
                          useAlt={useAlt}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : use4Props ? (
            <div
              className={`ye2-property-highlight-row-1 prop-3-layout using-4-prop-layout`}
            >
              <div className="ye2-property-highlight-inner-photo-container">
                <div className="ye2-prop-highlights-col-1">
                  {[0].map((placeholder, index) => {
                    let prop = currentSoldListings[index];

                    return (
                      <div className="row1-photo-col" key={placeholder}>
                        {isAgentPage && showButton ? (
                          <div>
                            <div className="property-number">{index + 1}</div>
                            <div
                              className="property-edit-photo-btn"
                              onClick={() => openEditDialogFn(prop)}
                            >
                              Edit
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <YETwoHighlightsProp
                          prop={prop}
                          agentData={agentData}
                          currentSoldListings={currentSoldListings}
                          useAlt={useAlt}
                          use1Prop3Photos={use1Prop3Photos}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="ye2-prop-highlights-col-2">
                  {[1, 2, 3].map((placeholder, index) => {
                    let prop = currentSoldListings[index + 1];

                    return (
                      <div className="row1-photo-col" key={placeholder}>
                        {isAgentPage && showButton ? (
                          <div>
                            <div className="property-number">{index + 2}</div>
                            <div
                              className="property-edit-photo-btn"
                              onClick={() => openEditDialogFn(prop)}
                            >
                              Edit
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <YETwoHighlightsProp
                          prop={prop}
                          agentData={agentData}
                          currentSoldListings={currentSoldListings}
                          useAlt={useAlt}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : use1Prop3Photos ? (
            <div
              className={`ye2-property-highlight-row-1 ${
                use3Props && "prop-3-layout"
              }`}
            >
              <div className="ye2-property-highlight-inner-photo-container">
                <div className="ye2-prop-highlights-col-1">
                  {[0].map((placeholder, index) => {
                    let prop = currentSoldListings[index];

                    return (
                      <div className="row1-photo-col" key={placeholder}>
                        {isAgentPage && showButton ? (
                          <div>
                            <div className="property-number">{index + 1}</div>
                            <div
                              className="property-edit-photo-btn"
                              onClick={() => openEditDialogFn(prop)}
                            >
                              Edit
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <YETwoHighlightsProp
                          prop={prop}
                          agentData={agentData}
                          currentSoldListings={currentSoldListings}
                          useAlt={useAlt}
                          use1Prop3Photos={use1Prop3Photos}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="ye2-prop-highlights-col-2">
                  {[1, 2].map((placeholder, index) => {
                    let prop = currentSoldListings[index + 1];
                    let firstProp = currentSoldListings[0];
                    return (
                      <div className="row1-photo-col" key={placeholder}>
                        {isAgentPage && showButton ? (
                          <div>
                            <div className="property-number">{index + 2}</div>
                            <div
                              className="property-edit-photo-btn"
                              onClick={() => openEditDialogFn(prop)}
                            >
                              Edit
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <YETwoHighlightsProp
                          prop={prop}
                          firstProp={firstProp}
                          agentData={agentData}
                          currentSoldListings={currentSoldListings}
                          useAlt={useAlt}
                          use1Prop3Photos={use1Prop3Photos}
                          altBanner={true}
                          showBanner={index === 0 ? false : true}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : use1Prop ? (
            <div className="ye2-property-highlight-row-1 prop-1-layout">
              <div className="ye2-property-highlight-inner-photo-container">
                <div className="ye2-prop-highlights-col-1">
                  {[0].map((placeholder, index) => {
                    let prop = currentSoldListings[index];

                    return (
                      <div className="row1-photo-col" key={placeholder}>
                        {isAgentPage && showButton ? (
                          <div>
                            <div className="property-number">{index + 1}</div>
                            <div
                              className="property-edit-photo-btn"
                              onClick={() => openEditDialogFn(prop)}
                            >
                              Edit
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <YETwoHighlightsProp
                          prop={prop}
                          agentData={agentData}
                          currentSoldListings={currentSoldListings}
                          useAlt={useAlt}
                          use1Prop={use1Prop}
                          mlsAreaID={mlsAreaID}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : use3Props ? (
            <div
              className={`ye2-property-highlight-row-1 ${
                use3Props && "prop-3-layout"
              }`}
            >
              <div className="ye2-property-highlight-inner-photo-container">
                <div className="ye2-prop-highlights-col-1">
                  {[0].map((placeholder, index) => {
                    let prop = currentSoldListings[index];

                    return (
                      <div className="row1-photo-col" key={placeholder}>
                        {isAgentPage && showButton ? (
                          <div>
                            <div className="property-number">{index + 1}</div>
                            <div
                              className="property-edit-photo-btn"
                              onClick={() => openEditDialogFn(prop)}
                            >
                              Edit
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <YETwoHighlightsProp
                          prop={prop}
                          agentData={agentData}
                          currentSoldListings={currentSoldListings}
                          useAlt={useAlt}
                          use1Prop3Photos={use1Prop3Photos}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="ye2-prop-highlights-col-2">
                  {[1, 2].map((placeholder, index) => {
                    let prop = currentSoldListings[index + 1];

                    return (
                      <div className="row1-photo-col" key={placeholder}>
                        {isAgentPage && showButton ? (
                          <div>
                            <div className="property-number">{index + 2}</div>
                            <div
                              className="property-edit-photo-btn"
                              onClick={() => openEditDialogFn(prop)}
                            >
                              Edit
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <YETwoHighlightsProp
                          prop={prop}
                          agentData={agentData}
                          currentSoldListings={currentSoldListings}
                          useAlt={useAlt}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : use2Props ? (
            <div
              className={`ye2-property-highlight-row-1 ${
                use2Props && "prop-2-layout"
              }`}
            >
              <div className="ye2-property-highlight-inner-photo-container">
                <div className="ye2-prop-highlights-col-1">
                  {[0].map((placeholder, index) => {
                    let prop = currentSoldListings[index];

                    return (
                      <div className="row1-photo-col" key={placeholder}>
                        {isAgentPage && showButton ? (
                          <div>
                            <div className="property-number">{index + 1}</div>
                            <div
                              className="property-edit-photo-btn"
                              onClick={() => openEditDialogFn(prop)}
                            >
                              Edit
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <YETwoHighlightsProp
                          prop={prop}
                          agentData={agentData}
                          currentSoldListings={currentSoldListings}
                          use2Props={use2Props}
                          // useAlt={useAlt}
                          // use1Prop3Photos={use1Prop3Photos}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="ye2-prop-highlights-col-2">
                  {[1].map((placeholder, index) => {
                    let prop = currentSoldListings[index + 1];

                    return (
                      <div className="row1-photo-col" key={placeholder}>
                        {isAgentPage && showButton ? (
                          <div>
                            <div className="property-number">{index + 2}</div>
                            <div
                              className="property-edit-photo-btn"
                              onClick={() => openEditDialogFn(prop)}
                            >
                              Edit
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <YETwoHighlightsProp
                          prop={prop}
                          agentData={agentData}
                          currentSoldListings={currentSoldListings}
                          useAlt={useAlt}
                          use2Props={use2Props}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              {!isAgentPage && (
                <QVFourTestimonial
                  testimonials={testimonials}
                  agentData={agentData}
                  printVersion={printVersion}
                  currentArea={currentArea}
                  tallerStyles={true}
                />
              )}
            </div>
          ) : switchedProperties ? (
            <>
              {row2}
              {row1}
            </>
          ) : (
            <>
              {row1}

              {row2}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default YETwoPropertyHighlight;
