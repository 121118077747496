import { red } from "@material-ui/core/colors";

export default theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0),
    height: '80vh',
  },
  grid: {
    height: '100%',
    backgroundColor: theme.palette.common.default,
    flexGrow: 1,
    display: 'block',
    alignItems: 'center',
  },
  item: {
    height: '100%',
    backgroundColor: theme.palette.common.default,
  },
  imageWrapper: {
    backgroundColor: theme.palette.common.default,
    marginTop: '23vmin',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'middle',
    margin: '0px auto',
  },
  image:{
    width: '250px',
    [theme.breakpoints.down('md')]: {
      width: '225px',
    }
  },
  sugestion: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
});