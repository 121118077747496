import React, { Component } from "react";
import QVFourCoverPage from "./QVFourCoverPage";

class QVFourFirstRight extends Component {
  render() {
    const {
      agentData,
      coverPageDetails,
      currentArea,
      hoData,
      version,
      printVersion,
      quarterlyAreaTable,
      quadrantModalOpen,
      isDemo,
    } = this.props;
    //console.log("qv4 first right", isDemo);
    return (
      <div className="qv4-first-right">
        <QVFourCoverPage
          marketHighLightsData={quarterlyAreaTable.marketHighLightsData}
          agentData={agentData}
          coverPageDetails={coverPageDetails}
          currentArea={currentArea}
          hoData={hoData}
          photoNum="1"
          currentSoldListings={quarterlyAreaTable.currentSoldListings}
          quarterlyAreaTable={quarterlyAreaTable}
          printVersion={printVersion}
          version={version}
          quadrantModalOpen={quadrantModalOpen}
          isDemo={isDemo}
        />
      </div>
    );
  }
}

export default QVFourFirstRight;
