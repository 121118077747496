import React, { Component } from 'react';
import classNames from 'classnames';
import {
  Portlet,
  PortletContent,
  PortletHeader,
  PortletLabel,
} from 'components';
import {
  Grid,
  Typography,
  Divider,
  Button,
  Slide,
  TextField
} from '@material-ui/core';
import compose from 'recompose/compose';
import _ from 'lodash';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { useTheme } from '@material-ui/styles';

import { WebsiteLayout } from 'layouts';
import { spacing } from '@material-ui/system';
import { LegalTermsComponent } from 'components';

class LegalScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        firstName: '',
        lastName: ''
      },
      touched: {
        firstName: false,
        lastName: false
      },
      errors: {
        firstName: false,
        lastName: false
      },
      isValid: false
    };
  }

  handleTCFieldChange = (field, value, event) => {
    const newState = { ...this.state };
    newState.values[field] = value;
    newState.touched[field] = true;
    this.setState(newState, () => {
      this.validateForm();
    });
  };

  validateForm = () => {
    const { values } = this.props;
    const newState = { ...this.state };
    /**check for first name */
    if (values.firstName.toLowerCase() !== newState.values.firstName.toLowerCase()) {
      newState.errors.firstName = true;
    } else {
      newState.errors.firstName = false;
    }
    /**check for last name also */
    if (values.lastName.toLowerCase() !== newState.values.lastName.toLowerCase()) {
      newState.errors.lastName = true;
    } else {
      newState.errors.lastName = false;
    }
    /**check for is valid also */
    if(!newState.errors.firstName && !newState.errors.lastName){
      newState.isValid = true;
    }else{
      newState.isValid = false;
    }
    this.setState(newState);
  };

  render() {
    const { classes, className, mlsAreaIds, localValues } = this.props;
    const { values, errors, touched, isValid } = this.state;
    const rootClassName = classNames(className);
    const { brokerageName } = localValues;
    return (
      <div className={classes.root}>
        <Grid container className={classes.grid} spacing={0}>
          <Grid item lg={12}>
            <Portlet className={rootClassName} outlined={false}>
              <PortletContent>
                <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                  <Grid container className={classes.grid} spacing={0}>
                    <Grid item lg={12} className={classes.paddingGrid}>
                      <LegalTermsComponent
                      mlsAreaIds={mlsAreaIds}
                      agentFirstName={localValues.firstName}
                      agentLastName={localValues.lastName}
                      brokerageName={brokerageName || ''}
                       />
                    </Grid>
                    <Grid item lg={12} className={classes.dividerPaddingGrid}>
                      <Divider />
                      <br />
                      <Typography variant="subtitle2" className={classes.centerText}>
                        Enter first name and last name again to accept legal terms and continue.
                      </Typography>
                    </Grid>
                    <Grid item lg={3}></Grid>
                    <Grid item lg={3}>
                      <div className={classes.field}>
                        <TextField
                          className={classes.textField}
                          label="First Name"
                          margin="dense"
                          type="text"
                          required
                          name="firstName"
                          value={values.firstName}
                          error={touched.firstName && errors.firstName}
                          onChange={event => {
                            this.handleTCFieldChange('firstName', event.target.value, event);
                          }}
                        />
                        {(touched.firstName && errors.firstName) && (
                          <Typography className={classes.fieldError} variant="body2">
                            {'First name not matching'}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item lg={3}>
                      <div className={classes.field}>
                        <TextField
                          className={classes.textField}
                          label="Last Name"
                          margin="dense"
                          type="text"
                          required
                          name="lastName"
                          value={values.lastName}
                          error={touched.lastName && errors.lastName}
                          onChange={event => {
                            this.handleTCFieldChange('lastName', event.target.value, event);
                          }}
                        />
                        {(touched.lastName && errors.lastName) && (
                          <Typography className={classes.fieldError} variant="body2">
                            {'Last name not matching'}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item lg={3}></Grid>
                    {/* Button Grid */}
                    <Grid item lg={3}></Grid>
                    <Grid item lg={6}>
                      <div className={classes.buttonGrid}>
                        <div>
                          <Button
                            variant="contained"
                            className={classes.cancelButton}
                            href="/home"
                          >
                            I don't accept
            </Button>
                        </div>
                        <div>
                          <Button
                            variant="contained"
                            className={classes.acceptButton}
                            onClick={this.props.acceptTermsAndConditions}
                            disabled={!isValid}
                          >
                            I accept
            </Button>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={3}></Grid>
                  </Grid>
                </Slide>
              </PortletContent>
            </Portlet>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default compose(withStyles(styles))(LegalScreen);
