import React, { Component } from "react";
import White40Percent from "./WhiteBox_2_wShadow_40perct.png";
import { Link } from "react-router-dom";
import BubbleBoxModal from "views/Pma/PmaEditor/Quarterly/QVThree/QVThreeFirstPage/BackpageBoxes/BubbleBoxModal";
import { pmaActions } from "_store/_actions";
import { connect } from "react-redux";
import BackpageIndividualBox from "views/Pma/PmaEditor/Quarterly/QVThree/QVThreeFirstPage/BackpageBoxes/BackpageIndividualBox";

class BackpageBoxes extends Component {
  state = {
    editQuadrantFormData: null,
    oldQuadrantData: [],
    selectedBubbleNumber: null,
    titleEdit: false,
    footerEdit: false,
    maxMainTitleLength: 40,
    maxFooter1Length: 83,
    maxFooter2Length: 20,
    addAreasModalOpen: false,
    filteredAreas: {},
    maxMainSubtitleLength: 45,
    savingMessageShowing: false,
  };

  handleFormClose = () => {
    this.props.updateBubbleModalOpen(false);

    this.setState({
      titleEdit: false,
      footerEdit: false,
      addAreasModalOpen: false,
      savingMessageShowing: false,
    });
  };
  onEditFormSubmit = () => {
    let { editQuadrantFormData, oldQuadrantData } = this.state;

    Object.keys(editQuadrantFormData).forEach((item) => {
      if (editQuadrantFormData[item] === "" || !editQuadrantFormData[item]) {
        editQuadrantFormData[item] = "-";
      }
    });

    let foundIndex = oldQuadrantData.findIndex(
      (x) => x.mktValAreaID == editQuadrantFormData.mktValAreaID
    );
    oldQuadrantData[foundIndex] = editQuadrantFormData;
    this.props.setBubbleDetails(oldQuadrantData);
    this.setState({
      savingMessageShowing: true,
    });

    setTimeout(() => {
      this.setState({ savingMessageShowing: false });
      this.props.updateBubbleModalOpen(false);
    }, 500);
  };
  openSelectedBubble = (bubbleNumber, titleEdit, footerEdit) => {
    const { mailProStatus } = this.props.agentData;
    if (mailProStatus === "allAreasApproved") {
      return;
    }
    this.props.updateBubbleModalOpen(true);

    this.setState({
      selectedBubbleNumber: bubbleNumber,
      titleEdit: titleEdit,
      footerEdit: footerEdit,
      savingMessageShowing: false,
    });
  };

  componentDidMount = () => {
    if (this.props.printVersion) {
      require("./print.scss");
    } else {
      require("./editor.scss");
    }
    const { bubbleBoxes, currentArea } = this.props;
    const { mlsAreaID } = currentArea;

    let newObj = [...bubbleBoxes]
      .filter((bubbleObj) => bubbleObj.mktValAreaID === currentArea.mlsAreaID)
      .map((i) => ({ ...i }))[0];

    let filteredData = [...bubbleBoxes]
      .filter(
        (bubbleObj) =>
          bubbleObj.mktValAreaID === currentArea.mlsAreaID ||
          currentArea.mlsAreaID.includes(bubbleObj.mktValAreaID)
      )
      .map((i) => ({ ...i }))[0];

    let removeDashes = Object.keys(filteredData).forEach((item) => {
      if (filteredData[item] === "-" || !filteredData[item]) {
        filteredData[item] = "";
      }
    });

    this.setState({
      oldQuadrantData: [...bubbleBoxes].map((i) => ({ ...i })),
      editQuadrantFormData: filteredData,
    });
  };
  // const {
  //   quadAreaID,
  //   mktValAreaTitle,
  //   mktValSubTitle,
  //   quad1Title,
  //   quad1Text,
  //   quad2Title,
  //   quad2Text,
  //   quad3Title,
  //   quad3Text,
  //   quad4Title,
  //   quad4Text,
  // } = this.state.editQuadrantFormData;
  // };
  // handleRestoreDefault = () => {
  //   const {
  //     selectedBubbleNumber,
  //     editQuadrantFormData,
  //     oldQuadrantData,
  //     titleEdit,
  //   } = this.state;
  //   let past = oldQuadrantData[selectedBubbleNumber];
  //   if (titleEdit) {
  //     this.setState({});
  //   } else if (selectedBubbleNumber >= 0) {
  //     let newObj = [...editQuadrantFormData].map((i) => ({ ...i }));
  //     let oldSelection = [...oldQuadrantData].map((i) => ({
  //       ...i,
  //     }))[selectedBubbleNumber];
  //     newObj[selectedBubbleNumber].text = oldSelection.text;
  //     this.setState({
  //       editQuadrantFormData: newObj,
  //     });
  //   }
  // };
  handleInputChange = (e) => {
    const {
      editQuadrantFormData,
      oldQuadrantData,
      titleEdit,
      footerEdit,
    } = this.state;
    const target = e.target;
    const value = target.value;
    const name = target.name;
    let clonedEditQuadrantFormData = Object.assign(editQuadrantFormData, {
      [name]: value,
    });
    this.setState({
      editQuadrantFormData: clonedEditQuadrantFormData,
    });
  };

  formatAreaTitle = (area) => {
    if (area.length > 10) {
      return "Neighborhood";
    } else {
      return area;
    }
  };
  formatAreaBody = (area) => {
    if (area.length > 10) {
      return "neighborhood";
    } else {
      return area;
    }
  };
  render() {
    const {
      openSelectedBubble,
      handleInputChange,
      handleRestoreDefault,
      applyToAllAreas,
    } = this;
    const {
      editQuadrantFormData,
      oldQuadrantData,
      selectedBubbleNumber,
      titleEdit,
      footerEdit,
      addAreasModalOpen,
      maxMainTitleLength,
      maxFooter1Length,
      maxFooter2Length,
      maxMainSubtitleLength,
      savingMessageShowing,
    } = this.state;

    const {
      currentArea,
      agentData,
      bubbleBoxes,
      printVersion,
      quadrantModalOpen,
      mlsAreas,
    } = this.props;

    const { listgenUserID, mailProStatus } = agentData;
    console.log("listgenUserID", listgenUserID);
    const { mlsNeighborhood, mlsAreaID } = currentArea;

    const { mobilePhoneNo } = agentData;
    const { formatAreaTitle, formatAreaBody } = this;

    let showButtons = true;
    if (
      mailProStatus === "allAreasApproved" ||
      mailProStatus === "readyForPrint"
    ) {
      showButtons = false;
    }
    if (editQuadrantFormData && Object.keys(editQuadrantFormData).length >= 1) {
      const {
        footerLine1,
        footerLine2,

        mktValAreaID,
        mktValAreaTitle,
        mktValBox1Text,
        mktValBox1Title,
        mktValBox1SubText,
        mktValBox2Text,
        mktValBox2Title,
        mktValBox2SubText,
        mktValBox3Text,
        mktValBox3Title,
        mktValBox3SubText,
        mktValSubTitle,
      } = editQuadrantFormData;

      return (
        <div
          className={
            printVersion
              ? "print-backpage-boxes-container"
              : "editor-backpage-boxes-container"
          }
        >
          {editQuadrantFormData &&
            Object.keys(editQuadrantFormData).length >= 1 &&
            quadrantModalOpen && (
              <BubbleBoxModal
                oldQuadrantData={oldQuadrantData}
                onCloseFn={this.handleFormClose}
                handleInputChange={handleInputChange}
                editQuadrantFormData={editQuadrantFormData}
                onEditFormSubmit={this.onEditFormSubmit}
                selectedBubbleNumber={selectedBubbleNumber}
                handleRestoreDefault={handleRestoreDefault}
                titleEdit={titleEdit}
                footerEdit={footerEdit}
                mlsAreas={mlsAreas}
                maxMainTitleLength={maxMainTitleLength}
                maxFooter1Length={maxFooter1Length}
                maxFooter2Length={maxFooter2Length}
                maxMainSubtitleLength={maxMainSubtitleLength}
                savingMessageShowing={savingMessageShowing}
              />
            )}
          {/*className={`${
              !footerLine1 && !footerLine2
                ? "extended-img"
                : "backpage-boxes-img"
            }`}*/}

          <div className="backpage-boxes">
            <div className="backpage-boxes-img">
              <img
                src="https://pma-coverphotos.s3.amazonaws.com/non-covers/backpage-june-2024-teal.jpg"
                alt="Backpage backcover"
              />
            </div>
            <div className="backpage-boxes-title-container 1234">
              {mktValAreaTitle && mktValAreaTitle !== "-" && (
                <div
                  className="backpage-boxes-title"
                  dangerouslySetInnerHTML={{ __html: mktValAreaTitle }}
                ></div>
              )}
              {mktValSubTitle && mktValSubTitle !== "-" && (
                <div
                  className="backpage-boxes-subtitle"
                  dangerouslySetInnerHTML={{ __html: mktValSubTitle }}
                ></div>
              )}
            </div>

            <div className="backpage-boxes-body">
              {!printVersion && showButtons && (
                <>
                  <div
                    className="bubble-btn bubble-title-btn"
                    onClick={() => openSelectedBubble(null, true)}
                  >
                    <div>Edit Title & Subtitle</div>
                  </div>
                </>
              )}

              <div
                className={`${
                  1 == selectedBubbleNumber && "selected-quadrant-section"
                } backpage-boxes-box-section section-1`}
                onClick={() => openSelectedBubble(1)}
                key={1}
              >
                <div className="backpage-boxes-section-number">1</div>
                {!printVersion && showButtons && (
                  <div
                    className="bubble-btn bubble-btn-1"
                    onClick={() => openSelectedBubble(1)}
                  >
                    <div>Edit Section #1</div>
                  </div>
                )}
                <div
                  className="backpage-box-box-img"
                  alt="Transparent dashboard container"
                ></div>
                <BackpageIndividualBox
                  mktValBoxTitle={mktValBox1Title}
                  mktValBoxText={mktValBox1Text}
                  mktValBoxSubText={mktValBox1SubText}
                  mlsAreaID={mlsAreaID}
                />
              </div>
              <div
                className={`${
                  2 == selectedBubbleNumber && "selected-quadrant-section"
                } backpage-boxes-box-section section-2`}
                onClick={() => openSelectedBubble(2)}
                key={2}
              >
                <div className="backpage-boxes-section-number">2</div>
                {!printVersion && showButtons && (
                  <div
                    className="bubble-btn bubble-btn-2"
                    onClick={() => openSelectedBubble(2)}
                  >
                    <div>Edit Section #2</div>
                  </div>
                )}
                <div
                  className="backpage-box-box-img"
                  alt="Transparent dashboard container"
                ></div>

                <BackpageIndividualBox
                  mktValBoxTitle={mktValBox2Title}
                  mktValBoxText={mktValBox2Text}
                  mktValBoxSubText={mktValBox2SubText}
                  mlsAreaID={mlsAreaID}
                />
              </div>
              <div
                className={`${
                  3 == selectedBubbleNumber && "selected-quadrant-section"
                } backpage-boxes-box-section section-3`}
                onClick={() => openSelectedBubble(3)}
                key={3}
              >
                <div className="backpage-boxes-section-number">3</div>
                {!printVersion && showButtons && (
                  <div
                    className="bubble-btn bubble-btn-3"
                    onClick={() => openSelectedBubble(3)}
                  >
                    <div>Edit Section #3</div>
                  </div>
                )}
                <div
                  className="backpage-box-box-img"
                  alt="Transparent dashboard container"
                ></div>
                <BackpageIndividualBox
                  mktValBoxTitle={mktValBox3Title}
                  mktValBoxText={mktValBox3Text}
                  mktValBoxSubText={mktValBox3SubText}
                  mlsAreaID={mlsAreaID}
                />
              </div>
            </div>
            {/*{(footerLine1 || footerLine2) && (
              <div className="backpage-boxes-footer-container">
                {!printVersion && showButtons && (
                  <>
                    <div
                      className="bubble-btn bubble-footer-btn"
                      onClick={() => openSelectedBubble(null, false, true)}
                    >
                      <div>Edit Footer</div>
                    </div>
                  </>
                )}

                <div
                  className="backpage-boxes-footer-line-1"
                  dangerouslySetInnerHTML={{ __html: footerLine1 }}
                ></div>

                <div
                  className="backpage-boxes-footer-line-2"
                  dangerouslySetInnerHTML={{ __html: footerLine2 }}
                ></div>
              </div>
            )}*/}
          </div>
        </div>
      );
    } else {
      return <div>Please contact us to upload information</div>;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    quadrantModalOpen: state.pma.quadrantModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateBubbleModalOpen: (isModalOpen) => {
      dispatch(pmaActions.updateQuadrantModalOpen(isModalOpen));
    },
    setBubbleDetails: (bubbleDetails) => {
      dispatch(pmaActions.setBubbleDetails(bubbleDetails));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BackpageBoxes);
