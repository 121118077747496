import React, { Fragment } from 'react';
import { Icon } from '@material-ui/core';
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import styles from '../Notes/AnniversaryAutomator/styles';
import compose from "recompose/compose";
import { withStyles } from '@material-ui/core/styles';
import MailProButton from './MailProButton';

const AnniversaryAutomatorArrowButton = ({iKey, label, onClick, classes, customStyle = {}, ...rest}) => {
  const FinalIcon = rest.reverse ? ArrowLeftIcon : ArrowRightIcon;
    return <MailProButton
    key={"anniversary_automator_" + iKey}
    variant="filled"
    label={
      <Fragment>
        <div className={classes.addSubmitButton}>
          {!rest.reverse && <div>{label}</div>}
          <div>
            <Icon style={{...{ marginTop: "-2px" }, ...customStyle.icon1}}>
              <FinalIcon
                style={{ width: "32px", height: "32px" }}
              />
            </Icon>
            <Icon style={{...{ marginTop: "-2px" }, ...customStyle.icon2}}>
              <FinalIcon
                style={{ width: "32px", height: "32px" }}
              />
            </Icon>
          </div>
          {rest.reverse && <div>{label}</div>}
        </div>
      </Fragment>
    }
    customStyle={{...{
        letterSpacing: "0.025em",
        color: 'white',
        paddingLeft: "40px",
        paddingRight: "40px",
    }, ...(customStyle.root || {})}}
    onClick={onClick}
    {...rest}
  />
}

export default compose(withStyles(styles))(AnniversaryAutomatorArrowButton); 