import React, { Component } from 'react';
import { propertyUtil } from 'views/Pma/PropertyUtil';

class NoteworthySales extends Component {
  render() {
    const { agentData, yearEnd } = this.props;
    const { endsWithS, formatSuffix } = propertyUtil;
    const { soldPhotoDetails } = yearEnd.pmaYearEndCityDTO;
    const { isMemberOfTeam, teamMembers, listgenUserFirstName } = agentData;
    // console.log('soldPhotoDetails', soldPhotoDetails);
    let threeListings = soldPhotoDetails.slice(0, 3).map((list, i) => {
      return (
        <div
          key={`${list.StreetNumber} ${list.StreetName} ${i}`}
          className="listing-col"
        >
          <img
            src={list.PhotoURL}
            alt={`${list.StreetNumber} ${list.StreetName}`}
            className="three-photo-img"
          />
          <div className="list-address-row">
            <p>
              {list.StreetNumber === '-' ? '' : list.StreetNumber}{' '}
              {list.StreetName} {formatSuffix(list.StreetSuffix)}{' '}
            </p>
            <p>${Number(list.SalePrice).toLocaleString()}</p>
          </div>
        </div>
      );
    });
    return (
      <div className="noteworthy-sales">
        <div className="noteworthy-sales-title-container">
          <div className="noteworthy-sales-divider" />
          {isMemberOfTeam ? (
            <div className="noteworthy-sales-title">
              {listgenUserFirstName} & {teamMembers[1].firstName}
              {endsWithS(teamMembers[1].firstName)} Recent Sales
            </div>
          ) : (
            <div className="noteworthy-sales-title">
              {listgenUserFirstName}
              {endsWithS(listgenUserFirstName)} Recent Sales
            </div>
          )}
          <div className="noteworthy-sales-divider" />
        </div>

        <div className="three-listings-container">{threeListings}</div>
      </div>
    );
  }
}

export default NoteworthySales;
