import React, { Component } from "react";
import YEOneFirstPage from "./YEOneFirstPage";
import YEOneSecondPage from "./YEOneSecondPage";

class YEOne extends Component {
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      pma,
      currentArea,
      mlsAreas,
      navbarData,
      coverPageDetails,
      testimonials,
      yearEnd,
      demoAccountAreaName,
      demoAccountCoverPhoto
    } = this.props;
    console.log("yeone", this.props);
    return (
      <div className={printVersion ? "ye-print-page" : "ye-pma-editor-page"}>
        <YEOneFirstPage
          agentData={agentData}
          hoData={hoData}
          currentArea={currentArea}
          mlsAreas={mlsAreas}
          printVersion={printVersion}
          version={version}
          navbarData={navbarData}
          coverPageDetails={coverPageDetails}
          testimonials={testimonials}
          yearEnd={yearEnd}
          demoAccountAreaName={demoAccountAreaName}
          demoAccountCoverPhoto={demoAccountCoverPhoto}
        />
        <YEOneSecondPage
          agentData={agentData}
          hoData={hoData}
          currentArea={currentArea}
          printVersion={printVersion}
          version={version}
          yearEnd={yearEnd}
        />
      </div>
    );
  }
}

export default YEOne;
