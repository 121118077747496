import React, { Component, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import validate from 'validate.js';
import _ from 'lodash';
import { withStyles } from "@material-ui/core";
import { withSnackbar } from 'notistack';
import {
  Grid,
  Button,
  CircularProgress,
  TextField,
  Typography,
  IconButton,
  Input
} from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import styles from "./styles";
import schema from "./schema";
import { ResetPassword as ResetPasswordAPI } from "services/users";
import { showErrorNotification } from "common/helpers";
import { showSuccessNotification } from "common/helpers";
import { Store } from "Store";
import { HANDLE_VALIDATIONS } from "store/types";
import { HANDLE_FIELD_CHANGE } from "store/types";
import { SHOW_PASSWORD } from "store/types";
import { ResetPasswordValidate } from "services/users";
import { START_LOADING } from "store/types";
import { STOP_LOADING } from "store/types";
import { STOP_LOADING_WITH_MESSAGE } from "store/types";
import { STOP_LOADING_WITH_SUCCESS_MESSAGE } from "store/types";
import { RESET_APP_STATE } from "store/types";
import { ResetAllLocalStorages } from "common/storage";
import { RESET_APP_STATE_EXCEPT_VALUES } from "store/types";
import querystring from 'querystring';
import DocumentTitle from "react-document-title";

const ResetPassword = (props) => {
  /**URL http://localhost:3000/password/reset/subach007@gmail.com/20c5678e-67fe-4271-8357-cbcc865ebda9 */
  /** http://localhost:3000/password/reset/?agentResetPasswordToken=fc092741-df25-4009-af80-a13cd800ff26&emailAddress=akchoudhary966@gmail.com */
  const { state, dispatch } = React.useContext(Store);
  const listgenLogo = '/images/listgen-logo.png';
  const listgenLogoAlt = 'ListGen Logo';
  /**put into the try catch */
  let userToken;
  let userEmail;
  try {
    let parsedParams = querystring.parse(props.location.search.substring(1));
    userToken = parsedParams.agentResetPasswordToken;
    userEmail = parsedParams.emailAddress;
  } catch (err) {
    userToken = '';
    userEmail = '';
  }
  const { history } = props;
  /**get props */
  const { classes } = props;
  const {
    values,
    touched,
    errors,
    isValid,
    submitSuccess,
    submitError,
    isLoading,
  } = state;
  /**define error methods */
  const showPasswordError = touched.password && errors.password;
  const showCPasswordError = touched.confirmPassword && errors.confirmPassword;


  const handleBack = () => {
    history.push('/');
  };

  const handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  const handleClickShowCPassword = () => {
    this.setState(state => ({ showCPassword: !state.showCPassword }));
  };

  const validateToken = () => {
    ResetPasswordValidate(userEmail, userToken).then(data => {
      /**nothing to do as because token is validated. */
      switch (Number(data)) {
        case 0:
          /**nothing to do */
          break;
        case 1:
          showErrorNotification(props, "Some unexpected error occured.");
          dispatch({
            type: STOP_LOADING_WITH_MESSAGE,
            message: 'Invalid/Expired Token. Unable to process request. Please request a new one.'
          });
          break;
        case 2:
          showErrorNotification(props, "Password matches with the existing password.");
          dispatch({
            type: STOP_LOADING_WITH_MESSAGE,
            message: 'Invalid/Expired Token. Unable to process request. Please request a new one.'
          });
          break;
        default:
          showErrorNotification(props, "Something went wrong. Please try after some time.");
          dispatch({
            type: STOP_LOADING_WITH_MESSAGE,
            message: 'Invalid/Expired Token. Unable to process request. Please request a new one.'
          });
          break;
      }
    }).catch(err => {
      dispatch({
        type: STOP_LOADING_WITH_MESSAGE,
        message: 'Invalid/Expired Token. Unable to process request. Please request a new one.'
      });
    });
  };

  useEffect(() => {
    /**reset all storage */
    ResetAllLocalStorages();
    /**reset app also */
    dispatch({
      type: RESET_APP_STATE
    });
    /**First validate the token */
    validateToken();
  }, []);

  const validateForm = _.debounce(() => {
    dispatch({
      type: HANDLE_VALIDATIONS,
      schema
    });
  }, 300);

  const handleResetPassword = () => {
    try {
      dispatch({
        type: START_LOADING
      });

      ResetPasswordAPI(userEmail, values.password).then(data => {
        switch (Number(data)) {
          case 0:
            /**clean the form */
            dispatch({
              type: STOP_LOADING_WITH_SUCCESS_MESSAGE,
              message: 'Password resets. Please wait redirecting to login page...'
            });
            /**Reset all keys also */
            ResetAllLocalStorages();
            setTimeout(() => {
              dispatch({
                type: RESET_APP_STATE
              });
              history.push('/logout?mode=4');
            }, 1500);
            break;
          case 1:
            showErrorNotification(props, "Some unexpected error occured.");
            break;
          case 2:
            showErrorNotification(props, "Password matches with the existing password.");
            break;
          default:
            showErrorNotification(props, "Something went wrong. Please try after some time.");
            break;
        }
        /**stop loading also */
        dispatch({
          type: STOP_LOADING
        });
      }).catch(err => {
        showErrorNotification(props, err);
        dispatch({
          type: STOP_LOADING
        });
      });
    } catch (err) {
      showErrorNotification(props, 'Unable to process request.');
      dispatch({
        type: STOP_LOADING
      });
    }
  };


  return (
    <DocumentTitle title="Reset Password">
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteWrapper} item lg={7}>
            <div className={classes.quote} />
          </Grid>
          <Grid className={classes.content} item lg={5} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                {/* <IconButton
                  className={classes.backButton}
                  onClick={this.handleBack}
                >
                  <ArrowBackIcon />
                </IconButton> */}
              </div>
              <div className={classes.contentBody}>
                <form className={classes.form}>
                  <div className={classes.imageWrapper}>
                    <img
                      alt={listgenLogoAlt}
                      className={classes.image}
                      src={listgenLogo}
                    />
                  </div>
                  <Typography className={classes.title} variant="h4">
                    Reset Password
                  </Typography>
                  <Typography className={classes.sugestion} variant="body1">
                    To update your password enter a new one below.
                  </Typography>
                  <Typography className={classes.sugestion} variant="body1">
                    To make it stronger, use upper and lower case letters, numbers and symbols like ! @ ^ )
                  </Typography>
                  {(submitSuccess === null && submitError === null) && (
                    <div>
                      <div className={classes.fields}>
                        <TextField
                          className={classes.textField}
                          label="New Password"
                          type={state.showPassword.password ? 'text' : 'password'}
                          name="password"
                          onChange={event => {
                            dispatch({
                              type: HANDLE_FIELD_CHANGE,
                              fieldType: event.target.type,
                              value: event.target.value,
                              name: 'password',
                            });
                            validateForm();
                          }
                          }
                          variant="outlined"
                          inputProps={{ minLength: 8 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={event => dispatch({
                                    type: SHOW_PASSWORD,
                                    name: 'password'
                                  })}
                                >
                                  {state.showPassword.password ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {showPasswordError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.password[0]}
                          </Typography>
                        )}
                        <TextField
                          className={classes.textField}
                          label="Confirm Password"
                          type={state.showPassword.confirmPassword ? 'text' : 'password'}
                          name="confirmPassword"
                          onChange={event => {
                            dispatch({
                              type: HANDLE_FIELD_CHANGE,
                              fieldType: event.target.type,
                              value: event.target.value,
                              name: 'confirmPassword',
                            });
                            validateForm();
                          }
                          }
                          variant="outlined"
                          inputProps={{ minLength: 8 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={event => dispatch({
                                    type: SHOW_PASSWORD,
                                    name: 'confirmPassword'
                                  })}
                                >
                                  {state.showPassword.confirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {showCPasswordError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.confirmPassword[0]}
                          </Typography>
                        )}
                      </div>
                      {isLoading ? (
                        <CircularProgress className={classes.progress} />
                      ) : (
                        <Button
                          className={classes.resetPasswordButton}
                          color="primary"
                          disabled={!isValid}
                          onClick={event => handleResetPassword()}
                          size="large"
                          variant="contained"
                        >
                          Reset Password
                        </Button>
                      )}
                    </div>
                  )}
                  {submitError && (
                    <Typography className={classes.fieldError} variant="body2">
                      {submitError}
                    </Typography>
                  )}
                  {submitSuccess && (
                    <Typography className={classes.submitSuccess} variant="body2">
                      {submitSuccess}
                    </Typography>
                  )}
                  <Typography className={classes.resendPassword} variant="body1">
                    Resend password reset link?{" "}
                    <Link className={classes.resendPasswordUrl} to="/password/forgot">
                      Back to reset link
                    </Link>
                  </Typography>
                </form>
              </div>
            </div>
          </Grid>
          <Grid item lg={3}></Grid>
        </Grid>
      </div>
    </DocumentTitle>
  );
}

ResetPassword.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles)
)(ResetPassword);
