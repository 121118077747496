import React from "react";

import classNames from "classnames";
import styles from "./styles";
import { withSnackbar } from "notistack";
import compose from "recompose/compose";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Button,
  Grid,
  Slide
} from "@material-ui/core";

const DeletePropertyModal = props => {
  const {
    classes,
    deleteModalOpen,
    closeDeleteModal,
    handleDeleteModal
  } = props;
  const { address } = props.details;

  return (
    <Dialog
      open={deleteModalOpen}
      scroll="paper"
      aria-labelledby="delete-property-modal"
      className={classes.deletePropertyModal}
      maxWidth={"lg"}
      fullWidth={true}
    >
      <DialogTitle>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className="area-map-title"
        >
          <Grid item onClick={closeDeleteModal}>
            <Button variant="outlined" color="inherit" size="small">
              Close
            </Button>
          </Grid>
          {/* <Grid item justify="center" alignItems="center">
          <Typography variant="h4">City: {originalCityName}</Typography>
        </Grid> */}
          <Grid item className="modal-title-container">
            <p>City: </p>
          </Grid>
          <Grid item></Grid>
        </Grid>
        <Grid item></Grid>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container>
          <Grid item lg={3} sm={3}>
            area
          </Grid>
          <Grid
            item
            lg={9}
            sm={9}
            justify="center"
            alignItems="flex-start"
            style={{ display: "flex" }}
          >
            <Typography
              variant="subtitle2"
              component="div"
              align="center"
              color="primary"
            >
              Content
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

DeletePropertyModal.propTypes = {
  classes: PropTypes.object.isRequired
};
export default compose(withSnackbar, withStyles(styles))(DeletePropertyModal);
