import React, { Component } from "react";
import SellerGraph from "./SellerGraph";
import { propertyUtil } from "views/Pma/PropertyUtil";

class SellerGraphContainer extends Component {
  render() {
    const { yearEnd } = this.props;
    const {
      roundSalePrice,
      calculateYESellersAdvantages,
      calculateThreeYEGraphs,
      useAreaOrCityYEGraph,
      calculateDiff,
    } = propertyUtil;
    const {
      graphAverages: graphAveragesArea,
      marketTrends: marketTrendsArea,
      marketTrendsPercentage: marketTrendsAreaPercentage,
    } = yearEnd.pmaYearEndRunAreaDTO;
    const {
      graphAverages: graphAveragesCity,
      marketTrends: marketTrendsCity,
      marketTrendsPercentage: marketTrendsCityPercentage,
    } = yearEnd.pmaYearEndCityDTO;
    // console.log('yearEnd.pmaYearEndCityDTO', yearEnd.pmaYearEndCityDTO);
    let percentageTrending = marketTrendsAreaPercentage.filter(
      (obj) => obj.TrendPercentageName === "1 Year Trends Percentage"
    );
    let pastYearObjArea = marketTrendsArea.filter(
      (obj) => obj.TrendName === "Past 1-Year Trending"
    );
    let currentYearObjArea = marketTrendsArea.filter(
      (obj) => obj.TrendName === "1-Year Trending"
    );

    let percentageTrendingCity = marketTrendsCityPercentage.filter(
      (obj) => obj.TrendPercentageName === "1 Year Trends Percentage"
    );
    let pastYearObjCity = marketTrendsCity.filter(
      (obj) => obj.TrendName === "Past 1-Year Trending"
    );

    let currentYearObjCity = marketTrendsCity.filter(
      (obj) => obj.TrendName === "1-Year Trending"
    );
    // console.log('currentYearObjCity', currentYearObjCity);
    const calculateSellersAdvantages = calculateYESellersAdvantages(
      ...pastYearObjArea,
      ...currentYearObjArea,
      ...pastYearObjCity,
      ...currentYearObjCity
    );

    let determineCityOrArea = useAreaOrCityYEGraph(calculateSellersAdvantages);
    let listToShow;
    if (determineCityOrArea) {
      listToShow = calculateThreeYEGraphs(
        determineCityOrArea,
        calculateSellersAdvantages
      );
    }

    let dataObj = {
      //SALE PRICE
      currentYearSalePriceAvg: null,
      pastYearSalePriceAvg: null,
      salePriceGraphValues: null,
      salePriceFormattedValues: null,
      salePriceTrendingPercPoint: null,
      salePriceDescription: null,
      //DAYS TO SELL
      currentYearDaysToSellAvg: null,
      pastYearDaysToSellAvg: null,
      daysToSellGraphValues: null,
      daysToSellFormattedValues: null,
      daysToSellTrendingPercPoint: null,
      daysToSellDescription: null,
      //RECEIVED OF ASKING
      currentYearReceivedOfAskingAvg: null,
      pastYearReceivedOfAskingAvg: null,
      receivedOfAskingGraphValues: null,
      receivedOfAskingFormattedValues: null,
      receivedOfAskingTrendingPercPoint: null,
      receivedOfAskingDescription: null,

      //UNITS SOLD
      currentYearUnitsSold: null,
      pastYearUnitsSold: null,
      unitsSoldGraphValues: null,
      unitsSoldFormattedValues: null,
      unitsSoldTrendingPercPoint: null,
      unitsSoldDescription: null,
    };
    if (determineCityOrArea === "area") {
      //SALE PRICE AREA
      dataObj.currentYearSalePriceAvg = roundSalePrice(
        currentYearObjArea[0].AvgSalePrice
      );
      dataObj.pastYearSalePriceAvg = roundSalePrice(
        pastYearObjArea[0].AvgSalePrice
      );
      dataObj.salePriceGraphValues = graphAveragesArea
        .slice(1)
        .map((x) => x.AverageSalePrice);
      dataObj.salePriceFormattedValues = graphAveragesArea.slice(1).map((x) => {
        if (x.AverageSalePrice === "0") {
          return "No Sales";
        } else {
          return roundSalePrice(x.AverageSalePrice);
        }
      });
      dataObj.salePriceTrendingPercPoint = calculateDiff(
        currentYearObjArea[0].AvgSalePrice,
        pastYearObjArea[0].AvgSalePrice
      );
      // console.log('dataObj', dataObj.salePriceTrendingPercPoint);
      if (dataObj.salePriceTrendingPercPoint >= 1) {
        dataObj.salePriceDescription = "Higher Sale Prices";
      } else {
        dataObj.salePriceDescription = "Sale Prices";
      }

      // DAYS TO SELL AREA
      dataObj.currentYearDaysToSellAvg = currentYearObjArea[0].AvgDaysOnMarket;
      dataObj.pastYearDaysToSellAvg = pastYearObjArea[0].AvgDaysOnMarket;
      dataObj.daysToSellGraphValues = graphAveragesArea
        .slice(1)
        .map((x) => x.AverageDaysOnMarket);
      dataObj.daysToSellFormattedValues = graphAveragesArea
        .slice(1)
        .map((x) => {
          if (x.AverageDaysOnMarket === "0" && x.AverageSalePrice === "0") {
            return "No Sales";
          } else {
            return x.AverageDaysOnMarket;
          }
        });

      dataObj.daysToSellTrendingPercPoint = Math.round(
        percentageTrending[0].PercentageAvgDaysOnMarket
      );
      if (dataObj.daysToSellTrendingPercPoint <= 1) {
        dataObj.daysToSellDescription = `${
          pastYearObjArea[0].AvgDaysOnMarket -
          currentYearObjArea[0].AvgDaysOnMarket
        } Days Faster`;
      } else {
        dataObj.daysToSellDescription = "Average Days To Sell";
      }

      //RECEIVED OF ASKING AREA
      dataObj.currentYearReceivedOfAskingAvg = `${Math.round(
        currentYearObjArea[0].AvgPercentOfReceiving
      )}%`;
      dataObj.pastYearReceivedOfAskingAvg = `${Math.round(
        pastYearObjArea[0].AvgPercentOfReceiving
      )}%`;
      dataObj.receivedOfAskingGraphValues = graphAveragesArea
        .slice(1)
        .map((x) => Math.round(x.AveragePercentOfReceiving));
      dataObj.receivedOfAskingFormattedValues = graphAveragesArea
        .slice(1)
        .map((x) => {
          if (x.AveragePercentOfReceiving === "0") {
            return "No Sales";
          } else {
            return `${Math.round(x.AveragePercentOfReceiving)}%`;
          }
        });
      dataObj.receivedOfAskingTrendingPercPoint =
        Math.round(currentYearObjArea[0].AvgPercentOfReceiving) -
        Math.round(pastYearObjArea[0].AvgPercentOfReceiving);
      if (dataObj.receivedOfAskingTrendingPercPoint >= 1) {
        dataObj.receivedOfAskingDescription = "Higher Offers";
      } else {
        dataObj.receivedOfAskingDescription = "% of Asking Price";
      }

      //UNITS SOLD AREA
      dataObj.currentYearUnitsSold = currentYearObjArea[0].HomesSold;
      dataObj.pastYearUnitsSold = pastYearObjArea[0].HomesSold;
      dataObj.unitsSoldGraphValues = graphAveragesArea
        .slice(1)
        .map((x) => x.NumberOfListings);
      dataObj.unitsSoldFormattedValues = graphAveragesArea.slice(1).map((x) => {
        if (x.NumberOfListings === "0") {
          return "No Sales";
        } else {
          return x.NumberOfListings;
        }
      });
      let homesSoldDiff = calculateDiff(
        currentYearObjArea[0].HomesSold,
        pastYearObjArea[0].HomesSold
      );
      dataObj.unitsSoldTrendingPercPoint = homesSoldDiff;
      if (homesSoldDiff >= 5) {
        dataObj.unitsSoldDescription = "More Homes Sold";
      } else {
        dataObj.unitsSoldDescription = "Homes Sold";
      }
    } else {
      //FOR CITY

      //SALE PRICE CITY
      dataObj.currentYearSalePriceAvg = roundSalePrice(
        currentYearObjCity[0].AvgSalePrice
      );
      dataObj.pastYearSalePriceAvg = roundSalePrice(
        pastYearObjCity[0].AvgSalePrice
      );
      dataObj.salePriceGraphValues = graphAveragesCity
        .slice(1)
        .map((x) => x.AverageSalePrice);
      dataObj.salePriceFormattedValues = graphAveragesCity.slice(1).map((x) => {
        if (x.AverageSalePrice === "0") {
          return "No Sales";
        } else {
          return roundSalePrice(x.AverageSalePrice);
        }
      });
      dataObj.salePriceTrendingPercPoint = calculateDiff(
        currentYearObjCity[0].AvgSalePrice,
        pastYearObjCity[0].AvgSalePrice
      );
      if (dataObj.salePriceTrendingPercPoint >= 1) {
        dataObj.salePriceDescription = "Higher Sale Prices";
      } else {
        dataObj.salePriceDescription = "Lower Sale Prices";
      }

      // DAYS TO SELL city
      dataObj.currentYearDaysToSellAvg = currentYearObjCity[0].AvgDaysOnMarket;
      dataObj.pastYearDaysToSellAvg = pastYearObjCity[0].AvgDaysOnMarket;
      dataObj.daysToSellGraphValues = graphAveragesCity
        .slice(1)
        .map((x) => x.AverageDaysOnMarket);
      dataObj.daysToSellFormattedValues = graphAveragesCity
        .slice(1)
        .map((x) => {
          if (x.AverageDaysOnMarket === "0" && x.AverageSalePrice === "0") {
            return "No Sales";
          } else {
            return x.AverageDaysOnMarket;
          }
        });
      dataObj.daysToSellTrendingPercPoint = Math.round(
        percentageTrendingCity[0].PercentageAvgDaysOnMarket
      );

      if (dataObj.daysToSellTrendingPercPoint <= 1) {
        let daysToSellDiffCity =
          pastYearObjCity[0].AvgDaysOnMarket -
          currentYearObjCity[0].AvgDaysOnMarket;
        dataObj.daysToSellDescription = `${daysToSellDiffCity} ${
          Number(daysToSellDiffCity) === 1 ? "Day" : "Days"
        } Faster`;
      } else {
        dataObj.daysToSellDescription = "Days To Sell";
      }

      //RECEIVED OF ASKING CITY
      dataObj.currentYearReceivedOfAskingAvg = `${Math.round(
        currentYearObjCity[0].AvgPercentOfReceiving
      )}%`;
      dataObj.pastYearReceivedOfAskingAvg = `${Math.round(
        pastYearObjCity[0].AvgPercentOfReceiving
      )}%`;
      dataObj.receivedOfAskingGraphValues = graphAveragesCity
        .slice(1)
        .map((x) => Math.round(x.AveragePercentOfReceiving));
      dataObj.receivedOfAskingFormattedValues = graphAveragesCity
        .slice(1)
        .map((x) => {
          if (x.AveragePercentOfReceiving === "0") {
            return "No Sales";
          } else {
            return `${Math.round(x.AveragePercentOfReceiving)}%`;
          }
        });
      dataObj.receivedOfAskingTrendingPercPoint =
        Math.round(currentYearObjCity[0].AvgPercentOfReceiving) -
        Math.round(pastYearObjCity[0].AvgPercentOfReceiving);

      if (dataObj.receivedOfAskingTrendingPercPoint >= 1) {
        dataObj.receivedOfAskingDescription = "Higher Offers";
      } else {
        dataObj.receivedOfAskingDescription = "% of Asking";
      }

      //UNITS SOLD
      //UNITS SOLD CITY
      dataObj.currentYearUnitsSold = currentYearObjCity[0].HomesSold;
      dataObj.pastYearUnitsSold = pastYearObjCity[0].HomesSold;
      dataObj.unitsSoldGraphValues = graphAveragesCity
        .slice(1)
        .map((x) => x.NumberOfListings);
      dataObj.unitsSoldFormattedValues = graphAveragesCity.slice(1).map((x) => {
        if (x.NumberOfListings === "0") {
          return "No Sales";
        } else {
          return x.NumberOfListings;
        }
      });
      let homesSoldDiff = calculateDiff(
        currentYearObjCity[0].HomesSold,
        pastYearObjCity[0].HomesSold
      );
      dataObj.unitsSoldTrendingPercPoint = homesSoldDiff;
      if (homesSoldDiff >= 5) {
        dataObj.unitsSoldDescription = "More Homes Sold";
      } else {
        dataObj.unitsSoldDescription = "Homes Sold";
      }
    }
    let colorList = [
      "rgba(75,132,174,1)",
      "rgba(137,208,200, 1)",
      "rgba(247,206,107,1)",
    ];
    let order = ["firstBg", "secondBg", "thirdBg"];
    return (
      <div className="seller-graph-container">
        {listToShow.includes("AVG SALE PRICE") && (
          <SellerGraph
            category="AVERAGE SALE PRICE"
            description={dataObj.salePriceDescription}
            currentYearDataPoint={dataObj.currentYearSalePriceAvg}
            lastYearDataPoint={dataObj.pastYearSalePriceAvg}
            trendingPercPoint={dataObj.salePriceTrendingPercPoint}
            dataPoints={dataObj.salePriceGraphValues}
            formattedPoints={dataObj.salePriceFormattedValues}
            bgColor={colorList[listToShow.indexOf("AVG SALE PRICE")]}
            colorOrder={order[listToShow.indexOf("AVG SALE PRICE")]}
            graphPosition={listToShow.indexOf("AVG SALE PRICE") + 1}
          />
        )}
        {listToShow.includes("DAYS TO SELL") && (
          <SellerGraph
            category="DAYS TO SELL"
            description={dataObj.daysToSellDescription}
            currentYearDataPoint={dataObj.currentYearDaysToSellAvg}
            lastYearDataPoint={dataObj.pastYearDaysToSellAvg}
            trendingPercPoint={dataObj.daysToSellTrendingPercPoint}
            dataPoints={dataObj.daysToSellGraphValues}
            formattedPoints={dataObj.daysToSellFormattedValues}
            bgColor={colorList[listToShow.indexOf("DAYS TO SELL")]}
            colorOrder={order[listToShow.indexOf("DAYS TO SELL")]}
            graphPosition={listToShow.indexOf("DAYS TO SELL") + 1}
          />
        )}

        {listToShow.includes("% RECEIVED OF ASKING") && (
          <SellerGraph
            category="AVERAGE % RECEIVED OF ASKING"
            description={dataObj.receivedOfAskingDescription}
            currentYearDataPoint={dataObj.currentYearReceivedOfAskingAvg}
            lastYearDataPoint={dataObj.pastYearReceivedOfAskingAvg}
            trendingPercPoint={dataObj.receivedOfAskingTrendingPercPoint}
            dataPoints={dataObj.receivedOfAskingGraphValues}
            formattedPoints={dataObj.receivedOfAskingFormattedValues}
            bgColor={colorList[listToShow.indexOf("% RECEIVED OF ASKING")]}
            colorOrder={order[listToShow.indexOf("% RECEIVED OF ASKING")]}
            graphPosition={listToShow.indexOf("% RECEIVED OF ASKING") + 1}
          />
        )}
        {listToShow.includes("UNITS SOLD") && (
          <SellerGraph
            category="UNITS SOLD"
            description={dataObj.unitsSoldDescription}
            currentYearDataPoint={dataObj.currentYearUnitsSold}
            lastYearDataPoint={dataObj.pastYearUnitsSold}
            trendingPercPoint={dataObj.unitsSoldTrendingPercPoint}
            dataPoints={dataObj.unitsSoldGraphValues}
            formattedPoints={dataObj.unitsSoldFormattedValues}
            bgColor={colorList[listToShow.indexOf("UNITS SOLD")]}
            colorOrder={order[listToShow.indexOf("UNITS SOLD")]}
            graphPosition={listToShow.indexOf("UNITS SOLD") + 1}
          />
        )}
      </div>
    );
  }
}

export default SellerGraphContainer;
