import React, { Component } from "react";
import "./customStyles.scss";
import "./customPrintStyles.scss";

class BackToSchool extends Component {
  render() {
    console.log("inside quadrant");
    return (
      <>
        <div className="johnsons-back-to-school">
          <div className="johnsons-back-to-school-body">
            <div className="johnsons-back-to-school-body-title-section">
              <div className="johnsons-back-to-school-body-title">
                <p>Celebrating Back to School!</p>
              </div>

              <div className="johnsons-back-to-school-subtitle-text">
                WE BELIEVE IN EDUCATION
              </div>
            </div>
            <div className="section-container">
              <div className="section-row">
                <div className="johnsons-back-to-school-section">
                  <div className="johnsons-back-to-school-section-title">
                    EDUCATION
                  </div>
                  <div className="johnsons-back-to-school-section-text first-section">
                    <p>Dianne graduated from UCSB with a</p>
                    <p>degree in Business Economics.</p>

                    <p className="extra-mt">Brianna graduated from Cal Poly</p>
                    <p>San Luis Obispo with a degree in</p>
                    <p>Graphic Communications.</p>
                  </div>
                </div>
                <div className="johnsons-back-to-school-section">
                  <div className="johnsons-back-to-school-section-title">
                    CERTIFICATIONS
                  </div>
                  <div className="johnsons-back-to-school-section-text second-section">
                    <p>Graduate Realtors Institute</p>
                    <p>Seniors Real Estate Specialist</p>
                    <p>NAR GREEN Designation</p>
                    <p>Accredited Buyer’s Representative</p>
                    <p>Seller’s Representative Specialist</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="johnsons-back-to-school-logo-container">
            <img
              src="https://pma-coverphotos.s3.amazonaws.com/johnsons-quadrant-tile.png"
              alt="Johnsons Icons"
            />
          </div>
        </div>
        <div className="johnsons-teacher-fund">
          <div className="teacher-fund-img-container">
            <img
              src="https://pma-coverphotos.s3.amazonaws.com/TeacherFund.png"
              className="teacher-fund-img"
            />
          </div>
          <div className="teacher-fund-title-container">
            <div className="teacher-fund-title">SCHOOL SUPPLIES DRIVE</div>
          </div>
          <div className="teacher-fund-body">
            Each year Brianna chairs the Teacher’s Fund School Supplies Drive
            benefitting our local classrooms. Please join us this year as we
            raise funds to help our local teacher’s purchase the supplies they
            need to equip their classrooms and help our communities’ students
            thrive.
          </div>
          <div className="teacher-wishlist">
            <p>Teacher’s Wishlist available at: TFSUPPLIESDRIVE.COM</p>
          </div>
          <div className="teacher-community">
            <p>
              Together with our community, the Teacher’s Fund has donated over
              $1.9 million to our local schools.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default BackToSchool;
