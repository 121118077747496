import React, { Component } from "react";
import BackPagePhoto from "./photo-101722_NOV_PMA_Back_grassesCROP.jpeg";

class ExpertsTrends extends Component {
  render() {
    const { printVersion } = this.props;
    return (
      <div className="experts-trends-trends-container">
        <div className="experts-trends-main-title">
          <div className="experts-trends-main-title-text">Experts</div>
        </div>
        <div className="experts-trends-subtitle-container">
          <div className="experts-trends-main-subtitle">
            weigh in on where the market is going
          </div>
        </div>
        <div className="experts-trends-image-text-container">
          <p>If you're considering selling your home soon,</p>
          <p>you haven't missed your window.</p>
          <p>
            <strong>Let's connect!</strong>
          </p>
        </div>
        <div className="experts-trends-number-section-column">
          {[1, 2, 3].map((x) => (
            <div key={x} className={`number-section section num-${x}`}>
              0{x}
            </div>
          ))}
        </div>
        <div className="experts-trends-image-container">
          <img
            className="experts-trends-image"
            // src="https://pma-coverphotos.s3.amazonaws.com/101722_NOV_PMA_Back_grassesCROP.jpg"
            src={BackPagePhoto}
          />
        </div>
        <div className="experts-trends-content-section">
          <div className="experts-trends-text-container">
            <div className="experts-trends-section">
              <div className="experts-trends-section-title">
                <p>RECORD LEVELS OF HOME</p>
                <p>EQUITY</p>
              </div>
              <div className="experts-trends-section-text">
                <p>"For homeowners deciding whether to make a</p>
                <p>move this year, remember that listing prices –</p>
                <p>while lower than a few months ago – remain</p>
                <p>higher than in prior years, so you’re still likely to</p>
                <p>find opportunities to cash-in on record-high levels</p>
                <p>of equity, particularly if you’ve owned your home</p>
                <p>for a longer period of time.”</p>
              </div>
              <div className="experts-trends-section-footer">
                <span className="experts-trends-author">
                  – Danielle Hale Chief Economist,
                </span>
                <span className="experts-trends-resource ml"> Realtor.com</span>
              </div>
            </div>

            <div className="experts-trends-section">
              <div className="experts-trends-section-title">
                <p>PRICES TO HOLD</p>
                <p>STEADY</p>
              </div>
              <div className="experts-trends-section-text">
                <p>“While mortgage rates are expected to continue</p>
                <p>to drift higher over the coming months...annual</p>
                <p>house price declines are not expected, given</p>
                <p>the ongoing supply-demand imbalance and</p>
                <p>continued strength in the labor market.”</p>
              </div>
              <div className="experts-trends-section-footer">
                <span className="experts-trends-author">
                  – Mark Fleming, Chief Economist,
                </span>
                <span className="experts-trends-resource ml">
                  First American
                </span>
              </div>
            </div>

            <div className="experts-trends-section">
              <div className="experts-trends-section-title">
                <p>SUPPLY OF HOMES TO REMAIN</p> <p>TIGHT</p>
              </div>
              <div className="experts-trends-section-text">
                <p>"Demand for homes continues to outpace supply.</p>
                <p>Inventory may have picked up, but it isn’t enough</p>
                <p>to make home prices drop. Housing inventory will</p>
                <p>remain tight for the next couple of years, so we</p>
                <p>don’t expect a depreciation of home prices."</p>
              </div>
              <div className="experts-trends-section-footer column">
                <div>
                  <span className="experts-trends-resource">– Money.com</span>
                  <span className="experts-trends-author">
                    {" "}
                    / Nadia Evangelou
                  </span>
                  <br />
                </div>
                <div>
                  <span className="experts-trends-author">Sr. Economist,</span>
                  <span className="experts-trends-resource ml">
                    National Association of Realtors
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExpertsTrends;
