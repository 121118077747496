
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { de } from 'date-fns/locale';
import classNames from 'classnames';


const useStyles = makeStyles({

    starContainer: {

        width: '30px',
        height: '30px', 
        textAlign: 'center',
        verticalAlign: 'middle',
        cursor: 'pointer',
    },

    starImage: {
        width: '100%', 
        height: '100%',
    },

});


const MailProStar = ({ defaultFilled = false, disableSelection = true, starContainerStyle = {} }) => {
    const styleClasses = useStyles();
    const [clicked, setClicked] = useState(defaultFilled);

    const handleStarClick = () => {
        if (disableSelection) return;
        setClicked(!clicked);
    };

    return (
        <span className={classNames(styleClasses.starContainer)} onClick={handleStarClick} style={starContainerStyle}>
            <img
                src={clicked ? require("./assets/star_gold_filled.png") : require("./assets/star_gold_outlined.png")}
                alt="Star Image"
                className={styleClasses.starImage}
            />
        </span>
    );
}

//define proptyps
MailProStar.propTypes = {
    defaultFilled: PropTypes.bool,
    disableSelection: PropTypes.bool,
};

export default MailProStar;