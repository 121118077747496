import React, { Component } from "react";
import FirstLeftHeader from "views/Pma/PmaEditor/CommonEditor/FirstLeftHeader";
import HOAddress from "views/Pma/PmaEditor/CommonEditor/HOAddress";
import QVFourLogoAndAvatar from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourLogoAndAvatar";
import "./customStyles.scss";
import "./customPrintStyles.scss";
import QVFourTestimonial from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourTestimonial";
import BeachCliff from "views/Pma/PmaEditor/CustomAgentPages/CustomImages/SB_SOS_Backcover.jpg";
import SeniorRelocation from "views/Pma/PmaEditor/CustomAgentPages/SeniorRelocation";
import SOSLogo from "views/Pma/PmaEditor/CustomAgentPages/CustomImages/sb-sos-logo.png";
import SOSQRCode from "views/Pma/PmaEditor/CustomAgentPages/sos_qr_code.png";
import PrintV2DebSamuel from "./PrintV2DebSamuel";

class RGB extends Component {
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      currentArea,
      demoAccountHOName,
      coverPageDetails,
    } = this.props;
    return (
      <div className={`${version}-first-left`}>
        <FirstLeftHeader
          agentData={agentData}
          printVersion={printVersion}
          currentArea={currentArea}
        />
        <QVFourLogoAndAvatar
          agentData={agentData}
          printVersion={printVersion}
        />
        {hoData &&
          hoData.changeDetails &&
          Object.keys(hoData.changeDetails).length > 1 && (
            <HOAddress
              hoData={hoData}
              version={version}
              agentData={agentData}
              currentArea={currentArea}
              printVersion={printVersion}
              mailInfoClass="mail-info-container-print"
              typeOfVersion={version}
              coverPageDetails={coverPageDetails}
            />
          )}
        <div className="senior-relocation">
          <img
            src="https://d2onbxnrte2nim.cloudfront.net/102422_SB_SOS_CMYK_LogoTesting-01.png"
            style={{ height: "auto", width: "100%" }}
          />
        </div>
      </div>
    );
  }
}

export default RGB;
