import React, { Component } from "react";

class QuickToMarketTrends extends Component {
  render() {
    const { isMemberOfTeam } = this.props.agentData;
    return (
      <div className="quick-to-market-trends">
        <div className="quick-to-market-bg">
          <img
            src="https://pma-coverphotos.s3.amazonaws.com/april-2022-backpage-leaf.jpg"
            alt="Leaf"
          />
        </div>
        <div className="quick-to-market-trends-title-container">
          <div className="quick-to-market-trends-title">Quick to Market</div>
          <div className="quick-to-market-subtitle">
            {isMemberOfTeam ? "Our team is" : "I am"} ready to prepare your home
            for sale in record time
          </div>
        </div>
        <div className="quick-to-market-trends-body">
          <div className="quick-to-market-trends-section-container">
            <div className="quick-to-market-trends-section shorter">
              <div className="quick-to-market-trends-section-title">
                Shorter Prep Time
              </div>
              <div className="quick-to-market-trends-section-text">
                <p>Over the last year, nearly 50% of</p>
                <p>home sellers took an average of only</p>
                <p>1 month to prepare their properties</p>
                <p>for sale, with 20% completing it in</p>
                <p>less than two weeks.</p>
              </div>
              <div className="quick-to-market-trends-author">
                Realtor.com Spring 2022 Survey
              </div>
            </div>
          </div>
          <div className="quick-to-market-trends-section-container">
            <div className="quick-to-market-trends-section shorter">
              <div className="quick-to-market-trends-section-title">
                Less up Front
              </div>
              <div className="quick-to-market-trends-section-text">
                <p>With this hyper-competitive</p>
                <p>market, we are finding that many</p>
                <p>homeowners are focusing on less</p>
                <p>costly and time consuming projects</p>
                <p>such as light landscaping updates,</p>
                <p>interior painting, and minor</p>
                <p>cosmetic tweaks.</p>
              </div>
              <div className="quick-to-market-trends-author">
                Realtor.com Spring 2022 Survey
              </div>
            </div>
          </div>
          <div className="quick-to-market-trends-section-container">
            <div className="quick-to-market-trends-section short">
              <div className="quick-to-market-trends-section-title">
                Selling "As Is"
              </div>
              <div className="quick-to-market-trends-section-text">
                <p>With bidding wars being the norm,</p>
                <p>buyers frequently forego traditional</p>
                <p>requests for repairs in order to</p>
                <p>stand out. Many of today's buyers</p>
                <p>are taking it a step further and</p>
                <p>waiving contingencies, like home</p>
                <p>inspections and appraisals.</p>
              </div>
              <div className="quick-to-market-trends-author">
                Realtor.com Spring 2022 Survey
              </div>
            </div>
          </div>
          <div className="quick-to-market-trends-section-container">
            <div className="quick-to-market-trends-section short">
              <div className="quick-to-market-trends-section-title">
                Maximize Value
              </div>
              <div className="quick-to-market-trends-section-text">
                <p>Professional staging can be one</p>
                <p>of the most effective ways to</p>
                <p>showcase a property. Statistics</p>
                <p>confirm that staged properties</p>
                <p>often sell faster and for higher</p>
                <p>prices than those that have not</p>
                <p>been staged.</p>
              </div>
              <div className="quick-to-market-trends-author">
                National Association of Realtors
              </div>
            </div>
          </div>
        </div>
        <div className="quick-to-market-cta">
          <p>
            Curious how quickly you can get your property on the market?{" "}
            <span>Let's connect!</span>
          </p>
        </div>
      </div>
    );
  }
}

export default QuickToMarketTrends;
