import React from 'react';
import { connect } from "react-redux";
import styles from "./styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import { pmaActions } from "_store/_actions";
import { MailProDialog } from '../MailProDialog/MailProDialog';
import { MailProDialogHeader } from '../MailProDialog/MailProDialogHeader';
import { MailProDialogBody } from '../MailProDialog/MailProDialogBody';
import MailProButton from '../UI/MailProButton';
import { TextField } from '@material-ui/core';
import classNames from 'classnames';

const AddGroup = ({ mode = 'add', classes, children }) => {
    const [open, setOpen] = React.useState(false);
    const [groupName, setCurrentGroup] = React.useState('');

    const openDialog = () => {
        setOpen(true);
    }

    const closeDialog = () => {
        setOpen(false);
    }

    const updateGroup = (newGroupName) => { }
    return <div className={classes.root}>
        {children ? <div onClick={openDialog}>{children}</div> : <div></div>}
        {open && <MailProDialog open={open}>
            <MailProDialogHeader>
                <div className={classNames(classes.displayRow, classes.addGroupTitle)} style={{ gap: 0 }}>
                    <div>Edit Group Name</div>
                    <div style={{ fontSize: '14px' }}>MAIN ST. HOMES</div>

                </div>
            </MailProDialogHeader>
            <MailProDialogBody>
                <div>
                    <div style={{ textAlign: 'center' }}>
                        <span className={classNames(classes.italic, classes.currentMailProColor)} style={{ fontSize: '13px' }}>Current Group Name:&nbsp;</span>
                        <span className={classes.currentGroupName}>MAIN ST. HOMES</span>
                    </div>
                    <div className={classes.newGroupNameBoxMain}>
                        <div>New Group Name:</div>
                        <div className={classes.newGroupNameBox}>
                            <TextField
                                value={groupName}
                                placeholder={"Type here:"}
                                onChange={(event) => updateGroup(event.target.value)}
                                //label={"New Group Name"}
                                fullWidth={true}
                                multiline={true}
                                rows={1}
                                rowsMax={2}
                                classes={{ root: classes.noteTextarea, }}
                                inputProps={{ style: { fontSize: '14px', color: 'black' } }}
                            //error={isPSError}
                            // InputProps={{
                            //     startAdornment: (
                            //         <InputAdornment position="start" style={{ color: "#000" }}>
                            //             <span style={{ fontWeight: 500 }}>P.S.</span>
                            //         </InputAdornment>
                            //     ),
                            // }}
                            />
                            <div className={classes.editorCharacterLimitContainer}>
                                <div></div>
                                <div>0/25</div>
                                <div>25 Characters Max</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.footer}>
                    <div>
                        <MailProButton
                            key={"mp_new_add_group_cance;"}
                            variant="outlined"
                            label={"CANCEL"}
                            customStyle={{ fontSize: "12px", fontWeight: 500   }}
                            onClick={closeDialog}
                        />
                    </div>
                    <div>
                        <MailProButton
                            key={"mp_new_update_group"}
                            variant="filled"
                            label={"UPDATE"}
                            customStyle={{ fontSize: "12px", fontWeight: 500  }}
                            onClick={() => { }}
                        />
                    </div>
                    <div></div>


                </div>
            </MailProDialogBody>
        </MailProDialog>}
    </div>
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles), withSnackbar)(AddGroup));
