import xAxios from "axios";
import { handleLogoutFn } from "common/helpers";

const axiosConfig = {
  baseURL: ''
};

if (process.env.NODE_ENV === 'production'){
  //|| process.env.NODE_ENV === 'development'
  axiosConfig.baseURL = "https://apiv2i.listgen.com/";
}


const axios = xAxios.create(axiosConfig);

/**Intercept the axios */
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      //place your reentry code
      //send mode to session expired abruptly
      handleLogoutFn(6);
    }
    return error;
  }
);

/**
 * @name Default Axios
 * @description: This function is used to set default parameters for axios
 */
export default axios;
