import React from 'react';
import { connect } from "react-redux";
import styles from "./styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import { pmaActions } from "_store/_actions";
import { MailProDialog } from '../MailProDialog/MailProDialog';
import { MailProDialogHeader } from '../MailProDialog/MailProDialogHeader';
import { MailProDialogBody } from '../MailProDialog/MailProDialogBody';
import MailProButton from '../UI/MailProButton';
import { Checkbox, Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import classNames from 'classnames';


const ListOfHomeownersInGroup = ({ classes, children }) => {
    const [open, setOpen] = React.useState(false);

    const openDialog = () => {
        setOpen(true);
    }

    const closeDialog = () => {
        setOpen(false);
    }

    const groupData = [
        {
            id: 1,
            hoName: 'Nancy Smith',
            siteAddress: '1234 Maine St, Monterey CA 93920',
            mailAddress: '1400 Oak Meadows, Ft Worth TX 19420'
        },
        {
            id: 2,
            hoName: 'Nancy Doe',
            siteAddress: '1234 Maine St, Monterey CA 93920',
            mailAddress: '1400 Oak Meadows, Ft Worth TX 19420'
        },
        {
            id: 3,
            hoName: 'Nancy Fancy',
            siteAddress: '1234 Maine St, Monterey CA 93920',
            mailAddress: '1400 Oak Meadows, Ft Worth TX 19420'
        }
    ]


    return <div className={classes.root}>
        {children ? <div onClick={openDialog}>{children}</div> : <div></div>}
        {open &&
            <MailProDialog open={open} classes={{paper: classes.listOfHosDialog}} width={'750px'}>
                <MailProDialogHeader>
                    <div className={classNames(classes.displayRow, classes.addHoTitle)} style={{ gap: 0 }}>
                        <div style={{ fontWeight: 700 }}>List of Homeowners in this Group</div>
                        <div style={{ fontWeight: 800, marginTop: '8px' }}>MAIN ST. HOMES</div>

                    </div>
                </MailProDialogHeader>
                <MailProDialogBody>
                    <div>
                        <div className={classNames(classes.dialogSubtitle, classes.currentMailProColor)}>
                            25 Homeowners in this group:
                        </div>
                        <div className={classes.listOfHosContainer}>
                            <Table>
                                <TableBody className={classNames(classes.listHoTableBody, classes.listHoRow)}>
                                    {groupData.map((data, key) => {
                                        return <TableRow>
                                            <TableCell>{key + 1}</TableCell>
                                            <TableCell>
                                                <div className={classNames(classes.displayRow)}>
                                                    <div style={{fontWeight: '700'}} className={classes.listHOAgent}>{data.hoName}</div>
                                                    <div>
                                                        <div className={classes.removeButton}>REMOVE</div>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                            <div className={classNames(classes.displayRow, classes.siteMailAddress)}>
                                                <div>Site: {data.siteAddress}</div>
                                                <div>Mail: {data.mailAddress}</div>
                                            </div>
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                    <div className={classes.footer}>
                        <div>
                            <MailProButton
                                key={"mp_new_add_group_cance;"}
                                variant="outlined"
                                label={"CANCEL"}
                                customStyle={{ fontSize: "12px", fontWeight: 500 }}
                                onClick={closeDialog}
                            />
                        </div>
                        <div>
                            <MailProButton
                                key={"mp_new_update_group"}
                                variant="filled"
                                label={"UPDATE"}
                                customStyle={{ fontSize: "12px", fontWeight: 500 }}
                                onClick={() => { }}
                            />
                        </div>
                        <div></div>


                    </div>
                </MailProDialogBody>
            </MailProDialog>}
    </div>
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles), withSnackbar)(ListOfHomeownersInGroup));
