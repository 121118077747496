import React, { Component } from "react";
const BaseURI = "http://ec2-3-217-204-102.compute-1.amazonaws.com/";

const Constants = {
  CorsURI: "https://cors-anywhere.herokuapp.com/",
  SantaCruzURI: "api.listgen.com",
  USER_AUTH_API: "/oauth/token",
  GET_ALL_USERS_API: "/login/getAllUsers",
  GET_AGENT_DASHBOARD_API: "/agent/getAgentProfile",
  GET_AGENT_DETAILS_API: "/agent/getAgentDetails",
  GET_AGENT_BASIC_DETAILS_API: "/agent/getBasicAgentDetails",
  REGISTER_AGENT_API: "/agent/registerAgent",
  UPDATE_MAILPRO_AFTER_REGISTER_API:
    "/admin/updateMailProForBlockingAndUnblocking",
  CREATE_AGENT_API: "/admin/createAgent",
  GET_ALL_AGENTS: "/admin/getAllAgents",
  SEARCH_AGENT_BY_STATUS_V1: "/admin/getAgentsForMailProInsights",
  UPDATE_AGENT_DETAILS_API: "/agent/updateAgentDetails",
  UPDATE_PROPERTY_HIGHLIGHTS:
    "/agent/updatePropertyPhotoDetailsForListgenUserArea",
  UPDATE_PROPERTY_HIGHLIGHTS_FOR_SINGLE_AREA:
    "/agent/updatePropertyPhotoDetailsForSingleListgenUserArea",
  GET_YEAR_END_STATEMENT: "/admin/getYTDInvoicesForAgent",
  PROP_HIGHLIGHTS_PHOTO_OPTIMIZATION:
    "/agent/uploads/uploadFeaturedPropertyPhotoAndOptimize",
  UPLOAD_COVER_PHOTO_IMAGE: "/admin/uploadPMACoverPhoto",
  FORGOT_PASSWORD_API: "/user/resetPasswordRequest",
  RESET_PASSWORD_VALIDATE_API: "/user/validateResetPasswordRequest",
  RESET_PASSWORD_API: "/user/resetPassword",
  UPDATE_PASSWORD_API: "/user/changePassword",
  CHANGE_EMAIL_API: "/user/changeEmail",
  AGENT_SEARCH_API: "/admin/searchAllAgentsByCounty",
  GET_OFFICE_FOR_DESIGNATED_BROKER: "/admin/getOfficeForDesignatedBroker",
  GET_AGENT_FOR_OFFICE: "/admin/getAgentsForOffice",
  VALIDATE_REGISTER_AGENT_API: "/agent/validateRegisterToken",
  ON_LOAD_REGISTER_AGENT_API: "/agent/registerAgentOnLoad",
  REGISTER_AGENT_VALIDATE_EMAIL_API: "/user/isTheEmailDuplicate",
  REGISTER_AGENT_ON_SUBMIT_API: "/agent/registerAgentOnSubmit",
  UPLAOD_AGENT_PHOTO: "/agent/uploads/uploadAgentPhoto",
  UPLOAD_AGENT_LOGO: "/agent/uploads/uploadAgentLogo",
  UPLAOD_AGENT_LOGO_2: "/agent/uploads/uploadAgentLogo2",
  UPLOAD_AGENT_SIGNATURE: "/agent/uploads/uploadAgentSignature",
  UPLOAD_AGENT_BROKERAGE_LOGO: "/agent/uploads/uploadBrokerageLogo",
  GET_AGENT_DETAILS_BY_LISTGEN_ID: "/admin/getAgentDetails",
  UPDATE_AGENT_DETAILS: "/admin/updateAgentDetails",
  GET_CITY_AREA_MAP: "/admin/getCityAreaMap",
  SUBMIT_HELP_REQUEST: "/agent/createListgenRequest",
  GET_TICKET_COMPLETE_DATA: "/admin/getTicket",
  GET_ALL_REQUESTS_DATA: "/admin/getAllListgenRequests",
  GET_ONE_SPECIFIC_REQUESTS_DATA: "/admin/getListgenRequestDetails",
  GET_ONE_SPECIFIC_REQUEST_NOTES_DATA: "/admin/getListgenRequestNotesInfo",
  UPDATE_LISTGEN_REQUEST_NOTES_DATA: "/admin/updateListgenRequestNotes",
  UPDATE_LISTGEN_REQUEST_STATUS_DATA: "/admin/updateListgenRequestStatus",
  GET_ALL_AGENT_DESIGNATIONS_DATA: "/agent/getAllListgenDesignations",
  UPLOAD_AGENT_TEAM_SIGNATURE: "/agent/uploads/uploadTeamAgentSignature",
  CREATE_AGENT_WITH_CC_INFO: "/agent/createCustomerWithCreditCardInfo",
  PMA_ESTIMATED_INVOICE_DATA: "/agent/calculateInvoicingPriceForAgent",
  GET_ALL_CITY_AREA_DATA: "/agent/getAllCityAreaMap",
  GET_ALL_CITY_AREA_DATA_FOR_COUNTY: "/admin/getAllCityAreaMapForCounty",
  GET_AGENT_ALL_CREDIT_CARDS: "/agent/getListgenUserCCInfo",
  SET_AGENT_DEFAULT_CREDIT_CARD: "/agent/setDefaultCard",
  REMOVE_AGENT_FROM_STRIPE_CREDIT_CARD_LG_DB: "/agent/removeCustomer",
  DELETE_AGENT_CREDIT_CARD_LG_DB_STRIPE: "/agent/deleteCreditCard",
  ADD_AGENT_CREDIT_CARD_INFO: "/agent/addCreditCardForAgent",
  CHARGE_AGENT_CREDIT_CARD: "/agent/chargeCustomer",
  GET_ALL_SPECIAL_PAYMENT_AGENTS: "/admin/getAllSpecialPaymentAgents",
  ADD_UPDATE_SPECIAL_PAYMENT_AGENTS: "/admin/updateSpecialPaymentInfoForAgent",
  GET_SPECIAL_PAYMENT_FOR_AN_AGENT: "/admin/getSpecialPaymentInfo",
  REMOVE_ALL_SPECIAL_PAYMENT_FOR_A_AGENT: "/admin/removeSpecialPaymentForAgent",
  LG_CONSTANT_ADD_NEW: "/admin/addLGConstant",
  LG_CONSTANT_GET_ONE: "/admin/getLGConstant", //"/admin/getLGConstantValue",
  LG_CONSTANT_GET_ALL: "/admin/getAllLGConstantsDetails",
  LG_CONSTANT_UPDATE_ONE: "/admin/updateLGConstant",
  LG_CONSTANT_DELETE_ONE: "/admin/deleteLGConstant",
  INITIALIZE_GLOBAL_PS_FOR_ALL_AGENTS: "/admin/initializeGlobalPSForAgents",
  SET_ADMIN_GLOBAL_NOTE_PS_FOR_ALL_AGENTS: "/admin/setGlobalNoteAndPSForAgents",
  UPDATE_MAILPRO_NAVBAR_INFO: "/agent/updateNavbarInfo",
  GENERATE_PRINT_PMA_URL: "/admin/generatePrintPMAURL",
  GENERATE_SINGLE_TEST_PRINT_PDF: "/admin/generateSingleTestPrintPMAPDF",
  GET_ALL_TEST_PRINT_PDF_COUNT: "/admin/allTestPrintPMAPDFsCount",
  GENERATE_TEST_PDFS_FOR_ALL_AGENTS:
    "/admin/generateTestPrintPMAPDFsForAllAgents",
  GET_CITIES_FOR_COUNTY: "/admin/getCitiesForCounty",
  GET_AREA_FOR_CITY: "/admin/getAreasForCity",
  GET_HO_NAME_MAP: "/admin/getHOANameMap",
  GET_COMPLEX_NAME_MAP: "/admin/getComplexNameMap",
  GET_ALL_HOA_NAMES_AND_COMPLEX_NAMES: "/admin/getAllHOANamesAndComplexNames",
  SAVE_HOA_NAMES_OR_COMPLEX_NAMES: "/admin/saveHOAorComplexNameMap",
  GET_AGENT_DATA_BY_AREA: "/admin/getListgenAgentDetailsForArea",
  DELETE_HOA_COMPLEX_NAME_MAPPING: "/admin/deleteHOAorComplexNameMap",
  GRAMMER_BOT_CHECK_API: "https://grammarbot.p.rapidapi.com/check",
  ADD_NEW_CLIENT_TESTIMONIALS: "/agent/addNewClientTestimonial",
  UPDATE_A_CLIENT_TESTIMONIALS: "/agent/updateClientTestimonial",
  GET_ALL_CLIENT_TESTIMONIALS: "/agent/getAllClientTestimonials",
  DELETE_A_CLIENT_TESTIMONIAL: "/agent/deleteClientTestimonial",
  ADD_UPDATE_STRENGTH_DESIGNATION: "/agent/addUpdateAgentDesignations",
  UPLOAD_DESIGNATION_LOGO_IMAGE: "/agent/uploads/uploadAgentDesignationLogo",
  UPLOAD_PROPERTY_HIGHLIGHT_PHOTO: "/agent/uploads/uploadFeaturedPropertyPhoto",
  GET_AGENT_STRENGTH_DESIGNATIONS: "/agent/getAgentDesignations",
  GET_AREA_PROP_HIGHLIGHTS: "/agent/getPropertyPhotoDetailsForListgenUserArea",
  GET_AREA_PHOTO_DETAILS: "/agent/getPropertyPhotoDetailsForListgenUserArea",
  ADD_UPDATE_ADVANTAGES: "/agent/addUpdateAgentAdvantages",
  GET_AGENT_ADVANTAGES: "/agent/getAgentAdvantages",
  GET_AGENTS_TO_BE_INVITED_FOR_COUNTY: "/admin/getAgentsToBeInvitedForCounty",
  GET_FREE_AREAS_BY_COUNTY_ID: "/agent/getCountOfRemainingAreasInCounty",
  GET_ALL_MISSION_STATEMENTS: "/agent/getAllMissionStatements",
  ADD_NEW_MISSION_STATEMENT: "/agent/addNewMissionStatement",
  UPDATE_NEW_MISSION_STATEMENT: "/agent/updateMissionStatement",
  DELETE_MISSION_STATEMENT: "/agent/deleteMissionStatement",
  SET_CURRENT_MISSION_STATEMENT: "/agent/setCurrentMissionStatement",
  UPLOAD_COVER_PHOTO_FOR_AGENTS: "/admin/setPMACoverPhoto",
  APP_DATA: {
    APP_CLIENT_ID: "ListGen",
    APP_CLIENT_SECRET: "ListgenSecret",
    APP_GRANT_TYPE: "password",
    APP_ADMIN_ROLE_ID: 1,
    APP_AGENT_ROLE_ID: 2,
    APP_COUNTY_ADMIN_ROLE_ID: 20,
    APP_STATE_ID: 1,
    APP_COUNTY_ID: 27,
    APP_RECAPTCHA_KEY: "6Lfpot4UAAAAAC7J_KrKqA9UrqEm3IytdruDCfLF",
    // GRAMMER_BOT_KEY: "620f0f0d08msh9118c73a28a51c6p17373ajsn9a93e751d306",
    // GRAMMER_BOT_HOST: "grammarbot.p.rapidapi.com",
  },
  //FORMAT IS COUNTY ADMIN LISTGEN USER ID: DEFAULT COUNTY ID/STATE ID
  COUNTY_ADMIN_DEFAULT_STATE_ID: {
    999999: 1,
  },
  COUNTY_ADMIN_DEFAULT_COUNTY_ID: {
    999999: 44,
  },
  LocalStorageBase: {
    LG_TOKEN_SALT: "v8QhrwGgu5",
    LG_ROI_TOKEN_SALT: "sfsXYCosNH",
    LG_OAUTH_TOKEN: "LG_APP_OAUTH_TOKEN",
    LG_OAUTH_TOKEN_CHECKER: "LG_APP_OAUTH_TOKEN_CHECKER",
    LG_APP_STATE: "LG_APP_STATE_DATA",
    LG_APP_SEMI_AUTH_STATE: "LG_APP_SEMI_AUTH_STATE",
    LG_APP_SEMI_AUTH_STATE_CHECKER: "LG_APP_SEMI_AUTH_STATE_CHECKER",
    LG_APP_NAVBAR_MLS_SESSION_DATA: "LG_APP_NAVBAR_MLS_SESSION_DATA",
    LG_APP_NAVBAR_MLS_SESSION_CHECKER: "LG_APP_NAVBAR_MLS_SESSION_CHECKER",
    LG_APP_CUT_OFF_DATA: "LG_APP_CUT_OFF_DATA",
    LG_APP_ACTIVE_AGENT_DATA: " LG_APP_ACTIVE_AGENT_DATA",
    LG_APP_ACTIVE_AGENT_DATA_CHECKER: "LG_APP_ACTIVE_AGENT_DATA_CHECKER",
    LG_APP_PMA_INFO_APP_DIALOG: " LG_APP_PMA_INFO_APP_DIALOG",
    LG_APP_ROI_AUTH_STATE: "LG_APP_ROI_AUTH_STATE",
    LG_APP_ROI_AUTH_STATE_CHECKER: "LG_APP_ROI_AUTH_STATE_CHECKER",
  },
  CookieStorageBase: {
    LG_RETRY_NUMBER_COOKIE: "LG_RETRY_NUMBER_COOKIE",
    LG_PMA_INFO_DIALOG_COOKIE: "LG_PMA_INFO_DIALOG_COOKIE",
    LG_DEFAULT_SELECTED_COUNTY_ID: "LG_DEFAULT_SELECTED_COUNTY_ID",
    LG_DEFAULT_SELECTED_STATE_ID: "LG_DEFAULT_SELECTED_STATE_ID",
    LG_DEFAULT_SELECTED_SCHEDULE_ID: "LG_DEFAULT_SELECTED_SCHEDULE_ID",
  },
  STATIC_TEXTS: {
    PMA_CUT_OFF: "Your new PMA will be ready to view soon",
    EDITOR_CUT_OFF: "The cut-off date for the next issue is ",
  }, // (Monterey and Santa Cruz County) and Wed, Oct 13th(Santa Barbara)
  CUT_OFF_DATES_COUNTY_WISE: {
    27: "Wednesday, Jan 19th",
    44: "Wednesday, Jan 19th",
    42: "Wednesday, Jan 19th",
    43: "Wednesday, Jan 19th",
    41: "Wednesday, Jan 19th",
  },
  PMA_CURRENT_VERSION: "q", //mq or q
  NO_AGENT_DASHBOARD_API_CALL_URLS: ["pmaurlswitcher", "printpma"],
  PMA_ADMIN: {
    MIN_STRENGTH_COUNT: 3,
    MAX_STRENGTH_COUNT: 4,
    MIN_ADVANTAGE_COUNT: 4,
    MAX_ADVANTAGE_COUNT: 5,
    MAX_HIGHLIGHT_TITLE_LENGTH: 70,
    MAX_HIGHLIGHT_SUBTITLE_LENGTH: 80,
  },
  COUNTY_ID_NAME_MAP: {
    "27": "Monterey",
    "44": "Santa Cruz",
    "42": "Santa Barbara",
    "43": "Santa Clara",
    "41": "San Mateo",
  },
  LG_DEFAULT_TEXT_SEPARATOR: "____**____"
};

export default Constants;
