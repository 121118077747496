export default theme => ({
  root: {
    width: '100%'
  },
  field: {
    //padding: theme.spacing.unit * 3
    margin: theme.spacing.unit * 2
  },
  textField: {
    width: '100%',
    maxWidth: '100%',
    //marginRight: theme.spacing.unit * 3
  },
  dropdownTopMargin: {
    marginTop: theme.spacing.unit * 1
  },
  dropdownTopMinusMargin: {
    marginTop: -theme.spacing.unit * 3
  },
  dropdownTopMinusMarginLastColumn: {
    marginTop: -theme.spacing.unit * 2
  },
  portletFooter: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 1
  },
  fieldError: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit
  },
  footerButtons: {
    position: 'relative',
    display: 'flex'
  },
  footerButtonsCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerButtonsLeftRight: {
    flex: 1
  },
  bold500: {
    fontWeight: 500
  }
});
