import React, { Component, Fragment, useState } from 'react';
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

import { withStyles, withWidth, Drawer } from '@material-ui/core';

import { Topbar, Footer } from './components';
import styles from './styles';
import Sidebar from './components/Sidebar';
import { GetStateStorage } from 'common/storage';

class Website extends Component {
  constructor(props) {
    super(props);

    const isMobile = ['xs', 'sm'].includes(props.width);//'md'

    this.state = {
      isOpen: !isMobile,
      localStateData: null
    };
    /**bind methods */
    this.handleToggleOpen = this.handleToggleOpen.bind(this);
  }

  componentDidMount() {
    let localStateData = GetStateStorage();
    this.setState({
      localStateData: localStateData
    })
  }


  handleToggleOpen = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  // handleXDrawerClose = () => {
  //   this.setState({
  //     isOpen: false
  //   });
  // };

  render() {
    const { classes, width, title, children, gradientMode, hideFooter, hideTopbar } = this.props;

    const { isOpen, localStateData } = this.state;
    const isMobile = ['xs', 'sm'].includes(width);
    const shiftTopbar = isOpen && !isMobile;
    const shiftContent = isOpen && !isMobile;

    /**handle sidebarMode also */
    let { sidebarMode, openSidebarModeSidebar, sidebarModeHandleClose } = this.props;
    openSidebarModeSidebar = openSidebarModeSidebar ? openSidebarModeSidebar : false;
    let xDrawerCloseMethod = Object.prototype.toString.call(sidebarModeHandleClose) == '[object Function]' ? sidebarModeHandleClose : this.handleToggleOpen;

    /**check for hide topbar */
    if (sidebarMode) {
      /**short mode for PMA only */
      return (
        <Fragment>
          <Drawer
            anchor="left"
            classes={{ paper: classes.drawerPaper }}
            onClose={xDrawerCloseMethod}
            open={openSidebarModeSidebar}
            variant='temporary'
          >
            <Sidebar
              className={classes.sidebar}
              handleDrawerClose={xDrawerCloseMethod}
              localStateData={localStateData}
            />
          </Drawer>
          <main
            className={classNames(classes.contentWithoutMargin)}
          >
            {children}
            {hideFooter ? '' : <Footer />}
          </main>
        </Fragment>
      );
    }
    return (
      <Fragment>
        {!hideTopbar ?
          <Topbar
            className={classNames(classes.topbar, {
              [classes.topbarShift]: shiftTopbar
            })}
            gradientMode={gradientMode}
            isSidebarOpen={isOpen}
            onToggleSidebar={this.handleToggleOpen}
            title={title}
            open={isOpen}
            isMobile={isMobile}
            localStateData={localStateData}
          />
          : ''}
        {
          (!hideTopbar && isMobile) ? <Drawer
            anchor="left"
            classes={{ paper: classes.drawerPaper }}
            onClose={xDrawerCloseMethod}
            open={isOpen}
            variant='temporary'
            handleDrawerClose={xDrawerCloseMethod}
          >
            <Sidebar
              className={classes.sidebar}
              handleDrawerClose={xDrawerCloseMethod}
              localStateData={localStateData}
            />
          </Drawer> : ''
        }
        <main
          className={classNames((hideTopbar ? classes.content : classes.contentWithoutMargin))}
        >
          {children}
          {hideFooter ? '' :
            <Footer />}
        </main>
      </Fragment>
    );
  }
}

Website.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  width: PropTypes.string.isRequired
};

export default compose(
  withStyles(styles),
  withWidth()
)(Website);
