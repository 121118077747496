export default theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(9)
    },
    portletContent: {
        boxShadow: '2px 2px 10px #012159',
        WebkitBoxShadow: '2px 2px 10px #012159',
        MozBoxShadow: '2px 2px 10px #012159',
    },
    content: {
        padding: theme.spacing(2)
    },
    paddingTC: {
        padding: theme.spacing(8),
        paddingTop: theme.spacing(4),
    }
});