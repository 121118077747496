import React, { Component, Fragment } from "react";
import classNames from "classnames";
import compose from "recompose/compose";
import PropTypes from "prop-types";

import { withStyles, withWidth } from "@material-ui/core";
import { Drawer } from "@material-ui/core";

import { Sidebar, Topbar, Footer } from "./components";
import styles from "./styles";
import { IsValidSessionSwitch, GetStateStorage } from "common/storage";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    const isMobile = ["xs", "sm", "md"].includes(props.width);

    this.state = {
      isOpen: !isMobile,
      sessionSwitchMode: false,
      localStateData: {},
    };
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleToggleOpen = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  componentDidMount() {
    /**check for switch session */
    let isValidSwitchSession = IsValidSessionSwitch();
    let localStateData = GetStateStorage();
    this.setState({
      sessionSwitchMode: isValidSwitchSession,
      localStateData: localStateData ? localStateData : {},
    });
  }

  render() {
    const { classes, width, title, children, isCompleteInvisible } = this.props;
    const { isOpen, sessionSwitchMode, localStateData } = this.state;

    const isMobile = ["xs", "sm", "md"].includes(width);
    const shiftTopbar = isOpen && !isMobile;
    const shiftContent = isOpen && !isMobile;

    return !isCompleteInvisible ? (
      <Fragment>
        <Topbar
          className={classNames(classes.topbar, {
            [classes.topbarShift]: shiftTopbar,
          })}
          isSidebarOpen={isOpen}
          onToggleSidebar={this.handleToggleOpen}
          title={title}
          localStateData={localStateData}
          sessionSwitchMode={sessionSwitchMode}
        />
        <Drawer
          anchor="left"
          classes={{ paper: classes.drawerPaper }}
          onClose={this.handleClose}
          open={isOpen}
          variant={isMobile ? "temporary" : "persistent"}
        >
          <Sidebar
            className={classes.sidebar}
            sessionSwitchMode={sessionSwitchMode}
          />
        </Drawer>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: shiftContent,
          })}
        >
          {children}
          <Footer />
        </main>
      </Fragment>
    ) : (
      <main>{children}</main>
    );
  }
}

Dashboard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  width: PropTypes.string.isRequired,
  isCompleteInvisible: PropTypes.bool.isRequired,
};

Dashboard.defaultProps = {
  isCompleteInvisible: false,
};

export default compose(withStyles(styles), withWidth())(Dashboard);
