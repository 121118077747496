import React, { Component, Fragment } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { pmaActions } from "_store/_actions";

//import MQOne from "./MQOne";
import MQTwo from "./MQTwo";
import MQThree from "./MQThree";

class MidQuarter extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.changeLoading(false);
    }, 500);
  }

  render() {
    const { isLoading } = this.props;
    const {
      printVersion,
      version,
      agentData,
      hoData,

      currentArea,
      MQCityDetails,
      mlsAreas,
      coverPageDetails,
      quarterlyAreaTable,
      quarterlyCityTable,
      qArea,
      qCity,
      testimonials,
      aprilData,
      agentAdvantages,
      agentDesignations,

      missionStatement,
      demoAccountAreaName,
      demoAccountCoverPhoto,
      demoAccountCity,
      demoAccountHOName,
    } = this.props;
    //console.log("inside midquarter index", this.props);
    // const nonPrintStyle = {
    //   padding: '16px 0 5px 0',
    // };
    return (
      <Fragment>
        {isLoading ? (
          <div
            style={{
              height: "90vh",
              display: "flex",
              alignaItems: "center",
              justifyContent: "center",
              marginTop: "20vh",
              marginBottom: "20vh",
              flexDirection: "row",
            }}
          >
            <div>
              <CircularProgress />
            </div>
            <div>
              <Typography variant="subtitle2">
                &nbsp;&nbsp;Loading...
              </Typography>
            </div>
          </div>
        ) : (
          <div
            className={printVersion ? "midquarter-print" : "midquarter-editor"}
          >
            <div id="back-to-top-anchor-pma-editor-page-1" />
            {version === "mq3" ? (
              <MQThree
                agentData={agentData}
                hoData={hoData}
                currentArea={currentArea}
                MQCityDetails={MQCityDetails}
                printVersion={printVersion}
                version={version}
                mlsAreas={mlsAreas}
                coverPageDetails={coverPageDetails}
                testimonials={testimonials}
                quarterlyAreaTable={quarterlyAreaTable}
                agentAdvantages={agentAdvantages}
                agentDesignations={agentDesignations}
                demoAccountAreaName={demoAccountAreaName}
                demoAccountCoverPhoto={demoAccountCoverPhoto}
                demoAccountCity={demoAccountCity}
                demoAccountHOName={demoAccountHOName}
                aprilData={aprilData}
                missionStatement={missionStatement}
                quarterlyCityTable={quarterlyCityTable}
              />
            ) : (
              <MQTwo
                agentData={agentData}
                hoData={hoData}
                currentArea={currentArea}
                MQCityDetails={MQCityDetails}
                printVersion={printVersion}
                version={version}
                mlsAreas={mlsAreas}
                coverPageDetails={coverPageDetails}
                testimonials={testimonials}
                demoAccountAreaName={demoAccountAreaName}
                demoAccountCoverPhoto={demoAccountCoverPhoto}
                demoAccountCity={demoAccountCity}
                demoAccountHOName={demoAccountHOName}
                qArea={qArea}
                qCity={qCity}
                quarterlyAreaTable={quarterlyAreaTable}
                quarterlyCityTable={quarterlyCityTable}
              />
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.pma.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeLoading: (status) => {
      dispatch(pmaActions.changeLoading(status));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MidQuarter);
