import React, { Component } from "react";
import MQTwoSecondLeft from "./MQTwoSecondLeft";
import MQTwoSecondRight from "./MQTwoSecondRight";

class MQTwoSecondPage extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      MQCityDetails,
      printVersion,
      mlsAreas,
      version,
      qArea,
      qCity,
      demoAccountAreaName,
      quarterlyAreaTable,
      quarterlyCityTable,
      demoAccountCity,
    } = this.props;

    return (
      <div
        className={
          "pma-page-container " +
          (printVersion
            ? "mq2-print-page-container"
            : "mq2-editor-page-container")
        }
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <MQTwoSecondLeft
          MQCityDetails={MQCityDetails}
          printVersion={printVersion}
          agentData={agentData}
          qArea={qArea}
          qCity={qCity}
          currentArea={currentArea}
          hoData={hoData}
          demoAccountAreaName={demoAccountAreaName}
          quarterlyAreaTable={quarterlyAreaTable}
          quarterlyCityTable={quarterlyCityTable}
          demoAccountCity={demoAccountCity}
        />
        <MQTwoSecondRight
          agentData={agentData}
          currentArea={currentArea}
          MQCityDetails={MQCityDetails}
          quarterlyAreaTable={quarterlyAreaTable}
          printVersion={printVersion}
          version={version}
          hoData={hoData}
          mlsAreas={mlsAreas}
          quarterlyCityTable={quarterlyCityTable}
        />
      </div>
    );
  }
}

export default MQTwoSecondPage;
