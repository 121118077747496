import palette from "theme/palette";

export default theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  linkSection: {
    margin: theme.spacing(3),
    fontWeight: '500',
    cursor: 'pointer',
  },
  linkDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  linkColor: {
    color: palette.primary.main
  }
});
