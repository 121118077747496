import React, {
  Component,
  Fragment,
  useState,
  useEffect,
  useContext,
} from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Input,
  TextField,
} from "@material-ui/core";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "../styles";
import { Dashboard as DashboardLayout } from "layouts";
import { withSnackbar } from "notistack";
import { Portlet } from "components";
import { PortletContent } from "components";
import { PortletHeader } from "components";
import classNames from "classnames";
import { PortletFooter } from "components";
import { PortletLabel } from "components";
import AddTestimonials from "./component/AddTestimonials";
import { GetPmaAdminDataAPI } from "services/agent";
import moment from "moment";
import { GetStateStorage, IsValidSessionSwitch } from "common/storage";
import { showSuccessNotification } from "common/helpers";
import { showErrorNotification } from "common/helpers";
import CommonConfirmationDialog from "components/CommonConfirmationDialog";
import { Store } from "Store";
import { UPDATE_PMA_TESTIMONIALS_COUNT } from "store/types";
import Base from "common/constants";
import DocumentTitle from "react-document-title";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Skeleton } from "@material-ui/lab";

const TestimonialContent = (props) => {
  const {
    classes,
    isLoading,
    isPrinted,
    isAdminAccess,
    iKey,
    item,
    handleFormOpen,
    askFroConfirmation,
  } = props;

  const convertTimeToUSATime = (inputTime) => {
    return moment(new Date(inputTime)).format("MM-DD-YYYY");
  };

  return (
    <div
      className={classNames(
        classes.testimonialTextboxDiv,
        classes.testimonialTextboxDivBorder,
        classes.displyFlex,
        isPrinted ? classes.lightBlueBG : ""
      )}
    >
      <div className={classNames(classes.textboxLeftTM, classes.columnFlex)}>
        <div
          className={classNames(
            classes.displyFlex,
            classes.spaceBWJustify,
            classes.rowFlex
          )}
        >
          {!isLoading ? (
            <Typography variant="subtitle2">
              Testimonial # {iKey + 1}
            </Typography>
          ) : (
            <Skeleton animation="wave" height={20} width="10%" />
          )}
          {!isLoading ? (
            <Typography variant="body1">
              Created:{" "}
              {item.createdDate
                ? convertTimeToUSATime(item.createdDate)
                : "---"}
            </Typography>
          ) : (
            <Skeleton animation="wave" height={20} width="10%" />
          )}
          {!isLoading ? (
            <Typography
              variant="body1"
              className={isPrinted ? classes.red900 : ""}
            >
              Last date printed/used:{" "}
              {item.lastUsedDate && item.isAlreadyUsed
                ? convertTimeToUSATime(item.lastUsedDate)
                : "---"}
            </Typography>
          ) : (
            <Skeleton animation="wave" height={20} width="10%" />
          )}
        </div>
        {!isLoading ? (
          <div
            className={classNames(
              classes.addedTestimonialsDiv,
              isPrinted
                ? classNames(classes.lightBlueBG, classes.blueGrayBorder)
                : classes.lightBlueBG,
              classes.wordBreak
            )}
          >
            <Typography variant="body1">{item.testimonialContent}</Typography>
          </div>
        ) : (
          <Skeleton animation="wave" height={100} width="100%" />
        )}
        <div className={classNames(classes.displyFlex, classes.flexEndJustify)}>
          {!isLoading ? (
            <div>
              {isAdminAccess ? (
                item.testimonialVerificationStatus === "verified" ? (
                  <div
                    className={classNames(
                      classes.green900,
                      classes.bold700Font,
                      classes.uppercase,
                      classes.px14
                    )}
                  >
                    Verified
                  </div>
                ) : (
                  <div
                    className={classNames(
                      classes.red900,
                      classes.bold700Font,
                      classes.uppercase,
                      classes.px14
                    )}
                  >
                    Not Verified
                  </div>
                )
              ) : (
                ""
              )}
            </div>
          ) : (
            <Skeleton animation="wave" height={10} width="10%" />
          )}
          <div
            className={classNames(
              classes.width50,
              classes.displyFlex,
              classes.flexEndJustify
            )}
          >
            {!isLoading ? (
              <Typography
                variant="subtitle2"
                className={classNames(
                  classes.hoRectangularBox,
                  classes.lightBlueBG
                )}
              >
                Homeowner's name:{" "}
                {`${item.clientFirstName}${
                  item.clientLastName ? ` ${item.clientLastName}` : ""
                }`}
              </Typography>
            ) : (
              <Skeleton animation="wave" height={10} width="40%" />
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames(
          classes.textboxRightTM,
          classes.displyFlex,
          classes.columnFlex,
          classes.centerAlign
        )}
      >
        {!isLoading ? (
          <Fragment>
            <Button
              color="primary"
              onClick={(event) => handleFormOpen("update", item)}
            >
              {isAdminAccess ? "Verify" : "Edit"}
            </Button>
            <Button
              color="primary"
              className={classNames(classes.red900, classes.bold500Font)}
              onClick={(event) => askFroConfirmation(item.testimonialID)}
            >
              Delete
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <Skeleton animation="wave" height={50} width="80%" />
            <Skeleton animation="wave" height={50} width="80%" />
          </Fragment>
        )}
      </div>
    </div>
  );
};

const AgentTestimonials = (props) => {
  const { classes } = props;
  const { dispatch } = useContext(Store);
  //state storage
  const isAValidSessionSwitch = IsValidSessionSwitch();
  const { userData } = GetStateStorage();
  const [addedTestimonials, setAddedTestimonials] = React.useState([]);

  const [showAddDialog, setAddDialog] = React.useState(false);
  const [oldData, setOldData] = React.useState(null);
  const [mode, setMode] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationDialog, setConfirmationDialog] = React.useState(false);
  const [confirmationConstantID, setConfirmationConstantID] = React.useState(
    null
  );

  //check for admin access
  const isAdminAccess =
    isAValidSessionSwitch ||
    userData.listgenRoleID === Base.APP_DATA.APP_ADMIN_ROLE_ID;

  const todayDate = moment(new Date()).format("YYYY-MM-DD");

  //load all the testimonials
  useEffect(() => {
    window.scrollTo(0, 0);
    if (addedTestimonials.length === 0) {
      loadAllTestimonials();
    }
  }, []);

  //for the edit moe send old data also
  const handleFormOpen = (mode = "add", oldData = null) => {
    setAddDialog(true);
    setOldData(oldData);
    setMode(mode);
  };

  const handleFormClose = () => {
    setAddDialog(false);
    setOldData(null);
    setMode(null);
  };

  const sortTestimonials = (testimonialsData) => {
    return testimonialsData.sort(
      (a, b) => b.testimonialSortOrder - a.testimonialSortOrder
    );
  };

  const loadAllTestimonials = () => {
    /**make loading true */
    setIsLoading(true);
    try {
      GetPmaAdminDataAPI("getTestimonials", userData.listgenUserID)
        .then((data) => {
          if (data.length > 0) {
            //sort the data now
            //sortTestimonials(data)
            setAddedTestimonials(data);
          } else {
            setAddedTestimonials([]);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setAddedTestimonials([]);
        });
    } catch (err) {
      setIsLoading(false);
      setAddedTestimonials([]);
    }
  };

  const pushDataToState = (mode, testimonialsData, testimonialID) => {
    let addedXTestimonials = [...addedTestimonials];
    let testimonialsCount = 0;
    if (mode === "add") {
      addedXTestimonials.push(testimonialsData);
      testimonialsCount = addedXTestimonials.length;
      //sortTestimonials(addedXTestimonials)
      setAddedTestimonials(addedXTestimonials);
    } else if (mode === "update") {
      let data = addedXTestimonials.map((item) => {
        if (item.testimonialID === testimonialID) {
          item = testimonialsData;
        }
        return item;
      });
      testimonialsCount = data ? data.length : 0;
      setAddedTestimonials(data ? data : []);
    } else if (mode === "delete") {
      let data = addedXTestimonials.filter(
        (item) => item.testimonialID !== testimonialID
      );
      testimonialsCount = data ? data.length : 0;
      setAddedTestimonials(data ? data : []);
    }
    //update the state
    dispatch({
      type: UPDATE_PMA_TESTIMONIALS_COUNT,
      count: testimonialsCount,
    });
  };

  const deleteAConstant = (lgTestimonialID) => {
    /**get the charge data */
    /**make loading true */
    try {
      GetPmaAdminDataAPI("deleteTestimonials", userData.listgenUserID, {
        lgTestimonialID,
      })
        .then((data) => {
          showSuccessNotification(props, `Testimonial deleted.`);
          pushDataToState("delete", {}, lgTestimonialID);
        })
        .catch((err) => {
          showErrorNotification(props, "Unable to process request.");
        });
    } catch (err) {
      showErrorNotification(props, "Unable to process request.");
    }
  };

  const askFroConfirmation = (lgTestimonialID) => {
    setConfirmationDialog(true);
    setConfirmationConstantID(lgTestimonialID);
  };

  const hideConfirmationDialog = () => {
    setConfirmationConstantID(null);
    setConfirmationDialog(false);
  };

  const isBeforeDataCheck = (isAlreadyUsed, usedDate) => {
    if (usedDate && usedDate !== null && isAlreadyUsed) {
      return moment(new Date(usedDate)).isBefore(todayDate);
    }
    return false;
  };

  return (
    <Fragment>
      {showAddDialog ? (
        <AddTestimonials
          mode={mode}
          oldData={oldData}
          onCloseFn={handleFormClose}
          completeData={addedTestimonials}
          listgenUserID={userData.listgenUserID}
          localUpdateFn={pushDataToState}
          isAdminAccess={isAdminAccess}
        />
      ) : (
        ""
      )}
      {showConfirmationDialog ? (
        <CommonConfirmationDialog
          title={`Are you sure you want to delete this?`}
          subtitle={`Once you delete a Testimonial it will be deleted forever. It won't be recoverable.`}
          closeFn={hideConfirmationDialog}
          methodToCallOnConfirmation={deleteAConstant}
          confirmationID={confirmationConstantID}
        />
      ) : (
        ""
      )}
      <DocumentTitle title="Client Testimonials">
        <DashboardLayout title="Client Testimonials">
          <div className={classNames(classes.root, classes.rootMinHeight)}>
            <Portlet className={classes.content}>
              <PortletHeader className={classes.stickyHead}>
                <PortletLabel
                  title="Existing Testimonials"
                  className={classes.primaryColor}
                />
                <Button
                  onClick={(event) => handleFormOpen("add", null)}
                  variant="contained"
                  color="primary"
                  //size="small"
                >
                  Create a New Testimonial
                </Button>
              </PortletHeader>
              <PortletContent noPadding>
                {isLoading ? (
                  // <div className={classNames(classes.progressWrapper, classes.noDataDiv)}>
                  //   <CircularProgress />
                  // </div>
                  <div>
                    {[1, 2, 3, 4].map((item) => (
                      <TestimonialContent
                        classes={classes}
                        isLoading={isLoading}
                        isPrinted={false}
                        isAdminAccess={false}
                        iKey={null}
                        item={{}}
                        handleFormOpen={() => {}}
                        askFroConfirmation={() => {}}
                      />
                      // <div className={classNames(classes.testimonialTextboxDiv, classes.displyFlex)}>
                      //   <div className={classNames(classes.textboxLeftTM, classes.columnFlex)}>
                      //     <div className={classNames(classes.displyFlex, classes.spaceBWJustify)}>
                      //       <Skeleton animation="wave" height={20} width="10%" />
                      //       <Skeleton animation="wave" height={20} width="10%" />
                      //       <Skeleton animation="wave" height={20} width="10%" />
                      //     </div>
                      //     <div className={classNames(classes.addedTestimonialsDiv, classes.wordBreak)}>
                      //       <Skeleton animation="wave" height={100} width="90%" />
                      //     </div>
                      //   </div>
                      //   <div className={classNames(classes.textboxRightTM, classes.displyFlex, classes.columnFlex)}>
                      //     <Skeleton animation="wave" height={10} width="10%" />
                      //   </div>
                      // </div>
                    ))}
                  </div>
                ) : addedTestimonials.length ? (
                  addedTestimonials.map((item, key) => {
                    let isPrinted = isBeforeDataCheck(
                      item.isAlreadyUsed,
                      item.lastUsedDate
                    );
                    return (
                      <Fragment>
                        <TestimonialContent
                          classes={classes}
                          isLoading={isLoading}
                          isPrinted={isPrinted}
                          isAdminAccess={isAdminAccess}
                          iKey={key}
                          item={item}
                          handleFormOpen={handleFormOpen}
                          askFroConfirmation={askFroConfirmation}
                        />
                      </Fragment>
                    );
                  })
                ) : (
                  <div
                    className={classNames(
                      classes.width100,
                      classes.displyFlex,
                      classes.centerJustify,
                      classes.centerAlign,
                      classes.noDataDiv
                    )}
                  >
                    <Typography variant="subtitle2">
                      Looks like no Testimonials has been added. Please add a
                      Testimonial by clicking on "
                      <span className={classes.bold500Font}>
                        Create a New Testimonial
                      </span>
                      ".
                    </Typography>
                  </div>
                )}
              </PortletContent>
            </Portlet>
          </div>
        </DashboardLayout>
      </DocumentTitle>
    </Fragment>
  );
};

AgentTestimonials.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose(withSnackbar, withStyles(styles))(AgentTestimonials);
