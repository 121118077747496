import { rgbToHex } from "@material-ui/core";
import {
  blueGrey,
  green,
  grey,
  pink,
  red,
  teal,
  agentRoi,
} from "@material-ui/core/colors";
import palette from "theme/palette";

export default (theme) => ({
  body: {
    webkitFontSmoothing: "auto",
  },
  propHighlightContainer: {
    padding: theme.spacing(3),
    margin: "auto",
    // height: "fit-content",
  },
  rootMinHeight: {
    minHeight: "90vh",
  },
  pageWidthMarginAuto: {
    width: "95%",
    margin: "auto",
  },
  marginAuto: {
    margin: "auto",
  },
  propHighlightTitleContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    // width: "100%"
    width: "792px",
    // border: "1px solid black",
  },
  propHighlightTitle: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    fontFamily: "objektiv-mk2, sans-serif",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "24px",
    letterSpacing: "0.025em",
    // color: "#475862"
  },
  highlightTitleDivider: {
    marginTop: "4px",
    marginBottom: "2px",
    height: "2px",
    width: "100%",
    backgroundColor: "#00558f",
  },
  highlightSubtitle: {
    fontFamily: "objektiv-mk2, sans-serif",
    fontWeight: 400,
    fontStyle: "italic",
    fontSize: "14px",
    color: "#00558f",
    textAlign: "center",
  },
  strengthEditDiv1: {
    display: "none",
  },
});
