import React, { Component } from 'react';
import classNames from 'classnames';
import {
    Portlet,
    PortletContent,
    PortletHeader,
    PortletLabel,
} from 'components';
import { Grid, Typography, Divider, Button } from '@material-ui/core';
import compose from 'recompose/compose';
import _ from 'lodash';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { WebsiteLayout } from 'layouts';
import { LegalTermsComponent } from 'components';
import { GetStateStorage } from 'common/storage';
import axios from 'common/interceptors';
import { CallGrammerBotApi } from 'services/others';
import DocumentTitle from 'react-document-title';

class LegalScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes, className } = this.props;
        const rootClassName = classNames(className);
        const storageData = GetStateStorage();
        console.log('storageData', storageData)

        return (
            <DocumentTitle title="Our Legal Terms">
                <WebsiteLayout>
                    <div className={classes.root}>
                        <Grid container className={classes.grid} spacing={0}>
                            <Grid item lg={2}></Grid>
                            <Grid item lg={8}>
                                <Portlet className={rootClassName}>
                                    {/* <PortletHeader>
                                    <PortletLabel
                                        title="Legal Terms and Condition"
                                    />
                                </PortletHeader> */}
                                    <PortletContent className={classes.portletContent}>
                                        <Grid container className={classes.grid} spacing={0}>

                                            <Grid item lg={12} className={classes.paddingTC}>
                                                <LegalTermsComponent
                                                    agentFirstName={storageData.userData.listgenUserFirstName}
                                                    agentLastName={storageData.userData.listgenUserLastName}
                                                    navbarInfo={storageData.userData.navbarInfo}
                                                    brokerageName={storageData.userData.brokerageName || ''}
                                                />
                                            </Grid>

                                        </Grid>

                                    </PortletContent>
                                </Portlet>
                            </Grid>
                            <Grid item lg={2}></Grid>
                        </Grid>
                    </div>
                </WebsiteLayout>
            </DocumentTitle>
        );
    }
}

export default compose(withStyles(styles))(LegalScreen);
