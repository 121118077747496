import axios from 'common/interceptors';
import Base from 'common/constants';
import querystring from 'querystring';
import { GetAuthorization } from 'common/helpers';
import { GetOAuthTokenByKey } from 'common/storage';
//axios.default.baseURL = '/oauth/token';
export const SignInUser = (username, password) => {
    return new Promise(async (resolve, reject) => {
        try {
            axios.post(Base.USER_AUTH_API, querystring.stringify({
                username: username,
                password: password,
                crossDomain: true,
                client_id: Base.APP_DATA.APP_CLIENT_ID,
                client_secret: Base.APP_DATA.APP_CLIENT_SECRET,
                grant_type: Base.APP_DATA.APP_GRANT_TYPE,
            }), {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        'Authorization': `Basic ${await GetAuthorization()}`,
                        'Access-Control-Allow-Origin': '*'
                    }
                }).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    } else {
                        reject("Invalid username or password.");
                    }
                }).catch((err) => {
                    if (err.response && err.response.data && err.response.data.error_description)
                        reject(err.response.data.error_description);
                    else
                        reject('OOPS!!! something went wrong.');
                });
        } catch (err) {
            reject('Something went wrong. Unable to process request.');
        }
    });
};

export const ForgotPassword = (emailAddress) => {
    return new Promise(async (resolve, reject) => {
        try {
            axios.post(Base.FORGOT_PASSWORD_API, {
                emailAddress,
            }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Basic ${await GetAuthorization()}`,
                        'Access-Control-Allow-Origin': '*'
                    }
                }).then((res) => {
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        reject("Unable to process request.");
                    }
                }).catch((err) => {
                    if (err.response && err.response.data && err.response.data.error_description)
                        reject(err.response.data.error_description);
                    else
                        reject('OOPS!!! something went wrong.');
                });
        } catch (err) {
            reject('Something went wrong. Unable to process request.');
        }
    });
};

export const ResetPasswordValidate = (emailAddress, agentResetPasswordToken) => {
    return new Promise(async (resolve, reject) => {
        try {
            axios.post(Base.RESET_PASSWORD_VALIDATE_API, {
                emailAddress,
                agentResetPasswordToken,
            }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Basic ${await GetAuthorization()}`,
                        'Access-Control-Allow-Origin': '*'
                    }
                }).then((res) => {
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        reject("Unable to process request.");
                    }
                }).catch((err) => {
                    if (err.response && err.response.data && err.response.data.error_description)
                        reject(err.response.data.error_description);
                    else
                        reject('OOPS!!! something went wrong.');
                });
        } catch (err) {
            reject('Something went wrong. Unable to process request.');
        }
    });
};

export const ResetPassword = (emailAddress, resetPassword) => {
    return new Promise(async (resolve, reject) => {
        try {
            axios.post(Base.RESET_PASSWORD_API, {
                emailAddress,
                resetPassword,
            }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Basic ${await GetAuthorization()}`,
                        'Access-Control-Allow-Origin': '*'
                    }
                }).then((res) => {
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        reject("Unable to process request.");
                    }
                }).catch((err) => {
                    if (err.response && err.response.data && err.response.data.error_description)
                        reject(err.response.data.error_description);
                    else
                        reject('OOPS!!! something went wrong.');
                });
        } catch (err) {
            reject('Something went wrong. Unable to process request.');
        }
    });
};

export const GetAllUsers = () => {
    return new Promise(async (resolve, reject) => {
        try {
          axios
            .get(Base.GET_ALL_USERS_API, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${GetOAuthTokenByKey('access_token')}`,
                'Access-Control-Allow-Origin': '*',
              },
              data: {},
              params: {},
            })
            .then(res => {
              if (res.status === 200) {
                resolve(res.data);
              } else {
                reject('Unable to process request.');
              }
            })
            .catch(err => {
              if (
                err.response &&
                err.response.data &&
                err.response.data.error_description
              )
                reject(err.response.data.error_description);
              else reject('OOPS!!! something went wrong.');
            });
        } catch (err) {
          reject('Something went wrong. Unable to process request.');
        }
      });
    // return new Promise((resolve, reject) => {
    //     try {
    //         fetch(`${Base.GET_ALL_USERS_API}`, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${GetOAuthTokenByKey('access_token')}`,
    //                 "Cache-Control": 'no-cache'
    //             }
    //         }).then(response => response.json())
    //             .then((res) => {
    //                 if (Object.keys(res).length > 0) {
    //                     resolve(res);
    //                 } else {
    //                     reject("Unable to process request.");
    //                 }
    //             }).catch((err) => {
    //                 if (err.response && err.response.data && err.response.data.error_description)
    //                     reject(err.response.data.error_description);
    //                 else
    //                     reject('OOPS!!! something went wrong.');
    //             });
    //     } catch (err) {
    //         reject('Something went wrong. Unable to process request.');
    //     }
    // });
};
