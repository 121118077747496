/**
 * Login Action Types
 */
export const DEEP_CLONE_STATE = 'DEEP_CLONE_STATE';
export const RESET_APP_STATE = 'RESET_APP_STATE';
export const RESET_APP_STATE_EXCEPT_VALUES = 'RESET_APP_STATE_EXCEPT_VALUES';
export const SET_TOKEN_DATA = 'SET_TOKEN_DATA';
export const HANDLE_FIELD_CHANGE = 'HANDLE_FIELD_CHANGE';
export const HANDLE_VALIDATIONS = 'HANDLE_VALIDATIONS';
export const SET_STATE_ERRORS = 'SET_STATE_ERRORS';
export const SHOW_PASSWORD = 'SHOW_PASSWORD';
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const STOP_LOADING_WITH_MESSAGE = 'STOP_LOADING_WITH_MESSAGE';
export const STOP_LOADING_WITH_SUCCESS_MESSAGE = 'STOP_LOADING_WITH_SUCCESS_MESSAGE';
export const CHANGE_MULTITPLE_DATA_INTO_STATE = 'CHANGE_MULTITPLE_DATA_INTO_STATE';


/**Dashboard types */
export const ADD_AGENT_DASHBOARD_DATA = 'ADD_AGENT_DASHBOARD_DATA';

/**MY account types */
export const UPDATE_USER_PROFILE_IMAGE = 'UPDATE_USER_PROFILE_IMAGE';

/**Logout data reset */
export const DO_LOGOUT_DATA_RESET = 'DO_LOGOUT_DATA_RESET';



export const ADMIN_AUTH_RECEIVED = 'ADMIN_AUTH_RECEIVED';
export const ADMIN_AUTH_SUCCESSFUL = 'ADMIN_AUTH_SUCCESSFUL';
export const ADMIN_AUTH_FAILED = 'ADMIN_AUTH_FAILED';

/**Update city area map */
export const UPDATE_CITY_AREA_MAP_DATA = 'UPDATE_CITY_AREA_MAP_DATA';

/**PMA admin screen */
export const UPDATE_PMA_STRENGTH_COUNT = 'UPDATE_PMA_STRENGTH_COUNT';
export const UPDATE_PMA_ADVANTAGE_COUNT = 'UPDATE_PMA_ADVANTAGE_COUNT';
export const UPDATE_PMA_TESTIMONIALS_COUNT = 'UPDATE_PMA_TESTIMONIALS_COUNT';
export const UPDATE_PMA_MISSIONS_COUNT = 'UPDATE_PMA_MISSIONS_COUNT';

