import React, { Component, Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Input,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from "@material-ui/core";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "../qv4styles.scss";
import { withSnackbar } from "notistack";
import classNames from "classnames";
import ClearIcon from "@material-ui/icons/Clear";
import { showSuccessNotification } from "common/helpers";
import { showErrorNotification } from "common/helpers";
import { AddUpdatePmaAdmin } from "services/agent";

import { propertyUtil } from "views/Pma/PropertyUtil";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PhotoErrorModal = (props) => {
  const {
    handleErrorModal,
    errorModalText,
    keepOrRemoveProperty,
    handleFormClose,
  } = props;
  console.log("*error modal props", props);
  const { formatSuffix } = propertyUtil;

  const displayAltTextLeft = () => {
    if (errorModalText === "Banner Property") {
      return "Keep Property";
    } else if (errorModalText === "Photo Changes Made") {
      return "Close";
    }
  };
  const displayAltTextRight = () => {
    if (errorModalText === "Banner Property") {
      return "Remove Property";
    } else if (errorModalText === "Photo Changes Made") {
      return "Keep Editing";
    }
  };

  const displayCloseText = () => {
    if (errorModalText === "Banner Property") {
      return "Remove Property";
    } else {
      return "Close";
    }
  };

  const displayErrorText = () => {
    if (errorModalText === "Photo Changes Made") {
      return (
        <div className="over-six-properties-photo-modal">
          <div className="over-six-modal-title">
            <p>Attention:</p>
          </div>
          <div className="over-six-modal-body">
            <p className="over-six-modal-text">Changes have been made</p>
            <p className="over-six-modal-text">
              Are you sure you want to close before exiting?
            </p>
          </div>
        </div>
      );
    } else if (errorModalText === "Max properties") {
      return (
        <div className="over-six-properties-photo-modal">
          <div className="over-six-modal-title">
            <p>Attention:</p>
          </div>
          <div className="over-six-modal-body">
            <p className="over-six-modal-text">
              You must remove (uncheck one of the 6 properties)
            </p>
            <p className="over-six-modal-text">
              before you can add another property
            </p>
          </div>
        </div>
      );
    } else if (errorModalText === "Missing inputs") {
      return (
        <div className="error-modal-text">
          <p>Please fill out all fields before submitting</p>
        </div>
      );
    } else if (errorModalText === "Missing properties") {
      return (
        <div className="error-modal-text">
          <p>Please select 6 properties to display</p>
        </div>
      );
    } else if (errorModalText === "Issue submitting form") {
      return (
        <div className="error-modal-text">
          <p>There was an issue submitting the form</p>
          <p>Please contact ListGen Support</p>
        </div>
      );
    } else if (errorModalText === "Banner Property") {
      return (
        <div className="error-modal-text">
          <p>Alert:</p>
          <p>You are about to remove a property with a banner</p>
        </div>
      );
    }
  };
  return (
    <Fragment>
      <Dialog
        open={true}
        //onClose={onCloseFn}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
        TransitionComponent={Transition}
      >
        <div
          className={`error-modal-container ${
            errorModalText === "Max properties" && "full-width-error-modal"
          }`}
        >
          {displayErrorText()}
          <div className="error-form-button-container">
            {errorModalText === "Photo Changes Made" ? (
              <div className="alt-button-container">
                <p
                  className="alt-button left"
                  onClick={() => handleErrorModal("close")}
                >
                  {displayAltTextLeft()}
                </p>
                <p
                  className="alt-button right"
                  onClick={() => handleFormClose()}
                >
                  {displayAltTextRight()}
                </p>
              </div>
            ) : errorModalText === "Banner Property" ? (
              <div className="alt-button-container">
                <p
                  className="alt-button left"
                  onClick={() => keepOrRemoveProperty(true)}
                >
                  {displayAltTextLeft()}
                </p>
                <p
                  className="alt-button right"
                  onClick={() => keepOrRemoveProperty(false)}
                >
                  {displayAltTextRight()}
                </p>
              </div>
            ) : errorModalText === "Max properties" ? (
              <div
                className="error-form-lg-close-button"
                onClick={() => handleErrorModal("close")}
              >
                <p>{displayCloseText()}</p>
              </div>
            ) : (
              <div
                className="error-form-cancel-button"
                onClick={() => handleErrorModal("close")}
              >
                <p>{displayCloseText()}</p>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default compose(withSnackbar, withStyles(styles))(PhotoErrorModal);
