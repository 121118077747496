import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import styles from "./styles";
import { Dashboard as DashboardLayout } from 'layouts';
import { CreateAgentForm } from './component';
import { GetCityAreaMap } from 'services/others';
import Base from 'common/constants';
import DocumentTitle from 'react-document-title';

class CreateAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //mlsData: {},
      openDialog: true,
    };
    /**bind methods */
    //this.getCityAreaData = this.getCityAreaData.bind(this);
  }

  // componentDidMount() {
  //   if (Object.keys(this.state.mlsData).length === 0) {
  //     this.getCityAreaData();
  //   }
  // }

  // getCityAreaData = () => {
  //   /**make loading true */
  //   try {
  //     GetCityAreaMap(Base.APP_DATA.APP_COUNTY_ID)
  //       .then(data => {
  //         if (Object.keys(data).length > 0) {
  //           this.setState((prevState, props) => {
  //             return {
  //               mlsData: data
  //             }
  //           });
  //         } else {
  //           this.setState({
  //             mlsData: {},
  //           });
  //         }
  //       })
  //       .catch(err => {
  //         this.setState({
  //           mlsData: {},
  //         });
  //       });
  //   } catch (err) {
  //     this.setState({
  //       mlsData: {},
  //     });
  //   }
  // };


  render() {
    const { classes } = this.props;
    return (
      <DocumentTitle title="Create Agent">
        <DashboardLayout title="Create Agent">
          <div className={classes.root}>
            <CreateAgentForm 
            //mlsData={this.state.mlsData}
            ></CreateAgentForm>
          </div>
        </DashboardLayout>
      </DocumentTitle>
    );
  }
}

CreateAgent.propTypes = {
  classes: PropTypes.object.isRequired
};
export default compose(
  withStyles(styles)
)(CreateAgent);
