import React, { Component } from "react";
import DashboardBodyRange from "./DashboardBodyRange";

class DashboardBody extends Component {
  render() {
    const { MQCityDetails, printVersion, agentData, currentArea } = this.props;
    const { dashBoardDetailsWithPriceRange } = MQCityDetails;

    let mappedRanges;
    if (!dashBoardDetailsWithPriceRange) {
      return <div className="dashboard-data-pending">Data pending</div>;
    } else {
      let reversed = [...dashBoardDetailsWithPriceRange].reverse();
      mappedRanges = reversed.map((range, i) => {
        return (
          <DashboardBodyRange
            printVersion={printVersion}
            key={i}
            rangeNum={i + 1}
            baths={range.AverageBaths}
            halfBaths={range.AverageHalfBaths}
            beds={range.AverageBeds}
            dom={range.AverageDaysOnMarket}
            garageSpaces={range.AverageGarageSpaces}
            sqft={range.AverageSquareFeet}
            lotSize={range.AverageLotSize}
            homesSold={range.HomesSold}
            oceanView={range.OceanView}
            golfView={range.golfView}
            priceRangeLow={range.PriceRangeLow}
            priceRangeHigh={range.PriceRangeHigh}
            agentData={agentData}
            currentArea={currentArea}
          />
        );
      });
      return <div className="dashboard-body">{mappedRanges}</div>;
    }
  }
}

export default DashboardBody;
