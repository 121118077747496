import Base from 'common/constants';
import querystring from 'querystring';
import { GetAuthorization } from 'common/helpers';
import { GetOAuthTokenByKey } from 'common/storage';
import axios from 'common/interceptors';

export const ChangePassword = (listgenUserID, oldPassword, newPassword) => {
    return new Promise(async (resolve, reject) => {
        try {
            axios.post(Base.UPDATE_PASSWORD_API, {
                listgenUserID: listgenUserID,
                oldPassword: oldPassword,
                newPassword: newPassword
            }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${GetOAuthTokenByKey('access_token')}`
                    }
                }).then((res) => {
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        reject("Unable to process request.");
                    }
                }).catch((err) => {
                    if (err.response && err.response.data && err.response.data.error_description)
                        reject(err.response.data.error_description);
                    else
                        reject('OOPS!!! something went wrong.');
                });
        } catch (err) {
            reject('Something went wrong. Unable to process request.');
        }
    });
};

export const ChangeEmail = (listgenUserID, emailAddress) => {
    return new Promise(async (resolve, reject) => {
        try {
            axios.post(Base.CHANGE_EMAIL_API, {
                listgenUserID: listgenUserID,
                emailAddress: emailAddress,
            }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${GetOAuthTokenByKey('access_token')}`
                    }
                }).then((res) => {
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        reject("Unable to process request.");
                    }
                }).catch((err) => {
                    if (err.response && err.response.data && err.response.data.error_description)
                        reject(err.response.data.error_description);
                    else
                        reject('OOPS!!! something went wrong.');
                });
        } catch (err) {
            reject('Something went wrong. Unable to process request.');
        }
    });
};