import React, { Component } from "react";
import QVFourEmailAndWebsiteFooter from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourEmailAndWebsiteFooter";
import { propertyUtil } from "views/Pma/PropertyUtil";
import DashboardBody from "views/Pma/PmaEditor/MidQuarter/MQTwo/MQTwoSecondPage/DashboardBody";
import DashboardHeader from "views/Pma/PmaEditor/MidQuarter/MQTwo/MQTwoSecondPage/DashboardHeader";
import DashboardIris from "views/Pma/PmaEditor/MidQuarter/MQTwo/MQTwoSecondPage/dashboard-iris.jpg";
import DashboardValuation from "views/Pma/PmaEditor/MidQuarter/MQTwo/MQTwoSecondPage/DashboardValuation";

class YETwoSecondLeft extends Component {
  render() {
    const {
      agentData,
      aprilData,
      currentArea,
      hoData,
      printVersion,
      MQCityDetails,
      demoAccountCity,
      quarterlyAreaTable,
      pmaQuarterlyCombinedCityDTO,
    } = this.props;
    console.log("*****ye two second page", this.props.quarterlyAreaTable);
    return (
      <div className="ye2-second-left">
        <img
          className="dashboard-iris-img"
          src="https://pma-coverphotos.s3.amazonaws.com/Dashboard_STEPs_082422.jpg"
        />
        {quarterlyAreaTable &&
          quarterlyAreaTable.dashBoardDetailsWithPriceRange && (
            <DashboardHeader
              MQCityDetails={quarterlyAreaTable}
              printVersion={printVersion}
            />
          )}
        {quarterlyAreaTable &&
          quarterlyAreaTable.dashBoardDetailsWithPriceRange && (
            <DashboardBody
              MQCityDetails={quarterlyAreaTable}
              printVersion={printVersion}
            />
          )}

        <DashboardValuation agentData={agentData} />
        <QVFourEmailAndWebsiteFooter
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
        />
      </div>
    );
  }
}

export default YETwoSecondLeft;
