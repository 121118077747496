import palette from '../../theme/palette';

export default theme => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(2),
    zIndex: 3
  },
  rootLeft: {
    position: 'fixed',
    bottom: theme.spacing(3),
    left: theme.spacing(2),
    zIndex: 3
  },
  rootForP1: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(9),
  },
  rootForP2: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(16),
  },
});
