import React, { Component } from "react";
import YearEndHighlights from "./YearEndHighlights";
import NoteworthySales from "./NoteworthySales";
import AgentLogoHeader from "./AgentLogoHeader";
import BottomRightFooter from "./BottomRightFooter";

class YEOneSecondPageRight extends Component {
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      pma,
      currentArea,
      mlsAreas,
      navbarData,
      coverPageDetails,
      yearEnd
    } = this.props;
    // console.log("ye 2nd page", this.props);
    return (
      <div className="ye1-second-page-right">
        <AgentLogoHeader agentData={agentData} />
        <YearEndHighlights
          agentData={agentData}
          hoData={hoData}
          currentArea={currentArea}
          printVersion={printVersion}
          yearEnd={yearEnd}
        />
        <NoteworthySales agentData={agentData} yearEnd={yearEnd} />
        <BottomRightFooter agentData={agentData} />
      </div>
    );
  }
}

export default YEOneSecondPageRight;
