import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Button, TextField, Typography } from '@material-ui/core';
import validate from 'validate.js';
import schema from './schema';
import _ from 'lodash';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

import styles from './styles';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        firstName: '',
        lastName: '',
        phoneNumber: ''
      },
      touched: {
        firstName: false,
        lastName: false,
        phoneNumber: false
      },
      errors: {
        firstName: null,
        lastName: null,
        phoneNumber: null
      },
      isValid: false,
      isLoading: false,
      showPassword: false,
      submitError: null
    };
  }

  handleFieldChange = (field, value, event) => {
    const newState = { ...this.state };
    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = event.target.value;
    this.setState(newState, this.validateForm);
  };

  validateForm = _.debounce(() => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  }, 300);

  componentDidMount(){
    /**update data into state */
    const {userData} = this.props;
    const newState = { ...this.state };
    newState.values = userData;
    const errors = validate(userData, schema);
    //newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    this.setState(newState);
  }

  render() {
    const {
      values,
      touched,
      errors,
      isValid,
      submitError,
      isLoading,
    } = this.state;
    const { classes, className, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);

    const showFirstNameError = touched.firstName && errors.firstName;
    const showLastNameError = touched.lastName && errors.lastName;
    const showPhoneNumberError = touched.dreNumber && errors.dreNumber;

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            subtitle="The information can be edited"
            title="Profile"
          />
        </PortletHeader>
        <PortletContent noPadding>
          <form
            autoComplete="off"
            noValidate
          >
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="First name"
                margin="dense"
                required
                value={this.state.values.firstName}
                variant="outlined"
                onChange={event =>
                  this.handleFieldChange("firstName", event.target.value, event)
                }
              />
              {showFirstNameError && (
                                        <Typography
                                            className={classes.fieldError}
                                            variant="body2"
                                        >
                                            {errors.firstName[0]}
                                        </Typography>
                                    )}
              <TextField
                className={classes.textField}
                label="Last name"
                margin="dense"
                required
                value={this.state.values.lastName}
                variant="outlined"
                onChange={event =>
                  this.handleFieldChange("lastName", event.target.value, event)
                }
              />
              {showLastNameError && (
                                        <Typography
                                            className={classes.fieldError}
                                            variant="body2"
                                        >
                                            {errors.lastName[0]}
                                        </Typography>
                                    )}
            </div>
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Phone Number"
                margin="dense"
                type="text"
                value={Number(this.state.values.phoneNumber)}
                variant="outlined"
                onChange={event =>
                  this.handleFieldChange("phoneNumber", event.target.value, event)
                }
              />
              {showPhoneNumberError && (
                                        <Typography
                                            className={classes.fieldError}
                                            variant="body2"
                                        >
                                            {errors.phoneNumber[0]}
                                        </Typography>
                                    )}
            </div>
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            variant="contained"
            disabled={!isValid}
          >
            Update Details
          </Button>
        </PortletFooter>
      </Portlet>
    );
  }
}

Account.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Account);
