export default {
    firstName: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 64,
      },
    },
    lastName: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 64,
      },
    },
    email: {
      presence: { allowEmpty: false, message: 'is required' },
      email: true,
      length: {
        maximum: 64,
      },
    },
    phone: {
      presence: {
        allowEmpty: false,
        message: 'is required',
      },
      length: {
        minimum: 6
      },
      numericality: {
        onlyInteger: true,
        message: 'is not valid',
      },
    },
    mailingAddress1: {
      presence: { allowEmpty: false, message: 'is required' },
      email: false,
      length: {
        maximum: 300,
      },
    },
      agentLicenseNumber: {
        presence: {
            allowEmpty: false,
            message: 'is required',
          },
          numericality: {
            onlyInteger: false,
            greaterThan: 0,
          },
          length: {
            is: 8,
          },
      },
      city: {
        presence: { allowEmpty: false, message: 'is required' },
        email: false,
        length: {
          maximum: 20,
        },
      },
      state: {
        presence: { allowEmpty: false, message: 'is required' },
        email: false,
        length: {
          maximum: 2,
        },
      },
      zipcode: {
        presence: { allowEmpty: false, message: 'is required' },
        email: false,
        // length: {
        //     is: 5,
        //     message: 'length is invalid',
        // },
      },
      // agentSignature: {
      //   presence: { allowEmpty: true, message: 'is required' },
      // },
      agentDesignation: {
        presence: { allowEmpty: false, message: 'is required' },
      }
  };