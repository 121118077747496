import React, { Component } from "react";
import MQTwoFirstPage from "./MQTwoFirstPage";
import MQTwoSecondPage from "./MQTwoSecondPage";

class MQTwo extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./mq2print.scss");
    } else {
      require("./mq2editor.scss");
    }
  }
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,

      currentArea,
      MQCityDetails,
      mlsAreas,
      coverPageDetails,
      testimonials,
      demoAccountAreaName,
      demoAccountCity,
      qArea,
      qCity,
      quarterlyAreaTable,
      quarterlyCityTable,
    } = this.props;

    return (
      <div className={printVersion ? "mq2-print-page" : "mq2-editor-page"}>
        <MQTwoFirstPage
          agentData={agentData}
          hoData={hoData}
          currentArea={currentArea}
          MQCityDetails={MQCityDetails}
          printVersion={printVersion}
          version={version}
          mlsAreas={mlsAreas}
          coverPageDetails={coverPageDetails}
          testimonials={testimonials}
          demoAccountAreaName={demoAccountAreaName}
          quarterlyAreaTable={quarterlyAreaTable}
          quarterlyCityTable={quarterlyCityTable}
        />
        <div id="back-to-top-anchor-pma-editor-page-2" />
        {/*<MQTwoSecondPage
          agentData={agentData}
          hoData={hoData}
          currentArea={currentArea}
          MQCityDetails={MQCityDetails}
          printVersion={printVersion}
          version={version}
          qArea={qArea}
          qCity={qCity}
          demoAccountAreaName={demoAccountAreaName}
          demoAccountCity={demoAccountCity}
          quarterlyAreaTable={quarterlyAreaTable}
          quarterlyCityTable={quarterlyCityTable}
        />*/}
      </div>
    );
  }
}

export default MQTwo;
