import React from 'react';
import { connect } from "react-redux";
import styles from "./styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import { pmaActions } from "_store/_actions";
import { MailProDialog } from '../MailProDialog/MailProDialog';
import { MailProDialogHeader } from '../MailProDialog/MailProDialogHeader';
import { MailProDialogBody } from '../MailProDialog/MailProDialogBody';
import MailProButton from '../UI/MailProButton';
import { Checkbox, TextField } from '@material-ui/core';
import classNames from 'classnames';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AllGroupList from './AllGroupList';


const RemoveHomeownerFromGroup = ({ classes, children }) => {
    const [open, setOpen] = React.useState(false);
    const [selectedGroups, setSelectedGroups] = React.useState([]);

    const openDialog = () => {
        setOpen(true);
    }

    const closeDialog = () => {
        setOpen(false);
    }

    const handleChange = (value) => {
        const selectedGroupsTemp = new Set(selectedGroups)
        selectedGroupsTemp.add(value)
        setSelectedGroups([...selectedGroupsTemp])
     }

    return <div className={classes.root}>
        {children ? <div onClick={openDialog}>{children}</div> : <div></div>}

        {open && <MailProDialog open={open}>
            <MailProDialogHeader>
                <div className={classNames(classes.displayRow, classes.addHoTitle, classes.removeHOTitle)} style={{ gap: 0 }}>
                    <div style={{ fontWeight: 700 }}>Remove this Homeowner: <span>NANCY SMITH</span></div>
                    <div style={{ fontStyle: 'normal'  }}>From this Group: <span>MAIN ST. HOMES</span></div>

                </div>
            </MailProDialogHeader>
            <MailProDialogBody>
                <div>
                    <div className={classes.dialogSubtitle}>
                        Select any other Group(s) you want to remove this Homeowner from:
                    </div>
                    <AllGroupList handleChange={handleChange} />
                    <div className={classes.redErrorText}>
                        <p> Are you sure you want to remove this</p>
                        <p>Homeowner {selectedGroups.length === 1 ? `from this Group?` : `from these ${selectedGroups.length} Groups`}</p> 
                    </div>
                </div>
                <div className={classes.footer}>
                    <div>
                        <MailProButton
                            key={"mp_new_add_group_cance;"}
                            variant="outlined"
                            label={"CANCEL"}
                            customStyle={{ fontSize: "12px", fontWeight: 500 }}
                            onClick={closeDialog}
                        />
                    </div>
                    <div>
                        <MailProButton
                            key={"mp_new_update_group"}
                            variant="filled"
                            label={"YES - REMOVE THIS HOMEOWNER"}
                            customStyle={{ fontSize: "12px", fontWeight: 700 }}
                            onClick={() => { }}
                        />
                    </div>
                    <div></div>


                </div>
            </MailProDialogBody>
        </MailProDialog>}
    </div>
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles), withSnackbar)(RemoveHomeownerFromGroup));
