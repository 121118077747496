import React, { Component } from "react";

class Barcode extends Component {
  render() {
    const { imbarcode } = this.props.hoData;
    return (
      <div>
        {/*<p className="electronic-service">ELECTRONIC SERVICE REQUESTED</p>*/}
        <div className="barcode">{imbarcode}</div>
      </div>
    );
  }
}

export default Barcode;
