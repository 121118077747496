import React, { Component } from "react";
import "./common.scss";
import { propertyUtil } from "views/Pma/PropertyUtil";

class FirstLeftHeader extends Component {
  componentWillMount() {
    if (this.props.printVersion) {
      require("./printCommon.scss");
    } else {
      require("./common.scss");
    }
  }
  render() {
    const {
      logoURL,
      officeAddressLine1,
      officeAddressLine2,
      officeCity,
      officeState,
      officeZipcode,
      brokerageName,
    } = this.props.agentData;
    const { filterBrokerage } = propertyUtil;
    const { hasLogo, currentArea } = this.props;
    const { mlsAreaID } = currentArea;
    return (
      <div className="first-left-header">
        <div className="logo-address">
          {hasLogo && (
            <div className="agentLogo1-left">
              {logoURL !== null ? (
                <img alt="Agent Logo" src={logoURL} />
              ) : (
                <div className="missing-logo">
                  *** PLEASE UPLOAD AGENT LOGO ***
                </div>
              )}
            </div>
          )}
        </div>
        {mlsAreaID === "99" ? (
          <div className="return-address">
            <p>RETURN ADDRESS:</p>
            <p>PO Box 7621</p>
            <p>Salinas, CA 93908</p>
          </div>
        ) : brokerageName === "intero" ? (
          <div className="return-address">
            <p>{filterBrokerage(brokerageName, true)}</p>
            <p>518 N Santa Cruz Ave</p>
            <p>Los Gatos CA 95030</p>
          </div>
        ) : (
          <div className="return-address">
            <p>{filterBrokerage(brokerageName, true)}</p>
            <p>{officeAddressLine1}</p>
            {officeAddressLine2 && <p>{officeAddressLine2}</p>}
            <p>
              {officeCity} {officeState} {officeZipcode}
            </p>
          </div>
        )}
        <div>
          <div className="postage">
            <p>PRESORTED STANDARD</p>
            <p>U.S. POSTAGE PAID</p>
            <p>SAN JOSE, CA</p>
            <p>PERMIT NO 1785</p>
          </div>
        </div>
      </div>
    );
  }
}

export default FirstLeftHeader;
