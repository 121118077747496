import React, { Component, Fragment, useEffect, useContext, useState } from 'react';
import classNames from 'classnames';
import { Portlet, PortletContent, PortletHeader, PortletLabel, PortletFooter } from 'components';
import {
    Grid,
    Typography,
    Divider,
    List,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Button
} from '@material-ui/core';
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import { WebsiteLayout } from 'layouts';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import DocumentTitle from 'react-document-title';
import Base from 'common/constants';
import { GetPmaEstimatedInvoiceData } from 'services/pma';
import { withSnackbar } from 'notistack';
import { showErrorNotification } from 'common/helpers';
import { showInfoNotification } from 'common/helpers';
import moment from 'moment';
import { GetStateStorage } from 'common/storage';

const PmaInvoice = (props) => {
    const { classes } = props;
    const listgenLogo = '/images/listgen-text-transparent-white.png';
    /**local storage settings */
    /**local state integration */
    const localStorageData = GetStateStorage();
    const { userData } = localStorageData;
    /**react invoice data */
    const [invoiceData, setInvoiceData] = useState({});
    /**loading mode */
    const [isLoading, setLoading] = useState(true);
    /**error state handling */
    const [isError, setErrorState] = useState(false);

    const startLoading = () => {
        setLoading(true);
    };
    const stopLoading = () => {
        setLoading(false);
    };
    /**methods */
    const formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return null;
    };

    const formatCurrency = (value, quantityMode = false) => {
        let xValue;
        try {
            if (!isNaN(value)) {
                let xNumber = (quantityMode ? Number(value) : Number(value).toFixed(2));
                return xNumber.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            } else {
                return '0.00';
            }
        } catch (err) {
            return value;
        }
    };

    useEffect(() => {
        try {
            startLoading();
            GetPmaEstimatedInvoiceData(userData.listgenUserID).then(data => {
                /**stop loading also */
                stopLoading();
                if (data.listgenUserID) {
                    setInvoiceData(data);
                } else {
                    setErrorState(true);
                    //showInfoNotification(props, 'No invoice data found.');
                }
            })
                .catch(err => {
                    setErrorState(true);
                    //showErrorNotification(props, 'Unable to process request.');
                    stopLoading();
                });
        } catch (err) {
            setErrorState(true);
            //showErrorNotification(props, 'Unable to process request.');
            stopLoading();
        }
    }, [userData.listgenUserID]);

    const convertTimeToUSATime = (inputTime) => {
        return moment(new Date()).format('MM/DD/YYYY');
    };

    return (
        <DocumentTitle title={"PMA Invoice"}>
            <WebsiteLayout>
                <div className={classes.root}>
                    <iframe
                        src={`https://api.listgen.com/provisionalInvoice/${userData.listgenUserID}/${moment(new Date()).format('DD')}/${moment(new Date()).format('MM')}/${moment(new Date()).format('YYYY')}`}
                        frameBorder={0}
                        width={"100%"}
                        height={'1400px'}
                    />
                </div>
            </WebsiteLayout>
        </DocumentTitle>
    )
};

export default compose(
    withSnackbar,
    withRouter,
    withStyles(styles)
)(PmaInvoice);



// <div className={classes.root}>
//     {isLoading ?
//         <Grid container>
//             <Grid item lg={12} sm={12} xs={12} md={12}>
//                 <div className={classes.singleComponent}>
//                     <CircularProgress />
//                 </div>
//             </Grid>
//         </Grid>
//         :
//         <Fragment>
//             {isError ?
//                 <Grid container>
//                     <Grid item lg={12} sm={12} xs={12} md={12}>
//                         <div className={classes.singleComponent}>
//                             <Typography variant="subtitle1" className={classes.errorState}>
//                                 Sorry, we are unable to generate invoice right now. Please try after some time or&nbsp;
//                             <Link
//                                     to='/help'
//                                 >
//                                     contact us
//                             </Link>
//                             .
//                 </Typography>
//                         </div>
//                     </Grid>
//                 </Grid>
//                 :
//                 <Grid className={classes.grid} container>
//                     <Grid item lg={2} sm={false} xs={false} md={3}></Grid>
//                     <Grid item lg={8} sm={12} xs={12} md={6}>
//                         <Portlet className={classes.root}>
//                             <PortletContent className={classes.portletContent}>
//                                 <div className={classes.invoiceBody}>
//                                     <div className={classes.headerSection}>
//                                         <div className={classes.headerContent}>
//                                             <div className={classes.headerLogo}>
//                                                 <div className={classes.logoMain}>
//                                                     <img
//                                                         src={listgenLogo}
//                                                         className={classes.logoMainTag}
//                                                     />
//                                                 </div>
//                                                 <div className={classes.printText}>
//                                                     <Typography variant="h4" className={classes.whiteColor}>
//                                                         PRINT/MAIL SUMMARY
// </Typography>
//                                                 </div>
//                                                 <div className={classes.mailText}>
//                                                     <Typography variant="caption" className={classes.whiteColor}>
//                                                         A final invoice will be e-mailed to you.
// </Typography>
//                                                 </div>
//                                             </div>
//                                             <div className={classes.headerUser}>
//                                                 <div className={classNames(classes.userMobile, classes.lineHeight)}>
//                                                     <Typography variant="body1" className={classes.whiteColor}>
//                                                         {formatPhoneNumber(userData.mobilePhoneNo)}
//                                                     </Typography>
//                                                 </div>
//                                                 <div className={classNames(classes.userEmail, classes.lineHeight)}>
//                                                     <Typography variant="body1" className={classes.whiteColor}>
//                                                         {userData.emailAddress}
//                                                     </Typography>
//                                                 </div>
//                                                 <div className={classNames(classes.userAccount, classes.lineHeight)}>
//                                                     <Typography variant="body1" className={classes.whiteColor}>
//                                                         ACCT. #: {userData.listgenUserID}
//                                                     </Typography>
//                                                 </div>
//                                             </div>
//                                             <div className={classes.headerMailing}>
//                                                 <div className={classes.mailingAddress}>
//                                                     <div className={classes.lineHeight}>
//                                                         <Typography variant="body1" className={classNames(classes.whiteColor)}>
//                                                             [Mailing Address]
//                                                     </Typography>
//                                                     </div>
//                                                     <div className={classes.lineHeight}>
//                                                         <Typography variant="body1" className={classNames(classes.whiteColor)}>
//                                                             {userData.mailingAddressLine1}
//                                                         </Typography>
//                                                     </div>
//                                                     {userData.mailingAddressLine2 ?
//                                                         <div className={classes.lineHeight}>
//                                                             <Typography variant="body1" className={classNames(classes.whiteColor)}>
//                                                                 {userData.mailingAddressLine2}
//                                                             </Typography>
//                                                         </div>
//                                                         : ''}
//                                                     <div className={classes.lineHeight}>
//                                                         <Typography variant="body1" className={classNames(classes.whiteColor)}>
//                                                             {userData.mailingCity}, {userData.mailingState}
//                                                         </Typography>
//                                                     </div>
//                                                     <div className={classes.lineHeight}>
//                                                         <Typography variant="body1" className={classNames(classes.whiteColor)}>
//                                                             {userData.mailingZipCode}
//                                                         </Typography>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className={classes.detailSection}>
//                                         <div className={classes.detailsContent}>
//                                             <div className={classes.detailText}>
//                                                 <Typography variant="body1" className={classes.teal800Color}>
//                                                     <span className={classes.estBoldText}>This is an estimated invoice.</span> If any addresses are undeliverable, your total amount will be less than what is shown.
//                                 </Typography>
//                                                 <Typography align="center" variant="body1" className={classes.teal800Color}>
//                                                     <span className={classes.estBoldText}>
//                                                         A final invoice will be e-mailed to you.
//                                 </span>
//                                                 </Typography>
//                                             </div>
//                                             <div className={classes.detailData}>
//                                                 <div className={classes.detailsBilling}>
//                                                     <List>
//                                                         <ListItem className={classes.listItemText}>
//                                                             <ListItemText
//                                                                 disableTypography={true}
//                                                                 primary={
//                                                                     <Fragment>
//                                                                         <div className={classes.lineHeightSemi}>
//                                                                             <Typography component={'span'} variant="body1">
//                                                                                 <span className={classes.detailsColor}>
//                                                                                     {
//                                                                                         userData.billingFirstName + ' ' +
//                                                                                         userData.billingLastName
//                                                                                     }
//                                                                                 </span>
//                                                                             </Typography>
//                                                                         </div>
//                                                                     </Fragment>
//                                                                 }
//                                                                 secondary={
//                                                                     <Fragment>
//                                                                         <div className={classes.lineHeightSemi}>
//                                                                             <Typography component={'span'} variant="body1" className={classNames(classes.blackColor)}>
//                                                                                 [Billing Address]
//                                                                         </Typography>
//                                                                         </div>
//                                                                         <div className={classes.lineHeightSemi}>
//                                                                             <Typography component={'span'} variant="body1" className={classNames(classes.blackColor)}>
//                                                                                 {userData.billingAddressLine1}
//                                                                             </Typography>
//                                                                         </div>
//                                                                         {userData.mailingAddressLine2 ?
//                                                                             <div className={classes.lineHeightSemi}>
//                                                                                 <Typography variant="body1" className={classNames(classes.blackColor)}>
//                                                                                     {userData.billingAddressLine2}
//                                                                                 </Typography>
//                                                                             </div>
//                                                                             : ''}
//                                                                         <div className={classes.lineHeightSemi}>
//                                                                             <Typography variant="body1" className={classNames(classes.blackColor)}>
//                                                                                 {userData.billingCity}, {userData.billingState}
//                                                                             </Typography>
//                                                                         </div>
//                                                                         <div className={classes.lineHeightSemi}>
//                                                                             <Typography variant="body1" className={classNames(classes.blackColor)}>
//                                                                                 {userData.billingZipCode}
//                                                                             </Typography>
//                                                                         </div>
//                                                                     </Fragment>
//                                                                 }
//                                                             />
//                                                         </ListItem>
//                                                     </List>
//                                                 </div>
//                                                 <div></div>
//                                                 <div className={classes.detailsInvoice}>
//                                                     <div className={classes.detailInvoiceNumber}>
//                                                         <List dense={false}>
//                                                             <ListItem className={classes.listItemText}>
//                                                                 <ListItemText
//                                                                     disableTypography={true}
//                                                                     primary={
//                                                                         <Fragment>
//                                                                             <div className={classes.lineHeightSemi}>
//                                                                                 <Typography component={'span'} variant="body1">
//                                                                                     <span className={classes.detailsColor}>
//                                                                                         Print Date
//                                                                                 </span>
//                                                                                 </Typography>
//                                                                             </div>
//                                                                         </Fragment>}
//                                                                     secondary={
//                                                                         <Fragment>
//                                                                             <div className={classes.lineHeightSemi}>
//                                                                                 <Typography component={'span'} variant="body1" className={classNames(classes.blackColor)}>
//                                                                                     {convertTimeToUSATime()}
//                                                                                 </Typography>
//                                                                             </div>
//                                                                         </Fragment>
//                                                                     }
//                                                                 />
//                                                             </ListItem>
//                                                         </List>
//                                                     </div>
//                                                 </div>
//                                                 <div className={classes.detailCharges}>
//                                                     <div className={classes.detailChargesContainer}>
//                                                         <table>
//                                                             <tr className={classes.chargeTableSpacer}>
//                                                                 <td valign="middle">
//                                                                     <Typography variant="body1">
//                                                                         Estimated Total Charges
//                                                                 </Typography>
//                                                                 </td>
//                                                                 <td valign="middle" className={classes.chargeTableTdAlignment}>
//                                                                     <div className={classNames(classes.lineHeightSemi, classes.detailChargesText)} align="right">
//                                                                         <Typography component={'span'} variant="body1" className={classes.detailChargesText} align="right">
//                                                                             ${formatCurrency(invoiceData.actualTotal)}
//                                                                         </Typography>
//                                                                     </div>
//                                                                 </td>
//                                                             </tr>
//                                                             {Math.round(parseFloat(invoiceData.actualTotal) * 100000) !== Math.round(parseFloat(invoiceData.discountedTotal) * 100000) ?
//                                                                 <tr className={classes.chargeTableSpacer}>
//                                                                     <td valign="middle">
//                                                                         <Typography variant="body1">
//                                                                             Special Pricing Discount
//                                                             </Typography>
//                                                                     </td>
//                                                                     <td valign="middle" className={classes.chargeTableTdAlignment} align="right">
//                                                                         <div className={classNames(classes.lineHeightSemi, classes.detailChargesText)}>
//                                                                             <Typography component={'span'} variant="body1" className={classes.detailChargesText} align="right">
//                                                                                 -${formatCurrency((parseFloat(invoiceData.actualTotal) - parseFloat(invoiceData.discountedTotal)))}
//                                                                             </Typography>
//                                                                         </div>
//                                                                     </td>
//                                                                 </tr>
//                                                                 : ''}
//                                                             <tr className={classes.chargeTableSpacer}>
//                                                                 <td colSpan="2" valign="middle">
//                                                                     <Divider className={classes.chargeDividerMain} />
//                                                                 </td>
//                                                             </tr>
//                                                             <tr className={classes.chargeTableSpacer}>
//                                                                 <td valign="middle">
//                                                                     <Typography variant="subtitle2">
//                                                                         Estimated Total Due
//                                                             </Typography>
//                                                                 </td>
//                                                                 <td valign="middle" className={classes.chargeTableTdAlignment}>
//                                                                     <div className={classNames(classes.lineHeightSemi, classes.detailChargesText)}>
//                                                                         <Typography component={'span'} variant="body1" className={classes.detailChargesTextMain} align="right">
//                                                                             ${formatCurrency(invoiceData.discountedTotal)}
//                                                                         </Typography>
//                                                                     </div>
//                                                                 </td>
//                                                             </tr>
//                                                         </table>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className={classes.dividerSection}>
//                                         <Divider className={classes.dividerMain} />
//                                     </div>
//                                     <div className={classes.tableSection}>
//                                         <Paper className={classes.tablePaper}>
//                                             <Table className={classes.table}>
//                                                 <TableHead>
//                                                     <TableRow>
//                                                         <TableCell className={classes.blueColor}>Area / Description</TableCell>
//                                                         <TableCell align="center" className={classes.blueColor}>
//                                                             PMA Quantity
// </TableCell>
//                                                         <TableCell align="right" className={classes.blueColor}>
//                                                             Unit Price
// </TableCell>
//                                                         <TableCell align="right" className={classes.blueColor}>
//                                                             Line Total
// </TableCell>
//                                                     </TableRow>
//                                                 </TableHead>
//                                                 <TableBody>
//                                                     {invoiceData.listAreaPaymentInfo && Array.isArray(invoiceData.listAreaPaymentInfo) ?
//                                                         <Fragment>
//                                                             {invoiceData.listAreaPaymentInfo.map((item, key) =>
//                                                                 <TableRow key={key}>
//                                                                     <TableCell align="left" style={{ verticalAlign: 'top' }}>
//                                                                         <div className={classes.firstTdContentHight}>
//                                                                             <Typography variant="subtitle1">
//                                                                                 {item.areaName}
//                                                                             </Typography>
//                                                                             <Typography variant="body2">
//                                                                                 {item.pmaDescription}
//                                                                             </Typography>
//                                                                         </div>
//                                                                         {item.noChargeForPMA ?
//                                                                             <div className={classes.secondTdHeight}>
//                                                                                 <Typography variant="body1">
//                                                                                     {`${item.noPMAChargeText}`}
//                                                                                 </Typography>
//                                                                             </div>
//                                                                             : ''}
//                                                                         {item.orgMonthlyCost ?
//                                                                             <div className={classes.thirdTdHeight}>
//                                                                                 <Typography variant="body1">
//                                                                                     Area Exclusivity Monthly Fee
//                                                                             </Typography>
//                                                                             </div>
//                                                                             : ''}
//                                                                         {item.noMonthlyCharge ?
//                                                                             <div className={classes.fourthTdHeight}>
//                                                                                 <Typography variant="body1">
//                                                                                     {`${item.noMonthlyChargeText}`}
//                                                                                 </Typography>
//                                                                             </div>
//                                                                             : ''}
//                                                                     </TableCell>
//                                                                     <TableCell align="center" style={{ verticalAlign: 'top' }}>
//                                                                         <div className={classes.firstTdContentHight}>
//                                                                             {formatCurrency(item.numOfPMAs, true)}
//                                                                         </div>
//                                                                         {item.noChargeForPMA ?
//                                                                             <div className={classNames(classes.secondTdHeight, classes.extraMarginForAlignment)}>
//                                                                                 {`-${formatCurrency(item.numOfPMAs, true)}`}
//                                                                             </div>
//                                                                             : ''}
//                                                                         {item.orgMonthlyCost ?
//                                                                             <div className={classes.thirdTdHeight}>
//                                                                                 1
//                                                                         </div>
//                                                                             : ''}
//                                                                         {item.noMonthlyCharge ?
//                                                                             <div className={classes.fourthTdHeight}>
//                                                                                 -1
//                                                                         </div>
//                                                                             : ''}
//                                                                     </TableCell>
//                                                                     <TableCell align="right" style={{ verticalAlign: 'top' }}>
//                                                                         <div className={classes.firstTdContentHight}>
//                                                                             ${formatCurrency(1.85)} each
//                                                                     </div>
//                                                                         {item.noChargeForPMA ?
//                                                                             <div className={classes.secondTdHeight}>
//                                                                                 -${`${formatCurrency(1.85)} each`}
//                                                                             </div>
//                                                                             : ''}
//                                                                         {item.orgMonthlyCost ?
//                                                                             <div className={classes.thirdTdHeight}>
//                                                                                 ${item.orgMonthlyCost}
//                                                                             </div>
//                                                                             : ''}
//                                                                         {item.noMonthlyCharge ?
//                                                                             <div className={classes.fourthTdHeight}>
//                                                                                 -${item.orgMonthlyCost}
//                                                                             </div>
//                                                                             : ''}
//                                                                     </TableCell>
//                                                                     <TableCell align="right" style={{ verticalAlign: 'top' }}>
//                                                                         <div className={classes.firstTdContentHight}>
//                                                                             ${formatCurrency(item.orgPMACost)}
//                                                                         </div>
//                                                                         {item.noChargeForPMA ?
//                                                                             <div className={classes.secondTdHeight}>
//                                                                                 -${`${formatCurrency(item.orgPMACost)}`}
//                                                                             </div>
//                                                                             : ''}
//                                                                         {item.orgMonthlyCost ?
//                                                                             <div className={classes.thirdTdHeight}>
//                                                                                 ${item.orgMonthlyCost}
//                                                                             </div>
//                                                                             : ''}
//                                                                         {item.noMonthlyCharge ?
//                                                                             <div className={classes.fourthTdHeight}>
//                                                                                 -${item.orgMonthlyCost}
//                                                                             </div>
//                                                                             : ''}
//                                                                     </TableCell>
//                                                                 </TableRow>
//                                                             )}
//                                                             <TableRow>
//                                                                 <TableCell rowSpan={3} />
//                                                                 <TableCell colSpan={2} className={classes.blueColor}>
//                                                                     <strong>Subtotal</strong>
//                                                                 </TableCell>
//                                                                 <TableCell align="right">
//                                                                     <strong>${formatCurrency(invoiceData.subTotal)}</strong>
//                                                                 </TableCell>
//                                                             </TableRow>
//                                                             <TableRow>
//                                                                 <TableCell className={classes.blueColor}>
//                                                                     <strong>Sales Tax (CA.)</strong>
//                                                                 </TableCell>
//                                                                 {/* Carmel Valley is 7.75% Tax */}
//                                                                 <TableCell align="center">7.75%</TableCell>
//                                                                 <TableCell align="right">
//                                                                     <strong>${formatCurrency(invoiceData.discountedTax)}</strong>
//                                                                 </TableCell>
//                                                             </TableRow>

//                                                             <TableRow>
//                                                                 <TableCell colSpan={2} className={classes.blueColor}>
//                                                                     <strong>Estimated Total Charges</strong>
//                                                                     {Math.round(parseFloat(invoiceData.actualTotal) * 100000) !== Math.round(parseFloat(invoiceData.discountedTotal) * 100000) ?
//                                                                         <Fragment>
//                                                                             <br />
//                                                                             <Typography variant="body2">
//                                                                                 (With Special Discount Applied)
//                                                                     </Typography>
//                                                                         </Fragment>
//                                                                         : ''}
//                                                                 </TableCell>
//                                                                 <TableCell align="right">
//                                                                     <strong>${
//                                                                         formatCurrency(invoiceData.discountedTotal)
//                                                                     }
//                                                                     </strong>
//                                                                 </TableCell>
//                                                             </TableRow>
//                                                         </Fragment>
//                                                         :
//                                                         <TableRow>
//                                                             <TableCell colSpan={4} />
//                                                         </TableRow>
//                                                     }
//                                                 </TableBody>
//                                             </Table>
//                                         </Paper>

//                                     </div>
//                                 </div>
//                             </PortletContent>
//                             <PortletFooter>
//                                 <div className={classes.footerSection}>
//                                     <Button
//                                         color="primary"
//                                         variant="outlined"
//                                         href="/pma"
//                                     >
//                                         Close
//                     </Button>
//                                 </div>
//                             </PortletFooter>
//                         </Portlet>
//                     </Grid>
//                     <Grid item lg={2} sm={false} xs={false} md={3}></Grid>
//                 </Grid>
//             }
//         </Fragment>
//     }
// </div>
