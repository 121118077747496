import React, { Component } from 'react';

class PSContents extends Component {
  render() {
    const {
      theHomeowners,
      hoTextPS,
      isMemberOfTeam,
      teamMembers,
      agentFirstNames,
      listgenUserFirstName,
    } = this.props;
    // console.log(this.props);
    return (
      <div className="left-justified-ps">
        <div className="inner-ps-text-container">
          <div className="homeowners-salutation">Dear {theHomeowners},</div>
          {hoTextPS === '<p></p>' ? (
            <div className="ps-text">Please give me a call today!</div>
          ) : (
            <div
              className="ps-text"
              dangerouslySetInnerHTML={{
                __html: hoTextPS,
              }}
            ></div>
          )}

          <div className="agent-signoff">
            <p className="signoff">Warm Regards,</p>

            {isMemberOfTeam && teamMembers.length === 2 ? (
              <p className="agent-name">
                {agentFirstNames(listgenUserFirstName, teamMembers)}
              </p>
            ) : (
              <p className="agent-name">{listgenUserFirstName}</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PSContents;
