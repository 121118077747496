
import React from 'react';
import compose from "recompose/compose";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = {
  disabledButton: {
    pointerEvents: 'none',
    opacity: '0.7'
  },
  outlinedButton: {
    fontSize: "11px",
    borderRadius: "16px",
    padding: "5px 16px",
    border: "1px solid #4A7396",
    background: "white",
    fontWeight: "200",
    cursor: "pointer",
    color: "#4A7396",
    "&:hover": {
      background: "#edf7f8",
    },
  },
  filledButton: {
    fontSize: "15px",
    borderRadius: "4px",
    padding: "5px 16px",
    border: "1px solid #4A7396",
    background: "#4A7396",
    fontWeight: "500",
    color: "#4A7396",
    letterSpacing: "0.025em",
    cursor: "pointer",
    color: 'white'
  },
  textButton: {
    fontSize: "12px",
    color: "#4A7396",
    fontWeight: "500",
    textDecoration: "underline",
    cursor: "pointer",
  },
  flashButton: {
    animation: "$new-blinker 2s linear infinite",
    animationDuration: '2s',
    '&:hover': {
      animation: '0',
      WebkitAnimation: 0
    }
  },
  "@keyframes new-blinker": {
    "85%": {
      opacity: 0
    }
  },
};

const MailProButton = withStyles(styles)(({ label, classes, additionalClass = null, variant = 'outlined', onClick = () => { }, customStyle = {}, disabled, animation = false }) => {
  return (variant === 'outlined' ?
    <button onClick={onClick} className={classNames(classes.outlinedButton, additionalClass, (disabled ? classes.disabledButton : ''), (animation ? classes.flashButton : ''))} style={customStyle} disabled={disabled}>{label}</button>
    : variant === 'text' ?
      <div className={classNames(classes.textButton, additionalClass, (disabled ? classes.disabledButton : ''), (animation ? classes.flashButton : ''))} style={customStyle} onClick={onClick}>{label}</div>
      :
      <button onClick={onClick} className={classNames(classes.filledButton, additionalClass, (disabled ? classes.disabledButton : ''), (animation ? classes.flashButton : ''))} style={customStyle} disabled={disabled}>{label}</button>);
});

MailProButton.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element
  ]).isRequired,
  additionalClass: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'filled', 'text']),
  onClick: PropTypes.func,
  customStyle: PropTypes.object,
  disabled: PropTypes.bool,
  animation: PropTypes.bool
};

export default MailProButton; 