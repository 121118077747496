import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 'auto',
        height: 'auto',
        transform: 'translateZ(0)',
    },
    fullHeight: {
        height: '100%'
    }
}));

export default function PropertyImagesGridList(props) {
    const classes = useStyles();
    const { propertyDetails = [] } = props;
    const { photos } = propertyDetails ? propertyDetails : {};
    let tileData =
        photos && Array.isArray(photos) ? photos : [];


    return (
        <div className={classes.root}>
            <GridList cellHeight={200} className={classes.gridList} cols={3}>
                {tileData.map((img, index) => (
                    <GridListTile key={index} cols={(index%4) > 0 ? 1 : 3} rows={(index%4) > 0 ? 1 : 2}>
                        <img src={img} alt={"Property Image"} className={classes.fullHeight}  />
                    </GridListTile>
                ))}
            </GridList>
        </div>
    );
}

