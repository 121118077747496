import Payment from 'payment';

export const clearNumber = (value = '') => {
    return value.replace(/\D+/g, '');
}

const formatExpiryMonth = (cvcValue) => {
    try {
        let cvcArray = cvcValue.split('/');
        let cvcMM = cvcArray[0];
        const clearValue = clearNumber(cvcMM);
        const clearYear = clearNumber();
        if (parseInt(cvcMM) > 12) {
            return '';
        } else if (cvcMM.length === 1 && parseInt(cvcMM) > 1) {
            return '0' + cvcMM + '/';
        }
        return clearValue;
    } catch (err) {
        return cvcValue;
    }
};

export const formatCreditCardNumber = (value) => {
    if (!value) {
        return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;

    switch (issuer) {
        case 'amex':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10,
            )} ${clearValue.slice(10, 15)}`;
            break;
        case 'dinersclub':
            if (clearValue.length <= 14) {
                nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                    4,
                    10,
                )} ${clearValue.slice(10, 14)}`;
            } else if (clearValue.length > 14) {
                nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                    4,
                    8,
                )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`;
            }
            break;
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                8,
            )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
            break;
    }

    return nextValue.trim();
}

export const formatCVC = (value, prevValue, allValues = {}) => {
    const clearValue = clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
        const issuer = Payment.fns.cardType(allValues.number);
        maxLength = issuer === 'amex' ? 4 : 3;
    }
    return clearValue.slice(0, maxLength);
}

export const formatExpirationDate = (value) => {
    const clearValue = clearNumber(value);
    if (clearValue.length >= 3) {
        return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }
    //return clearValue;

    return formatExpiryMonth(clearValue);
}

export const formatFormData = (data) => {
    return Object.keys(data).map(d => `${d}: ${data[d]}`);
}
