import React, { Component } from "react";
import MQThreeFirstPage from "./MQThreeFirstPage";
import MQThreeSecondPage from "./MQThreeSecondPage";

class MQThree extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./mq3print.scss");
    } else {
      require("./mq3editor.scss");
    }
  }
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      currentArea,
      MQCityDetails,
      mlsAreas,
      coverPageDetails,
      testimonials,
      quarterlyAreaTable,
      quarterlyCityTable,
      missionStatement,
      agentAdvantages,
      agentDesignations,
      demoAccountAreaName,
      demoAccountCoverPhoto,
      demoAccountCity,
      demoAccountHOName,
    } = this.props;

    return (
      <div className={printVersion ? "mq3-print-page" : "mq3-editor-page"}>
        <MQThreeFirstPage
          agentData={agentData}
          hoData={hoData}
          currentArea={currentArea}
          MQCityDetails={MQCityDetails}
          printVersion={printVersion}
          version={version}
          mlsAreas={mlsAreas}
          coverPageDetails={coverPageDetails}
          testimonials={testimonials}
          quarterlyAreaTable={quarterlyAreaTable}
          quarterlyCityTable={quarterlyCityTable}
          agentAdvantages={agentAdvantages}
          agentDesignations={agentDesignations}
          demoAccountAreaName={demoAccountAreaName}
          demoAccountCoverPhoto={demoAccountCoverPhoto}
          demoAccountHOName={demoAccountHOName}
          missionStatement={missionStatement}
        />
        <div id="back-to-top-anchor-pma-editor-page-2" />
        <MQThreeSecondPage
          agentData={agentData}
          hoData={hoData}
          currentArea={currentArea}
          MQCityDetails={MQCityDetails}
          printVersion={printVersion}
          version={version}
          mlsAreas={mlsAreas}
          quarterlyCityTable={quarterlyCityTable}
          quarterlyAreaTable={quarterlyAreaTable}
          demoAccountAreaName={demoAccountAreaName}
          demoAccountCity={demoAccountCity}
          demoAccountHOName={demoAccountHOName}
        />
      </div>
    );
  }
}

export default MQThree;
