import React, { Component } from "react";
import MQThreeSecondLeft from "./MQThreeSecondLeft";
import MQThreeSecondRight from "./MQThreeSecondRight";

class MQThreeSecondPage extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      MQCityDetails,
      printVersion,
      mlsAreas,
      version,
      quarterlyCityTable,
      quarterlyAreaTable,

      demoAccountAreaName,
      demoAccountCity,
      demoAccountHOName,
    } = this.props;

    return (
      <div
        className={
          "pma-page-container " +
          (printVersion
            ? "mq3-print-page-container"
            : "mq3-editor-page-container")
        }
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <MQThreeSecondLeft
          MQCityDetails={MQCityDetails}
          printVersion={printVersion}
          agentData={agentData}
          quarterlyAreaTable={quarterlyAreaTable}
          quarterlyCityTable={quarterlyCityTable}
          currentArea={currentArea}
          hoData={hoData}
          demoAccountAreaName={demoAccountAreaName}
          demoAccountCity={demoAccountCity}
        />
        <MQThreeSecondRight
          agentData={agentData}
          currentArea={currentArea}
          MQCityDetails={MQCityDetails}
          printVersion={printVersion}
          version={version}
          hoData={hoData}
          mlsAreas={mlsAreas}
          quarterlyAreaTable={quarterlyAreaTable}
          quarterlyCityTable={quarterlyCityTable}
          demoAccountAreaName={demoAccountAreaName}
          demoAccountHOName={demoAccountHOName}
        />
      </div>
    );
  }
}

export default MQThreeSecondPage;
