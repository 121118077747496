import React from "react";
import PmaEditor from "./PMAEditor";
import axios from "common/interceptors";

class PrintTableQuadrantsPMA extends React.Component {
  state = {
    singleHOData: null,
    agentData: null,
    mlsAreas: null,
    marketActivity: null,
    MQCityDetails: null,
    currentArea: null,
    localSellingAgents: null,
    version: "table-quadrants",
    coverPageDetails: null,
    quarterlyAreaTable: null,
    testimonials: null,
    agentDesignations: null,
    agentAdvantages: null,
    salePriceForCities: null,
    yearEnd: null,
    qArea: null,
    qCity: null,
    missionStatement: null,
    aprilData: null,
    flipBook: false,
    quadrantDetails: null,
  };

  async componentDidMount() {
    let mailProId = this.props.match.params.mailproid;
    let listgenUserID = this.props.match.params.listgenUserID;
    let brokerage = this.props.match.params.brokerage;

    const { version } = this.state;
    let url = window.location.href;

    if (url.includes("test-print")) {
      let split = url.split("/");
      listgenUserID = url[url.length - 2];
      let area = url[url.length - 1];
    }

    let date = new Date();
    let thisYear = date.getFullYear();
    const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
    const today = `${date.getDate()}`.padStart(2, 0);

    let isDemo = false;
    // if (
    //   thisArea === "DEMO-SCL-16A" ||
    //   thisArea === "DEMO-SCL-16B" ||
    //   thisArea === "DEMO-SCL-16D" ||
    //   thisArea === "DEMO-SCL-16E" ||
    //   thisArea === "DEMO-SCL-16N"
    // ) {
    //   isDemo = false;
    // }
    let diffVersionToUse;
    let usesDifferentVersion = false;
    try {
      let mailPrint = await axios
        .get(
          `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
          {
            headers: {
              "content-type": "application/json",
            },
            data: {},
          }
        )
        .then((res) => {
          const singleHOData = res.data.mailerProDTO;
          //const agentData = res.data.listgenUserDTO;
          this.setState({
            singleHOData,
            //agentData,
          });
        })
        .catch((err) => console.log("error retrieving idDetails" + err));

      let navBar = await axios
        .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
          headers: {
            "content-type": "application/json",
          },
          data: {},
        })

        .then((res) => {
          this.setState({
            mlsAreas: res.data,
          });
        })
        .catch((err) => console.log("error retrieving navbarInfo" + err));
      const thisArea = this.state.singleHOData.areaID;
      let currentArea =
        this.state.mlsAreas.filter((area) => area.mlsAreaID === thisArea)[0] ||
        this.state.mlsAreas[0];
      const { mlsAreaID, mlsCityID } = currentArea;

      const { singleHOData } = this.state;
      const { subdivisionName } = singleHOData;
      if (currentArea) {
        let getPmaDetails = await axios
          .get(
            `/agent/getPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
              subdivisionName ? subdivisionName : "-"
            }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,

            {
              headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
              data: {},
            }
          )
          .then((res) => {
            const {
              mailerPrintInfoDTO,
              lgTestmonialsDTO,
              missionStatementDTO,
              quadrantInfoList,
            } = res.data;
            const singleHOData = mailerPrintInfoDTO.mailerProDTO;
            let agentData = mailerPrintInfoDTO.listgenUserDTO;
            if (
              listgenUserID === "103" ||
              listgenUserID === "105" ||
              listgenUserID === "100037"
            ) {
              agentData.emailAddress = "sarah.sullivan@compass.com";
              agentData.brokerageName = brokerage;
            }
            this.setState({
              singleHOData,
              agentData,
              currentArea,
              coverPageDetails: res.data.pmaCoverPhotoDTO,
              quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
              quadrantDetails: quadrantInfoList,
              quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
              testimonials: lgTestmonialsDTO,
              MQCityDetails: res.data.pmaQuarterlyCityDTO,
              aprilData: {
                pmaQuarterlyAreaDTO: res.data.pmaQuarterlyAreaDTO,
                pmaQuarterlyCityDTO: res.data.pmaQuarterlyCityDTO,
              },
              bubbleBoxes: res.data.marketValueInfoList,
              testimonials: res.data.lgTestmonialsDTO,
              missionStatement: missionStatementDTO,
            });
          })
          .catch((err) =>
            console.log("error retrieving table bubble data" + err)
          );
      }
    } catch (error) {
      console.log("error with bubble compare print apis", error);
    }
  }
  render() {
    const { propertyData } = this.props;
    const {
      singleHOData,
      agentData,
      mlsAreas,
      marketActivity,
      MQCityDetails,
      currentArea,
      version,
      quarterlyCityTable,
      quarterlyAreaTable,
      localSellingAgents,
      coverPageDetails,
      testimonials,
      agentDesignations,
      agentAdvantages,
      salePriceForCities,
      yearEnd,
      qArea,
      qCity,
      aprilData,
      flipBook,
      missionStatement,
      propertyPhotoDetailsDTO,
      pmaQuarterlyCombinedCityDTO,
      quadrantDetails,
    } = this.state;
    if (
      currentArea &&
      singleHOData &&
      agentData &&
      quarterlyAreaTable &&
      quarterlyAreaTable.monthsSupplyTable &&
      coverPageDetails &&
      quadrantDetails
    ) {
      return (
        <div className="pma-creator">
          <main>
            <section>
              <PmaEditor
                propertyData={propertyData}
                printVersion={true}
                version={version}
                singleHOData={singleHOData}
                agentData={agentData}
                currentArea={currentArea}
                marketActivity={marketActivity}
                MQCityDetails={MQCityDetails}
                mlsAreas={mlsAreas}
                quarterlyAreaTable={quarterlyAreaTable}
                quarterlyCityTable={quarterlyCityTable}
                localSellingAgents={localSellingAgents}
                coverPageDetails={coverPageDetails}
                printTestimonials={testimonials}
                printAgentAdvantages={agentAdvantages}
                printAgentDesignations={agentDesignations}
                salePriceForCities={salePriceForCities}
                yearEnd={yearEnd}
                qArea={qArea}
                qCity={qCity}
                aprilData={aprilData}
                flipBook={flipBook}
                dontShowPma={false}
                missionStatement={missionStatement}
                propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
                pmaQuarterlyCombinedCityDTO={pmaQuarterlyCombinedCityDTO}
                quadrantDetails={quadrantDetails}
              />
            </section>
          </main>
        </div>
      );
    } else {
      return <div>1Loading PMA...</div>;
    }
  }
}

export default PrintTableQuadrantsPMA;
