import React, { Component } from "react";
import MQTwoFirstLeft from "./MQTwoFirstLeft";
import MQTwoFirstRight from "./MQTwoFirstRight";
import V2DebSamuel from "views/Pma/PmaEditor/CustomAgentPages/V2DebSamuel";

class MQTwoFirstPage extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      MQCityDetails,
      printVersion,
      mlsAreas,
      version,
      coverPageDetails,
      testimonials,
      demoAccountAreaName,
      quarterlyAreaTable,
      quarterlyCityTable,
    } = this.props;
    const { listgenUserID } = agentData;
    console.log("first page", this.props);
    return (
      <div
        className={
          "pma-page-container " +
          (printVersion
            ? "mq2-print-page-container"
            : "mq2-editor-page-container")
        }
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {listgenUserID === "100585" ? (
          <V2DebSamuel
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version="mq2"
            //  demoAccountHOName={demoAccountHOName}
            coverPageDetails={coverPageDetails}
          />
        ) : (
          <MQTwoFirstLeft
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            MQCityDetails={MQCityDetails}
            quarterlyAreaTable={quarterlyAreaTable}
            printVersion={printVersion}
            mlsAreas={mlsAreas}
            version={version}
            testimonials={testimonials}
            demoAccountAreaName={demoAccountAreaName}
            coverPageDetails={coverPageDetails}
            quarterlyCityTable={quarterlyCityTable}
          />
        )}
        <MQTwoFirstRight
          agentData={agentData}
          currentArea={currentArea}
          MQCityDetails={MQCityDetails}
          printVersion={printVersion}
          version={version}
          hoData={hoData}
          mlsAreas={mlsAreas}
          coverPageDetails={coverPageDetails}
          quarterlyAreaTable={quarterlyAreaTable}
        />
      </div>
    );
  }
}

export default MQTwoFirstPage;
