import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import styles from "../AnniversaryAutomator/styles";
import compose from "recompose/compose";
import { withSnackbar } from "notistack";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import classNames from "classnames";
import MailProButton from "../../UI/MailProButton";
import MailProArrowButton from "../../UI/MailProArrowButton";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { notePaperMaxWidth } from "./NotesConfig";

const useStyles = makeStyles((theme) => ({
  rootPaper: {
    maxWidth: `${notePaperMaxWidth()}px !important`,
    maxHeight: "calc(100% - 32px)",
    fontFamily: "objektiv-mk2",
  },
  rootPaperDecorative: {
    borderRadius: "0px !important",
    fontFamily: "objektiv-mk2",
    border: "1.5px solid #8EA2AD", //#757374
    margin: "16px",
    background: "#FBFBF5",
    fontFamily: "objektiv-mk2",
  },
  rootPaperRadius: {
    borderRadius: "4px !important",
  },
  backDropBackgroundLightBlue: {
    background: 'rgba(233, 247, 254, 0.8)',
  },
  reviewDialogHeader: {
    background: "#4A7396",
    padding: "16px",
    textAlign: "center",
    "&>*": {
      fontSize: "21px",
      fontWeight: "600",
      color: "white",
      letterSpacing: "0.01em",
      fontFamily: "objektiv-mk2",
    },
  },
  reviewDialogContentTitle: {
    color: "#4A7396",
    fontSize: "13.5px",
    textAlign: "center",
    fontWeight: "600",
  },
  reviewDialogContent: {
    padding: "32px",
  },
  reviewDialogContentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },
  reviewDialogGreetStart: {
    borderBottom: "1px solid #444444",
    width: "100%",
    marginBottom: "16px",
    marginTop: "16px",
    //marginLeft: "-32px",
    textTransform: "uppercase",
    "&>span": {
      //marginLeft: "32px",
    },
  },
  reviewDialogGreetEnd: {
    textAlign: "right",
    marginTop: "8px",
  },
  reviewDialogContentText: {
    fontSize: "13.5px",
    lineHeight: "17px",
    fontWeight: "400",
  },
  reviewDialogPS: {
    marginTop: "16px",
    "&>span": {
      fontWeight: 500,
    },
  },
  reviewDialogFooter: {
    width: "100%",
    display: "flex",
    padding: "16px 32px",
    alignItems: "center",
    justifyContent: "space-between",
  }
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function NoteAutomatorNoteReviewDialog(props) {
  const [open, setOpen] = React.useState(true);
  const { personalNote, personalPS, onYesClick, onNoClick, greetTextTop, signOffTextBottom, headerText } = props;
  const classes = useStyles();

  const handleEdit = () => {
    onNoClick();
    handleClose();
  };

  const handleLooksAhead = () => {
    onYesClick();
    handleClose();
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <div onClick={handleClickOpen}>{props.children}</div> */}
      {open && (
        <Dialog
          onClose={handleClose}
          maxWidth="sm"
          fullWidth={true}
          classes={{
            paper: classNames(
              classes.rootPaper,
              classes.rootPaperDecorative,
              classes.rootPaperRadius
            ),
          }}
          open={open}
          TransitionComponent={Transition}
          keepMounted
          BackdropProps={{
            classes: {
              root: classes.backDropBackgroundLightBlue
            }
          }}
        >
          <DialogTitle className={classes.reviewDialogHeader}>
            <div>
              {headerText}
            </div>
          </DialogTitle>
          <DialogContent className={classes.reviewDialogContent}>
            <div className={classes.reviewDialogContentContainer}>
              <div className={classes.reviewDialogContentTitle}>
                PLEASE DOUBLE CHECK FOR TYPOS!
              </div>
              <div>
                <div
                  className={classNames(
                    classes.reviewDialogGreetStart,
                    classes.reviewDialogContentText
                  )}
                >
                  <span>{greetTextTop}!</span>
                </div>
                <div className={classNames(classes.reviewDialogContentText, classes.wordBreakNotePS)} dangerouslySetInnerHTML={{ __html: personalNote }}></div>
                <div
                  className={classNames(
                    classes.reviewDialogGreetEnd,
                    classes.reviewDialogContentText
                  )}
                >
                  {signOffTextBottom}
                </div>
                {/* <div
                  className={classNames(
                    classes.reviewDialogContentText,
                    classes.reviewDialogPS,
                    classes.wordBreakNotePS
                  )}
                >
                  <span>P.S.:</span> {personalPS}
                </div> */}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className={classes.reviewDialogFooter}>
              <div>
                <MailProButton
                  key={"note_automator_review_edit"}
                  variant="filled"
                  label={"Edit"}
                  customStyle={{
                    fontSize: "17px",
                    fontWeight: 500,
                    color: 'white',
                    paddingTop: '4px',
                    paddingBottom: '4px'
                  }}
                  onClick={handleEdit}
                />
              </div>
              <div>
                <MailProArrowButton
                  iKey={"submit_automator_note_button"}
                  label={"Ok, Looks Correct — Next"}
                  onClick={handleLooksAhead}
                  customStyle={{
                    root: {
                      fontSize: "17px",
                      fontWeight: "500",
                    }
                  }}
                />
              </div>
              <div></div>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
//define proptypes
NoteAutomatorNoteReviewDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  personalNote: PropTypes.string.isRequired,
  personalPS: PropTypes.string.isRequired,
  onYesClick: PropTypes.func.isRequired,
  onNoClick: PropTypes.func.isRequired,
  greetTextTop: PropTypes.string.isRequired,
  signOffTextBottom: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
};

export default compose(
  withStyles(styles),
  withSnackbar
)(NoteAutomatorNoteReviewDialog);
