import React, { Component } from "react";

class AvailableSoon extends Component {
  render() {
    return (
      <div className="available-soon">
        <p>The backpage is under</p>
        <p> development and will be</p>
        <p> available soon</p>
      </div>
    );
  }
}

export default AvailableSoon;
