import React from 'react';
import { connect } from "react-redux";
import styles from "./styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import { pmaActions } from "_store/_actions";
import { MailProDialog } from '../MailProDialog/MailProDialog';
import { MailProDialogHeader } from '../MailProDialog/MailProDialogHeader';
import { MailProDialogBody } from '../MailProDialog/MailProDialogBody';
import MailProButton from '../UI/MailProButton';
import classNames from 'classnames';
import AllGroupList from './AllGroupList';


const AddHomeownerToGroup = ({ mode = 'add', classes, children }) => {
    const [open, setOpen] = React.useState(false);
    const [groupName, setCurrentGroup] = React.useState('');

    const openDialog = () => {
        setOpen(true);
    }

    const closeDialog = () => {
        setOpen(false);
    }

    const handleChange = (event) => { }

    const assingedToAnyGroup = false;

    return <div className={classes.root}>
        {children ? <div onClick={openDialog}>{children}</div> : <div></div>}
        {open && <MailProDialog open={open}>
            <MailProDialogHeader>
                <div className={classNames(classes.displayRow, classes.addHoTitle)} style={{ gap: 0 }}>
                    <div style={{ fontWeight: 700 }}>Add this Homeowner to Another Group(s)</div>
                    <div style={{ fontWeight: 700 }}>Homeowner: JOHN DOE</div>
                    <div style={{ marginTop: '16px' }}>Mail Add: 1234 Maine St, Monterey CA 93920</div>
                    <div style={{ fontWeight: 800, marginTop: '8px' }}>Site Add: 1400 Oak Meadows, Ft Worth TX 19420</div>

                </div>
            </MailProDialogHeader>
            <MailProDialogBody>
                <div>
                    {!assingedToAnyGroup && <div className={classes.notAssginedToAnyGroup}>This Homeowner has not been added to a Group yet</div>}
                    {assingedToAnyGroup ? <div className={classes.dialogSubtitle}>
                        Select up to 3 Groups for this Homeowner:
                    </div>
                    :
                    <div className={classes.dialogSubtitle}>
                    Select the Group(s) you want this homeowner to be in: (Max 3 Groups)
                    </div>}
                    <AllGroupList handleChange={handleChange} />
                </div>
                <div className={classes.footer}>
                    <div>
                        <MailProButton
                            key={"mp_new_add_group_cance;"}
                            variant="outlined"
                            label={"CANCEL"}
                            customStyle={{ fontSize: "12px", fontWeight: 500 }}
                            onClick={closeDialog}
                        />
                    </div>
                    <div>
                        <MailProButton
                            key={"mp_new_update_group"}
                            variant="filled"
                            label={"SUBMIT"}
                            customStyle={{ fontSize: "12px", fontWeight: 500 }}
                            onClick={() => { }}
                        />
                    </div>
                    <div></div>


                </div>
            </MailProDialogBody>
        </MailProDialog>}
    </div>
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles), withSnackbar)(AddHomeownerToGroup));
