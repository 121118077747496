import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";

class QVThreeCoverPageData extends Component {
  calculateDataPoint = (point) => {
    const { roundSalePrice, infinityCheck } = propertyUtil;
    const { category } = this.props;

    switch (category) {
      case "Sale Price":
      case "Highest Sale Price":
        return `${roundSalePrice(point).toLowerCase()}`;
      case "% Received of Asking":
        return `${Math.round(point)}%`;
      case "Days to Sell":
        return `${Number(point).toLocaleString()}`;
      case "Homes Sold":
        return `${Number(point).toLocaleString()}`;
      default:
        return `${infinityCheck(point)}`;
    }
  };
  determineTriangle = (perc) => {
    //MUST REMOVE THIS
    if (perc === "none") return "";
    perc = +perc;
    // if (this.props.mlsAreaID === "SBA-20K") {
    //   return <div className="data-section-top-row-balanced"></div>;
    // } else
    if (perc > 0) {
      return <div className="data-section-top-row-triangle up" />;
    } else if (perc < 0) {
      return <div className="data-section-top-row-triangle down" />;
    } else {
      return <div className="data-section-top-row-balanced">(No Change)</div>;
    }
  };
  formatCategory = (category, mlsAreaID) => {
    const { useMedian } = this.props;
    if (category === "Homes Sold" && mlsAreaID === "SBA-20FInv") {
      return "Total Properties Sold";
    } else if (category === "Homes Sold" && mlsAreaID === "SBA-20FCondo") {
      return "Total Condos Sold";
    } else if (category === "Sale Price") {
      if (useMedian) {
        return "Median Sale Price";
      } else {
        return "Average Sale Price";
      }
    } else if (category === "% Received of Asking") {
      return "Average % Received of Asking";
    } else if (category === "Days to Sell") {
      return "Average Days to Sell";
    } else if (category === "Homes Sold") {
      return "Total Homes Sold";
    } else if (category === "Highest Sale Price") {
      return "Highest Sale Price";
    }
  };
  render() {
    const { category, dataPoint, perc, mlsAreaID, agentData } = this.props;
    const { calculateDataPoint, determineTriangle } = this;
    const { roundSalePrice, infinityCheck } = propertyUtil;
    const { listgenUserID } = agentData;

    let isValidPerc;
    if (perc && perc != 0 && perc !== "none") {
      isValidPerc = true;
    } else {
      isValidPerc = false;
    }
    const formattedPerc = infinityCheck(Math.abs(+perc));

    return (
      <div className={`cover-page-body-data-section`}>
        <div className="data-section-bottom-row-category">
          {this.formatCategory(category, mlsAreaID)}
        </div>
        <div className="data-section-top-row">
          {category === "Sale Price" || category === "Highest Sale Price" ? (
            <p className="data-section-data-point inline">
              <span className="small-dollar-sign">$</span>
              {`${roundSalePrice(dataPoint, true).toLowerCase()}`}
            </p>
          ) : (
            <p className="data-section-data-point">
              {calculateDataPoint(dataPoint)}
            </p>
          )}
          {determineTriangle(perc)}
          {isValidPerc && (
            <div className="data-section-top-row-perc-diff">
              {+perc <= -100 ? "" : formattedPerc >= 100 ? ">" : ""}
              {formattedPerc}%
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default QVThreeCoverPageData;
