import React, { Component, Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Input,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from "@material-ui/core";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "views/Pma/PmaEditor/CommonEditor/common.scss";
import { withSnackbar } from "notistack";
import classNames from "classnames";
import ClearIcon from "@material-ui/icons/Clear";
import { showSuccessNotification } from "common/helpers";
import { showErrorNotification } from "common/helpers";
import { AddUpdatePmaAdmin } from "services/agent";
import { propertyUtil } from "views/Pma/PropertyUtil";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PropHighlightsAddProperty = (props) => {
  const {
    //representedOptions,
    editPropFormData,
    handleInputChange,
    handleStatusChange,
    openUploadMedia,
    onCloseFn,
    handleAddProperty,
    fromPhotoTable,
    agentData,
    radioSaleChangeFunc,
  } = props;
  //console.log("**Add property props", editPropFormData);
  const { isMemberOfTeam, agentLicenseNumber, teamMembers } = agentData;
  const teamMember1License =
    isMemberOfTeam && teamMembers[0].agentLicenseNumber;
  const teamMember2License =
    isMemberOfTeam && teamMembers[1].agentLicenseNumber;

  const { formatSuffix } = propertyUtil;

  let isListingAgent = false;

  if (
    (!isMemberOfTeam &&
      agentLicenseNumber === editPropFormData.listingAgentLicenseID) ||
    (isMemberOfTeam &&
      (teamMember1License === editPropFormData.listingAgentLicenseID ||
        teamMember2License === editPropFormData.listingAgentLicenseID)) ||
    (editPropFormData.isCoListingAgent &&
      editPropFormData.isCoListingAgent === "true") ||
    editPropFormData.coListingAgents
  ) {
    isListingAgent = true;
  }
  let isSellingAgent = false;
  if (
    (!isMemberOfTeam &&
      agentLicenseNumber === editPropFormData.sellingAgentLicenseID) ||
    (isMemberOfTeam &&
      (teamMember1License === editPropFormData.sellingAgentLicenseID ||
        teamMember2License === editPropFormData.sellingAgentLicenseID)) ||
    (editPropFormData.isCoSellingAgent &&
      editPropFormData.isCoSellingAgent === "true")
  ) {
    isSellingAgent = true;
  }
  let statusOrder = [];
  if (!editPropFormData.status || editPropFormData.status === "Sold") {
    statusOrder = ["Sold", "Pending", "Contingent", "Active"];
  } else if (editPropFormData.status === "Pending") {
    statusOrder = ["Pending", "Contingent", "Active", "Sold"];
  } else if (editPropFormData.status === "Contingent") {
    statusOrder = ["Contingent", "Active", "Sold", "Pending"];
  } else {
    statusOrder = ["Active", "Sold", "Pending", "Contingent"];
  }

  let mapOrder = [];
  if (
    (editPropFormData.isBuyingAgent && editPropFormData.isSellingAgent) ||
    (isSellingAgent && isListingAgent)
  ) {
    mapOrder = ["Buyer/Seller", "Buyer", "Seller"];
  } else if (editPropFormData.isBuyingAgent || isListingAgent) {
    mapOrder = ["Buyer", "Seller", "Buyer/Seller"];
  } else {
    mapOrder = ["Seller", "Buyer/Seller", "Buyer"];
  }
  // } else if (
  //   editPropFormData.isSellingAgent ||
  //   isSellingAgent ||
  //   (!editPropFormData.isSellingAgent && !editPropFormData.isBuyingAgentAgent)
  // ) {
  //   mapOrder = ["Seller", "N/A", "Buyer/Seller", "Buyer"];
  // }
  // else {
  //   mapOrder = ["N/A", "Buyer/Seller", "Buyer", "Seller"];
  // }

  return (
    <Fragment>
      <Dialog
        open={true}
        //onClose={onCloseFn}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
        fullWidth={true}
        TransitionComponent={Transition}
      >
        <div
          className={`${
            fromPhotoTable
              ? "photo-table-photo-edit-property-container"
              : "photo-edit-property-container"
          }`}
        >
          <div className="photo-edit-property-title-container">
            <p className="photo-edit-property-title">Add a Property</p>
            <div className="photo-edit-property-title-divider" />
            <p className="photo-edit-property-subtitle-text">
              Add a Property that was sold Off-Market or is not shown on the
              list
            </p>
          </div>
          <div className="photo-edit-property-form new-props">
            <div className="photo-edit-property-form-title-container">
              <p className="photo-edit-property-form-title-text">
                Input Property Details Here:
              </p>
              <div className="photo-edit-property-form-title-divider"></div>
            </div>
            <div
              className="photo-edit-property-radio-container"
              onChange={radioSaleChangeFunc}
            ></div>

            <div className="photo-edit-property-form-body">
              <div className="photo-edit-form-body-col">
                <div className="photo-edit-form-thead">
                  <div className="status-th">
                    <p>Status</p>
                  </div>
                  <div className="represented-th">
                    <p>Represented</p>
                  </div>
                  <div className="address-th">Address</div>
                  {!fromPhotoTable && <div className="city-th">City</div>}

                  {editPropFormData.status === "Sold" ? (
                    <div className="sale-price-th">Sale Price</div>
                  ) : (
                    <div className="sale-price-th">List Price</div>
                  )}

                  <div className="brokerage-th">
                    <p>Listing</p>
                    <p>Brokerage</p>
                  </div>
                  <div className="photo-th">
                    <p>Property</p>
                    <p>Photo</p>
                  </div>
                </div>
                <div className="photo-edit-form-input">
                  <div className="status-td">
                    <div className="radio-container">
                      <div className="radio-and-label">
                        <input
                          type="radio"
                          id="sold"
                          value="Sold"
                          checked={editPropFormData.status === "Sold"}
                          onChange={handleStatusChange}
                        />
                        <label htmlFor="sold">Sold</label>
                      </div>
                      <div className="radio-and-label">
                        <input
                          type="radio"
                          id="active"
                          value="Active"
                          checked={editPropFormData.status === "Active"}
                          onChange={handleStatusChange}
                        />
                        <label htmlFor="active">Active</label>
                      </div>
                      <div className="radio-and-label">
                        <input
                          type="radio"
                          id="pending"
                          value="Pending"
                          checked={editPropFormData.status === "Pending"}
                          onChange={handleStatusChange}
                        />
                        <label htmlFor="pending">Pending</label>
                      </div>
                      <div className="radio-and-label">
                        <input
                          type="radio"
                          id="contingent"
                          value="Contingent"
                          checked={editPropFormData.status === "Contingent"}
                          onChange={handleStatusChange}
                        />
                        <label htmlFor="contingent">Contingent</label>
                      </div>
                    </div>
                  </div>
                  {/*<div className="status-td">
                    <div
                      className={`prop-highlight-arrow-down ${
                        fromPhotoTable && "higher-prop-highlight-arrow-down"
                      }`}
                      onClick={props.handleStatusOptionsFunc}
                    ></div>
                    <div className="prop-highlight-options">
                      {statusOrder &&
                        statusOrder.map((option) => (
                          <div key={option}>{option}</div>
                        ))}
                    </div>
                  </div>
*/}
                  <div className="represented-td">
                    <div
                      className={`prop-highlight-arrow-down lower-prop-highlight-arrow-down`}
                      onClick={props.handleRepresentedOptionsFunc}
                    ></div>
                    <div className="prop-highlight-options">
                      {mapOrder &&
                        mapOrder.map((option) => (
                          <div key={option}>{option}</div>
                        ))}
                    </div>
                  </div>
                  <div className="address-input-col address-td">
                    <input
                      type="text"
                      id="streetNumber"
                      name="streetNumber"
                      value={editPropFormData.streetNumber || ""}
                      onChange={handleInputChange}
                      placeholder="Street Number"
                    ></input>
                    <input
                      type="text"
                      id="streetName"
                      name="streetName"
                      value={editPropFormData.streetName || ""}
                      onChange={handleInputChange}
                      placeholder="Street Name"
                    ></input>
                    <input
                      type="text"
                      id="streetSuffix"
                      name="streetSuffix"
                      value={editPropFormData.streetSuffix || ""}
                      onChange={handleInputChange}
                      placeholder="Street Suffix"
                    ></input>

                    <input
                      type="text"
                      id="unitNumber"
                      name="unitNumber"
                      value={editPropFormData.unitNumber || ""}
                      onChange={handleInputChange}
                      placeholder="Unit Number"
                    ></input>
                  </div>
                  {!fromPhotoTable && (
                    <div className="city-name-td">
                      <input
                        type="text"
                        id="cityName"
                        name="cityName"
                        value={editPropFormData.cityName || ""}
                        onChange={handleInputChange}
                      ></input>
                    </div>
                  )}
                  <div className="sale-price-input sale-price-td">
                    {/* <span className="sale-price-input-dollar">$</span> */}

                    {editPropFormData.status === "Sold" ? (
                      <input
                        type="text"
                        id="sale-price"
                        name="salePrice"
                        value={editPropFormData.salePrice}
                        onChange={handleInputChange}
                      ></input>
                    ) : (
                      <input
                        type="text"
                        id="list-price"
                        name="listPrice"
                        value={editPropFormData.listPrice}
                        onChange={handleInputChange}
                      ></input>
                    )}
                  </div>

                  <div className="brokerage-name-td">
                    <input
                      type="text"
                      id="brokerage-name"
                      name="brokerageName"
                      value={editPropFormData.brokerageName || ""}
                      onChange={handleInputChange}
                    ></input>
                  </div>

                  <div
                    className="photo-td"
                    onClick={(event) =>
                      openUploadMedia(1, editPropFormData.photoURL)
                    }
                  >
                    {editPropFormData.justAdded ? (
                      <div className="photo-add-photo-container add-prop">
                        <img
                          src={editPropFormData.optimizedPhotoURL}
                          alt="Home"
                        />
                      </div>
                    ) : (
                      <div className="photo-add-photo-container add-prop  photo-td">
                        <p>Click to</p>
                        <p>Upload Photo</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="edit-form-button-container">
            <div className="edit-form-cancel-button" onClick={onCloseFn}>
              <p>Cancel</p>
            </div>
            <div
              className="edit-form-submit-button"
              onClick={() => handleAddProperty(false)}
            >
              <p>Submit For all Areas</p>
            </div>
            <div
              className="edit-form-submit-button"
              onClick={() => handleAddProperty(true)}
            >
              <p>Submit For this Area Only</p>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default compose(
  withSnackbar,
  withStyles(styles)
)(PropHighlightsAddProperty);
