import React, { Fragment, useRef, useEffect } from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import classNames from "classnames";
import styles from "../AnniversaryAutomator/styles";
import { withSnackbar } from "notistack";
import compose from "recompose/compose";
import NoteAutomatorPSSelector from "./NoteAutomatorPSSelector";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  psEditorContainer: {
    marginTop: "16px",
    minHeight: "80px",
    padding: "1em",
    border: "1px solid #8EA2AD",
    borderRadius: "4px",
    fontFamily: "objektiv-mk2",
    background: '#F2EEE6',
  },
  noteTextarea: {
    "& label": {
      fontFamily: "objektiv-mk2",
    },
    "& .MuiInputBase-input": {
      fontSize: "12px",
      lineHeight: "17px",
      fontFamily: "objektiv-mk2",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid #4A7396",
    },
  },
  
  editorCharacterLimitContainer: {
    marginTop: '4px',
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&>*": {
      fontSize: "15px",
      fontStyle: "italic",
      color: "#4A7396",
    },
  },
  errorText: {
    textAlign: "center",
    fontSize: "12.5px",
    letterSpacing: "0.025em",
    marginTop: '12px'
  },
  errorLight: {
    color: red[400],
  },
}));


const NoteAutomatorPSEditor = ({
  property,
  cameFrom,
  autoOpenPSSelector,
  errorMessages,
  onChange,
  currentPS,
  psMaxLength, 
  psMinLength,
  placeholder,
  ...rest
}) => {

  const classes = useStyles();

  const updatePS = (psValue) => {
    onChange(psValue);
  };

  const isPSError =
  currentPS && currentPS.length > psMaxLength ? true : false;
  const psRef = useRef();

  return (
    <div className={classes.psEditorContainer}>
      <div>
        <TextField
          {...rest}
          value={currentPS}
          placeholder={placeholder}
          onChange={(event) => updatePS(event.target.value)}
          fullWidth={true}
          multiline={true}
          rows={1}
          rowsMax={2}
          classes={{ root: classes.noteTextarea, }}
          inputProps={{style: {fontSize: '14px', color: 'black'}}}
          error={isPSError}
          inputRef={psRef}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ color: "#000" }}>
                <span style={{fontWeight: 500}}>P.S.</span>
              </InputAdornment>
            ),
          }}
        />
        <div className={classes.editorCharacterLimitContainer}>
          <div></div>
          <div>Characters Used: {currentPS && currentPS.length || 0} / {psMaxLength}</div>
          <div>Characters Max {psMaxLength}/Min {psMinLength}</div>
        </div>
        <div>
          {(errorMessages || []).length > 0 && (
            <p className={classNames(classes.errorText, classes.errorLight)}>
              {errorMessages.join(" ")}
            </p>
          )}
        </div>
      </div>
      <div style={{ marginTop: "16px" }}>
        <NoteAutomatorPSSelector 
        defaultOpen={autoOpenPSSelector} 
        key={"aa-ps-editor"} 
        currentPS={currentPS} 
        onChange={updatePS} />
      </div>
    </div>
  );
};

//define proptypes
NoteAutomatorPSEditor.propTypes = {
  property: PropTypes.object.isRequired,
  cameFrom: PropTypes.string.isRequired,
  autoOpenPSSelector: PropTypes.bool.isRequired,
  errorMessages: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  currentPS: PropTypes.string.isRequired,
  psMaxLength: PropTypes.number.isRequired,
  psMinLength: PropTypes.number.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default compose(
  withStyles(styles),
  withSnackbar
)(NoteAutomatorPSEditor);
