import React, { Fragment, Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button, Typography, Divider, Container, TextField, CircularProgress, Grid, ListItem, Checkbox, ListItemText, ListItemIcon, Fab, List, Chip, DialogActions } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { propertyUtil } from '../../PropertyUtil';
import Slide from '@material-ui/core/Slide';
import classNames from 'classnames';
import styles from './styles';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { pmaActions } from '_store/_actions';
import { GetStateStorage } from 'common/storage';
import { renderLogicUtil } from '../../RenderLogicUtil';
import { agentBarSwitchClosureV2, MailProBlockedEditingDisabledBar, MailProCloseButton } from './MailProHTMLShortMethods';
import { RichTextEditorV2Component } from 'components';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class EditTagsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      submitted: false,
      error: "",
      isLoading: false,
      isBlocked: false,
      selected: [],
      customStateTags: [],
      emptyCheckBoxClick: null,
      customInput: "",
      isBlocked: false,
      defaultTags: [
        "Friend",
        "Acquaintance",
        "Hot Seller Lead",
        "Hot Buyer Lead",
        "Warm Seller Lead",
        "Warm Buyer Lead",
        "Neighbor",
        "Past Client",
        //"My Old Sale"
      ],
      htmlNote: '',
      plainNote: '',//tag comments
      hoName: ''
    };
    this.maxCustomTagCount = 3;
    this.maxToalTagCount = 3;
    this.maxCustomTagLength = 30;
    this.minTagNoteLength = 0;
    this.maxTagNoteLength = 1500;
    /**bind methods */
    this.updateTagNote = this.updateTagNote.bind(this);
    this.updateTagPlainNote = this.updateTagPlainNote.bind(this);
  }

  getTagsForTable = () => {
    const { tagsNotes } = this.props.changeDetails
      ? this.props.changeDetails
      : this.props.propertyDetails
        ? this.props.propertyDetails
        : {};
    const { tags, customTags } = renderLogicUtil.getTagsAndCustomTags(this.props.changeDetails)
    let tagsNotesPlain = renderLogicUtil.getTagsComments(this.props.changeDetails)
    return { tags, customTags, tagsNotes, tagsNotesPlain };
  };

  getMaxLengthTagArray = (customTags) => {
    return Array.apply(null, Array(this.maxCustomTagCount)).map((x, i) => (customTags && customTags[i] ? customTags[i] : ''));
  };

  handleClickOpen = () => {
    let { property, isMailProLocked } = this.props;
    let { tags, customTags, tagsNotes, tagsNotesPlain } = this.getTagsForTable();
    const { lgOwnerFirstName, lgOwnerLastName, lgSecondaryOwnerFirstName, lgSecondaryOwnerLastName } = property && property.changeDetails ? property.changeDetails : {};
    let hoName = `${lgOwnerFirstName}${lgOwnerLastName && lgOwnerLastName !== "-" ? ' ' + lgOwnerLastName : ''}${lgSecondaryOwnerFirstName && lgSecondaryOwnerFirstName !== "-" ? ` and ${lgSecondaryOwnerFirstName}${lgSecondaryOwnerLastName && lgSecondaryOwnerLastName !== "-" ? ` ` + lgSecondaryOwnerLastName : ''}` : ''}`;
    //assign array into custom tags
    //ma
    customTags = this.getMaxLengthTagArray(customTags);
    this.setState({
      open: true,
      submitted: false,//reset the submitted on open of modal
      selected: Array.isArray(tags) ? tags : [],
      customStateTags: Array.isArray(customTags) ? customTags : [],
      customStateTagsCopy: Array.isArray(customTags) ? customTags : [],
      isBlocked: isMailProLocked,
      htmlNote: tagsNotes ? tagsNotes : '',
      plainNote: tagsNotesPlain ? tagsNotesPlain : '',
      hoName: hoName
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      error: "",
      customInput: ""
    });

  };


  /**Old Methods */

  // onInputChange = e => {
  //   this.setState({
  //     customInput: e.target.value,
  //     error: "",
  //     submitted: false
  //   });
  // };

  async onSubmit(e) {
    const { isBlocked, plainNote, selected } = this.state;
    if (isBlocked) {
      this.setState({
        error: "Mailpro has been locked for this mailing."
      });
    } else if (plainNote && plainNote.length > this.maxTagNoteLength) {
      this.setState({
        error: `A maximum ${this.maxTagNoteLength} characters can be added for NOTE.`
      });
    } else if (selected >= this.maxToalTagCount) {
      this.setState({
        error: `You may only select up to ${this.maxToalTagCount} tags.`,
        submitted: false
      });
    } else {
      this.setState(
        {
          isLoading: true
        },
        async () => {
          setTimeout(async () => {
            const { selected, customStateTags, htmlNote } = this.state;
            const {
              areaID,
              mailerProID,
              mailingDetails,
              propertyDetails,
              changeDetails,
              agentID,
              mailingStatus,
              imbarcode,
              isPropertyMapped,
              numericIMBarcode,
              parcelNumber,
              propertyData,
            } = this.props;
            let isEmptySelected;
            if (selected && selected.length >= 1) {
              isEmptySelected = selected;
            } else {
              isEmptySelected = null;
            }

            let isEmptyCustomTags;
            if (customStateTags && customStateTags.length >= 1) {
              //remove the empty tags
              isEmptyCustomTags = customStateTags.filter(item => item !== '');
            } else {
              isEmptyCustomTags = null;
            }
            let updatedChangeDetails = Object.assign({}, changeDetails, {
              tags: isEmptySelected,
              customTags: isEmptyCustomTags,
              tagsNotes: htmlNote
            });

            let actualHomeowners = propertyUtil.getActualHomeowners(propertyData);
            let agentList = propertyUtil.getActualAgents(propertyData).length;
            let unblockedHO = propertyUtil.getActualUnblockedHomeowners(
              actualHomeowners
            ).length;
            //combines the unblocked homeowners + ALL the agents, this will be the total number of pma's we are sending out
            let totalUnblocked = agentList + unblockedHO;
            let userData = GetStateStorage();

            const hoData = {
              mailerProID,
              areaID,
              agentID,
              mailingStatus,
              changeDetails: updatedChangeDetails,
              listgenUserID: userData.userID ? userData.userID : "",
              numOfPMAs: totalUnblocked
            };

            await this.props.updateHomeownerProp(hoData);
            /**reset the header also */
            this.props.resetMailproCurrentHeaderSort();

            setTimeout(async () => {
              await this.props.updateRecentUpdatedMailProId(mailerProID);
            }, 10);

            this.setState(
              {
                isLoading: false,
                submitted: true,//it should be true. table will only update if its true
                error: ""
              },
              () => {
                setTimeout(() => {
                  this.handleClose();
                }, 1000);
              }
            );
          }, 300);
        }
      );
    }
  };

  onCheckboxChange = (key, tag) => {
    let { selected } = this.state;
    /**check for selected tag */
    selected = selected ? selected : [];
    let currentIndex = selected.indexOf(tag);
    // if (selected >= this.maxToalTagCount) {
    //   this.setState({
    //     error: `You may only select up to ${this.maxToalTagCount} tags.`,
    //     submitted: false
    //   });
    // } else {
    this.setState(prevState => {
      return {
        selected: (tag !== '' ? (currentIndex === -1 ? prevState.selected.concat(tag) : prevState.selected.filter(x => x !== tag)) : prevState.selected),
        emptyCheckBoxClick: (tag === '' ? key : null),
        error: "",
        submitted: false
      }
    });
    //}
  };

  handleCustomTagValue = (key, value, selectedIndex) => {
    /**
     * We want to auto select custom tag if agne tis typing and count should eb less then 3. Remember agent can edit the tag also
     */
    const { customStateTags, selected } = this.state;
    let customStateTagsT = Array.from(customStateTags);
    let selectedTags = Array.from(selected);
    customStateTagsT[key] = (value ? value : '');

    //now if it previously selected and now we are adding it abck then we have to update in selected tags
    if (selectedIndex !== -1 && value !== '') {
      selectedTags[selectedIndex] = value;
    } if (selectedIndex !== -1 && value === '') {
      //delete from the selected array because value is empty noe so unselect
      selectedTags = selectedTags.filter((item, key) => key !== selectedIndex);
    } else if (selectedIndex === -1 && selected.length < this.maxToalTagCount && value !== '') {
      //this is a case when either agent just start typing the tag / or editing back. we will autoa ssing in both case if tag is not empty and total count is less than max count
      selectedTags.push(value);
    }
    this.setState({
      customStateTags: customStateTagsT,
      emptyCheckBoxClick: null,//no empty checkbox click from custom auto input values
      selected: selectedTags,
      error: "",
      submitted: false
    });
  };

  isDisabled = tag => {
    const { selected } = this.state;
    if (selected && selected.length >= this.maxToalTagCount && !selected.includes(tag)) {
      return true;
    } else {
      return false;
    }
  };

  updateTagNote = (htmlData, plainData) => {
    this.setState({
      htmlNote: htmlData,
      plainNote: plainData
    });
  };

  updateTagPlainNote = (plainData) => {
    this.setState({
      plainNote: plainData,
    });
  };

  deleteTag = tag => {
    const { customStateTags, selected, isBlocked } = this.state;
    let filteredTags = customStateTags.filter(item => item !== tag);
    let selectedFiltered = (selected ? selected : []).filter(
      item => item !== tag
    );

    if (isBlocked) {
      this.setState({
        error: "Mailpro has been locked for this mailing."
      });
    } else {
      this.setState(
        {
          customStateTags: filteredTags,
          selected: selectedFiltered,
          error: ""
        },
        () => {
          //this.onSubmit();
        }
      );
    }
  };

  render() {
    const { classes, propertyDetails, agentData } = this.props;
    const { open, submitted, error, stateBlocked, isBlocked, isLoading, selected, customStateTags, customInput, defaultTags, emptyCheckBoxClick, htmlNote, hoName } = this.state;
    /**My Sold tag mapping */
    let xAutoTag = renderLogicUtil.getAutoTagMapping(
      propertyDetails,
      agentData
    );
    /**Temp selected tags for the table */
    const { tags, customTags, tagsNotesPlain } = this.getTagsForTable();//copy of selected and customState tags
    /**chip common */
    const tagGenerator = (i, tag, tagClassName) => {
      return (
        <div key={i} className={classes.tagsDefaulltMapSection}>
          <div>
            <Checkbox
              checked={selected && selected.includes(tag)}
              onChange={() => this.onCheckboxChange(i, tag)}
              name={tag}
              disabled={this.isDisabled(tag)}
              color="primary"
            />
          </div>
          <div>
            <Chip
              label={tag}
              size="small"
              className={`tagpill ${tagClassName} ${tag.split(" ").join("").toLowerCase()}`}
            />
          </div>
        </div>
      );
    };
    return (
      <Fragment>
        {this.props.children ? (
            <div onClick={this.handleClickOpen}>
              {this.props.children}
            </div>
          ) : (<div className={classNames("tableTdFlex", {
          [classes.borderPadding44]: (tagsNotesPlain && tagsNotesPlain !== ''),
          [classes.lightYellowBackground]: (tagsNotesPlain && tagsNotesPlain !== ''),
        })}>
          <div className="tableTdFlex-First textCursor">
            {/* No need to check tagspill length two times */}
            {tags && tags.length ? (
              <div className="tags-table-body">
                {tags.map((tag, i) => {
                  return (
                    <div key={i} className={`tagpill ${(
                      (customTags && customTags.includes(tag)) ? 'custom-tag' : tag.split(" ").join("").toLowerCase()
                    )}`}>
                      <div>{tag}</div>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            {xAutoTag.map((tag, i) => {
              return (
                <div key={"my-sold-0-" + i} className={`tagpill mylisting`}>
                  <div>{tag}</div>
                </div>
              );
            })}
            {tagsNotesPlain && tagsNotesPlain !== '' ?
              <p className={classNames("pointer", classes.redColor)} style={{ marginTop: '8px' }} onClick={this.handleClickOpen}>
                You have added<br />COMMENTS <br />for this Homeowner
              </p>
              : ''}
          </div>
          <div className="tableTdFlex-Second pointer" onClick={this.handleClickOpen}>

            <p
              className="pointer mailProLinkClass blue-text"
            >
              Tag<br />This Homeowner
            </p>
          </div>
        </div>)}
        {open ?
          <Dialog
            open={true}
            onClose={this.handleClose}
            scroll={"paper"}
            aria-labelledby="propert-view-dialog"
            aria-describedby="propert-view-dialog-description"
            maxWidth={'lg'}
            TransitionComponent={Transition}
            keepMounted
            fullWidth
          >
            <DialogTitle id="scroll-dialog-title">
              <div className={classes.propertyFactHeader}>
                <div></div>
                <div>
                  <Typography variant="h4" align="center">
                    Homeowner TAGS &amp; COMMENTS
                  </Typography>
                  <Typography variant="body1" align="center">
                    Select up to <span className={classes.lightBoldText}>{this.maxToalTagCount} TAGS</span> to assign to this homeowner.
                  </Typography>
                </div>
                <MailProCloseButton onClick={this.handleClose} />
              </div>
            </DialogTitle>
            {/* Ho Dynamic section html begin */}
            {agentBarSwitchClosureV2(classes).call(this)}
            {/* Ho Dynamic section html end */}
            <DialogContent dividers={true} className={classes.dialogContentPadding}>
              <div>
                <div
                  className={classes.propertyDetailsModal}
                //onClick={props.toggleDetailsModal}
                >
                  <MailProBlockedEditingDisabledBar
                  isBlocked={isBlocked}
                  classes={classes}
                  />
                  <Container>
                    <Fragment>
                      <Grid container className={classes.grid}>
                        <Grid item lg="5" md="5" sm="12" xs="12">
                          {/* <Typography variant="subtitle2">
                            System defined tags:
                              </Typography> */}
                          <Typography variant="subtitle2" align="center" className={classNames(classes.bottom1Margin, {
                            [classes.greenColor]: selected.length === this.maxToalTagCount
                          })}>
                            {selected ? selected.length : "0"}/{this.maxToalTagCount} selected
                          </Typography>
                          {/* //default tags mapping */}
                          <div className={classes.tagsDefaulltMapGrid}>
                            {defaultTags.map((tag, i) => {
                              return tagGenerator(i, tag, 'noborderchip');
                            })}
                          </div>

                          {/* //auto tags */}
                          <div className={classes.tagsDefaulltMapGrid}>
                            {xAutoTag.map((tag, i) => {
                              return (
                                <div key={i} className={classes.tagsDefaulltMapSection}>
                                  <div>
                                    <Checkbox
                                      checked={true}
                                      name={tag}
                                      disabled={true}
                                      color="primary"
                                    />
                                  </div>
                                  <div>
                                    <Chip
                                      label={tag}
                                      size="small"
                                      className={`tagpill ${tag.split(" ").join("").toLowerCase()}`}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          {customStateTags && customStateTags.map((tag, i) => {
                            return (
                              <div key={i} className={classes.tagsDefaulltMapSection}>
                                <div>
                                  <Checkbox
                                    checked={selected && selected.includes(tag)}
                                    onChange={() => this.onCheckboxChange(i, tag)}
                                    name={tag}
                                    disabled={this.isDisabled(tag)}
                                    color="primary"
                                  />
                                </div>
                                <div className={classNames(classes.tagCustomContDiv, {
                                  [classes.tagCustomContDivEdit]: true,//(tag === ''),//(openCustomTagTextboxKey === i)
                                })}>
                                  {/* {tag !== '' ?
                                    <Chip
                                      label={tag}
                                      size="small"
                                      color="secondary"
                                      onDelete={this.deleteTag.bind(this, tag)}
                                      className={`tagpill custom-tag ${tag.split(" ").join("").toLowerCase()}`}
                                    />
                                    : */}
                                  {/* // (openCustomTagTextboxKey === i ? */}
                                  <Fragment>
                                    <div>
                                      <TextField
                                        label="Type your own custom tag..."
                                        id={`outlined-ho-first-name-${i}`}
                                        className={classNames(classes.textField, classes.dense0margin)}
                                        margin="dense"
                                        variant="standard"
                                        name="newNickname1"
                                        inputProps={{ maxLength: this.maxCustomTagLength }}
                                        value={tag}
                                        size="small"
                                        onChange={event => this.handleCustomTagValue(i, event.target.value, selected.indexOf(tag))}
                                        error={emptyCheckBoxClick === i}
                                        helperText={(emptyCheckBoxClick === i) ? "Please enter custom tag first to select" : ""}
                                      />
                                    </div>
                                    <div>
                                      <Typography variant="caption" align="center" className={classes.characterCounterText}>
                                        Character count {tag && tag.length ? tag.length : 0}/{this.maxCustomTagLength}
                                      </Typography>
                                    </div>
                                  </Fragment>
                                  {/* } */}
                                  {/* )} */}
                                  {/* : <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onClick={event => this.setState({ openCustomTagTextboxKey: i })}
                                      >
                                        {tag !== '' ? 'Edit' : 'Create'} Custom Tag
                                    </Button>
                          } */}
                                  {/* } */}
                                </div>
                              </div>
                            );
                          })
                          }
                        </Grid>
                        <Divider orientation="vertical" flexItem className={classes.cMADivmDivider} />
                        <Grid item lg="6" md="6" sm="12" xs="12">
                          <div className={classNames(classes.field, classes.fieldMarginTop, classes.fieldMarginBottom)}>
                            <Typography variant="subtitle2" className={classes.chooseResonText}>
                              Add comments for {hoName}:
                              {/* Add notes for Homeowner: */}
                            </Typography>
                          </div>
                          <Divider />
                          <div className={classes.customTextareaSection}>
                            <div className={classNames(classes.field, classes.fieldMarginTop)}>
                              <RichTextEditorV2Component
                                preText={''}
                                postText={''}
                                //placeHolder={"Please enter GLOBAL NOTE, which will be sent to all homeowners who do not have a personal note assigned to them."}
                                minLength={this.minTagNoteLength}
                                maxLength={this.maxTagNoteLength}
                                onChange={this.updateTagNote}
                                updatePlainNoteFn={this.updateTagPlainNote}
                                preData={htmlNote}
                                placeholder={'Type any comments you want to add for this homeowner...'}//Type any notes you want to save for this homeowner...
                                readOnly={false}
                                type={'comment'}
                                category={"comment"}
                              //activateButtonFn={this.handleSubmitButton}
                              />
                            </div>
                          </div>
                          <Divider />
                        </Grid>
                      </Grid>
                      {/* } */}
                    </Fragment>
                  </Container>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Fragment>
                {isLoading ? (
                  <div className={classNames(classes.fullWidth, classes.cProgressStopBlinking)}>
                    <CircularProgress className={classes.cProgress} />
                  </div>
                ) : (
                  <div className={classes.portletFooterWithMessageDiv}>
                    {(submitted || error) ? <div className={classes.fullWidthMessageDiv}>
                      {submitted && (
                        <Typography variant="subtitle2" align="center">
                          <div
                            className={classNames(
                              classes.greenColor,
                              classes.centerAlign,
                              classes.lightBoldText
                            )}
                          >
                            Submitted Successfully!
                          </div>
                        </Typography>
                      )}
                      {error && (
                        <Typography variant="subtitle2" align="center" style={{ margin: '8px' }}>
                          <div
                            className={classNames(
                              classes.darkRedColor,
                              classes.centerAlign,
                              classes.lightBoldText
                            )}
                          >
                            {error}
                          </div>
                        </Typography>
                      )}
                    </div>
                      : ''}
                    <div className={classes.portletFooter}>
                      <div className={classes.footerButtons}>
                        <div className={classes.footerButtonsLeftRight}>
                          <Button
                            variant="outlined"
                            color="default"
                            onClick={event => this.handleClose(event)}
                          >
                            Cancel
                          </Button>
                        </div>
                        <div className={classes.footerButtonsCenter}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={stateBlocked === ''}
                            onClick={event => this.onSubmit(event)}
                          >
                            Submit
                          </Button>
                          {/* ) : ''} */}
                        </div>
                        <div className={classes.footerButtonsLeftRight}></div>
                      </div>
                    </div>
                  </div>)
                }
              </Fragment>

            </DialogActions>
          </Dialog>
          : ''}
      </Fragment>
    );
  };
};

function mapStateToProps(state) {
  return {
    currentArea: state.pma.currentArea,
    mlsAreas: state.pma.mlsAreas,
    propertyData: state.pma.propertyData,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateHomeownerProp: hoData => {
      dispatch(pmaActions.updateHomeownerProp(hoData));
    },
    resetMailproCurrentHeaderSort: () => {
      dispatch(pmaActions.resetMailproCurrentHeaderSort());
    },
  updateRecentUpdatedMailProId: (mailerProID) => {
    dispatch(pmaActions.updateRecentUpdatedMailProId(mailerProID));
  }
}
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withStyles(styles))(EditTagsDialog));