import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import moment from "moment";
import Seashore from "./Seashore.png";

class QVTwoTopSales extends Component {
  getMonthAndYearString = (inputTime) => {
    try {
      return moment(inputTime).format("MMM D, YYYY");
    } catch (err) {
      return "";
    }
  };
  calculatePricePerSqft = (calculation, saleP, sqft) => {
    if (calculation === "-") {
      let res = Math.round(saleP / sqft);
      return res.toLocaleString();
    } else {
      return Math.round(calculation).toLocaleString();
    }
  };

  render() {
    const {
      getPropView,
      formatBaths,
      toAcre,
      getSubdivisionName,
    } = propertyUtil;
    // console.log(this.props);
    const { currentArea, qArea, hoData } = this.props;
    const { subdivisionName } = hoData;
    const {
      currentYearSoldListingsCount,
      pastSecondYearSoldListingsCount,
    } = qArea;
    const { mlsAreaID, mlsNeighborhood } = currentArea;
    let areaTitle = getSubdivisionName(subdivisionName, mlsNeighborhood, true);
    let sorted =
      qArea && qArea.pinkBoxDataArea.sort((a, b) => b.salePrice - a.salePrice);
    const tableHeader = (
      <thead>
        <tr>
          <th>Sale Date</th>
          <th>Address</th>
          <th>List Price</th>
          <th>Sale Price</th>
          <th>
            <p>Days on</p>
            <p>Market</p>
          </th>
          <th>
            <p>Beds</p>
            <p>Baths</p>
          </th>
          <th>
            <p>Living</p>
            <p>SqFt</p>
          </th>
          <th>
            <p>Lot Size</p>
          </th>
          <th>View</th>
        </tr>
      </thead>
    );
    let currentSolds = sorted.slice(0, 7).map((list, i) => (
      <tr key={`${list.address} ${list.listPrice} ${list.saleDate} ${i}`}>
        {list.saleDate === "-" ? (
          <td></td>
        ) : (
          <td>{this.getMonthAndYearString(list.saleDate)}</td>
        )}
        <td>{list.address}</td>
        <td>${Number(list.listPrice).toLocaleString()}</td>
        <td>
          <p>
            {list.salePrice === "-"
              ? "—"
              : `$${Number(list.salePrice).toLocaleString()}`}
          </p>
        </td>
        <td>{list.daysOnMarket}</td>
        <td>{formatBaths(list.bedBaths)}</td>
        <td>{Math.round(list.squareFeet).toLocaleString()}</td>
        <td>{toAcre(list.lotSqFt, false, false, true)}</td>
        <td>{getPropView(list.view) ? getPropView(list.view) : "—"}</td>
      </tr>
    ));
    return (
      <div className="qv2-top-sales">
        <img className="top-sales-img" src={Seashore} alt="Seashore" />
        <div className="top-sales-content">
          <div className="top-sales-title-container">
            <div className="top-sales-title-section">
              <p>Top Sales Prices 2020</p>
            </div>
            <div className="top-sales-title-divider"></div>
            <div className="top-sales-subtitle">{areaTitle}</div>
          </div>
          <div className="top-sales-table-and-footer">
            <table className="regular-sales-table">
              {tableHeader}
              <tbody>{currentSolds}</tbody>
            </table>
            <div className="top-sales-footer">
              <p>Total Units Sold in 2020: {currentYearSoldListingsCount}</p>
              <p className="top-sales-footer-lowercase">vs</p>{" "}
              <p>2019: {pastSecondYearSoldListingsCount}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QVTwoTopSales;
