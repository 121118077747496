import React, { Component } from 'react';
import { propertyUtil } from 'views/Pma/PropertyUtil';

import SothebysLogo from '_img/brokerage-logos/sothebys-brokerage-logo.png';
import OverTheMoonLogo from '_img/brokerage-logos/over-the-moon-brokerage-logo.png';
import ShankleLogo from '_img/brokerage-logos/shankle-brokerage-logo.png';
import TownNCountryLogo from '_img/brokerage-logos/town-n-country-brokerage-logo.png';
import ColdwellBankerLogo from '_img/brokerage-logos/coldwell-banker-stacked-brokerage-logo.png';
import StackedLuxuryColdwellBankerLogo from '_img/brokerage-logos/coldwell-banker-stacked-luxury-brokerage-logo.png';
import DPGLogo from '_img/brokerage-logos/dpg-brokerage-logo.png';
import BetterHomesLogo from '_img/brokerage-logo-white-bg/better-homes-brokerage-logo.jpg';
import KellerWilliamsLogo from '_img/brokerage-logos/fallon-brokerage-logo.png';

class YECoverPageInfo extends Component {
  phoneCircleDivider = num => {
    return (
      <div className="phone-num-row">
        {num.slice(0, 3)}
        <div className="phone-circle-divider" />
        {num.slice(3, 6)} <div className="phone-circle-divider" />
        {num.slice(6)}
      </div>
    );
  };
  render() {
    const {
      listgenUserFirstName,
      listgenUserLastName,
      agentDesignation,
      mobilePhoneNo,
      agentLicenseNumber,
      isMemberOfTeam,
      teamMembers,
      brokerageName,
    } = this.props.agentData;
    const { phoneCircleDivider } = this;
    const { blackBorder } = propertyUtil;
    let fullName = `${listgenUserFirstName} ${listgenUserLastName}`;
    let secondAgent = 'agent';
    if (isMemberOfTeam) {
      secondAgent = `${teamMembers[1].firstName} ${teamMembers[1].lastName}`;
    }
    let smallerNameAndPhoneFont =
      fullName.length > 18 || secondAgent.length > 18 ? true : false;
    // console.log('smaller name', smallerNameAndPhoneFont);
    return (
      <div className="ye-cover-page-info">
        <div className="transparent-inner-container">
          <div
            className={'agent-info-section ' + (isMemberOfTeam ? 'team' : '')}
          >
            {isMemberOfTeam ? (
              <div className="team-info-col">
                <div className="team-agent-container">
                  <div className="team-agent-col">
                    <div className="team-agent-and-phone">
                      <div
                        className={
                          'team-agent-name ' +
                          (smallerNameAndPhoneFont
                            ? 'smaller-name-and-phone'
                            : '')
                        }
                      >
                        {listgenUserFirstName} {listgenUserLastName}
                      </div>
                      <div className="team-vertical-divider" />
                      <div
                        className={
                          'team-agent-phone ' +
                          (smallerNameAndPhoneFont
                            ? 'smaller-name-and-phone'
                            : '')
                        }
                      >
                        {phoneCircleDivider(mobilePhoneNo)}
                      </div>
                    </div>
                    <div className="team-sub-info">
                      <div className="team-designation-and-dre">
                        <p
                          className={`designation-text ${
                            smallerNameAndPhoneFont ? 'smaller-designation' : ''
                          } `}
                        >
                          {agentDesignation}
                        </p>
                        {/* <div className="team-designation-divider">|</div> */}
                        <div className="dre-row">
                          <p className="dre-word">dre</p>{' '}
                          <p className="dre-num">#{agentLicenseNumber}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="team-agent-container">
                  <div className="team-agent-col">
                    <div className="team-agent-and-phone">
                      <p
                        className={
                          'team-agent-name ' +
                          (smallerNameAndPhoneFont
                            ? 'smaller-name-and-phone'
                            : '')
                        }
                      >
                        {teamMembers[1].firstName} {teamMembers[1].lastName}
                      </p>
                      <div className="team-vertical-divider" />
                      <div
                        className={
                          'team-agent-phone ' +
                          (smallerNameAndPhoneFont
                            ? 'smaller-name-and-phone'
                            : '')
                        }
                      >
                        {phoneCircleDivider(teamMembers[1].phone)}
                      </div>
                    </div>
                    <div className="team-sub-info">
                      <div className="team-designation-and-dre">
                        <p
                          className={`designation-text ${
                            smallerNameAndPhoneFont ? 'smaller-designation' : ''
                          } `}
                        >
                          {teamMembers[1].agentDesignation}
                        </p>
                        {/* <div className="team-designation-divider">|</div> */}
                        <div className="dre-row">
                          <p className="dre-word">dre</p>
                          <p className="dre-num">
                            {' '}
                            #{teamMembers[1].agentLicenseNumber}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="agent-info-col">
                <div className="agent-and-phone">
                  <p
                    className={
                      'agent-name ' + (fullName.length > 16 ? 'small' : '')
                    }
                  >
                    {listgenUserFirstName} {listgenUserLastName}
                  </p>
                  <div className="vertical-divider" />
                  <div
                    className={
                      'agent-phone ' + (fullName.length > 16 ? 'small' : '')
                    }
                  >
                    {phoneCircleDivider(mobilePhoneNo)}
                  </div>
                </div>
                <div className="designation-and-dre">
                  <p
                    className={`agent-designation ${
                      smallerNameAndPhoneFont ? 'smaller-agent-designation' : ''
                    } `}
                  >
                    {agentDesignation}
                  </p>
                  <p className="dre-word">DRE</p>
                  <p className="dre-num"> # {agentLicenseNumber}</p>
                </div>
              </div>
            )}
            <div
              className={`agent-info-box-logo ${brokerageName.includes(
                'Keller Williams'
              ) && 'longer-agent-info-box-logo'}`}
            >
              {brokerageName && brokerageName.includes('Sotheby') ? (
                <img
                  className="info-box-sothebys-logo"
                  src={SothebysLogo}
                  alt="Sothebys Brokerage Logo"
                />
              ) : brokerageName.includes('Moon') ? (
                <img
                  src={OverTheMoonLogo}
                  className="info-box-over-the-moon-logo"
                  alt="Over The Moon Brokerage Logo"
                />
              ) : brokerageName.includes('Shankle') ? (
                <img
                  src={ShankleLogo}
                  className="info-box-shankle-logo"
                  alt="Shankle Brokerage Logo"
                />
              ) : brokerageName.includes('Country') ? (
                <img
                  src={TownNCountryLogo}
                  className="info-box-town-n-country-logo"
                  alt="Town n Country Logo"
                />
              ) : brokerageName.includes('Better') &&
                brokerageName.includes('Homes') ? (
                <img
                  src={BetterHomesLogo}
                  className="info-boxbetter-homes-logo"
                  alt="Better Homes and Gardens Logo"
                />
              ) : brokerageName.includes('Coldwell') &&
                agentLicenseNumber === '01181084' ? (
                <img
                  src={StackedLuxuryColdwellBankerLogo}
                  className="info-box-coldwell-banker-logo"
                  alt="Coldwell Banker Logo"
                />
              ) : brokerageName.includes('Coldwell') ? (
                <img
                  src={ColdwellBankerLogo}
                  className="info-box-coldwell-banker-logo"
                  alt="Coldwell Banker Logo"
                />
              ) : brokerageName.includes('Davi') ? (
                <img
                  src={DPGLogo}
                  className="info-box-dpg-logo"
                  alt="Davi Properties Group Logo"
                />
              ) : brokerageName.includes('Keller Williams') ? (
                <img
                  src={KellerWilliamsLogo}
                  className="info-box-kw-logo"
                  alt="Keller Williams Logo"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default YECoverPageInfo;
