import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    footerButtons: {
        position: 'relative',
        display: 'flex',
    },
    portletFooter: {
        display: 'inline-block',
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '100%'
    },
    footerButtonsCenter: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    footerButtonsLeftRight: {
        flex: 1,
    },
}));

/**@deprecated use CommonConfirmationDialogV2 */
export default function CommonConfirmationDialog(props) {

    const { title, subtitle, confirmationID, closeFn, methodToCallOnConfirmation, methodToCallOnCancel, confirmationButtonText, cancelButtonText, stopAutoConfirmationFnExecution, className } = props;
    const [open, setOpen] = React.useState(true);

    /**classes */
    const classes = useStyles();

    const handleClose = () => {
        closeFn();
        //pass the confirmation id in normal mode only. not require on click of yes/no
        methodToCallOnConfirmation(confirmationID);
    };

    const handleConfirmationClick = () => {
        /**call the close method first */
        closeFn();
        methodToCallOnConfirmation();
    };

    const handleCancelClick = () => {
        /**call the close method first */
        closeFn();
        methodToCallOnCancel();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={closeFn}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={className || {}}
            >
                <DialogTitle id="cc-alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {subtitle}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className={classes.portletFooter}>
                        <div className={classes.footerButtons}>
                            <div className={classes.footerButtonsLeftRight}>
                                <Button
                                    variant="outlined"
                                    onClick={event => stopAutoConfirmationFnExecution ? handleCancelClick(): closeFn()}
                                    color="default"
                                >
                                    {cancelButtonText ? cancelButtonText : 'Cancel'}
                </Button>
                            </div>
                            <div className={classes.footerButtonsCenter}>
                                <Button
                                    variant="contained"
                                    onClick={event => stopAutoConfirmationFnExecution ? handleConfirmationClick(): handleClose()}//in handle close we are passing confirmation method
                                    color="primary"
                                >
                                    {confirmationButtonText ? confirmationButtonText : 'Yes, Proceed'}
                </Button>
                            </div>
                            <div className={classes.footerButtonsLeftRight}></div>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
