import { homeConstants } from '../../_constants';

const initState = {
  availableCountyAreas: 0
};

export function homeReducer(state = initState, action) {
  switch (action.type) {
    case homeConstants.UPDATE_AVAILABLE_AREA_COUNT:
      return {
        ...state,
        availableCountyAreas: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
