export default theme => ({
    root: {
        height: 'auto',
        width: '100%',
    },
    grid: {
        width: '100%',
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center'
    },
    field: {
        //padding: theme.spacing(3)
        margin: theme.spacing(3)
    },
    formControl: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '100%',
        maxWidth: '100%',
        //marginRight: theme.spacing(3)
    },
    dropdownTopMargin: {
        marginTop: -theme.spacing(3)
    },
    dropdownTopMargin6Unit: {
        marginTop: -theme.spacing(5)
    },
    dropdownTopMargin1UnitPlus: {
        marginTop: theme.spacing(1)
    },
    fieldError: {
        color: theme.palette.error.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    expiryDate: {
        width: '100%',
    },
    cvcNumber: {
        width: '100%'
    },
    cardHolder: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
    }
});