import { Button } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { Alert } from "@material-ui/lab";
import React, { useRef } from "react";

export const MailProErrorFormatter = ({errors = [], style = {fontFamily : 'objektiv-mk2'}}) => {
  const alertRef = useRef();
  if (!Array.isArray(errors) || !errors.length) {
    return null;
  }
  return (
    <Alert
      ref={alertRef}
      severity="error"
      variant="outlined"
      style={{ ...{ marginTop: "12px", marginBottom: "12px" }, ...style }}
      closeText={"Close"}
      //onClose={}
      action={<Button style={{background: red[600], color: 'white'}} onClick={() => {
        alertRef && (alertRef.current.style.display = "none");
      }}>Close</Button>}
    >
      <p style={{ marginBottom: "8px", fontWeight: 500 }}>
        Before we proceed, We noticed the following errors:{" "}
      </p>
      <ul>
        {errors.map((error) => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    </Alert>
  );
};
