import React, { useEffect, useState, Fragment, useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles, Typography } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import styles from "./styles";

const LegalTermsCompass = (props) => {
  const {
    classes,
    className,
    agentFirstName,
    agentLastName,
    navbarInfo,
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <Typography variant="body1" className={classes.headerText}>
          SERVICES AGREEMENT
          <br />
          BETWEEN
          <br />
          LISTGEN, INC. a California Corporation
          <br />
          AND
          <br />
          {agentFirstName !== "" &&
          agentFirstName !== null &&
          agentLastName !== "" &&
          agentLastName !== null
            ? "<LICENSEE> (the “Licensee”) (" +
              (agentFirstName + " " + agentLastName) +
              ")"
            : "<LICENSEE> (the “Licensee”)"}
        </Typography>
      </div>
      <div className={classes.tcText}>
        <div className={classNames(classes.section, classes.section0)}>
          <Typography variant="body1">
            This Service Agreement{" "}
            <span className={classes.underlineOnly}>
              replaces and supersedes
            </span>{" "}
            the previous Service Agreement that is presented in the ListGen
            registration screen.
          </Typography>
        </div>
        <div className={classNames(classes.section, classes.section1)}>
          <Typography variant="body1">
            THIS SERVICES AGREEMENT (the “AGREEMENT”) is entered into by and
            between ListGen, Inc, a California Corporation (“ListGen”), and{" "}
            {"<LICENSEE>"} (the “Licensee”), which may collectively be referred
            to as the “Parties”.
          </Typography>
        </div>
        <div className={classNames(classes.section, classes.section2)}>
          <Typography variant="body1">
            WHEREAS, Licensee would like to have ListGen perform certain
            services on behalf of Licensee; and
          </Typography>
        </div>
        <div className={classNames(classes.section, classes.section3)}>
          <Typography variant="body1">
            WHEREAS, ListGen has the experience, capabilities, and expertise
            necessary to perform the services contemplated by this AGREEMENT.
          </Typography>
        </div>
        <div className={classNames(classes.section, classes.section4)}>
          <Typography variant="body1">
            NOW THEREFORE, in consideration of the mutual agreements contained
            herein, the Parties agree as follows:
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section5,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>
              1. Term of Agreement.
            </span>{" "}
            The term of this Agreement shall begin as of the last date of
            signature by the Parties (the “EFFECTIVE DATE”), and shall continue
            for one (1) year. The Agreement shall automatically renew unless
            Licensee provides ListGen with written notification of non-renewal
            within sixty (60) days prior to the termination date.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>2. Exclusivity fee.</span>{" "}
            Buyer shall have exclusive rights to the following geographic areas
            (neighborhood(s):
            {Array.isArray(navbarInfo) && navbarInfo.length > 0 ? (
              <Fragment>
                <span className={classes.areaNames}>
                  {navbarInfo.map((item, key) => {
                    return (
                      <span key={key}>
                        {key + 1}.&nbsp;{item.mlsNeighborhood} ({item.mlsAreaID}
                        )
                      </span>
                    );
                  })}
                </span>
              </Fragment>
            ) : (
              ""
            )}
            (the “Exclusive Market Area”). Buyer agrees to pay ListGen a fee of{" "}
            <span className={classes.boldOnly}>
              $200 for each Exclusive Market Area (the "
              <span className={classes.boldOnly}>Area Exclusivity</span> fee") 9
              times a year, $200 per mailing
            </span>
            . Immediately prior to each mailing this fee shall be automatically
            charged to Buyer’s credit card, which card will be charged
            automatically 3 days prior to the printing of the brochures (PMAs).
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            3.2. ListGen does not guarantee or in any way assure the Licensee of
            a specific return on investment (ROI) or REAL ROI or otherwise. ROI
            will be different for each licensed area and each Licensee.
            Licensees are responsible for generating their own return on
            investment.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>
              4. Scope of Services.
            </span>{" "}
            ListGen agrees to perform the following services in accordance with
            all applicable State and Federal laws and regulations.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>
              5.1 Precision Market Analysis&#8482;:
            </span>{" "}
            <span className={classes.boldOnly}>
              ListGen will prepare a Precision Market Analysis (the “PMA”) for
              Buyer’s Exclusive Market Area nine (9) times during the year
            </span>
            . There are 3 months PMAs will NOT be printed and sent to addresses
            within Licensee’s Exclusive Market Area. Typically, these three
            months are March, August, and December. If ListGen changes these
            non-mailing months the agent will be notified via email at least 7
            days prior to the month that the mailings will occur.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>5.2 PMA Format.</span>{" "}
            ListGen will inform the licensee if and when a 6-page version of the
            PMA becomes available and determine the price at that time. Six page
            PMAs are OPTIONAL and will only be printed with the agent’s
            authority. (by selecting the 6-page version on the website)
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>5.3 Data Accuracy.</span>{" "}
            ListGen takes great care in gathering and publishing the data we
            gather from multiple sources. ListGen does not guarantee the data to
            be accurate.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>5.4 PMA costs.</span> The
            current cost for a 4-page PMA is{" "}
            <span className={classes.boldOnly}>$0.99</span> per PMA. (as of
            April 1st, 2024)
          </Typography>
          <div className={classes.sectionBreaker}>
            <Typography variant="body1">
              <span className={classes.sectionSpanText}>
                During the first year of the Licensee’s licensing agreement,
              </span>
              ListGen agrees to not increase the current Compass Exclusive
              pricing of the PMA. ListGen will strive to maintain the Exclusive
              Compass Pricing beyond the first twelve months of mailings, but as
              ListGen’s costs increase, we reserve the right to increase prices
              accordingly. IF/WHEN there is a price increase after the first
              year, ListGen will inform the Licensee 30 days prior to a price
              increase via email and the price increase will go into effect on
              the thirty first day.{" "}
              <span className={classes.boldOnly}>
                Licensees will be notified 30 days prior to any price increase.
              </span>
            </Typography>
          </div>
          <div className={classes.sectionBreaker}>
            <Typography variant="body1">
              The cost for each PMA includes the cost of compiling the
              information and data, printing, and mailing for each PMA. The cost
              of each mailing will be charged to Licensee’s credit card after
              Licensee has approved the PMA through the Licensee’s personal
              MailPro™ dashboard on the ListGen website.
            </Typography>
          </div>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>5.5. PMA Approval.</span>
            Prior to each mailing, the Licensee will receive two ListGen
            generated emails, the first email seven (7) days and the second
            email three (3) days before the scheduled printing. For those
            Licensees that have not approved their PMAs and/or Mailing list,
            these emails will prompt the Licensee to go to his/her MailPro™
            dashboard and REVIEW and APPROVE the current PMA. Due to the
            time-sensitive nature of the PMA data, the Licensee will only be
            able to edit and/or compile certain sections prior to the scheduled
            print/mail date.
          </Typography>
          <div className={classNames(classes.sectionBreaker)}>
            <Typography
              variant="body1"
              className={classNames(classes.redColor, classes.boldOnly)}
            >
              If the Licensee does not approve their scheduled PMAs by the
              designated CUT-OFF date, all the Licensee’s areas will be deemed
              approved and the PMA will be printed and mailed automatically and
              charged to the Licensee’s credit card.
            </Typography>
          </div>

          <div className={classNames(classes.sectionBreaker)}>
            <Typography
              variant="body1"
              className={classNames(classes.redColor, classes.boldOnly)}
            >
              Should the agent leave the Compass brokerage, the Exclusive
              Compass pricing will terminate immediately. It is the
              responsibility of the agent to inform ListGen in writing that
              he/she has terminated their affiliation with Compass. After
              terminating an affiliation with Compass and upon request, ListGen
              will provide the agent the non-Compass pricing.
            </Typography>
          </div>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>5.6 Mailing Lists.</span>{" "}
            ListGen uses proprietary software to capture each homeowner’s
            mailing address within the Licensee’s Exclusive Market Area. The PMA
            will not be sent to Active or Pending listings listed on the
            Multiple Listing Service (MLS) for the Exclusive Market Area except
            for those listings owned by Licensee. Aside from those listings that
            are owned by other agents and are automatically blocked by the
            system,{" "}
            <span className={classes.boldOnly}>
              the Licensee cannot block more than 10% of the addresses contained
              within the Exclusive Market Area
            </span>
            . Licensee cannot add any addresses to the list of Addresses to be
            mailed the PMA for the Exclusive Market Area. Returned PMAs will be
            sent to the Licensee’s return mailing address. No credit for
            undeliverable mail will be issued to the Licensee.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>6.1. License.</span> The
            ListGen website, software, Precision Market Analysis&#8482;,
            MailPro&#8482;, and associated information are subject to various
            patents and copyrights. Subject to the terms and conditions of the
            Agreement, ListGen grants to Licensee a limited, non-exclusive,
            non-transferable, non-assignable, non-sub-licensable license to use
            the ListGen website and affiliated software, the Precision Market
            Analysis&#8482;, and other information generated through this
            Agreement during the Term of this Agreement (the “License”).
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>
              6.2. License Restrictions.
            </span>{" "}
            Licensee shall not:
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.subsubPoint}>6.2.1</span> share, copy, or
            reproduce in any way the contents, or any part of the contents of
            the PMA or homeowner’s websites with any person or entity without
            prior written consent from ListGen;
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.subsubPoint}>6.2.2</span> copy or use for
            their own use, any part of the contents within the PMA or
            homeowner’s website (homeownersinsight.com) with any non-authorized
            mailings, emails or other such written/printed communications;
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.subsubPoint}>6.2.3.</span> use any of the
            contents of the PMA or homeowner’s websites to construct their own
            market newsletters, communications or mailings to anyone outside of
            their licensed areas;
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.subsubPoint}>6.2.4.</span> use the Licensed
            Software and Services except as expressly permitted under this
            Agreement;
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.subsubPoint}>6.2.5.</span> adapt, alter,
            publicly display, publicly perform, translate, create derivative
            works of, or otherwise modify the Licensed Software or Services
            other than as permitted under this Agreement;
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.subsubPoint}>6.2.6.</span> sell, resell,
            sublicense, lease, rent, disclose, or distribute the Licensed
            Software or Services;
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.subsubPoint}>6.2.7.</span> transfer the
            right to use the ListGen website, software, or Services to any third
            party;
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.subsubPoint}>6.2.8.</span> reverse
            engineer, decompile, disassemble or otherwise attempt to derive the
            source code for the Software, except as permitted by applicable law;
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.subsubPoint}>6.2.9.</span> remove, alter or
            obscure any proprietary notices on the Software, Documentation or
            the Precision Market Analysis; or
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.subsubPoint}>6.2.10.</span> allow third
            parties to access or use the Software or Services. Licensee is
            authorized to permit licensed team members or authorized assistants
            to use the Software and the Services at the direction and control of
            Licensee.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>
              7. Confidential Information.
            </span>{" "}
            By virtue of this Agreement, the Parties may have access to
            information that is confidential to one another (“Confidential
            Information”). Confidential Information shall include without
            limitation the Software, Services, any reports or data generated by
            ListGen or Licensee’s use of the ListGen website, the terms and
            pricing under this Agreement, and all information clearly identified
            as confidential. Any benchmarks, evaluations or other comparative
            reports related to the Software and the Precision Market
            Analysis&#8482; are included within the definition of Confidential
            Information.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            -
            <br />
            8.1 Confidential Information shall not include information that:
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.subsubPoint}>8.1.1</span> is or becomes a
            part of the public domain through no act or omission of the
            receiving party;
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.subsubPoint}>8.1.2</span> was in the
            receiving party’s lawful possession prior to the disclosure and had
            not been obtained by the receiving party either directly or
            indirectly from the disclosing party;
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.subsubPoint}>8.1.3</span> is lawfully
            disclosed to the receiving party by a third party without
            restriction on disclosure; or
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.subsubPoint}>8.1.4</span> is independently
            developed by the receiving party without reference to the disclosing
            party’s Confidential Information. The parties agree to hold each
            other’s Confidential Information in confidence during the term of
            this Agreement and after termination of this Agreement. The parties
            agree, unless required by law, not to make each other’s Confidential
            Information available in any form to any third party for any purpose
            other than the implementation of this Agreement.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>
              8.2 Required Disclosures.
            </span>{" "}
            In the event the receiving party is required under applicable law,
            rule, regulation, court or administrative order to disclose
            Confidential Information of the disclosing party, the receiving
            party shall use commercially reasonable efforts to:
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.subsubPoint}>8.2.1</span> promptly give
            notice, if permitted, to the disclosing party in order to enable the
            disclosing party to seek a protective order or other remedy;
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.subsubPoint}>8.2.2</span> consult with the
            disclosing party with respect to the scope of the request or legal
            process to the extent possible; and
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.subsubPoint}>8.2.3</span> limit any such
            disclosure to the extent necessary and required.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>
              9. General Warranty.
            </span>{" "}
            Each Party represents and warrants that it has the right and power
            to enter into this Agreement, and that an authorized representative
            has executed this Agreement.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>
              10. Compliance with Laws.
            </span>{" "}
            Each party will perform its obligations under this Agreement in a
            manner that complies with all applicable laws. Each party will
            promptly notify the other party if it discovers it is itself in
            violation of applicable law that affects its performance under this
            Agreement or it receives a written allegation of noncompliance from
            a government agency. The party not in compliance will promptly take
            corrective action and notify the other of the steps taken to correct
            its noncompliance.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>
              11. Licensee Suspension.
            </span>{" "}
            If the Licensee is suspended or otherwise prevented from conducting
            licensed activities by the state licensing board that issued the
            Licensee’s real estate license, the PMA for the Exclusive Market
            Area will not be mailed during the time which Licensee’s real estate
            license is suspended. Licensee will continue to be charged the
            Exclusive Market Area fee during any suspension. If, after 60 days,
            Licensee is still unable to conduct licensed activities, this
            Agreement will automatically terminate. Licensee’s credit card will
            be charged a termination fee of $600 upon the termination of this
            Agreement pursuant to this Paragraph. It is the responsibility of
            Licensee to notify ListGen when Licensee’s license is reinstated by
            sending the updated license status announcement to{" "}
            <strong>support@listgen.com</strong>
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>
              12. Disclaimer of Warranties.
            </span>{" "}
            Licensee expressly understands and agrees that, to the extent
            permitted by applicable law, ListGen specifically and generally
            disclaims all warranties whether express, implied or statutory with
            respect to the Software, the Precision Market Analysis, and the
            Services provided to Licensee under the Agreement, including without
            limitation any implied warranties of merchantability, fitness for a
            particular purpose, or any warranty of financial success using the
            Services provided by ListGen. Licensee acknowledges that it is the
            sole responsibility of Licensee to convert any leads generated by
            the Services provided by ListGen into actual clients. Licensee also
            expressly understands and agrees that ListGen specifically and
            generally disclaims any express or implied warranty regarding the
            accuracy of data that is printed or shown within the homeowner’s
            websites.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>
              13. Indemnification.
            </span>{" "}
            Licensee shall defend, indemnify and hold ListGen, its officers,
            employees or agents, harmless from and against any and all
            liability, loss, expense (including reasonable attorneys’ fees), or
            claims for injury or damages arising out of the performance of this
            Agreement.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>
              14. Limitation of Liability.
            </span>{" "}
            ListGen shall not be liable for any lost profits, costs, lost
            business, or for any indirect, consequential, punitive, or other
            special damages suffered by Licensee, its employees, agents, or team
            members arising out of or related to this Agreement for all causes
            of action of any kind, including tort, contract, negligence, strict
            liability or breach of warranty.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>
              15. Interruption of Service.
            </span>{" "}
            If ListGen does not complete the mailing within 30 days of approval
            of the PMA, ListGen shall complete the mailing at no cost to
            Licensee unless the failure to perform is excused pursuant to
            Paragraph 15.1, below.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.subsubPoint}>15.1</span> ListGen shall be
            excused from any delay or failure in performance required hereunder
            if caused by reason of any occurrence or contingency beyond its
            reasonable control, including, but not limited to, acts of God, acts
            of war or terrorism, fire, insurrection, laws, proclamations,
            edicts, ordinances or regulations, strikes, lock-outs or other
            serious labor disputes, riots, earthquakes, floods, explosions or
            other acts of nature. When such events have abated, the Parties’
            respective obligations hereunder shall resume.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>
              16. Effect of Termination.
            </span>{" "}
            Upon termination or expiration of this Agreement, any provisions
            herein which are intended to continue and survive such termination
            or expiration (including without limitation, Sections 5, 7, 8, 9 and
            10) shall survive expiration or termination of this Agreement.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>17. Notice.</span> Any
            notice required or permitted under this AGREEMENT shall be effective
            only if given in writing and delivered by one Party to the other by
            personal service, by US Mail, or by electronic mail.
            <span className={classes.addressFlex}>
              <span
                className={classNames(classes.addressRow, classes.marginTop4Px)}
              >
                <span className={classes.addressRowLeft}>To ListGen:</span>
                <span className={classes.addressRowRight}>
                  ListGen, Inc.
                  <br />
                  PO Box 1104
                  <br />
                  Carmel Valley, CA 93924
                  <br />
                  Email: support@listgen.com
                </span>
              </span>
              <span className={classes.addressRow}>
                <span className={classes.addressRowLeft}>To Licensee:</span>
                <span className={classes.addressRowRight}>
                  As directed in the ListGen registration procedure.
                </span>
              </span>
            </span>
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>18. Governing Law.</span>{" "}
            The Agreement will be governed by the laws of the State of
            California. Any action or proceeding arising from or relating to the
            Agreement must be brought exclusively in the Superior Court of
            California for the County of Monterey in Monterey, California. Each
            Party irrevocably consents to the personal jurisdiction and venue
            in, and agrees to service of process issued by, this court.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>
              19. Waiver, Modification, and Amendment.
            </span>{" "}
            No failure or delay by either party in exercising any right under
            this Agreement will constitute a waiver of that right. Any amendment
            or modification to this Agreement must be in writing signed by both
            parties.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>20. Severability.</span>{" "}
            If any provision of this Agreement is held by a court of competent
            jurisdiction to be contrary to law, the provision will be deemed
            null and void, and the remaining provisions of this Agreement will
            remain in effect.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>21. Counterparts.</span>{" "}
            This Agreement may be executed in counterparts, each of which will
            be considered an original, but all of which together will constitute
            one and the same instrument. Delivery of an executed counterpart of
            this AGREEMENT by facsimile or a .pdf data file or other scanned
            executed counterpart by email shall be equally as effective as
            delivery of a manually executed counterpart of this AGREEMENT. Each
            duplicate and counterpart shall be equally admissible in evidence,
            and each shall fully bind each party who has executed it. The
            Parties to this document agree that a copy of the original signature
            (including an electronic copy) may be used for any and all purposes
            for which the original signature may have been used. The Parties
            agree they will have no rights to challenge the use or authenticity
            of this document based solely on the absence of an original
            signature.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>
              22. Attorney’s Fees.
            </span>{" "}
            The parties agree that the prevailing party of any action or
            arbitration brought to enforce the terms of this Agreement shall be
            entitled to reasonable attorney’s fees and costs.
          </Typography>
        </div>
        <div
          className={classNames(
            classes.section,
            classes.section6,
            classes.sectionMainTC
          )}
        >
          <Typography variant="body1">
            <span className={classes.sectionSpanText}>
              23. Entire Agreement.
            </span>{" "}
            This Agreement constitutes the entire agreement between the parties
            and supersedes all prior or contemporaneous oral or written
            agreements.
          </Typography>
        </div>
      </div>
    </div>
  );
};

LegalTermsCompass.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  agentFirstName: PropTypes.string.isRequired,
  agentLastName: PropTypes.string.isRequired,
  navbarInfo: PropTypes.object.isRequired,
};

LegalTermsCompass.defaultProps = {
  agentFirstName: "",
  agentLastName: "",
  navbarInfo: {},
};

export default withStyles(styles)(LegalTermsCompass);
