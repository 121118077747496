import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { withStyles, IconButton } from "@material-ui/core";
import validate from "validate.js";
import schema from "./schema";
import _ from "lodash";
import compose from "recompose/compose";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Checkbox,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  OutlinedInput,
} from "@material-ui/core";
import {
  Portlet,
  PortletContent,
  PortletFooter,
  PortletHeader,
  PortletLabel,
} from "components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputAdornment from "@material-ui/core/InputAdornment";
import styles from "./styles";
import { AgentAssistantsContainer, AreaTaglineDialog, TeamDialogContainer } from "..";
import { RegisterAgentUploadPhotoAPI } from "services/agent";
import { showSuccessNotification } from "common/helpers";
import { showErrorNotification } from "common/helpers";
import { withSnackbar } from "notistack";
import { RegisterAgentValidateEmail } from "services/agent";
import { UpdateAgentDetailsAPI } from "services/agent";
import MaskedInput from "react-text-mask";
import { ImageUploadDialog } from "components";
import { MediaViewComponent } from "components";
import { GetStateStorage } from "common/storage";
import Base from "common/constants";
import { Alert } from "@material-ui/lab";

const TextMaskCustom = React.memo((props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask={false}
      guide={true}
      keepCharPositions={false}
    />
  );
});

const PortletFooterComponent = ({
  classes,
  isLoading,
  isValid,
  forceDisableEditing,
  userData = {},
  resetFormValues,
  completeAgentRegistration,
  showClearButton = true,
}) => {
  return userData.listgenRoleID === Base.APP_DATA.APP_AGENT_ROLE_ID ? (
    <PortletFooter className={classes.portletFooter}>
      <div className={classes.footerButtons}>
        <div className={classes.footerButtonsLeftRight}>
          {showClearButton && (
            <Button
              variant="outlined"
              disabled={forceDisableEditing}
              onClick={() => resetFormValues()}
            >
              Clear Details
            </Button>
          )}
        </div>
        <div className={classes.footerButtonsCenter}>
          {isLoading ? (
            <CircularProgress className={classes.progress} />
          ) : (
            <Button
              color="primary"
              variant="contained"
              disabled={!isValid || forceDisableEditing}
              onClick={(event) => {
                completeAgentRegistration();
              }}
            >
              Update Details
            </Button>
          )}
        </div>
        <div className={classes.footerButtonsLeftRight}></div>
      </div>
    </PortletFooter>
  ) : (
    ""
  );
};

class AgentDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        firstName: "",
        lastName: "",
        agentLicenseNumber: "", //
        brokerLicenseNumber: "", //
        emailAddress: "",
        displayEmailAddress: "",
        mobilePhone: "",
        officePhone: "",
        agentDesignation: "Realtor",
        //password: '',
        //confirmPassword: '',
        mailingAddressLine1: "",
        mailingAddressLine2: "",
        city: "",
        state: "",
        zipcode: "",
        website: "",
        tagline: "",
        officeAddressLine1: "",
        officeAddressLine2: "",
        officeCity: "",
        officeState: "",
        officeZipcode: "",
        agentPhoto: "",
        agentLogo: "",
        agentLogo2: "",
        agentSignature: "",
        agentBrokerageLogoURL: "",
        billingAddresSameAsMailingAddress: false,
        billingFirstName: "",
        billingLastName: "",
        billingAddressLine1: "",
        billingAddressLine2: "",
        billingCity: "",
        billingState: "",
        billingZipcode: "",
        isMemberOfTeam: false,
        teamName: "",
        //acceptedTermsAndConditions: false,
        teamMembers: [],
        userStatus: "",
        statusDescription: "",
        navbarInfo: [],
        agentAssistantDTOs: []
      },
      errors: {
        firstName: null,
        lastName: null,
        agentLicenseNumber: null,
        brokerLicenseNumber: null,
        emailAddress: null,
        displayEmailAddress: null,
        mobilePhone: null,
        officePhone: null,
        agentDesignation: null,
        website: null,
        tagline: null,
        officeAddressLine1: null,
        officeAddressLine2: null,
        officeCity: null,
        officeState: null,
        officeZipcode: null,
        agentAssistantDTOs: [],
        //password: null,
        //confirmPassword: null,
        mailingAddressLine1: null,
        mailingAddressLine2: null,
        city: null,
        state: null,
        zipcode: null,
        billingFirstName: null,
        billingLastName: null,
        billingAddressLine1: null,
        billingAddressLine2: null,
        billingCity: null,
        billingState: null,
        billingZipcode: null,
        teamMembers: [],
        userStatus: null,
        statusDescription: null,
        navbarInfo: null,
        agentAssistantDTOs: [],
      },
      touched: {
        firstName: false,
        lastName: false,
        agentLicenseNumber: false,
        brokerLicenseNumber: false,
        emailAddress: false,
        displayEmailAddress: false,
        mobilePhone: false,
        officePhone: false,
        agentDesignation: false,
        website: false,
        tagline: false,
        officeAddressLine1: false,
        officeAddressLine2: false,
        officeCity: false,
        officeState: false,
        officeZipcode: false,
        agentAssistantDTOs: [],
        //password: false,
        //confirmPassword: false,
        mailingAddressLine1: false,
        mailingAddressLine2: false,
        city: false,
        state: false,
        zipcode: false,
        billingFirstName: false,
        billingLastName: false,
        billingAddressLine1: false,
        billingAddressLine2: false,
        billingCity: false,
        billingState: false,
        billingZipcode: false,
        teamMembers: [],
        userStatus: null,
        statusDescription: null,
        navbarInfo: true,
        agentAssistantDTOs: true
      },
      settings: {
        file: {
          accept: "image/*",
          multiple: true,
        },
      },
      agentData: {},
      isValid: false,
      isLoading: false,
      submitError: null,
      openDialog: false,
      maxMediaSize: 5000000,
      mediaSize: {
        profile: {
          width: "160",
          height: "80",
        },
        logo: {
          width: "200",
          height: "75",
        },
        signature: {
          width: "200",
          height: "60",
        },
      },
      designationData: [],
      listgenUserID: null,
      labelWidthMobilePhone: 0,
      labelWidthOfficePhone: 0,
      currentImage: null,
      imageType: "profile",
      openImageUplaodDialog: false,
      /**media view dialog */
      openMediaView: false,
      mediaURL: null,
      mediaTitle: null,
    };
    /**bind methods */
    this.onDialogClose = this.onDialogClose.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.setTeamProfileField = this.setTeamProfileField.bind(this);
    this.setAssistantFieldandAutoSave = this.setAssistantFieldandAutoSave.bind(this);
    this.dataPacther = this.dataPacther.bind(this);
    this.removeTeamMembersDetails = this.removeTeamMembersDetails.bind(this);
    this.openImageUplaodDialog = this.openImageUplaodDialog.bind(this);
    this.onMediaDialogSubmit = this.onMediaDialogSubmit.bind(this);
    this.onMediaDialogClose = this.onMediaDialogClose.bind(this);
    this.openImageUploadDialogFinal = this.openImageUploadDialogFinal.bind(
      this
    );
    this.closeImageUploadDialogFinal = this.closeImageUploadDialogFinal.bind(
      this
    );
    this.startLoading = this.startLoading.bind(this);
    this.stopLoading = this.stopLoading.bind(this);
    this.submitIfValid = this.submitIfValid.bind(this);
    /**Create ref */
    this.labelRefOfficePhone = React.createRef();
    this.labelRefMobilePhone = React.createRef();
    /**media view changes */
    this.openMediaViewDialog = this.openMediaViewDialog.bind(this);
    this.closeMediaViewDialog = this.closeMediaViewDialog.bind(this);
    /**Get session storage */
    const stateData = GetStateStorage();
    this.userData = stateData.userData;
    /**pdf check regex */
    this.regexWithPDF = new RegExp(/^.*\.(PDF|pdf)$/);
  }

  handleFieldChange = (field, value, event) => {
    const newState = { ...this.state };
    newState.submitError = null;
    newState.touched[field] = true;
    if (
      field === "zipcode" ||
      field === "officeZipcode" ||
      field === "billingZipcode"
    ) {
      newState.values[field] = event.target.value.replace(/[^0-9-]/g, "");
    } else if (field === "mobilePhone" || field === "officePhone") {
      newState.values[field] = event.target.value.replace(/\D/g, "");
    } else if (
      field === "agentPhoto" ||
      field === "agentLogo" ||
      field === "agentLogo2" ||
      field === "agentSignature" ||
      field === "agentBrokerageLogoURL"
    ) {
      newState.values[field] = value;
    } else {
      newState.values[field] =
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value;
    }

    this.setState(newState, this.validateForm);
  };

  setTeamProfileField = (teamProfileData) => {
    const newState = { ...this.state};
    newState.values.teamMembers = teamProfileData;
    newState.openDialog = false;
    this.setState(newState, () => {
      this.submitIfValid();
    });
  };

  setAssistantFieldandAutoSave = (assistantData) => {
    const newState = { ...this.state };
    if(assistantData.length === 0){
      //If no assistant data, remove the key
      newState.values.agentAssistantDTOs = [];
    } else {
    newState.values.agentAssistantDTOs = assistantData;
    }
    this.setState(newState, () => {
      this.submitIfValid();
    });    
  };

  isAValidZipCode = (filedName, value, errors) => {
    const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    if (!isValidZip) {
      errors[filedName] = ["Zipcode is invalid"];
    }
    return errors;
  };

  

  // isAValidWebsiteUrl = (userEnteredUrl) => {
  //   let pattern1 = /^((http|https|ftp):\/\/)/;
  //   let pattern2 = /^((www.):\/\/)/;

  //   if (pattern1.test(userEnteredUrl) || pattern2.test(userEnteredUrl)) {
  //     return false;
  //   }
  //   return true;
  // };

  validateWebsite = (value, errors) => {
    errors = !errors ? {} : errors;
    let pattern = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}\.[a-zA-Z0-9]([a-zA-Z]{1,6})$/;
    let pattern1 = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}\.[a-zA-Z0-9][a-zA-Z0-9-]{0,61}\.[a-zA-Z0-9]([a-zA-Z]{1,6})$/;
    let pattern2 = /^((https?):\/\/)?([a-zA-Z0-9][a-zA-Z0-9-]{0,61}\.)+[a-zA-Z0-9]{2,61}(\/[^\s]*)?$/;

    // If empty, do not validate
    if (value === "" || !value) {
      delete errors["website"];
      return errors;
    }

    // We have a value
    if (value.substring(0, 4) === "www.") {
      errors["website"] = ["www. is not allowed at the URL start"];
    } else if (
      !pattern.test(value) &&
      !pattern1.test(value) &&
      !pattern2.test(value)
    ) {
      errors["website"] = ["Invalid website URL"];
    }

    return errors;
  };

  validateForm = _.debounce(() => {
    const { values } = this.state;
    const newState = { ...this.state };
    let errors = validate(values, schema);
    errors = !errors ? {} : errors;

    errors = this.validateWebsite(values.website, errors);
    errors = this.isAValidZipCode("zipcode", values.zipcode, errors);
    errors = this.isAValidZipCode(
      "billingZipcode",
      values.billingZipcode,
      errors
    );
    //errors = this.isAValidZipCode("officeZipcode", values.officeZipcode, errors)
    newState.errors = errors || {};
    newState.isValid = errors && Object.keys(errors).length > 0 ? false : true;
    this.setState(newState);
  }, 300);

  onDialogClose = () => {
    const haveTeamMembers =
      this.state.values.teamMembers.length > 0 ? true : false;
    if (haveTeamMembers) {
      const newState = { ...this.state };
      newState.openDialog = false;
      this.setState(newState);
    } else {
      this.removeTeamMembersDetails();
    }
  };

  dataPacther = (reverseMode = false, stateData, serverData) => {
    //password, confirmPassword, agentLicenseNumber, brokerLicenseNumber, team members removed
    try {
      let stateKeys = [
        "firstName",
        "lastName",
        "brokerageName",
        "creditCardInfo",
        "emailAddress",
        "displayEmailAddress",
        "mobilePhone",
        "officePhone",
        "mailingAddressLine1",
        "mailingAddressLine2",
        "city",
        "state",
        "zipcode",
        "agentDesignation",
        "website",
        "tagline",
        "officeAddressLine1",
        "officeAddressLine2",
        "officeCity",
        "officeState",
        "officeZipcode",
        "agentPhoto",
        "agentLogo",
        "agentLogo2",
        "agentSignature",
        "agentBrokerageLogoURL",
        "billingAddresSameAsMailingAddress",
        "billingFirstName",
        "billingLastName",
        "billingAddressLine1",
        "billingAddressLine2",
        "billingCity",
        "billingState",
        "billingZipcode",
        "isMemberOfTeam",
        "teamName",
        "agentLicenseNumber",
        "brokerLicenseNumber",
        "userStatus",
        "statusDescription",
        "navbarInfo",
        'agentAssistantDTOs'
      ];

      let serverKeys = [
        "listgenUserFirstName",
        "listgenUserLastName",
        "brokerageName",
        "creditCardInfo",
        "emailAddress",
        "displayEmailAddress",
        "mobilePhoneNo",
        "officePhoneNo",
        "mailingAddressLine1",
        "mailingAddressLine2",
        "mailingCity",
        "mailingState",
        "mailingZipCode",
        "agentDesignation",
        "websiteURL",
        "tagLine",
        "officeAddressLine1",
        "officeAddressLine2",
        "officeCity",
        "officeState",
        "officeZipcode",
        "photoURL",
        "logoURL",
        "logoURL2",
        "signatureURL",
        "brokerageLogoURL",
        "isBillingAddressSame",
        "billingFirstName",
        "billingLastName",
        "billingAddressLine1",
        "billingAddressLine2",
        "billingCity",
        "billingState",
        "billingZipCode",
        "isMemberOfTeam",
        "teamName",
        "agentLicenseNumber",
        "brokerLicenseNumber",
        "userStatus",
        "statusDescription",
        "navbarInfo",
        'agentAssistantDTOs'
      ];
      let from = [];
      let fromData = [];
      let to = [];
      let toData = [];
      if (reverseMode) {
        from = stateKeys;
        to = serverKeys;
        /**assign data also */
        fromData = _.cloneDeep(stateData);
        toData = _.cloneDeep(serverData);
        /**now patch the data */
        let returnData = {};
        returnData = _.cloneDeep(serverData);
        returnData = _.omit(returnData, from);
        for (let key in from) {
          //if (fromData[from[key]])
          returnData[to[key]] =
            fromData.hasOwnProperty(from[key]) && fromData[from[key]] !== null
              ? fromData[from[key]]
              : "";
          //check for website. from app to server
          if (
            from[key] === "website" &&
            fromData[from[key]] !== "" &&
            fromData[from[key]].substring(0, 4) !== "www." &&
            (fromData[from[key]].match(/\./g) || []).length <= 1
          ) {
            returnData[to[key]] = "www." + fromData[from[key]];
          }
        }
        return returnData;
      } else {
        /**REVERSE MODE DATA */
        from = serverKeys;
        to = stateKeys;
        /**assign data also */
        fromData = _.cloneDeep(serverData);
        toData = _.cloneDeep(stateData);
        for (let key in from) {
          if (
            fromData.hasOwnProperty(from[key]) &&
            fromData[from[key]] !== null
          ) {
            toData[to[key]] = fromData[from[key]];
            //check for website. from server to app
            if (
              from[key] === "websiteURL" &&
              fromData[from[key]].substring(0, 4) === "www."
            ) {
              toData[to[key]] = fromData[from[key]].slice(
                4,
                fromData[from[key]].length
              );
            }
          }
        }
        /**check for team mebers also if reverse mode is false */
        if (!reverseMode && fromData.teamMembers !== null) {
          for (let i = 0; i < 4; i++) {
            if (!fromData.teamMembers[i]) {
            } else {
              /**data is present in server data */
              toData.teamMembers[i] = fromData.teamMembers[i];
            }
          }
        }
        return toData;
      }
    } catch (err) {
      return {};
    }
  };

  handleWindowScroll = () => {
    if (!window) return;
    const hash = window.location.hash;
    if (hash) {
      const targetId = hash.substring(1);

      // Find the target div by its ID
      const targetDiv = document.querySelector(`#${targetId}`);
      // Scroll to the target div if it exists
      if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo(0, 0);
    }
  };

  componentDidMount = () => {
    /**set the label width */
    this.setState(
      {
        labelWidthOfficePhone: this.labelRefOfficePhone.current.offsetWidth,
        labelWidthMobilePhone: this.labelRefMobilePhone.current.offsetWidth,
      },
      () => {
        this.handleWindowScroll();
      }
    );
  };

  componentWillReceiveProps = (newProps) => {
    if (Object.keys(newProps.agentData).length > 0) {
      this.setState((prevState, props) => {
        return {
          agentData: newProps.agentData,
          values: this.dataPacther(false, prevState.values, newProps.agentData),
          designationData: newProps.designationData,
        };
      }, this.markTouchedTrueandValidate);
    }
  };

  markTouchedTrueandValidate = () => {
    const newState = { ...this.state };
    for (let key in newState.values) {
      if (newState.values[key] !== "") {
        newState.touched[key] = true;
      }
    }
    this.setState(newState, this.validateForm);
  };

  startLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  stopLoading = () => {
    this.setState({
      isLoading: false,
    });
  };

  setEmailToInvalid = () => {
    const newState = { ...this.state };
    newState.touched.emailAddress = true;
    newState.errors.emailAddress = [
      "Email already exists. Please change your email.",
    ];
    this.setState(newState);
  };

  validateRegisterEmail = () => {
    /**first check for whether user entered valid email or not */
    //this.state.touched.emailAddress &&
    let haveErrors = validate(
      { emailAddress: this.state.values.emailAddress },
      { emailAddress: { email: true } }
    );
    if (haveErrors) {
      /* we have error in our email so return no need to call api */
      return;
    }
    /**make loading true */
    this.startLoading();
    try {
      RegisterAgentValidateEmail(
        this.state.values.emailAddress,
        this.props.listgenUserID
      )
        .then((data) => {
          switch (data) {
            case true:
            case "true":
              /* email exists already */
              this.setEmailToInvalid();
              break;
            case false:
            case "false":
              /* Do nothing */
              break;
            default:
              this.setEmailToInvalid();
              break;
          }
          /**stop loading also */
          this.stopLoading();
        })
        .catch((err) => {
          //showErrorNotification(this.props, "Unable to process request.");
          this.setEmailToInvalid();
          this.stopLoading();
        });
    } catch (err) {
      //showErrorNotification(this.props, "Unable to process request.");
      this.setEmailToInvalid();
      this.stopLoading();
    }
  };

  copyValuesFromMailingToBilling = (isChecked) => {
    const newState = { ...this.state };
    if (isChecked) {
      newState.values.billingFirstName = newState.values.firstName;
      newState.values.billingLastName = newState.values.lastName;
      newState.values.billingAddressLine1 = newState.values.mailingAddressLine1;
      newState.values.billingAddressLine2 = newState.values.mailingAddressLine2;
      newState.values.billingCity = newState.values.city;
      newState.values.billingState = newState.values.state;
      newState.values.billingZipcode = newState.values.zipcode;
    } else {
      // newState.values.billingFirstName = '';
      // newState.values.billingLastName = '';
      // newState.values.billingAddressLine1 = '';
      // newState.values.billingAddressLine2 = '';
      // newState.values.billingCity = '';
      // newState.values.billingState = '';
      // newState.values.billingZipcode = '';
    }
    this.setState(newState);
  };

  setModelDialogOpen = (field, value, event) => {
    /**only set if checkbox is checked. special method to handle team member model only */
    if (event.target.checked) {
      const newState = { ...this.state };
      newState.openDialog = true;
      this.setState(newState, this.handleFieldChange(field, value, event));
    } else {
      this.handleFieldChange(field, value, event);
    }
  };

  removeTeamMembersDetails = () => {
    const newState = { ...this.state };
    newState.values.teamMembers = [];
    newState.values.isMemberOfTeam = false;
    newState.values.teamName = "";
    newState.openDialog = true;
    this.setState(newState);
  };

  editTeamMembersDetails = () => {
    const newState = { ...this.state };
    newState.openDialog = true;
    this.setState(newState);
  };

  patchTeamMemberFirstRow = () => {
    const { values } = this.state;
    return {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.mobilePhone,
      email: values.emailAddress,
      agentLicenseNumber: values.agentLicenseNumber,
      mailingAddress1: values.mailingAddressLine1,
      mailingAddress2: values.mailingAddressLine2,
      city: values.city,
      state: values.state,
      zipcode: values.zipcode,
      agentSignature: values.agentSignature,
      agentDesignation: values.agentDesignation,
    };
  };

  submitIfValid = () => {
    const forceDisableEditing = this.shouldForceDisableEditing();
    if (this.state.isValid && !this.state.isLoading && !forceDisableEditing) {
      this.startLoading();
      setTimeout(this.completeAgentRegistration(), 100);
    } else {
      showErrorNotification(
        this.props,
        "Unable to process: form is invalid or profile update is on hold. If the issue persists, contact support.",
      5000
      );
    }
  }

  completeAgentRegistration = () => {
    let data = this.dataPacther(true, this.state.values, this.state.values);
    data.listgenUserID = this.state.agentData.listgenUserID;
    data.ownedMLSID = this.state.agentData.ownedMLSID;
    data.agentRegisterToken = this.state.agentData.agentRegisterToken;
    //return;
    if (!data.isMemberOfTeam) {
      /**assign the data null values if no team members is present */
      data.teamName = "";
      data.teamMembers = [];
    } else {
      /**patch first row from original data */
      data.teamMembers[0] = this.patchTeamMemberFirstRow();
    }
    /**check for billing address */
    if (this.state.values.billingAddresSameAsMailingAddress) {
      data.billingFirstName = this.state.values.firstName;
      data.billingLastName = this.state.values.lastName;
      data.billingAddressLine1 = this.state.values.mailingAddressLine1;
      data.billingAddressLine2 = this.state.values.mailingAddressLine2;
      data.billingCity = this.state.values.city;
      data.billingState = this.state.values.state;
      data.billingZipCode = this.state.values.zipcode;
    }
    //add data to recevied agent response if in case server sending more keys
    data = { ...this.props.agentData, ...data };
    this.startLoading();
    try {
      UpdateAgentDetailsAPI(data)
        .then((data) => {
          switch (data) {
            case true:
            case "true":
              showSuccessNotification(
                this.props,
                "Details updated successfully. Reloading page in 3 seconds...",
                3000
              );
              //refresh page to update stores
              setTimeout(() => {
                if (window) window.location.reload();
              }, 3000);
              break;
            case false:
            case "false":
              showErrorNotification(
                this.props,
                "Unable to update details. Please try again."
              );
              break;
            default:
              showErrorNotification(this.props, "Something went wrong.");
              break;
          }
          /**stop loading also */
          this.stopLoading();
        })
        .catch((err) => {
          showErrorNotification(this.props, "Unable to process request.");
          this.stopLoading();
        });
    } catch (err) {
      showErrorNotification(this.props, "Unable to process request.");
      this.stopLoading();
    }
  };

  openImageUplaodDialog = (imageType, currentImage) => {
    this.setState(
      {
        currentImage,
        imageType,
      },
      this.openImageUploadDialogFinal
    );
  };

  openImageUploadDialogFinal = () => {
    this.setState({
      openImageUplaodDialog: true,
    });
  };

  closeImageUploadDialogFinal = () => {
    this.setState({
      openImageUplaodDialog: false,
    });
  };

  onMediaDialogSubmit = (uploadedImageUrl) => {
    let fieldType = "agentPhoto";
    let imageType = this.state.imageType;
    if (imageType === "logo") {
      fieldType = "agentLogo";
    } else if (imageType === "logo2") {
      fieldType = "agentLogo2";
    } else if (imageType === "signature") {
      fieldType = "agentSignature";
    } else if (imageType === "brokerageLogo") {
      fieldType = "agentBrokerageLogoURL";
    }
    this.setState(
      {
        currentImage: null,
        imageType: null,
        openImageUplaodDialog: false,
      },
      () => {
        this.closeImageUploadDialogFinal();
        this.handleFieldChange(fieldType, uploadedImageUrl, null);
      }
    );
  };

  onMediaDialogClose = () => {
    this.setState({
      currentImage: null,
      imageType: null,
      openImageUplaodDialog: false,
    });
  };

  openMediaViewDialog = (mediaURL, mediaTitle) => {
    this.setState(
      {
        mediaURL,
        mediaTitle,
      },
      this.setState({
        openMediaView: true,
      })
    );
  };

  closeMediaViewDialog = () => {
    this.setState({
      mediaURL: null,
      mediaTitle: null,
      openMediaView: false,
    });
  };

  openPDFInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };

  isAnyAreaTaglineAdded = () => {
    return (
      this.state.values.navbarInfo &&
      this.state.values.navbarInfo.find(
        (item) => item.tagLine && item.tagLine !== ""
      ) !== undefined
    );
  };

  getFormattedTagline = (index = 0) => {
    const areaData =  this.state.values.navbarInfo.find((item) => item.tagLine && item.tagLine !== "");
    if(areaData === undefined) return '';
    const lineWiseTagline = (areaData.tagLine || '').split(Base.LG_DEFAULT_TEXT_SEPARATOR);
    return lineWiseTagline[index] ? lineWiseTagline[index] : '';
  }

  shouldForceDisableEditing = () => {
    const forceDisableEditing =
    this.userData && this.userData.hasOwnProperty("enableMailProAccess")
      ? !this.userData.enableMailProAccess
      : false;
      return forceDisableEditing;
  }

  render() {
    const {
      classes,
      className,
      agentData,
      statusData,
      designationData,
      listgenUserID
    } = this.props;
    const rootClassName = classNames(classes.root, className);
    const {
      values,
      touched,
      errors,
      isValid,
      submitError,
      isLoading,
    } = this.state;

    /**Error handling */
    /**Personal Info */
    const showFirstNameError = touched.firstName && errors.firstName;
    const showLastNameError = touched.lastName && errors.lastName;
    const showAgentLicenseError =
      touched.agentLicenseNumber && errors.agentLicenseNumber;
    const showBrokerLicenseError =
      touched.brokerLicenseNumber && errors.brokerLicenseNumber;
    const showAgentEmailError = touched.emailAddress && errors.emailAddress;
    const showAgentDisplayEmailError =
      touched.displayEmailAddress && errors.displayEmailAddress;
    const showAgentMobileError = touched.mobilePhone && errors.mobilePhone;
    const showAgentOfficeError = touched.officePhone && errors.officePhone;
    const showPasswordError = touched.password && errors.password;
    const showConfirmPasswordError =
      touched.confirmPassword && errors.confirmPassword;
    const showDesignationError = touched.designation && errors.designation;

    /**Mailing address */
    const showMailingAddressLine1Error =
      touched.mailingAddressLine1 && errors.mailingAddressLine1;
    const showMailingAddressLine2Error =
      touched.mailingAddressLine2 && errors.mailingAddressLine2;
    const showMailingCityError = touched.city && errors.city;
    const showMailingStateError = touched.state && errors.state;
    const showMailingZipcodeError = touched.zipcode && errors.zipcode;

    /**Agent marketing */
    const showWebsiteError = touched.website && errors.website;
    const showTaglineError = touched.tagline && errors.tagline;
    const showAgentPhotoError = touched.agentPhoto && errors.agentPhoto;
    const showAgentLogoError = touched.agentLogo && errors.agentLogo;
    const showAgentSignatureError =
      touched.agentSignature && errors.agentSignature;
    const showOfficeAddressLine1Error =
      touched.officeAddressLine1 && errors.officeAddressLine1;
    const showOfficeAddressLine2Error =
      touched.officeAddressLine2 && errors.officeAddressLine2;
    const showOfficeCityError = touched.officeCity && errors.officeCity;
    const showOfficeStateError = touched.officeState && errors.officeState;
    const showOfficeZipcodeError =
      touched.officeZipcode && errors.officeZipcode;

    /**Billing address */
    const showBillingFirstNameError =
      touched.billingFirstName && errors.billingFirstName;
    const showBillingLastNameError =
      touched.billingLastName && errors.billingLastName;
    const showBillingAddressLine1Error =
      touched.billingAddressLine1 && errors.billingAddressLine1;
    const showBillingAddressLine2Error =
      touched.billingAddressLine2 && errors.billingAddressLine2;
    const showBillingCityError = touched.billingCity && errors.billingCity;
    const showBillingStateError = touched.billingState && errors.billingState;
    const showBillingZipcodeError =
      touched.billingZipcode && errors.billingZipcode;

    /**Status */
    const showStatusError = touched.userStatus && errors.userStatus;
    const showStatusDescriptionError =
      touched.statusDescription && errors.statusDescription;

    /**uplaoded message */
    const commonUploadStatusMessage = (message, isCompleted = true) => {
      return isCompleted ? (
        <Typography
          component="div"
          align="center"
          variant="body2"
          className={classNames(classes.photoUploadedMessage, classes.green500)}
        >
          {message}
        </Typography>
      ) : (
        <Typography variant="subtitle2" className={classes.noCuurentImage}>
          {message}
        </Typography>
      );
    };

    //check for disable editing key
    const forceDisableEditing = this.shouldForceDisableEditing();
    return (
      <Fragment>
        {this.state.values.isMemberOfTeam ? (
          <TeamDialogContainer
            maxWidth={"lg"}
            openDialog={this.state.openDialog}
            onDialogClose={this.onDialogClose}
            setTeamProfileField={this.setTeamProfileField}
            currentProfileData={this.state.values.teamMembers}
            teamNameValue={this.state.values.teamName}
            teamNameTouched={this.state.touched.teamName}
            teamNameErrors={this.state.errors.teamName}
            parentHandleFieldChange={this.handleFieldChange}
            listgenUserID={listgenUserID}
            designationData={designationData}
            parentValues={this.state.values}
          />
        ) : (
          ""
        )}
        {this.state.openImageUplaodDialog && this.state.imageType !== null ? (
          <ImageUploadDialog
            openDialog={this.state.openImageUplaodDialog}
            onSubmit={this.onMediaDialogSubmit}
            onClose={this.onMediaDialogClose}
            imageType={this.state.imageType}
            listgenUserID={listgenUserID}
            currentImage={this.state.currentImage}
            isMyAccountUpload={true}
            isAdminUploading={
              this.userData.listgenRoleID === Base.APP_DATA.APP_ADMIN_ROLE_ID
                ? true
                : false
            }
          />
        ) : (
          ""
        )}
        {this.state.openMediaView ? (
          <MediaViewComponent
            mediaURL={this.state.mediaURL}
            mediaTitle={this.state.mediaTitle}
            onOpen={this.openMediaViewDialog}
            onClose={this.closeMediaViewDialog}
          />
        ) : (
          ""
        )}
        {forceDisableEditing && (
          <Alert
            severity="info"
            variant="filled"
            style={{ margin: "8px", fontSize: "14px" }}
          >
            PMA Printing is in progress. You can update your profile after that.
          </Alert>
        )}
        <form autoComplete="off" noValidate>
          <Grid container className={classes.grid} spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Portlet className={rootClassName} key="basic-details">
                <PortletHeader>
                  <PortletLabel title="Personal Info" />
                </PortletHeader>
                <PortletContent noPadding>
                  <Grid container className={classes.grid} spacing={0}>
                    <Grid item lg={4}>
                      <div className={classes.field}>
                        <TextField
                          className={classes.textField}
                          label="First name"
                          margin="dense"
                          required
                          variant="outlined"
                          name="firstName"
                          value={values.firstName}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "firstName",
                              event.target.value,
                              event
                            )
                          }
                        />
                        {showFirstNameError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.firstName[0]}
                          </Typography>
                        )}
                        <TextField
                          className={classes.textField}
                          label="Agent License Number"
                          margin="dense"
                          required
                          variant="outlined"
                          name="agentLicenseNumber"
                          value={values.agentLicenseNumber}
                          inputProps={{
                            readOnly: false,
                          }}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "agentLicenseNumber",
                              event.target.value,
                              event
                            )
                          }
                        />
                        {showAgentLicenseError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.agentLicenseNumber[0]}
                          </Typography>
                        )}
                        <FormControl
                          className={classNames(
                            classes.formControl,
                            classes.marignTop1Unit
                          )}
                          variant="outlined"
                        >
                          <InputLabel
                            required={true}
                            ref={this.labelRefMobilePhone}
                            htmlFor={
                              "formatted-text-mask-input-mobile-phone-parent"
                            }
                          >
                            Mobile Phone #
                          </InputLabel>
                          <OutlinedInput
                            value={values.mobilePhone}
                            labelWidth={this.state.labelWidthMobilePhone}
                            className={classes.textField}
                            name="mobilePhone"
                            margin="dense"
                            onChange={(event) => {
                              this.handleFieldChange(
                                "mobilePhone",
                                event.target.value,
                                event
                              );
                            }}
                            required={true}
                            id={"formatted-text-mask-input-mobile-phone-parent"}
                            inputComponent={TextMaskCustom}
                          />
                        </FormControl>
                        {showAgentMobileError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.mobilePhone[0]}
                          </Typography>
                        )}
                        <div>
                          <TextField
                            className={classes.textField}
                            label="Email"
                            margin="dense"
                            variant="outlined"
                            name="emailAddress"
                            value={values.emailAddress}
                            required
                            onBlur={(event) => {
                              this.validateRegisterEmail();
                            }}
                            onChange={(event) =>
                              this.handleFieldChange(
                                "emailAddress",
                                event.target.value,
                                event
                              )
                            }
                            helperText={
                              "Only for login purpose. System will convert all characters to lower case."
                            }
                          />
                          {showAgentEmailError && (
                            <Typography
                              className={classes.fieldError}
                              variant="body2"
                            >
                              {errors.emailAddress[0]}
                            </Typography>
                          )}
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={4}>
                      <div className={classes.field}>
                        <TextField
                          className={classes.textField}
                          label="Last name"
                          margin="dense"
                          required
                          variant="outlined"
                          name="lastName"
                          value={values.lastName}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "lastName",
                              event.target.value,
                              event
                            )
                          }
                        />
                        {showLastNameError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.lastName[0]}
                          </Typography>
                        )}
                        <TextField
                          className={classes.textField}
                          label="Broker License Number"
                          margin="dense"
                          variant="outlined"
                          required
                          name="brokerLicenseNumber"
                          value={values.brokerLicenseNumber}
                          inputProps={{
                            readOnly: false,
                          }}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "brokerLicenseNumber",
                              event.target.value,
                              event
                            )
                          }
                        />
                        {showBrokerLicenseError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.brokerLicenseNumber[0]}
                          </Typography>
                        )}
                        <FormControl
                          className={classNames(
                            classes.formControl,
                            classes.marignTop1Unit
                          )}
                          variant="outlined"
                        >
                          <InputLabel
                            required={false}
                            ref={this.labelRefOfficePhone}
                            htmlFor={
                              "formatted-text-mask-input-office-phone-parent"
                            }
                          >
                            Office Phone #
                          </InputLabel>
                          <OutlinedInput
                            value={values.officePhone}
                            labelWidth={this.state.labelWidthOfficePhone}
                            className={classes.textField}
                            name="officePhone"
                            margin="dense"
                            onChange={(event) => {
                              this.handleFieldChange(
                                "officePhone",
                                event.target.value,
                                event
                              );
                            }}
                            required={true}
                            id={"formatted-text-mask-input-office-phone-parent"}
                            inputComponent={TextMaskCustom}
                          />
                        </FormControl>
                        {showAgentMobileError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {Array.isArray(errors.officePhone)
                              ? errors.officePhone[0]
                              : ""}
                          </Typography>
                        )}
                        {/* <TextField
                                                    className={classes.textField}
                                                    type="password"
                                                    label="Confirm Password"
                                                    margin="dense"
                                                    variant="outlined"
                                                    required
                                                    name="confirmPassword"
                                                    value={values.confirmPassword}
                                                    onChange={event =>
                                                        this.handleFieldChange("confirmPassword", event.target.value, event)
                                                    }
                                                />
                                                {showConfirmPasswordError && (
                                                    <Typography
                                                        className={classes.fieldError}
                                                        variant="body2"
                                                    >
                                                        {errors.confirmPassword[0]}
                                                    </Typography>
                                                )} */}
                      </div>
                    </Grid>
                    <Grid item lg={4}>
                      <div className={classes.field}>
                        <TextField
                          id="select-agent-designation"
                          select
                          label="Select Your Designation"
                          className={classes.textField}
                          value={values.agentDesignation}
                          //required
                          error={showDesignationError}
                          variant="outlined"
                          onChange={(event) => {
                            this.handleFieldChange(
                              "agentDesignation",
                              event.target.value,
                              event
                            );
                          }}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="dense"
                        >
                          {designationData.map((option) => (
                            <MenuItem
                              key={option.listgenDesignationID}
                              value={option.listgenDesignationName}
                            >
                              {option.listgenDesignationName}
                            </MenuItem>
                          ))}
                        </TextField>
                        {showDesignationError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.agentDesignation[0]}
                          </Typography>
                        )}
                        <div className={classes.brokerageField}>
                          <InputLabel>Brokerage Name</InputLabel>
                          <Typography
                            variant="subtitle2"
                            className={classes.brokerageFieldTypo}
                          >
                            {values.brokerageName}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={12}>
                      <div className={classes.field}>
                        <AgentAssistantsContainer
                        maxWidth={"lg"}
                        onDialogClose={this.onDialogClose}
                        setAssistantFieldandAutoSave={this.setAssistantFieldandAutoSave}
                        currentAssistantData={this.state.values.agentAssistantDTOs}
                        listgenUserID={listgenUserID}
                         />
                      </div>
                    </Grid>
                  </Grid>
                </PortletContent>
                <PortletFooterComponent
                  classes={classes}
                  isLoading={isLoading}
                  isValid={isValid}
                  forceDisableEditing={forceDisableEditing}
                  userData={this.userData}
                  resetFormValues={this.resetFormValues}
                  completeAgentRegistration={this.completeAgentRegistration}
                  showClearButton={false}
                />
              </Portlet>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Portlet className={rootClassName} key="mailing-details">
                <PortletHeader>
                  <PortletLabel
                    subtitle={
                      <Fragment>
                        This is the address where your copy of the Precision
                        Market Analysis will be mailed. Preferably your{" "}
                        <strong>HOME ADDRESS</strong>, not your office address.
                      </Fragment>
                    }
                    title="Mailing Address"
                  />
                </PortletHeader>
                <PortletContent noPadding>
                  <Grid container className={classes.grid} spacing={0}>
                    <Grid item lg={6}>
                      <div className={classes.field}>
                        <TextField
                          className={classes.textField}
                          label="Mailing Address"
                          margin="dense"
                          required
                          variant="outlined"
                          name="mailingAddressLine1"
                          value={values.mailingAddressLine1}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "mailingAddressLine1",
                              event.target.value,
                              event
                            )
                          }
                        />
                        {showMailingAddressLine1Error && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.mailingAddressLine1[0]}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item lg={6}>
                      {/* <div className={classes.field}>
                                                <TextField
                                                    className={classes.textField}
                                                    label="Mailing Address Line 2"
                                                    margin="dense"
                                                    variant="outlined"
                                                    name="mailingAddressLine2"
                                                    value={values.mailingAddressLine2}
                                                    onChange={event =>
                                                        this.handleFieldChange("mailingAddressLine2", event.target.value, event)
                                                    }
                                                />
                                                {showMailingAddressLine2Error && (
                                                    <Typography
                                                        className={classes.fieldError}
                                                        variant="body2"
                                                    >
                                                        {errors.mailingAddressLine2[0]}
                                                    </Typography>
                                                )}
                                            </div> */}
                    </Grid>
                  </Grid>
                  <Grid container className={classes.grid} spacing={0}>
                    <Grid item lg={2}>
                      <div className={classes.field}>
                        <TextField
                          className={classNames(
                            classes.textField,
                            classes.dropdownTopMargin
                          )}
                          label="City"
                          margin="dense"
                          required
                          name="city"
                          value={values.city}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "city",
                              event.target.value,
                              event
                            )
                          }
                          variant="outlined"
                        ></TextField>
                        {showMailingCityError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.city[0]}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item lg={2}>
                      <div className={classes.field}>
                        <TextField
                          className={classNames(
                            classes.textField,
                            classes.dropdownTopMargin
                          )}
                          label="State"
                          margin="dense"
                          required
                          name="state"
                          value={values.state.toUpperCase()}
                          inputProps={{
                            maxLength: 2,
                          }}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "state",
                              event.target.value.toUpperCase(),
                              event
                            )
                          }
                          variant="outlined"
                        ></TextField>
                        {showMailingStateError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.state[0]}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item lg={2}>
                      <div className={classes.field}>
                        <TextField
                          className={classNames(
                            classes.textField,
                            classes.dropdownTopMargin
                          )}
                          label="Zipcode"
                          margin="dense"
                          variant="outlined"
                          name="zipcode"
                          value={values.zipcode}
                          required
                          inputProps={{
                            maxLength: 10,
                          }}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "zipcode",
                              event.target.value,
                              event
                            )
                          }
                        />
                        {showMailingZipcodeError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.zipcode[0]}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </PortletContent>
                <PortletFooterComponent
                  classes={classes}
                  isLoading={isLoading}
                  isValid={isValid}
                  forceDisableEditing={forceDisableEditing}
                  userData={this.userData}
                  resetFormValues={this.resetFormValues}
                  completeAgentRegistration={this.completeAgentRegistration}
                  showClearButton={false}
                />
              </Portlet>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Portlet className={rootClassName} key="additional-details">
                <PortletHeader>
                  <PortletLabel
                    title="Agent Marketing"
                    subtitle="This is the Address that will be Printed as the Return Address on Precision Market Analysis. Any returned mail will be sent to this address"
                  />
                </PortletHeader>
                <PortletContent className={classes.autoHeightPortlet} noPadding>
                  <Grid container className={classes.grid} spacing={0}>
                    <Grid item lg={4}>
                      <div className={classNames(classes.field)}>
                        <TextField
                          className={classes.textField}
                          label="Website (optional)"
                          margin="dense"
                          variant="outlined"
                          name="website"
                          value={values.website}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "website",
                              event.target.value,
                              event
                            )
                          }
                          helperText={"e.g. www.xyz.com or xyz.abc.com"}
                          InputProps={{
                            startAdornment:
                              (values.website.match(/\./g) || []).length <=
                              1 ? (
                                <InputAdornment
                                  position="start"
                                  className={classes.websiteAdornment}
                                >
                                  www.
                                </InputAdornment>
                              ) : (
                                ""
                              ),
                          }}
                        />
                        {showWebsiteError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.website[0]}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item lg={4}>
                      <div className={classNames(classes.field)}>
                        <TextField
                          className={classes.textField}
                          label="Display Email"
                          margin="dense"
                          variant="outlined"
                          name="displayEmailAddress"
                          value={values.displayEmailAddress}
                          // ? values.displayEmailAddress : values.emailAddress
                          required={false}
                          // onBlur={event => {
                          //   this.validateRegisterEmail();
                          // }}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "displayEmailAddress",
                              event.target.value,
                              event
                            )
                          }
                          helperText={
                            "Optional. How do you want to display your email on PMA."
                          }
                          type="text"
                        />
                        {showAgentDisplayEmailError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.displayEmailAddress[0]}
                          </Typography>
                        )}
                      </div>

                      {/* <div className={classNames(classes.field)}>
                        <TextField
                          className={classes.textField}
                          label="Tagline (optional) Example: Serving the city xx for over 2 years..."
                          margin="dense"
                          variant="outlined"
                          name="tagline"
                          value={values.tagline}
                          inputProps={{
                            maxLength: 105,
                          }}
                          multiline={true}
                          rowsMax={2}
                          onChange={event =>
                            this.handleFieldChange(
                              'tagline',
                              event.target.value,
                              event
                            )
                          }
                          helperText="Example: Serving the city xx for over 2 years...(*max 105 characters)"
                        />
                        {showTaglineError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.tagline[0]}
                          </Typography>
                        )}
                      </div> */}
                    </Grid>
                    <Grid item lg={4}></Grid>
                  </Grid>
                  {/* <Grid container spacing={0}> */}
                  <Grid container className={classes.grid} spacing={0}>
                    <Grid item lg={6}>
                      <div
                        className={classNames(
                          classes.field,
                          classes.marginTopTextOfficefield
                        )}
                      >
                        <TextField
                          className={classNames(classes.textField)}
                          label="Office Address"
                          margin="dense"
                          required={false}
                          variant="outlined"
                          name="officeAddressLine1"
                          value={values.officeAddressLine1}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "officeAddressLine1",
                              event.target.value,
                              event
                            )
                          }
                        />
                        {showOfficeAddressLine1Error && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.officeAddressLine1[0]}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item lg={6}>
                      <div
                        className={classNames(
                          classes.field,
                          classes.marginTopTextOfficefield
                        )}
                      >
                        {/* <TextField
                                                    className={classNames(classes.textField)}
                                                    label="Office Address Line 2"
                                                    margin="dense"
                                                    variant="outlined"
                                                    name="officeAddressLine2"
                                                    value={values.officeAddressLine2}
                                                    onChange={event =>
                                                        this.handleFieldChange("officeAddressLine2", event.target.value, event)
                                                    }
                                                />
                                                {showOfficeAddressLine2Error && (
                                                    <Typography
                                                        className={classes.fieldError}
                                                        variant="body2"
                                                    >
                                                        {errors.officeAddressLine2[0]}
                                                    </Typography>
                                                )} */}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.grid} spacing={0}>
                    <Grid item lg={2} md={2}>
                      <div
                        className={classNames(
                          classes.field,
                          classes.marginTopTextOfficefield
                        )}
                      >
                        <TextField
                          className={classNames(classes.textField)}
                          label="Office City"
                          margin="dense"
                          required={false}
                          name="officeCity"
                          value={values.officeCity}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "officeCity",
                              event.target.value,
                              event
                            )
                          }
                          variant="outlined"
                        ></TextField>
                        {showOfficeCityError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.officeCity[0]}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item lg={2} md={2}>
                      <div
                        className={classNames(
                          classes.field,
                          classes.marginTopTextOfficefield
                        )}
                      >
                        <TextField
                          className={classNames(classes.textField)}
                          label="State"
                          margin="dense"
                          required={false}
                          name="officeState"
                          value={values.officeState.toUpperCase()}
                          inputProps={{
                            maxLength: 2,
                          }}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "officeState",
                              event.target.value.toUpperCase(),
                              event
                            )
                          }
                          variant="outlined"
                        ></TextField>
                        {showOfficeStateError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.officeState[0]}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item lg={2} md={2}>
                      <div
                        className={classNames(
                          classes.field,
                          classes.marginTopTextOfficefield
                        )}
                      >
                        <TextField
                          className={classNames(classes.textField)}
                          label="Zipcode"
                          margin="dense"
                          variant="outlined"
                          name="officeZipcode"
                          value={values.officeZipcode}
                          required={false}
                          inputProps={{
                            maxLength: 10,
                          }}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "officeZipcode",
                              event.target.value,
                              event
                            )
                          }
                        />
                        {showOfficeZipcodeError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.officeZipcode[0]}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item lg={6}></Grid>
                  </Grid>

                  {/* </Grid> */}
                  <Grid container className={classes.grid} spacing={0}>
                    {/* </Grid>
                                    <Grid container spacing={0}> */}
                    <Grid
                      item
                      lg={
                        this.userData.listgenRoleID ===
                        Base.APP_DATA.APP_ADMIN_ROLE_ID
                          ? 3
                          : 4
                      }
                    >
                      <div
                        className={classes.imageHolderWrapper}
                        id="agent-photo-section"
                      >
                        <div className={classes.imageHolder}>
                          {values.agentPhoto
                            ? commonUploadStatusMessage(
                                "Photo uploaded successfully."
                              )
                            : commonUploadStatusMessage(
                                "Please Upload your Photo"
                              )}
                          {values.agentPhoto && (
                            <img
                              src={values.agentPhoto}
                              alt={"Agent Photo"}
                              className={classes.imageTag}
                              onClick={(event) =>
                                this.openMediaViewDialog(
                                  values.agentPhoto,
                                  "Agent Photo"
                                )
                              }
                            />
                          )}
                        </div>
                        <div className={classes.fieldSection}>
                          <div className={classes.fieldSectionContainer}>
                            <Button
                              onClick={(event) =>
                                this.openImageUplaodDialog(
                                  "profile",
                                  values.agentPhoto
                                )
                              }
                              variant="contained"
                              component="span"
                              color="primary"
                              className={classNames(
                                classes.uploadButton,
                                classes.marginTop2Unit,
                                !values.agentPhoto && classes.flashButton
                              )}
                            >
                              Upload Photo
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      lg={
                        this.userData.listgenRoleID ===
                        Base.APP_DATA.APP_ADMIN_ROLE_ID
                          ? 3
                          : 4
                      }
                    >
                      <div
                        className={classes.imageHolderWrapper}
                        id="agent-logo-section"
                      >
                        <div className={classes.imageHolder}>
                          {values.agentLogo
                            ? commonUploadStatusMessage(
                                "Logo uploaded successfully"
                              )
                            : commonUploadStatusMessage(
                                this.userData.listgenRoleID ===
                                  Base.APP_DATA.APP_ADMIN_ROLE_ID
                                  ? "No current agent logo with light background"
                                  : "Please Upload your Logo",
                                false
                              )}
                          {values.agentLogo && (
                            <img
                              src={values.agentLogo}
                              alt={
                                this.userData.listgenRoleID ===
                                Base.APP_DATA.APP_ADMIN_ROLE_ID
                                  ? "Agent Light Background Logo"
                                  : "Agent Logo"
                              }
                              className={classes.imageTag}
                              onClick={(event) =>
                                this.openMediaViewDialog(
                                  values.agentLogo,
                                  this.userData.listgenRoleID ===
                                    Base.APP_DATA.APP_ADMIN_ROLE_ID
                                    ? "Agent Light Background Logo"
                                    : "Agent Logo"
                                )
                              }
                            />
                          )}
                        </div>
                        <div className={classes.fieldSection}>
                          <div className={classes.fieldSectionContainer}>
                            <Button
                              onClick={(event) =>
                                this.openImageUplaodDialog(
                                  "logo",
                                  values.agentLogo
                                )
                              }
                              variant="contained"
                              component="span"
                              color="primary"
                              className={classNames(
                                classes.uploadButton,
                                classes.marginTop2Unit,
                                !values.agentLogo && classes.flashButton
                              )}
                            >
                              {this.userData.listgenRoleID ===
                              Base.APP_DATA.APP_ADMIN_ROLE_ID
                                ? "Upload Agent Light BG Logo"
                                : "Upload Logo"}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    {this.userData.listgenRoleID ===
                    Base.APP_DATA.APP_ADMIN_ROLE_ID ? (
                      <Grid item lg={3}>
                        <div className={classes.imageHolderWrapper}>
                          <div className={classes.imageHolder}>
                            {values.agentLogo2
                              ? commonUploadStatusMessage(
                                  "Dark logo uploaded successfully"
                                )
                              : commonUploadStatusMessage(
                                  "No current agent logo with dark background"
                                )}
                            {values.agentLogo2 && (
                              <img
                                src={values.agentLogo2}
                                alt={"Agent Dark Background Logo"}
                                className={classes.imageTag}
                                onClick={(event) =>
                                  this.openMediaViewDialog(
                                    values.agentLogo2,
                                    "Agent Dark Background Logo"
                                  )
                                }
                              />
                            )}
                          </div>
                          <div className={classes.fieldSection}>
                            <div className={classes.fieldSectionContainer}>
                              <Button
                                onClick={(event) =>
                                  this.openImageUplaodDialog(
                                    "logo2",
                                    values.agentLogo2
                                  )
                                }
                                variant="contained"
                                component="span"
                                color="primary"
                                className={classNames(
                                  classes.uploadButton,
                                  classes.marginTop2Unit,
                                  !values.agentLogo2 && classes.flashButton
                                )}
                              >
                                Upload Agent Dark BG Logo
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    ) : (
                      ""
                    )}
                    {this.userData.listgenRoleID ===
                    Base.APP_DATA.APP_ADMIN_ROLE_ID ? (
                      // {/* //show agent brokerage url 2 if it's a admin login, grid lg logic is aready applied on top elements */}
                      <Grid item lg={3}>
                        <div className={classes.imageHolderWrapper}>
                          <div className={classes.imageHolder}>
                            {values.agentBrokerageLogoURL
                              ? commonUploadStatusMessage(
                                  "Brokerage logo uploaded successfully"
                                )
                              : commonUploadStatusMessage(
                                  " No current agent brokerage logo"
                                )}
                            {values.agentBrokerageLogoURL && (
                              <img
                                src={values.agentBrokerageLogoURL}
                                alt={"Agent Brokerage Logo"}
                                className={classes.imageTag}
                                onClick={(event) =>
                                  this.openMediaViewDialog(
                                    values.agentBrokerageLogoURL,
                                    "Agent Brokerage Logo"
                                  )
                                }
                              />
                            )}
                          </div>
                          <div className={classes.fieldSection}>
                            <div className={classes.fieldSectionContainer}>
                              <Button
                                onClick={(event) =>
                                  this.openImageUplaodDialog(
                                    "brokerageLogo",
                                    values.agentBrokerageLogoURL
                                  )
                                }
                                variant="contained"
                                component="span"
                                color="primary"
                                className={classNames(
                                  classes.uploadButton,
                                  classes.marginTop2Unit,
                                  !values.agentBrokerageLogoURL &&
                                    classes.flashButton
                                )}
                              >
                                Upload Agent Brokerage Logo
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    ) : null}
                    <Grid item lg={3}>
                      <div
                        className={classes.imageHolderWrapper}
                        id="area-tagline-section"
                      >
                        <div className={classes.imageHolder}>
                          <div>
                            {this.isAnyAreaTaglineAdded()
                              ? commonUploadStatusMessage(
                                  "Tagline added successfully"
                                )
                              : commonUploadStatusMessage(
                                  "Please add your Tagline"
                                )}
                          </div>
                          {this.isAnyAreaTaglineAdded() && (
                            <div style={{ marginTop: "16px" }}>
                              <Typography variant="subtitle2">
                                {this.getFormattedTagline()}
                              </Typography>
                              <Typography variant="subtitle2">
                                {this.getFormattedTagline(1)} 
                              </Typography>
                              <Typography
                                variant="caption"
                                className={classes.marginTop1Unit}
                              >
                                Click on View / Edit Tagline button to see
                                more...
                              </Typography>
                            </div>
                          )}
                        </div>
                        <div className={classes.fieldSection}>
                          <div className={classes.fieldSectionContainer}>
                            <AreaTaglineDialog
                              navbarInfo={values.navbarInfo}
                              parentHandleFieldChange={this.handleFieldChange}
                              listgenUserID={listgenUserID}
                              isValid={isValid}
                              forceDisableEditing={forceDisableEditing}
                              isLoading={isLoading}
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>

                    {/* <Grid item lg={4}>
                      <div className={classes.imageHolderWrapper}>
                        {values.agentSignature ? (
                          <div className={classes.imageHolder}>
                            {this.regexWithPDF.test(
                              values.agentSignature.toLowerCase()
                            ) ? (
                              <IconButton
                                color="primary"
                                title="Click to view PDF"
                                onClick={event =>
                                  this.openPDFInNewTab(values.agentSignature)
                                }
                              >
                                <PictureAsPdfIcon
                                  fontSize="large"
                                  className={classes.pdfIcon}
                                />
                              </IconButton>
                            ) : (
                              <img
                                src={values.agentSignature}
                                alt={'Agent Signature'}
                                className={classes.imageTag}
                                onClick={event =>
                                  this.openMediaViewDialog(
                                    values.agentSignature,
                                    'Agent Signature'
                                  )
                                }
                              />
                            )}
                          </div>
                        ) : (
                          <Typography
                            variant="subtitle2"
                            className={classes.noCuurentImage}
                          >
                            No current signature
                          </Typography>
                        )}
                        <div className={classes.fieldSection}>
                          <div className={classes.fieldSectionContainer}>
                            <Button
                              onClick={event =>
                                this.openImageUplaodDialog(
                                  'signature',
                                  values.agentSignature
                                )
                              }
                              variant="contained"
                              component="span"
                              color="primary"
                              className={classNames(
                                classes.uploadButton,
                                classes.marginTop2Unit
                              )}
                            >
                              Upload Agent Signature
                            </Button>
                            <Typography
                              variant="body2"
                              className={classes.marginTop1Unit}
                            >
                              If you are a member of a TEAM: Each team member
                              will be asked to upload their own signatures
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Grid> */}
                  </Grid>
                </PortletContent>
              </Portlet>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Portlet className={rootClassName} key="brokerage-details">
                <PortletHeader>
                  <PortletLabel title="Billing Info" />
                </PortletHeader>
                <PortletContent noPadding>
                  <Grid container spacing={0}>
                    <Grid item lg={12}>
                      {/* <div className={classes.field}> */}
                      <div className={classes.checkboxField}>
                        <Checkbox
                          color="primary"
                          checked={values.billingAddresSameAsMailingAddress}
                          onChange={(event) => {
                            this.handleFieldChange(
                              "billingAddresSameAsMailingAddress",
                              event.target.value,
                              event
                            );
                            this.copyValuesFromMailingToBilling(
                              event.target.checked
                            );
                          }}
                        />
                        <div>
                          <Typography variant="subtitle1">
                            Is billing address same as mailing address
                          </Typography>
                        </div>
                      </div>
                      {/* {showB && (
                                                    <Typography
                                                        className={classes.fieldError}
                                                        variant="body2"
                                                    >
                                                        {errors.billingAddresSameAsMailingAddress[0]}
                                                    </Typography>
                                                )} */}
                      {/* </div> */}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item lg={6}>
                      <div className={classes.field}>
                        <TextField
                          className={classNames(classes.textField)}
                          label="First Name"
                          margin="dense"
                          required
                          name="billingFirstName"
                          value={
                            values.billingAddresSameAsMailingAddress
                              ? values.firstName
                              : values.billingFirstName
                          }
                          inputProps={{
                            readOnly: values.billingAddresSameAsMailingAddress,
                          }}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "billingFirstName",
                              event.target.value,
                              event
                            )
                          }
                          variant="outlined"
                        ></TextField>
                        {showBillingFirstNameError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.billingFirstName[0]}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item lg={6}>
                      <div className={classes.field}>
                        <TextField
                          className={classes.textField}
                          label="Last Name"
                          margin="dense"
                          required
                          variant="outlined"
                          name="billingLastName"
                          value={
                            values.billingAddresSameAsMailingAddress
                              ? values.lastName
                              : values.billingLastName
                          }
                          inputProps={{
                            readOnly: values.billingAddresSameAsMailingAddress,
                          }}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "billingLastName",
                              event.target.value,
                              event
                            )
                          }
                        />
                        {showBillingLastNameError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.billingLastName[0]}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item lg={6}>
                      <div
                        className={classNames(
                          classes.field,
                          classes.marginTopTextfield
                        )}
                      >
                        <TextField
                          className={classNames(classes.textField)}
                          label="Billing Address"
                          margin="dense"
                          required
                          variant="outlined"
                          name="billingAddressLine1"
                          value={
                            values.billingAddresSameAsMailingAddress
                              ? values.mailingAddressLine1
                              : values.billingAddressLine1
                          }
                          inputProps={{
                            readOnly: values.billingAddresSameAsMailingAddress,
                          }}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "billingAddressLine1",
                              event.target.value,
                              event
                            )
                          }
                        />
                        {showBillingAddressLine1Error && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.billingAddressLine1[0]}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item lg={6}></Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item lg={6}>
                      <div
                        className={classNames(
                          classes.field,
                          classes.marginTopTextfield
                        )}
                      >
                        <TextField
                          className={classNames(classes.textField)}
                          label="Billing Address Line 2"
                          margin="dense"
                          variant="outlined"
                          name="billingAddressLine2"
                          value={
                            values.billingAddresSameAsMailingAddress
                              ? values.mailingAddressLine2
                              : values.billingAddressLine2
                          }
                          inputProps={{
                            readOnly: values.billingAddresSameAsMailingAddress,
                          }}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "billingAddressLine2",
                              event.target.value,
                              event
                            )
                          }
                        />
                        {showBillingAddressLine2Error && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.billingAddressLine2[0]}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item lg={6}></Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item lg={2} md={2}>
                      <div
                        className={classNames(
                          classes.field,
                          classes.marginTopTextfield
                        )}
                      >
                        <TextField
                          className={classNames(classes.textField)}
                          label="City"
                          margin="dense"
                          required
                          name="billingCity"
                          value={
                            values.billingAddresSameAsMailingAddress
                              ? values.city
                              : values.billingCity
                          }
                          inputProps={{
                            readOnly: values.billingAddresSameAsMailingAddress,
                          }}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "billingCity",
                              event.target.value,
                              event
                            )
                          }
                          variant="outlined"
                        ></TextField>
                        {showBillingCityError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.billingCity[0]}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item lg={2} md={2}>
                      <div className={classes.field}>
                        <TextField
                          className={classNames(
                            classes.textField,
                            classes.dropdownTopMargin
                          )}
                          label="State"
                          margin="dense"
                          required
                          name="billingState"
                          value={
                            values.billingAddresSameAsMailingAddress
                              ? values.state.toUpperCase()
                              : values.billingState.toUpperCase()
                          }
                          inputProps={{
                            maxLength: 2,
                            readOnly: values.billingAddresSameAsMailingAddress,
                          }}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "billingState",
                              event.target.value.toUpperCase(),
                              event
                            )
                          }
                          variant="outlined"
                        ></TextField>
                        {showBillingStateError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.billingState[0]}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item lg={2} md={2}>
                      <div
                        className={classNames(
                          classes.field,
                          classes.marginTopTextfield
                        )}
                      >
                        <TextField
                          className={classNames(classes.textField)}
                          label="Zipcode"
                          margin="dense"
                          variant="outlined"
                          name="billingZipcode"
                          value={
                            values.billingAddresSameAsMailingAddress
                              ? values.zipcode
                              : values.billingZipcode
                          }
                          required
                          inputProps={{
                            maxLength: 10,
                            readOnly: values.billingAddresSameAsMailingAddress,
                          }}
                          onChange={(event) =>
                            this.handleFieldChange(
                              "billingZipcode",
                              event.target.value,
                              event
                            )
                          }
                        />
                        {showBillingZipcodeError && (
                          <Typography
                            className={classes.fieldError}
                            variant="body2"
                          >
                            {errors.billingZipcode[0]}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item lg={6}></Grid>
                  </Grid>
                </PortletContent>
                <PortletFooterComponent
                  classes={classes}
                  isLoading={isLoading}
                  isValid={isValid}
                  forceDisableEditing={forceDisableEditing}
                  userData={this.userData}
                  resetFormValues={this.resetFormValues}
                  completeAgentRegistration={this.completeAgentRegistration}
                  showClearButton={false}
                />
              </Portlet>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Portlet className={rootClassName} key="brokerage-details">
                <PortletHeader>
                  <PortletLabel title="Team Info" />
                </PortletHeader>
                <PortletContent noPadding>
                  <Grid container spacing={0}>
                    <Grid item lg={12}>
                      <div>
                        {!values.isMemberOfTeam ||
                        !values.teamMembers.length ? (
                          <div className={classes.checkboxField}>
                            <Checkbox
                              color="primary"
                              checked={values.isMemberOfTeam}
                              onChange={(event) => {
                                this.setModelDialogOpen(
                                  "isMemberOfTeam",
                                  event.target.value,
                                  event
                                );
                              }}
                            />
                            <div>
                              <Typography variant="subtitle1">
                                I am a member of a team
                              </Typography>
                            </div>
                          </div>
                        ) : (
                          <Fragment>
                            <div className={classes.field}>
                              <Typography variant="subtitle2">
                                You are a member of team :{" "}
                                <strong>{values.teamName}</strong>
                              </Typography>
                              <div className={classes.teamMemberButtons}>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => this.editTeamMembersDetails()}
                                >
                                  Edit Team Member Details
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="default"
                                  onClick={() =>
                                    this.removeTeamMembersDetails()
                                  }
                                >
                                  Remove Team Members Details
                                </Button>
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </PortletContent>
                <PortletFooterComponent
                  classes={classes}
                  isLoading={isLoading}
                  isValid={isValid}
                  forceDisableEditing={forceDisableEditing}
                  userData={this.userData}
                  resetFormValues={this.resetFormValues}
                  completeAgentRegistration={this.completeAgentRegistration}
                  showClearButton={true}
                />
                {/* {this.userData.listgenRoleID ===
                  Base.APP_DATA.APP_AGENT_ROLE_ID ? (
                  <PortletFooter className={classes.portletFooter}>
                    <div className={classes.footerButtons}>
                      <div className={classes.footerButtonsLeftRight}>
                        <Button
                          variant="outlined"
                          disabled={forceDisableEditing}
                          onClick={() => this.resetFormValues()}
                        >
                          Clear Details
                        </Button>
                      </div>
                      <div className={classes.footerButtonsCenter}>
                        {isLoading ? (
                          <CircularProgress className={classes.progress} />
                        ) : (
                          <Button
                            color="primary"
                            variant="contained"
                            disabled={!isValid || forceDisableEditing}
                            onClick={(event) => {
                              this.completeAgentRegistration();
                            }}
                          >
                            Update Details
                          </Button>
                        )}
                      </div>
                      <div className={classes.footerButtonsLeftRight}></div>
                    </div>
                  </PortletFooter>
                ) : (
                  ""
                )} */}
              </Portlet>
            </Grid>
            {this.userData.listgenRoleID === Base.APP_DATA.APP_ADMIN_ROLE_ID ? (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Portlet className={rootClassName} key="brokerage-details">
                  <PortletHeader>
                    <PortletLabel title="Status Info" />
                  </PortletHeader>
                  <PortletContent noPadding>
                    <Grid container spacing={0}>
                      <Grid item lg={4}>
                        <div className={classes.field}>
                          <TextField
                            className={classNames(classes.textField)}
                            label="Agent Status"
                            margin="dense"
                            select
                            SelectProps={{ native: false }}
                            name="userStatus"
                            required
                            value={values.userStatus}
                            onChange={(event) =>
                              this.handleFieldChange(
                                "userStatus",
                                event.target.value,
                                event
                              )
                            }
                            variant="outlined"
                          >
                            {statusData.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          {showStatusError && (
                            <Typography
                              className={classes.fieldError}
                              variant="body2"
                            >
                              {errors.userStatus[0]}
                            </Typography>
                          )}
                        </div>
                      </Grid>
                      <Grid item lg={8}>
                        <div className={classes.field}>
                          <TextField
                            className={classNames(classes.textField)}
                            label="Status Description"
                            margin="dense"
                            name="statusDescription"
                            value={values.statusDescription}
                            multiline
                            rows="3"
                            rowsMax="6"
                            onChange={(event) =>
                              this.handleFieldChange(
                                "statusDescription",
                                event.target.value,
                                event
                              )
                            }
                            variant="outlined"
                          ></TextField>
                          {showStatusDescriptionError && (
                            <Typography
                              className={classes.fieldError}
                              variant="body2"
                            >
                              {errors.statusDescription[0]}
                            </Typography>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </PortletContent>
                  <PortletFooter className={classes.portletFooter}>
                    <div className={classes.footerButtons}>
                      <div className={classes.footerButtonsLeftRight}>
                        <Button
                          variant="outlined"
                          onClick={() => this.resetFormValues()}
                          disabled={forceDisableEditing}
                        >
                          Clear Details
                        </Button>
                      </div>
                      <div className={classes.footerButtonsCenter}>
                        {isLoading ? (
                          <CircularProgress className={classes.progress} />
                        ) : (
                          <Button
                            color="primary"
                            variant="contained"
                            disabled={!isValid || forceDisableEditing}
                            onClick={(event) => {
                              this.completeAgentRegistration();
                            }}
                          >
                            Update Details
                          </Button>
                        )}
                      </div>
                      <div className={classes.footerButtonsLeftRight}></div>
                    </div>
                  </PortletFooter>
                </Portlet>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </form>
      </Fragment>
    );
  }
}

export default compose(withStyles(styles), withSnackbar)(AgentDetailsForm);
