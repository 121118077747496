import React, { Component } from "react";
import QVFourCoverPage from "../../../Quarterly/QVFour/QVFourFirstPage/QVFourCoverPage";

class MQTwoFirstRight extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      coverPageDetails,
      printVersion,
      version,
      quarterlyAreaTable,
    } = this.props;
    return (
      <div className="mq2-first-right">
        <QVFourCoverPage
          currentArea={currentArea}
          agentData={agentData}
          coverPageDetails={coverPageDetails}
          hoData={hoData}
          printVersion={printVersion}
          currentSoldListings={quarterlyAreaTable.currentSoldListings}
          marketHighLightsData={quarterlyAreaTable.marketHighLightsData}
          photoNum="0"
          version={version}
          quarterlyAreaTable={quarterlyAreaTable}
          //printDate="March 30"
        />
      </div>
    );
  }
}

export default MQTwoFirstRight;
