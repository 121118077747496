import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import QV2AHGraph from "./QV2AHGraph";

class QV2AvailableHomes extends Component {
  render() {
    const { currentArea, qArea, qCity, hoData } = this.props;
    const { mlsAreaID, mlsNeighborhood } = currentArea;
    const { subdivisionName } = hoData;
    console.log("this.props", this.props);
    const {
      homesNumber,
      slashSeparatedArea,
      splitAreas,
      getSubdivisionName,
    } = propertyUtil;
    let areaTitle = getSubdivisionName(subdivisionName, mlsNeighborhood);
    let twoAreas = true;
    if (mlsAreaID === "157" || mlsAreaID === "1522155") {
      twoAreas = false;
    }
    if (qCity.cityName && qArea.monthsSupplyTable) {
      const {
        avgHomesSoldLast12Months: city12,
        cityName,

        numberOfHomesForSale: citySale,
        maxHousesSoldLast12Months: maxCity,
      } = qCity;

      const {
        AvgHomesSoldLast12Months: area12,
        // AreaName,

        AreaNumberOfHousesForSale: areaSale,
      } = qArea.monthsSupplyTable;

      const avgCity = Number(city12);
      let cityScale = [];

      for (let i = 0; i <= 10; i++) {
        if (avgCity === 0) {
          cityScale = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
          break;
        } else if (i === 0) {
          cityScale.push(0);
        } else {
          cityScale.push(cityScale[cityScale.length - 1] + avgCity);
        }
      }
      let mappedCityBar = cityScale.map((tick) => (
        <p key={tick.toString()}>{tick}</p>
      ));

      const avgArea = Number(area12);

      let areaScale = [];

      for (let i = 0; i <= 10; i++) {
        if (avgArea === 0) {
          areaScale = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
          break;
        } else if (i === 0) {
          areaScale.push(0);
        } else {
          areaScale.push(areaScale[areaScale.length - 1] + avgArea);
          // console.log('areaScale i', areaScale);
        }
      }

      let mappedAreaBar = areaScale.map((tick) => (
        <p key={tick.toString()}>{tick}</p>
      ));

      const areaPx = Math.min(
        100,
        homesNumber(
          areaSale,
          avgArea * 10,
          false,
          false,
          maxCity,
          citySale,
          avgCity,
          avgArea
        )
      );

      const areaBg = homesNumber(
        areaSale,
        avgArea * 10,
        true,
        false,
        maxCity,
        citySale,
        avgCity,
        avgArea
      )[0];

      const areaInventory = homesNumber(
        areaSale,
        avgArea * 10,
        true,
        false,
        maxCity,
        citySale,
        avgCity,
        avgArea
      )[1];

      const cityPx = Math.min(
        100,
        homesNumber(
          citySale,
          avgCity * 10,
          false,
          true,
          maxCity,
          citySale,
          avgCity,
          avgArea
        )
      );
      const cityBg = homesNumber(
        citySale,
        avgCity * 10,
        true,
        true,
        maxCity,
        citySale,
        avgCity,
        avgArea
      )[0];
      const cityInventory = homesNumber(
        citySale,
        avgCity * 10,
        true,
        true,
        maxCity,
        citySale,
        avgCity,
        avgArea
      )[1];

      let cityPerc;
      if (cityPx === 0 || cityPx === 1) {
        cityPerc = 2;
      } else if (cityPx > 0 && citySale < 2) {
        cityPerc = Math.round(
          (citySale / cityScale[cityScale.length - 1]) * 100
        );
      } else {
        cityPerc = cityPx;
      }
      // console.log("cityPerc", cityPerc);
      // console.log("cityPx", cityPx);

      let areaPerc;
      if (areaPx === 0 || areaPx === 1) {
        areaPerc = 1;
      } else if (areaPx > 0 && areaSale < 2) {
        areaPerc = Math.round(
          (areaSale / areaScale[areaScale.length - 1]) * 100
        );
      } else {
        areaPerc = areaPx;
      }
      // console.log("areaPerc", areaPerc);
      // console.log("areaPx", areaPx);

      const cityOverflowDiff = citySale - cityScale.slice(-1);
      const areaOverflowDiff = areaSale - areaScale.slice(-1);

      let allAreas;
      const areaSplit = splitAreas(mlsNeighborhood);
      const areaCount = areaSplit.length;
      allAreas = slashSeparatedArea(mlsNeighborhood);

      return (
        <div className="qv2-available-homes-container">
          <div className="available-homes-title-container">
            <p className="available-homes-title-text">
              Available Homes For Sale
            </p>
            <div className="available-homes-title-divider" />
          </div>
          <div className="available-homes-inner">
            <div
              className={"ah-bar-container " + (twoAreas ? "" : "two-areas")}
            >
              {twoAreas && (
                <QV2AHGraph
                  isCity={true}
                  areaCityName={cityName}
                  bg={cityBg}
                  ddPerc={cityPerc}
                  inventory={cityInventory}
                  sale={citySale}
                  overflowDiff={cityOverflowDiff}
                  mappedBar={mappedCityBar}
                />
              )}

              <QV2AHGraph
                isCity={false}
                areaCount={areaCount}
                areaSplit={areaSplit}
                // areaCityName={allAreas}
                areaCityName={areaTitle}
                bg={areaBg}
                ddPerc={areaPerc}
                inventory={areaInventory}
                sale={areaSale}
                overflowDiff={areaOverflowDiff}
                mappedBar={mappedAreaBar}
                twoAreas={twoAreas}
              />
            </div>
            <div className="inventory-bars-container">
              <div className="inventory-bar-item low-inventory-bar">
                <div className="sold inventory-legend" />
                <div className="bar-item-row">
                  <span>
                    <strong>LOW </strong>Inventory
                  </span>
                  <span className="bar-item-divider">|</span>
                  <span>&lt; 4 month's supply</span>
                </div>
              </div>

              <div className="inventory-bar-item">
                <div className="active inventory-legend" />
                <div className="bar-item-row">
                  <span>
                    <strong>BALANCED </strong>Inventory
                  </span>
                  <span className="bar-item-divider">|</span>
                  <span>4-6 month's supply</span>
                </div>
              </div>

              <div className="inventory-bar-item">
                <div className="primary inventory-legend" />
                <div className="bar-item-row">
                  <span>
                    <strong>HIGH </strong>Inventory
                  </span>
                  <span className="bar-item-divider">|</span>
                  <span>&gt; 6 month's supply</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <p>Loading..</p>;
    }
  }
}

export default QV2AvailableHomes;
