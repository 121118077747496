import React, { Component } from "react";
import BryanUhrigQRLogo from "_img/agentLogos/bryan_uhrig_qr.png";
import VillagePropertiesForbesLogo from "_img/agentLogos/village-properties-forbes.jpg";
import VillagePropertiesForbesLogo2 from "_img/agentLogos/village-properties-forbes2.png";
import VillagePropAndForbesLogo from "_img/agentLogos/village-prop-and-forbes-logo.png";
import LisaMLogo from "_img/agentLogos/LisaMLogo.png";
import SuzanneGarciaHeartLogo from "_img/agentLogos/suzanne-garcia-heart-logo.png";

//3rd page, the photo page, above the global note to the top right
class QVFourAgentLogoHeader extends Component {
  render() {
    let { logoURL, listgenUserID } = this.props.agentData;
    let containerStyle;

    let logoStyle;
    const isClaudiaM = listgenUserID === "100075";
    let isDavidKim = listgenUserID === "100778";
    let isDonPerry = listgenUserID === "101649";
    let isDerkBrill = listgenUserID === "105785";
    let isAnnetteVait = listgenUserID === "101001";
    const isKyle3Team = listgenUserID === "103406";

    if (isKyle3Team) {
      logoStyle = "long-agent-logo-header-v1";
    } else if (
      listgenUserID === "101003" ||
      listgenUserID === "100329" ||
      listgenUserID === "100216" ||
      listgenUserID === "100220" ||
      listgenUserID === "100152" ||
      listgenUserID === "100171" ||
      listgenUserID === "100134" ||
      listgenUserID === "100129" ||
      listgenUserID === "100076" ||
      listgenUserID === "100026" ||
      listgenUserID === "100314" ||
      listgenUserID === "100462" ||
      listgenUserID === "100567" ||
      listgenUserID === "100588" ||
      listgenUserID === "102941"
    ) {
      containerStyle = "less-margin-agent-logo-header";
      logoStyle = "tall-agent-logo";
    }
    if (listgenUserID === "106655" || listgenUserID === "103455") {
      logoStyle = "agent-logo-header-height-45";
    } else if (
      listgenUserID === "100401" ||
      // listgenUserID === "100778" ||
      listgenUserID === "100374" ||
      listgenUserID === "101677" ||
      listgenUserID === "100528" ||
      listgenUserID === "100474" ||
      listgenUserID === "100656" ||
      listgenUserID === "100585" ||
      listgenUserID === "102288" ||
      listgenUserID === "101332" ||
      listgenUserID === "102641" ||
      listgenUserID == "105790" ||
      listgenUserID === "104504" ||
      listgenUserID === "102843" ||
      listgenUserID === "107001" ||
      listgenUserID === "105047" ||
      listgenUserID === "105795" ||
      listgenUserID === "104113" ||
      listgenUserID === "105799" ||
      listgenUserID === "102694" ||
      listgenUserID === "104412" ||
      listgenUserID === "102734" ||
      listgenUserID === "105851" ||
      listgenUserID === "105100" ||
      listgenUserID === "104804" ||
      listgenUserID === "102712" ||
      listgenUserID === "102660" ||
      listgenUserID === "106472" ||
      listgenUserID === "104835" ||
      listgenUserID === "104819" ||
      listgenUserID === "106145" ||
      listgenUserID === "105189" ||
      listgenUserID === "105896" ||
      listgenUserID === "107012" ||
      listgenUserID === "105856" ||
      listgenUserID === "105959" ||
      listgenUserID === "106144"
    ) {
      containerStyle = "reduced-agent-logo-header";
      logoStyle = "xl-agent-logo";
    } else if (
      listgenUserID === "100567" ||
      listgenUserID === "100056" ||
      listgenUserID === "100047" ||
      listgenUserID === "100074" ||
      listgenUserID === "100458" ||
      listgenUserID === "101566" ||
      listgenUserID === "100778" ||
      listgenUserID === "105473" ||
      listgenUserID === "104626" ||
      listgenUserID === "104597" ||
      listgenUserID === "104510" ||
      listgenUserID === "105678" ||
      listgenUserID === "104683" ||
      listgenUserID === "102674" ||
      listgenUserID === "100746" ||
      listgenUserID === "106024"
    ) {
      containerStyle = "reduced-agent-logo-header";
      logoStyle = "reduced-width-agent-logo-header";
    } else if (listgenUserID === "103405") {
      containerStyle = "reduced-agent-logo-header";
      logoStyle = "larger-logo";
    } else if (
      listgenUserID === "102809" ||
      listgenUserID === "102340" ||
      listgenUserID === "106421" ||
      listgenUserID === "105797"
    ) {
      logoStyle = "agent-logo-height-85";
    } else if (
      listgenUserID === "100138" ||
      listgenUserID === "100348" ||
      listgenUserID === "106578" ||
      listgenUserID === "102808" ||
      listgenUserID === "102507" ||
      listgenUserID === "105222" ||
      listgenUserID === "102481" ||
      listgenUserID === "105016" ||
      listgenUserID === "102995" ||
      listgenUserID === "101479" ||
      listgenUserID === "105810" ||
      listgenUserID === "104709" ||
      listgenUserID === "102551" ||
      listgenUserID === "106543" ||
      listgenUserID === "106531" ||
      listgenUserID === "105830" ||
      listgenUserID === "106433" ||
      listgenUserID === "106141" ||
      listgenUserID === "106661" ||
      listgenUserID === "105056" ||
      listgenUserID === "100210"
    ) {
      logoStyle = "xxl-agent-logo";
      containerStyle = "xxl-agent-logo-header";
    }

    if (listgenUserID === "105867") {
      logoStyle = "agent-logo-height-25";
    } else if (
      listgenUserID === "100038" ||
      listgenUserID === "100059" ||
      listgenUserID === "100031" ||
      listgenUserID === "100483" ||
      listgenUserID === "102894" ||
      listgenUserID === "105867" ||
      listgenUserID === "104607"
    ) {
      logoStyle = "reduce-height-logo";
    } else if (
      listgenUserID === "100015" ||
      listgenUserID === "100049" ||
      listgenUserID === "100054" ||
      listgenUserID === "102833" ||
      listgenUserID === "102753" ||
      listgenUserID === "106031"
    ) {
      logoStyle = "medium-height-logo";
    } else if (
      listgenUserID === "100227" ||
      listgenUserID === "100411" ||
      listgenUserID === "103404" ||
      listgenUserID === "104480" ||
      listgenUserID === "105789" ||
      listgenUserID === "102802" ||
      listgenUserID === "106647"
    ) {
      logoStyle = "m1-height-logo";
    } else if (listgenUserID === "100040") {
      logoStyle = "width-275-height-60";
    }
    let isSuzanneGarcia = listgenUserID === "106647";
    if (listgenUserID === "100902") {
      logoURL = VillagePropAndForbesLogo;
    } else if (isSuzanneGarcia) {
      logoURL = SuzanneGarciaHeartLogo;
    }

    const isUhrig = listgenUserID === "100568";
    const isDianneJohnson = listgenUserID === "100988";

    return (
      <div className={`qv4-agent-logo-header ${containerStyle}`}>
        {!isDianneJohnson &&
          listgenUserID !== "100746" &&
          listgenUserID !== "106020" &&
          logoURL && (
            <img
              className={`agent-logo-header-img ${logoStyle}`}
              src={
                isKyle3Team
                  ? "https://pma-coverphotos.s3.amazonaws.com/non-covers/MAF_GROUP_4_white_lg_500px.png"
                  : isAnnetteVait
                  ? "https://pma-coverphotos.s3.amazonaws.com/non-covers/Annette+Vait+Logo_Black-wBHHS_500px.png"
                  : isDerkBrill
                  ? "https://pma-coverphotos.s3.amazonaws.com/compressed-derkbrill-horizontal.jpg"
                  : isDonPerry
                  ? "https://pma-coverphotos.s3.amazonaws.com/LP-GROUP_logo_2_111722-01.png"
                  : listgenUserID === "100585"
                  ? "https://pma-coverphotos.s3.amazonaws.com/040623_DSamuel_D-S-Logo_500px_(2).png"
                  : logoURL
              }
              alt="agent logo"
            />
          )}

        {isUhrig && (
          <img
            className="uhrig-agent-logo-header"
            src={BryanUhrigQRLogo}
            alt="Bryan Uhrig Logo"
          />
        )}
        {(isDianneJohnson || listgenUserID === "100746") && (
          <img
            className="dianne-johnson-agent-logo-header"
            src={VillagePropertiesForbesLogo2}
            alt="Village Properties Forbes Logo"
          />
        )}
      </div>
    );
  }
}

export default QVFourAgentLogoHeader;
