export const allStrengthDataBase = {
  //isCustom: true, whether its custom or not. custom values agent can edit
  //isSuggestion: true,//means  whether we add the value in textbox or not.. its a suggestion
  Custom: [
    {
      listgenDesignationName: "Type your custom strength # 1",
      listgenDesignationID: 1,
      isCustom: true,
    },
    {
      listgenDesignationName: "Type your custom strength # 2",
      listgenDesignationID: 2,
      isCustom: true,
    },
    {
      listgenDesignationName: "Type your custom strength # 3",
      listgenDesignationID: 3,
      isCustom: true,
    },
    {
      listgenDesignationName: "Type your custom strength # 4",
      listgenDesignationID: 4,
      isCustom: true,
    },
  ],
  Designations: [],
  Sales: [
    {
      listgenDesignationName: "Top producer (brokerage name) 20XX-20XX",
      listgenDesignationID: 5,
      isCustom: true,
      isSuggestion: true, //means
    },
    {
      listgenDesignationName: "Highest units sold (brokerage name) 20XX - 20XX",
      listgenDesignationID: 6,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "XX% sales increase year-over-year 20XX - 20XX",
      listgenDesignationID: 7,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "Over $XX sold (or) Over homes sold",
      listgenDesignationID: 8,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "Top producer XX out of the last XX years",
      listgenDesignationID: 9,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "(brokerage name) Agent of the Year 20XX",
      listgenDesignationID: 10,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName:
        "(your brokerage name) Highest % increase YOY",
      listgenDesignationID: 11,
      isCustom: true,
      isSuggestion: true,
    },
  ],
  Education: [
    {
      listgenDesignationName: "MBA Masters in Business Administration",
      listgenDesignationID: 12,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "BA in Real Estate Economics",
      listgenDesignationID: 13,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "BA in Business Administration",
      listgenDesignationID: 14,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "Associated Degree in XXXXX",
      listgenDesignationID: 15,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "MS in Real Estate",
      listgenDesignationID: 16,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "GRI - Graduate Real Estate Institute",
      listgenDesignationID: 17,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "Certificate in XXXXX",
      listgenDesignationID: 18,
      isCustom: true,
      isSuggestion: true,
    },
  ],
  Experience: [
    {
      listgenDesignationName: "Realtor XX years on the Monterey Peninsula",
      listgenDesignationID: 19,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "Born and raised on the Monterey Peninsula",
      listgenDesignationID: 20,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "Team leader of XXXX 20XX - 20XX",
      listgenDesignationID: 21,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName:
        "Owned and operated over XX successful businesses",
      listgenDesignationID: 22,
      isCustom: true,
      isSuggestion: true,
    },
  ],
  Volunteer: [
    {
      listgenDesignationName:
        "Board member at XX organization",
      listgenDesignationID: 23,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName:
        "Volunteer at XX organization or non profit",
      listgenDesignationID: 24,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "Docent at XX organization or non profit",
      listgenDesignationID: 25,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "Volunteer SPAC (or other) last X years",
      listgenDesignationID: 26,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "Volunteer Monterey County Food Bank (or other)",
      listgenDesignationID: 27,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "Doner Monterey Food Bank (or other) 20XX - 20XX",
      listgenDesignationID: 28,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "Hospice Volunteer at XXXX",
      listgenDesignationID: 29,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "Museum docent (Monterey Bay Aquarium or other)",
      listgenDesignationID: 30,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "Women’s shelter volunteer",
      listgenDesignationID: 31,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "Soccer/football/baseball (other sport) coach",
      listgenDesignationID: 32,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "Girl / Boy Scout leader (or other)",
      listgenDesignationID: 33,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "American Red Cross Blood drive doner (or other)",
      listgenDesignationID: 34,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "Church/synagogue volunteer",
      listgenDesignationID: 35,
      isCustom: true,
      isSuggestion: true,
    },
  ],
  Family: [
    {
      listgenDesignationName: "Proud Mom/Dad of X beautiful/wonderful children",
      listgenDesignationID: 37,
      isCustom: true,
      isSuggestion: true,
    },
    {
      listgenDesignationName: "Proud Parent of X honor roll highschoolers",
      listgenDesignationID: 38,
      isCustom: true,
      isSuggestion: true,
    },
  ],
};

export const allStrengthDescriptions = {
  Sales: "Share your top Sales or Revenue achievements",
  Education: "Share your higher education degree or certificate",
  Experience: "Share your local experience as a resident or community leader",
  Volunteer: "Add volunteer commitment of organization you are part of",
  Family: "Share something you are proud of",
};
