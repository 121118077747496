import React, { Component, Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles, IconButton, InputAdornment } from '@material-ui/core';
import _ from 'lodash';
import {
  Grid,
  TextField,
  Typography,
  Divider,
  InputLabel,
  Button,
  Zoom
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import styles from './styles';
import { withSnackbar } from 'notistack';
import compose from 'recompose/compose';
import { ImageUploadDialog } from 'components';
import { MediaViewComponent } from 'components';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';

const Step3 = props => {
  const [open, setOpen] = React.useState(false);
  const [openUploadImageDialog, setUploadImageDialog] = React.useState(false);
  const [imageType, setImageType] = React.useState('profile');
  const [currentImage, setCurrentImage] = React.useState(null);
  const {
    classes,
    className,
    handleFieldChange,
    validateForm,
    validateFile,
    haveErrors
  } = props;
  const rootClassName = classNames(classes.root, className);
  const { values, touched, errors, listgenUserID } = props;
  const regexWithPDF = new RegExp(/^.*\.(PDF|pdf)$/);


  /**Error handling */
  const showWebsiteError = touched.website && errors.website;
  const showTaglineError = touched.tagline && errors.tagline;

  /**media view */
  const [mediaURL, setMediaURL] = React.useState(null);
  const [mediaTitle, setMediaTitle] = React.useState(null);
  const [openMediaView, setOpenMediaView] = React.useState(false);

  const showOfficeAddressLine1Error =
    touched.officeAddressLine1 && errors.officeAddressLine1;
  const showOfficeAddressLine2Error =
    touched.officeAddressLine2 && errors.officeAddressLine2;
  const showOfficeCityError = touched.officeCity && errors.officeCity;
  const showOfficeStateError = touched.officeState && errors.officeState;
  const showOfficeZipcodeError = touched.officeZipcode && errors.officeZipcode;

  const openImageUplaodDialog = (imageType, currentImage) => {
    setImageType(imageType);
    setCurrentImage(currentImage);
    setUploadImageDialog(true);
  };

  const onDialogClose = () => {
    setImageType('profile');
    setCurrentImage(null);
    setUploadImageDialog(false);
  };

  const onDialogSubmit = (uploadedImageURL) => {
    if (imageType === 'profile') {
      handleFieldChange("agentPhoto", uploadedImageURL, '');
    } else if (imageType === 'logo') {
      handleFieldChange("agentLogo", uploadedImageURL, '');
    } else if (imageType === 'logo2') {
      handleFieldChange("agentLogo2", uploadedImageURL, '');
    } else if (imageType === 'signature') {
      handleFieldChange("agentSignature", uploadedImageURL, '');
    }
    setImageType('profile');
    setCurrentImage(null);
    setUploadImageDialog(false);
  };

  const openMediaViewDialog = (mediaURL, mediaTitle) => {
    setMediaURL(mediaURL);
    setMediaTitle(mediaTitle);
    setOpenMediaView(true);
  };

  const closeMediaViewDialog = () => {
    setMediaURL(null);
    setMediaTitle(null);
    setOpenMediaView(false);
  };

  const openPDFInNewTab = (url) => {
    var win = window.open(url, '_blank');
    win.focus();
  };

  return (
    <Fragment>
      {openMediaView ?
        <MediaViewComponent
          mediaURL={mediaURL}
          mediaTitle={mediaTitle}
          onOpen={openMediaViewDialog}
          onClose={closeMediaViewDialog}
        />
        : ''}
      <ImageUploadDialog
        openDialog={openUploadImageDialog}
        onSubmit={onDialogSubmit}
        onClose={onDialogClose}
        imageType={imageType}
        listgenUserID={listgenUserID}
        currentImage={currentImage}
        isMyAccountUpload={false}
        isAdminUploading={false}//always be false in registration
      />
      <Grid container className={classes.grid} spacing={0}>
        <Grid item lg={6}>
          <div className={classes.field}>
            <TextField
              className={classes.textField}
              label="Website (optional)"
              margin="dense"
              type="text"
              //variant="outlined"
              name="website"
              value={values.website}
              error={haveErrors(showWebsiteError)}
              onChange={event => {
                handleFieldChange('website', event.target.value, event);
              }}
              helperText={"e.g. www.xyz.com or xyz.abc.com"}
              InputProps={{
                startAdornment: (((values.website.match(/\./g) || []).length <= 1) ? <InputAdornment position="start" className={classes.websiteAdornment}>www.</InputAdornment> : ''),
              }}
            />
            {showWebsiteError && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.website[0]}
              </Typography>
            )}
          </div>
        </Grid>

        <Grid item lg={6}>
          <div className={classes.field}>
            <TextField
              multiline
              rowsMax="3"
              className={classes.textField}
              label="Tagline (optional)"
              margin="dense"
              type="text"
              helperText={
                <Fragment>
                  If you or your team have a 'tagline' or 'slogan' you use in your marketing then this can
                  <br />
                  <br />
                  optionally appear at the top of your cover letter in the PMA. (*max 105 characters)
                  <br />
                  <br />
                  Example: (Use your brokerage's name here) top-selling agent for units sold 2016, 2017
                  <br />
                  OR
                   <br />
                  #1 team in (City name or market) dollars sold in 2018.
                    <br />  OR <br />   Providing the finest real estate service in (name) for over (xx) years.
                    <br />
                    OR
                    <br />
                    Serving the city xx for over 2 years...
                </Fragment>
              }
              name="tagline"
              value={values.tagline}
              error={haveErrors(showTaglineError)}
              inputProps={{ maxLength: 105 }}
              onChange={event => {
                handleFieldChange('tagline', event.target.value, event);
              }}
            />
            {showTaglineError && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.tagline[0]}
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid item lg={12}>
        <Typography className={classes.title} variant="subtitle2">
          Office Address (Optional)
        </Typography>
        <Divider />
      </Grid>
      <Grid container className={classes.grid} spacing={0}>
        <Grid item lg={4}>
          <div className={classes.field}>
            <TextField
              className={classes.textField}
              label="Office Address"
              margin="dense"
              type="text"
              required={false}
              // helperText="This is the address your personal PMA will be mailed to. It is also the address where any non-deliverable PMAs will be returned to."
              //variant="outlined"
              name="mailingAddressLine1"
              value={values.officeAddressLine1}
              error={haveErrors(showOfficeAddressLine1Error)}
              onChange={event => {
                handleFieldChange(
                  'officeAddressLine1',
                  event.target.value,
                  event
                );
              }}
              helperText="This is the Address that will be Printed as Return Address on Mailing. Any returned mail will be sent to this address"
            />
            {showOfficeAddressLine1Error && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.officeAddressLine1[0]}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={4}>
          <div className={classes.field}>
            {/* <TextField
              className={classes.textField}
              label="Office Address Line 2"
              margin="dense"
              type="text"
              //variant="outlined"
              name="officeAddressLine2"
              value={values.officeAddressLine2}
              error={haveErrors(showOfficeAddressLine2Error)}
              onChange={event => {
                handleFieldChange(
                  'officeAddressLine2',
                  event.target.value,
                  event
                );
              }}
            />
            {showOfficeAddressLine2Error && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.officeAddressLine2[0]}
              </Typography>
            )} */}
          </div>
        </Grid>
      </Grid>

      <Grid container className={classes.grid} spacing={0}>
        <Grid item lg={4}>
          <div className={classes.field}>
            <TextField
              className={classNames(
                classes.textField,
                classes.dropdownTopMargin
              )}
              label="Office City"
              margin="dense"
              type="text"
              required={false}
              //variant="outlined"
              name="officeCity"
              value={values.officeCity}
              error={haveErrors(showOfficeCityError)}
              onChange={event => {
                handleFieldChange('officeCity', event.target.value, event);
              }}
            />
            {showOfficeCityError && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.officeCity[0]}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={1}>
          <div className={classes.field}>
            <TextField
              className={classNames(
                classes.textField,
                classes.dropdownTopMargin
              )}
              label="State"
              margin="dense"
              type="text"
              required={false}
              inputProps={{ maxLength: 2 }}
              name="officeState"
              value={values.officeState.toUpperCase()}
              error={haveErrors(showOfficeStateError)}//only show error when we have values in it.due to length issue we have to apply validation here and validation.js does not support is empty check
              onChange={event => {
                handleFieldChange(
                  'officeState',
                  event.target.value.toUpperCase() ? event.target.value.toUpperCase() : null,
                  event
                );
              }}
            />
            {showOfficeStateError && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.officeState[0]}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={1} />
        <Grid item lg={2}>
          <div className={classes.field}>
            <TextField
              className={classNames(
                classes.textField,
                classes.dropdownTopMargin
              )}
              label="Office Zip Code"
              margin="dense"
              type="text"
              required={false}
              name="officeZipcode"
              value={values.officeZipcode}
              error={haveErrors(showOfficeZipcodeError)}
              inputProps={{ maxLength: 10 }}
              onChange={event => {
                handleFieldChange('officeZipcode', event.target.value, event);
              }}
            />
            {showOfficeZipcodeError && (
              <Typography className={classes.fieldError} variant="body2">
                {errors.officeZipcode[0]}
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container className={classNames(classes.grid, classes.spaceAround)}>
        <Grid item lg={12}>
          <Typography className={classes.title} variant="subtitle2">
            Upload Pictures (Optional)
          </Typography>
          <Divider />
        </Grid>
        <Grid item lg={4}>
          <div className={classes.imageHolderWrapper}>
            {values.agentPhoto ? (
              <div className={classes.imageHolder}>
                <img
                  src={values.agentPhoto}
                  alt={"Agent Photo"} className={classes.imageTag}
                  onClick={event => openMediaViewDialog(values.agentPhoto, 'Agent Photo')}
                />
              </div>
            ) : ''}
            <div className={classes.fieldSection}>
              <Zoom in={true} {...{ timeout: 300 }}>
                <div className={classes.fieldSectionContainer}>
                  <Button
                    onClick={event => openImageUplaodDialog('profile', values.agentPhoto)}
                    variant="contained"
                    component="span"
                    className={classNames(
                      classes.uploadButton,
                      classes.marginTop2Unit
                    )}
                  >
                    Upload Agent Photo
                </Button>
                  <Typography variant="body2">
                    Upload your photo or team photo (Optional)
                  </Typography>
                </div>
              </Zoom>
            </div>
          </div>
        </Grid>
        <Grid item lg={4}>
          <div className={classes.imageHolderWrapper}>
            {values.agentLogo ? (
              <div className={classes.imageHolder}>
                <img
                  src={values.agentLogo}
                  alt={"Agent Logo"}
                  className={classes.imageTag}
                  onClick={event => openMediaViewDialog(values.agentLogo, 'Agent Logo')}
                />
              </div>
            ) : ''}
            <div className={classes.fieldSection}>
              <Zoom in={true} {...{ timeout: 300 }}>
                <div className={classes.fieldSectionContainer}>
                  <Button
                    onClick={event => openImageUplaodDialog('logo', values.agentLogo)}
                    variant="contained"
                    component="span"
                    className={classNames(
                      classes.uploadButton,
                      classes.marginTop2Unit
                    )}
                  >
                    Upload Agent Logo
                </Button>
                <Typography variant="body2">
                    Optional
                  </Typography>
                </div>
              </Zoom>
            </div>
          </div>
        </Grid>
        {/* <Grid item lg={4}>
          <div className={classes.imageHolderWrapper}>
            {values.agentLogo2 ? (
              <div className={classes.imageHolder}>
                <img
                  src={values.agentLogo2}
                  alt={"Agent Logo 2"}
                  className={classes.imageTag}
                  onClick={event => openMediaViewDialog(values.agentLogo2, 'Agent Logo 2')}
                />
              </div>
            ) : ''}
            <div className={classes.fieldSection}>
              <Zoom in={true} {...{ timeout: 300 }}>
                <div className={classes.fieldSectionContainer}>
                  <Button
                    onClick={event => openImageUplaodDialog('logo2', values.agentLogo2)}
                    variant="contained"
                    component="span"
                    className={classNames(
                      classes.uploadButton,
                      classes.marginTop2Unit
                    )}
                  >
                    Upload Agent Logo 2
                </Button>
                </div>
              </Zoom>
            </div>
          </div>
        </Grid> */}
        <Grid item lg={4}>
          {/*           
          <div className={classes.imageHolderWrapper}>
            {values.agentSignature ? (
              <div className={classes.imageHolder}>
                {
                  regexWithPDF.test(values.agentSignature.toLowerCase()) ? (
                    <IconButton
                      color="primary"
                      title="Click to view PDF"
                      onClick={event => openPDFInNewTab(values.agentSignature)}
                    >
                      <PictureAsPdfIcon fontSize="large" className={classes.pdfIcon} />
                    </IconButton>
                  ) : (
                      <img
                        src={values.agentSignature}
                        alt={"Agent Signature"}
                        className={classes.imageTag}
                        onClick={event => openMediaViewDialog(values.agentSignature, 'Agent Signature')}
                      />
                    )
                }
              </div>
            ) : ''}
            <div className={classes.fieldSection}>
              <Zoom in={true} {...{ timeout: 300 }}>
                <div className={classes.fieldSectionContainer}>
                  <Button
                    onClick={event => openImageUplaodDialog('signature', values.agentSignature)}
                    variant="contained"
                    component="span"
                    className={classNames(
                      classes.uploadButton,
                      classes.marginTop2Unit
                    )}
                  >
                    Upload Agent Signature
                </Button>
                  <Typography variant="body2">
                    If you are a member of a TEAM: Each team member will be
                    asked to upload their own signatures
                  </Typography>
                </div>
              </Zoom>
            </div>
          </div>
         */}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles)
)(Step3);