import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import CategoryShadowBox from "./category-shadow-box.png";
import Shadow15 from "./WhiteClearBox_wShadow_15perct.png";
import Shadow25 from "./WhiteClearBox_wShadow_25perct.png";
import Shadow35 from "./WhiteClearBox_wShadow_35perct.png";

class QVThreeTopCategory extends Component {
  formatData = data => {
    const {
      roundSalePriceTenths,
      highestSalesRange,
      roundSalePrice
    } = propertyUtil;

    // console.log("data is", data);
    const { category, higherSpacedStyles, highestSalePrice } = this.props;

    if (category === "Hottest") {
      if (data.length === 1) {
        return (
          <div
            className={`top-category-data-sale-range-one ${higherSpacedStyles} first-line`}
          >
            {roundSalePriceTenths(data[0].PriceRangeLow, false, false)}
            {highestSalesRange(
              roundSalePriceTenths(data[0].PriceRangeHigh, false, false),
              highestSalePrice
            )}
          </div>
        );
      } else if (data.length > 1) {
        return (
          <div className="top-category-data-sale-range-two">
            <p className={higherSpacedStyles}>
              {roundSalePriceTenths(data[0].PriceRangeLow, false, false)}
              {highestSalesRange(
                roundSalePriceTenths(data[0].PriceRangeHigh, false, false)
              )}
            </p>
            <p className={higherSpacedStyles}>
              {roundSalePriceTenths(data[1].PriceRangeLow, false, false)}
              {highestSalesRange(
                roundSalePriceTenths(data[1].PriceRangeHigh, false, false),
                highestSalePrice
              )}
            </p>
          </div>
        );
      }
    } else if (category === "Fastest") {
      return (
        <div className="top-category-data">
          <p className={`${higherSpacedStyles} first-line`}>
            {data === "0" ? 1 : data}{" "}
            {data === "0" || data === "1" ? "Day" : "Days"}
          </p>
        </div>
      );
    } else {
      return (
        <div className="top-category-data">
          <p className={`${higherSpacedStyles} first-line`}>
            {roundSalePrice(data, false, false, false)}
          </p>
          {/*<div className="top-category-description">
            <p className="regular-spaced-styles second-reg-line v-centered">
              {highestPriceAddress}
            </p>
          </div>*/}
        </div>
      );
    }
  };

  formatDescription = description => {
    const {
      category,
      higherSpacedStyles,
      highestPriceAddress,
      highestSalePrice,
      homesSoldInRange
    } = this.props;
    const { roundSalePriceTenths, highestSalesRange } = propertyUtil;
    if (category === "Fastest") {
      if (description.length === 1) {
        // console.log("PriceRangeLow", description[0].PriceRangeLow);
        // console.log("PriceRangeHigh", description[0].PriceRangeHigh);
        return (
          <div className="top-category-description">
            <p
              className={`regular-spaced-styles second-reg-line v-centered lowercase`}
            >
              {roundSalePriceTenths(description[0].PriceRangeLow, false, true)}
              {highestSalesRange(
                roundSalePriceTenths(
                  description[0].PriceRangeHigh,
                  false,
                  true
                ),
                highestSalePrice
              )}
            </p>
            <div className="bottom-description">
              <p>This Price Range Sold</p>
              <p>in the Least Number of Days</p>
            </div>
          </div>
        );
      } else {
        return (
          <div className="top-category-description two-descriptions">
            <div className={`${higherSpacedStyles} second-reg-line neg-mt-3`}>
              <p>
                {roundSalePriceTenths(
                  description[0].PriceRangeLow,
                  false,
                  true
                )}
                {highestSalesRange(
                  roundSalePriceTenths(
                    description[0].PriceRangeHigh,
                    false,
                    true
                  )
                )}
              </p>
              <p>
                {roundSalePriceTenths(
                  description[1].PriceRangeLow,
                  false,
                  true
                )}
                {highestSalesRange(
                  roundSalePriceTenths(
                    description[1].PriceRangeHigh,
                    false,
                    true
                  )
                )}
              </p>
            </div>
            <div className="bottom-description">
              <p>These 2 Price Ranges Sold</p>
              <p>in the Least Number of Days</p>
            </div>
          </div>
        );
      }
    } else if (category === "Highest") {
      // console.log("highest sale price", highestPriceAddress);
      let temporaryAddress = highestPriceAddress.split(" ");
      let formattedAddress;

      if (temporaryAddress[0] == "0") {
        formattedAddress = temporaryAddress.slice(1);
      } else {
        formattedAddress = temporaryAddress;
      }
      return (
        <div className="top-category-description">
          <div className="regular-spaced-styles second-reg-line v-centered highest-price-address">
            {formattedAddress.join(" ").toLowerCase()}
          </div>

          <div className="bottom-description">
            <p>This Property Sold at</p>
            <p>the Highest Sale Price</p>
          </div>
        </div>
      );
    } else if (category === "Hottest") {
      if (description === 1) {
        return (
          <div className="top-category-description">
            <p className={`regular-spaced-styles second-reg-line v-centered`}>
              {homesSoldInRange} {homesSoldInRange === 1 ? "Home" : "Homes"}{" "}
              Sold
            </p>

            <div className={`${higherSpacedStyles} second-reg-line`} />
            <div className="bottom-description">
              <p>This Price Range</p>
              <p>Sold the Most Homes</p>
            </div>
          </div>
        );
      } else {
        return (
          <div className="top-category-description bottom-description">
            <p>These 2 Price Ranges</p>
            <p>Sold the Most Homes</p>
          </div>
        );
      }
    }
  };
  render() {
    const {
      category,
      data,
      description,
      higherSpacedStyles,
      index
    } = this.props;
    // console.log("this.props", this.props);

    return (
      <div className="top-category">
        <div className="top-category-title">{category}</div>
        <div className="top-category-img-body">
          <div className="top-category-divider" />
          <img
            src={Shadow15}
            className="category-shadow-box-img"
            alt="category shadow box"
          />
          <div className="top-category-body">
            {this.formatData(data)}
            {this.formatDescription(description)}
          </div>
        </div>
      </div>
    );
  }
}

export default QVThreeTopCategory;
