import React, { Component } from 'react';
import classNames from 'classnames';
import { Portlet, PortletContent } from 'components';
import { Grid, Typography, CircularProgress, Divider } from '@material-ui/core';
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import { ResetAllLocalStorages, SetSemiAuthInStorage, SetSemiAuthExpireTimreInStorage, IsValidSemiAuth, IsValidLoginAuth, ResetDataExceptSessionSwitchLocalStorages, SetLPROIAuthInStorage } from 'common/storage';
import { RegisterAgentValidate } from 'services/agent';
import querystring from 'querystring';
import { handleLogoutFn } from 'common/helpers';

//Url 1: For Register screen
//http://localhost:3000/validateAgentToken?registerToken=ef6e6cba-e0d7-4378-87c4-21051bf33562&emailAddress=akchoudhary.nat.966@gmail.com

//Url 2: For roi landing page
//http://localhost:3000/validateAgentToken?roiToken=ef6e6cba-e0d7-4378-87c4-21051bf33562%20&emailAddress=akchoudhary966@gmail.com&listgenUserFirstName=Amit%20&listgenUserLastName=Kumar%20&brokerageName=Coldwell%20Banker%20Realty%20&countyID=44%20&countyName=Santa%20Cruz%20&listgenUserID=12345

class TokenValidatorScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            secureToken: null,
            emailAddress: null,
            isTokenExpired: true,
            validationMesaage: 'Validating Token...',
            showLoading: true,
            validationMode: null//r means for register screen,sl means for sales_landing screen
        };
    }

    componentWillMount = () => {
        try {
            let parsedParams = querystring.parse(
                this.props.location.search.substring(1)
            );
            let mode = 'r';
            let token = '';
            if (parsedParams.registerToken !== undefined) {
                //mode would be register 
                mode = 'r';
                token = parsedParams.registerToken;
            } else if (parsedParams.roiToken !== undefined) {
                //mode would be roi landing page
                mode = 'roi'
                token = parsedParams.roiToken;
            } else if (parsedParams.obToken !== undefined) {
                //mode would be sales landing page
                mode = 'ob'//onboarding
                token = parsedParams.obToken;
            } else {
                throw 'Invalid validation type!';
            }
            //set the state here
            this.setState({
                secureToken: token,
                emailAddress: parsedParams.emailAddress,
                validationMode: mode
            });
        } catch (err) {
            this.setState({
                secureToken: null,
                emailAddress: null,
                validationMode: null
            });
            /**Redirect to login with invalid token status */
            this.redirectToLogin();
        }
    };

    componentDidMount = () => {
        const { validationMode } = this.state;
        if (validationMode === 'r') {
            /**check for full auth first. If someone if already logged in show invalid page. Only open register screen if thers no session */
            let isValidLogin = IsValidLoginAuth();
            if (isValidLogin) {
                this.setState({
                    showLoading: false,
                    validationMesaage: "Sorry, you don't have access to this page."
                });
            } else {
                this.validateRegisterToken();
            }
        } else if (validationMode === 'roi') {
            //for landing page we dont have any session restriction
            this.validateROIToken();
        } else if (validationMode === 'ob') {
            //for landing page we dont have any session restriction
            this.validateOBToken();
        }
    };

    redirectToLogin = () => {
        ResetAllLocalStorages();
        ResetDataExceptSessionSwitchLocalStorages();
        const { history } = this.props;
        history.push('/login?mode=2');
    };

    validateROIToken = () => {
        try {
            RegisterAgentValidate(this.state.secureToken)
                .then(data => {
                    if (data === true || data === 'true') {
                        //reset all data first
                        handleLogoutFn(0, false);
                        SetLPROIAuthInStorage(this.state.secureToken, this.state.emailAddress);
                        const { history } = this.props;
                        this.setState({
                            showLoading: false,
                            validationMesaage: "Token validated. Redirecting to area selection page..."
                        }, () => {
                            setTimeout(() => {
                                history.push(`${'/lp/open/agent/areas-by-city?'}${this.props.location.search.substring(1)}`);
                            }, 1000);
                        });
                    } else {
                        this.redirectToLogin()
                    }
                });
        } catch (err) {
            this.redirectToLogin();
        }
    };

    validateOBToken = () => {
        const { history } = this.props;
        this.setState({
            showLoading: true,
            validationMesaage: "Please wait..."
        }, () => {
            //set some cookies also to check
            setTimeout(() => {
                history.push(`${'/lp/schedule/presentation?obToken=ef6e6cba-e0d7-4378-87c4-21051bf33562&emailAddress=akchoudhary966@gmail.com&name=Amit Kumar&brokerage=Coldwell Banker Realty&location=Holiday Inn&presentationCity=Aptos&presentaionState=California&listgenUserID=12345&presentationID=12weee&scheduleID=343545'}`);
            }, 1000);
        });
    };

    validateRegisterToken = () => {
        try {
            RegisterAgentValidate(this.state.secureToken)
                .then(data => {
                    switch (data) {
                        case true:
                        case 'true':
                            // this.setState({//making no sense
                            //     isTokenExpired: false,
                            // });
                            /**Set LOcal storage data now */
                            SetSemiAuthInStorage(this.state.secureToken, this.state.emailAddress);
                            SetSemiAuthExpireTimreInStorage(null);
                            /**redirect to home page now*/
                            const { history } = this.props;
                            this.setState({
                                showLoading: false,
                                validationMesaage: "Token validated. Redirecting to register page..."
                            }, () => {
                                setTimeout(() => {
                                    history.push('/agent/register');
                                }, 1000);
                            });
                            break;
                        case false:
                        case 'false':
                            this.redirectToLogin();
                            break;
                        default:
                            this.redirectToLogin();
                            break;
                    }
                })
                .catch(err => {
                    this.redirectToLogin();
                });
        } catch (err) {
            this.redirectToLogin();
        }
    };


    render() {
        const { classes, className } = this.props;
        const rootClassName = classNames(className);


        return (
            <div className={rootClassName}>
                <Grid className={classes.grid} container>
                    <Grid item lg={3} sm={12} xs={12} md={3}></Grid>
                    <Grid item lg={6} sm={12} xs={12} md={6}>
                        <Portlet>
                            <PortletContent className={classes.displayFlex}>
                                <Grid className={classes.grid} container>
                                    <Grid item lg={12} sm={12} xs={12} md={12}>
                                        {this.state.showLoading ?
                                            <CircularProgress />
                                            : ''}
                                    </Grid>
                                    <Grid item lg={12} sm={12} xs={12} md={12}>
                                        <Typography variant="subtitle2" className={classes.displayFlexText}>
                                            {this.state.validationMesaage}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </PortletContent>
                        </Portlet>
                    </Grid>
                    <Grid item lg={3} sm={12} xs={12} md={3}></Grid>
                </Grid>
            </div>
        );
    };
}

export default compose(
    withStyles(styles)
)(TokenValidatorScreen);

