import { red, green } from "@material-ui/core/colors";

export default theme => ({
  root: {
    width: '100%',
  },
  portletFooter: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  fieldError: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  detailandTableDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  listItemText: {
    '&>div>span': {
      fontSize: '16px',
      fontWeight: 500
    },
    '&>div>p': {
      marginTop: theme.spacing(1),
      fontSize: '14px',
      fontWeight: 400,
      color: 'inherit'
    },
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '48px',
    display: 'flex',
    justifyContent: 'center',
  },
  skeletonWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  noCardWrapper: {
    paddingTop: '24px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  removeAgentButton: {
    backgroundColor: red[800],
    color: 'white',
    '&:hover': {
      backgroundColor: red[600],
    }
  },
  boldText: {
    fontWeight: 500
  },
  extraBoldText: {
    fontWeight: 800
  },
  successMessageContainer: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    color: 'white',
    border: `1px solid ${theme.palette.success.main}`,
    borderRadius: '4px',
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  clearDefaultCardMessageButton: {
    padding: theme.spacing(0.5),
    color: 'white',
    // '&:hover': {
    //   backgroundColor: red[900],
    //   //border: '1px solid white'
    // }
  }
});
