import React, { Component, useEffect, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles, CircularProgress, Typography, List, ListItem, ListItemText, Grid, Divider, Button, IconButton } from '@material-ui/core';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

import styles from './styles';
import { withSnackbar } from 'notistack';
import { compose } from 'recompose';
import { CreditCardTableComponent } from '..';
import { GetAgentCreditCardListAPI } from 'services/agent';
import { useState } from 'react';
import { AddCreditCardForAnAgentAPI } from 'services/agent';
import { showErrorNotification } from 'common/helpers';
import { RemoveAgentFromStripeWithCreditCardAPI } from 'services/agent';
import { DeleteAgentCreditCardAPI } from 'services/agent';
import { SetDefaultCreditCardForAnAgentAPI } from 'services/agent';
import { AddNewCreditCardDialogComponent } from '..';
import { CreditCardConfirmationDialogComponent } from '..';
import { ChargeCreditCardDialogComponent } from '..';
import { showSuccessNotification } from 'common/helpers';
import CommonConfirmationDialog from 'components/CommonConfirmationDialog';
import ClearIcon from '@material-ui/icons/Clear';
import { Skeleton } from '@material-ui/lab';
import { systemLogRequest } from 'common/helpers';

const CreditCardList = (props) => {

  const { classes, className, enableCreditDialogFn, isAdmin, listgenUserID, listgenAgentFirstName, listgenAgentLastName, listgenAgentEmailAddress, enableCCConfirmationDialogFn,
    closeCCConfirmationDialogFn, enableCCChargeDialogFn, closeCCChargeDialogFn, chargeCurrency, ...rest } = props;
  const rootClassName = classNames(classes.root, className);

  /**state data variables */
  const [cardList, setCardList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompLoading, setIsCompLoading] = useState(false);
  const [isTopCompLoading, setIsTopCompLoading] = useState(false);
  const [agentHaveCard, setAgentHaveCard] = useState(false);
  const [openCCAdditionDialog, setOpenCCAdditionDialog] = useState(false);
  const [openCreditCardConfirmationDialog, setCreditCardConfirmationDialog] = useState(false);
  const [openChargeCreditCardDialog, setOpenChargeCreditCardDialog] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState(null);
  const [confirmationSubtitle, setConfirmationSubtitle] = useState(null);
  const [confirmationMethodType, setConfirmationMethodType] = useState(null);
  const [tempCardID, setTempCardID] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const [defaultCardMessage, setDefaultCardMessage] = useState('');
  const [newCard, setNewCardData] = useState({
    nameOnCard: null,
    customerID: null,
    emailID: null,
    cardNumber: null,
    expiryMonth: null,
    expiryYear: null,
    cvc: null,
    isDefault: false
  });
  const [chargeData, setChargeData] = useState({
    customerID: null,
    cardID: null,
    amount: null,
    currency: 'USD'
  });

  useEffect(() => {
    /**call the get agent credit cards API */
    if (listgenUserID) {
      getAgentCreditCards();
    }
  }, [listgenUserID]);

  const openCCAddDialog = () => {
    setOpenCCAdditionDialog(true);
  };

  const closeCCAddDialog = () => {
    setOpenCCAdditionDialog(false);
  };

  const openConfirmationDialog = (title, subtitle, cardID, confirmationType) => {
    setConfirmationTitle(title);
    setConfirmationSubtitle(subtitle);
    setTempCardID(cardID);
    setConfirmationMethodType(confirmationType);
    setCreditCardConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    setConfirmationTitle(null);
    setConfirmationSubtitle(null);
    setTempCardID(null);
    setCreditCardConfirmationDialog(false);
    setConfirmationMethodType(null);
  };

  const enableCCChargeDialog = (cardID) => {
    setTempCardID(cardID);
    setOpenChargeCreditCardDialog(true);
  };

  const closeCCChargeDialog = () => {
    setTempCardID(null);
    setOpenChargeCreditCardDialog(false);
  };

  const getCustomerID = (cardData) => {
    let firstCardData = cardData.find((item, key) => key == 0);
    let customerID = firstCardData.customerID ? firstCardData.customerID : null;
    /**now set the customer id */
    setCustomerID(customerID);
  };

  const sortByDefaultCardList = (cardData) => {
    let defaultCard = [];
    let noDefaultCards = [];
    cardData.forEach((card, key) => {
      if (card.isDefault) {
        defaultCard.push(card)
      } else {
        noDefaultCards.push(card);
      }
    });
    return defaultCard.concat(noDefaultCards);
  };

  const getAgentCreditCards = () => {
    /**make loading true */
    setIsLoading(true);
    try {
      GetAgentCreditCardListAPI(listgenUserID)
        .then(data => {
          if (data.length > 0) {
            //first get the customer id
            /**add the credit card now */
            getCustomerID(data);
            //now sort the data by isdefault
            setCardList(sortByDefaultCardList(data));
            setIsLoading(false);
            /**check for agent have card or now */
            setAgentHaveCard(true);
          } else {
            //assign empty data
            setCardList([]);
            setIsLoading(false);
            setAgentHaveCard(false);
          }
        })
        .catch(err => {
          //showErrorNotification(this.props, "Unable to process request.");
          //assign empty data
          setCardList([]);
          setIsLoading(false);
          setAgentHaveCard(false)
        });
    } catch (err) {
      //showErrorNotification(this.props, "Unable to process request.");
      //something went wrong
      setCardList([]);
      setIsLoading(false);
      setAgentHaveCard(false)
    }
  };

  const removeAgentFromStripeWithCreditCard = (tempID) => {
    /**make loading true */
    setIsTopCompLoading(true);
    try {
      RemoveAgentFromStripeWithCreditCardAPI(listgenUserID, customerID)
        .then(data => {
          if (data) {
            showSuccessNotification(props, "Agent Removed.");
            setIsTopCompLoading(false);
            getAgentCreditCards();
            //agent removed now we have to ask
          } else {
            //assign empty data
            setIsTopCompLoading(false);
            systemLogRequest('credit_card', 110, {}, data);
            showErrorNotification(props, "Unable to process request. Error Code: 110");
          }
        })
        .catch(err => {
          systemLogRequest('credit_card', 111, err);
          showErrorNotification(props, "Unable to process request. Error Code: 111");
          setIsTopCompLoading(false);
        });
    } catch (err) {
      systemLogRequest('credit_card', 112, err);
      showErrorNotification(props, "Unable to process request. Error Code: 112");
      setIsTopCompLoading(false);
    }
  };

  const deleteAgentCreditCard = (cardID) => {
    /**make loading true */
    setIsCompLoading(true);
    try {
      DeleteAgentCreditCardAPI(listgenUserID, customerID, cardID)
        .then(data => {
          if (data) {
            setIsCompLoading(false);
            getAgentCreditCards();
            //agent removed now we have to ask
          } else {
            //assign empty data
            setIsCompLoading(false);
            systemLogRequest('credit_card', 113, {}, data);
            showErrorNotification(props, "Unable to process request. Error Code: 113");
          }
        })
        .catch(err => {
          systemLogRequest('credit_card', 114, err);
          showErrorNotification(props, "Unable to process request. Error Code: 114");
          setIsCompLoading(false);
        });
    } catch (err) {
      systemLogRequest('credit_card', 115, err);
      showErrorNotification(props, "Unable to process request. Error Code: 115");
      setIsCompLoading(false);
    }
  };

  const setDefaultCreditCardForAnAgent = (cardID, customerID) => {
    /**make loading true */
    setIsCompLoading(true);
    try {
      SetDefaultCreditCardForAnAgentAPI(listgenUserID, cardID, customerID)
        .then(data => {
          if (data) {
            setIsCompLoading(false);
            //get agent curretn default card from card list
            let currentDefaultData = cardList.find(cardData => cardData.cardID === cardID);
            setDefaultCardMessage(`Your default card has been changed to ${currentDefaultData.nameOnCard} (${currentDefaultData.brand.toUpperCase()}, ****${currentDefaultData.last4})`);
            //reload the table
            getAgentCreditCards();
          } else {
            //assign empty data
            setIsCompLoading(false);
            systemLogRequest('credit_card', 116, {}, data);
            showErrorNotification(props, "Unable to process request. Error Code: 116");
          }
        })
        .catch(err => {
          systemLogRequest('credit_card', 117, err);
          showErrorNotification(props, "Unable to process request. Error Code: 117");
          setIsCompLoading(false);
        });
    } catch (err) {
      systemLogRequest('credit_card', 118, err);
      showErrorNotification(props, "Unable to process request. Error Code: 118");
      setIsCompLoading(false);
    }
  };


  return (
    <Fragment>
      {openChargeCreditCardDialog ?
        <ChargeCreditCardDialogComponent
          chargeCurrency={chargeCurrency}
          listgenUserID={listgenUserID}
          customerID={customerID}
          chargeCardID={tempCardID}
          closeFn={closeCCChargeDialog}
        /> : ''}
      {openCreditCardConfirmationDialog ?
        <CommonConfirmationDialog
          title={confirmationTitle}
          subtitle={confirmationSubtitle}
          closeFn={closeConfirmationDialog}
          methodToCallOnConfirmation={confirmationMethodType === "remove_agent" ? removeAgentFromStripeWithCreditCard : deleteAgentCreditCard}
          confirmationID={tempCardID}
        />
        : ''}
      {openCCAdditionDialog ?
        <AddNewCreditCardDialogComponent
          listgenUserID={listgenUserID}
          listgenAgentFirstName={listgenAgentFirstName}
          listgenAgentLastName={listgenAgentLastName}
          listgenAgentEmailAddress={listgenAgentEmailAddress}
          customerID={customerID}
          agentHaveCard={agentHaveCard}
          closeCCDialogFn={closeCCAddDialog}
          getAgentCreditCards={getAgentCreditCards}
        />
        : ''}
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            title="Added Credit Cards"
          />
          {
            //dont show this if topcomp is loading
            !isTopCompLoading ?
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={openCCAddDialog}
                >
                  {!agentHaveCard ? 'Add Credit Card' : 'Add Another Credit Card'}
                </Button>
                {isAdmin && agentHaveCard ?
                  //only adminc an remove the agent from stripe
                  <Fragment>&nbsp;&nbsp;&nbsp;<Button
                    className={classes.removeAgentButton}
                    variant="contained"
                    color="default"
                    onClick={event => openConfirmationDialog("Are you sure to remove agent from stripe?", "It remove all cards for the agent from the stripe and ListGen also.", null, "remove_agent")}
                  >
                    Remove Agent From Stripe
                  </Button></Fragment> : ''}

              </div>
              : ''}
        </PortletHeader>
        <PortletContent noPadding>
          <Grid
            direction="row"
            container
            alignItems="center"
            justify="center"
          >
            <Grid
              item
              lg={3}
              md={3}
              sm={12}
              xs={12}
            >
              {!isLoading ?
                <List>
                  <ListItem className={classes.listItemText}>
                    <ListItemText primary="Agent First Name" secondary={listgenAgentFirstName} />
                  </ListItem>
                </List>
                :
                <div className={classes.skeletonWrapper}>
                  <Skeleton animation="wave" height={30} width="40%" />
                </div>}
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={12}
              xs={12}
            >
              {!isLoading ?
                <List>
                  <ListItem className={classes.listItemText}>
                    <ListItemText primary="Agent Last Name" secondary={listgenAgentLastName} />
                  </ListItem>
                </List>
                :
                <div className={classes.skeletonWrapper}>
                  <Skeleton animation="wave" height={30} width="40%" />
                </div>}
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={12}
              xs={12}
            >
              {!isLoading ?
                <List>
                  <ListItem className={classes.listItemText}>
                    <ListItemText primary="Listgen Account Number" secondary={listgenUserID} />
                  </ListItem>
                </List>
                :
                <div className={classes.skeletonWrapper}>
                  <Skeleton animation="wave" height={30} width="40%" />
                </div>}
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={12}
              xs={12}
            >
              {!isLoading ?
                <List>
                  <ListItem className={classes.listItemText}>
                    <ListItemText primary="Email ID" secondary={listgenAgentEmailAddress} />
                  </ListItem>
                </List>
                :
                <div className={classes.skeletonWrapper}>
                  <Skeleton animation="wave" height={30} width="40%" />
                </div>}
            </Grid>
          </Grid>
          <Grid
            container
          >
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Divider className={classes.detailandTableDivider} />
              {defaultCardMessage ?
                <div className={classes.successMessageContainer}>
                  {defaultCardMessage}
                  <IconButton
                    onClick={event => setDefaultCardMessage('')}
                    className={classes.clearDefaultCardMessageButton}
                  >
                    <ClearIcon />
                  </IconButton>
                </div>
                : ''}
            </Grid>
          </Grid>
          <Grid
            container
          >
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {isLoading ?
                // <div className={classes.progressWrapper}>
                //   <CircularProgress />
                // </div>
                <div className={classes.skeletonWrapper}>
                  <Skeleton animation="wave" height={120} width="90%" />
                  <Skeleton animation="wave" height={120} width="90%" />
                </div>
                :
                <Fragment>
                  {!agentHaveCard ?
                    <div className={classes.noCardWrapper}>
                      <Typography variant="subtitle2">Looks like {isAdmin ? 'agent' : 'you'} didn't add any card with us. Please add {isAdmin ? 'agent' : 'your'} first credit card by clicking on "<span className={classes.extraBoldText}>Add Credit Card</span>".</Typography></div>
                    :
                    <CreditCardTableComponent
                      isAdmin={isAdmin}
                      enableCCConfirmationDialogFn={openConfirmationDialog}
                      closeCCConfirmationDialogFn={closeConfirmationDialog}
                      enableCCChargeDialogFn={enableCCChargeDialogFn}
                      setDefaultCreditCardForAnAgent={setDefaultCreditCardForAnAgent}
                      enableCCChargeDialogFn={enableCCChargeDialog}
                      cardList={cardList}
                      isLoading={isCompLoading}
                    />}
                </Fragment>
              }
            </Grid>
          </Grid>
        </PortletContent>
      </Portlet>
    </Fragment>
  );
}

CreditCardList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles)
)(CreditCardList);
