import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Dashboard as DashboardLayout } from 'layouts';
import { AccountProfile, AccountDetails, AccountUpdatePassword } from './components';
import { AccountUpdateEmail } from './components';
import compose from "recompose/compose";
import { QuickLinksPortlet } from './components';
import DocumentTitle from 'react-document-title';

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    minHeight: '80vh',
    //width: '100%',
    maxWidth: '100%',
  },
  container: {
    marginTop: theme.spacing(2)
  }
});

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      userData: {
        firstName: 'Amit',
        lastName: 'Kumar',
        fullname: 'Amit Kumar',
        email: 'akchoudhary966@gmail.com',
        phoneNumber: '9109911163',
        role: 'Agent',
        status: 'active',
        progress: 100,
      }
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <DocumentTitle title="My Account">
        <DashboardLayout title="My Account">
          <div className={classes.root}>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                lg={5}
                md={6}
                xl={4}
                xs={12}
              >
                <AccountProfile userData={this.state.userData} />
                <QuickLinksPortlet />
              </Grid>
              <Grid
                item
                lg={7}
                md={6}
                xl={8}
                xs={12}
              >
                {/* <AccountDetails userData={this.state.userData} /> */}
                <AccountUpdateEmail />
                <AccountUpdatePassword userData={this.state.userData} />
              </Grid>

            </Grid>
          </div>
        </DashboardLayout>
      </DocumentTitle>
    );
  }
}

Account.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(Account);
