import React, { Component } from "react";
import White40Percent from "../BubbleCompareSecondPage/WhiteBox_2_wShadow_40perct.png";
import White40Percent0 from "../BubbleCompareSecondPage/WhiteBox_wShadow_7inX1-5625in_112123_80perct.png";
import Gray20Percent from "../BubbleCompareSecondPage/GreyBox_wShadow_20perct.png";
import { Link } from "react-router-dom";
import BubbleBoxModal from "./BubbleBoxModal";
import { pmaActions } from "_store/_actions";
import { connect } from "react-redux";
import { propertyUtil } from "views/Pma/PropertyUtil";

class BackpageBoxes extends Component {
  state = {
    editQuadrantFormData: null,
    oldQuadrantData: [],
    selectedBubbleNumber: null,
    titleEdit: false,
    footerEdit: false,
    maxMainTitleLength: 50,
    maxMainSubtitleLength: 30,
    maxBoldedLineLength: 77,
    maxFooter1Length: 77,
    maxFooter2Length: 15,
    addAreasModalOpen: false,
    filteredAreas: {},

    savingMessageShowing: false,
  };

  handleFormClose = () => {
    this.props.updateBubbleModalOpen(false);

    this.setState({
      titleEdit: false,
      footerEdit: false,
      addAreasModalOpen: false,
      savingMessageShowing: false,
    });
  };
  onEditFormSubmit = () => {
    const { editQuadrantFormData, oldQuadrantData } = this.state;

    let foundIndex = oldQuadrantData.findIndex(
      (x) => x.mktValAreaID == editQuadrantFormData.mktValAreaID
    );
    let clonedEditQuadrantFormData = Object.assign(editQuadrantFormData, {
      mktValBox1SubText:
        "Simply scan the QR code on page 3 or go to: OurProperty.info/%HOQRCODE%",
    });

    console.log("editQuadrantFormData", editQuadrantFormData);

    oldQuadrantData[foundIndex] = clonedEditQuadrantFormData;
    this.props.setBubbleDetails(oldQuadrantData);
    this.setState({
      savingMessageShowing: true,
    });
    console.log("editQuadrantFormData", editQuadrantFormData);
    setTimeout(() => {
      this.setState({ savingMessageShowing: false });
      this.props.updateBubbleModalOpen(false);
    }, 500);
  };
  openSelectedBubble = (bubbleNumber, titleEdit, footerEdit) => {
    // this.props.updateBubbleModalOpen(true);
    // this.setState({
    //   selectedBubbleNumber: bubbleNumber,
    //   titleEdit: titleEdit,
    //   footerEdit: footerEdit,
    //   savingMessageShowing: false,
    // });
  };

  componentDidMount = () => {
    const { bubbleBoxes, currentArea } = this.props;
    const { mlsAreaID } = currentArea;
    //console.log("didMount", bubbleBoxes);
    let newObj = [...bubbleBoxes]
      .filter((bubbleObj) => bubbleObj.mktValAreaID === currentArea.mlsAreaID)
      .map((i) => ({ ...i }))[0];

    this.setState({
      oldQuadrantData: [...bubbleBoxes].map((i) => ({ ...i })),
      editQuadrantFormData: [...bubbleBoxes]
        .filter(
          (bubbleObj) =>
            bubbleObj.mktValAreaID === currentArea.mlsAreaID ||
            currentArea.mlsAreaID.includes(bubbleObj.mktValAreaID)
        )
        .map((i) => ({ ...i }))[0],
    });
  };
  // const {
  //   quadAreaID,
  //   mktValAreaTitle,
  //   mktValSubTitle,
  //   quad1Title,
  //   quad1Text,
  //   quad2Title,
  //   quad2Text,
  //   quad3Title,
  //   quad3Text,
  //   quad4Title,
  //   quad4Text,
  // } = this.state.editQuadrantFormData;
  // };
  // handleRestoreDefault = () => {
  //   const {
  //     selectedBubbleNumber,
  //     editQuadrantFormData,
  //     oldQuadrantData,
  //     titleEdit,
  //   } = this.state;
  //   let past = oldQuadrantData[selectedBubbleNumber];
  //   if (titleEdit) {
  //     this.setState({});
  //   } else if (selectedBubbleNumber >= 0) {
  //     let newObj = [...editQuadrantFormData].map((i) => ({ ...i }));
  //     let oldSelection = [...oldQuadrantData].map((i) => ({
  //       ...i,
  //     }))[selectedBubbleNumber];
  //     newObj[selectedBubbleNumber].text = oldSelection.text;
  //     this.setState({
  //       editQuadrantFormData: newObj,
  //     });
  //   }
  // };
  handleInputChange = (e) => {
    const {
      editQuadrantFormData,
      oldQuadrantData,
      titleEdit,
      footerEdit,
    } = this.state;
    const target = e.target;
    const value = target.value;
    const name = target.name;
    let clonedEditQuadrantFormData = Object.assign(editQuadrantFormData, {
      [name]: value,
    });
    this.setState({
      editQuadrantFormData: clonedEditQuadrantFormData,
    });
  };

  formatAreaTitle = (area) => {
    if (area.length > 10) {
      return "Neighborhood";
    } else {
      return area;
    }
  };
  formatAreaBody = (area) => {
    if (area.length > 10) {
      return "neighborhood";
    } else {
      return area;
    }
  };
  render() {
    const {
      openSelectedBubble,
      handleInputChange,
      handleRestoreDefault,
      applyToAllAreas,
    } = this;
    const {
      editQuadrantFormData,
      oldQuadrantData,
      selectedBubbleNumber,
      titleEdit,
      footerEdit,
      addAreasModalOpen,
      maxMainTitleLength,
      maxFooter1Length,
      maxFooter2Length,
      maxMainSubtitleLength,
      maxBoldedLineLength,
      savingMessageShowing,
    } = this.state;

    const {
      currentArea,
      agentData,
      bubbleBoxes,
      printVersion,
      quadrantModalOpen,
      mlsAreas,
      hoData,
    } = this.props;
    let { homeOwnerURLID, homeOwnerURL } = hoData;
    const { listgenUserID } = agentData;

    const { mlsNeighborhood } = currentArea;
    const { mobilePhoneNo } = agentData;
    const { formatAreaTitle, formatAreaBody } = this;
    const { agentSignoffForGlobalNote } = propertyUtil;
    let backpageSignoff = agentSignoffForGlobalNote(agentData, null, false);

    let showButtons = false;
    if (editQuadrantFormData && Object.keys(editQuadrantFormData).length >= 1) {
      let {
        footerLine1,
        footerLine2,

        mktValAreaID,
        mktValAreaTitle,
        mktValBox1Text,
        mktValBox1Title,
        mktValBox1SubText,
        mktValBox2Text,
        mktValBox2Title,
        mktValBox2SubText,
        mktValBox3Text,
        mktValBox3Title,
        mktValSubTitle,
        mktValBox3SubText,
      } = editQuadrantFormData;

      // mktValBox1Text = "";
      // mktValBox1Title = "";
      // mktValBox2Text = "";
      // mktValBox2Title = "";
      // mktValBox3Text = "";
      // mktValBox3Title = "";
      // mktValSubTitle = "";

      return (
        <>
          {editQuadrantFormData &&
            Object.keys(editQuadrantFormData).length >= 1 &&
            quadrantModalOpen && (
              <BubbleBoxModal
                oldQuadrantData={oldQuadrantData}
                onCloseFn={this.handleFormClose}
                handleInputChange={handleInputChange}
                editQuadrantFormData={editQuadrantFormData}
                onEditFormSubmit={this.onEditFormSubmit}
                selectedBubbleNumber={selectedBubbleNumber}
                handleRestoreDefault={handleRestoreDefault}
                titleEdit={titleEdit}
                footerEdit={footerEdit}
                mlsAreas={mlsAreas}
                maxMainTitleLength={maxMainTitleLength}
                maxFooter1Length={maxFooter1Length}
                maxFooter2Length={maxFooter2Length}
                maxMainSubtitleLength={maxMainSubtitleLength}
                maxBoldedLineLength={maxBoldedLineLength}
                savingMessageShowing={savingMessageShowing}
              />
            )}
          {/*className={`${
              !footerLine1 && !footerLine2
                ? "extended-img"
                : "backpage-boxes-img"
            }`}*/}

          <div className="backpage-boxes-img">
            <img src="https://pma-coverphotos.s3.amazonaws.com/nov-2023-backpage-img.jpg" />
          </div>

          <div className="backpage-boxes4">
            {listgenUserID === "x100210" ? (
              <div className="custom-backpage-boxes-container">
                <div className="backpage-boxes-title custom-backpage-boxes-title">
                  <p>Experts</p>
                  <p>Weigh In:</p>
                </div>
                <div className="backpage-boxes-subtitle custom-backpage-boxes-subtitle">
                  <p>Continued Strength</p>
                  <p>for Sellers</p>
                </div>
              </div>
            ) : (
              <div className="backpage-boxes-title-container">
                <div
                  className="backpage-boxes-title"
                  dangerouslySetInnerHTML={{ __html: mktValAreaTitle }}
                ></div>
                {/*<div
                  className="backpage-boxes-subtitle"
                  dangerouslySetInnerHTML={{ __html: mktValSubTitle }}
                ></div>*/}
              </div>
            )}

            <div className="backpage-boxes-body">
              {!printVersion && showButtons && (
                <>
                  <div
                    className="bubble-btn bubble-title-btn"
                    onClick={() => openSelectedBubble(null, true)}
                  >
                    <div>Edit Title & Subtitle</div>
                  </div>
                </>
              )}

              <div
                className={`${
                  1 == selectedBubbleNumber && "selected-quadrant-section"
                } backpage-boxes-box-section section-1`}
                onClick={() => openSelectedBubble(1)}
                key={1}
              >
                {/*<div className="backpage-boxes-section-number">1</div>*/}
                {!printVersion && showButtons && (
                  <div
                    className="bubble-btn bubble-btn-1"
                    onClick={() => openSelectedBubble(1)}
                  >
                    <div>Edit Section #1</div>
                  </div>
                )}
                <img
                  className="backpage-box-box-img"
                  src={White40Percent}
                  alt="Transparent dashboard container"
                />

                <div className="backpage-boxes-box">
                  <div className="backpage-boxes-box-title-container">
                    <p
                      dangerouslySetInnerHTML={{ __html: mktValBox1Title }}
                    ></p>
                  </div>
                  <div className="backpage-boxes-info-container">
                    {listgenUserID === "100411" ? (
                      <div className="backpage-boxes-info-col">
                        <p
                          dangerouslySetInnerHTML={{ __html: mktValBox1Text }}
                        ></p>
                        <p className="backpage-boxes-bold">
                          Simply scan the QR code above or go to:
                          <a href="https://hmbt.co/DcY6Gq" target="_blank">
                            <span className="backpage-boxes-qr-code">
                              https://hmbt.co/DcY6Gq
                            </span>
                          </a>
                        </p>
                      </div>
                    ) : (
                      <div className="backpage-boxes-info-col">
                        <p
                          dangerouslySetInnerHTML={{ __html: mktValBox1Text }}
                        ></p>
                        <p className="backpage-boxes-bold">
                          Simply scan the QR code on page 3 or go to:
                          <a href={homeOwnerURL} target="_blank">
                            <span className="backpage-boxes-qr-code">
                              OurProperty.info/{homeOwnerURLID}
                            </span>
                          </a>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`${
                  2 == selectedBubbleNumber && "selected-quadrant-section"
                } backpage-boxes-box-section section-2`}
                onClick={() => openSelectedBubble(2)}
                key={2}
              >
                {/*<div className="backpage-boxes-section-number">2</div>*/}
                {!printVersion && showButtons && (
                  <div
                    className="bubble-btn bubble-btn-2"
                    onClick={() => openSelectedBubble(2)}
                  >
                    <div>Edit Section #2</div>
                  </div>
                )}
                <img
                  className="backpage-box-box-img"
                  src={White40Percent}
                  alt="Transparent dashboard container"
                />
                <div className="backpage-boxes-box">
                  <div className="backpage-boxes-box-title-container">
                    <p
                      dangerouslySetInnerHTML={{ __html: mktValBox2Title }}
                    ></p>
                  </div>
                  <div className="backpage-boxes-info-container">
                    <div className="backpage-boxes-info-col">
                      <p
                        dangerouslySetInnerHTML={{ __html: mktValBox2Text }}
                      ></p>

                      <p className="backpage-boxes-bold">{mktValBox2SubText}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  3 == selectedBubbleNumber && "selected-quadrant-section"
                } backpage-boxes-box-section section-3`}
                onClick={() => openSelectedBubble(3)}
                key={3}
              >
                {/*<div className="backpage-boxes-section-number">3</div>*/}
                {!printVersion && showButtons && (
                  <div
                    className="bubble-btn bubble-btn-3"
                    onClick={() => openSelectedBubble(3)}
                  >
                    <div>Edit Section #3</div>
                  </div>
                )}
                <img
                  className="backpage-box-box-img"
                  src={White40Percent}
                  alt="Transparent dashboard container"
                />
                <div className="backpage-boxes-box">
                  <div className="backpage-boxes-box-title-container">
                    <p
                      dangerouslySetInnerHTML={{ __html: mktValBox3Title }}
                    ></p>
                  </div>
                  <div className="backpage-boxes-info-container">
                    <div className="backpage-boxes-info-col">
                      <p
                        dangerouslySetInnerHTML={{ __html: mktValBox3Text }}
                      ></p>
                      <p className="backpage-boxes-bold">{mktValBox3SubText}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {footerLine1 && (
              <div className="backpage-boxes-footer-container">
                {!printVersion && showButtons && (
                  <>
                    <div
                      className="bubble-btn bubble-footer-btn"
                      onClick={() => openSelectedBubble(null, false, true)}
                    >
                      <div>Edit Footer</div>
                    </div>
                  </>
                )}

                <div
                  className="backpage-boxes-footer-line-1"
                  dangerouslySetInnerHTML={{ __html: footerLine1 }}
                ></div>

                {/*<div
                  className="backpage-boxes-footer-line-2"
                  dangerouslySetInnerHTML={{ __html: footerLine2 }}
                ></div>*/}
              </div>
            )}
          </div>
        </>
      );
    } else {
      return <div>Please contact us to upload information</div>;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    quadrantModalOpen: state.pma.quadrantModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateBubbleModalOpen: (isModalOpen) => {
      dispatch(pmaActions.updateQuadrantModalOpen(isModalOpen));
    },
    setBubbleDetails: (bubbleDetails) => {
      dispatch(pmaActions.setBubbleDetails(bubbleDetails));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BackpageBoxes);
