import React, { Component } from "react";
import QVFourCoverPage from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourCoverPage";

class YETwoFirstRight extends Component {
  render() {
    const {
      agentData,
      coverPageDetails,
      currentArea,
      hoData,
      aprilData,
      printVersion,
      demoAccountCoverPhoto,
      demoAccountAreaName,
      quarterlyAreaTable,
      version,
      typeOfVersion,
      isDemo,
    } = this.props;

    return (
      <div className="ye2-first-right">
        <QVFourCoverPage
          agentData={agentData}
          currentArea={currentArea}
          coverPageDetails={coverPageDetails}
          hoData={hoData}
          printVersion={printVersion}
          demoAccountCoverPhoto={demoAccountCoverPhoto}
          demoAccountAreaName={demoAccountAreaName}
          quarterlyAreaTable={quarterlyAreaTable}
          currentSoldListings={quarterlyAreaTable.currentSoldListings}
          marketHighLightsData={quarterlyAreaTable.marketHighLightsData}
          photoNum="1"
          version={version}
          only6Months={true}
          typeOfVersion="ye2"
          aprilData={aprilData}
          yearEnd={true}
          //printDate="January 20"
          isDemo={isDemo}
        />
      </div>
    );
  }
}

export default YETwoFirstRight;
