import palette from '../../theme/palette';
import { grey } from '@material-ui/core/colors';

export default theme => ({
  root: {

  },
  appBar: {
    position: 'relative',
    background: '#00225a'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: palette.common.white
  },
  colorWhite: {
    color: theme.palette.common.white
  },
  progressWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100vh'
  },
  messageBox: {
    textAlign: 'center',
    margin: theme.spacing(4)
  },
  listItemText: {
    height: '80px',
    '&>div>span': {
      fontSize: '16px',
      fontWeight: 500
    },
    '&>div>p': {
      marginTop: theme.spacing(1),
      fontSize: '14px',
      fontWeight: 400,
      color: 'inherit'
    },
  },
  portletMainClass: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: '100%'
  },
  noteBlock: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  noteBlockText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  noteBlockSubtext: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(2),
    color: grey['400'],
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  greyColor: {
    color: grey['500']
  },
  portletFooter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noNoteFound: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    width: '100%'
  },
  marginTop3Unit: {
    marginTop: theme.spacing(3)
  },
  notesFixHeight: {},
  adminNotesContainer: {
    maxHeight: '33vh',
    overflowY: 'scroll',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  fieldError: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  fieldSuccess: {
    color: theme.palette.success.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  }
});
