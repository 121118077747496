import React from "react";
import { connect } from "react-redux";
import styles from "./styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
// import HomeValuationDialog from "./HomeValuationDialog";
import moment from "moment";
import HomeValuationsRatingHandler from "./HomeValuationsRatingHandler";
import { green } from "@material-ui/core/colors";
import { isAVeryGoodListing } from "./HomeValuationUtils";
import palette from "theme/palette";

const MailProValuationSummary = ({ classes, property: xProperty, propertyData, ...rest }) => {
    const [property, setProperty] = React.useState({ ...xProperty })
    const {
        isRequestedForValuation,
        valuationRequestedDateTime,
        isHomeValuationGivenByAgent,
        likelyhoodOfListing,
        approxDateOfListing,
        homeValuationGivenDate,
        agentCommentsOnHO,
        homeOwnerURLID
    } = property;


    const quickUpdatePropertyData = (partialHomeValuationData) => {
        setProperty({ ...property, ...partialHomeValuationData });
    }

    const openinNewTab = () => {
        const newWindow = window.open(`https://api.listgen.com/homeOwnerValuation/${homeOwnerURLID}`, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const determineStyle = () => {
        const style = {}
        const isAVeryGoodListinng = isAVeryGoodListing(isHomeValuationGivenByAgent, likelyhoodOfListing);
        if (isAVeryGoodListinng) {//4/5/
            style.backgroundColor = green[500]
            style.color = 'white'
        } else if (likelyhoodOfListing == 0) {//0
            style.backgroundColor = 'transparent'
            style.border = `1px solid ${palette.primary.dark}`
            style.borderRadius = '4px'
        } else if (likelyhoodOfListing == 1) {//1
            style.backgroundColor = green[50]
        } else if (likelyhoodOfListing > 0) {//1,2,3//isHomeValuationGivenByAgent && 
            style.backgroundColor = green[100]
        } else {//yellow -1
            style.backgroundColor = '#FFE699'
        }
        return { ...style };
    }


    return (
        <div className="global-ps-body-container tableTdFlex">
            <div className="tableTdFlex-First textCursor">
                {isRequestedForValuation && <div className={classes.homeValuationsMain} style={determineStyle()}>
                    <div style={{ wordBreak: 'break-word' }}>{isHomeValuationGivenByAgent ? 'I HAVE PROVIDED A HOME VALUATION' : 'A HOME VALUATION HAS BEEN REQUESTED'}</div>
                    {/* <div>HOME VALUATION</div> */}
                    {valuationRequestedDateTime && <div>
                        {moment(isHomeValuationGivenByAgent ? homeValuationGivenDate : valuationRequestedDateTime).format(
                            "MMM DD, YYYY"
                        )}
                    </div>}
                    <br />
                    <div>
                        <div className={classes.homeValuationsViewDetailsButton} onClick={() => openinNewTab()}>
                            View Details
                        </div>
                    </div>
                    <div>
                        <HomeValuationsRatingHandler
                            property={property}
                            {...rest}
                            likelyhoodOfListing={likelyhoodOfListing}
                            quickUpdatePropertyData={quickUpdatePropertyData}
                        />
                    </div>
                </div>}
            </div>
            <div className="tableTdFlex-Second"></div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        propertyData: state.pma.propertyData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles), withSnackbar)(MailProValuationSummary));
