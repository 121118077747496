import React, { Component } from 'react';
import { propertyUtil } from 'views/Pma/PropertyUtil';

class YECoverPageData extends Component {
  calculateDataPoint = point => {
    const { roundSalePrice, infinityCheck } = propertyUtil;
    const { category } = this.props;
    switch (category) {
      case 'Sale Price':
      case 'Highest Sale Price':
        return `${roundSalePrice(point).toLowerCase()}`;
      case '% Received of Asking':
        return `${Math.round(point)}%`;
      case 'Days to Sell':
        return `${infinityCheck(Number(point).toLocaleString())}`;
      case 'Homes Sold':
        return `${Number(point).toLocaleString()}`;
      default:
        return `${infinityCheck(point)}`;
    }
  };
  determineTriangle = perc => {
    if (perc === 'none') return '';
    perc = +perc;
    if (perc > 0) {
      return <div className="data-section-top-row-triangle up" />;
    } else if (perc < 0) {
      return <div className="data-section-top-row-triangle down" />;
    } else {
      return <div className="data-section-top-row-balanced">(No Change)</div>;
    }
  };
  formatCategory = category => {
    if (category === 'Sale Price') {
      return 'Average Sale Price';
    } else if (category === '% Received of Asking') {
      return 'Average % Received of Asking';
    } else if (category === 'Days to Sell') {
      return 'Average Days to Sell';
    } else if (category === 'Homes Sold') {
      return 'Total Unit Sales';
    } else if (category === 'Highest Sale Price') {
      return 'Highest Sale Price';
    }
  };
  render() {
    const { category, dataPoint, perc } = this.props;
    const { calculateDataPoint, determineTriangle } = this;
    const { roundSalePrice, plusOrMinus, infinityCheck } = propertyUtil;
    let isValidPerc;
    if (perc && perc != 0 && perc !== 'none') {
      isValidPerc = true;
    } else {
      isValidPerc = false;
    }
    return (
      <div className="cover-page-body-data-section">
        <div className="data-section-bottom-row-category">
          {this.formatCategory(category)}
        </div>
        <div className="data-section-top-row">
          {category === 'Sale Price' || category === 'Highest Sale Price' ? (
            <p className="data-section-data-point inline">
              <span className="small-dollar-sign">$</span>
              {`${roundSalePrice(dataPoint, true).toLowerCase()}`}
            </p>
          ) : (
            <p className="data-section-data-point">
              {calculateDataPoint(dataPoint)}
            </p>
          )}
          {determineTriangle(perc)}
          {isValidPerc && (
            <div className="data-section-top-row-perc-diff">
              {infinityCheck(Math.abs(perc))}%
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default YECoverPageData;
