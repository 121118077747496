import React, { Component, Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Divider, Input, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Slide } from '@material-ui/core';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { compose } from 'recompose';
import styles from "../../../styles";
import { withSnackbar } from 'notistack';
import classNames from 'classnames';
import ClearIcon from "@material-ui/icons/Clear";
import { showSuccessNotification } from 'common/helpers';
import { showErrorNotification } from 'common/helpers';
import { AddUpdatePmaAdmin } from 'services/agent';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddMissionsComponent = (props) => {
  const { mode, oldData, completeData, classes, listgenUserID, localUpdateFn, handleAddOrUpdateMission, onCloseFn, isAdminAccess, userData } = props;

  //Min variables
  const minCharacterLength = 200;
  const maxCharacterLength = 610;
  const [updateID, setUpdateID] = React.useState(null);
  const [newMissions, setNewMissions] = React.useState("");
  const [firstorTeamName, setFirstorTeamName] = React.useState("");
  const [missionHeader, setMissionHeader] = React.useState(null);
  const [showError, setShowError] = React.useState("");
  /**loading variables */
  const [isLoading, setIsLoading] = useState(false);

  const getUserDataVariable = () => {
    let { isMemberOfTeam, teamName, listgenUserFirstName } = userData ? userData : {};
    if (isMemberOfTeam) {
      return teamName ? teamName : '';
    } else {
      return listgenUserFirstName ? listgenUserFirstName : '';
    }
  };

  useEffect(() => {
    if (mode === 'update') {
      //setUpdateID();
      setNewMissions(oldData ? oldData.missionStatementContent : '');
      setFirstorTeamName(oldData ? oldData.namePreferred : '');
      setMissionHeader(oldData ? oldData.missionStatementHeader : null);
    } else {
      //set the firs tor team name
      setFirstorTeamName(getUserDataVariable())
    }
  }, []);

  useEffect(() => {
    setShowError("");
  }, [newMissions, firstorTeamName]);

  const checkErros = () => {
    let errorString = [];
    if (!newMissions || (newMissions && newMissions.length < minCharacterLength)) {
      //add first error
      errorString.push(`Mission Statement is incomplete. It should have atleast ${minCharacterLength} characters.`);
    }
    if (newMissions && newMissions.length > maxCharacterLength) {
      //add first error
      errorString.push(`Mission Statement length is exceeded by ${newMissions.length - maxCharacterLength} characters. Please shorten the mission statement.`);
    }
    if (!firstorTeamName) {
      //add space if theres any previous error
      errorString.push('Agent first / Team name is required.');
    }
    //check if errors is there add errros and return false
    if (errorString.length > 0) {
      setShowError(errorString.map(i => <Fragment>{i} <br /></Fragment>));
      return false;
    } else {
      //no erro return true
      return true;
    }

  };

  const AddUpdateAMissionData = (mode) => {
    //first check fro error
    if (checkErros()) {
      /**get the charge data */
      /**make loading true */
      setIsLoading(true);

      try {
        let data = {};
        if (mode === 'add') {
          data = {
            listgenUserID,
            namePreferred: firstorTeamName,
            missionStatementContent: newMissions,
            missionStatementHeader: missionHeader,
            missionStatementSortOrder: (completeData.length + 1),
            isCurrentlyActive: true,//completeData.length === 0 ? true : false,//mark first mission as highlighted
          };
        } else {
          data = Object.assign(oldData, {
            namePreferred: firstorTeamName,
            missionStatementContent: newMissions,
            missionStatementHeader: missionHeader,
          });
        }
        //check for the verification status
        if (isAdminAccess) {
          data["missionStatementVerificationStatus"] = "verified";
        }

        handleAddOrUpdateMission(mode, data, oldData, (isSuccess) => {
          setIsLoading(false);
          if (isSuccess) {
            onCloseFn();
          } else {
            //handle error
          }
        });
      } catch (err) {
        showErrorNotification(props, "Unable to process request.");
        setIsLoading(false);
      }
    }
  };

  return (
    <Fragment>
      <Dialog
        open={true}
        onClose={onCloseFn}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
        TransitionComponent={Transition}
      >
        <DialogTitle id="cc-alert-dialog-title">
          <div className={classNames(classes.width100, classes.displyFlex, classes.rowFlex, classes.spaceBWJustify)}>
            <div></div>
            <div>
              <Typography variant="h4" align="center" className={classes.uppercase} color="primary">
                {mode === 'add' ? 'You are creating a new mission statement' : 'You are editing an existing mission statement'}
              </Typography>
              <Typography variant="body1" align="center">
                {/* Please add as many Mission Statements as you like.  */}
                Your Mission Statement will print on the back cover page of your PMAs.
                {/* <br />
                The system will rotate your Mission Statements so a new one is printed each time. Only the quarterly versions of your PMAs will print your Mission Statements. */}
              </Typography>
            </div>
            <div onClick={onCloseFn} className={classNames(classes.displyFlex, classes.rowFlex, classes.flexEndJustify, classes.flexStartAlign, classes.closeModalBtn)}>
              <Button variant="contained" size="small" className={classNames(classes.closeredButton, classes.whiteColor, classes.textCenterAlign, classes.red900)}>
                <ClearIcon className={classes.whiteColor} />
              </Button>
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers={true}>
          <p className={classNames(classes.requiredRed, classes.bold500Font, classes.px12, classes.requirePLeft)}>* Required Field</p>
          <div className={classNames(classes.widthAuto, classes.displyFlex, classes.namesDiv)} style={{ width: '40%' }}>
            <TextField
              id="outlined-mission-header"
              label="Enter Misson Header for Mission Statement"
              variant="outlined"
              helperText={"Mission Header, Max character: 60"}
              margin="dense"
              value={missionHeader}
              onChange={event => setMissionHeader(event.target.value)}
              inputProps={{
                maxLength: 60,
                className: classNames(classes.lightBlueBG, classes.hoverBgWhite),
              }}
              error={showError}
              fullWidth={true}
            />
          </div>
          <div className={classNames(classes.testimonialTextboxDiv, classes.displyFlex)}>
            <div className={classes.textboxLeftTM}>
              <TextField
                className={classNames(classes.lightBlueBG, classes.hoverBgLightBlue)}//(!newMissions ? classNames(classes.lightBlueBG, classes.hoverBgWhite) : '')}
                key={"missionss-main-box-"}
                label={<span className={classes.bold500Font}>&#8220;Type your Mission Statement here&#8221;</span>}
                multiline
                rows={5}
                variant="outlined"
                fullWidth={true}
                InputProps={{ classes: { root: classNames(classes.textAreaMultiline, classes.hoverBgLightBlue) } }}
                inputProps={{
                  //maxLength: 370,
                  //className: classes.hoverBgLightBlue
                }}
                required={true}
                value={newMissions}
                onChange={event => setNewMissions(event.target.value)}
                error={showError && (!newMissions || (newMissions && newMissions.length < minCharacterLength) || (newMissions && newMissions.length > maxCharacterLength))}
              />
              <div className={classNames(classes.width100, classes.displyFlex, classes.centerAlign, classes.spaceBWJustify)}>
                <div className={classNames(classes.px12)}>(Minimum Character Count: {minCharacterLength})</div>
                <div className={classNames(classes.px12, (newMissions.length > maxCharacterLength ? classes.paletteRed : ''))}>(Character Count: {newMissions.length}/{maxCharacterLength})</div>
              </div>
            </div>
          </div>
          <div className={classNames(classes.widthAuto, classes.displyFlex, classes.namesDiv)} style={{ width: '40%' }}>
            <TextField
              required
              id="outlined-first-name"
              label="Enter Your First Name(s) or Team Name Here"
              variant="outlined"
              helperText={"First name or Team name or Initials, Max character: 60"}
              margin="dense"
              value={firstorTeamName}
              onChange={event => setFirstorTeamName(event.target.value)}
              inputProps={{
                maxLength: 60,
                className: classNames(classes.lightBlueBG, classes.hoverBgWhite),
              }}
              error={showError && !firstorTeamName}
              fullWidth={true}
            />
          </div>
          <div className={classNames(classes.errorDiv)}>
            {showError ?
              <Typography variant="body1" align="center" className={classNames(classes.paletteRed)}>{showError}</Typography>
              : ''}
          </div>
        </DialogContent>
        {isLoading ?
          <div className={classNames(classes.progressWrapper, classes.displyFlex, classes.centerJustify)} style={{ height: '42px' }}>
            <CircularProgress />
          </div>
          :
          // <DialogActions>
          <Fragment>
            <div className={classNames(classes.portletFooter)}>
              <div className={classes.footerButtons}>
                <div className={classes.footerButtonsLeftRight}>
                  <Button
                    variant="outlined"
                    onClick={event => onCloseFn()}
                    color="default"
                  >
                    Cancel
                  </Button>
                </div>
                <div className={classes.footerButtonsCenter}>
                  <Button
                    variant="contained"
                    onClick={event => AddUpdateAMissionData(mode)}
                    color="primary"
                  >
                    {!isAdminAccess ? 'Submit' : 'Verify and Close'}
                  </Button>
                </div>
                <div className={classNames(classes.footerButtonsLeftRight, classes.displyFlex, classes.flexEndJustify)}></div>
              </div>
            </div>
          </Fragment>
          // </DialogActions>
        }
      </Dialog>
    </Fragment>
  );
}

AddMissionsComponent.propTypes = {
  classes: PropTypes.object.isRequired
};
export default compose(
  withSnackbar,
  withStyles(styles)
)(AddMissionsComponent);
