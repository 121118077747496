import React from "react";
import PmaEditor from "./PMAEditor";
import axios from "common/interceptors";

class PrintBubbleTable extends React.Component {
  state = {
    singleHOData: null,
    agentData: null,
    mlsAreas: null,
    marketActivity: null,
    MQCityDetails: null,
    currentArea: null,
    localSellingAgents: null,
    version: "mq2",
    coverPageDetails: null,
    quarterlyAreaTable: null,
    testimonials: null,
    agentDesignations: null,
    agentAdvantages: null,
    salePriceForCities: null,
    yearEnd: null,
    qArea: null,
    qCity: null,
    missionStatement: null,
    aprilData: null,
    flipBook: false,
  };

  async componentDidMount() {
    let mailProId = this.props.match.params.mailproid;
    let listgenUserID = this.props.match.params.listgenUserID;
    let brokerage = this.props.match.params.brokerage;
    const { version } = this.state;
    let url = window.location.href;
    if (url.includes("test-print")) {
      listgenUserID = url[url.length - 2];
    }
    let date = new Date();
    let thisYear = date.getFullYear();
    const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
    const today = `${date.getDate()}`.padStart(2, 0);
    try {
      let mailPrint = await axios
        .get(
          `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
          {
            headers: {
              "content-type": "application/json",
            },
            data: {},
          }
        )
        .then((res) => {
          const singleHOData = res.data.mailerProDTO;
          //const agentData = res.data.listgenUserDTO;
          this.setState({
            singleHOData,
            //agentData,
          });
        })
        .catch((err) => console.log("error retrieving idDetails" + err));
    } catch (error) {}
    let navBar = await axios
      .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
        headers: {
          "content-type": "application/json",
        },
        data: {},
      })
      .then((res) => {
        this.setState({
          mlsAreas: res.data,
        });
      })
      .catch((err) => {});
    const thisArea = this.state.singleHOData.areaID;
    if (version === "mq2") {
      const currentArea = this.state.mlsAreas.filter(
        (area) => area.mlsAreaID === thisArea
      )[0];
      const { mlsAreaID, mlsCityID } = currentArea;
      const { singleHOData } = this.state;
      const { subdivisionName } = singleHOData;
      let coverPageDetails1 = await axios
        .get(
          `/agent/getTableDataWithBubbleDataPMA?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
            subdivisionName ? subdivisionName : "-"
          }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
          {
            headers: {
              "content-type": "application/json",
            },
            data: {},
          }
        )
        .then((res) => {
          const { mailerPrintInfoDTO } = res.data;
          const singleHOData = mailerPrintInfoDTO.mailerProDTO;
          let agentData = mailerPrintInfoDTO.listgenUserDTO;
          if (
            listgenUserID === "103" ||
            listgenUserID === "105" ||
            listgenUserID === "100037"
          ) {
            agentData.brokerageName = brokerage;
          }
          this.setState({
            version: "mq2",
            testimonials: res.data.lgTestmonialsDTO,
            coverPageDetails: res.data.pmaCoverPhotoDTO,
            quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
            quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
            currentArea,
            singleHOData,
            agentData,
          });
        })
        .catch((err) => {});
    }
  }
  render() {
    const { propertyData } = this.props;
    const {
      singleHOData,
      agentData,
      mlsAreas,
      marketActivity,
      MQCityDetails,
      currentArea,
      version,
      quarterlyCityTable,
      quarterlyAreaTable,
      localSellingAgents,
      coverPageDetails,
      testimonials,
      agentDesignations,
      agentAdvantages,
      salePriceForCities,
      yearEnd,
      qArea,
      qCity,
      aprilData,
      flipBook,
      missionStatement,
      propertyPhotoDetailsDTO,
      pmaQuarterlyCombinedCityDTO,
      quadrantDetails,
    } = this.state;
    if (
      version === "mq2" &&
      currentArea &&
      singleHOData &&
      agentData &&
      quarterlyAreaTable &&
      quarterlyCityTable &&
      coverPageDetails
    ) {
      return (
        <div className="pma-creator">
          <main>
            <section>
              <PmaEditor
                propertyData={propertyData}
                printVersion={true}
                version={version}
                singleHOData={singleHOData}
                agentData={agentData}
                currentArea={currentArea}
                marketActivity={marketActivity}
                MQCityDetails={MQCityDetails}
                mlsAreas={mlsAreas}
                quarterlyAreaTable={quarterlyAreaTable}
                quarterlyCityTable={quarterlyCityTable}
                localSellingAgents={localSellingAgents}
                coverPageDetails={coverPageDetails}
                printTestimonials={testimonials}
                printAgentAdvantages={agentAdvantages}
                printAgentDesignations={agentDesignations}
                salePriceForCities={salePriceForCities}
                yearEnd={yearEnd}
                qArea={qArea}
                qCity={qCity}
                aprilData={aprilData}
                flipBook={flipBook}
                dontShowPma={false}
                missionStatement={missionStatement}
                propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
                pmaQuarterlyCombinedCityDTO={pmaQuarterlyCombinedCityDTO}
                quadrantDetails={quadrantDetails}
              />
            </section>
          </main>
        </div>
      );
    } else {
      return <div>Loading PMA...</div>;
    }
  }
}

export default PrintBubbleTable;
