export default theme => ({
  root: {
    height: 'auto',
    //padding: theme.spacing.unit * 3,
    marginTop: theme.spacing(8),
    backgroundColor: theme.palette.background.default,
  },
  grid: {
    height: '100%',
  },

  App: {
    textAlign: 'center',
  },

  AppLogo: {
    animation: 'App-logo-spin infinite 20s linear',
    height: '40vmin',
    pointerEvents: 'none',
  },

  AppHeader: {
    backgroundColor: '#012159',
    minHeight: '10vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'calc(10px + 2vmin)',
    color: 'white',
    width: '100%',
  },

  topBox: {
    background: '#012159',
  },
  section: {
    paddingTop: '100px',
    margin: 'auto',
    textAlign: 'center',
  },
  clear: {
    clear: 'both',
  },
  bottomBox: {
    //paddingBottom: "150px"
  },
  font75px: {
    fontSize: '75px',
  },
  font85px: {
    fontSize: '85px',
  },
  margin4UnitBottom: {
    marginBottom: theme.spacing(4),
  },
  whiteColor: {
    color: theme.palette.common.white,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  plusColColor: {
    color: '#0177fd',
  },
  orgColor: {
    color: '#012159',
  },
  fontWeigth500: {
    fontWeight: 500,
  },
  cardHolder: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'grid',
    },
  },
  card: {
    width: '380px',
    height: '230px',
    borderRadius: '0px',
    boxShadow: 'none',
    color: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      width: '45vh',
    },
  },
  freeCol: {
    backgroundColor: '#3792fd',
    padding: theme.spacing(2),
    color: theme.palette.common.white,
  },
  plusCol: {
    backgroundColor: '#fff',
    color: '#0177fd',
    padding: theme.spacing(2),
  },
  inlinePriceBox: {
    display: 'flex-grid',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    '&>span': {
      verticalAlign: 'bottom',
      fontSize: '80px',
    },
  },
  displayInline: {
    display: 'inline',
  },
  eachFeature: {
    color: '#fff',
    margin: '20px auto',
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    whiteSpace: 'pre',
  },
  eachFeatureBlock: {
    display: 'block',
  },
  eachFeatureIcon: {
    marginLeft: '20%',
    marginRight: '30px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '1vh',
      marginRight: '1vh',
    },
  },
  eachFeaturemTitle: {
    textAlign: 'left',
    fontSize: '12px',
    lineHeight: '26px',
    color: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  eachFeaturemDescription: {
    textAlign: 'left',
    fontSize: '18px',
    lineHeight: '28px',
    color: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  eachQandA: {
    textAlign: 'left',
    padding: theme.spacing(3),
  },
  afTitle: {
    color: theme.palette.common.white,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    '&>span': {
      fontWeight: 'normal',
    },
  },
  icon: {
    color: '#fff',
    fontSize: '60px',
  },
});
