export default theme => ({
  root: {},
  field: {
    //padding: theme.spacing.unit * 3
    margin: theme.spacing(3),
  },
  textField: {
    width: '100%',
    maxWidth: '100%',
    //marginRight: theme.spacing(3
  },
  dropdownTopMargin: {
    marginTop: -theme.spacing(5),
  },
  dropdownTopMargin2Unit: {
    marginTop: -theme.spacing(2),
  },
  marginTop2Unit: {
    marginTop: theme.spacing(2),
  },
  dropdownTopMargin1UnitPlus: {
    marginTop: theme.spacing(1),
  },
  marginBottom5Units: {
    marginBottom: theme.spacing(5),
  },
  fieldError: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  hideField: {
    display: 'none',
  },
  imageHolderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  imageHolder: {
    maxWidth: '200px',
    //maxHeight: '500px',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    height: '200px',
    objectFit: 'cover'
  },
  imageHolderDialog: {
    maxWidth: '200px',
    //maxHeight: '500px',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    height: 'auto',
    objectFit: 'cover'
  },
  imageTag: {
    width: '100%',
    height: '200px',
    cursor: 'pointer'
  },
  fieldSection: {
    width: '100%',
    height: '85px'
  },
  fieldSectionContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  fieldSectionError: {
    width: '100%'
  },
  uploadButton: {
    backgroundColor: theme.palette.primary.mediumLight,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    margin: 'auto',
    top: '25vh',
    left: '26vw',
  },
  addMoreButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(2),
    padding: 0,
    marginTop: -theme.spacing(3),
    marginBottom: -theme.spacing(3),
  },
  footerButtons: {
    position: 'relative',
    display: 'flex',
  },
  portletFooter: {
    display: 'inline-block',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  footerButtonsCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerButtonsLeftRight: {
    flex: 1,
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  blueText: {
    color: 'blue',
    display: 'inline',
  },
  spaceAround: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  alignItemCenter: {
    alignSelf: 'center',
  },
  pdfIcon: {
    width: '100%',
    height: '100%',
  },
  websiteAdornment: {
    marginRight: 0,
    marginTop: -theme.spacing(0.5),
    '&>p': {
      fontWeight: 600
    }
  }
});
