import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import moment from "moment";

class MarketSaleRow extends Component {
  getMonthAndYearString = (inputTime) => {
    try {
      return moment(inputTime).format("MMM D, YYYY");
    } catch (err) {
      return "";
    }
  };

  calculatePricePerSqft(calculation, saleP, sqft) {
    if (calculation === "-") {
      let res = Math.round(saleP / sqft);
      return res.toLocaleString();
    } else {
      return Math.round(calculation).toLocaleString();
    }
  }
  render() {
    const {
      formatSuffix,
      formatBaths,

      propTypeAcronym,
      shortenStatus,
    } = propertyUtil;

    const { solds, mlsAreaID } = this.props;
    let isCountryClubGate = mlsAreaID === "132" ? true : false;
    // console.log("solds", solds);
    const listMap =
      solds &&
      solds.map((list, i) => (
        <tr
          key={`${list.address} ${list.listPrice} ${list.saleDate} ${i}`}
          className={list.status.toLowerCase()}
        >
          <td
            // className={`status-text`}
            className={`status-text ${list.status.toLowerCase()}`}
            align="left"
          >
            {shortenStatus(list.status)}
          </td>
          <td>
            {list.saleDate === "-" ? (
              ""
            ) : (
              <p>{this.getMonthAndYearString(list.saleDate)}</p>
            )}
          </td>

          <td className="prop-type-text">
            {propTypeAcronym(list.propertyType)}
          </td>
          <td>
            {list.streetNumber === "-" || list.streetNumber === "0"
              ? null
              : list.streetNumber}{" "}
            {list.streetName}{" "}
            {list.streetSuffix && formatSuffix(list.streetSuffix, mlsAreaID)}
            {(!isCountryClubGate && list.propertyType === "Condominium") ||
            (!isCountryClubGate &&
              list.propertyType === "Townhouse" &&
              list.unitNumber !== "-")
              ? `, #${list.unitNumber}`
              : ""}
          </td>
          <td>${Number(list.listPrice).toLocaleString()}</td>

          <td>
            <p>
              {list.salePrice === "-"
                ? "—"
                : `$${Number(list.salePrice).toLocaleString()}`}
            </p>
          </td>

          <td>{list.daysOnMarket}</td>
          <td>{formatBaths(list.bedBaths)}</td>
          <td>{Math.round(list.squareFeet).toLocaleString()}</td>
        </tr>
      ));
    return <tbody>{listMap}</tbody>;
  }
}

export default MarketSaleRow;
