import React, { Fragment, useState, useEffect } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import styles from './styles';
import { pmaActions } from "../../../../_store/_actions";
import {
    CircularProgress,
    Typography,
    Button,
} from "@material-ui/core";
import { GetStateStorage } from "common/storage";
import { propertyUtil } from "views/Pma/PropertyUtil";
import { withStyles } from "@material-ui/styles";
import { compose } from "recompose";

const DeleteProperty = (props) => {

    const [isLoading, setLoading] = useState(false);
    const [isMailProBlocked, setBlocked] = useState(false);
    const { classes, isMailProLocked, blocked } = props;

    useEffect(() => {
        let isMailProBlocked = isMailProLocked;
        setBlocked(isMailProBlocked);
    }, [isMailProBlocked]);

    /**
     * It's a copy of blocked dialog onsubmit same method would gonna call in this also
     */
    const onSubmit = async (e) => {
        //e.preventDefault();
        setLoading(true);
        const { propertyData, mlsAreas, currentArea,  } = props;
        const {
            areaID,
            mailerProID,
            changeDetails,
            agentID,
            mailingStatus,
            imbarcode,
            isPropertyMapped,
            numericIMBarcode,
            parcelNumber
        } = props;

        try {
            // //combines the unblocked homeowners + ALL the agents, this will be the total number of pma's we are sending out
            // let totalUnblocked = agentList + unblockedHO;
            //Take the change details of this particular homeowner and it will assign the block status from state. "null" value means that the homeowner is NOT blocked. If the homeowner is blocked, they will not get a mailing
            let updatedChangeDetails = Object.assign({}, changeDetails, {
                blocked: (blocked === "Delete" ? null : "Delete")//put logic by checking current blocked status whether it's already deleted or not
            });
            /**get the user session data */
            let userData = GetStateStorage();
            let xPropertyData = propertyData.slice();
            let newPropertyData = xPropertyData.map(property => {
                if (property.mailerProID === mailerProID) {
                    property.changeDetails = updatedChangeDetails;
                }
                return property;
            });
            /**update the store now */
            const updatedPropertyStore = await props.updateCompleteMailerProDataAsync(
                newPropertyData,
                userData && userData.userData ? userData.userData : {}
            );
            
            let mlsAreasCopy = mlsAreas.slice();
            const index = mlsAreas
                .map(e => e.mlsAreaID)
                .indexOf(currentArea.mlsAreaID);
            /**NOW CALCULATE AGAIN BECAUSE DATA IN UPDATED */

            let actualHomeowners = propertyUtil.getActualHomeowners(propertyData);
            let agentList = propertyUtil.getActualAgents(propertyData).length;
            let unblockedHO = propertyUtil.getActualUnblockedHomeowners(
                actualHomeowners
            ).length;
            //combines the unblocked homeowners + ALL the agents, this will be the total number of pma's we are sending out
            let totalUnblocked = agentList + unblockedHO;
            //updated api so we just send the numOfPMASending and the price is calculated on the backend
            let updatedMLSProperty = Object.assign({}, mlsAreas[index], {
                numOfPMASending: totalUnblocked
            });
            //putting the updated current area back into the mlsAreas object so that we can send the edited mlsAreas object to the api
            mlsAreasCopy[index] = updatedMLSProperty;
            //console.log('mlsAreasCopy from BLOCKED', mlsAreasCopy);

            const hoData = {
                agentID,
                areaID,
                changeDetails: updatedChangeDetails,
                imbarcode,
                isPropertyMapped,
                mailerProID,
                mailingStatus,
                numericIMBarcode,
                parcelNumber,
                listgenUserID: userData.userID ? userData.userID : "",
                numOfPMAs: totalUnblocked
            };
            //api that takes the updated changeDetails object + the other un-nested variables
            const updateHO = await props.updateHomeownerProp(hoData);

            setTimeout(() => {
                props.updateRecentUpdatedMailProId(mailerProID);
            }, 10)
            setLoading(false);
        } catch {
            setLoading(false);
        }
    }

    return (
        //check whether is already deleted or not
        <div className={classes.wrapper}>
            <Button
                size="small"
                variant="outlined"
                color="primary"
                className={blocked === "Delete" ? classes.buttonSuccess : (blocked ? classes.buttonDangerBlocked : classes.buttonDanger)}
                disabled={isMailProBlocked || isLoading}//if mailpro is locked then make it disabled.
                onClick={event => onSubmit()}
            >
                {blocked === "Delete" ? 'Restore' : 'Delete Prop'}
        </Button>
            {isLoading && <CircularProgress color="primary" size={24} className={classes.buttonProgress} />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        propertyData: state.pma.propertyData,
        currentArea: state.pma.currentArea,
        mlsAreas: state.pma.mlsAreas
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHomeownerProp: hoData =>
            dispatch(pmaActions.updateHomeownerProp(hoData)),

        mailProHeaderSort: sorted => dispatch(pmaActions.mailProHeaderSort(sorted)),
        updateNavbar: mlsAreas => dispatch(pmaActions.updateNavbar(mlsAreas)),
        updateCompleteMailerProDataAsync: (propertyData, agentData) => {
            dispatch(pmaActions.updateCompleteMailerProDataAsync(propertyData, agentData));
        },
        updateRecentUpdatedMailProId: (mailProID) => dispatch(pmaActions.updateRecentUpdatedMailProId(mailProID))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(DeleteProperty));
