import React, { Fragment } from 'react';
import { IsValidSessionSwitch } from 'common/storage';
import { noteUtils } from '../Notes/Common/NoteUtils';
import { Button, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { NotesCameFrom } from '../Notes/Common/NotesConfig';
import { renderLogicUtil } from 'views/Pma/RenderLogicUtil';
import { MailProConstants } from '../../PmaMailingList/MailProConstants';


export const ApproveMailingListPendingActions = ({ children, hoFilter, propertyData, mode, classes, ...rest }) => {

    const handleNewMailingClickButtons = (mode) => {
        rest.handleClose();
        rest.explicitEnableNewMailProInitFn(true);
        mode !== MailProConstants.newAnniversary && rest.disableNewMailingForCurrentSessionFn(false);
        rest.setNewMailProInitDialogModeFn(mode);
    };

    const newMailingHOPropertiesCount = renderLogicUtil.countMailProInitProperties(
        propertyData,
        true,
        MailProConstants.newMailing,
    );

    const newlyConstructedHOPropertiesCount = renderLogicUtil.countMailProInitProperties(
        propertyData,
        true,
        MailProConstants.isNewProperty
    );
    const isSessionSwitch = IsValidSessionSwitch();
    const anyNewPropertyorHOPending = (newMailingHOPropertiesCount > 0 || newlyConstructedHOPropertiesCount > 0) && !isSessionSwitch;
    const anyHomeownerHavingPendingAnniversaryNoteCount = (noteUtils.getAllPropertiesPendingAnniversaryNoteswrtHOFilter(propertyData, NotesCameFrom.Anniversary, hoFilter) || []).length ;//0
    //disabling this
    const anyHomeownerHavingPendingAnniversaryNote = anyHomeownerHavingPendingAnniversaryNoteCount > 0 ;//false

    const getBody = () => {
        return anyHomeownerHavingPendingAnniversaryNote ?

            <Fragment>
                <Typography variant="subtitle2" align="center" className={classNames(classes.redColor, classes.boldFont)}>
                    Please Note:
                </Typography>
                <br />
                <Typography variant="subtitle2" align="center" className={classNames(classes.redColor, classes.boldFont)}>
                    You have {anyHomeownerHavingPendingAnniversaryNoteCount} NEW Homeowner{anyHomeownerHavingPendingAnniversaryNoteCount > 1 ? 's' : ''} that you can send a Happy Anniversary Note to.
                </Typography>
                <br />
                <Typography variant="subtitle2" align="center" className={classNames(classes.redColor, classes.boldFont)}>
                    Before you can approve this mailing, you must finish sending your {anyHomeownerHavingPendingAnniversaryNoteCount} Homeowner{anyHomeownerHavingPendingAnniversaryNoteCount > 1 ? 's' : ''} a Happy Anniversary Note.
                </Typography>
            </Fragment>

            : anyNewPropertyorHOPending ?
                <Fragment>
                    <Typography variant="subtitle2" align="center" className={classNames(classes.redColor, classes.boldFont)}>
                        Please Note:
                    </Typography>
                    <br />
                    {newMailingHOPropertiesCount > 0 ?
                        <Typography variant="subtitle2" align="center" className={classNames(classes.redColor, classes.boldFont)}>
                            You have {newMailingHOPropertiesCount} NEW Homeowner{newMailingHOPropertiesCount > 1 ? 's' : ''} that have moved into your area.
                        </Typography>
                        :
                        <Typography variant="subtitle2" align="center" className={classNames(classes.redColor, classes.boldFont)}>
                            You have {newlyConstructedHOPropertiesCount} NEW {newlyConstructedHOPropertiesCount > 0 ? 'Properties' : 'Property'} that can be added to your MailPro mailing list.
                        </Typography>}
                    <br />
                    <Typography variant="subtitle2" align="center" className={classNames(classes.redColor, classes.boldFont)}>
                        Before you can APPROVE this PMA you must review and/or Personalize these Homeowner's names.
                    </Typography>
                </Fragment>

                : children;
    }

    const getFooter = () => {
        return anyHomeownerHavingPendingAnniversaryNote ?
            <div className={classes.footerButtons}>
                <div className={classes.footerButtonsLeftRight}></div>
                <div className={classes.footerButtonsCenter}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.finishedButton}
                        onClick={event => { handleNewMailingClickButtons(MailProConstants.newAnniversary) }}
                    >
                        CLICK HERE TO SEND ANNIVERSARY NOTE TO {anyHomeownerHavingPendingAnniversaryNoteCount > 1 ? 'HOMEOWNERS' : 'HOMEOWNER'}
                    </Button>
                </div>
                <div className={classes.footerButtonsLeftRight}></div>
            </div>
            :

            anyNewPropertyorHOPending ? <div className={classes.footerButtons}>
                <div className={classes.footerButtonsLeftRight}></div>
                <div className={classes.footerButtonsCenter}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.finishedButton}
                        onClick={event => { handleNewMailingClickButtons(newMailingHOPropertiesCount > 0 ? MailProConstants.newMailing : MailProConstants.isNewProperty) }}
                    >
                        CLICK HERE TO PERSONALIZE MY NEW {newMailingHOPropertiesCount > 0 ?
                            (newMailingHOPropertiesCount > 1 ? 'HOMEOWNERS' : 'HOMEOWNER')
                            : (newlyConstructedHOPropertiesCount > 1 ? 'PROPERTIES' : 'PROPERTY')}
                    </Button>
                </div>
                <div className={classes.footerButtonsLeftRight}></div>
            </div> : children;
    }


    return mode === 'body' ? getBody() : getFooter()
}