import { rgbToHex } from "@material-ui/core";
import {
  blueGrey,
  green,
  grey,
  pink,
  red,
  teal,
  agentRoi
} from "@material-ui/core/colors";
import palette from "theme/palette";

export default theme => ({
  root: {
    padding: theme.spacing(3),
    height: "fit-content"
  },
  rootMinHeight: {
    minHeight: "90vh"
  },
  pageWidthMarginAuto: {
    width: "95%",
    margin: "auto"
  },
  agentROIBackground: {
    backgroundColor: "#ECFBFB"
  },
  agentROIContainer: {
    width: 900,
    backgroundColor: "#f5f2df",
    margin: "20px auto",
    border: "1px solid gray"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "40px 0"
  },
  headerDate: {
    color: palette.agentRoi.darkBlue,
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 19
  },
  headerBrokerageLogo: {
    height: "auto",
    maxHeight: 100,
    width: "auto",
    maxWidth: 500
  },
  headerTitle: {
    color: palette.agentRoi.darkBlue,
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 37,
    fontVariant: "small-caps"
  },
  headerLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  },
  currentROISection: {
    padding: "10px 0",
    backgroundColor: palette.agentRoi.mediumBlue
  },
  currentROISectionContent: {
    display: "flex",
    justifyContent: "space-between"
  },
  currentROISectionName: {
    color: "white",
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 24,
    textTransform: "uppercase"
  },
  currentROISectionRight: {
    display: "flex",
    flexDirection: "row"
  },
  currentROIText: {
    color: "white",
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 24,
    fontVariant: "small-caps"
  },
  currentROIData: {
    color: "white",
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 24,
    fontVariant: "small-caps",
    marginLeft: 40
  },
  allAreas: {
    // border: "1px solid red",
    paddingTop: 40
  },
  allAreasTitle: {
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 24,
    textTransform: "uppercase",
    color: palette.agentRoi.darkBlue
  },
  solds: {
    backgroundColor: "#C0DFDE"
  },
  escrow: {
    backgroundColor: "#D5ECDF"
  },
  active: {
    backgroundColor: "#D3E9F7"
  },
  totalTotal: {
    backgroundColor: "#C4DAEB"
  },

  totalTotalRow: {},
  regTableSpacing: {
    // border: "1px solid red",
    borderCollapse: "collapse",
    "& $emptyRow": {
      width: "100%",
      height: 30
    },
    "& tr": {
      // borderBottom: "1px solid palette.agentRoi.tableDivider"
      borderBottom: "1px solid #D4D4D5"
      // backgroundColor: "pink"
    },
    "& tbody": {
      "& $totalTotalRow": {
        fontWeight: 700,
        "& td": {
          fontWeight: 700
        }
      }
    },

    "& tbody tr:nth-child(1)": {
      borderTop: "1px solid darkgray"
    },

    "& td": {
      height: 30,
      textAlign: "left",
      fontFamily: "mr-eaves-xl-modern-narrow, sans-serif",
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: 16,
      textTransform: "uppercase"
    },

    "& th": {
      height: 15,
      textAlign: "left",
      fontFamily: "mr-eaves-xl-modern-narrow, sans-serif",
      fontWeight: 300,
      fontStyle: "normal",
      fontSize: 15,
      color: palette.agentRoi.mediumBlue
    },

    "& td:nth-child(1)": {
      // borderTop: "1px solid darkgray",
      // color: "orange",
      width: 240,
      // border: "1px solid blue",
      paddingLeft: 20
    },
    "& th:nth-child(1)": {
      // color: "orange",
      width: 240
    },
    "& td:nth-child(2)": {
      // color: "orange",
      width: 90,
      // border: "1px solid blue",
      paddingLeft: 20
    },
    "& th:nth-child(2)": {
      //color: "orange",
      width: 90
    },
    "& td:nth-child(3)": {
      // border: "1px solid blue",
      // color: "orange",
      width: 220
    },
    "& th:nth-child(3)": {
      // color: "orange",
      width: 220
    },
    "& td:nth-child(4)": {
      // border: "1px solid blue",
      // color: "orange",
      width: 220
    },
    "& th:nth-child(4)": {
      // color: "orange",
      width: 220
    },
    "& td:nth-child(5)": {
      // border: "1px solid blue",
      // color: "orange",
      width: 220
    },
    "& th:nth-child(5)": {
      // color: "orange",
      width: 220
    },
    "& td:nth-child(6)": {
      // border: "1px solid blue",
      // color: "orange",
      width: 220
    },
    "& th:nth-child(6)": {
      // color: "orange",
      width: 220
    },
    "& td:nth-child(7)": {
      // border: "1px solid blue",
      // color: "orange",
      width: 220
    },
    "& th:nth-child(7)": {
      // color: "orange",
      width: 220
    }
  },
  regTableTbody: {
    // border: "1px solid blue"
  },
  allAreasTable: {
    marginTop: 20
  },
  areaAnalysis: {
    // border: "1px solid green",
    marginTop: 40
  },
  areaAnalysisHeader: {
    backgroundColor: palette.agentRoi.mediumBlue,
    padding: "5px 0"
  },
  areaAnalysisHeaderContent: {
    display: "flex",
    justifyContent: "space-between",
    width: "95%",
    margin: "auto"
  },
  areaAnalysisHeaderLeftText: {
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 22,
    color: "white"
  },
  areaAnalysisHeaderRightText: {
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 22,
    color: "white"
  },
  areaAnalysisHeaderSeparator: {
    padding: "0 10px"
  },
  areaAnalysisSubheader: {
    paddingTop: 5,
    display: "flex",
    width: "95%",
    margin: "auto",
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 300,
    fontStyle: "normal",
    fontSize: 13,
    color: palette.agentRoi.mediumBlue
  },
  areaAnalysisStartDate: {
    marginRight: 30
  },
  detailedBreakdown: {
    marginTop: 20

    // border: "1px solid green"
  },
  detailedBreakdownTitle: {
    color: palette.agentRoi.mediumBlue,
    fontFamily: "mr-eaves-xl-modern-narrow, sans-serif",
    fontWeight: 300,
    fontStyle: "normal",
    fontSize: 15,
    textTransform: "uppercase",
    paddingLeft: 20
  },
  detailedBreakdownTable: {
    // border: "1px dotted blue",
    width: "100%",
    borderCollapse: "collapse",
    marginTop: 10,
    "& tbody tr:nth-child(1)": {
      borderTop: "1px solid darkgray"
    },
    "& tbody tr": {
      borderBottom: "1px solid #D4D4D5"
    },
    "& td": {
      height: 30,
      textAlign: "left",
      fontFamily: "mr-eaves-xl-modern-narrow, sans-serif",
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: 16,
      textTransform: "uppercase",
      color: palette.agentRoi.darkGray
    },

    "& th": {
      height: 15,
      textAlign: "left",
      fontFamily: "mr-eaves-xl-modern-narrow, sans-serif",
      fontWeight: 300,
      fontStyle: "normal",
      fontSize: 15,
      color: palette.agentRoi.mediumBlue
    },

    "& td:nth-child(1)": {
      // borderTop: "1px solid darkgray",
      // color: "orange",
      width: 190,

      paddingLeft: 20
    },
    "& th:nth-child(1)": {
      // color: "orange",
      // border: "1px solid blue",
      width: "190px",
      paddingLeft: 20
      // margin: "auto"
    },
    "& td:nth-child(2)": {
      // color: "orange",
      width: 790,
      // border: "1px solid blue",
      paddingLeft: 20
    },
    "& th:nth-child(2)": {
      //color: "orange",
      width: 790,
      paddingLeft: 20
    },
    "& td:nth-child(3)": {
      // border: "1px solid blue",
      // color: "orange",
      width: 220
    },
    "& th:nth-child(3)": {
      // color: "orange",
      width: 220
    },
    "& td:nth-child(4)": {
      // border: "1px solid blue",
      // color: "orange",
      width: 220
    },
    "& th:nth-child(4)": {
      // color: "orange",
      width: 220
    },
    "& td:nth-child(5)": {
      // border: "1px solid blue",
      // color: "orange",
      width: 270
    },
    "& th:nth-child(5)": {
      // color: "orange",
      width: 270
    },
    "& td:nth-child(6)": {
      // border: "1px solid blue",
      textAlign: "center",
      // color: "orange",
      width: 120
    },
    "& th:nth-child(6)": {
      // color: "orange",
      width: 120
    }
  },
  cancelIconContainer: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  addCircleRow: {
    display: "flex",
    alignItems: "center",
    "& span": {
      paddingLeft: 5
    }
  },
  roiFooter: {
    color: palette.agentRoi.mediumBlue,
    fontFamily: "mr-eaves-xl-modern-narrow, sans-serif",
    fontWeight: 300,
    fontStyle: "normal",
    margin: "30px 0 30px 60px"
    // marginLeft: 60
  }
});
