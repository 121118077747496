import Base from "common/constants";
import { AES, enc } from "crypto-js";

const SetOAuthToken = (data) => {
  /**first reset all other tokens */
  ResetAllLocalStorages();
  /**now set as fresh token */
  localStorage.setItem(
    Base.LocalStorageBase.LG_OAUTH_TOKEN,
    JSON.stringify(data)
  );
  let tokenStoreText = `${data.userName}${data.access_token}${data.expires_in}${data.refresh_token}`;
  /**set into local storage also with encryption */
  let cipherText = AES.encrypt(
    tokenStoreText,
    Base.LocalStorageBase.LG_TOKEN_SALT
  );
  /**plain text is userName i.e. userEmail Address */
  return localStorage.setItem(
    Base.LocalStorageBase.LG_OAUTH_TOKEN_CHECKER,
    cipherText
  );
};

const IsValidLoginAuth = (noReset = true) => {
  /**get data from local storage */
  try {
    let localData = GetStateStorage();
    let tokenTempData = GetOAuthToken();
    let tokenData = JSON.parse(tokenTempData);
    let tokenCheckerData = GetOAuthCheckerToken();
    if (!localData || !tokenCheckerData || !tokenTempData) {
      return false;
    }
    // Decrypt the tokendata
    let bytes = AES.decrypt(
      tokenCheckerData.toString(),
      Base.LocalStorageBase.LG_TOKEN_SALT
    );
    let plainOAuthChecketText = bytes.toString(enc.Utf8);
    //build the oauth check plain string
    let tokenStoreText = `${tokenData.userName}${tokenData.access_token}${tokenData.expires_in}${tokenData.refresh_token}`;
    //start the checking
    let isValid = false;
    if (
      localData &&
      localData.isUserLoggedIn &&
      localData.tokenData.userName === tokenData.userName &&
      localData.tokenData.access_token === tokenData.access_token &&
      localData.tokenData.refresh_token === tokenData.refresh_token &&
      localData.tokenData.expires_in === tokenData.expires_in &&
      tokenStoreText === plainOAuthChecketText
    ) {
      isValid = true;
    }
    return isValid;
  } catch (err) {
    /**Looks like someone changed local storage. reset every storage. */
    // if(noReset){
    //   ResetAllLocalStorages();
    // }
    return false;
  }
};

const IsValidSessionSwitch = () => {
  /**get data from local storage */
  try {
    let tokenTempData = GetOAuthToken();
    let tokenData = JSON.parse(tokenTempData);
    let tokenCheckerData = GetOAuthCheckerToken();
    if (!tokenCheckerData || !tokenTempData) {
      return false;
    }
    // Decrypt the tokendata
    let bytes = AES.decrypt(
      tokenCheckerData.toString(),
      Base.LocalStorageBase.LG_TOKEN_SALT
    );
    let plainOAuthChecketText = bytes.toString(enc.Utf8);
    //build the oauth check plain string
    let tokenStoreText = `${tokenData.userName}${tokenData.access_token}${tokenData.expires_in}${tokenData.refresh_token}`;
    let isValid = false;
    if (
      tokenStoreText === plainOAuthChecketText &&
      tokenData.oldSessionData &&
      tokenData.oldSessionData.userName &&
      tokenData.userName !== tokenData.oldSessionData.userName
    ) {
      isValid = true;
    }
    return isValid;
  } catch (err) {
    return false;
  }
};

const IsValidSemiAuth = () => {
  try {
    /**Note:- Return false if user is fully logged in. means IsValidLoginAuth returning true.  */
    let checkForLoginAuth = IsValidLoginAuth(false);
    if (checkForLoginAuth) {
      return false;
    }
    let isValid = false;
    let localData = GetSemiAuthStateStorage();
    let tokenCheckerData = GetSemiAuthCheckerToken();
    if (!localData || !tokenCheckerData) {
      return false;
    }
    // Decrypt the tokendata
    let bytes = AES.decrypt(
      tokenCheckerData.toString(),
      Base.LocalStorageBase.LG_TOKEN_SALT
    );
    let plainText = JSON.parse(bytes.toString(enc.Utf8));
    if (
      localData.emailAddress &&
      localData.emailAddress === plainText.emailAddress &&
      localData.registerToken === plainText.registerToken
    ) {
      isValid = true;
    }
    return isValid;
  } catch (err) {
    // console.log('IsValidSemiAuth ERROR');
    // console.log(err);
    return false;
  }
};

const IsValidNavbarData = () => {
  /**get data from local storage */
  try {
    let localData = GetNavbarDataFromStorage();
    let checkerData = GetNavbarCheckerData();
    if (!localData || !checkerData) {
      return false;
    }
    // Decrypt the tokendata
    let bytes = AES.decrypt(
      checkerData.toString(),
      Base.LocalStorageBase.LG_TOKEN_SALT
    );
    let plainText = bytes.toString(enc.Utf8);
    let isValid = false;
    /**check for every key in object */
    if (plainText === JSON.stringify(localData)) {
      isValid = true;
    }
    return isValid;
  } catch (err) {
    /**Looks like someone changed local storage. reset every storage. */
    // if(noReset){
    //   ResetAllLocalStorages();
    // }
    return false;
  }
};

const IsValidLPROIAuth = () => {
  try {
    /**Note:- Return false if user is fully logged in. means IsValidLoginAuth returning true.  */
    let checkForLoginAuth = IsValidLoginAuth(false);
    let checkForSemiAuth = IsValidSemiAuth();
    if (checkForLoginAuth || checkForSemiAuth) {
      return false;
    }
    let isValid = false;
    let localData = GetLPROIAuthStateStorage();
    let tokenCheckerData = GetLpROIAuthCheckerToken();
    if (!localData || !tokenCheckerData) {
      return false;
    }
    // Decrypt the tokendata
    let bytes = AES.decrypt(
      tokenCheckerData.toString(),
      Base.LocalStorageBase.LG_ROI_TOKEN_SALT
    );
    let plainText = JSON.parse(bytes.toString(enc.Utf8));
    if (
      localData.emailAddress &&
      localData.emailAddress === plainText.emailAddress &&
      localData.roiToken === plainText.roiToken
    ) {
      isValid = true;
    }
    return isValid;
  } catch (err) {
    return false;
  }
};

const SetSemiAuthInStorage = (registerToken, emailAddress) => {
  let data = {
    registerToken,
    emailAddress,
  };
  localStorage.setItem(
    Base.LocalStorageBase.LG_APP_SEMI_AUTH_STATE,
    JSON.stringify(data)
  );
  /**set into local storage also with encryption */
  let cipherText = AES.encrypt(
    JSON.stringify(data),
    Base.LocalStorageBase.LG_TOKEN_SALT
  );
  /**plain text is userName i.e. userEmail Address */
  return localStorage.setItem(
    Base.LocalStorageBase.LG_APP_SEMI_AUTH_STATE_CHECKER,
    cipherText
  );
};

const SetSemiAuthExpireTimreInStorage = (data, expires = null) => {
  let now = Date.now();
  let schedule = 0;
  if (expires === undefined || expires === null) {
    expires = 6 * 60 * 60; /* default: seconds for 6 hours */
    //build value
    schedule = now + expires * 1000;
  } else {
    expires = Math.abs(expires); /* make sure it's positive */
    //assign value
    schedule = expires;
  }
  localStorage.setItem(
    Base.LocalStorageBase.LG_APP_SEMI_AUTH_STATE + "_expiresIn",
    schedule
  );
};

/**
 * For Landing Page data
 */
const SetLPROIAuthInStorage = (roiToken, emailAddress) => {
  //LG_ROI_TOKEN_SALT, LG_APP_ROI_AUTH_STATE, LG_APP_ROI_AUTH_STATE_CHECKER
  let data = {
    roiToken,
    emailAddress,
  };
  localStorage.setItem(
    Base.LocalStorageBase.LG_APP_ROI_AUTH_STATE,
    JSON.stringify(data)
  );
  /**set into local storage also with encryption */
  let cipherText = AES.encrypt(
    JSON.stringify(data),
    Base.LocalStorageBase.LG_ROI_TOKEN_SALT
  );
  /**plain text is userName i.e. userEmail Address */
  localStorage.setItem(
    Base.LocalStorageBase.LG_APP_ROI_AUTH_STATE_CHECKER,
    cipherText
  );
  let schedule = Date.now() + 30 * 24 * 60 * 60 * 1000; //default for 30 days, 1000 for millisecond converison
  /**set the expire time also */
  localStorage.setItem(
    Base.LocalStorageBase.LG_APP_ROI_AUTH_STATE + "_expiresIn",
    schedule
  );
};

const SetActiveAgentDataInStorage = (data) => {
  /**now set as fresh token */
  return localStorage.setItem(
    Base.LocalStorageBase.LG_APP_ACTIVE_AGENT_DATA,
    JSON.stringify(data)
  );
};

const SetActiveAgentDataExpireTimeInStorage = (expires = null) => {
  let now = Date.now();
  let schedule = now + expires * 1000;
  if (expires === undefined || expires === null) {
    expires = 3 * 60 * 60; /* default: seconds for 3 hours */
    //build expire value
    schedule = now + expires * 1000;
  } else {
    expires = Math.abs(expires); /* make sure it's positive */
    schedule = expires;
  }
  localStorage.setItem(
    Base.LocalStorageBase.LG_APP_ACTIVE_AGENT_DATA_CHECKER + "_expiresIn",
    schedule
  );
};

const GetNavbarDataFromStorage = () => {
  try {
    let value = localStorage.getItem(
      Base.LocalStorageBase.LG_APP_NAVBAR_MLS_SESSION_DATA
    );
    if (value === null || value === "") {
      return {};
    }
    return JSON.parse(value);
  } catch (e) {
    // console.log(
    //   "getStorage: Error reading key [" +
    //     Base.LocalStorageBase.LG_APP_NAVBAR_MLS_SESSION_DATA +
    //     "] from localStorage: " +
    //     JSON.stringify(e)
    // );
    return null;
  }
};

const GetSemiAuthStateStorage = () => {
  let now = Date.now();
  /* set expiration for storage */
  let expiresIn = localStorage.getItem(
    Base.LocalStorageBase.LG_APP_SEMI_AUTH_STATE + "_expiresIn"
  );
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  if (expiresIn < now) {
    /* Data Expired */
    removeStorage(Base.LocalStorageBase.LG_APP_SEMI_AUTH_STATE);
    return null;
  } else {
    try {
      let value = localStorage.getItem(
        Base.LocalStorageBase.LG_APP_SEMI_AUTH_STATE
      );
      if (value === null || value === "") {
        return {};
      }
      return JSON.parse(value);
    } catch (e) {
      // console.log(
      //   "getStorage: Error reading key [" +
      //     Base.LocalStorageBase.LG_APP_SEMI_AUTH_STATE +
      //     "] from localStorage: " +
      //     JSON.stringify(e)
      // );
      return null;
    }
  }
};

const GetLPROIAuthStateStorage = () => {
  let now = Date.now();
  /* set expiration for storage */
  let expiresIn = localStorage.getItem(
    Base.LocalStorageBase.LG_APP_ROI_AUTH_STATE + "_expiresIn"
  );
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  if (expiresIn < now) {
    /* Data Expired */
    removeStorage(Base.LocalStorageBase.LG_APP_ROI_AUTH_STATE);
    return null;
  } else {
    try {
      let value = localStorage.getItem(
        Base.LocalStorageBase.LG_APP_ROI_AUTH_STATE
      );
      if (value === null || value === "") {
        return {};
      }
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }
};

const SetStateInStorage = (data, expires = null) => {
  return localStorage.setItem(
    Base.LocalStorageBase.LG_APP_STATE,
    JSON.stringify(data)
  );
};

const SetNavbarDataInStorage = (data, expires = null) => {
  localStorage.setItem(
    Base.LocalStorageBase.LG_APP_NAVBAR_MLS_SESSION_DATA,
    JSON.stringify(data)
  );
  /**set into local storage also with encryption */
  let cipherText = AES.encrypt(
    JSON.stringify(data),
    Base.LocalStorageBase.LG_TOKEN_SALT
  );
  /**plain text is userName i.e. userEmail Address */
  return localStorage.setItem(
    Base.LocalStorageBase.LG_APP_NAVBAR_MLS_SESSION_CHECKER,
    cipherText
  );
};

const SetExpireTimerInStorage = (data, expires = null) => {
  let now = Date.now();
  let schedule = 0;
  if (expires === undefined || expires === null) {
    expires = 23.5 * 60 * 60; /* default: seconds for 23.5 hours */
    //build the schedule method
    schedule = now + expires * 1000;
  } else {
    expires = Math.abs(expires); /* make sure it's positive */
    //add the expire time
    schedule = expires;
  }
  localStorage.setItem(
    Base.LocalStorageBase.LG_APP_STATE + "_expiresIn",
    schedule
  );
};

// const SetCutOffStateInStorage = (userID) => {
//   return localStorage.setItem(
//     Base.LocalStorageBase.LG_APP_CUT_OFF_DATA,
//     userID
//   );
// };

// const SetCutOffExpireTimeInStorage = (expires = null) => {
//   let now = Date.now();
//   let schedule = now + expires * 1000;
//   if (expires === undefined || expires === null) {
//     expires = 23.5 * 60 * 60; /* default: seconds for 24 hours */
//     /*build the expire at */
//     schedule = now + expires * 1000;
//   } else {
//     expires = Math.abs(expires); /* make sure it's positive */
//     //assign expire value
//     schedule = expires;
//   }
//   localStorage.setItem(
//     Base.LocalStorageBase.LG_APP_CUT_OFF_DATA + "_expiresIn",
//     schedule
//   );
// };

const GetActiveAgentDataFromStorage = () => {
  let now = Date.now();
  /* set expiration for storage */
  let expiresIn = localStorage.getItem(
    Base.LocalStorageBase.LG_APP_ACTIVE_AGENT_DATA_CHECKER + "_expiresIn"
  );
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  if (expiresIn < now) {
    /* Data Expired */
    removeStorage(Base.LocalStorageBase.LG_APP_ACTIVE_AGENT_DATA);
    return null;
  } else {
    try {
      let value = localStorage.getItem(
        Base.LocalStorageBase.LG_APP_ACTIVE_AGENT_DATA
      );
      if (value === null || value === "") {
        return null;
      }
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }
};

// const GetCutOffStateStorage = () => {
//   let now = Date.now();
//   /* set expiration for storage */
//   let expiresIn = localStorage.getItem(
//     Base.LocalStorageBase.LG_APP_CUT_OFF_DATA + "_expiresIn"
//   );
//   if (expiresIn === undefined || expiresIn === null) {
//     expiresIn = 0;
//   }

//   if (expiresIn < now) {
//     /* Data Expired */
//     removeStorage(Base.LocalStorageBase.LG_APP_CUT_OFF_DATA);
//     return null;
//   } else {
//     try {
//       let value = localStorage.getItem(
//         Base.LocalStorageBase.LG_APP_CUT_OFF_DATA
//       );
//       if (value === null || value === "") {
//         return null;
//       }
//       return value;
//     } catch (e) {
//       return null;
//     }
//   }
// };

const GetStateStorage = () => {
  let now = Date.now();
  /* set expiration for storage */
  let expiresIn = localStorage.getItem(
    Base.LocalStorageBase.LG_APP_STATE + "_expiresIn"
  );
  // let expiresIn = 832823832938900;
  //  LG_APP_SEMI_AUTH_STATE_expiresIn
  // let expiresIn =
  //   localStorage.getItem("LG_APP_SEMI_AUTH_STATE_expiresIn") ||
  //   localStorage.getItem(Base.LocalStorageBase.LG_APP_STATE + "_expiresIn");

  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  if (expiresIn < now) {
    /* Data Expired */

    removeStorage(Base.LocalStorageBase.LG_APP_STATE);
    return null;
  } else {
    try {
      let value = localStorage.getItem(Base.LocalStorageBase.LG_APP_STATE);

      if (value === null || value === "") {
        return {};
      }
      return JSON.parse(value);
    } catch (e) {
      // console.log(
      //   "getStorage: Error reading key [" +
      //     Base.LocalStorageBase.LG_APP_STATE +
      //     "] from localStorage: " +
      //     JSON.stringify(e)
      // );
      return null;
    }
  }
};

const removeStorage = (key) => {
  try {
    localStorage.removeItem(key);
    localStorage.removeItem(key + "_expiresIn");
  } catch (e) {
    // console.log(
    //   "removeStorage: Error removing key [" +
    //     key +
    //     "] from localStorage: " +
    //     JSON.stringify(e)
    // );
    return false;
  }
  return true;
};

const ResetAllLocalStorages = () => {
  try {
    localStorage.removeItem(Base.LocalStorageBase.LG_APP_STATE);
    localStorage.removeItem(Base.LocalStorageBase.LG_APP_STATE + "_expiresIn");
    localStorage.removeItem(Base.LocalStorageBase.LG_OAUTH_TOKEN);
    localStorage.removeItem(Base.LocalStorageBase.LG_OAUTH_TOKEN_CHECKER);
    localStorage.removeItem(
      Base.LocalStorageBase.LG_APP_NAVBAR_MLS_SESSION_DATA
    );
    localStorage.removeItem(
      Base.LocalStorageBase.LG_APP_NAVBAR_MLS_SESSION_CHECKER
    );
    //semi auth tokens
    localStorage.removeItem(
      Base.LocalStorageBase.LG_APP_SEMI_AUTH_STATE + "_expiresIn"
    );
    localStorage.removeItem(Base.LocalStorageBase.LG_APP_SEMI_AUTH_STATE);
    localStorage.removeItem(
      Base.LocalStorageBase.LG_APP_SEMI_AUTH_STATE_CHECKER
    );
    //cut off tokens
    localStorage.removeItem(Base.LocalStorageBase.LG_APP_CUT_OFF_DATA);
    localStorage.removeItem(
      Base.LocalStorageBase.LG_APP_CUT_OFF_DATA + "_expiresIn"
    );
    //lp roi token
    localStorage.removeItem(Base.LocalStorageBase.LG_APP_ROI_AUTH_STATE);
    localStorage.removeItem(
      Base.LocalStorageBase.LG_APP_ROI_AUTH_STATE_CHECKER
    );
    localStorage.removeItem(
      Base.LocalStorageBase.LG_APP_ROI_AUTH_STATE + "_expiresIn"
    );
  } catch (e) {
    // console.log(
    //   "resetAllLocalStorages: Error removing all keys from localStorage: " +
    //     JSON.stringify(e)
    // );
  }
};

/**
 * Reson of doing this is on session switch we dont want to remove the active agent data and reload again.
 * On logout screen we will call this and remove the data
 */
const ResetDataExceptSessionSwitchLocalStorages = () => {
  try {
    localStorage.removeItem(Base.LocalStorageBase.LG_APP_ACTIVE_AGENT_DATA);
    localStorage.removeItem(
      Base.LocalStorageBase.LG_APP_ACTIVE_AGENT_DATA_CHECKER + "_expiresIn"
    );
  } catch (e) {
    //something goes wrong
  }
};

const GetOAuthToken = () => {
  return localStorage.getItem(Base.LocalStorageBase.LG_OAUTH_TOKEN);
};

const GetOAuthCheckerToken = () => {
  return localStorage.getItem(Base.LocalStorageBase.LG_OAUTH_TOKEN_CHECKER);
};

const GetNavbarCheckerData = () => {
  return localStorage.getItem(
    Base.LocalStorageBase.LG_APP_NAVBAR_MLS_SESSION_CHECKER
  );
};

const GetSemiAuthCheckerToken = () => {
  return localStorage.getItem(
    Base.LocalStorageBase.LG_APP_SEMI_AUTH_STATE_CHECKER
  );
};

const GetLpROIAuthCheckerToken = () => {
  return localStorage.getItem(
    Base.LocalStorageBase.LG_APP_ROI_AUTH_STATE_CHECKER
  );
};

const GetOAuthTokenByKey = (key) => {
  try {
    let oAuthTokenData = JSON.parse(
      localStorage.getItem(Base.LocalStorageBase.LG_OAUTH_TOKEN)
    );
    return oAuthTokenData[key] ? oAuthTokenData[key] : "";
  } catch (err) {
    return "";
  }
};

const GetStateExpireTimerData = () => {
  return localStorage.getItem(
    Base.LocalStorageBase.LG_APP_STATE + "_expiresIn"
  );
};

export {
  SetOAuthToken,
  GetOAuthToken,
  GetOAuthTokenByKey,
  SetStateInStorage,
  GetStateStorage,
  ResetAllLocalStorages,
  SetExpireTimerInStorage,
  IsValidLoginAuth,
  IsValidSemiAuth,
  IsValidSessionSwitch,
  GetOAuthCheckerToken,
  SetSemiAuthInStorage,
  SetSemiAuthExpireTimreInStorage,
  GetSemiAuthCheckerToken,
  GetSemiAuthStateStorage,
  GetNavbarCheckerData,
  SetNavbarDataInStorage,
  IsValidNavbarData,
  GetNavbarDataFromStorage,
  // SetCutOffStateInStorage,
  // SetCutOffExpireTimeInStorage,
  // GetCutOffStateStorage,
  SetActiveAgentDataInStorage,
  SetActiveAgentDataExpireTimeInStorage,
  GetActiveAgentDataFromStorage,
  ResetDataExceptSessionSwitchLocalStorages,
  GetStateExpireTimerData,
  SetLPROIAuthInStorage,
  IsValidLPROIAuth,
};
