import React, { useEffect, useRef, useState, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Typography,
  Grid,
  FormControl,
  InputAdornment,
  Input,
  TextField,
  CircularProgress
} from "@material-ui/core";
import classNames from "classnames";
import styles from "./styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import ClearIcon from "@material-ui/icons/Clear";
import { GetStateStorage } from "common/storage";
import { SubmitHelpRequestAPI } from "services/others";
import { showSuccessNotification } from "common/helpers";
import { showErrorNotification } from "common/helpers";
import { withSnackbar } from "notistack";

const YETwoPropertyHighlightModal = props => {
  console.log("modal props", props);
  /**get classes */
  const {
    classes,
    handlePropertyHighlightDialog,
    currentArea,
    isModalOpen
  } = props;

  console.log(currentArea);

  const [isLoading, setLoading] = useState(false);
  const [issueList, setIssueList] = useState({});

  const handleClose = () => {
    handlePropertyHighlightDialog(false);
  };

  const setTextfiledValue = (value, key) => {
    let xData = Object.assign({}, issueList);
    if (!xData[key]) {
      xData[key] = "";
    }
    xData[key] = value;
    setIssueList(xData);
  };

  const disableButton = () => {
    return Object.values(issueList).filter(item => item !== "").length === 0;
  };

  const handleSubmit = () => {
    /**make loading true */
    {
      /*setLoading(true);
    try {
      let storageData = GetStateStorage();
      let {
        listgenUserFirstName,
        listgenUserLastName,
        emailAddress,
        mobilePhoneNo,
        listgenUserID
      } = storageData.userData;
      let agentName = `${listgenUserFirstName}${
        listgenUserLastName ? ` ${listgenUserLastName}` : ""
      }`;
      let message = Object.values(issueList)
        .filter(item => item !== "")
        .join(", ");
      SubmitHelpRequestAPI(
        listgenUserID,
        agentName,
        emailAddress,
        mobilePhoneNo,
        "I have found issues in my PMA",
        message,
        "request",
        currentArea.mlsCityName,
        currentArea.mlsNeighborhood
      )
        .then(data => {
          switch (data) {
            case true:
              showSuccessNotification(props, "Request submitted.");
              break;
            case false:
              showErrorNotification(props, "Some unexpected error occured.");
              break;
            default:
              showErrorNotification(
                props,
                "Something went wrong. Please try after some time."
              );
              break;
          }
          setLoading(false);
          handleClose();
        })
        .catch(err => {
          showErrorNotification(props, "Unable to process request.");
          setLoading(false);
        });
    } catch (err) {
      showErrorNotification(props, "Unable to process request.");
      setLoading(false);
    }*/
    }
  };

  let numberOfLines = [0, 1, 2, 3, 4];

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="property-highlight-dialog"
        aria-describedby="property-highlight-dialog"
        maxWidth={"lg"}
        disableBackdropClick={true}
      >
        <DialogTitle id="scroll-dialog-title">
          <div className={classes.propertyFactHeader}>
            <div></div>
            <div>
              <Typography
                variant="body1"
                align="center"
                className={classNames(
                  classes.lightBoldText,
                  classes.pmaHighlight16pxFont
                )}
              >
                Please select property photos.
              </Typography>
              <Typography
                variant="body1"
                align="center"
                className={classNames(
                  classes.lightBoldText,
                  classes.pmaHighlight16pxFont
                )}
              >
                For the backpage of your PMA
              </Typography>
            </div>
            <div onClick={handleClose} className={classes.closeModalBtn}>
              <Button
                variant="contained"
                size="small"
                className={classes.closeredButton}
              >
                <ClearIcon className={classes.whiteColor} />
              </Button>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Fragment>
            {isLoading ? (
              <div
                className={classNames(
                  classes.fullWidth,
                  classes.cProgressStopBlinking
                )}
              >
                <CircularProgress className={classes.cProgress} />
              </div>
            ) : (
              <div
                className={classNames(
                  classes.portletFooter,
                  classes.approvalFooter
                )}
              >
                <div className={classes.footerButtons}>
                  <div className={classes.footerButtonsLeftRight}>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={event => handleClose()}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={classes.footerButtonsCenter}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.paleBlueButton}
                      onClick={event => handleSubmit()}
                      disabled={disableButton()}
                    >
                      Submit
                    </Button>
                  </div>
                  <div className={classes.footerButtonsLeftRight}></div>
                </div>
              </div>
            )}
          </Fragment>
        </DialogContent>
      </Dialog>
    </div>
  );
};

YETwoPropertyHighlightModal.propTypes = {
  approveMailingListFn: PropTypes.func.isRequired,
  continueEditingModeFn: PropTypes.func.isRequired
  //mlsAreas: PropTypes.array.isRequired,
  //areaName: PropTypes.string.isRequired,
};

YETwoPropertyHighlightModal.defaultProps = {
  approveMailingListFn: () => {},
  continueEditingModeFn: () => {}
  //mlsAreasa: [],
  //areaName: null
};

export default compose(
  withStyles(styles),
  withSnackbar
)(YETwoPropertyHighlightModal);
