// Material helpers
import { createMuiTheme } from '@material-ui/core/styles';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';
const theme = createMuiTheme({
  palette,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  typography: {
    useNextVariants: true,
    ...typography,
  },
});

export default theme;
