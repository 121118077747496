import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { CreditCardComponent } from 'components';

/**Payment logos */
import Amex from '../../../../_img/registrationIcons/amex.png';
import Discover from '../../../../_img/registrationIcons/discover.png';
import Mastercard from '../../../../_img/registrationIcons/mastercard.png';
import Visa from '../../../../_img/registrationIcons/visa.png';

import StripeLock from '../../../../_img/registrationIcons/stripe-lock.webp';

import { LockOutlined, InfoOutlined } from '@material-ui/icons';
import { showErrorNotification } from 'common/helpers';
import { showSuccessNotification } from 'common/helpers';
import { AddCreditCardForAnAgentAPI } from 'services/agent';
import { AddAgentCCInfo } from 'services/agent';
import { CircularProgress } from '@material-ui/core';
import compose from 'recompose/compose';
import { withSnackbar } from 'notistack';
import CommonConfirmationDialog from 'components/CommonConfirmationDialog';
import { systemLogRequest } from 'common/helpers';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },

    stripeBody: {
        marginTop: -theme.spacing(2),
        marginBottom: theme.spacing(3),
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paymentIcon: {
        width: '4em',
    },
    paymentIconHolder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    centerDivider: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1)
    },
    poweredByStripe: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: -theme.spacing(4),
        marginBottom: -theme.spacing(4)
    },
    stripeLogoImageWrapper: {
        maxWidth: '120px',
        textAlign: 'center',
        marginTop: theme.spacing(2),
        height: 'auto',
        objectFit: 'cover'
    },
    poweredByStripeImage: {
        width: '100%'
    },
    paymentSecureBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    securePayment: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
    },
    paymentFooter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
    },
    footerButtons: {
        position: 'relative',
        display: 'flex',
    },
    portletFooter: {
        display: 'inline-block',
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '100%'
    },
    footerButtonsCenter: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    footerButtonsLeftRight: {
        flex: 1,
    },
    progressWrapper: {
        paddingTop: '8px',
        paddingBottom: '8px',
        display: 'flex',
        justifyContent: 'center'
    },
    dialogActions: {
        padding: 0
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
}))(MuiDialogActions);

const AddNewCreditCardDialog = (props) => {
    const classes = makeStyles(styles)();
    const { closeCCDialogFn, listgenUserID, customerID, listgenAgentFirstName, listgenAgentLastName, listgenAgentEmailAddress, agentHaveCard, getAgentCreditCards } = props;
    const [creditCardData, setCreditCardData] = useState(null);

    const [isCreditCardInfoValid, setCreditCardInfoValid] = useState(null);
    /**new credit card data */
    const [newCard, setNewCardData] = useState({
        nameOnCard: null,
        cardNumber: null,
        expiryMonth: null,
        expiryYear: null,
        cvc: null,
        isDefault: (agentHaveCard ? false : true)//if agent have card then by default no need to send is default true
    });

    /**loading variables */
    const [isLoading, setIsLoading] = useState(false);

    /**Open confirmation dialog */
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    const handleFieldChange = (key, value) => {
        const newState = newCard;
        /**set the state */
        newState[key] = value;
        setNewCardData(newState);
    };
    const handleCreditCardData = (creditCardData) => {
        setCreditCardData(creditCardData);
    };

    const handleCreditCardValidity = (isValid) => {
        setCreditCardInfoValid(isValid);
    };

    /**Use effect to handle is valid */
    useEffect(() => {
        /**its a virtualized field change. not gonna show any error */
        if (isCreditCardInfoValid) {
            let expiryArray = creditCardData.expiry.split('/');
            handleFieldChange('nameOnCard', creditCardData.name);
            handleFieldChange('cardNumber', creditCardData.number.replace(/\D+/g, ''));
            handleFieldChange('expiryMonth', expiryArray[0]);
            handleFieldChange('expiryYear', '20' + expiryArray[1]);
            handleFieldChange('cvc', creditCardData.cvc);
        } else {
            /**make null all */
            handleFieldChange('nameOnCard', '');
            handleFieldChange('cardNumber', '');
            handleFieldChange('expiryMonth', '');
            handleFieldChange('expiryYear', '');
            handleFieldChange('cvc', '');
        }
    }, [isCreditCardInfoValid]);

    const handleYesClick = () => {
        /**now call according to whether agent have card or not */
        let nextFn = agentHaveCard ? addCreditCardForAnAgent : addFirstCreditCardForAnAgent;
        nextFn(true);
    };

    const handleNoClick = () => {
        /**now call according to whether agent have card or not */
        let nextFn = agentHaveCard ? addCreditCardForAnAgent : addFirstCreditCardForAnAgent;
        nextFn(false);
    };
    
    const closeConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
    };

    const openDefaultCardDialog = () => {
        setOpenConfirmationDialog(true);
    };

    const addCreditCardForAnAgent = (isDefault) => {
        /**make loading true */
        const { nameOnCard, cardNumber, expiryMonth, expiryYear, cvc } = newCard;
        setIsLoading(true);
        try {
            AddCreditCardForAnAgentAPI(nameOnCard, listgenUserID, customerID, listgenAgentEmailAddress, cardNumber, expiryMonth, expiryYear, cvc, isDefault)
                .then(data => {
                    if (data) {
                        setIsLoading(false);
                        /**reload the cards now */
                        showSuccessNotification(props, "Card Added.");
                        closeCCDialogFn();
                        getAgentCreditCards();
                    } else {
                        //assign empty data
                        setIsLoading(false);
                        systemLogRequest('credit_card', 104, {}, data);
                        showErrorNotification(props, "Unable to add your credit card on stripe. Error Code: 104");
                    }
                })
                .catch(err => {
                    systemLogRequest('credit_card', 105, err);
                    showErrorNotification(props, "Unable to process request. Error Code: 105");
                    setIsLoading(false);
                });
        } catch (err) {
            systemLogRequest('credit_card', 105, err);
            showErrorNotification(props, "Unable to process request. Error Code: 106");
            setIsLoading(false);
        }
    };

    const addFirstCreditCardForAnAgent = () => {
        /**make loading true */
        const { nameOnCard, cardNumber, expiryMonth, expiryYear, cvc, isDefault } = newCard;
        setIsLoading(true);
        try {
            AddAgentCCInfo(listgenUserID, listgenAgentEmailAddress, cardNumber, expiryMonth, expiryYear, cvc, nameOnCard)
                .then(data => {
                    if (data) {
                        setIsLoading(false);
                        /**reload the cards now */
                        showSuccessNotification(props, "Card Added.");
                        closeCCDialogFn();
                        getAgentCreditCards();
                    } else {
                        //assign empty data
                        setIsLoading(false);
                        systemLogRequest('credit_card', 101, {}, data);
                        showErrorNotification(props, "Unable to process request. Error Code: 101");
                    }
                })
                .catch(err => {
                    systemLogRequest('credit_card', 102, err);
                    showErrorNotification(props, "Unable to process request. Error Code: 102");
                    setIsLoading(false);
                });
        } catch (err) {
            systemLogRequest('credit_card', 103, err);
            showErrorNotification(props, "Unable to process request. Error Code: 103");
            setIsLoading(false);
        }
    };

    return (
        <div>
            {openConfirmationDialog ?
                <CommonConfirmationDialog
                    title={"Do you want to make this card your default card?"}
                    subtitle={"Default card will be used to charge for the PMA. You can change default card preference later also."}
                    closeFn={closeConfirmationDialog}
                    methodToCallOnConfirmation={handleYesClick}
                    methodToCallOnCancel={handleNoClick}
                    confirmationID={null}
                    confirmationButtonText={"Yes"}
                    cancelButtonText={"No"}
                    stopAutoConfirmationFnExecution={true}
                /> : ''}
            <Dialog disableBackdropClick={true} aria-labelledby="customized-dialog-title" open={true} maxWidth="md" fullWidth={true}>
                <DialogTitle id="customized-dialog-title" onClose={event => closeCCDialogFn(event)}>
                    {agentHaveCard ? 'Add a new Credit Card' : 'Add First Credit Card'}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div className={classes.poweredByStripe}>
                        <div className={classes.stripeLogoImageWrapper}>
                            <img
                                src={StripeLock}
                                className={classes.poweredByStripeImage}
                                alt="Powered By Stripe"
                            />
                        </div>
                    </div>
                    <CreditCardComponent
                        holdername={newCard.nameOnCard}
                        handleCreditCardData={handleCreditCardData}
                        handleCreditCardValidity={handleCreditCardValidity}
                        cardNumber={newCard.cardNumber}
                        expiryMonth={newCard.expiryMonth}
                        expiryYear={newCard.expiryYear}
                        cvc={newCard.cvc}
                    />

                    <div className={classes.paymentSecureBox}>
                        <div className={classes.paymentIconHolder}>
                            <div>
                                <img
                                    src={Amex}
                                    alt="American Express"
                                    className={classes.paymentIcon}
                                />
                            </div>
                            <div>
                                <img
                                    src={Discover}
                                    alt="Discover"
                                    className={classes.paymentIcon}
                                />
                            </div>
                            <div>
                                <img
                                    src={Visa}
                                    alt="Visa"
                                    className={classes.paymentIcon}
                                />
                            </div>
                            <div>
                                <img
                                    src={Mastercard}
                                    alt="Master Card"
                                    className={classes.paymentIcon}
                                />
                            </div>
                        </div>
                        <div className={classes.securePayment}>
                            <IconButton>
                                <LockOutlined />
                            </IconButton>
                            <Typography variant="subtitle2">
                                Secure Stripe Payment (256 bit SSL)
             </Typography>
                        </div>
                    </div>
                    <div className={classes.paymentFooter}>
                        <div>
                            <IconButton color="primary">
                                <InfoOutlined fontSize='large' />
                            </IconButton>
                        </div>
                        <div className={classes.bottomText}>
                            <Typography variant="subtitle2" className={classes.dialogContent}>
                                Your credit card information is encrypted, saved and held on file by STRIPE.
                        </Typography>
                            <Typography variant="subtitle2" className={classes.dialogContent}>
                                Your credit card will be automatically charged with each mailing.
                        </Typography>
                        </div>
                    </div>
                
                </DialogContent>
                {isLoading ?
                    <div className={classes.progressWrapper}>
                        <CircularProgress />
                    </div>
                    :
                    <DialogActions className={classes.dialogActions}>
                        <div className={classes.portletFooter}>
                            <div className={classes.footerButtons}>
                                <div className={classes.footerButtonsLeftRight}>
                                    <Button
                                        variant="outlined"
                                        onClick={event => closeCCDialogFn()}
                                        color="default"
                                    >
                                        Cancel
                </Button>
                                </div>
                                <div className={classes.footerButtonsCenter}>
                                    <Button
                                        variant="contained"
                                        onClick={event => openDefaultCardDialog()}
                                        color="primary"
                                        disabled={!isCreditCardInfoValid}
                                    >
                                        Add Card
                </Button>
                                </div>
                                <div className={classes.footerButtonsLeftRight}></div>
                            </div>
                        </div>
                    </DialogActions>
                }
            </Dialog>
        </div>
    );
}

export default compose(
    withSnackbar
)(AddNewCreditCardDialog);
