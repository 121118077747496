import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { TextField, MenuItem, Button, Typography, InputAdornment, IconButton, Tooltip } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import classNames from 'classnames';
import MailingHeaderAdvanceFilterBox from './MailingHeaderAdvanceFilterBox';
// import { MailingHeaderGroupFilterBox } from './Groups/MailingHeaderGroupFilterBox';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
    root: {
        //padding: '2px 4px',
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        justifyContent: 'flex-end',
        paddingBottom: theme.spacing(1.5),
    },
    filterBySelect: {
        //width: '22%',
        //paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1)
    },
    searchBox: {
        //width: '40%',
        //paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(1)
    },
    resetButton: {
        marginTop: theme.spacing(1),
        //width: '38%',
        //paddingLeft: theme.spacing(1),
        //paddingRight: theme.spacing(1)
    },
    textField: {
        width: '100%'
    },
    redColorResetButton: {
        color: '#b82525',
        border: '#b82525',
        marginTop: '4px',
        cursor: 'pointer',
        fontWeight: 500,
        //color: 'white',
        '&:hover': {
            color: '#b82525',
            border: '#b82525',
            textDecoration: 'underline'
            //color: 'white',
        }
    },
    resetButtonMarginLeft: {
        marginLeft: theme.spacing(0.5)
    },
    // filterByBoxExtraWidth: {
    //     width: '113%'
    // }
    searchButton: {
        padding: '8px 8px',
        minWidth: '32px'
    },
    startIconButton: {
        padding: 0,
        margin: 0
    },
    flashButton: {
        animation: "$new-blinker-sb 2s linear 20",
        animationDuration: '2s',
        '&:hover': {
            animation: '0',
            WebkitAnimation: 0
        }
    },
    "@keyframes new-blinker-sb ": {
        "85%": {
            opacity: 0
        }
    },
}));

export const MailingHeaderSearchBox = (props) => {
    const classes = useStyles();
    const filterByOption = [
        { id: 'all', label: 'Total' },
        { id: 'active', label: 'Unblocked' },
        { id: 'blocked', label: 'Blocked' },
        { id: 'delete', label: 'Deleted' },
        { id: 'duplicateMailingAddress', label: 'Duplicate Mailing Addresses' },
        { id: 'homeValuation', label: 'Home Valuations' }
    ];
    const { onSearchChange, onResetClick, onFilterClick, hoSearch, hoFilter, showDeleteFilter, showDuplicateMailingAddressFilter, countHOMap } = props;
    const [filterValue, setFilterValue] = useState("active");
    const [searchValue, setSearchValue] = useState("");
    const [inputWidth, setInputWidth] = useState('300');

    useEffect(() => {
        setSearchValue(hoSearch);
    }, [hoSearch]);

    useEffect(() => {
        setFilterValue(hoFilter);
    }, [hoFilter]);

    const handleLocalFilterClick = (event) => {
        setFilterValue(event.target.value);
        setTimeout(() => {
            onFilterClick(event);
        }, 200);
    };

    const handleLocalSearchValue = (event) => {
        setTimeout(() => {
            onSearchChange(searchValue);
        }, 200);
    };

    const handleLocalReset = (event) => {
        setFilterValue('active');
        setSearchValue('');
        setTimeout(() => {
            onResetClick(event);
        }, 200);
    };

    const getFilterByOptionLabel = () => {
        const option = filterByOption.find(option => option.id === hoFilter);
        return option ? (option.label === 'Total' ? 'All' : option.label) : '';
    }

    return (
        <div>
            <div className={classes.root}>
                {/* <MailingHeaderGroupFilterBox /> */}
                <div className={classes.filterBySelect}>
                    <TextField
                        id="outlined-select-filterby"
                        select
                        label="Filter By"
                        placeholder="Filter By"
                        className={classNames(classes.textField, classes.filterByBoxExtraWidth)}
                        fullWidth
                        value={filterValue}
                        onChange={event => handleLocalFilterClick(event)}
                        margin="dense"
                        SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        variant="outlined"
                    >
                        {filterByOption.map(option => (
                            //hide delete for other agent
                            (((option.id === 'delete' && !showDeleteFilter) || (option.id === 'duplicateMailingAddress' && !showDuplicateMailingAddressFilter)) ? '' :
                                <MenuItem key={option.id} value={option.id}>
                                    {option.label} ({countHOMap[option.id]})
                                </MenuItem>
                            )
                        ))}
                    </TextField>
                </div>
                <div className={classes.searchBox}>
                    <TextField
                        id="standard-name"
                        label="Search Mailing List"
                        placeholder="Search Mailing List"
                        className={classes.textField}
                        value={searchValue}
                        onChange={event => { setSearchValue(event.target.value) }}
                        variant="outlined"
                        margin="dense"
                    />
                    <div style={{ marginBottom: '-19px' }}>
                        <MailingHeaderAdvanceFilterBox {...props} currentHOFilterLabel={getFilterByOptionLabel()} />
                    </div>
                </div>
                <div className={classes.resetButton}>
                    <Tooltip title="Search anything in MailPro">
                        <Button
                            onClick={event => handleLocalSearchValue(event)}
                            color="primary"
                            variant="contained"
                            className={classes.searchButton}
                            startIcon={<SearchIcon fontSize='small' />}
                            classes={{
                                startIcon: classes.startIconButton
                            }}
                        />
                    </Tooltip>
                    {/* <Button
                        onClick={event => handleLocalSearchValue(event)}
                        color="primary"
                        variant="contained"
                    >Search</Button> */}
                    {/* &nbsp; */}
                    {searchValue ?
                        <div
                            onClick={event => handleLocalReset(event)}
                            className={classNames(classes.redColorResetButton, classes.flashButton)}
                        //className={searchValue ? classNames(classes.redColor, classes.resetButtonMarginLeft) : classes.resetButtonMarginLeft}
                        >
                            Reset
                        </div>
                        : ''}
                </div>
            </div>
        </div>
    );
}
