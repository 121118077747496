import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { MailProDialog } from 'views/Pma/MailPro/components/MailProDialog/MailProDialog';
import { MailProDialogHeader } from 'views/Pma/MailPro/components/MailProDialog/MailProDialogHeader';
import { MailProDialogBody } from 'views/Pma/MailPro/components/MailProDialog/MailProDialogBody';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        fontFamily: "objektiv-mk2 !important",
    },
    backDropBackgroundDark: {
        background: "rgba(0, 0, 0, 0.8)",
    },
    objectivMk2: {
        fontFamily: "objektiv-mk2 !important",
    },
    connfirmationDialogPaper: {
        minWidth: '600px'
    },
    connfirmationDialogHeader: {
        fontSize: '15px',
        fontFamily: "objektiv-mk2",
        lineHeight: "18px",
        letterSpacing: "0.01em",
        fontWeight: 500,
        color: 'white',
        textAlign: 'center'
    },
    margin: {
        margin: theme.spacing(1),
    },
    footerButtons: {
        position: 'relative',
        display: 'flex',
    },
    portletFooter: {
        display: 'inline-block',
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '100%'
    },
    footerButtonsCenter: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    footerButtonsLeftRight: {
        flex: 1,
    },
}));

export default function CommonConfirmationDialogV2({ title, subtitle, methodToCallOnConfirmation, methodToCallOnCancel, confirmationButtonText, cancelButtonText, className, children, headerClass = {} }) {

    const [open, setOpen] = React.useState(false);

    /**classes */
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmationClick = () => {
        /**call the close method first */
        handleClose();
        methodToCallOnConfirmation && methodToCallOnConfirmation();
    };

    const handleCancelClick = () => {
        /**call the close method first */
        handleClose();
        methodToCallOnCancel && methodToCallOnCancel();
    };

    return (
        <div>
            <div onClick={() => setOpen(true)} className={classes.objectivMk2}>{children}</div>
            <MailProDialog
                open={open}
                classes={{
                    paper: classes.connfirmationDialogPaper
                }}
                className={className || {}}
                BackdropProps={{
                    classes: {
                        root: classes.backDropBackgroundDark,//backDropBackgroundLightBlue,
                    },
                }}
                disableEnforceFocus>
                <MailProDialogHeader>
                    <div className={classNames(classes.connfirmationDialogHeader, headerClass)}>{title}</div>
                </MailProDialogHeader>
                <MailProDialogBody>
                    {subtitle}
                </MailProDialogBody>
                <DialogActions>
                    <div className={classes.portletFooter}>
                        <div className={classes.footerButtons}>
                            <div className={classes.footerButtonsLeftRight}>
                                <Button
                                    variant="outlined"
                                    onClick={handleCancelClick}
                                    color="default"
                                    className={classes.objectivMk2}
                                    size='small'
                                >
                                    {cancelButtonText ? cancelButtonText : 'Cancel'}
                                </Button>
                            </div>
                            <div className={classes.footerButtonsCenter}>
                                <Button
                                    variant="contained"
                                    onClick={handleConfirmationClick}//in handle close we are passing confirmation method
                                    color="primary"
                                    className={classes.objectivMk2}
                                    size='small'
                                >
                                    {confirmationButtonText ? confirmationButtonText : 'Yes, Proceed'}
                                </Button>
                            </div>
                            <div className={classes.footerButtonsLeftRight}></div>
                        </div>
                    </div>
                </DialogActions>
            </MailProDialog>
        </div>
    );
}
