import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import compose from 'recompose/compose';
import { withSnackbar } from 'notistack';
import { showErrorNotification } from 'common/helpers';
import { CircularProgress } from '@material-ui/core';
import { ChargeAgentCreditCardAPI } from 'services/agent';
import { showSuccessNotification } from 'common/helpers';
import { systemLogRequest } from 'common/helpers';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    footerButtons: {
        position: 'relative',
        display: 'flex',
    },
    portletFooter: {
        display: 'inline-block',
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '100%'
    },
    footerButtonsCenter: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    footerButtonsLeftRight: {
        flex: 1,
    },
    progressWrapper: {
        paddingTop: '8px',
        paddingBottom: '8px',
        display: 'flex',
        justifyContent: 'center'
    },
}));
const ChargeCreditCardDialog = (props) => {

    const { chargeCurrency, listgenUserID, customerID, chargeCardID, closeFn } = props;
    const [open, setOpen] = React.useState(true);
    const [chargeAmount, setChargeAmount] = React.useState(null);
    const [values, setValues] = React.useState({
        amount: ''
    });
    /**loading variables */
    const [isLoading, setIsLoading] = useState(false);
    /**classes */
    const classes = useStyles();

    const handleClose = () => {
        //setOpen(false);
        closeFn();
    };

    const handleChange = prop => event => {
        setChargeAmount(event.target.value);
    };


    const chargeAgentCreditCard = () => {
        /**get the charge data */
        /**make loading true */
        setIsLoading(true);
        try {
            ChargeAgentCreditCardAPI(listgenUserID, customerID, chargeCardID, chargeAmount, chargeCurrency)
                .then(data => {
                    if (data) {
                        showSuccessNotification(props, "Agent card charged.");
                        setIsLoading(false);
                        //agent charged so close the dialog
                        handleClose();
                    } else {
                        //assign empty data
                        setIsLoading(false);
                        systemLogRequest('credit_card', 107, {}, data);
                        showErrorNotification(props, "Unable to process request. Error Code: 107");
                    }
                })
                .catch(err => {
                    systemLogRequest('credit_card', 108, err);
                    showErrorNotification(props, "Unable to process request. Error Code: 108");
                    setIsLoading(false);
                });
        } catch (err) {
            systemLogRequest('credit_card', 109, err);
            showErrorNotification(props, "Unable to process request. Error Code: 109");
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle id="cc-alert-dialog-title">Enter the amount that you'd like to charge</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <FormControl fullWidth className={classes.margin} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-amount">Enter Amount</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                value={chargeAmount}
                                onChange={handleChange()}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                labelWidth={85}
                            />
                        </FormControl>
                    </DialogContentText>
                </DialogContent>
                {isLoading ?
                    <div className={classes.progressWrapper}>
                        <CircularProgress />
                    </div>
                    :
                    <DialogActions>
                        <div className={classes.portletFooter}>
                            <div className={classes.footerButtons}>
                                <div className={classes.footerButtonsLeftRight}>
                                    <Button
                                        variant="outlined"
                                        onClick={event => handleClose()}
                                        color="default"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div className={classes.footerButtonsCenter}>
                                    <Button
                                        variant="contained"
                                        onClick={event => chargeAgentCreditCard(event)}
                                        color="primary"
                                        disabled={!(Number(chargeAmount) > 0)}
                                    >
                                        Charge Amount
                                    </Button>
                                </div>
                                <div className={classes.footerButtonsLeftRight}></div>
                            </div>
                        </div>
                    </DialogActions>
                }
            </Dialog>
        </div>
    );
}

export default compose(
    withSnackbar
)(ChargeCreditCardDialog);
