import React, { useEffect, useRef, useState, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
    Typography,
    Grid,
    FormControl,
    InputAdornment,
    Input,
    TextField,
    CircularProgress,
} from "@material-ui/core";
import classNames from "classnames";
import styles from "../MailPro/components/styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import ClearIcon from "@material-ui/icons/Clear";
import { GetStateStorage } from "common/storage";
import { SubmitHelpRequestAPI } from "services/others";
import { showSuccessNotification } from "common/helpers";
import { showErrorNotification } from "common/helpers";
import { withSnackbar } from "notistack";

const PMAIssueReportsDialog = (props) => {
    /**get classes */
    const {
        classes,
        handlePMAIssuesDialog,
        currentArea
    } = props;

    console.log(currentArea);

    const [isLoading, setLoading] = useState(false);
    const [issueList, setIssueList] = useState({});

    const firstInputRef = useRef();
    /**set focus to first element */
    useEffect(() => {
        setTimeout(() => {
            firstInputRef.current.focus();
        }, 100);
    }, []);

    const handleClose = () => {
        handlePMAIssuesDialog(false);
    };

    const setTextfiledValue = (value, key) => {
        let xData = Object.assign({}, issueList);
        if (!xData[key]) {
            xData[key] = '';
        }
        xData[key] = value;
        setIssueList(xData);
    };

    const disableButton = () => {
        return Object.values(issueList).filter(item => item !== '').length === 0;
    };

    const getLocalData = () => {
        let data = {};
        let localStateData = GetStateStorage();
        data = localStateData && localStateData.userData ? localStateData.userData : {};
        return data;
    }

    const subjectBuilder = () => {
        let data = getLocalData();
        const { brokerageName, listgenUserFirstName, listgenUserLastName } = data;
        const nameString = `${listgenUserFirstName ? `${listgenUserFirstName}${listgenUserLastName ? ` ${listgenUserLastName}` : ``}` : ``}`;
        return `${nameString}${brokerageName ? ` | ${brokerageName}` : ``}`;
    }

    const handleSubmit = () => {
        /**make loading true */
        setLoading(true);
        try {
            let storageData = GetStateStorage();
            let { listgenUserFirstName, listgenUserLastName, emailAddress, mobilePhoneNo, listgenUserID } = storageData.userData;
            let agentName = `${listgenUserFirstName}${listgenUserLastName ? ` ${listgenUserLastName}` : ''}`;
            let message = Object.values(issueList).filter(item => item !== '').join(', ');
            //"I have found issues in my PMA"
            SubmitHelpRequestAPI(
                listgenUserID, agentName, emailAddress, mobilePhoneNo, subjectBuilder(), message, 'request', currentArea.mlsCityName, currentArea.mlsNeighborhood).then(data => {
                    switch (data) {
                        case true:
                            showSuccessNotification(props, "Request submitted.");
                            break;
                        case false:
                            showErrorNotification(props, "Some unexpected error occured.");
                            break;
                        default:
                            showErrorNotification(props, "Something went wrong. Please try after some time.");
                            break;
                    }
                    setLoading(false);
                    handleClose();
                }).catch(err => {
                    showErrorNotification(props, "Unable to process request.");
                    setLoading(false);
                });
        } catch (err) {
            showErrorNotification(props, "Unable to process request.");
            setLoading(false);
        }
    };


    let numberOfLines = [0, 1, 2, 3, 4];

    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="approval-mailing-list-dialog"
                aria-describedby="approval-mailing-list-dialog"
                maxWidth={"md"}
                disableBackdropClick={true}
            >
                <DialogTitle id="scroll-dialog-title">
                    <div className={classes.propertyFactHeader}>
                        <div></div>
                        <div>
                            <Typography
                                variant="body1"
                                align="center"
                                className={classNames(classes.lightBoldText, classes.pmaIssues16pxFont)}
                            >
                                Please list any issues you have with this PMA.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="center"
                                className={classNames(classes.lightBoldText, classes.pmaIssues16pxFont)}
                            >
                                Our support team will respond as quickly as possible!
                            </Typography>
                        </div>
                        <div onClick={handleClose} className={classes.closeModalBtn}>
                            <Button variant="contained" size="small" className={classes.closeredButton}>
                                <ClearIcon className={classes.whiteColor} />
                            </Button>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className={classes.hoIssuesGrid}>
                        <Grid container>
                            {numberOfLines.map((item, key) => {
                                return (
                                    <Grid item lg={12} key={key}>
                                        <TextField
                                            id={`pma-issues-input-${key}`}
                                            className={classNames((key > 0 ? classes.fieldMarginTop : ''), classes.textField)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">{key + 1}.</InputAdornment>
                                                ),
                                            }}
                                            inputRef={key === 0 ? firstInputRef : undefined}
                                            key={key}
                                            onChange={event => setTextfiledValue(event.target.value, key)}
                                            inputProps={{ autoComplete: 'off' }}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </div>
                    <Fragment>
                        {isLoading ? (
                            <div className={classNames(classes.fullWidth, classes.cProgressStopBlinking)}>
                                <CircularProgress className={classes.cProgress} />
                            </div>
                        ) : (
                            <div
                                className={classNames(classes.portletFooter, classes.approvalFooter)}
                            >
                                <div className={classes.footerButtons}>
                                    <div className={classes.footerButtonsLeftRight}>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            onClick={(event) => handleClose()}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                    <div className={classes.footerButtonsCenter}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.paleBlueButton}
                                            onClick={(event) => handleSubmit()}
                                            disabled={disableButton()}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                    <div className={classes.footerButtonsLeftRight}></div>
                                </div>
                            </div>
                        )}
                    </Fragment>
                </DialogContent>
            </Dialog>
        </div>
    );
};

PMAIssueReportsDialog.propTypes = {
    approveMailingListFn: PropTypes.func.isRequired,
    continueEditingModeFn: PropTypes.func.isRequired,
    //mlsAreas: PropTypes.array.isRequired,
    //areaName: PropTypes.string.isRequired,
};

PMAIssueReportsDialog.defaultProps = {
    approveMailingListFn: () => { },
    continueEditingModeFn: () => { },
    //mlsAreasa: [],
    //areaName: null
};

export default compose(
    withStyles(styles),
    withSnackbar,
)(PMAIssueReportsDialog);
