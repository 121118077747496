import { cyan, orange, red, blueGrey, indigo, green } from '@material-ui/core/colors';

export default theme => ({
  root: {
    width: 'auto',
    minHeight: '95vh',
    height: 'auto',
    //padding: theme.spacing.unit * 3,
    marginTop: theme.spacing(8),
    //backgroundColor: theme.palette.background.default,
    //color: theme.palette.common.white,

    //backgroundRepeat: 'no-repeat',
    //backgroundPosition: 'center',
    background: 'url(./images/ListGen_Web_Banner-min.jpg)',
    backgroundSize: '100% 100%',
    width: '100%',
    height: 'calc(100vh - 64px)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  // bgBackground: {
  //   background: 'url(./images/ListGen_Web_Banner-min.jpg)',
  //   backgroundSize: '100% 100%',
  //   width: '100%',
  //   height: 'calc(100vh - 64px)',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundPosition: 'center',
  // },
  // backgroundGradient: {
  //   // background:
  //   //   'linear-gradient(45deg, rgba(42,27,161,0.7), rgba(29,210,177,0.7) 100%)',
  // },
  // grid: {
  //   //height: '100%',
  // },
  homeImageHeader: {
    //backgroundColor: theme.palette.common.neutral,
    height: 'calc(100vh - 64px)',
    // width: 'calc(100vw)',
    // display: 'flex',
    // justifyContent: 'left',
    // alignItems: 'left',
    // height: 'calc(100vh - 64px)',
  },
  videoModal: {
    position: 'absolute',
    left: '50vh',
    top: '30vh',
    width: 800,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    height: theme.spacing(20),
    outline: 'none',
  },
  closeBtn: {
    color: theme.palette.error.dark,
    textAlign: 'right',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  modalTitle: {
    textAlign: 'center',
  },
  whiteColor: {
    color: theme.palette.common.white,
  },
  blackColor: {
    color: theme.palette.common.black,
  },
  textOverImage: {
    //marginTop: theme.spacing.unit * 2,
    display: 'flex',
    position: 'relative',
    backgroundColor: 'inherit',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    width: '100%',
    height: '100%',
    //marginTop: -theme.spacing(14),
    //marginBottom: theme.spacing(8),
    //marginLeft: -theme.spacing(4)
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  textOIHeading: {
    visibility: 'visible',
    animationName: 'fadeInLeft',
    animationDelay: '0.3s',
    color: theme.palette.common.white,
  },
  textOIDescription: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    lineHeight: '20px',
  },
  textOIButtons: {
    height: '75%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    '&>button': {
      marginRight: theme.spacing(4),
    },
  },
  textOIButtonBorder: {
    border: '2px solid white',
  },
  whiteBorderButton: {
    color: 'white',
    backgroundColor: 'transparent',
    border: '1px solid white',
    boxShadow: 'none',
    fontSize: '18px',
    fontWeight: '400',
    wordSpacing: '2px',
    letterSpacing: '4px',
    '&:hover': {
      backgroundColor: 'white',//'#f0efef',
      color: '#000000'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      fontWeight: '400',
      wordSpacing: '0px',
      letterSpacing: '1px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      fontWeight: '300',
      wordSpacing: '0px',
      letterSpacing: '0.5px',
    }
  },
  whiteBorderButtonSmall: {
    fontSize: '14px',
    letterSpacing: '2px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      fontWeight: '400',
      wordSpacing: '0px',
      letterSpacing: '1px',
    }
  },
  greenBgButton: {
    backgroundColor: green[500],
    fontWeight: '500',
    border: `1px solid ${green[500]}`,
    '&:hover': {
      border: 'inherit'
    }
  },
  gridButtonDesign: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  firstGridWidth: {
    width: '23%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  secondGridWidth: {
    width: '49%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  thirdGridWidth: {
    width: '28%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  gridInnerButtonDesign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '60%'//'40%'
  },
  gridDisplay: {
    minHeight: '40vh',
    height: 'auto',
    //display: 'flex',
    //justifyContent: 'center',
    //alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    padding: theme.spacing(4),
  },
  gridColorOne: {
    backgroundColor: '#7E57C2',
  },
  gridColorTwo: {
    backgroundColor: '#AB47BC',
  },
  gridColorThird: {
    backgroundColor: '#25A59A',
  },
  gridColorFourth: {
    backgroundColor: '#28B6F6',
  },
  gridIcon: {
    width: '64px',
    height: '64px',
  },
  gridTitleMargin: {
    marginTop: theme.spacing(1),
  },
  gridDescriptionMargin: {
    marginTop: theme.spacing(4),
    //marginBottom: theme.spacing(2)
  },
  WWWUsGrid: {
    background: '#f5f5f5',
    //minHeight: '50vh',
    height: 'auto',
    textAlign: 'center',
    padding: theme.spacing(8),
  },
  WWWUsPadding: {
    padding: theme.spacing(8),
  },
  iconOneColor: {
    color: orange[500],
  },
  iconTwoColor: {
    color: cyan[500],
  },
  iconThirdColor: {
    color: red[500],
  },
  newText: {
    color: red[500],
    border: `2px solid ${red[500]}`,
    borderRadius: `4px`,
    fontWeight: 800,
    fontSize: '12px',
    padding: '1px 2px',
    animation: "$new-blinker 3s linear 3",
    animationDuration: '3s',
    letterSpacing: '1px'
  },
  newTextWithoutBorder: {
    color: red[500],
    fontWeight: 800,
    fontSize: '12px',
    padding: '1px 2px',
    animation: "$new-blinker 1s linear 10",
    animationDuration: '3s',
    letterSpacing: '1px'
  },
  "@keyframes new-blinker": {
    "85%": {
      opacity: 0
    }
  },
  iconFourthColor: {
    color: indigo[500],
  },
  blueGrayColor: {
    color: blueGrey[400],
  },
  BPGrid: {
    background: theme.palette.common.white,
    height: 'auto',
    textAlign: 'center',
    padding: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  },
  BPPadding: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  BpGridContainerMargin: {
    marginTop: theme.spacing(4),
  },
  BBHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  BBHolderInner: {
    width: '80%',
  },
  BPImageCardHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  BPImageCard: {
    width: '80%',
    height: '50%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  BPImage: {
    width: '100%',
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    marginBottom: -theme.spacing(1),
  },
  BBIconButton: {
    padding: theme.spacing(0),
  },
  BBIcon: {
    width: '32px',
    height: '32px',
    verticalAlign: 'top',
  },
  BBTextContainer: {
    textAlign: 'left',
  },
  BPFlexColumnGrid: {
    display: 'flex',
    flexDirection: 'column',
  },
  BPGridMarginTop: {
    marginTop: theme.spacing(3),
  },
  homeFooter: {
    background: '#2F3F9F',
    height: '8vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      height: '8vh',
    },
    [theme.breakpoints.down('md')]: {
      height: '10vh',
    },
  },
  pmaFragment: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cutOffMargin: {
    top: 0,
    marginBottom: theme.spacing(6),
    '&>p': {
      fontSize: '18px'
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
      '&>p': {
        fontSize: '14px'
      },
    },
    color: 'white',//red[200],
    //border: `1px solid ${red[200]}`,
    //borderRadius: '4px',
    padding: '2px'
  },
  newHOSection: {
    top: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&>p': {
      fontSize: '18px'
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
      '&>p': {
        fontSize: '14px'
      },
    },
    color: red[200],
    border: `1px solid ${red[200]}`,
    borderRadius: '4px',
    padding: '2px',
    cursor: 'pointer',
    '&:hover': {
      background: red[50]
    }
  },
  useChromeSection: {
    top: theme.spacing(7),
    position: 'absolute'
  },
  notChromeCard: {
    padding: theme.spacing(2)
  },
  pmaFragmentButtons: {
    display: 'inherit',
    flexDirection: 'column',
    alignItems: 'center'
  },
  areaAvailable: {
    marginTop: theme.spacing(1),
    //background: 'white',
    padding: '1px',
    color: 'white',
    height: '24px'
  },
  flashButton: {
    animation: "$new-blinker 2s linear 20",
    animationDuration: '2s',
    '&:hover': {
      animation: '0',
      WebkitAnimation: 0
    }
  },
  closeMenuButton : {
    background: red[400],
    color: 'white',
    fontSize: '500',
    cursor: 'pointer',
    border: `1px solid ${red[400]}`,
    padding: '2px',
    borderRadius: '4px'
  },
  fabBottomMargin: {
    marginBottom: '16px'
  },
  incompleteDetailsOrRedButton: {
    background: red[500],
    color: 'white'
  }
});
