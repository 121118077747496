import { red } from '@material-ui/core/colors';

export default theme => ({
  width100: {
    width: '100%'
  },
  displayFlex: {
    display: 'flex',
  },
  justifyContentSbtw: {
    justifyContent: 'space-between'
  },
  justifyContentEnd: {
    justifyContent: 'flex-end'
  },
  justifyContentCenter: {
    justifyContent: 'center'
  },
  flexDirectionRow: {
    flexDirection: 'row',
  },
  flexDirectionColumn: {
    flexDirection: 'column',
  },
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  positionFixed: {
    position: 'fixed'
  },
  whiteColor: {
    color: 'white',
  },
  redColor900: {
    color: red[900]
  },
  redBg900: {
    backgroundColor: red[900]
  },
  redBg500: {
    backgroundColor: red[500],
  },
  redBgHover500: {
    '&:hover': {
      backgroundColor: red[500],
    }
  },
  textAlignC: {
    textAlign: 'center'
  },
  bold500: {
    fontWeight: 500
  },
  bold800: {
    fontWeight: 800
  },
  closeModalBtn: {
    margin: theme.spacing(-1.5, -2.5, 1, 2),
  },
  closeredButton: {
    minWidth: theme.spacing(2.5),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: red[900],
      color: 'white',
    }
  },
  mediaImageHolder: {
    width: 'auto',
    maxWidth: '960px',//md width
    objectFit: 'cover',
  },
  mediaImage: {
    maxWidth: '100%'
  }
});
