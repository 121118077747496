import React, { Component, Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Input,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from "@material-ui/core";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "./styles";
import { withSnackbar } from "notistack";
import classNames from "classnames";
import ClearIcon from "@material-ui/icons/Clear";
import { showSuccessNotification } from "common/helpers";
import { showErrorNotification } from "common/helpers";
import { AddUpdatePmaAdmin } from "services/agent";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const HighlightTitleDialog = (props) => {
  const {
    representedOptions,
    editPropFormData,
    handleInputChange,
    openUploadMedia,
    onCloseFn,
    handleAddTitle,
    propertyHighlightsTitle,
    propertyHighlightsSubtitle,
    handleTitleChange,
    onHighlightTitleClose,
    oldTitle,
    maxHighlightTitleLength,
    maxHighlightSubtitleLength,
  } = props;

  let overMaxTitle =
    propertyHighlightsTitle &&
    propertyHighlightsTitle.length &&
    propertyHighlightsTitle.length > maxHighlightTitleLength;
  let overMaxSubtitle =
    propertyHighlightsSubtitle &&
    propertyHighlightsSubtitle.length &&
    propertyHighlightsSubtitle.length > maxHighlightSubtitleLength;
  return (
    <Fragment>
      <Dialog
        open={true}
        //onClose={onCloseFn}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth={true}
        TransitionComponent={Transition}
      >
        <div className="edit-property-container">
          <div className="edit-property-title-container">
            <p className="edit-property-title">
              Edit Property Highlight Title and Subtitle
            </p>
            <div className="edit-property-title-divider" />
            <div className="edit-property-subtitle-text column-text">
              <p>
                You have the option to edit the Title and Subtitle OR you can
                leave them as they are.
              </p>
              <p className="click-submit-text">
                (click CANCEL if you want to leave them unchanged)
              </p>
            </div>
          </div>
          <div className="edit-property-form">
            <div className="edit-property-form-title-container">
              <p className="edit-property-form-subtitle-text mt-subtitle">
                Please <span>DOUBLE CHECK</span> each field for accuracy.
              </p>
              <div className="edit-property-form-title-divider"></div>
            </div>
            <div className="edit-property-form-body">
              <div className="title-dialog-body-col">
                <div className="title-column">
                  <div className="title-section">
                    <div className="old-text-container">
                      <p>
                        Current <span> Title: </span>{" "}
                      </p>
                      <p className="actual-title-text-1">
                        {oldTitle.propertyHighlightsTitle}
                      </p>
                    </div>
                    <input
                      className="extra-long-input"
                      type="text"
                      id="propertyHighlightsTitle"
                      name="propertyHighlightsTitle"
                      value={propertyHighlightsTitle || ""}
                      onChange={handleTitleChange}
                    ></input>
                    <label
                      htmlFor="propertyHighlightsTitle"
                      className={overMaxTitle ? "character-count-error" : ""}
                    >
                      Character count:{" "}
                      {propertyHighlightsTitle && propertyHighlightsTitle.length
                        ? propertyHighlightsTitle.length
                        : "0"}
                      /{maxHighlightTitleLength} Max
                    </label>
                  </div>
                  <div className="title-section">
                    <div className="old-text-container">
                      <p>
                        Current <span> Subtitle: </span>
                      </p>{" "}
                      <p className="actual-title-text">
                        {oldTitle.propertyHighlightsSubtitle}
                      </p>
                    </div>
                    <textarea
                      className="extra-long-input textbox-subtitle"
                      //type="text"
                      id="propertyHighlightsSubtitle"
                      name="propertyHighlightsSubtitle"
                      value={propertyHighlightsSubtitle || ""}
                      onChange={handleTitleChange}
                    ></textarea>
                    <br />
                    <label
                      htmlFor="propertyHighlightsTitle"
                      className={overMaxSubtitle ? "character-count-error" : ""}
                    >
                      Character count:{" "}
                      {propertyHighlightsSubtitle &&
                      propertyHighlightsSubtitle.length
                        ? propertyHighlightsSubtitle.length
                        : "0"}
                      /{maxHighlightSubtitleLength} Max
                    </label>
                  </div>

                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div className="edit-form-button-container">
            <div
              className="edit-form-cancel-button"
              onClick={onHighlightTitleClose}
            >
              <p>Cancel</p>
            </div>
            <div
              className="edit-form-submit-button"
              onClick={() =>
                handleAddTitle(overMaxTitle, overMaxSubtitle, false)
              }
            >
              <p>Submit For All Areas</p>
            </div>
            <div
              className="edit-form-submit-button"
              onClick={() =>
                handleAddTitle(overMaxTitle, overMaxSubtitle, true)
              }
            >
              <p>Submit For This Area Only</p>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default compose(withSnackbar, withStyles(styles))(HighlightTitleDialog);
