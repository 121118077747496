import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { compose } from 'recompose';
import styles from "./styles";
import { AgentUpdateDetailsComponent } from './component';
import { GetAgentDetail } from 'services/agent';
import _ from 'lodash';
import { GetAllDesignationsData } from 'services/others';
import Alert from '@material-ui/lab/Alert';
import { GetStateStorage, IsValidSessionSwitch } from 'common/storage';
import { isAFaultyPage } from 'common/helpers';
import { isThisSecond } from 'date-fns/esm';
import DocumentTitle from 'react-document-title';

class AgentDetails extends Component {
  constructor(props) {
    super(props);
    const state = {
      listgenUserID: null,
      agentData: {},
      designationData: [],
      statusData: [
        {
          label: 'Active',
          value: 'active'
        },
        {
          label: 'Inactive',
          value: 'inActive'
        }
      ],
      faultyPage: false
    };
    /**bind methods */
    this.getAgentPreviousData = this.getAgentPreviousData.bind(this);
    /////Check for faulty page also

    this.state = state;
  }


  getAgentPreviousData = () => {
    /**make loading true */
    try {
      GetAgentDetail(this.state.listgenUserID)
        .then(data => {
          if (Object.keys(data).length > 0) {
            this.setState((prevState, props) => {
              return {
                agentData: data
              }
            });
          } else {
            this.setState({
              agentData: {},
            });
          }
        })
        .catch(err => {
          //showErrorNotification(this.props, "Unable to process request.");
          this.setState({
            agentData: {},
          });
        });
    } catch (err) {
      //showErrorNotification(this.props, "Unable to process request.");
      this.setState({
        agentData: {},
      });
    }
  };

  componentDidMount() {
    //check for faulty data also
    try {
      const listgenParamsUserID = atob(this.props.match.params.listgenUserID);
      //validate for the faulty page also
      const { userData } = GetStateStorage();
      const listgenRoleID = userData.listgenRoleID;
      const listgenStorageUserID = userData.listgenUserID;
      ///Check for faulty page. if its the faulty page then redirect to dashbaord. In case of error faulty page will redirec to other route///Check for faulty page. if its the faulty page then redirect to dashbaord. In case of error faulty page will redirec to other route
      isAFaultyPage(listgenRoleID, listgenStorageUserID, listgenParamsUserID).then(result => {
        this.setState({
          listgenUserID: listgenParamsUserID,
          faultyPage: false
        }, () => {
          /**call api */
          this.getAgentPreviousData();
          /**ge the designation data */
          this.getAllDesignationsData();
        });

      });
    } catch (err) {
      //this.state = state;
      this.setState({
        faultyPage: true
      });
    }
  };

  /**new methods */
  makeDeisgnationDataSequence = (designationDataArray) => {
    /**first get the Realtor from list */
    let returnData = [];
    let xData = designationDataArray.find(item => item.listgenDesignationName === 'Realtor');
    if (xData) {
      returnData.push(xData);
    }
    /**Now Broker Associate */
    xData = designationDataArray.find(item => item.listgenDesignationName === 'Broker Associate');
    if (xData) {
      returnData.push(xData);
    }
    /**now short remaining in alphabetical order */
    designationDataArray.sort(function (a, b) {
      if (a.listgenDesignationName < b.listgenDesignationName) { return -1; }
      if (a.listgenDesignationName > b.listgenDesignationName) { return 1; }
      return 0;
    });
    /**now push to return data */
    designationDataArray.forEach(item => {
      if (item.listgenDesignationName !== 'Realtor' && item.listgenDesignationName !== 'Broker Associate') {
        returnData.push(item);
      }
    });
    return returnData;
  };

  getAllDesignationsData = () => {
    /**make loading true */
    try {
      GetAllDesignationsData()
        .then(data => {
          if (data.length > 0) {
            this.setState((prevState, props) => {
              return {
                designationData: this.makeDeisgnationDataSequence(data)
              };
            });
          } else {
            this.setState({
              designationData: []
            });
          }
        })
        .catch(err => {
          //showErrorNotification(this.props, "Unable to process request.");
          this.setState({
            designationData: []
          });
        });
    } catch (err) {
      //showErrorNotification(this.props, "Unable to process request.");
      this.setState({
        designationData: []
      });
    }
  };


  render() {
    const { classes } = this.props;
    const { agentData, listgenUserID, statusData, faultyPage } = this.state;
    return (
      <DocumentTitle title="Update Profile">
        <DashboardLayout title="Agent Details">
          <div className={classes.root}>
            <Grid container className={classes.grid} spacing={2}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}>
                {!faultyPage ? <AgentUpdateDetailsComponent
                  agentData={agentData}
                  listgenUserID={listgenUserID}
                  designationData={this.state.designationData}
                  statusData={statusData}>
                </AgentUpdateDetailsComponent>
                  :
                  <Alert variant="filled" severity="error" style={{ display: 'flex', alignItems: 'center' }}>
                    Something went wrong while fetching agent details. Please try after some time.
              </Alert>
                }
              </Grid>
            </Grid>
          </div>
        </DashboardLayout>
      </DocumentTitle>
    );
  }
}

AgentDetails.propTypes = {
  classes: PropTypes.object.isRequired
};
export default compose(
  withStyles(styles),
  withRouter,
)(AgentDetails);
