import React from "react";
import PmaEditor from "./PMAEditor";
import axios from "common/interceptors";

class PrintPMA extends React.Component {
  state = {
    singleHOData: null,
    agentData: null,
    mlsAreas: null,
    marketActivity: null,
    MQCityDetails: null,
    currentArea: null,
    localSellingAgents: null,
    version: "qv2",
    coverPageDetails: null,
    quarterlyAreaTable: null,
    testimonials: null,
    agentDesignations: null,
    agentAdvantages: null,
    salePriceForCities: null,
    yearEnd: null,
    qArea: null,
    qCity: null,
    missionStatement: null,
    aprilData: null,
    flipBook: false,
  };

  async componentDidMount() {
    let mailProId = this.props.match.params.mailproid;
    let listgenUserID = this.props.match.params.listgenUserID;
    let brokerage = this.props.match.params.brokerage;
    const { version } = this.state;
    let url = window.location.href;
    if (url.includes("test-print")) {
      listgenUserID = url[url.length - 2];
    }
    let date = new Date();
    let thisYear = date.getFullYear();
    const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
    const today = `${date.getDate()}`.padStart(2, 0);
    try {
      let mailPrint = await axios
        .get(
          `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
          {
            headers: {
              "content-type": "application/json",
            },
            data: {},
          }
        )
        .then((res) => {
          const singleHOData = res.data.mailerProDTO;
          let agentData = res.data.listgenUserDTO;
          if (listgenUserID === "103" || listgenUserID === "105") {
            agentData.brokerageName = brokerage;
          }
          this.setState({
            singleHOData,
            agentData,
          });
        })
        .catch((err) => console.log("error retrieving idDetails" + err));
    } catch (error) {
      console.log("error", error);
    }
    let navBar = await axios
      .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
        headers: {
          "content-type": "application/json",
        },
        data: {},
      })
      .then((res) => {
        this.setState({
          mlsAreas: res.data,
        });
      })
      .catch((err) => {
        console.log("error retrieving navbarInfo" + err);
      });
    const thisArea = this.state.singleHOData.areaID;
    if (version === "ye2") {
      const { singleHOData } = this.state;
      const currentArea = this.state.mlsAreas.filter(
        (area) => area.mlsAreaID === thisArea
      )[0];
      const { subdivisionName } = singleHOData;
      const { mlsCityID, mlsAreaID } = currentArea;
      let updatedID = "";
      let getPmaDetails = await axios
        .get(
          `/agent/getPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
            subdivisionName ? subdivisionName : "-"
          }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,

          {
            headers: {
              "content-type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            data: {},
          }
        )
        .then((res) => {
          console.log("inside market trends res.data", res.data);
          const {
            mailerPrintInfoDTO,
            lgTestmonialsDTO,
            missionStatementDTO,
            propertyPhotoDetailsDTO,
          } = res.data;
          const singleHOData = mailerPrintInfoDTO.mailerProDTO;
          const agentData = mailerPrintInfoDTO.listgenUserDTO;
          this.setState({
            singleHOData,
            agentData,
            currentArea,
            coverPageDetails: res.data.pmaCoverPhotoDTO,
            quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
            quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
            testimonials: lgTestmonialsDTO,
            propertyPhotoDetailsDTO: propertyPhotoDetailsDTO,
            aprilData: {
              pmaQuarterlyAreaDTO: res.data.pmaQuarterlyAreaDTO,
              pmaQuarterlyCityDTO: res.data.pmaQuarterlyCityDTO,
            },
            bubbleBoxes: res.data.marketValueInfoList,
            testimonials: res.data.lgTestmonialsDTO,
            missionStatement: missionStatementDTO,
          });
        })
        .catch((err) =>
          console.log("error retrieving table bubble data" + err)
        );
    } else if (version === "qv3") {
      let date = new Date();
      let thisYear = date.getFullYear();
      const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
      const today = `${date.getDate()}`.padStart(2, 0);
      try {
        const mailPrint = await axios.get(
          `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
          {
            headers: {
              "content-type": "application/json",
            },
            data: {},
          }
        );
        this.setState({
          singleHOData: mailPrint.data.mailerProDTO,
          agentData: mailPrint.data.listgenUserDTO,
        });
      } catch (error) {
        console.log("error", error);
      }
      let navBar = await axios
        .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
          headers: {
            "content-type": "application/json",
          },
          data: {},
        })
        .then((res) => {
          this.setState({
            mlsAreas: res.data,
          });
        })
        .catch((err) => {
          console.log("error retrieving navbarInfo" + err);
        });
      const thisArea = this.state.singleHOData.areaID;
      let areaAndDate = `${thisArea}-${thisYear}-${thisMonth}-${today}`;
      const currentArea = this.state.mlsAreas.filter(
        (area) => area.mlsAreaID === thisArea
      )[0];
      const { mlsCityID, mlsAreaID } = currentArea;
      const { singleHOData } = this.state;
      if (singleHOData) {
        const { subdivisionName } = singleHOData;
        let qv2Details = await axios
          .get(
            `/agent/getQuarterlyPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
              subdivisionName ? subdivisionName : "-"
            }`,
            {
              headers: {
                "content-type": "application/json",
              },
              params: {},
              data: {},
            }
          )
          .then((res) => {
            this.setState({
              qArea: res.data.pmaQuarterlyAreaDTO,
              qCity: res.data.pmaQuarterlyCityDTO,
              currentArea,
            });
          })
          .catch((err) => {
            console.log("error retrieving quarter area table" + err);
          });
        let midquarterPMADetails = await axios
          .get(
            `/agent/getMidQuarterlyPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
              subdivisionName ? subdivisionName : "-"
            }&listgenUserID=${listgenUserID}`,
            {
              headers: {
                "content-type": "application/json",
              },
              data: {},
            }
          )
          .then((res) => {
            this.setState({
              MQCityDetails: res.data.pmaMidQuarterlyAreaDTO,
            });
          })
          .catch((err) =>
            console.log("error retrieving MQCityDetails activity" + err)
          );
        let aprilPMA = await axios
          .get(
            `/agent/getAprilPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=-`,
            {
              headers: {
                "content-type": "application/json",
              },
              data: {},
            }
          )
          .then((res) => {
            this.setState({
              aprilData: res.data,
            });
          })
          .catch((err) =>
            console.log("error retrieving MQCityDetails activity" + err)
          );
      }
      let areaDetails = await axios
        .get(`/agent/getPMAQuarterlyAreaTableDetails`, {
          headers: {
            "content-type": "application/json",
          },
          params: {
            pMAQuarterlyAreaIDRunDate: areaAndDate,
          },
          data: {},
        })
        .then((res) => {
          this.setState({
            quarterlyAreaTable: res.data,
          });
        })
        .catch((err) => {
          console.log("error retrieving quarter area table" + err);
        });
      let salePriceForCities = await axios
        .get(
          `/agent/getAvgSalePriceForCitiesLast12Months?avgSalePricePeriod=Last12MonthsCityAverages-${2021}-${6}-${1}`,
          {
            headers: {
              "content-type": "application/json",
            },
            data: {},
          }
        )
        .then((res) => {
          this.setState({
            salePriceForCities: res.data,
          });
        })
        .catch((err) => {
          console.log("error retrieving navbarInfo" + err);
        });
      let cityDetails = await axios
        .get("/agent/getPMAQuarterlyCityTableDetails", {
          headers: {
            "content-type": "application/json",
          },
          params: {
            pMAQuarterlyCityIDRunDate: `${currentArea.mlsCityID}-2020-11-13`,
          },
          data: {},
        })
        .then((res) => {
          this.setState({
            quarterlyCityTable: res.data,
          });
        })
        .catch((err) =>
          console.log("error retrieving quarter city details" + err)
        );
      let replacedArea = mlsCityID;
      if (currentArea) {
        let coverPageDetails = await axios
          .get(
            `/admin/getPMACoverPhotoDetails?regionID=${replacedArea}&regionType=city`,
            {
              headers: {
                "content-type": "application/json",
              },
              data: {},
            }
          )
          .then((res) => {
            this.setState({
              coverPageDetails: res.data,
            });
          })
          .catch((err) =>
            console.log("error retrieving coverPageDetails activity" + err)
          );
        let marketActivity = await axios
          .get(
            `/agent/getPMAMidQuarterMarketActivity?areaIDPMAMidQuarterDailyRunDate=${currentArea.mlsAreaID}-2021-01-13`,
            {
              headers: {
                "content-type": "application/json",
              },
              data: {},
            }
          )
          .then((res) => {
            this.setState({
              marketActivity: res.data,
            });
          })
          .catch((err) =>
            console.log("error retrieving market activity" + err)
          );
      }
    } else if (thisArea === "DEMO-SCL-16N") {
      const currentArea = this.state.mlsAreas.filter(
        (area) => area.mlsAreaID === thisArea
      )[0];
      const { mlsAreaID, mlsCityID } = currentArea;
      const { singleHOData } = this.state;
      const { subdivisionName } = singleHOData;
      let coverPageDetails1 = await axios
        .get(
          `/agent/getTableDataWithBubbleDataPMA?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
            subdivisionName ? subdivisionName : "-"
          }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
          {
            headers: {
              "content-type": "application/json",
            },
            data: {},
          }
        )
        .then((res) => {
          const { mailerPrintInfoDTO } = res.data;
          const singleHOData = mailerPrintInfoDTO.mailerProDTO;
          const agentData = mailerPrintInfoDTO.listgenUserDTO;
          this.setState({
            version: "mq2",
            testimonials: res.data.lgTestmonialsDTO,
            coverPageDetails: res.data.pmaCoverPhotoDTO,
            quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
            quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
            currentArea,
            singleHOData,
            agentData,
          });
        })
        .catch((err) =>
          console.log("error retrieving table bubble data" + err)
        );
    } else if (thisArea === "DEMO-SCL-16D") {
      const { singleHOData } = this.state;
      const currentArea = this.state.mlsAreas.filter(
        (area) => area.mlsAreaID === thisArea
      )[0];
      const { subdivisionName } = singleHOData;
      const { mlsCityID, mlsAreaID } = currentArea;
      let updatedID = "";
      let dashBoardDonutApi = await axios
        .get(
          `/agent/getDashBoardDonutAndAvgSalePriceChart?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
            subdivisionName ? subdivisionName : "-"
          }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
          {
            headers: {
              "content-type": "application/json",
            },
            params: {},
            data: {},
          }
        )
        .then((res) => {
          const {
            navbarInfoList,
            pmaCoverPhotoDTO,
            mailerPrintInfoDTO,
            pmaQuarterlyAreaDTO,
            pmaQuarterlyCityDTO,
            missionStatementDTO,
          } = res.data;
          const singleHOData = mailerPrintInfoDTO.mailerProDTO;
          const agentData = mailerPrintInfoDTO.listgenUserDTO;
          this.setState({
            version: "qv2",
            singleHOData,
            agentData,
            currentArea,
            coverPageDetails: pmaCoverPhotoDTO,
            quarterlyAreaTable: pmaQuarterlyAreaDTO,
            aprilData: { pmaQuarterlyAreaDTO, pmaQuarterlyCityDTO },
          });
        })
        .catch((err) => {
          console.log("error retrieving may api" + err);
        });
    } else if (
      (version === "year-end-2022" && listgenUserID !== "103") ||
      thisArea === "DEMO-SCL-16A"
    ) {
      let date = new Date();
      let thisYear = date.getFullYear();
      const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
      const today = `${date.getDate()}`.padStart(2, 0);
      try {
        let mailPrint = await axios
          .get(
            `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
            {
              headers: {
                "content-type": "application/json",
              },
              data: {},
            }
          )
          .then((res) => {
            const singleHOData = res.data.mailerProDTO;
            const agentData = res.data.listgenUserDTO;
            this.setState({
              singleHOData,
              agentData,
            });
          })
          .catch((err) => console.log("error retrieving idDetails" + err));
      } catch (error) {
        console.log("error", error);
      }
      let navBar = await axios
        .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
          headers: {
            "content-type": "application/json",
          },
          data: {},
        })
        .then((res) => {
          this.setState({
            mlsAreas: res.data,
          });
        })
        .catch((err) => {
          console.log("error retrieving navbarInfo" + err);
        });
      const thisArea = this.state.singleHOData.areaID;
      let areaAndDate = `${thisArea}-${thisYear}-${thisMonth}-${today}`;
      const currentArea = this.state.mlsAreas.filter(
        (area) => area.mlsAreaID === thisArea
      )[0];
      const { mlsCityID, mlsAreaID } = currentArea;
      const cityAndDate = `${currentArea.mlsCityID}-${thisYear}-${thisMonth}-${today}`;
      const { singleHOData } = this.state;
      if (singleHOData) {
        const { subdivisionName, mailerProID } = singleHOData;
        let updatedID;
        if (listgenUserID === "100037" && mlsAreaID === "160SalesDemo") {
          updatedID = "160";
        } else if (listgenUserID === "100037" && mlsAreaID === "143SalesDemo") {
          updatedID = "143";
        } else if (listgenUserID === "100037" && mlsAreaID === "126SalesDemo") {
          updatedID = "126";
        } else {
          updatedID = mlsAreaID;
        }
        let decemberApi = await axios
          .get(
            `/agent/getMarketTrendsPMA?areaCityPeriod=${
              updatedID ? updatedID : mlsAreaID
            }-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
              subdivisionName ? subdivisionName : "-"
            }&listgenUserID=${listgenUserID}&mailerProID=${mailerProID}`,
            {
              headers: {
                "content-type": "application/json",
              },
              data: {},
            }
          )
          .then((res) => {
            const {
              pmaCoverPhotoDTO,
              mailerPrintInfoDTO,
              propertyPhotoDetailsDTO,
              pmaQuarterlyCombinedCityDTO,
              pmaQuarterlyAreaDTO,
              pmaQuarterlyCityDTO,
              quadrantInfoList,
            } = res.data;
            const {
              lgTestimonialsDTO,
              mailerProDTO,
              listgenUserDTO,
            } = mailerPrintInfoDTO;
            this.setState({
              aprilData: { pmaQuarterlyCityDTO, pmaQuarterlyAreaDTO },
              currentArea,
              coverPageDetails: pmaCoverPhotoDTO,
              pmaQuarterlyCombinedCityDTO,
              propertyPhotoDetailsDTO,
              singleHOData: mailerProDTO,
              agentData: listgenUserDTO,
              testimonials: lgTestimonialsDTO,
              quadrantDetails: quadrantInfoList,
              version: "year-end-2022",
            });
          })
          .catch((err) =>
            console.log("error retrieving MQCityDetails activity" + err)
          );
      }
      let replacedArea;
      if (mlsAreaID === "752") {
        replacedArea = "5389";
      } else if (mlsAreaID === "1522155") {
        replacedArea = "5370";
      } else {
        replacedArea = mlsCityID;
      }
    } else if (
      (version === "table-quadrants" && listgenUserID !== "103") ||
      thisArea === "DEMO-SCL-16B"
    ) {
      let date = new Date();
      let thisYear = date.getFullYear();
      const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
      const today = `${date.getDate()}`.padStart(2, 0);
      try {
        let mailPrint = await axios
          .get(
            `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
            {
              headers: {
                "content-type": "application/json",
              },
              data: {},
            }
          )
          .then((res) => {
            const singleHOData = res.data.mailerProDTO;
            const agentData = res.data.listgenUserDTO;
            this.setState({
              singleHOData,
              agentData,
            });
          })
          .catch((err) => console.log("error retrieving idDetails" + err));
      } catch (error) {
        console.log("error", error);
      }
      let navBar = await axios
        .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
          headers: {
            "content-type": "application/json",
          },
          data: {},
        })
        .then((res) => {
          this.setState({
            mlsAreas: res.data,
          });
        })
        .catch((err) => {
          console.log("error retrieving navbarInfo" + err);
        });
      const thisArea = this.state.singleHOData.areaID;
      const currentArea = this.state.mlsAreas.filter(
        (area) => area.mlsAreaID === thisArea
      )[0];
      const { mlsCityID, mlsAreaID } = currentArea;
      const { singleHOData } = this.state;
      const { subdivisionName } = singleHOData;
      if (singleHOData) {
        const { subdivisionName, mailerProID } = singleHOData;
        if (currentArea) {
          let dashboardAndTable = await axios
            .get(
              `/agent/getTableDataWithDonutAndAvgSalePriceChartPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
                subdivisionName ? subdivisionName : "-"
              }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
              {
                headers: {
                  "content-type": "application/json",
                },
                params: {},
                data: {},
              }
            )
            .then((res) => {
              const {
                navbarInfoList,
                pmaCoverPhotoDTO,
                mailerPrintInfoDTO,
                pmaQuarterlyAreaDTO,
                pmaQuarterlyCityDTO,
                missionStatementDTO,
                pmaQuarterlyCombinedCityDTO,
                quadrantInfoList,
                propertyPhotoDetailsDTO,
              } = res.data;
              const singleHOData = mailerPrintInfoDTO.mailerProDTO;
              const agentData = mailerPrintInfoDTO.listgenUserDTO;
              const currentAreaFromApi = navbarInfoList.filter(
                (area) => area.mlsAreaID === thisArea
              )[0];
              this.setState({
                quarterlyAreaTable: pmaQuarterlyAreaDTO,
                quarterlyCityTable: pmaQuarterlyCityDTO,
                MQCityDetails: pmaQuarterlyCityDTO,
                coverPageDetails: pmaCoverPhotoDTO,
                currentArea,
                testimonials: mailerPrintInfoDTO.lgTestimonialsDTO,
                quadrantDetails: quadrantInfoList,
                propertyPhotoDetailsDTO,
                version: "table-quadrants",
              });
            })
            .catch((err) => {
              console.log("error retrieving may api" + err);
            });
        }
      }
    } else if (
      (version === "qv4" && listgenUserID !== "103") ||
      thisArea === "DEMO-SCL-16E"
    ) {
      let date = new Date();
      let thisYear = date.getFullYear();
      const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
      const today = `${date.getDate()}`.padStart(2, 0);
      try {
        let mailPrint = await axios
          .get(
            `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
            {
              headers: {
                "content-type": "application/json",
              },
              data: {},
            }
          )
          .then((res) => {
            const singleHOData = res.data.mailerProDTO;
            const agentData = res.data.listgenUserDTO;
            this.setState({
              singleHOData,
              agentData,
            });
          })
          .catch((err) => console.log("error retrieving idDetails" + err));
        let navBar = await axios
          .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
            headers: {
              "content-type": "application/json",
            },
            data: {},
          })
          .then((res) => {
            this.setState({
              mlsAreas: res.data,
            });
          })
          .catch((err) => console.log("error retrieving navbarInfo" + err));
        const thisArea = this.state.singleHOData.areaID;
        const currentArea = this.state.mlsAreas.filter(
          (area) => area.mlsAreaID === thisArea
        )[0];
        const { singleHOData } = this.state;
        const { mlsCityID, mlsAreaID } = currentArea;
        let subdivision =
          singleHOData &&
          singleHOData.subdivisionName &&
          singleHOData.subdivisionName.length > 1
            ? singleHOData.subdivisionName
            : "-";
        const cityAndDate = `${currentArea.mlsCityID}-${thisYear}-${thisMonth}-${today}`;
        const { mailerProID, subdivisionName } = singleHOData;
        if (currentArea) {
          let getSpeedoMeterPMADetails = await axios
            .get(
              `/agent/getSpeedoMeterPMADetails?areaCityPeriod=${mlsAreaID}-${cityAndDate}&subDivision=${subdivision}&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
              {
                headers: {
                  "content-type": "application/json",
                },
                data: {},
              }
            )
            .then((res) => {
              const { lgTestimonialsDTO } = res.data.mailerPrintInfoDTO;
              this.setState({
                coverPageDetails: res.data.pmaCoverPhotoDTO,
                quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
                quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
                testimonials: lgTestimonialsDTO,
                singleHOData: res.data.mailerPrintInfoDTO.mailerProDTO,
                agentData: res.data.mailerPrintInfoDTO.listgenUserDTO,
                currentArea,
                quadrantDetails: res.data,
                version: "qv4",
              });
            })
            .catch((err) =>
              console.log("error retrieving speedometer details" + err)
            );
        }
      } catch (error) {
        console.log("error with qv4 print apis", error);
      }
    }
  }
  render() {
    const { propertyData } = this.props;
    const {
      singleHOData,
      agentData,
      mlsAreas,
      marketActivity,
      MQCityDetails,
      currentArea,
      version,
      quarterlyCityTable,
      quarterlyAreaTable,
      localSellingAgents,
      coverPageDetails,
      testimonials,
      agentDesignations,
      agentAdvantages,
      salePriceForCities,
      yearEnd,
      qArea,
      qCity,
      aprilData,
      flipBook,
      missionStatement,
      propertyPhotoDetailsDTO,
      pmaQuarterlyCombinedCityDTO,
      quadrantDetails,
    } = this.state;
    if (
      (version === "qv2" &&
        currentArea &&
        singleHOData &&
        agentData &&
        coverPageDetails &&
        quarterlyAreaTable) ||
      (version === "year-end-2022" &&
        currentArea &&
        singleHOData &&
        agentData &&
        aprilData &&
        coverPageDetails) ||
      (version === "table-quadrants" &&
        currentArea &&
        singleHOData &&
        agentData &&
        quarterlyAreaTable &&
        quarterlyAreaTable.monthsSupplyTable &&
        coverPageDetails &&
        quadrantDetails &&
        quadrantDetails.length &&
        MQCityDetails &&
        MQCityDetails.avgHomesSoldLast12Months) ||
      version === "qv4" ||
      version === "mq2" ||
      (version === "ye2" &&
        currentArea &&
        singleHOData &&
        agentData &&
        coverPageDetails)
    ) {
      return (
        <div className="pma-creator">
          <main>
            <section>
              <PmaEditor
                propertyData={propertyData}
                printVersion={true}
                version={version}
                singleHOData={singleHOData}
                agentData={agentData}
                currentArea={currentArea}
                marketActivity={marketActivity}
                MQCityDetails={MQCityDetails}
                mlsAreas={mlsAreas}
                quarterlyAreaTable={quarterlyAreaTable}
                quarterlyCityTable={quarterlyCityTable}
                localSellingAgents={localSellingAgents}
                coverPageDetails={coverPageDetails}
                printTestimonials={testimonials}
                printAgentAdvantages={agentAdvantages}
                printAgentDesignations={agentDesignations}
                salePriceForCities={salePriceForCities}
                yearEnd={yearEnd}
                qArea={qArea}
                qCity={qCity}
                aprilData={aprilData}
                flipBook={flipBook}
                dontShowPma={false}
                missionStatement={missionStatement}
                propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
                pmaQuarterlyCombinedCityDTO={pmaQuarterlyCombinedCityDTO}
                quadrantDetails={quadrantDetails}
              />
            </section>
          </main>
        </div>
      );
    } else {
      return <div>Loading PMA...</div>;
    }
  }
}

export default PrintPMA;
