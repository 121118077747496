import React, { Component, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles, CircularProgress } from '@material-ui/core';
import { Button, TextField, Typography } from '@material-ui/core';
import validate from 'validate.js';
import schema from './schema';
import _ from 'lodash';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

import styles from './styles';
import { withSnackbar } from 'notistack';
import { compose } from 'recompose';
import { Store } from 'Store';
import { HANDLE_VALIDATIONS } from 'store/types';
import { START_LOADING } from 'store/types';
import { showSuccessNotification } from 'common/helpers';
import { showErrorNotification } from 'common/helpers';
import { STOP_LOADING } from 'store/types';
import { RESET_APP_STATE } from 'store/types';
import { ChangeEmail as ChangeEmailAPI } from 'services/account';
import { HANDLE_FIELD_CHANGE } from 'store/types';
import { SET_STATE_ERRORS } from 'store/types';
import { RESET_APP_STATE_EXCEPT_VALUES } from 'store/types';
import { Alert } from '@material-ui/lab';

const UpdateEmail = (props) => {

  const { state, dispatch } = React.useContext(Store);
  const [isValid, setValid] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const {
    values,
    touched,
    errors,
    submitError,
  } = state;
  const { classes, className, ...rest } = props;
  const rootClassName = classNames(classes.root, className);

  const showEmailError = touched.email && errors.email;
  useEffect(() => {
    /**reset app also */
    dispatch({
      type: RESET_APP_STATE_EXCEPT_VALUES
    });
    validateForm.call(this);
  }, []);

  const validateForm = _.debounce(() => {
    const { values } = state;
    const errors = validate(values, schema);
    dispatch({
      type: SET_STATE_ERRORS,
      errors: (errors || {})
    });
    setValid(errors ? false : true);
  }, 300);

  const changeEmail = () => {
    try {
      const { values } = state;
      const { history } = props;
      setLoading(true);
      /**call the data api over here */
      ChangeEmailAPI(state.userID, values.email).then(data => {
        switch (Number(data)) {
          case 0:
            showSuccessNotification(props, "Email changed. Redirecting to login.");
            /**clean the form */
            dispatch({
              type: RESET_APP_STATE
            });
            /**redirect to login page */
            setTimeout(() => {
              history.push('/logout?mode=5');
            }, 1500);
            break;
          case 1:
            showErrorNotification(props, "Some unexpected error occured.");
            break;
          case 2:
            showErrorNotification(props, "Email already associated with other account.");
            break;
          default:
            showErrorNotification(props, "Something went wrong. Please try after some time.");
            break;
        }
        /**stop loading also */
        setLoading(false);
      }).catch(err => {
        showErrorNotification(props, err);
        setLoading(false);
      });
    } catch (err) {
      showErrorNotification(props, 'Unable to process request.');
      setLoading(false);
    }
  };

  const forceDisableEditing = state && state.userData && state.userData.hasOwnProperty('enableMailProAccess') ? !state.userData.enableMailProAccess : false;

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader>
        <PortletLabel
          title="Change Email"
        />
      </PortletHeader>
      <PortletContent noPadding>
        <form
          autoComplete="off"
          noValidate
        >
          {forceDisableEditing && <Alert severity='info' variant='filled' style={{margin: '8px', fontSize: '14px'}}>PMA Printing is in progress. You can update your email after that.</Alert>}
          <div className={classes.field}>
            <TextField
              className={classes.textField}
              label="Email Address"
              margin="dense"
              required
              value={state.values.email}
              variant="outlined"
              helperText="You will have to login again after changing email"
              onChange={event => {
                dispatch({
                  type: HANDLE_FIELD_CHANGE,
                  fieldType: event.target.type,
                  value: event.target.value,
                  name: 'email',
                });
                validateForm();
              }
              }
            />
            {showEmailError && (
              <Typography
                className={classes.fieldError}
                variant="body2"
              >
                {errors.email[0]}
              </Typography>
            )}
          </div>
        </form>
      </PortletContent>
      <PortletFooter className={classes.portletFooter}>
        {isLoading ? (
          <CircularProgress className={classes.progress} />
        ) : (
            <Button
              color="primary"
              variant="contained"
              disabled={!isValid || forceDisableEditing}
              onClick={event => changeEmail()}
            >
              Change Email
          </Button>
          )}
      </PortletFooter>
    </Portlet>
  );
}

UpdateEmail.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles)
)(UpdateEmail);
