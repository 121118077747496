import palette from "theme/palette";

export default theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    zIndex: theme.zIndex.appBar
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: 'auto',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flex: ' 1 1 auto'
  },
  toolbarLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolbarCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  toolbarRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginLeft: theme.spacing(0),
    fontWeight: '500'
  },
  menuButton: {
    marginLeft: '-4px'
  },
  menuButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
    '&>svg:nth-child(1)': {
      width: '26px',
      height: '26px'
    },
    '&>span:nth-child(2)': {
      fontSize: '16px'
    }
  },
  notificationsButton: {
    marginLeft: 'auto'
  },
  accountButton: {
    marginLeft: 'auto',
    '&:hover': {
      borderRadius: '10%'
    }
  },
  agentNameDiv: {
    width: 'fit-content',
    border: `1px solid ${palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: palette.background.default,
    fontSize: '14px',
    padding: theme.spacing(0.5),
    fontWeight: 500,
    '&>div': {
      color: palette.primary.main,
    }
  }
});
