import palette from '../../theme/palette';

export default theme => ({
  root: {
    flexGrow: 0,
    flexShrink: 0,
    overflow: 'hidden',
    borderRadius: '5px',
    display: 'inline-flex',
    border: `1px solid ${palette.border}`
  },
  option: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: palette.common.white
  },
  optionSelected: {
    backgroundColor: palette.primary.light,
    color: palette.primary.main
  },
  divider: {
    width: '1px',
    backgroundColor: palette.divider
  }
});
