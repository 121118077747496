import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import moment from "moment";

class QVThreeMarketSaleRow extends Component {
  getMonthAndYearString = (inputTime) => {
    // console.log("inputTime", inputTime);
    try {
      let month = moment(inputTime).format("MMM");
      let day = moment(inputTime).format("D");
      let year = moment(inputTime).format("YY");
      // console.log("month", month);
      // console.log("day", day);
      // console.log("year", year);
      return (
        <div className="date-td">
          <div>{month}</div>
          <div>{day},</div>
          <div>{year}</div>
        </div>
      );
      // return moment(inputTime).format("MMM D, YYYY");
    } catch (err) {
      return "";
    }
  };

  calculatePricePerSqft(calculation, saleP, sqft) {
    if (calculation === "-") {
      let res = Math.round(saleP / sqft);
      return res.toLocaleString();
    } else {
      return Math.round(calculation).toLocaleString();
    }
  }
  render() {
    const {
      getPropView,
      formatSuffix,
      formatBaths,
      toAcre,
      cutName,
      propTypeAcronym,
      shortenStatus,
      shortenPropType,
    } = propertyUtil;
    const { shortenYear } = propertyUtil;
    const { solds, mlsAreaID, older, isSingleTable } = this.props;
    // console.log("row", this.props);
    let isCountryClubGate = mlsAreaID === "132" ? true : false;

    const listMap =
      solds &&
      solds.map((list, i) => {
        let hasUnitNum =
          !isCountryClubGate &&
          (list.propertyType === "Townhouse" ||
            list.propertyType === "Condominium") &&
          list.unitNumber !== "-";
        // console.log("list.address", list.address);
        // console.log('list.unitNumber !== "-"', list.unitNumber !== "-");
        let addressName = "";
        if (
          list.streetNumber !== "-" &&
          list.streetNumber != "0" &&
          list.streetNumber !== "."
        ) {
          addressName += list.streetNumber;
        }
        addressName += ` ${list.streetName}`;
        if (list.streetSuffix && list.streetSuffix !== "-") {
          addressName += ` ${list.streetSuffix}`;
        }
        if (hasUnitNum) {
          addressName += `, #${list.unitNumber}`;
        }
        let addressSize = addressName.length > 25 ? "smaller-row-address" : "";

        return (
          <tr
            key={`${list.address} ${list.listPrice} ${list.saleDate} ${i}`}
            className={`${list.status.toLowerCase()} ${
              older ? "presolds-row" : "recent-row"
            } ${isSingleTable ? "single-table-row" : ""}`}
          >
            <td
              // className={`status-text`}
              className={`status-text ${list.status.toLowerCase()}`}
              align="left"
            >
              {list.isOffMarketSale == "1" && <strong>*</strong>}
              <p>{shortenStatus(list.status, "pendingOnly")}</p>
            </td>

            {list.saleDate === "-" ? (
              <td></td>
            ) : (
              <td className="market-table-sale-date-row">
                {this.getMonthAndYearString(list.saleDate)}
              </td>
            )}

            <td className="prop-type-text">
              {propTypeAcronym(list.propertyType)}
            </td>
            <td>
              <p className={addressSize}>
                {addressName}
                {/*{list.streetNumber === "-" || list.streetNumber === "0"
                  ? null
                  : list.streetNumber}{" "}
                {list.streetName}{" "}
                {list.streetSuffix && list.streetSuffix !== "-"
                  ? list.streetSuffix
                  : null}
                {hasUnitNum ? `, #${list.unitNumber}` : ""}*/}
              </p>
            </td>
            <td>
              {list.listPrice === "-" ? (
                <div className="off-centered-dash">
                  <p>—</p>
                </div>
              ) : (
                <div>
                  <p>$</p>
                  <p>{Number(list.listPrice).toLocaleString()}</p>
                </div>
              )}
            </td>

            <td>
              {list.salePrice === "-" ? (
                <div className="off-centered-dash">
                  <p>—</p>
                </div>
              ) : (
                <div>
                  <p>$</p>
                  <p>{Number(list.salePrice).toLocaleString()}</p>
                </div>
              )}
            </td>
            {list.isOffMarketSale == "1" &&
            (list.daysOnMarket === "0" || list.daysOnMarket === "-") ? (
              <td>
                <div className="off-centered-dash">
                  <p>—</p>
                </div>
              </td>
            ) : (
              <td>{list.daysOnMarket}</td>
            )}

            <td>{formatBaths(list.bedBaths)}</td>
            <td>
              {list.squareFeet === "-" || list.squareFeet === "0" ? (
                <p>N/A</p>
              ) : (
                <p>{Math.round(list.squareFeet).toLocaleString()}</p>
              )}
            </td>
          </tr>
        );
      });
    return <tbody>{listMap}</tbody>;
  }
}

export default QVThreeMarketSaleRow;
