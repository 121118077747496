import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";

class QVThreeAvgPriceBarChart extends Component {
  sliceAreaList = (list) => {
    const { subdivisionName } = this.props.hoData;
    const { mlsNeighborhood } = this.props.currentArea;
    const { splitAreas } = propertyUtil;
    //the exact area is needed because we use an "includes" method and so these need to be the exact area and not just an area that contains this area name
    let exactArea = ["Foothill", "East San Roque", "Santa Barbara Foothills"];
    //puts the star next to the current PMA area's name
    function giveStar(subdivisionName) {
      let splitSubdivision = splitAreas(subdivisionName);
      //filters out the subdivision
      let filterOutSD = fullListArr.filter((area) => {
        if (exactArea.includes(mlsNeighborhood)) {
          return area[0] !== splitSubdivision[0];
        } else {
          return !area[0].includes(splitSubdivision[0]);
        }
      });
      let subdivisionArr = fullListArr.filter((area) => {
        if (exactArea.includes(mlsNeighborhood)) {
          return area[0] === splitSubdivision[0];
        } else {
          return area[0].includes(splitSubdivision[0]);
        }
      });
      let equalLenSubAndFull =
        fullListArr.length === 6 && filterOutSD.length === 6;
      let sortedWithoutSubdivision = filterOutSD
        .sort((a, b) => b[1] - a[1])
        .slice(0, equalLenSubAndFull ? 6 : 5);
      let addSubdivisionBack = [...sortedWithoutSubdivision, ...subdivisionArr];
      let sortSlicedWithSubdivision = addSubdivisionBack.sort(
        (a, b) => b[1] - a[1]
      );
      return sortSlicedWithSubdivision.slice(0, 6);
    }
    let fullListArr = Object.entries(list);
    let containsArea = splitAreas(mlsNeighborhood).some((area) => {
      let includes = fullListArr.map((listItem) => {
        return listItem[0].includes(area);
      });
      return includes;
    });
    if (subdivisionName || containsArea) {
      let sortSlicedWithSubdivision = giveStar(
        subdivisionName ? subdivisionName : mlsNeighborhood
      );
      return sortSlicedWithSubdivision;
    } else {
      let sortByPrice = fullListArr.sort((a, b) => b[1] - a[1]);
      let sliceSorted = sortByPrice.slice(0, 6);
      return sliceSorted;
    }
  };
  calculateBarLengths = (areaList) => {
    const { roundSalePriceTenths, calculateRoundedTenths } = propertyUtil;
    let allRoundedSalePrices = areaList.map((area, i) =>
      Number(calculateRoundedTenths(area[1]))
    );
    let uniqueRoundedSalePrices = new Set(allRoundedSalePrices);
    let sortedUnique = [...uniqueRoundedSalePrices].sort((a, b) => b - a);
    let lengthObj = {};
    let count = Object.keys(lengthObj).length;
    for (let i = 0; i < sortedUnique.length; i++) {
      let area = sortedUnique[i];
      lengthObj[area] = i;
    }
    return lengthObj;
  };

  render() {
    const {
      roundSalePriceTenths,
      calculateRoundedTenths,
      splitAreas,
    } = propertyUtil;
    let specialMonths = 6;
    const { hoData, currentArea, MQCityDetails } = this.props;
    const { mlsAreaID, mlsCityID, mlsNeighborhood } = currentArea;
    const { subdivisionName } = hoData;
    const { sliceAreaList } = this;
    const {
      AverageSalePriceForBigSurCitiesLast12Months,
      AverageSalePriceForCarmelHighlandsCitiesLast12Months,
      AverageSalePriceForDelReyOaksCitiesLast12Months,
    } = MQCityDetails.avgSalePriceMap;
    const {
      avgSalePriceLast12MonthsForNbrHood,
      avgSalePriceMapLast12MonthsForLaSelvaBeach: avg12LaSelvaBeach,
      avgSalePriceMapLast12MonthsForNaturalBridges: avg12NaturalBridges,
      avgSalePriceMapLast12MonthsForOldCountryClubPark: avg12OldCountryClubPark,
      avgSalePriceMapLast12MonthsForOpalCliffs: avg12OpalCliffs,
      avgSalePriceMapLast12MonthsForRioDelMar: avg12RioDelMar,
      avgSalePriceMapLast12MonthsForSantaCruzGardens: avg12SantaCruzGardens,
      avgSalePriceMapLast12MonthsForSeaCliffBeach: avg12SeaCliffBeach,
      avgSalePriceMapLast12MonthsForSteamersLane: avg12SteamersLane,
      avgSalePriceMapLast12MonthsForEastMesa,
      avgSalePriceMapLast12MonthsForEastSanRoque,
      avgSalePriceMapLast12MonthsForHopeRanch,
      avgSalePriceMapLast12MonthsForKellogEast,
      avgSalePriceMapLast12MonthsForMontecito,
      avgSalePriceMapLast12MonthsForBelAirKnolls,
      avgSalePriceMapLast12MonthsForCampanil,
      avgSalePriceMapLast12MonthsForHope,
      avgSalePriceMapLast12MonthsForMountainView,
      avgSalePriceMapLast12MonthsForFootHills,
      avgSalePriceMapLast12MonthsForSamarkand,
      avgSalePriceMapLast12MonthsForWinchesterCanyon,
      avgSalePriceMapLast12MonthsForWestMesa,
      avgSalePriceMapLast12MonthsForSantaBarbaraFootHills,
      avgSalePriceMapLast12MonthsForBuelltonAndLosOlivos,
      avgSalePriceMapLast12MonthsForSantaYnez,
      avgSalePriceMapLast12MonthsForDowntown,
      avgSalePriceMapLast12MonthsForEastAltaMesa,
      avgSalePriceMapLast12MonthsForUpperEast,
      avgSalePriceMapLast12MonthsForUpperRiviera,
      avgSalePriceMapLast12MonthsForHollister,
      avgSalePriceMapLast12MonthsForHiddenValley,
      avgSalePriceMapLast12MonthsForSeaBright,
      avgSalePriceMapLast12MonthsForAptosHills,
      avgSalePriceMapLast12MonthsForPleasurePointEast,
      avgSalePriceMapLast12MonthsForMoreMesaHopeRanchAnnex,
      avgSalePriceMapLast12MonthsForLindenAve,
      avgSalePriceMapLast12MonthsForSantaYnezAndSolvang,
      avgSalePriceMapLast12MonthsForKellogEastAndLosCarneros,
      avgSalePriceMapLast12MonthsForBenLomond
    } = MQCityDetails;
    let areaList;
    let fullListLength;
    let neighborhoodAreaListLength = Object.keys(
      avgSalePriceLast12MonthsForNbrHood
    ).length;
    let subtitle;
    const addChartData = (places, area) => {
      subtitle = area ? "By Surrounding Neighborhoods" : "Neighboring Cities";
      areaList = sliceAreaList(places);
      fullListLength = Object.keys(places);
    };
    //Mostly for santa barbara, that has the custom bar chart areas
    if (
      mlsAreaID === "SBA-30E" &&
      Object.keys(avgSalePriceMapLast12MonthsForMoreMesaHopeRanchAnnex).length >
        0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForMoreMesaHopeRanchAnnex, true);
    } else if (
      (mlsAreaID === "SBA-40C" || mlsAreaID === "SBA-40H") &&
      Object.keys(avgSalePriceMapLast12MonthsForSantaYnezAndSolvang).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForSantaYnezAndSolvang, true);
    } else if (
      mlsAreaID === "45E" &&
      Object.keys(avgSalePriceMapLast12MonthsForPleasurePointEast).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForPleasurePointEast, true);
    } else if (
      mlsAreaID === "51B" &&
      Object.keys(avgSalePriceMapLast12MonthsForAptosHills).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForAptosHills, true);
    } else if (
      mlsAreaID === "SBA-5E" &&
      Object.keys(avgSalePriceMapLast12MonthsForLindenAve).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForLindenAve, true);
    } else if (
      (mlsAreaID === "SBA-42A" || mlsAreaID === "SBA-42B") &&
      Object.keys(avgSalePriceMapLast12MonthsForSeaBright).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForSeaBright, true);
    } else if (
      mlsAreaID === "SBA-20B" &&
      Object.keys(avgSalePriceMapLast12MonthsForHiddenValley).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForHiddenValley, true);
    } else if (
      (mlsAreaID === "SBA-30D" || mlsAreaID === "SBA-30F") &&
      Object.keys(avgSalePriceMapLast12MonthsForHollister).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForHollister, true);
    } else if (
      mlsAreaID === "SBA-15C" &&
      Object.keys(avgSalePriceMapLast12MonthsForUpperRiviera).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForUpperRiviera, true);
    } else if (
      mlsAreaID === "SBA-15E" &&
      Object.keys(avgSalePriceMapLast12MonthsForUpperEast).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForUpperEast, true);
    } else if (
      mlsAreaID === "SBA-20L" &&
      Object.keys(avgSalePriceMapLast12MonthsForEastAltaMesa).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForEastAltaMesa, true);
    } else if (
      (mlsAreaID === "SBA-20FCondo" ||
        mlsAreaID === "SBA-20FInv" ||
        mlsAreaID === "SBA-20FRes") &&
      Object.keys(avgSalePriceMapLast12MonthsForDowntown).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForDowntown, true);
    } else if (
      (mlsAreaID === "SBA-40F" || mlsAreaID === "SBA-40G") &&
      Object.keys(avgSalePriceMapLast12MonthsForBuelltonAndLosOlivos).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForBuelltonAndLosOlivos, false);
    } else if (
      mlsAreaID === "SBA-25A" &&
      Object.keys(avgSalePriceMapLast12MonthsForHopeRanch).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForHopeRanch, true);
    } else if (
      mlsAreaID === "SBA-35G" &&
      Object.keys(avgSalePriceMapLast12MonthsForFootHills).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForFootHills, true);
    } else if (
      mlsAreaID === "SBA-20I" &&
      Object.keys(avgSalePriceMapLast12MonthsForWestMesa).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForWestMesa, true);
    } else if (
      mlsAreaID === "SBA-35A" &&
      Object.keys(avgSalePriceMapLast12MonthsForWinchesterCanyon).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForWinchesterCanyon, true);
    } else if (
      mlsAreaID === "SBA-20C" &&
      Object.keys(avgSalePriceMapLast12MonthsForSamarkand).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForSamarkand, true);
    } else if (
      mlsAreaID === "SBA-35H" &&
      Object.keys(avgSalePriceMapLast12MonthsForSantaBarbaraFootHills).length >
        0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForSantaBarbaraFootHills, true);
    } else if (
      mlsAreaID === "SBA-35F" &&
      Object.keys(avgSalePriceMapLast12MonthsForMountainView).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForMountainView, true);
    } else if (
      mlsAreaID === "SBA-15J" &&
      Object.keys(avgSalePriceMapLast12MonthsForHope).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForHope, true);
    } else if (
      mlsAreaID === "SBA-25B" &&
      Object.keys(avgSalePriceMapLast12MonthsForCampanil).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForCampanil, true);
    } else if (
      mlsAreaID === "SBA-20G" &&
      Object.keys(avgSalePriceMapLast12MonthsForBelAirKnolls).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForBelAirKnolls, true);
    } else if (
      mlsAreaID === "SBA-35DE" &&
      Object.keys(avgSalePriceMapLast12MonthsForKellogEastAndLosCarneros)
        .length > 0
    ) {
      addChartData(
        avgSalePriceMapLast12MonthsForKellogEastAndLosCarneros,
        true
      );
    } else if (
      mlsCityID === "5060" &&
      Object.keys(avgSalePriceMapLast12MonthsForMontecito).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForMontecito, true);
    } else if (
      mlsAreaID === "SBA-35E" &&
      Object.keys(avgSalePriceMapLast12MonthsForKellogEast).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForKellogEast, true);
    } else if (
      mlsAreaID === "SBA-15H" ||
      mlsAreaID === "SBA-15I" ||
      (mlsAreaID === "SBA-15E" &&
        Object.keys(avgSalePriceMapLast12MonthsForEastSanRoque).length > 0)
    ) {
      addChartData(avgSalePriceMapLast12MonthsForEastSanRoque, true);
    } else if (
      mlsAreaID === "SBA-20J" &&
      Object.keys(avgSalePriceMapLast12MonthsForEastMesa).length > 0
    ) {
      addChartData(avgSalePriceMapLast12MonthsForEastMesa, true);
    } else if (
      mlsAreaID === "43C" &&
      Object.keys(avg12SteamersLane).length > 0
    ) {
      addChartData(avg12SteamersLane, true);
    } else if (
      mlsAreaID === "47A" &&
      Object.keys(avg12SeaCliffBeach).length > 0
    ) {
      subtitle = "By Neighborhood";
      areaList = sliceAreaList(avg12SeaCliffBeach);
      fullListLength = Object.keys(avg12SeaCliffBeach);
    } else if (
      mlsAreaID === "46C" &&
      Object.keys(avg12SantaCruzGardens).length > 0
    ) {
      subtitle = "By Neighborhood";
      areaList = sliceAreaList(avg12SantaCruzGardens);
      fullListLength = Object.keys(avg12SantaCruzGardens);
    } else if (mlsAreaID === "44A" && Object.keys(avg12OpalCliffs).length > 0) {
      subtitle = "By Neighborhood";
      areaList = sliceAreaList(avg12OpalCliffs);
      fullListLength = Object.keys(avg12OpalCliffs);
    } else if (
      mlsAreaID === "48E" &&
      Object.keys(avg12OldCountryClubPark).length > 0
    ) {
      subtitle = "By Neighborhood";
      areaList = sliceAreaList(avg12OldCountryClubPark);
      fullListLength = Object.keys(avg12OldCountryClubPark);
    } else if (
      mlsAreaID === "43A" &&
      Object.keys(avg12NaturalBridges).length > 0
    ) {
      subtitle = "By Neighborhood";
      areaList = sliceAreaList(avg12NaturalBridges);
      fullListLength = Object.keys(avg12NaturalBridges);
    } else if (
      mlsAreaID === "51A" &&
      Object.keys(avg12LaSelvaBeach).length > 0
    ) {
      subtitle = "By Neighborhood";
      areaList = sliceAreaList(avg12LaSelvaBeach);
      fullListLength = Object.keys(avg12LaSelvaBeach);
    }  else if (
      mlsAreaID === "36A" &&
      Object.keys(avgSalePriceMapLast12MonthsForBenLomond).length > 0
    ) {
      subtitle = "By Neighborhood";
      areaList = sliceAreaList(avgSalePriceMapLast12MonthsForBenLomond);
      fullListLength = Object.keys(avgSalePriceMapLast12MonthsForBenLomond);
    } else if (
      mlsAreaID === "108" &&
      Object.keys(AverageSalePriceForDelReyOaksCitiesLast12Months).length > 0
    ) {
      subtitle = "By City";
      //del rey oaks
      areaList = sliceAreaList(AverageSalePriceForDelReyOaksCitiesLast12Months);
      fullListLength = Object.keys(
        AverageSalePriceForDelReyOaksCitiesLast12Months
      );
    } else if (
      mlsAreaID === "157" &&
      Object.keys(AverageSalePriceForBigSurCitiesLast12Months).length > 1
    ) {
      subtitle = "By City";
      //big sur
      areaList = sliceAreaList(AverageSalePriceForBigSurCitiesLast12Months);
      fullListLength = Object.keys(AverageSalePriceForBigSurCitiesLast12Months);
    } else if (
      (mlsCityID === "5370" || mlsAreaID === "1522155") &&
      Object.keys(AverageSalePriceForCarmelHighlandsCitiesLast12Months).length >
        1
    ) {
      subtitle = "By City";
      //carmel highlands
      areaList = sliceAreaList(
        AverageSalePriceForCarmelHighlandsCitiesLast12Months
      );
      fullListLength = Object.keys(
        AverageSalePriceForCarmelHighlandsCitiesLast12Months
      );
    } else {
      subtitle = "By Neighborhood";
      areaList = sliceAreaList(avgSalePriceLast12MonthsForNbrHood);
      fullListLength = Object.keys(avgSalePriceLast12MonthsForNbrHood).length;
    }

    let areaSplit = splitAreas(mlsNeighborhood);
    let barLengthObj = this.calculateBarLengths(areaList);
    let mappedSix = areaList.map((area, i) => {
      let roundedPrice = calculateRoundedTenths(area[1]);
      let barLength = barLengthObj[roundedPrice];
      let isSubdivisionArea = area[0].includes(subdivisionName) ? true : false;
      let barAreaSplit = splitAreas(area[0].trim());
      let exactArea = [
        "Foothill",
        "East San Roque",
        "More Mesa & Hope Ranch Annex",
        "Santa Barbara Foothills",
      ];
      let isArea;
      if (exactArea.includes(mlsNeighborhood)) {
        if (barAreaSplit.length === 2) {
          isArea =
            areaSplit[0] === barAreaSplit[0] &&
            areaSplit[1] === barAreaSplit[1];
        } else {
          isArea = mlsNeighborhood === barAreaSplit[0] && !barAreaSplit[1];
        }
      } else if (
        mlsNeighborhood === "Carmel Highlands" &&
        barAreaSplit[0] === "Carmel"
      ) {
        isArea = false;
      } else if (
        mlsNeighborhood === "Aptos Hills / Larkin Valley" &&
        barAreaSplit[0].includes(areaSplit[0]) &&
        barAreaSplit[0].includes(areaSplit[1])
      ) {
        isArea = true;
      } else {
        isArea = mlsNeighborhood.includes(barAreaSplit[0]);
      }
      return (
        <div className="avg-sale-price-row" key={i}>
          <div className="avg-sale-price-area">
            {isArea && <div className="subdivision-star">&#9733;</div>}
            <div
              className={`avg-sale-price-area-text ${
                isArea && "shorter-bar-text-width"
              }`}
            >
              {/*splits the area names so it looks better on the bar chart*/}
              {isArea && barAreaSplit[0] === "West Cliff Steamer Lane" ? (
                <p>
                  West Cliff <br /> Steamer Lane{" "}
                </p>
              ) : isArea && barAreaSplit[0] === "Opal Cliffs Neighborhoods" ? (
                <p>
                  Opal Cliffs <br />
                  Neighborhoods
                </p>
              ) : isArea && barAreaSplit[0] === "Winchester Canyon" ? (
                <p>
                  Rancho Embarcadero <br />
                  Winchester
                </p>
              ) : isArea && barAreaSplit[0] === "Linden Ave Neighborhood" ? (
                <p>
                  Linden Ave
                  <br />
                  Neighborhood
                </p>
              ) : isArea && barAreaSplit[0] === "Santa Barbara Foothills" ? (
                <p>
                  Santa Barbara <br />
                  Foothills
                </p>
              ) : (
                <p>{barAreaSplit[0]}</p>
              )}
              {barAreaSplit.length > 0 && <p>{barAreaSplit[1]}</p>}
              {barAreaSplit.length > 1 && <p>{barAreaSplit[2]}</p>}
            </div>
          </div>
          <div className="avg-sale-price-bar-container">
            <div
              className={`avg-sale-price-bar color-${i} bar-length-${barLength}`}
            />
            <p className="avg-sale-price-data">
              {roundSalePriceTenths(area[1], false, true)}
            </p>
          </div>
        </div>
      );
    });
    let notShownAreas = fullListLength - areaList.length;
    let notShownType;
    if (subtitle.includes("City")) {
      if (notShownAreas === 1) {
        notShownType = "City";
      } else {
        notShownType = "Cities";
      }
    } else if (subtitle.includes("Neighborhood")) {
      if (notShownAreas === 1) {
        notShownType = "Neighborhood";
      } else {
        notShownType = "Neighborhoods";
      }
    }
    return (
      <div className="qv3-avg-price-bar-chart">
        <div className="avg-price-bar-chart-header">
          <div className="avg-price-bar-chart-inner-header-content">
            <div className="avg-price-bar-chart-title">Average Sale Price</div>
            <div className="avg-price-bar-chart-subtitle">
              <div>{subtitle}</div>

              <div className="avg-price-bar-chart-subtitle-circle">—</div>
              <div>Last 12 Months</div>
            </div>
          </div>
        </div>

        {mappedSix}
        {mlsAreaID !== "36A" && (
          <div className="avg-price-bar-chart-not-shown">
            <p>
              Due to space constraints, there are neighborhoods with lower
              average sale prices that are not shown.
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default QVThreeAvgPriceBarChart;
