import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { Slide } from '@material-ui/core';
import PropTypes from 'prop-types';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    rootPaper: {
        width: `600px`,
        maxHeight: "calc(100% - 32px)",
        fontFamily: "objektiv-mk2",
        maxWidth: 'none'
    },
    rootPaperDecorative: {
        borderRadius: "4px !important",
        fontFamily: "objektiv-mk2",
        border: "1.5px solid #8EA2AD", //#757374
        margin: "16px",
        background: "#FBFBF5",
        fontFamily: "objektiv-mk2",
    },
    backDropBackgroundDark: {
        background: "rgba(0, 0, 0, 0.8)",
    },
});
export const MailProDialog = ({ children, classes:extraClasses = {}, ...rest }) => {
    const classes = useStyles();

    return <Dialog
        classes={{
            paper: classNames(classes.rootPaper, classes.rootPaperDecorative, (extraClasses.paper && extraClasses.paper)),
        }}
        fullScreen={false}
        TransitionComponent={Transition}
        keepMounted
        BackdropProps={{
            classes: {
                root: classes.backDropBackgroundDark,
            },
        }}
        disableEnforceFocus
        {...rest}
    >
        {children}
    </Dialog>
}


MailProDialog.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object,
};