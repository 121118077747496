import { grey, green, blue, red, pink } from "@material-ui/core/colors";
import palette from "theme/palette";

export default theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(9),
        background: 'white'
    },
    portletContent: {
        boxShadow: '2px 2px 10px #012159',
        WebkitBoxShadow: '2px 2px 10px #012159',
        MozBoxShadow: '2px 2px 10px #012159',
    },
    content: {
        padding: theme.spacing(2)
    },
    paddingTC: {
        padding: theme.spacing(8),
        paddingTop: theme.spacing(4),
    },
    paddingTCPrinting: {
        padding: theme.spacing(8),
        paddingTop: theme.spacing(2),
    },
    lineSpacingBreak: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    lineSpacingBreakPrinting: {
        paddingTop: theme.spacing(0.2),
        paddingBottom: theme.spacing(0.2)
    },
    tableBodyRow: {
        height: theme.spacing(5)
    },
    tableBodyRowPrinting: {
        height: theme.spacing(4)
    },
    lightColor: {
        color: grey[600]
    },
    lineThrough: {
        textDecoration: 'line-through'
    },
    currentScheduleYear : {
        color: red[900],
        animation: "$new-blinker 2s linear 20",
                    animationDuration: '2s',
                    '&:hover': {
                        animation: '0',
                        WebkitAnimation: 0
                    }
    },
    ongoingPrinting: {
        '& .ongoingCutoff': {
            color: red[900],
            '&>div>.flashDate': {
                animation: "$new-blinker 2s linear 20",
                animationDuration: '2s',
                '&:hover': {
                    animation: '0',
                    WebkitAnimation: 0
                }
            },
            '& .flashDate10': {
                animation: "$new-blinker 2s linear 10",
                animationDuration: '2s',
                '&:hover': {
                    animation: '0',
                    WebkitAnimation: 0
                }
            },
        },
        '&>td': {
            background: green[100],
            fontWeight: 500,
        },
    },
    completedPrinting: {
        '&>td': {
            color: palette.primary.main,
            fontWeight: 500,
        },
    },
    lastCompletedMailing: {
        backgroundColor: blue[50]
    },
    downloadSection: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start'
    },
    printBackground: {
        background: 'white'
    },
    headerMain: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%'
    },
    topFiltersContainer: {
        background: pink[50],
        padding: '8px',
        borderRadius: '4px',
        border: `1px solid ${pink[50]}`,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '12px'
    },
    selectScheduleYear: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    imageSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    imageWrapper: {
        height: '52px',
        width: '144px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    },
    image: {
        width: '100%'
    },
    mailingTextSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mailingTextMargin: {
        marginTop: theme.spacing(1),
        // marginLeft: '-4vw'
    },
    customizedPaddingSchedule: {
        padding: '20px 12px !important'
    },
    inReviewColor: {
        color: green[500],
        fontWeight: 800
    },
    inPrintingColor: {
        color: red[500],
        fontWeight: 800
    },
    postOfficeColor: {
        color: blue[400],
        fontWeight: 800
    },
    deliveryColor: {
        color: blue[800],
        fontWeight: 800
    },
    deliveredColor: {
        color: green[800],
        fontWeight: 800
    },
    loadingSchedule: {
        height: '50vh'
    },
    noScheduleContainer: {
        height: '50vh',
    },
    noSchedule: {
        background: red[100],
        fontWeight: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2)
    },
    //Admin Calendar Side CSS
    addMoreButton: {
        marginTop: theme.spacing(2),
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    buttonWrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        //color: gray[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    toSeparator: {
        margin: '8px 0',
        fontWeight: 500,
        //color: red[400]
    },
    customizedPadding: {
        padding: '20px 6px !important'
    },
    stickyHead: {
        background: blue[100],
        position: '-webkit-sticky',
        position: 'sticky',
        top: 64,
        zIndex: 10,
        height: theme.spacing(7),
    },
    headingColor: {
        color: '#3d85c6'
    },
    deleteButton: {
        color: red[500],
        fontSize: '12px',
        fontWeight: 'normal',
        marginTop: theme.spacing(2)
    },
    lightRedBg: {
        background: red[50]
    },
    lightGreenBg: {
        background: green[100]
    },
    greenColor: {
        color: green[500]
    },
    clearButton: {
        background: red[500]
    },
    "@keyframes new-blinker": {
        "85%": {
            opacity: 0
        }
    },
});