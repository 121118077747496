import React, { Component } from "react";
import { propertyUtil } from "../../PropertyUtil";

class LogoAndAvatar extends Component {
  render() {
    const {
      logoURL,
      brokerageName,
      mobilePhoneNo,
      photoURL,
      isMemberOfTeam,
      listgenUserFirstName,
      teamMembers,
      emailAddress
    } = this.props.agentData;
    // console.log("brokerageName", brokerageName);
    const { formatPhoneNoWithDashes, filterBrokerage } = propertyUtil;
    let filteredBrokerage = filterBrokerage(brokerageName);
    let smallFontBrokerage = filteredBrokerage.length > 40 ? true : false;
    return (
      <div className="logo-and-avatar-container">
        <div className="left-upper-col-agent-logo">
          <img src={logoURL} alt="Logo" />
        </div>
        {/* <div className="avatar-and-address-col"> */}
        <div className="agent-photo-container">
          <img src={photoURL} className="agent-photo" alt="Agent" />
        </div>
        {isMemberOfTeam ? (
          <div className="logo-and-avatar-team-contact">
            <div className="contact-section-team">
              <div className="contact-me-team-container">
                <div className="contact-me-team-inner">
                  <div className="contact-me row">
                    <p>Contact: {listgenUserFirstName}</p>
                    <div className="contact-me-divider" />{" "}
                    <p>{formatPhoneNoWithDashes(mobilePhoneNo)}</p>
                  </div>
                  <div className="contact-me row">
                    <p>Contact: {teamMembers[1].firstName}</p>
                    <div className="contact-me-divider" />{" "}
                    <p>{formatPhoneNoWithDashes(teamMembers[1].phone)}</p>
                  </div>
                </div>
              </div>
              <div className="stacked-address">
                <div className="address-col">{emailAddress}</div>
                <p
                  className={`address-city ${smallFontBrokerage &&
                    "small-brokerage-text"}`}
                >
                  {filteredBrokerage}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="contact-section-single">
            <div className="contact-me contact-me-single-inner">
              CONTACT ME <div className="contact-me-single-divider"></div>{" "}
              {mobilePhoneNo.slice(0, 3)} <div className="contact-me-circle" />{" "}
              {mobilePhoneNo.slice(3, 6)} <div className="contact-me-circle" />{" "}
              {mobilePhoneNo.slice(6)}
            </div>
            <div className="stacked-address single-agent">
              <div className="address-col">
                <div className="office-address-container">
                  <p className="office-address">{emailAddress}</p>
                </div>
              </div>
              <p
                className={`address-city ${smallFontBrokerage &&
                  "small-brokerage-text"}`}
              >
                {filteredBrokerage}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default LogoAndAvatar;
