

import 'date-fns';
import React from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import { enUS } from 'date-fns/locale';
import { parseISO } from 'date-fns';
import { format } from 'date-fns';
// import { convertToLocalTime } from 'date-fns-timezone';
import { formatInTimeZone } from 'date-fns-tz'


//define make styles
const useStyles = makeStyles({
    root: {
        '& label.Mui-focused': {
            borderBottomColor: '#4A7396',
            color: 'green'
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#4A7396',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#3B5C78',//darker than this

        }
    },
    label: {
        fontSize: '12px',
        color: '#4A7396'
    },
    placeholderColor: {
        color: '#3D85C6'
    }
});

const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
//https://stackoverflow.com/questions/60382084/material-ui-datepicker-showing-wrong-date




function MailProDatePicker({ placeholder, defaultValue, handleDateChange, disabled, fullWidth = true, showTodayButton = false, okLabel = "", cancelLabel = "", clearLabel = "" }) {

    const classes = useStyles();

    const formatDate = (date) => {
        if (!date) return new Date().toLocaleString();

        // Get the timezone from browser using native methods
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
       // const dateTmp = Date.parse(date.toLocaleString());

        return formatInTimeZone(date, timezone, DEFAULT_DATE_FORMAT) // 2014-10-25 06:46:20-04:00

        // const localDate = convertToLocalTime(dateTmp, {
        //     timeZone: timezone,
        // });

        // return format(localDate, DEFAULT_DATE_FORMAT);
    };

    const updateDate = (selectedDate) => {
        handleDateChange(selectedDate ? formatDate(selectedDate) : null)
    }

    placeholder = placeholder || "Select approximate date"

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                autoOk
                variant="dialog"
                format={"MMM dd, yyyy"}
                value={defaultValue ? parseISO(defaultValue) : null}
                onChange={updateDate}
                disabled={disabled}
                placeholder={placeholder || "Select date"}
                InputProps={{
                    classes: {
                        root: classes.root,
                        input: classes.placeholderColor
                    }
                }}
                InputLabelProps={{
                    classes: {
                        root: classes.label,
                    }
                }}
                clearable={true}
                clearLabel={clearLabel}
                okLabel={okLabel}
                cancelLabel={cancelLabel}
                showTodayButton={showTodayButton}
                allowKeyboardControl={true}
                style={{ fontSize: '10px', color: '#4A7396' }}
                fullWidth={fullWidth}
            />
        </MuiPickersUtilsProvider>
    );
}

//define proptypess
MailProDatePicker.propTypes = {
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    handleDateChange: PropTypes.func,
    disable: PropTypes.bool,
    fullWidth: PropTypes.bool,
    showTodayButton: PropTypes.bool,
    okLabel: PropTypes.string,
    clearLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
}

export default MailProDatePicker;

