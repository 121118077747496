import { teal } from "@material-ui/core/colors";

export default theme => ({
    root: {
        width: '100%',
        height: '1400px',
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
        // display: 'flex',
        // flex: 1,
        // alignItems: 'center',
        // justifyContent: 'center'
    },
    singleComponent: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh'
    },
    errorState: {
        color: theme.palette.error.main,
    },
    blueColor: {
        color: '#012159'
    },
    whiteColor: {
        color: 'white'
    },
    detailsColor: {
        color: '#858585'
    },
    blackColor: {
        color: '#11161A'
    },
    lineHeight: {
        height: '30px'
    },
    lineHeightSemi: {
        height: '25px'
    },
    portletContent: {
        padding: '0'
    },
    invoiceBody: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    headerContent: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        padding: theme.spacing(3)
    },
    headerSection: {
        minHeight: '130px',
        height: 'auto',
        backgroundColor: '#012159',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px 4px 0 0'
    },
    headerLogo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    logoMainTag: {
        height: 'auto',
        width: '200px'
    },
    detailSection: {
        height: '200px',
        width: '100%',
    },
    detailsContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
    },
    detailData: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%'
    },
    detailCharges: {
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'flex-end'
    },
    detailChargesContainer: {
        marginTop: theme.spacing(2),
    },
    chargeTableSpacer: {
        marginTop: theme.spacing(1.5),
    },
    chargeTableTdAlignment: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(0.5)
    },
    chargeDividerMain: {
        height: '1px',
        background: '#012159',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    detailChargesText: {
        fontSize: '16px',
        fontWeight: '500',
        color: '#012159'
    },
    detailChargesTextMain: {
        fontSize: '30px',
        fontWeight: '500',
        color: '#012159'
    },
    listItemText: {
        '&>div>span': {
            fontSize: '14px',
            fontWeight: '400'
        },
        '&>div>p': {
            //marginTop: theme.spacing(1),
            fontSize: '14px',
            fontWeight: '400'
        },
    },
    listItemTextPricing: {
        '&>div>span': {
            fontSize: '14px',
            fontWeight: '400'
        },
        '&>div>p': {
            marginTop: theme.spacing(2),
            fontSize: '14px',
            fontWeight: '400'
        },
    },
    dividerSection: {
        width: '100%'
    },
    dividerMain: {
        height: '4px',
        background: '#012159',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    table: {
        borderSpacing: '0'
    },
    tableSection: {
        width: '100%',
        height: 'auto'
    },
    tablePaper: {
        boxShadow: 'none',
        borderRadius: '0'
    },
    marginTopBorder: {
        borderTop: '2px solid #012159'
    },
    tableLittleDarkColor: {
        backgroundColor: '#F1F1F1'
    },
    footerSection: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    estBoldText: {
        fontWeight: 500
    },
    teal800Color: {
        color: teal[800]
    },
    extraMarginForAlignment: {
        //marginTop: theme.spacing(0.5),
    },
    firstTdContentHight: {
        height: '43px'
    },
    secondTdHeight: {
        height: '40px'
    },
    thirdTdHeight: {
        height: '20px'
    },
    fourthTdHeight: {
        height: '15px'
    },
    pmaCharge: {
        //marginTop: theme.spacing(6.5)
    },
    specialDiscountMargin: {
        //marginTop: theme.spacing(2)
    },
    specialDiscountMarginExtra: {
        marginTop: theme.spacing(5.5)
    }
});