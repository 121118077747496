import React, { Component } from 'react';
import { render } from 'react-dom';
import Card from 'react-credit-cards';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Typography,
    CardActions,
    CardContent,
    Grid,
    TextField
}
    from '@material-ui/core';
import styles from './styles';
import classNames from 'classnames';
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    formatFormData,
    clearNumber
} from './utils';
/**credit card css */
import 'react-credit-cards/es/styles-compiled.css';
import Payment from 'payment';

class CreditCardBox extends Component {
    constructor(props) {
        super(props);
        /**get holder name */
        this.state = {
            values: {
                number: '',
                name: '',
                expiry: '',
                cvc: ''
            },
            errors: {
                number: true,
                name: true,
                expiry: true,
                cvc: true,
            },
            touched: {
                number: false,
                name: false,
                expiry: false,
                cvc: false,
            },
            issuer: '',
            focused: '',
            formData: null,
            isValid: false
        };
    }

    componentDidMount = () => {
        /**change name to holder name */
        let { holdername, cardNumber, expiryMonth, expiryYear, cvc } = this.props;
        const { handleCreditCardData } = this.props;
        const newState = { ...this.state };
        if (holdername && holdername !== '') {
            newState.values.name = holdername;
            newState.touched.name = true;
            newState.errors.name = false;
        }
        /**change card number */
        if (cardNumber && cardNumber !== '') {
            cardNumber = formatCreditCardNumber(cardNumber);
            newState.values.number = cardNumber;
            newState.touched.number = true;
            /**special check for dinersclub card having 16 digit length */
            if(Payment.fns.cardType(clearNumber(cardNumber)) === 'dinersclub' && clearNumber(cardNumber).length === 16){
                newState.errors.number = false;
            }else{
                newState.errors.number = !Payment.fns.validateCardNumber(cardNumber);
            }
            
        }
        /**change expiry */
        if (expiryMonth && expiryMonth !== '' && expiryYear && expiryYear !== '') {
            let expiry = formatExpirationDate(expiryMonth + expiryYear.toString().substring(2));
            newState.values.expiry = expiry;
            newState.touched.expiry = true;
            newState.errors.expiry = !Payment.fns.validateCardExpiry(expiry);
        }
        /**change card number */
        if (cvc && cvc !== '') {
            cvc = formatCVC(cvc);
            newState.values.cvc = cvc;
            newState.touched.cvc = true;
            newState.errors.cvc = !Payment.fns.validateCardCVC(cvc);
        }

        this.setState(newState, () => {
            /**handle credit card dtaa first */
            handleCreditCardData(newState.values);
            this.validateForm('name', holdername);
        });
    };


    handleCallback = ({ issuer }, isValid) => {
        if (isValid) {
            this.setState({ issuer });
        }
    };

    handleInputFocus = ({ target }) => {
        this.setState({
            focused: target.name,
        });
    };

    handleInputChange = ({ target }) => {
        if (target.name === 'number') {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === 'expiry') {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === 'cvc') {
            target.value = formatCVC(target.value);
        }
        /**get data from props */
        const newState = { ...this.state };
        newState.values[target.name] = target.value;
        newState.touched[target.name] = true;
        const { handleCreditCardData } = this.props;
        this.setState(newState, () => {
            handleCreditCardData(newState.values);
            this.validateForm(target.name, target.value);
        });
    };

    validateForm = (field, value) => {
        const newState = { ...this.state };
        let isValid = true;
        if (field === 'number') {
            if(Payment.fns.cardType(clearNumber(value)) === 'dinersclub' && clearNumber(value).length === 16){
                isValid = true;
            }else{
            isValid = Payment.fns.validateCardNumber(value);
            }
        } else if (field === 'expiry') {
            isValid = Payment.fns.validateCardExpiry(value);
        } else if (field === 'cvc') {
            isValid = Payment.fns.validateCardCVC(value);
        } else if (field === 'name') {
            isValid = value === '' ? false : true;
        }
        /**set error to true accordingly. assign reverse because isvalid false means have erros */
        newState.errors[field] = !isValid;
        /**check for all field */
        let allValid = true;
        for (let key in newState.errors) {
            if (newState.errors[key]) {
                allValid = false;
            }
        }
        newState.isValid = allValid;
        /**send validity to parent */
        const { handleCreditCardValidity } = this.props;
        this.setState(newState, () => {
            handleCreditCardValidity(allValid);
        });
    };

    handleSubmit = e => {
        // e.preventDefault();
        // const { issuer } = this.state;
        // const formData = [...e.target.elements]
        //     .filter(d => d.name)
        //     .reduce((acc, d) => {
        //         acc[d.name] = d.value;
        //         return acc;
        //     }, {});

        // this.setState({ formData });
        // this.form.reset();
    };

    render() {
        const { focused, values, errors, touched, issuer, formData } = this.state;
        const { classes } = this.props;

        return (
            <div key="Payment">
                <div className="App-payment">
                    <Grid container className={classes.grid}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div className={classes.cardHolder}>
                                <Card
                                    number={values.number}
                                    name={values.name}
                                    expiry={values.expiry}
                                    cvc={values.cvc}
                                    focused={focused}
                                    callback={this.handleCallback}
                                />
                            </div>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container className={classes.grid}>
                                <Grid item lg={12}>
                                    <div className={classes.field}>
                                        <TextField
                                            className={classNames(
                                                classes.textField
                                            )}
                                            label="Credit Card Number"
                                            margin="dense"
                                            type="tel"
                                            pattern="[\d| ]{16,22}"
                                            required
                                            name="number"
                                            value={values.number}
                                            //variant="outlined"
                                            onFocus={event => this.handleInputFocus(event)}
                                            error={touched.number && errors.number}
                                            onBlur={event => this.validateForm('number', values.number)}
                                            onChange={event => {
                                                this.handleInputChange(event);
                                            }}
                                        />
                                        {touched.number && errors.number && (
                                            <Typography className={classes.fieldError} variant="body2">
                                                {'Invalid card number'}
                                            </Typography>
                                        )}
                                    </div>
                                </Grid>
                                <Grid item lg={12}>
                                    <div className={classes.field}>
                                        <TextField
                                            className={classNames(
                                                classes.textField,
                                                classes.dropdownTopMargin
                                            )}
                                            label="Your Name"
                                            margin="dense"
                                            type="text"
                                            required
                                            name="name"
                                            value={values.name}
                                            //variant="outlined"
                                            onFocus={event => this.handleInputFocus(event)}
                                            error={touched.name && errors.name}
                                            onBlur={event => this.validateForm('name', values.name)}
                                            onChange={event => {
                                                this.handleInputChange(event);
                                            }}
                                        />
                                        {touched.name && errors.name && (
                                            <Typography className={classes.fieldError} variant="body2">
                                                {'Invalid name'}
                                            </Typography>
                                        )}
                                    </div>
                                </Grid>
                                <Grid item lg={12}>
                                    <Grid container className={classes.grid}>
                                        <Grid item lg={4}>
                                            <div className={classes.field}>
                                                <TextField
                                                    className={classNames(
                                                        classes.textField,
                                                        classes.dropdownTopMargin6Unit,
                                                        classes.expiryDate
                                                    )}
                                                    label="Expiry(MM / YY)"
                                                    margin="dense"
                                                    type="tel"
                                                    required
                                                    name="expiry"
                                                    pattern="\d\d/\d\d"
                                                    value={values.expiry}
                                                    //variant="outlined"
                                                    onFocus={event => this.handleInputFocus(event)}
                                                    error={touched.expiry && errors.expiry}
                                                    onBlur={event => this.validateForm('expiry', values.expiry)}
                                                    onChange={event => {
                                                        this.handleInputChange(event);
                                                    }}
                                                />
                                                {touched.expiry && errors.expiry && (
                                                    <Typography className={classes.fieldError} variant="body2">
                                                        {'Invalid expiry date'}
                                                    </Typography>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item lg={3}>
                                            <div className={classes.field}>
                                                <TextField
                                                    className={classNames(
                                                        classes.textField,
                                                        classes.dropdownTopMargin6Unit,
                                                        classes.cvcNumber
                                                    )}
                                                    label="CVC"
                                                    margin="dense"
                                                    type="tel"
                                                    required
                                                    name="cvc"
                                                    value={values.cvc}
                                                    pattern="\d{3,4}"
                                                    //variant="outlined"
                                                    onFocus={event => this.handleInputFocus(event)}
                                                    error={touched.cvc && errors.cvc}
                                                    onBlur={event => this.validateForm('cvc', values.cvc)}
                                                    onChange={event => {
                                                        this.handleInputChange(event);
                                                    }}
                                                />
                                                {touched.cvc && errors.cvc && (
                                                    <Typography className={classes.fieldError} variant="body2">
                                                        {'Invalid cvc'}
                                                    </Typography>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item lg={5}></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
};

CreditCardBox.propTypes = {
    classes: PropTypes.object.isRequired,
    holderName: PropTypes.string.isRequired,
    cardNumber: PropTypes.string.isRequired,
    expiryMonth: PropTypes.string.isRequired,
    expiryYear: PropTypes.string.isRequired,
    cvc: PropTypes.string.isRequired,
    handleCreditCardData: PropTypes.func.isRequired,
    handleCreditCardValidity: PropTypes.func.isRequired
};

CreditCardBox.defaultProps = {
    holderName: '',
    cardNumber: '',
    expiryMonth: '',
    expiryYear: '',
    cvc: '',
    handleCreditCardData: () => { },
    handleCreditCardValidity: () => { }
};

export default withStyles(styles)(CreditCardBox);
