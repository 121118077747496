import React, { Fragment, Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button, Typography, Divider, Container, TextField, CircularProgress, Grid, ListItem, Checkbox, ListItemText, ListItemIcon, Fab, List, DialogActions } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { propertyUtil } from '../../PropertyUtil';
import Slide from '@material-ui/core/Slide';
import classNames from 'classnames';
import styles from './styles';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { pmaActions } from '_store/_actions';
import { GetStateStorage } from 'common/storage';
import { agentBarSwitchClosureV2, MailProBlockedEditingDisabledBar, MailProCloseButton } from './MailProHTMLShortMethods';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class BlockPropertyDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      submitted: false,
      error: "",
      isLoading: false,
      isBlocked: false,
      stateBlocked: "",
      //isDNM: false,
      blockOption: [
        "Returned-Mail [Undeliverable]",
        "Homeowner-Blocked",
        "Agent-Blocked",
        "Another Real Estate Agent",
        "I have opted not to add this Homeowner"
      ]
    };
  }

  handleClickOpen = () => {
    /**check for default global PS */
    let { isMailProLocked } = this.props;
    this.setState({
      open: true,
      stateBlocked: (this.props.blocked ? this.props.blocked : ''),
      isBlocked: isMailProLocked
    });
  };

  handleClose = (isSuccess = false) => {
    this.setState({ open: false, ps: "", submitted: false, error: "", newNickname1: "", newNickname2: "", stateBlocked: "" });
  };


  /**Old Methods */

  onOptionChange = option => {
    this.setState((prevState, props) => {
      return {
        stateBlocked: (prevState.stateBlocked !== option ? option : ''),
        submitted: false,
        error: ""
      };
    });
  };

  onReset = () => {
    if (this.state.isBlocked) {
      this.setState({
        error: "Mailpro has been locked for this mailing."
      });
    } else {
      this.setState(
        {
          stateBlocked: null,//if anything selected by the agent
          submitted: false
        },
        () => {
          this.onSubmit("");
        }
      );
    }
  };

  async onSubmit(e) {
    /**check for block status */
    const { isBlocked, stateBlocked } = this.state;
    if (isBlocked) {
      this.setState({
        error: "Mailpro has been locked for this mailing."
      });
    } else if (stateBlocked === '') {
      this.setState({
        error: "Please select a block reason."
      });
    } else {
      this.setState(
        {
          isLoading: true
        },
        async () => {
          setTimeout(async () => {
            const { propertyData, mlsAreas, currentArea } = this.props;
            const { mlsAreaID } = currentArea;
            const {
              areaID,
              mailerProID,
              changeDetails,
              agentID,
              mailingStatus,
              imbarcode,
              isPropertyMapped,
              numericIMBarcode,
              parcelNumber
            } = this.props;
            // //combines the unblocked homeowners + ALL the agents, this will be the total number of pma's we are sending out
            // let totalUnblocked = agentList + unblockedHO;
            //Take the change details of this particular homeowner and it will assign the block status from state. "null" value means that the homeowner is NOT blocked. If the homeowner is blocked, they will not get a mailing
            let updatedChangeDetails = Object.assign({}, changeDetails, {
              blocked: stateBlocked,
              newMailing: false,
              isNewProperty: false,
            });
            /**get the user session data */
            let userData = GetStateStorage();
            let xPropertyData = propertyData.slice();
            let newPropertyData = xPropertyData.map(property => {
              if (property.mailerProID === mailerProID) {
                property.changeDetails = updatedChangeDetails;
              }
              return property;
            });
            /**Need to call updateCompleteMailerProDataAsync becuase have to update overall store counts. for e.g. acitve mailing */
            await this.props.updateCompleteMailerProDataAsync(
              newPropertyData,
              userData && userData.userData ? userData.userData : {}
            );
            
            let actualHomeowners = propertyUtil.getActualHomeowners(propertyData);
            let agentList = propertyUtil.getActualAgents(propertyData).length;
            let unblockedHO = propertyUtil.getActualUnblockedHomeowners(
              actualHomeowners
            ).length;
            //combines the unblocked homeowners + ALL the agents, this will be the total number of pma's we are sending out
            let totalUnblocked = agentList + unblockedHO;
            const hoData = {
              agentID,
              areaID,
              changeDetails: updatedChangeDetails,
              imbarcode,
              isPropertyMapped,
              mailerProID,
              mailingStatus,
              numericIMBarcode,
              parcelNumber,
              listgenUserID: userData.userID ? userData.userID : "",
              numOfPMAs: totalUnblocked
            };
            //api that takes the updated changeDetails object + the other un-nested variables
            await this.props.updateHomeownerProp(hoData);
            
            setTimeout(async () => {
              await this.props.updateRecentUpdatedMailProId(mailerProID);
            }, 10);

            this.setState(
              {
                isLoading: false,
                submitted: true,
                error: ""
              },
              () => {
                this.handleClose(true);
              }
            );
          }, 300);
        }
      );
    }
  }

  render() {
    const { classes, mailingDetails, buttonText, blocked, isCalledFromMailPro } = this.props;
    const { open, submitted, error, blockOption, stateBlocked, isBlocked, isLoading } = this.state;
    //isDNM
    //get the owner first name and last name
    return (
      <Fragment>
        {this.props.children ? (
            <div onClick={this.handleClickOpen}>
              {this.props.children}
            </div>
          ) : (
        <div className="global-ps-body-container tableTdFlex">
          <div className="tableTdFlex-First textCursor" title={"Block/Unblock Homeowner"}>
            {!buttonText ? 
            <p className="blue-text mailProLinkClass pointer quickMarginTop" onClick={this.handleClickOpen} style={{marginLeft: '24px', padding: '8px 2px', fontSize: '10px', width: '50px'}}>
            BLOCK <br/>MAILINGS
        </p>
            // <Button
            //   size="small"
            //   onClick={this.handleClickOpen}
            //   color="primary"
            //   aria-label="Change Block Status"
            //   className={"fabButtonBlock"}
            // >
            //   <MoreHorizIcon />
            //   BLOCK MAILINGS
            // </Button>
              :
              <Typography variant='body2' style={{ cursor: 'pointer' }} onClick={event => (stateBlocked ? this.onReset(event) : this.handleClickOpen(event))} className={classes.newPropertyHO}>{buttonText}
              </Typography>
            }
          </div>
          <div className="tableTdFlex-Second pointer" onClick={this.handleClickOpen}>
            
          </div>
        </div>)}
        {open ?
          <Dialog
            open={true}
            onClose={this.handleClose}
            scroll={"paper"}
            aria-labelledby="propert-view-dialog"
            aria-describedby="propert-view-dialog-description"
            maxWidth={'sm'}
            TransitionComponent={Transition}
            keepMounted
            fullWidth
          >
            <DialogTitle id="scroll-dialog-title">
              <div className={classes.propertyFactHeader}>
                <div></div>
                <div>
                  <Typography variant="h4" align="center">
                    Block Homeowner
                  </Typography>
                  <Typography variant="body1" align="center">
                    If you would like to block this particular homeowner, please select the reason below.
                  </Typography>
                </div>
                <MailProCloseButton onClick={this.handleClose} />
              </div>
            </DialogTitle>
            {/* Ho Dynamic section html begin */}
            {agentBarSwitchClosureV2(classes).call(this)}
            {/* Ho Dynamic section html end */}
            <DialogContent dividers={true}>
              <div>
                <div
                  className={classes.propertyDetailsModal}
                //onClick={props.toggleDetailsModal}
                >
                  <MailProBlockedEditingDisabledBar
                  isBlocked={isBlocked}
                  classes={classes}
                  />
                  <Container>
                    <Fragment>
                      <Grid container className={classes.grid}>
                        <Grid item lg="12" className={classes.blockListTextSection}>
                          <Typography variant="subtitle2" className={classes.chooseResonText}>
                            Choose a block reason:
                          </Typography>
                          <List
                            className={classes.blockReasonList}
                            dense={true}
                          >
                            {blockOption.map((option, key) => {
                              const labelId = `checkbox-list-label-${option.label}`;
                              return (
                                <Fragment>
                                  <ListItem
                                    key={key}
                                    role={undefined}
                                    dense={false}
                                    button
                                    onClick={event => this.onOptionChange(option)}
                                    selected={propertyUtil.getBlockedReasonGroup(stateBlocked) === option}>
                                    <ListItemIcon>
                                      <Checkbox
                                        edge="start"
                                        color="primary"
                                        checked={propertyUtil.getBlockedReasonGroup(stateBlocked) === option}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText className={classes.blockReasonListItemText} component="span" id={labelId} primary={option}
                                      secondary={(option != "Another Real Estate Agent" && option !== "I have opted not to add this Homeowner") ? propertyUtil.getBlockedReasonDescription(option) : ''}
                                    />
                                  </ListItem>
                                  <Divider variant="inset" component="li" />
                                </Fragment>
                              );
                            })}
                          </List>
                        </Grid>
                      </Grid>
                    </Fragment>
                    {/* )} */}
                  </Container>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              {/* {isBlocked ? '' : */}
              <Fragment>
                {isLoading ? (
                  <div className={classNames(classes.fullWidth, classes.cProgressStopBlinking)}>
                    <CircularProgress className={classes.cProgress} />
                  </div>
                ) : (
                  <div className={classes.portletFooterWithMessageDiv}>
                    {(submitted || error) ? <div className={classes.fullWidthMessageDiv}>
                      {submitted && (
                        <Typography variant="subtitle2" align="center">
                          <div
                            className={classNames(
                              classes.greenColor,
                              classes.centerAlign,
                              classes.lightBoldText
                            )}
                          >
                            Submitted Successfully!
                          </div>
                        </Typography>
                      )}
                      {error && (
                        <Typography variant="subtitle2" align="center" style={{ margin: '8px' }}>
                          <div
                            className={classNames(
                              classes.darkRedColor,
                              classes.centerAlign,
                              classes.lightBoldText
                            )}
                          >
                            {error}
                          </div>
                        </Typography>
                      )}
                    </div>
                      : ''}
                    <div className={classes.portletFooter}>
                      <div className={classes.footerButtons}>
                        <div className={classes.footerButtonsLeftRight}>
                          <Button
                            variant="outlined"
                            color="default"
                            onClick={event => this.onReset(event)}
                          >
                            Unblock
                          </Button>
                        </div>
                        <div className={classes.footerButtonsCenter}>
                          <Button
                            variant="contained"
                            color="primary"
                            //disabled={stateBlocked === ''}
                            onClick={event => this.onSubmit(event)}
                          >
                            Submit
                          </Button>
                        </div>
                        <div className={classes.footerButtonsLeftRight}></div>
                      </div>
                    </div>
                  </div>)
                }
              </Fragment>
              {/* } */}
            </DialogActions>
          </Dialog>
          : ''}
      </Fragment>
    );
  };
};

function mapStateToProps(state) {
  return {
    currentArea: state.pma.currentArea,
    mlsAreas: state.pma.mlsAreas
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateHomeownerProp: hoData => {
      dispatch(pmaActions.updateHomeownerProp(hoData));
    },
    updateCompleteMailerProDataAsync: (propertyData, agentData) => {
      dispatch(pmaActions.updateCompleteMailerProDataAsync(propertyData, agentData));
    },
    updateRecentUpdatedMailProId: mailerProID => {
      dispatch(pmaActions.updateRecentUpdatedMailProId(mailerProID));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withStyles(styles))(BlockPropertyDialog));