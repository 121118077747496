import React, { Component } from "react";
import QVThreeFirstPage from "./QVThreeFirstPage";
import QVThreeSecondPage from "./QVThreeSecondPage";
import PropTypes from "prop-types";

class QVThree extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./qv3print.scss");
    } else {
      require("./qv3styles.scss");
    }
  }
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      currentArea,
      mlsAreas,
      coverPageDetails,
      aprilData,
      flipBook,
      demoAccountHOName,
      demoAccountCoverPhoto,
      demoAccountAreaName,
      demoAccountCity,
      quarterlyAreaTable,
      testimonials,
      typeOfVersion,
      handlePropertyHighlightDialog,
      bubbleBoxes,
    } = this.props;
    console.log("qv3", this.props);
    return (
      <div>
        <div
          className={printVersion ? "qv3-print-page" : "qv3-pma-editor-page"}
        >
          <QVThreeFirstPage
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version={version}
            coverPageDetails={coverPageDetails}
            aprilData={aprilData}
            demoAccountHOName={demoAccountHOName}
            demoAccountCoverPhoto={demoAccountCoverPhoto}
            demoAccountAreaName={demoAccountAreaName}
            testimonials={testimonials}
            typeOfVersion={typeOfVersion}
            bubbleBoxes={bubbleBoxes}
          />
          <QVThreeSecondPage
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version={version}
            aprilData={aprilData}
            demoAccountCity={demoAccountCity}
            demoAccountHOName={demoAccountHOName}
            quarterlyAreaTable={quarterlyAreaTable}
          />
        </div>
      </div>
    );
  }
}

export default QVThree;
