import Base from 'common/constants';
import querystring from 'querystring';
import { GetAuthorization } from 'common/helpers';
import { GetOAuthTokenByKey } from 'common/storage';
import axios from 'common/interceptors';

export const GetOfficeForDesignatedBroker = (designatedBrokerID) => {
    return new Promise(async (resolve, reject) => {
        try {
            axios
                .get(Base.GET_OFFICE_FOR_DESIGNATED_BROKER, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${GetOAuthTokenByKey('access_token')}`,
                        'Access-Control-Allow-Origin': '*',
                    },
                    data: {},
                    params: {
                        designatedBroker: designatedBrokerID,
                    },
                })
                .then(res => {
                    if (Array.isArray(res.data)) {
                        resolve(res.data);
                    } else {
                        reject('Unable to process request.');
                    }
                })
                .catch(err => {
                    if (
                        err.response &&
                        err.response.data &&
                        err.response.data.error_description
                    )
                        reject(err.response.data.error_description);
                    else reject('OOPS!!! something went wrong.');
                });
        } catch (err) {
            reject('Something went wrong. Unable to process request.');
        }
    });
    //////////
    // return new Promise((resolve, reject) => {
    //     try {
    //         fetch(`${Base.GET_OFFICE_FOR_DESIGNATED_BROKER}/?designatedBroker=` + designatedBrokerID, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${GetOAuthTokenByKey('access_token')}`,
    //                 "Cache-Control": 'no-cache'
    //             }
    //         }).then(response => response.json())
    //             .then((res) => {
    //                 if (Array.isArray(res)) {
    //                     resolve(res);
    //                 } else {
    //                     reject("Unable to process request.");
    //                 }
    //             }).catch((err) => {
    //                 if (err.response && err.response.data && err.response.data.error_description)
    //                     reject(err.response.data.error_description);
    //                 else
    //                     reject('OOPS!!! something went wrong.');
    //             });
    //     } catch (err) {
    //         reject('Something went wrong. Unable to process request.');
    //     }
    // });
};

export const GetAgentsForOffice = (officeID) => {
    return new Promise(async (resolve, reject) => {
        try {
            axios
                .get(Base.GET_AGENT_FOR_OFFICE, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${GetOAuthTokenByKey('access_token')}`,
                        'Access-Control-Allow-Origin': '*',
                    },
                    data: {},
                    params: {
                        officeID,
                    },
                })
                .then(res => {
                    if (Array.isArray(res.data)) {
                        resolve(res.data);
                    } else {
                        reject('Unable to process request.');
                    }
                })
                .catch(err => {
                    if (
                        err.response &&
                        err.response.data &&
                        err.response.data.error_description
                    )
                        reject(err.response.data.error_description);
                    else reject('OOPS!!! something went wrong.');
                });
        } catch (err) {
            reject('Something went wrong. Unable to process request.');
        }
    });
    // return new Promise((resolve, reject) => {
    //     try {
    //         fetch(`${Base.GET_AGENT_FOR_OFFICE}/?officeID=` + officeID, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${GetOAuthTokenByKey('access_token')}`,
    //                 "Cache-Control": 'no-cache'
    //             }
    //         }).then(response => response.json())
    //             .then((res) => {
    //                 if (Array.isArray(res)) {
    //                     resolve(res);
    //                 } else {
    //                     reject("Unable to process request.");
    //                 }
    //             }).catch((err) => {
    //                 if (err.response && err.response.data && err.response.data.error_description)
    //                     reject(err.response.data.error_description);
    //                 else
    //                     reject('OOPS!!! something went wrong.');
    //             });
    //     } catch (err) {
    //         reject('Something went wrong. Unable to process request.');
    //     }
    // });
};