import palette from "theme/palette";

export default theme => ({
  root: {
    //borderBottom: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    zIndex: 1200,
    flexGrow: 1,
    color: theme.palette.common.white,
  },
  appBar: {
    //linear-gradient(45deg, #dee2ef, #6ad9c9 100%)
    //linear-gradient(45deg, rgba(42,27,161,0.1), rgba(29,210,177,0.9) 100%)
    background: "white",
    backgroundSize: 'cover'
  },
  grow: {
    flexGrow: 0.4,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    }
  },
  colorWhite: {
    //color: theme.palette.common.white,
    textTransform: "capitalize",
  },
  middleMenus: {
    display: 'flex',
    color: theme.palette.common.white,
    flexGrow: 0.6,
    '&>button': {
      marginLeft: theme.spacing(1)
    }
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  menuButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
    '&>svg:nth-child(1)': {
      width: '26px',
      height: '26px'
    },
    '&>span:nth-child(2)': {
      fontSize: '16px'
    }
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: '64px',
    //maxWidth: '100vw',
    [theme.breakpoints.down('md')]: {
      minHeight: '64px',
      //maxWidth: '100vw',
    }
  },
  title: {
    marginLeft: theme.spacing(0),
    fontWeight: '500'
  },
  // menuButton: {
  //   marginLeft: '-4px'
  // },
  notificationsButton: {
    marginLeft: 'auto'
  },
  accountButton: {
    marginLeft: 'auto',
    '&:hover': {
      borderRadius: '10%'
    }
  },
  inlineImageHolder: {
    display: 'inline-flex',
    width: '100%'
  },
  imageWrapper: {
    height: '52px',
    width: '48px',
    border: 'none',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(0.5)
  },
  imageWrapperAlt: {
    height: '52px',
    width: '144px',
    border: 'none',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  image: {
    width: '100%',
  },
  underlineButtonMenu: {
    borderBottom: '2.4px solid #012159',
    paddingBottom: theme.spacing(1),
  },
  leftMargin1: {
    marginLeft: theme.spacing(1)
  },
  menuButtonMargin: {
    //height: theme.spacing(8)
  },
  // hoverButton: {
  //   '&:hover': {
  //     backgroundColor: palette.latestLightGreen.main
  //   }

  // },
  accountNameMain: {
    //hide when less than 1050px
    [theme.breakpoints.down(1080)]: {
      display: 'none'
    }
  },
  accountNameDivider: {
    margin: theme.spacing(1.5, 1.5),
    //1050 is less then md also...md starts from 1200
    [theme.breakpoints.down(1080)]: {
      margin: theme.spacing(1.5, 1.5),
      display: 'none'
    }
  },
});
