import { green, red, blueGrey, grey, yellow, amber } from "@material-ui/core/colors";
import palette from "theme/palette";

export default theme => ({
  root: {
    fontFamily: "objektiv-mk2 !important",
  },
  //add group details
  objectiveMk2: {
    fontFamily: "objektiv-mk2",
  },
  displayRow: {
    fontFamily: "objektiv-mk2",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    gap: '16px'
  },
  addGroupTitle: {
    lineHeight: "18px",
    letterSpacing: "0.01em",
    fontWeight: 500,
    color: 'white'
  },
  italic: {
    fontStyle: 'italic'
  },
  fontWeight500: {
    fontWeight: 500
  },
  currentMailProColor: {
    color: palette.primary.currentMailProColor
  },
  currentGroupName: {
    fontSize: '14px',
    fontWeight: 500,
    color: palette.primary.currentMailProColor
  },
  newGroupNameBoxMain: {
    margin: '5% 10%',
    fontSize: '14px'
  },
  newGroupNameBox: {
    border: '1px #D5DBDD solid',
    borderRadius: '4px',
    padding: '12px',
    backgroundColor: '#E9F7FC'
  },
  editorCharacterLimitContainer: {
    marginTop: '4px',
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&>*": {
      fontSize: "15px",
      fontStyle: "italic",
      color: palette.primary.currentMailProColor,
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 32px'
  },
  //add homeowner to group
  addHoTitle: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: "18px",
    letterSpacing: "0.01em",
    paddingTop: '16px',
    paddingBottom: '16px',
    color: 'white'
  },
  dialogSubtitle: {
    fontSize: '14px',
    margin: '24px 0',
    textAlign: 'center'
  },
  addHoToGroupBoxMain: {
    margin: '5% 10%',
    fontSize: '14px'
  },
  addHoToGroupBox: {
    border: '1px #D5DBDD solid',
    borderRadius: '4px',
    padding: '12px',
    backgroundColor: '#E9F7FC',
    gap: '16px'
  },
  checkboxLabel: {
    fontSize: '13px',
    lineHeight: '11px',
    letterSpacing: '0.01em',
    fontWeight: 500,
  },
  addHoToGroupFieldset: {
    paddingLeft: '32px'
  },
  notAssginedToAnyGroup: {
    color: '#A34546',
    textAlign: 'center',
    margin: '12px 0'
  },
  //ListOfHomeownersInGroup
  listOfHosDialog: {
    width: '750px'
  },
  listOfHosDialogDisplayRow: {
    gap: '16px'
  },
  listOfHosContainer: {
    width: '100%',
    fontSize: '14px'
  },
  listHOAgent: {
    lineHeight: "12px",
    letterSpacing: "0.01em",
    fontSize: '14px'
  },
  removeButton: {
    color: '#A34546',
    cursor: 'pointer',
    fontSize: '14px',
    textDecoration: 'underline',
    textDecorationColor: '#D7D6D5'
  },
  siteMailAddress: {
    lineHeight: "12px",
    letterSpacing: "0.01em",
    fontWeight: 400,
    fontSize: '14px'
  },
  listHoRow: {
    '&>tr:nth-child(1)': {
      borderTop: '1px solid #D1D6D7'
    },
    '&>tr': {
      borderBottom: '1px solid #D1D6D7'
    }
  },
  listHoTableBody: {
    '&>tr:nth-child(odd)': {
      color: '#F3F0E8'
    },
    '&>tr:nth-child(odd):hover': {
      color: '#F3F0E8'
    }
  },
  //RemoveHomeownerFromGroup
  removeHOTitle: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.01em',
    textTransform: 'uppercase',
    fontWeight: 500,
    fontStyle: 'italic',
    color: '#FFFFFF',
  },
  redErrorText: {
    color: red[600],
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    margin: '32px 0'
  },
  //ManageAGroup
  manageGroupButtons: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  manageGroupTitle: {
    lineHeight: "20px",
    letterSpacing: "0.01em",
    fontWeight: 500,
    color: 'white'
  },
  manageGroupHeader: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.01em',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    fontWeight: 500,
    fontStyle: 'italic'
  },
  manageGroupSubHeader: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    fontWeight: 500,
    color: '#FFFFFF',
  },
  manageGroupContainer: {
    fontFamily: "objektiv-mk2",
    display: 'flex',
    width: '100%', 
    flexDirection: 'column',
  },
  manageGroupCustomPS: {
    background: '#E9F7FE',
    border: '1px solid #8EA2AD',
    padding: '12px',
    fontSize: '15px',
    letterSpacing: '0.01em',
    borderRadius: '4px',
    textAlign: 'center',
  },
  manageGroupBlackLinks: {
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.01em',
    color: '#000000',
  },
  manageGroupDelete: {
    color: '#922324',
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.01em',
  },
  //HoGroups
  hoGroupsMain: {
    border: '1px solid #B2D5E9',
    backgroundColor: '#B2D5E9',
    //box shadow for this color #B2D5E9
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: '4px',
    fontFamily: 'inherit !important',
    borderRadius: '4px',
  },
  hoGroupsHeader: {
    fontSize: '12px',
    marginBottom: '6px' 
  },
  hoGroupsBoxItem: {
    marginBottom: '6px',
    textDecoration: 'underline',
    fontSize: '12px',
    cursor: 'pointer',
    fontWeight: 500
  }
});
