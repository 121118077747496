export default theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        //padding: theme.spacing.unit * 3,
        marginTop: theme.spacing(8),
        backgroundColor: theme.palette.background.default,
    },
    grid: {
        height: '100%'
    },

    displayFlex: {
        // display: 'flex',
        // justifyContent: "center",
        // alignItems: "center",
        textAlign: "center"
    },
    displayFlexText: {
        marginTop: theme.spacing(2)
    }
});