import React, { Component } from "react";

class Quadrant extends Component {
  render() {
    const { title, text } = this.props;
    return (
      // <div className="quadrant-body" key={title}>
      <div className="quadrant-section">
        <div className="quadrant-section-title">{title}</div>
        <div className="quadrant-section-text">
          <div>{text}</div>
        </div>
      </div>
      // </div>
    );
  }
}

export default Quadrant;
