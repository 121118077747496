import palette from "theme/palette";
import { red } from "@material-ui/core/colors";

export default theme => ({
  dialogTitle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'block',
    paddingLeft: theme.spacing(3),
    textAlign: 'center'
  },
  title: {
    fontWeight: '500'
  },
  taglineDialogPaper: {
    maxWidth: '1100px'
  },
  subtitle: {
    color: palette.text.secondary,
    //marginTop: theme.spacing(0.5),
    fontWeight: '400'
  },
  subtitleSpace: {
    marginTop: theme.spacing(1),
  },
  dialogContent: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: palette.grey,
    fontWeight: 500
  },
  uploadButton: {
    backgroundColor: theme.palette.primary.mediumLight,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  requiredFields: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(2.5),
    padding: 0,
    marginBottom: -theme.spacing(3),
  },
  field: {
    marginLeft: theme.spacing(2),
  },
  areaGrid: {
    display: 'flex',
    width: "100%",
    padding: "0 32px",
    alignItems: "center",
    justifyContent: "space-between"
  },
  areaName: {
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center',
    textAlign: 'center',
    marginRight: theme.spacing(3)
  },
  textfieldParentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textfieldContainer: {
    width: '85%'
  },
  textField: {
    width: '100%',
    maxWidth: '100%',
  },
  characterCountContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  taglineCount: {
    fontSize: '12px'
  },
  fieldError: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  dividerTeamProfile: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  footerButtons: {
    position: 'relative',
    display: 'flex'
  },
  portletFooter: {
    display: 'inline-block',
    paddingLeft: theme.spacing(2),
    //paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    //marginTop: theme.spacing(2)
  },
  footerButtonsCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerButtonsLeft: {
    flex: 1,
  },
  footerButtonsRight: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  textAlignRight: {
    textAlign: 'right'
  },
  flashButton: {
    animation: "$new-blinker 2s linear 20",
    animationDuration: '2s',
    '&:hover': {
      animation: '0',
      WebkitAnimation: 0
    }
  },
  "@keyframes new-blinker": {
    "40%": {
      opacity: 0.8
    },
    "85%": {
      opacity: 0.6
    },
    "95%": {
      opacity: 0.4
    }
  },
  flashButton: {
    animation: "$new-blinker 3s linear infinite",
    '&:hover': {
      animation: '0',
      WebkitAnimation: 0
    }
  },
});