import React, { Component } from "react";
import BubbleCompareFirstLeft from "./BubbleCompareFirstLeft";
import BubbleCompareFirstRight from "./BubbleCompareFirstRight";
import YETwoFirstLeft from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoFirstPage/YETwoFirstLeft";
import QVFourFirstLeft from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourFirstLeft";
import V2DebSamuel from "views/Pma/PmaEditor/CustomAgentPages/V2DebSamuel";
import RGB from "views/Pma/PmaEditor/CustomAgentPages/RGB";

class BubbleCompareFirstPage extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      MQCityDetails,
      printVersion,
      mlsAreas,
      version,
      coverPageDetails,
      testimonials,
      demoAccountAreaName,
      quarterlyAreaTable,
      quarterlyCityTable,
      bubbleBoxes,
      isDemo,
      propertyPhotoDetailsDTO,
      handlePropertyHighlightDialog,
      missionStatement,
      quadrantModalOpen,
      quadrantDetails,
    } = this.props;

    const { mlsAreaID } = currentArea;
    const { listgenUserID } = agentData;
    return (
      <div
        className={
          printVersion
            ? "bubble-compare-print-page-container"
            : "pma-page-container bubble-compare-editor-page-container"
        }
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {(printVersion && mlsAreaID === "SBA-10A" && !isDemo) ||
        (printVersion && mlsAreaID === "SBA-10G" && !isDemo) ? (
          <RGB
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version="bubble-compare"
            //  demoAccountHOName={demoAccountHOName}
            coverPageDetails={coverPageDetails}
          />
        ) : listgenUserID === "100585" && !isDemo ? (
          <RGB
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version="bubble-compare"
            //  demoAccountHOName={demoAccountHOName}
            coverPageDetails={coverPageDetails}
          />
        ) : listgenUserID === "100656" ? (
          <QVFourFirstLeft
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            printVersion={printVersion}
            version={version}
            mlsAreas={mlsAreas}
            coverPageDetails={coverPageDetails}
            quarterlyAreaTable={quarterlyAreaTable}
            testimonials={testimonials}
            quadrantModalOpen={quadrantModalOpen}
            quadrantDetails={quadrantDetails}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            missionStatement={missionStatement}
            isDemo={isDemo}
          />
        ) : listgenUserID === "100560" ? (
          <YETwoFirstLeft
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version={version}
            demoAccountAreaName={demoAccountAreaName}
            coverPageDetails={coverPageDetails}
            testimonials={testimonials}
            handlePropertyHighlightDialog={handlePropertyHighlightDialog}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            quarterlyAreaTable={quarterlyAreaTable}
            mlsAreaID={mlsAreaID}
            isDemo={isDemo}
          />
        ) : (
          <BubbleCompareFirstLeft
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            MQCityDetails={MQCityDetails}
            quarterlyAreaTable={quarterlyAreaTable}
            printVersion={printVersion}
            mlsAreas={mlsAreas}
            version={version}
            testimonials={testimonials}
            demoAccountAreaName={demoAccountAreaName}
            coverPageDetails={coverPageDetails}
            quarterlyCityTable={quarterlyCityTable}
            bubbleBoxes={bubbleBoxes}
            isDemo={isDemo}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
          />
        )}
        <BubbleCompareFirstRight
          agentData={agentData}
          currentArea={currentArea}
          MQCityDetails={MQCityDetails}
          printVersion={printVersion}
          version={version}
          hoData={hoData}
          mlsAreas={mlsAreas}
          coverPageDetails={coverPageDetails}
          quarterlyAreaTable={quarterlyAreaTable}
          isDemo={isDemo}
        />
      </div>
    );
  }
}

export default BubbleCompareFirstPage;
