import React, { Fragment, Component, createRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button, Typography, Divider, Container, TextField, CircularProgress, Grid, DialogActions } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { propertyUtil } from '../../PropertyUtil';
import Slide from '@material-ui/core/Slide';
import classNames from 'classnames';
import styles from './styles';
import compose from 'recompose/compose';
import { withStyles, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { pmaActions } from '_store/_actions';
import { GetStateStorage, IsValidSessionSwitch } from 'common/storage';
import Tooltip from '@material-ui/core/Tooltip';
import { getHONamesBaseDataClosureV2, MailProBlockedEditingDisabledBar, MailProCloseButton } from './MailProHTMLShortMethods';
import { agentBarSwitchClosureV2 } from './MailProHTMLShortMethods';
import { isAnyHONameChangedV2 } from './MailProHTMLShortMethods';
import { getHONamesOriginalDataClosureV2 } from './MailProHTMLShortMethods';
import { EllipsisLoader } from '../../../../components/EllipsisLoader/index';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class EditHomeownerDialogV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      newNickname1: "",
      newNickname2: "",
      submitted: false,
      error: "",
      isLoading: false,
      isBlocked: false,
      //edit first and last name variables
      xOwner1First: '',
      xOwner1Last: '',
      xOwner2First: '',
      xOwner2Last: '',
    };
    this.submitButtonRef = createRef();
  }

  handleClickOpen = () => {
    this.setState(prevState => ({
      ...{ open: true },
      ...this.getOpenData()
    }));
    //set the submit event
    window.addEventListener("keyup", this.handleEnterClick);
  }

  handleEnterClick = (event) => {
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      // document.getElementById("myBtn").click();
      this.submitButtonRef.current.click();
    }
  }

  getOpenData = () => {
    let { property, isMailProLocked } = this.props;
    //call the first name and last name seprator methods
    let isValidSwitchSession = IsValidSessionSwitch();
    return {
      ...{
        isBlocked: isMailProLocked,
        isASessionSwitch: isValidSwitchSession,
      },
      ...(getHONamesBaseDataClosureV2()).call(this, property)
    };
    //focus on first element
  };

  handleClose = () => {
    this.setState({
      open: false, ps: "", submitted: false, error: "", newNickname1: "", newNickname2: "", xOwner1First: '',
      xOwner1Last: '',
      xOwner2First: '',
      xOwner2Last: '',
    });
    window.removeEventListener("keyup", this.handleEnterClick)
  };

  onInputChange = e => {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    //we need to set caret position also because we are converting value to capital value it will misplace caret value
    this.setState({
      [e.target.name]: propertyUtil.capitalizeString(e.target.value),
      submitted: false,
      error: ""
    }, () => input.setSelectionRange(start, end));
    /**show agent name preview also */
    //this.agentNamePreview();
  };

  undoHONames = () => {
    this.setState((_, props) => (getHONamesOriginalDataClosureV2()).call(this, props.property));
  };

  async handleLeaveOnSubmit() {
    /**so here case is in case of new mailing Leave As is we are not opening dialog so there would be no values in  xOwner1First, xOwner1Last, xOwner2First, xOwner2Last. so on submit he will send OwnerFirst, OwnerLast as empty. so we first set these values and then call on submit.  */
    this.setState(this.getOpenData(), () => {
      this.onSubmit(false, 'leave_as_it');
    });
  };


  async onSubmit(resetMode = false, newMailingMode = null) {
    /**check for block status */
    const { newNickname1, newNickname2, isASessionSwitch, isBlocked } = this.state;
    if (isBlocked) {
      this.setState({
        error: "Mailpro has been locked for this mailing.",
      });
    } else {
      let { xOwner1First, xOwner1Last, xOwner2First, xOwner2Last } = this.state;
      this.setState(
        {
          isLoading: true
        },
        async () => {
          setTimeout(async () => {
            const {
              areaID,
              mailerProID,
              changeDetails,
              agentID,
              mailingStatus,
              imbarcode,
              isPropertyMapped,
              numericIMBarcode,
              parcelNumber,
              propertyDetails,
              propertyData,
              mailingDetails,
              property
            } = this.props;
            //build the mailing details object
            let updatedMailingDetails = Object.assign({}, mailingDetails);
            //build the change details object
            /**
             * We dont want mark HO names edited in case agent clicked on leave as it as.
             */
            let updatedChangeDetails = Object.assign(
              {},
              changeDetails,
              newMailingMode === "leave_as_it"
                ? {
                    newMailing: false,
                    isNewProperty: false,
                    lgIsOwnerNameModified: false
                  }
                : {
                    lgOwnerFirstName: xOwner1First ? xOwner1First : "-",
                    lgOwnerLastName: xOwner1Last ? xOwner1Last : "-",
                    lgSecondaryOwnerFirstName: xOwner2First
                      ? xOwner2First
                      : "-",
                    lgSecondaryOwnerLastName: xOwner2Last ? xOwner2Last : "-",
                    //set the new mailing flag to false
                    newMailing: false,
                    isNewProperty: false,
                    lgIsOwnerNameModified: true,
                  }
            );
            /**
             * Start processw of get homeowner, blocked ho etc
             */
            const actualHomeowners = propertyData.filter(
              prop => parseInt(prop.agentID) === 0
            );
            //filters out just the agents
            const agentList = propertyData.filter(
              prop => parseInt(prop.agentID) > 0
            ).length;
            //filters out the homeowners that are NOT blocked (the ones we are sending a pma to)
            let unblockedHO = actualHomeowners.filter(prop => {
              if (!prop.changeDetails) {
                return true;
              }
              if (prop.changeDetails && prop.changeDetails.blocked === null) {
                return true;
              }
              if (!prop.changeDetails.blocked) {
                return true;
              }
              return false;
            }).length;
            let totalUnblocked = agentList + unblockedHO;
            /**get user ID */
            let userData = GetStateStorage();
            const hoData = {
              agentID,
              areaID,
              changeDetails: updatedChangeDetails,
              mailingDetails: updatedMailingDetails,
              imbarcode,
              isPropertyMapped,
              mailerProID,
              mailingStatus,
              numericIMBarcode,
              parcelNumber,
              listgenUserID: userData.userID ? userData.userID : "",
              numOfPMAs: totalUnblocked,
              // isFirstNameModified: isAnyFirstNameChanged,
              // isLastNameModified: isAnyLastNameChanged,
              isAgent: (isASessionSwitch ? false : true)
            };
            //for change details
            //call this api if anything got changed in  names if isAnyFirstNameChanged variable is true
            await this.props.updateHomeownerProp(hoData);
            /** no need to call updateCompleteMailerProDataAsync as we dont have to update entire store just a property */
            setTimeout(async () => {
              await this.props.updateRecentUpdatedMailProId(mailerProID);
            }, 10);

            this.setState(
              {
                isLoading: false,
                submitted: true,
                error: ""
              },
              () => {
                this.props.updateEditedNewMailingData({ ...hoData, newMailingMode, ...{ isPropertyAlreadyEdited: this.props.newMailingPropertyEdited } })
                this.handleClose();
              }
            );
          }, 500);
        }
      );
    }
  }

  render() {
    const { classes, mailingDetails, changeDetails, editedHOName, HOCommonSectionHtml, property, newMailingsHOMode, isNewPropertyHOMode, newMailingPropertyEdited, newPropertyHOBottomChildrens, newMailingConfig, propertyDetails } = this.props;
    const { open, submitted, error, newNickname1, newNickname2, isBlocked, isLoading, xOwner1First, xOwner1Last, xOwner2First, xOwner2Last } = this.state;
    //fetch the edited homwowner name
    const { lgOwnerFirstName, lgOwnerLastName, lgSecondaryOwnerFirstName, lgSecondaryOwnerLastName, lgIsOwnerNameModified } = changeDetails ? changeDetails : {};
    //fetch original ho names
    let { OwnerName, SecondaryOwnerName, OwnerFirst, OwnerLast, SecondaryOwnerFirst, SecondaryOwnerLast, SuggestedOwnerName, SuggestedSecondaryOwnerName } = mailingDetails;
    return (
      <Fragment>
        {(newMailingsHOMode || isNewPropertyHOMode) ?
          (isLoading ?
            <EllipsisLoader /> :
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                {newMailingConfig.showPersonalisationButton && <div style={{ marginLeft: '20px' }}>
                  <p className="blue-text mailProLinkClass pointer quickMarginTop" onClick={event => this.handleClickOpen()}>
                    Personalize Homeowner Name
                  </p>
                </div>}
                {/* {newMailingsHOMode && newMailingPropertyEdited ?  */}
                {newMailingConfig.showChildren ? <div style={{ padding: '0px 8px' }}>{this.props.children}</div> : null}
                <div className={classes.marginTop0_5em}>
                  {/* //newMailingsHOMode && !newMailingPropertyEdited */}
                  {newMailingConfig.showLeaveAsItAsButton ? <div className={classNames(classes.leavAsIsBtn)} onClick={event => this.handleLeaveOnSubmit()}>Leave As Is</div> : ''}
                </div>
              </div>
              {newPropertyHOBottomChildrens &&  //isNewPropertyHOMode
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  {newPropertyHOBottomChildrens}
                  <div></div>
                </div>
              }
            </div>
          ) : this.props.children ? (
            <div onClick={this.handleClickOpen}>
              {this.props.children}
            </div>
          ) : (
            <div className="global-ps-body-container tableTdFlex">
              <div className="tableTdFlex-First textCursor">
                <div
                  className="stacked"
                  style={{
                    paddingLeft: '1em',
                    textAlign: 'left',
                  }}>
                  {/* if there is nickname, show the nickname, otherwise just show the full name */}
                  <div className="nickname-table-body">
                    <div>
                      <strong>Original HO Name:</strong>
                      <div>
                        <p>{(OwnerFirst && OwnerFirst !== '-' ? `${OwnerFirst}${OwnerLast && OwnerLast !== "-" ? ' ' + OwnerLast : ''}` : '')}</p>
                        <p>{(SecondaryOwnerFirst && SecondaryOwnerFirst !== "-" ? `${SecondaryOwnerFirst}${SecondaryOwnerLast && SecondaryOwnerLast !== "-" ? ' ' + SecondaryOwnerLast : ''}` : '')}</p>
                      </div>
                    </div>
                    <br/>
                    {SuggestedOwnerName && SuggestedOwnerName != "-" && <div>
                      <strong>PMA Print Name:</strong>
                      <div>
                        <p>{(SuggestedOwnerName && SuggestedOwnerName !== '-' ? `${SuggestedOwnerName}` : '')}</p>
                        <p>{(SuggestedSecondaryOwnerName && SuggestedSecondaryOwnerName !== "-" ? `${SuggestedSecondaryOwnerName}` : '')}</p>
                      </div>
                    </div>}
                    <br />
                    {lgIsOwnerNameModified ?
                      <div className={classNames(classes.hoTableNames, classes.borderPadding44, classes.fitContentWidth, classes.lightGreenBackground)}>
                        <div>
                          <Tooltip title="This is exactly how the Homeowner name will print on their PMA" arrow placement="right-end" classes={{ tooltip: classes.customTooltip }}>
                            <div>
                              <p className={"font-weight-bold"}>Final PMA Print Name:</p>
                              <div>{lgOwnerFirstName && lgOwnerFirstName !== "-" ?
                                `${lgOwnerFirstName}${lgOwnerLastName && lgOwnerLastName !== "-" ? ' ' + lgOwnerLastName : ""}`
                                : ''}
                              </div>

                              <div>{lgSecondaryOwnerFirstName && lgSecondaryOwnerFirstName !== "-" ?
                                `${lgSecondaryOwnerFirstName}${lgSecondaryOwnerLastName && lgSecondaryOwnerLastName !== "-" ? ' ' + lgSecondaryOwnerLastName : ""}`
                                : ''}
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                      : <div></div>}
                  </div>
                </div>
                {this.props && this.props.blocked && this.props.blocked !== null ? (
                  <div className={"dnm-blue-background"}>
                    <p className="dnm-block-reason">
                      <strong>BLOCKED REASON:</strong>
                    </p>
                    <br />
                    {this.props.blocked.toLowerCase() === "dnm" ? (
                      <p>
                        <Fragment>
                          Homeowner is registered
                          <br />
                          with the county as:
                          <br />
                          <strong>DO NOT MAIL</strong>
                        </Fragment>
                      </p>
                    ) : (this.props.blocked.toLowerCase() === "active") ? ( // || this.props.blocked.toLowerCase() === "another real estate agent"
                      <Fragment>
                        <p>
                        ACTIVELY LISTED by
                        <br/>
                         {(propertyDetails && propertyDetails.listingAgentFullName) ? <strong>{propertyDetails.listingAgentFullName}&nbsp;-&nbsp;{propertyDetails.listingOfficeName}</strong> : <strong>Another Agent</strong>}
                        </p>
                      </Fragment>
                    ) : (this.props.blocked.toLowerCase() === "duplicate mailing addresses") ? ( 
                      <Fragment>
                        <p>
                        Homeowner owns multiple properties
                        <br/>
                        <br/>
                        <strong>
                        DUPLICATE MAILING ADDRESS
                        </strong>
                        </p>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <p
                          className={
                            this.props.blocked === "Delete"
                              ? "deleteBlockStatus"
                              : ""
                          }
                        >
                          <strong>
                            {propertyUtil
                              .getBlockedReasonDescription(this.props.blocked)
                              .toUpperCase()}
                          </strong>
                        </p>
                      </Fragment>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="tableTdFlex-Second pointer" onClick={this.handleClickOpen}>
                <p className="blue-text mailProLinkClass pointer">
                  Personalize
                  <br />
                  Homeowner Name
                </p>
              </div>
            </div>)}
        {open ?
          <Dialog
            open={true}
            onClose={this.handleClose}
            scroll={"paper"}
            aria-labelledby="propert-view-dialog"
            aria-describedby="propert-view-dialog-description"
            maxWidth={'md'}
            TransitionComponent={Transition}
            keepMounted
          //fullWidth
          >
            <DialogTitle id="scroll-dialog-title">
              <div className={classes.propertyFactHeader}>
                <div></div>
                <div>
                  <Typography variant="h4" align="center">
                    Personalize Homeowner's Names
                  </Typography>
                </div>
                <MailProCloseButton onClick={this.handleClose} />
              </div>
            </DialogTitle>
            {/* Agent bar switching begin */}
            {agentBarSwitchClosureV2(classes, true).call(this)}
            {/* Agent bar switching ended */}
            <DialogContent dividers={true} className={classes.dialogContentPadding}>

              <div>
                <div
                  className={classNames(classes.propertyDetailsModal, classes.propertyDetailsModalMinWidth)}
                //onClick={props.toggleDetailsModal}
                >
                  <MailProBlockedEditingDisabledBar
                  isBlocked={isBlocked}
                  classes={classes}
                  />
                  <Container>
                    <Typography variant="body1" align="right" className={classNames(classes.requiredFieldMessage)}>* Required Fields</Typography>
                    <Grid container className={classes.grid}>
                      <Grid item lg="12" className={classes.hoNameTextSection}>
                        <Typography variant="h6" align="center">
                          You can EDIT / PERSONALIZE Homeowner's names by typing in the spaces below.
                        </Typography>
                        {/* <Typography variant="body1" align="center">
                          {propertyUtil.emptyValueCheck(OwnerFirst)}
                          {propertyUtil.emptyValueCheck(OwnerLast) ? `, ${propertyUtil.emptyValueCheck(OwnerLast)}` : ''}
                        </Typography> */}
                      </Grid>
                    </Grid>
                    <Grid container className={classNames(classes.grid, classes.hoOnlyDialog)}>
                      <Grid item lg="6" md="6" sm="12" xs="12">
                        <div className={classNames(classes.mailignField)}>
                          <Typography variant="body1" className={classes.hoNameTextSection}>
                            <span className={classes.lightBoldText}>Homeowner #1 First Name:</span>&nbsp;&nbsp;&nbsp;<Typography color="primary" variant="body1" component="span">{OwnerFirst && OwnerFirst !== "-" ? OwnerFirst : '---'}</Typography>
                            {/* {ogHoFirstName.oneHO ? ogHoFirstName.oneHO : '---'} */}
                          </Typography>
                          <TextField
                            label={"Type Edited Homeowner First Name here"}
                            id="outlined-first-ho-first-name-1"
                            className={classes.textField}
                            margin="dense"
                            variant="outlined"
                            name="xOwner1First"
                            onChange={this.onInputChange}
                            value={xOwner1First}
                            required={true}
                            autoComplete='off'
                            inputProps={{
                              // className: classNames({
                              //   [classes.nicknameEdited]: (OwnerFirst !== xOwner1First),
                              // }),
                              autoComplete: 'off'
                            }}
                            autoFocus={true}
                          />
                          <Typography variant="body2" className={classes.darkRedColor}>{(!xOwner1First) ? 'Homeowner # 1 first name is required' : ''}</Typography>
                        </div>
                      </Grid>
                      <Grid item lg="6" md="6" sm="12" xs="12">
                        <div className={classNames(classes.mailignField)}>
                          <Typography variant="body1" className={classes.hoNameTextSection}>
                            <span className={classes.lightBoldText}>Homeowner #1 Last Name:</span>&nbsp;&nbsp;&nbsp;<Typography color="primary" variant="body1" component="span">{OwnerLast && OwnerLast !== "-" ? OwnerLast : '---'}</Typography>
                            {/* {ogHoSecondName.oneHO ? ogHoSecondName.oneHO : '---'} */}
                          </Typography>
                          <TextField
                            label={"Type Edited Homeowner Last Name here"}
                            id="outlined-first-ho-second-name-1"
                            className={classes.textField}
                            margin="dense"
                            variant="outlined"
                            name="xOwner1Last"
                            onChange={this.onInputChange}
                            value={xOwner1Last}
                            autoComplete='off'
                            inputProps={{
                              // className: classNames({
                              //   [classes.nicknameEdited]: (OwnerLast !== xOwner1Last),
                              // }),
                              autoComplete: 'off'
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container className={classNames(classes.grid, classes.hoOnlyDialog)}>
                      <Grid item lg="6" md="6" sm="12" xs="12">
                        <div className={classNames(classes.mailignField)}>
                          <Typography variant="body1" className={classes.hoNameTextSection}>
                            <span className={classes.lightBoldText}>Homeowner #2 First Name:</span>&nbsp;&nbsp;&nbsp;<Typography color="primary" variant="body1" component="span">{SecondaryOwnerFirst && SecondaryOwnerFirst !== "-" ? SecondaryOwnerFirst : '---'}</Typography>
                            {/* {ogHoFirstName.twoHO ? ogHoFirstName.twoHO : '---'} */}
                          </Typography>
                          <TextField
                            label={"Type Edited Homeowner First Name here"}
                            id="outlined-second-ho-first-name-1"
                            className={classes.textField}
                            margin="dense"
                            variant="outlined"
                            name="xOwner2First"
                            onChange={this.onInputChange}
                            value={xOwner2First}
                            autoComplete='off'
                            inputProps={{
                              // className: classNames({
                              //   [classes.nicknameEdited]: (SecondaryOwnerFirst !== xOwner2First)
                              // }),
                              autoComplete: 'off'
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item lg="6" md="6" sm="12" xs="12">
                        <div className={classNames(classes.mailignField)}>
                          <Typography variant="body1" className={classes.hoNameTextSection}>
                            <span className={classes.lightBoldText}>Homeowner #2 Last Name:</span>&nbsp;&nbsp;&nbsp;<Typography color="primary" variant="body1" component="span">{SecondaryOwnerLast && SecondaryOwnerLast !== "-" ? SecondaryOwnerLast : '---'}</Typography>
                            {/* {ogHoSecondName.twoHO ? ogHoSecondName.twoHO : '---'} */}
                          </Typography>
                          <TextField
                            label={"Type Edited Homeowner Last Name here"}
                            id="outlined-second-ho-second-name-2"
                            className={classes.textField}
                            margin="dense"
                            variant="outlined"
                            name="xOwner2Last"
                            onChange={this.onInputChange}
                            value={xOwner2Last}
                            autoComplete='off'
                            inputProps={{
                              // className: classNames({
                              //   [classes.nicknameEdited]: (SecondaryOwnerLast !== xOwner2Last)
                              // }),
                              autoComplete: 'off'
                            }}
                          />
                          <Typography variant="body2" className={classes.darkRedColor}>{(!xOwner2First && xOwner2Last) ? 'Homeowner # 2 first name is required to fill homeowner # 2 last name' : ''}</Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </DialogContent>

            <DialogActions>
              <Fragment>
                {isLoading ? (
                  <div className={classNames(classes.fullWidth, classes.cProgressStopBlinking)}>
                    <CircularProgress className={classes.cProgress} />
                  </div>
                ) : (
                  <div className={classes.portletFooterWithMessageDiv}>
                    {(submitted || error) ? <div className={classes.fullWidthMessageDiv}>
                      {submitted && (
                        <Typography variant="subtitle2" align="center">
                          <div
                            className={classNames(
                              classes.greenColor,
                              classes.centerAlign,
                              classes.lightBoldText
                            )}
                          >
                            Submitted Successfully!
                          </div>
                        </Typography>
                      )}
                      {error && (
                        <Typography variant="subtitle2" align="center" style={{ margin: '8px' }}>
                          <div
                            className={classNames(
                              classes.darkRedColor,
                              classes.centerAlign,
                              classes.lightBoldText
                            )}
                          >
                            {error}
                          </div>
                        </Typography>
                      )}
                    </div>
                      : ''}
                    <div className={classes.portletFooter}>
                      <div className={classes.footerButtons}>
                        <div className={classes.footerButtonsLeftRight}>
                          {isAnyHONameChangedV2().call(this, property) ?
                            <Button
                              variant="outlined"
                              color="default"
                              onClick={event => this.undoHONames(event)}
                            >
                              Undo
                            </Button>
                            : ''}
                        </div>
                        <div className={classes.footerButtonsCenter}>
                          {/* {!isBlocked ? ( */}
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={(!xOwner2First && xOwner2Last) || xOwner1First === ''}
                            onClick={event => this.onSubmit()}
                            ref={this.submitButtonRef}
                          >
                            Submit
                          </Button>
                          {/* ) : ''} */}
                        </div>
                        <div className={classes.footerButtonsLeftRight}></div>
                      </div>
                    </div>
                  </div>)
                }
              </Fragment>
            </DialogActions>
          </Dialog>
          : ''}
      </Fragment>
    );
  };
};

function mapStateToProps(state) {
  return {
    currentArea: state.pma.currentArea,
    mlsAreas: state.pma.mlsAreas
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateHomeownerProp: hoData => {
      dispatch(pmaActions.updateHomeownerProp(hoData));
    },
    updateCompleteMailerProDataAsync: (propertyData, agentData) => {
      dispatch(pmaActions.updateCompleteMailerProDataAsync(propertyData, agentData));
    },
    updateEditedNewMailingData: (data) => dispatch(pmaActions.updateEditedNewMailingData(data)),
    updateRecentUpdatedMailProId: (mailProID) => dispatch(pmaActions.updateRecentUpdatedMailProId(mailProID))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withStyles(styles))(EditHomeownerDialogV2));