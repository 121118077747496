import Base from 'common/constants';
import React, { Component, useContext } from 'react';
import { Button } from "@material-ui/core";
import { GetOAuthToken, GetStateStorage, ResetAllLocalStorages, ResetDataExceptSessionSwitchLocalStorages, GetStateExpireTimerData, SetOAuthToken, SetStateInStorage, SetExpireTimerInStorage, IsValidSemiAuth, IsValidLoginAuth, GetOAuthTokenByKey } from 'common/storage';
import { ResetAllCookies } from 'common/cookies';
import moment from 'moment';
import axios from "common/interceptors";

const GetAuthorization = async () => {
    return btoa(`${Base.APP_DATA.APP_CLIENT_ID}:${Base.APP_DATA.APP_CLIENT_SECRET}`);
};

const style = {
    color: '#FFFFFF'
};

const autoHideDuration = 5000;

const verticalAnchorOrigin = 'top';

const horizontalAnchorOrigin = 'right';

/**
 * @name showNotification
 * @description shows notification as per given variant. Variant could be default | error | success | info | warning
 * @param {*} props 
 * @param {*} message 
 * @param {*} variant 
 */
const showNotification = async (props, message, variant, autoHideDuration, vertical = verticalAnchorOrigin, horizontal = horizontalAnchorOrigin) => {
    const action = (key) => (
        <Button onClick={() => { props.closeSnackbar(key) }} style={style}>
            {'Got It'}
        </Button>
    );
    props.enqueueSnackbar(message, {
        variant,
        preventDuplicate: true,
        autoHideDuration,
        anchorOrigin: {
            vertical,
            horizontal,
        },
        action,
    });
};

/**
 * @name showDefaultNotification
 * @description shows default notification
 * @param {*} props 
 * @param {*} message
 * @param {*} hideDuration Default to autoHideDuration (5000)
 */
const showDefaultNotification = async (props, message, hideDuration = autoHideDuration, vertical = verticalAnchorOrigin, horizontal = horizontalAnchorOrigin) => {
    showNotification(props, message, 'error', hideDuration, vertical, horizontal);
};

/**
 * @name showSuccessNotification
 * @description shows success notification
 * @param {*} props 
 * @param {*} message
 * @param {*} hideDuration Default to autoHideDuration (5000)
 */
const showSuccessNotification = async (props, message, hideDuration = autoHideDuration, vertical = verticalAnchorOrigin, horizontal = horizontalAnchorOrigin) => {
    showNotification(props, message, 'success', hideDuration, vertical, horizontal);
};

/**
 * @name showInfoNotification
 * @description shows inof notification
 * @param {*} props 
 * @param {*} message
 * @param {*} hideDuration Default to autoHideDuration (5000)
 */
const showInfoNotification = async (props, message, hideDuration = autoHideDuration, vertical = verticalAnchorOrigin, horizontal = horizontalAnchorOrigin) => {
    showNotification(props, message, 'info', hideDuration, vertical, horizontal);
};

/**
 * @name showWarningNotification
 * @description shows warning notification
 * @param {*} props 
 * @param {*} message
 * @param {*} hideDuration Default to autoHideDuration (5000)
 */
const showWarningNotification = async (props, message, hideDuration = autoHideDuration, vertical = verticalAnchorOrigin, horizontal = horizontalAnchorOrigin) => {
    showNotification(props, message, 'warning', hideDuration, vertical, horizontal);
};

/**
 * @name showErrorNotification
 * @description shows error notification
 * @param {*} props 
 * @param {*} message
 * @param {*} hideDuration Default to autoHideDuration (5000)
 */
const showErrorNotification = async (props, message, hideDuration = autoHideDuration, vertical = verticalAnchorOrigin, horizontal = horizontalAnchorOrigin) => {
    showNotification(props, message, 'error', hideDuration, vertical, horizontal);
};
/**
 * @name commonSessionSwitchFn
 * @param {*} selectedAgentData Session switch for which agent
 * @param {*} reloadPage Whether to reload page or nor, default Yes
 * @param {*} refreshTimeInMS Refresh time in milli seconds, default 1000 milli seconds
 */
const commonSessionSwitchFn = (selectedAgentData, reloadPage = true, refreshTimeInMS = 1000) => {
    let currentTokenData = JSON.parse(GetOAuthToken());
    let currentStateData = GetStateStorage();
    //we need this time because on every session switch we cannot increase the expire time. We arent generating oauth token on every session switch.
    let currentStateExpireTime = GetStateExpireTimerData();
    let { roleID, userName } = currentTokenData;
    if(selectedAgentData.listgenUserRoleID){
        selectedAgentData.listgenRoleID = selectedAgentData.listgenUserRoleID;
    }
    /**set the token again */
    /**set the agent profile also */
    //change username and role id + add old admin session data
    currentTokenData['roleID'] = selectedAgentData.listgenRoleID;
    currentTokenData['userName'] = selectedAgentData.emailAddress;
    /**assign only if have no old session data */
    if (currentTokenData && !currentTokenData.oldSessionData) {
        currentTokenData['oldSessionData'] = {
            userName,
            userID: currentStateData.userID,
            userData: currentStateData.userData,
            userRole: roleID
        };
    }
    SetOAuthToken(currentTokenData);
    /**now update the current state data also */
    currentStateData['userID'] = selectedAgentData.listgenUserID;
    currentStateData['userData'] = selectedAgentData;
    currentStateData['userName'] = selectedAgentData.emailAddress;
    currentStateData['userRole'] = selectedAgentData.listgenRoleID;
    currentStateData['tokenData'] = currentTokenData;
    SetStateInStorage(currentStateData);
    /**Now set expire time also */
    SetExpireTimerInStorage(currentStateData, currentStateExpireTime);
    //Reset all cookies
    ResetAllCookies();
    //reload the screen to update all stores, if required
    if (reloadPage) {
        setTimeout(() => {
            window.location.reload();
        }, refreshTimeInMS);
    }
};


/**
 * @name convertV2AgentsDatatoV1
 * @param {*} v2AgentsData 
 */
const convertV2AgentsDatatoV1 = (v2AgentsData) => {
    v2AgentsData  =  v2AgentsData.map(agentData => {
        if(agentData.listgenUserRoleID){
            agentData.listgenRoleID = agentData.listgenUserRoleID;
        }
        if(agentData.listAreaDetails){
            agentData.navbarInfo = agentData.listAreaDetails;
        }
        //areaID to mlsAreaID
        //cityID to mlsCityID
        //cityName to mlsCityName
        //areaName to mlsNeighborhood

        if(agentData.navbarInfo){
            agentData.navbarInfo = agentData.navbarInfo.map(area => {
                area.mlsAreaID = area.areaID;
                area.mlsCityID = area.cityID;
                area.mlsCityName = area.cityName;
                area.mlsNeighborhood = area.areaName;
                return area;
            });
        }
        return agentData;
    });
    return v2AgentsData;
}

/**
 * @name handleLogoutFn
 * @param {*} logoutMode By which mode logging out in system, default would be 1
 */
const handleLogoutFn = (logoutMode = 1, reloadPage = true) => {
    ResetAllLocalStorages();
    //Reset except session switch data also
    ResetDataExceptSessionSwitchLocalStorages();
    //Reset all cookies
    ResetAllCookies();
    //redirect page
    if (reloadPage) {
        window.location.href = `/login?mode=${logoutMode}`;
    }
};

/**
 * @name isAFaultyPage
 * @param {*} userRoleID Current session user role id, from storage
 * @param {*} storageUserID storage session listgen user id
 * @param {*} queryParmaUserID query params session user listgen id
 */
const isAFaultyPage = async (userRoleID, storageUserID, queryParmaUserID) => {
    return new Promise((resolve, reject) => {
        if (userRoleID === Base.APP_DATA.APP_AGENT_ROLE_ID && storageUserID !== queryParmaUserID) {
            //both have different session ids so change. faulty page redirect to dashbaord
            window.location.href = '/dashboard?mode=2';
            //reject('/dashboard?mode=2');
        }
        resolve(false);
    });
};


/**
 * @name isCommonEmptyCheck
 * @param {*} value 
 * @returns true if the value is empty. false means value is not empty, null, undefined etc
 */
const isCommonEmptyCheck = (value) => {
    return (typeof value === "undefined" || value === null || !value || value.toString().length === 0);
}

/**
 * @name getDefaultStateID
 * @description To get listgen default state ID
 * @param {*} listgenUserRole 
 * @param {*} listgenUserID 
 * @returns 
 */
const getDefaultStateID = (listgenUserRole, listgenUserID) => {
    return parseInt(listgenUserRole) === Base.APP_DATA.APP_ADMIN_ROLE_ID ? Base.APP_DATA.APP_STATE_ID : parseInt(listgenUserRole) === Base.APP_DATA.APP_COUNTY_ADMIN_ROLE_ID ? (Base.COUNTY_ADMIN_DEFAULT_STATE_ID[parseInt(listgenUserID)] ? Base.COUNTY_ADMIN_DEFAULT_STATE_ID[parseInt(listgenUserID)] : '') : '';
};

/**
 * @name getDefaultCountyID
 * @description To get listgen default county ID
 * @param {*} listgenUserRole 
 * @param {*} listgenUserID 
 * @returns 
 */
const getDefaultCountyID = (listgenUserRole, listgenUserID) => {
    return parseInt(listgenUserRole) === Base.APP_DATA.APP_ADMIN_ROLE_ID ? Base.APP_DATA.APP_COUNTY_ID : parseInt(listgenUserRole) === Base.APP_DATA.APP_COUNTY_ADMIN_ROLE_ID ? (Base.COUNTY_ADMIN_DEFAULT_COUNTY_ID[listgenUserID] ? Base.COUNTY_ADMIN_DEFAULT_COUNTY_ID[listgenUserID] : '') : '';
};

/**
 * @name systemLogRequest
 * @description To log system request on loggly.
 * @param {*} listgenUserID 
 * @param {*} code 
 * @param {*} error 
 * @param {*} data 
 */
const systemLogRequest = (page, code, error, data = {}) => {
    //grab the local state
    let localState = GetStateStorage();
    window._LTracker.push({
        screen: page,
        userData: localState.userData && localState.userData.listgenUserID ? true : false,
        listgenUserID: (localState.userID ? localState.userID : 'N/A'),
        isValidLogin: IsValidLoginAuth(),
        IsValidSemiAuth: IsValidSemiAuth(),
        data: JSON.stringify(data),
        code: code,
        message: (error.message ? error.message : ``),
        stack: (error.stack ? error.stack : ''),
        userAgent: (window.navigator.userAgent ? window.navigator.userAgent : 'N/A'),
        appVersion: (window.navigator.appVersion ? window.navigator.appVersion : 'N/A'),
        appName: (window.navigator.appName ? window.navigator.appName : 'N/A'),
    });
};

/**
 * @name getPrintTimesText
 * @description Calculate current agent PMA print count
 * @param {*} param0 
 * @returns return
 */

const getAgentPrintTimesText = ({ totalTimesMailed, totalTimesPMAPrinted }) => {
    let versionTimesPrinted = null;
    let keyToCheckDueToVersioning = totalTimesPMAPrinted || totalTimesMailed;
    versionTimesPrinted =
        parseInt(keyToCheckDueToVersioning) > 0 ? parseInt(keyToCheckDueToVersioning) + 1 : 1; //Add 1 if count is greater than 0 because total count is how many times agent printed the PMA. so current one will be +1 to total count.
    let versionTimesPrintedText = "";
    //add suffix to print number
    if (versionTimesPrinted == 1) {
        versionTimesPrintedText = "1st";
    } else if (versionTimesPrinted == 2) {
        versionTimesPrintedText = "2nd";
    } else if (versionTimesPrinted == 3) {
        versionTimesPrintedText = "3rd";
    } else {
        versionTimesPrintedText = `${versionTimesPrinted}th`;
    }
    return versionTimesPrintedText;
};


/**
 * @name SortArrayByMonth
 * @description Sort an array by months
 * @param {*} arr 
 * @param {*} monthKey Key by which month can be accessed
 */
const SortArrayByMonth = (arr, monthKey, abbrevationMode = true) => {
    const months = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    const monthsAbbrevated = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec", ""];
    const finalMonths = abbrevationMode ? monthsAbbrevated : months;
    arr.sort(function (a, b) {
        return finalMonths.indexOf(a[monthKey] || "") - finalMonths.indexOf(b[monthKey] || "");
    });
    return arr;
}

/**
 * @name capitalizeFirstLetter
 * @param {*} inputString 
 * @returns 
 */
const capitalizeFirstLetterForFirstWord = (inputString) => {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

/**
 * capitalizeFirstLetterForEveryWord
 * @param {*} inputString 
 * @returns 
 */
const capitalizeFirstLetterForEveryword = (inputString) => {
    return inputString ? inputString.split(" ").reduce((previousValue, currentValue) => previousValue + " " + capitalizeFirstLetterForFirstWord(currentValue), "") : ""
}

/**
 * @name getCityFromCityString
 * @param {*} city 
 * @param {*} mode city | cityID
 * @returns 
 */
export const getCityFromCityString = (city, mode = 'city') => {
    if (city) {
        let splitCity = city.split(':')
        return (mode === 'city' ? splitCity[0] : splitCity[1])
    } else {
        return city;
    }
}


const getSystemDateFormat = (inputDate) => {
    return inputDate ? moment(new Date(inputDate)).format(
        "MMM DD, YY"
    ) : '';
}


const CommonAxiosAPIHandler = (mode = "get", fnParams, options = null) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
        "Access-Control-Allow-Origin": "*",
    };
    let axiosFn = null;
    if (mode === 'GET') {
        axiosFn = axios.get(fnParams[0], { headers, data: {} })
    } else {
        axiosFn = axios({
            method: mode,
            url: fnParams[0],
            data: fnParams[1],
            headers: options || headers
        })
    }
    return new Promise(async (resolve, reject) => {
        try {
            axiosFn
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res.data);
                    } else {
                        reject("Unable to process request.");
                    }
                })
                .catch((err) => {
                    if (
                        err.response &&
                        err.response.data &&
                        err.response.data.error_description
                    )
                        reject(err.response.data.error_description);
                    else reject("OOPS!!! something went wrong.");
                });
        } catch (err) {
            reject("Something went wrong. Unable to process request.");
        }
    });
};

const isAKylesAccount = (agentData) => {
    const { listgenUserID } = agentData || {};
    return (listgenUserID === "100076" || listgenUserID === "100047");
}

const copyToClipboard = (message) => {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(message).then(() => {
            alert("Data copied to clipboard!");
        }).catch(err => {
            console.error("Failed to copy text: ", err);
        });
    } else {
        const textarea = document.createElement("textarea");
        textarea.value = message;
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand("copy");
            alert("Data copied to clipboard!");
        } catch (err) {
            console.error("Fallback: Oops, unable to copy", err);
        }
        document.body.removeChild(textarea);
    }
}


export {
    GetAuthorization,
    showDefaultNotification,
    showSuccessNotification,
    showInfoNotification,
    showWarningNotification,
    showErrorNotification,
    commonSessionSwitchFn,
    handleLogoutFn,
    isAFaultyPage,
    isCommonEmptyCheck,
    getDefaultStateID,
    getDefaultCountyID,
    systemLogRequest,
    getAgentPrintTimesText,
    SortArrayByMonth,
    capitalizeFirstLetterForFirstWord,
    capitalizeFirstLetterForEveryword,
    getSystemDateFormat,
    CommonAxiosAPIHandler,
    isAKylesAccount,
    convertV2AgentsDatatoV1,
    copyToClipboard
}