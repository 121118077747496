import React, { useEffect, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import classNames from 'classnames';
import {
  Grid,
  TextField,
  Typography,
  Tooltip,
  Divider,
  Button,
  FormControl,
  InputLabel,
  Input,
  OutlinedInput,
  MenuItem
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormatListNumberedRtlRounded } from '@material-ui/icons';
import MaskedInput from 'react-text-mask';


const TextMaskCustom = React.memo((props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask={false}
      guide={true}
      keepCharPositions={false}
    />
  );
});

const TeamProfile = React.memo(props => {
  let {
    classes,
    keyID,
    deleteRow,
    stateData,
    handleFieldChange,
    cleanRow,
    errors,
    openImageUplaodDialog,
    designationData,
    parentValues,
    openMediaViewDialog
  } = props;


  const [labelWidth, setLabelWidth] = React.useState(0);
  const labelRef = React.useRef(null);

  React.useEffect(() => {
    setLabelWidth(labelRef.current.offsetWidth);
  }, []);

  const getStateValue = (key, field) => {
    try {
      if (stateData[key]) {
        return stateData[key]['values'][field];
      } else {
        return '';
      }
    } catch (err) {
      return '';
    }
  };

  const getErrorState = (key, field) => {
    try {
      return (stateData[key]['touched'][field] && errors[key][field].length) > 0
        ? true
        : false;
    } catch (err) {
      return false;
    }
  };

  const getErrorStateValue = (key, field) => {
    try {
      return (stateData[key]['touched'][field] && errors[key][field].length) > 0
        ? errors[key][field][0]
        : '';
    } catch (err) {
      return '';
    }
  };
  return (
    <Fragment>
      <Grid container spacing={0} key={'agent_details_grid_main' + keyID}>
        <Grid item lg={12}>
          <Typography variant="subtitle2" className={classes.teamMemberTitle}>
            Team Member # {keyID + 1}
          </Typography>
        </Grid>
        <Grid item lg={2}>
          <div className={classes.field}>
            <TextField
              className={classNames(classes.textField)}
              label="First Name"
              margin="dense"
              required
              variant="outlined"
              name="firstName"
              value={
                keyID === 0 ?
                parentValues.firstName :
                getStateValue(keyID, 'firstName')
              }
              error={getErrorState(keyID, 'firstName')}
              inputProps={
                {
                  readOnly: (keyID === 0 ? true : false)
                }
              }
              onChange={event =>
                handleFieldChange('firstName', event.target.value, keyID)
              }
            />
            {getErrorState(keyID, 'firstName') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'firstName')}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={2}>
          <div className={classes.field}>
            <TextField
              className={classNames(classes.textField)}
              label="Last Name"
              margin="dense"
              required
              variant="outlined"
              name="lastName"
              value={
                keyID === 0 ?
                parentValues.lastName :
                getStateValue(keyID, 'lastName')
              }
              error={getErrorState(keyID, 'lastName')}
              inputProps={
                {
                  readOnly: (keyID === 0 ? true : false)
                }
              }
              onChange={event =>
                handleFieldChange('lastName', event.target.value, keyID)
              }
            />
            {getErrorState(keyID, 'lastName') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'lastName')}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={2}>
          <div className={classes.field}>
            <FormControl className={classNames(classes.formControl, classes.marginTop1Unit)} variant="outlined">
              <InputLabel
                required={true}
                ref={labelRef}
                htmlFor={"formatted-text-mask-input-mobile-phone" + keyID}>Phone</InputLabel>
              <OutlinedInput
                margin="dense"
                value={
                  keyID === 0 ?
                  parentValues.mobilePhone :
                  getStateValue(keyID, 'phone')
                }
                name="phone"
                labelWidth={labelWidth}
                inputProps={
                  {
                    readOnly: (keyID === 0 ? true : false)
                  }
                }
                onChange={
                  event => {
                    handleFieldChange('phone', event.target.value, keyID);
                  }
                }
                required={true}
                id={"formatted-text-mask-input-mobile-phone" + keyID}
                inputComponent={TextMaskCustom}
              />
            </FormControl>
            {getErrorState(keyID, 'phone') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'phone')}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={4}>
          <div className={classes.field}>
            <TextField
              className={classNames(classes.textField)}
              label="Email"
              margin="dense"
              required
              variant="outlined"
              name="email"
              value={
                keyID === 0 ?
                parentValues.emailAddress :
                getStateValue(keyID, 'email')
              }
              error={getErrorState(keyID, 'email')}
              inputProps={
                {
                  readOnly: (keyID === 0 ? true : false)
                }
              }
              onChange={event =>
                handleFieldChange('email', event.target.value, keyID)
              }
            />
            {getErrorState(keyID, 'email') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'email')}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={2} />
        <Grid item lg={2}>
          <div className={classes.field}>
            <TextField
              id="select-agent-designation"
              select
              label="Select Your Designation"
              className={classes.textField}
              required
              variant="outlined"
              name="agentDesignation"
              inputProps={
                {
                  readOnly: (keyID === 0 ? true : false)
                }
              }
              value={
                keyID === 0 ?
                parentValues.agentDesignation :
                getStateValue(keyID, 'agentDesignation')
              }
              error={getErrorState(keyID, 'agentDesignation')}
              onChange={event =>
                handleFieldChange('agentDesignation', event.target.value, keyID)
              }
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="dense"
            >
              {designationData.map(option => (
                <MenuItem key={option.listgenDesignationID} value={option.listgenDesignationName}>
                  {option.listgenDesignationName}
                </MenuItem>
              ))}
            </TextField>
            {getErrorState(keyID, 'agentDesignation') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'agentDesignation')}
              </Typography>
            )}
          </div>
        </Grid>
        {/* <Grid item lg={1} /> */}
        <Grid item lg={2}>
          <div className={classes.field}>
            <TextField
              className={classNames(classes.textField)}
              label="DRE License #"
              margin="dense"
              required
              variant="outlined"
              name="agentLicenseNumber"
              value={
                keyID === 0 ?
                parentValues.agentLicenseNumber :
                getStateValue(keyID, 'agentLicenseNumber')
              }
              error={getErrorState(keyID, 'agentLicenseNumber')}
              inputProps={
                {
                  readOnly: (keyID === 0 ? true : false)
                }
              }
              onChange={event =>
                handleFieldChange('agentLicenseNumber', event.target.value, keyID)
              }
            />
            {getErrorState(keyID, 'agentLicenseNumber') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'agentLicenseNumber')}
              </Typography>
            )}
          </div>
        </Grid>
        {/* Row 1 End */}
        <Grid item lg={2}>
          <div className={classes.field}>
            <TextField
              className={classNames(classes.textField)}
              label="Mailing Address Line 1"
              margin="dense"
              required
              variant="outlined"
              name="mailingAddress1"
              value={
                keyID === 0 ?
                parentValues.mailingAddressLine1 :
                getStateValue(keyID, 'mailingAddress1')
              }
              error={getErrorState(keyID, 'mailingAddress1')}
              inputProps={
                {
                  readOnly: (keyID === 0 ? true : false)
                }
              }
              onChange={event =>
                handleFieldChange('mailingAddress1', event.target.value, keyID)
              }
            />
            {getErrorState(keyID, 'mailingAddress1') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'mailingAddress1')}
              </Typography>
            )}
          </div>
        </Grid>
        {/* <Grid item lg={2}>
          <div className={classes.field}>
            <TextField
              className={classNames(classes.textField)}
              label="Mailing Address Line 2"
              margin="dense"
              variant="outlined"
              name="mailingAddress2"
              value={
                keyID === 0 ?
                parentValues.mailingAddressLine2 : 
                getStateValue(keyID, 'mailingAddress2')
              }
              error={getErrorState(keyID, 'mailingAddress2')}
              inputProps={
                {
                  readOnly: (keyID === 0 ? true : false),
                }
              }
              onChange={event =>
                handleFieldChange('mailingAddress2', event.target.value, keyID)
              }
            />
            {getErrorState(keyID, 'mailingAddress2') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'mailingAddress2')}
              </Typography>
            )}
          </div>
        </Grid> */}
        <Grid item lg={1}>
          <div className={classes.field}>
            <TextField
              className={classNames(classes.textField)}
              label="City"
              margin="dense"
              variant="outlined"
              name="city"
              required
              value={
                keyID === 0 ?
                parentValues.city :
                getStateValue(keyID, 'city')
              }
              error={getErrorState(keyID, 'city')}
              inputProps={
                {
                  readOnly: (keyID === 0 ? true : false),
                }
              }
              onChange={event =>
                handleFieldChange('city', event.target.value, keyID)
              }
            />
            {getErrorState(keyID, 'city') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'city')}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={1}>
          <div className={classes.field}>
            <TextField
              className={classNames(classes.textField)}
              label="State"
              margin="dense"
              variant="outlined"
              name="state"
              required
              value={
                keyID === 0 ?
                parentValues.state :
                getStateValue(keyID, 'state').toUpperCase()
              }
              error={getErrorState(keyID, 'state')}
              inputProps={
                {
                  readOnly: (keyID === 0 ? true : false),
                  maxLength: 2
                }
              }
              onChange={event =>
                handleFieldChange('state', event.target.value.toUpperCase(), keyID)
              }
            />
            {getErrorState(keyID, 'state') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'state')}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={2}>
          <div className={classes.field}>
            <TextField
              className={classNames(classes.textField)}
              label="Zipcode"
              margin="dense"
              variant="outlined"
              name="zipcode"
              required
              inputProps={
                {
                  readOnly: (keyID === 0 ? true : false),
                  maxLength: 10
                }
              }
              value={
                keyID === 0 ?
                parentValues.zipcode :
                getStateValue(keyID, 'zipcode')
              }
              error={getErrorState(keyID, 'zipcode')}
              onChange={event =>
                handleFieldChange('zipcode', event.target.value, keyID)
              }
            />
            {getErrorState(keyID, 'zipcode') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'zipcode')}
              </Typography>
            )}
          </div>
        </Grid>
        {/* <Grid item lg={1}></Grid> */}
        {/* <Grid item lg={1}></Grid> */}
        <Grid
          item
          lg={2}
          className={classes.paddingRight}
          key={'agent_details_grid_4' + keyID}
        >
          <div className={classes.rowActions}>
            <Tooltip
              title="Clear text in row"
              placement="bottom"
              key={'agent_details_tooltip_eraser' + keyID}
            >
              <IconButton
                aria-label="Close"
                key={'agent_details_button_eraser' + keyID}
                onClick={event => cleanRow(keyID)}
              >
                <svg
                  style={{ height: '24px', width: '24px' }}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0000008a"
                    d="M16.24,3.56L21.19,8.5C21.97,9.29 21.97,10.55 21.19,11.34L12,20.53C10.44,22.09 7.91,22.09 6.34,20.53L2.81,17C2.03,16.21 2.03,14.95 2.81,14.16L13.41,3.56C14.2,2.78 15.46,2.78 16.24,3.56M4.22,15.58L7.76,19.11C8.54,19.9 9.8,19.9 10.59,19.11L14.12,15.58L9.17,10.63L4.22,15.58Z"
                  />
                </svg>
              </IconButton>
            </Tooltip>
            {keyID > 0 ? (
              <Tooltip
                title="Delete row"
                placement="bottom"
                key={'agent_details_tooltip_delete' + keyID}
              >
                <IconButton
                  aria-label="Delete"
                  key={'agent_details_button_delete' + keyID}
                  onClick={event => deleteRow(keyID)}
                >
                  <DeleteIcon key={'agent_details_icon_delete' + keyID} />
                </IconButton>
              </Tooltip>
            ) : (
                ''
              )}
          </div>
        </Grid>
      </Grid>
      <Grid container key={'agent_details_grid_secondary' + keyID}>
        <Fragment>
          {/* <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridMargin}>
            <Typography className={classes.title} variant="subtitle2">
              Upload{getStateValue(keyID, 'agentSignature') ? 'ed' : ''} Signature
          </Typography>
            <Divider />
          </Grid> */}
{/* 
          <Grid item lg={4} md={4} sm={4} xs={4} className={classes.gridMargin}>
            <div className={classes.imageHolderWrapper}>
              {getStateValue(keyID, 'agentSignature') ? (
                <div className={classes.imageHolder}>
                  <img 
                  src={
                    keyID === 0 ?
                    parentValues.agentSignature : 
                    getStateValue(keyID, 'agentSignature')
                  }
                   alt={"Agent Signature"}
                   onClick={event => openMediaViewDialog(
                     (
                      keyID === 0 ?
                      parentValues.agentSignature : 
                      getStateValue(keyID, 'agentSignature')
                     ),
                     'Agent Signature'
                   )}
                  className={classes.imageTag} />
                </div>
              ) : ''}
               {keyID > 0 ?
              <div className={classes.fieldSection}>
                  <Button
                    key={'agent-signature-button-' + keyID}
                    onClick={event => openImageUplaodDialog('signature', getStateValue(keyID, 'agentSignature'), keyID)}
                    variant="contained"
                    component="span"
                    className={classNames(
                      classes.uploadButton
                    )}
                  >
                    Upload Agent Signature
                </Button>
              </div>
               : ''}
            </div>
          </Grid>
         */}
        </Fragment>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Divider className={classes.gridDivider} />
        </Grid>
      </Grid>
    </Fragment>
  );
});

export default withStyles(styles)(TeamProfile);
