import React, { Component } from "react";
import { propertyUtil } from "../../PropertyUtil";
import PSContents from "./PSContents";
import LeftJustifiedPS from "./LeftJustifiedPS";
import GreetingsPS from "./GreetingsPS";

class PS extends Component {
  componentWillMount() {
    if (this.props.printVersion) {
      require("./printCommon.scss");
    } else {
      require("./common.scss");
    }
  }
  getCurrentArea = (mlsAreas, currentArea) => {
    let xArea = {};
    if (!mlsAreas || (Array.isArray(mlsAreas) && mlsAreas.length === 0)) {
      return currentArea;
    }
    (mlsAreas ? mlsAreas : []).map(area => {
      if (area.mlsAreaID === currentArea.mlsAreaID) {
        xArea = area;
      }
    });
    return xArea;
  };

  render() {
    const { mailingNum } = this.props;
    // console.log("ps", this.props);
    const { changeDetails, mailingDetails } = this.props.hoData;
    const { HONickname, agentFirstNames } = propertyUtil;
    let theHomeowners = HONickname(changeDetails, mailingDetails, "&");
    let { currentArea, mlsAreas, version } = this.props;
    /**change current area */
    currentArea = this.getCurrentArea(mlsAreas, currentArea);

    let versionGlobalPS;
    if (version === "q") versionGlobalPS = currentArea.globalPSQuarterly;
    if (version === "mq") versionGlobalPS = currentArea.globalPSMidQuarterly;
    const { OwnerFirst, OwnerLast } = mailingDetails;
    const {
      listgenUserFirstName,
      isMemberOfTeam,
      teamMembers
    } = this.props.agentData;

    let hoTextPS;
    if (
      changeDetails &&
      changeDetails.textPS &&
      changeDetails.textPS.length > 1
    ) {
      const { nickName, textPS } = changeDetails;
      hoTextPS = textPS;
    } else if (versionGlobalPS.length > 1) {
      hoTextPS = versionGlobalPS;
    } else {
      hoTextPS = "Give me a call today!";
    }
    let agentSignoff = !teamMembers
      ? listgenUserFirstName
      : teamMembers[0].firstName + " and " + teamMembers[1].firstName;

    if (version === "q" || version === "mq") {
      return (
        <GreetingsPS
          version={version}
          theHomeowners={theHomeowners}
          hoTextPS={hoTextPS}
          isMemberOfTeam={isMemberOfTeam}
          teamMembers={teamMembers}
          agentFirstNames={agentFirstNames}
          listgenUserFirstName={listgenUserFirstName}
          agentSignoff={agentSignoff}
          mailingNum={mailingNum}
        />
      );
    } else {
      return (
        <PSContents
          version={version}
          theHomeowners={theHomeowners}
          hoTextPS={hoTextPS}
          isMemberOfTeam={isMemberOfTeam}
          teamMembers={teamMembers}
          agentFirstNames={agentFirstNames}
          listgenUserFirstName={listgenUserFirstName}
          agentSignoff={agentSignoff}
        />
      );
    }
  }
}

export default PS;
