import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { pmaActions } from "_store/_actions";
import QVTwo from "./QVTwo";
import QVThree from "./QVThree";
import QVFour from "./QVFour";

class Quarterly extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.changeLoading(false);
    }, 500);
  }

  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      currentArea,
      mlsAreas,
      quarterlyAreaTable,
      quarterlyCityTable,
      navbarData,
      coverPageDetails,
      testimonials,
      propertyPhotoDetailsDTO,
      quadrantModalOpen,
      qArea,
      qCity,
      aprilData,
      quadrantDetails,
      bubbleBoxes,
      recentActivityPhotoList,
      photoCheckboxModalOpen,
      missionStatement,
      isDemo,
      MQCityDetails,
      yearEnd,
    } = this.props;

    const nonPrintStyle = {
      padding: "10px 0 25px 0",
    };

    return (
      <Fragment>
        <div
          className={"pma-editor " + (printVersion ? "quarterly-print" : "")}
          style={printVersion ? null : nonPrintStyle}
        >
          <div id="back-to-top-anchor-pma-editor-page-1" />
          {version === "qv4" ? (
            <QVFour
              agentData={agentData}
              hoData={hoData}
              currentArea={currentArea}
              mlsAreas={mlsAreas}
              printVersion={printVersion}
              version={version}
              navbarData={navbarData}
              quarterlyCityTable={quarterlyCityTable}
              quarterlyAreaTable={quarterlyAreaTable}
              coverPageDetails={coverPageDetails}
              testimonials={testimonials}
              quadrantModalOpen={quadrantModalOpen}
              quadrantDetails={quadrantDetails}
              propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
              missionStatement={missionStatement}
              isDemo={isDemo}
              MQCityDetails={MQCityDetails}
              yearEnd={yearEnd}
            />
          ) : version === "qv3" ? (
            <QVThree
              agentData={agentData}
              hoData={hoData}
              currentArea={currentArea}
              mlsAreas={mlsAreas}
              printVersion={printVersion}
              version={version}
              navbarData={navbarData}
              quarterlyAreaTable={quarterlyAreaTable}
              coverPageDetails={coverPageDetails}
              qArea={qArea}
              qCity={qCity}
              aprilData={aprilData}
              bubbleBoxes={bubbleBoxes}
              propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
              recentActivityPhotoList={recentActivityPhotoList}
              photoCheckboxModalOpen={photoCheckboxModalOpen}
            />
          ) : (
            <QVTwo
              agentData={agentData}
              hoData={hoData}
              currentArea={currentArea}
              mlsAreas={mlsAreas}
              printVersion={printVersion}
              version={version}
              navbarData={navbarData}
              quarterlyAreaTable={quarterlyAreaTable}
              coverPageDetails={coverPageDetails}
              qArea={qArea}
              qCity={qCity}
              aprilData={aprilData}
              bubbleBoxes={bubbleBoxes}
              propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
              recentActivityPhotoList={recentActivityPhotoList}
              photoCheckboxModalOpen={photoCheckboxModalOpen}
            />
          )}
          <div id="back-to-top-anchor-pma-editor-page-2" />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.pma.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeLoading: (status) => {
      dispatch(pmaActions.changeLoading(status));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Quarterly);
