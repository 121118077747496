import React, { Fragment, lazy, Suspense } from "react";
// import PropTypes from 'prop-types';
import { connect } from "react-redux";
import MailingTableHeader from "views/Pma/MailPro/PmaMailingList/MailingTableHeader";
import PropertyRecord from "views/Pma/MailPro/PmaMailingList/PropertyRecord";
import AgentRecord from "views/Pma/MailPro/PmaMailingList/AgentRecord";
import "views/Pma/MailPro/PmaMailingList/index.scss";
import { pmaActions } from "_store/_actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MediaViewComponent } from "components";
import { propertyUtil } from "views/Pma/PropertyUtil";
import { Paper, Fab, Typography, Button, Grow } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { ScrollToTopComponent } from "components";
import { tableSortUtil } from "views/Pma/TableSortUtil";
import { renderLogicUtil } from "views/Pma/RenderLogicUtil";
import AgentDataVerification from "./AgentDataVerification";

const NewMailingComponentTable = lazy(() =>
  import("views/Pma/MailPro/PmaMailingList/NewMailingComponentTable")
);

const MailProLoader = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        background: "#f8fafc",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <CircularProgress />
        </div>
        <div>
          <Typography variant="subtitle2">
            &nbsp;&nbsp;Loading MailPro...
          </Typography>
        </div>
      </div>
    </div>
  );
};

class PmaMailingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openMediaView: false,
      mediaURL: null,
      triggerNow: false,
      propertyRecords: [],
      dataToDisplay: [],
      pageNumber: 1,
      agentRecords: [],
      allDataDisplyed: false,
      noRecordMatched: false,
      //isLoading: true,
      //enablePagination: false
    };
    this.pageLimit = 100;
    /**bind methods */
    this.openMediaViewDialog = this.openMediaViewDialog.bind(this);
    this.closeMediaViewDialog = this.closeMediaViewDialog.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.updateTable();
    }, 1000);
    //register scroll evemt
    this.addEventScroller();
    //change the step. In PMA by default it will zero so no update required. Here we need to update after the mailpro navbar load
    //We already calling in mailpro index file but we need to call here also
    setTimeout(() => {
      this.props.switchStep(1);
    }, 50);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.pma.currentArea.mlsAreaID !==
      prevProps.pma.currentArea.mlsAreaID
    ) {
      //on chnage of area first reset current data. on load of new area data mailpro id will change then table rendering will happen
      this.scrollToTop(0, 0);
      this.setState({
        propertyRecords: [],
        dataToDisplay: [],
      });
    } else if (
      this.props.pma.currentAreaFirstMailProID !==
      prevProps.pma.currentAreaFirstMailProID
    ) {
      setTimeout(() => {
        this.scrollToTop(0, 0);
        this.updateTable(true);
      }, 1000);
    } else if (
      this.props.pma.hoSearch !== prevProps.pma.hoSearch ||
      this.props.pma.hoFilter !== prevProps.pma.hoFilter ||
      this.props.pma.hoQuickFilter !== prevProps.pma.hoQuickFilter ||
      this.props.pma.hoAdvanceFilter !== prevProps.pma.hoAdvanceFilter
    ) {
      setTimeout(() => {
        this.scrollToTop(0, 0);
        //we have to reset thepagination because in this case the data to diaply count will change
        this.updateTable(true);
      }, 1000);
    } else if (
      (this.props.pma.mailProHeader !== prevProps.pma.mailProHeader &&
        this.props.pma.mailProHeader !== "") ||
      (this.props.pma.mailProSortDirection !==
        prevProps.pma.mailProSortDirection &&
        this.props.pma.mailProHeader !== "")
    ) {
      //do not render die to header sort if header sort is empty
      setTimeout(() => {
        this.updateTable();
      }, 1000);
    }
  }

  addEventScroller() {
    window.addEventListener("scroll", this.handleScrolling);
  }

  removeEventScroller() {
    window.removeEventListener("scroll", this.handleScrolling);
  }

  doFilter = (property) => {
    const { hoSearch } = this.props.pma;
    return property.toLowerCase().includes(hoSearch.toLowerCase());
  };

  doNumFilter = (property) => {
    const { hoSearch } = this.props.pma;
    return property.includes(hoSearch);
  };

  abovePrice = (property) => {
    const { hoSearch } = this.props.pma;
    /**check if text is number only. otherwise sedn false */
    if (property && parseFloat(hoSearch) > 0) {
      return (
        parseFloat(property) == parseFloat(hoSearch.replace(/[^0-9-.]/g, ""))
      );
      //return parseFloat(property) >= parseFloat(hoSearch.replace(/[^0-9-.]/g, ''));
    } else {
      /**no number */
      return false;
    }
  };

  listingFilter = (property) => {
    const { hoSearch } = this.props.pma;
    const formatListing = (prop) => {
      return `${prop.slice(0, 2).toLowerCase()}${prop.slice(2)}`;
    };
    let loweredProp = formatListing(property);
    let loweredSearch = formatListing(hoSearch);
    return loweredProp.includes(loweredSearch);
  };

  parcelFilter = (property) => {
    const { hoSearch } = this.props.pma;

    const propReplace = (prop) => {
      let regex = /-/gi;
      return prop.replace(regex, "");
    };
    return propReplace(property).includes(propReplace(hoSearch));
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  openMediaViewDialog = (mediaURL) => {
    this.setState(
      {
        mediaURL,
      },
      this.setState({
        openMediaView: true,
      })
    );
  };

  closeMediaViewDialog = () => {
    this.setState({
      mediaURL: null,
      mediaTitle: null,
      openMediaView: false,
    });
  };

  handleScrolling = (event) => {
    const {
      scrollTop,
      scrollHeight,
      clientHeight,
    } = event.target.scrollingElement; // document.getElementById('auto-scroll-mailing-list-table-body');
    if (scrollTop + clientHeight >= scrollHeight - 20) {
      //time to add more data
      //event.preventDefault();
      //event.stopPropagation();
      //load next set after a certain time
      //setTimeout(() => {
      this.setState(
        (prevState) => {
          let newData = [
            ...prevState.dataToDisplay,
            ...prevState.propertyRecords.slice(
              prevState.pageNumber * this.pageLimit,
              (prevState.pageNumber + 1) * this.pageLimit
            ),
          ];
          return {
            //propertyRecords: [],
            dataToDisplay: newData,
            pageNumber: prevState.pageNumber + 1,
            allDataDisplyed:
              newData.length === prevState.propertyRecords.length,
          };
        },
        () => {
          const { allDataDisplyed } = this.state;
          //all property displayed deregister the scroll event
          if (allDataDisplyed) {
            window.removeEventListener("scroll", this.handleScrolling);
          }
        }
      );
      //}, 500);
    }
  };

  render() {
    const {
      propertyData,
      mlsAreas,
      isLoading,
      agentData,
      nextPMAPrintDate,
    } = this.props;
    const lastPMAPrintDate = propertyUtil.getLastPrintDate();
    const { currentArea } = this.props.pma;
    const {
      propertyRecords,
      agentRecords,
      pageNumber,
      dataToDisplay,
      noRecordMatched,
    } = this.state;

    //check all data displayed or not
    let allPropertyDisplyed = propertyRecords.length === dataToDisplay.length;

    return mlsAreas && mlsAreas.length >= 1 ? (
      <Fragment>
        <AgentDataVerification
          agentData={agentData}
          mlsAreas={mlsAreas}
          navbarData={currentArea}
          handleDrawerOpen={this.handleDrawerOpen}
          enableMailProApprovalProcess={this.state.enableMailProApprovalProcess}
          handlePMAIssuesDialog={this.handlePMAIssuesDialog}
          propertyData={propertyData}
          //version={version}
          //switchVersion={this.switchVersion}
        />
      </Fragment>
    ) : null;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateNavbar: (mlsAreas) => dispatch(pmaActions.updateNavbar(mlsAreas)),
    mailProHeaderSort: (sorted, mailProHeader) =>
      dispatch(pmaActions.mailProHeaderSort(sorted, mailProHeader)),
    changeLoading: (status) => {
      dispatch(pmaActions.changeLoading(status));
    },
    resetMailproCurrentHeaderSort: () => {
      dispatch(pmaActions.resetMailproCurrentHeaderSort());
    },
    switchStep: (step) => dispatch(pmaActions.switchStep(step)),
  };
};

function mapStateToProps(state) {
  return {
    properties: state.pma.properties,
    pma: state.pma,
    propertyData: state.pma.propertyData,
    currentArea: state.pma.currentArea,
    currentStep: state.pma.currentStep,
    mlsAreas: state.pma.mlsAreas,
    isLoading: state.pma.isLoading,
    nextPMAPrintDate: state.pma.nextPMAPrintDate,
    explicitEnableNewMailProInit: state.pma.explicitEnableNewMailProInit,
  };
}

const connectedMailingTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(PmaMailingList);
export default connectedMailingTable;

// import React, { Component } from "react";
// import { connect } from "react-redux";
// import { Link, withRouter } from "react-router-dom";
// import compose from "recompose/compose";
// import AgentDataVerification from "./AgentDataVerification";
// import { pmaActions } from "../../../_store/_actions";
// import { GetStateStorage, IsValidNavbarData } from "common/storage";
// import { WebsiteLayout } from "layouts";
// import { CircularProgress, Typography, Button, Grid } from "@material-ui/core";
// import querystring from "querystring";
// import PMAIssueReportsDialog from "views/Pma/PmaEditor/PMAIssueReportsDialog";
// // import PMAEditorInformationDialog from "./components/PMAEditorInformationDialog";
// import { handleLogoutFn } from "common/helpers";
// import { Alert } from "@material-ui/lab";
// import { isEmpty } from "lodash";
//
// class DataVerificationMain extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       navbarData: {},
//       resetLocalState: false,
//       openDrawer: false,
//       isFullMode: true,
//       enableMailProApprovalProcess: false, //no need to touch this as it will have no effect. use component Did Mount. It iwll controlled by backend now
//       openPMAIssuesDialog: false,
//       enablePMAInformationDialog: false,
//       // enablePMAInformationDialog: true,// true means system will display the PMA info dialog. When we push Year End PMA then make it false
//       openPMAInformationDialog: false,
//     };
//     /**bind methods */
//     this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
//     this.handlePMAIssuesDialog = this.handlePMAIssuesDialog.bind(this);
//   }
//   componentWillMount = () => {
//     /** check for default step */
//     try {
//       this.props.switchStep(0);
//     } catch (err) {}
//   };
//
//   componentWillReceiveProps(newProps) {}
//
//   componentDidUpdate() {
//     const { mlsAreas, switchArea, currentArea, isLoading } = this.props;
//
//     if (
//       mlsAreas &&
//       Array.isArray(mlsAreas) &&
//       mlsAreas.length > 0 &&
//       isEmpty(currentArea)
//     ) {
//       this.setState(
//         {
//           navbarData: { ...mlsAreas[0] },
//         },
//         () => {
//           //currentArea.mlsAreaID !== mlsAreas[0].mlsAreaID &&
//           switchArea(mlsAreas[0]);
//         }
//       );
//     }
//   }
//
//   componentDidMount() {
//     const stateData = GetStateStorage();
//     const agentData = stateData.userData;
//     //check if state data not founf
//     const { mlsAreas, switchArea } = this.props;
//     /**Get session storage */
//     let navbarData =
//       agentData && Array.isArray(agentData.navbarInfo)
//         ? agentData.navbarInfo[0]
//         : {};
//
//     const isValid = IsValidNavbarData();
//     !this.props.mlsAreas.length &&
//       this.props.getNavbarInfo(stateData.userData.listgenUserID, navbarData);
//     /**get next pma print date */
//
//     const { mlsAreaID, mlsCityID, mlsCityName } = navbarData;
//
//     let showApproval = []; // ["101003", "101006", "100474"];
//
//     if (showApproval.includes(agentData.listgenUserID)) {
//       this.setState({
//         enableMailProApprovalProcess: true, //it's also linked with mailpro approval button is also enable
//       });
//     } else {
//       this.setState({
//         enableMailProApprovalProcess: agentData.enableApprovalButton || false, //check the agent data for this process
//       });
//     }
//     if (isValid) {
//       // this.setState({
//       //   navbarData: navbarData
//       // });
//       // switchArea(navbarData);
//     } else {
//       console.log("No MLS areas found", mlsAreas);
//     }
//     /**now scroll to top also */
//     window.scrollTo(0, 0);
//     //show the pma information dialog
//     if (this.state.enablePMAInformationDialog) {
//       this.setState({
//         openPMAInformationDialog: true,
//       });
//     }
//   }
//
//   handleDrawerOpen = () => {
//     this.setState({
//       openDrawer: true,
//     });
//   };
//
//   handleDrawerClose = () => {
//     this.setState({
//       openDrawer: false,
//     });
//   };
//
//   handlePMAIssuesDialog = (openDialog = false) => {
//     this.setState({
//       openPMAIssuesDialog: openDialog,
//     });
//   };
//
//   // switchVersion = v => {
//   //   console.log('version is switching to ', v, typeof v);
//   //   const switchVersion = this.props.switchVersion(v);
//   // };
//
//   render() {
//     const { mlsAreas, currentArea, currentStep, propertyData } = this.props;
//     const stateData = GetStateStorage();
//     const agentData = stateData.userData;
//     console.log("inside agent index");
//     console.log("mlsAreas", mlsAreas);
//     console.log("currentArea", currentArea);
//     console.log("data verification index", this.props);
//     return (
//       <WebsiteLayout
//         sidebarMode={true}
//         openSidebarModeSidebar={this.state.openDrawer}
//         sidebarModeHandleClose={this.handleDrawerClose}
//         hideFooter={true}
//       >
//         {this.state.openPMAIssuesDialog ? (
//           <PMAIssueReportsDialog
//             handlePMAIssuesDialog={this.handlePMAIssuesDialog}
//             currentArea={this.props.currentArea}
//           />
//         ) : (
//           ""
//         )}
//
//         {mlsAreas && mlsAreas.length >= 1 && !isEmpty(currentArea) ? (
//           <AgentDataVerification
//             agentData={agentData}
//             mlsAreas={mlsAreas}
//             navbarData={currentArea}
//             handleDrawerOpen={this.handleDrawerOpen}
//             enableMailProApprovalProcess={
//               this.state.enableMailProApprovalProcess
//             }
//             handlePMAIssuesDialog={this.handlePMAIssuesDialog}
//             propertyData={propertyData}
//             //version={version}
//             //switchVersion={this.switchVersion}
//           />
//         ) : (
//           <div
//             style={{
//               height: "80vh",
//               width: "100%",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               flexDirection: "row",
//               background: "inherit",
//             }}
//           >
//             <div>
//               <CircularProgress />
//             </div>
//             {/* style={{ marginTop: '2vh', marginLeft: '2vh' }} */}
//             <div>
//               <Typography variant="subtitle2">
//                 &nbsp;&nbsp;Loading...
//               </Typography>
//             </div>
//           </div>
//         )}
//       </WebsiteLayout>
//     );
//   }
// }
//
// const mapStateToProps = (state) => {
//   return {
//     currentArea: state.pma.currentArea,
//     mlsAreas: state.pma.mlsAreas,
//     localStorageData: state.localStorageData,
//     version: state.pma.version,
//     currentStep: state.pma.currentStep,
//     propertyData: state.pma.propertyData,
//     isLoading: state.pma.isLoading,
//     nextPMAPrintDate: state.pma.nextPMAPrintDate,
//   };
// };
// function mapDispatchToProps(dispatch) {
//   return {
//     getNavbarInfo: (listgenUserID) =>
//       dispatch(pmaActions.getNavbarInfo(listgenUserID)),
//     switchArea: (area) => dispatch(pmaActions.switchArea(area)),
//     switchStep: (step) => dispatch(pmaActions.switchStep(step)),
//     updateLocalStorageDataForRedux: (localStorageData) =>
//       dispatch(pmaActions.updateLocalStorageDataForRedux(localStorageData)),
//     switchVersion: (version) => {
//       dispatch(pmaActions.switchVersion(version));
//     },
//     explicitEnableNewMailProInitFn: (data) =>
//       dispatch(pmaActions.explicitEnableNewMailProInitFn(data)),
//     changeLoading: (status) => {
//       dispatch(pmaActions.changeLoading(status));
//     },
//   };
// }
// //withRouter
// export default compose(
//   withRouter,
//   connect(mapStateToProps, mapDispatchToProps)
// )(DataVerificationMain);
