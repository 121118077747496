import React, { Component } from "react";
import QVThreeSecondLeft from "views/Pma/PmaEditor/Quarterly/QVThree/QVThreeSecondPage/QVThreeSecondLeft";
import QVTwoSecondRight from "./QVTwoSecondRight";

class QVTwoSecondPage extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      printVersion,
      version,
      mlsAreas,
      quarterlyAreaTable,
      aprilData,
      recentActivityPhotoList,
      photoCheckboxModalOpen,
    } = this.props;

    return (
      <div
        className={
          "pma-page-container " +
          (printVersion
            ? "qv2-print-page-container"
            : "qv2-pma-editor-page-container")
        }
      >
        <QVThreeSecondLeft
          currentArea={currentArea}
          hoData={hoData}
          agentData={agentData}
          aprilData={aprilData}
        />
        <QVTwoSecondRight
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
          version={version}
          hoData={hoData}
          mlsAreas={mlsAreas}
          quarterlyAreaTable={quarterlyAreaTable}
          aprilData={aprilData}
          recentActivityPhotoList={recentActivityPhotoList}
          photoCheckboxModalOpen={photoCheckboxModalOpen}
        />
      </div>
    );
  }
}

export default QVTwoSecondPage;
