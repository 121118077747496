import { green } from "@material-ui/core/colors";

export default (theme) => ({
  root: {
    height: "auto",
  },
  grid: {
    //padding: theme.spacing(2),
  },
  field: {
    //padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  formControl: {
    width: "100%",
  },
  textField: {
    width: "100%",
    maxWidth: "100%",
    //marginRight: theme.spacing(3)
    marginBottom: theme.spacing(1),
  },
  dropdownTopMargin: {
    marginTop: -theme.spacing(5),
  },
  marginTopTextfield: {
    marginTop: -theme.spacing(3),
  },
  marginTopTextOfficefield: {
    marginTop: -theme.spacing(3),
  },
  portletFooter: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  fieldError: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  mailingExpansionPanel: {
    boxShadow: "none",
    backgroundColor: "transparent",
    padding: 0,
  },
  mailingExpansionHeader: {
    marginTop: -theme.spacing(2),
    marginBottom: -theme.spacing(3),
    "& > div:first-child": {
      margin: 0,
    },
  },
  mailingExpansionContent: {
    padding: 0,
    margin: 0,
    marginTop: -theme.spacing(2),
  },
  checkboxField: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  checkboxAssistantField: {
    //marginTop: -theme.spacing(1),
    marginLeft: -theme.spacing(0.1),
  },
  marignTop1Unit: {
    marginTop: theme.spacing(1),
  },
  marignTop4UnitMinus: {
    marginTop: -theme.spacing(4),
  },
  marignTop2UnitMinus: {
    marginTop: -theme.spacing(2),
  },
  marignTop5Unit: {
    marginTop: theme.spacing(5),
  },
  footerButtons: {
    position: "relative",
    display: "flex",
  },
  footerButtonsCenter: {
    justifyContent: "center",
    alignItems: "center",
  },
  footerButtonsLeftRight: {
    flex: 1,
  },
  hideField: {
    display: "none",
  },
  teamMemberButtons: {
    marginTop: theme.spacing(2),
    "&>button": {
      marginRight: theme.spacing(2),
    },
  },
  noCuurentImage: {
    //marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  imageHolderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  imageHolder: {
    maxWidth: "200px",
    //maxHeight: '500px',
    textAlign: "center",
    marginTop: theme.spacing(1),
    height: "220px",
    objectFit: "cover",
  },
  imageHolderDialog: {
    maxWidth: "200px",
    //maxHeight: '500px',
    textAlign: "center",
    marginTop: theme.spacing(2),
    height: "auto",
    objectFit: "cover",
  },
  imageTag: {
    width: "200px",
    height: "auto",
    cursor: "pointer",
  },
  fieldSection: {
    width: "100%",
    height: "85px",
  },
  fieldSectionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  fieldSectionError: {
    width: "100%",
  },
  uploadButton: {
    backgroundColor: theme.palette.primary.mediumLight,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  marginTop1Unit: {
    marginTop: theme.spacing(1),
  },
  autoHeightPortlet: {
    height: "auto",
    paddingBottom: theme.spacing(4),
  },
  brokerageField: {
    marginTop: theme.spacing(1.5),
  },
  brokerageFieldTypo: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  pdfIcon: {
    width: "100%",
    height: "100%",
  },
  websiteAdornment: {
    marginRight: 0,
    "&>p": {
      fontWeight: 600,
    },
  },
  green500: {
    color: green[500],
  },
  photoUploadedMessage: {
    marginBottom: theme.spacing(0.5),
    fontSize: "15px",
  },
  "@keyframes new-blinker": {
    "85%": {
      opacity: 0,
    },
  },
  flashButton: {
    animation: "$new-blinker 2s linear 20",
    animationDuration: "2s",
    "&:hover": {
      animation: "0",
      WebkitAnimation: 0,
    },
  },
});
