import React, { Component } from "react";
import QVFourSellerGraph from "./QVFourSellerGraph";
import { propertyUtil } from "views/Pma/PropertyUtil";

class QVFourSellerGraphContainer extends Component {
  render() {
    const {
      calculateSellersAdvantages,
      graphAveragesArea,
      graphAveragesCity,
      determineCityOrArea,
      currentYearObjArea,
      currentYearObjCity,
      currentYearAreaPerc,
      currentYearCityPerc,
      pastYearObjArea,
      pastYearObjCity,
      quarterlyAreaTable,
      quarterlyCityTable,
      printVersion,
      currentArea,
      useMedian,
    } = this.props;

    const { mlsAreaID } = currentArea;
    const {
      roundSalePrice,
      calculateThreeYEGraphs,
      calculateDiff,
    } = propertyUtil;
    const { marketHighLightsData } = quarterlyAreaTable;
    const {
      current180DaysMedianSalePrice,

      previous180DaysMedianSalePrice,
      median180DaysPercentage,
    } = marketHighLightsData;
    let listToShow;
    if (determineCityOrArea) {
      listToShow = calculateThreeYEGraphs(
        determineCityOrArea,
        calculateSellersAdvantages
      );
    }

    let dataObj = {
      useMedian: false,
      //SALE PRICE
      currentYearSalePriceAvg: null,
      pastYearSalePriceAvg: null,
      salePriceGraphValues: null,
      salePriceFormattedValues: null,
      salePriceTrendingPercPoint: null,
      salePriceDescription: null,
      salePriceSellersAdvantage: false,
      //DAYS TO SELL
      currentYearDaysToSellAvg: null,
      pastYearDaysToSellAvg: null,
      daysToSellGraphValues: null,
      daysToSellFormattedValues: null,
      daysToSellTrendingPercPoint: null,
      daysToSellDescription: null,
      daysToSellSellersAdvantage: false,
      //RECEIVED OF ASKING
      currentYearReceivedOfAskingAvg: null,
      pastYearReceivedOfAskingAvg: null,
      receivedOfAskingGraphValues: null,
      receivedOfAskingFormattedValues: null,
      receivedOfAskingTrendingPercPoint: null,
      receivedOfAskingDescription: null,
      receivedOfAskingSellersAdvantage: false,

      //UNITS SOLD
      currentYearUnitsSold: null,
      pastYearUnitsSold: null,
      unitsSoldGraphValues: null,
      unitsSoldFormattedValues: null,
      unitsSoldTrendingPercPoint: null,
      unitsSoldDescription: null,
      unitsSoldSellersAdvantage: false,
    };
    const {
      areaSellersAdvantages,
      citySellersAdvantages,
    } = calculateSellersAdvantages;
    if (determineCityOrArea === "area") {
      if (useMedian) {
        dataObj.currentYearSalePriceAvg = roundSalePrice(
          current180DaysMedianSalePrice
        );
        dataObj.pastYearSalePriceAvg = roundSalePrice(
          previous180DaysMedianSalePrice
        );
        dataObj.salePriceTrendingPercPoint = median180DaysPercentage;
        dataObj.useMedian = true;
      } else {
        dataObj.currentYearSalePriceAvg = roundSalePrice(
          currentYearObjArea[0].AvgSalePrice
        );
        dataObj.pastYearSalePriceAvg = roundSalePrice(
          pastYearObjArea[0].AvgSalePrice
        );
        dataObj.salePriceTrendingPercPoint = calculateDiff(
          currentYearObjArea[0].AvgSalePrice,
          pastYearObjArea[0].AvgSalePrice
        );
      }

      dataObj.salePriceGraphValues = graphAveragesArea
        .slice(1, 6)
        .map((x) => x.AverageSalePrice);
      dataObj.salePriceFormattedValues = graphAveragesArea
        .slice(1, 6)
        .map((x) => {
          if (x.AverageSalePrice === "0") {
            return "No Sales";
          } else {
            return roundSalePrice(x.AverageSalePrice);
          }
        });

      if (useMedian) {
        dataObj.salePriceDescription = "Median Sale Prices";
      } else if (dataObj.salePriceTrendingPercPoint >= 1) {
        dataObj.salePriceDescription = "Higher Sale Prices";
      } else {
        dataObj.salePriceDescription = "Sale Prices";
      }
      dataObj.salePriceSellersAdvantage = areaSellersAdvantages.includes(
        "AVG SALE PRICE"
      );

      // DAYS TO SELL AREA
      dataObj.currentYearDaysToSellAvg = currentYearObjArea[0].AvgDaysOnMarket;
      dataObj.pastYearDaysToSellAvg = pastYearObjArea[0].AvgDaysOnMarket;
      dataObj.daysToSellGraphValues = graphAveragesArea
        .slice(1, 6)
        .map((x) => x.AverageDaysOnMarket);
      dataObj.daysToSellFormattedValues = graphAveragesArea
        .slice(1, 6)
        .map((x) => {
          if (x.AverageDaysOnMarket === "0" && x.AverageSalePrice === "0") {
            return "No Sales";
          } else {
            return x.AverageDaysOnMarket;
          }
        });

      dataObj.daysToSellTrendingPercPoint = Math.round(
        currentYearAreaPerc[0].PercentageAvgDaysOnMarket
      );
      let domDiff =
        pastYearObjArea[0].AvgDaysOnMarket -
        currentYearObjArea[0].AvgDaysOnMarket;
      if (
        dataObj.daysToSellTrendingPercPoint <= 1 &&
        currentYearObjArea[0].AvgDaysOnMarket !== "0"
      ) {
        dataObj.daysToSellDescription = `${Math.abs(
          pastYearObjArea[0].AvgDaysOnMarket -
            currentYearObjArea[0].AvgDaysOnMarket
        )} ${domDiff === 1 ? "Day" : "Days"} Faster`;
      } else {
        dataObj.daysToSellDescription = "Days to Sell";
      }
      dataObj.daysToSellSellersAdvantage =
        areaSellersAdvantages.includes("DAYS TO SELL") &&
        currentYearObjArea[0].AvgDaysOnMarket !== "0";

      //RECEIVED OF ASKING AREA
      dataObj.currentYearReceivedOfAskingAvg = `${Math.round(
        currentYearObjArea[0].AvgPercentOfReceiving
      )}%`;
      dataObj.pastYearReceivedOfAskingAvg = `${Math.round(
        pastYearObjArea[0].AvgPercentOfReceiving
      )}%`;
      dataObj.receivedOfAskingGraphValues = graphAveragesArea
        .slice(1, 6)
        .map((x) => Math.round(x.AveragePercentOfReceiving));
      dataObj.receivedOfAskingFormattedValues = graphAveragesArea
        .slice(1, 6)
        .map((x) => {
          if (x.AveragePercentOfReceiving === "0") {
            return "No Sales";
          } else {
            return `${Math.round(x.AveragePercentOfReceiving)}%`;
          }
        });
      dataObj.receivedOfAskingTrendingPercPoint =
        Math.round(currentYearObjArea[0].AvgPercentOfReceiving) -
        Math.round(pastYearObjArea[0].AvgPercentOfReceiving);
      if (dataObj.receivedOfAskingTrendingPercPoint >= 1) {
        dataObj.receivedOfAskingDescription = "Higher Offer Prices";
      } else {
        dataObj.receivedOfAskingDescription = "% of Asking Price";
      }
      dataObj.receivedOfAskingSellersAdvantage = areaSellersAdvantages.includes(
        "% RECEIVED OF ASKING"
      );

      //UNITS SOLD AREA
      dataObj.currentYearUnitsSold = currentYearObjArea[0].HomesSold;
      dataObj.pastYearUnitsSold = pastYearObjArea[0].HomesSold;
      dataObj.unitsSoldGraphValues = graphAveragesArea
        .slice(1, 6)
        .map((x) => x.NumberOfListings);
      dataObj.unitsSoldFormattedValues = graphAveragesArea
        .slice(1, 6)
        .map((x) => {
          if (x.NumberOfListings === "0") {
            return "No Sales";
          } else {
            return x.NumberOfListings;
          }
        });
      let homesSoldDiff = calculateDiff(
        currentYearObjArea[0].HomesSold,
        pastYearObjArea[0].HomesSold
      );
      dataObj.unitsSoldTrendingPercPoint = homesSoldDiff;
      if (homesSoldDiff >= 5) {
        dataObj.unitsSoldDescription = "More Homes Sold";
      } else {
        dataObj.unitsSoldDescription = "Homes Sold";
      }
      dataObj.unitsSoldSellersAdvantage = areaSellersAdvantages.includes(
        "UNITS SOLD"
      );
    } else {
      //FOR CITY

      //SALE PRICE CITY
      dataObj.currentYearSalePriceAvg = roundSalePrice(
        currentYearObjCity[0].AvgSalePrice
      );
      dataObj.pastYearSalePriceAvg = roundSalePrice(
        pastYearObjCity[0].AvgSalePrice
      );
      dataObj.salePriceGraphValues = graphAveragesCity
        .slice(1, 6)
        .map((x) => x.AverageSalePrice);
      dataObj.salePriceFormattedValues = graphAveragesCity
        .slice(1, 6)
        .map((x) => {
          if (x.AverageSalePrice === "0") {
            return "No Sales";
          } else {
            return roundSalePrice(x.AverageSalePrice);
          }
        });
      dataObj.salePriceTrendingPercPoint = calculateDiff(
        currentYearObjCity[0].AvgSalePrice,
        pastYearObjCity[0].AvgSalePrice
      );
      if (dataObj.salePriceTrendingPercPoint >= 1) {
        dataObj.salePriceDescription = "Higher Sale Prices";
      } else {
        dataObj.salePriceDescription = "Lower Sale Prices";
      }
      dataObj.salePriceSellersAdvantage = citySellersAdvantages.includes(
        "AVG SALE PRICE"
      );
      // DAYS TO SELL city
      dataObj.currentYearDaysToSellAvg = currentYearObjCity[0].AvgDaysOnMarket;
      dataObj.pastYearDaysToSellAvg = pastYearObjCity[0].AvgDaysOnMarket;
      dataObj.daysToSellGraphValues = graphAveragesCity
        .slice(1, 6)
        .map((x) => x.AverageDaysOnMarket);

      dataObj.daysToSellFormattedValues = graphAveragesCity
        .slice(1, 6)
        .map((x) => {
          if (x.AverageDaysOnMarket === "0" && x.AverageSalePrice === "0") {
            return "No Sales";
          } else {
            return x.AverageDaysOnMarket;
          }
        });
      dataObj.daysToSellTrendingPercPoint = Math.round(
        currentYearCityPerc[0].PercentageAvgDaysOnMarket
      );

      if (
        dataObj.daysToSellTrendingPercPoint <= 1 &&
        dataObj.currentYearDaysToSellAvg != "0"
      ) {
        let daysToSellDiffCity =
          pastYearObjCity[0].AvgDaysOnMarket -
          currentYearObjCity[0].AvgDaysOnMarket;
        dataObj.daysToSellDescription = `${daysToSellDiffCity} ${
          daysToSellDiffCity == "1" ? "Day" : "Days"
        } Faster`;
      } else {
        dataObj.daysToSellDescription = "Days to Sell";
      }
      dataObj.daysToSellSellersAdvantage =
        citySellersAdvantages.includes("DAYS TO SELL") &&
        currentYearObjArea[0].AvgDaysOnMarket !== "0";

      //RECEIVED OF ASKING CITY
      dataObj.currentYearReceivedOfAskingAvg = `${Math.round(
        currentYearObjCity[0].AvgPercentOfReceiving
      )}%`;
      dataObj.pastYearReceivedOfAskingAvg = `${Math.round(
        pastYearObjCity[0].AvgPercentOfReceiving
      )}%`;
      dataObj.receivedOfAskingGraphValues = graphAveragesCity
        .slice(1, 6)
        .map((x) => Math.round(x.AveragePercentOfReceiving));
      dataObj.receivedOfAskingFormattedValues = graphAveragesCity
        .slice(1, 6)
        .map((x) => {
          if (x.AveragePercentOfReceiving === "0") {
            return "No Sales";
          } else {
            return `${Math.round(x.AveragePercentOfReceiving)}%`;
          }
        });
      dataObj.receivedOfAskingTrendingPercPoint =
        Math.round(currentYearObjCity[0].AvgPercentOfReceiving) -
        Math.round(pastYearObjCity[0].AvgPercentOfReceiving);

      if (dataObj.receivedOfAskingTrendingPercPoint >= 1) {
        dataObj.receivedOfAskingDescription = "Higher Offer Prices";
      } else {
        dataObj.receivedOfAskingDescription = "% of Asking";
      }

      dataObj.receivedOfAskingSellersAdvantage = citySellersAdvantages.includes(
        "% RECEIVED OF ASKING"
      );
      //UNITS SOLD
      //UNITS SOLD CITY
      dataObj.currentYearUnitsSold = currentYearObjCity[0].HomesSold;
      dataObj.pastYearUnitsSold = pastYearObjCity[0].HomesSold;
      dataObj.unitsSoldGraphValues = graphAveragesCity
        .slice(1, 6)
        .map((x) => x.NumberOfListings);
      dataObj.unitsSoldFormattedValues = graphAveragesCity
        .slice(1, 6)
        .map((x) => {
          if (x.NumberOfListings === "0") {
            return "No Sales";
          } else {
            return x.NumberOfListings;
          }
        });
      let homesSoldDiff = calculateDiff(
        currentYearObjCity[0].HomesSold,
        pastYearObjCity[0].HomesSold
      );
      dataObj.unitsSoldTrendingPercPoint = homesSoldDiff;
      if (homesSoldDiff >= 5) {
        dataObj.unitsSoldDescription = "More Homes Sold";
      } else {
        dataObj.unitsSoldDescription = "Homes Sold";
      }
      dataObj.unitsSoldSellersAdvantage = citySellersAdvantages.includes(
        "UNITS SOLD"
      );
    }
    let colorList = [
      "rgba(113, 155, 153, 1)",
      "rgba(178,139,65, 1)",
      "rgba(114,53,79, 1)",
    ];
    let order = ["firstBg", "secondBg", "thirdBg"];
    let bottomLabelsText = graphAveragesArea
      .slice(1, 6)
      .map(
        (x) => `${x.NameOfQuarter.slice(-2)} ${x.NameOfQuarter.slice(0, 4)}`
      );

    return (
      <div className="qv4-seller-graph-container">
        {listToShow.includes("AVG SALE PRICE") && (
          <QVFourSellerGraph
            category="AVERAGE SALE PRICE"
            categoryName={
              dataObj.useMedian ? "MEDIAN SALE PRICE" : "AVERAGE SALE PRICE"
            }
            description={dataObj.salePriceDescription}
            currentYearDataPoint={dataObj.currentYearSalePriceAvg}
            lastYearDataPoint={dataObj.pastYearSalePriceAvg}
            trendingPercPoint={dataObj.salePriceTrendingPercPoint}
            dataPoints={dataObj.salePriceGraphValues}
            formattedPoints={dataObj.salePriceFormattedValues}
            bgColor={colorList[listToShow.indexOf("AVG SALE PRICE")]}
            colorOrder={order[listToShow.indexOf("AVG SALE PRICE")]}
            graphPosition={listToShow.indexOf("AVG SALE PRICE") + 1}
            bottomLabelsText={bottomLabelsText}
            isSellersAdvantage={dataObj.salePriceSellersAdvantage}
            totalUnitsSold6Months="XX"
            totalUnitsSoldPrevious6Months="XX"
            printVersion={printVersion}
            totalUnitsSold6Months={dataObj.currentYearUnitsSold}
            totalUnitsSoldPrevious6Months={dataObj.pastYearUnitsSold}
            useMedian={dataObj.useMedian}
          />
        )}
        {listToShow.includes("DAYS TO SELL") && (
          <QVFourSellerGraph
            category="DAYS TO SELL"
            categoryName="AVERAGE DAYS TO SELL"
            description={dataObj.daysToSellDescription}
            currentYearDataPoint={dataObj.currentYearDaysToSellAvg}
            lastYearDataPoint={dataObj.pastYearDaysToSellAvg}
            trendingPercPoint={dataObj.daysToSellTrendingPercPoint}
            dataPoints={dataObj.daysToSellGraphValues}
            formattedPoints={dataObj.daysToSellFormattedValues}
            bgColor={colorList[listToShow.indexOf("DAYS TO SELL")]}
            colorOrder={order[listToShow.indexOf("DAYS TO SELL")]}
            graphPosition={listToShow.indexOf("DAYS TO SELL") + 1}
            bottomLabelsText={bottomLabelsText}
            printVersion={printVersion}
            isSellersAdvantage={dataObj.daysToSellSellersAdvantage}
            totalUnitsSold6Months={dataObj.currentYearUnitsSold}
            totalUnitsSoldPrevious6Months={dataObj.pastYearUnitsSold}
            useMedian={dataObj.useMedian}
          />
        )}

        {listToShow.includes("% RECEIVED OF ASKING") && (
          <QVFourSellerGraph
            category="AVERAGE % RECEIVED OF ASKING"
            categoryName="AVERAGE % RECEIVED OF ASKING"
            description={dataObj.receivedOfAskingDescription}
            currentYearDataPoint={dataObj.currentYearReceivedOfAskingAvg}
            lastYearDataPoint={dataObj.pastYearReceivedOfAskingAvg}
            trendingPercPoint={dataObj.receivedOfAskingTrendingPercPoint}
            dataPoints={dataObj.receivedOfAskingGraphValues}
            formattedPoints={dataObj.receivedOfAskingFormattedValues}
            bgColor={colorList[listToShow.indexOf("% RECEIVED OF ASKING")]}
            colorOrder={order[listToShow.indexOf("% RECEIVED OF ASKING")]}
            graphPosition={listToShow.indexOf("% RECEIVED OF ASKING") + 1}
            bottomLabelsText={bottomLabelsText}
            printVersion={printVersion}
            isSellersAdvantage={dataObj.receivedOfAskingSellersAdvantage}
            totalUnitsSold6Months={dataObj.currentYearUnitsSold}
            totalUnitsSoldPrevious6Months={dataObj.pastYearUnitsSold}
            useMedian={dataObj.useMedian}
          />
        )}
        {listToShow.includes("UNITS SOLD") && (
          <QVFourSellerGraph
            category="UNITS SOLD"
            categoryName="UNITS SOLD"
            description={dataObj.unitsSoldDescription}
            currentYearDataPoint={dataObj.currentYearUnitsSold}
            lastYearDataPoint={dataObj.pastYearUnitsSold}
            trendingPercPoint={dataObj.unitsSoldTrendingPercPoint}
            dataPoints={dataObj.unitsSoldGraphValues}
            formattedPoints={dataObj.unitsSoldFormattedValues}
            bgColor={colorList[listToShow.indexOf("UNITS SOLD")]}
            colorOrder={order[listToShow.indexOf("UNITS SOLD")]}
            graphPosition={listToShow.indexOf("UNITS SOLD") + 1}
            bottomLabelsText={bottomLabelsText}
            isSellersAdvantage={dataObj.unitsSoldSellersAdvantage}
            printVersion={printVersion}
            useMedian={dataObj.useMedian}
          />
        )}
      </div>
    );
  }
}

export default QVFourSellerGraphContainer;
