import React, { Component } from "react";
import YEOneSecondPageLeft from "./YEOneSecondPageLeft";
import YEOneSecondPageRight from "./YEOneSecondPageRight";

class YEOneSecondPage extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      yearEnd,
      printVersion,
    } = this.props;
    return (
      <div
        className={
          "pma-page-container " + (printVersion ? "print-page-container" : "")
        }
      >
        <div className="ye1-second-page">
          <YEOneSecondPageLeft
            agentData={agentData}
            yearEnd={yearEnd}
            currentArea={currentArea}
          />
          <YEOneSecondPageRight
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            yearEnd={yearEnd}
          />
        </div>
      </div>
    );
  }
}

export default YEOneSecondPage;
