import { green, red } from "@material-ui/core/colors";

export default (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.darker,
    color: "white",
  },
  height64px: {
    height: theme.spacing(8),
  },
  height128px: {
    height: theme.spacing(16),
  },
  height152px: {
    height: theme.spacing(19),
  },
  rootAppBar: {
    position: "fixed",
    width: "100%",
    top: 0,
    left: 0,
    right: "auto",
    color: "white",
    height: "auto",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
    borderBottom: "1px solid #d4d4d4",
  },
  menuContainer: {
    display: "flex",
    flexDirection: "row",
    //justifyContent: 'space-around',
    alignItems: "center",
    color: "white",
    backgroundColor: theme.palette.primary.darker,
    height: theme.spacing(8),
  },
  whiteText: {
    color: "white",
    fontSize: 18,
    height: "100%",
  },
  icon: {
    //fontSize: '24px',
    marginTop: "4px",
    marginLeft: "-4px",
  },
  menuButtonLabel: {
    display: "flex",
    flexDirection: "column",
    "&>svg:nth-child(1)": {
      width: "26px",
      height: "26px",
    },
    "&>span:nth-child(2)": {
      fontSize: "16px",
    },
  },
  tabContainer: {
    backgroundColor: theme.palette.primary.darker,
    color: "white",
    // '&.MuiTab-wrapper': {
    //   color: 'green',
    //   backgroundColor: 'orange',
    // },
    // '&.MuiButtonBase-root MuiTab-root MuiTab-textColorInherit MappedNav-tabs-135': {
    //   color: theme.palette.primary.mediumLight,
    // },
  },
  tabs: {
    // backgroundColor: theme.palette.primary.darker,
    color: theme.palette.primary.light,
    fontSize: "18px",
    opacity: 1,
    // '&.MuiButtonBase-root MuiTab-root MuiTab-textColorInherit MappedNav-tabs-135': {
    //   color: theme.palette.primary.mediumLight,
    // },
  },
  tabIndicator: {
    backgroundColor: "white !important",
  },
  menuItem: {
    color: "white",
    backgroundColor: theme.palette.primary.darker,

    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    paddingTop: 0,
    paddingBottom: 0,
    borderBottom: "1px dashed white",
  },
  menuItemNoBorderBottom: {
    color: "white",
    backgroundColor: theme.palette.primary.darker,

    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuHover: {
    "&:hover": {
      backgroundColor: theme.palette.primary.currentMailProColor,
    },
  },
  menuSelected: {
    backgroundColor: `${theme.palette.black.jetBlack} !important`,
  },
  downloadIcon: {
    width: '36px', 
    height: '36px'
  },
  menuItemContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexWrap: 'nowrap',
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  "&.MuiList-padding": {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuItemText: {
    color: "white",
    textAlign: "left",
    whiteSpace: "initial"
  },
  menuList: {
    top: "100px",
  },
  approvedText: {
    color: "limegreen",
  },
  incompleteText: {
    color: theme.palette.error.medium,
  },
  listItemContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    //paddingTop: theme.spacing(0)
  },
  listContainerExtraMarginTop: {
    marginTop: -theme.spacing(1),
  },
  listContainer: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "auto",
  },
  listDescription: {
    color: "white",
    fontSize: "18px",
  },
  listDescriptionForLongAreaNames: {
    color: "white",
    fontSize: "14px",
    wordWrap: "break-word",
  },
  areaRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    '&>div:nth-child(1)': {
      flexBasis: "70%",
    },
    '&>div:nth-child(2)': {
      flexBasis: "30%",
      textAlign: "right",
    },
  },
  alignRight: {
    textAlign: "right",
  },
  marginRightMinus: {
    marginRight: -theme.spacing(7),
  },
  noHover: {
    "&:hover": {
      backgroundColor: theme.palette.primary.darker,
    },
  },
  mediumLightBg: {
    backgroundColor: theme.palette.primary.mediumLight,
  },
  menuSection: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(4),
    justifyContent: "space-between",
  },
  flexStartJustify: {
    justifyContent: "flex-start",
  },
  flexSpaceJustify: {
    justifyContent: "space-between",
  },
  tabSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  areaSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //paddingLeft: '11vw',
    paddingRight: theme.spacing(2),
  },
  areaSectionPaddingLeft: {
    paddingLeft: "8vw",
  },
  areaSectionPaddingLeftSmall: {
    paddingLeft: "3vw",
  },
  centerListArea: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  mailProLogoHolder: {
    width: "150px",
    height: "64px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mailProImageTag: {
    width: "100%",
  },
  mailingHeader: {
    height: theme.spacing(11), //
    marginTop: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff8e1",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    overflow: "auto",
  },
  blockedHeaderNotice: {
    backgroundColor: "#b71c1c",
    color: "white",
    padding: ".5em",
    //marginLeft: "-3em",
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    borderRadius: '4px'
  },
  holdHeaderNotice: {
    backgroundColor: "#387a09",
    color: "white",
    padding: "0.6em",
    //marginLeft: "-3em",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    borderRadius: "4px",
  },
  blockedHeaderSuccess: {
    backgroundColor: green[700],
    color: "white",
    padding: ".3em", //".5em",
    //marginLeft: "-3em",
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    borderRadius: "4px",
  },
  // firstBlockedTextSection: {
  //   display: "flex",
  //   justifyContent: "flex-start",
  //   alignItems: "center",
  //   width: "20%"
  // },
  firstToLastBlockedTextSectionUpdated: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "30%", //20%
  },
  secondBlockedFilterSection: {
    //width: '45%',
  },
  // lastButtonSection: {
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   minWidth: "10%"
  // },
  lasttoFirstButtonSectionUpdated: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "25%",
    maxWidth: "33%",
  },
  alreadyApproved: {
    color: "#387a09",
    border: "1px solid #387a09",
    padding: "10px",
  },
  approveMailingList: {
    color: "white !important",
    backgroundColor: "#387a09",
    position: "relative",
    animation: "$new-blinker 2s linear infinite",
    animationDuration: "2s",
    paddingLeft: '4px',
    paddingRight: '4px',
    fontSize: '13px',
    "&:hover": {
      color: "white !important",
      backgroundColor: "#387a09",
    },

  },
  spanFont20px: {
    color: theme.palette.primary.darker,
    fontSize: "16px", //18px
    fontWeight: 500,
  },
  deleteSpanMailing: {
    color: red[900],
  },
  whiteColor: {
    color: "white",
  },
  blockMarginRight: {
    marginRight: theme.spacing(2),
  },
  boldFontWeight: {
    fontWeight: 500,
  },
  versionSectionDiv: {
    display: "flex-box",
    justifyContent: "center",
  },
  borderRadius: {
    borderRadius: "4px",
  },
  redBg: {
    backgroundColor: red[900],
    color: "white",
    paddingLeft: "0.5em",
    paddingRight: "0.5em",
  },
  greenBg: {
    color: "white",
    backgroundColor: "green",
    paddingLeft: "0.5em",
    paddingRight: "0.5em",
  },
  greenBgPadding: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  unapproveAreaDiv: {
    color: "white",
    display: "flex",
    //padding: theme.spacing(0.5),
    textAlign: "left",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-evenly",
    backgroundColor: red[900],
    padding: "0.2em",
    maxWidth: "8vw",
    borderRadius: "4px",
  },
  unapproveAreaText: {
    lineHeight: "16px",
  },
  smallerMiddleGridSection: {
    width: "21%",
    flexBasis: "21%",
  },
  listItemTextRoot: {
    minWidth: "auto",
    //marginTop: 0,
    marginBottom: 0,
  },

  pmaApprovalButtonLineHeight18: {
    lineHeight: "18px",
  },
  approvalTooltipArrow: {
    color: "black",
  },
  approvalTooltipMain: {
    backgroundColor: "black !important",
    color: "white",
    fontWeight: 500,
    fontSize: "14px",
    padding: theme.spacing(2),
  },
  approvalRedColor: {
    color: red[500],
  },
  approvalGreenBg: {
    color: "white",
    backgroundColor: "green",
    padding: "0.2em",
  },
  approvalButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(2, 0, 0, 0),
  },
  approvalButton: {
    color: "white",
    //border: '1px solid white',
    cursor: "pointer",
    background: "#3D85C6",
    "&:hover": {
      background: "#0B5394",
      color: "white",
    },
  },
  approvalBackdrop: {
    zIndex: 1400,
    color: "#fff",
  },
  approvalBackdropHighlight: {
    padding: theme.spacing(1.5, 2),
    background: "#FFF",
    zIndex: 1505, //higher then approvalBackdrop zIndex
    position: "relative",
    borderRadius: "4px",
  },
  tooltipArrow: {
    top: "70px",
  },
  tooltipArrowMain: {
    //marginTop: '-0.48em',
    marginTop: "-0.71em",
    marginBottom: "auto",
    top: 0,
    bottom: "auto !important",
    color: "#000",
    "&::before": {
      transformOrigin: "0 100% !important",
    },
  },
  supportButton: {
    fontSize: 50,
    color: "white",
    "&:hover": {
      cursor: "pointer",
    },
  },
  avatarChip: {
    width: `${theme.spacing(3)}px !important`,
    height: `${theme.spacing(3)}px !important`,
    marginLeft: `0 !important`,
    color: "white !important",
    backgroundColor: theme.palette.primary.main,
    fontWeight: 800,
  },
  clickableChip: {
    "&:hover": {
      backgroundColor: "#3D85C6 !important",
      color: "white !important",
    },
  },
  chipClickablePrimary: {
    backgroundColor: "#3D85C6",
    "&:focus": {
      backgroundColor: "#3D85C6",
    },
    "&:hover": {
      backgroundColor: "#316a9e",
    },
  },
  deleteIconColorPrimary: {
    color: `${red[300]} !important`,
    width: "22px",
    height: "22px",
    "&:hover": {
      color: `${red[400]} !important`,
    },
  },
  newText: {
    color: red[500],
    border: `2px solid ${red[500]}`,
    borderRadius: `4px`,
    fontWeight: 800,
    fontSize: "14px",
    padding: "4px 4px",
    animation: "$new-blinker 2s linear 10",
    WebkitAnimation: "$new-blinker 2s linear 10",
    animationDuration: "1s",
    letterSpacing: "1px",
    marginLeft: -theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  "@keyframes new-blinker": {
    "80%": {
      opacity: 0,
    },
  },
  flashButton: {
    animation: "$new-blinker 2s linear infinite",
    animationDuration: "2s",
    "&:hover": {
      animation: "0",
      WebkitAnimation: 0,
    },
  },
  newPropertyChipBg: {
    background: `#52e0da`,
  },
  newlyConstructedChipBg: {
    background: `#2ca8a1`,
  },
  clickableNewPropertyChip: {
    "&:hover": {
      backgroundColor: "#52e0da !important",
      color: "white !important",
    },
  },
  clickableNewlyConstructedChip: {
    "&:hover": {
      backgroundColor: "#2ca8a1 !important",
      color: "white !important",
    },
  },
});
