import React, { Component } from 'react';
import { propertyUtil } from 'views/Pma/PropertyUtil';

class BottomRightFooter extends Component {
  render() {
    const {
      brokerageName,
      listgenUserFirstName,
      listgenUserLastName,
      isMemberOfTeam,
      teamMembers,
    } = this.props.agentData;
    const { blackBorder } = propertyUtil;
    const hasBlackBorder = blackBorder(brokerageName);
    let hasSameLastName;
    let teamWithSameLastName;
    if (isMemberOfTeam) {
      if (listgenUserLastName === teamMembers[1].lastName) {
        hasSameLastName = true;
        teamWithSameLastName = `${listgenUserFirstName} and ${teamMembers[1].firstName} ${listgenUserLastName}`;
      }
    }
    return (
      <div
        className={
          'bottom-right-footer ' +
          (hasBlackBorder ? 'black-bottom-right-footer' : '')
        }
      >
        <div className="bottom-right-footer-inner-container">
          {hasSameLastName ? (
            <div className="bottom-right-footer-text">
              Compiled by {teamWithSameLastName}
            </div>
          ) : isMemberOfTeam ? (
            <div className="bottom-right-footer-text">
              Compiled by {listgenUserFirstName} {listgenUserLastName} &{' '}
              {teamMembers[1].firstName} {teamMembers[1].lastName}
            </div>
          ) : (
            <div className="bottom-right-footer-text">
              Compiled by {listgenUserFirstName} {listgenUserLastName}
            </div>
          )}

          <div className="bottom-right-footer-divider" />
          <div className="bottom-right-footer-text">{brokerageName}</div>
        </div>
      </div>
    );
  }
}

export default BottomRightFooter;
