import React, {
  Component,
  Fragment,
  useState,
  useEffect,
  useContext,
} from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "../styles";
import styles2 from "./styles.scss";
import "./styles.scss";
import { Dashboard as DashboardLayout } from "layouts";
import { withSnackbar } from "notistack";
import { Portlet } from "components";
import { PortletContent } from "components";
import { PortletHeader } from "components";
import classNames from "classnames";
import { GetAreaPropHighlights } from "services/others";
import Base from "common/constants";
import {
  GetStateStorage,
  GetNavbarDataFromStorage,
  IsValidSessionSwitch,
} from "common/storage";

import moment from "moment";
import { GetPmaAdminDataAPI } from "services/agent";
import { showErrorNotification } from "common/helpers";
import { showSuccessNotification } from "common/helpers";
// import LinearProgress from "@mui/material/LinearProgress";
import { withRouter } from "react-router-dom";
import { Store } from "Store";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";
import { propertyUtil } from "views/Pma/PropertyUtil";
import FirstPage from "./FirstPage";
import ExistingQuadrants from "./ExistingQuadrants";

const AgentFocus = (props) => {
  const { classes } = props;
  const { dispatch } = useContext(Store);
  //session related variables
  const isAValidSessionSwitch = IsValidSessionSwitch();
  const { userData } = GetStateStorage();

  /**loading variables */
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState("");
  const [currentPage, setCurrentPage] = useState("first");
  const [forTeams, setForTeams] = useState("");
  const [defaultOptional, setDefaultOptional] = useState("");

  const handleSelectedCheckbox = (type) => {
    console.log("type is", type);
    if (selectedCheckbox === type) {
      setSelectedCheckbox("");
    } else if (selectedCheckbox === "") {
      setSelectedCheckbox(type);
    } else if (selectedCheckbox !== type) {
      setShowError("deselect checkbox");
    }
  };
  const handleForTeams = (type) => {
    console.log("type for teams", type);
    setForTeams(type);
  };
  const handleNext = ({ type, page }) => {
    console.log("type", type);
    console.log("page", page);
    if (type === "quadrant" && page == "first") {
      console.log("first and quadrant");
      setCurrentPage("existing quadrants");
    } else if (type === "quadrant" && page === "existing quadrant") {
      console.log("go next");
    }
  };

  const handleDefaultOptional = (type) => {
    setDefaultOptional(type);
  };
  return (
    <Fragment>
      <DocumentTitle title="Agent Focus">
        <DashboardLayout title="">
          <div className={classes.root}>
            <Portlet
              className={classes.contentPadded}
              className="agent-focus-container"
            >
              <PortletContent noPadding>
                {currentPage === "first" ? (
                  <FirstPage
                    selectedCheckbox={selectedCheckbox}
                    handleSelectedCheckbox={handleSelectedCheckbox}
                    handleNext={handleNext}
                  />
                ) : currentPage === "existing quadrants" ? (
                  <ExistingQuadrants
                    forTeams={forTeams}
                    handleForTeams={handleForTeams}
                    handleDefaultOptional={handleDefaultOptional}
                    defaultOptional={defaultOptional}
                    handleNext={handleNext}
                  />
                ) : (
                  ""
                )}
              </PortletContent>
            </Portlet>
          </div>
        </DashboardLayout>
      </DocumentTitle>
    </Fragment>
  );
};

AgentFocus.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose(
  withSnackbar,
  withStyles(styles),
  withRouter
)(AgentFocus);
