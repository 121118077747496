// Palette
import palette from '../palette';

export default {
  //MuiFormLabel: {
    asterisk: {
      color: palette.error.main,
      fontWeight: 800,
      '&$error': {
        color: palette.error.main,
        fontWeight: 800,
      },
    }
  //}
};
