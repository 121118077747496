import React, { Component } from "react";
import QVFourPhotoSection from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPhotoSection";
import QVFourPresentedByFooter from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPresentedByFooter";
import QVFourAgentLogoHeader from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourAgentLogoHeader";
import QVFourPS from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPS";

class QVThreeSecondRight extends Component {
  render() {
    const {
      currentArea,
      agentData,
      printVersion,
      version,
      hoData,
      mlsAreas,
      aprilData,
      demoAccountHOName,
    } = this.props;
    const { currentSoldListings } = aprilData.pmaQuarterlyAreaDTO;
    console.log("3rd right", this.props);
    return (
      <div className="qv3-second-right">
        <QVFourAgentLogoHeader agentData={agentData} />
        {hoData &&
          hoData.changeDetails &&
          Object.keys(hoData.changeDetails).length > 1 && (
            <QVFourPS
              agentData={agentData}
              hoData={hoData}
              currentArea={currentArea}
              mlsAreas={mlsAreas}
              version={version}
              printVersion={printVersion}
              mailingNum="qv3"
              demoAccountHOName={demoAccountHOName}
            />
          )}

        <QVFourPhotoSection
          currentArea={currentArea}
          agentData={agentData}
          printVersion={printVersion}
          version={version}
          hoData={hoData}
          currentSoldListings={currentSoldListings}
        />
        <QVFourPresentedByFooter
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
        />
      </div>
    );
  }
}

export default QVThreeSecondRight;
