export default {
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  agentDesignation: {
    presence: { allowEmpty: false, message: "is required" },
  },
  emailAddress: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  // displayEmailAddress: {
  //   presence: { allowEmpty: true, message: "is required" },
  //   email: true,
  //   length: {
  //     minimum: 0,
  //     maximum: 64,
  //   },
  // },
  agentLicenseNumber: {
    presence: {
      allowEmpty: false,
      message: "is required",
    },
    numericality: {
      onlyInteger: false,
      greaterThan: 0,
    },
    length: {
      is: 8,
    },
  },
  brokerLicenseNumber: {
    presence: { allowEmpty: false, message: "is required" },
    numericality: {
      onlyInteger: false,
      greaterThan: 0,
    },
    length: {
      is: 8,
    },
  },
  mobilePhone: {
    presence: {
      allowEmpty: false,
      message: "is required",
    },
    length: {
      minimum: 6
    },
    numericality: {
      onlyInteger: true,
      message: 'is not valid',
    },
  },
  // password: {
  //   presence: { allowEmpty: false, message: 'is required' },
  //   length: {
  //     minimum: 6,
  //     tooShort: 'should have a minimum of 6 characters',
  //   },
  // },
  // confirmPassword: {
  //   presence: { allowEmpty: false, message: 'is required' },
  //   equality: {
  //     attribute: 'password',
  //     message: 'is not matching',
  //     comparator: function(v1, v2) {
  //       return v1 === v2;
  //     },
  //   },
  //   length: {
  //     maximum: 128,
  //   },
  // },
  // website: function (value, attributes, attributeName, options, constraints) {
  //   let pattern1 = /^((http|https|ftp|:|\/\/)+)/;
  //   let pattern2 = /^((www.)+)/;
  //   // test the patterns
  //   console.log('First test=', pattern1.test(value));
  //   console.log('Second test=', pattern2.test(value));
  //   if (pattern1.test(value) || pattern2.test(value)) {
  //     return true;
  //     return {
  //       presence: { message: "should be a valid url." }
  //     }
  //   }
  //   return null;
  // },
  website: {
    presence: { allowEmpty: true, message: "is required" },
    // format: {
    //   pattern:
    //   "[a-zA-Z0-9][a-zA-Z0-9-]{1,61}(\.{1})[a-zA-Z0-9]([a-zA-Z]{1,6})"
    // },
    // length: function(value, attributes, attributeName, options, constraints) {
    //   if (value.substring(0,3) === 'www.') {
    //     return {
    //       message: 'www. is not allowed at url start.'
    //     };
    //   }
    //   // Unknown card, don't validate length
    //   return false;
    // }
  },
  tagline: {
    presence: { allowEmpty: true, message: "is required" },
  },
  // officeAddressLine1: {
  //   presence: { allowEmpty: false, message: "is required" },
  // },
  // officeAddressLine2: {
  //   presence: { allowEmpty: true, message: "is required" },
  // },
  // officeCity: {
  //   presence: { allowEmpty: false, message: "is required" },
  // },
  // officeState: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: {
  //     is: 2,
  //     message: "length is invalid",
  //   },
  // },
  // officeZipcode: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: {
  //     is: 5,
  //     message: "length is invalid",
  //   },
  // },
  // teamName: {
  //   presence: { allowEmpty: false, message: 'is required' },
  // },
  mailingAddressLine1: {
    presence: { allowEmpty: false, message: "is required" },
  },
  mailingAddressLine2: {
    presence: { allowEmpty: true, message: "is required" },
  },
  city: {
    presence: { allowEmpty: false, message: "is required" },
  },
  state: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      is: 2,
      message: "length is invalid",
    },
  },
  zipcode: {
    presence: {
      allowEmpty: false,
      message: "is required",
    },
    // length: {
    //   is: 5,
    //   message: "length is invalid",
    // },
  },
  billingFirstName: {
    presence: { allowEmpty: false, message: "is required" },
  },
  billingLastName: {
    presence: { allowEmpty: false, message: "is required" },
  },
  billingAddressLine1: {
    presence: { allowEmpty: false, message: "is required" },
  },
  billingCity: {
    presence: { allowEmpty: false, message: "is required" },
  },
  billingState: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      is: 2,
      message: "length is invalid",
    },
  },
  billingZipcode: {
    presence: { allowEmpty: false, message: "is required" },
    // length: {
    //   is: 5,
    //   message: "length is invalid",
    // },
  },
  // agentPhoto: {
  //   presence: { allowEmpty: false, message: 'is required' },
  // },
  // agentLogo: {
  //   presence: { allowEmpty: false, message: 'is required' },
  // },
  // agentLogo2: {
  //   presence: { allowEmpty: false, message: 'is required' },
  // },
  // agentSignature: {
  //   presence: { allowEmpty: false, message: 'is required' },
  // },
  // agentBrokerageLogoURL: {
  //   presence: { allowEmpty: false, message: 'is required' },
  // },
  userStatus: {
    presence: { allowEmpty: false, message: "is required" },
  },
  statusDescription: {
    presence: { allowEmpty: true, message: "is required" },
  },
};
