import palette from "theme/palette";

export default theme => ({
  field: {
    //padding: theme.spacing(3),
    //marginTop: -theme.spacing(3),
    //marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    //marginRight: theme.spacing(2)
  },
  gridMargin: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  textField: {
    width: '100%',
    maxWidth: '100%',
    //marginRight: theme.spacing(3)
  },
  rowActions: {
    marginTop: theme.spacing(1),
    textAlign: 'center'
  },
  marginTop1Unit: {
    marginTop: theme.spacing(1)
  },
  gridDivider: {
    margin: theme.spacing(2)
  },
  imageHolderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  imageHolder: {
    maxWidth: '200px',
    //maxHeight: '500px',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    height: '200px',
    objectFit: 'cover'
  },
  imageHolderDialog: {
    maxWidth: '200px',
    //maxHeight: '500px',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    height: 'auto',
    objectFit: 'cover'
  },
  imageTag: {
    width: '100%',
    height: '200px',
    cursor: 'pointer'
  },
  fieldSection: {
    width: '100%',
    height: '85px'
  },
  fieldSectionContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  fieldSectionError: {
    width: '100%'
  },
  uploadButton: {
    backgroundColor: theme.palette.primary.mediumLight,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  teamMemberTitle: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontSize: '16px'
  },
  fieldError: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
});