import React, { Fragment, Component, createRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button, Typography, Divider, Container, TextField, CircularProgress, Grid, Link, DialogActions } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { propertyUtil } from '../../PropertyUtil';
import Slide from '@material-ui/core/Slide';
import classNames from 'classnames';
import styles from './styles';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { pmaActions } from '_store/_actions';
import { GetStateStorage, IsValidSessionSwitch } from 'common/storage';
import EllipsisLoader from 'components/EllipsisLoader';
import { renderLogicUtil } from '../../RenderLogicUtil';
import { anyFirstNameChangedClosure, MailProBlockedEditingDisabledBar, MailProCloseButton } from './MailProHTMLShortMethods';
import { anyLastNameChangedClosure } from './MailProHTMLShortMethods';
import { agentBarSwitchClosure } from './MailProHTMLShortMethods';
import { getHONamesBaseDataClosure } from './MailProHTMLShortMethods';
import { agentBarSwitchClosureV2 } from './MailProHTMLShortMethods';
import { getHONamesBaseDataClosureV2 } from './MailProHTMLShortMethods';
import { getHONamesOriginalDataClosureV2 } from './MailProHTMLShortMethods';
import { isAnyHONameChangedV2 } from './MailProHTMLShortMethods';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class EditMailingDetailsDialogV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      xOwner1First: "",
      xOwner1Last: "",
      xOwner2First: "",
      xOwner2Last: "",
      xMailAddress1: "",
      xMailAddress2: "",
      xMailCity: "",
      xMailState: "",
      xMailZip: "",
      submitted: false,
      error: "",
      isLoading: false,
      isBlocked: false,
      isASessionSwitch: false,
      editMailingAddress: false
    };
    this.submitButtonRef = createRef();
  }

  handleClickOpen = () => {
    //oneHO,twoHO, fill the state anme
    this.setState(prevState => ({
      ...{ open: true },
      ...this.getOpenData()
    }));
    window.addEventListener("keyup", this.handleEnterClick);
  };

  handleEnterClick = (event) => {
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      // document.getElementById("myBtn").click();
      this.submitButtonRef.current.click();
    }
  }

  /**
   * 
   * @returns We are separating this function because we need this when submitting for Leave As Is for new mailing. We need to set these value first.
   */
  getOpenData = () => {
    let { property, isMailProLocked } = this.props;
    //check for valid session switch
    let isValidSwitchSession = IsValidSessionSwitch();
    //divide the first name and last name
    let { mailingDetails } = this.props;
    let { MailAddress, MailCity, MailState, MailZip } = mailingDetails;
    // let ogHoFirstName = propertyUtil.originalHONameComplete(mailingDetails);
    // let ogHoSecondName = propertyUtil.originalHOSecondNameComplete(mailingDetails);
    return {
      ...{
        isBlocked: isMailProLocked,
        isASessionSwitch: isValidSwitchSession,
        xMailAddress1: propertyUtil.emptyValueCheck(MailAddress),
        xMailCity: propertyUtil.emptyValueCheck(MailCity),
        xMailState: propertyUtil.emptyValueCheck(MailState.slice(0, 2)),
        xMailZip: propertyUtil.emptyValueCheck(MailZip.slice(0, 5))
      },
      ...(getHONamesBaseDataClosureV2()).call(this, property)
    };
  };

  undoHONames = () => {
    this.setState((_, props) => (getHONamesOriginalDataClosureV2()).call(this, props.property));
  };

  handleClose = (isSubmitted = false, newMailingMode = null) => {
    this.setState({
      open: false, ps: "",
      submitted: false,
      error: "",
      xOwner1First: "",
      xOwner1Last: "",
      xOwner2First: "",
      xOwner2Last: "",
      xMailAddress1: "",
      xMailAddress2: "",
      xMailCity: "",
      xMailState: "",
      xMailZip: ""
    });
    window.addEventListener("keyup", this.handleEnterClick);
  };
  /**Old Methods */

  onInputChange = e => {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    //we need to set caret position also because we are converting value to capital value it will misplace caret value
    this.setState({
      [e.target.name]: (e.target.name === 'xMailState' ? e.target.value.toUpperCase() : (['xOwner1First', 'xOwner1Last', 'xOwner2First', 'xOwner2Last'].includes(e.target.name) ? propertyUtil.capitalizeString(e.target.value) : e.target.value)),
      submitted: false,
      error: ""
    }, () => {
      input.setSelectionRange(start, end);
    });
  };

  copyHoData = (hoNameField, hoNameValue) => {
    this.setState({
      [hoNameField]: propertyUtil.emptyValueCheck(hoNameValue)
    });
  };

  copyMailingData = () => {
    const {
      mailingDetails
    } = this.props;
    /**Check for the  ho first nad last name */
    let { MailAddress, MailCity, MailState, MailZip } = mailingDetails;
    this.setState({
      xMailAddress1: propertyUtil.emptyValueCheck(MailAddress),
      xMailCity: propertyUtil.emptyValueCheck(MailCity),
      xMailState: propertyUtil.emptyValueCheck(MailState.slice(0, 2)),
      xMailZip: propertyUtil.emptyValueCheck(MailZip.slice(0, 5))
    });
  };

  handleMailingAddressEdit = async (makeEnable) => {
    this.setState({
      editMailingAddress: makeEnable
    });
  };

  async handleLeaveOnSubmit() {
    /**so here case is in case of new mailing Leave As is we are not opening dialog so there would be no values in  xOwner1First, xOwner1Last, xOwner2First, xOwner2Last. so on submit he will send OwnerFirst, OwnerLast as empty. so we first set these values and then call on submit.  */
    this.setState(this.getOpenData(), () => {
      this.onSubmit('leave_as_it');
    });
  };

  async onSubmit(newMailingMode = null) {
    /**check for block status */
    let { isBlocked } = this.state;
    if (isBlocked) {
      this.setState({
        error: "Mailpro has been locked for this mailing.",
      });
    } else {
      this.setState(
        {
          isLoading: true
        },
        async () => {
          let { isASessionSwitch, xOwner1First, xOwner1Last, xOwner2First, xOwner2Last, xMailAddress1, xMailAddress2, xMailCity, xMailState, xMailZip, editMailingAddress } = this.state;
          setTimeout(async () => {
            const {
              areaID,
              mailerProID,
              changeDetails,
              agentID,
              mailingStatus,
              imbarcode,
              isPropertyMapped,
              numericIMBarcode,
              parcelNumber,
              propertyDetails,
              propertyData,
              mailingDetails,
              property
            } = this.props;
            let { MailAddress, MailCity, MailState, MailZip } = mailingDetails;
            //get the names again from comparison
            //mailing address changes
            let xMailAddress = `${xMailAddress1}${xMailAddress2 ? `, ${xMailAddress2}` : ''}`;
            /**now continue */
            let updatedMailingDetails = Object.assign({}, mailingDetails, {
              MailAddress: (editMailingAddress ? xMailAddress : MailAddress),
              MailCity: (editMailingAddress ? xMailCity : MailCity),
              MailState: (editMailingAddress ? xMailState : MailState),
              MailZip: (editMailingAddress ? xMailZip : MailZip),
            });
            /** build update change details */
            let updatedChangeDetails = Object.assign({}, changeDetails, {
              lgOwnerFirstName: xOwner1First ? xOwner1First : '-',
              lgOwnerLastName: xOwner1Last ? xOwner1Last : '-',
              lgSecondaryOwnerFirstName: xOwner2First ? xOwner2First : '-',
              lgSecondaryOwnerLastName: xOwner2Last ? xOwner2Last : '-',
              //set the new mailing flag to false
              newMailing: false,
              isNewProperty: false,
              lgIsOwnerNameModified: true
            });
            //check and append is there anything changed
            // let anythingChanged = isAnyHONameChangedV2().call(this, { mailingDetails: updatedMailingDetails, changeDetails: updatedChangeDetails });
            // updatedChangeDetails.lgIsOwnerNameModified = anythingChanged;
            /**
             * Start doing new changes for home owner api
             */
            const actualHomeowners = propertyData.filter(
              prop => parseInt(prop.agentID) === 0
            );
            //filters out just the agents
            const agentList = propertyData.filter(
              prop => parseInt(prop.agentID) > 0
            ).length;
            //filters out the homeowners that are NOT blocked (the ones we are sending a pma to)
            let unblockedHO = actualHomeowners.filter(prop => {
              if (!prop.changeDetails) {
                return true;
              }
              if (prop.changeDetails && prop.changeDetails.blocked === null) {
                return true;
              }
              if (!prop.changeDetails.blocked) {
                return true;
              }
              return false;
            }).length;
            let totalUnblocked = agentList + unblockedHO;
            /**get user ID */
            let userData = GetStateStorage();
            const hoData = {
              agentID,
              areaID,
              changeDetails: updatedChangeDetails,
              mailingDetails: updatedMailingDetails,
              imbarcode,
              isPropertyMapped,
              mailerProID,
              mailingStatus,
              numericIMBarcode,
              parcelNumber,
              listgenUserID: userData.userID ? userData.userID : "",
              numOfPMAs: totalUnblocked,
              // isFirstNameModified: isAnyFirstNameChanged,
              // isLastNameModified: isAnyLastNameChanged,
              isAgent: (isASessionSwitch ? false : true)
            };
            //for change details
            //call this api if anything got changed in  names if isAnyFirstNameChanged variable is true
            await this.props.updateHomeownerProp(hoData);
            /** no need to call updateCompleteMailerProDataAsync as we dont have to update entire store just a property */
            setTimeout(async () => {
              await this.props.updateRecentUpdatedMailProId(mailerProID);
            }, 10);

            // setTimeout(() => {
            this.setState(
              {
                isLoading: false,
                submitted: true,
                error: ""
              },
              () => {
                this.props.updateEditedNewMailingData({ ...hoData, newMailingMode, ...{ isPropertyAlreadyEdited: this.props.newMailingPropertyEdited } })
                this.handleClose(true, newMailingMode);
              }
            );
            // }, 1200);
          }, 300);
        }
      );
    }
  }

  render() {
    const { classes, mailingDetails, newMailingsHOMode, newMailingPropertyEdited, property, changeDetails } = this.props;
    const { open, submitted, error, isBlocked, isLoading, xOwner1First, xOwner1Last, xOwner2First, xOwner2Last, xMailAddress1, xMailAddress2, xMailCity, xMailState, xMailZip, editMailingAddress } = this.state;
    let { MailAddress, MailCity, MailState, MailZip, SiteNumber, SiteStreet, SiteCity, SiteState, SiteZip } = mailingDetails;

    //fetch the edited homwowner name
    const { lgOwnerFirstName, lgOwnerLastName, lgSecondaryOwnerFirstName, lgSecondaryOwnerLastName } = changeDetails ? changeDetails : {};
    //fetch original ho names
    let { OwnerName, SecondaryOwnerName, OwnerFirst, OwnerLast, SecondaryOwnerFirst, SecondaryOwnerLast } = mailingDetails;
    return (
      <Fragment>
        <Fragment>
        <div>
              {/* onClick={event => this.handleClickOpen(event)} */}
              <p className="blue-text mailProLinkClass pointer quickMarginTop" onClick={event => this.handleClickOpen(event)}>
                Edit
                <br />
                Mailing Address
              </p>
            </div>
          {/* {newMailingsHOMode ?
            (isLoading ?
              <EllipsisLoader /> :
              <Fragment>
                <div>
                  <p className="blue-text mailProLinkClass pointer quickMarginTop" onClick={event => this.handleClickOpen()}>
                    Edit Homeowner's Name
                  </p>
                </div>
                <div className={classes.marginTop0_5em}>
                  {newMailingsHOMode && !newMailingPropertyEdited ? <div className={classNames(classes.leavAsIsBtn)} onClick={event => this.handleLeaveOnSubmit()}>Leave As Is</div> : ''}
                </div>
              </Fragment>
            ) :
            <div>
              <p className="blue-text mailProLinkClass pointer quickMarginTop" onClick={event => this.handleClickOpen(event)}>
                Edit
                <br />
                Mailing Address
              </p>
            </div>
          } */}
        </Fragment>
        {open ?
          <Dialog
            open={true}
            onClose={event => this.handleClose()}
            scroll={"paper"}
            aria-labelledby="propert-view-dialog"
            aria-describedby="propert-view-dialog-description"
            maxWidth={'lg'}
            TransitionComponent={Transition}
            keepMounted
            fullWidth
          >
            <DialogTitle id="scroll-dialog-title">
              <div className={classes.propertyFactHeader}>
                <div></div>
                <div>
                  <Typography variant="h4" align="center">
                    Edit Homeowner Name and/or Mailing Address
                  </Typography>
                  <Typography variant="body1" align="center">
                    You may change the homeowner(s) names and/or their mailing address below.
                  </Typography>
                  <Typography variant="body1" align="center">
                    Please note: All mailing address fields must be filled in before the UPDATE button will appear.
                  </Typography>
                </div>
                <MailProCloseButton onClick={this.handleClose} />
              </div>
            </DialogTitle>
            {/* Agent bar switching begin */}
            {agentBarSwitchClosureV2(classes, true).call(this)}
            {/* Agent bar switching ended */}
            {/* Ho Dynamic section html begin */}
            {/* Ho Dynamic section html end */}
            <div className="hoFullCommonSection">
              <div className="common100Width">
                <Divider />
              </div>
            </div>
            <DialogContent dividers={true} className={classes.dialogContentPadding}>
              <div>
                <div
                  className={classes.propertyDetailsModal}
                >
                  <MailProBlockedEditingDisabledBar
                  isBlocked={isBlocked}
                  classes={classes}
                  />
                  <Container>
                    <Typography variant="body1" align="right" className={classNames(classes.requiredFieldMessage)}>* Required Fields</Typography>

                    <Grid container className={classNames(classes.grid)}>
                      <Grid item lg="7" md="7" sm="12" xs="12">
                        <Grid container className={classNames(classes.grid)}>
                          <Grid item lg="12" md="12" sm="12" xs="12" className={classNames(classes.hoNameTextSection, classes.hoNameTextSectionTop, classes.mailign2BottomSpace)}>
                            <Typography variant="h5" align="center">
                              Edit Homeowners Names &nbsp;
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container className={classNames(classes.grid)}>
                          <Grid item lg="6" md="6" sm="12" xs="12">
                            <div className={classNames(classes.mailignField, classes.mailign2BottomSpace)}>
                              <Typography variant="body1" className={classes.hoNameTextSection}>
                                <span className={classes.lightBoldText}>Homeowner #1 First Name:</span>&nbsp;&nbsp;&nbsp;<Typography color="primary" variant="body1" component="span">{OwnerFirst && OwnerFirst !== "-" ? OwnerFirst : '---'}</Typography>
                                {/* {ogHoFirstName.oneHO ? ogHoFirstName.oneHO : '---'} */}
                                {/* &nbsp; &nbsp;{ogHoFirstName.oneHO ? <span className="blue-link-copy-underline" onClick={event => this.copyHoData('xOwner1First', (ogHoFirstName.oneHO ? ogHoFirstName.oneHO : ''))}>Autofill</span> : ''} */}
                              </Typography>
                              <TextField
                                //label={<span>Homeowner 1:&nbsp;&nbsp;<span className={classes.lightBoldText}>FIRST NAME</span></span>}
                                label={"Type Edited Homeowner First Name here"}
                                id="outlined-first-ho-first-name-1"
                                className={classes.textField}
                                margin="dense"
                                variant="outlined"
                                name="xOwner1First"
                                onChange={this.onInputChange}
                                value={xOwner1First}
                                autoComplete='off'
                                required={true}
                                inputProps={{
                                  // className: classNames({
                                  //   [classes.nicknameEdited]: (OwnerFirst !== xOwner1First)
                                  // }),
                                  autoComplete: 'off'
                                }}
                                autoFocus={true}
                              />
                              <Typography variant="body2" className={classes.darkRedColor}>{(!xOwner1First) ? 'Homeowner # 1 first name is required' : ''}</Typography>
                            </div>
                          </Grid>
                          <Grid item lg="6" md="6" sm="12" xs="12">
                            <div className={classNames(classes.mailignField, classes.mailign2BottomSpace)}>
                              <Typography variant="body1" className={classes.hoNameTextSection}>
                                <span className={classes.lightBoldText}>Homeowner #1 Last Name:</span>&nbsp;&nbsp;&nbsp;<Typography color="primary" variant="body1" component="span">
                                  {OwnerLast && OwnerLast !== "-" ? OwnerLast : '---'}</Typography>
                                {/* {ogHoSecondName.oneHO ? ogHoSecondName.oneHO : '---'} */}
                                {/* &nbsp; &nbsp;{ogHoSecondName.oneHO ? <span className="blue-link-copy-underline" onClick={event => this.copyHoData('xOwner1Last', (ogHoSecondName.oneHO ? ogHoSecondName.oneHO : ''))}>Autofill</span> : ''} */}
                              </Typography>
                              <TextField
                                //label={<span>Homeowner 1:&nbsp;&nbsp;<span className={classes.lightBoldText}>LAST NAME</span></span>}
                                label={"Type Edited Homeowner Last Name here"}
                                id="outlined-first-ho-second-name-1"
                                className={classes.textField}
                                margin="dense"
                                variant="outlined"
                                name="xOwner1Last"
                                onChange={this.onInputChange}
                                value={xOwner1Last}
                                autoComplete='off'
                                inputProps={{
                                  // className: classNames({
                                  //   [classes.nicknameEdited]: (OwnerLast !== xOwner1Last),
                                  // }),
                                  autoComplete: 'off'
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container className={classNames(classes.grid)}>
                          <Grid item lg="6" md="6" sm="12" xs="12">
                            <div className={classNames(classes.mailignField)}>
                              <Typography variant="body1" className={classes.hoNameTextSection}>
                                <span className={classes.lightBoldText}>Homeowner #2 First Name:</span>&nbsp;&nbsp;&nbsp;<Typography color="primary" variant="body1" component="span">
                                  {SecondaryOwnerFirst && SecondaryOwnerFirst !== "-" ? SecondaryOwnerFirst : '---'}</Typography>
                                {/* {ogHoFirstName.twoHO ? ogHoFirstName.twoHO : '---'} */}
                                {/* &nbsp; &nbsp;{ogHoFirstName.twoHO ? <span className="blue-link-copy-underline" onClick={event => this.copyHoData('xOwner2First', (ogHoFirstName.twoHO ? ogHoFirstName.twoHO : ''))}>Autofill</span> : ''} */}
                              </Typography>
                              <TextField
                                //label={<span>Homeowner 2:&nbsp;&nbsp;<span className={classes.lightBoldText}>FIRST NAME</span></span>}
                                label={"Type Edited Homeowner First Name here"}
                                id="outlined-second-ho-first-name-1"
                                className={classes.textField}
                                margin="dense"
                                variant="outlined"
                                name="xOwner2First"
                                onChange={this.onInputChange}
                                value={xOwner2First}
                                autoComplete='off'
                                inputProps={{
                                  // className: classNames({
                                  //   [classes.nicknameEdited]: (SecondaryOwnerFirst !== xOwner2First),
                                  // }),
                                  autoComplete: 'off'
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item lg="6" md="6" sm="12" xs="12">

                            <div className={classNames(classes.mailignField)}>
                              <Typography variant="body1" className={classes.hoNameTextSection}>
                                <span className={classes.lightBoldText}>Homeowner #2 Last Name:</span>&nbsp;&nbsp;&nbsp;<Typography color="primary" variant="body1" component="span">{SecondaryOwnerLast && SecondaryOwnerLast !== "-" ? SecondaryOwnerLast : '---'}</Typography>
                                {/* {ogHoSecondName.twoHO ? ogHoSecondName.twoHO : '---'} */}
                                {/* &nbsp; &nbsp;{ogHoSecondName.twoHO ? <span className="blue-link-copy-underline" onClick={event => this.copyHoData('xOwner2Last', (ogHoSecondName.twoHO ? ogHoSecondName.twoHO : ''))}>Autofill</span> : ''} */}
                              </Typography>
                              <TextField
                                //label={<span>Homeowner 2:&nbsp;&nbsp;<span className={classes.lightBoldText}>LAST NAME</span></span>}
                                label={"Type Edited Homeowner Last Name here"}
                                id="outlined-second-ho-second-name-2"
                                className={classes.textField}
                                margin="dense"
                                variant="outlined"
                                name="xOwner2Last"
                                onChange={this.onInputChange}
                                value={xOwner2Last}
                                autoComplete='off'
                                inputProps={{
                                  // className: classNames({
                                  //   [classes.nicknameEdited]: (SecondaryOwnerLast !== xOwner2Last)
                                  // }), 
                                  autoComplete: 'off'
                                }}
                              />
                              <Typography variant="body2" className={classes.darkRedColor}>{(!xOwner2First && xOwner2Last) ? 'Homeowner # 2 first name is required to fill homeowner # 2 last name' : ''}</Typography>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider orientation="vertical" flexItem className={classes.cMADivmDivider} />
                      <Grid item lg="4" md="4" sm="12" xs="12">
                        <Grid container className={classNames(classes.grid)}>
                          <Grid item lg="12" md="12" sm="12" xs="12" className={classNames(classes.hoNameTextSection, classes.hoNameTextSectionTop, classes.mailign2BottomSpace)}>
                            <Typography variant="h5" align="center">
                              Edit Mailing Address &nbsp;
                            </Typography>
                          </Grid>
                          {/* <Grid container className={classes.grid}> */}
                          {/* <Grid item lg="12" className={classes.hoNameTextSection}>
                              <Typography variant="h5" align="center">
                                Edit Mailing Address &nbsp;
                  </Typography>
                            </Grid> */}
                          <Grid item lg="12" md="12" sm="12" xs="12" className={classes.hoNameTextSection}>
                            <div className={classes.currentMailingAddressDiv}>
                              <div>
                                <p>Current</p>
                                <p>Mailing</p>
                                <p>Address</p>
                              </div>
                              <Divider orientation="vertical" flexItem className={classes.cMADivmDivider} />
                              <div className={classes.cMADivmMiddle}>
                                {propertyUtil.iHavingAllValueEmpty(
                                  MailAddress,
                                  MailCity,
                                  MailState
                                ) ? "---" :
                                  <Fragment>
                                    <p>{propertyUtil.emptyValueCheck(MailAddress)}</p>
                                    <p>
                                      {propertyUtil.emptyValueCheck(MailCity) !== ""
                                        ? propertyUtil.emptyValueCheck(MailCity) + ", "
                                        : ""}
                                      {propertyUtil.emptyValueCheck(MailState) !== ""
                                        ? propertyUtil.emptyValueCheck(MailState) + ""
                                        : ""}
                                      {' '}
                                      {propertyUtil.emptyValueCheck(MailZip) !== ""
                                        ? propertyUtil.emptyValueCheck(MailZip) + ""
                                        : ""}
                                    </p>
                                  </Fragment>}
                              </div>
                              {!propertyUtil.iHavingAllValueEmpty(
                                MailAddress,
                                MailCity,
                                MailState
                              ) ?
                                <Fragment>
                                  <Divider orientation="vertical" flexItem className={classes.cMADivmDivider} /><div className={classes.cMADivmLast}>
                                    <span
                                      className={classNames("blue-link-copy-underline", (!editMailingAddress ? '' : classes.darkRedColor), classes.lightBoldText)}
                                      onClick={event => { !editMailingAddress ? this.handleMailingAddressEdit(true) : this.handleMailingAddressEdit(false) }}>
                                      {!editMailingAddress ? 'Edit Mailing Address' : 'Cancel'}
                                    </span>
                                  </div>
                                </Fragment>
                                : ''}
                            </div>
                          </Grid>
                          {/* </Grid> */}
                          {editMailingAddress &&
                            <Fragment>
                              <Grid container className={classes.grid}>
                                <Grid item lg="12">
                                  <div className={classNames(classes.mailignField, classes.fieldMarginTop)}>
                                    <TextField
                                      label="Street Address 1"
                                      id="outlined-mailing-address-3-1"
                                      className={classes.textField}
                                      margin="dense"
                                      variant="outlined"
                                      name="xMailAddress1"
                                      onChange={this.onInputChange}
                                      required={true}
                                      value={xMailAddress1}
                                      autoComplete='off'
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container className={classes.grid}>
                                <Grid item lg="12">
                                  <div className={classNames(classes.mailignField, classes.fieldMarginTop)}>
                                    <TextField
                                      label="Street Address 2"
                                      id="outlined-mailing-address-3-2"
                                      className={classes.textField}
                                      margin="dense"
                                      variant="outlined"
                                      name="xMailAddress2"
                                      onChange={this.onInputChange}
                                      required={false}
                                      value={xMailAddress2}
                                      autoComplete='off'
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container className={classes.grid}>
                                <Grid item lg="6">
                                  <div className={classNames(classes.mailignField, classes.fieldMarginTop)}>
                                    <TextField
                                      label="City"
                                      id="outlined-ho-first-name-4"
                                      className={classes.textField}
                                      margin="dense"
                                      variant="outlined"
                                      name="xMailCity"
                                      onChange={this.onInputChange}
                                      required={true}
                                      value={xMailCity}
                                      autoComplete='off'
                                    />
                                  </div>
                                </Grid>
                                <Grid item lg="2">
                                  <div className={classNames(classes.mailignField, classes.fieldMarginTop)}>
                                    <TextField
                                      label="State"
                                      id="outlined-ho-first-name-5"
                                      className={classes.textField}
                                      inputProps={{ maxLength: 2, autoComplete: "off" }}
                                      margin="dense"
                                      variant="outlined"
                                      name="xMailState"
                                      onChange={this.onInputChange}
                                      required={true}
                                      value={xMailState}
                                      autoComplete='off'
                                    //helperText={"State length is 2 characters e.g. CA"}
                                    />
                                  </div>
                                </Grid>
                                <Grid item lg="4">
                                  <div className={classNames(classes.mailignField, classes.fieldMarginTop)}>
                                    <TextField
                                      label="Zip Code"
                                      id="outlined-ho-first-name-6"
                                      className={classes.textField}
                                      inputProps={{ maxLength: 5 }}
                                      margin="dense"
                                      variant="outlined"
                                      name="xMailZip"
                                      onChange={this.onInputChange}
                                      required={true}
                                      value={xMailZip}
                                      autoComplete='off'
                                    //helperText={"Zip Code length is 5 digits e.g. 12345"}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Fragment>
                          }

                          <Typography variant="h6" align="center" component="div" style={{ marginTop: '12px' }}>
                            Site Address: &nbsp;
                            {((propertyUtil.emptyValueCheck(SiteNumber) === '' && propertyUtil.emptyValueCheck(SiteStreet) === '') || (propertyUtil.emptyValueCheck(SiteCity) === '' && propertyUtil.emptyValueCheck(SiteState) === '')) ? (
                              <Fragment>
                                <span>
                                  {/* <p>---</p> */}
                                  <span>No Site Address</span>
                                </span>
                              </Fragment>
                            ) : (
                              <span>
                                <span>
                                  {propertyUtil.emptyValueCheck(SiteNumber)}{' '}
                                  {propertyUtil.emptyValueCheck(SiteStreet)}{' '}
                                  {propertyUtil.emptyValueCheck(SiteCity)}{''}
                                  ,{' '}{propertyUtil.emptyValueCheck(SiteState)}{' '}{propertyUtil.emptyValueCheck(SiteZip)}
                                </span>
                              </span>
                            )}
                          </Typography>

                        </Grid>
                      </Grid>
                    </Grid>

                  </Container>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              {/* {isBlocked ? '' : */}
              <Fragment>
                {isLoading ? (
                  <div className={classNames(classes.fullWidth, classes.cProgressStopBlinking)}>
                    <CircularProgress className={classNames(classes.cProgress)} />
                  </div>
                ) : (
                  <div className={classes.portletFooterWithMessageDiv}>
                    {(submitted || error) ? <div className={classes.fullWidthMessageDiv}>
                      {submitted && (
                        <Typography variant="subtitle2" align="center">
                          <div
                            className={classNames(
                              classes.greenColor,
                              classes.centerAlign,
                              classes.lightBoldText
                            )}
                          >
                            Submitted Successfully!
                          </div>
                        </Typography>
                      )}
                      {error && (
                        <Typography variant="subtitle2" align="center" style={{ margin: '8px' }}>
                          <div
                            className={classNames(
                              classes.darkRedColor,
                              classes.centerAlign,
                              classes.lightBoldText
                            )}
                          >
                            {error}
                          </div>
                        </Typography>
                      )}
                    </div>
                      : ''}
                    <div className={classes.portletFooter}>
                      <div className={classes.footerButtons}>
                        <div className={classes.footerButtonsLeftRight}>
                          {(isAnyHONameChangedV2().call(this, property)) ?
                            <Button
                              variant="outlined"
                              color="default"
                              onClick={event => this.undoHONames(event)}
                            >
                              Undo
                            </Button>
                            : ''}
                          {/* <Button
                            variant="outlined"
                            color="default"
                            onClick={event => this.handleClose()}
                          >
                            Cancel
                          </Button> */}
                        </div>
                        <div className={classes.footerButtonsCenter}>
                          {/* {!isBlocked ? ( */}
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={(editMailingAddress && (xMailAddress1 === "" || xMailCity === "" || xMailState === "" || xMailZip === "" || xMailState.length !== 2 || xMailZip.length !== 5)) || (!xOwner2First && xOwner2Last) || xOwner1First === ''}
                            onClick={event => this.onSubmit(event)}
                            ref={this.submitButtonRef}
                          >
                            Update
                          </Button>
                          {/* ) : ''} */}
                        </div>
                        <div className={classes.footerButtonsLeftRight}></div>
                      </div>
                    </div>
                  </div>)
                }
              </Fragment>
              {/* } */}
            </DialogActions>
          </Dialog>
          : ''}
      </Fragment>
    );
  };
};

function mapStateToProps(state) {
  return {
    currentArea: state.pma.currentArea,
    mlsAreas: state.pma.mlsAreas
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateHomeownerProp: hoData => {
      dispatch(pmaActions.updateHomeownerProp(hoData));
    },
    updateEditedNewMailingData: (data) => dispatch(pmaActions.updateEditedNewMailingData(data)),
    updateRecentUpdatedMailProId: (mailProID) => dispatch(pmaActions.updateRecentUpdatedMailProId(mailProID))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withStyles(styles))(EditMailingDetailsDialogV2));