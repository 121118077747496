import { red } from "@material-ui/core/colors";

export default theme => ({
  homeValuationsMain: {
    boxShadow: '0px 4px 4px rgba(255, 191, 0, 0.25)',
    padding: '8px',
    borderRadius: '4px',
    textAlign: 'center',
    lineHeight: '16px',
    fontSize: '12px',
  },
  homeValuationsViewDetailsButton: {
    //create css to look like anchor tag blue link
    color: '#0000FF',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.01em',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  flashText: {
    //flast text logic
    color: '#0000FF',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.01em',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  flashText: {
    animation: "$mailpro-blinker 2s linear 2000",
    animationDuration: '2s',
    '&:hover': {
      animation: '0',
      WebkitAnimation: 0
    }
  },
  "@keyframes mailpro-blinker": {
    "85%": {
      opacity: 0
    }
  },
  //HomeValuationsRating Handler
  homeValuationsRatingHandlerMain: {
    marginTop: '16px'
  },
  homeValuationsRatingsButton: {
    //undeline
    color: '#0000FF',
    fontSize: '11px',
    lineHeight: '18px',
    letterSpacing: '0.01em',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: '16px'
  },
  homeValuationsRatingHandlerTextTitle: {
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.01em',
    color: '#000000',
    fontWeight: 'bold',
    marginBottom: '8px',
    marginTop: '-6px'
  },
  homeValuationsRatingHandlerBlueLink: {
    color: '#0000FF',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.01em',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: '8px'
  },

  //homeValuationRatingStarsViewMain
  homeValuationRatingStarsViewMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '12px'
  },

  //HomeValuationRating Dialog
  homeValuationDialogPaper: {
    width: '1000px'
  },
  homeValuationRatingDialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '&>span': {
      padding: '8px',
    }
  },
  ratingDialogSubmittedFullName: {
    fontSize: '20px',
    lineHeight: '32px'
  },
  objectiveMk2: {
    fontFamily: "objektiv-mk2",
  },
  ratingListTitle: {
    color: '#4A7396',
    //Objektiv Mk2 · Italic · 11 Pt
    fontFamily: "objektiv-mk2",
    fontStyle: 'italic',
    fontSize: '12.5px',
    lineHeight: '16px',
    letterSpacing: '0.01em',
    marginTop: '16px',
    fontWeight: 800
  },
  ratingDialogHeader: {
    fontSize: '16px',
    fontFamily: "objektiv-mk2",
    lineHeight: "18px",
    letterSpacing: "0.01em",
    fontWeight: 500,
    color: 'white',
    textAlign: 'center'
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 32px'
  },
  ratingDialogSubHeader: {
    fontSize: '14px',
  },
  ratingBox: {
    backgroundColor: '#F2EEE6',
    border: '1px solid #8EA2AD',
    padding: '4px 8px',
    borderRadius: '4px',
  },
  centerFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  providedHomeValuation: {
    fontSize: '13px',
    lineHeight: '18px',
    letterSpacing: '0.01em',
    color: '#000000',
    fontWeight: 'normal',
    fontFamily: "objektiv-mk2",
  },
  ratingCheckboxList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '16px 32px'
  },
  ratingCheckboxListInner: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px'
  },
  ratingCheckboxListInnerText: {
    fontSize: '10px',
    lineHeight: '18px',
    letterSpacing: '0.01em',
    color: '#000000',
    fontWeight: 'normal',
    fontFamily: "objektiv-mk2",
    display: 'flex',
    alignItems: 'center',
    minWidth: '130px'
  },
  ratingCheckboxListInnerStar: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px'
  },
  ratingCheckboxListInnerDate: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0px',
    width: '300px'
  },
  commentsTitle: {
    color: '#4A7396',
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.01em',
    textTransform: 'uppercase',
    fontStyle: 'italic',
    marginTop: '16px'
  },
  underline: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  errorBox: {
    border: `1px solid ${red[500]}`
  }

});
