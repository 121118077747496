import React, { Component } from "react";

class DashboardHeader extends Component {
  render() {
    const { dashBoardMonths } = this.props.MQCityDetails;
    const { useCity } = this.props;
    return (
      <div className="dashboard-header">
        <div className="dashboard-header-content">
          <div className="dashboard-title">
            <p className="dashboard-title-light">How does your</p>
            <p className="dashboard-title-dark">property compare?</p>
          </div>
          <div className="dashboard-subtitle-section">
            <div className="dashboard-subtitle-container">
              <div className="dashboard-subtitle-header">
                <p className="dashboard-subtitle-light">When it's</p>
                <p className="dashboard-subtitle-dark">time to sell ...</p>
              </div>
            </div>
            <div className="dashboard-info">
              <p>
                Below are three price ranges of homes that sold in your
                {useCity ? " city" : " neighborhood"} over the last {Number(dashBoardMonths) > 0 ? dashBoardMonths : 1} months.
                This is a simple, yet effective way to see how your property
                might fit into one of these 3 price categories.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardHeader;
