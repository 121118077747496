// WHAT A COINCIDENCE!
// -The same rules apply to this red alert as the above two alerts.
// -With one exception: The system does not delete this alert after one year. (no need to delete this since there is no 'annual' trigger that would make the previous alert out of date)
// Amit:- Added a new condition. If all mailing related details are empty than no need to show coincidence. Because it'a not gonna make any sense
import React, { Fragment } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import MailingAddressView from "./MailingAddressView";
import EditMailingDetailsDialogV2 from "./EditMailingDetailsDialogV2";
import CustomNoteDialogV2 from "./Notes/PersonalNote/CustomNoteDialogV2";

/**
 * THe funda of showing edit mailing address dialog is
 * 1. if absentee Ho is true then we will show Edit Mailing Address in middle, in first flex
 * 2. if not then we will show in seconds flex
 * 3. for conincedence we arent including Edit Mailing Address dialog in Custom not dialog so it wont come and by point no 2 it will automatically show in td. Also we sending hideEditMailingAddressDialog in coincendence block so the top Mailing address view wont show the edit mailing dialog/option.
 */
const AbsenteeHO = (props) => {
    //console.log(props.mailingDetails)
    let { coincidence, MailAddress, MailCity, MailState, SiteNumber, StreetSuffix, SiteStreet, SiteCity, SiteState } = props.mailingDetails;
    /**abent home owner params */
    const {
        agentID,
        areaID,
        mailerProID,
        mailingStatus,
        changeDetails,
        propertyDetails,
        propertyData,
        mailingDetails,
        agentData,
        property,
        propertySequenceNumber,
        lastPMAPrintDate,
        isMailProLocked
    } = props;

    //moved to render logic util file
    //let bypassMailingCityCheck = (agentData && (agentData.listgenUserID === "100458" || agentData.listgenUserID === "100526") ? true : false);

    // const nameCorrector = inputString => {
    //     if (
    //         !inputString ||
    //         inputString === "" ||
    //         inputString === "0" ||
    //         inputString === "EmptyValue" ||
    //         inputString === "-"
    //     ) {
    //         return "";
    //     }
    //     return inputString;
    // };

    // const isHomeownerAbsentie = () => {
    //     /**clean the data */
    //     MailAddress = propertyUtil.emptyValueCheck(MailAddress);
    //     MailCity = propertyUtil.emptyValueCheck(MailCity);
    //     MailState = propertyUtil.emptyValueCheck(MailState);
    //     SiteNumber = propertyUtil.emptyValueCheck(SiteNumber);
    //     SiteStreet = propertyUtil.emptyValueCheck(SiteStreet);
    //     SiteCity = propertyUtil.emptyValueCheck(SiteCity);
    //     SiteState = propertyUtil.emptyValueCheck(SiteState);
    //     SiteStreet = propertyUtil.emptyValueCheck(SiteStreet);
    //     /**
    //      * If MailAddress, SiteNumber or SiteStreet is empty then don't show home owner absent text
    //      */
    //     if (!MailAddress || !SiteStreet) {
    //         return false;
    //     }//bypassMailingCityCheck
    //     let xMailAddress = MailAddress + (!bypassMailingCityCheck ? " " + MailCity : '') + " " + MailState;//
    //     let siteAddress =
    //         SiteNumber + " " + SiteStreet + (!bypassMailingCityCheck ? " " + SiteCity : '') + " " + SiteState;//

    //     if ((xMailAddress.toLowerCase() === siteAddress.toLowerCase()) || (MailCity === SiteCity && MailState === SiteState && MailAddress.includes(SiteNumber + " " + SiteStreet))) {
    //         return false;
    //         // not a absentie HO
    //     } else {
    //         return true;
    //         //not following any of condition absentee Ho
    //     }
    // }
    /** */
    return (
        <Fragment>
            <Fragment>
                {propertyUtil.iHavingAllValueEmpty(
                    MailAddress,
                    MailCity,
                    MailState
                ) ? (
                    <div className="global-ps-body-container tableTdFlex">
                        <div className="tableTdFlex-First textCursor">
                            ---
                        </div>
                        <div className="tableTdFlex-Second">
                            <EditMailingDetailsDialogV2
                                mailingDetails={mailingDetails}
                                areaID={areaID}
                                agentID={agentID}
                                mailerProID={mailerProID}
                                mailingStatus={mailingStatus}
                                changeDetails={changeDetails}
                                propertyData={propertyData}
                                propertyDetails={propertyDetails}
                                newMailingsMode={false}
                                newMailingsModeID={''}
                                closeFn={undefined}
                                propertySequenceNumber={propertySequenceNumber}
                                property={property}
                                isMailProLocked={isMailProLocked}
                            />
                        </div>
                    </div>
                ) : (
                    <Fragment>
                        {/* Add logic for owner absentie */}
                        {/* Is having absentee HO then build flex structure in custom note maker */}
                        {property.HOQuickFilterData.isAbsentee ? (
                            <CustomNoteDialogV2
                                cameFrom="AbsentHOPS"
                                agentID={agentID}
                                areaID={areaID}
                                mailerProID={mailerProID}
                                mailingStatus={mailingStatus}
                                changeDetails={changeDetails}
                                propertyDetails={propertyDetails}
                                propertyData={propertyData}
                                mailingDetails={mailingDetails}
                                agentData={agentData}
                                propertySequenceNumber={propertySequenceNumber}
                                property={property}
                                lastPMAPrintDate={lastPMAPrintDate}
                                isMailProLocked={isMailProLocked}
                            />
                        ) : (
                            //   Not having absentee HO make flex layout here itself
                            <div className="global-ps-body-container tableTdFlex">
                                <div className="tableTdFlex-First textCursor">
                                    <div>
                                        {/* Call the Mailing Address View here */}
                                        <MailingAddressView
                                            mailingDetails={mailingDetails}
                                            areaID={areaID}
                                            agentID={agentID}
                                            mailerProID={mailerProID}
                                            mailingStatus={mailingStatus}
                                            changeDetails={changeDetails}
                                            propertyDetails={propertyDetails}
                                            propertyData={propertyData}
                                            agentData={agentData}
                                            hideEditMailingAddressDialog={true}
                                            property={property}
                                            propertySequenceNumber={propertySequenceNumber}
                                            lastPMAPrintDate={lastPMAPrintDate}
                                            isMailProLocked={isMailProLocked}
                                        />
                                    </div>
                                    {coincidence ?
                                        <Fragment>
                                            {/* Check for Coincidence here because we aren't calling absentee HO  */}
                                            <br />
                                            <CustomNoteDialogV2
                                                cameFrom="Coincidence"
                                                agentID={agentID}
                                                areaID={areaID}
                                                mailerProID={mailerProID}
                                                mailingStatus={mailingStatus}
                                                changeDetails={changeDetails}
                                                propertyDetails={propertyDetails}
                                                propertyData={propertyData}
                                                mailingDetails={mailingDetails}
                                                agentData={agentData}
                                                propertySequenceNumber={propertySequenceNumber}
                                                property={property}
                                                lastPMAPrintDate={lastPMAPrintDate}
                                                isMailProLocked={isMailProLocked}
                                            />
                                        </Fragment>
                                        : ''}
                                </div>
                                <div className="tableTdFlex-Second">
                                    {/* Nothing to shoe because there's no absentee HO */}
                                    <EditMailingDetailsDialogV2
                                        mailingDetails={mailingDetails}
                                        areaID={areaID}
                                        agentID={agentID}
                                        mailerProID={mailerProID}
                                        mailingStatus={mailingStatus}
                                        changeDetails={changeDetails}
                                        propertyData={propertyData}
                                        propertyDetails={propertyDetails}
                                        newMailingsMode={false}
                                        newMailingsModeID={''}
                                        closeFn={undefined}
                                        property={property}
                                        propertySequenceNumber={propertySequenceNumber}
                                        isMailProLocked={isMailProLocked}
                                    />
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
            </Fragment>
        </Fragment>
    );
}

export default AbsenteeHO;
