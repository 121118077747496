import { rgbToHex } from "@material-ui/core";
import {
  blueGrey,
  green,
  grey,
  pink,
  red,
  teal,
  agentRoi
} from "@material-ui/core/colors";
import palette from "theme/palette";

export default theme => ({
  root: {
    padding: theme.spacing(3),
    height: "fit-content"
  },
  deletePropertyModal: {
    border: "1px solid red"
  }
});
