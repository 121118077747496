import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Typography,
    Card,
    CardActions,
    CardContent
}
    from '@material-ui/core';
import styles from './styles';
import classNames from 'classnames';

const CardLayout = (props) => {
    const { classes, cardData } = props;

    return (
        <div className={classes.root}>
            <div className={classes.card}>
                <div className={classNames(classes.cardFront, classes.cardPart, 'front_card')}>
                    <div className={classes.cardFrontTop}>
                        <img className={classNames(classes.cardFrontSquare, classes.cardSquare)} src="/images/little_square.png" />
                        <Typography variant="h3" className={classNames(classes.cardFrontLogo, classes.cardLogo)}>{cardData.card.brand}</Typography>
                    </div>
                    <div className={classes.cardFrontBottom}>
                        <Typography variant="subtitle2" className={classes.cardNumber}>**** **** **** {cardData.card.last4}</Typography>
                        <div className={classes.cardSpace75}>
                            <Typography variant="caption" className={classes.cardLabel}>Card Holder/Email</Typography>
                            <Typography variant="subtitle1" className={classes.cardInfo}>{cardData.card.name}</Typography>
                        </div>
                        <div className={classes.cardSpace25}>
                            <Typography variant="caption" className={classes.cardLabel}>Expires</Typography>
                            <Typography variant="subtitle1" className={classes.cardInfo}>{cardData.card.exp_month}/{cardData.card.exp_year}</Typography>
                        </div>
                    </div>
                </div>
                <div className={classNames(classes.cardBack, classes.cardPart, 'back_card')}>
                    <div className={classes.cardBlackLine}></div>
                    <div className={classes.cardBackContent}>
                        <div className={classes.cardSecret}>
                            <span className={classes.cardSecretFirst}></span>
                            <div  className={classes.cardSecretLast}>
                            <Typography variant="body2">
                            XXX
                            </Typography>
                            </div>
                        </div>
                        <div>
                            <img className={classNames(classes.cardBackSquare, classes.cardSquare)} src="/images/little_square.png" />
                            <Typography variant="h3" className={classNames(classes.cardBackLogo, classes.cardLogo)}>VISA</Typography>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}


CardLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    cardData: PropTypes.object.isRequired
};

CardLayout.defaultProps = {
    cardData: {}
};

export default withStyles(styles)(CardLayout);
