// Palette
import palette from '../palette';

export default {
  text: {
    textTransform: 'capitalize',
  },
  outlined: {
    textTransform: 'capitalize',
  },
  contained: {
    backgroundColor: palette.common.white,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: palette.common.neutral
    }
  }
};
