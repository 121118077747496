import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { GetStateStorage } from 'common/storage';
import moment from 'moment';
import { propertyUtil } from './PropertyUtil';
import { renderLogicUtil } from './RenderLogicUtil';

const getActualHomeowners = (propertyData) => {
  return propertyData.filter(
    prop => parseInt(prop.agentID) === 0
  );
};

const getAgentList = (propertyData) => {
  return propertyData.filter(prop => parseInt(prop.agentID) > 0);
};

const lastSaleSorting = (propertyData, lastPMAPrintDate, nextPMAPrintDate, asc) => {
  const actualHomeowners = getActualHomeowners(propertyData);
  const agentList = getAgentList(propertyData);
  //priority is action items, last sale and null data
  let actionItemsData = [];
  let lastSaleData = [];
  let nullData = [];
  //divide the data
  actualHomeowners.map(function (xA) {
    let { latestSaleDate } = xA;
    /**pass into value corrector */
    let lastSaleDate = propertyUtil.valueCorrector(latestSaleDate);
    const { listingStatus } = xA.propertyDetails ? xA.propertyDetails : {};
    const { blocked } = xA.changeDetails ? xA.changeDetails : {};
    const { mailingDetails } = xA;
    const havePrice = (mailingDetails && mailingDetails.Price && parseInt(mailingDetails.Price) !== 0) ? true : false;//last sale property record logic
    /**check for action item logic */
    let havingActionItem = haveAnyActionItems(lastSaleDate, lastPMAPrintDate, nextPMAPrintDate, listingStatus, blocked);
    /**if it's blocked and last sale is non null then assign to last sale data */
    if (havingActionItem) {
      actionItemsData.push(xA);
    } else if (lastSaleDate || (lastSaleDate && havePrice)) { //isPropertyMapped not required anymore
      //we only show date when either property is mapped or have mailing details price.
      lastSaleData.push(xA);
    } else {
      nullData.push(xA);
    }
  });
  //no need to touch nullData. sort the last sale data only
  let actionItemsSorted = actionItemsData.sort(dateSortLogic);
  let lastSaleSorted = lastSaleData.sort(dateSortLogic);
  let newSorted = [];
  if (asc) {
    newSorted = [...actionItemsSorted, ...lastSaleSorted, ...nullData, ...agentList];
  } else {
    newSorted = [...actionItemsSorted.reverse(), ...lastSaleSorted.reverse(), ...nullData, ...agentList];
  }

  return newSorted;
};

const haveAnyActionItems = (lastSaleDate, lastPMAPrintDate, nextPMAPrintDate, listingStatus, blocked) => {
  //isPropertyMapped not required any more
  /**change the date first */
  let xLastSaleDate = moment(new Date(lastSaleDate)).format('YYYY-MM-DD');
  let isSoldDateBwLastPrintandToday = propertyUtil.checkIsSoldDateBwLastPrintMinus3MonthsandToday(
    xLastSaleDate,
    lastPMAPrintDate
  );
  let showCongrats = propertyUtil.checkCongratsLogic(xLastSaleDate, lastPMAPrintDate);
  let showAnniversary = propertyUtil.checkAnniversaryLogic(
    xLastSaleDate,
    lastPMAPrintDate,
    nextPMAPrintDate
  );
  /**if blocked means no action item */
  if (blocked) {
    return false;
  }
  if (showCongrats || showAnniversary) {
    return true;
  }
  if ((listingStatus == 'expired' || listingStatus == 'Canceled') && isSoldDateBwLastPrintandToday) {
    return true;
  }
  //nothing matched 
  return false;
};

const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

const isDateNaN = (d) => {
  return isNaN(new Date(d).getTime()) ? true : false;
};

const dateSortLogic = (a, b) => {
  a = (a && a.latestSaleDate)
    ? new Date(a.latestSaleDate)
    : (a.mailingDetails && a.mailingDetails.Docdate)
      ? new Date(a.mailingDetails.Docdate)
      : null;
  b =
    (b && b.latestSaleDate)
      ? new Date(b.latestSaleDate)
      : (b.mailingDetails && b.mailingDetails.Docdate)
        ? new Date(b.mailingDetails.Docdate)
        : null;
  /**check for isNaN */
  a = isValidDate(a) ? a : null;
  b = isValidDate(b) ? b : null;

  //return a > b || b === null ? -1 : a < b || a === null ? 1 : 0;
  return (a > b || b === null) ? 1 : (a < b || a === null) ? -1 : 0;
}

const filterByTagsandHomeownerName = (propertyData, agentData, asc) => {
  //here we will first sort by tags and then for props who dont have tags we will sort by agent name
  const actualHomeowners = getActualHomeowners(propertyData);
  const agentList = getAgentList(propertyData);
  const hoMaps = {
    tagHOs: [],
    nonTagHOs: []
  }
  actualHomeowners.map((ho) => {
    if(renderLogicUtil.getPropertyTotalTagCount(ho) > 0){
      hoMaps.tagHOs.push(ho)
    } else {
      hoMaps.nonTagHOs.push(ho)
    }
  });
  const tagsSortedProps = tagsSorting(hoMaps.tagHOs, agentData, asc)
  //we always want in asc order only for nonTagsSortedProps
  const nonTagsSortedProps = hoOwnerFirstSorting(hoMaps.nonTagHOs, agentData, true)
  return [...tagsSortedProps, ...nonTagsSortedProps, ...agentList];
}



const tagsSorting = (propertyData, agentData, asc) => {
  /**get actual HO */
  const actualHomeowners = getActualHomeowners(propertyData);
  const agentList = getAgentList(propertyData);
  /**sort by note count */
  let sorted;
  /**write sort logic here */
  sorted = actualHomeowners.sort((a, b) => {
    /**get first tags and custom tags length + get auto tag length also */
    a = renderLogicUtil.getPropertyTotalTagCount(a, agentData);
    b = renderLogicUtil.getPropertyTotalTagCount(b, agentData);
    /**sort the data now */
    if (a > b) {
      return 1;
    } else if (a == b) {
      return 0;
    } else {
      return -1;
    }
  });
  if (asc) {
    return [...sorted, ...agentList];
  } else {

    return [...sorted.reverse(), ...agentList];
  }
};

const agentNameSorting = (propertyData, key, asc) => {
  let nonNullData = [];
  let nullData = [];
  let xAgentList = [];
  let xNonNullData = [];
  let xNullData = [];
  let sorted = [];
  let xSorted = [];
  /**get actual HO */
  const actualHomeowners = getActualHomeowners(propertyData);
  const agentList = getAgentList(propertyData);
  xAgentList = [...agentList];
  /**write sort logic here */
  /**divided data according to values. we dont have to sort null data BTW */
  actualHomeowners.map((a) => {
    let xA = (
      (a && a.isPropertyMapped && a.propertyDetails && a.propertyDetails[key] ? propertyUtil.splitandGetFirstName(a.propertyDetails[key]) : null));
    /**wcheck it's null or empty value */
    xA = ((xA === '' || xA === null) ? null : xA);
    if (xA !== null) {
      nonNullData.push(a);
    } else {
      nullData.push(a);
    }
  });
  /**dereference the array */
  xNonNullData = [...nonNullData];
  xNullData = [...nullData];
  sorted = nonNullData.sort((a, b) => {
    //a.isPropertyMapped
    /**get listing agent full name and split it. */
    a = propertyUtil.splitandGetFirstName(a.propertyDetails[key]);
    b = propertyUtil.splitandGetFirstName(b.propertyDetails[key]);
    /**sort the data now */
    if (a > b) {
      return 1;
    } else if (a === b) {
      return 0;
    } else {
      return -1;
    }
  });
  xSorted = [...sorted];
  xAgentList = [...agentList];
  if (asc) {
    return [...xSorted, ...xNullData, ...xAgentList];
  } else {
    return [...xSorted.reverse(), ...xNullData, ...xAgentList];
  }
};

const getSiteAddressFromMailingDetails = (a) => {
  /**put special condition for site address and site street */
  let siteNumber = ((a && a.mailingDetails && a.mailingDetails.SiteNumber) ? propertyUtil.valueCorrector(a.mailingDetails.SiteNumber) : '');
  let siteStreet = ((a && a.mailingDetails && a.mailingDetails.SiteStreet) ? '' + propertyUtil.valueCorrector(a.mailingDetails.SiteStreet) : '');

  if ((!siteNumber && !siteStreet)) {
    return null;
  }
  /**now do normal one */
  return ((a && a.mailingDetails && a.mailingDetails.SiteNumber) ? propertyUtil.valueCorrector(a.mailingDetails.SiteNumber) : '')
    + ((a && a.mailingDetails && a.mailingDetails.SiteStreet) ? '' + propertyUtil.valueCorrector(a.mailingDetails.SiteStreet) : '')
    + ((a && a.mailingDetails && a.mailingDetails.SiteCity) ? '' + propertyUtil.valueCorrector(a.mailingDetails.SiteCity) : '')
    + ((a && a.mailingDetails && a.mailingDetails.SiteState) ? '' + propertyUtil.valueCorrector(a.mailingDetails.SiteState) : '');
};

const getMailingAddressFromMailingDetails = (a) => {
  return ((a && a.mailingDetails && a.mailingDetails.MailAddress) ? propertyUtil.valueCorrector(a.mailingDetails.MailAddress) : '')
    + ((a && a.mailingDetails && a.mailingDetails.MailCity) ? '' + propertyUtil.valueCorrector(a.mailingDetails.MailCity) : '')
    + ((a && a.mailingDetails && a.mailingDetails.MailState) ? '' + propertyUtil.valueCorrector(a.mailingDetails.MailState) : '');
};

const mailingDetailsSorting = (propertyData, key, asc) => {
  /**get actual HO */
  const actualHomeowners = getActualHomeowners(propertyData);
  const agentList = getAgentList(propertyData);
  /**sort by note count */
  let sorted = [];
  let nullData = [];
  let nonNullData = [];
  /**write sort logic here */
  let xFn = (key === "SiteStreet" ? getSiteAddressFromMailingDetails : (key === "MailAddress" ? getMailingAddressFromMailingDetails : (() => { })));
  actualHomeowners.map((item) => {
    /**build the string a1 */
    let a = xFn(item);
    if (a) {
      nonNullData.push(item);
    } else {
      nullData.push(item);
    }
  });
  /**process for non null data */
  sorted = nonNullData.sort((a, b) => {
    /**build the string a1 */
    a = xFn(a);
    /**build the string b */
    b = xFn(b);
    /**sort the data now */
    if (a > b) {
      return 1;
    } else if (a == b) {
      return 0;
    } else {
      return -1;
    }
  });
  if (asc) {
    return [...sorted, ...nullData, ...agentList];
  } else {
    return [...sorted.reverse(), ...nullData, ...agentList];
  }
};

const propertyTypeSorting = (propertyData, key, asc) => {
  /**get actual HO */
  const actualHomeowners = getActualHomeowners(propertyData);
  const agentList = getAgentList(propertyData);
  /**sort by note count */
  let sorted = [];
  let nullData = [];
  let nonNullData = [];
  /**write sort logic here */
  actualHomeowners.map((item) => {
    /**build the string a1 */
    if (item.mailingDetails) {
      nonNullData.push(item);
    } else {
      nullData.push(item);
    }
  });
  /**process for non null data */
  sorted = nonNullData.sort((a, b) => {
    const isAADU = (a || {}).isPropertyADUAttached === true;
    const isBADU = (b || {}).isPropertyADUAttached === true;
    /**build the string a1 */
    a = (a.mailingDetails.ExpandedPropertyType || '') + (isAADU ? 1 : 0);
    /**build the string b */
    b = (b.mailingDetails.ExpandedPropertyType || '') + (isBADU ? 1 : 0);
    /**sort the data now */
    if (a > b) {
      return 1;
    } else if (a == b) {
      return 0;
    } else {
      return -1;
    }
  });
  if (asc) {
    return [...sorted, ...nullData, ...agentList];
  } else {
    return [...sorted.reverse(), ...nullData, ...agentList];
  }
};

const getLatestPSOorNoteDate = (changeDetails) => {
  let {
    textPSDate,
    personalPSDate,
  } = changeDetails ? changeDetails : {};
  //textPSDate = textPSDate ? new Date(textPSDate).toString() : textPSDate;
  //personalPSDate = personalPSDate ? new Date(personalPSDate).toString() : personalPSDate;
  if(textPSDate || personalPSDate){
    if(textPSDate && (!personalPSDate || personalPSDate === null)) return textPSDate;
    if(personalPSDate && (!textPSDate || textPSDate === null)) return personalPSDate;
    return (textPSDate > personalPSDate ? textPSDate : personalPSDate);
  } else {
    const {date, psDate} = getLastNoteByType(changeDetails)
    return ( date > psDate ? date : psDate);
  }
  
}

const getLastNoteByType = (changeDetails) => {
  //check this method
  //let isHavingNote = false;
  let lastNote = null;
  const { personalPSHistory } = changeDetails
      ? changeDetails
      : {};
  let newNotes = [];
  (personalPSHistory ? personalPSHistory : []).map(item => {
    if (item && item.type) {
      newNotes.push(item);
    }
  });
  lastNote = Array.isArray(newNotes) && newNotes.length > 0 ? newNotes : null;
  return lastNote ? lastNote[lastNote.length - 1] : {};
};

const personalPSSorting = (propertyData, key, asc) => {
  /**get actual HO */
  const actualHomeowners = getActualHomeowners(propertyData);
  const agentList = getAgentList(propertyData);
  /**write sort logic here */
  let sorted = actualHomeowners.sort((xA, xB) => {
    /**get ps1 length */
    let a = getLatestPSOorNoteDate(xA.changeDetails)
    let b = getLatestPSOorNoteDate(xB.changeDetails)
    /**sort the data now */
    if (!a && b) return -1;
    else if (a && !b) return 1;
    else if (a === b) return 0;
    else return (a > b) ? 1 : (b > a ? -1 : 0);
  });
  if (asc) {
    return [...sorted, ...agentList];
  } else {
    return [...sorted.reverse(), ...agentList];
  }
};

const hoOwnerFirstSorting = (propertyData, key, asc) => {
  /**get actual HO */
  const actualHomeowners = getActualHomeowners(propertyData);
  const agentList = getAgentList(propertyData);
  let nonNullData = [];
  let nullData = [];
  /**filter the data first */
  actualHomeowners.map((item) => {
    if (item && item.mailingDetails && item.mailingDetails.OwnerFirst && propertyUtil.valueCorrector(item.mailingDetails.OwnerFirst)) {
      nonNullData.push(item);
    } else {
      nullData.push(item);
    }
  });
  /**sort the non null data now */
  let sorted = nonNullData.sort((a, b) => {
    a = a && a.mailingDetails && a.mailingDetails.OwnerFirst && a.mailingDetails.OwnerFirst.toLowerCase();
    b = b && b.mailingDetails && b.mailingDetails.OwnerFirst && b.mailingDetails.OwnerFirst.toLowerCase();
    if (a < b)
      return -1;
    if (a > b) return 1;
    return 0; //default return value (no sorting)
  });
  /**make the order */
  if (asc) {
    return [...sorted, ...nullData, ...agentList];
  } else {
    return [...sorted.reverse(), ...nullData, ...agentList];
  }
};


const hoValuationSorting = (propertyData, key, asc) => {
  //write  a javascript code to sort in this order where in actualHomeowners 
  //isHomeValuationRequested should be true filter by that first and then
  //key isHomeValuationGivenByAgent is false 
  // if isHomeValuationGivenByAgent true check for key likelyhoodOfListing in actualHomeowners that  value can be -1,0,1,2,3,4,5 so order in -1, 5, 4, 3, 2, 1, 1. actualHomeowners is an array of objects
  const actualHomeowners = [...getActualHomeowners(propertyData)];
  const agentList = [...getAgentList(propertyData)];

  const nonRequestedHomeowners = [];
  const requestedHomeowners = [];

  actualHomeowners.map((homeowner) => {
    if (homeowner.isRequestedForValuation) {
      requestedHomeowners.push(homeowner);
    } else {
      nonRequestedHomeowners.push(homeowner);
    }
  });

  requestedHomeowners.sort((a, b) => {
    const aIsAgentGiven = a.isHomeValuationGivenByAgent === true;
    const bIsAgentGiven = b.isHomeValuationGivenByAgent === true;
    const aIsNull = a.isHomeValuationGivenByAgent === null;
    const bIsNull = b.isHomeValuationGivenByAgent === null;
  
    if (aIsNull && bIsNull) {
      return 0; // both are null, no change in order
    } else if (aIsNull) {
      return 1; // a is null, it comes after b
    } else if (bIsNull) {
      return -1; // b is null, it comes before a
    } else if (!aIsAgentGiven && bIsAgentGiven) {
      return 1; // a comes after b
    } else if (aIsAgentGiven && !bIsAgentGiven) {
      return -1; // a comes before b
    } else if (aIsAgentGiven && bIsAgentGiven) {
      const order = [-1, 5, 4, 3, 2, 1, 0];
      const aLikelyhood = a.likelyhoodOfListing !== null ? a.likelyhoodOfListing : -1; // Default to -1 if null
      const bLikelyhood = b.likelyhoodOfListing !== null ? b.likelyhoodOfListing : -1; // Default to -1 if null
      return order.indexOf(bLikelyhood) - order.indexOf(aLikelyhood);
    }
    return 0; // no change in order
  });

  //build a logic
  if (asc) {
    return [...requestedHomeowners, ...nonRequestedHomeowners, ...agentList];
  } else {
    return [...requestedHomeowners.reverse(), ...nonRequestedHomeowners, ...agentList];
  }
}

export const tableSortUtil = {
  lastSaleSorting,
  tagsSorting,
  filterByTagsandHomeownerName,
  agentNameSorting,
  mailingDetailsSorting,
  propertyTypeSorting,
  personalPSSorting,
  hoOwnerFirstSorting,
  hoValuationSorting
};