import React, { createContext, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import styles from "./styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import AnniversaryAutomatorDialogHeader from "./AnniversaryAutomatorDialogHeader";
import AnniversaryAutomatorDialogHeaderExtra from "./AnniversaryAutomatorDialogHeaderExtra";
import AnniversaryAutomatorNoteandPSView from "./AnniversaryAutomatorNoteandPSView";
import { renderLogicUtil } from "views/Pma/RenderLogicUtil";
import { withSnackbar } from "notistack";
import { DialogContent, Slide } from "@material-ui/core";
import AnniversaryAutomatorDialogFooter from "./AnniversaryAutomatorDialogFooter";
import classNames from "classnames";
import { pmaActions } from "_store/_actions";
import { noteUtils } from "../Common/NoteUtils";
import { showErrorNotification, showInfoNotification, showSuccessNotification } from "common/helpers";
import { propertyUtil } from "views/Pma/PropertyUtil";
import { getAnniversaryNoteForHomeowner, skipAnniversaryNotesForMailProIDs } from "../Common/NoteServices";
import moment from "moment";
import { MailProErrorFormatter } from "../../UI/MailProErrorFormatter";
import { NotesCameFrom } from "../Common/NotesConfig";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AnniversaryAutomatorContext = createContext();

function AnniversaryAutomatorDialog(props) {
  const initialState = {
    open: props.defaultOpen || false,
    currentHOIndex: 0,
    currentHOAnniversaryNote: "",
    isLoading: false,
    isLoadingSystemDefaultNoteData: false,
    isSubmitted: false,
    localNoteCopy: "",
    localPSCopy: "",
    errorMessage: [],
    isAnyLastHomeownerNoteExist: false,
    isNotePSOverridden: false,
  };

  const [state, dispatch] = React.useReducer(anniversaryReducer, Object.assign({}, initialState));
  const prevStateHOIndex = useRef(initialState.currentHOIndex);

  const {
    classes,
    agentData,
    hoFilter,
    propertyData,
    cameFrom,
    isMailProLocked
  } = props;

  //TODO Here when we will open anniversary dialog from navbar filter box then we are not going to unmount it so need to reset values manually

  //step 1. sort by MailPro filters, sort by came From, sort by anniversary count
  const anniversaryHomeownersWithoutSort = noteUtils.sortPropertyByPersonalNoteType(
    (propertyData || []).filter(
      (prop) =>
        prop.HOQuickFilterData.isAnniversary &&
        renderLogicUtil.checkPropertyStatuswrtHOFilterStatus(prop, hoFilter)
    )
  );
  const anniversaryHomeowners = noteUtils.sortPropertyByAnniversaryCount(anniversaryHomeownersWithoutSort)
  const anniversaryHomeownersCount = anniversaryHomeowners.length;
  const currentHOProperty = anniversaryHomeowners[state.currentHOIndex];

  const anniversarySkippableMailProIds=  noteUtils.getMailProIDsWhereAnniversaryNoteCanbeSkipped(anniversaryHomeowners, cameFrom);

  const isBlocked = isMailProLocked;
  const currentArea = propertyUtil.getCurrentArea(props.mlsAreas, props.currentArea);

  const handleClickOpen = () => {
    dispatch({ type: "open", payload: true });
  };

  useEffect(() => {
    //state.open && isAnyLastHomeownerHasNote(); //dont delete this line
    if(anniversaryHomeownersCount > 1){
      // we need to open where we need to add anniversary note or left last time
      const propertySequenceNumber = noteUtils.fetchPropertySequenceNumberWhereNeedtoAddNote(anniversaryHomeowners, cameFrom);
      dispatch({ type: "currentHOIndex", payload: propertySequenceNumber });
    }
  }, [anniversaryHomeownersCount, state.open])

  useEffect(() => {
    //in case of reverse we will not load default note automatically
    state.currentHOIndex >= prevStateHOIndex.current &&  state.open && validateAndFetchSystemNote();
    prevStateHOIndex.current = state.currentHOIndex;
    handleErrors([]);
  }, [state.currentHOIndex, state.open])

  const handleClose = () => {
    dispatch({ type: "open", payload: false });
    props.explicitEnableNewMailProInitFn(false);
    props.disableNewMailingForCurrentSessionFn(true);
    props.setNewMailProInitDialogModeFn(null);
    resetOnClose();
  };

  const resetOnClose = () => {
    prevStateHOIndex.current = 0;
    dispatch({type: 'reset', payload: null});
  }

  function anniversaryReducer(state, action) {
    switch (action.type) {
      case "update_note_ps":
        return {
          ...state,
          ...{
            localNoteCopy: action.payload.localNoteCopy,
            localPSCopy: action.payload.localPSCopy,
          },
        };

        case "update_note":
        return {
          ...state,
          ...{
            localNoteCopy: action.payload.localNoteCopy
          },
        };

        case "update_overriding_status":
        return {
          ...state,
          ...{
            isNotePSOverridden: action.payload
          },
        };

        case "reset":
        return {...initialState};

      default:
        return { ...state, ...{ [action.type]: action.payload } };
    }
  }

  const handleLoading = (isLoading) => {
    dispatch({ type: "isLoading", payload: isLoading });
  };

  const handleSystemNoteLoading = (isLoadingSystemDefaultNoteData) => {
    dispatch({ type: "isLoadingSystemDefaultNoteData", payload: isLoadingSystemDefaultNoteData });
  };

  const handleErrors = (errors) => {
    dispatch({ type: "errorMessage", payload: errors });
  }

  const validateandSetIsNoteOverridable = () => {
    const isOverrideNotePS = noteUtils.shouldOveridePersonalNote(
      currentHOProperty,
      cameFrom
    );
    dispatch({
      type: "update_overriding_status",
      payload: isOverrideNotePS,
    });
  }

  const updateLocalNotePS = (htmlNote, personalPS) => {
    dispatch({
      type: "update_note_ps",
      payload: { localNoteCopy: htmlNote, localPSCopy: personalPS },
    });
    handleErrors([]);
    validateandSetIsNoteOverridable();
  };
  

  // const isAnyLastHomeownerHasNote = () => {
  //   if(anniversaryHomeownersCount > 1){
  //     const {lastNoteHTML} =  noteUtils.getLastWrittenNotesandPS(anniversaryHomeowners, cameFrom);
  //     dispatch({
  //       type: "isAnyLastHomeownerNoteExist",
  //       payload: (lastNoteHTML != ""),
  //     });
  //   }
  // };

  const getLastHomeownerNotePS = () => {
    if(anniversaryHomeownersCount > 1){
      const {lastNoteHTML, lastNotePS} =  noteUtils.getLastWrittenNotesandPS(anniversaryHomeowners, cameFrom);
      return {localNoteCopy: lastNoteHTML, localPSCopy: lastNotePS}
    }
    return {localNoteCopy: '', localPSCopy: ''};
  };

  const validateAndFetchSystemNote = () => {
    const { lastNoteHTML } = noteUtils.getNotePSFromPropertyandCameFrom(currentHOProperty, cameFrom);
    if(lastNoteHTML || noteUtils.isAnniversaryNotePSSkipped(currentHOProperty)){
      return;
    }
    fetchAndSetSystemNote();
  }

  const fetchAndSetSystemNote = () => {
    const {mlsAreaID, mlsNeighborhood, mlsCityName} = currentArea;
    const  {isMemberOfTeam} = agentData;
    const {HOQuickFilterData: {isAbsentee}, mailingDetails: {SiteNumber, SiteStreet, DocDate, YearBuilt}, latestSaleDate} = currentHOProperty;
    const siteAddress = `${propertyUtil.valueCorrector(SiteNumber)} ${propertyUtil.valueCorrector(SiteStreet)}`
    let yearPurchased = propertyUtil.valueCorrector(latestSaleDate) || propertyUtil.valueCorrector(DocDate) 
    if (!yearPurchased){
      yearPurchased = propertyUtil.valueCorrector(YearBuilt)
    } else {
      yearPurchased = moment(yearPurchased).format('YYYY-MM-DD')
    }
    //agent Data
    const dataToSend = { 
      areaID: mlsAreaID, areaName:  mlsNeighborhood, siteAddress, purchaseDate: yearPurchased, isAbsenteeHO: isAbsentee, 
      anniversaryCount: renderLogicUtil.getHOAnniversaryNumber(currentHOProperty) ,
      isMemberOfTeam: isMemberOfTeam,
      cityName: mlsCityName
    }
    handleSystemNoteLoading(true);
    getAnniversaryNoteForHomeowner(dataToSend).then(data => {
      updateLocalNotePS(data.commonNotes, (data.commonPS || ''))
    }).catch(err => {
      showErrorNotification(props, "Unable to load system default note");
    }).finally(() => {
      handleSystemNoteLoading(false);
    })
  }

  const handleNextProperty = () => {
    if (anniversaryHomeownersCount <= 1 || state.currentHOIndex === anniversaryHomeownersCount - 1) {
      //applicable where either  one HO is there or we added note for all Hos
      showInfoNotification(
        props,
        "Excellent! You have finished sending all your Happy Anniversary Notes.",
        8000
      );
      setTimeout(() => {
        handleClose();
      }, 1000);
    } else {
      updateLocalNotePS("", "");
      dispatch({ type: "currentHOIndex", payload: state.currentHOIndex + 1 });
    }
  };

  const handlLastHOProperty = () => {
    if(state.currentHOIndex > 0)
    dispatch({ type: "currentHOIndex", payload: state.currentHOIndex - 1 });
  };
  

  const handleNextHOPropertyClick = (saveData) => {
    if (saveData) {
      const errors = noteUtils.isNotePSReadyForSubmit(
        isBlocked,
        state.localNoteCopy,
        state.localPSCopy,
        cameFrom
      );
      if (errors.length > 0) {
        handleErrors(errors);
        return;
      }
      startNotePSSavingFlow(false, 'both');
      return;
    } else {
      handleNextProperty();
    }
  };

  const startNotePSSavingFlow = (resetMode = false, resetType = 'both') => {
    try {
      const updatedChangeDetails = noteUtils.buildNotePSDataBeforeSubmit(
        cameFrom,
        (currentHOProperty || {}).changeDetails,
        isBlocked,
        state.localNoteCopy,
        null,//Change this back to state.localPSCopy once we have PS back in anniversary. We have automated ps in anniversary and because of its getting submitted
        "both",
        resetMode,
        resetType
      );
      handleLoading(true);
      saveAnniversaryNoteChanges(updatedChangeDetails, resetMode)
        .then(() => {
          intermediateSaveNotePSSuccess();
        })
        .catch((err) => {
          console.error("Error in saveAnniversaryNoteChanges: " + err);
          handleLoading(false);// Do not use finally here because after saving we wants to keep button disabled for some time untinl note moved to next property
        });
    } catch (err) {
      handleErrors([err.message]);
    }
  };

  const intermediateSaveNotePSSuccess = () => {
    showSuccessNotification(props, 'Done. Moving to next Homeowner...', 2000)
    //isAnyLastHomeownerHasNote(); //dont delete this line
    setTimeout(() => {
      //add some timeout for UX
      handleNextProperty();
      handleLoading(false);
    }, 1200);
  }

  const saveAnniversaryNoteChanges = (
    updatedChangeDetails,
    resetMode = false
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        /**get vars */
        const hoData = noteUtils.buildHoDataCommonForMailPro(
          currentHOProperty,
          propertyData,
          updatedChangeDetails
        );

        const afterSuccess = () => {
          updateMailproAsync(
            (currentHOProperty || {}).mailerProID,
            updatedChangeDetails
          );
          setTimeout(async () => {
            await props.updateRecentUpdatedMailProId((currentHOProperty || {}).mailerProID);
          }, 10);
          resolve();
        }

        if (!resetMode) {
          //if reset mode is not enabled we need to save both note and ps
          //if reset mode is enable then we just need to restore last any personal note and ps
          props.updateQuickPersonalNotePS(hoData, "note", resetMode, () => {

            if(  updatedChangeDetails.personalPS && updatedChangeDetails.personalPS.length > 0){
              props.updateQuickPersonalNotePS(hoData, "ps", resetMode, () => {
                afterSuccess();
              })
            } else {
              afterSuccess();
            }
          });
        } else {
          //Incase of reset if last note is equal to came from note then we need to reset both note and ps otherwise skip becuase we dont have to reset other kind of personal notes
          const lastNoteIsSameToCameFrom = noteUtils.isPropertyHasACameFromNoteWritten(currentHOProperty, cameFrom);
          lastNoteIsSameToCameFrom ? await props.updateQuickPersonalNotePS(
            hoData,
            "note",
            resetMode,
            () => {
              afterSuccess();
            }
          ) : afterSuccess();
        }
      } catch (err) {
        handleErrors([err.message]);
        reject();
      }
    });
  };

  const updateMailproAsync = async (mailerProID, updatedChangeDetails) => {
    /**update the local store also */
    let xPropertyData = propertyData.slice();
    let newPropertyData = xPropertyData.map((property) => {
      if (property.mailerProID === mailerProID) {
        property.changeDetails = updatedChangeDetails;
      }
      return property;
    });
    /**update the store now */
    await props.updateCompleteMailerProDataAsync(newPropertyData);
  };

  const skipAnniversaryNoteandPS = async () => {
    const mailProID = currentHOProperty.mailerProID;
    handleLoading(true);
    await skipAnniversaryNotesForMailProIDs([mailProID]).then(async () => {
      //first check if last note is a anniversary note or not
      const lastNoteIsSameToCameFrom = noteUtils.isPropertyHasACameFromNoteWritten(currentHOProperty, cameFrom);
      if (lastNoteIsSameToCameFrom) {
        //if last note is a anniversary note then we need to reset both note and ps
         startNotePSSavingFlow(true, 'both');
      } else {
        //if last note is not anniversary note than we just need to skip anniversary note without touching any other kind of personal note or ps i.e. no need to reset but still sending reset mode as true as in saveAnniversaryNoteChanges it will again check for last note and will skip if last note is not anniversary note. If we will send reset as false than it will set that note as an anniversary note and that will be wrong.
        const updatedChangeDetails = noteUtils.updateDatatoSkipAnniversaryNotePS(currentHOProperty, true);
        await saveAnniversaryNoteChanges(updatedChangeDetails, true)
        .then(intermediateSaveNotePSSuccess).catch(err => {
          showErrorNotification(props, "Unable to process skip anniversary note request");
          handleLoading(false);
        });
        //send reset to true
      }
    }).catch(err => {
      showErrorNotification(props, "Unable to skip anniversary note");
    }
    );
  };

  const skipBulkAnniversaryNotes = async () => {
    //Includes PS as well
    //Bulk anniversary FN can only skip notes where not anniversary noteand ps is written
    const mailProIDs = noteUtils.getMailProIDsWhereAnniversaryNoteCanbeSkipped(anniversaryHomeowners, cameFrom);
    handleLoading(true);
    await skipAnniversaryNotesForMailProIDs(mailProIDs).then(() => {
      showSuccessNotification(props, "Anniversary notes are skipped. Please wait while MailPro refreshes", 2000);
      setTimeout(() => {
        handleClose();
        window.location.reload();
      }, 1000);
    }).catch(err => {
      showErrorNotification(props, "Unable to skip anniversary notes");
    }).finally(() => {
      handleLoading(false);
    });
  }
  

  return (
    <div>
      <div
        onClick={anniversaryHomeowners.length > 0 ? handleClickOpen : () => {}}
      >
        {props.children}
      </div>
      {state.open && (
        <Dialog
          onClose={handleClose}
          fullWidth={true}
          classes={{
            paper: classNames(classes.rootPaper, classes.rootPaperDecorative),
          }}
          open={state.open}
          fullScreen={false}
          TransitionComponent={Transition}
          keepMounted
          BackdropProps={{
            classes: {
              root: classes.backDropBackgroundDark,//backDropBackgroundLightBlue,
            },
          }}
          disableEnforceFocus
          disableBackdropClick
        >
          <AnniversaryAutomatorContext.Provider value={{currentArea:currentArea, mlsAreas:props.mlsAreas}}>
          <AnniversaryAutomatorDialogHeader
            classes={classes}
            property={currentHOProperty}
            propertySequenceNumber={state.currentHOIndex + 1}
            agentData={agentData}
            propertyData={propertyData}
            onCloseClick={handleClose}
            isMailProLocked={isMailProLocked}
            anniversarySkippableMailProIdsCount={anniversarySkippableMailProIds.length}
          />
          <AnniversaryAutomatorDialogHeaderExtra
            classes={classes}
            property={currentHOProperty}
            propertySequenceNumber={state.currentHOIndex + 1}
            agentData={agentData}
            propertyData={propertyData}
            isMailProLocked={isMailProLocked}
          />
          <DialogContent className={classes.dialogContentMain}>
            <MailProErrorFormatter errors={state.errorMessage} />
            <AnniversaryAutomatorNoteandPSView
              classes={classes}
              cameFrom={"Anniversary"}
              property={currentHOProperty}
              isBlocked={isBlocked}
              agentData={agentData}
              updateFinalLocalCopyNotePS={updateLocalNotePS}
              localNoteCopy={state.localNoteCopy}
              localPSCopy={state.localPSCopy}
              key={state.currentHOIndex}
              restoreSystemNotePS={fetchAndSetSystemNote}
              //isAnyLastHomeownerNoteExist={state.isAnyLastHomeownerNoteExist}
              getLastHomeownerNotePS={getLastHomeownerNotePS}
              isNotePSSkipped={noteUtils.isAnniversaryNotePSSkipped(currentHOProperty)}//Keeping anniversary as of now
              isNotePSOverridden={state.isNotePSOverridden}
              isLoadingSystemDefaultNoteData={state.isLoadingSystemDefaultNoteData}
            />
          </DialogContent>
          <AnniversaryAutomatorDialogFooter
            classes={classes}
            propertySequenceNumber={state.currentHOIndex + 1}
            handleNextHOProperty={(saveData) =>
              handleNextHOPropertyClick(saveData)
            }
            isLoading={state.isLoading}
            isLoadingSystemDefaultNoteData={state.isLoadingSystemDefaultNoteData}
            handlLastHOProperty={handlLastHOProperty}
            resetNoteandPS={skipAnniversaryNoteandPS}
            skipBulkAnniversaryNotes={skipBulkAnniversaryNotes}
            anniversarySkippableMailProIdsCount={anniversarySkippableMailProIds.length}
            anniversaryHomeownersCount={anniversaryHomeownersCount}
          />
          </AnniversaryAutomatorContext.Provider>
        </Dialog>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    hoFilter: state.pma.hoFilter,
    propertyData: state.pma.propertyData,
    pma: state.pma,
    currentArea: state.pma.currentArea,
    mlsAreas: state.pma.mlsAreas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    explicitEnableNewMailProInitFn: (data) =>
      dispatch(pmaActions.explicitEnableNewMailProInitFn(data)),
    disableNewMailingForCurrentSessionFn: (data) =>
      dispatch(pmaActions.disableNewMailingForCurrentSessionFn(data)),
    setNewMailProInitDialogModeFn: (data) =>
      dispatch(pmaActions.setNewMailProInitDialogModeFn(data)),
    updateQuickPersonalNotePS: (
      hoData,
      mode,
      resetMode,
      onSuccessCallbackFn
    ) => {
      dispatch(
        pmaActions.updateQuickPersonalNotePS(
          hoData,
          mode,
          resetMode,
          onSuccessCallbackFn
        )
      );
    },
    updateCompleteMailerProDataAsync: (propertyData) => {
      dispatch(pmaActions.updateCompleteMailerProDataAsync(propertyData));
    },
    updateRecentUpdatedMailProId: (mailerProID) => {
      dispatch(pmaActions.updateRecentUpdatedMailProId(mailerProID));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withStyles(styles), withSnackbar)(AnniversaryAutomatorDialog));
