import { green, blue, lightBlue } from "@material-ui/core/colors";

export default theme => ({
  root: {},
  field: {
    //padding: theme.spacing(3)
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  textField: {
    width: '100%',
    maxWidth: '100%',
    //marginRight: theme.spacing(3)
  },
  dropdownTopMargin4Unit: {
    marginTop: -theme.spacing(4),
  },
  dropdownTopMargin2Unit: {
    marginTop: -theme.spacing(2),
  },
  dropdownTopMargin1UnitPlus: {
    marginTop: theme.spacing(1),
  },
  fieldError: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  hideField: {
    display: 'none',
  },
  checkboxField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: '-15px',
    display: 'flex',
    alignItems: 'center',
  },
  teamTitleMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  textCenter: {
    textAlign: 'center',
  },
  teamPadding: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  portletMargin: {
    marginTop: -theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  teamButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  lightBlueBg: {
    backgroundColor: theme.palette.primary.mediumLight,
  },
  primaryBlueBg: {
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    margin: theme.spacing(3),
    fontSize: 45,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  uploadButton: {
    backgroundColor: theme.palette.primary.mediumLight,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    marginTop: theme.spacing(2)
  },
  spaceAround: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  imageHolderWrapper: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  imageHolder: {
    maxWidth: '200px',
    //maxHeight: '500px',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    height: '200px',
    objectFit: 'cover'
  },
  imageHolderDialog: {
    maxWidth: '200px',
    //maxHeight: '500px',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    height: 'auto',
    objectFit: 'cover'
  },
  imageTag: {
    width: '100%',
    height: '200px',
    cursor: 'pointer'
  },
  expansionPanelDetails: {
    padding: 0
  },
  addAnotherTeamMember: {
    marginTop: theme.spacing(2.3)
  },
  expansionPanelHeader: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  expansionPanelHeaderTeam: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  autoFilled: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  green500: {
    color: green[500]
  },
  expansionPanelSummary: {
    backgroundColor: lightBlue[50]
  },
  blue500: {
    backgroundColor: blue[500]
  },
  formControl: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
});
