import palette from '../../theme/palette';

export default theme => ({
  root: {
    width: '100%',
    maxWidth: 460,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '80%',
    maxHeight: 435,
  },
  chipDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogFooterActions: {
    justifyContent: 'space-between'
  },
  cursorPointer: {
    cursor: 'pointer'
  }
});
