import { red, yellow } from "@material-ui/core/colors";

export default theme => ({
    root: {
        width: '100%',
        height: 'auto',
        textAlign: 'center'
    },
    headerContainer: {
        textAlign: 'center',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    headerText: {
        fontSize: '18px',
        fontWeight: 'bolder',
        lineHeight: '1.5'
    },
    section: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'block',
        textAlign: 'left'
    },
    underlineOnly: {
        textDecoration: 'underline',
    },
    sectionSpanText: {
        textDecoration: 'underline',
        fontWeight: '500',
        marginRight: theme.spacing(1)
    },
    sectionMainTC: {
        marginTop: theme.spacing(3)
    },
    sectionIndent: {
        textIndent: '60px'
    },
    sectionBreaker: {
        marginTop: theme.spacing(2)
    },
    italicText: {
        fontStyle: 'italic'
    },
    subsubPoint: {
        marginRight: theme.spacing(1.5)
    },
    addressFlex: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    addressRow: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '70%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    addressRowRight: {
        textAlign: 'left',
        width: '80%',
        fontWeight: '500'
    },
    superBoldOnly: {
        fontWeight: 800
    },
    boldOnly: {
        fontWeight: 500
    },
    redColor: {
        color: red[500]
    },
    addressRowLeft: {
        fontWeight: '500'
    },
    marginTop4Px: {
        marginTop: theme.spacing(5), 
    },
    areaNames: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '&>span': {
            fontWeight: '450',
            height: '25px'
        }
    },
    yellowLightBG: {
        background: yellow[300]
    },
    redLightBG: {
        background: '#f4cccc'
    }
});