import React, { Component } from "react";
import { Link } from "react-router-dom";

class MQThreeTestimonial2 extends Component {
  render() {
    const { testimonials, agentData, missionStatement } = this.props;

    // console.log("agentData", agentData);
    // const { listgenUserName,}
    //console.log("listgenUserID", agentData.listgenUserID);
    const {
      listgenUserFirstName,
      listgenUserLastName,
      teamMembers,
      isMemberOfTeam,
      listgenUserID,
    } = agentData;
    let signoffText;
    let textContent;
    let agentName;
    let useMissionStatement = true;
    if (listgenUserID === "100588") {
      agentName = "Team Scarborough";
    } else if (isMemberOfTeam) {
      agentName = `${listgenUserFirstName} & ${teamMembers[1].firstName}`;
    } else {
      agentName = `${listgenUserFirstName}`;
    }
    let alternateTitle;
    if (listgenUserID === "100778") {
      alternateTitle = "Our Mission";
    }

    if (missionStatement && Object.keys(missionStatement).length > 0) {
      const { missionStatementContent, namePreferred } = missionStatement;
      useMissionStatement = true;
      //console.log("inside name preferred", namePreferred);
      if (listgenUserID === "100047") {
        signoffText = "Kyle and David";
      } else {
        signoffText = namePreferred;
      }

      textContent = missionStatementContent;
    }
    // else {
    //   const {
    //     clientFirstName,
    //     clientLastName,
    //     testimonialContent,
    //   } = testimonials;
    //   signoffText = `${clientFirstName} ${
    //     clientLastName ? clientLastName : ""
    //   }`;
    //   textContent = testimonialContent;
    // }
    // if (!textContent) {
    //   textContent = "Please add mission statement";
    // }

    let textClass;
    // if (textContent.length >= 200 && textContent.length <= 300) {
    //   textClass = "larger-testimonial-mission-text";
    // } else {
    //   textClass = "medium-testimonial-mission-text";
    // }
    if (missionStatement === "none" || !missionStatement) {
      return (
        <div className="mq3-testimonial2-container">
          <div className="mq3-testimonial2-title-container">
            {alternateTitle && alternateTitle.length > 1 ? (
              <div className="mq3-testimonial2-title">{alternateTitle}</div>
            ) : (
              <div className="mq3-testimonial2-title">
                {isMemberOfTeam || listgenUserID === "100988" ? "Our" : "My"}{" "}
                Commitment To You
              </div>
            )}
            <div className="mq3-testimonial2-title-divider"></div>
          </div>
          <Link to="/agent/missionstatements">
            <div className="mq3-testimonial">
              <div className="testimonial-top-layer">
                <div className="testimonial-container">
                  <div className="testimonial-content-row">
                    <div className="add-testimonial-text">
                      Please Click Here to Add Your Mission Statement (required)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      );
    } else {
      return (
        <div className="mq3-testimonial2-container">
          <div className="mq3-testimonial2-title-container">
            {alternateTitle && alternateTitle.length > 1 ? (
              <div className="mq3-testimonial2-title">{alternateTitle}</div>
            ) : (
              <div className="mq3-testimonial2-title">
                {isMemberOfTeam || listgenUserID === "100988" ? "Our" : "My"}{" "}
                Commitment To You
              </div>
            )}
            <div className="mq3-testimonial2-title-divider"></div>
          </div>
          <div className="mq3-testimonial">
            <div className="testimonial-top-layer">
              <div className="testimonial-container">
                <div className="testimonial-content-row">
                  <div className={`testimonial-text ${textClass}`}>
                    {textContent}
                  </div>
                </div>

                <div
                  className={`testimonial-signoff ${
                    textClass
                      ? textClass
                      : "Please add testimonial or mission statement"
                  }`}
                >
                  <p>— {signoffText}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default MQThreeTestimonial2;
