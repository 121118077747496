import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listItemText: {
    '&>span': {
      fontSize: '14px',
      fontWeight: '400'
    }
  },
  listItemDense: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

export default function HelpListForm(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(null);
  const { primaryDropdwonData, handleListSelection } = props;

  const handleToggle = (index, value) => () => {
    if (index === checked) {
      //unselect
      setChecked(null);
      handleListSelection(null, null);
    } else {
      setChecked(index);
      handleListSelection(index, value);
    }
  };

  return (
    <List
      className={classes.root}
      dense={true}
    >
      {primaryDropdwonData.map((option, key) => {
        const labelId = `checkbox-list-label-${option.label}`;

        return (
          <Fragment>
            <ListItem
              key={key}
              role={undefined}
              dense={true}
              button
              onClick={handleToggle(key, option.value)}
              selected={checked === key}
              className={classes.listItemDense}
              >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  color="primary"
                  checked={checked === key}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText className={classes.listItemText} component="span" id={labelId} primary={option.value} />
            </ListItem>
            {/* <Divider variant="inset" component="li" /> */}
          </Fragment>
        );
      })}
    </List>
  );
}
