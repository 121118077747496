import React, { Component } from "react";
import MQThreeIndicatorSection from "./MQThreeIndicatorSection";
import { propertyUtil } from "views/Pma/PropertyUtil";

class MQThreeMarketIndicators extends Component {
  determineComparison = () => {
    const { infinityCheck } = propertyUtil;
    function calculateDiff(curr, prev) {
      if (curr === "0" && prev === "0") {
        return "0";
      } else if (curr === "0") {
        return -100;
      } else if (prev === "0") {
        return 100;
      } else {
        curr = Math.round(curr);
        prev = Math.round(prev);
        let res = infinityCheck(
          Math.round(((curr - prev) / Math.abs(prev)) * 100)
        );
        return res;
      }
    }
    const {
      quarterlyAreaTable,
      currentArea,
      useCity,
      quarterlyCityTable,
    } = this.props;

    const { marketHighLightsData } = quarterlyAreaTable;
    const { keyMarketIndicatorAveragesForCity } = quarterlyCityTable;
    const { mlsAreaID } = currentArea;

    let dataObj = {
      compareDays: null,
      salePriceDiff: null,
      salePriceDataPoint: null,
      receivedPerc: null,
      receivedDataPoint: null,
      daysToSellPerc: null,
      daysToSellDataPoint: null,
      unitsSoldPerc: null,
      unitsSoldDataPoint: null,
      sqftPercDiff: null,
      sqftDataPoint: null,
      monthComparison: null,
      highestSalePrice: null,
    };
    const {
      current180DaysAvgSalePrice,
      previous180DaysAvgSalePrice,
      current180DaysPercentReceivedOfAsking,
      previous180DaysPercentReceivedOfAsking,
      current180DaysAvgDOM,
      previous180DaysAvgDOM,
      current180DaysUnitsSold,
      previous180DaysUnitsSold,
      current180DaysPricePerSqFt,
      previous180DaysPricePerSqFt,

      current90DaysAvgSalePrice,
      previous90DaysAvgSalePrice,
      current90DaysPercentReceivedOfAsking,
      previous90DaysPercentReceivedOfAsking,
      current90DaysAvgDOM,
      previous90DaysAvgDOM,
      current90DaysUnitsSold,
      previous90DaysUnitsSold,
      current90DaysPricePerSqFt,
      previous90DaysPricePerSqFt,
    } = marketHighLightsData;
    //console.log("marketHighLightsData", marketHighLightsData);

    if (useCity) {
      //if los olivos or Buellton

      const {
        keyMarketIndicatorAveragesForCity,
        keyMarketIndicatorAveragesPercentageForCity,
      } = quarterlyCityTable;
      let currSix = keyMarketIndicatorAveragesForCity.filter(
        (term) => term.NameOfTerm === "CurrentSixMonths"
      )[0];
      let prevSix = keyMarketIndicatorAveragesForCity.filter(
        (term) => term.NameOfTerm === "PreviousSixMonths"
      )[0];

      const {
        AverageDaysOnMarket: currAverageDaysOnMarket,
        AveragePercentOfReceiving: currAveragePercentOfReceiving,
        AveragePricePerSqFt: currAveragePricePerSqFt,
        AverageSalePrice: currAverageSalePrice,
        NameOfTerm: currNameOfTerm,
        NumberOfListings: currNumberOfListings,
      } = currSix;
      const {
        AverageDaysOnMarket: prevAverageDaysOnMarket,
        AveragePercentOfReceiving: prevAveragePercentOfReceiving,
        AveragePricePerSqFt: prevAveragePricePerSqFt,
        AverageSalePrice: prevAverageSalePrice,
        NameOfTerm: prevNameOfTerm,
        NumberOfListings: prevNumberOfListings,
      } = prevSix;

      dataObj.compareDays = "6 Months";
      dataObj.monthComparison = 6;
      dataObj.salePriceDataPoint = currAverageSalePrice;
      dataObj.salePriceDiff = calculateDiff(
        currAverageSalePrice,
        prevAverageSalePrice
      );
      dataObj.receivedDataPoint = currAveragePercentOfReceiving;
      dataObj.receivedPerc = calculateDiff(
        currAveragePercentOfReceiving,
        prevAveragePercentOfReceiving
      );
      dataObj.sqftDataPoint = currAveragePricePerSqFt;
      dataObj.sqftPercDiff = calculateDiff(
        currAveragePricePerSqFt,
        prevAveragePricePerSqFt
      );
      dataObj.daysToSellDataPoint = currAverageDaysOnMarket;
      dataObj.daysToSellPerc = calculateDiff(
        currAverageDaysOnMarket,
        prevAverageDaysOnMarket
      );
      dataObj.unitsSoldDataPoint = currNumberOfListings;
      dataObj.unitsSoldPerc = calculateDiff(
        currNumberOfListings,
        prevNumberOfListings
      );
      // console.log("dataObj", dataObj);
      return dataObj;
    } else if (+current90DaysUnitsSold < 1 || +previous90DaysUnitsSold < 1) {
      dataObj.compareDays = "6 Months";
      dataObj.monthComparison = 6;
      dataObj.salePriceDataPoint = current180DaysAvgSalePrice;
      dataObj.salePriceDiff = calculateDiff(
        current180DaysAvgSalePrice,
        previous180DaysAvgSalePrice
      );
      dataObj.receivedDataPoint = current180DaysPercentReceivedOfAsking;
      dataObj.receivedPerc = calculateDiff(
        current180DaysPercentReceivedOfAsking,
        previous180DaysPercentReceivedOfAsking
      );
      dataObj.sqftDataPoint = current180DaysPricePerSqFt;
      dataObj.sqftPercDiff = calculateDiff(
        current180DaysPricePerSqFt,
        previous180DaysPricePerSqFt
      );
      dataObj.daysToSellDataPoint = current180DaysAvgDOM;
      dataObj.daysToSellPerc = calculateDiff(
        current180DaysAvgDOM,
        previous180DaysAvgDOM
      );
      dataObj.unitsSoldDataPoint = current180DaysUnitsSold;
      dataObj.unitsSoldPerc = calculateDiff(
        current180DaysUnitsSold,
        previous180DaysUnitsSold
      );
      // console.log("dataObj", dataObj);
      return dataObj;
    } else {
      dataObj.compareDays = "90 Days";
      dataObj.monthComparison = 6;
      dataObj.salePriceDataPoint = current90DaysAvgSalePrice;
      dataObj.salePriceDiff = calculateDiff(
        current90DaysAvgSalePrice,
        previous90DaysAvgSalePrice
      );
      dataObj.receivedDataPoint = current90DaysPercentReceivedOfAsking;
      dataObj.receivedPerc = calculateDiff(
        current90DaysPercentReceivedOfAsking,
        previous90DaysPercentReceivedOfAsking
      );

      dataObj.sqftDataPoint = current90DaysPricePerSqFt;
      dataObj.sqftPercDiff = calculateDiff(
        current90DaysPricePerSqFt,
        previous90DaysPricePerSqFt
      );

      dataObj.daysToSellDataPoint = current90DaysAvgDOM;
      dataObj.daysToSellPerc = calculateDiff(
        current90DaysAvgDOM,
        previous90DaysAvgDOM
      );
      dataObj.unitsSoldDataPoint = current90DaysUnitsSold;
      dataObj.unitsSoldPerc = calculateDiff(
        current90DaysUnitsSold,
        previous90DaysUnitsSold
      );
      //console.log("dataObj", dataObj);
      return dataObj;
    }
  };

  render() {
    // console.log(this.props);
    const {
      current90DaysAvgSalePrice,
      previous90DaysAvgSalePrice,
      current90DaysAvgDOM,
      previous90DaysAvgDOM,
      current90DaysPricePerSqFt,
      previous90DaysPricePerSqFt,
      current90DaysUnitsSold,
      previous90DaysUnitsSold,
    } = this.props.quarterlyAreaTable.marketHighLightsData;
    const { infinityCheck } = propertyUtil;

    const determineComparison = this.determineComparison();
    // console.log("**determineComparison", determineComparison);
    return (
      <div className="mq3-market-indicators">
        <div className="mini-market-title">
          <div className="left-market-title-container">
            <div className="left-market-title-section">
              <p>Key Market Indicators</p>
            </div>
            <div className="mini-market-title-divider"></div>
          </div>

          <div
            className={`right-market-title-section ${
              determineComparison.compareDays === "6 Months" &&
              "smaller-market-title-section"
            }`}
          >
            <p>Last {determineComparison.compareDays}</p>
            <p className="lc-market-title">vs</p>
            <p> Previous {determineComparison.compareDays}</p>
          </div>
        </div>
        <div className="indicator-container">
          <div className="inner-indicator-container">
            <MQThreeIndicatorSection
              category="Sale Prices"
              value={determineComparison.salePriceDataPoint}
              perc={determineComparison.salePriceDiff}
            />
            <MQThreeIndicatorSection
              category="% Received of Asking"
              value={determineComparison.receivedDataPoint}
              perc={determineComparison.receivedPerc}
            />
            <MQThreeIndicatorSection
              category="Days to Sell"
              value={determineComparison.daysToSellDataPoint}
              perc={determineComparison.daysToSellPerc}
            />

            <MQThreeIndicatorSection
              category="Square Ft"
              value={determineComparison.sqftDataPoint}
              perc={determineComparison.sqftPercDiff}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MQThreeMarketIndicators;
