import React, { Fragment } from "react";
import { Button, Divider, makeStyles, Typography } from "@material-ui/core";
import { propertyUtil } from "../../PropertyUtil";
import classNames from "classnames";
import ClearIcon from "@material-ui/icons/Clear";
import { red } from "common/colors";
import palette from "theme/palette";
import theme from "theme";

export const isAnyHONameChangedV2 = () => {
  return function ({
    mailingDetails: {
      OwnerFirst,
      OwnerLast,
      SecondaryOwnerFirst,
      SecondaryOwnerLast,
    },
    changeDetails: {
      lgOwnerFirstName,
      lgOwnerLastName,
      lgSecondaryOwnerFirstName,
      lgSecondaryOwnerLastName,
    },
  }) {
    // console.log('OwnerFirst, OwnerLast, SecondaryOwnerFirst, SecondaryOwnerLast==', OwnerFirst, OwnerLast, SecondaryOwnerFirst, SecondaryOwnerLast);
    // console.log("lgOwnerFirstName, lgOwnerLastName, lgSecondaryOwnerFirstName, lgSecondaryOwnerLastName==", lgOwnerFirstName, lgOwnerLastName, lgSecondaryOwnerFirstName, lgSecondaryOwnerLastName);
    return (
      (OwnerFirst !== lgOwnerFirstName &&
        OwnerFirst !== "-" &&
        lgOwnerFirstName !== "-") ||
      (OwnerLast !== lgOwnerLastName &&
        OwnerLast !== "-" &&
        lgOwnerLastName !== "-") ||
      (SecondaryOwnerFirst !== lgSecondaryOwnerFirstName &&
        SecondaryOwnerFirst !== "-" &&
        lgSecondaryOwnerFirstName !== "-") ||
      (SecondaryOwnerLast !== lgSecondaryOwnerLastName &&
        SecondaryOwnerLast !== "-" &&
        lgSecondaryOwnerLastName !== "-")
    );
  };
};

export const getHONamesBaseDataClosureV2 = () => {
  return function ({
    changeDetails: {
      lgOwnerFirstName,
      lgOwnerLastName,
      lgSecondaryOwnerFirstName,
      lgSecondaryOwnerLastName,
    },
  }) {
    return {
      xOwner1First:
        lgOwnerFirstName && lgOwnerFirstName !== "-" ? lgOwnerFirstName : "",
      xOwner1Last:
        lgOwnerLastName && lgOwnerLastName !== "-" ? lgOwnerLastName : "",
      xOwner2First:
        lgSecondaryOwnerFirstName && lgSecondaryOwnerFirstName !== "-"
          ? lgSecondaryOwnerFirstName
          : "",
      xOwner2Last:
        lgSecondaryOwnerLastName && lgSecondaryOwnerLastName !== "-"
          ? lgSecondaryOwnerLastName
          : "",
    };
  };
};

export const getHONamesOriginalDataClosureV2 = () => {
  return function ({
    mailingDetails: {
      OwnerFirst,
      OwnerLast,
      SecondaryOwnerFirst,
      SecondaryOwnerLast,
    },
  }) {
    return {
      xOwner1First: OwnerFirst && OwnerFirst !== "-" ? OwnerFirst : "",
      xOwner1Last: OwnerLast && OwnerLast !== "-" ? OwnerLast : "",
      xOwner2First:
        SecondaryOwnerFirst && SecondaryOwnerFirst !== "-"
          ? SecondaryOwnerFirst
          : "",
      xOwner2Last:
        SecondaryOwnerLast && SecondaryOwnerLast !== "-"
          ? SecondaryOwnerLast
          : "",
    };
  };
};

export const agentBarSwitchClosureV2 = (classes, showSuggestedNames = false) => {
  return function () {
    //some component directly have mailing details some property object containg mailing details.. same for change details
    let {
      OwnerName,
      SecondaryOwnerName,
      OwnerFirst,
      OwnerLast,
      SecondaryOwnerFirst,
      SecondaryOwnerLast,
      SuggestedOwnerName,
      SuggestedSecondaryOwnerName
    } = this.props.mailingDetails || this.props.property.mailingDetails;
    const {
      lgOwnerFirstName,
      lgOwnerLastName,
      lgSecondaryOwnerFirstName,
      lgSecondaryOwnerLastName,
      lgIsOwnerNameModified,
    } = this.props.changeDetails || this.props.property.changeDetails;
    return (
      <div className={classNames(classes.topNameDialogBar)}>
        <div
          className={classes.lightGreenBackground}
          style={{
            padding: "8px",
            borderRadius: "4px",
            border: "2px solid green",
          }}
        >
          <Typography
            variant="subtitle2"
            align="center"
            component="p"
            style={{ paddingBottom: "8px" }}
          >
            <span className={classes.boldText}>ORIGINAL</span> Homeowner's Name
          </Typography>
          <div>
            <div style={{ paddingBottom: "4px" }}>
              {OwnerFirst && OwnerFirst !== "-" ? (
                <Typography variant="subtitle2" align="left" component="p">
                  Homeowner 1:&nbsp;&nbsp;
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="primary"
                  >
                    {OwnerFirst}
                    {OwnerLast && OwnerLast !== "-" ? " " + OwnerLast : ""}
                  </Typography>
                </Typography>
              ) : (
                ""
              )}
            </div>
            {SecondaryOwnerFirst && SecondaryOwnerFirst !== "-" ? (
              <Typography variant="subtitle2" align="left" component="p">
                Homeowner 2:&nbsp;&nbsp;
                <Typography
                  variant="subtitle2"
                  component="span"
                  color="primary"
                >
                  {SecondaryOwnerFirst}
                  {SecondaryOwnerLast && SecondaryOwnerLast !== "-"
                    ? " " + SecondaryOwnerLast
                    : ""}
                </Typography>
              </Typography>
            ) : (
              ""
            )}
          </div>
          {showSuggestedNames && SuggestedOwnerName && SuggestedOwnerName != "-" && <div>
          <Typography
            variant="subtitle2"
            align="center"
            component="p"
            style={{ paddingBottom: "8px", marginTop: '16px' }}
          >
            <span className={classes.boldText}>Suggested</span> Homeowner's Name
          </Typography>
          <div>
            <div style={{ paddingBottom: "4px" }}>
              {SuggestedOwnerName && SuggestedOwnerName !== "-" ? (
                <Typography variant="subtitle2" align="left" component="p">
                  Homeowner 1:&nbsp;&nbsp;
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="primary"
                  >
                    {SuggestedOwnerName}
                  </Typography>
                </Typography>
              ) : (
                ""
              )}
            </div>
            {SuggestedSecondaryOwnerName && SuggestedSecondaryOwnerName !== "-" && (
              <Typography variant="subtitle2" align="left" component="p">
                Homeowner 2:&nbsp;&nbsp;
                <Typography
                  variant="subtitle2"
                  component="span"
                  color="primary"
                >
                  {SuggestedSecondaryOwnerName}
                </Typography>
              </Typography>
            )}
          </div>
          </div>}
        </div>
        {/* <Divider orientation="vertical" flexItem style={{ margin: '0 16px' }} /> */}
        {lgIsOwnerNameModified ? (
          <div
            className={classes.lightGreenBackground}
            style={{
              padding: "8px",
              borderRadius: "4px",
              border: "2px solid green",
            }}
          >
            <Typography
              variant="subtitle2"
              align="center"
              component="p"
              style={{ paddingBottom: "8px" }}
            >
              <span className={classes.boldText}>PERSONALIZED</span> Homeowner's
              Name
            </Typography>
            <div>
              <div style={{ paddingBottom: "4px" }}>
                {lgOwnerFirstName && lgOwnerFirstName !== "-" ? (
                  <Typography variant="subtitle2" align="left" component="p">
                    Homeowner 1:&nbsp;&nbsp;
                    <Typography
                      variant="subtitle2"
                      component="span"
                      color="primary"
                    >
                      {lgOwnerFirstName}
                      {lgOwnerLastName && lgOwnerLastName !== "-"
                        ? " " + lgOwnerLastName
                        : ""}
                    </Typography>
                  </Typography>
                ) : (
                  ""
                )}
              </div>
              {lgSecondaryOwnerFirstName &&
              lgSecondaryOwnerFirstName !== "-" ? (
                <Typography variant="subtitle2" align="left" component="p">
                  Homeowner 2:&nbsp;&nbsp;
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="primary"
                  >
                    {lgSecondaryOwnerFirstName}
                    {lgSecondaryOwnerLastName &&
                    lgSecondaryOwnerLastName !== "-"
                      ? " " + lgSecondaryOwnerLastName
                      : ""}
                  </Typography>
                </Typography>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };
};

export const MailProBlockedEditingDisabledBar = ({ classes, isBlocked }) => {
  return isBlocked ? (
    <Typography
      variant="subtitle2"
      //className={classes.redColor}
      align="center"
      className="red-notice-color"
    >
      <p>
        Your PMAs are currently being printed — MailPro is temporarily locked.
      </p>
      <p>
        If you have an urgent support request please{" "}
        <a href="/help?mode=window" target="_blank">
          CLICK HERE
        </a>
        .
      </p>
    </Typography>
  ) : (
    ""
  );
};

export const MailProCloseButton = ({ variant, ...rest }) => {
  const useStyles = makeStyles({
    closeModalBtn: {
      display: 'flex',
      marginTop: -theme.spacing(1.5),
      flexDirection: 'row',
      alignItems: 'start',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(1),
      marginRight: -theme.spacing(2.5),
    },
    closeredButton: {
      minWidth: theme.spacing(2.5),
      color: "white",
      backgroundColor: red[900],
      textAlign: "center",
      verticalAlign: "middle",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: red[900],
        color: "white",
      },
    },
    primaryButton: {
      backgroundColor: palette.primary.main,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: palette.primary.main,
        color: "white",
      },
    },
    whiteColor: {
      color: "white",
    },
  });
  const classes = useStyles();
  return (
    <div
      className={classes.closeModalBtn}
      {...rest}
    >
      <Button
        variant="contained"
        size="small"
        className={variant === "default"
          ? classes.closeredButton
          : classNames(classes.closeredButton, classes.primaryButton)}
      >
        <ClearIcon className={classes.whiteColor} />
      </Button>
    </div>
  );
};