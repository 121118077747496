import React, { Component } from "react";
import "./customStyles.scss";
import "./customPrintStyles.scss";

class JohnsonsQuadrantsTwo extends Component {
  render() {
    //console.log("inside quadrant");
    return (
      <>
        <div className="johnsons-quadrants-two">
          <div className="johnsons-quadrants-body-two">
            <div className="body-title-section-two">
              <div className="johnsons-quadrants-body-title-two">
                <p>How can we help YOU?</p>
              </div>

              <div className="johnsons-quadrants-body-subtitle-text-two">
                We Have solutions for homeowners
              </div>
            </div>
            <div className="section-container-two">
              <div className="section-row-two">
                <div className="johnsons-quadrants-section-two">
                  <div className="johnsons-quadrants-section-subtitle-two">
                    Connect you with
                  </div>
                  <div className="johnsons-quadrants-section-title-two">
                    Home Service Pros
                  </div>
                  <div className="johnsons-quadrants-section-text-two">
                    <p>Experience has connected us with many local</p>
                    <p>top-notch service professionals from roofers to</p>
                    <p>painters to gardeners and plumbers.</p>
                  </div>
                </div>
                <div className="johnsons-quadrants-section-two">
                  <div className="johnsons-quadrants-section-subtitle-two">
                    Provide Opinion On
                  </div>
                  <div className="johnsons-quadrants-section-title-two">
                    Home Improvement
                  </div>
                  <div className="johnsons-quadrants-section-text-two">
                    <p>As a homeowner considering a home</p>
                    <p>improvement project, we're sure you have many</p>
                    <p>questions regarding what projects to prioritize.</p>
                  </div>
                </div>
              </div>
              <div className="section-row-two">
                <div className="johnsons-quadrants-section-two">
                  <div className="johnsons-quadrants-section-subtitle-two">
                    Provide an estimate on your
                  </div>
                  <div className="johnsons-quadrants-section-title-two">
                    Home Value
                  </div>
                  <div className="johnsons-quadrants-section-text-two">
                    <p>Your home is a significant financial asset and</p>
                    <p>
                      if you're interested in it's potential value in the
                      current
                    </p>
                    <p>market, we can provide an analysis that's far more</p>
                    <p>in-depth than what you'll find online.</p>
                  </div>
                </div>
                <div className="johnsons-quadrants-section-two last-paragraph-reduced-mt">
                  <div className="johnsons-quadrants-section-text-two">
                    <p>Will upgrading your primary bath increase</p>
                    <p>your home's value? How about solar? What is</p>
                    <p>the simplest project to boost curb appeal?</p>
                  </div>

                  <div className="johnsons-quadrants-section-text-two last-paragraph-high-mt">
                    <p>We've been involved in plenty of renovation</p>
                    <p>projects and can provide guidance as to what's</p>
                    <p>worth it and what will ultimately boost your</p>
                    <p>home's value in today's market</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="johnsons-quadrants-logo-container-two">
            <img
              src="https://pma-coverphotos.s3.amazonaws.com/johnsons-quadrant-tile.png"
              alt="Johnsons Icons"
            />
          </div>
        </div>
        <div className="johnsons-testimonial-two">
          <div className="johnsons-testimonial-inner-two">
            <p>
              "I really can't say nice enough things about the work of Bri and
              Dianne Johnson. As a first time seller, I was a bit unsure going
              into the process, but they made the entire experience so easy.
              They were very responsive to any questions I had, maintained
              professional communication, and could not have been more kind. I
              was selling an investment property from afar and they handled
              everything in town wonderfully. They organized a beautiful staging
              of the home, listed with stunning marketing, and we received
              multiple offers within a few days. They helped facilitate a 14 day
              escrow flawlessly and I could not be happier! I highly recommend
              Brianna and Dianne to anyone looking to buy or sell their
              property."
            </p>
            <div className="johnsons-testimonial-two-signoff-container">
              <p>— Megan M</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default JohnsonsQuadrantsTwo;
