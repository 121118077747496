import React, {
  Component,
  Fragment,
  useState,
  useEffect,
  useContext,
} from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "../styles";
import styles2 from "./styles.scss";
import "./styles.scss";
import { Dashboard as DashboardLayout } from "layouts";
import { withSnackbar } from "notistack";
import { Portlet } from "components";
import { PortletContent } from "components";
import { PortletHeader } from "components";
import classNames from "classnames";
import { GetAreaPropHighlights } from "services/others";
import Base from "common/constants";
import {
  GetStateStorage,
  GetNavbarDataFromStorage,
  IsValidSessionSwitch,
} from "common/storage";

import moment from "moment";
import { GetPmaAdminDataAPI } from "services/agent";
import { showErrorNotification } from "common/helpers";
import { showSuccessNotification } from "common/helpers";
// import LinearProgress from "@mui/material/LinearProgress";
import { withRouter } from "react-router-dom";
import { Store } from "Store";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";
import { propertyUtil } from "views/Pma/PropertyUtil";

const FirstPage = (props) => {
  const {
    classes,
    selectedCheckbox,
    handleSelectedCheckbox,
    handleNext,
  } = props;
  const { dispatch } = useContext(Store);
  //session related variables
  const isAValidSessionSwitch = IsValidSessionSwitch();
  const { userData } = GetStateStorage();

  /**loading variables */
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="first-page">
      <div className="screen-number">1st Screen</div>
      <div className="checkbox-container">
        <div
          className="checkbox-row"
          onClick={() => handleSelectedCheckbox("existing")}
        >
          <input
            type="checkbox"
            id="existing-quadrant"
            name="existing-quadrant"
            checked={selectedCheckbox === "existing"}
          />
          <label for="existing-quadrant">
            This is an <strong>Existing</strong> Quadrant
          </label>
        </div>
        <div
          className="checkbox-row"
          onClick={() => handleSelectedCheckbox("new")}
        >
          <input
            type="checkbox"
            id="new-quadrant"
            name="new-quadrant"
            checked={selectedCheckbox === "new"}
          />
          <label for="new-quadrant">
            This is a <strong>New</strong> Quadrant
          </label>
        </div>
      </div>
      <div className="button-container">
        <div className="cancel-btn">
          <div>Cancel</div>
        </div>
        <div
          className={`next-btn ${selectedCheckbox && "highlighted-btn"}`}
          onClick={() => handleNext({ type: "quadrant", page: "first" })}
        >
          <div>Next</div>
        </div>
      </div>
    </div>
  );
};

FirstPage.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose(withSnackbar, withStyles(styles), withRouter)(FirstPage);
