import React, { Component, Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { showErrorNotification } from '../../../../common/helpers';
import { withStyles, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import {
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  Checkbox,
  Fab,
  IconButton,
  Paper,
  Grow,
  Slide,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControl,
  InputLabel,
  Input,
  MenuItem
} from '@material-ui/core';
import {
  Portlet,
  PortletContent,
  PortletFooter,
  PortletHeader,
  PortletLabel,
} from 'components';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import styles from './styles';
import { withSnackbar } from 'notistack';
import compose from 'recompose/compose';
import { ImageUploadDialog } from 'components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import MaskedInput from 'react-text-mask';
import { MediaViewComponent } from 'components';


const TextMaskCustom = React.memo((props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask={false}
      guide={true}
      keepCharPositions={false}
    />
  );
});

const TeamDetails = props => {
  const {
    classes,
    className,
    handleFieldChange,
    handleTeamFieldChange,
    validateForm,
    numOfTeamMembers,
    addTeamMember,
    addTeamMemberError,
    isMemberOfTeam,
    deleteTeamMember,
    isPartOfATeam,
    designationData
  } = props;

  const rootClassName = classNames(classes.root, className);
  const { values, touched, errors, isValid, isLoading, listgenUserID } = props;
  const [isTeamMember, setTeamMeber] = React.useState(values.isMemberOfTeam);
  /**method related to upload agent signature */
  const [openUploadImageDialog, setUploadImageDialog] = React.useState(false);
  const [imageType, setImageType] = React.useState('signature');
  const [currentImage, setCurrentImage] = React.useState(null);
  const [currentKey, setCurrentKey] = React.useState(0);
  /**Error handling */
  const showTeamNameError = touched.teamName && errors.teamName ? true : false;
  const showMemberOfATeamError = touched.isMemberOfTeam && errors.isMemberOfTeam;
  /**media view */
  const [mediaURL, setMediaURL] = React.useState(null);
  const [mediaTitle, setMediaTitle] = React.useState(null);
  const [openMediaView, setOpenMediaView] = React.useState(false);

  useEffect(() => {
    function handleStatusChange() {
      handleTeamFieldChange('firstName', values.firstName, 0);
    }
  });

  const getStateValue = (key, field) => {
    try {
      if (values.teamMembers[key][field]) {
        return values.teamMembers[key][field];
      } else {
        return '';
      }
    } catch (err) {
      return '';
    }
  };

  const getErrorState = (key, field) => {
    try {
      return (touched.teamMembers[key][field] && errors.teamMembers[key][field].length > 0)
        ? true
        : false;
    } catch (err) {
      return false;
    }
  };

  const getErrorStateValue = (key, field) => {
    try {
      return (touched.teamMembers[key][field] && errors.teamMembers[key][field].length > 0)
        ? (errors.teamMembers[key][field][0] ? errors.teamMembers[key][field][0] : errors.teamMembers[key][field])
        : '';
    } catch (err) {
      return '';
    }
  };


  const checkDetailsFillStatus = (key) => {
    try {
      if (typeof errors.teamMembers[key] === 'object' && errors.teamMembers[key] !== null && Object.keys(errors.teamMembers[key]).length > 0) {
        let isFilled = true;
        for (let xKey in errors.teamMembers[key]) {
          if (errors.teamMembers[key][xKey].length > 0) {
            isFilled = false;
          }
        }
        return isFilled;
      } else if (!errors.teamMembers[key]) {
        return true;
      } else {
        return true;
      }
    } catch (err) {
      return false;
    }
  };

  const openImageUplaodDialog = (imageType, currentImage, key) => {
    setImageType(imageType);
    setCurrentImage(currentImage);
    setUploadImageDialog(true);
    setCurrentKey(key);
  };

  const onDialogClose = () => {
    setImageType('profile');
    setCurrentImage(null);
    setUploadImageDialog(false);
    setCurrentKey(0);
  };

  const onDialogSubmit = (uploadedImageURL) => {
    handleTeamFieldChange(
      'agentSignature',
      uploadedImageURL,
      currentKey
    );
    setImageType('profile');
    setCurrentImage(null);
    setUploadImageDialog(false);
    setCurrentKey(0);
  };

  const openMediaViewDialog = (mediaURL, mediaTitle) => {
    setMediaURL(mediaURL);
    setMediaTitle(mediaTitle);
    setOpenMediaView(true);
  };


  const closeMediaViewDialog = () => {
    setMediaURL(null);
    setMediaTitle(null);
    setOpenMediaView(false);
  };

  return (
    <Fragment>
      {openMediaView ?
        <MediaViewComponent
          mediaURL={mediaURL}
          mediaTitle={mediaTitle}
          onOpen={openMediaViewDialog}
          onClose={closeMediaViewDialog}
        />
        : ''}
      <ImageUploadDialog
        openDialog={openUploadImageDialog}
        onSubmit={onDialogSubmit}
        onClose={onDialogClose}
        imageType={imageType}
        listgenUserID={listgenUserID}
        currentImage={currentImage}
        teamMode={true}
      />
      <Grid
        container
        className={classNames(classes.grid, classes.teamPadding)}
        spacing={4}
        justify="center"
      >
        <Grid item lg={4}>
          <Grow
            in={true}
            {...{ timeout: 200 }}
          >
            <Button
              variant={isTeamMember ? 'contained' : 'outlined'}
              color={isTeamMember ? 'primary' : 'default'}
              onClick={event => {
                handleFieldChange('isMemberOfTeam', true, event);
                setTeamMeber(true);
                isPartOfATeam();
              }}
            >
              Click Here if this is a team
          </Button>
          </Grow>
        </Grid>

        <Grid item lg={4}>
          <Grow
            in={true}
            {...{ timeout: 1200 }}
          >
            <Button
              variant={!isTeamMember ? 'contained' : 'outlined'}
              color={!isTeamMember ? 'primary' : 'default'}
              onClick={event => {
                props.notPartOfATeam();
                setTeamMeber(false);
              }
              }
            >
              Click if you are an individual agent
          </Button>
          </Grow>
        </Grid>

        <Grid item lg={6} />
      </Grid>
      {isTeamMember ? (
        <Fragment>
          <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <div>
              <Grid item lg={12}>
                <Typography
                  variant="subtitle1"
                  className={classNames(classes.teamPadding, classes.textCenter)}
                >
                  Please load each team member in the order you want them to appear
                  at the bottom of the COVER LETTER in the PMA.
                </Typography>
              </Grid>
              <Grid container className={classes.grid} spacing={0}>
                <Grid item lg={2} />
                <Grid item lg={4}>
                  <div className={classes.field}>
                    <TextField
                      className={classNames(classes.textField)}
                      label="Team Name"
                      autoFocus
                      margin="dense"
                      type="text"
                      required
                      name="teamName"
                      value={values.teamName}
                      error={showTeamNameError}
                      onChange={event => {
                        handleFieldChange('teamName', event.target.value, event);
                      }}
                    />
                    {showTeamNameError && (
                      <Typography className={classes.fieldError} variant="body2">
                        {errors.teamName[0]}
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid item lg={4}>
                  <div className={classes.teamPadding}>
                    <Typography variant="subtitle2">Note:</Typography>
                    <Typography variant="caption">
                      You can add upto 4 team members.
                </Typography>
                  </div>
                </Grid>
                <Grid item lg={2} />
              </Grid>

              {numOfTeamMembers.map((item, key) => {
                return (
                  <Fragment key={'fragment-' + key}>
                    <Grow in={true} {...{ timeout: 500 }}>
                      <ExpansionPanel defaultExpanded={key === 0 ? false : true}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={key === 0 ? classes.expansionPanelSummary : (
                          checkDetailsFillStatus(key) ? classes.expansionPanelSummary : ''
                        )}>
                          <div className={classes.expansionPanelHeader}>
                            <div className={classes.expansionPanelHeaderTeam}>
                              <Typography variant="subtitle2">
                                {"Team member #" + (key + 1)}
                              </Typography>
                            </div>
                            <div className={classes.autoFilled}>
                              {key === 0 ?
                                <Fragment>
                                  <IconButton>
                                    <CheckCircleOutline className={classes.green500} />
                                  </IconButton>
                                  <Typography variant="body2" className={classes.green500}>
                                    Details auto filled from previous data
                              </Typography>
                                </Fragment>
                                :
                                (checkDetailsFillStatus(key) ?
                                  <Fragment>
                                    <IconButton>
                                      <CheckCircleOutline className={classes.green500} />
                                    </IconButton>
                                    <Typography variant="body2" className={classes.green500}>
                                      All details filled
                              </Typography>
                                  </Fragment>
                                  : '')
                              }
                            </div>
                          </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                          <div className={classes.portletMargin}>
                            <Portlet
                              outlined={false}
                            >
                              <PortletContent noPadding>
                                <Grid container alignItems="center">
                                  <Grid item lg={11}>
                                    <Grid key={'Grid' + key} container className={classes.grid} spacing={0}>
                                      <Grid item lg={3}>
                                        <div className={classes.field}>
                                          <TextField
                                            className={classes.textField}
                                            label="First Name"
                                            margin="dense"
                                            type="text"
                                            required={true}
                                            inputProps={
                                              {
                                                readOnly: (key === 0 ? true : false)
                                              }
                                            }
                                            name="firstName"
                                            value={
                                              key === 0
                                                ? values.firstName
                                                : getStateValue(key, 'firstName')
                                            }
                                            error={getErrorState(key, 'firstName')}
                                            onChange={event => {
                                              handleTeamFieldChange(
                                                'firstName',
                                                event.target.value,
                                                key
                                              );
                                            }}
                                          />
                                          {getErrorState(key, 'firstName') && (
                                            <Typography className={classes.fieldError} variant="body2">
                                              {getErrorStateValue(key, 'firstName')}
                                            </Typography>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item lg={3}>
                                        <div className={classes.field}>
                                          <TextField
                                            className={classes.textField}
                                            label="Last Name"
                                            margin="dense"
                                            type="text"
                                            required={true}
                                            inputProps={
                                              {
                                                readOnly: (key === 0 ? true : false)
                                              }
                                            }
                                            name="lastName"
                                            value={
                                              key === 0
                                                ? values.lastName
                                                : getStateValue(key, 'lastName')
                                            }
                                            error={getErrorState(key, 'lastName')}
                                            onChange={event => {
                                              handleTeamFieldChange(
                                                'lastName',
                                                event.target.value,
                                                key
                                              );
                                            }}
                                          />
                                          {getErrorState(key, 'lastName') && (
                                            <Typography className={classes.fieldError} variant="body2">
                                              {getErrorStateValue(key, 'lastName')}
                                            </Typography>
                                          )}
                                        </div>
                                      </Grid>

                                      <Grid item lg={4}>
                                        <div className={classes.field}>
                                          <TextField
                                            id={'select-agent-designation' + key}
                                            select
                                            label="Select Your Designation"
                                            className={classes.textField}
                                            value={
                                              key === 0
                                                ? values.agentDesignation
                                                : getStateValue(key, 'agentDesignation')
                                            }
                                            required
                                            error={getErrorState(key, 'agentDesignation')}
                                            onChange={event => {
                                              handleTeamFieldChange(
                                                'agentDesignation',
                                                event.target.value,
                                                key
                                              );
                                            }}
                                            SelectProps={{
                                              MenuProps: {
                                                className: classes.menu,
                                              },
                                            }}
                                            inputProps={
                                              {
                                                readOnly: (key === 0 ? true : false)
                                              }
                                            }
                                            margin="dense"
                                          >
                                            {designationData.map(option => (
                                              <MenuItem key={option.listgenDesignationID} value={option.listgenDesignationName}>
                                                {option.listgenDesignationName}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                          {getErrorState(key, 'agentDesignation') && (
                                            <Typography className={classes.fieldError} variant="body2">
                                              {getErrorStateValue(key, 'agentDesignation')}
                                            </Typography>
                                          )}
                                        </div>
                                      </Grid>

                                      <Grid item lg={2} />
                                      <Grid item lg={3}>
                                        <div className={classes.field}>
                                          <FormControl className={classNames(classes.formControl, classes.dropdownTopMargin)}>
                                            <InputLabel required={true} htmlFor={"formatted-text-mask-input-mobile-phone" + key}>Phone</InputLabel>
                                            <Input
                                              value={
                                                key === 0
                                                  ? values.mobilePhone
                                                  : getStateValue(key, 'phone')
                                              }
                                              name="phone"
                                              error={getErrorState(key, 'phone')}
                                              className={classNames(
                                                classes.textField
                                              )}
                                              inputProps={
                                                {
                                                  readOnly: (key === 0 ? true : false)
                                                }
                                              }
                                              onChange={
                                                event => {
                                                  handleTeamFieldChange(
                                                    'phone',
                                                    event.target.value,
                                                    key
                                                  );
                                                }
                                              }
                                              required={true}
                                              id={"formatted-text-mask-input-mobile-phone" + key}
                                              inputComponent={TextMaskCustom}
                                            />
                                          </FormControl>
                                          {getErrorState(key, 'phone') && (
                                            <Typography className={classes.fieldError} variant="body2">
                                              {getErrorStateValue(key, 'phone')}
                                            </Typography>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item lg={4}>
                                        <div className={classes.field}>
                                          <TextField
                                            className={classes.textField}
                                            label="Email"
                                            margin="dense"
                                            type="text"
                                            required={true}
                                            inputProps={
                                              {
                                                readOnly: (key === 0 ? true : false)
                                              }
                                            }
                                            name="email"
                                            value={
                                              key === 0
                                                ? values.emailAddress
                                                : getStateValue(key, 'email')
                                            }
                                            error={getErrorState(key, 'email')}
                                            onChange={event => {
                                              handleTeamFieldChange(
                                                'email',
                                                event.target.value,
                                                key
                                              );
                                            }}
                                          />
                                          {getErrorState(key, 'email') && (
                                            <Typography className={classes.fieldError} variant="body2">
                                              {getErrorStateValue(key, 'email')}
                                            </Typography>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item lg={3}>
                                        <div className={classes.field}>
                                          <TextField
                                            className={classes.textField}
                                            label="Agent DRE License #"
                                            margin="dense"
                                            type="text"
                                            required={true}
                                            inputProps={
                                              {
                                                readOnly: (key === 0 ? true : false)
                                              }
                                            }
                                            name="agentLicenseNumber"
                                            value={
                                              key === 0
                                                ? values.agentLicenseNumber
                                                : getStateValue(key, 'agentLicenseNumber')
                                            }
                                            error={getErrorState(key, 'agentLicenseNumber')}
                                            onChange={event => {
                                              handleTeamFieldChange(
                                                'agentLicenseNumber',
                                                event.target.value,
                                                key
                                              );
                                            }}
                                          />
                                          {getErrorState(key, 'agentLicenseNumber') && (
                                            <Typography className={classes.fieldError} variant="body2">
                                              {getErrorStateValue(key, 'agentLicenseNumber')}
                                            </Typography>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid
                                        key={'Grid' + key + 'secondLine'}
                                        container
                                        className={classNames(
                                          classes.grid,
                                          classes.paperPaddingBottom
                                        )}
                                        spacing={0}
                                      >
                                        <Grid item lg={3}>
                                          <div className={classes.field}>
                                            <TextField
                                              className={classes.textField}
                                              label="Mailing Address"
                                              helperText={
                                                <Fragment>
                                                  This is the address your personal PMA will be mailed to.
                                              Preferably your <strong>HOME ADDRESS</strong>, not your office address.
                                            </Fragment>
                                              }
                                              margin="dense"
                                              type="text"
                                              required={true}
                                              inputProps={
                                                {
                                                  readOnly: (key === 0 ? true : false)
                                                }
                                              }
                                              name="mailingAddress1"
                                              value={
                                                key === 0
                                                  ? values.mailingAddressLine1
                                                  : getStateValue(key, 'mailingAddress1')
                                              }
                                              error={getErrorState(key, 'mailingAddress1')}
                                              onChange={event => {
                                                handleTeamFieldChange(
                                                  'mailingAddress1',
                                                  event.target.value,
                                                  key
                                                );
                                              }}
                                            />
                                            {getErrorState(key, 'mailingAddress1') && (
                                              <Typography className={classes.fieldError} variant="body2">
                                                {getErrorStateValue(key, 'mailingAddress1')}
                                              </Typography>
                                            )}
                                          </div>
                                        </Grid>

                                        {/* <Grid item lg={3}>
                                          <div className={classes.field}>
                                            <TextField
                                              className={classes.textField}
                                              label="Mailing Address 2"
                                              margin="dense"
                                              type="text"
                                              required={false}
                                              inputProps={
                                                {
                                                  readOnly: (key === 0 ? true : false)
                                                }
                                              }
                                              name="mailingAddress2"
                                              value={
                                                key === 0
                                                  ? values.mailingAddressLine2
                                                  : getStateValue(key, 'mailingAddress2')
                                              }
                                              error={getErrorState(key, 'mailingAddress2')}
                                              onChange={event => {
                                                handleTeamFieldChange(
                                                  'mailingAddress2',
                                                  event.target.value,
                                                  key
                                                );
                                              }}
                                            />
                                            {getErrorState(key, 'mailingAddress2') && (
                                              <Typography className={classes.fieldError} variant="body2">
                                                {getErrorStateValue(key, 'mailingAddress2')}
                                              </Typography>
                                            )}
                                          </div>
                                        </Grid> */}

                                        <Grid item lg={3}>
                                          <div className={classes.field}>
                                            <TextField
                                              className={classes.textField}
                                              label="City"
                                              margin="dense"
                                              type="text"
                                              required={true}
                                              inputProps={
                                                {
                                                  readOnly: (key === 0 ? true : false)
                                                }
                                              }
                                              name="city"
                                              value={
                                                key === 0
                                                  ? values.city
                                                  : getStateValue(key, 'city')
                                              }
                                              error={getErrorState(key, 'city')}
                                              onChange={event => {
                                                handleTeamFieldChange(
                                                  'city',
                                                  event.target.value,
                                                  key
                                                );
                                              }}
                                            />
                                            {getErrorState(key, 'city') && (
                                              <Typography className={classes.fieldError} variant="body2">
                                                {getErrorStateValue(key, 'city')}
                                              </Typography>
                                            )}
                                          </div>
                                        </Grid>

                                        <Grid item lg={2}>
                                          <div className={classes.field}>
                                            <TextField
                                              className={classes.textField}
                                              label="State"
                                              margin="dense"
                                              type="text"
                                              required={true}
                                              inputProps={
                                                {
                                                  readOnly: (key === 0 ? true : false),
                                                  maxLength: 2
                                                }
                                              }
                                              name="state"
                                              value={
                                                key === 0
                                                  ? values.state.toUpperCase()
                                                  : getStateValue(key, 'state').toUpperCase()
                                              }
                                              error={getErrorState(key, 'state')}
                                              onChange={event => {
                                                handleTeamFieldChange(
                                                  'state',
                                                  event.target.value.toUpperCase(),
                                                  key
                                                );
                                              }}
                                            />
                                            {getErrorState(key, 'state') && (
                                              <Typography className={classes.fieldError} variant="body2">
                                                {getErrorStateValue(key, 'state')}
                                              </Typography>
                                            )}
                                          </div>
                                        </Grid>

                                        <Grid item lg={2}>
                                          <div className={classes.field}>
                                            <TextField
                                              className={classes.textField}
                                              label="Zipcode"
                                              margin="dense"
                                              type="text"
                                              required={true}
                                              inputProps={
                                                {
                                                  readOnly: (key === 0 ? true : false),
                                                  maxLength: 5
                                                }
                                              }
                                              name="zipcode"
                                              value={
                                                key === 0
                                                  ? values.zipcode
                                                  : getStateValue(key, 'zipcode')
                                              }
                                              error={getErrorState(key, 'zipcode')}
                                              onChange={event => {
                                                handleTeamFieldChange(
                                                  'zipcode',
                                                  event.target.value,
                                                  key
                                                );
                                              }}
                                            />
                                            {getErrorState(key, 'zipcode') && (
                                              <Typography className={classes.fieldError} variant="body2">
                                                {getErrorStateValue(key, 'zipcode')}
                                              </Typography>
                                            )}
                                          </div>
                                        </Grid>

                                        <Grid item lg={1}></Grid>      
                                      </Grid>
                                      {/* Only show upload button from second team member */}
{/*                                       
                                      <Grid
                                        key={'Grid' + key + 'thirdLine'}
                                        container
                                        className={classNames(
                                          classes.grid,
                                          classes.spaceAround
                                        )}
                                        spacing={0}
                                      >
                                        <Grid item lg={12}>
                                          <Typography className={classes.title} variant="subtitle2">
                                            Upload{getStateValue(key, 'agentSignature') ? 'ed' : ''} Signature
          </Typography>
                                          <Divider />
                                        </Grid>

                                        <Grid item lg={4} md={4} sm={4} xs={4}>
                                          <div className={classes.imageHolderWrapper}>
                                            {getStateValue(key, 'agentSignature') ? (
                                              <div className={classes.imageHolder}>
                                                <img
                                                  src={getStateValue(key, 'agentSignature')}
                                                  alt={"Agent Signature"}
                                                  className={classes.imageTag}
                                                  onClick={event => openMediaViewDialog(getStateValue(key, 'agentSignature'), 'Agent Signature')}
                                                />
                                              </div>
                                            ) : ''}
                                            {key !== 0 ?
                                              <div className={classes.fieldSection}>
                                                <Button
                                                  key={'agent-signature-button-' + key}
                                                  onClick={event => openImageUplaodDialog('signature', getStateValue(key, 'agentSignature'), key)}
                                                  variant="contained"
                                                  component="span"
                                                  className={classNames(
                                                    classes.uploadButton
                                                  )}
                                                >
                                                  Upload Agent Signature
                </Button>
                                              </div>
                                              : ''}
                                          </div>
                                        </Grid>
                                      
                                      </Grid>
                                     */}
                                    </Grid>
                                  </Grid>
                                  {/* only put the delete icon for the 2nd team member and above because the first team member will always be the agent that is currently registering */}
                                  {key !== 0 && (
                                    <Grid item lg={1}>
                                      <Tooltip title="Delete Team Member">
                                        <IconButton
                                          aria-label="Delete Team Member"
                                          size="medium"
                                          color="primary"
                                          onClick={() => deleteTeamMember(key)}
                                        >
                                          <DeleteForeverIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  )}
                                </Grid>
                              </PortletContent>
                            </Portlet>
                          </div>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grow>
                  </Fragment>
                );
              })}

              <Grid container justify="center">
                {(Array.isArray(numOfTeamMembers) && numOfTeamMembers.length !== 4) ?
                  <Button
                    variant="outlined"
                    className={classNames(classes.addAnotherTeamMember)}
                    onClick={addTeamMember}
                    color="primary">
                    ADD ANOTHER TEAM MEMBER
                  </Button>
                  :
                  <Typography variant="subtitle2" color="primary" className={classes.dropdownTopMargin1UnitPlus}>
                    You may not have more than 4 members on a team
            </Typography>
                }
              </Grid>
            </div>
          </Slide>
        </Fragment>
      ) : (
          ''
        )}
    </Fragment>
  );
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles)
)(TeamDetails);
