import React, { Component, Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Input,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from "@material-ui/core";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "./styles.scss";
import { withSnackbar } from "notistack";
import classNames from "classnames";
import ClearIcon from "@material-ui/icons/Clear";
import { showSuccessNotification } from "common/helpers";
import { showErrorNotification } from "common/helpers";
import { AddUpdatePmaAdmin } from "services/agent";

import { propertyUtil } from "views/Pma/PropertyUtil";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditQuadrantModal = (props) => {
  const {
    editQuadrantFormData,
    oldQuadrantData,
    handleInputChange,

    onCloseFn,
    modalType,
    areaID,
    onEditFormSubmit,
  } = props;
  console.log("*edit quadrant props", props);

  let maxLength;
  if (modalType === "title") {
    maxLength = 16;
  } else {
    maxLength = 30;
  }

  let overMaxLength =
    editQuadrantFormData[modalType] &&
    editQuadrantFormData[modalType].length &&
    editQuadrantFormData[modalType].length > maxLength;
  console.log("maxLength", maxLength);

  return (
    <Fragment>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth={true}
        TransitionComponent={Transition}
      >
        <div className="edit-quadrant-container">
          <div className="edit-quadrant-title-container">
            <p className="edit-quadrant-title">Change Quadrant Details</p>
            <div className="edit-quadrant-title-divider" />
            <div className="edit-quadrant-subtitle-text">
              You can change Quadrant details by clicking the{" "}
              <div className="subtitle-edit-button"> edit</div> button under
              each field
            </div>
          </div>
          <div className="edit-quadrant-form old-props">
            <div className="edit-quadrant-form-title-container">
              <p className="edit-quadrant-form-title-text">
                Current Quadrant Details{" "}
              </p>
              <div className="edit-quadrant-form-title-divider"></div>
            </div>
            <div className="edit-quadrant-form-body">
              <div className="edit-form-body-col">
                <div className="edit-form-thead">
                  <div>
                    <p>Title</p>
                  </div>
                </div>
                <div className="edit-form-input">
                  <div>
                    <div>{oldQuadrantData.title && oldQuadrantData.title}</div>
                  </div>
                </div>
              </div>
              <div className="edit-form-body-col">
                <div className="edit-form-thead">
                  <div>
                    <p>Text</p>
                  </div>
                </div>
                <div className="edit-form-input">
                  <div>
                    <div>{oldQuadrantData.text && oldQuadrantData.text}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="edit-quadrant-form new-props">
            <div className="edit-quadrant-form-title-container">
              <p className="edit-quadrant-form-title-text">
                Make Changes to the Title and Text Here
              </p>
              <div className="edit-quadrant-form-title-divider"></div>
            </div>
            <div className="edit-quadrant-form-body">
              <div className="edit-form-body-col">
                <div className="edit-form-thead">
                  <div>
                    <p>Title</p>
                  </div>
                </div>
                <div className="edit-form-input">
                  <div className="address-input-col">
                    <input
                      type="text"
                      id="title"
                      name="title"
                      value={editQuadrantFormData.title || ""}
                      onChange={handleInputChange}
                      placeholder={editQuadrantFormData.title}
                    ></input>
                    <label
                      htmlFor="propertyHighlightsTitle"
                      className={overMaxLength ? "character-count-error" : ""}
                    >
                      Character count:{" "}
                      {editQuadrantFormData.title &&
                      editQuadrantFormData.title.length
                        ? editQuadrantFormData.title.length
                        : "0"}
                      /{maxLength}
                    </label>
                  </div>
                </div>
              </div>

              <div className="edit-form-body-col">
                <div className="edit-form-thead">
                  <div>
                    <p>Text</p>
                  </div>
                </div>
                <div className="edit-form-input">
                  <div className="address-input-col">
                    <input
                      type="text"
                      id="text"
                      name="text"
                      value={editQuadrantFormData.text || ""}
                      onChange={handleInputChange}
                      placeholder={editQuadrantFormData.text}
                    ></input>
                    <label
                      htmlFor="propertyHighlightsTitle"
                      className={overMaxLength ? "character-count-error" : ""}
                    >
                      Character count:{" "}
                      {editQuadrantFormData.text &&
                      editQuadrantFormData.text.length
                        ? editQuadrantFormData.text.length
                        : "0"}
                      /{maxLength}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="edit-form-button-container">
            <div className="edit-form-cancel-button" onClick={onCloseFn}>
              <p>Cancel</p>
            </div>
            <div className="edit-form-submit-button" onClick={onEditFormSubmit}>
              <p>Submit</p>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default compose(withSnackbar, withStyles(styles))(EditQuadrantModal);
