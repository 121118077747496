export default {
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 8,
      maximum: 128
    }
  },
  confirmPassword: {
    presence: { allowEmpty: false, message: 'is required' },
    equality: {
      attribute: "password",
      message: "is not matching",
      comparator: function(v1, v2) {
        return v1 === v2;
      }
    },
    length: {
      minimum: 8,
      maximum: 128
    }
  },
};
