import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import palette from 'theme/palette';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    headerMain: {
        padding: 0,
        margin: 0,
        width: "100%",
        fontFamily: "objektiv-mk2 !important",
        backgroundColor: `${palette.primary.currentMailProColor} !important`,
        minHeight: '64px',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white !important'
    },
});

export const MailProDialogHeader = ({ children, ...rest }) => {
    const classes = useStyles();
    return <DialogTitle className={classes.headerMain}  {...rest}>{children}</DialogTitle>
}


MailProDialogHeader.propTypes = {
    children: PropTypes.node.isRequired,
};