import React from "react";
import { connect } from "react-redux";
import PmaEditor from "./PMAEditor";
//import './index.scss';
import { GetAgentDetail } from "services/agent";
import { GetOAuthTokenByKey } from "common/storage";
import axios from "common/interceptors";
import { propertyUtil } from "views/Pma/PropertyUtil";

class PrintBubbleCompare extends React.Component {
  state = {
    singleHOData: null,
    agentData: null,
    mlsAreas: null,
    marketActivity: null,
    MQCityDetails: null,
    currentArea: null,
    localSellingAgents: null,
    bubbleBoxes: [],
    // version: "year-end-2022",
    version: "bubble-compare",
    coverPageDetails: null,
    quarterlyAreaTable: null,
    testimonials: null,
    agentDesignations: null,
    agentAdvantages: null,
    salePriceForCities: null,
    yearEnd: null,
    qArea: null,
    qCity: null,
    missionStatement: null,
    aprilData: null,
    flipBook: false,
  };

  async componentDidMount() {
    let mailProId = this.props.match.params.mailproid;
    let listgenUserID = this.props.match.params.listgenUserID;
    let brokerage = this.props.match.params.brokerage;
    //console.log(this.props.match.params);

    const { version } = this.state;
    let url = window.location.href;

    if (url.includes("test-print")) {
      let split = url.split("/");
      listgenUserID = url[url.length - 2];
      let area = url[url.length - 1];
    }

    let date = new Date();
    let thisYear = date.getFullYear();
    const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
    const today = `${date.getDate()}`.padStart(2, 0);

    let isDemo = false;
    // if (
    //   thisArea === "DEMO-SCL-16A" ||
    //   thisArea === "DEMO-SCL-16B" ||
    //   thisArea === "DEMO-SCL-16D" ||
    //   thisArea === "DEMO-SCL-16E" ||
    //   thisArea === "DEMO-SCL-16N"
    // ) {
    //   isDemo = false;
    // }
    let diffVersionToUse;
    let usesDifferentVersion = false;
    try {
      let mailPrint = await axios
        .get(
          `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
          {
            headers: {
              "content-type": "application/json",
            },
            data: {},
          }
        )
        .then((res) => {
          //  console.log("mailprint res", res.data);
          const singleHOData = res.data.mailerProDTO;
          //  const agentData = res.data.listgenUserDTO;
          this.setState({
            singleHOData,
            //agentData,
          });
        })
        .catch((err) => console.log("error retrieving idDetails" + err));

      let navBar = await axios
        .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
          headers: {
            "content-type": "application/json",
          },
          data: {},
        })

        .then((res) => {
          //console.log("navbar res", res.data);
          this.setState({
            mlsAreas: res.data,
          });
        })
        .catch((err) => console.log("error retrieving navbarInfo" + err));
      const thisArea = this.state.singleHOData.areaID;
      let currentArea =
        this.state.mlsAreas.filter((area) => area.mlsAreaID === thisArea)[0] ||
        this.state.mlsAreas[0];
      const { mlsAreaID, mlsCityID } = currentArea;
      //  console.log("159", mlsAreaID);
      const { singleHOData } = this.state;
      const { subdivisionName } = singleHOData;
      if (currentArea) {
        //  console.log("inside currentArea", currentArea);
        let getPmaDetails = await axios
          .get(
            `/agent/getPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
              subdivisionName ? subdivisionName : "-"
            }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,

            {
              headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
              data: {},
            }
          )
          .then((res) => {
            //  console.log("inside speedometer res.data");
            const {
              mailerPrintInfoDTO,
              lgTestmonialsDTO,
              missionStatementDTO,
            } = res.data;
            const singleHOData = mailerPrintInfoDTO.mailerProDTO;
            let agentData = mailerPrintInfoDTO.listgenUserDTO;
            let boxes = res.data.marketValueInfoList;
            const { formatDemoBrokerage, formatDemoEmail } = propertyUtil;
            let isDemo = false;
            let coverPhotoDetails = res.data.pmaCoverPhotoDTO;
            if (
              listgenUserID === "103" ||
              listgenUserID === "105" ||
              listgenUserID === "101"
            ) {
              agentData.brokerageName = formatDemoBrokerage(brokerage);
              agentData.emailAddress = formatDemoEmail(brokerage);
              isDemo = true;
              boxes[0].mktValAreaID = currentArea.mlsAreaID;
              boxes[0].mktValAreaTitle = "EXPERTS WEIGH IN:";
              boxes[0].mktValSubTitle = "Continued Strength for Sellers";
              let demoMarketValue = res.data.marketValueInfoList;
              demoMarketValue[0].mktValAreaID = currentArea.mlsAreaID;
              coverPhotoDetails.coverPhoto =
                "https://pma-coverphotos.s3.amazonaws.com/BeachFire_April2023_Lepere_2.jpg";
              // currentArea.mlsNeighborhood = "Pruneyard";
            }

            this.setState({
              singleHOData,
              agentData,
              currentArea,
              coverPageDetails: coverPhotoDetails,
              quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
              quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
              testimonials: lgTestmonialsDTO,
              aprilData: {
                pmaQuarterlyAreaDTO: res.data.pmaQuarterlyAreaDTO,
                pmaQuarterlyCityDTO: res.data.pmaQuarterlyCityDTO,
              },
              bubbleBoxes: boxes,
              testimonials: res.data.lgTestmonialsDTO,
              missionStatement: missionStatementDTO,
              isDemo: isDemo,
            });
          })
          .catch((err) =>
            console.log("error retrieving table bubble data" + err)
          );

        let getPmaDetails2 = await axios
          .get(
            `/agent/getPMADetails?areaCityPeriod=SBA-10F-5060-2023-11-06&subDivision=-&listgenUserID=100778&mailerProID=74709436-c8d5-4270-b9e3-86012d4d7389dup`,

            {
              headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
              data: {},
            }
          )
          .then((res) => {
            let demoMarketValue = res.data.marketValueInfoList;
            demoMarketValue[0].mktValAreaID = currentArea.mlsAreaID;

            this.setState({
              bubbleBoxes: res.data.marketValueInfoList,
            });
          })
          .catch((err) =>
            console.log("error retrieving table bubble data" + err)
          );
      }
    } catch (error) {
      console.log("error with bubble compare print apis", error);
    }
  }

  render() {
    const { propertyData } = this.props;
    const {
      singleHOData,
      agentData,
      mlsAreas,
      marketActivity,
      MQCityDetails,
      currentArea,
      version,
      quarterlyCityTable,
      quarterlyAreaTable,
      localSellingAgents,
      coverPageDetails,
      testimonials,
      agentDesignations,
      agentAdvantages,
      salePriceForCities,
      yearEnd,
      qArea,
      qCity,
      aprilData,
      flipBook,
      missionStatement,
      propertyPhotoDetailsDTO,
      pmaQuarterlyCombinedCityDTO,
      quadrantDetails,
      bubbleBoxes,
      isDemo,
    } = this.state;

    //below, show the main data points that we need so we can make sure the page doesnt show until the data is there. You may need to edit ye2 for September's api by putting the variables we will be using in ye2
    if (
      version === "bubble-compare" &&
      currentArea &&
      singleHOData &&
      agentData &&
      bubbleBoxes &&
      Object.keys(bubbleBoxes).length >= 1

      // &&
      //   currentArea &&
      //   singleHOData &&
      //   agentData &&
      //   quarterlyAreaTable &&
      //   quarterlyCityTable &&
      //   coverPageDetails &&
      //   quadrantDetails &&
      //   quadrantDetails.length
    ) {
      return (
        <div className="pma-creator">
          <main>
            <section>
              <PmaEditor
                propertyData={propertyData}
                printVersion={true}
                version={version}
                singleHOData={singleHOData}
                agentData={agentData}
                currentArea={currentArea}
                marketActivity={marketActivity}
                MQCityDetails={MQCityDetails}
                mlsAreas={mlsAreas}
                quarterlyAreaTable={quarterlyAreaTable}
                quarterlyCityTable={quarterlyCityTable}
                localSellingAgents={localSellingAgents}
                coverPageDetails={coverPageDetails}
                printTestimonials={testimonials}
                printAgentAdvantages={agentAdvantages}
                printAgentDesignations={agentDesignations}
                salePriceForCities={salePriceForCities}
                yearEnd={yearEnd}
                qArea={qArea}
                qCity={qCity}
                aprilData={aprilData}
                flipBook={flipBook}
                dontShowPma={false}
                missionStatement={missionStatement}
                propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
                pmaQuarterlyCombinedCityDTO={pmaQuarterlyCombinedCityDTO}
                quadrantDetails={quadrantDetails}
                bubbleBoxes={bubbleBoxes}
                isDemo={isDemo}
              />
            </section>
          </main>
        </div>
      );
    } else {
      return <div>Loading PMA...</div>;
    }
  }
}

export default PrintBubbleCompare;
