export const MainSchema = {
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64,
    },
  },
  emailAddress: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
  agentDesignation: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  agentLicenseNumber: {
    presence: {
      allowEmpty: false,
      message: 'is required',
    },
    numericality: {
      onlyInteger: false,
      greaterThan: 0,
    },
    length: {
      is: 8,
    },
  },
  brokerLicenseNumber: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      onlyInteger: false,
      greaterThan: 0,
    },
    length: {
      is: 8,
    },
  },
  mobilePhone: {
    presence: {
      allowEmpty: false,
      message: 'is required',
    },
    length: {
      minimum: 6
    },
    numericality: {
      onlyInteger: true,
      message: 'is not valid',
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },

    length: {
      minimum: 8,
      tooShort: 'should have a minimum of 8 characters',
    },
  },
  confirmPassword: {
    presence: { allowEmpty: false, message: 'is required' },
    equality: {
      attribute: 'password',
      message: 'is not matching',
      comparator: function (v1, v2) {
        return v1 === v2;
      },
    },
    length: {
      minimum: 8,
      maximum: 128,
    },
  },
  // website: {
  //   presence: { allowEmpty: false, message: 'is required' },
  // },
  // tagline: {
  //   presence: {
  //     allowEmpty: false,
  //     message: 'is required',
  //   },
  //   length: {
  //     maximum: 105,
  //     message: 'cannot exceed 75 characters',
  //   },
  // },
  // agentPhoto: {
  //   presence: { allowEmpty: false, message: 'is required' },
  // },
  // agentPhoto2: {
  //   presence: { allowEmpty: false, message: 'is required' },
  // },
  // agentLogo: {
  //   presence: { allowEmpty: false, message: 'is required' },
  // },
  // agnetSignature: {
  //   presence: { allowEmpty: false, message: 'is required' },
  // },
  teamName: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  mailingAddressLine1: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  mailingAddressLine2: {
    presence: { allowEmpty: true, message: 'is required' },
  },
  city: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  state: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      is: 2,
      message: 'length can have a maximum of 2 characters',
    },
  },
  zipcode: {
    presence: {
      allowEmpty: false,
      message: 'is required',
    },
    // numericality: {
    //   onlyInteger: false,
    //   message: 'must be a number',
    // },
    // length: {
    //   is: 5,
    //   message: 'length is invalid',
    // },
  },
  billingFirstName: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  billingLastName: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  billingAddressLine1: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  billingAddressLine2: {
    presence: { allowEmpty: true, message: 'is required' },
  },
  billingCity: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  billingState: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  billingZipcode: {
    presence: {
      allowEmpty: false,
      message: 'is required',
    },
    // numericality: {
    //   onlyInteger: true,
    //   message: 'must be a number',
    // },
    // length: {
    //   is: 5,
    //   message: 'length is invalid',
    // },
  },
  // officeState: {
  //   presence: { allowEmpty: true, message: 'is required' },
  //   length: {
  //     is: 2,
  //     message: 'length can have a maximum of 2 characters',
  //   },
  // },
  // officeZipcode: {
  //   presence: {
  //     allowEmpty: true,
  //     message: 'is required',
  //   },
  //   numericality: {
  //     onlyInteger: true,
  //     message: 'must be a number',
  //   },
  //   length: {
  //     is: 5,
  //     message: 'length is invalid',
  //   },
  // },
  autoPayAccepted: {
    presence: {
      allowEmpty: false,
      message: 'is required',
    },
    inclusion: [true]
  },
  nameOnCard: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64,
    },
  },
  cardNumber: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      onlyInteger: false,
      greaterThan: 0,
    },
    length: {
      minimum: 13,
      maximum: 24
    },
  },
  expiryMonth: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      onlyInteger: false,
      greaterThan: 0,
    },
    length: {
      is: 2,
    },
  },
  expiryYear: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      onlyInteger: false,
      greaterThan: 0,
    },
    length: {
      is: 4,
    },
  },
  cvc: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      onlyInteger: false,
      greaterThan: 0,
    },
    length: {
      minimum: 3,
      maximum: 4
    },
  },
};

export const TeamMemberSchema = {
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64,
    },
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
  phone: {
    presence: {
      allowEmpty: false,
      message: 'is required',
    },
    length: {
      minimum: 6
    },
    numericality: {
      onlyInteger: true,
      message: 'is not valid',
    },
  },
  mailingAddress1: {
    presence: { allowEmpty: false, message: 'is required' },
    email: false,
    length: {
      maximum: 300,
    },
  },
  mailingAddress2: {
    presence: { allowEmpty: true, message: 'is required' },
    length: {
      maximum: 300,
    },
  },
  agentLicenseNumber: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      onlyInteger: false
    },
    length: {
      is: 8,
    },
  },
  city: {
    presence: { allowEmpty: false, message: 'is required' },
    email: false,
    length: {
      maximum: 20,
    },
  },
  state: {
    presence: { allowEmpty: false, message: 'is required' },
    email: false,
    length: {
      maximum: 2,
    },
  },
  zipcode: {
    presence: {
      allowEmpty: false,
      message: 'is required',
    },
    // numericality: {
    //   onlyInteger: true,
    //   message: 'must be a number',
    // },
    // length: {
    //   is: 5,
    //   message: 'length is invalid',
    // },
  },
  agentDesignation: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  // agentSignature: {
  //   presence: { allowEmpty: false, message: 'is required' },
  // },
};

