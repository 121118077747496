export default theme => ({
    root: {
        width: 'auto',
        height: 'auto'
    },
    grid: {
        height: '100%'
    },
    padding4Unit: {
        padding: theme.spacing(4)
    },
    leftAlign: {
        textAlign: 'left'
    },
    card: {
        maxWidth: '100%',
        boxShadow: 'none',
        display: 'flex',
        height: 300,
    },
    media: {
        height: 300,
        width: '100%'
    },
    cardContentHeight: {
        height: 230,
        overflowY: 'auto',
        marginBottom: theme.spacing(2)
    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    cardContent: {
        flex: '1 1 100%',
    },
});