import React, { Component, useState, useEffect, Fragment } from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import styles from "./styles";
//
import { withRouter, useLocation } from "react-router-dom";
import classNames from "classnames";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withStyles, Grid, Divider, CircularProgress } from "@material-ui/core";
import Base from "common/constants";
import {
  Badge,
  IconButton,
  Popover,
  Toolbar,
  Typography,
  Drawer,
  TextField,
  Button,
} from "@material-ui/core";
import { LockSharp as LockSharpIcon } from "@material-ui/icons";
import HelpPrimaryDropdwonData from "./HelpDropDownData.json";
import HelpSubmitForm from "./component/HelpSubmitForm";
import ClearIcon from "@material-ui/icons/Clear";

const DrawerModeHelpScreen = (props) => {
  //const { state, dispatch } = React.useContext(Store);
  const [openHelpRequest, setHelpRequest] = useState(false);
  /*classes */
  const { classes, DefaultComponent, ...leftProps } = props;

  const handleOpenHelpRequestScreen = (event) => {
    setHelpRequest(true);
  };
  const handleCloseHelpRequestScreen = () => {
    setHelpRequest(false);
  };

  return (
    <Fragment>
      <DefaultComponent
        clickFn={(event) => handleOpenHelpRequestScreen()}
        {...leftProps}
      />
      {/* <Suspense fallback={<Typography variant="subtitle2">Loading...</Typography>}> */}
      <Drawer
        anchor={"right"}
        open={openHelpRequest}
        onClose={handleCloseHelpRequestScreen}
        transitionDuration={500}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "absolute",
          }}
        >
          {/* <div>{mediaTitle}</div> */}
          <div></div>
          {/* display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-start', */}
          <div
            style={{ marginTop: "8px", marginRight: "8px" }}
            onClick={handleCloseHelpRequestScreen}
          >
            <Button
              variant="contained"
              size="small"
              style={{ color: "white", textAlign: "center" }}
              className={classNames(classes.redBg900, classes.closeredButton)}
              title="Close Help Dialog"
            >
              <ClearIcon className={classes.whiteColor} />
            </Button>
          </div>
        </div>
        <div style={{ width: "40vw", padding: "16px 24px 32px 24px" }}>
          <HelpSubmitForm
            history={props.history}
            shortDialogMode={true}
            handleCloseHelpRequestDialog={handleCloseHelpRequestScreen}
            accessedFromAreaID={props.accessedFromAreaID}
          />
        </div>
      </Drawer>
      {/* </Suspense> */}
    </Fragment>
  );
};

DrawerModeHelpScreen.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  DefaultComponent: PropTypes.func,
};

//<DefaultComponent variant="contained" onClick={event => handleOpenHelpRequestScreen()}>{buttonText}</DefaultComponent>
DrawerModeHelpScreen.defaultProps = {
  text: "Support",
  size: "small",
  DefaultComponent: ({ text, clickFn, size }) => (
    <Button variant="contained" size={size} onClick={clickFn}>
      {text}
    </Button>
  ),
};

export default compose(withRouter, withStyles(styles))(DrawerModeHelpScreen);
