import React, { Component } from "react";

class GreetingsPS extends Component {
  render() {
    const {
      theHomeowners,
      hoTextPS,
      isMemberOfTeam,
      teamMembers,
      agentFirstNames,
      listgenUserFirstName,
      mailingNum,
    } = this.props;

    // console.log("greetingsPS", this.props);
    // console.log(theHomeowners.length);
    let smallerHOText = false;
    if (theHomeowners.length > 11) {
      smallerHOText = true;
    }
    return (
      <div
        className={mailingNum ? `${mailingNum}-greetings-ps` : "greetings-ps"}
      >
        <div className="greetings-ps-title-container">
          <div
            className={`greetings-ps-title ${
              smallerHOText ? "smaller-greetings-ho-text" : ""
            }`}
          >
            Greetings {theHomeowners}
          </div>
          <div className="greetings-ps-divider" />
          <div className="greetings-ps-subtitle">
            Strongest Market Since 2005
          </div>
        </div>
        <div className="greetings-ps-box">
          {hoTextPS === "<p></p>" ? (
            <div className="greetings-ps-text">
              Please stay tuned for your next PMA!
            </div>
          ) : (
            <div
              className="greetings-ps-text"
              dangerouslySetInnerHTML={{
                __html: hoTextPS,
              }}
            ></div>
          )}
          <div className="agent-signoff">
            <p className="signoff">Here's to new beginnings,</p>

            {isMemberOfTeam && teamMembers.length === 2 ? (
              <p className="agent-name">
                {agentFirstNames(listgenUserFirstName, teamMembers)}
              </p>
            ) : (
              <p className="agent-name">{listgenUserFirstName}</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default GreetingsPS;
