import React, { Component } from "react";
import QVFourDotGraph from "./QVFourDotGraph";
import { propertyUtil } from "views/Pma/PropertyUtil";
import moment from "moment";

class QVFourSellerGraph extends Component {
  determineArrow = (perc, index) => {
    return (
      <div className="seller-change-arrow-container">
        {perc == 0 ? (
          <div />
        ) : perc > 0 ? (
          <img
            src={`https://pma-coverphotos.s3.amazonaws.com/july-2024-up-arrow-${index}.png`}
            alt="Up Arrow"
            className="seller-graph-arrow"
          />
        ) : (
          <img
            src={`https://pma-coverphotos.s3.amazonaws.com/july-2024-down-arrow-${index}.png`}
            alt="Down Arrow"
            className="seller-graph-arrow"
          />
        )}
      </div>
    );
  };
  determineDateRange = (type, startOrEnd) => {
    let date = {
      start: "",
      end: "",
    };
    if (type === "prev") {
      if (startOrEnd === "start") {
        return "Jan";
      } else {
        return "Mar";
      }
    }
    if (type === "curr") {
      if (startOrEnd === "end") {
        return "Mar";
      } else {
        return "Jan";
      }
    }
    return [date.start, date.end];
  };
  render() {
    const {
      category,
      categoryName,
      description,
      dataPoints,
      formattedPoints,
      currentYearDataPoint,
      lastYearDataPoint,
      trendingPercPoint,
      bgColor,
      colorOrder,
      graphPosition,
      bottomLabelsText,
      isSellersAdvantage,
      totalUnitsSold6Months,
      totalUnitsSoldPrevious6Months,
      printVersion,
    } = this.props;
    // console.log("seller graph", this.props);
    const { determineArrow, determineDateRange } = this;
    const { posOrNeg } = propertyUtil;
    let percentageData = trendingPercPoint;

    const under1PercChange = trendingPercPoint >= -1 && trendingPercPoint <= 1;
    return (
      <div className="seller-adv-section">
        <div className="seller-adv-row">
          <QVFourDotGraph
            dataPoints={dataPoints}
            category={category}
            formattedPoints={formattedPoints}
            bgColor={bgColor}
            colorOrder={colorOrder}
            graphPosition={graphPosition}
            bottomLabelsText={bottomLabelsText}
            printVersion={printVersion}
          />
          <div className="seller-data-section">
            <div className="seller-data-section-category-row">
              <div className="seller-data-section-category-text">
                {categoryName}
              </div>
            </div>

            <div className="seller-two-box-row">
              <div
                className={`seller-data-inner-section data-inner-bg-${graphPosition}`}
              >
                <div className="seller-data-inner-row">
                  <div className="seller-data-inner-section-col">
                    <div className="seller-year-and-data-col">
                      <div className="seller-data-year">Previous</div>
                      <div className="seller-data-year">6 Months</div>
                      {/*<div className="seller-data-description">
                        {determineDateRange("prev", "start")} -{" "}
                        {determineDateRange("prev", "end")}
                      </div>*/}
                      <div className="seller-data-data">
                        {lastYearDataPoint}
                      </div>
                      {category === "AVERAGE SALE PRICE" &&
                      totalUnitsSoldPrevious6Months ? (
                        <div className="seller-data-description">
                          {totalUnitsSoldPrevious6Months}{" "}
                          {totalUnitsSoldPrevious6Months == 1
                            ? "Unit"
                            : "Units"}{" "}
                          Sold
                        </div>
                      ) : (
                        <div className="seller-data-description"></div>
                      )}
                    </div>
                  </div>
                  <div className="seller-data-section-divider" />
                  <div className="seller-data-inner-section-col">
                    <div className="seller-year-and-data-col">
                      <div className="seller-data-year">Last</div>
                      <div className="seller-data-year">6 Months</div>
                      {/*<div className="seller-data-description">
                        {determineDateRange("curr", "start")} -{" "}
                        {determineDateRange("curr", "end")}
                      </div>*/}
                      <div className="seller-data-data">
                        {currentYearDataPoint}
                      </div>
                      {totalUnitsSold6Months &&
                      category === "AVERAGE SALE PRICE" ? (
                        <div className="seller-data-description">
                          {totalUnitsSold6Months}{" "}
                          {totalUnitsSold6Months == 1 ? "Unit" : "Units"} Sold
                        </div>
                      ) : (
                        <div className="seller-data-description"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="seller-description-perc">
                <div className="seller-description-text">{description}</div>

                {category == "AVERAGE SALE PRICE" &&
                totalUnitsSoldPrevious6Months == 0 ? (
                  <div className="seller-perc-diff-container">
                    <div className="seller-perc-prev-change">
                      <p>ZERO UNITS SOLD</p>
                      <p>in PREVIOUS 6 MOS.</p>
                    </div>
                  </div>
                ) : (totalUnitsSold6Months == 0 && trendingPercPoint == -100) ||
                  (totalUnitsSold6Months == 0 &&
                    currentYearDataPoint == "0%" &&
                    category === "AVERAGE % RECEIVED OF ASKING") ? (
                  <div className="seller-perc-diff-container">
                    <div className="seller-perc-no-change">
                      <p>ZERO UNITS SOLD</p>
                      <p>in LAST 6 MONTHS</p>
                    </div>
                  </div>
                ) : !trendingPercPoint ? (
                  <div className="seller-perc-diff-container">
                    <div className="seller-perc-no-change">
                      <p>No</p>
                      <p>Change</p>
                    </div>
                  </div>
                ) : under1PercChange ? (
                  <div className="seller-perc-diff-container">
                    <div className="seller-perc-no-change">
                      <p>Less Than a</p>
                      <p>1% Change</p>
                    </div>
                  </div>
                ) : (
                  <div className="seller-perc-diff-container">
                    <div className="seller-perc-diff">
                      {trendingPercPoint >= 100
                        ? ">100"
                        : trendingPercPoint <= -100
                        ? "<100"
                        : posOrNeg(trendingPercPoint, 0, true)}
                      %
                    </div>
                    {determineArrow(trendingPercPoint, +graphPosition - 1)}
                  </div>
                )}

                <div className="qv4-sellers-adv-text">
                  {isSellersAdvantage ? "Seller's Advantage" : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QVFourSellerGraph;
