import React, { Component } from 'react';

class Unavailable extends Component {
  render() {
    return (
      <div className="unavailable">
        Your Precision Market Analysis Is Currently In Development
      </div>
    );
  }
}

export default Unavailable;
