import React, { Fragment } from "react";
import { propertyUtil } from "../../PropertyUtil";
import LastSale from "./LastSale";
import OffMarketSale from './OffMarketSale';


const LastSaleView = (props) => {
    const { isPropertyMapped, propertyDetails, mailingDetails, areaID, agentID, mailerProID, mailingStatus, changeDetails, propertyData, blocked, nextPMAPrintDate, lastPMAPrintDate, agentData, property, propertySequenceNumber, isMailProLocked } = props;

    return (
            <LastSale
                propertyDetails={propertyDetails}
                mailingDetails={mailingDetails}
                areaID={areaID}
                agentID={agentID}
                mailerProID={mailerProID}
                mailingStatus={mailingStatus}
                changeDetails={changeDetails}
                isPropertyMapped={isPropertyMapped}
                propertyData={propertyData}
                blocked={blocked}
                nextPMAPrintDate={nextPMAPrintDate}
                lastPMAPrintDate={lastPMAPrintDate}
                agentData={agentData}
                propertySequenceNumber={propertySequenceNumber}
                property={property}
                isMailProLocked={isMailProLocked}
            />
    );
};

export default LastSaleView;