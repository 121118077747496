import React, { Component } from "react";

class GummowListingPackages extends Component {
  render() {
    return (
      <div className="gummow-listing-packages">
        <div className="gummow-listing-packages-title-container">
          <div className="gummow-listing-packages-title-text">
            Our Listing Packages
          </div>
        </div>
        <div className="gummow-listing-body">
          <div className="gummow-listing-body-col">
            <div className="gummow-listing-body-col-title">
              <div className="gummow-listing-body-col-title-text">Standard</div>
              <div className="gummow-listing-body-col-title-divider"></div>
            </div>
            <div className="gummow-listing-packages-container">
              <p>Luxury Photography</p>
              <p>Full Home Videography</p>
              <p>Video on Local Amenities</p>
              <p>Twilight Photography</p>
              <p>Drone Photography</p>
              <p>Online Virtual 3D Tour</p>
              <p>2D Floorplan</p>
              <p>Luxury Custom Home Website</p>
              <p>2 Post Luxury Yard Sign</p>
              <p>Pre-Market Exposure</p>
              <p>Heavy Social Media Exposure</p>
              <p>Just Listed Mailers</p>
              <p>Luxury Property Brochures</p>
              <p>Open Houses</p>
              <p>Twilight Wine / Cheese Open House</p>
              <p>Paid Online Advertisements</p>
              <p>Manage ROI Remodel</p>
            </div>
          </div>
          <div className="gummow-listing-body-col">
            <div className="gummow-listing-body-col-title">
              <div className="gummow-listing-body-col-title-text">Premium</div>
              <div className="gummow-listing-body-col-title-divider"></div>
            </div>
            <div className="gummow-listing-packages-container">
              <p>Luxury Photography</p>
              <p>Full Home Videography</p>
              <p>Video on Local Amenities</p>
              <p>Twilight Photography</p>
              <p>Drone Photography</p>
              <p>Online Virtual 3D Tour</p>
              <p>2D Floorplan</p>
              <p>Luxury Custom Home Website</p>
              <p>2 Post Luxury Yard Sign</p>
              <p>Pre-Market Exposure</p>
              <p>Heavy Social Media Exposure</p>
              <p>Just Listed Mailers</p>
              <p>Luxury Property Brochures</p>
              <p>Open Houses</p>
              <p>Twilight Wine / Cheese Open House</p>
              <p>Paid Online Advertisements</p>
              <p>Manage ROI Remodel</p>
              <p className="gummow-listing-package-bolder">Home Inspections</p>
              <p className="gummow-listing-package-bolder">
                Luxury Home Staging
              </p>
              <p className="gummow-listing-package-bolder">Deep Clean</p>
            </div>
          </div>
        </div>
        <div className="gummow-listing-footer">
          <div className="gummow-listing-footer-inner-container">
            <div className="gummow-listing-footer-title">
              "Flip to Sell" available for an additional .5%
            </div>
            <div className="gummow-listing-footer-title">
              ** Did we leave anything out? Consider it done! **
            </div>
            <div className="gummow-listing-footer-divider"></div>
            <div className="gummow-listing-footer-disclaimer">
              DISCLAIMER: In the event the listing agreement is canceled, seller
              to reimburse the Gummow Brothers for any incurred
            </div>
            <div className="gummow-listing-footer-disclaimer">
              costs outside of the "standard" package. Failure to do so within a
              reasonable time will result in a 10% interest fee.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GummowListingPackages;
