import React, { Component } from 'react';
import { propertyUtil } from 'views/Pma/PropertyUtil';

class CompiledByFooter extends Component {
  render() {
    const { websiteURL, emailAddress, brokerageName } = this.props.agentData;
    const { blackBorder } = propertyUtil;
    const hasBlackBorder = blackBorder(brokerageName);
    return (
      <div
        className={
          'compiled-by-footer ' + (hasBlackBorder ? 'black-compiled-by' : '')
        }
      >
        {websiteURL ? (
          <div className="compiled-by-footer-inner-container">
            <p>
              Email:<span className="compiled-by-colon-padding"></span>
              {emailAddress}
            </p>
            <div className="compiled-by-footer-space" />
            {websiteURL && (
              <p>
                Website:<span className="compiled-by-colon-padding"></span>
                {websiteURL.replace(/^www./g, '')}
              </p>
            )}
          </div>
        ) : (
          <div className="compiled-by-footer-inner-container center">
            <p>
              Email:<span className="compiled-by-colon-padding"></span>
              {emailAddress}
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default CompiledByFooter;
