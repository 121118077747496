import { GetStateStorage } from "common/storage";



const getHelpObject = (property, getDataFn = () => { }) => {
  let data = getDataFn();
  let {
    listgenUserID,
    listgenUserFirstName,
    listgenUserLastName,
    emailAddress,
    mobilePhoneNo,
  } = data;
  let name =
    (listgenUserFirstName ? listgenUserFirstName : "") +
    " " +
    (listgenUserLastName ? listgenUserLastName : "");
  emailAddress = emailAddress ? emailAddress : "";
  mobilePhoneNo = mobilePhoneNo ? mobilePhoneNo : "";
  data = {
    listgenUserID,
    name,
    email: emailAddress,
    phone: mobilePhoneNo,
    listgenUserFirstName,
    listgenUserLastName,
  };
  return !property ? data : data[property];
};

const subjectBuilder = (getDataFn = () => { }) => {
  let data = getDataFn();
  const { brokerageName, listgenUserFirstName, listgenUserLastName } = data;
  const nameString = `${listgenUserFirstName
      ? `${listgenUserFirstName}${listgenUserLastName ? ` ${listgenUserLastName}` : ``
      }`
      : ``
    }`;

  return `${nameString}${brokerageName ? ` | ${brokerageName}` : ``}`;
};

const getAgentAreasandCityName = (accessedFromAreaID, getDataFn) => {
  let data = getDataFn();
  const { navbarInfo } = data;
  let areaNames = "-",
    cityName = "-";
  if (accessedFromAreaID) {
    (navbarInfo || []).map((area) => {
      if (area.mlsAreaID === accessedFromAreaID) {
        areaNames = area.mlsNeighborhood;
        cityName = area.mlsCityName;
      }
    });
  } else if(navbarInfo) {
    cityName = (navbarInfo || [])[0].mlsCityName;
    areaNames = (navbarInfo || [])
      .map((area) => area.mlsNeighborhood)
      .join(", ");
  }
  return { cityName, areaNames };
};

const helpUtils = {
  getHelpObject,
  subjectBuilder,
  getAgentAreasandCityName,
};

export default helpUtils;