import React from "react";
import { connect } from "react-redux";
import PmaEditor from "./PMAEditor";
//import './index.scss';
import { GetAgentDetail } from "services/agent";
import { GetOAuthTokenByKey } from "common/storage";
import axios from "common/interceptors";
import { propertyUtil } from "views/Pma/PropertyUtil";

class PrintSpeedometerPMA extends React.Component {
  state = {
    singleHOData: null,
    agentData: null,
    mlsAreas: null,
    marketActivity: null,
    MQCityDetails: null,
    currentArea: null,
    version: "qv4",
    coverPageDetails: null,
    qArea: null,
    qCity: null,
    aprilData: null,
    missionStatement: null,
    isDemo: true,
  };

  async componentDidMount() {
    let mailProId = this.props.match.params.mailproid;
    let listgenUserID = this.props.match.params.listgenUserID;
    let brokerage = this.props.match.params.brokerage;

    const { version } = this.state;
    console.log("this.props.match.params.", brokerage);

    let url = window.location.href;
    if (url.includes("test-print")) {
      let split = url.split("/");
      listgenUserID = url[url.length - 2];
      let area = url[url.length - 1];
    }
    let date = new Date();
    let thisYear = date.getFullYear();
    const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
    const today = `${date.getDate()}`.padStart(2, 0);
    try {
      let mailPrint = await axios
        .get(
          `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
          {
            headers: {
              "content-type": "application/json",
            },
            data: {},
          }
        )
        .then((res) => {
          //console.log("first res", res.data);
          const singleHOData = res.data.mailerProDTO;
          const agentData = res.data.listgenUserDTO;
          this.setState({
            singleHOData,
            agentData,
          });
        })
        .catch((err) => console.log("error retrieving idDetails" + err));
    } catch (error) {
      console.log("error", error);
    }

    let navBar = await axios
      .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
        headers: {
          "content-type": "application/json",
        },
        data: {},
      })

      .then((res) => {
        this.setState({
          mlsAreas: res.data,
        });
      })
      .catch((err) => {
        // console.log('error retrieving navbarInfo' + err)
      });

    //  console.log("qv3 print", this.props);

    const thisArea = this.state.singleHOData.areaID;

    let areaAndDate = `${thisArea}-${thisYear}-${thisMonth}-${today}`;

    if (version === "qv4") {
      // const mailProId = this.props.match.params.mailproid;
      // const listgenUserID = this.props.match.params.listgenUserID;
      let date = new Date();
      let thisYear = date.getFullYear();
      const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
      const today = `${date.getDate()}`.padStart(2, 0);

      try {
        let mailPrint = await axios
          .get(
            `/admin/getMailPrintInfoDetails?listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,
            {
              headers: {
                "content-type": "application/json",
              },
              data: {},
            }
          )
          .then((res) => {
            const singleHOData = res.data.mailerProDTO;
            let agentData = res.data.listgenUserDTO;

            this.setState({
              singleHOData,
              //agentData,
            });
          })
          .catch((err) => console.log("error retrieving idDetails" + err));

        let navBar = await axios
          .get(`/agent/getNavbarInfo?listgenUserID=${listgenUserID}`, {
            headers: {
              "content-type": "application/json",
            },
            data: {},
          })

          .then((res) => {
            this.setState({
              mlsAreas: res.data,
            });
          })
          .catch((err) => console.log("error retrieving navbarInfo" + err));
        const thisArea = this.state.singleHOData.areaID;
        let currentArea = this.state.mlsAreas.filter(
          (area) => area.mlsAreaID === thisArea
        )[0];
        const { singleHOData } = this.state;
        const { mlsCityID, mlsAreaID } = currentArea;
        // if (mlsAreaID === "DEMO-SCL-17D") {
        //   currentArea.mlsNeighborhood = "Summerland";
        // }
        let subdivision =
          singleHOData &&
          singleHOData.subdivisionName &&
          singleHOData.subdivisionName.length > 1
            ? singleHOData.subdivisionName
            : "-";
        //console.log("singleHOData", this.state.singleHOData);
        const cityAndDate = `${currentArea.mlsCityID}-${thisYear}-${thisMonth}-${today}`;

        const { formatDemoBrokerage, formatDemoEmail } = propertyUtil;

        const { mailerProID, subdivisionName } = singleHOData;
        if (currentArea) {
          let getPmaDetails = await axios
            .get(
              `/agent/getPMADetails?areaCityPeriod=${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}&subDivision=${
                subdivisionName ? subdivisionName : "-"
              }&listgenUserID=${listgenUserID}&mailerProID=${mailProId}`,

              {
                headers: {
                  "content-type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                },
                data: {},
              }
            )
            .then((res) => {
              const {
                mailerPrintInfoDTO,
                lgTestmonialsDTO,
                missionStatementDTO,
                quadrantInfoList,
                pmaQuarterlyCityDTO,
              } = res.data;
              const singleHOData = mailerPrintInfoDTO.mailerProDTO;
              let agentData = mailerPrintInfoDTO.listgenUserDTO;
              console.log("agentData", agentData);
              const { formatDemoBrokerage, formatDemoEmail } = propertyUtil;

              let isDemo = false;
              let demoValues = {
                agentDesignation: "Broker Associate",
                agentLicenseNumber: "12345678",
                billingAddressLine1: "7979 Soquel Drive test",
                billingAddressLine2: null,
                billingCity: "Aptos",
                billingFirstName: "Sarah",
                billingLastName: "Sullivan",
                billingState: "CA",
                billingZipCode: "95003",
                brokerLicenseNumber: "01317331",
                brokerageLogoURL: null,
                brokerageName: "COMPASS",
                displayEmailAddress: "sarah@compass.com",
                emailAddress: "sarah@compass.com",

                isMemberOfTeam: false,
                listgenRoleID: 2,
                listgenUserFirstName: "Sarah",
                listgenUserID: "101",
                listgenUserLastName: "Sullivan",
                listgenUserPassword: null,
                logoURL:
                  "https://uploadagentdetails.s3.amazonaws.com/1626808274547-SarahSullivan-logo-lightBG.png",
                logoURL2:
                  "https://uploadagentdetails.s3.amazonaws.com/1566296755270-test-file-for-upload.jpg",
                mailProStatus: null,
                mailingAddressLine1: "7979 Soquel Drive test",
                mailingAddressLine2: null,
                mailingCity: "Aptos",
                mailingMonthsInvoicesCount: null,
                mailingState: "CA",
                mailingZipCode: "95003",
                missionStatementsCount: null,
                missionStatementsVerifiedCount: null,
                mobilePhoneNo: "3219876543",
                officeAddressLine1: "7979 Soquel Drive",
                officeAddressLine2: null,
                officeCity: "Aptos",
                officePhoneNo: null,
                officeState: "CA",
                officeZipcode: "95003",
                photoURL:
                  "https://uploadagentdetails.s3.amazonaws.com/1699300827098-1674530346469-sarahsullivan_nosuch_032821-1.jpg",
                signatureURL:
                  "https://uploadagentdetails.s3.amazonaws.com/1566296763311-test-file-for-upload.jpg",
                websiteURL: "www.SarahSullivanHomes.com",
                demoCoverDate: "July 20",
              };

              agentData = Object.assign(agentData, demoValues);
              res.data.pmaCoverPhotoDTO = Object.assign(
                res.data.pmaCoverPhotoDTO,
                {
                  coverPhoto:
                    "https://d2onbxnrte2nim.cloudfront.net/Summerkitchen_July2024.jpg",
                  coverPhotoCredit1:
                    "Cover Credit: Interior Design by Hayley Bridges Design",
                }
              );
              // if (
              //   listgenUserID === "103" ||
              //   listgenUserID === "105" ||
              //   listgenUserID === "101"
              // ) {
              agentData.brokerageName = formatDemoBrokerage(brokerage);
              agentData.emailAddress = formatDemoEmail(brokerage);

              isDemo = true;
              // currentArea.mlsNeighborhood = "Pruneyard";
              // }
              let testimonials = lgTestmonialsDTO;
              testimonials.testimonialContent =
                "I have been buying and selling personal real estate for many years in California. Working with Sarah was a refreshing experience and I would rate her services a 10 out of 10. It's rare to find a Realtor that provides such attention to detail, demonstrates true concern for her clients, and tirelessly works day and night to accomplish our goals.  Thank you Sarah!";
              this.setState({
                singleHOData,
                agentData,
                currentArea,
                MQCityDetails: pmaQuarterlyCityDTO,
                coverPageDetails: res.data.pmaCoverPhotoDTO,
                quarterlyAreaTable: res.data.pmaQuarterlyAreaDTO,
                quarterlyCityTable: res.data.pmaQuarterlyCityDTO,
                testimonials: lgTestmonialsDTO,
                aprilData: {
                  pmaQuarterlyAreaDTO: res.data.pmaQuarterlyAreaDTO,
                  pmaQuarterlyCityDTO: res.data.pmaQuarterlyCityDTO,
                },
                bubbleBoxes: res.data.marketValueInfoList,
                //testimonials: res.data.lgTestmonialsDTO,
                missionStatement: missionStatementDTO,
                quadrantDetails: quadrantInfoList,
                isDemo: isDemo,
              });
            })
            .catch((err) =>
              console.log("error retrieving table bubble data" + err)
            );
        }
      } catch (error) {
        console.log("error with qv4 print apis", error);
      }
    }
  }
  render() {
    const { propertyData } = this.props;
    const {
      singleHOData,
      agentData,
      mlsAreas,
      marketActivity,
      MQCityDetails,
      currentArea,
      version,
      quarterlyCityTable,
      quarterlyAreaTable,
      coverPageDetails,
      agentAdvantages,
      yearEnd,
      qArea,
      qCity,
      aprilData,
      missionStatement,
      quadrantDetails,
      testimonials,
      isDemo,
    } = this.state;
    console.log("this.state", this.state);
    if (
      version === "qv4" &&
      currentArea &&
      singleHOData &&
      agentData &&
      quarterlyAreaTable &&
      coverPageDetails &&
      testimonials &&
      testimonials.testimonialContent
    ) {
      return (
        <div className="pma-creator">
          <main>
            <section>
              <PmaEditor
                quadrantDetails={quadrantDetails}
                propertyData={propertyData}
                printVersion={true}
                version={version}
                singleHOData={singleHOData}
                agentData={agentData}
                currentArea={currentArea}
                marketActivity={marketActivity}
                MQCityDetails={MQCityDetails}
                mlsAreas={mlsAreas}
                quarterlyAreaTable={quarterlyAreaTable}
                quarterlyCityTable={quarterlyCityTable}
                coverPageDetails={coverPageDetails}
                qArea={qArea}
                qCity={qCity}
                aprilData={aprilData}
                dontShowPma={false}
                missionStatement={missionStatement}
                printTestimonials={testimonials}
                isDemo={isDemo}
                MQCityDetails={MQCityDetails}
              />
            </section>
          </main>
        </div>
      );
    } else {
      return <div>Loading PMA...</div>;
    }
  }
}
export default PrintSpeedometerPMA;
