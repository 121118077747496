import React, { Component } from "react";
import SellerGraphContainer from "./SellerGraphContainer";
import DotGraph from "./DotGraph";
import Speedometer from "./Speedometer";
import CompiledByFooter from "./CompiledByFooter";

class YEOneSecondPageLeft extends Component {
  render() {
    const { quarterlyCityTable, agentData, yearEnd, currentArea } = this.props;
    return (
      <div className="ye1-second-page-left">
        <Speedometer currentArea={currentArea} yearEnd={yearEnd} />
        <SellerGraphContainer
          agentData={agentData}
          quarterlyCityTable={quarterlyCityTable}
          yearEnd={yearEnd}
        />
        <CompiledByFooter agentData={agentData} />
      </div>
    );
  }
}

export default YEOneSecondPageLeft;
