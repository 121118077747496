import React, { useEffect, useState, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Button, Typography, Divider, Container, Popover, Tooltip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Slide from '@material-ui/core/Slide';
import classNames from 'classnames';
import styles from '../../styles';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Filter from 'bad-words';
import { pmaActions } from '_store/_actions';
import MailingAddressView from '../../MailingAddressView';
import moment from 'moment';
import { propertyUtil } from 'views/Pma/PropertyUtil';
import { renderLogicUtil } from 'views/Pma/RenderLogicUtil';

const CustomNoteTextMakerV2 = props => {
  const {
    classes,
    cameFrom,
    lastNoteData,
    formattedSold,
    lastSalePrice,
    personalPSHistory,
    openDialog,
    mailingDetails,
    areaID,
    agentID,
    mailerProID,
    mailingStatus,
    changeDetails,
    propertyDetails,
    propertyData,
    agentData,
    expiryDate,
    cancelledDate,
    property,
    propertySequenceNumber,
    lastPMAPrintDate,
    newMailingsHOMode,
    isMailProLocked
  } = props;


  let {
    textPS,//personal note
    textPSDate,
    personalPS,//personal ps
    personalPSDate,
    typePS
  } = changeDetails ? changeDetails : {};


  let isAddedForSameType = textPS && cameFrom === typePS

  const truncateHelper = localPS => {
    //chnage the local ps first
    localPS = localPS
      .replace(/<[^>]+>/g, '')
      .replace(/(&nbsp;|<br>|<br \/>)/g, '');
    if (localPS && localPS.length > 50) {
      let cutPersonal = `${propertyUtil.iTextTruncater(localPS, 15, 80)}...`;
      return cutPersonal;
    }
    return localPS;
  };

  const formatDate = d => {
    return moment(new Date(d)).format('M/D/YYYY');
  };

  const afterDateNoteDefault = () => {
    {/* //add +1 becuase in array we only have last added personal ps notes */ }
    let noteCount = (Array.isArray(personalPSHistory)
      ? personalPSHistory.length
      : 0) + (textPS ? 1 : 0);
    let lastAnyNoteDate =
      lastNoteData && lastNoteData.date
        ? formatDate(lastNoteData.date)
        : '---';
    let lastAnyPSDate =
      lastNoteData && lastNoteData.psDate
        ? formatDate(lastNoteData.psDate)
        : '---';
    //send data only if any note is there
    return (noteCount > 0 || personalPS) ? (
      <Fragment>
        <div className={'dynamic-update-ps is-current-ps breakAll'}>
          {textPS ? (
            <Fragment>
              <h4 className={'font-weight-bold'}>
                {truncateHelper(textPS)}
              </h4>
              <br />
            </Fragment>
          ) : (
            <p></p>
          )
            // <p>No personal note for current mailing</p>
          }
          {(textPS && textPSDate) || lastNoteData && (lastNoteData.date || lastNoteData.psDate) ?
            <Fragment>
              <p>Last Personal Note:</p>
              <p>{textPS ? (textPSDate ? formatDate(textPSDate) : '---') : lastAnyNoteDate}</p>
              <p>Last Personal PS:</p>
              <p>{personalPS ? (personalPSDate ? formatDate(personalPSDate) : '---') : lastAnyPSDate}</p>
              <p>
                Total:{' '}
                {noteCount}
              </p>
            </Fragment>
            : <p></p>}
          <p className={classes.redColor} style={{ marginTop: '8px' }}>
            {textPS && personalPS ? 'You have added a PERSONAL NOTE and a P.S. for this homeowner' : (!personalPS && textPS ? 'You have added a PERSONAL NOTE to this homeowner for this mailing.' : (personalPS && !textPS ? 'You have added a PERSONAL P.S. to this homeowner for this mailing' : ''))}
          </p>
        </div>
      </Fragment>
    ) : (
      ''
    );
  };

  const switchAddEdit = () => {
    return textPS ? 'Edit' : 'Add';
  };

  const getCommonLastSaleBlock = (formattedSold, lastSalePrice) => {
    return <Fragment>
      <div>
        <div>{formattedSold ? formattedSold : 'Sale Date Unknown'}</div>
        <div className="at-the-rate">&#64;</div>
        <div>{lastSalePrice ? `$${lastSalePrice}` : 'Sale Price Unknown'}</div>
      </div>
      <br />
    </Fragment>
  };

  const getPersonalNoteText = () => {
    // Here we have a case. Kyle want to show Last note date and count in personal note column for every property whether we have note for current version or not. that's why er aren't putting afterDateNote check here. For oterh personal note items we can put because we only showing the text not the note in case of NO NOTE.
    return (
      <Fragment>
        <div className="tableTdFlex-First textCursor">
          {afterDateNoteDefault()}
        </div>
        <div className="tableTdFlex-Second pointer" onClick={event => openDialog()}>
          {newMailingsHOMode ?
            <p className="blue-text mailProLinkClass">
              Write a Personal Note to this Homeowner
            </p>
            :
            <p className="blue-text mailProLinkClass pointer">
              {switchAddEdit()}
              <br />
              Personal Note
            </p>}
        </div>
      </Fragment>
    );
  };

  const getAHONoteText = () => {
    return (
      <Fragment>
        <div className="tableTdFlex-First textCursor">
          {/* Load the Mailing Address View */}
          <MailingAddressView
            mailingDetails={mailingDetails}
            areaID={areaID}
            agentID={agentID}
            mailerProID={mailerProID}
            mailingStatus={mailingStatus}
            changeDetails={changeDetails}
            propertyDetails={propertyDetails}
            propertyData={propertyData}
            agentData={agentData}
            property={property}
            propertySequenceNumber={propertySequenceNumber}
            lastPMAPrintDate={lastPMAPrintDate}
            isMailProLocked={isMailProLocked}

          />
          {/* <br /> */}
          <p
            className={classNames(`dynamic-update-ps ${isAddedForSameType ? 'is-current-ps' : 'sold'
              } font-weight-bold breakAll action-item-margin-top`, classes.redColor)}
          >
            {/* action-item-margin-top class to make absentee ho on same height to action items (cancelled, expired etc) */}
            ABSENTEE
            <br />
            HOMEOWNER
          </p>
          {isAddedForSameType ? (
            <p
              className={`${isAddedForSameType ? 'is-current-ps' : 'sold'
                } action-item-margin-top`}
            >
              PERSONAL NOTE created
            </p>
          ) : (
            ''
          )}
        </div>
        <div
          className="tableTdFlex-Second pointer"
          onClick={event => openDialog()}
        >
          <p className="blue-text mailProLinkClass pointer">
            {switchAddEdit()}
            <br />
            Personal Note
          </p>
        </div>
      </Fragment>
    );
  };

  const getAnniversaryNoteText = () => {
    return (
      <Fragment>
        <div className="tableTdFlex-First textCursor">
          {getCommonLastSaleBlock(formattedSold, lastSalePrice)}
          {/* <br /> */}
          <p
            className={`dynamic-update-ps ${isAddedForSameType ? 'is-current-ps' : 'sold'
              } font-weight-bold breakAll`}
            style={{ marginTop: '28px' }}
          >
            {propertyUtil.daySuffixMailPro(renderLogicUtil.getHOAnniversaryNumber(property))}
            <br />
            ANNIVERSARY!
          </p>
          {isAddedForSameType ? (
            <p
              className={`${isAddedForSameType ? 'is-current-ps' : 'sold'
                } action-item-margin-top`}
            >
              PERSONAL NOTE created
            </p>
          ) : (
            ''
          )}
        </div>
        <div
          className="tableTdFlex-Second pointer"
          onClick={event => openDialog()}
        >
          <p className="blue-text mailProLinkClass">
            {switchAddEdit()}
            <br />
            Personal Note
          </p>
        </div>
      </Fragment>
    );
  };

  const getCongratsNoteText = () => {
    return (
      <Fragment>
        <div className="tableTdFlex-First">
          {getCommonLastSaleBlock(formattedSold, lastSalePrice)}
          {/* <br /> */}
          <p
            className={`dynamic-update-ps ${isAddedForSameType ? 'is-current-ps' : 'sold'
              } font-weight-bold breakAll`}
            style={{ marginTop: '28px' }}
          >
            CONGRATS!
          </p>
          {isAddedForSameType ? (
            <p
              className={`${isAddedForSameType ? 'is-current-ps' : 'sold'
                } action-item-margin-top `}
            >
              PERSONAL NOTE created
            </p>
          ) : (
            ''
          )}
          <div
            className={`${textPS ? 'is-current-ps' : 'sold'
              } font-weight-bold`}
          >
            JUST PURCHASED!
          </div>
        </div>
        <div
          className="tableTdFlex-Second pointer"
          onClick={event => openDialog()}
        >
          <p className="blue-text mailProLinkClass">
            {switchAddEdit()}
            <br />
            Personal Note
          </p>
        </div>
      </Fragment>
    );
  };

  const getCanceledNoteText = () => {
    return (
      <Fragment>
        <div className="tableTdFlex-First">
          {getCommonLastSaleBlock(formattedSold, lastSalePrice)}
          {/* <br /> */}
          <p
            className={`dynamic-update-ps ${isAddedForSameType ? 'is-current-ps' : 'sold'
              } font-weight-bold breakAll`}
            style={{ marginTop: '28px' }}
          >
            CANCELLED!
          </p>
          <p
            className={`dynamic-update-ps ${isAddedForSameType ? 'is-current-ps' : 'sold'
              } breakAll extraDateMarginTop`}
          >
            {cancelledDate ? formatDate(cancelledDate) : ''}
          </p>
          {isAddedForSameType ? (
            <p
              className={`${textPS ? 'is-current-ps' : 'sold'
                } action-item-margin-top`}
            >
              PERSONAL NOTE created
            </p>
          ) : (
            ''
          )}
        </div>
        <div
          className="tableTdFlex-Second pointer"
          onClick={event => openDialog()}
        >
          <p className="blue-text mailProLinkClass">
            {switchAddEdit()}
            <br />
            Personal Note
          </p>
        </div>
      </Fragment>
    );
  };

  const getExpiredNoteText = () => {
    return (
      <Fragment>
        <div className="tableTdFlex-First">
          {getCommonLastSaleBlock(formattedSold, lastSalePrice)}
          {/* <br /> */}
          <p
            className={`dynamic-update-ps ${isAddedForSameType ? 'is-current-ps' : 'sold'
              } font-weight-bold breakAll`}
            style={{ marginTop: '28px' }}
          >
            EXPIRED!
          </p>
          <p
            className={`dynamic-update-ps ${isAddedForSameType ? 'is-current-ps' : 'sold'
              } breakAll extraDateMarginTop`}
          >
            {expiryDate ? formatDate(expiryDate) : ''}
          </p>
          {isAddedForSameType ? (
            <p
              className={`${textPS ? 'is-current-ps' : 'sold'
                } action-item-margin-top`}
            >
              PERSONAL NOTE created
            </p>
          ) : (
            ''
          )}
        </div>
        <div
          className="tableTdFlex-Second pointer"
          onClick={event => openDialog()}
        >
          <p className="blue-text mailProLinkClass">
            {switchAddEdit()}
            <br />
            Personal Note
          </p>
        </div>
      </Fragment>
    );
  };

  const getCoincidenceNoteText = () => {
    // Coincidence is a special case. Here we dont want flex layout because it's gonna show in same column of absentee HO. Second we don't want to show any edit link because agent can only submit Coincidence note by clicking on Coincidence text.
    return (
      <Fragment>
        <div
          className={`dynamic-update-ps ${isAddedForSameType ? 'is-current-ps' : 'sold'
            } font-weight-bold breakAll pointer`}
          onClick={event => openDialog()}
        >
          COINCIDENCE!
        </div>
      </Fragment>
    );
  };

  const getTableTextAccordingToType = () => {
    if (cameFrom === 'PersonalPS') {
      return getPersonalNoteText();
    } else if (cameFrom === 'AbsentHOPS') {
      return getAHONoteText();
    } else if (cameFrom === 'Anniversary') {
      return getAnniversaryNoteText();
    } else if (cameFrom === 'Congrats') {
      return getCongratsNoteText();
    } else if (cameFrom === 'Cancelled') {
      return getCanceledNoteText();
    } else if (cameFrom === 'Expired') {
      return getExpiredNoteText();
    } else if (cameFrom === 'Coincidence') {
      return getCoincidenceNoteText();
    }
  };

  return <Fragment>{getTableTextAccordingToType()}</Fragment>;
};

export default compose(withStyles(styles))(CustomNoteTextMakerV2);