import React, { Component } from "react";

class JohnsonsCaseStudy extends Component {
  render() {
    return (
      <div className="johnsons-case-study">
        <div className="johnsons-case-study-inner-container">
          <div className="johnsons-case-study-img-col">
            <img src="https://pma-coverphotos.s3.amazonaws.com/non-covers/020924_JohnsonTeam_BackCovr_1.jpg" />
          </div>
          <div className="johnsons-case-study-body-col">
            <div className="johnsons-case-study-logo">
              <img src="https://pma-coverphotos.s3.amazonaws.com/johnsons-poppy-feb-2024.png" />
            </div>
            <div className="johnsons-case-study-title">
              <p className="johnsons-case-study-caps-title">CASE STUDY:</p>
              <p className="johnsons-case-study-noncaps-title">
                our most recent sale
              </p>
            </div>
            <div className="johnsons-case-study-body-text">
              <p>
                Sometimes, the best way to understand the current real estate
                market is to take a deep-dive into an actual recent sale...
              </p>
            </div>

            <div className="johnsons-case-study-body-text">
              <p>
                Over the holidays we had a call from out of state sellers who
                needed to sell their long-time famly home in Santa Barbara.
                Their tenant had, unfortunately, left the property a mess. We
                crafted a long distance property prep, strategic marketing and
                pricing plan with our clients, including...
              </p>
            </div>
            <div className="johnsons-case-study-bolded-text">
              Cleaning • Gardening • Painting • New Carpeting • Staging •
              Professional Photography
            </div>

            <div className="johnsons-case-study-body-text">
              <p>We hit the market in the second week of January.</p>
              <p>Our prep & marketing efforts attracted...</p>
            </div>

            <div className="johnsons-case-study-bullet-points">
              <div className="johnsons-case-study-bullet-points-row">
                <div className="johnsons-case-study-bullet-point"></div>
                <div className="johnsons-case-study-bullet-point-text">
                  <span className="bolded-bullet-point-text">Over 300</span>{" "}
                  open house guests
                </div>
              </div>

              <div className="johnsons-case-study-bullet-points-row">
                <div className="johnsons-case-study-bullet-point"></div>
                <div className="johnsons-case-study-bullet-point-text">
                  <span className="bolded-bullet-point-text">
                    18 solid offers
                  </span>
                </div>
              </div>

              <div className="johnsons-case-study-bullet-points-row">
                <div className="johnsons-case-study-bullet-point"></div>
                <div className="johnsons-case-study-bullet-point-text">
                  1 happy new homeowner - at least 17 buyers still need homes
                </div>
              </div>

              <div className="johnsons-case-study-bullet-points-row">
                <div className="johnsons-case-study-bullet-point"></div>
                <div className="johnsons-case-study-bullet-point-text">
                  Gaining our selling clients{" "}
                  <span className="bolded-bullet-point-text">
                    $205,000 over their asking price!
                  </span>
                </div>
              </div>
            </div>
            <div className="johnsons-case-study-body-text">
              <p>
                We see this phenomenon happening market-wide (yes, even a $25
                million property had multiple offers in January). Having a
                marketing and pricing strategy is key! We will work closely with
                you, your unique property and your situation in mind to craft a
                winning strategy to help you accomplish your property goals.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default JohnsonsCaseStudy;
