import React, { useEffect, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import classNames from 'classnames';
import {
  Grid,
  TextField,
  Typography,
  Tooltip,
  Divider,
  Button,
  FormControl,
  InputLabel,
  Input,
  OutlinedInput,
  MenuItem,
  Checkbox
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import MaskedInput from 'react-text-mask';


const TextMaskCustom = React.memo((props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask={false}
      guide={true}
      keepCharPositions={false}
    />
  );
});

const AssistantsProfile = React.memo(props => {
  let {
    classes,
    keyID,
    deleteRow,
    stateData,
    handleFieldChange,
    cleanRow,
    errors,
  } = props;


  const [labelWidth, setLabelWidth] = React.useState(0);
  const labelRef = React.useRef(null);

  React.useEffect(() => {
    setLabelWidth(labelRef.current.offsetWidth);
  }, []);

  const getStateValue = (key, field) => {
    try {
      if (stateData[key]) {
        return stateData[key][field];
      } else {
        return '';
      }
    } catch (err) {
      return '';
    }
  };

  const getErrorState = (key, field) => {
    try {
      return (errors[key][field].length) > 0
        ? true
        : false;
    } catch (err) {
      return false;
    }
  };

  const getErrorStateValue = (key, field) => {
    try {
      return (errors[key][field].length) > 0
        ? errors[key][field][0]
        : '';
    } catch (err) {
      return '';
    }
  };

  const agentWantstoSendAssitantPMACopy = getStateValue(keyID, 'sendPMACopy');
  return (
    <Fragment>
      <Grid container spacing={0} key={'agent_details_grid_main' + keyID}>
        <Grid item lg={12}>
          <Typography variant="subtitle2" className={classes.teamMemberTitle}>
            Assistant # {keyID + 1}
          </Typography>
        </Grid>
        <Grid item lg={2}>
          <div className={classes.field}>
            <TextField
              className={classNames(classes.textField)}
              label="First Name"
              margin="dense"
              required
              variant="outlined"
              name="assistantFirstName"
              value={getStateValue(keyID, 'assistantFirstName')}
              error={getErrorState(keyID, 'assistantFirstName')}
              onChange={event =>
                handleFieldChange('assistantFirstName', event.target.value, keyID)
              }
            />
            {getErrorState(keyID, 'assistantFirstName') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'assistantFirstName')}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={2}>
          <div className={classes.field}>
            <TextField
              className={classNames(classes.textField)}
              label="Last Name"
              margin="dense"
              required
              variant="outlined"
              name="assistantLastName"
              value={getStateValue(keyID, 'assistantLastName')}
              error={getErrorState(keyID, 'assistantLastName')}
              onChange={event =>
                handleFieldChange('assistantLastName', event.target.value, keyID)
              }
            />
            {getErrorState(keyID, 'assistantLastName') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'assistantLastName')}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={2}>
          <div className={classes.field}>
            <FormControl className={classNames(classes.formControl, classes.marginTop1Unit)} variant="outlined">
              <InputLabel
                required={true}
                ref={labelRef}
                htmlFor={"formatted-text-mask-input-mobile-phone" + keyID}>Phone</InputLabel>
              <OutlinedInput
                margin="dense"
                value={getStateValue(keyID, 'phone')}
                name="phone"
                labelWidth={labelWidth}
                onChange={
                  event => {
                    handleFieldChange('phone', event.target.value, keyID);
                  }
                }
                required={true}
                id={"formatted-text-mask-input-mobile-phone" + keyID}
                inputComponent={TextMaskCustom}
                error={getErrorState(keyID, 'phone')}
              />
            </FormControl>
            {getErrorState(keyID, 'phone') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'phone')}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={4}>
          <div className={classes.field}>
            <TextField
              className={classNames(classes.textField)}
              label="Email"
              margin="dense"
              required
              variant="outlined"
              name="emailID"
              value={getStateValue(keyID, 'emailID')}
              error={getErrorState(keyID, 'emailID')}
              onChange={event =>
                handleFieldChange('emailID', event.target.value, keyID)
              }
            />
            {getErrorState(keyID, 'emailID') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'emailID')}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={2} />
        </Grid>
        {/* Row 1 End */}
        <Grid container key={'agent_details_grid_secondary' + keyID}>
        <Grid item lg={10} md={10} sm={10} xs={10}>
          <div className={classes.sendPMACopyBox} onChange={(event) => {handleFieldChange("sendPMACopy", event.target.checked, keyID) }}>
            <Checkbox
              color="primary"
              checked={getStateValue(keyID, 'sendPMACopy')}
              
            />
            <div>
              <Typography variant="subtitle1">
                Do you want to send this assistant a copy of PMA?
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid
          item
          lg={2}
          className={classes.paddingRight}
          key={'agent_details_grid_4' + keyID}
        >
          <div className={classes.rowActions}>
            {/* <Tooltip
              title="Clear text in row"
              placement="bottom"
              key={'agent_details_tooltip_eraser' + keyID}
            >
              <IconButton
                aria-label="Close"
                key={'agent_details_button_eraser' + keyID}
                onClick={event => cleanRow(keyID)}
              >
                <svg
                  style={{ height: '24px', width: '24px' }}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0000008a"
                    d="M16.24,3.56L21.19,8.5C21.97,9.29 21.97,10.55 21.19,11.34L12,20.53C10.44,22.09 7.91,22.09 6.34,20.53L2.81,17C2.03,16.21 2.03,14.95 2.81,14.16L13.41,3.56C14.2,2.78 15.46,2.78 16.24,3.56M4.22,15.58L7.76,19.11C8.54,19.9 9.8,19.9 10.59,19.11L14.12,15.58L9.17,10.63L4.22,15.58Z"
                  />
                </svg>
              </IconButton>
            </Tooltip> */}
              <Tooltip
                title="Delete Assistant"
                placement="bottom"
                key={'agent_details_tooltip_delete' + keyID}
              >
                <IconButton
                  aria-label="Delete"
                  key={'agent_details_button_delete' + keyID}
                  onClick={event => deleteRow(keyID)}
                >
                  <DeleteIcon key={'agent_details_icon_delete' + keyID} />
                </IconButton>
              </Tooltip>
          </div>
        </Grid>
        </Grid>
        {agentWantstoSendAssitantPMACopy ? <Grid container key={'agent_details_send_pma_' + keyID}>
        <Grid item lg={2}>
          <div className={classes.field}>
            <TextField
              className={classNames(classes.textField)}
              label="Mailing Address Line 1"
              margin="dense"
              required
              variant="outlined"
              name="mailingAddr1"
              value={getStateValue(keyID, 'mailingAddr1')}
              error={getErrorState(keyID, 'mailingAddr1')}
              onChange={event =>
                handleFieldChange('mailingAddr1', event.target.value, keyID)
              }
            />
            {getErrorState(keyID, 'mailingAddr1') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'mailingAddr1')}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={1}>
          <div className={classes.field}>
            <TextField
              className={classNames(classes.textField)}
              label="City"
              margin="dense"
              variant="outlined"
              name="city"
              required
              value={getStateValue(keyID, 'city')}
              error={getErrorState(keyID, 'city')}
              onChange={event =>
                handleFieldChange('city', event.target.value, keyID)
              }
            />
            {getErrorState(keyID, 'city') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'city')}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={1}>
          <div className={classes.field}>
            <TextField
              className={classNames(classes.textField)}
              label="State"
              margin="dense"
              variant="outlined"
              name="state"
              required
              value={getStateValue(keyID, 'state').toUpperCase()}
              error={getErrorState(keyID, 'state')}
              onChange={event =>
                handleFieldChange('state', event.target.value.toUpperCase(), keyID)
              }
              inputProps={
                {
                  maxLength: 2
                }
              }
            />
            {getErrorState(keyID, 'state') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'state')}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item lg={2}>
          <div className={classes.field}>
            <TextField
              className={classNames(classes.textField)}
              label="Zipcode"
              margin="dense"
              variant="outlined"
              name="zipCode"
              required
              value={getStateValue(keyID, 'zipCode')}
              error={getErrorState(keyID, 'zipCode')}
              onChange={event =>
                handleFieldChange('zipCode', event.target.value, keyID)
              }
              inputProps={
                {
                  maxLength: 10
                }
              }
            />
            {getErrorState(keyID, 'zipCode') && (
              <Typography className={classes.fieldError} variant="body2">
                {getErrorStateValue(keyID, 'zipCode')}
              </Typography>
            )}
          </div>
        </Grid>
      </Grid> : null}
      <Grid container key={'agent_details_grid_secondary' + keyID}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Divider className={classes.gridDivider} />
        </Grid>
      </Grid>
    </Fragment>
  );
});

export default withStyles(styles)(AssistantsProfile);
