import React, { Component } from "react";
import Overlay55 from "./overlay-55.png";
import Overlay65 from "./overlay-65.png";
import Overlay75 from "./overlay-75.png";

class DBIndicator extends Component {
  determineBenefit = (benefit) => {
    if (benefit === "Seller's") {
      return "— A Seller's Advantage";
    } else if (benefit === "buyer's") {
      return "— A Buyers Advantage";
    }
  };
  render() {
    const { statement, header, logic, index, benefit } = this.props;
    let shadowToUse;
    if (+index === 1) {
      shadowToUse = Overlay55;
    } else if (+index === 2) {
      shadowToUse = Overlay65;
    } else if (+index === 3) {
      shadowToUse = Overlay65;
    } else if (+index === 4) {
      shadowToUse = Overlay75;
    }
    let logicStart = logic.slice();
    // console.log("logicStart", logicStart);
    let benefitSentence = logic.slice();
    if (benefit) {
      benefitSentence += `<span class="indicator-benefit">${this.determineBenefit(
        benefit
      )}</span>`;
    }

    return (
      <div className="db-indicator">
        <p className={`indicator-header-num header-num-${index}`}>{index}</p>
        <div className="db-divider" />
        <div className="db-floating-box">
          <div
            className={`db-box-inner-container box-inner-${
              index & 1 ? "odd" : "even"
            }`}
          >
            <div className="indicator-header">
              <p className="indicator-header-category">{header}</p>
            </div>

            <div
              className="indicator-explanation"
              dangerouslySetInnerHTML={{ __html: statement }}
            ></div>
            <div className="indicator-advantage-and-benefit-container">
              <div className="indicator-advantage">
                <div
                  dangerouslySetInnerHTML={{ __html: benefitSentence }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DBIndicator;
