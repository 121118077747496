import React from 'react';
import PropTypes, { string } from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { isArray } from 'util';

const styles = theme => ({
    card: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    websiteUrl: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        textDecoration: 'none',
    },
});

const AgentDetail = (props) => {
    const { classes, theme, agentData } = props;

    const getPhotoUrl = (photoURL) => {
        if (isArray(photoURL)) {
            return photoURL[0];
        } else {
            return photoURL;
        }
    };

    const openAgentWebsite = (website) => {
        if (!website.match(/^[a-zA-Z]+:\/\//)) {
            website = 'http://' + website;
        }
        window.open(website, '_blank');
    };

    return (
        <Card className={classes.card}>
            {agentData.agentPhotoURLs ?
                <CardMedia
                    className={classes.cover}
                    image={getPhotoUrl(agentData.agentPhotoURLs)}
                    title="Agent image"
                />
                : ''}
             {agentData.photoURL ?
                <CardMedia
                    className={classes.cover}
                    image={getPhotoUrl(agentData.agentPhotoURLs)}
                    title="Agent image"
                />
                : ''}    
            <div className={classes.details}>

                <CardContent className={classes.content}>
                    <Typography component="h6" variant="h6">
                        {agentData.fullName}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        {
                            (agentData.addressLine1 ? agentData.addressLine1 + ', ' : '') +
                            (agentData.addressCityName ? agentData.addressCityName + ', ' : '') +
                            (agentData.addressStateCode ? agentData.addressStateCode : '')
                        }
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                        <strong>License Number:&nbsp;</strong> {agentData.licenseNumber ? agentData.licenseNumber : 'N/A'}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                    <strong>Mobile Phone:&nbsp;</strong> {agentData.mobilePhone ? agentData.mobilePhone : 'N/A'}
                    </Typography>
                </CardContent>
                {agentData.agentURL ?
                    <CardActions>
                        <Button size="small" onClick={event => openAgentWebsite(agentData.agentURL)} className={classes.websiteUrl}>
                            Visit Agent Website
                    </Button>
                    </CardActions>
                    : ''}
            </div>

        </Card>
    );
}

AgentDetail.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    agentData: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(AgentDetail);
