import React, { useEffect, useState, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, CircularProgress, Grid, Dialog, DialogContent, Button, Typography, Card, CardMedia, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import styles from './styles';
import { GetAgentDetail } from 'services/agent';
import { isArray } from 'util';
import { Portlet, PortletContent, PortletHeader, PortletLabel } from 'components';
import { MediaViewComponent } from 'components';
import { Store } from 'Store';
import Base from 'common/constants';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AgentProfileComponent = props => {
  const { classes, className, onOpen, onClose, listgenUserID } = props;

  const noImageURL = '/images/no-image.png';

  const rootClassName = classNames(classes.root, className);

  const [open, setOpen] = useState(true);

  const [isLoading, setLoading] = useState(false);

  const [message, setMessage] = useState(null);

  const [agentData, setAgentData] = useState({});

  /**local state integration */
  const { state, dispatch } = useContext(Store);
  const { userData } = state;

  /**media view */
  const [mediaURL, setMediaURL] = useState(null);
  const [mediaTitle, setMediaTitle] = useState(null);
  const [openMediaView, setOpenMediaView] = useState(false);
  /**PDF test regex */
  const regexWithPDF = new RegExp(/^.*\.(PDF|pdf)$/);

  const handleClickOpen = () => {
    setOpen(true);
    if (onOpen) {
      onOpen();
    }
  }

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getAgentProfile();
    }, 200);
  }, [listgenUserID]);

  const getAgentProfile = () => {
    /**make loading true */
    setLoading(true);
    try {
      GetAgentDetail(listgenUserID)
        .then(data => {
          setAgentData(data);
          // if (data.length > 0) {
          //   this.setState({
          //     allAgentData: data,
          //     isLoading: false
          //   });
          // } else {
          //   //showSuccessNotification(this.props, "No agents found.");
          //   setMessage('No agent detail found.');
          // }
          setLoading(false);
        })
        .catch(err => {
          setMessage('Unable to process request.');
          setLoading(false);
        });
    } catch (err) {
      setMessage('Unable to process request.');
      setLoading(false);
    }
  };

  const formatPhoneNumber = (phoneNumberString) => {
    /** check for null values */
    if (!phoneNumberString) {
      return '';
    }
  
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  
    // Handle numbers with more than 6 digits
    if (cleaned.length > 6) {
      var match = cleaned.match(/^(\d{3})(\d{3})(\d+)$/);
      if (match) {
        return match[1] + '-' + match[2] + '-' + match[3];
      }
    }  
    // If the number has less than 6 digits, return it as is
    return null;
  };
  

  const openMediaViewDialog = (mediaURL, mediaTitle) => {
    setMediaURL(mediaURL);
    setMediaTitle(mediaTitle);
    setOpenMediaView(true);
  };


  const closeMediaViewDialog = () => {
    setMediaURL(null);
    setMediaTitle(null);
    setOpenMediaView(false);
  };

  const getAgentAreas = () => {
    if (agentData && agentData.navbarInfo && Array.isArray(agentData.navbarInfo)) {
      return agentData.navbarInfo.map(area => {
        return `${area.mlsNeighborhood} (${area.mlsAreaID})`
      }).join(', ');
    } else {
      return '---';
    }
  };

  const openPDFInNewTab = (url) => {
    var win = window.open(url, '_blank');
    win.focus();
  };


  return (
    <div className={rootClassName}>
      {openMediaView ?
        <MediaViewComponent
          mediaURL={mediaURL}
          mediaTitle={mediaTitle}
          onOpen={openMediaViewDialog}
          onClose={closeMediaViewDialog}
        />
        : ''}
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar} color="default">
          <Toolbar>
            {/* <IconButton edge="start" color="default" onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton> */}
            <Typography variant="h4" className={classes.title}>
              Agent Profile
            </Typography>
            <Button color="inherit" className={classes.colorWhite} onClick={handleClose}>
              Close Profile
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {isLoading ?
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
            : ''}
          {message ?
            <Typography variant="subtitle2" className={classes.messageBox}>{message}</Typography>
            : ''}
          {/* {agentData} */}
          {(Object.keys(agentData).length > 0) ?
            <div className={classes.agentDetailsOuter}>
              <Grid container className={classNames(classes.grid, classes.mainGrid)}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className={classes.agentDetails}>
                    <Portlet>
                      <PortletContent>
                        <Grid container className={classes.grid}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h6" className={classes.boldFont}>
                              Assigned MLS Area's: {getAgentAreas()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </PortletContent>
                    </Portlet>
                    <Portlet className={classes.portletMainClass}>
                      <PortletHeader>
                        <PortletLabel
                          title="Personal Info"
                        />
                      </PortletHeader>
                      <PortletContent>
                        <Grid container className={classes.grid}>
                          <Grid item lg={4} md={4} sm={6} xs={6}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="First Name"
                                  secondary={agentData.listgenUserFirstName}
                                />
                              </ListItem>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Agent License Number"
                                  secondary={agentData.agentLicenseNumber}
                                />
                              </ListItem>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Mobile Phone"
                                  secondary={formatPhoneNumber(agentData.mobilePhoneNo)}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                          <Grid item lg={4} md={4} sm={6} xs={6}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Last Name"
                                  secondary={agentData.listgenUserLastName}
                                />
                              </ListItem>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Brokerage License Number"
                                  secondary={agentData.brokerLicenseNumber}
                                />
                              </ListItem>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Office Phone"
                                  secondary={formatPhoneNumber(agentData.officePhoneNo)}
                                />
                              </ListItem>
                            </List>

                          </Grid>
                          <Grid item lg={4} md={4} sm={6} xs={6}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Email"
                                  secondary={agentData.emailAddress}
                                />
                              </ListItem>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Agent Designation"
                                  secondary={agentData.agentDesignation}
                                />
                              </ListItem>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Brokerage Name"
                                  secondary={agentData.brokerageName}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                        </Grid>
                      </PortletContent>
                    </Portlet>

                    <Portlet className={classes.portletMainClass}>
                      <PortletHeader>
                        <PortletLabel
                          title="Assistant Details"
                        />
                      </PortletHeader>
                      <PortletContent>
                        <Grid container className={classes.grid}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            {agentData.agentAssistantDTOs && Array.isArray(agentData.agentAssistantDTOs) && agentData.agentAssistantDTOs.length > 0 ?
                              <Fragment>
                                <Table className={classes.table} size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>First Name</TableCell>
                                      <TableCell>Last Name</TableCell>
                                      <TableCell>Phone</TableCell>
                                      <TableCell>Email</TableCell>
                                      <TableCell>Send PMA Copy?</TableCell>
                                      <TableCell>Mailing Address</TableCell>
                                      <TableCell>City</TableCell>
                                      <TableCell>State</TableCell>
                                      <TableCell>Zipcode</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {agentData.agentAssistantDTOs.map(row => (
                                      <TableRow key={row.name}>
                                        <TableCell component="th" scope="row">
                                          {row.assistantFirstName}
                                        </TableCell>
                                        <TableCell>{row.assistantLastName}</TableCell>
                                        <TableCell>{formatPhoneNumber(row.phone)}</TableCell>
                                        <TableCell>{row.emailID}</TableCell>
                                        <TableCell>{row.sendPMACopy ? "Yes" : "No"}</TableCell>
                                        <TableCell>{row.mailingAddr1}</TableCell>
                                        <TableCell>{row.city}</TableCell>
                                        <TableCell>{row.state}</TableCell>
                                        <TableCell>{row.zipCode}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Fragment>
                              : <Typography variant="subtitle2">
                                No Assistant Details Found
                              </Typography>
                            }
                          </Grid>
                        </Grid>
                      </PortletContent>
                    </Portlet>



                    <Portlet className={classes.portletMainClass}>
                      <PortletHeader>
                        <PortletLabel
                          title="Mailing Address"
                        />
                      </PortletHeader>
                      <PortletContent>
                        <Grid container className={classes.grid}>
                          <Grid item lg={4} md={4} sm={6} xs={6}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Mailing Address Line 1"
                                  secondary={agentData.mailingAddressLine1}
                                />
                              </ListItem>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="City"
                                  secondary={agentData.mailingCity}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                          <Grid item lg={4} md={4} sm={6} xs={6}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Mailing Address Line 2"
                                  secondary={agentData.mailingAddressLine2}
                                />
                              </ListItem>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="State"
                                  secondary={agentData.mailingState}
                                />
                              </ListItem>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Zipcode"
                                  secondary={agentData.mailingZipCode}
                                />
                              </ListItem>
                            </List>

                          </Grid>
                          <Grid item lg={4} md={4} sm={6} xs={6}>
                          </Grid>
                        </Grid>
                      </PortletContent>
                    </Portlet>
                    <Portlet className={classes.portletMainClass}>
                      <PortletHeader>
                        <PortletLabel
                          title="Agent Marketing"
                        />
                      </PortletHeader>
                      <PortletContent>
                        <Grid container className={classes.grid}>
                          <Grid item lg={4} md={4} sm={6} xs={6}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Website"
                                  secondary={agentData.websiteURL}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                          <Grid item lg={4} md={4} sm={6} xs={6}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Display Email"
                                  secondary={agentData.displayEmailAddress}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Tagline"
                                  secondary={agentData.tagLine}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                        </Grid>
                        <Grid container className={classes.grid}>
                          <Grid item lg={4} md={4} sm={6} xs={6}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Office Address Line 1"
                                  secondary={agentData.officeAddressLine1}
                                />
                              </ListItem>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Office City"
                                  secondary={agentData.officeCity}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                          <Grid item lg={4} md={4} sm={6} xs={6}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Office Address Line 2"
                                  secondary={agentData.officeAddressLine2}
                                />
                              </ListItem>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="State"
                                  secondary={agentData.officeState}
                                />
                              </ListItem>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Zipcode"
                                  secondary={agentData.officeZipcode}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                          <Grid item lg={4} md={4} sm={6} xs={6}>
                          </Grid>
                        </Grid>

                        <Grid container className={classes.grid}>
                          {/* User Image Section */}
                          <Grid item lg={3} md={3} sm={12} xs={12}>
                            <Typography variant="h5" className={classes.stepHeader}>
                              Agent Photo
                            </Typography>
                            <Divider className={classes.dividerRightMargin} />
                            <div className={classes.imageHolder}>
                              {agentData.photoURL ? (
                                <div className={classes.imageHolder}>
                                  <img
                                    src={agentData.photoURL}
                                    alt={"Agent Profile"}
                                    className={classes.imageTag}
                                    onClick={event => openMediaViewDialog(agentData.photoURL, 'Agent Profile Image')}
                                  />
                                </div>
                              ) : (
                                <div className={classes.imageHolder}>
                                  <img
                                    src={noImageURL}
                                    alt={"No Agent Profile Image"}
                                    className={classes.imageTag}
                                  />
                                </div>
                              )}
                            </div>
                          </Grid>
                          <Grid item lg={3} md={3} sm={12} xs={12}>
                            <Typography variant="h5" className={classes.stepHeader}>
                              Agent Light Background Logo
                            </Typography>
                            <Divider className={classes.dividerRightMargin} />
                            <div className={classes.imageHolder}>
                              {agentData.logoURL ? (
                                <div className={classes.imageHolder}>
                                  <img
                                    src={agentData.logoURL}
                                    alt={"Agent Light Background Logo"}
                                    className={classes.imageTag}
                                    onClick={event => openMediaViewDialog(agentData.logoURL, 'Agent Light Background Logo')}
                                  />
                                </div>
                              ) : (
                                <div className={classes.imageHolder}>
                                  <img
                                    src={noImageURL}
                                    alt={"No Agent Light Background Logo"}
                                    className={classes.imageTag}
                                  />
                                </div>
                              )}
                            </div>
                          </Grid>
                          <Grid item lg={3} md={3} sm={12} xs={12}>
                            <Typography variant="h5" className={classes.stepHeader}>
                              Agent Dark Background Logo
                            </Typography>
                            <Divider className={classes.dividerRightMargin} />
                            <div className={classes.imageHolder}>
                              {agentData.logoURL2 ? (
                                <div className={classes.imageHolder}>
                                  <img
                                    src={agentData.logoURL2}
                                    alt={"Agent Dark Background Logo"}
                                    className={classes.imageTag}
                                    onClick={event => openMediaViewDialog(agentData.logoURL2, 'Agent Dark Background Logo')}
                                  />
                                </div>
                              ) : (
                                <div className={classes.imageHolder}>
                                  <img
                                    src={noImageURL}
                                    alt={"No Agent Dark Background Logo"}
                                    className={classes.imageTag}
                                  />
                                </div>
                              )}
                            </div>
                          </Grid>
                          <Grid item lg={3} md={3} sm={12} xs={12}>
                            <Typography variant="h5" className={classes.stepHeader}>
                              Agent Brokerage Logo
                            </Typography>
                            <Divider className={classes.dividerRightMargin} />
                            <div className={classes.imageHolder}>
                              {agentData.brokerageLogoURL ? (
                                <div className={classes.imageHolder}>
                                  <img
                                    src={agentData.brokerageLogoURL}
                                    alt={"Agent Brokerage Logo"}
                                    className={classes.imageTag}
                                    onClick={event => openMediaViewDialog(agentData.brokerageLogoURL, 'Agent Brokerage Logo')}
                                  />
                                </div>
                              ) : (
                                <div className={classes.imageHolder}>
                                  <img
                                    src={noImageURL}
                                    alt={"No Agent Brokerage Logo"}
                                    className={classes.imageTag}
                                  />
                                </div>
                              )}
                            </div>
                          </Grid>
                          {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography variant="h5" className={classes.stepHeader}>
                              Agent Signature
                      </Typography>
                            <Divider className={classes.dividerRightMargin} />
                            <div className={classes.imageHolder}>
                              {agentData.signatureURL ? (
                                <div className={classes.imageHolder}>
                                  {regexWithPDF.test(agentData.signatureURL.toLowerCase()) ? (
                                    <IconButton
                                      color="primary"
                                      title="Click to view PDF"
                                      onClick={event => openPDFInNewTab(agentData.signatureURL)}
                                    >
                                      <PictureAsPdfIcon fontSize="large" className={classes.pdfIcon}/>
                                    </IconButton>) : (
                                      <img
                                        src={agentData.signatureURL}
                                        alt={"Agent Signature"}
                                        className={classes.imageTag}
                                        onClick={event => openMediaViewDialog(agentData.signatureURL, 'Agent Signature')}
                                      />
                                    )}
                                </div>
                              ) :
                                <div className={classes.imageHolder}>
                                  <img
                                    src={noImageURL}
                                    alt={"No Agent Signature"}
                                    className={classes.imageTag}
                                  />
                                </div>
                              }
                            </div>
                          </Grid> */}
                        </Grid>
                      </PortletContent>
                    </Portlet>
                    <Portlet className={classes.portletMainClass}>
                      <PortletHeader>
                        <PortletLabel
                          title="Billing Address"
                        />
                      </PortletHeader>
                      <PortletContent>
                        <Grid container className={classes.grid}>
                          <Grid item lg={4} md={4} sm={6} xs={6}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Billing address same to mailing address"
                                  secondary={(agentData.isBillingAddressSame === true || agentData.isBillingAddressSame === 'true') ? "Yes" : "No"}
                                />
                              </ListItem>
                            </List>
                            <List>

                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Billing Address Line 1"
                                  secondary={agentData.billingAddressLine1}
                                />
                              </ListItem>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="City"
                                  secondary={agentData.billingCity}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                          <Grid item lg={4} md={4} sm={6} xs={6}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="First Name"
                                  secondary={agentData.billingFirstName}
                                />
                              </ListItem>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Billing Address Line 2"
                                  secondary={agentData.billingAddressLine2}
                                />
                              </ListItem>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="State"
                                  secondary={agentData.billingState}
                                />
                              </ListItem>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Zipcode"
                                  secondary={agentData.billingZipCode}
                                />
                              </ListItem>
                            </List>

                          </Grid>
                          <Grid item lg={4} md={4} sm={6} xs={6}>
                            <List>
                              <ListItem className={classes.listItemText}>
                                <ListItemText
                                  primary="Last Name"
                                  secondary={agentData.billingLastName}
                                />
                              </ListItem>
                            </List>
                          </Grid>
                        </Grid>
                      </PortletContent>
                    </Portlet>
                    {userData.listgenRoleID === Base.APP_DATA.APP_ADMIN_ROLE_ID ?
                      <Portlet className={classes.portletMainClass}>
                        <PortletHeader>
                          <PortletLabel
                            title="Others"
                          />
                        </PortletHeader>
                        <PortletContent>
                          <Grid container className={classes.grid}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography variant="h5" className={classes.stepHeader}>

                              </Typography>
                              {/* <Divider /> */}
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={6}>
                              <List>
                                {/* <ListItem className={classes.listItemText}>
                                  <ListItemText
                                    primary="MLS Area's"
                                    secondary={getAgentAreas()}
                                  //secondary={isArray(agentData.ownedMLSID) ? agentData.ownedMLSID.join(',') : ''}
                                  />
                                </ListItem> */}
                                <ListItem className={classes.listItemText}>
                                  <ListItemText
                                    primary="User Creation Date"
                                    secondary={agentData.userCreationDate}
                                  />
                                </ListItem>
                              </List>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={6}>
                              <List>
                                <ListItem className={classes.listItemText}>
                                  <ListItemText
                                    primary="Current Status"
                                    secondary={agentData.userStatus}
                                  />
                                </ListItem>
                                <ListItem className={classes.listItemText}>
                                  <ListItemText
                                    primary="Password Change Date"
                                    secondary={agentData.passwordChangeDate}
                                  />
                                </ListItem>
                              </List>

                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={6}>
                              <List>
                                <ListItem className={classes.listItemText}>
                                  <ListItemText
                                    primary="Status Description"
                                    secondary={agentData.statusDescription}
                                  />
                                </ListItem>
                              </List>
                            </Grid>
                          </Grid>
                        </PortletContent>
                      </Portlet>
                      : ''}
                    <Portlet className={classes.portletMainClass}>
                      <PortletHeader>
                        <PortletLabel
                          title="Team Members"
                        />
                      </PortletHeader>
                      <PortletContent>
                        <Grid container className={classes.grid}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            {agentData.teamMembers ?
                              <Fragment>
                                <List>
                                  <ListItem className={classes.listItemText}>
                                    <ListItemText
                                      primary="Is Member of a Team"
                                      secondary={(agentData.isMemberOfTeam || agentData.isMemberOfTeam === 'true') ? 'Yes' : 'No'}
                                    />
                                  </ListItem>
                                  <ListItem className={classes.listItemText}>
                                    <ListItemText
                                      primary="Team Name"
                                      secondary={agentData.teamName}
                                    />
                                  </ListItem>
                                </List>
                                <Table className={classes.table} size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>First Name</TableCell>
                                      <TableCell>Last Name</TableCell>
                                      <TableCell>Phone</TableCell>
                                      <TableCell>Email</TableCell>
                                      <TableCell>Agent License No</TableCell>
                                      <TableCell>Agent Designation</TableCell>
                                      <TableCell>Mailing Address 1</TableCell>
                                      {/* <TableCell>Mailing Address 2</TableCell> */}
                                      <TableCell>City</TableCell>
                                      <TableCell>State</TableCell>
                                      <TableCell>Zipcode</TableCell>
                                      {/* <TableCell>Agent Signature</TableCell> */}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {agentData.teamMembers.map(row => (
                                      <TableRow key={row.name}>
                                        <TableCell component="th" scope="row">
                                          {row.firstName}
                                        </TableCell>
                                        <TableCell>{row.lastName}</TableCell>
                                        <TableCell>{formatPhoneNumber(row.phone)}</TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{row.agentLicenseNumber}</TableCell>
                                        <TableCell>{row.agentDesignation}</TableCell>
                                        <TableCell>{row.mailingAddress1}</TableCell>
                                        {/* <TableCell>{row.mailingAddress2}</TableCell> */}
                                        <TableCell>{row.city}</TableCell>
                                        <TableCell>{row.state}</TableCell>
                                        <TableCell>{row.zipcode}</TableCell>
                                        {/* <TableCell>
                                          <Button
                                            color="primary"
                                            size="small"
                                            onClick={event => openMediaViewDialog((row.agentSignature ? row.agentSignature : noImageURL), 'Team Member Signature')}
                                          >
                                            View
                                            </Button>
                                        </TableCell> */}
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Fragment>
                              : <Typography variant="subtitle2">
                                No Team Members Found
                              </Typography>
                            }
                          </Grid>
                        </Grid>
                      </PortletContent>
                    </Portlet>
                  </div>

                </Grid>
                {/* <Grid item lg={1} md={2} sm={1} xs={1}></Grid> */}
              </Grid>
            </div>
            : ''}
        </DialogContent>
      </Dialog>
    </div>
  );
};

AgentProfileComponent.propTypes = {
  listgenUserID: PropTypes.string.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
};

AgentProfileComponent.defaultProps = {
  onOpen: () => { },
  onClose: () => { }
};

export default withStyles(styles)(AgentProfileComponent);

