import React, { Component } from 'react';

class PSContents extends Component {
  render() {
    const {
      version,
      theHomeowners,
      hoTextPS,
      versionGlobalPS,
      isMemberOfTeam,
      teamMembers,
      agentFirstNames,
      listgenUserFirstName,
      step,
    } = this.props;
    return (
      <div
        className={
          'attention-grabber-or-ps ' +
          (version === 'q' ? 'q ' : ' mq ') +
          (step === 'mp' ? 'mp' : '')
        }
      >
        <div className={'mq-ps-container ' + (version === 'q' ? 'q' : 'mq')}>
          {version === 'q' ? (
            <div className="q">
              <p className="homeowners-salutation">Hi {theHomeowners},</p>

              <p
                className={'ps-text ' + (version === 'q' ? 'q' : 'mq')}
                // dangerouslySetInnerHTML={{
                //   __html: hoTextPS
                //     ? hoTextPS
                //     : versionGlobalPS
                //     ? versionGlobalPS
                //     : 'Give me a call today!',
                // }}
                dangerouslySetInnerHTML={{
                  __html: hoTextPS,
                }}
              ></p>

              <div className="agent-signoff">
                <p className="signoff">Warm regards,{'  '}</p>

                {isMemberOfTeam && teamMembers.length === 2 ? (
                  <p className="indent-20px">
                    {agentFirstNames(listgenUserFirstName, teamMembers)}
                  </p>
                ) : (
                  <p className="indent-20px">{listgenUserFirstName}</p>
                )}
              </div>
            </div>
          ) : (
            <div className="mq-ps">
              <p className="homeowners-salutation">Hi {theHomeowners},</p>
              <p
                className={'ps-text ' + (version === 'q' ? 'q' : 'mq')}
                dangerouslySetInnerHTML={{
                  __html: hoTextPS,
                }}
                // dangerouslySetInnerHTML={{
                //   __html: hoTextPS
                //     ? hoTextPS
                //     : versionGlobalPS
                //     ? versionGlobalPS
                //     : 'Give me a call today!',
                // }}
              ></p>
              <div className="agent-signoff">
                <p className="signoff">Warm regards,{'  '}</p>

                {isMemberOfTeam && teamMembers.length === 2 ? (
                  <p className="indent-20px">
                    {agentFirstNames(listgenUserFirstName, teamMembers)}
                  </p>
                ) : (
                  <p className="indent-20px">{listgenUserFirstName}</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PSContents;
