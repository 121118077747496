import React, { Component } from "react";
import Cloud from "./transparent-cloud.png";
import { propertyUtil } from "views/Pma/PropertyUtil";

class CloudSection extends Component {
  render() {
    const {
      splitAreas,
      coverPageAreaFontSize,
      filterOutSubdivision
    } = propertyUtil;
    const { currentArea, hoData } = this.props;
    const { subdivisionName, parcelNumber } = hoData;
    const { mlsNeighborhood } = currentArea;

    let areaSplit = splitAreas(mlsNeighborhood);
    let filteredOut;
    if (parcelNumber === "0" || !subdivisionName) {
      filteredOut = null;
    } else {
      filteredOut = filterOutSubdivision(mlsNeighborhood, subdivisionName);
    }

    if (subdivisionName && subdivisionName.length > 1) {
      return (
        <div className="cloud-section">
          <div className="cloud-section-area-container">
            <div className="cloud-section-title">{subdivisionName}</div>
            <div className="cloud-section-subtitle">Your Neighborhood</div>
          </div>

          <img src={Cloud} alt="Cloud" className="qv2-cloud-img" />
        </div>
      );
    } else if (areaSplit && areaSplit.length > 1) {
      return (
        <div className="cloud-section">
          <div className="cloud-section-area-container">
            <div className="cloud-section-title">Your Neighborhood</div>
            <div className="cloud-section-subtitle">
              {areaSplit.join(" + ")}
            </div>
          </div>

          <img src={Cloud} alt="Cloud" className="qv2-cloud-img" />
        </div>
      );
    } else {
      return (
        <div className="cloud-section">
          <div className="cloud-section-area-container">
            <div className="cloud-section-title">{mlsNeighborhood}</div>
            <div className="cloud-section-subtitle">Your Neighborhood</div>
          </div>

          <img src={Cloud} alt="Cloud" className="qv2-cloud-img" />
        </div>
      );
    }
  }
}

export default CloudSection;
