import React, {Fragment} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { IconButton, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ImageIcon from '@material-ui/icons/Image';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PrintFormatDialog({ version, viewPMAURL }) {
    const [open, setOpen] = React.useState(false);
    const dialogRef = React.useRef(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // const isVaidUrl = (url) => url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);

    const { innerWidth: width, innerHeight: height } = window;

    return (
        <div>
            {/* <IconButton color="primary" aria-label="View Version" component="span" onClick={handleClickOpen} disabled={!version}>
                <PictureAsPdfIcon />
            </IconButton> */}
            <IconButton color="primary" aria-label="View Version" onClick={handleClickOpen} disabled={!version}>
                <ImageIcon style={{ width: '32px', height: '32px' }} />
            </IconButton>
            {version ? <Fragment>Click to <br />View PMA</Fragment> : 'Select version to view PMA'}
           {open ? <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                fullScreen
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                ref={dialogRef}
            >
                {/* <DialogTitle id="alert-dialog-slide-title" style={{ height: '20px' }}>
                    <div style={{ display: 'flex', width: '100%', height: '20px', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h5">
                            {version} PMA
                        </Typography>
                        <Button onClick={handleClose} color="primary" variant="contained" size="small">
                            Ok, Got it!
                        </Button>
                    </div>
                </DialogTitle> */}
                <DialogContent
                    style={{ padding: 0, overflowY: 'hidden' }}>
                    {/* <object data="./test-pma-100047-165.pdf" type="application/pdf" width="100%" height="100%" /> */}
                    <div style={{ position: 'absolute', width: '300px', height: '56px', right: '0px', top: '0px', background: 'white' }}>
                        <Button onClick={handleClose} color="default" variant="contained" style={{ background: red[500], color: 'white', left: '100px', top: '10px', position: 'relative' }}>
                            Ok, Got it!
                        </Button>
                    </div>
                    {/* // require("./pdfs/test-pma-100076-108.pdf") */}
                    <iframe id="pdf-pma-version-viewer" src={viewPMAURL} width={(width) + 'px'} height={(height) + 'px'} frameBorder="0" title='XYZ CCC' name='KKKKKk' />
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Ok, Got it!
                    </Button>
                </DialogActions> */}
            </Dialog>
            : null}
        </div>
    );
}
