import { green } from "@material-ui/core/colors";

export default theme => ({
    root: {},
    dialogContent: {
        marginTop: theme.spacing(2)
    },
    footerButtons: {
        position: 'relative',
        display: 'flex',
    },
    portletFooter: {
        display: 'inline-block',
        //paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),
        //paddingBottom: theme.spacing(1),
        width: '100%',
        marginLeft: '8px',
        marginRight: '8px'
    },
    footerButtonsCenter: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    footerButtonsLeftRight: {
        flex: 1,
    },
    rightAlign: {
        textAlign: 'end'
    },
    contentDisplay: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    colorGreen: {
        color: green[500],
    },
    congratsText: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    cardSection: {
        width: '100%',
        height: '300px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    bottomText: {
        marginTop: theme.spacing(2)
    }
});