import React, { Fragment } from "react";

import "./index.scss";
import SiteAddress from "../components/SiteAddress";
import PhotoView from "../components/PhotoView";
import AbsenteeHO from "../components/AbsenteeHO";
import LastSaleView from "../components/LastSaleView";
import BlockPropertyDialog from "../components/BlockPropertyDialog";
import EditTagsDialog from "../components/EditTagsDialog";
import EditHomeownerDialogV2 from "../components/EditHomeownerDialogV2";
import PreviewPMAFromMailPro from "../components/PreviewPMAFromMailPro";
import { renderLogicUtil } from "views/Pma/RenderLogicUtil";
import CustomNoteDialogV2 from "../components/Notes/PersonalNote/CustomNoteDialogV2";
import GlobalNoteDialogV2 from "../components/Notes/GlobalNote/GlobalNoteDialogV2";
import MailProValuationSummary from "../components/HomeValuations/MailProValuationSummary";
import BuyerandListingAgentView from "../components/BuyerandListingAgentView";
import { propertyUtil } from "views/Pma/PropertyUtil";

class PropertyRecord extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      property, //property object
      propertyData,
      propertyDetails,
      mailingDetails,
      areaID,
      mailerProID,
      agentID,
      mailingStatus,
      changeDetails,
      isPropertyMapped,
      openMediaViewDialog,
      agentData,
      nextPMAPrintDate,
      lastPMAPrintDate,
      currentArea,
      propertySequenceNumber,
      hoaSubdivisionName,
      isMailProLocked
    } = this.props;
    const { blocked } = this.props
      .changeDetails
      ? this.props.changeDetails
      : {};
    const {
      SiteNumber,
      SiteStreet,
      SiteCity,
      SiteState,
      SiteZip,
      Parcel,
      // Bathfull,
      // Lotacres,
      OwnerFirst,
      OwnerLast
    } = mailingDetails;

    /**listing status */
    const { listingStatus } = propertyDetails ? propertyDetails : {};
    //check for the property details and mailing details
    propertyDetails = propertyDetails ? propertyDetails : {};
    mailingDetails = mailingDetails ? mailingDetails : {};


    /**get the html data */
    return (
      <tr className={"property-record " + (!blocked ? "" : "blocked")}>
        <td>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%', flexDirection: 'column' }}>
            <div style={{ marginTop: '8px', marginLeft: '24px' }} title={"MailPro Sequence Number"}>#{propertySequenceNumber}</div>
            <p>
              <br />
              <br />
            </p>
            <div onClick={this.handleOptions} style={{ marginTop: '-16px' }}>
              <BlockPropertyDialog
                blocked={blocked}
                areaID={areaID}
                agentID={agentID}
                mailerProID={mailerProID}
                mailingStatus={mailingStatus}
                changeDetails={changeDetails}
                propertyData={propertyData}
                propertyDetails={propertyDetails}
                agentData={agentData}
                mailingDetails={mailingDetails}
                propertySequenceNumber={propertySequenceNumber}
                property={property}
                isMailProLocked={isMailProLocked}
              />
            </div>
            {!blocked ? <PreviewPMAFromMailPro
              mailerProID={mailerProID}
              agentID={agentID}
              userData={agentData}
              customStyle={{ marginLeft: '24px', padding: '8px 2px', fontSize: '10px', width: '50px' }}
            /> : <div></div>}
          </div>
        </td>
        <td align="center" valign="top">
          <EditHomeownerDialogV2
            mailingDetails={mailingDetails}
            areaID={areaID}
            agentID={agentID}
            mailerProID={mailerProID}
            mailingStatus={mailingStatus}
            changeDetails={changeDetails}
            propertyData={propertyData}
            propertyDetails={propertyDetails}
            blocked={blocked}
            propertySequenceNumber={propertySequenceNumber}
            property={property}
            isPropertyMapped={isPropertyMapped}
            isMailProLocked={isMailProLocked}
          />
        </td>
        {/* <td> {webMap}</td> */}
        <td align="center" valign="top">
          <GlobalNoteDialogV2
            changeDetails={changeDetails}
            propertyDetails={propertyDetails}
            propertyData={propertyData}
            mailerProID={mailerProID}
            mailingDetails={mailingDetails}
            agentData={agentData}
            propertySequenceNumber={propertySequenceNumber}
            property={property}
            lastPMAPrintDate={lastPMAPrintDate}
            isPropertyMapped={isPropertyMapped}
            isMailProLocked={isMailProLocked}
          />
        </td>

        <td align="center" valign="top">
          <CustomNoteDialogV2
            cameFrom="PersonalPS"
            agentID={agentID}
            areaID={areaID}
            mailerProID={mailerProID}
            mailingStatus={mailingStatus}
            changeDetails={changeDetails}
            propertyDetails={propertyDetails}
            propertyData={propertyData}
            mailingDetails={mailingDetails}
            agentData={agentData}
            propertySequenceNumber={propertySequenceNumber}
            property={property}
            lastPMAPrintDate={lastPMAPrintDate}
            isPropertyMapped={isPropertyMapped}
            isMailProLocked={isMailProLocked}
          />
        </td>
        <td align="center" valign="top">
          <MailProValuationSummary 
          property={property}
          agentData={agentData}
          mailerProID={mailerProID}
          changeDetails={changeDetails}
          isMailProLocked={isMailProLocked}
          />
        </td>
        <td align="center" valign="top">
          <EditTagsDialog
            agentID={agentID}
            areaID={areaID}
            mailerProID={mailerProID}
            mailingStatus={mailingStatus}
            changeDetails={changeDetails}
            propertyDetails={propertyDetails}
            propertyData={propertyData}
            agentData={agentData}
            propertySequenceNumber={propertySequenceNumber}
            property={property}
            isPropertyMapped={isPropertyMapped}
            isMailProLocked={isMailProLocked}
          />
        </td>

        <td align="center" valign="top">
          <SiteAddress
            blocked={blocked}
            areaID={areaID}
            agentID={agentID}
            mailerProID={mailerProID}
            mailingStatus={mailingStatus}
            changeDetails={changeDetails}
            propertyData={propertyData}
            propertyDetails={propertyDetails}
            agentData={agentData}
            SiteNumber={SiteNumber}
            SiteStreet={SiteStreet}
            SiteCity={SiteCity}
            SiteState={SiteState}
            SiteZip={SiteZip}
            Parcel={Parcel}
            currentArea={currentArea}
            //hoaSubdivisionName={hoaSubdivisionName} //hoaSubdivisionName
            property={property}
            mailingDetails={mailingDetails}
            isPropertyMapped={isPropertyMapped}
            isMailProLocked={isMailProLocked}
          />
        </td>
        <td align="center" valign="top">
          <PhotoView
            isPropertyMapped={isPropertyMapped}
            propertyDetails={propertyDetails}
            mailingDetails={mailingDetails}
            listingStatus={listingStatus}
            openMediaViewDialog={openMediaViewDialog}
            propertySequenceNumber={propertySequenceNumber}
            property={property}
            agentData={agentData}
          />
        </td>
        <td align="center" valign="top">
          <AbsenteeHO
            propertyDetails={propertyDetails}
            mailingDetails={mailingDetails}
            areaID={areaID}
            agentID={agentID}
            mailerProID={mailerProID}
            mailingStatus={mailingStatus}
            changeDetails={changeDetails}
            propertyData={propertyData}
            agentData={agentData}
            propertySequenceNumber={propertySequenceNumber}
            property={property}
            lastPMAPrintDate={lastPMAPrintDate}
            isPropertyMapped={isPropertyMapped}
            isMailProLocked={isMailProLocked}
          />
        </td>
        <td align="center" valign="top">
          <LastSaleView
            propertyDetails={propertyDetails}
            mailingDetails={mailingDetails}
            areaID={areaID}
            agentID={agentID}
            mailerProID={mailerProID}
            mailingStatus={mailingStatus}
            changeDetails={changeDetails}
            isPropertyMapped={isPropertyMapped}
            propertyData={propertyData}
            blocked={blocked}
            nextPMAPrintDate={nextPMAPrintDate}
            lastPMAPrintDate={lastPMAPrintDate}
            agentData={agentData}
            propertySequenceNumber={propertySequenceNumber}
            property={property}
            isMailProLocked={isMailProLocked}
          />
        </td>
        <td align="center" valign="top">
          <BuyerandListingAgentView
           propertyDetails={this.props.propertyDetails}
           isPropertyMapped={isPropertyMapped}
           />
        </td>
      </tr>
    );
  }
}

export default PropertyRecord;
