import { makeStyles } from '@material-ui/core';
import React, { Component } from 'react';
import palette from 'theme/palette';

const useStyles = makeStyles({
    lgEllipsisContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    lgEllipsisLoader: {
        display: 'inline-block',
        position: 'relative',
        width: 'auto',
        height: 'auto',
        '&>div': {
            position: 'absolute',
            //top: '33px',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            background: palette.primary.main,
            animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)'
        },
        '&>div:nth-child(1)': {
            left: '4px',
            animation: '$lds-ellipsis1 0.6s infinite'
        },
        '&>div:nth-child(2)': {
            left: '4px',
            animation: '$lds-ellipsis2 0.6s infinite'
        },
        '&>div:nth-child(3)': {
            left: '16px',
            animation: '$lds-ellipsis2 0.6s infinite'
        },
        '&>div:nth-child(4)': {
            left: '28px',
            animation: '$lds-ellipsis3 0.6s infinite'
        }
    },
    "@keyframes lds-ellipsis1": {
        "0%": {
            transform: "scale(0)"
        },
        "100%": {
            transform: "scale(1)"
        }
    },
    "@keyframes lds-ellipsis3": {
        "0%": {
            transform: "scale(1)"
        },
        "100%": {
            transform: "scale(0)"
        }
    },
    "@keyframes lds-ellipsis2": {
        "0%": {
            transform: "translate(0, 0)"
        },
        "100%": {
            transform: "translate(12px, 0)"
        }
    }
});
export const EllipsisLoader = () => {
    const classes = useStyles();
    return <div className={classes.lgEllipsisContainer}>
        <div className={classes.lgEllipsisLoader}>
            <div></div><div></div><div></div><div></div>
        </div>
    </div>;
};


export default EllipsisLoader;