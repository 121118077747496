import React, { Component } from "react";
import TinyCoverBG from "_img/cover-page-bg/tiny_cover.jpg";
import YECoverPageData from "./YECoverPageData";
import YECoverPageInfo from "./YECoverPageInfo";
import { propertyUtil } from "views/Pma/PropertyUtil";

class YECoverPage extends Component {
  divideAreas = (areas) => {
    const { splitAreas, filterOutSubdivision } = propertyUtil;
    const { demoAccountAreaName } = this.props;
    const { subdivisionName, parcelNumber } = this.props.hoData;
    let areaSplit = splitAreas(areas);
    let len = areaSplit.length;
    let maxCount;
    if (len === 1) {
      maxCount = areaSplit[0].length;
    } else if (len === 2) {
      maxCount = Math.max(areaSplit[0].length, areaSplit[1].length);
    } else if (len === 3) {
      maxCount = Math.max(
        areaSplit[0].length,
        areaSplit[1].length,
        areaSplit[2].length
      );
    }
    let coverPageFontSize = "";
    if (maxCount >= 17) {
      coverPageFontSize = "medium-cover-page-area-text";
    }
    let oneLineOneArea = ["Country Club West", "Country Club East"];
    let filteredOut;
    if (parcelNumber === "0" || !subdivisionName) {
      filteredOut = null;
    } else {
      filteredOut = filterOutSubdivision(areas, subdivisionName);
    }
    if (demoAccountAreaName && demoAccountAreaName.length > 1) {
      return (
        <div className="one-cover-page-area">
          <div>{demoAccountAreaName}</div>
        </div>
      );
    } else if (areaSplit[0] === " Estates of Pebble Beach") {
      return (
        <div
          // className={coverPageFontSize}
          className="one-cover-page-area"
        >
          <div>Estates of</div>
          <div>Pebble Beach</div>
        </div>
      );
    } else if (oneLineOneArea.includes(areaSplit[0])) {
      return (
        <div className="one-line-cover-page">
          <div>{areaSplit[0]}</div>
        </div>
      );
    } else if (subdivisionName && subdivisionName.length > 1) {
      if (subdivisionName === "Bay Ridge/Hidden Hills") {
        return (
          <div className="ye-subdivision-container">
            <div className={`subdivision-title ${coverPageFontSize}`}>
              Bay Ridge <br /> Hidden Hills
            </div>
            <div className="subdivision-subtitle-container">
              <div className="including-subtitle">Including </div>
              <div className="subdivision-subtitle">{filteredOut}</div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="ye-subdivision-container">
            <div className={`subdivision-title ${coverPageFontSize}`}>
              {subdivisionName}
            </div>
            <div className="subdivision-subtitle-container">
              <div className="including-subtitle">Including </div>
              <div className="subdivision-subtitle">{filteredOut}</div>
            </div>
          </div>
        );
      }
    } else if (len === 1) {
      return (
        <div className="one-cover-page-area">
          <div>{areaSplit[0]}</div>
        </div>
      );
    } else if (len === 2) {
      return (
        <div className={`two-cover-page-area ${coverPageFontSize}`}>
          <div>{areaSplit[0]} </div>
          <div>{areaSplit[1]}</div>
        </div>
      );
    } else {
      return (
        <div className="three-cover-page-area">
          <div>{areaSplit[0]}</div>
          <div>{areaSplit[1]}</div>
          <div>{areaSplit[2]}</div>
        </div>
      );
    }
  };
  determineComparison = () => {
    const { infinityCheck } = propertyUtil;
    function calculateDiff(curr, prev) {
      if (curr === "0" && prev === "0") {
        return "0";
      } else if (curr === "0") {
        return -100;
      } else if (prev === "0") {
        return 100;
      } else {
        curr = Math.round(curr);
        prev = Math.round(prev);
        let res = infinityCheck(
          Math.round(((curr - prev) / Math.abs(prev)) * 100)
        );
        return res;
      }
    }
    const {
      marketTrends,
      highestSalePriceForCurrYear,
    } = this.props.yearEnd.pmaYearEndRunAreaDTO;
    let currentYearObj = marketTrends.filter(
      (obj) => obj.TrendName === "1-Year Trending"
    );
    let pastYearObj = marketTrends.filter(
      (obj) => obj.TrendName === "Past 1-Year Trending"
    );
    const {
      AvgSalePrice: AvgSalePriceCurrentYear,
      AvgPercentOfReceiving: AvgPercentOfReceivingCurrentYear,
      AvgDaysOnMarket: AvgDaysOnMarketCurrentYear,
      HomesSold: HomesSoldCurrentYear,
    } = currentYearObj[0];
    const {
      AvgSalePrice: AvgSalePricePastYear,
      AvgPercentOfReceiving: AvgPercentOfReceivingPastYear,
      AvgDaysOnMarket: AvgDaysOnMarketPastYear,
      HomesSold: HomesSoldPastYear,
    } = pastYearObj[0];
    let dataObj = {
      compareDays: null,
      salePriceDiff: null,
      salePriceDataPoint: null,
      receivedPerc: null,
      receivedDataPoint: null,
      daysToSellPerc: null,
      daysToSellDataPoint: null,
      unitsSoldPerc: null,
      unitsSoldDataPoint: null,
      monthComparison: null,
      highestSalePrice: null,
    };
    dataObj.monthComparison = 12;
    dataObj.salePriceDiff = calculateDiff(
      AvgSalePriceCurrentYear,
      AvgSalePricePastYear
    );
    dataObj.salePriceDataPoint = AvgSalePriceCurrentYear;
    dataObj.receivedPerc = calculateDiff(
      AvgPercentOfReceivingCurrentYear,
      AvgPercentOfReceivingPastYear
    );
    dataObj.receivedDataPoint = AvgPercentOfReceivingCurrentYear;
    dataObj.daysToSellDataPoint = AvgDaysOnMarketCurrentYear;
    dataObj.daysToSellPerc = calculateDiff(
      AvgDaysOnMarketCurrentYear,
      AvgDaysOnMarketPastYear
    );
    dataObj.unitsSoldDataPoint = HomesSoldCurrentYear;
    dataObj.unitsSoldPerc = calculateDiff(
      HomesSoldCurrentYear,
      HomesSoldPastYear
    );
    dataObj.highestSalePrice = highestSalePriceForCurrYear;
    return dataObj;
  };
  render() {
    const {
      agentData,
      coverPageDetails,
      currentArea,
      hoData,
      demoAccountCoverPhoto,
    } = this.props;
    const { subdivisionName } = hoData;
    const { mlsNeighborhood } = currentArea;
    const determineComparison = this.determineComparison();
    let cityPhoto =
      demoAccountCoverPhoto && demoAccountCoverPhoto.length > 1
        ? demoAccountCoverPhoto
        : coverPageDetails.bgPhotoList
        ? coverPageDetails.bgPhotoList[0]
        : TinyCoverBG;
    return (
      <div className="ye-cover-page-container">
        <div
          style={{
            backgroundImage: `url(${cityPhoto})`,
          }}
          className="cover-page-bg-photo"
        />
        <div className="cover-page-color-overlay" />
        <div className="cover-page-edition">2020 Year-End Market Edition</div>
        <div className="cover-page-title-container">
          <div className={`cover-page-city-area`}>
            {this.divideAreas(mlsNeighborhood, true)}
          </div>
        </div>
        <div className="cover-page-text-container">
          <div className="cover-page-body">
            <div className="cover-page-body-sideways-text">
              At a glance | 2020 <span className="sideways-lowercase">vs</span>{" "}
              2019
            </div>
            <div className="cover-page-body-data-container">
              <YECoverPageData
                category="Homes Sold"
                dataPoint={determineComparison.unitsSoldDataPoint}
                perc={determineComparison.unitsSoldPerc}
              />
              <YECoverPageData
                category="Sale Price"
                dataPoint={determineComparison.salePriceDataPoint}
                perc={determineComparison.salePriceDiff}
              />
              <YECoverPageData
                category="Highest Sale Price"
                dataPoint={determineComparison.highestSalePrice}
                perc="none"
              />
            </div>
          </div>
        </div>
        <YECoverPageInfo agentData={agentData} />
      </div>
    );
  }
}

export default YECoverPage;
