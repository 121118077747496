import React, { Component } from "react";
import { Link } from "react-router-dom";

class QVThreeTestimonial extends Component {
  render() {
    const { testimonials, agentData } = this.props;
    const {
      listgenUserFirstName,
      listgenUserLastName,
      teamMembers,
      isMemberOfTeam,
      listgenUserID
    } = agentData;
    let signoffText;
    let textContent;
    let agentName;

    if (listgenUserID === "100588") {
      agentName = "the Scarboroughs";
    } else if (isMemberOfTeam) {
      agentName = `${listgenUserFirstName} and ${teamMembers[1].firstName}`;
    } else {
      agentName = `${listgenUserFirstName}`;
    }
    if (
      testimonials === "none" ||
      !testimonials ||
      !testimonials.testimonialContent
    ) {
      return <div></div>;
    } else {
      const {
        clientFirstName,
        clientLastName,
        testimonialContent
      } = testimonials;
      signoffText = `${clientFirstName ? clientFirstName : ""} ${
        clientLastName ? clientLastName : ""
      }`;
      textContent = testimonialContent ? testimonialContent : "";

      if (!textContent) {
        textContent = "Please add testimonial";
      }

      let textClass;
      if (textContent.length >= 200 && textContent.length <= 300) {
        textClass = "larger-testimonial-mission-text";
      } else {
        textClass = "medium-testimonial-mission-text";
      }
      let containerClass = "";
      if (listgenUserID === "100190") {
        containerClass = "extra-width-testimonial";
      }
      return (
        <div className={`qv3-testimonial2-container ${containerClass}`}>
          <div className="qv3-testimonial-title">Team Up with {agentName}</div>
          <div className="qv3-testimonial-divider"></div>
          <div className="qv3-testimonial">
            <div className="testimonial-top-layer">
              <div className="testimonial-container">
                <div className="testimonial-content-row">
                  <div className="testimonial-text">"{textContent}"</div>
                </div>

                <div className="testimonial-signoff">
                  <p>— {signoffText}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default QVThreeTestimonial;
