export default theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: 'auto',
    padding: theme.spacing(3),
    minHeight: '80vh',
  },
  grid: {
    height: '100%',
    backgroundColor: theme.palette.common.default,
    flexGrow: 1,
    display: 'block',
    alignItems: 'center',
  },
  item: {
    height: '100%',
    backgroundColor: theme.palette.common.default,
  },
});