import React, { Component, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles, CircularProgress } from '@material-ui/core';
import { Button, TextField, Typography, IconButton } from '@material-ui/core';
import validate from 'validate.js';
import schema from './schema';
import _ from 'lodash';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter,
} from 'components';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import styles from './styles';
import { Store } from 'Store';
import { HANDLE_FIELD_CHANGE } from 'store/types';
import { HANDLE_VALIDATIONS } from 'store/types';
import { SHOW_PASSWORD } from 'store/types';
import { ChangePassword as ChangePasswordAPI } from 'services/account';
import { START_LOADING } from 'store/types';
import { STOP_LOADING } from 'store/types';
import { RESET_APP_STATE } from 'store/types';
import { showSuccessNotification } from 'common/helpers';
import { showErrorNotification } from 'common/helpers';
import { withSnackbar } from 'notistack';
import { compose } from 'recompose';
import { RESET_APP_STATE_EXCEPT_VALUES } from 'store/types';

const Account = (props) => {
  const { state, dispatch } = React.useContext(Store);
  const { values, touched, errors, isValid, submitError, isLoading } = state;
  const { classes, className, ...rest } = props;
  const rootClassName = classNames(classes.root, className);

  useEffect(() => {
    /**reset app also */
    dispatch({
      type: RESET_APP_STATE_EXCEPT_VALUES,
    });
    validateForm();
  }, []);

  const validateForm = _.debounce(() => {
    dispatch({
      type: HANDLE_VALIDATIONS,
      schema,
    });
  }, 300);

  const changePassword = () => {
    try {
      const { values } = state;
      dispatch({
        type: START_LOADING,
      });
      /**call the data api over here */
      ChangePasswordAPI(state.userID, values.oldPassword, values.newPassword)
        .then(data => {
          switch (Number(data)) {
            case 0:
              showSuccessNotification(props, 'Password changed.');
              /**clean the form */
              dispatch({
                type: RESET_APP_STATE,
              });
              break;
            case 1:
              showErrorNotification(props, 'Some unexpected error occured.');
              break;
            case 2:
              showErrorNotification(props, 'Old password does not match.');
              break;
            default:
              showErrorNotification(
                props,
                'Something went wrong. Please try after some time.'
              );
              break;
          }
          /**stop loading also */
          dispatch({
            type: STOP_LOADING,
          });
        })
        .catch(err => {
          showErrorNotification(props, err);
          dispatch({
            type: STOP_LOADING,
          });
        });
    } catch (err) {
      showErrorNotification(props, 'Unable to process request.');
      dispatch({
        type: STOP_LOADING,
      });
    }
  };

  const showOldPasswordError = touched.oldPassword && errors.oldPassword;
  const showNewPasswordError = touched.newPassword && errors.newPassword;
  const showConfirmPasswordError =
    touched.confirmPassword && errors.confirmPassword;

  return (
    <Portlet {...rest} className={rootClassName}>
      <PortletHeader>
        <PortletLabel title="Change Password" />
      </PortletHeader>
      <PortletContent noPadding>
        <form autoComplete="off" noValidate>
          <div className={classes.contentBody}>
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Old Password"
                margin="dense"
                type={state.showPassword.oldPassword ? 'text' : 'password'}
                name="oldPassword"
                value={state.values.oldPassword}
                onChange={event => {
                  dispatch({
                    type: HANDLE_FIELD_CHANGE,
                    fieldType: event.target.type,
                    value: event.target.value,
                    name: 'oldPassword',
                  });
                  validateForm();
                }}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={event =>
                          dispatch({
                            type: SHOW_PASSWORD,
                            name: 'oldPassword',
                          })
                        }
                      >
                        {state.showPassword.oldPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {showOldPasswordError && (
                <Typography className={classes.fieldError} variant="body2">
                  {errors.oldPassword[0]}
                </Typography>
              )}
            </div>
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="New Password"
                margin="dense"
                type={state.showPassword.newPassword ? 'text' : 'password'}
                name="newPassword"
                value={state.values.newPassword}
                onChange={event => {
                  dispatch({
                    type: HANDLE_FIELD_CHANGE,
                    fieldType: event.target.type,
                    value: event.target.value,
                    name: 'newPassword',
                  });
                  validateForm();
                }}
                variant="outlined"
                inputProps={{ minLength: 8 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={event =>
                          dispatch({
                            type: SHOW_PASSWORD,
                            name: 'newPassword',
                          })
                        }
                      >
                        {state.showPassword.newPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {showNewPasswordError && (
                <Typography className={classes.fieldError} variant="body2">
                  {errors.newPassword[0]}
                </Typography>
              )}
            </div>
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Confirm Password"
                margin="dense"
                type={state.showPassword.confirmPassword ? 'text' : 'password'}
                name="confirmPassword"
                value={state.values.confirmPassword}
                onChange={event => {
                  dispatch({
                    type: HANDLE_FIELD_CHANGE,
                    fieldType: event.target.type,
                    value: event.target.value,
                    name: 'confirmPassword',
                  });
                  validateForm();
                }}
                variant="outlined"
                inputProps={{ minLength: 8 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={event =>
                          dispatch({
                            type: SHOW_PASSWORD,
                            name: 'confirmPassword',
                          })
                        }
                      >
                        {state.showPassword.confirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {showConfirmPasswordError && (
                <Typography className={classes.fieldError} variant="body2">
                  {errors.confirmPassword[0]}
                </Typography>
              )}
            </div>
          </div>
        </form>
      </PortletContent>
      <PortletFooter className={classes.portletFooter}>
        <div className={classes.footerButtons}>
          <div className={classes.footerButtonsLeftRight}>
            <Button
              color="default"
              variant="contained"
              onClick={event =>
                dispatch({
                  type: RESET_APP_STATE,
                })
              }
            >
              Clear
            </Button>
          </div>
          <div className={classes.footerButtonsCenter}>
            {isLoading ? (
              <CircularProgress className={classes.progress} />
            ) : (
              <Button
                color="primary"
                variant="contained"
                disabled={!isValid}
                onClick={event => changePassword()}
              >
                Change Password
              </Button>
            )}
          </div>
        </div>
      </PortletFooter>
    </Portlet>
  );
}

Account.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles)
)(Account);
