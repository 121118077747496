import {
  green,
  red,
  blueGrey,
  grey,
  yellow,
  amber
} from "@material-ui/core/colors";
import palette from "theme/palette";

export default theme => ({
  mb10: {
    marginBottom: "10px"
  },
  mr5: {
    marginRight: "5px"
  },
  capitalize: {
    textTransform: "capitalize"
  },
  propertyDetailsModal: {
    // backgroundColor: "#F5F2DF",
    // width: "900px",
    // height: "auto",
    position: "absolute",
    top: "1%",
    left: "1%",
    overflow: "scroll",
    // height: "900px",
    height: "auto",
    width: "90vw",
    display: "block"

    // height: "600px"
  },
  propertyDetailsHeader: {
    backgroundColor: "#4A7396",
    height: "85px"
  },
  propertyDetailsHeaderInner: {
    display: "flex",
    justifyContent: "space-between",
    width: "93.5%",
    margin: "auto",
    paddingTop: "10px"
  },
  propertyDetailsHeaderLeft: {},
  propertyDetailsTitle: {
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 36,
    textTransform: "uppercase",
    letterSpacing: "0.1em",
    color: "white"
  },
  propertyDetailsHeaderSubtitle: {
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 300,
    fontStyle: "normal",
    fontSize: 16,
    letterSpacing: "0.025em",
    color: "white"
  },
  propertyDetailsHeaderRight: {},
  propertyDetailsHeaderAddress: {
    color: "white",
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 24,
    letterSpacing: "0.025em",
    lineHeight: "26px"
  },
  propertyDetailsBodyHeader: {
    display: "flex",
    marginTop: "20px"
  },
  bodyHeaderLeft: {
    display: "flex",

    alignItems: "center"
  },
  leftCol: {
    width: "27.5%"
  },
  sidewaysPhotoText: {
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 300,
    fontStyle: "normal",
    writingMode: "vertical-rl",
    textOrientation: "sideways-right",
    transform: "rotate(180deg)",
    color: palette.propDetails.mainGray,
    fontSize: 12,
    lineHeight: "15px"
  },
  headerPhotoCol: {
    marginLeft: "10px"
  },
  headerPhotoColImage: {
    height: "120px",
    width: "180px",
    maxHeight: "120px",
    maxWidth: "180px",
    marginTop: "5px"
  },
  hoverHand: {
    cursor: "pointer"
  },
  headerPhotoColMain: {
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 16,
    fontVariant: "small-caps",
    color: palette.propDetails.mainBlue,
    lineHeight: "15px"
  },
  headerPhotoRow: {
    display: "flex",
    width: "180px",
    justifyContent: "space-between"
  },
  headerPhotoColSub: {
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 300,
    fontStyle: "normal",
    fontSize: 15,
    color: palette.propDetails.mainBlue
  },
  photoPhotoMapButton: {
    backgroundColor: palette.propDetails.mainBlue,
    color: "white",
    padding: "2px 7px",
    "&:hover": {
      cursor: "pointer"
    }
  },
  rightCol: {
    width: "55%"
  },
  bodyHeaderRightTitle: {
    display: "flex",
    marginBottom: "10px"
  },
  bodyHeaderRightTitleText: {
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 26,
    textTransform: "uppercase",
    letterSpacing: "0.1em",
    color: "#C0BFBF"
  },
  fullDataRow: {
    display: "flex",
    height: "22px",
    justifyContent: "space-between",
    alignItems: "center"
  },
  listDataRow: {
    display: "flex",
    height: "22px",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  dataRowLeft: {
    width: "40%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end"
  },
  listDataRowLeft: {
    width: "30%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end"
  },
  dataRowRight: {
    width: "55%",
    display: "flex",
    alignItems: "flex-end"
  },
  listDataRowRight: {
    width: "60%",
    display: "flex",
    alignItems: "flex-end"
  },
  salePrice: {
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 300,
    fontStyle: "normal",
    fontSize: 15,
    letterSpacing: "0.025em",
    color: palette.propDetails.mainGray,
    textDecoration: "underline",
    textTransform: "uppercase"
  },
  salePriceDataPoint: {
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 300,
    fontStyle: "normal",
    fontSize: 26,
    letterSpacing: "0.025em",
    color: palette.propDetails.mainBlue,
    marginLeft: 15
  },
  dataName: {
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 300,
    fontStyle: "normal",
    fontSize: 13,
    letterSpacing: "0.025em",
    color: palette.propDetails.mainGray,
    textTransform: "uppercase",
    textDecoration: "underline",
    marginRight: "5px"
  },
  dataDivider: {
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 300,
    fontStyle: "normal",
    fontSize: 13,
    letterSpacing: "0.025em",
    color: palette.propDetails.mainGray,
    textTransform: "uppercase",
    marginLeft: "5px",
    marginRight: "5px"
  },
  dataRow: {
    display: "flex",
    alignItems: "center"
  },

  dataPoint: {
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 300,
    fontStyle: "normal",
    fontSize: 16,
    letterSpacing: "0.025em",
    color: "#000000",
    // width: "45%",
    textAlign: "right"
  },
  boldDataPoint: {
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 16,
    color: "#000000"
  },
  boldData: {
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 15,
    color: "#000000",
    letterSpacing: "0.025em"
  },
  soldStatusDataPoint: {
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 24,
    height: "22px",
    letterSpacing: "0.025em",
    color: palette.propDetails.mainRed,
    width: "50%"
  },
  leftAlign: {
    textAlign: "left",
    "& dataPoint": {
      width: "20%"
    }
  },
  firstPageBody: {
    // border: "1px solid red"
  },
  firstPageContent: {
    // border: "1px solid green",
    width: "90%",
    margin: "auto"
  },

  secondPageContent: {
    // border: "1px solid green",
    width: "90%",
    margin: "auto"
  },

  firstContentRow: {
    display: "flex",
    marginBottom: "15px",
    height: "100%"
  },
  firstContentCol: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "15px",
    height: "100%"
  },
  contentRowDivider: {
    height: "auto",
    backgroundColor: "#b6b8b9",
    width: "2px",
    marginRight: "20px"
  },
  firstContentTitle: {
    display: "flex",
    flexDirection: "column",
    width: "13%",
    alignItems: "flex-start",
    justifyContent: "center"
  },
  firstContentInnerTitle: {
    width: "100%"
  },
  firstContentTitleText: {
    color: palette.propDetails.mainBlue,
    fontFamily: "mr-eaves-modern, sans-serif",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 23,
    fontVariant: "small-caps"
  },
  firstContentSubtitle: {
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 300,
    fontStyle: "normal",
    fontSize: 13,
    letterSpacing: "0.025em",
    color: palette.propDetails.mainRed
  },
  firstContentParagraph: {
    textAlign: "left",
    // borderLeft: "1px solid black",
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 300,
    fontStyle: "normal",
    fontSize: 13,
    color: "#000000",
    marginBottom: "5px"
  },
  firstContentDataList: {
    width: "100%"
  },
  firstContentSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
    // alignItems: "center"
  },
  soldText: {
    color: palette.propDetails.mainRed,
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 16,
    letterSpacing: "0.025em"
  },
  mlsNumberAndGate: {
    color: palette.propDetails.lightBlue,
    fontFamily: "mr-eaves-xl-modern, sans-serif",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: 15,
    textTransform: "uppercase"
  },
  contentTable: {
    // border: "1px solid red"
    margin: "13px 0 13px 0"
  },
  emptyTableContentRow: {
    height: "20px"
  },

  tableContentRow: {
    display: "flex",
    // border: "1px solid blue",
    "&>div": {
      fontFamily: "mr-eaves-xl-modern, sans-serif",
      fontWeight: 300,
      fontStyle: "normal",
      fontSize: "16px",
      letterSpacing: "0.025em",
      color: "black",
      margin: 0
    },

    "&>div:first-child": {
      width: "10%"
    },
    "&>div:nth-child(2)": {
      width: "15%"
    },
    "&>div:nth-child(3)": {
      width: "10%"
    },
    "&>div:nth-child(4)": {
      width: "10%"
    },
    "&>div:nth-child(5)": {
      width: "10%"
    },
    "&>div:nth-child(6)": {
      width: "20%"
    },
    "&>div:nth-child(7)": {
      width: "20%"
    }
  },
  tableContentTitleRow: {
    marginBottom: "10px",
    "& $dataName": {
      fontFamily: "mr-eaves-xl-modern, sans-serif",
      fontWeight: 300,
      fontStyle: "normal",
      fontSize: "16px",
      letterSpacing: "0.025em",
      color: palette.propDetails.mainGray,
      margin: 0
    }
  },
  horizontalBorder: {
    width: "100%",
    height: "1px",
    backgroundColor: palette.propDetails.mainBlue,
    margin: "5px 0 5px 0"
  },
  noMargin: {
    margin: 0
  }
});
