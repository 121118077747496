import React, { Component } from "react";
import { Link } from "react-router-dom";

class MQTwoTestimonial extends Component {
  render() {
    const { testimonials, agentData } = this.props;
    const {
      clientFirstName,
      clientLastName,
      testimonialContent,
    } = testimonials;
    const { isMemberOfTeam, listgenUserID } = agentData;
    if (
      testimonials === "none" ||
      !testimonials ||
      !testimonials.testimonialContent
    ) {
      return (
        <div className="mq2-testimonial-container">
          <Link to="/agent/clienttestimonials">
            <div className="mq2-testimonial">
              <div className="testimonial-top-layer">
                <div className="testimonial-container">
                  <div className="testimonial-content-row">
                    <div className="add-testimonial-text">
                      Please add Testimonial to profile page. Click HERE!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      );
    } else {
      return (
        <div className="mq2-testimonial">
          <div className="mq2-testimonial-title-container">
            <div className="mq2-testimonial-title-text">
              {listgenUserID === "100988"
                ? "Home is Our Passion"
                : "Delivering Excellence"}
            </div>
          </div>
          <div className="mq2-testimonial-subtitle-container">
            <div className="mq2-testimonial-text-container">
              <div
                className={`mq2-testimonial-text ${
                  listgenUserID === "100462" && "testimonial-alt-spacing"
                }`}
              >
                “{testimonialContent}”
              </div>
            </div>

            <div className="mq2-testimonial-signoff">
              — {clientFirstName} {clientLastName}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default MQTwoTestimonial;
