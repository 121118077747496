import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  psViewContainer: {
    marginTop: "24px",
  },
  noteTextarea: {
    "& label": {
      fontFamily: "objektiv-mk2",
    },
    "& .MuiInputBase-input": {
      fontSize: "12px",
      lineHeight: "17px",
      fontFamily: "objektiv-mk2",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid #4A7396",
    },
  },
  maxCharacterLimit: {
    textAlign: "right",
    fontSize: "10.5px",
    letterSpacing: "0.025em",
    fontStyle: "italic",
    color: "#4A7396",
    opacity: "0.7",
  },
}));

const NoteAutomatorPSViewMode = ({
  psMinLength,
  localPSCopy,
  ComponentWrapper = Fragment,
  placeholder,
  ...rest
}) => {
  const classes = useStyles();

  return <div className={classes.psViewContainer}>
    <ComponentWrapper>
      <TextField
        {...rest}
        value={localPSCopy}
        placeholder={placeholder}
        fullWidth={true}
        multiline={true}
        rows={1}
        rowsMax={5}
        classes={{ root: classes.noteTextarea }}
        disabled={false}
        autoFocus={false}
        inputProps={{ style: { fontSize: "12px", color: "black" } }}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start" style={{ color: "#000" }}>
              <span style={{ fontWeight: 500 }}>P.S.</span>
            </InputAdornment>
          ),
        }}
      />
    </ComponentWrapper>
    <p className={classes.maxCharacterLimit}>
      Character Count: {localPSCopy.length || 0} / Min{" "}
      {psMinLength} characters
    </p>
  </div>
}

NoteAutomatorPSViewMode.propTypes = {
  classes: PropTypes.object.isRequired,
  psMinLength: PropTypes.number.isRequired,
  psRef: PropTypes.object.isRequired,
  localPSCopy: PropTypes.string.isRequired,
  ComponentWrapper: PropTypes.elementType,
};

export default NoteAutomatorPSViewMode;