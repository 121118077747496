import React, { useEffect, useState, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button, Typography, Divider, Container } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Slide from '@material-ui/core/Slide';
import classNames from 'classnames';
import styles from '../../styles';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { connect } from "react-redux";
import Filter from "bad-words";
import { pmaActions } from '_store/_actions';
import { propertyUtil } from 'views/Pma/PropertyUtil';
import { renderLogicUtil } from 'views/Pma/RenderLogicUtil';

const CustomNoteDialogHeading = (props) => {
    const { classes, cameFrom, property } = props;


    const getDialogHeading = () => {
        /**there's no according to type note found show default text */
        let returnData;
        if (cameFrom === "PersonalPS") {
            returnData = (
                <Fragment>
                    <Typography variant="h4" align="center">
                        PERSONAL NOTE TO THE HOMEOWNER
          </Typography>
                    {/* <Typography variant="body1" align="center">
                        You may create a PERSONAL NOTE for this particular homeowner.
                </Typography> */}
                    <Typography variant="body1" align="center" className={classes.darkRedColor}>
                        When you create a PERSONAL NOTE it will override the GLOBAL NOTE for this homeowner <span className={classes.boldText}>only</span> and for this mailing <span className={classes.boldText}>only</span>.
                </Typography>
                </Fragment>
            );
        } else if (cameFrom === "AbsentHOPS") {
            returnData = (
                <Fragment>
                    <Typography variant="h4" align="center">
                        ABSENTEE HOMEOWNER
          </Typography>
                    <Typography variant="subtitle1" align="center">
                        Go ahead and create a PERSONAL NOTE for this ABSENTEE HOMEOWNER!
                </Typography>
                    <Typography variant="body1" align="center" className={classes.darkRedColor}>
                        When you create a PERSONAL NOTE it will override the GLOBAL NOTE for this homeowner <span className={classes.boldText}>only</span> and for this mailing <span className={classes.boldText}>only</span>.
                </Typography>
                </Fragment>
            );
        } else if (cameFrom === "Anniversary") {
            returnData = (
                <Fragment>
                    <Typography variant="h4" align="center">
                        HAPPY ANNIVERSARY!
          </Typography>
                    <Typography variant="subtitle1" align="center">
                        Go ahead and create a PERSONAL NOTE wishing them a HAPPY {propertyUtil.daySuffix(renderLogicUtil.getHOAnniversaryNumber(property))} ANNIVERSARY!
                </Typography>
                    <Typography variant="body1" align="center" className={classes.darkRedColor}>
                        When you create a PERSONAL NOTE it will override the GLOBAL NOTE for this homeowner <span className={classes.boldText}>only</span> and for this mailing <span className={classes.boldText}>only</span>.
                </Typography>
                </Fragment>
            );
        } else if (cameFrom === "Cancelled") {
            returnData = (
                <Fragment>
                    <Typography variant="h4" align="center">
                        CANCELLED!
          </Typography>
                    <Typography variant="subtitle1" align="center">
                        These homeowner now have an CANCELLED listing!
                </Typography>
                    <Typography variant="body1" align="center" className={classes.darkRedColor}>
                        When you create a PERSONAL NOTE it will override the GLOBAL NOTE for this homeowner <span className={classes.boldText}>only</span> and for this mailing <span className={classes.boldText}>only</span>.
                </Typography>
                </Fragment>
            );
        } else if (cameFrom === "Congrats") {
            returnData = (
                <Fragment>
                    <Typography variant="h4" align="center">
                        CONGRATS!
          </Typography>
                    <Typography variant="subtitle1" align="center">
                        Go ahead and create a PERSONAL NOTE congratulating them on their new purchase.
                </Typography>
                    <Typography variant="body1" align="center" className={classes.darkRedColor}>
                        When you create a PERSONAL NOTE it will override the GLOBAL NOTE for this homeowner <span className={classes.boldText}>only</span> and for this mailing <span className={classes.boldText}>only</span>.
                </Typography>
                </Fragment>
            );
        } else if (cameFrom === "Expired") {
            returnData = (
                <Fragment>
                    <Typography variant="h4" align="center">
                        EXPIRED!
          </Typography>
                    <Typography variant="subtitle1" align="center">
                        This is an excellent time to create a PERSONAL NOTE to this EXPIRED Listing homeowner!
                </Typography>
                    <Typography variant="body1" align="center" className={classes.darkRedColor}>
                        When you create a PERSONAL NOTE it will override the GLOBAL NOTE for this homeowner <span className={classes.boldText}>only</span> and for this mailing <span className={classes.boldText}>only</span>.
                </Typography>
                </Fragment>
            );
        } else if (cameFrom === "Coincidence") {
            returnData = (
                <Fragment>
                    <Typography variant="h4" align="center">
                        WHAT A COINCIDENCE!
          </Typography>
                    <Typography variant="subtitle1" align="center">
                        These homeowners have family in Sacramento, CA, where you grew up!
                </Typography>
                    <Typography variant="body1" align="center" className={classes.darkRedColor}>
                        When you create a PERSONAL NOTE it will override the GLOBAL NOTE for this homeowner <span className={classes.boldText}>only</span> and for this mailing <span className={classes.boldText}>only</span>.
                </Typography>
                </Fragment>
            );
        }
        return returnData;
    };

    return (
        <div>
            {getDialogHeading()}
        </div>
    );
};

export default compose(withStyles(styles))(CustomNoteDialogHeading);