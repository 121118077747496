export default {
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  dreNumber: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      onlyInteger: false,
      greaterThan: 0,
    },
    length: {
      is: 8
    }
  },
  license: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 10,
      minimum: 1
    }
  },
  ownedMlsId: {
    presence: { allowEmpty: false, message: 'is required' },
  }
};