import Base from "common/constants";
import querystring from "querystring";
import { CommonAxiosAPIHandler, GetAuthorization } from "common/helpers";
import { GetOAuthTokenByKey } from "common/storage";
import { isArray } from "util";
import axios from "common/interceptors";

export const GetPmaEstimatedInvoiceData = (listgenUserID) => {
  return CommonAxiosAPIHandler("GET", [
    `${Base.PMA_ESTIMATED_INVOICE_DATA}?listgenUserID=${listgenUserID}`
  ]);
};

export const UpdateNavbarInfoForAgent = mlsAreas => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(Base.UPDATE_MAILPRO_NAVBAR_INFO, mlsAreas, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*"
          }
        })
        .then(res => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch(err => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};
