import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";

class FactBubbles extends Component {
  addressClass = (address) => {
    let len = address.length;
    if (len >= 23) {
      return "dark-bubble-subdata medium-address";
    } else {
      return "dark-bubble-subdata";
    }
  };
  formatSalePrice = (price) => {
    price = +price;
    return price;
  };
  formatAskingPerc = (perc) => {
    if (perc === 0) {
      return `100% of asking price`;
    } else if (perc >= 0.5 && perc <= 1) {
      return `1% over asking`;
    } else {
      return `${Math.round(perc)}% over asking`;
    }
  };
  askingData = (rawPoint) => {
    let point = Math.round(rawPoint);
    if (point < 0) {
      return `${Math.abs(point)} under asking`;
    } else if (point > 0) {
      return `${point} over asking`;
    } else if (point === 0) {
      return `No Change`;
    }
  };
  formatSubtitle = (rawPoint) => {
    let point = Math.round(rawPoint);
    if (point === 0) {
      return "0% Change";
    } else if (point < 0) {
      return `Down ${Math.abs(point)}%`;
    } else if (point >= 500) {
      return `Up > 500%`;
    } else {
      return `Up ${point}%`;
    }
  };
  formatDate = (rawDate) => {
    let date = new Date(rawDate);
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${month} ${year}`;
  };
  areaToUse = (area) => {
    const { subdivisionName } = this.props.hoData;
    const { slashSeparatedArea } = propertyUtil;
    if (subdivisionName && subdivisionName.length > 1) {
      return subdivisionName;
    } else if (area.length > 27) {
      return "Your Neighborhood";
    } else {
      return slashSeparatedArea(area, true);
    }
  };
  render() {
    const { roundSalePrice, roundSalePriceTenths, homesNumber } = propertyUtil;
    const { currentArea, bubbleDataDetails, quarterlyAreaTable } = this.props;
    // const {
    //   avgHomesSoldLast12Months: city12,
    //   cityName,
    //
    //   numberOfHomesForSale: citySale,
    //   maxHousesSoldLast12Months: maxCity,
    // } = MQCityDetails;

    const {
      AvgHomesSoldLast12Months: area12,
      // AreaName,

      AreaNumberOfHousesForSale: areaSale,
    } = quarterlyAreaTable.monthsSupplyTable;
    const avgArea = Number(area12);

    const areaInventory = homesNumber(
      areaSale,
      avgArea * 10,
      true,
      false,
      // maxCity,
      // citySale,
      // avgCity,
      avgArea
    )[1];
    console.log(this.props);
    console.log("bubbleDataDetails", bubbleDataDetails);
    const {
      HighestSaleAddress,
      HighestSaleAskingPricePercentage,
      HighestSalePrice2021,
      HighestSalePropertyDate,
      HomesSold2021,
      HomesSold2021AtOrAboveAskingPricePercentage,
      HomesSold2021LessThan14DaysPercentage,
      HomesSold2021WithPriceReductionPercentage,
      HomesSold2021LessThan14Days,
      HomesSold2021AtorAboveAskingPrice,
      HomesSold2021WithPriceReduction,
      HomesSold2020vs2021LessThan14DaysPercentage,
      PercentageSoldAtOrOVerListPrice,
    } = bubbleDataDetails;
    const { mlsNeighborhood } = currentArea;
    const {
      areaToUse,
      formatDate,
      formatSubtitle,
      formatAskingPerc,
      addressClass,
      formatSalePrice,
    } = this;
    //console.log("***bubbles", this.props);
    return (
      <div className="fact-bubbles">
        <div className="fact-bubble-dark-highlight"></div>
        <div className="fact-bubble-light-highlight"></div>
        {/*<div className="fact-bubble-footnote">
          *This is a national average according to the National Association of
          Realtors
        </div>*/}
        <div className="fact-bubbles-title-container">
          <div className="fact-bubbles-title-text">
            {areaToUse(mlsNeighborhood)}
          </div>
        </div>
        <div className="fact-bubbles-subtitle-container">
          <div className="fact-bubbles-subtitle-text">Interesting Facts</div>
        </div>
        <div className="fact-bubble-top-line"></div>

        <div className="fact-bubbles-body">
          <div className="dark-bubble">
            <div className="fact-bubble-img-container">
              <img
                className="fact-bubble-img"
                src="https://pma-coverphotos.s3.amazonaws.com/green-circle.png"
              />
            </div>
            <div className="bubble-content">
              <div className="dark-bubble-title">
                <p>2022 Highest</p>
                <p>Sale Price</p>
              </div>
              <div className="dark-bubble-data">
                {roundSalePrice(
                  HighestSalePrice2021,
                  false,
                  false,
                  false,
                  false,
                  3
                )}
              </div>
              <div className="dark-bubble-subdata-container">
                <div className={addressClass(HighestSaleAddress)}>
                  {HighestSaleAddress.toLowerCase()}
                </div>
                <div className="dark-bubble-subdata">
                  {formatDate(HighestSalePropertyDate)}
                </div>
              </div>
            </div>
          </div>
          <div className="dark-bubble">
            <div className="fact-bubble-img-container">
              <img
                className="fact-bubble-img"
                src="https://pma-coverphotos.s3.amazonaws.com/yellow-circle.png"
              />
            </div>
            <div className="bubble-content">
              <div className="dark-bubble-title">
                <p>Sold in</p>
                <p>14 Days or Less</p>
              </div>
              <div className="dark-bubble-data smaller-data">
                {HomesSold2021LessThan14DaysPercentage}% of Homes
              </div>
              <div className="dark-bubble-subdata-container">
                <div className="dark-bubble-subdata"></div>
                <div className="dark-bubble-subdata">2022</div>
              </div>
            </div>
          </div>
          <div className="dark-bubble">
            <div className="fact-bubble-img-container">
              <img
                className="fact-bubble-img"
                src="https://pma-coverphotos.s3.amazonaws.com/gray-circle.png"
              />
            </div>
            <div className="bubble-content">
              <div className="dark-bubble-title">
                <p>Sold At or Over</p> <p>Asking Price</p>
              </div>
              <div className="dark-bubble-data">
                {Math.round(HomesSold2021AtOrAboveAskingPricePercentage)}% of
                Homes
              </div>

              <div className="dark-bubble-subdata-container">
                <div className="dark-bubble-subdata">2022</div>
              </div>
            </div>
          </div>
          <div className="dark-bubble">
            <div className="fact-bubble-img-container">
              <img
                className="fact-bubble-img"
                src="https://pma-coverphotos.s3.amazonaws.com/pink-circle.png"
              />
            </div>
            <div className="bubble-content">
              <div className="dark-bubble-title">
                <p>Price</p>
                <p>Reductions</p>
              </div>
              <div className="dark-bubble-data">
                {Math.round(HomesSold2021WithPriceReductionPercentage)}% of
                Homes
              </div>
              <div className="dark-bubble-subdata-container">
                <div className="dark-bubble-subdata">2022</div>
              </div>
            </div>
          </div>
        </div>
        <div className="fact-bubble-bottom-line"></div>
      </div>
    );
  }
}

export default FactBubbles;
