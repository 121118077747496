import React, { Component } from "react";
import QVFourEmailAndWebsiteFooter from "../../../Quarterly/QVFour/QVFourSecondPage/QVFourEmailAndWebsiteFooter";
import MQThreeMarketSalesTable from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/MQThreeMarketSalesTable";
import MQThreeMarketIndicators from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/MQThreeMarketIndicators";

class MQTwoSecondLeft extends Component {
  render() {
    const {
      agentData,
      printVersion,
      currentArea,
      hoData,
      demoAccountAreaName,
      quarterlyAreaTable,
      quarterlyCityTable,
    } = this.props;
    let isBuellton =
      currentArea.mlsAreaID === "SBA-40F" ||
      currentArea.mlsAreaID === "SBA-40G";
    return (
      <div className="mq2-second-left">
        <div className="mq3-second-left-middle-section">
          <MQThreeMarketSalesTable
            currentArea={currentArea}
            quarterlyAreaTable={
              isBuellton ? quarterlyCityTable : quarterlyAreaTable
            }
            hoData={hoData}
            agentData={agentData}
            demoAccountAreaName={demoAccountAreaName}
            lastMidQuarterlyPrintDate={
              quarterlyAreaTable.lastMidQuarterlyPrintDate
            }
            printVersion={printVersion}
            quarterlyCityTable={quarterlyCityTable}
          />
          <MQThreeMarketIndicators
            quarterlyAreaTable={quarterlyAreaTable}
            quarterlyCityTable={quarterlyCityTable}
            currentArea={currentArea}
            isBuellton={isBuellton}
          />
        </div>
        <QVFourEmailAndWebsiteFooter
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
        />
      </div>
    );
  }
}

export default MQTwoSecondLeft;
