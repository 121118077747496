import React, { useEffect, Fragment } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { Backdrop, withStyles, Button, Tooltip } from "@material-ui/core";
import styles from "./styles";
import compose from "recompose/compose";
import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowDropIcon from "@material-ui/icons/ArrowDropDown";
import { IsValidSessionSwitch } from "common/storage";
import { withRouter, Link } from "react-router-dom";

import { Grid, Tabs, Tab } from "@material-ui/core";
import NavbarFilterBox from "./NavbarFilterBox";
import DrawerModeHelpScreen from "views/Help/DrawerModeHelpScreen";
import AreaSummary from "./AreaSummary";
import MailProSupport from "../MailPro/components/MailProSupport";
import { DownloadMarketDataComponent } from "components";

const MappedNav = (props) => {
  let {
    mlsAreas,
    changeCurrentArea,
    handleSwitchStep,
    currentStep,
    currentArea,
    currentPeriod,
    handleDrawerOpen,
    propertyData,
    agentData,
    isLoading,
    version,
    mailingVersion,
    showUnapprovedBox,
    unapprovedAreaCount,
    classes,
    enableMailProApprovalProcess,
    isUnavailable,
  } = props;

  const isValidSwitchSession = IsValidSessionSwitch();

  const handleTabClick = (index, event) => {
    //reset the new HO dialog settings also
    const { mlsAreaID, mlsCityID } = props.currentArea;
    console.log("inside tab", props);
    props.resetNewMailProInitDialogFn();

    props.history.push(
      index === 0
        ? `/pma/${mlsAreaID}`
        : index === 1
        ? `/mailpro/${mlsAreaID}`
        : `/data-verification/${mlsCityID}/${mlsAreaID}`
    );
    window.history.pushState(
      {},
      "",
      index === 0
        ? `/pma/${mlsAreaID}`
        : index === 1
        ? `/mailpro/${mlsAreaID}`
        : `/data-verification/${mlsCityID}/${mlsAreaID}`
    );
    if (index === 0) {
      //console.log("handling switch step");
      props.handleSwitchStep(0);
    }

    // handleSwitchStep(index);
  };

  /** */
  //  console.log("currentStep", currentStep);
  let isPmaEditor = window.location.href.includes("/pma");
  //  console.log("currentArea is", currentArea);
  return (
    <div>
      <div
        className={classNames(
          classes.root,
          isPmaEditor || props.explicitEnableNewMailProInit || currentStep === 2
            ? classes.height64px
            : classes.height152px
        )}
      >
        {/* currentStep === 0 || isLoading
            ? classes.height64px
            : classes.height128px */}
        <AppBar
          position="static"
          color="default"
          className={classes.rootAppBar}
        >
          <Grid container className={classes.menuContainer}>
            <Grid item lg={!isValidSwitchSession ? 4 : 5} md={4} sm={4} xs={4}>
              {/* do justify switch based on current step. */}
              <div
                className={classNames(
                  classes.menuSection,
                  classes.flexSpaceJustify
                  // currentStep === 0
                  //   ? classes.flexSpaceJustify
                  //   : classes.flexStartJustify
                )}
              >
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={(event) => handleDrawerOpen()}
                  edge="start"
                  className={classNames(classes.menuButton)}
                  classes={{ label: classes.menuButtonLabel }}
                >
                  <MenuIcon />
                  <span>Menu</span>
                </IconButton>
                <div>
                  <Tooltip
                    title={`Download ${
                      currentArea && currentArea.mlsNeighborhood
                    } Area MailPro Database`}
                    aria-label="view"
                  >
                    <div>
                      <DownloadMarketDataComponent
                        areaID={(currentArea || {}).mlsAreaID}
                        areaName={(currentArea || {}).mlsNeighborhood}
                        iconButton={true}
                        buttonStyle={{ color: "white" }}
                        iconClasses={{ root: classes.downloadIcon }}
                      />
                    </div>
                  </Tooltip>
                </div>
                <div>
                  <MailProSupport
                    accessedFromAreaID={(currentArea || {}).mlsAreaID}
                    accessedFromAreaName={(currentArea || {}).mlsNeighborhood}
                    agentData={agentData}
                  />
                  {/* <DrawerModeHelpScreen
                    accessedFromAreaID={(currentArea || {}).mlsAreaID}
                  /> */}
                </div>
                <div style={{ width: "15%" }}></div>
              </div>
            </Grid>

            <Grid
              item
              lg
              md
              sm
              xs
              className={classNames(
                classes.tabSection,
                classes.smallerMiddleGridSection
              )}
            >
              <div className={(classes.root, classes.tabSection)}>
                  <Tabs
                    value={currentStep}
                    className={classes.tabContainer}
                    TabIndicatorProps={{
                      className: classes.tabIndicator,
                    }}
                    // classes={{
                    //   indicator: classes.mediumLightBg,
                    //   textColor: classes.mediumLightBg,
                    // }}
                    centered
                  >
                    {/* > */}

                    <Tab
                      label="PMA Editor"
                      className={classNames(classes.tabs)}
                      onClick={(event) => handleTabClick(0, event)}
                    />

                    <Tab
                      label="MailPro"
                      className={classNames(classes.tabs)}
                      // index={1}
                      onClick={(event) => handleTabClick(1, event)}
                    />
                    {/*{isValidSwitchSession && (
                      <Tab
                        label="Data Verification"
                        className={classNames(classes.tabs)}
                        index={2}
                        onClick={(event) => handleTabClick(2, event)}
                      />
                    )}*/}
                  </Tabs>
              </div>
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={4}
              xs={4}
              className={classNames(classes.areaSection)}
            >
              <AreaSummary
                currentArea={currentArea}
                showUnapprovedBox={showUnapprovedBox}
                unapprovedAreaCount={unapprovedAreaCount}
                mlsAreas={mlsAreas}
                enableMailProApprovalProcess={enableMailProApprovalProcess}
                changeCurrentArea={changeCurrentArea}
                agentData={agentData}
              />
            </Grid>
          </Grid>
          {/* && !isLoading */}
          {isUnavailable ? null : currentStep === 1 &&
            !props.explicitEnableNewMailProInit ? (
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <NavbarFilterBox
                  mlsAreas={mlsAreas}
                  currentArea={currentArea}
                  //handleSearch={this.handleSearch}
                  propertyData={propertyData}
                  isMailProLocked={props.isMailProLocked}
                  agentID={agentData.listgenUserID}
                  agentData={agentData}
                  enableMailProApprovalProcess={enableMailProApprovalProcess}
                  changeCurrentArea={changeCurrentArea}
                  mailProStatus={agentData.mailProStatus}
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </AppBar>
        <div id="back-to-top-anchor" />
      </div>
    </div>
  );
};

export default compose(withRouter, withStyles(styles))(MappedNav);
