import React, { Fragment } from 'react';
import { Typography, List, ListItem, ListItemText, Backdrop, Menu, MenuItem, Grid, withStyles } from '@material-ui/core';
import ArrowDropIcon from '@material-ui/icons/ArrowDropDown';
import classNames from 'classnames';
import styles from "./styles";
import { withRouter, Link } from "react-router-dom";
import compose from 'recompose/compose';

const LineItem = ({ area, classes, children, extraStyle = {} }) => {
    return <Typography className={classNames(classes.menuItemText, area.mailingStatus === "Approved" && classes.approvedText)} style={extraStyle} component={"div"}>
        {children}
    </Typography>
}


const AreaSummary = ({ classes, currentArea = {}, showUnapprovedBox, unapprovedAreaCount, mlsAreas = [], enableMailProApprovalProcess, changeCurrentArea, agentData = {} }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickListItem = (event, index) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index, area) => {
        const { listgenUserID } = agentData;
        setAnchorEl(null);
        if (currentArea.mlsAreaID !== area.mlsAreaID) {
            changeCurrentArea(index, area, listgenUserID);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const statusMap =
        currentArea.mailingStatus === "Approved" ? (
            <Typography className={classNames(classes.approvedText)}>
                Approved
            </Typography>
        ) : (
            <Typography className={classNames(classes.incompleteText)}>
                Not approved yet
            </Typography>
        );
    const { totalCurrentOverallCharges, totalNumberOfPMAs } = mlsAreas && mlsAreas[0].paymentData || {}

    const getFigure = (input, toFixed = true) => {
        const number = input === null ? 0 : Number(input);
        return toFixed ? number.toFixed(2) : number;
    }

    return (
        <>
            {showUnapprovedBox && unapprovedAreaCount > 0 ? (
                <div className={classNames(classes.unapproveAreaDiv)}>
                    <Typography
                        variant="body1"
                        className={classNames(
                            classes.whiteColor,
                            classes.unapproveAreaText
                        )}
                    >
                        Please Note: You have {unapprovedAreaCount} remaining area
                        {unapprovedAreaCount > 1 ? "s" : ""} to approve.
                    </Typography>
                </div>
            ) : (
                ""
            )}

            <List
                aria-label="Area-Summary"
                disablePadding
                className={classNames(
                    classes.listContainer,
                    currentArea &&
                        currentArea.mlsNeighborhood &&
                        currentArea.mlsNeighborhood.length < 24
                        ? ""
                        : classes.listContainerExtraMarginTop
                )}
            >
                <ListItem
                    button
                    className={classNames(classes.listItemContainer)}
                    aria-haspopup="true"
                    aria-controls="lock-menu"
                    aria-label="Area-Summary-Selected"
                    onClick={(e) => {
                        handleClickListItem(e);
                    }}
                >
                    <ListItemText
                        disableTypography
                        primary={enableMailProApprovalProcess && statusMap}
                        classes={{ root: classes.listItemTextRoot }}
                    />
                    {/* NAVBAR AREA NAME */}
                    {currentArea &&
                        currentArea.mlsNeighborhood &&
                        currentArea.mlsNeighborhood.length < 24 ? (
                        // after 24 area will start in next line (Only for checking). While showing we are using 35 character because that time we decreased the font size. In 18px width area will start in next line.
                        <ListItemText
                            primary={
                                <Fragment>
                                    <Typography
                                        key={currentArea.mlsAreaID}
                                        className={classes.listDescription}
                                        title={currentArea.mlsNeighborhood}
                                    >
                                        {currentArea && currentArea.mlsNeighborhood}
                                    </Typography>
                                </Fragment>
                            }
                            className={classes.centerListArea}
                            classes={{ root: classes.listItemTextRoot }}
                        />
                    ) : (
                        <ListItemText
                            primary={
                                <Fragment>
                                    <Typography
                                        key={currentArea.mlsAreaID}
                                        className={classes.listDescriptionForLongAreaNames}
                                        title={currentArea.mlsNeighborhood}
                                    >
                                        {
                                            currentArea && currentArea.mlsNeighborhood
                                        }
                                    </Typography>
                                </Fragment>
                            }
                            className={classes.centerListArea}
                            classes={{ root: classes.listItemTextRoot }}
                        />
                    )}
                    <ListItemText
                        disableTypography
                        classes={{ root: classes.listItemTextRoot }}
                    >
                        <ArrowDropIcon
                            className={classes.icon}
                            fontSize={"large"}
                        />
                    </ListItemText>
                </ListItem>
            </List>
            <Backdrop
                className={classes.approvalBackdrop}
                open={Boolean(anchorEl)}
                onClick={handleClose}
            >
                <Menu
                    MenuListProps={{ style: { padding: 0 } }}
                    id="area-summary"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    className={classNames(classes.menuList)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    {mlsAreas.map((area, index) => (
                        <MenuItem
                            className={classNames(
                                classes.menuItem,
                                classes.menuHover,
                            )}
                            key={area.mlsAreaID}
                            selected={currentArea.mlsAreaID === area.mlsAreaID}
                            value={area.mlsAreaID}
                            onClick={(event) =>
                                handleMenuItemClick(event, index, area)
                            }
                            classes={{
                                selected: classes.menuSelected,
                            }}
                        >
                            <div style={{ width: '100%', minWidth: '20vw' }}>
                                <Grid container className={classes.menuItemContainer} component={"div"}>
                                    <Grid item component={"div"}>
                                        <div className={classes.areaRowContainer}>
                                            <LineItem area={area} classes={classes} extraStyle={{ textDecoration: 'underline' }}>{area.mlsNeighborhood}</LineItem>
                                            <LineItem area={area} classes={classes}> {getFigure(area.numOfPMASending, false)} mailings </LineItem>
                                        </div>
                                    </Grid>
                                    {enableMailProApprovalProcess && !area.isAreaPaused && (
                                        <Grid container className={classes.menuItemContainer} component={"div"}>
                                            <Grid item component={"div"}>

                                                {area.mailingStatus === "Approved" ? (
                                                    <Typography className={classes.approvedText} align="center">Approved</Typography>
                                                ) : (
                                                    <Typography className={classes.incompleteText} align="center">
                                                        You have not approved this area yet
                                                    </Typography>
                                                )}

                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>

                                {area.isAreaPaused ? (
                                    <Grid container className={classes.menuItemContainer} component={"div"}>
                                        <Grid item component={"div"}>
                                            <Typography className={classes.incompleteText} align="center">
                                                THIS AREA IS TEMPORARILY PAUSED
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container className={classes.menuItemContainer} component={"div"}>
                                        <Grid item component={"div"}>
                                            <div className={classes.areaRowContainer}>
                                                <LineItem area={area} classes={classes}>PMA Charges</LineItem>
                                                <LineItem area={area} classes={classes}> ${getFigure(area.currentPMAsCostForArea)} </LineItem>
                                            </div>
                                        </Grid>
                                        <Grid item component={"div"}>
                                            <div className={classes.areaRowContainer}>
                                                <LineItem area={area} classes={classes}>Area Exclusivity Fee</LineItem>
                                                <LineItem area={area} classes={classes}> ${getFigure(area.currentMonthlyExclusivityFeeForArea)} </LineItem>
                                            </div>
                                        </Grid>
                                        <Grid item component={"div"}>
                                            <div className={classes.areaRowContainer}>
                                                <LineItem area={area} classes={classes}>Area Charges</LineItem>
                                                <LineItem area={area} classes={classes}>  ${getFigure(area.currentOverallChargesForArea)} </LineItem>
                                            </div>
                                        </Grid>
                                    </Grid>)}
                            </div>
                        </MenuItem>
                    ))}

                    <MenuItem
                        key={"overall-menu-area-id"}
                        className={classNames(
                            classes.menuItemNoBorderBottom,
                            classes.noHover
                        )}
                        selected={false}
                    >
                        <Grid container className={classes.menuItemContainer}>
                            <Grid item component={"div"}>
                                <div className={classNames(classes.areaRowContainer)}>
                                    <LineItem area={{}} classes={classes} extraStyle={{ fontWeight: 500, fontSize: '17px' }}> Total Mailings</LineItem>
                                    <LineItem area={{}} classes={classes} extraStyle={{ fontWeight: 500, fontSize: '17px' }}> {getFigure(totalNumberOfPMAs, false)} </LineItem>
                                </div>
                            </Grid>
                            <Grid item component={"div"}>
                                <div className={classes.areaRowContainer}>
                                    <LineItem area={{}} classes={classes} extraStyle={{ fontWeight: 500, fontSize: '17px' }}> Total Charges</LineItem>
                                    <LineItem area={{}} classes={classes} extraStyle={{ fontWeight: 500, fontSize: '17px' }}>  ${getFigure(totalCurrentOverallCharges)} </LineItem>
                                </div>
                            </Grid>
                        </Grid>
                    </MenuItem>
                </Menu>
            </Backdrop>
        </>
    );
};

export default compose(withRouter, withStyles(styles))(AreaSummary);