import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import noImage from "../../../../_img/not-available.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Button, Typography, Divider, Container } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { propertyUtil } from "../../PropertyUtil";
import Slide from "@material-ui/core/Slide";
import { red } from "@material-ui/core/colors";
import classNames from "classnames";
import styles from "./propertyDetailsStyles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core";
import PropertyImageSliderDialog from "./PropertyImageSliderDialog";
import moment from "moment";
import { renderLogicUtil } from "../../RenderLogicUtil";
import TestHouse from "./test-house.jpg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PropertyDetailsModal = props => {
  const {
    classes,
    handleClose,
    openImageSlider,
    selectedImageIndex,
    closeImageSlider,
    Fragment,
    setSelectedImageIndex,
    setImageSlider,
    propertyDetails,
    open
  } = props;
  const getCorrectedData = inputString => {
    if (
      !inputString ||
      inputString === "-" ||
      inputString === "0" ||
      inputString === "EmptyValue" ||
      inputString === "*no Site Address*"
    ) {
      return "---";
    }
    return inputString;
  };

  const enlargeImage = imageNumber => {
    let { openMediaViewDialog, propertyDetails } = props;
    let { photos } = propertyDetails;
    if (photos[imageNumber]) {
      openMediaViewDialog(photos[imageNumber]);
    }
  };

  const shortenedMonthDate = myDate => {
    // const today = `${date.getDate()}`;
    // const month = date.toLocaleString("default", { month: "short" });
    // const year = date.getFullYear();
    if (myDate) {
      return moment(new Date(myDate)).format("MMM D, YYYY");
    } else {
      return null;
    }

    // return `${month} ${today} ${year}`;
  };
  const { roundSalePrice, toAcre } = propertyUtil;
  const {
    property,
    // propertyDetails,
    mailingDetails,
    propertySequenceNumber,
    agentData
  } = props;
  console.log("agentData", agentData);
  const {
    listgenUserFirstName,
    isMemberOfTeam,
    teamName,
    teamMembers,
    listgenUserLastName,
    brokerageName
  } = agentData;
  let agentName;
  if (isMemberOfTeam && listgenUserLastName === teamMembers[1].lastName) {
    agentName = `${listgenUserFirstName} & ${teamMembers[1].firstName} ${teamMembers[1].lastName}`;
  }
  if (isMemberOfTeam) {
    agentName = `${listgenUserFirstName} ${listgenUserLastName} & ${teamMembers[1].firstName} ${teamMembers[1].lastName}`;
  } else {
    agentName = `${listgenUserFirstName} ${listgenUserLastName}`;
  }
  const {
    SiteNumber,
    SiteStreet,
    SiteCity,
    SiteState,
    SiteZip,
    Bathfull,
    Lotacres
  } = mailingDetails;
  const neighborhood = propertyDetails.areaText;
  const {
    photos,
    listingID,
    //parcelNumber,Not in use anyo\more
    areaID,
    county,
    age,
    yearBuilt,
    parcelNumber,
    classType,
    propertySubtype,
    listPrice,
    originalPrice,
    beds,
    squareFeet,
    garageSpaces,
    lotSize,
    view,
    listingOfficeName,
    daysOnMarket,
    buyerAgentFullName,
    listDate,
    halfBaths,
    noOfStories,
    description,
    agentRemarks,
    listingAgentFullName,
    listingStatus,
    streetDirPrefix,
    streetName,
    streetNumber,
    streetSuffix,
    listingAgentLicenseID,
    city,
    state,
    zipcode,
    optimizedPhotoURL,
    salePrice,
    lastSaleDate,
    buyerOfficeName,
    areaText
  } = propertyDetails;
  let date = new Date();
  const today = `${date.getDate()}`;
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  //let splitListDate = listDate ? listDate.slice(0, 10).split("-") : null;
  //(`${splitListDate[1]}/${splitListDate[2]}/${splitListDate[0]}`)
  let formattedListDate = listDate
    ? moment(new Date(listDate)).format("M/D/YYYY")
    : null;
  //new modal
  let propPhoto = !photos ? null : photos[0];
  let photoMap =
    photos &&
    photos.map((photo, index) => (
      <div key={index} style={{ cursor: "pointer" }}>
        <img src={photo} alt="Property" style={{ cursor: "pointer" }} />
      </div>
    ));
  //calculate property details
  let havingPropertyDetails =
    propertyDetails && Object.keys(propertyDetails).length > 0 ? true : false;
  let pricePerSqft =
    salePrice && squareFeet ? Math.round(salePrice / squareFeet) : 0;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="propert-view-dialog"
      aria-describedby="propert-view-dialog-description"
      fullScreen={true}
      TransitionComponent={Transition}
      keepMounted
    >
      <div className={classes.propertyDetailsModal}>
        <div className={classes.propertyDetailsHeader}>
          <div className={classes.propertyDetailsHeaderInner}>
            <div className={classes.propertyDetailsHeaderLeft}>
              <div className={classes.propertyDetailsTitle}>
                Property Details
              </div>
              <div className={classes.propertyDetailsHeaderSubtitle}>
                As of {month} {today}, {year}
              </div>
            </div>
            <div className={classes.propertyDetailsHeaderRight}>
              <div className={classes.propertyDetailsHeaderAddress}>
                {streetNumber}{" "}
                {getCorrectedData(streetDirPrefix) !== "---" && streetDirPrefix}{" "}
                <span className={classes.capitalize}>
                  {streetName && streetName.toLowerCase()}{" "}
                  {streetSuffix && streetSuffix.toLowerCase()}
                </span>
              </div>
              <div className={classes.propertyDetailsHeaderAddress}>
                <span className={classes.capitalize}>
                  {city && city.toLowerCase()}
                </span>{" "}
                {state} {zipcode}
              </div>
              <div className={classes.propertyDetailsHeaderSubtitle}>
                X Street: Main St
              </div>
            </div>
          </div>
        </div>
        <div className={classes.propertyDetailsBody}>
          <div className={classes.propertyDetailsBodyHeader}>
            <div
              className={classNames(classes.bodyHeaderLeft, classes.leftCol)}
            >
              <div className={classes.sidewaysPhotoText}>CLICK PHOTO</div>
              <div className={classes.headerPhotoCol}>
                <div className={classes.headerPhotoColSub}>Prepared for:</div>
                <div className={classes.headerPhotoColMain}>
                  XXXXXXX XXXXXXXX
                </div>
                {!optimizedPhotoURL ? (
                  <img
                    src={noImage}
                    alt="No Property Image"
                    className={classes.headerPhotoColImage}
                  />
                ) : (
                  <img
                    src={optimizedPhotoURL}
                    alt="Property Photo"
                    className={classNames(
                      classes.headerPhotoColImage,
                      classes.hoverHand
                    )}
                  />
                )}

                <div className={classes.headerPhotoRow}>
                  <div className={classes.headerPhotoColSub}>Provided by:</div>
                  <div className={classes.photoPhotoMapButton}>Map</div>
                </div>

                <div className={classes.headerPhotoColMain}>{agentName}</div>
                <div className={classes.headerPhotoColSub}>{brokerageName}</div>
              </div>
            </div>
            <div
              className={classNames(classes.bodyHeaderRight, classes.rightCol)}
            >
              <div
                className={classNames(
                  classes.bodyHeaderRightTitle,
                  classes.fullDataRow
                )}
              >
                <div
                  className={classNames(
                    classes.bodyHeaderRightTitleText,
                    classes.dataRowLeft
                  )}
                >
                  AGENT FULL
                </div>
                <div className={classes.dataRowRight}>
                  <div className={classes.salePrice}>Sale Price:</div>
                  <div className={classes.salePriceDataPoint}>$XXXXXX</div>
                </div>
                {/*{getCorrectedData(salePrice) !== "---" && (
                  <div className={classes.dataRowRight}>
                    <div className={classes.salePrice}>Sale Price:</div>
                    <div className={classes.salePriceDataPoint}>
                      ${salePrice && salePrice.toLocaleString()}
                    </div>
                  </div>
                )}*/}
              </div>
              <div className={classes.headerDataBody}>
                <div className={classes.fullDataRow}>
                  <div className={classes.dataRowLeft}>
                    <div className={classes.dataName}>Sale Date:</div>
                    <div className={classes.dataPoint}>
                      {shortenedMonthDate(lastSaleDate)} lastSaleDate?
                    </div>
                  </div>
                  <div className={classes.dataRowRight}>
                    <div
                      className={classNames(
                        classes.soldStatusDataPoint,
                        classes.mr5
                      )}
                    >
                      {listingStatus}
                    </div>
                    {listingStatus === "Sold" && pricePerSqft !== 0 && (
                      <div className={classes.dataPoint}>{pricePerSqft}</div>
                    )}
                  </div>
                </div>
                <div className={classes.fullDataRow}>
                  <div className={classes.dataRowLeft}>
                    <div className={classes.dataName}>COE Date:</div>
                    <div className={classes.dataPoint}>XX, XX, XXXX</div>
                  </div>
                  <div className={classes.dataRowRight}>
                    <div className={classNames(classes.dataName)}>Area:</div>
                    <div className={classes.dataPoint}>
                      ({areaID}) {areaText}
                    </div>
                  </div>
                </div>
                <div className={classes.fullDataRow}>
                  <div className={classes.dataRowLeft}>
                    <div className={classes.dataName}>List Price:</div>
                    <div className={classes.dataPoint}>
                      ${Number(listPrice).toLocaleString()}
                    </div>
                  </div>
                  <div className={classes.dataRowRight}>
                    <div className={classNames(classes.boldDataPoint)}>
                      {beds} Beds | XX Full Baths | {halfBaths} Partial{" "}
                      {halfBaths === "1" ? "Bath" : "Baths"}
                    </div>
                  </div>
                </div>
                <div className={classes.fullDataRow}>
                  <div className={classes.dataRowLeft}>
                    <div className={classes.dataName}>Original LP:</div>
                    <div className={classes.dataPoint}>
                      ${Number(originalPrice).toLocaleString()}
                    </div>
                  </div>
                  <div className={classes.dataRowRight}>
                    <div
                      className={classNames(
                        classes.boldDataPoint,
                        classes.dataRow
                      )}
                    >
                      <p className={classes.mr5}>Approx. Living Square Feet:</p>{" "}
                      <p>xxxx</p>
                    </div>
                  </div>
                </div>
                <div className={classes.fullDataRow}>
                  <div className={classes.dataRowLeft}>
                    <div className={classes.dataName}>Status Change:</div>
                    <div className={classes.dataPoint}>XX/XX/XX</div>
                  </div>
                  <div className={classes.dataRowRight}>
                    <div className={classes.boldDataPoint}>
                      Approx. Lot Size: {toAcre(lotSize)} sqft | Approx. Acres{" "}
                      {toAcre(lotSize, false, true)}
                    </div>
                  </div>
                </div>
                <div className={classes.fullDataRow}>
                  <div className={classes.dataRowLeft}>
                    <div className={classes.dataName}>Days on Market:</div>
                    <div className={classes.dataPoint}>{daysOnMarket}</div>
                  </div>
                  <div className={classes.dataRowRight}>
                    <div className={classNames(classes.dataName)}>Type:</div>
                    <div className={classes.dataPoint}>{propertySubtype}</div>
                  </div>
                </div>
                <div className={classes.fullDataRow}>
                  <div className={classes.dataRowLeft}>
                    <div className={classes.dataName}>Parcel #:</div>
                    <div className={classes.dataPoint}>{parcelNumber}</div>
                  </div>
                  <div
                    className={classNames(
                      classes.dataRowRight,
                      classes.leftAlign
                    )}
                  >
                    <div className={classes.dataRowRight}>
                      <div className={classNames(classes.dataName)}>
                        2nd Living Type:
                      </div>
                      <div className={classes.dataPoint}>XXXXXX</div>
                    </div>
                  </div>
                </div>
                <div className={classes.fullDataRow}>
                  <div className={classes.dataRowLeft}></div>
                  <div
                    className={classNames(
                      classes.dataRowRight,
                      classes.leftAlign
                    )}
                  >
                    <div className={classes.dataRowRight}>
                      <div className={classNames(classes.dataName)}>
                        Zoning:
                      </div>
                      <div
                        className={classNames(classes.dataPoint, classes.mr5)}
                      >
                        XXXXXX
                      </div>
                      <div className={classNames(classes.dataName)}>
                        Land Use:
                      </div>
                      <div className={classes.dataPoint}>XXX</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.firstPageContent}>
          <div className={classes.firstContentRow}>
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>
                  Public Remarks
                </div>
                <div className={classes.firstContentSubtitle}>
                  Property Conditions: XXXXXXX
                </div>
              </div>
            </div>
            <div className={classes.contentRowDivider}></div>
            <div className={classes.firstContentSection}>
              <div className={classes.firstContentParagraph}>XXXXXX</div>
            </div>
          </div>
          <div className={classes.firstContentRow}>
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>
                  Private Remarks
                </div>
              </div>
            </div>
            <div className={classes.contentRowDivider}></div>
            <div className={classes.firstContentSection}>
              <div className={classes.firstContentParagraph}>
                XXXXX is this agent remarks?
              </div>
            </div>
          </div>
          <div className={classes.firstContentRow}>
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>Directions</div>
              </div>
            </div>
            <div className={classes.contentRowDivider}></div>
            <div className={classes.firstContentSection}>
              <div className={classes.firstContentParagraph}>XXXXXX</div>
            </div>
          </div>
          <div className={classes.firstContentRow}>
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>
                  Listing Details 1
                </div>
              </div>
            </div>
            <div className={classes.contentRowDivider}></div>
            <div className={classes.firstContentSection}>
              <div className={classes.firstContentDataList}>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Commission:
                    </div>
                    <div className={classes.dataPoint}>XXXX</div>
                  </div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      Comission Type:
                    </div>
                    <div className={classes.dataPoint}>
                      XXXX is this always Percentage?
                    </div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      List Date:
                    </div>
                    <div className={classes.dataPoint}>
                      {shortenedMonthDate(listDate)}
                    </div>
                  </div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      Ownership:
                    </div>
                    <div className={classes.dataPoint}>xxxxxx</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Status Change:
                    </div>
                    <div className={classes.dataPoint}>XXXXXX date</div>
                  </div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      Occupied By:
                    </div>
                    <div className={classes.dataPoint}>XXXX</div>
                  </div>
                </div>

                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}></div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      Occ:Name:
                    </div>
                    <div className={classes.dataPoint}>
                      XXXX Occupant name | Phone #: XXXX
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.firstContentRow}>
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>
                  Listing Details 2
                </div>
              </div>
            </div>
            <div className={classes.contentRowDivider}></div>
            <div className={classes.firstContentSection}>
              <div className={classes.firstContentDataList}>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Listing Status:
                    </div>
                    <div className={classNames(classes.dataRow)}>
                      <p className={classNames(classes.soldText, classes.mr5)}>
                        XXXXX
                      </p>
                      <p className={classes.dataPoint}>XXXXX</p>
                    </div>
                  </div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      Prop Type:
                    </div>
                    <div className={classes.dataPoint}>
                      XXXX (shown above is that ok?)
                    </div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Listing ID:
                    </div>
                    <div
                      className={classNames(
                        classes.dataPoint,
                        classes.mlsNumberAndGate
                      )}
                    >
                      {listingID}
                    </div>
                    {/*<div className={classNames(classes.dataName)}>
                      Parcel #:
                    </div>
                    <div className={classes.dataPoint}>{parcelNumber}</div>*/}
                  </div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>Style:</div>
                    <div className={classes.dataPoint}>XXXXXX</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Expires on MLS:
                    </div>
                    <div className={classes.dataPoint}>
                      XXXX is this expiry date?
                    </div>
                  </div>
                  <div
                    className={classNames(
                      classes.listDataRowRight,
                      classes.dataRow
                    )}
                  >
                    <div className={classNames(classes.dataName)}>
                      City Limit:
                    </div>
                    <div className={classNames(classes.dataPoint, classes.mr5)}>
                      XXXXX
                    </div>
                    <div className={classNames(classes.dataName)}>
                      Incorporated
                    </div>
                    <div className={classes.dataPoint}>XXXXX</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Sale Price/SQFT:
                    </div>
                    <div className={classes.dataPoint}>$XXXX</div>
                  </div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      County Name:
                    </div>
                    <div className={classes.dataPoint}>{county}</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Possession:
                    </div>
                    <div className={classes.dataPoint}>XXXX</div>
                  </div>
                  <div className={classes.listDataRowRight}></div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Year Built:
                    </div>
                    <div className={classes.dataPoint}>
                      {yearBuilt} data is not coming
                    </div>
                  </div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      Listing Type:
                    </div>
                    <div className={classes.dataPoint}>XXXXXXX</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>Has HOA:</div>
                    <div className={classNames(classes.dataPoint)}>XXXX</div>
                    <div className={classNames(classes.dataDivider)}>|</div>
                    <div className={classNames(classes.dataName)}>Dues:</div>
                    <div className={classes.dataPoint}>$ XX/mo</div>
                  </div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      Finance Terms:
                    </div>
                    <div className={classes.dataPoint}>XXXXX</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.firstContentRow}>
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>
                  Showing Instructions
                </div>
              </div>
            </div>
            <div className={classes.contentRowDivider}></div>
            <div className={classes.firstContentSection}>
              <div className={classes.firstContentCol}>
                <div className={classes.dataRow}>
                  <div className={classNames(classes.boldData, classes.mr5)}>
                    XXXXX |
                  </div>
                  <div className={classNames(classes.boldData, classes.mr5)}>
                    XXXX |
                  </div>
                  <div className={classes.boldData}>XXXX</div>
                </div>
                <div className={classNames(classes.dataRow, classes.mb10)}>
                  <div className={classNames(classes.boldData, classes.mr5)}>
                    XXXX |
                  </div>
                  <div className={classNames(classes.boldData, classes.mr5)}>
                    XXXX |
                  </div>
                  <div className={classNames(classes.boldData, classes.mr5)}>
                    LockBox: XXXX |
                  </div>
                  <div
                    className={classNames(
                      classes.gateCode,
                      classes.mlsNumberAndGate
                    )}
                  >
                    Gate Code: XXXX
                  </div>
                </div>
                <div
                  className={classNames(
                    classes.firstContentParagraph,
                    classes.dataRow
                  )}
                >
                  <div className={classNames(classes.dataName)}>
                    Showing Instructions:
                  </div>
                  <div>XXXXX</div>
                </div>

                <div
                  className={classNames(
                    classes.firstContentParagraph,
                    classes.dataRow
                  )}
                >
                  <div className={classNames(classes.dataName)}>
                    Directions:
                  </div>
                  <div>XXXXX</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.firstContentRow}>
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>
                  Listing Agent
                </div>
              </div>
            </div>
            <div className={classes.contentRowDivider}></div>
            <div className={classes.firstContentSection}>
              <div className={classes.dataRow}>
                <div
                  className={classNames(
                    classes.boldData,
                    classes.mr5,
                    classes.agentName,
                    classes.capitalize
                  )}
                >
                  {listingAgentFullName && listingAgentFullName.toLowerCase()}
                </div>
                <div className={classNames(classes.boldData, classes.mr5)}>
                  XXXXXX |
                </div>
                <div className={classes.boldData}>XXXXX</div>
              </div>
              <div className={classes.listDataRow}>
                <div className={classes.listDataRowLeft}>
                  <div className={classNames(classes.dataName)}>LA DRE #:</div>
                  <div className={classNames(classes.dataPoint)}>
                    {listingAgentLicenseID}
                  </div>
                  <div className={classNames(classes.dataDivider)}>|</div>
                  <div className={classNames(classes.dataName)}>BROKERAGE:</div>
                  <div className={classes.dataPoint}>{listingOfficeName}</div>
                </div>
                <div className={classes.listDataRowRight}>
                  <div className={classNames(classes.dataName)}>
                    BROKERAGE DRE:
                  </div>
                  <div className={classes.dataPoint}>XXXXXXXX</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.secondPageContent}>
          <div className={classes.firstContentRow}>
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>
                  Buyers Agent
                </div>
              </div>
            </div>
            <div className={classes.contentRowDivider}></div>
            <div className={classes.firstContentSection}>
              <div className={classes.dataRow}>
                <div
                  className={classNames(
                    classes.boldData,
                    classes.mr5,
                    classes.agentName
                  )}
                >
                  {buyerAgentFullName}
                </div>
                <div className={classNames(classes.dataDivider)}>|</div>
                <div className={classNames(classes.boldData, classes.mr5)}>
                  XXX.XXX.XXX
                </div>
                <div className={classNames(classes.dataDivider)}>|</div>
                <div className={classNames(classes.boldData)}>XXX@XX.com</div>
                <div className={classNames(classes.dataDivider)}>|</div>
                <div className={classNames(classes.dataName)}>DRE #:</div>
                <div className={classNames(classes.dataPoint, classes.mr5)}>
                  XXXXXXX
                </div>
              </div>

              <div className={classes.listDataRow}>
                <div className={classNames(classes.dataName)}>Brokerage:</div>
                <div className={classes.dataPoint}>{buyerOfficeName}</div>

                <div className={classNames(classes.dataDivider)}>|</div>
                <div className={classes.listDataRowRight}>
                  <div className={classNames(classes.dataName)}>
                    Brokerage DRE:
                  </div>
                  <div className={classes.dataPoint}>XXXXXXXX</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.firstContentCol}>
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>
                  Recent History
                </div>
              </div>
            </div>
            <div className={classes.horizontalBorder}></div>
            <div className={classes.firstContentSection}>
              <div className={classes.contentTable}>
                <div
                  className={classNames(
                    classes.tableContentRow,
                    classes.tableContentTitleRow
                  )}
                >
                  <div
                    className={classNames(classes.dataName, classes.dataTitle)}
                  >
                    MLS ID
                  </div>
                  <div className={classes.dataName}>Change Date</div>
                  <div className={classes.dataName}>Status</div>
                  <div className={classes.dataName}>COE Date</div>
                  <div className={classes.dataName}>Exp Date</div>
                  <div className={classes.dataName}>List Office</div>
                  <div className={classes.dataName}>List/Sale Price</div>
                </div>
                <div className={classes.tableContentRow}>
                  <div className={classes.tableTd}>XXXXX</div>
                  <div className={classes.tableTd}>XXXXX</div>
                  <div className={classes.tableTd}>XXXXX</div>
                  <div className={classes.tableTd}>XXXXX</div>
                  <div className={classes.tableTd}>XXXXX</div>
                  <div className={classes.tableTd}>XXXXX</div>
                  <div className={classes.tableTd}>$XXXXX</div>
                </div>
                <div className={classes.emptyTableContentRow}></div>
                <div className={classes.tableContentRow}>
                  <div className={classes.tableTd}>XXXXX</div>
                  <div className={classes.tableTd}>XXXXX</div>
                  <div className={classes.tableTd}>XXXXX</div>
                  <div className={classes.tableTd}>XXXXX</div>
                  <div className={classes.tableTd}>XXXXX</div>
                  <div className={classes.tableTd}>XXXXX</div>
                  <div className={classes.tableTd}></div>
                </div>
                <div className={classes.tableContentRow}>
                  <div className={classes.tableTd}>XXXXX</div>
                  <div className={classes.tableTd}>XXXXX</div>
                  <div className={classes.tableTd}>XXXXX</div>
                  <div className={classes.tableTd}>XXXXX</div>
                  <div className={classes.tableTd}>XXXXX</div>
                  <div className={classes.tableTd}>XXXXX</div>
                  <div className={classes.tableTd}>$XXXXX</div>
                </div>
              </div>
            </div>
            <div className={classes.horizontalBorder}></div>
          </div>

          {/*<div className={classes.firstContentRow}>
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>
                  Listing Details 1
                </div>
              </div>
            </div>
            <div className={classes.contentRowDivider}></div>
            <div className={classes.firstContentSection}>
              <div className={classes.firstContentDataList}>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Commission:
                    </div>
                    <div className={classes.dataPoint}>2.50</div>
                  </div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      Comission Type:
                    </div>
                    <div className={classes.dataPoint}>Percentage</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      List Date:
                    </div>
                    <div className={classes.dataPoint}>Apr 1, 2019</div>
                  </div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      Ownership:
                    </div>
                    <div className={classes.dataPoint}>xxxxxx</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Status Change:
                    </div>
                    <div className={classes.dataPoint}>May 25, 2019</div>
                  </div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      Occupied By:
                    </div>
                    <div className={classes.dataPoint}>Tenant</div>
                  </div>
                </div>
              </div>
            </div>
          </div>*/}
          {/*<div className={classes.firstContentRow}>
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>Directions</div>
              </div>
            </div>
            <div className={classes.contentRowDivider}></div>
            <div className={classes.firstContentSection}>
              <div className={classes.firstContentParagraph}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                Curae.
              </div>
            </div>
          </div>*/}
          <div className={classes.firstContentRow}>
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>
                  Building Details
                </div>
              </div>
            </div>
            <div className={classes.contentRowDivider}></div>
            <div className={classes.firstContentSection}>
              <div className={classes.firstContentDataList}>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Number of Stories:
                    </div>
                    <div className={classNames(classes.dataRow)}>
                      <p className={classes.dataPoint}>{noOfStories}</p>
                    </div>
                  </div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      Water Source:
                    </div>
                    <div className={classes.dataPoint}>XXXXX</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>Well:</div>
                    <div className={classes.dataPoint}>XXXXX</div>
                  </div>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Year Built:
                    </div>
                    <div
                      className={classNames(
                        classNames(classes.dataPoint, classes.mr5)
                      )}
                    >
                      {yearBuilt}
                    </div>

                    <div className={classNames(classes.dataName)}>Age:</div>
                    <div className={classes.dataPoint}>{age}</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Foundation:
                    </div>
                    <div className={classes.dataPoint}>XXXX</div>
                  </div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      Structure Sqft:
                    </div>
                    <div className={classes.dataPoint}>XXXXX</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>Roof:</div>
                    <div className={classes.dataPoint}>XXXXX</div>
                  </div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      Living SQFT:
                    </div>
                    <div className={classes.dataPoint}>{squareFeet}</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Cooling - AC:
                    </div>
                    <div className={classes.dataPoint}>XXXXX</div>
                  </div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      Guest House/ADU:
                    </div>
                    <div className={classes.dataPoint}>XXXXXX</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>Heating:</div>
                    <div className={classes.dataPoint}>XXXXXXXX</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.horizontalBorder}></div>
          <div
            className={classNames(classes.firstContentRow, classes.noMargin)}
          >
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>
                  Views | Style
                </div>
              </div>
            </div>
            <div className={classes.contentRowDivider}></div>
            <div className={classes.firstContentSection}>
              <div className={classNames(classes.dataRow)}>
                <div className={classNames(classes.firstContentParagraph)}>
                  {view}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.horizontalBorder}></div>
          <div className={classes.firstContentRow}>
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>
                  Interior Details
                </div>
              </div>
            </div>
            <div className={classes.contentRowDivider}></div>
            <div className={classes.firstContentSection}>
              <div className={classes.firstContentDataList}>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Bedrooms:
                    </div>
                    <div className={classNames(classes.dataRow)}>
                      <p className={classes.dataPoint}>{beds}</p>
                    </div>
                  </div>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Dining Room:
                    </div>
                    <div
                      className={classNames(
                        classNames(classes.dataPoint, classes.mr5)
                      )}
                    >
                      XXXXX
                    </div>

                    <div className={classNames(classes.dataName)}>Formal:</div>
                    <div className={classes.dataPoint}>XXXXXX</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Full Baths:
                    </div>
                    <div
                      className={classNames(
                        classNames(classes.dataPoint, classes.mr5)
                      )}
                    >
                      XXXXX
                    </div>

                    <div className={classNames(classes.dataName)}>
                      Partial Baths:
                    </div>
                    <div className={classes.dataPoint}>{halfBaths}</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  {/*<div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>Heating:</div>
                    <div className={classes.dataPoint}>XXXXX</div>
                  </div>*/}
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      Dining Other:
                    </div>
                    <div className={classes.dataPoint}>XXXXX</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  {/*<div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>Cooling:</div>
                    <div className={classes.dataPoint}>XXXXX</div>
                  </div>*/}
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      Has Fireplace:
                    </div>
                    <div className={classes.dataPoint}>XXXX</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Primary on 1st Floor:
                    </div>
                    <div className={classes.dataPoint}>XXXXX</div>
                  </div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      Fireplace:
                    </div>
                    <div className={classes.dataPoint}>XXXXXX</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Family Room:
                    </div>
                    <div className={classes.dataPoint}>XXXX</div>
                  </div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>
                      Flooring:
                    </div>
                    <div className={classes.dataPoint}>XXXXXX</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Other Room:
                    </div>
                    <div className={classes.dataPoint}>XXXXX</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.firstContentRow}>
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>
                  Exterior Details
                </div>
              </div>
            </div>
            <div className={classes.contentRowDivider}></div>
            <div className={classes.firstContentSection}>
              <div className={classes.firstContentDataList}>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Ext Amenities:
                    </div>
                    <div className={classNames(classes.dataRow)}>
                      <p className={classes.dataPoint}>XXXXXX</p>
                    </div>
                  </div>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Horse Property
                    </div>
                    <div
                      className={classNames(
                        classNames(classes.dataPoint, classes.mr5)
                      )}
                    >
                      XXXXX
                    </div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Soil Condit:
                    </div>
                    <div
                      className={classNames(
                        classNames(classes.dataPoint, classes.mr5)
                      )}
                    >
                      XXXXXX
                    </div>
                    <div className={classes.listDataRowRight}>
                      <div className={classNames(classes.dataName)}>
                        Security:
                      </div>
                      <div className={classes.dataPoint}>XXXX</div>
                    </div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Lot Description:
                    </div>
                    <div className={classes.dataPoint}>XXXX</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.firstContentRow}>
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>
                  Garage & <br /> Parking Details
                </div>
              </div>
            </div>
            <div className={classes.contentRowDivider}></div>
            <div className={classes.firstContentSection}>
              <div className={classes.firstContentDataList}>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Garage Spaces:
                    </div>
                    <div className={classNames(classes.dataRow)}>
                      <p className={classes.dataPoint}>{garageSpaces}</p>
                    </div>
                  </div>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Electric Vehicle Hookups:
                    </div>
                    <div
                      className={classNames(
                        classNames(classes.dataPoint, classes.mr5)
                      )}
                    >
                      XXXXX
                    </div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Carports:
                    </div>
                    <div
                      className={classNames(
                        classNames(classes.dataPoint, classes.mr5)
                      )}
                    >
                      XXXXX
                    </div>
                  </div>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Parking Spaces:
                    </div>
                    <div
                      className={classNames(
                        classNames(classes.dataPoint, classes.mr5)
                      )}
                    >
                      XXXXXX
                    </div>

                    <div className={classNames(classes.dataName)}>
                      Open Parking:
                    </div>
                    <div className={classes.dataPoint}>XXXX</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Garage Parking Features:
                    </div>
                    <div className={classes.dataPoint}>
                      XXXXX is this "garageParking" code?
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.firstContentRow}>
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>
                  Utilities/Water
                </div>
              </div>
            </div>
            <div className={classes.contentRowDivider}></div>
            <div className={classes.firstContentSection}>
              <div className={classes.firstContentDataList}>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Sewer/Septic:
                    </div>
                    <div className={classNames(classes.dataRow)}>
                      <p className={classes.dataPoint}>XXXXX</p>
                    </div>
                  </div>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Energy Saving:
                    </div>
                    <div
                      className={classNames(
                        classNames(classes.dataPoint, classes.mr5)
                      )}
                    >
                      XXXXX
                    </div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Utilities:
                    </div>
                    <div
                      className={classNames(
                        classNames(classes.dataPoint, classes.mr5)
                      )}
                    >
                      XXXXX
                    </div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Water Source:
                    </div>
                    <div className={classes.dataPoint}>XXXX</div>
                  </div>
                  <div className={classes.listDataRowRight}>
                    <div className={classNames(classes.dataName)}>Solar:</div>
                    <div className={classes.dataPoint}>XXXX</div>
                  </div>
                </div>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classes.dataPoint}>XXXX/XXXX</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.firstContentRow}>
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>HOA</div>
              </div>
            </div>
            <div className={classes.contentRowDivider}></div>
            <div className={classes.firstContentSection}>
              <div className={classes.firstContentDataList}>
                <div className={classes.listDataRow}>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Homeowner Association:
                    </div>
                    <div className={classNames(classes.dataRow)}>
                      <p className={classes.dataPoint}>XXXXX</p>
                    </div>
                  </div>
                  <div className={classes.listDataRowLeft}>
                    <div className={classNames(classes.dataName)}>
                      Subdivision
                    </div>
                    <div
                      className={classNames(
                        classNames(classes.dataPoint, classes.mr5)
                      )}
                    >
                      XXXXXX
                    </div>

                    <div className={classNames(classes.dataName)}>
                      HOA Name:
                    </div>
                    <div className={classes.dataPoint}>XXXXXX</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.firstContentRow}>
            <div className={classes.firstContentTitle}>
              <div className={classes.firstContentInnerTitle}>
                <div className={classes.firstContentTitleText}>Schools</div>
              </div>
            </div>
            <div className={classes.contentRowDivider}></div>
            <div className={classes.firstContentSection}>
              <div className={classes.listDataRow}>
                <div className={classes.firstContentParagraph}>
                  <div className={classNames(classes.dataName)}>
                    Elem School District Name:
                  </div>
                  <div className={classNames(classes.dataRow)}>
                    <p className={classes.dataPoint}>XXXXX</p>
                  </div>
                </div>
              </div>
              <div className={classes.listDataRow}>
                <div className={classes.listDataRowLeft}>
                  <div className={classNames(classes.dataName)}>
                    Middle School District Name:
                  </div>
                  <div
                    className={classNames(
                      classNames(classes.dataPoint, classes.mr5)
                    )}
                  >
                    XXXXX
                  </div>
                </div>
              </div>
              <div className={classes.listDataRow}>
                <div className={classes.listDataRowLeft}>
                  <div className={classNames(classes.dataName)}>
                    High School District Name:
                  </div>
                  <div className={classes.dataPoint}>XXXXX</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default compose(withStyles(styles))(PropertyDetailsModal);
