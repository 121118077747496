
import React, { Component, Fragment, useEffect } from 'react';
import classNames from 'classnames';
import {
    Grid,
    Typography,
    Card,
    CardMedia,
    CardContent
} from '@material-ui/core';
import compose from "recompose/compose";
import _ from 'lodash';
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import PropTypes, { object } from 'prop-types';

const ProfileCard = (props) => {
    const { classes, profileData } = props;
    return (
        <Fragment>
            <Grid className={classes.grid} container>
                {profileData.map(item => (
                    <Grid item lg={6} sm={12} xs={12} md={6} className={classes.padding4Unit} key={item.id}>
                        <Card className={classes.card}>
                            <div className={classes.cardDetails}>
                                <CardMedia
                                    className={classes.media}
                                    image={item.image}
                                    title={item.name}
                                />
                                <CardContent className={classNames(classes.cardContent)}>
                                    <Typography gutterBottom variant="h6" className={classes.leftAlign}>
                                        {item.name}
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle2" className={classes.leftAlign} color="primary">
                                        {item.designation}
                                    </Typography>
                                    <div className={classes.cardContentHeight}>
                                        <Typography variant="body1" className={classes.leftAlign}>
                                            {item.description}
                                        </Typography>
                                    </div>
                                </CardContent>
                            </div>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Fragment>
    );
};

ProfileCard.propTypes = {
    profileData: PropTypes.arrayOf(object).isRequired
};

export default compose(
    withStyles(styles),
)(ProfileCard);