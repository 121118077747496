// WHAT A COINCIDENCE!
// -The same rules apply to this red alert as the above two alerts.
// -With one exception: The system does not delete this alert after one year. (no need to delete this since there is no 'annual' trigger that would make the previous alert out of date)
// Amit:- Added a new condition. If all mailing related details are empty than no need to show coincidence. Because it'a not gonna make any sense
import React, { Fragment } from "react";
import { propertyUtil } from "../../PropertyUtil";
import EditMailingDetailsDialogV2 from "./EditMailingDetailsDialogV2";
import CustomNoteDialogV2 from "./Notes/PersonalNote/CustomNoteDialogV2";


const MailingAddressView = (props) => {
  let {
    mailingDetails, areaID, agentID, mailerProID, mailingStatus, changeDetails, propertyDetails, propertyData, agentData, hideEditMailingAddressDialog, property, propertySequenceNumber, lastPMAPrintDate, isMailProLocked
  } = props;
  //check hideEditMailingAddressDialog
  hideEditMailingAddressDialog = hideEditMailingAddressDialog ? true : false;
  const {
    // mailingAddress,
    coincidence,
    MailAddress,
    MailCity,
    MailState,
    MailZip,
  } = mailingDetails;

  // const nameCorrector = inputString => {
  //   if (
  //     !inputString ||
  //     inputString === "" ||
  //     inputString === "0" ||
  //     inputString === "EmptyValue" ||
  //     inputString === "-"
  //   ) {
  //     return "";
  //   }
  //   return inputString;
  // };

  return (
    <Fragment>
      <div className="capitalizeTextTransform">
        <p>{propertyUtil.emptyValueCheck(MailAddress).toLowerCase()}</p>
        <p>
          {propertyUtil.emptyValueCheck(MailCity) !== ""
            ? propertyUtil.emptyValueCheck(MailCity).toLowerCase() + ","
            : ""}
             {' '}
          {propertyUtil.emptyValueCheck(MailState) !== ""
            ? propertyUtil.emptyValueCheck(MailState) + ""
            : ""}
          
        </p>
        <p>
        {propertyUtil.emptyValueCheck(MailZip) !== ""
            ? propertyUtil.emptyValueCheck(MailZip) + ""
            : ""}</p>
      </div>
      {!hideEditMailingAddressDialog ?
        <EditMailingDetailsDialogV2
          mailingDetails={mailingDetails}
          areaID={areaID}
          agentID={agentID}
          mailerProID={mailerProID}
          mailingStatus={mailingStatus}
          changeDetails={changeDetails}
          propertyData={propertyData}
          propertyDetails={propertyDetails}
          newMailingsMode={false}
          newMailingsModeID={''}
          closeFn={undefined}
          propertySequenceNumber={propertySequenceNumber}
          property={property}
          isMailProLocked={isMailProLocked}
        />
        : ''}
      {/* <p><br/></p> */}
      {coincidence ?
        <Fragment>
          {/* Check for Coincidence here because we aren't calling absentee HO  */}
          <br />
          <CustomNoteDialogV2
            cameFrom="Coincidence"
            agentID={agentID}
            areaID={areaID}
            mailerProID={mailerProID}
            mailingStatus={mailingStatus}
            changeDetails={changeDetails}
            propertyDetails={propertyDetails}
            propertyData={propertyData}
            mailingDetails={mailingDetails}
            agentData={agentData}
            propertySequenceNumber={propertySequenceNumber}
            property={property}
            lastPMAPrintDate={lastPMAPrintDate}
            isMailProLocked={isMailProLocked}
          />
          <br />
        </Fragment>
        : ''}
    </Fragment>
  );
}

export default MailingAddressView;
