import React, { Component } from 'react';

class AgentRecord extends Component {
  render() {
    /**get data from props */
    const {
      MailAddress,
      MailCity,
      MailState,
      OwnerFirst,
      OwnerLast,
    } = this.props.mailingDetails;
    return (
      <tr className="property-record agent-record">
        <td>{null}</td>
        <td>
          {OwnerFirst && (OwnerFirst !== 'EmptyValue' ? OwnerFirst : '-')} {OwnerLast && (OwnerLast !== 'EmptyValue' ? OwnerLast : '-')}
        </td>
        <td>-</td>
        <td>-</td>
        <td>
          {' '}
          <div className="tagpill team-member">Team Member</div>
        </td>
        <td>-</td>
        <td>
          <p>{MailAddress && (MailAddress !== 'EmptyValue' ? MailAddress : '-')}</p>
          <p>
            {MailCity && (MailCity !== 'EmptyValue' ? MailCity : '-')}, {MailState && (MailState !== 'EmptyValue' ? MailState : '-')}
          </p>
        </td>
        <td>-</td>
        <td>
          {' '}
          <p>{MailAddress && (MailAddress !== 'EmptyValue' ? MailAddress : '-')}</p>
          <p>
            {MailCity && (MailCity !== 'EmptyValue' ? MailCity : '-')}, {MailState && (MailState !== 'EmptyValue' ? MailState : '-')}
          </p>
        </td>
        <td>-</td>
        <td>-</td>

        <td>-</td>
      </tr>
    );
  }
}

export default AgentRecord;
