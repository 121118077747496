import React, { Component, Fragment, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Divider, Input, TextField, ListSubheader, List, ListItem, Collapse, IconButton } from '@material-ui/core';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { compose } from 'recompose';
import styles, { getDDListStyle, getDDItemStyle } from "../styles";
import { Dashboard as DashboardLayout } from 'layouts';
import { withSnackbar } from 'notistack';
import { Portlet } from 'components';
import { PortletContent } from 'components';
import { PortletHeader } from 'components';
import classNames from 'classnames';
import { PortletFooter } from 'components';
import { ImageUploadDialog } from 'components';
import { GetAllDesignationsData } from 'services/others';
import Base from 'common/constants';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { GetStateStorage, IsValidSessionSwitch } from 'common/storage';
import { allStrengthDataBase, allStrengthDescriptions } from './strengthData';
import moment from 'moment';
import { GetPmaAdminDataAPI } from 'services/agent';
import { AddUpdatePmaAdmin } from 'services/agent';
import { showErrorNotification } from 'common/helpers';
import { showSuccessNotification } from 'common/helpers';
import { withRouter } from 'react-router-dom';
import { UPDATE_PMA_STRENGTH_COUNT } from 'store/types';
import { Store } from 'Store';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';
import { MediaViewComponent } from 'components';
import DocumentTitle from 'react-document-title';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Skeleton } from '@material-ui/lab';

const AgentStrengths = (props) => {
  const { classes } = props;
  const { dispatch } = useContext(Store);
  //session related variables
  const isAValidSessionSwitch = IsValidSessionSwitch();
  const { userData } = GetStateStorage();
  const regexWithPDF = new RegExp(/^.*\.(PDF|pdf)$/);
  //strength count
  const minStrengthCount = Base.PMA_ADMIN.MIN_STRENGTH_COUNT;
  const maxStrengthCount = Base.PMA_ADMIN.MAX_STRENGTH_COUNT;
  const perStrengthCharacterCount = 50;
  const perMinStrengthCharacterCount = 5;
  //system variables
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [openUploadImageDialog, setUploadImageDialog] = React.useState(false);
  const [openViewImageDialog, setViewImageDialog] = React.useState(false);
  const [uploadImageKey, setUploadImageKey] = React.useState(null);
  const [uploadCurrentImage, setUploadCurrentImage] = React.useState(null);
  const [openMenu, setOpenMenu] = React.useState("");
  //const [isCustomMode, setCustomMode] = React.useState(false);
  const [designationData, setDesignationData] = React.useState([]);
  const [strengthData, setStrengthsData] = React.useState([]);
  /**loading variables */
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);
  //custom list
  const customList = allStrengthDataBase;
  //checkf or admin
  const isAdminAccess = isAValidSessionSwitch || userData.listgenRoleID === Base.APP_DATA.APP_ADMIN_ROLE_ID;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!designationData.length) {
      getAllDesignationsData();
    }
  }, []);

  useEffect(() => {
    if (designationData && Object.keys(designationData).length > 0) {
      //load the designations now
      loadAllAddedStrengths();
    }
  }, [designationData]);

  const addRemoveStrength = (optionText, optionID, isaAddition, isCustom, isSuggestion, imageIcon) => {
    let xStrengthData = [...strengthData];
    if (isaAddition) {
      //one more strength added
      xStrengthData.push(
        {
          listgenUserID: userData.listgenUserID,
          optionText,
          optionID: parseInt(optionID),
          designationName: ((!isCustom || isSuggestion) ? optionText : ""),
          isCustom: (isCustom ? isCustom : false),
          isSuggestion: (isSuggestion ? isSuggestion : false),
          //check any image exist in designationImages or not
          designationLogoURL: imageIcon,
          designationLogoText: "",
          createdDate: moment(new Date()).format('MM-DD-YYYY h:mm A'),
          isAgentUploaded: (isAValidSessionSwitch ? false : true),
          designationSortOrder: selectedOptions.length,
          designationsVerificationStatus: ""
        }
      );
      setStrengthsData(xStrengthData);
    } else {
      let tempData = xStrengthData.filter(item => item.optionID !== optionID);
      setStrengthsData(tempData);
    }
  };
  //https://designations-icons.s3.amazonaws.com/realtor.png"


  const addMoreOption = (optionText, optionID, isCustom, isSuggestion, imageIcon = '') => {
    let xList = [...selectedOptions];
    //optionID can int and string both so
    optionID = parseInt(optionID);
    //check item exist or not
    let isItem = xList.find(xItem => xItem === optionID);
    if (!isItem && selectedOptions.length > minStrengthCount) {//only return if its not a selected option
      return;
    }
    if (isItem) {
      //adding the items
      xList = xList.filter(xItem => optionID !== xItem);
      //call the strength method now
      addRemoveStrength(optionText, optionID, false, isCustom, isSuggestion, imageIcon);
    } else {
      //removing the item
      xList.push(optionID);
      //call the strength method now
      addRemoveStrength(optionText, optionID, true, isCustom, isSuggestion, imageIcon);
    }
    setSelectedOptions(xList);
  };

  const openUploadMedia = async (key, currentImage) => {
    setUploadImageDialog(true);
    setUploadCurrentImage(currentImage);
    setUploadImageKey(key);
  };

  const handleStrengthUplaod = async (uploadedImageUrl) => {
    if (uploadedImageUrl) {
      let xData = [...strengthData];
      for (let i = 0, len = xData.length; i < len; i++) {
        if (i === uploadImageKey) {
          xData[i]['designationLogoURL'] = uploadedImageUrl;
          //remove the text becuase image is uplaoded
          xData[i]['designationLogoText'] = "";
        }
      }
      //set back to state
      setStrengthsData(xData);
    }
    setUploadImageDialog(false);
    setUploadCurrentImage(null);
    setUploadImageKey(null);
    setShowError('');
  };

  const handleStrengthEditing = async (fieldName, strengthOptionID, newValue) => {
    let xStrengthData = [...strengthData];
    xStrengthData.map(item => {
      if (item.optionID === strengthOptionID) {
        item[fieldName] = newValue;
        //check if filed is image or text then empty the opoosite one
        if (fieldName === 'designationLogoURL') {
          item['designationLogoText'] = '';
        } else if (fieldName === 'designationLogoText') {
          item['designationLogoURL'] = '';
        }
      }
      return item;
    });
    setStrengthsData(xStrengthData);
    setShowError('');
  };

  //Media view methods
  const openViewMedia = async (currentImage) => {
    setViewImageDialog(true);
    setUploadCurrentImage(currentImage);
  };

  const closeViewMedia = async () => {
    setViewImageDialog(false);
    setUploadCurrentImage(null);
  };

  const makeDeisgnationDataSequence = (designationDataArray) => {
    /**first get the Realtor from list */
    let returnData = [];
    // let xData = designationDataArray.find(item => item.listgenDesignationName === 'Realtor');
    // if (xData) {
    //   returnData.push(xData);
    // }
    // /**Now Broker Associate */
    // xData = designationDataArray.find(item => item.listgenDesignationName === 'Broker Associate');
    // if (xData) {
    //   returnData.push(xData);
    // }
    /**now short remaining in alphabetical order */
    designationDataArray.sort(function (a, b) {
      if (a.listgenDesignationName < b.listgenDesignationName) { return -1; }
      if (a.listgenDesignationName > b.listgenDesignationName) { return 1; }
      return 0;
    });
    /**now push to return data */
    // designationDataArray.forEach(item => {
    //   if (item.listgenDesignationName !== 'Realtor' && item.listgenDesignationName !== 'Broker Associate') {
    //     returnData.push(item);
    //   }
    // });
    //customList["Designations"] = returnData;
    customList["Designations"] = designationDataArray;
    return customList;
    //return [...customList, ...returnData];
  };

  const getAllDesignationsData = () => {
    setIsLoading(true);
    /**make loading true */
    GetAllDesignationsData()
      .then(data => {
        if (data.length > 0) {
          setDesignationData(makeDeisgnationDataSequence(data));
        } else {
          setDesignationData([]);
        }
      })
      .catch(err => {
        //showErrorNotification(this.props, "Unable to process request.");
        setDesignationData([]);
      });
  };


  const loadAllAddedStrengths = () => {
    /**make loading true */
    try {
      GetPmaAdminDataAPI("getStrengths", userData.listgenUserID)
        .then(data => {
          if (data.length > 0) {
            //sort the data now
            setStrengthsData(data);
            setSelectedOptions(data.map(item => item.optionID));
            console.log('stregth dat alogged==', data);
          } else {
            setStrengthsData([]);
          }
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
          //setAddedTestimonials([]);
        });
    } catch (err) {
      setIsLoading(false);
      //setAddedTestimonials([]);
    }
  };

  const isValidForm = () => {
    let isValid = true;
    //IF isAValidSessionSwitch means its the admin not agent.
    //if its admin then check from image and text also
    //to validate check 3 value should not be empty
    //|| (item.designationLogoURL === '' || item.designationLogoText === '')
    let data = (isAValidSessionSwitch ? strengthData.filter(item => item.designationName === '' || (item.designationName && item.designationName.length < perMinStrengthCharacterCount)) : strengthData.filter(item => (item.designationName === '' || item.designationName.length < perMinStrengthCharacterCount)));
    //minimum length required is 4.
    if (data && data.length)
      isValid = false;
    //return the data
    return isValid;
  };

  const handleSubmitForm = () => {
    //first check for error states
    let isValid = isValidForm();
    if (!isValid) {
      //show the errors
      setShowError(true);
    } else {
      //now start loading and submit the form
      try {
        setBtnLoading(true);
        let xStrengthData = [...strengthData];
        //add the verification status key if it's an admin
        if (isAdminAccess) {
          //add the verification status here
          xStrengthData = xStrengthData.map(item => {
            item.designationsVerificationStatus = "verified";
            return item;
          });
        }
        //console.log("Final strength data logged=", strengthData);
        //return;
        //call the API now
        //console.log(strengthData);
        //return;
        AddUpdatePmaAdmin("addUpdateStrength", xStrengthData)
          .then(data => {
            showSuccessNotification(props, `Designations and/or Strengths saved. Moving on to ADVANTAGES...`);
            setBtnLoading(false);
            //update the store
            dispatch({
              type: UPDATE_PMA_STRENGTH_COUNT,
              count: data
            });
            //redirect to advantages page
            setTimeout(() => {
              props.history.push('/agent/myadvantages');
            }, 3000);
          })
          .catch(err => {
            showErrorNotification(props, "Something went wrong while processing your request.");
            setBtnLoading(false);
          });
      } catch (err) {
        showErrorNotification(props, "Unable to process request.");
        //console.log(err);
        setBtnLoading(false);
      }
    }
  };

  const openPDFInNewTab = url => {
    var win = window.open(url, '_blank');
    win.focus();
  };

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let dataToSort = Array.from(strengthData);
    let startIndex = result.source.index;
    let endIndex = result.destination.index;
    let [startData] = dataToSort.splice(startIndex, 1);
    dataToSort.splice(endIndex, 0, startData);
    setStrengthsData(dataToSort);
    // setTimeout(() => {
    //   handleSubmitForm();
    // }, 500);
  };


  return (
    <Fragment>
      {openViewImageDialog ?
        <MediaViewComponent
          mediaURL={uploadCurrentImage}
          mediaTitle={"Agent Strength /or Designation Logo"}
          onOpen={() => { }}
          onClose={closeViewMedia}
        />
        : ''}
      {openUploadImageDialog ?
        <ImageUploadDialog
          openDialog={true}
          onSubmit={handleStrengthUplaod}
          onClose={handleStrengthUplaod}
          isMyAccountUpload={isAValidSessionSwitch}
          imageType={"designationLogo"}
          listgenUserID={userData.listgenUserID}//not required  because designation Logo doesnt required any image upload
          currentImage={uploadCurrentImage}
          teamMode={false}
        />
        : ''}
      <DocumentTitle title="My Strengths / Designations">
        <DashboardLayout title="">
          <div className={classes.root}>
            <Portlet className={classes.contentPadded}>
              <PortletContent noPadding>
                <Grid container>
                  <Grid lg="12">
                    <div className={classNames(classes.width100, classes.displyFlex, classes.columnFlex, classes.centerJustify, classes.centerAlign, classes.topTextSection)}>
                      <Typography align="center" className={classNames(classes.headerMainFont, classes.bold500Font)} color="primary">My Strengths /&nbsp;Designations</Typography>
                      <Typography align="center" className={classNames(classes.strengthFont)}>Please add up to {maxStrengthCount} Designations and/or Strengths.</Typography>
                      <Typography align="center" className={classNames(classes.strengthFont)}>In every few issues of your PMAs, these {minStrengthCount}-{maxStrengthCount} points will print on the back cover page.</Typography>
                      {/* <Typography align="center" className={classNames(classes.strengthFont, classes.paletteRed)}>
                      <span className={classes.bold500Font}>Note:</span> <span className={classes.underline}>A minimum of {minStrengthCount} Strengths and/or Designations are required</span> before they will print in your PMAs.
                  </Typography> */}
                    </div>
                  </Grid>
                </Grid>
                <div className={classes.strengthEditDiv}>
                  <div className={classNames(classes.strengthsLeftSection, classes.width30, classes.lightBlueBG, classes.stickyHeadNormal, classes.displyFlex, classes.columnFlex)}>
                    <div className={classes.strengthsLeftSectionH}>
                      <Typography variant="body1" className={classes.bold500Font} color="primary">Strength &amp; Designation Suggestions</Typography>
                      <div className={classNames(classes.width100, classes.displyFlex, classes.spaceBWJustify)}>
                        <Typography variant="body1"> Scroll to see the complete list.</Typography>
                        {/* <div className={classNames(classes.blueLink, classes.pointer, classes.sampleLink)}>View Sample</div> */}
                      </div>
                    </div>
                    <Divider />
                    {isLoading ?
                      // <div className={classNames(classes.progressWrapper, classes.displyFlex, classes.centerJustify, classes.columnFlex)}>
                      <div>
                        {/* <CircularProgress /> */}
                        <Skeleton animation="wave" height={30} variant="rect" width="100%" />
                        <Skeleton animation="wave" height={30} variant="rect" width="90%" style={{ margin: '4px 0 16px 10%' }} />
                        <Skeleton animation="wave" height={30} variant="rect" width="90%" style={{ margin: '4px 0 16px 10%' }}  />
                        <Skeleton animation="wave" height={30} variant="rect" width="100%" />
                        <Skeleton animation="wave" height={30} variant="rect" width="90%" style={{ margin: '4px 0 16px 10%' }} />
                        <Skeleton animation="wave" height={30} variant="rect" width="90%" style={{ margin: '4px 0 16px 10%' }}  />
                        <Skeleton animation="wave" height={30} variant="rect" width="100%"/>
                        <Skeleton animation="wave" height={30} variant="rect" width="90%" style={{ margin: '4px 0 16px 10%' }} />
                        <Skeleton animation="wave" height={30} variant="rect" width="90%" style={{ margin: '4px 0 16px 10%' }}  />
                      </div>
                      :
                      <Fragment>
                        <div className={classNames(classes.optionListDiv, (designationData.Designations ? classes.optionWebkitScroll : ''), (selectedOptions.length > minStrengthCount ? classes.disableCursor : ''))}>
                          <List className={classes.listRoot} subheader={<li />}>
                            {/* //Dont show the expand less and more for the first and second cateogry (0, 1) collaspe. 0,1 always be expanded */}
                            {(Object.keys(designationData)).map((categoryName, key) => {
                              return (<li key={`section-c-${key}`} className={classes.listSection}>
                                <ul className={classes.ul}>

                                  <ListSubheader
                                    className={classNames(classes.listSubHeader, classes.displyFlex, classes.spaceBWJustify, classes.centerAlign, classes.pointer, (key > 1 ? classes.listSubHeaderBorder : ''))}
                                    onClick={event => (openMenu === key ? setOpenMenu("") : setOpenMenu(key))}
                                  >
                                    <ListItemText
                                      primary={<Fragment>
                                        <span className={classes.bold500Font} style={{color: 'white'}}>{categoryName}</span>
                                  &nbsp;
                                  <span style={{color: 'white'}}>{allStrengthDescriptions[categoryName] ? <Fragment>({allStrengthDescriptions[categoryName]})</Fragment> : ''}</span>
                                      </Fragment>}
                                    //secondary={allStrengthDescriptions[categoryName] ? allStrengthDescriptions[categoryName] : ''} color="primary"
                                    //classes={{ primary: classes.bold500Font }}
                                    />
                                    {key > 1 ? (openMenu === key ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />) : ''}
                                  </ListSubheader>
                                  {/* //In strengths we maintain the exactly selected option. so here we hide the suggestion instead we can truncate that. In advantge we can do because after saving we dont keep track of actual selected advantages suggestion row       */}
                                  <Collapse in={(key <= 1) || (key > 1 && openMenu === key)} timeout="auto" unmountOnExit>
                                    {designationData[categoryName].map((subCateogry) => {
                                      let itemName = ((!subCateogry.isCustom || subCateogry.isSuggestion) ? subCateogry.listgenDesignationName : subCateogry.listgenDesignationID);
                                      let imageIcon = (subCateogry.listgenDesignationIcon ? subCateogry.listgenDesignationIcon : '');
                                      return (
                                        <ListItem
                                          key={`item-sc-${key}-${subCateogry.listgenDesignationID}`}
                                          className={classNames(classes.muiItemGrouped, classes.pointer)}
                                          onClick={event => addMoreOption(subCateogry.listgenDesignationName, subCateogry.listgenDesignationID, (subCateogry.isCustom), subCateogry.isSuggestion, imageIcon)}
                                        >
                                          <Checkbox color="primary" key={"agent_advantages_checkbox-" + subCateogry.listgenDesignationID}
                                            checked={selectedOptions.indexOf(parseInt(subCateogry.listgenDesignationID)) > -1}
                                            disabled={(selectedOptions.length > minStrengthCount && selectedOptions.indexOf(parseInt(subCateogry.listgenDesignationID)) === -1)}
                                          />
                                          <ListItemText primary={subCateogry.listgenDesignationName.substring(0, perStrengthCharacterCount)} />
                                          {/* //substring(0, perStrengthCharacterCount) */}
                                        </ListItem>
                                      );

                                    })}
                                  </Collapse>
                                </ul>
                              </li>);
                            })}
                          </List>
                        </div>
                      </Fragment>}
                  </div>
                  <Divider orientation="vertical" flexItem className={classes.middleDivider} />
                  <div className={classes.strengthsRightSection}>
                    {(!selectedOptions.length) ?
                      <Typography variant="body1" align="center" color="primary" className={classNames(classes.height100, classes.displyFlex, classes.centerJustify, classes.centerAlign, classes.bold500Font, classes.selectFLText, classes.paletteRed, classes.minus15pxTop)}><span className={classes.underline}>A minimum of {minStrengthCount} Strengths and/or Designations are required</span>&nbsp;before they will print in your PMAs.</Typography>
                      :
                      <Fragment>
                        {/* <p className={classNames(classes.width100, classes.requiredRed, classes.px12)}>* Required Field</p> */}
                        {strengthData.length > 1 ?
                          <Typography variant="body1" align="center" className={classes.dragDropContainer}>Strengths and/or Designations will print in same order on PMA. You can drag and drop to change order.</Typography>
                          : ''}
                        <DragDropContext
                          onDragEnd={handleDragEnd}
                        >
                          <Droppable droppableId="lg-agent-strength-dd">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getDDListStyle(snapshot.isDraggingOver)}
                              >
                                {strengthData.map((item, key) => {
                                  return (
                                    <Draggable key={item.optionID} draggableId={`lg-ag-${item.optionID}`} index={key}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          //style={{cursor: 'move'}}
                                          style={getDDItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style,
                                            true
                                          )}
                                        >
                                          <div className={classNames(classes.width100, classes.textboxMain, classes.displyFlex)} key={`agent-strength-md-${key}`}>
                                            <div className={classNames(classes.textboxLeftAS, classes.displyFlex, classes.rowFlex)}>
                                              <div className={classNames(classes.strengthImageTxtMain, classes.displyFlex, classes.centerAlign, classes.centerJustify,)}>
                                                {/* //Non custom always be image */}
                                                {!item.isCustom ?
                                                  (item.designationLogoURL ?
                                                    <div className={classes.strengthImageHolder}>
                                                      <img className={classes.strengthImage} src={item.designationLogoURL} />
                                                    </div>
                                                    :
                                                    <div>No logo found.</div>
                                                  ) : (
                                                    <div className={classNames(classes.displyFlex, classes.columnFlex, ((item.designationLogoURL || item.designationLogoText) ? classes.boxCustomImageLayout : ''))}>
                                                      {/* //classes.px56Height,  */}
                                                      <div className={classNames(classes.displyFlex, classes.centerAlign, classes.columnFlex, classes.centerJustify)}>
                                                        {item.designationLogoURL ?
                                                          <div className={classNames(classes.strengthImageHolder)}>
                                                            {regexWithPDF.test(
                                                              item.designationLogoURL.toLowerCase()
                                                            ) ? (
                                                              <IconButton color="primary" title="Click to view PDF" onClick={event => openPDFInNewTab(item.designationLogoURL)}>
                                                                <PictureAsPdfIcon fontSize="large" className={classes.strengthPDFIcon} />
                                                              </IconButton>
                                                            ) : (
                                                              <img className={classNames(classes.strengthImage, classes.pointer)} src={item.designationLogoURL} onClick={event => openViewMedia(item.designationLogoURL)} />
                                                            )}
                                                          </div>
                                                          : ''}
                                                        <p className={classNames(classes.blueLink, classes.pointer, classes.underline, classes.px12)} onClick={event => openUploadMedia(key, item.designationLogoURL)}>{item.designationLogoURL ? 'Change Logo or Image' : 'Upload Logo or Image'}</p>
                                                        <p className={classNames(classes.px12)}>(Optional)</p>
                                                        {item.designationLogoURL ?
                                                          <p className={classNames(classes.paletteRed, classes.pointer, classes.underline, classes.px12)} onClick={event => handleStrengthEditing("designationLogoURL", item.optionID, "")}>Delete</p>
                                                          : ''}

                                                      </div>
                                                      {/* //Show this section if a admin logged in or admin logged in through session switch */}
                                                      {isAdminAccess ?
                                                        <Fragment>
                                                          <div className={classNames(classes.orDiv)}>Or, you can enter your<br />own custom text.</div>
                                                          <TextField
                                                            key={"strength-txt-textbox-" + key}
                                                            label={"Enter Left Text here"}
                                                            //placeholder={item.isCustom ? "Type your custom strength" : ""}
                                                            //variant="outlined"
                                                            //fullWidth={true}
                                                            required={true}
                                                            value={item.designationLogoText ? item.designationLogoText : ''}
                                                            margin="dense"
                                                            inputProps={{
                                                              maxLength: 4,
                                                            }}
                                                            helperText={`Max 4 characters`}
                                                            onChange={event => handleStrengthEditing('designationLogoText', item.optionID, event.target.value)}
                                                          />
                                                        </Fragment> : ''}
                                                    </div>
                                                  )}
                                              </div>
                                              <div className={classes.strengthTextboxMain}>
                                                <TextField
                                                  key={"strength-textbox-" + key}
                                                  label={`Type your strength # ${(key + 1)} here`}
                                                  //<span className={classes.bold500Font}></span>
                                                  //placeholder={`Type your strength # ${(key + 1)} here`}
                                                  //placeholder={item.isCustom ? "Type your custom strength" : ""}
                                                  variant="outlined"
                                                  fullWidth={true}
                                                  required={true}
                                                  value={item.designationName}
                                                  margin="dense"
                                                  inputProps={{
                                                    disabled: !item.isCustom,
                                                    minLength: perMinStrengthCharacterCount,
                                                    maxLength: perStrengthCharacterCount,
                                                    className: classNames(classes.lightBlueBG, classes.hoverBgWhite)
                                                  }}
                                                  className={(!item.isCustom ? classes.disabled : '')}
                                                  onChange={event => handleStrengthEditing('designationName', item.optionID, event.target.value)}
                                                  error={item.isCustom && showError && (item.designationName === '' || (item.designationName && item.designationName.length < perMinStrengthCharacterCount))}
                                                />

                                                <Fragment>
                                                  <div className={classNames(classes.width100, classes.displyFlex, classes.spaceBWJustify, classes.px12)}>
                                                    {item.isCustom ? <div key={"strength-cc-1-" + key}>You can edit the text in this box.</div> : <div></div>}
                                                    {isAdminAccess ?
                                                      (item.designationsVerificationStatus === "verified" ?
                                                        <div className={classNames(classes.green900, classes.bold700Font, classes.uppercase, classes.px14)}>Verified</div>
                                                        : <div className={classNames(classes.red900, classes.bold700Font, classes.uppercase, classes.px14)}>Not Verified</div>)
                                                      : <div></div>}
                                                    {item.isCustom ? <div key={"strength-cc-2-" + key}>(Character Count: {item.designationName.length}/{perStrengthCharacterCount})</div> : <div></div>}
                                                  </div>
                                                </Fragment>
                                              </div>
                                            </div>
                                            <div
                                              className={classNames(classes.textboxRightAS, classes.pointer, classes.displyFlex, classes.columnFlex, classes.centerAlign)}
                                              onClick={event => addMoreOption(item.optionText, item.optionID, item.isCustom, item.isSuggestion, item.designationLogoURL)}>
                                              {/* <Button color="primary">Edit</Button> */}
                                              <p color="default" className={classNames(classes.paletteRed, classes.bold500Font)}>Delete</p>
                                            </div>
                                          </div>
                                          {/* <Divider className={classes.smallDivider} /> */}
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                        {/* //minimum 3 strength required */}
                        {strengthData.length < minStrengthCount ?
                          <div className={classNames(classes.progressWatcher, classes.px14, classes.textCenterAlign, classes.lightRed)}>
                            {`A minimum of ${minStrengthCount - (strengthData.length)} more Strength${(minStrengthCount - (strengthData.length) > 1 ? `'s` : ``)} / Designation is required. (${maxStrengthCount} are preferred)`}
                          </div>
                          : ''}
                        {strengthData.length === minStrengthCount ?
                          <div className={classNames(classes.progressWatcher, classes.px14, classes.textCenterAlign, classes.lightRed)}>
                            Great!&nbsp;&nbsp;&nbsp;You have selected {minStrengthCount} Strengths/Designations. <span className={classes.bold700Font}>For best results, go ahead and add one more.</span>
                          </div>
                          : ''}
                        {strengthData.length === maxStrengthCount ?
                          <div className={classNames(classes.progressWatcher, classes.px14, classes.textCenterAlign, classes.lightGreen)}>
                            Nice!&nbsp;&nbsp;&nbsp;Please click on <span className={classNames(classes.bold500Font)}>Save and Continue</span> to go to <span className={classNames(classes.uppercase, classes.bold500Font)}>Neighborhood advantages</span>.
                        </div>
                          : ''}
                        <div className={classes.width100}>
                          {showError ?
                            <Typography variant="body1" align="center" className={classNames(classes.paletteRed, classes.errorDiv)}>Please fill in all required fields. Minimum characters required to fill a strength field is {perMinStrengthCharacterCount}.</Typography>
                            : ''}
                        </div>
                        {/* Portlet Footer Changes */}
                        {isBtnLoading ?
                          <div className={classNames(classes.progressWrapper, classes.displyFlex, classes.centerJustify)}>
                            <CircularProgress />
                          </div>
                          :
                          <Fragment>
                            <div className={classes.portletFooter}>
                              <div className={classes.footerButtons}>
                                <div className={classes.footerButtonsLeftRight}></div>
                                <div className={classes.footerButtonsCenter}>
                                  <Button
                                    variant="contained"
                                    onClick={event => handleSubmitForm()}
                                    color="primary"
                                    disabled={!(strengthData.length >= minStrengthCount)}
                                  >
                                    {isAdminAccess ? 'Verify and Continue' : 'Save and Continue'}
                                  </Button>
                                </div>
                                <div className={classes.footerButtonsLeftRight}></div>
                              </div>
                            </div>
                          </Fragment>
                        }
                      </Fragment>
                    }
                  </div>
                </div>
              </PortletContent>
            </Portlet>
          </div>
        </DashboardLayout>
      </DocumentTitle>
    </Fragment>
  );
}

AgentStrengths.propTypes = {
  classes: PropTypes.object.isRequired
};
export default compose(
  withSnackbar,
  withStyles(styles),
  withRouter,
)(AgentStrengths);
