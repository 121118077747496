export const apiConstants = {
  url: "http://ec2-3-217-204-102.compute-1.amazonaws.com/",
  corsUrl: "https://cors-anywhere.herokuapp.com/",

  login: "/oauth/token",
  registerAgent: "/agent/registerAgent",
  getBasicAgentDetails: "/agent/getBasicAgentDetails",
  getAgentDetails: "/agent/getAgentDetails",
  getAgentDashboard: "/agent/getAgentDashboard",

  handleRegisterToken: "/agent/handleRegisterToken",

  changeAccountPassword: "/agent/changePassword",
  changeEmail: "/agent/changeEmail",
  updateAgentDetails: "/agent/updateAgentDetails",

  resetPasswordRequest: "/agent/resetPasswordRequest",
  validateResetPasswordRequest: "/agent/validateResetPasswordRequest",
  resetPassword: "/agent/resetPassword",

  createAgent: "/agent/createAgent",
  getAllUsers: "/login/getAllUsers",

  getMailerProDetails: "/agent/getMailerProDetails",
  updateMailerProDetails: "/agent/updateMailerProDetails",
  updateMailerProDetailsV1: "/agent/updateMailerProDetailsAndGetNavbarInfoDTO",
  updateMailerProEntity: "/admin/updateMailerProEntry",
  getMailProIDDetails: "/admin/getMailProIDDetails",
  approveMailProIDs: "/agent/approveMailProIDs",
  changeMailProStatus: "/agent/changeAgentMailProStatus",
  getMarketActivity: "/agent/getPMAMidQuarterMarketActivity",

  getNavbarInfo: "/agent/getNavbarInfo",
  getNavbarInfoV1: "/admin/getNavbarInfoDTO",

  updateNavbar: "/agent/updateNavbarInfo",
  getQuarterAreaTable: "/agent/getPMAQuarterlyAreaTableDetails",
  getQuarterCityTable: "/agent/getPMAQuarterlyCityTableDetails",
  getPMACoverPhotoDetails: "/admin/getPMACoverPhotoDetails",
  getLocalSellingAgents: "/agent/getLocalAgentSellingPercent",
  getCityAreaMap: "/admin/getCityAreaMap",
  getMQ2CityDetails: "/agent/getMidQuarterlyPMADetails",
  getTablePmaApi: "/agent/getTableDataPMADetails",
  getTableBubbleData: "/agent/getTableDataWithBubbleDataPMA",
  getPriceRangeDashboardPriceRangeWithBubbleData:
    "/agent/getPriceRangeDashboardPriceRangeWithBubbleDataPMA",
  getAprilApi: "/agent/getAprilPMADetails",
  getMayApi: "/agent/getTableDataPMADetails",
  getSpeedometerData: "/agent/getSpeedoMeterPMADetails",
  getBubbleBoxDetails: "/admin/getMarketValueInfoForAgent",
  getQuadrantDetails: "/admin/getQuadrantInfoForAgent",
  setQuadrantDetails: "/admin/setQuadrantInfoForAgent",
  setBubbleDetails: "/admin/setMarketValueInfoForAgent",
  setMissionStatementDetails: "/admin/setMissionStatementDetails",
  getPmaDetailsApi: "/agent/getPMADetails",
  getDecemberData: "/agent/getDashBoardWithPriceRangeAndFeaturePhotos", //getAprilPMADetails
  getTotalUnits: "/agent/getPMAMidQuarterCityDetails",
  getTestimonials: "/agent/getAllClientTestimonials",
  getDashboardDonutApi: "/agent/getDashBoardDonutAndAvgSalePriceChart",
  getDonutAndTable:
    "/agent/getTableDataWithDonutAndAvgSalePriceChartPMADetails",
  getMarketTrends: "/agent/getMarketTrendsPMA",
  getAgentDesignations: "/agent/getAgentDesignations",
  getAgentAdvantages: "/agent/getAgentAdvantages",
  getSalePriceForCities: "/agent/getAvgSalePriceForCitiesLast12Months",
  getSAT: "/admin/getMailPrintInfoDetails",
  getPropertyPhotoDetails: "/agent/getPropertyPhotoDetailsForListgenUserArea",
  getRecentActivityPhotos: "/agent/getPropertyPhotoDetailsForListgenUserArea",
  getAgentQR: "/admin/getMailerProIDForAgentDRENo",

  getMissionStatement: "/agent/getCurrentMissionStatement",
  getYearEndDetails: "/agent/getYearEndPMADetails",
  qAreaAndCityDetails: "/agent/getQuarterlyPMADetails",
  getDataVerification: "/agent/getPMADataVerificationData",
  creds: {
    client_id: "ListGen",
    client_secret: "ListgenSecret",
    grant_type: "password",
  },
  setPersonalPSForMailerProID: "/agent/setPersonalPSForMailerProID",
  setPersonalNoteForMailerProID: "/agent/setPersonalNoteForMailerProID",
  resetPersonalNoteAndPSForMailerProID:
    "/agent/resetPersonalNoteAndPSForMailerProID",
};

export const apiConstantsAdmin = {
  createAgent: "/agent/createAgent",
  getAllUsers: "/login/getAllUsers",
};
