import React, { useState, useRef, Fragment } from "react";
import {
  Editor,
  EditorState,
  ContentState,
  RichUtils,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useEffect } from "react";
import { withSnackbar } from "notistack";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import palette from "theme/palette";

const HANDLED = "handled",
  NOT_HANDLED = "not-handled";

  //define css
const useStyles = makeStyles((theme) => ({
  rteEditorContainerOuter: {
    background: "#E9F7FE",
    minHeight: "100px",
    padding: "1em",
    border: "1px solid #8EA2AD",
    borderRadius: "4px",
    fontFamily: "objektiv-mk2",
  },
  rteEditorContainer: {
    cursor: "text",
    minHeight: "60%",
  },
  rteTextareaError: {
    borderBottomColor: '#ED4740'
  },
  rteEditorContainerFocus: {
     borderBottom: `2px solid ${palette.primary.main}`,
  },
  rteEditorContent: {
    fontSize: "14px",
    lineHeight: "17px",
    color: "black !important",
  },
  rteEditorNavBar: {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
    "&>*": {
      fontSize: "19px",
    },
  },
  viewNoteUnfocused: {
    borderBottom: "1px solid #4A7396",//#8EA2AD
  },
  readOnlyBg: {
    background: "rgba(0, 0, 0, 0.09)",
    pointerEvents: "none",
  },
  extraGreetingText: {
    fontSize: "14px",
    lineHeight: "17px",
    color: "black !important",
    marginBottom: "12px",
  },
  editorCharacterLimitContainer: {
    marginTop: '4px',
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&>*": {
      fontSize: "15px",
      fontStyle: "italic",
      color: "#4A7396",
    },
  },
  errorText: {
    textAlign: "center",
    fontSize: "12.5px",
    letterSpacing: "0.025em",
    marginTop: '12px'
  },
  errorLight: {
    color: red[400],
  },
}));

const NavButton = ({
  children,
  readOnly = false,
  classes,
  onClick,
  customStyle,
}) => {
  return (
    <IconButton
      disabled={readOnly}
      onClick={onClick}
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      style={customStyle}
    >
      {children}
    </IconButton>
  );
};

const customStyleMap = {
  BOLD: {
    fontWeight: "bold",
  },
  STRIKETHROUGH: {
    textDecoration: "line-through",
  },
};

let options = {
  inlineStyles: {
    BOLD: { element: "b" }, //style: { fontWeight: 'bold' }
    TITLE: {
      attributes: { class: "RichEditor-TITLE" },
      style: { fontSize: "1.2em" },
    },
  },
};

const NoteAutomatorNoteEditor = (props) => {
  const rte = useRef(null);
  const classes = useStyles();
  /**props */
  const {
    minLength,
    maxLength,
    readOnly,
    preText,
    postText,
    type,
    category,
    placeholder,
    showPlaceholder,
    showInlineButton,
    showToolbar,
    returnTextMode,
    rteResetEditor,
  } = props;

  //pre state methods
  const getEditorState = (preData) => {
    let content = stateFromHTML(preData);
    return EditorState.createWithContent(content);
  };
  //sanitize predata
  let { preData } = props;
  preData = preData ? preData : "";

  const [editorState, setEditorState] = useState(getEditorState(preData));
  const [hasFocus, setFocus] = useState(false);
  /**Editor content related methods */
  /**build plain text according to pre data */
  const [editorPlainText, setEditorPlainText] = React.useState(
    preData ? editorState.getCurrentContent().getPlainText() : ""
  ); //'\u0001'

  //build an reset hooks. This requires when we reset the global note or personal note
  useEffect(() => {
    if (rteResetEditor) {
      //get the latest editor state becuase use state editorState wont be updatedso quickly so plain text below one would be old one
      let editorState = getEditorState(preData);
      setEditorState(editorState);
      let plainText = preData
        ? editorState.getCurrentContent().getPlainText()
        : "";
      setEditorPlainText(plainText);
      props.updatePlainNoteFn(plainText);
      props.disableRTEResetMode(false);
    }
  }, [rteResetEditor]);

  //Update ps method of parent. we gonna call this to update the plain text in parent global/personal ps editor. We need it for first tiem only
  useEffect(() => {
    props.updatePlainNoteFn(editorPlainText);
  }, []);

  const onChange = (editorState) => {
    setEditorState(editorState);
    const plainTextContent = editorState
      .getCurrentContent()
      .getPlainText()
      .replace(/(\r\n|\n|\r)/gm, " "); //'\u0001'
    setEditorPlainText(plainTextContent);
    if (returnTextMode === "html") {
      props.onChange(
        stateToHTML(editorState.getCurrentContent(), options),
        plainTextContent
      );
    } else {
      props.onChange(plainTextContent, plainTextContent);
    }
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return HANDLED;
    }
    return NOT_HANDLED;
  };

  const setStyle = (style) => {
    onChange(RichUtils.toggleInlineStyle(editorState, style));
  };

  const onUnderlineClick = () =>
    onChange(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
  const onBoldClick = () =>
    onChange(RichUtils.toggleInlineStyle(editorState, "BOLD"));
  const onItalicClick = () => setStyle("ITALIC");

  const isErrorInNote = props.errorMessages.length > 0;

  return (
    <Fragment>
      <div className={classes.rteEditorNavBar}>
        <NavButton
          readOnly={readOnly}
          onClick={onBoldClick}
          customStyle={{ fontWeight: "bold" }}
        >
          B
        </NavButton>
        <NavButton
          readOnly={readOnly}
          onClick={onItalicClick}
          customStyle={{ fontStyle: "italic" }}
        >
          I
        </NavButton>
        <NavButton
          readOnly={readOnly}
          onClick={onUnderlineClick}
          customStyle={{ textDecoration: "underline" }}
        >
          U
        </NavButton>
      </div>
      <div className={classes.rteEditorContainerOuter}>
        <div
          className={classNames(
            classes.rteEditorContainer,
            classes.viewNoteUnfocused, 
            hasFocus ? classes.rteEditorContainerFocus : "",
            readOnly ? classes.readOnlyBg : "",
            isErrorInNote ? classes.rteTextareaError: ""
          )}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            rte.current.editor.focus();
            setFocus(true);
          }}
        >
          {preText ? <div className={classNames(classes.extraGreetingText)}>{preText}</div> : ''}
          <Editor
            ref={rte}
            {...{ editorState, onChange, handleKeyCommand, customStyleMap }}
            onBlur={(event) => setFocus(false)}
            stripPastedStyles={true}
            readOnly={readOnly}
            placeholder={showPlaceholder ? placeholder : ""}
          />
          {postText ? (
            <div
              className={classNames(classes.extraGreetingText)}
              style={{ textAlign: "right", marginTop: '16px' }}
            >
              {postText}
            </div>
          ) : (
            <div style={{ minHeight: "16px" }}></div>
          )}
        </div>
        <div className={classes.editorCharacterLimitContainer}>
          <div></div>
          <div>
            Characters Used: {(editorPlainText && editorPlainText.length) || 0}{" "}
            / {props.noteMaxLength}
          </div>
          <div>
            Characters Max {props.noteMaxLength}/Min{" "}
            {props.noteMinLength}
          </div>
        </div>
        <div>
          {isErrorInNote && (
            <p className={classNames(classes.errorText, classes.errorLight)}>
              {props.errorMessages.join(" ")}
            </p>
          )}
        </div>
        <style>
        {`.DraftEditor-editorContainer > .public-DraftEditor-content{
          color: black !important;
        }
        .DraftEditor-editorContainer > .notranslate.public-DraftEditor-content{
          color: black !important;
        }
        .DraftEditor-editorContainer .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr{
          font-size: 14px !important;
        }`}
        </style>
      </div>
    </Fragment>
  );
};

NoteAutomatorNoteEditor.propTypes = {
  onChange: PropTypes.func,
  updatePlainNoteFn: PropTypes.func,
  showPlaceholder: PropTypes.bool,
  placeholder: PropTypes.string,
  returnTextMode: PropTypes.oneOf(["plain", "html"]),
  rteResetEditor: PropTypes.bool,
  disableRTEResetMode: PropTypes.func,
  noteMaxLength: PropTypes.number,
  noteMinLength: PropTypes.number,
  errorMessages: PropTypes.array,
};

NoteAutomatorNoteEditor.defaultProps = {
  onChange: () => {},
  updatePlainNoteFn: () => {},
  minLength: 200,
  maxLength: 1050,
  preText: "",
  postText: "",
  placeholder: "",
  preData: "",
  showPlaceholder: false,
  showToolbar: true,
  // type: "globalNote",
  // category: "note",
  returnTextMode: "html",
  rteResetEditor: false,
  disableRTEResetMode: () => {},
  noteMaxLength: 0,
  noteMinLength: 0,
  errorMessages: [],
};

export default compose(
  withSnackbar
)(NoteAutomatorNoteEditor);
