import React, { Fragment, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  InputLabel,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import styles from "./styles";
import classNames from "classnames";
import { RegisterAgentUploadPhotoAPI } from "services/agent";
import { withSnackbar } from "notistack";
import compose from "recompose/compose";
import { showErrorNotification } from "common/helpers";
import { showSuccessNotification } from "common/helpers";
import { RegisterAgentUploadTeamSignatureAPI } from "services/agent";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdfOutlined";
import CommonConfirmationDialog from "components/CommonConfirmationDialog";

const ImageUpload = (props) => {
  const [errors, setErrors] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [isUploaded, setIsUploaded] = React.useState(false);
  /**Confirmation dialog changes */
  const [showConfirmationDialog, setConfirmationDialog] = React.useState(false);
  const [confirmationFileData, setConfirmationFileData] = React.useState("");

  const [bypassSizeValidation, setBypassSizeValidation] = React.useState(true);
  const [mediaData, setMediaData] = React.useState({
    maxMediaSize: 50000000,
    // mediaSize: {
    //   profile: {
    //     width: '160',
    //     height: '80',
    //   },
    //   logo: {
    //     width: '200',
    //     height: '75',
    //   },
    //   logo2: {
    //     width: '160',
    //     height: '80',
    //   },
    //   signature: {
    //     width: '200',
    //     height: '60',
    //   },
    // },
  });
  /**props data */
  const {
    listgenUserID,
    classes,
    onSubmit,
    onClose,
    imageType,
    openDialog,
    currentImage,
    teamMode,
  } = props;
  let { isMyAccountUpload } = props;
  /**check for optional param */
  isMyAccountUpload = isMyAccountUpload ? isMyAccountUpload : false;
  const [uploadedImage, setUploadedImage] = React.useState(currentImage);
  /**Image upload by admin */
  let { isAdminUploading } = props;
  isAdminUploading = isAdminUploading ? true : false;
  /**pdf regex */
  const regexWithPDF = new RegExp(/^.*\.(PDF|pdf)$/);

  /**useEffect to launch dialog */
  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  /**useEffect to show changed image */
  useEffect(() => {
    setUploadedImage(currentImage);
  }, [currentImage]);

  /**image settings */
  //Allow PDF upload if it's an agent account not admin account
  //Allow PDF upload for an agent account not admin account
  const settings = {
    file: {
      accept:
        (imageType === "signature" && !isMyAccountUpload) ||
        (imageType === "designationLogo" && !isMyAccountUpload)
          ? "image/*,application/pdf"
          : "image/*",
      multiple: false,
    },
  };

  const handleDialogClose = () => {
    setOpen(false);
    onClose();
  };

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    setOpen(false);
    onSubmit(uploadedImage);
  };

  const removeMediaErros = (message) => {
    setErrors(message);
  };

  const validateFile = (file) => {
    /**call remove media errors first */
    removeMediaErros();
    /**check for file type first */
    var regex = new RegExp(/^.*\.(jpg|jpeg|JPG|JPEG|png|PNG)$/);
    var regexWithPDF = new RegExp(/^.*\.(jpg|jpeg|JPG|JPEG|png|PNG|PDF|pdf)$/);
    /**now check for signature image type. if type is signature than allow pdf also */
    if (
      (imageType === "signature" && !isMyAccountUpload) ||
      (imageType === "designationLogo" && !isMyAccountUpload)
    ) {
      if (!regexWithPDF.test(file.name.toLowerCase())) {
        setErrors(
          "Invalid file type. Only jpg | JPG | jpeg | JPEG | png | PNG | PDF | pdf extension file allowed."
        );
        return;
      }
    } else {
      if (!regex.test(file.name.toLowerCase())) {
        setErrors(
          "Invalid file type. Only jpg | JPG | jpeg | JPEG | png | PNG extension file allowed."
        );
        return;
      }
    }
    /**check for size */
    if (file.size > mediaData.maxMediaSize) {
      setErrors(
        "Invalid file size. Maximum file size should not exceed 50 mb."
      );
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    const parentScope = this;
    reader.onload = function (e) {
      var image = new Image();
      image.src = e.target.result;
      // image.onload = function () {
      //   var height = this.height;
      //   var width = this.width;
      //   /**this validation is gonna bypass */
      //   if (
      //     !bypassSizeValidation &&
      //     (!(width <= mediaData.mediaSize[imageType].width) ||
      //       !(height <= mediaData.mediaSize[imageType].height))
      //   ) {
      //     setErrors(
      //       'Invalid media size. Allowed resolution(width: ' +
      //       mediaData.mediaSize[imageType].width +
      //       ' x height: ' +
      //       mediaData.mediaSize[imageType].height +
      //       '). Current resolution(width: ' +
      //       width +
      //       ' x height: ' +
      //       height +
      //       ')'
      //     );
      //     return;
      //   }
      //   /**everythinf fine. upload image */
      //   uploadImages(file);
      // };
      uploadImages(file);
    };
  };

  const uploadMedia = (event) => {
    console.log("event", event.target.files);
    //event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      setConfirmationFileData(event.target.files[0]);
      setConfirmationDialog(true);
      return;
    }
  };

  const cancelUpload = () => {
    console.log("cancelling upload");
    setConfirmationDialog(false);
    setConfirmationFileData("");
    removeMediaErros();
  };

  const proceedWithUpload = (filesData) => {
    console.log("proceeding with upload");
    setConfirmationDialog(false);
    validateFile(filesData);
  };

  const getText = () => {
    if (imageType === "profile") {
      return (
        <Fragment>
          <Typography variant="subtitle1" align="center">
            If you are part of a team, please upload your TEAM photo.
          </Typography>
          <Typography className={classes.marginTop2Unit}>
            Photo must have a .png or .jpg file extension and should not exceed
            50 MB in size.
          </Typography>
        </Fragment>
      );
    } else if (imageType === "logo" || imageType === "logo2") {
      return (
        <Fragment>
          <Typography
            variant="subtitle1"
            align="center"
            className={classNames(classes.marginTop2Unit)}
          >
            Please upload agent logo below. This will appear on a blue
            background.
          </Typography>
          <Typography>
            Photo must have a .png or .jpg file extension and should not exceed
            50 MB in size.
          </Typography>
        </Fragment>
      );
    } else if (imageType === "signature") {
      return (
        <Fragment>
          <Typography
            variant="subtitle1"

            // className={classes.marginBottom5Units}
          >
            To make your signature more personal we suggest you use{" "}
            <Typography className={classes.blueText}>BLUE INK</Typography> on a
            bright white background. Please send us 4 or 5 signatures on the
            same page so we can rotate them each mailing - making them appear
            even more personalized. Please scan in at a minimum of 300 DPI.
            We'll take care of separating them out.
          </Typography>
          <Typography className={classes.marginTop2Unit}>
            If you are uploading an image than it should have .png or .jpg file
            extension and should not exceed 50 MB in size.
            {!isMyAccountUpload ? (
              <Fragment>
                <br />
                If you are uploading PDF than it should have .pdf file extension
                and should not exceed 50 MB in size.
              </Fragment>
            ) : (
              ""
            )}
          </Typography>
        </Fragment>
      );
    } else if (imageType === "brokerageLogo") {
      return (
        <Fragment>
          <Typography
            variant="subtitle1"
            align="center"
            className={classNames(classes.marginTop2Unit)}
          >
            Please upload agent brokerage logo below.
          </Typography>
          <Typography>
            Photo must have a .png or .jpg file extension and should not exceed
            50 MB in size.
          </Typography>
        </Fragment>
      );
    } else if (imageType === "designationLogo") {
      return (
        <Fragment>
          <Typography
            variant="subtitle1"
            align="center"
            className={classNames(classes.marginTop2Unit)}
          >
            Please upload strength logo or image below.
          </Typography>
          <Typography className={classes.marginTop2Unit}>
            If you are uploading an image than it should have .png or .jpg file
            extension and should not exceed 50 MB in size.
            {!isMyAccountUpload ? (
              <Fragment>
                <br />
                <br />
                If you are uploading PDF than it should have .pdf file extension
                and should not exceed 50 MB in size.
              </Fragment>
            ) : (
              ""
            )}
          </Typography>
        </Fragment>
      );
    } else if (imageType === "propertyHighlight") {
      return (
        <Fragment>
          <Typography
            variant="subtitle1"
            align="center"
            className={classNames(classes.marginTop2Unit)}
          >
            Please upload property highlight photo.
          </Typography>
          <Typography className={classes.marginTop2Unit}>
            If you are uploading an image than it should have .png or .jpg file
            extension and should not exceed 50 MB in size.
          </Typography>
        </Fragment>
      );
    } else if (imageType === "coverPhoto") {
      return (
        <Fragment>
          <Typography
            variant="subtitle1"
            align="center"
            className={classNames(classes.marginTop2Unit)}
          >
            Please upload cover photo for PMA.
          </Typography>
          <Typography className={classes.marginTop2Unit}>
            If you are uploading an image than it should have .png or .jpg file
            extension and should not exceed 2 MB in size.
          </Typography>
        </Fragment>
      );
    }
  };

  const getButtonText = () => {
    if (imageType === "profile") {
      return "Upload Agent Photo";
    } else if (imageType === "logo") {
      return isAdminUploading
        ? "Upload Agent Light Background Logo"
        : "Upload Agent Logo";
    } else if (imageType === "logo2") {
      return "Upload Agent Dark Background Logo";
    } else if (imageType === "signature") {
      return "Upload Agent Signature";
    } else if (imageType === "brokerageLogo") {
      return "Upload Agent Brokerage Logo";
    } else if (imageType === "designationLogo") {
      return "Upload Strength Logo or Image";
    } else if (imageType === "propertyHighlight") {
      return "Upload Property Highlight Image";
    } else if (imageType === "coverPhoto") {
      return "Upload PMA Cover Photo";
    }
  };

  const getConfirmationDialogText = () => {
    if (imageType === "profile") {
      return "Profile Image";
    } else if (imageType === "logo") {
      return isAdminUploading
        ? "Agent Light Background Logo Image"
        : " Logo Image";
    } else if (imageType === "logo2") {
      return "Agent Dark Background Logo Image";
    } else if (imageType === "signature") {
      return "Signature Image/PDF";
    } else if (imageType === "brokerageLogo") {
      return "Agent Brokerage Logo";
    } else if (imageType === "designationLogo") {
      return "Strength Logo or Image";
    } else if (imageType === "propertyHighlight") {
      return "Property Highlight Image";
    } else if (imageType === "coverPhoto") {
      return "PMA Cover Photo";
    }
  };

  const uploadImages = async (file) => {
    console.log("upload image file is", file);
    /**make loading true */
    setLoading(true);
    try {
      let apiCall = teamMode
        ? RegisterAgentUploadTeamSignatureAPI(listgenUserID, file) //it's for team members signature upload
        : RegisterAgentUploadPhotoAPI(
            imageType,
            listgenUserID,
            file,
            isAdminUploading
          );

      apiCall
        .then((data) => {
          console.log("image data is", data);
          if (data) {
            //setIsUploaded(true);
            //setUploadedImage(data);
            if (imageType === "profile") {
              showSuccessNotification(props, "Agent photo uploaded.");
            } else if (imageType === "logo") {
              showSuccessNotification(
                props,
                isAdminUploading
                  ? "Agent light background logo uploaded."
                  : "Agent logo uploaded."
              );
            } else if (imageType === "logo2") {
              showSuccessNotification(
                props,
                "Agent dark background logo uploaded."
              );
            } else if (imageType === "signature") {
              showSuccessNotification(props, "Agent signature uploaded.");
            } else if (imageType === "brokerageLogo") {
              showSuccessNotification(props, "Agent brokerage logo uploaded.");
            } else if (imageType === "designationLogo") {
              showSuccessNotification(props, "Strength logo/image uploaded.");
            } else if (imageType === "propertyHighlight") {
              showSuccessNotification(
                props,
                "Property highlight image uploaded."
              );
            } else if (imageType === "coverPhoto") {
              showSuccessNotification(
                props,
                "PMA cover photo is ready to assign."
              );
            }
            /**now call handle submit */
            //handleSubmit();
            setTimeout(() => {
              setOpen(false);
              onSubmit(data);
              //reset the confirmation file data
              setConfirmationFileData("");
            }, 600);
          } else {
            setIsUploaded(false);
            setUploadedImage(null);
            setConfirmationFileData("");
            showErrorNotification(props, "Unable to upload image.");
          }
          /**stop loading also */
          setLoading(false);
        })
        .catch((err) => {
          console.log("error happened", err);
          showErrorNotification(props, "Unable to process request.");
          setIsUploaded(false);
          setUploadedImage(null);
          setConfirmationFileData("");
          setLoading(false);
        });
    } catch (err) {
      console.log("error in uploading", err);
      showErrorNotification(props, "Unable to process request.");
      setIsUploaded(false);
      setUploadedImage(null);
      setConfirmationFileData("");
      setLoading(false);
    }
  };

  const openPDFInNewTab = (url) => {
    console.log("open pdf in new tab", url);
    var win = window.open(url, "_blank");
    win.focus();
  };

  return (
    <Fragment>
      {showConfirmationDialog ? (
        <CommonConfirmationDialog
          title={`Are you sure you want to upload this?`}
          subtitle={`Once you upload a new ${getConfirmationDialogText()} it will replace the previous one if it's uploaded before. The previous uploaded ${getConfirmationDialogText()} will no longer be available for your ListGen account.`}
          closeFn={cancelUpload}
          methodToCallOnConfirmation={proceedWithUpload}
          confirmationID={confirmationFileData}
        />
      ) : (
        ""
      )}
      <Dialog
        open={open}
        onClose={handleDialogClose}
        scroll="paper"
        aria-labelledby="image-upload-dialog-title"
      >
        <DialogTitle id="image-upload-dialog-title">
          <div>{getText()}</div>
        </DialogTitle>
        <DialogContent>
          <div
            className={classNames(classes.field, classes.marignTop4UnitMinus)}
          >
            <div className={classes.imageHolderWrapper}>
              {uploadedImage ? (
                <div className={classes.imageHolderDialog}>
                  {regexWithPDF.test(uploadedImage.toLowerCase()) ? (
                    <IconButton
                      color="primary"
                      onClick={(event) => openPDFInNewTab(uploadedImage)}
                    >
                      <PictureAsPdfIcon
                        fontSize="large"
                        className={classes.pdfIcon}
                      />
                    </IconButton>
                  ) : (
                    <img
                      src={uploadedImage}
                      alt="Uploaded Image"
                      className={classes.imageTag}
                    />
                  )}
                </div>
              ) : (
                ""
              )}
              <div className={classes.fieldSection}>
                <TextField
                  className={classNames(classes.textField, classes.hideField)}
                  id="additional-agent-image"
                  name="agentLogo"
                  onChange={(event) => {
                    console.log("changing event", event);
                    uploadMedia(event);
                  }}
                  onClick={(event) => {
                    console.log("event is", event);
                    event.target.value = null;
                  }}
                  type="file"
                  inputProps={settings.file}
                />
                <InputLabel htmlFor="additional-agent-image">
                  {!isLoading ? (
                    <Button
                      variant="contained"
                      component="span"
                      className={classNames(
                        classes.center,

                        imageType === "propertyHighlight"
                          ? classes.uploadPropHighlightsButton
                          : classes.uploadButton
                      )}
                    >
                      {getButtonText()}
                    </Button>
                  ) : (
                    <CircularProgress className={classes.progress} />
                  )}
                </InputLabel>
              </div>
              <div className={classes.fieldSectionError}>
                {errors && (
                  <Typography className={classes.fieldError} variant="body2">
                    {errors}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        {/* <DialogActions>
        {!isLoading ? (
          <div className={classes.portletFooter}>
            <div className={classes.footerButtons}>
              <div className={classes.footerButtonsLeftRight}>
                <Button
                  variant="outlined"
                  onClick={event => handleDialogClose()}
                  color="default"
                >
                  Cancel
                </Button>
              </div>
              <div className={classes.footerButtonsCenter}>
                <Button
                  variant="contained"
                  onClick={event => handleSubmit()}
                  color="primary"
                >
                  Submit
                </Button>
              </div>
              <div className={classes.footerButtonsLeftRight}></div>
            </div>
          </div>
        ) : (
          ''
        )}
      </DialogActions> */}
      </Dialog>
    </Fragment>
  );
};

ImageUpload.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  imageType: PropTypes.string.isRequired,
  openDialog: PropTypes.bool.isRequired,
  listgenUserID: PropTypes.string.isRequired,
  currentImage: PropTypes.string,
  teamMode: PropTypes.bool,
};

ImageUpload.defaultProps = {
  onSubmit: () => {},
  onClose: () => {},
  imageType: "profile",
  teamMode: false,
  isMyAccountUpload: false,
  isAdminUploading: false,
};

export default compose(withSnackbar, withStyles(styles))(ImageUpload);
